import * as React from 'react';
import {processContentId} from '../processContentId';
import {useTrackSliderLoad} from './useTrackSliderLoad';
import {Filter, FocusCategory} from '../../context/tracking/TrackingService';
import {
    getValueByScreenSize,
    isBreakpointConfig
} from '@volkswagen-onehub/components-core';
import {slidesPerPageConfig} from '../../d6/components/content-slider/helpers';
import {useRef} from 'react';
import {ContentSlide} from '../../d6/components/content-slider';

export function useTrackContentSliderLoad(props: {
    contentName: string;
    contentLabels: string;
    cqPath: string;
    sliderItemsLength: number;
    trackingFocusCategories: FocusCategory[];
    filterlist: Filter[];
    enableTracking: boolean;
}): (filteredSlides: ContentSlide[], currentIndex: number) => void {
    const {
        contentName,
        contentLabels,
        cqPath,
        sliderItemsLength,
        trackingFocusCategories,
        filterlist,
        enableTracking
    } = props;
    const trackSliderLoad = useTrackSliderLoad();
    const alreadyTrackedIndex = useRef(new Set<string>());

    const width = typeof window !== 'undefined' ? window.innerWidth || 0 : 0;

    const currentSlidesPerPage = isBreakpointConfig(slidesPerPageConfig)
        ? getValueByScreenSize(width, slidesPerPageConfig)
        : slidesPerPageConfig;

    return React.useCallback(
        (filteredSlides: ContentSlide[], currentIndex: number): void => {
            for (
                let i = currentIndex;
                i < currentIndex + currentSlidesPerPage;
                i++
            ) {
                if (
                    i < filteredSlides.length &&
                    !alreadyTrackedIndex.current.has(filteredSlides[i].key) &&
                    enableTracking
                ) {
                    const contentId = processContentId(
                        `${cqPath}/${filteredSlides[i].key}`,
                        `${contentName || 'contentSliderSection'}`
                    );

                    trackSliderLoad(
                        contentId,
                        contentLabels,
                        sliderItemsLength,
                        filteredSlides[i].element.props.itemPosition,
                        trackingFocusCategories,
                        filterlist
                    );
                    alreadyTrackedIndex.current.add(filteredSlides[i].key);
                }
            }
        },
        [
            trackSliderLoad,
            contentName,
            contentLabels,
            currentSlidesPerPage,
            cqPath,
            sliderItemsLength,
            trackingFocusCategories,
            filterlist,
            enableTracking
        ]
    );
}
