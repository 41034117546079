import {DisclaimerManagerV1} from '@volkswagen-onehub/disclaimer-manager';
import {ObservableMap, observable} from 'mobx';
import {GlobalDisclaimerRegistryModel} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {getGlobal} from '../../utils/getGlobal';
import {Logger} from '../logger/Logger';
import {
    DisclaimerStore,
    GroupedDisclaimersWithReference,
    RegisteredDisclaimer,
    StandardDisclaimerStore
} from './DisclaimerStore';
import {DisclaimerStoreProxy} from './DisclaimerStoreProxy';
import {LayerManagerV24} from '@volkswagen-onehub/layer-manager';
import {RouterService} from '../route/RouterService';
import {NavigationStore} from '../navigation/NavigationStore';

/**
 * The global disclaimer store manages the disclaimer on the main page. It manages the disclaimers in the new and the old page
 * which exist at the same time during navigation (very obvious for the case of a transition).
 * The disclaimers outside of the navigable area in the footer and Topbar are managed by the permanent DisclaimerProxyStore
 * and the disclaimers in the navigable area are managed by the exchangeable store.
 */
@singleton('GlobalDisclaimerStore')
export class GlobalDisclaimerStore {
    /** the internal disclaimer store */
    public permanent!: DisclaimerStoreProxy;

    /** path the disclaimer store relates to */
    @observable private path: string = '/';

    private layeredStoreMap!: ObservableMap<string, StandardDisclaimerStore>;

    @inject()
    private globalDisclaimerRegistryModel!: GlobalDisclaimerRegistryModel;

    @inject()
    private disclaimerManager!: DisclaimerManagerV1;

    @inject()
    private layerManager!: LayerManagerV24;

    @inject()
    private routerService!: RouterService;

    @inject()
    private navigationStore!: NavigationStore;

    @inject() private logger!: Logger;

    @postConstruct()
    public init(): void {
        getGlobal().disclaimer = this;
        this.layeredStoreMap = new ObservableMap();
        this.permanent = new DisclaimerStoreProxy(
            this.createStore(this.path),
            this.logger
        );
    }

    public setScopedDisclaimerManagerToActualPageForFAConsumer(
        consumerId: string,
        legalEntity?: string
    ): void {
        if (legalEntity) {
            this.disclaimerManager.setDefaultLegalForConsumer(
                consumerId,
                legalEntity
            );
        }
        this.disclaimerManager.setDefaultScopeForConsumer(
            consumerId,
            this.path
        );
    }

    /**
     * creates new store, if path has been changed, otherwise current store will persist
     */
    public getOrCreateStore(path: string): DisclaimerStore {
        if (this.path !== path) {
            this.switchStore(path);
        }

        return this.permanent.store;
    }

    public get footerDisclaimers(): GroupedDisclaimersWithReference[] {
        return this.permanent.store.footerDisclaimers;
    }

    public get openableDisclaimer(): RegisteredDisclaimer | undefined {
        return this.permanent.store.openableDisclaimer;
    }

    public getLayeredStore(path: string): DisclaimerStore {
        let store = this.layeredStoreMap.get(path);

        if (!store) {
            store = this.createStore(path);
            this.layeredStoreMap.set(path, store);
        }

        return store;
    }

    private switchStore(path: string): void {
        this.path = path;
        this.permanent.store = this.createStore(path);
    }

    private createStore(path: string): StandardDisclaimerStore {
        return new StandardDisclaimerStore(
            this.disclaimerManager.getScopedDisclaimerManager(path),
            this.layerManager,
            this.globalDisclaimerRegistryModel,
            this.routerService,
            this.navigationStore
        );
    }
}
