import {
    ContextTrackingData,
    TrackingDTO,
    TrackingService
} from '../../../../context/tracking/TrackingService';
import {processContentId} from '../../../../utils/processContentId';

export const createTrackingFunction = (
    trackingService: TrackingService,
    contentId: string,
    layerButton: boolean,
    buttonStyle: string | undefined,
    alternativeActionUrl?: string,
    additionalTrackingData?: Partial<TrackingDTO>,
    actionId?: string
) => {
    const updatedAdditionalTrackingData = modifyContentActionId(
        additionalTrackingData,
        actionId
    );

    return (
        href: string,
        linkName: string,
        trackingData: ContextTrackingData,
        layerName?: string
    ) => {
        if (alternativeActionUrl) {
            if (buttonStyle === 'secondary') {
                trackingService.trackLinkClick(
                    contentId,
                    href,
                    linkName,
                    trackingData,
                    updatedAdditionalTrackingData,
                    actionId
                );
            } else {
                trackingService.trackButtonClick(
                    contentId,
                    href,
                    linkName,
                    trackingData,
                    updatedAdditionalTrackingData,
                    actionId
                );
            }
        } else {
            trackingService.trackButtonClick(
                contentId,
                href,
                linkName,
                trackingData,
                updatedAdditionalTrackingData
            );

            if (layerButton) {
                trackingService.trackLayerLoad(
                    processContentId(href, layerName),
                    trackingData
                );
            }
        }
    };
};

const modifyContentActionId = (
    additionalTrackingData?: Partial<TrackingDTO>,
    actionId?: string
): Partial<TrackingDTO> => {
    const trackingDate = additionalTrackingData || {};
    if (!trackingDate.content) {
        trackingDate.content = {};
    }
    trackingDate.content.modifiedByAction = actionId;

    return trackingDate;
};
