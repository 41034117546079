import {LogEntry} from './HttpLogger';
import fetch from '@strictsoftware/fetch';

export async function sendMessage(url: string, event: LogEntry): Promise<void> {
    const data = new URLSearchParams();
    data.append('Action', 'SendMessage');
    data.append('MessageBody', JSON.stringify(event));

    try {
        await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: data
        });
    } catch (reason) {
        console.error('error while sending log msg to %s: %s', url, reason);
    }
    return undefined;
}
