import {
    Breakpoints,
    Text,
    designTokens,
    ThemeDefinition
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled, {css, StyledComponent} from 'styled-components';
import {
    LayoutTable,
    TableItemModel,
    TableSectionModel
} from '../../../../../generated/core';
import {ContainerItem} from '../../../../infrastructure/ContainerExporter';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import * as MergeColumnCellElement from '../../elements/table/cells/MergeColumnCellElement';
import * as MergeRowCellElement from '../../elements/table/cells/MergeRowCellElement';
import {
    getCellContent,
    getCellType,
    getCells,
    getColSpan,
    getCqPath,
    getNumberOfColumns,
    getRowSpan,
    getRows,
    getScope,
    StyledCaptionForScreenReaders
} from './utils';
import {elseUndefined} from '../../../../utils/elseUndefined';
import {getStartDirection} from '../../../../d6/components/helpers';

const StyledTable = styled.table`
    width: 100%;
    empty-cells: show;
    border-collapse: collapse;
    display: table;
    @media (max-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;

interface StyledCellProps extends React.TdHTMLAttributes<HTMLTableCellElement> {
    layout: LayoutTable;
    isSummary?: boolean;
}

const StyledTd = styled.td<StyledCellProps>`
    padding-left: ${props => props.theme.size.static150};
    padding-right: ${props => props.theme.size.static150};
    padding-top: ${props => props.theme.size.static200};
    padding-bottom: ${props => props.theme.size.static250};
    border: 1px solid ${designTokens.color['grey-100']};
    vertical-align: top;

    ${props =>
        props.isSummary &&
        css`
            span {
                font-weight: bold;
            }
        `};

    ${StyledTable} tbody tr &:first-child {
        border-left: 0;
    }

    ${StyledTable} tbody tr &:last-child {
        border-right: 0;
    }

    ${StyledTable} tbody tr:last-child & {
        border-bottom: 1px solid
            ${props =>
                props.layout === 'HEADER_CATEGORY_SUM'
                    ? designTokens.color['black-000']
                    : designTokens.color['grey-100']};
    }
`;

const StyledTh = styled.th<StyledCellProps>`
    padding-left: ${props => props.theme.size.static150};
    padding-right: ${props => props.theme.size.static150};
    padding-top: ${props => props.theme.size.static200};
    padding-bottom: ${props => props.theme.size.static250};
    vertical-align: top;
    text-align: ${props => getStartDirection(props.theme.direction)};

    ${StyledTable} tr &:first-child {
        border-left: 0;
    }

    ${StyledTable} tr &:last-child {
        border-right: 0;
    }

    ${StyledTable} tbody tr &:first-child {
        border-right: 2px solid ${designTokens.color['black-000']};
    }

    ${StyledTable} tbody tr & {
        border-bottom: 1px solid ${designTokens.color['grey-100']};
    }

    ${StyledTable} thead tr & {
        padding-top: 0;
        border-top: 0;
        border-bottom: 2px solid ${designTokens.color['black-000']};
    }

    ${StyledTable} tfoot tr & {
        border-bottom: 0;
        border-top: 2px solid ${designTokens.color['black-000']};
    }

    ${props =>
        props.layout === 'HEADER_CATEGORY_SUM' &&
        css`
            ${StyledTable} tbody tr:last-child & {
                border-bottom: 0;
            }
        `}

    ${props =>
        props.layout === 'CATEGORY' &&
        css`
            ${StyledTable} tbody tr:first-child & {
                border-top: 1px solid ${designTokens.color['grey-100']};
            }
        `}
`;

interface StyledColProps {
    width?: number;
    columnsCount: number;
}

export enum rowType {
    headRowType = 'headRowType',
    bodyRowType = 'bodyRowType',
    footRowType = 'footRowType'
}

const StyledCol = styled.col<StyledColProps>`
    width: ${props =>
        props.width ? `${props.width}%` : `calc(100% / ${props.columnsCount})`};
`;

export class DesktopTable extends React.PureComponent<
    TableSectionModel | TableItemModel
> {
    public render(): React.ReactNode {
        const {
            layout,
            width1,
            width2,
            width3,
            width4,
            width5,
            width6,
            caption
        } = this.props;
        const tbodyRows = getRows(this.props);
        const columnsCount = getNumberOfColumns(tbodyRows);
        const theadRow = layout !== 'CATEGORY' ? tbodyRows.shift() : null;
        const tfootRow =
            layout === 'HEADER_CATEGORY_SUM' ? tbodyRows.pop() : null;
        const columnWidths: number[] = [
            width1,
            width2,
            width3,
            width4,
            width5,
            width6
        ];

        const colgroup = [];

        for (let i = 0; i < columnsCount; i++) {
            colgroup.push(
                <StyledCol
                    key={i}
                    columnsCount={columnsCount}
                    width={columnWidths[i]}
                />
            );
        }

        return (
            <StyledTable>
                {elseUndefined(
                    caption,
                    <StyledCaptionForScreenReaders>
                        {caption}
                    </StyledCaptionForScreenReaders>
                )}
                <colgroup>{colgroup}</colgroup>
                {theadRow && (
                    <CyAttributeAppender name="tableHeader">
                        <thead>
                            {this.renderRows(
                                [theadRow],
                                StyledTh,
                                rowType.headRowType
                            )}
                        </thead>
                    </CyAttributeAppender>
                )}
                <CyAttributeAppender name="tableBody">
                    <tbody>
                        {this.renderRows(
                            tbodyRows,
                            undefined,
                            rowType.bodyRowType
                        )}
                    </tbody>
                </CyAttributeAppender>
                {tfootRow && (
                    <CyAttributeAppender name="tableFooter">
                        <tfoot>
                            {this.renderRows(
                                [tfootRow],
                                StyledTd,
                                rowType.footRowType
                            )}
                        </tfoot>
                    </CyAttributeAppender>
                )}
            </StyledTable>
        );
    }

    private renderRows(
        rows: ContainerItem[],
        component?: StyledComponent<'th', ThemeDefinition, StyledCellProps>,
        tableRowType?: rowType
    ): React.ReactNode {
        const {layout} = this.props;
        return rows.map((row, rowIdx) => {
            const cells = getCells(row);

            return (
                <tr key={row.key}>
                    {cells.map((cell, cellIdx) => {
                        const cqPath = getCqPath(this.props, row, cell);
                        let content = getCellContent(cell, cqPath);
                        const colSpan = getColSpan(cells, cellIdx + 1);
                        const rowSpan = getRowSpan(rows, rowIdx + 1, cellIdx);
                        const cellType = getCellType(cell);
                        const scope = getScope(layout, tableRowType);

                        if (
                            cellType === MergeRowCellElement.RESOURCE_TYPE ||
                            cellType === MergeColumnCellElement.RESOURCE_TYPE
                        ) {
                            return null;
                        }

                        const Component =
                            component || this.getBodyComponentType(cellIdx);

                        if (Component === StyledTh) {
                            content = <Text bold>{content}</Text>;
                        }

                        return (
                            <Component
                                scope={
                                    Component === StyledTh ? scope : undefined
                                }
                                isSummary={tableRowType === rowType.footRowType}
                                key={cell.key}
                                rowSpan={rowSpan}
                                colSpan={colSpan}
                                layout={layout}
                            >
                                {content}
                            </Component>
                        );
                    })}
                </tr>
            );
        });
    }

    private getBodyComponentType(
        colIndex: number
    ): StyledComponent<'th', ThemeDefinition, StyledCellProps> {
        if (this.props.layout === 'HEADER') {
            return StyledTd;
        }

        return colIndex === 0 ? StyledTh : StyledTd;
    }
}
