import React from 'react';

interface TabIndexObserverProps {
    children: React.ReactNode;
    childContainerSelector: string;
    activeChildContainerSelector: string;
}

const TABINDEX_ORIGINAL = 'data-tabindex-original';

const FOCUSABLE_ELEMENTS = [
    'a',
    'button',
    'input',
    'textarea',
    'select',
    'details',
    'audio',
    'video',
    '[tabindex]'
];

export class ChildContainerTabIndexManager extends React.Component<
    TabIndexObserverProps
> {
    private readonly contentObserver?: MutationObserver;

    private readonly observingNode: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    public constructor(props: TabIndexObserverProps) {
        super(props);

        if (typeof window !== 'undefined' && 'MutationObserver' in window) {
            this.contentObserver = new MutationObserver(
                this.observeInteractiveContent
            );
        }
    }

    private readonly getFocusableElementSelector = (parent = '') =>
        FOCUSABLE_ELEMENTS.map(el => `${parent} ${el}`).join(',');

    private readonly handleTabIndexes = () => {
        const {
            activeChildContainerSelector,
            childContainerSelector
        } = this.props;

        const observingNode = this.observingNode.current;

        const allFocusableElements = observingNode?.querySelectorAll(
            this.getFocusableElementSelector(childContainerSelector)
        );

        const activeContainerElements = observingNode?.querySelectorAll(
            this.getFocusableElementSelector(activeChildContainerSelector)
        );

        // set -1 tabindex for all interaction elements in all containers
        if (allFocusableElements) {
            allFocusableElements.forEach(item => {
                const itemTabindex = item.getAttribute('tabindex');
                const originalItemTabindex = item.getAttribute(
                    TABINDEX_ORIGINAL
                );

                // set original if it doesn't exist or if it exists and tabindex was overwritten
                if (originalItemTabindex == null || itemTabindex !== '-1') {
                    item.setAttribute(
                        TABINDEX_ORIGINAL,
                        itemTabindex == null ? 'none' : itemTabindex
                    );
                }
                item.setAttribute('tabindex', '-1');
            });
        }

        // reset tabIndex for children on active container

        if (activeContainerElements) {
            activeContainerElements.forEach(item => {
                const itemOriginalTabindex = item.getAttribute(
                    TABINDEX_ORIGINAL
                );

                if (
                    itemOriginalTabindex === 'none' ||
                    itemOriginalTabindex == null
                ) {
                    item.removeAttribute('tabindex');
                } else {
                    item.setAttribute('tabindex', itemOriginalTabindex);
                }

                item.removeAttribute(TABINDEX_ORIGINAL);
            });
        }
    };

    private readonly observeInteractiveContent = (
        mutationsList: MutationRecord[]
    ) => {
        const focusable = this.getFocusableElementSelector('');
        const hasFocusableMutation = mutationsList.some(
            (entry): boolean | void =>
                // a tabindex got updated (not by us)
                (entry.type === 'attributes' &&
                    entry.attributeName === 'tabindex' &&
                    entry.target instanceof Element &&
                    entry.target.getAttribute(TABINDEX_ORIGINAL) != null &&
                    entry.target.getAttribute('tabindex') !== '-1') ||
                // or a focusable child got mounted
                (entry.type === 'childList' &&
                    Array.from(entry.addedNodes).some(
                        node =>
                            node instanceof Element && node.matches(focusable)
                    ))
        );

        if (hasFocusableMutation) {
            this.handleTabIndexes();
        }
    };

    public componentDidMount(): void {
        this.handleTabIndexes();

        const observingNode = this.observingNode.current;

        if (observingNode && this.contentObserver) {
            this.contentObserver.observe(observingNode, {
                attributes: true,
                attributeFilter: ['tabindex'],
                childList: true,
                subtree: true
            });
        }
    }

    public componentWillUnmount(): void {
        return this.contentObserver?.disconnect();
    }

    public componentDidUpdate(prevProps: TabIndexObserverProps): void {
        if (
            prevProps.activeChildContainerSelector !==
            this.props.activeChildContainerSelector
        ) {
            this.handleTabIndexes();
        }
    }

    public render(): JSX.Element {
        return <div ref={this.observingNode}>{this.props.children}</div>;
    }
}
