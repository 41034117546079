import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';
import {resetButtonStyles, StyledAccessibleLabel} from '../helpers';
import {PaginationData} from '../mobile-carousel-v2';

const itemWidth =
    '77px'; /* based on design, fixed width  needed for horizontal scroll */

const outlineWidth = '2px';
const paddingSize = '2px';
const borderSize = '2px';
const overlayColor = '0,0,0,0.4';

const StyledMediaElementContainer = styled.button<ItemStyledProps>`
    ${resetButtonStyles}
    cursor: pointer;
    width: ${itemWidth};
    height: auto;
    margin-right: ${props => props.theme.size.static100};

    @media (min-width: ${Breakpoints.b960}px) {
        width: 100%;
        max-width: calc(
            20% - ${props => props.theme.size.dynamic0020} +
                ${props => props.theme.size.dynamic0020} / 4
        );
        min-width: calc(
            18% - ${props => props.theme.size.static100}
        ); /* if more than 5 items, the 6th one will be partially shown */
    }

    padding-bottom: ${paddingSize};

    border-bottom: ${borderSize} solid
        ${props => (props.isActive ? 'black' : 'transparent')};

    &:focus {
        outline-offset: 1px;
        outline: solid ${outlineWidth} ${props => props.theme.colors.focus.main};
    }
`;

StyledMediaElementContainer.displayName = 'StyledMediaElementContainer';

const StyledOverlay = styled.div`
    bottom: 0;
    top: 0;
    left: 0px;
    right: 0px;
    position: absolute;
    pointer-events: none;
    background: rgba(${overlayColor});
`;

StyledOverlay.displayName = 'StyledOverlay';

const StyledMediaElementWrapper = styled.div`
    position: relative;
`;

StyledMediaElementWrapper.displayName = 'StyledMediaElementWrapper';

interface ItemStyledProps {
    readonly isActive: boolean;
}

interface MediaElementPropsV3 {
    readonly children: JSX.Element;
    readonly paginationData: PaginationData;
    readonly isActive: boolean;
    readonly mediaRef?: React.Ref<HTMLDivElement>;
    onClick(e: React.MouseEvent): void;
}

export function MediaElementV3({
    isActive,
    children,
    mediaRef,
    paginationData: {id, panelId, label},
    onClick
}: MediaElementPropsV3): JSX.Element {
    return (
        <StyledMediaElementContainer
            onClick={onClick}
            role="tab"
            id={id}
            aria-controls={panelId}
            tabIndex={isActive ? 0 : -1}
            aria-selected={isActive ? 'true' : 'false'}
            isActive={isActive}
        >
            <StyledMediaElementWrapper ref={mediaRef}>
                {children}
                <StyledAccessibleLabel>{label}</StyledAccessibleLabel>
                {!isActive && <StyledOverlay />}
            </StyledMediaElementWrapper>
        </StyledMediaElementContainer>
    );
}
