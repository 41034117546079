import {Constants} from '@adobe/cq-react-editable-components';
import * as React from 'react';
import {ContainerExporter} from '../../infrastructure/ContainerExporter';

export function getDnDContainer(
    _resourceType: string,
    props: ContainerExporter,
    className: string = ''
): JSX.Element {
    return (
        <div
            key="__new"
            className={`${Constants.NEW_SECTION_CLASS_NAMES} ${className}`}
            data-cq-data-path={`${props.cqPath}/*`}
        />
    );
}
