import * as React from 'react';
import styled from 'styled-components';

import {DisclaimerProps} from './helpers';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {useDisclaimers} from '../../context/disclaimer/useDisclaimers';

const StyledLayerDisclaimerReferences = styled.div`
    padding: ${props => props.theme.size.dynamic0200} 0
        ${props => props.theme.size.dynamic0250};
`;

export const SimpleDisclaimerContainer = (props: DisclaimerProps) => {
    const {displayTypes} = props;
    const disclaimers = useDisclaimers(...(displayTypes || []));
    const hasReferences = disclaimers && disclaimers.length !== 0;

    if (!hasReferences) {
        return null;
    }

    return (
        <CyAttributeAppender name="sectionDisclaimers">
            <StyledLayerDisclaimerReferences>
                <DisclaimersWithSafewords disclaimers={disclaimers} />
            </StyledLayerDisclaimerReferences>
        </CyAttributeAppender>
    );
};
