import {css} from 'styled-components';
import {
    styled,
    Breakpoints,
    createA11yOutlineStyles
} from '@volkswagen-onehub/components-core';

import {StyledNextBestActionPanelElementTypes} from './types';
import {resetButtonStyles, textOverflowEllipsis} from '../helpers';

const mainBarHeight = '76px';

export const StyledWrapper = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
`;

export const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: ${props => props.theme.size.static200};
    padding: ${props => props.theme.size.static250}
        ${props => props.theme.size.static250} 0;

    height: ${mainBarHeight};
    background: ${props => props.theme.colors.background.primary};
    border-top: solid 1px ${props => props.theme.colors.border.divider};

    @media (min-width: ${Breakpoints.b960}px) {
        justify-content: unset;
        gap: ${props => props.theme.size.static400};
        padding: ${props => props.theme.size.static250}
            ${props => props.theme.size.grid001} 0;
    }
`;

export const StyledSubactionsWrapper = styled.div<{
    readonly expanded?: boolean;
}>`
    position: absolute;
    bottom: ${mainBarHeight};
    left: 0;
    right: 0;
    z-index: -1;

    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.static150};
    padding: ${props => props.theme.size.static250};

    background: ${props => props.theme.colors.background.primary};
    border-top: solid 1px ${props => props.theme.colors.border.divider};

    transition: transform 0.25s;
    transform: translateY(100%);

    ${({expanded}) =>
        expanded &&
        css`
            transform: translateY(0);
        `}
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    align-items: center;
    text-align: start;
    width: 100%;

    // ellipsis on mobile
    @media (max-width: ${Breakpoints.b960 - 1}px) {
        > span {
            ${textOverflowEllipsis}
        }
    }

    // line breaks with 2 max lines on tablet/desktop
    @media (min-width: ${Breakpoints.b960}px) {
        > span {
            display: inline-block;
            max-height: 40px;
            word-break: break-word; // must have for long words without space
        }
    }
`;

export const StyledIconWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 24px;
    height: 24px;

    margin-inline-end: ${props => props.theme.size.static150};

    // icon hover bg
    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: calc(100% + ${props => props.theme.size.static250});
        height: calc(100% + ${props => props.theme.size.static250});
        border-radius: 50%;
        background: ${props => props.theme.colors.hoverIndicator};
        opacity: 0;
    }
`;

// buttons

const generalButtonTheme = css<StyledNextBestActionPanelElementTypes>`
    ${resetButtonStyles};
    appearance: none;
    outline: 0;
    text-decoration: underline;
    isolation: isolate;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    width: auto;
    padding: 0 20px;
    height: 44px;
    max-width: 100%;
    overflow: hidden;

    &:focus {
        outline: none;
    }

    ${({theme}) => css`
        color: ${theme.colors.button.tertiary.default};
    `}
`;

export const StyledMainButton = css`
    ${({theme}) => css`
        border-radius: 32px;
        background: ${theme.colors.accent.nbab.default};
        border: 2px solid transparent;
        text-decoration: none;
        color: ${theme.colors.content.invertedOnSurface};

        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s ease-in-out;

        &:hover,
        &:active {
            background: ${theme.colors.accent.nbab.hover};
            color: ${theme.colors.content.inverted};
        }

        :focus-visible {
            outline: 3px solid ${theme.colors.focus.accent};
            outline-offset: unset;
        }

        // no linebreak & no ellipsis for horizontal version
        @media (min-width: ${Breakpoints.b960}px) {
            white-space: nowrap;
            flex-shrink: 0;
        }
    `}
`;

export const buttonSideSpacingForIconHover = css`
    padding: 0;
    padding-inline-start: ${props => props.theme.size.static150};
    margin-inline-start: calc(-1 * ${props => props.theme.size.static150});
`;

export const StyledDropdownButton = css<StyledNextBestActionPanelElementTypes>`
    ${buttonSideSpacingForIconHover};

    &:hover {
        ${StyledIconWrapper}:before {
            opacity: 1;
        }

        color: ${props => props.theme.colors.button.tertiary.hover};
        text-decoration-thickness: ${props => props.theme.size.border.heavy};
    }

    ${({theme}) => css`
        :focus-visible {
            ${StyledIconWrapper}:before {
                opacity: 0;
            }

            color: ${theme.colors.button.tertiary.active};

            span {
                ${createA11yOutlineStyles(theme, 'solid')};
                border-radius: ${theme.size.border.heavy};
                outline-offset: unset;
            }
        }
    `};
`;

export const StyledSubactionButton = css`
    ${buttonSideSpacingForIconHover};
    padding-right: 2px; // NOTE: due focus outline

    &:hover {
        ${StyledIconWrapper}:before {
            opacity: 1;
        }

        color: ${props => props.theme.colors.button.tertiary.hover};
        text-decoration-thickness: ${props => props.theme.size.border.heavy};
    }

    ${({theme}) => css`
        :focus-visible {
            ${StyledIconWrapper}:before {
                opacity: 0;
            }

            color: ${theme.colors.button.tertiary.active};

            span {
                ${createA11yOutlineStyles(theme, 'solid')};
                border-radius: ${theme.size.border.heavy};
                outline-offset: unset;
            }
        }
    `};
`;

export const StyledNextBestActionPanelButton = styled.button<
    StyledNextBestActionPanelElementTypes
>`
    ${generalButtonTheme};

    ${({type}) =>
        type === 'main' &&
        css`
            ${StyledMainButton} // main button
        `}

    ${({type}) =>
        type === 'dropdown' &&
        css`
            ${StyledDropdownButton} // dropdown button
        `}

    ${({type}) =>
        type === 'subaction' &&
        css`
            ${StyledSubactionButton} // subaction button
        `}
`;

export const StyledNextBestActionPanelLink = styled.a<
    StyledNextBestActionPanelElementTypes
>`
    ${generalButtonTheme};

    ${({type}) =>
        type === 'main' &&
        css`
            // main link
            ${StyledMainButton}
        `}

    ${({type}) =>
        type === 'subaction' &&
        css`
            // subaction link
            ${StyledSubactionButton}
        `}
`;
