interface onScrollEndedListenerProps {
    onScrollEndedCallback: () => void;
    onScrollCallback?: () => void;
    delay?: number; // on scroll ended callback delay
    options?: boolean | AddEventListenerOptions;
    elementToBeChecked?: string | null;
}

export const onScrollEndedListener = (props: onScrollEndedListenerProps) => {
    const {
        onScrollEndedCallback,
        onScrollCallback,
        delay = 200,
        options,
        elementToBeChecked = 'document'
    } = props;

    let timer: number | null = null;
    const onScroll = (e: Event) => {
        const target = e.target as HTMLElement;

        // If elementToBeChecked defined, check if scroll event fired by that element
        if (
            !elementToBeChecked ||
            target.nodeName?.toLowerCase()?.includes(elementToBeChecked)
        ) {
            if (onScrollCallback) {
                onScrollCallback();
            }

            if (timer) {
                clearTimeout(timer);
            }

            timer = setTimeout(onScrollEndedCallback, delay);
        }
    };

    window.addEventListener('scroll', onScroll, options);

    return () => {
        if (timer) {
            clearTimeout(timer);
        }
        window.removeEventListener('scroll', onScroll, options);
    };
};
