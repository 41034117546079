import * as React from 'react';

import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {LoginInfoFocusLayerModel} from '../../../../generated/core';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    CTA,
    Breakpoints
} from '@volkswagen-onehub/components-core';

type ExtraProps = {onClose: () => void};

export type LoginInfoFocusLayerProps = LoginInfoFocusLayerModel & ExtraProps;

export function InternalLoginInfoFocusLayer(
    props: LoginInfoFocusLayerProps
): JSX.Element {
    const headingPrivate = (
        <HeadingElement order={'H2'} path={'headingPrivate'} />
    );
    const copyPrivate = <CopyItem path={'copyPrivate'} />;
    const headingCommercial = (
        <HeadingElement order={'H2'} path={'headingCommercial'} />
    );
    const copyCommercial = <CopyItem path={'copyCommercial'} />;

    return (
        <AuthoringWrapper
            title="Login Info Focus Layer"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.dynamic0200}
                padding={{
                    left: ContainerPadding.grid001,
                    right: ContainerPadding.grid001,
                    top: {
                        [Breakpoints.default]: ContainerPadding.dynamic0050,
                        [Breakpoints.b560]: ContainerPadding.dynamic0130
                    },
                    bottom: {
                        [Breakpoints.default]: ContainerPadding.dynamic0050,
                        [Breakpoints.b560]: ContainerPadding.dynamic0130
                    }
                }}
            >
                <Container
                    wrap={ContainerWrap.always}
                    gutter={{
                        [Breakpoints.default]: ContainerGutter.dynamic0130,
                        [Breakpoints.b560]: ContainerGutter.dynamic0050
                    }}
                >
                    {headingPrivate}
                    {copyPrivate}
                </Container>
                <Container
                    wrap={ContainerWrap.always}
                    gutter={{
                        [Breakpoints.default]: ContainerGutter.dynamic0130,
                        [Breakpoints.b560]: ContainerGutter.dynamic0050
                    }}
                >
                    {headingCommercial}
                    {copyCommercial}
                    <CTA
                        tag="button"
                        emphasis="secondary"
                        type="button"
                        size="large"
                        onClick={props.onClose}
                    >
                        {props.btnCloseLabel}
                    </CTA>
                </Container>
            </Container>
        </AuthoringWrapper>
    );
}

export const LoginInfoFocusLayer = MapTo<ExtraProps>(
    'vwa-ngw18/components/structure/login/loginInfoFocusLayer',
    InternalLoginInfoFocusLayer
);
