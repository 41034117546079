import {SpaAsyncConfig, SpaGlobalConfig} from '../../generated/core';
import {Logger} from '../context/logger/Logger';
import {Registry} from '../infrastructure/di/Registry';
import {Environment} from '../infrastructure/di/annotations';
import {isInBrowser} from './browser/isInBrowser';
import {isDevMode} from './devModeUtils';
import {getGlobal} from './getGlobal';
import {updateSingletonProperties} from './updateSingletonProperties';

interface PropOverwriting {
    [prop: string]: any;
}
export interface ObjectOverwriting {
    [name: string]: PropOverwriting;
}

const registerConfigObject = (
    registry: Registry,
    configuration: any,
    env?: Environment
) => {
    Object.keys(configuration).forEach((key: string) => {
        const type =
            key.substring(0, 1).toUpperCase() + key.substring(1, key.length);
        registry.addSingleton(type, configuration[key] as object, {env});
    });
};

export const registerSpaGlobalConfig = (
    registry: Registry,
    spaGlobalConfig: SpaGlobalConfig,
    env?: Environment
) => {
    registry.addSingleton('SpaGlobalConfig', spaGlobalConfig, {env});
    const logger: Logger = registry.getSingleton('Logger');

    if (isInBrowser() && isDevMode(spaGlobalConfig)) {
        const objectOverwriting: ObjectOverwriting = getGlobal().config.getJson(
            'overwriting'
        );
        updateSingletonProperties(
            (key: string) =>
                !!key
                    ? (spaGlobalConfig as any)[key]
                    : (spaGlobalConfig as any),
            logger,
            objectOverwriting
        );
    }
    registerConfigObject(registry, spaGlobalConfig, env);
};

export const registerAsyncConfig = (
    registry: Registry,
    asyncConfig: SpaAsyncConfig
) => {
    registry.addSingleton('SpaAsyncConfig', asyncConfig);
    registerConfigObject(registry, asyncConfig);
};
