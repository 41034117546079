import * as React from 'react';

import {MediaAspectRatio} from '../../../../../../generated/api';
import {useMediaContext} from '../../../../../context';
import {VideoPlayerProxy} from '../../../../../services/video/VideoPlayerProxy';

const DEFAULT_ASPECT_RATIO: MediaAspectRatio = 'r_16_9';

export function useVideoAspectRatio(
    matchParent: boolean | undefined,
    assetAspectRatio: MediaAspectRatio,
    videoPlayerProxy?: React.RefObject<VideoPlayerProxy>
): 'matchParentHeight' | MediaAspectRatio {
    const mediaContext = useMediaContext();
    const isInFullScreen =
        videoPlayerProxy &&
        videoPlayerProxy.current &&
        videoPlayerProxy.current.isInFullScreen;
    const {
        ratio = assetAspectRatio || DEFAULT_ASPECT_RATIO,
        useParentAspectRatio = false
    } = mediaContext;

    return React.useMemo(() => {
        return useParentAspectRatio || matchParent || isInFullScreen
            ? 'matchParentHeight'
            : ratio;
    }, [matchParent, ratio, useParentAspectRatio, isInFullScreen]);
}
