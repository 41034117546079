import {MediaAlignment as AemMediaAlignment} from '../../generated/core';
import {MediaAlignment} from '../d6/components/copy-inset-media';

/**
 * Provides mapping between LSG enum and value saved in AEM for aspect ratio.
 */
export const mediaAlignmentMap: {[key in AemMediaAlignment]: MediaAlignment} = {
    LEFT: MediaAlignment.LEFT,
    RIGHT: MediaAlignment.RIGHT
};
