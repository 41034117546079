import * as React from 'react';
import {RouterService} from '../../context/route/RouterService';

export const createLinkHandler = (
    routerService: RouterService,
    linkUrl: string
) => (e: React.MouseEvent) => {
    if (linkUrl && routerService.isAppLink(linkUrl)) {
        routerService.navigate(linkUrl);
        e.preventDefault();
    }
};
