import {UserData} from './UserData';
import {OrganizationData} from './OrganizationData';
import {CustomerType} from '@volkswagen-onehub/authservice-config';

export interface LoginStatus {
    statusResolved: boolean;
    isLoggedIn: boolean;
    userData: UserData | null;
    organizationData: OrganizationData | null;
    authenticatedCustomerType: CustomerType | null;
}

export enum ErrorStatus {
    configError = 'configError',
    loginFailed = 'loginFailed',
    authenticationFailed = 'authenticationFailed',
    popUpBlocked = 'popUpBlocked',
    unknownError = 'unknownError'
}

export class AuthProxyError extends Error {
    public status: ErrorStatus;

    public constructor(status: ErrorStatus) {
        super();
        this.status = status;
    }
}
