import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {DisclaimerContainerProps} from './helpers';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';

const StyledDisclaimerReferences = styled.div`
    padding-inline: ${props => props.theme.size.grid002};
    padding-top: ${props => props.theme.size.dynamic0100};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid003};
    }
`;
StyledDisclaimerReferences.displayName = 'StyledDisclaimerReferences';

const InternalSectionDisclaimerContainer = observer(function ISDC(
    props: DisclaimerContainerProps
): JSX.Element | null {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const hasReferences = Boolean(disclaimers?.length);

    return hasReferences ? (
        <CyAttributeAppender name="sectionDisclaimers">
            <StyledDisclaimerReferences>
                <DisclaimersWithSafewords disclaimers={disclaimers} />
            </StyledDisclaimerReferences>
        </CyAttributeAppender>
    ) : null;
});

export const SectionDisclaimerContainer = React.memo(
    InternalSectionDisclaimerContainer
);
