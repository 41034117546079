import * as React from 'react';

export interface Config<P> {
    componentClass: React.ComponentType<P>;
}

export interface BasicIncludeProps {
    path: string;
    className?: string;
    containerProps?: {[key: string]: any};
    forceReload?: boolean;
    personalizable?: boolean;
    isNotEditable?: boolean;
    noElement?: boolean;
}

export interface IncludeProps extends BasicIncludeProps {
    resourceType: string;
    extraProps?: {[key: string]: any};
    defaultContentPath?: string;
}

export type Include = React.FunctionComponent<IncludeProps>;

export class Compatibility {
    private isInEditorValue: boolean = false;

    public setIsInEditor(value: boolean): void {
        this.isInEditorValue = value;
    }

    public isInEditor(): boolean {
        return this.isInEditorValue;
    }
}
