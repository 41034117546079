import {getOrCreateRegistry} from './infrastructure/di/Registry';
import {PageRoot} from './modules/structure/PageRoot';
import {renderSpa} from './spa';

import {SpaModel} from '../generated/core';
import './registries/generalModules';
import {domReady} from './utils/domReady';
import {getGlobal} from './utils/getGlobal';
import {onLoadingCascadeFinished} from './utils/loadingCascade';
import {getClientlibsBaseUrl} from './utils/getClientlibsBaseUrl';

export async function startCms(): Promise<void> {
    await domReady();
    const model = getModel();
    const {log} = getGlobal();
    const registry = getOrCreateRegistry(log);

    const clientlibsBaseUrl = getClientlibsBaseUrl(model.spaGlobalConfig);

    // the webpack public path must point to the app path, so that chunks can be loaded properly.
    __webpack_public_path__ = clientlibsBaseUrl + '/';

    renderSpa(model, registry, PageRoot, 'reactmount', log);
}

function getModel(): SpaModel {
    const spaEl = document.getElementById('spaModel');
    if (!spaEl) {
        throw new Error('cannot find spa model');
    }
    return JSON.parse(spaEl.innerHTML);
}

function run(): void {
    onLoadingCascadeFinished(startCms);
}

typeof window !== 'undefined' && performance.mark('startRender');
run();
