export type DragIntention = 'vertical' | 'horizontal';
export type DragDirection = 'left' | 'right' | 'none';

export interface DragStartEvent {
    x: number;
    y: number;
    time: number;
}

const DRAG_TOLERANCE = 10;
export const SHOWCASE_PLAYBACK_RATE = 1.5;

export function determineDragIntention(
    touch: Touch,
    moveStart: DragStartEvent
): DragIntention | undefined {
    const {clientX, clientY} = touch;
    const deltaX = Math.abs(moveStart.x - clientX);
    const deltaY = Math.abs(moveStart.y - clientY);

    if (deltaX > deltaY) {
        return 'horizontal';
    }

    if (deltaY > deltaX) {
        return 'vertical';
    }

    return undefined;
}

export function getDragDirection(
    offsetX: number,
    clientX: number
): DragDirection {
    const delta = clientX - offsetX;
    if (delta < 0) {
        return 'left';
    }

    if (delta > 0) {
        return 'right';
    }

    return 'none';
}

export function isDragEvent(clientX: number, currentX?: number): boolean {
    return Boolean(currentX && Math.abs(currentX - clientX) > DRAG_TOLERANCE);
}

export function isDragged(dragDirection?: DragDirection): boolean {
    return dragDirection !== 'none';
}
