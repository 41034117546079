import {SpaGlobalConfig} from '../../generated/core';
import {getClientlibsBaseUrl} from './getClientlibsBaseUrl';

export const getIconUrl = (
    iconName: string,
    variant: string,
    config: SpaGlobalConfig
) => {
    const baseUrl = getClientlibsBaseUrl(config);
    return `${baseUrl}/clientlibs/statics/icons/${iconName}/${variant}.svg`;
};
