import {inject, singleton} from '../../infrastructure/di/annotations';
import {BASIC_STAGE_RESOURCE_TYPE, BasicStageData} from './BasicStageData';
import {ContentStore} from '../content/ContentStore';
import {ModelStore} from '../model/ModelStore';
import {convertBboStageData} from './convertBboStageData';
import {BasicStageSectionModel, SpaAsyncConfig} from '../../../generated/core';
import {modelToProps} from '../../infrastructure/api/modelToProps';
import {ImageServiceV1} from '@volkswagen-onehub/image-service';
import {RouterService} from '../route/RouterService';

export const BBO_VARIANT = '__bbo__';

@singleton('BboService')
export class BboService {
    @inject()
    private contentStore!: ContentStore;

    @inject()
    private modelStore!: ModelStore;

    @inject()
    private routerService!: RouterService;

    @inject()
    private spaAsyncConfig!: SpaAsyncConfig;

    @inject()
    private imageService!: ImageServiceV1;

    public swapBasicStage(data: BasicStageData) {
        const pageModel = this.contentStore.getCurrentPageRootModel();
        const pagePath = this.routerService.pagePath;
        if (pageModel) {
            if (pageModel.stageType !== BASIC_STAGE_RESOURCE_TYPE) {
                return;
            }
            const path = pageModel.stagePath;
            const absPath = pagePath + '/jcr:content/root/' + path;
            const originalTransferModel = this.modelStore.getData(absPath);
            const cqPath = this.modelStore.getCustomContentPath(
                BBO_VARIANT,
                absPath
            );

            const content = convertBboStageData(
                this.imageService,
                this.spaAsyncConfig,
                data,
                modelToProps(
                    originalTransferModel,
                    absPath
                ) as BasicStageSectionModel,
                cqPath
            );
            this.modelStore.insertCustomContent(BBO_VARIANT, absPath, content);
        }
    }
}
