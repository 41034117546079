import * as React from 'react';
import {SpaGlobalConfig} from '../../../../generated/core';
import {TrackingService} from '../../../context/tracking/TrackingService';
import {
    LOGIN_INFO_FOCUS_LAYER_CONTEND_ID,
    LoginInfoFocusLayerService
} from '../../../context/vwid/LoginInfoFocusLayerService';
import {mapUrlFactory} from '../../../infrastructure/hub/createCmsService';
import {createLinkName} from '../createLinkName';
import {LIFL_PROTOCOL, LinkHandlerProps} from '../LinkHandlers';

export const liflHandler = (
    href: string,
    props: LinkHandlerProps
): [React.EventHandler<React.SyntheticEvent>, string] => {
    const {registry, baseLinkProps, trackingData, linkRef} = props;
    const loginInfoFocusLayerService: LoginInfoFocusLayerService = registry.getSingleton(
        'LoginInfoFocusLayerService'
    );
    const trackingService: TrackingService = registry.getSingleton(
        'TrackingService'
    );
    const globalConfig: SpaGlobalConfig = registry.getSingleton(
        'SpaGlobalConfig'
    );
    const mappedHref = mapUrlFactory(globalConfig)(getLiflPathname(href));
    return [
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            loginInfoFocusLayerService.openLayer(mappedHref);

            let linkName = baseLinkProps?.linkName;
            if (!linkName && linkRef) {
                linkName = createLinkName(linkRef);
            }
            trackingService.trackLinkClick(
                href,
                LOGIN_INFO_FOCUS_LAYER_CONTEND_ID,
                linkName,
                trackingData
            );
            trackingService.trackLayerLoad(
                LOGIN_INFO_FOCUS_LAYER_CONTEND_ID,
                trackingData
            );
        },
        mappedHref
    ];
};

function getLiflPathname(href: string) {
    return href.substring(LIFL_PROTOCOL.length);
}
