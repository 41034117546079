import * as React from 'react';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import ReactDOM from 'react-dom';
import {ThemeContext} from 'styled-components';
import {Direction, createCSSMarginByDirection} from '../helpers';
import {RenderPaginationProps} from '../mobile-carousel-v2';
import {TabPagination} from '../mobile-carousel-v2/tab-pagination';
import {MediaElementV3} from './media-element';
import {GalleryPagination} from './gallery-pagination';

enum AlignItems {
    START = 'start',
    END = 'end'
}

const SMOOTH_SCROLL_DURATION = 200; // ms

function getAlignItemsValue(
    isAlignItems: boolean,
    direction: Direction
): AlignItems {
    if (isAlignItems) {
        return AlignItems.START;
    }

    return direction === Direction.RTL ? AlignItems.START : AlignItems.END;
}

const StyledLayoutWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${props => props.theme.size.dynamic0050} 0 ${props =>
    props.theme.size.static250} 0;

	// for focus outline space without outline getting cut off
	padding: ${props => props.theme.size.static150};

	@media (min-width: ${Breakpoints.b560}px) {
		${props =>
            createCSSMarginByDirection(
                props.theme.direction,
                props.theme.size.grid004,
                props.theme.size.grid004
            )}

		width: calc(100% - ${props => props.theme.size.grid008});
		flex-direction: column;
	}


	@media (min-width: ${Breakpoints.b960}px) {
		${props =>
            createCSSMarginByDirection(
                props.theme.direction,
                props.theme.size.grid002,
                undefined
            )}
		align-items: flex-start;
		width: calc(100% - ${props => props.theme.size.grid002});
		flex-direction: row;
	}

	/* focus-within is not supported on old Edge, but is only a nice extra anyway */

	&:focus-within {
		outline-offset: -1px;
		outline: solid 1px ${props => props.theme.colors.focus.main};
	}
`;

interface StyledPreviewGalleryProps {
    readonly alignItems: AlignItems;
}
const StyledPreviewGallery = styled.div<StyledPreviewGalleryProps>`
    margin: 0;
    display: inline-flex;
    direction: ltr;
    padding: 0 ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding: 0;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        width: 100%;
        padding: 0;
        justify-content: ${props =>
            props.alignItems === AlignItems.START ? 'flex-start' : 'flex-end'};
    }
`;

StyledPreviewGallery.displayName = 'StyledPreviewGallery';

const StyledScrollWrapper = styled.div<StyledPreviewGalleryProps>`
    flex: 1;
    max-width: 100%;

    &::-webkit-scrollbar {
        display: none; /* hide scroll-bar in chrome */
    }
    scrollbar-width: none; /* hide scroll-bar in firefox */

    // for focus outline space without outline getting cut off
    padding: ${props => props.theme.size.static100} 0;
    margin: calc(-1 * ${props => props.theme.size.static100}) 0;

    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    direction: ltr;
    text-align: ${props =>
        props.alignItems === AlignItems.START ? 'end' : 'start'};

    @media (min-width: ${Breakpoints.b560}px) {
        text-align: center;
    }

    &:focus {
        outline: none;
    }
`;
StyledScrollWrapper.displayName = 'StyledScrollWrapper';

type PreviewRowProps = RenderPaginationProps &
    Readonly<{
        children: JSX.Element[];
        onPaginationClick?(delta: number): void;
    }>;

interface PreviewRowState {
    showPagination: boolean;
}

export class PreviewRow extends React.Component<
    PreviewRowProps,
    PreviewRowState
> {
    private readonly scrollWrapperRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();
    private readonly firstMediaElementRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();
    private readonly previewGalleryRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    private animationFrame: number | null = null;

    public constructor(props: PreviewRowProps) {
        super(props);

        this.state = {
            showPagination: false
        };
    }

    private readonly smoothHorizontalScrolling = (
        scrollContainer: HTMLDivElement,
        x: number
    ): void => {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
        }

        const maxScroll =
            scrollContainer.scrollWidth -
            scrollContainer.getBoundingClientRect().width;
        const target = Math.min(maxScroll, Math.max(0, x));
        const current = scrollContainer.scrollLeft;
        const scrollDelta = target - current;

        let scrollDeltaDone = 0; // px
        let startTimestamp: number;

        const step = (timestamp: number) => {
            if (!startTimestamp) {
                startTimestamp = timestamp;
            }

            const ratioDone = Math.min(
                1,
                (timestamp - startTimestamp) / SMOOTH_SCROLL_DURATION
            );

            const scrollDeltaNow = Math.round(
                scrollDelta * ratioDone - scrollDeltaDone
            );

            scrollDeltaDone += scrollDeltaNow;
            scrollContainer.scrollLeft += scrollDeltaNow;

            if (ratioDone < 1) {
                this.animationFrame = window.requestAnimationFrame(step);
            }
        };

        if (Math.abs(scrollDelta) > 0) {
            this.animationFrame = window.requestAnimationFrame(step);
        }
    };

    private readonly handleResize = (): void => {
        const previewGalleryRef = ReactDOM.findDOMNode(
            this.scrollWrapperRef.current
        );
        const mediaElement = ReactDOM.findDOMNode(
            this.firstMediaElementRef.current
        );

        const itemCount = React.Children.count(this.props.children);

        if (
            previewGalleryRef instanceof HTMLDivElement &&
            mediaElement instanceof HTMLDivElement
        ) {
            const previewGalleryContainerWidth = previewGalleryRef.getBoundingClientRect()
                .width;
            const mediaElementWidth = Math.floor(
                mediaElement.getBoundingClientRect().width
            );
            const mediaElementsMargin = (itemCount - 1) * 4; // no margin on the last item
            const totalMediaElementsWidth =
                Math.floor(mediaElementWidth * itemCount) + mediaElementsMargin;
            const windowWidth = Math.floor(window.innerWidth);
            const paddingOnMobileView = windowWidth / 12;

            if (previewGalleryContainerWidth < totalMediaElementsWidth) {
                this.setState({showPagination: true});
            } else if (
                windowWidth < 560 &&
                totalMediaElementsWidth + paddingOnMobileView > windowWidth
            ) {
                this.setState({showPagination: true});
            } else {
                this.setState({showPagination: false});
            }
        }
    };

    public componentDidUpdate(prevProps: Readonly<PreviewRowProps>): void {
        const {activeIndex} = this.props;
        const itemCount = React.Children.count(this.props.children);
        const lastItemIndex = prevProps.activeIndex;

        if (activeIndex !== lastItemIndex) {
            const mediaElement = ReactDOM.findDOMNode(
                this.firstMediaElementRef.current
            );
            const scrollContainer = ReactDOM.findDOMNode(
                this.scrollWrapperRef.current
            );

            if (
                (lastItemIndex === 0 && activeIndex === 1) ||
                (lastItemIndex === itemCount - 1 &&
                    activeIndex === itemCount - 2)
            ) {
                // don't scroll for far-edge thumbnails
                return;
            }

            if (
                lastItemIndex !== activeIndex &&
                mediaElement instanceof Element &&
                scrollContainer instanceof HTMLDivElement
            ) {
                // assumption: same for all media elements
                const itemWidth =
                    mediaElement.getBoundingClientRect().width + 4;
                let scrollAmount = itemWidth;
                if (
                    itemCount < 6 ||
                    (lastItemIndex === 0 && activeIndex === itemCount - 1) ||
                    (lastItemIndex === itemCount - 1 && activeIndex === 0)
                ) {
                    scrollAmount = itemWidth * itemCount;
                }

                const scrollDirection = lastItemIndex < activeIndex ? 1 : -1;

                // Can be replaced by element.scrollTo({ behavior: 'smooth' }),
                // if/when browser support is there.
                this.smoothHorizontalScrolling(
                    scrollContainer,
                    scrollContainer.scrollLeft + scrollAmount * scrollDirection
                );
            }
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);

        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
        }
    }

    public componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // initial call
    }

    public render(): JSX.Element {
        const {
            onSlideSelect,
            onPaginationClick,
            activeIndex,
            children,
            paginationData,
            paginationLabel
        } = this.props;
        const {showPagination} = this.state;
        const itemCount = React.Children.count(children);

        return (
            <ThemeContext.Consumer>
                {value => {
                    const direction = value.direction as Direction;

                    return (
                        <StyledLayoutWrapper>
                            <StyledScrollWrapper
                                ref={this.scrollWrapperRef}
                                alignItems={getAlignItemsValue(
                                    itemCount > 5,
                                    direction
                                )}
                                tabIndex={-1}
                            >
                                <TabPagination
                                    paginationData={paginationData}
                                    onSlideSelect={onSlideSelect}
                                    activeIndex={activeIndex}
                                    tabListRef={this.previewGalleryRef}
                                >
                                    {({
                                        tabListKeyDownHandler,
                                        getTabClickHandler
                                    }) => (
                                        <StyledPreviewGallery
                                            alignItems={getAlignItemsValue(
                                                itemCount > 5,
                                                direction
                                            )}
                                            aria-label={paginationLabel}
                                            ref={this.previewGalleryRef}
                                            role="tablist"
                                            onKeyDown={tabListKeyDownHandler}
                                        >
                                            {children.map(
                                                (mediaElement, idx) => (
                                                    <MediaElementV3
                                                        isActive={
                                                            idx === activeIndex
                                                        }
                                                        onClick={getTabClickHandler(
                                                            idx
                                                        )}
                                                        key={`previewElement_${mediaElement.key}`}
                                                        mediaRef={
                                                            idx === 0
                                                                ? this
                                                                      .firstMediaElementRef
                                                                : undefined
                                                        }
                                                        paginationData={
                                                            paginationData[idx]
                                                        }
                                                    >
                                                        {mediaElement}
                                                    </MediaElementV3>
                                                )
                                            )}
                                        </StyledPreviewGallery>
                                    )}
                                </TabPagination>
                            </StyledScrollWrapper>
                            {showPagination && (
                                <GalleryPagination
                                    itemCount={itemCount}
                                    activeItemIndex={activeIndex}
                                    onSlideChanged={onSlideSelect}
                                    onPaginationClick={onPaginationClick}
                                />
                            )}
                        </StyledLayoutWrapper>
                    );
                }}
            </ThemeContext.Consumer>
        );
    }
}
