import * as React from 'react';
import {observer} from 'mobx-react-lite';

import {AnchorTarget} from '../../../components/AnchorTarget';
import {TrackingDTO} from '../../../context/tracking/TrackingService';

import {
    ViewportTrackingHookParams,
    useEnterViewportTracking
} from './section-wrapper-utils/enterViewportTracking';
import {useAnchorUpdater} from './section-wrapper-utils/activeAnchorUpdater';
import {useSectionUpdater} from './section-wrapper-utils/activeSectionUpdater';
import {SectionContext} from '../../structure/SectionContext';
import {
    useModelContext,
    useModelStore,
    useMydealerService,
    useNavigationService,
    useNavigationStore,
    usePersonalizationContext,
    useSectionContext
} from '../../../context';
import {TeaserPersonalizationList} from '../../../utils/tracking/TeaserPersonalizationList';
import {NextBestAction} from '../../structure/nbab/NextBestAction';
import {isInBrowser} from '../../../utils/browser/isInBrowser';
import {CommonSectionModel} from '../../../../generated/core';
import {modelToProps} from '../../../infrastructure/api/modelToProps';
import {C} from '../../../registries/compatibilty';
import {VISIBILITY_TRACKING_TIMEOUT} from './constants/trackingTimeout';
import {useSectionVisitedTracker} from './section-wrapper-utils/useSectionVisitedTracker';
import {useSectionVisibility} from '../../../infrastructure/compatibility/useSectionVisibility';
import {useIsClientOnlyRendering} from '../../../utils/useIsClientOnlyRendering';

export type SectionWrapperProps = React.PropsWithChildren<{
    anchorId?: string;
    sectionId: string;
    mboxId: string;
    contentLabels?: string;
    contentName?: string;
    teaserList?: TeaserPersonalizationList;
    className?: string;
    additionalTrackingData?: Partial<TrackingDTO>;
    customDataAttribute?: {key: string; value: string};
    sectionGroup?: boolean;
    refCallback?: (ref: React.RefObject<HTMLDivElement>) => void;
    teaserMboxIds?: string[];
}>;

const TRACKING_SECTION_RATIO_THRESHOLD = 0.299; // 1 = 100%
const TRACKING_VIEWPORT_RATIO_THRESHOLD = 0.499; // 1 = 100%

const InternalSectionWrapper = observer(function SW(
    props: SectionWrapperProps
): JSX.Element {
    const {
        anchorId,
        children,
        className,
        sectionId,
        additionalTrackingData,
        contentLabels,
        teaserList,
        customDataAttribute,
        sectionGroup,
        mboxId,
        teaserMboxIds,
        refCallback
    } = props;
    const ref = React.useRef(null);

    const navigationService = useNavigationService();
    const navigationStore = useNavigationStore();
    const inPageNavigationType = navigationStore?.inPageNavigationType;
    const isSmoothScrollRunning = navigationService.isSmoothScrollRunning;
    const teasers = teaserList ? teaserList.teasers : undefined;
    const trackingParams: ViewportTrackingHookParams = React.useMemo(
        () => ({
            sectionId,
            teaserList: teasers,
            contentLabels,
            additionalTrackingData
        }),
        [sectionId, teasers, contentLabels, additionalTrackingData]
    );
    const personalizationContext = usePersonalizationContext();
    const ready =
        (!personalizationContext?.isPersonalizable ||
            !personalizationContext?.isLoading) &&
        !teaserList?.loading;

    useEnterViewportTracking(
        ref,
        trackingParams,
        VISIBILITY_TRACKING_TIMEOUT,
        TRACKING_SECTION_RATIO_THRESHOLD,
        TRACKING_VIEWPORT_RATIO_THRESHOLD,
        ready,
        isSmoothScrollRunning,
        anchorId
    );

    useAnchorUpdater(ref, anchorId, inPageNavigationType);
    useSectionUpdater(ref, sectionId);

    useSectionVisitedTracker(ref, mboxId, ...(teaserMboxIds || []));

    React.useEffect(() => {
        if (refCallback) {
            refCallback(ref);
        }
    }, [ref, refCallback]);

    return (
        <>
            <NextBestAction
                sectionId={sectionId}
                sectionRef={ref}
                sectionGroup={sectionGroup}
                contentLabels={contentLabels}
            />
            <AnchorTarget
                anchorId={anchorId}
                innerRef={ref}
                className={className}
                isSection
                customDataAttribute={customDataAttribute}
            >
                {children}
            </AnchorTarget>
        </>
    );
});

export function SectionWrapper(props: SectionWrapperProps): JSX.Element | null {
    const {sectionId, contentName = '', children, contentLabels} = props;
    const skipWrapper = useSectionContext()?.skipWrapper;
    const renderMyDealer = useMydealerSectionVisible();
    const {visible, setVisible} = useSectionVisibility();

    React.useEffect(() => {
        if (
            !C.isInEditor() &&
            props.anchorId &&
            renderMyDealer !== undefined &&
            renderMyDealer !== visible
        ) {
            setVisible(renderMyDealer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderMyDealer, props, setVisible]);

    // do not render with my dealer settings for SSR or on first render (to be in sync with SSR on first render)
    const isClientOnlyRendering = useIsClientOnlyRendering();
    if (!isClientOnlyRendering && renderMyDealer !== undefined) {
        return null;
    }

    if (skipWrapper) {
        return <>{children}</>;
    }
    return (
        <SectionContext.Provider
            value={{
                sectionId: sectionId,
                contentName: contentName,
                contentLabels: contentLabels
            }}
        >
            <InternalSectionWrapper {...props} />
        </SectionContext.Provider>
    );
}

const useMydealerSectionVisible = (): boolean | undefined => {
    const [myDealerActive, setMyDealerActive] = React.useState(false);
    const mydealerService = useMydealerService();
    const path = useModelContext();
    const sectionModel = (modelToProps(
        useModelStore().getData(path.value || '/'),
        path.value || '/'
    ) as unknown) as CommonSectionModel;
    React.useEffect(() => {
        let unsubscribe: () => void | undefined;
        if (isMydealerSensitive(sectionModel) && Boolean(mydealerService)) {
            const dealerStateActive = Boolean(
                mydealerService.getSelectedDealer()
            );

            unsubscribe = mydealerService.subscribe(() => {
                setMyDealerActive(Boolean(mydealerService.getSelectedDealer()));
            });

            setMyDealerActive(dealerStateActive);
        }
        return () => {
            if (unsubscribe) unsubscribe();
        };
    }, [sectionModel, mydealerService]);
    return shouldBeRendered(sectionModel, myDealerActive);
};

function isMydealerSensitive(sectionModel: CommonSectionModel) {
    return (
        sectionModel.mydealerSettings &&
        sectionModel.mydealerSettings.type !== 'NONE'
    );
}

function shouldBeRendered(
    sectionModel: CommonSectionModel,
    myDealerActive: boolean
) {
    switch (
        sectionModel.mydealerSettings &&
        sectionModel.mydealerSettings.type
    ) {
        case 'VISIBLE':
            return isInBrowser() && !!myDealerActive;

        case 'INVISIBLE':
            return isInBrowser() && !myDealerActive;
    }
    return undefined;
}
