import {
    ApiKeyProviderConfig,
    defineApiKeyProvider,
    SharedApiKeyProvider
} from '@volkswagen-onehub/api-key-provider';
import {ServiceRegistration} from './ServiceRegistration';
import {FeatureHubModel} from '../../../generated/core';

export function createApiKeyProvider(
    featureHubModel: FeatureHubModel
): ServiceRegistration<SharedApiKeyProvider> {
    const config: ApiKeyProviderConfig = {
        apiKeysByConsumerName: featureHubModel.featureAppsApiKeyMap,
        apiKeysByConsumerId: featureHubModel.featureServicesApiKeyMap
    };

    const definition = defineApiKeyProvider(config);

    return {
        definition
    };
}
