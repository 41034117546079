import {BreadcrumbServiceV1} from '@volkswagen-onehub/breadcrumb-service';
import {BreadcrumbV1} from '@volkswagen-onehub/breadcrumb-service/lib/v1';
import {computed, observable} from 'mobx';
import {SpaAsyncConfig} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {getSecondaryConsumerForMydealer} from '../../infrastructure/hub/getSecondaryConsumerForMydealer';
import {TemplateType} from '../../utils/templateType';
import {ContentStore} from '../content/ContentStore';
import {RouterService} from '../route/RouterService';
import {
    Breadcrumb,
    BreadcrumbStore,
    BreadcrumbStoreId
} from './BreadcrumbStore';

@singleton(BreadcrumbStoreId, {env: 'client'})
export class ClientBreadcrumbStore implements BreadcrumbStore {
    @inject()
    private breadcrumbService!: BreadcrumbServiceV1;

    @inject()
    private routerService!: RouterService;

    @inject()
    protected contentStore!: ContentStore;

    @inject()
    private spaAsyncConfig!: SpaAsyncConfig;

    @observable
    public faLinks: Breadcrumb[];

    public constructor() {
        this.faLinks = [];
    }

    @postConstruct()
    public initialize(): void {
        this.breadcrumbService.listen(() => {
            this.updateFALinks();
        });
        this.routerService.listen(() => {
            this.updateFALinks();
        });
    }

    private convert(breadcrumbV1: BreadcrumbV1): Breadcrumb {
        return {
            title: breadcrumbV1.label,
            url: breadcrumbV1.href,
            hidden: false,
            onClick: breadcrumbV1.onClick
        };
    }

    private updateFALinks(): void {
        const secConsumerIds = this.spaAsyncConfig.featureHubModel
            .secondaryHistoryKeysForPath;

        const mydealerSecondaryConsumerId = getSecondaryConsumerForMydealer(
            this.spaAsyncConfig,
            this.routerService.pagePath
        );
        const secConsumerId =
            secConsumerIds[this.routerService.pagePath] ||
            mydealerSecondaryConsumerId;

        if (secConsumerId) {
            this.faLinks = this.breadcrumbService
                .getBreadcrumbs(secConsumerId)
                .map(value => this.convert(value));
        } else {
            this.faLinks = [];
        }
    }

    @computed
    public get links(): Breadcrumb[] {
        const pageRootModel = this.contentStore.getCurrentPageRootModel();
        if (!pageRootModel) {
            return [];
        }

        const templateType = pageRootModel.pageTrackingModel.templateType;
        if (templateType === TemplateType.HOME) {
            return [];
        }

        const cmsLinks = pageRootModel.breadcrumbModel.links;
        if (
            cmsLinks.length > 0 &&
            this.faLinks.length > 0 &&
            cmsLinks[cmsLinks.length - 1].hidden
        ) {
            return cmsLinks.slice(0, cmsLinks.length - 1).concat(this.faLinks);
        }
        return cmsLinks.slice().concat(this.faLinks);
    }

    public get homeLabel(): string {
        const pageRootModel = this.contentStore.getCurrentPageRootModel();
        if (!pageRootModel) {
            return '';
        }
        return pageRootModel.breadcrumbModel.homeLabel;
    }

    public get ariaLabel(): string {
        const pageRootModel = this.contentStore.getCurrentPageRootModel();
        if (!pageRootModel) {
            return '';
        }
        return pageRootModel.breadcrumbModel.ariaLabel;
    }
}
