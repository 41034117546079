import {
    SharedLayerManager,
    layerManagerDefinition,
    gfaLayerManagerDefinition
} from '@volkswagen-onehub/layer-manager';
import {ServiceRegistration} from './ServiceRegistration';
import {SharedGfaLayerManager} from '@volkswagen-onehub/gfa-layer-manager';

export function createLayerManager(): [
    ServiceRegistration<SharedLayerManager>,
    ServiceRegistration<SharedGfaLayerManager>
] {
    return [
        {
            definition: layerManagerDefinition,
            service: {
                typeName: 'LayerManager',
                version: '^2.6.0'
            }
        },
        {
            definition: gfaLayerManagerDefinition
        }
    ];
}
