import * as React from 'react';

import {CTAProps} from '@volkswagen-onehub/components-core';

import {LinkWindowTarget} from '../../../../../generated/api';
import {ButtonElementModel} from '../../../../../generated/core';
import {
    CmsActionButtonLink,
    CmsActionButtonLinkProps
} from '../../../../components/links/CmsActionButtonLink';
import {CmsButtonLink} from '../../../../components/links/CmsButtonLink';
import {
    useTrackingService,
    useUserInteractionService
} from '../../../../context';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {processContentId} from '../../../../utils/processContentId';
import {TrackingActionOverride} from '../../../../infrastructure/TrackableLinkProps';
import {useActionEnabled} from '../../../../components/links/action/useActionEnabled';
import {getActionByHref} from '../../../../components/links/action/actionHandler';
import {createTrackingFunction} from './createTrackingFunction';
import {TrackingDTO} from '../../../../context/tracking/TrackingService';

export interface ButtonElementExtraProps extends Omit<CTAProps, 'tag'> {
    ignoreExternalLinkLightbox?: boolean;
    additionalTrackingData?: Partial<TrackingDTO>;
}

export type ButtonElementProps = Partial<ButtonElementModel> &
    ButtonElementExtraProps &
    CmsActionButtonLinkProps;

export function ButtonElementComponent(props: ButtonElementProps): JSX.Element {
    const trackingService = useTrackingService();
    const userInteractionService = useUserInteractionService();
    const {
        layerButton,
        additionalTrackingData,
        alternativeActionUrl,
        alternativeLabel,
        alternativeTitle,
        layerType = 'CONTENT',
        buttonStyle,
        buttonUrl,
        buttonLabel = 'Call to Action Button',
        buttonTitle,
        ignoreExternalLinkLightbox,
        icon,
        onClick,
        trackingActionOverride,
        originalCqPath
    } = props;

    const alternativeActionEnabled = useActionEnabled(
        alternativeActionUrl || ''
    );

    const contentId = processContentId(originalCqPath);

    const {actionId} = getActionByHref(
        userInteractionService,
        alternativeActionUrl
    );

    const trackingAction =
        trackingActionOverride ||
        createTrackingFunction(
            trackingService,
            contentId,
            Boolean(layerButton),
            buttonStyle,
            alternativeActionEnabled ? alternativeActionUrl : undefined,
            additionalTrackingData,
            actionId
        );

    if (alternativeActionUrl && alternativeActionEnabled) {
        // alternate action is aused
        return createButton(
            {
                ...props,
                buttonLabel: alternativeLabel || buttonLabel,
                buttonUrl: alternativeActionUrl,
                buttonTitle: alternativeTitle || buttonTitle
            },
            contentId,
            trackingAction,
            Boolean(ignoreExternalLinkLightbox)
        );
    } else if (layerButton) {
        // buttonType layer
        if (buttonStyle === 'action') {
            return (
                <CmsActionButtonLink
                    contentId={contentId}
                    icon={icon}
                    href={buttonUrl}
                    isLayerLink
                    layerProps={{layerType}}
                    trackingActionOverride={trackingAction}
                    onClick={onClick}
                    ignoreExternalLinkLightbox={ignoreExternalLinkLightbox}
                >
                    {buttonLabel}
                </CmsActionButtonLink>
            );
        } else {
            return (
                <CmsButtonLink
                    contentId={contentId}
                    href={buttonUrl}
                    emphasis={buttonStyle}
                    title={buttonTitle}
                    isLayerLink
                    layerProps={{layerType}}
                    trackingActionOverride={trackingAction}
                    onClick={onClick}
                    ignoreExternalLinkLightbox={ignoreExternalLinkLightbox}
                >
                    {buttonLabel}
                </CmsButtonLink>
            );
        }
    } else {
        // buttonType link
        return createButton(
            props,
            contentId,
            trackingAction,
            Boolean(ignoreExternalLinkLightbox)
        );
    }
}

function createButton(
    buttonElementModel: Partial<ButtonElementModel>,
    contentId: string,
    trackingAction: TrackingActionOverride,
    ignoreExternalLinkLightbox: boolean
) {
    const {
        buttonStyle,
        buttonUrl,
        icon,
        buttonLabel,
        buttonTarget,
        buttonTitle
    } = buttonElementModel;

    if (buttonStyle === 'action') {
        return (
            <CmsActionButtonLink
                contentId={contentId}
                icon={icon}
                href={buttonUrl}
                trackingActionOverride={trackingAction}
                ignoreExternalLinkLightbox={ignoreExternalLinkLightbox}
            >
                {buttonLabel}
            </CmsActionButtonLink>
        );
    } else {
        return (
            <CmsButtonLink
                contentId={contentId}
                href={buttonUrl}
                target={buttonTarget as LinkWindowTarget}
                emphasis={buttonStyle}
                title={buttonTitle}
                trackingActionOverride={trackingAction}
                ignoreExternalLinkLightbox={ignoreExternalLinkLightbox}
            >
                {buttonLabel}
            </CmsButtonLink>
        );
    }
}

export const ButtonElement = MapTo<ButtonElementProps>(
    'vwa-ngw18/components/editorial/elements/buttonElement',
    ButtonElementComponent
);
