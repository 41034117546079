import * as React from 'react';

import {LayerType} from '../../../generated/api';
import {
    useExternalLinkLightboxService,
    useLayerStore,
    useLogger,
    useRegistry,
    useRouterService,
    useTrackingService
} from '../../context';
import {ExternalLinkLightboxService} from '../../context/externalLinkLightbox/ExternalLinkLightboxService';
import {LayerStore} from '../../context/layer/LayerStore';
import {RouterService} from '../../context/route/RouterService';
import {
    ContextTrackingData,
    TrackingService
} from '../../context/tracking/TrackingService';
import {useContextTrackingData} from '../../hooks/useContextTrackingData';
import {TrackableLinkProps} from '../../infrastructure/TrackableLinkProps';
import {noop} from '../../utils/noop';
import {processContentId} from '../../utils/processContentId';
import {createLinkName} from './createLinkName';
import {LinkHandlers} from './LinkHandlers';
import {Emphasis} from './types';

const PROTOCOL_REGEX = `^[a-z]{1,10}:/?`;

export type ClickTargetElement = HTMLAnchorElement | HTMLButtonElement;

export interface BaseCmsLinkProps extends TrackableLinkProps {
    readonly contentId?: string;
    readonly ignoreExternalLinkLightbox?: boolean;
    readonly isDownloadLink?: boolean;
    readonly documentPath?: string;
    readonly isLayerLink?: boolean;
    readonly disabled?: boolean;
    readonly showLayerIcon?: boolean;
    readonly showExternalIcon?: boolean;
    readonly emphasis?: Emphasis;
    readonly rel?: 'next' | 'prev';
    readonly isRichTextLink?: boolean;
    readonly layerProps?: {
        layerType: LayerType;
        options?: any;
    };
}

function useCmsLinkHref(props: BaseCmsLinkProps): string {
    const layerStore = useLayerStore();
    const {href, isLayerLink} = props;

    if (isLayerLink && href) {
        return layerStore.createLayerHref(href);
    }

    return href || '';
}

export function useCmsLink(
    linkRef: React.MutableRefObject<ClickTargetElement | null>,
    baseLinkProps: BaseCmsLinkProps
): [React.EventHandler<React.SyntheticEvent>, string] {
    const logger = useLogger();
    const routerService = useRouterService();
    const trackingService = useTrackingService();
    const externalLinkLightboxService = useExternalLinkLightboxService();
    const layerStore = useLayerStore();
    const trackingData = useContextTrackingData();
    const clientHref = useCmsLinkHref(baseLinkProps);
    const {isLayerLink, href} = baseLinkProps;
    const registry = useRegistry();

    if (href) {
        const match = href.match(PROTOCOL_REGEX);
        if (match?.length) {
            const protocol = match[0];
            const linkHandler = LinkHandlers.get(protocol);
            if (linkHandler) {
                const handler = linkHandler(href, {
                    registry,
                    trackingService,
                    baseLinkProps,
                    trackingData,
                    linkRef
                });
                if (handler) {
                    return handler;
                } else {
                    logger.general.warn(
                        'linkHandler returned undefined ',
                        href
                    );
                    return [
                        () => {
                            // nothing to do
                        },
                        '#'
                    ];
                }
            }
        }

        if (isLayerLink) {
            return [
                onClickOpenLayerFn(
                    baseLinkProps,
                    layerStore,
                    trackingService,
                    trackingData,
                    linkRef
                ),
                clientHref
            ];
        }

        if (routerService.isAppLink(href)) {
            return [
                onClickInternal(
                    baseLinkProps,
                    externalLinkLightboxService,
                    routerService,
                    trackingService,
                    trackingData,
                    linkRef
                ),
                clientHref
            ];
        }
    }

    return [
        onClickExternal(
            baseLinkProps,
            externalLinkLightboxService,
            trackingService,
            trackingData,
            linkRef
        ),
        clientHref
    ];
}

function onClickInternal(
    baseLinkProps: BaseCmsLinkProps,
    externalLinkLightboxService: ExternalLinkLightboxService,
    routerService: RouterService,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>
): React.EventHandler<React.SyntheticEvent> {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
        const {target, href = '', onClick = noop} = baseLinkProps;

        if (externalLinkLightboxService.isOpened()) {
            externalLinkLightboxService.closeLightbox();
        }

        trackLinkClick(
            baseLinkProps,
            trackingService,
            trackingData,
            linkRef,
            false
        );
        onClick(e);

        if (target !== '_blank') {
            e.preventDefault();
            routerService.navigate(href);
        }
    };
}

function onClickExternal(
    baseLinkProps: BaseCmsLinkProps,
    externalLinkLightboxService: ExternalLinkLightboxService,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>
): React.EventHandler<React.SyntheticEvent> {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
        const {
            target = '_self',
            ignoreExternalLinkLightbox,
            href = '',
            onClick = noop
        } = baseLinkProps;
        if (
            externalLinkLightboxService.shouldOpenLightbox(href) &&
            !ignoreExternalLinkLightbox
        ) {
            e.preventDefault();
            externalLinkLightboxService.openLightbox(href, target);
        }

        trackLinkClick(
            baseLinkProps,
            trackingService,
            trackingData,
            linkRef,
            true
        );
        onClick(e);
    };
}

function trackLinkClick(
    baseLinkProps: BaseCmsLinkProps,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>,
    isExternal: boolean = false
): void {
    const {trackingActionOverride, href = ''} = baseLinkProps;
    const linkName = baseLinkProps.linkName
        ? baseLinkProps.linkName
        : createLinkName(linkRef);
    if (trackingActionOverride) {
        trackingActionOverride(href, linkName || '', trackingData);
    } else if (isExternal) {
        trackingService.trackLinkClickExternal(
            href,
            baseLinkProps.contentId,
            linkName,
            trackingData
        );
    } else {
        trackingService.trackLinkClick(
            href,
            baseLinkProps.contentId,
            linkName,
            trackingData
        );
    }
}

function onClickOpenLayerFn(
    baseLinkProps: BaseCmsLinkProps,
    layerStore: LayerStore,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>
): React.EventHandler<React.SyntheticEvent> {
    const {onClick = noop} = baseLinkProps;
    return (e: React.MouseEvent<HTMLButtonElement>) => {
        openLayer(
            baseLinkProps,
            layerStore,
            trackingService,
            trackingData,
            linkRef
        );

        e.preventDefault();
        onClick(e);
    };
}

function openLayer(
    baseLinkProps: BaseCmsLinkProps,
    layerStore: LayerStore,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>
): void {
    if (!layerStore || !baseLinkProps.href) {
        return;
    }

    layerStore.openLayer(baseLinkProps.href).then(data => {
        trackOpenLayer(
            baseLinkProps,
            trackingService,
            trackingData,
            linkRef,
            data && data.model
                ? data.model.trackingModel.contentName
                : undefined
        );
    });
}

function trackOpenLayer(
    baseLinkProps: BaseCmsLinkProps,
    trackingService: TrackingService,
    trackingData: ContextTrackingData,
    linkRef: React.MutableRefObject<ClickTargetElement | null>,
    layerName?: string
): void {
    const {trackingActionOverride, contentId, href = ''} = baseLinkProps;
    const linkName = baseLinkProps.linkName
        ? baseLinkProps.linkName
        : createLinkName(linkRef);
    if (trackingActionOverride) {
        trackingActionOverride(href, linkName || '', trackingData, layerName);
    } else {
        trackingService.trackLinkClick(href, contentId, linkName, trackingData);

        trackingService.trackLayerLoad(
            processContentId(href, layerName),
            trackingData
        );
    }
}
