import * as React from 'react';

import {
    TextAppearance,
    TextTag,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import styled from 'styled-components';

import {CopyItemModel} from '../../../../generated/core';
import {Copy, CopyProps} from '../../../components/Copy';
import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {useLayerContext} from '../../../context';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {LinkElementComponent} from '../elements/LinkElementComponent';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

export const COPY_BODY_CLASS = 'copy-item-body';

export interface CopyItemExtraProps {
    readonly hideItemDisclaimers?: boolean;
    readonly useDropCap?: boolean;
    readonly appearance?: TextAppearance | TokenTextAppearance;
    readonly tag?: TextTag;
    readonly newDisclaimer?: boolean;
}

type CopyItemProps = CopyProps & CopyItemModel & CopyItemExtraProps;

/**
 * I101 Copy Item
 */
function InternalCopyItem(props: CopyItemProps): JSX.Element {
    const {hideItemDisclaimers} = props;
    const layerContext = useLayerContext();
    const isLayerContext = Boolean(layerContext.layerType);
    const body = (
        <>
            <Copy {...props} placeholder="Type your text here..." />
            {createAdditionalLink(props)}
        </>
    );

    if (props.newDisclaimer) {
        return (
            <CyAttributeAppender name="copyItem" addTag={'div'}>
                {hideItemDisclaimers || isLayerContext ? (
                    // Note: we use this class to add specific styling for the Announcement Bar
                    <div className={COPY_BODY_CLASS}>{body}</div>
                ) : (
                    <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
                        <>
                            {body}
                            <BellowItemDisclaimerContainer
                                displayTypes={['T4_ITEM_BASED']}
                            />
                        </>
                    </GeneralDisclaimerProvider>
                )}
            </CyAttributeAppender>
        );
    }

    const displayAllDisclaimersBelow = hideItemDisclaimers || isLayerContext;

    return (
        <SimpleDisclaimerWrapper
            getItemDisclaimers={displayAllDisclaimersBelow}
        >
            <CyAttributeAppender name="copyItem" addTag={'div'}>
                {displayAllDisclaimersBelow ? (
                    // Note: we use this class to add specific styling for the Announcement Bar
                    <div className={COPY_BODY_CLASS}>{body}</div>
                ) : (
                    <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
                        {body}
                        <BellowItemDisclaimerContainer
                            displayTypes={['T4_ITEM_BASED']}
                        />
                    </GeneralDisclaimerProvider>
                )}
            </CyAttributeAppender>
        </SimpleDisclaimerWrapper>
    );
}

const StyledLinkContainer = styled.div`
    margin-top: ${props => props.theme.size.dynamic0100};
`;

function createAdditionalLink(props: CopyItemModel): JSX.Element | void {
    const {
        linkHref,
        linkTitle,
        linkTarget,
        layerLink,
        layerType,
        layerPath,
        linkTextRichtext,
        originalCqPath,
        cqPath
    } = props;

    const additionalLinkHref = layerLink ? layerPath : linkHref;

    if (additionalLinkHref && linkTitle) {
        return (
            <CyAttributeAppender name="copyAdditionalLink">
                <StyledLinkContainer>
                    <LinkElementComponent
                        layerLink={layerLink}
                        layerType={layerType}
                        layerPath={layerPath}
                        linkUrl={linkHref}
                        linkLabel={linkTitle}
                        linkLabelRichtext={linkTextRichtext}
                        linkTitle={linkTitle}
                        linkTarget={linkTarget}
                        originalCqPath={originalCqPath}
                        cqPath={cqPath}
                    />
                </StyledLinkContainer>
            </CyAttributeAppender>
        );
    }
}

export const CopyItem = MapTo<CopyItemExtraProps>(
    'vwa-ngw18/components/editorial/items/copyItem',
    InternalCopyItem
);
