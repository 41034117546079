import * as React from 'react';

import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {SectionWrapper} from './SectionWrapper';
import {PremiereSectionModel} from '../../../../generated/core';
import {PremiereSection as PremiereSectionLSG} from '../../../d6/components/premiere';
import {CountdownItem} from '../items/PremiereCountdownItem';
import {RevealItem} from '../items/PremiereRevealItem';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {CountdownState} from '../../../context/tracking/TrackingService';
import {useTrackingService} from '../../../context';
import {ContainerItem} from '../../../infrastructure/ContainerExporter';
import {ButtonElement} from '../elements/buttonElement/ButtonElement';

export const premiereResponsiveMediaConfig: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: '(max-aspect-ratio: 1/1)',
        sizes: '100vw',
        aspectRatio: 'r_4_5',
        portray: true
    },
    {
        mediaQuery: '(min-aspect-ratio: 1/1) and (max-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_4_3'
    },
    {
        mediaQuery: '(min-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_16_9'
    }
];

export const PremiereButtonElements = (props: {
    buttons: ContainerItem[];
    countdownState: CountdownState;
}) => {
    const trackingService = useTrackingService();

    return (
        <>
            {props.buttons.map((child: ContainerItem) => {
                const trackButtonClick = (href: string, linkName: string) =>
                    trackingService.trackButtonClick(
                        child.key,
                        href,
                        linkName,
                        {countdownState: props.countdownState}
                    );
                return (
                    <ButtonElement
                        path={`buttonsParsys/${child.key}`}
                        key={child.key}
                        trackingActionOverride={trackButtonClick}
                    />
                );
            })}
        </>
    );
};

const InternalPremiereSection = (props: PremiereSectionModel): JSX.Element => {
    const {
        anchorId,
        contentId: sectionId,
        contentName,
        contentLabels,
        startOfReveal,
        tagTypeBroken,
        mboxId
    } = props;

    const time = startOfReveal - Date.now();

    const premiereSection = C.isInEditor() ? (
        <>
            {time < 0 ? (
                <AlertBox severity={AlertBoxSeverity.ERROR}>
                    <span>
                        Please set the Pre Order Date or one that is not in the
                        past
                    </span>
                </AlertBox>
            ) : (
                <AlertBox severity={AlertBoxSeverity.INFO}>
                    <span>
                        Countdown date set to:{' '}
                        {new Date(startOfReveal).toLocaleString()}
                    </span>
                </AlertBox>
            )}
            <CountdownItem endTime={startOfReveal} path="countdownItem" />
            <RevealItem path="revealItem" />
        </>
    ) : (
        <PremiereSectionLSG
            beforeReveal={
                <CountdownItem endTime={startOfReveal} path="countdownItem" />
            }
            afterReveal={<RevealItem path="revealItem" />}
            date={time}
        />
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S140 Premiere Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                key={sectionId}
                contentName={contentName}
            >
                {premiereSection}
            </SectionWrapper>
        </AuthoringWrapper>
    );
};
export const PremiereSection = MapTo(
    'vwa-ngw18/components/editorial/sections/premiereSection',
    InternalPremiereSection
);
