import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {DisclaimerContainerProps} from './helpers';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';

const StyledBelowSectionDisclaimerReferences = styled.div`
    padding-inline: ${props => props.theme.size.grid002};
    padding-top: ${props => props.theme.size.dynamic0100};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid006};
    }
`;

export const BelowSectionDisclaimerContainer = observer(function BSDC(
    props: DisclaimerContainerProps
) {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const hasReferences = disclaimers && disclaimers.length !== 0;

    return (
        <>
            {hasReferences && (
                <StyledBelowSectionDisclaimerReferences>
                    <CyAttributeAppender name="sectionDisclaimers">
                        <DisclaimersWithSafewords disclaimers={disclaimers} />
                    </CyAttributeAppender>
                </StyledBelowSectionDisclaimerReferences>
            )}
        </>
    );
});
