import styled, {css} from 'styled-components';
import {VideoPlayerV2CalculatedAspectRatio} from '../video-player-v2';

interface AspectRatioContainerProps {
    readonly aspectRatio?: VideoPlayerV2CalculatedAspectRatio;
}

export const StyledAspectRatioContainer = styled.div<AspectRatioContainerProps>`
    ${props =>
        typeof props.aspectRatio === 'number' &&
        css`
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: ${props.aspectRatio * 100}%;
        `}
    ${props => props.aspectRatio === 'matchParentHeight' && 'height: 100%'};
    ${props => props.aspectRatio === 'matchParentWidth' && 'width: 100%'};
    ${props =>
        props.aspectRatio &&
        css`
            > * {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        `}
`;
StyledAspectRatioContainer.displayName = 'StyledAspectRatioContainer';
