import * as React from 'react';

import {
    NextBestActionButton as D6NextBestActionButton,
    NextBestAction as D6NextBestAction
} from '../../../d6/components/next-best-action-button';

import {getIcon} from '../../../utils/getMappedIcon';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {useScrollToSectionOfFocussedNbab} from './useScrollToSectionOfFocussedNbab';
import {SingleNbabTrackingData} from './useSingleNbabTracking';
import {useSingleNbabClick} from './useSingleNbabClick';

export type NextBestActionButtonProps = Readonly<{
    actionModel: MergeNextBestActionModel;
    isHidden?: boolean;
    inaccessible?: boolean; // "true" value makes the button inaccessible for keyboard and screen-reader
    trackingData?: SingleNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
}>;

export function MobileNextBestActionButton({
    inaccessible,
    isHidden,
    actionModel,
    sectionRef,
    trackingData
}: NextBestActionButtonProps): JSX.Element | null {
    const ref = React.useRef(null);

    useScrollToSectionOfFocussedNbab(ref, sectionRef);

    // create link
    const [linkClick, linkHref] = useSingleNbabClick(
        ref,
        actionModel,
        trackingData
    );

    const href = linkHref;
    const action = ({
        content: actionModel.label,
        icon: getIcon(actionModel.icon, 'default'),
        tag: href && actionModel.linkType !== 'action' ? 'a' : 'button',
        href,
        onClick: linkClick,
        ariaLabel: actionModel.label,
        title: actionModel.title,
        target:
            actionModel.linkType === 'external'
                ? actionModel.externalLinkTarget
                : undefined
    } as unknown) as D6NextBestAction;

    return (
        <D6NextBestActionButton
            action={action}
            ref={ref}
            tabIndex={inaccessible ? -1 : 0}
            hideIcon={isHidden}
            expandContent
            title={actionModel.title}
            isHidden={isHidden}
        />
    );
}
