import React from 'react';
import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import ResizeObserver from 'resize-observer-polyfill';

type FakeGalleryWrapProps = {
    clientGalleryHeight: number;
};

// Acts as a spacer, for actual gallery, exposed for testing
export const StyledFakeGalleryWrapper = styled.div<FakeGalleryWrapProps>`
    width: 100%;
    display: none;

    @media (min-width: ${Breakpoints.b960}px) {
        min-height: ${props => props.clientGalleryHeight}px;

        display: block;
        visibility: hidden;
    }
`;

type FakeGalleryProps = React.PropsWithChildren<{
    galleryRef: React.RefObject<HTMLDivElement>;
    activeGalleryIndex: number;
}>;

/**
 * The FakeDesktopGallery is used by the Highlight Feature Section.
 * It preserves vertical space, which is needed, because for accessiblity
 * reasons, the galleries are rendered using `position: absolute`, from inside
 * the accordion body.
 *
 * The component uses a given ref to track the size of the actual gallery.
 * Because we don't know the client size on the server, for ssr we should
 * actually pass a gallery as a child.
 */
export const FakeDesktopGallery = ({
    activeGalleryIndex,
    children,
    galleryRef
}: FakeGalleryProps) => {
    const [clientGalleryHeight, setClientGalleryHeight] = React.useState(0);

    const galleryResizeObserver = React.useMemo(
        () =>
            isInBrowser()
                ? new ResizeObserver(entries => {
                      if (entries[0]) {
                          setClientGalleryHeight(entries[0].contentRect.height);
                      }
                  })
                : undefined,
        []
    );

    React.useEffect(() => {
        const galleryDiv = galleryRef.current;

        if (galleryDiv) {
            setClientGalleryHeight(galleryDiv.getBoundingClientRect().height);
            galleryResizeObserver!.observe(galleryDiv);
        }

        return () => {
            if (galleryDiv) {
                galleryResizeObserver!.unobserve(galleryDiv);
            }
        };
        // activeGalleryIndex has effect on which gallery is in ref
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeGalleryIndex]);

    return (
        <StyledFakeGalleryWrapper clientGalleryHeight={clientGalleryHeight}>
            {children}
        </StyledFakeGalleryWrapper>
    );
};
