import {singleton} from '../../infrastructure/di/annotations';

import {METHOD_NOT_AVAILABLE_ON_SERVER} from '../../constants/exceptions';
import {
    InPageMenuItemModel,
    NavigationStore,
    NavigationStoreId
} from './NavigationStore';
import {NavigationTopBarScrollDirection} from './NavigationTopBarScrollDirection';

@singleton(NavigationStoreId, {env: 'server'})
export class NavigationStoreServer implements NavigationStore {
    public activeInPageNavigationItem: InPageMenuItemModel | undefined;
    public activeInPageNavigationItemId: string | undefined;
    public activeInPageNavigationItemTitle: string = '';
    public activeInPageNavigationItemUrl: string = '#';
    public activeSectionId: string | undefined;
    public activeSectionGroupId: string | undefined;
    public inPageNavigationItems: InPageMenuItemModel[] = [];
    public visibleInPageNavigationItems: InPageMenuItemModel[] = [];
    public inPageNavigationType: string | undefined;
    public isFlyoutMenuOpen: boolean = false;
    public isInPageNavigationOpen: boolean = false;
    public isInPageNavigationVisible: boolean = false;
    public isTopBarAboveStage: boolean = true;
    public isMenuLabelVisible: boolean = true;
    public lastScrollDirection: NavigationTopBarScrollDirection =
        NavigationTopBarScrollDirection.UP;
    public navFlyoutTeaserPath: string = '';
    public navFlyoutTeaserSecondLevelPath: string = '';
    public isAnnouncementBarVisible: boolean = false;
    public getAnnouncementBarHeight: number = 0;

    public withScrollLock(_callback: () => void): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public closeFlyoutMenu(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public closeInPageNavigation(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setInPageNavigationItems(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setInPageItemsVisibility(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public isInPageItemVisible(_id: string): boolean {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setInPageNavigationItem(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setInPageNavigationType(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public openFlyoutMenu(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public openInPageNavigation(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setActiveInPageNavigationItem(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setInPageNavIntersectionItem(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public removeInPageNavIntersectionItem(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setActiveSection(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public toggleInPageNavigation(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public updateScrollPosition(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public updateScrollPositionOnAnimationFrame(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public onLoad(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setStageHeight(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setSecondLevelTeaserData(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public onBackButtonClick(): boolean {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }

    public setAnnouncementBarHeight(): void {
        throw Error(METHOD_NOT_AVAILABLE_ON_SERVER);
    }
}
