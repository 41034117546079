import * as React from 'react';
import {isInBrowser} from '../../../utils/browser/isInBrowser';
import {smoothScroll} from '../../../utils/smoothScroll';

const TOP_BAR_HEIGHT = 100;

export const handleScrollToSection = (
    ref: React.RefObject<HTMLDivElement>,
    sectionRef?: React.RefObject<HTMLDivElement>
) => {
    if (
        isInBrowser() &&
        sectionRef?.current &&
        document?.activeElement === ref.current
    ) {
        smoothScroll({
            animationDuration: 100,
            distance:
                (sectionRef?.current?.getBoundingClientRect().top || 0) -
                window.innerHeight / 2 -
                TOP_BAR_HEIGHT
        });
    }
};

export function useScrollToSectionOfFocussedNbab(
    nbabRef: React.RefObject<HTMLDivElement>,
    sectionRef?: React.RefObject<HTMLDivElement>
): void {
    React.useEffect(() => {
        if (nbabRef && nbabRef.current) {
            const onScrollToSection = () => {
                handleScrollToSection(nbabRef, sectionRef);
            };

            const handleActiveElementChanged = () => {
                document.removeEventListener('keyup', onScrollToSection);
                document.addEventListener('keyup', onScrollToSection, true);
            };

            document.addEventListener(
                'focusin',
                handleActiveElementChanged,
                true
            );

            return function cleanup(): void {
                document.removeEventListener(
                    'focusin',
                    handleActiveElementChanged
                );
                document.removeEventListener('keyup', onScrollToSection);
            };
        } else {
            return;
        }
    }, [nbabRef, sectionRef]);
}
