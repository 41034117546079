import * as React from 'react';

import {
    AspectRatio,
    AspectRatioContainer
} from '@volkswagen-onehub/components-core';
import {MediaAssetImageModel} from '../../generated/core';
import {ResponsiveMediaRendererConf} from './ResponsiveMediaRenderer';
import {ImageWithFocalPoint} from '../d6/components/image-with-focal-point';
import {useImageService} from '../context';
import {C} from '../registries/compatibilty';
import {aspectRatioMap} from '../utils/aspectRatioMap';
import {focalPointMap} from '../utils/focalPointMap';
import {getImageResourceSet} from '../utils/getImageResourceSet';
import {DecorativeLazyPicture, DecorativePicture} from './Picture';
import {useEnableSmartImaging} from '../hooks/useEnableSmartImaging';

interface VideoPosterProps {
    image: MediaAssetImageModel;
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    onLoad?: () => void;
    onError?: () => void;
}

export const VideoPoster: React.FunctionComponent<VideoPosterProps> = (
    props: VideoPosterProps
) => {
    const imageService = useImageService();
    const enableSmartImaging = useEnableSmartImaging();

    const {onLoad, onError, image} = props;

    if (!image?.src) {
        return null;
    }

    const renderPicture = () => {
        const {src} = getImageResourceSet(
            imageService,
            image,
            enableSmartImaging
        );

        let pictureConfig;
        if (props.responsiveMediaConfig) {
            pictureConfig = props.responsiveMediaConfig.map(configItem => {
                const imageResourceSet = getImageResourceSet(
                    imageService,
                    {
                        ...image,
                        aspectRatio: configItem.aspectRatio
                    },
                    enableSmartImaging
                );

                return {
                    ...configItem,
                    ...imageResourceSet
                };
            });
        } else {
            const imageResourceSet = getImageResourceSet(
                imageService,
                image,
                enableSmartImaging
            );
            const configItem = {
                aspectRatio: image.aspectRatio
            };

            pictureConfig = [
                {
                    ...configItem,
                    ...imageResourceSet
                }
            ];
        }

        if (C.isInEditor() || !props.image.hasAsset) {
            return (
                <DecorativePicture
                    src={image.src}
                    onLoad={onLoad}
                    onError={onError}
                    config={pictureConfig}
                />
            );
        }

        return (
            <DecorativeLazyPicture
                src={src}
                onLoad={onLoad}
                onError={onError}
                config={pictureConfig}
            />
        );
    };

    return (
        <AspectRatioContainer
            ratio={
                image.aspectRatio
                    ? aspectRatioMap[image.aspectRatio]
                    : AspectRatio['16:9']
            }
        >
            <ImageWithFocalPoint
                focalPoint={focalPointMap[image.focalPoint || 'CENTER_CENTER']}
            >
                {renderPicture()}
            </ImageWithFocalPoint>
        </AspectRatioContainer>
    );
};
