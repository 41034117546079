import {Breakpoints} from '@volkswagen-onehub/components-core';

// NOTE: due Safari rendering vs scroll, no lower value then 150ms
export const SCROLL_RESET_TIMEOUT = 150;

export const slidesPerPageConfig = {
    [Breakpoints.default]: 1,
    [Breakpoints.b560]: 2,
    [Breakpoints.b1280]: 3
};

export const getSlidesPerPage = (mediaQueries: any) => {
    let x = slidesPerPageConfig[Breakpoints.default];

    if (mediaQueries?.isTablet || mediaQueries?.isLaptop) {
        x = slidesPerPageConfig[Breakpoints.b560];
    }
    if (mediaQueries?.isDesktop) {
        x = slidesPerPageConfig[Breakpoints.b1280];
    }
    return x;
};

export const isElementOutOfViewport = (mediaQueries: any, index: number) => {
    let maxItemsToAnimate = getSlidesPerPage(mediaQueries);

    return Boolean(index > maxItemsToAnimate);
};

export const getAnimationDelay = (mediaQueries: any, index: number) => {
    const BASE_DELAY = 200;
    const STEP_DELAY = 100;
    let maxItemsToDelay = getSlidesPerPage(mediaQueries);

    if (index < maxItemsToDelay + 1) {
        return BASE_DELAY + index * STEP_DELAY;
    }
    return 0;
};
