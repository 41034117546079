import * as React from 'react';

export const calcOffsetSizeRatio = (e: React.MouseEvent<HTMLDivElement>) =>
    e.nativeEvent.offsetX / e.currentTarget.clientWidth;

export const getProgressBarMouseDownEventData = (
    e: React.MouseEvent<HTMLDivElement>,
    rect: ClientRect
) => {
    const clientValue = e.clientX;

    return {
        clientValue,
        offsetValue: clientValue - rect.left
    };
};

export const getClientValue = (e: MouseEvent) => e.clientX;

export const getClientTouchValue = (e: TouchEvent) =>
    e.targetTouches[0].clientX;

export const getProgressBarSize = (el: Element) => el.clientWidth;

export const calcDelta = (clientValue: number, prevClientValue: number) =>
    clientValue - prevClientValue;

export const isBelowMin = (clientValue: number, rect: ClientRect) =>
    clientValue < rect.left;

export const isAboveMax = (clientValue: number, rect: ClientRect) =>
    clientValue > rect.right;

export const getTouchData = (e: React.TouchEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clientValue = e.targetTouches[0].clientX;
    const offsetValue = clientValue - rect.left;

    return {
        clientValue,
        offsetValue
    };
};
