import {
    filterServiceDefinition,
    SharedFilterService
} from '@volkswagen-onehub/filter-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createFilterService(): ServiceRegistration<
    SharedFilterService
> {
    return {
        definition: filterServiceDefinition,
        service: {
            typeName: 'FilterService',
            version: '^1.0.0'
        }
    };
}
