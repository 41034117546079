import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';

type FooterProps = Readonly<{
    disclaimers?: React.ReactNode;
    disclaimersGlobal?: JSX.Element;
    disclaimersReferenced?: JSX.Element;
    mainNav?: JSX.Element[];
    bottomNav?: JSX.Element;
    copyright?: JSX.Element;
    marketLogo?: JSX.Element;
    visible?: boolean;
    // remove once CMS uses zIndex prop instead
    isLayerOpen?: boolean;
    zIndex?: number;
    isHorizontalView: boolean;
}>;

const StyledFooterBody = styled.div`
    border-top: solid 2px ${props => props.theme.colors.border.hover};
    padding: ${props => props.theme.size.static500} 0;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-top: ${props => props.theme.size.static550};
        padding-left: ${props => props.theme.size.grid001};
        padding-right: ${props => props.theme.size.grid001};
    }
`;
StyledFooterBody.displayName = 'StyledFooterBody';

const StyledMainNavWrap = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        display: flex;
        justify-content: space-between;
    }
`;
StyledMainNavWrap.displayName = 'StyledMainNavWrap';

const StyledFooterDisclaimers = styled.div<{
    isHorizontalView?: boolean;
}>`
    border-top: solid 2px ${props => props.theme.colors.border.divider};
    padding: ${props => props.theme.size.static500} 0
        ${props => props.theme.size.static200};
    padding-bottom: ${props =>
        props.isHorizontalView
            ? props.theme.size.static750
            : props.theme.size.static200};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-top: ${props => props.theme.size.static550};
    }
`;
StyledFooterDisclaimers.displayName = 'StyledFooterDisclaimers';

type StyledFooterProps = Readonly<{
    visible?: boolean;
    zIndex?: number;
}>;
const StyledFooter = styled.footer<StyledFooterProps>`
	${props => !props.visible && 'display: none;'}

	// necessary for use with layers and Floating Button / NBAB
	${props =>
        props.zIndex != null &&
        `
		z-index: ${props.zIndex};
		position: relative;
	`}

    // NOTE: NGWD6-34354 - Temporary background for TikTok in-app browser before merge of core-components update with NGWD6-34293. Afterwards this can be removed again.
    background: white;
`;
StyledFooter.displayName = 'StyledFooter';

export const Footer: React.FunctionComponent<FooterProps> = props => {
    const {
        mainNav,
        bottomNav,
        disclaimersGlobal,
        disclaimersReferenced,
        disclaimers,
        marketLogo,
        visible,
        isLayerOpen,
        zIndex,
        isHorizontalView
    } = props;

    return (
        <StyledFooter
            visible={visible}
            // fallback to isLayerOpen behaviour until CMS implements zIndex prop
            zIndex={zIndex != null ? zIndex : isLayerOpen ? -1 : undefined}
        >
            <Layout>
                <Container
                    padding={{
                        left: ContainerPadding.grid002,
                        right: ContainerPadding.grid002
                    }}
                    wrap={ContainerWrap.always}
                >
                    <StyledFooterBody>
                        <StyledMainNavWrap>{mainNav}</StyledMainNavWrap>
                        {bottomNav}
                        {marketLogo}
                    </StyledFooterBody>
                    {/* TODO these are legacy disclaimers, remove after merging to cms */}
                    {(disclaimersGlobal || disclaimersReferenced) && (
                        <StyledFooterDisclaimers>
                            <Container
                                wrap={ContainerWrap.always}
                                gutter={ContainerGutter.static200}
                            >
                                {disclaimersReferenced}
                                {disclaimersGlobal}
                            </Container>
                        </StyledFooterDisclaimers>
                    )}
                    {disclaimers && (
                        <StyledFooterDisclaimers
                            isHorizontalView={isHorizontalView}
                        >
                            {disclaimers}
                        </StyledFooterDisclaimers>
                    )}
                </Container>
            </Layout>
        </StyledFooter>
    );
};
Footer.displayName = 'Footer';

export * from './parts/bottom-nav';
export * from './parts/copyright-element';
export * from './parts/disclaimer-group';
export * from './parts/market-logo';
