import {SingleConfigurationFlowNavigatorV1} from '@volkswagen-onehub/single-configuration-flow-navigator';
import * as React from 'react';
import {SINGLE_CONFIGURATION_FLOW_NAVIGATOR} from '../../../infrastructure/hub/createSingleFlowNavigator';
import {CONFIGURE_PROTOCOL, LinkHandlerProps} from '../LinkHandlers';

export const configureHandler = (
    href: string,
    props: LinkHandlerProps
): [React.EventHandler<React.SyntheticEvent>, string] => {
    const {registry} = props;
    const flowNavigator: SingleConfigurationFlowNavigatorV1 = registry.getSingleton(
        SINGLE_CONFIGURATION_FLOW_NAVIGATOR
    );
    const navigation = flowNavigator.createNodeNavigation(
        getConfigurePathname(href)
    );
    return [
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            if (navigation) {
                navigation.onClick();
            }
        },
        navigation ? navigation.href : href
    ];
};

function getConfigurePathname(href: string) {
    return href.substring(CONFIGURE_PROTOCOL.length);
}
