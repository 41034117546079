import {action, observable} from 'mobx';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {
    PersonalizedAction,
    PersonalizedNbab
} from '../../modules/structure/nbab/PersonalizedNbab';
import {RouterService} from '../route/RouterService';

const EMPTY_NBAB: PersonalizedNbab = {actions: []};

@singleton('PersonalizedNbabStore', {env: 'client'})
export class PersonalizedNbabStore {
    private pagePath?: string;
    @observable private _personalizedNbab: PersonalizedNbab = EMPTY_NBAB;
    @inject() private routerService!: RouterService;

    public get personalizedActions(): PersonalizedAction[] {
        const actions = this._personalizedNbab.actions;
        if (this.pagePath === this.routerService.pagePath) return actions;
        return [];
    }

    @action
    pushNbab(personalizedNbab: PersonalizedNbab) {
        this._personalizedNbab = personalizedNbab;
        this.pagePath = this.routerService.pagePath;
    }
}
