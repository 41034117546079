import {
    faContentServiceDefinition,
    SharedFaContentService
} from '@volkswagen-onehub/fa-content-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createFeatureAppContentService(): ServiceRegistration<
    SharedFaContentService
> {
    const definition = faContentServiceDefinition;

    return {
        definition
    };
}
