import * as React from 'react';
import {VideoPlayerV2State} from '../../../../../d6/components/video-player-v2';

import {useMediaContext} from '../../../../../context';

export type VideoStateChangeCallback = (
    state: VideoPlayerV2State,
    startedByIO: boolean
) => void;

export function useVideoStateChangeHandler(
    trackStateChange: VideoStateChangeCallback
): VideoStateChangeCallback {
    const mediaContext = useMediaContext();
    const {setInteractionData} = mediaContext;

    return React.useCallback(
        (state: VideoPlayerV2State, startedByIO: boolean) => {
            trackStateChange(state, startedByIO);

            if (setInteractionData) {
                setInteractionData({
                    type: 'VIDEO',
                    data: state,
                    startedByIO
                });
            }
        },
        [trackStateChange, setInteractionData]
    );
}
