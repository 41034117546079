import * as React from 'react';

import {
    Text,
    TokenTextAppearance,
    TokenTextColor,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';
import {BaseLinkProps} from '../../teaser-element/types';

export const INPAGE_BAR_HEIGHT = 52; // topbar lineheight + it's paddings

export interface StyledBarLinkProps {
    readonly isActive?: boolean;
}

//height - 2px spacing to the bottom of bar
const StyledBarLink = styled.a<StyledBarLinkProps>`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.button.primary.default};

    border-bottom: solid 2px transparent;

    &:hover {
        color: ${props => props.theme.colors.button.primary.hover};
        border-color: ${props => props.theme.colors.button.primary.hover};
    }

    &:focus {
        outline: none;
    }

    &:focus > span {
        outline: solid 2px ${props => props.theme.colors.button.primary.hover};
        outline-offset: 4px;
    }

    border: none;

    ${props =>
        props.isActive &&
        `
			border-color: ${props.theme.colors.button.primary.default};
		`};
    position: relative;

    &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        bottom: -8px;
        background-color: ${props => props.theme.colors.button.primary.default};
        opacity: ${props => (props.isActive ? 1 : 0)};
    }

    &:hover::after {
        opacity: 1;
        background-color: ${props => props.theme.colors.button.primary.hover};
    }

    &:active::after {
        opacity: 1;
    }
`;

export interface InPageNavBarLinkProps extends BaseLinkProps {
    readonly isActive?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const NavBarLink = (
    props: React.PropsWithChildren<InPageNavBarLinkProps>
): JSX.Element => {
    const {children, href, rel, title, isActive, onClick} = props;

    return (
        <StyledBarLink
            href={href}
            onClick={onClick}
            rel={rel}
            title={title}
            isActive={isActive}
            aria-current={isActive}
        >
            <Text
                tag={TextTag.span}
                appearance={TokenTextAppearance.copy100}
                color={TokenTextColor.inherit}
                staticSize
                bold
            >
                {children}
            </Text>
        </StyledBarLink>
    );
};
