import {
    useBBOContext,
    usePersonalizationContext,
    usePersonalizationStore,
    useSectionContext,
    useSectionGroupContext,
    useTrackingService
} from '../../context';
import * as React from 'react';
import {processContentId} from '../processContentId';
import {createContextTrackingData} from './createContextTrackingData';
import {
    ContextTrackingData,
    Filter,
    FocusCategory
} from '../../context/tracking/TrackingService';

export function useTrackSliderClick(): (
    href: string,
    contentId: string,
    linkName: string,
    layerName: string | undefined,
    layerLink: boolean,
    contextData: ContextTrackingData,
    filterlist: Filter[],
    itemCount?: number,
    itemPosition?: number,
    focusCategories?: FocusCategory[]
) => void {
    const trackingService = useTrackingService();
    const personalizationContext = usePersonalizationContext();
    const sectionContext = useSectionContext();
    const sectionGroupContext = useSectionGroupContext();
    const bboContex = useBBOContext();
    const metadata = usePersonalizationStore().getMetaData();

    return React.useCallback(
        (
            href,
            contentId,
            linkName,
            layerName,
            layerLink,
            contextData,
            filterlist,
            itemCount?,
            itemPosition?,
            focusCategories?
        ): void => {
            trackingService.trackLinkClick(
                href,
                contentId,
                linkName,
                createContextTrackingData(
                    personalizationContext,
                    metadata,
                    bboContex,
                    sectionContext,
                    sectionGroupContext.sectionGroupContentId,
                    contextData.contentLabels,
                    itemCount,
                    itemPosition
                ),
                {
                    filter: {filterlist},
                    content: {FocusCategories: focusCategories}
                }
            );

            layerLink &&
                trackingService.trackLayerLoad(
                    processContentId(href, layerName),
                    contextData
                );
        },
        [
            trackingService,
            personalizationContext,
            metadata,
            sectionContext,
            sectionGroupContext.sectionGroupContentId,
            bboContex
        ]
    );
}
