import * as React from 'react';
import styled, {css} from 'styled-components';
import {Breakpoints} from '@volkswagen-onehub/components-core';

interface TextOnlyTeaserSectionProps {
    headline?: JSX.Element;
}

const StyledTeaserItemContainer = styled.div<{
    numberOfTeaserItems: number;
    numberOfColumnsOnDesktop: number;
}>`
    --max-columns: 1;

    @media (min-width: ${Breakpoints.b560}px) {
        --max-columns: 2;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        --max-columns: ${props => props.numberOfColumnsOnDesktop};
    }

    --number-of-column-gaps: var(--max-columns);
    --column-gap: ${props => props.theme.size.grid001};

    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--column-gap);
    padding: 0 ${props => props.theme.size.grid002};

    & > * {
        width: ${props => props.theme.size.grid016};
    }

    ${props =>
        props.numberOfTeaserItems > 1 &&
        css`
            & > * {
                width: calc(
                    100% / var(--max-columns) -
                        (var(--column-gap) * var(--number-of-column-gaps)) /
                        var(--max-columns)
                );
            }
        `}
`;
StyledTeaserItemContainer.displayName = 'StyledTeaserItemContainer';

const StyledTextOnlyTeaserSection = styled.div`
    display: grid;
    grid-row-gap: ${props => props.theme.size.dynamic0250};
    justify-items: center;
`;
StyledTextOnlyTeaserSection.displayName = 'StyledTextOnlyTeaserSection';

export const getNumberOfColumnsOnDesktop = (numberOfTeaserItems: number) => {
    if (numberOfTeaserItems % 3 === 0) {
        return 3;
    }

    if (numberOfTeaserItems < 6 && numberOfTeaserItems % 2 === 0) {
        return 2;
    }

    return 3;
};

export const TextOnlyTeaserSection = (
    props: React.PropsWithChildren<TextOnlyTeaserSectionProps>
): JSX.Element => {
    const {headline, children} = props;

    const numberOfTeaserItems = React.Children.count(children);

    return (
        <StyledTextOnlyTeaserSection>
            {headline ? headline : null}
            <StyledTeaserItemContainer
                numberOfTeaserItems={numberOfTeaserItems}
                numberOfColumnsOnDesktop={getNumberOfColumnsOnDesktop(
                    numberOfTeaserItems
                )}
            >
                {children}
            </StyledTeaserItemContainer>
        </StyledTextOnlyTeaserSection>
    );
};
