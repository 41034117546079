import * as React from 'react';

import {CountdownItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {ResponsiveMediaRenderer} from '../../../components/ResponsiveMediaRenderer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {CopyItem} from './CopyItem';

import {UniversalDisclaimerContainer} from '../../../components/disclaimers/UniversalDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {CountdownState} from '../../../context/tracking/TrackingService';
import {CountdownItem as CountdownItemLSG} from '../../../d6/components/premiere/countdown';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {elseUndefined} from '../../../utils/elseUndefined';
import {InternalParsys, ParsysProps, ParsysType} from '../../structure/Parsys';
import {
    PremiereButtonElements,
    premiereResponsiveMediaConfig
} from '../sections/PremiereSection';
import {StyledDisclaimers} from '../../../d6/components/premiere/styles';

export type CountdownItemProps = CountdownItemModel & CountdownItemExtraProps;

export interface CountdownItemExtraProps {
    endTime: number;
}

export const CountdownBulletsParsys = MapTo<ParsysProps>(
    'vwa-ngw18/components/editorial/elements/powerTeaserPromoElement/bulletsParsys',
    InternalParsys
);
const BULLETS_PARSYS_PATH = 'bulletsParsys';

export const CountdownButtonsParsys = MapTo<ParsysProps>(
    'vwa-ngw18/components/editorial/items/countdownItem/buttonsParsys',
    InternalParsys
);
const BUTTONS_PARSYS_PATH = 'buttonsParsys';

const InternalCountdownItem = (props: CountdownItemProps): JSX.Element => {
    const {
        daysLabel,
        hoursLabel,
        minutesLabel,
        secondsLabel,
        isCutout,
        endTime
    } = props;
    const inEditor = C.isInEditor();
    const mediaElements = getChildItems(props.cqItems['media']);
    const isVideo = mediaElements[0]?.model?.coverImage;
    const buttonElements = getChildItems(props.cqItems[BUTTONS_PARSYS_PATH]);
    const hasButtons = inEditor || buttonElements.length > 0;
    const hasBullets =
        inEditor ||
        getChildItems(props.cqItems[BULLETS_PARSYS_PATH]).length > 0;

    const wrappedMediaElement = (
        <ResponsiveMediaRenderer configs={premiereResponsiveMediaConfig}>
            <MediaElement
                path="media"
                containerProps={{matchParent: true}}
                useParentAspectRatio={!isVideo}
                responsiveMediaConfig={premiereResponsiveMediaConfig}
                countdownState={CountdownState.PRE}
            />
        </ResponsiveMediaRenderer>
    );

    const countdownBullets = elseUndefined(
        hasBullets,
        <CountdownBulletsParsys
            key="bullets"
            path={BULLETS_PARSYS_PATH}
            parsysType={ParsysType.ITEM_PARSYS}
        />
    );

    const countdownButtonsParsys = elseUndefined(
        hasButtons,
        <CountdownButtonsParsys
            key="buttons"
            path={BUTTONS_PARSYS_PATH}
            parsysType={ParsysType.INLINE_BUTTON_PARSYS}
        />
    );

    return (
        <AuthoringWrapper
            title="Countdown Item"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <CountdownItemLSG
                    endTime={endTime}
                    isCutout={isCutout}
                    media={wrappedMediaElement}
                    heading={
                        <HeadingElement path="heading" style="H2" order="H2" />
                    }
                    copy={<CopyItem path="copy" />}
                    buttons={
                        inEditor ? (
                            countdownButtonsParsys
                        ) : (
                            <PremiereButtonElements
                                buttons={buttonElements}
                                countdownState={CountdownState.PRE}
                            />
                        )
                    }
                    bullets={countdownBullets}
                    disclaimers={
                        <UniversalDisclaimerContainer
                            customStylingWrapper={StyledDisclaimers}
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    }
                    dayLabel={daysLabel}
                    hourLabel={hoursLabel}
                    minutesLabel={minutesLabel}
                    secondsLabel={secondsLabel}
                />
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
};

export const CountdownItem = MapTo<CountdownItemExtraProps>(
    'vwa-ngw18/components/editorial/items/countdownItem',
    InternalCountdownItem
);
