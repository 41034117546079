import * as React from 'react';
import styled, {css} from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';
import {MoodGallery} from '../../../d6/components/mood-gallery';

import {MediaAspectRatio} from '../../../../generated/api';
import {MoodGallerySectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    MediaContext,
    MediaInteraction,
    VideoInteraction
} from '../../../context/media/MediaContext';
import {useCarouselState} from '../../../hooks/useCarouselState';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {elseUndefined} from '../../../utils/elseUndefined';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {SectionWrapper} from './SectionWrapper';
import {useXOfYLabel} from '../../../components/highlight-feature/HighlightGallery';
import {VideoPlayerV2DisplayMode} from '../../../d6/components/video-player-v2';
import {MoodGalleryMobile} from '../../../d6/components/mood-gallery-mobile';
import {MobileCarouselV2} from '../../../d6/components/mobile-carousel-v2';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

interface StyledMobileWrapperProps {
    showMobile: boolean;
}

export const StyledMobileWrapper = styled.div<StyledMobileWrapperProps>`
    display: block;

    @media (min-width: ${Breakpoints.b1280}px) {
        display: ${props => (props.showMobile ? 'block' : 'none')};
    }
`;
StyledMobileWrapper.displayName = 'StyledMobileWrapper';

const StyledTabletWrapper = styled.div`
    display: none;

    @media (min-width: ${Breakpoints.b1280}px) {
        display: block;
    }
`;
StyledTabletWrapper.displayName = 'StyledTabletWrapper';

// remove extra whitespace under animation element
const animationElementAlignment = css`
    .animationElement {
        margin-bottom: -4px;
    }
`;

const StyledMoodGalleryAuthor = styled.div`
    .mediaElement.cq-Editable-dom {
        padding-bottom: 0;
    }

    .imageElement.cq-Editable-dom {
        margin-bottom: 0;
    }

    ${animationElementAlignment};
`;
StyledMoodGalleryAuthor.displayName = 'StyledMoodGalleryAuthor';

const StyledMoodGallery = styled.div`
    ${animationElementAlignment};
`;
StyledMoodGallery.displayName = 'StyledMoodGallery';

// the sizes attribute for media elements in desktop viewports
const mediaSizes: {[key: number]: string[]} = {
    1: ['100vw'],
    2: ['75vw', '50vw'],
    3: ['66vw', '37vw', '33vw'],
    4: ['66vw', '37vw', '33vw', '25vw'],
    5: ['50vw', '37vw', '25vw', '33vw', '25vw']
};

const StyledItemWrapper = styled.div`
    width: 100%;
`;

function getImages(
    numberOfElements: number,
    setInteractionData: (data: MediaInteraction) => void,
    aspectRatio?: MediaAspectRatio,
    size?: string
): JSX.Element[] {
    const images = [];
    for (let i = 0; i < numberOfElements; i++) {
        images.push(
            <StyledItemWrapper key={i}>
                <MediaContext.Provider
                    value={{
                        ratio: aspectRatio,
                        sizes: size || mediaSizes[numberOfElements][i],
                        setInteractionData
                    }}
                >
                    <MediaElement path={`media_${i}`} />
                </MediaContext.Provider>
            </StyledItemWrapper>
        );
    }

    return images;
}

interface VideoFullscreenHandler {
    showMobileView: boolean;
    setInteractionData(data: MediaInteraction): void;
}

function useVideoFullscreenHandler(): VideoFullscreenHandler {
    const [showMobileView, setShowMobileView] = React.useState(false);
    const setInteractionData = React.useCallback(
        (data: VideoInteraction) => {
            if (data.type === 'VIDEO') {
                const showMobile =
                    data.data.displayMode ===
                    VideoPlayerV2DisplayMode.FULLSCREEN;
                if (showMobileView !== showMobile) {
                    setShowMobileView(showMobile);
                }
            }
        },
        [showMobileView]
    );

    return {showMobileView, setInteractionData};
}

function InternalMoodGallerySection(
    props: MoodGallerySectionModel
): JSX.Element | null {
    const {
        anchorId,
        mboxId,
        translations,
        contentLabels,
        cqItems,
        contentId: sectionId,
        contentName,
        tagTypeBroken
    } = props;
    const [activeItem, handleSlideChange] = useCarouselState(0);

    const {showMobileView, setInteractionData} = useVideoFullscreenHandler();

    const claim: JSX.Element | undefined = elseUndefined(
        C.isInEditor() || !cqItems.heading.empty,
        <HeadingElement path="heading" style="H1" order="H2" />
    );

    const moodGallery = (
        <MoodGallery claim={claim}>
            {getImages(props.numberOfElements, setInteractionData)}
        </MoodGallery>
    );

    const getXOfYLabel = useXOfYLabel(translations['carousel.slideXOfYLabel']);

    // for an author display only desktop version since some items in carousel could be hidden and author would not be able to reach them
    if (C.isInEditor()) {
        return (
            <StyledMoodGalleryAuthor id={anchorId}>
                <AuthoringWrapper
                    anchorId={anchorId}
                    title="S105 Mood Gallery Section"
                    bgColor={AuthoringWrapper.BG_COLOR_SECTION}
                    tagTypeBroken={tagTypeBroken}
                >
                    {moodGallery}
                </AuthoringWrapper>
            </StyledMoodGalleryAuthor>
        );
    }

    return (
        <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
                className="vwa-cms-mood-gallery-module"
            >
                <StyledTabletWrapper>{moodGallery}</StyledTabletWrapper>
                <StyledMobileWrapper showMobile={showMobileView}>
                    <MoodGalleryMobile claim={claim}>
                        <MobileCarouselV2
                            carouselId={`${sectionId}-carousel`}
                            activeSlide={activeItem}
                            onSlideChanged={handleSlideChange}
                            paginationDefaultItemLabel={
                                translations['moodGallery.carouselItemLabel']
                            }
                            getXOfYLabel={getXOfYLabel}
                            galleryLabel={translations['carousel.galleryLabel']}
                            paginationLabel={
                                translations['carousel.gallerySlidesLabel']
                            }
                        >
                            {getImages(
                                props.numberOfElements,
                                setInteractionData,
                                'r_3_4',
                                '100vw'
                            )}
                        </MobileCarouselV2>
                    </MoodGalleryMobile>
                </StyledMobileWrapper>
            </SectionWrapper>
        </GeneralDisclaimerProvider>
    );
}

export const MoodGallerySection = MapTo(
    'vwa-ngw18/components/editorial/sections/moodGallerySection',
    InternalMoodGallerySection
);
