import {
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';

import * as React from 'react';
import {observer} from 'mobx-react-lite';

import {useTrackingService} from '../../../context';
import {
    Degree180Context,
    Degree180Store
} from '../../../context/media/Degree180Store';
import {
    KEYPRESS_TIMELINE_MOVE_DEFAULT,
    KEYPRESS_TIMELINE_MOVE_FASTER
} from '../../../components/draggable-video/DraggableVideo';
import {ArrowKeys} from '../../../d6/components/video-player-helpers/types';
import {MIN_VOLUME_VALUE} from '../../../d6/components/video-player-helpers/constants';
import {hyphens} from '../../../d6/components/helpers';
import {
    HorizontalProgressBar,
    VideoPlayerProgressBarSelectedValue
} from '../../../d6/components/video-player-progress-bar';
import {MediaInteractionElementModel} from '../../../../generated/core';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import styled from 'styled-components';

const SCRUBBER_BASE_SIZE = 16;
const SCRUBBER_DRAGGED_SIZE = 20;

export const getPlayerTimeOnKeyDown = (
    key: ArrowKeys,
    shiftKey: boolean,
    currentTime: number,
    duration: number
): number => {
    const KEYPRESS_TIMELINE_MOVE = shiftKey
        ? KEYPRESS_TIMELINE_MOVE_FASTER
        : KEYPRESS_TIMELINE_MOVE_DEFAULT;
    let updatedPlayerTime = 0;

    if (key === ArrowKeys.ArrowLeft) {
        updatedPlayerTime = currentTime - KEYPRESS_TIMELINE_MOVE;
        updatedPlayerTime = updatedPlayerTime <= 0 ? 0 : updatedPlayerTime;
    }

    if (key === ArrowKeys.ArrowRight) {
        updatedPlayerTime = currentTime + KEYPRESS_TIMELINE_MOVE;
        updatedPlayerTime =
            updatedPlayerTime >= duration ? duration : updatedPlayerTime;
    }

    return updatedPlayerTime;
};

const StyledContainer = styled.div`
    align-items: center;
    display: flex;
    margin-top: ${props => props.theme.size.static350};
    margin-bottom: ${props => props.theme.size.static400};
`;

StyledContainer.displayName = 'StyledContainer';

const StyledTextElement = styled.div`
    display: flex;
    flex: 1;

    ${hyphens}

    &:first-child {
        justify-content: flex-end;
    }

    &:last-child {
        justify-content: flex-start;
    }
`;

StyledTextElement.displayName = 'StyledTextElement';

const StyledInteractionElement = styled.div`
    align-items: center;
    display: flex;
    flex: 2;
    padding: 0 ${props => props.theme.size.static350};
    padding-top: 0;
    height: ${props => props.theme.size.static400};
    width: 100%;
    min-width: calc(
        90px + ${props => props.theme.size.static200 * 2}px
    ); // according to the design
    z-index: 1; // prevents Flyout from overlapping
`;

StyledInteractionElement.displayName = 'StyledInteractionElement';

type MediaInteractionElementExtraProps = Readonly<{
    isShown: boolean;
}>;

export type MediaInteractionElementProps = Partial<
    MediaInteractionElementModel
> &
    MediaInteractionElementExtraProps;

export const MediaInteractionElementComponent = observer(function MIEC({
    isShown,
    startLabel,
    endLabel,
    contentId
}: MediaInteractionElementProps): JSX.Element | null {
    const degree180Store = React.useContext(Degree180Context) as Degree180Store;
    const [trackingCalledTimes, setTrackingCalledTimes] = React.useState(0);
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData();

    if (!isShown) {
        return null;
    }

    const handleDragChanged = (data: VideoPlayerProgressBarSelectedValue) => {
        degree180Store.updateDrag({
            direction: 'none',
            currentTime: data.value
        });

        if (trackingCalledTimes === 0) {
            trackingService.trackSliderElementLoad(
                contentId || 'mediaInteractionElement',
                trackingData
            );
            setTrackingCalledTimes(trackingCalledTimes + 1);
        }
    };

    const handleProgressBarKeyDown = (key: ArrowKeys, shiftKey: boolean) => {
        const updatedPlayerTime = getPlayerTimeOnKeyDown(
            key,
            shiftKey,
            degree180Store.currentTime,
            degree180Store.duration
        );
        handleDragChanged({value: updatedPlayerTime});
    };

    return (
        <StyledContainer>
            <StyledTextElement>
                <Text
                    appearance={TokenTextAppearance.copy200}
                    tag={TextTag.span}
                >
                    {startLabel}
                </Text>
            </StyledTextElement>
            <StyledInteractionElement>
                <HorizontalProgressBar
                    valueMin={MIN_VOLUME_VALUE}
                    valueMax={degree180Store.duration}
                    value={degree180Store.currentTime}
                    fillProgress={false}
                    scrubberBaseSize={SCRUBBER_BASE_SIZE}
                    scrubberDraggedSize={SCRUBBER_DRAGGED_SIZE}
                    onDragChange={handleDragChanged}
                    onProgressBarKeyDown={handleProgressBarKeyDown}
                    enablePulsing
                />
            </StyledInteractionElement>
            <StyledTextElement>
                <Text
                    appearance={TokenTextAppearance.copy200}
                    tag={TextTag.span}
                >
                    {endLabel}
                </Text>
            </StyledTextElement>
        </StyledContainer>
    );
});

export const MediaInteractionElement = MapTo<MediaInteractionElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/interactionElement',
    MediaInteractionElementComponent
);
