import * as React from 'react';
import styled from 'styled-components';

import {AuthoringMediaInfo} from '../../generated/core';

const StyledMediaInfoBox = styled.div`
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: auto;
    height: initial;
    bottom: auto;
    right: 10px;
    top: 20px;
    left: 10px;
    z-index: 10;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
`;

StyledMediaInfoBox.displayName = 'StyledMediaInfoBox';

const StyledMediaInfo = styled.div`
    display: block;
    margin-top: 5px;
`;

const StyledMediaInfoInner = styled.div`
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
`;

const StyledLabel = styled.div`
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
`;

const StyledWarning = styled.div`
    color: salmon;
    font-weight: bold;
    font-size: 12px;
`;

export const AuthoringMediaInfoBox: React.FunctionComponent = props => (
    <StyledMediaInfoBox data-uitest-role="mediainfobox">
        {props.children}
    </StyledMediaInfoBox>
);

export const MediaInfo: React.FunctionComponent<AuthoringMediaInfo> = (
    props
): JSX.Element => (
    <StyledMediaInfo>
        <StyledMediaInfoInner>
            {props.label && <StyledLabel>{props.label}</StyledLabel>}
            {props.warning && (
                <StyledWarning>WARNING: {props.warning}</StyledWarning>
            )}
            {props.info && <div>{props.info}</div>}
        </StyledMediaInfoInner>
    </StyledMediaInfo>
);
