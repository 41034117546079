import * as React from 'react';

export function useOnImageLoad(
    imageRef: React.RefObject<HTMLImageElement | undefined>,
    onLoad: (() => void) | undefined,
    onError: (() => void) | undefined
) {
    const handleLoad = React.useCallback(() => {
        if (onLoad) {
            onLoad();
        }
    }, [onLoad]);

    const handleError = React.useCallback(() => {
        if (onError) {
            onError();
        }
    }, [onError]);

    React.useEffect(() => {
        const img = imageRef.current;
        if (img) {
            if (img.complete) {
                handleLoad();
            } else {
                img.addEventListener('load', handleLoad);
                img.addEventListener('error', handleError);
            }
        }
    }, [handleLoad, handleError, imageRef]);
}
