import * as React from 'react';

import {VideoPlayerV2State} from '../../../../../d6/components/video-player-v2';

import {useTrackingService} from '../../../../../context';
import {VideoPlayerTrackingService} from '../../../../../services/video/VideoPlayerTrackingService';
import {processContentId} from '../../../../../utils/processContentId';
import {VideoStateChangeCallback} from './useVideoStateChangeHandler';
import {useContextTrackingData} from '../../../../../hooks/useContextTrackingData';
import {CountdownState} from '../../../../../context/tracking/TrackingService';

export interface VideoTrackingParams {
    originalCqPath: string;
    contentName: string;
    title: string;
    fileReference: string;
    countdownState?: CountdownState;
}

type MilestoneInitCallback = (
    e: React.SyntheticEvent<HTMLVideoElement>
) => void;

export interface VideoTrackingCallbacks {
    onVideoStateChange: VideoStateChangeCallback;
    onMilestoneInit?: MilestoneInitCallback;
    onPlay(): void;
    onPause(): void;
    onAutoPause(): void;
    onFullScreenEntered(): void;
    onFullScreenExited(): void;
    onMute(): void;
    onUnmute(): void;
}

function useVideoPlayerTrackingService(
    params: VideoTrackingParams
): VideoPlayerTrackingService {
    const {
        originalCqPath,
        contentName,
        title,
        fileReference,
        countdownState
    } = params;
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData(
        null,
        undefined,
        undefined,
        countdownState
    );

    const service = React.useRef(
        new VideoPlayerTrackingService(
            processContentId(originalCqPath, contentName),
            title,
            fileReference,
            trackingData,
            trackingService
        )
    );

    return service.current;
}

export function useVideoTracking(
    params: VideoTrackingParams
): VideoTrackingCallbacks {
    const videoTrackingService = useVideoPlayerTrackingService(params);

    const onFullScreenEntered = React.useCallback(() => {
        videoTrackingService.trackFullScreenEntered();
    }, [videoTrackingService]);

    const onFullScreenExited = React.useCallback(() => {
        videoTrackingService.trackFullScreenExited();
    }, [videoTrackingService]);

    const onMute = React.useCallback(() => {
        videoTrackingService.trackMute();
    }, [videoTrackingService]);

    const onUnmute = React.useCallback(() => {
        videoTrackingService.trackUnmute();
    }, [videoTrackingService]);

    const onPause = React.useCallback(() => {
        videoTrackingService.trackPauseClick();
    }, [videoTrackingService]);

    const onAutoPause = React.useCallback(() => {
        videoTrackingService.trackAutomaticPause();
    }, [videoTrackingService]);

    const onPlay = React.useCallback(() => {
        videoTrackingService.trackPlayClick();
    }, [videoTrackingService]);

    const onMilestoneInit = React.useCallback(
        (e: React.SyntheticEvent<HTMLVideoElement>) => {
            videoTrackingService.initMilestones(e);
        },
        [videoTrackingService]
    );

    const onVideoStateChange = React.useCallback(
        (state: VideoPlayerV2State, startedByIO: boolean) => {
            videoTrackingService.trackVideoStateChange(state, startedByIO);
        },
        [videoTrackingService]
    );

    return {
        onFullScreenEntered,
        onFullScreenExited,
        onPause,
        onAutoPause,
        onPlay,
        onVideoStateChange,
        onMilestoneInit,
        onMute,
        onUnmute
    };
}
