import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import React from 'react';
import {DisplayType} from '../../../../../generated/core';
import {BellowItemDisclaimerContainer} from '../../../../components/disclaimers/BellowItemDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../../context/disclaimer/GeneralDisclaimerProvider';
import {HeadingElement} from '../../elements/HeadingElement';
import {CopyItem} from '../../items/CopyItem';

const StyledIntro = styled.div`
    --outer-columns: ${props => props.theme.size.grid002};
    --inner-columns: 0;

    display: grid;
    grid-template-columns:
        var(--outer-columns) var(--inner-columns) 1fr var(--inner-columns)
        var(--outer-columns);
    grid-auto-rows: max-content;
    row-gap: var(--size-dynamic0100);

    & > * {
        grid-column: 3/4;
    }

    & > h2 {
        grid-column: 2/5;
    }

    @media (min-width: ${Breakpoints.b560}px) {
        --outer-columns: ${props => props.theme.size.grid005};
        --inner-columns: ${props => props.theme.size.grid001};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        --outer-columns: ${props => props.theme.size.grid006};
    }
`;

export const Intro = (props: {
    showHeadline: boolean;
    showCopy: boolean;
}): JSX.Element | null => {
    const {showHeadline, showCopy} = props;
    const disclaimerTypes: DisplayType[] = ['T4_ITEM_BASED'];

    if (!showHeadline && !showCopy) {
        return null;
    }

    return (
        <GeneralDisclaimerProvider displayTypes={disclaimerTypes}>
            <StyledIntro>
                {showHeadline && (
                    <HeadingElement
                        path="heading"
                        hideItemDisclaimers
                        order="H2"
                    />
                )}
                {showCopy && <CopyItem path="copy" hideItemDisclaimers />}
                <BellowItemDisclaimerContainer displayTypes={disclaimerTypes} />
            </StyledIntro>
        </GeneralDisclaimerProvider>
    );
};
