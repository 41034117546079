import * as React from 'react';

import {MapTo} from '../../../../../infrastructure/compatibility/MapTo';
import {MediaElement} from '../../MediaElement';
import {BaseCellProps, StyledBaseCell} from './EmptyCellElement';

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/imageCellElement';

class ImageCellElementComponentClass extends React.PureComponent<
    BaseCellProps
> {
    public render(): React.ReactNode {
        return (
            <StyledBaseCell isNarrow={this.props.isNarrow}>
                <MediaElement path="mediaElement" />
            </StyledBaseCell>
        );
    }
}

export const ImageCellElement = MapTo(
    RESOURCE_TYPE,
    ImageCellElementComponentClass
);
