import {
    AnchorLinkModel,
    ExternalLinkModel,
    TeaserElementModel
} from '../../../generated/core';
import {NavigationTopBarScrollDirection} from './NavigationTopBarScrollDirection';

export const NavigationStoreId = 'NavigationStore';

export interface InPageMenuItemModel {
    readonly id: string;
    readonly index: number;
    readonly title: string;
    readonly url: string;
    readonly visible?: boolean;
}

export interface NavigationStore {
    readonly isTopBarAboveStage: boolean;
    readonly lastScrollDirection: NavigationTopBarScrollDirection;
    readonly activeInPageNavigationItem: InPageMenuItemModel | undefined;
    readonly activeInPageNavigationItemTitle: string;
    readonly activeInPageNavigationItemUrl: string;
    readonly activeInPageNavigationItemId: string | undefined;
    readonly activeSectionId: string | undefined;
    readonly activeSectionGroupId: string | undefined;
    readonly inPageNavigationItems: InPageMenuItemModel[];
    readonly visibleInPageNavigationItems: InPageMenuItemModel[];
    readonly inPageNavigationType: string | undefined;
    readonly isFlyoutMenuOpen: boolean;
    readonly isInPageNavigationVisible: boolean;
    readonly isInPageNavigationOpen: boolean;
    readonly isMenuLabelVisible: boolean;
    readonly isAnnouncementBarVisible: boolean;
    readonly getAnnouncementBarHeight: number;
    navFlyoutTeaserPath: string;
    navFlyoutTeaserSecondLevelPath: string;

    /**
     * Updates scroll direction.
     */
    updateScrollPositionOnAnimationFrame(): void;

    /**
     * In browser environment use exclusively `updateScrollPositionOnAnimationFrame`.
     *
     * @param scrollPosition
     * @param windowHeight
     * @see updateScrollPositionOnAnimationFrame
     */
    updateScrollPosition(scrollPosition: number, windowHeight: number): void;

    openFlyoutMenu(): void;

    closeFlyoutMenu(): void;

    openInPageNavigation(): void;

    closeInPageNavigation(): void;

    toggleInPageNavigation(): void;

    setActiveInPageNavigationItem(id?: string): void;

    setInPageNavIntersectionItem(id: string, sectionGroupId?: string): void;

    setInPageItemsVisibility(ids: string[], visibility: boolean): void;

    isInPageItemVisible(id: string): boolean;

    removeInPageNavIntersectionItem(id: string): void;

    setActiveSection(sectionId?: string, sectionGroupId?: string): void;

    /**
     * lock the scrolling behavior before executing the action (e.g. click on in page nav link) and unlock afterwards (time based)
     * @param callback
     */
    withScrollLock(callback: () => void): void;

    setInPageNavigationItems(
        items?: AnchorLinkModel[] | ExternalLinkModel[] | null
    ): void;

    setInPageNavigationItem(
        item: AnchorLinkModel | ExternalLinkModel,
        index: number,
        originalId: string
    ): void;

    setInPageNavigationType(type?: string): void;

    onLoad(): void;

    setStageHeight(height?: number): void;

    setSecondLevelTeaserData(teaser: TeaserElementModel): void;

    onBackButtonClick(): boolean;

    setAnnouncementBarHeight(height: number): void;
}
