import {FeatureAppContainer} from '@feature-hub/react';
import {styled} from '@volkswagen-onehub/components-core';
import featureAppDefinition from '@volkswagen-onehub/notifications-feature-app';
import * as React from 'react';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {ZIndex} from '../../utils/zIndex';

const NotificationsWrapper = styled.div`
    z-index: ${ZIndex.notifications.z};
    position: relative;
`;

export function FeatureAppNotifications(): JSX.Element {
    return (
        <CyAttributeAppender name="notificatiosWrapper">
            <NotificationsWrapper>
                <FeatureAppContainer
                    featureAppDefinition={featureAppDefinition}
                    featureAppId="s2:notifications"
                />
            </NotificationsWrapper>
        </CyAttributeAppender>
    );
}
