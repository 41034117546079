import {Accordion, Text} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {AccordionItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {useTrackingService} from '../../../context';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {RichtextFullElement} from '../elements/RichtextElement';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {HeadingElement} from '../elements/HeadingElement';

const heading = (
    <Text bold>
        <HeadingElement path="heading" style="H3" order="H3" />
    </Text>
);

const copy = <RichtextFullElement path="copy" />;

export enum AccordionGroupState {
    click = 'open by click',
    deepLink = 'open per default'
}

export interface AccordionOpeningType {
    groupItemState: AccordionGroupState;
}

export interface AccordionItemExtraProps extends AccordionItemModel {
    readonly forceOpen: boolean;
}

function InternalAccordionItem(props: AccordionItemExtraProps): JSX.Element {
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData();

    const {anchorId, contentId, forceOpen, label, defaultOpen} = props;
    const [isOpen, setIsOpen] = React.useState(false);

    function onItemClick(): void {
        setIsOpen(!isOpen);
        trackingService.trackAccordionClickV2({
            id: contentId,
            openClick: !isOpen,
            url: window.location.pathname,
            linkName: label,
            contextData: trackingData,
            accordion: {groupItemState: AccordionGroupState.click}
        });
    }

    React.useEffect(() => {
        if (!Boolean(defaultOpen)) {
            return;
        }
        setIsOpen(defaultOpen);
        trackingService.trackAccordionClickV2({
            id: contentId,
            openClick: defaultOpen,
            url: window.location.pathname,
            linkName: label,
            contextData: trackingData,
            accordion: {groupItemState: AccordionGroupState.deepLink}
        });
        // *should only render once*
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (forceOpen && isOpen !== forceOpen) {
            setIsOpen(forceOpen);
        }
    }, [isOpen, forceOpen]);

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
            title="I117 Accordion Item"
        >
            <Accordion
                id={anchorId}
                isOpen={C.isInEditor() || isOpen}
                headline={heading}
                onClick={onItemClick}
            >
                {copy}
            </Accordion>
        </AuthoringWrapper>
    );
}

export const AccordionItem = MapTo<Partial<AccordionItemExtraProps>>(
    'vwa-ngw18/components/editorial/items/accordionItem',
    InternalAccordionItem
);
