import * as React from 'react';
import {ButtonElementModel} from '../../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../../components/AlertBox';
import {getElement} from './getElement';
import {BasicStageSectionProps} from './BasicStageSection';

export const SECONDARY_STYLE_MUSNT_BE_PRIMARY =
    'secondary button should not use button style primary.';
export const PRIMARY_STYLE_MUSNT_BE_SECONDARY =
    'primary button should not use button style secondary.';
export function AuthorAlerts(props: BasicStageSectionProps) {
    const messages = [];
    const primaryButton = getElement<ButtonElementModel>(
        props.cqItems,
        'primaryButton'
    );
    const secondaryButton = getElement<ButtonElementModel>(
        props.cqItems,
        'secondaryButton'
    );
    if (
        props.showSecondaryButton &&
        secondaryButton &&
        secondaryButton.buttonStyle === 'primary'
    ) {
        messages.push(SECONDARY_STYLE_MUSNT_BE_PRIMARY);
    }
    if (
        props.showPrimaryButton &&
        primaryButton &&
        primaryButton.buttonStyle === 'secondary'
    ) {
        messages.push(PRIMARY_STYLE_MUSNT_BE_SECONDARY);
    }

    return (
        <AlertBox severity={AlertBoxSeverity.WARNING}>
            {messages.map(m => (
                <p key={m}>{m}</p>
            ))}
        </AlertBox>
    );
}
