import * as React from 'react';

import {Text, TokenTextAppearance} from '@volkswagen-onehub/components-core';

export interface VideoPlayerTimeProps {
    readonly timeInSeconds?: number;
}

const TIME_PLACEHOLDER = '--:--';
const SECONDS_IN_MINUTE = 60;

const formatSeconds = (seconds: number) =>
    seconds < 10 ? `0${seconds.toFixed(0)}` : seconds.toFixed(0);

const formatMinutes = (minutes: number) => minutes.toFixed(0);

export const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / SECONDS_IN_MINUTE);
    const seconds = Math.round(timeInSeconds % SECONDS_IN_MINUTE);

    return `${formatMinutes(minutes)}:${formatSeconds(seconds)}`;
};

export const VideoPlayerTime: React.FunctionComponent<VideoPlayerTimeProps> = props => {
    const {timeInSeconds} = props;

    const time =
        timeInSeconds === undefined
            ? TIME_PLACEHOLDER
            : formatTime(timeInSeconds);

    return <Text appearance={TokenTextAppearance.copy100}>{time}</Text>;
};
