import {
    AdobeTargetService,
    MboxedOffers
} from '../adobeTarget/AdobeTargetService';
import {Logger} from '../logger/Logger';
import {
    MetaData,
    Profile,
    ProfileV2,
    SmartDigitalService
} from '../smartDigital/SmartDigitalService';
import {isInVec} from '../../utils/personalization/isInVec';
import {FeatureTogglesModel} from '../../../generated/core';

export async function loadProfile(
    smartDigitalService: SmartDigitalService,
    logger: Logger,
    pagePath: string
): Promise<ProfileV2> {
    return smartDigitalService
        .getLocalProfile(pagePath)
        .catch((e: Error | string) => {
            const message = typeof e === 'string' ? e : e.message;
            logger.personalization.warn(
                'Unable to load 1:1 profile: ',
                message
            );

            return {personalization: {}, metadata: {} as MetaData};
        });
}

export async function loadOffers(
    adobeTargetService: AdobeTargetService,
    localProfile: Profile | undefined,
    mboxes: string[],
    logger: Logger
): Promise<MboxedOffers[]> {
    return adobeTargetService
        .getOffers(mboxes, localProfile)
        .catch((e: Error | string) => {
            const message = typeof e === 'string' ? e : e.message;
            logger.personalization.warn('Unable to load 1:1 offers: ', message);

            return [];
        });
}

export function isOptIn(toggles: FeatureTogglesModel): boolean {
    if (toggles.enableConsentCheckForPersonalization) {
        // this is the way that vw bootstrapper library works, right now, there is not any proper function call
        // @ts-ignore
        const {vwdata} = window;
        if (!vwdata || !vwdata.consent) {
            return true;
        }
        return vwdata.consent.getPersonalizationStatus() === 1;
    }
    return true;
}

export function isVecOrNotOptIn(toggles: FeatureTogglesModel): boolean {
    return !isOptIn(toggles) || isInVec();
}
