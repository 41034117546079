import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    designTokens
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from 'styled-components';

export enum AlertBoxSeverity {
    ERROR,
    WARNING,
    INFO
}

export interface AlertBoxProps {
    readonly severity?: AlertBoxSeverity;
}

export const severityTextColorMap = {
    [AlertBoxSeverity.ERROR]: designTokens.color['white-000'],
    [AlertBoxSeverity.WARNING]: designTokens.color['black-000'],
    [AlertBoxSeverity.INFO]: designTokens.color['white-000']
};

export const severityBackgroundColorMap = {
    [AlertBoxSeverity.ERROR]: designTokens.color['red-100'],
    [AlertBoxSeverity.WARNING]: designTokens.color['yellow-000'],
    [AlertBoxSeverity.INFO]: designTokens.color['blue-200']
};

interface StyledAlertBoxProps {
    readonly severity: AlertBoxSeverity;
}

const StyledAlertBox = styled.div<StyledAlertBoxProps>`
    background-color: ${props => severityBackgroundColorMap[props.severity]};
    color: ${props => severityTextColorMap[props.severity]};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

StyledAlertBox.displayName = 'StyledAlertBox';

export const AlertBox: React.FunctionComponent<AlertBoxProps> = (
    props
): JSX.Element => (
    <StyledAlertBox severity={props.severity || AlertBoxSeverity.ERROR}>
        <Container
            padding={ContainerPadding.dynamic0100}
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.dynamic0020}
        >
            {props.children}
        </Container>
    </StyledAlertBox>
);
