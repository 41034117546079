import * as React from 'react';
import ReactDOM from 'react-dom';

// Only needed for styled components (3 | 4) compatibility
// Wrap a ref into styledCompatRef to let the wrapped ref always
// point to the underlying DOM node.
// Output can be plugged into the "ref" prop and reflects v3 `innerRef` or v4 `ref` behaviour.
// The type of the function is made to be compatible with v4
export function styledCompatRef<T = Element>(ref?: React.Ref<T>): React.Ref<T> {
    return (element: T | null) => {
        if (ref) {
            let node = element;
            if (element) {
                // In styled components v3 the element is the Styled Component Wrapper Instance.
                // In styled components v4 the element is the DOM Node (HTMLElement, SVGElement).
                // That's why we need the forwarding in the first place.
                node = ReactDOM.findDOMNode(
                    (element as unknown) as React.ReactInstance
                ) as null | T;
            }

            if ('current' in ref) {
                // actually never readonly.
                // @ts-ignore
                ref.current = node;
            } else {
                ref(node);
            }
        }
    };
}

// optional memo optimization when hooks can be used
export const useStyledCompatRef: typeof styledCompatRef = ref =>
    React.useMemo(() => styledCompatRef(ref), [ref]);
