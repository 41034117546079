import {ConfigurableConsole} from '../context/logger/ConfigurableConsole';
/**
 * uncaught errors have no log level attached. Here is the level mapping for known issues. Needs to be kept up-to-date.
 */
const levelMapping: {[message: string]: ConsoleMethods} = {
    'Script error': 'info', // this is usually a user in dev tools
    'ResizeObserver loop limit exceeded': 'warn', // doesn't impact functionality.
    'ResizeObserver loop completed with undelivered notifications': 'warn' // doesn't impact functionality
};

type ConsoleMethods = 'error' | 'warn' | 'info';

export function assignLoggerForErrorEvents(
    uncaughtLogger: ConfigurableConsole
): void {
    window.addEventListener('error', e => {
        const method: ConsoleMethods =
            Object.keys(levelMapping)
                .filter(key => e.message.includes(key))
                .map(key => levelMapping[key])
                .find(m => !!m) || 'error';
        if (Boolean(e.error)) {
            uncaughtLogger[method]('Uncaught Error', e.error);
        } else {
            uncaughtLogger[method](
                'Uncaught Error: %s \nat <%s>%s:%s',
                e.message,
                e.filename,
                e.lineno,
                e.colno
            );
        }
    });
}
