import {TokenTextAppearance} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {ScrollytellingSectionModel} from '../../../../../generated/core';
import {useTrackingService} from '../../../../context';
import {
    ContextTrackingData,
    VideoPlayerData
} from '../../../../context/tracking/TrackingService';
import {useContextTrackingData} from '../../../../hooks/useContextTrackingData';
import {ContainerItem} from '../../../../infrastructure/ContainerExporter';
import {getChildItems} from '../../../../utils/container/getContainerChildItems';
import {HeadingElement} from '../../elements/HeadingElement';
import {CopyItem} from '../../items/CopyItem';
import {IntroOutroAnimations} from './IntroOutroAnimation';
import {ScrollLogicProvider} from './ScrollLogic';
import {SkipButton} from './SkipButton';
import {ScrollableVideoElement} from './Video';
import {millisecondsToHHMMSS} from './helper';

export const PATH_SECTION_PARSYS = 'scrollytellingSectionParsys';

const isNotEmpty = (entry: ContainerItem) => {
    const showHeadline = !Boolean(entry.model.cqItems?.heading?.empty);
    const showCopy = !Boolean(entry.model.cqItems?.copy?.empty);

    return showHeadline || showCopy;
};

export type VideoTrackingData = {
    videoData: VideoPlayerData;
    contextTrackingData: ContextTrackingData;
};

const useVideoTrackingData = (
    props: ScrollytellingSectionModel
): VideoTrackingData => {
    const contextTrackingData = useContextTrackingData();
    const {title, fileReference} = getChildItems(
        props.cqItems['media']
    )[0].model;

    return {
        videoData: {
            playerName: 'scrollytelling-video-player',
            title,
            id: `scrollytelling-video-player-${contextTrackingData.sectionId}`,
            platform: 'aem',
            url: fileReference
        },
        contextTrackingData
    };
};

export interface ContentBlock {
    headline: JSX.Element;
    copy: JSX.Element;
    start: number;
    end: number;
    id: string;
    track: VoidFunction;
}

export const ScrollableVideo = (props: ScrollytellingSectionModel) => {
    const {duration, cqItems, skipLabel, contentId} = props;
    const trackingService = useTrackingService();
    const {videoData, contextTrackingData} = useVideoTrackingData(props);
    const alreadyTrackedItems = React.useRef(new Set<number>());

    const computedContent = getChildItems(cqItems[PATH_SECTION_PARSYS])
        .filter(isNotEmpty)
        .map(({key, model}, index, array) => ({
            id: key,
            headline: (
                <HeadingElement
                    path={`${PATH_SECTION_PARSYS}/${key}/heading`}
                    order="H3"
                    hideItemDisclaimers
                />
            ),
            copy: (
                <CopyItem
                    path={`${PATH_SECTION_PARSYS}/${key}/copy`}
                    appearance={TokenTextAppearance.copy200}
                    hideItemDisclaimers
                />
            ),
            start: model.start / duration,
            end: model.end / duration,
            track() {
                if (alreadyTrackedItems.current.has(index)) {
                    return;
                }
                trackingService.trackElementSwipe(key, videoData, {
                    ...contextTrackingData,
                    itemCount: array.length,
                    itemPosition: index + 1
                });
                alreadyTrackedItems.current.add(index);
            }
        }));

    const trackSkipButtonClick = (progress: number) => {
        trackingService.trackSkipButtonClick(
            contentId,
            {
                ...videoData,
                length: millisecondsToHHMMSS(duration),
                timeplayed: millisecondsToHHMMSS(duration * progress)
            },
            contextTrackingData
        );
    };

    const skipButton = (
        <SkipButton
            skipButtonLabel={skipLabel}
            trackOnClick={trackSkipButtonClick}
        />
    );

    return (
        <ScrollLogicProvider {...props}>
            <IntroOutroAnimations>
                <ScrollableVideoElement
                    content={computedContent}
                    skipButton={skipButton}
                />
            </IntroOutroAnimations>
        </ScrollLogicProvider>
    );
};
