import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';
import {getStartDirection} from '../helpers';

const StyledCustomContainer = styled.div`
    display: flex;
    @media (max-width: ${Breakpoints.b560}px) {
        flex-direction: column;
    }
`;

StyledCustomContainer.displayName = 'ArticleMetaInfo.StyledCustomContainer';

const StyledCustomChildWrapper = styled.div`
	@media (min-width: ${Breakpoints.b560}px) {
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static200};
		margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static200};
		border-${props =>
            getStartDirection(props.theme.direction)}: 1px solid ${props =>
    props.theme.text.color.primary};
	}
	@media (max-width: ${Breakpoints.b560}px) {
		margin-top: ${props => props.theme.size.static150};
	}
`;

StyledCustomChildWrapper.displayName =
    'ArticleMetaInfo.StyledCustomChildWrapper';

export const ArticleMetaInfo: React.FunctionComponent<React.PropsWithChildren<{}>> = props => {
    const childElements = React.Children.toArray(props.children).filter(
        React.isValidElement
    );

    return (
        <StyledCustomContainer>
            {React.Children.map(childElements, (child, index) =>
                index > 0 ? (
                    <StyledCustomChildWrapper>{child}</StyledCustomChildWrapper>
                ) : (
                    <div>{child}</div>
                )
            )}
        </StyledCustomContainer>
    );
};

ArticleMetaInfo.displayName = 'ArticleMetaInfo';
