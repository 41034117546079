import {
    singleton,
    inject,
    postConstruct
} from '../../infrastructure/di/annotations';
import * as React from 'react';
import {PersistentStorageService} from '../persistentStorage/PersistentStorageService';
import {
    LayerManagerV2,
    FocusLayerSizeV2,
    LayerHandleV2
} from '@volkswagen-onehub/layer-manager';
import {LoginStore} from '../vwid/LoginStore';
import {
    IdkServiceConnector,
    UpdateMergeConsent,
    MergeConsentStatus
} from '../vwid/IdkServiceConnector';
import {when} from 'mobx';
import {
    ConsentModel,
    ConsentConfiguration,
    SpaGlobalConfig
} from '../../../generated/core';
import {Logger} from '../logger/Logger';
import {ConsentLayer} from './ConsentLayer';
import {SmartDigitalService} from '../smartDigital/SmartDigitalService';
import {random} from '../../utils/random';

const SESSION_KEY = 'consent.shown';

@singleton('ConsentService', {env: 'client'})
export class ConsentService {
    @inject()
    private sessionStorage!: PersistentStorageService;
    @inject()
    private layerManager!: LayerManagerV2;
    @inject()
    private consentConfiguration!: ConsentConfiguration;
    @inject()
    private loginStore!: LoginStore;
    @inject()
    private logger!: Logger;
    @inject()
    private consentModel!: ConsentModel;
    @inject()
    private idkServiceConnector!: IdkServiceConnector;
    @inject()
    private smartDigitalService!: SmartDigitalService;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    private alreadyShown: boolean = false;

    @postConstruct()
    initialize() {
        this.alreadyShown = Boolean(this.sessionStorage.get(SESSION_KEY));
        // The event AuthServiceEvent.LOGIN is never fired, so we rely on AuthService.isAuthenticated() here. This will be fired each time the user logs in or reloads the page when logged in.
        when(
            () =>
                this.loginStore.isLoggedIn && this.loginStore.userData != null,
            async () => {
                try {
                    await this.afterLoginDetermined();
                } catch (e) {
                    this.logger.consent.error(
                        'failed to show consent after login',
                        e
                    );
                }
            }
        );
        this.loginStore.listenToLogout(async () => {
            this.alreadyShown = false;
            this.sessionStorage.remove(SESSION_KEY);
        });
    }

    private consentLayer: LayerHandleV2<{}> | null = null;

    private closeConsentLayer = (): void => {
        if (this.consentLayer) {
            this.consentLayer.close();
        }
        this.consentLayer = null;
    };

    private async afterLoginDetermined(): Promise<void> {
        if (!this.spaGlobalConfig.loginModel.mergeConsentEnabled) {
            return;
        }
        if (this.alreadyShown) {
            return;
        }
        const consent = await this.idkServiceConnector.getMergeConsentStatus();
        if (this.isConsentDecided(consent)) {
            this.logger.consent.debug(
                'consent was decided before %',
                consent.userDecision
            );
            const userId = this.loginStore.userData!.sub;
            if (userId) {
                this.trackConsentGiven(
                    consent.userDecision || false,
                    userId,
                    consent
                );
            }
            return;
        }
        // don't repeat the following steps again
        this.alreadyShown = true;
        this.sessionStorage.set(SESSION_KEY, 'true');
        if (this.showConsent()) {
            const createHandler = (userDecision: boolean) => {
                return async () => {
                    const update: UpdateMergeConsent = {
                        documentKey: consent.documentKey,
                        version: consent.version,
                        locale: consent.locale,
                        userDecision
                    };
                    this.closeConsentLayer();
                    try {
                        await this.idkServiceConnector.setMergeConsentStatus(
                            update
                        );
                        const userId = this.loginStore.userData!.sub;
                        this.trackConsentGiven(userDecision, userId, consent);
                    } catch (e) {
                        this.logger.consent.error(
                            'failed to update merge consent',
                            e
                        );
                    }
                };
            };

            if (this.consentLayer) {
                this.closeConsentLayer();
            }

            this.consentLayer = this.layerManager.openFocusLayer(
                _state => {
                    return (
                        <ConsentLayer
                            acceptLabel={this.consentModel.mergeConsentAccept}
                            declineLabel={this.consentModel.mergeConsentDecline}
                            disclaimer={consent.disclaimer}
                            text={consent.text}
                            title={consent.title}
                            accept={createHandler(true)}
                            decline={createHandler(false)}
                            onClose={this.closeConsentLayer}
                            btnCloseLabel={this.consentModel.btnCloseLabel}
                        />
                    );
                },
                {},
                {
                    onClose: this.closeConsentLayer,
                    userCloseable: true,
                    size: FocusLayerSizeV2.A
                }
            );
        }
    }

    private trackConsentGiven(
        userDecision: boolean,
        userId: string,
        consent: MergeConsentStatus
    ) {
        if (userDecision) {
            this.smartDigitalService.pushConsentAccepted(
                userId,
                String(consent.version)
            );
        }
    }

    private showConsent(): boolean {
        const {showConsentProbability} = this.consentConfiguration;
        const dice = random() * 100;
        return showConsentProbability > 0 && dice <= showConsentProbability;
    }
    private isConsentDecided(consent: MergeConsentStatus): boolean {
        return (
            consent &&
            consent.userDecision !== null &&
            typeof consent.userDecision !== 'undefined'
        );
    }
}
