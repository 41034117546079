import {ProvidedExternals} from '@feature-hub/core';
import {Externals} from '@feature-hub/module-loader-amd';
import * as featureHubReact from '@feature-hub/react';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as styledComponents from 'styled-components';
import packageJson from '../../../package.json';

interface DependenciesJSONObject {
    [key: string]: string;
}

export const providedExternalsImports: Externals = {
    react: React,
    'react-dom': ReactDom,
    '@feature-hub/react': featureHubReact,
    'styled-components': styledComponents
};

export const providedExternalsVersions: ProvidedExternals = Object.keys(
    providedExternalsImports
).reduce<ProvidedExternals>(
    (versions: {[key: string]: string}, key: string): ProvidedExternals => {
        versions[key] = (packageJson.dependencies as DependenciesJSONObject)[
            key
        ];

        return versions;
    },
    {}
);
