import {observer} from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import {
    LanguageSwitcher as LsgLanguageSwitcher,
    LanguageSwitcherItem
} from '../../../d6/components/language-switcher-onehub';

import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {useContentStore, useTrackingService} from '../../../context';
import {mapLanguageLinks} from './mapLanguageLinks';

const StyledNav = styled.nav``;

export interface LanguageLinkProps {
    readonly linkHref: string;
    readonly locale: string;
    readonly currentLink?: string;
    readonly title?: string;
    readonly lang: string;
    readonly ariaLabel?: string;
}

function LanguageLink(props: LanguageLinkProps): JSX.Element {
    const {linkHref, locale, currentLink, lang} = props;

    const text = locale.toUpperCase();
    const trackingDPAndKey = `one_hub_language_switch_${locale}`;
    const isActive = Boolean(currentLink && linkHref === currentLink);
    const trackingService = useTrackingService();

    const onClick = React.useCallback(() => {
        trackingService.trackQuickAccessLinkClick(linkHref, text);
    }, [trackingService, linkHref, text]);

    return (
        <LanguageSwitcherItem isActive={isActive}>
            <CmsTextLink
                {...props}
                key={trackingDPAndKey}
                href={linkHref}
                ignoreExternalLinkLightbox
                trackingActionOverride={onClick}
            >
                {lang}
            </CmsTextLink>
        </LanguageSwitcherItem>
    );
}

const LanguageSwitcher: React.FunctionComponent = observer(function LS() {
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();

    if (!pageRootModel || !pageRootModel.languageSwitcherModel) {
        return null;
    }

    return (
        <StyledNav aria-label={pageRootModel.languageSwitcherModel.navLabel}>
            <LsgLanguageSwitcher>
                {mapLanguageLinks(
                    pageRootModel.languageSwitcherModel,
                    LanguageLink
                )}
            </LsgLanguageSwitcher>
        </StyledNav>
    );
});
LanguageSwitcher.displayName = 'LanguageSwitcher';
export {LanguageSwitcher};
