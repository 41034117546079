import * as React from 'react';
import {
    QueryResults,
    useMatchMediaWithDefaultQueries,
    emptyGlobalQueries
} from './useMatchMedia';
import {isInBrowser} from '../../utils/browser/isInBrowser';

const MediaQueryContext = React.createContext<QueryResults | null>(null);

interface MediaQueryContextProps {
    children: React.ReactNode;
}

export function MediaQueryContextProvider({
    children
}: MediaQueryContextProps): JSX.Element {
    const isBrowser = isInBrowser();
    const mediaQueries = useMatchMediaWithDefaultQueries();

    return isBrowser ? (
        <MediaQueryContext.Provider value={mediaQueries}>
            {children}
        </MediaQueryContext.Provider>
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
}

export function useMediaQueries() {
    const context = React.useContext(MediaQueryContext);
    return context || emptyGlobalQueries;
}

export function useMediaQueriesWithoutDefault() {
    return React.useContext(MediaQueryContext);
}
