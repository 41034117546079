import * as React from 'react';
import ReactDOM from 'react-dom';
import styled, {css} from 'styled-components';
import {buttonFocusStateCSS, resetButtonStyles} from '../helpers';

import {
    DownloadTranscript,
    EnterFullscreen,
    ExitFullscreen,
    Pause,
    Play
} from '@volkswagen-onehub/icons-core';

import {TOUCH_START_VS_END_MAX_PIXEL_DIFF} from './constants';

type VideoButtonProps = Readonly<{
    disabled?: boolean;
    dataCy?: string;
    title?: string;
    linkReference?: string;
    download?: boolean;
    onClick?(): void;
}>;

type BigPlayButtonProps = Readonly<{
    title?: string;
}>;

const svgButtonHover = css`
    svg {
        path {
            fill: ${props => props.theme.colors.button.primary.default};
        }
        @media (hover: hover) {
            &:hover {
                path {
                    fill: ${props => props.theme.colors.button.primary.hover};
                }
            }
        }
    }
`;

const StyledSmallControlButton = styled.button`
    ${resetButtonStyles}
    ${props => buttonFocusStateCSS(props)}

	display: block;
    width: ${props => props.theme.size.static350};
    height: ${props => props.theme.size.static350};
    cursor: pointer;
    ${svgButtonHover}
    position: relative;
`;
StyledSmallControlButton.displayName = 'StyledSmallControlButton';

const StyledSmallControlLink = styled.a`
    ${props => buttonFocusStateCSS(props)}

    display: block;
    width: ${props => props.theme.size.static350};
    height: ${props => props.theme.size.static350};
    cursor: pointer;
    ${svgButtonHover}
    position: relative;
`;
StyledSmallControlLink.displayName = 'StyledSmallControlLink';

// @todo: temporary solution, design tokens for background and icons to be defined, passed to designers
const StyledBigControlButton = styled.button`
    ${resetButtonStyles}
    ${props => buttonFocusStateCSS(props)}
	background: rgba(0, 0, 0, 0.55);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${props => props.theme.size.static450};
    height: ${props => props.theme.size.static450};
    z-index: 1;
    cursor: pointer;
    ${svgButtonHover}
`;
StyledBigControlButton.displayName = 'StyledBigControlButton';

export const BigPlayButton: React.FunctionComponent<BigPlayButtonProps> = (
    props: BigPlayButtonProps
) => (
    <StyledBigControlButton
        data-cy="videoBigPlayButton"
        title={props.title}
        aria-label={props.title}
    >
        <Play variant="default" />
    </StyledBigControlButton>
);

class SmallControlButton extends React.PureComponent<VideoButtonProps> {
    // Following variables are used for preserving the state between different
    // event renders. They don't need to be part of the component state and cause
    // re-render. That's why they are created as instance variables and readonly
    // check needs to be disabled.
    private touchY?: number;
    private readonly linkRef: React.RefObject<
        HTMLAnchorElement
    > = React.createRef();

    private readonly handleButtonClick = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick) {
            this.props.onClick();
        }
    };

    private readonly handleMouseClick = (
        e: React.MouseEvent<HTMLAnchorElement>
    ) => {
        e.stopPropagation();
    };

    private readonly handleKeyboardClick = (
        e: React.KeyboardEvent<HTMLAnchorElement>
    ) => {
        const linkNode = ReactDOM.findDOMNode(this.linkRef.current);

        if (linkNode instanceof HTMLAnchorElement && e.keyCode === 32) {
            e.preventDefault();
            e.stopPropagation();
            linkNode.click();
        }
    };

    private readonly handleTouchStart = (
        e: React.TouchEvent<HTMLButtonElement>
    ) => {
        if (e.touches.length > 0) {
            const touch = e.touches.item(0);
            this.touchY = touch.pageY;
        }
    };

    private readonly handleTouchEnd = (
        e: React.TouchEvent<HTMLButtonElement>
    ) => {
        if (this.props.onClick && this.touchY && e.changedTouches.length > 0) {
            const touch = e.changedTouches.item(0);
            const deltaY = Math.abs(touch.pageY - this.touchY);
            if (deltaY < TOUCH_START_VS_END_MAX_PIXEL_DIFF) {
                // stop propagation to click
                e.preventDefault();
                e.stopPropagation();

                // perform same action as click
                this.props.onClick();
            }
            this.touchY = undefined;
        }
    };

    public render(): JSX.Element {
        const {disabled, title, dataCy, linkReference, download} = this.props;

        const tabIndexState = disabled ? -1 : 0;

        return linkReference ? (
            <StyledSmallControlLink
                title={title}
                onClick={this.handleMouseClick}
                onKeyDown={this.handleKeyboardClick}
                aria-label={title}
                tabIndex={tabIndexState}
                data-cy={dataCy}
                href={linkReference}
                download={download}
                ref={this.linkRef}
            >
                {this.props.children}
            </StyledSmallControlLink>
        ) : (
            <StyledSmallControlButton
                title={title}
                aria-label={title}
                onClick={this.handleButtonClick}
                onTouchStart={this.handleTouchStart}
                onTouchEnd={this.handleTouchEnd}
                data-cy={dataCy}
                disabled={disabled}
            >
                {this.props.children}
            </StyledSmallControlButton>
        );
    }
}

// todo: svg should be part of the icon project in the future
export const EnableFullScreenButton: React.FunctionComponent<VideoButtonProps> = props => (
    <SmallControlButton
        title={props.title}
        onClick={props.onClick}
        dataCy="videoEnableFullScreenButton"
        disabled={props.disabled}
    >
        <EnterFullscreen variant="default" ariaHidden />
    </SmallControlButton>
);

// todo: svg should be part of the icon project in the future
export const DisableFullScreenButton: React.FunctionComponent<VideoButtonProps> = props => (
    <SmallControlButton
        title={props.title}
        onClick={props.onClick}
        dataCy="videoDisableFullScreenButton"
        disabled={props.disabled}
    >
        <ExitFullscreen variant="default" ariaHidden />
    </SmallControlButton>
);

export type TogglePlayButtonProps = Readonly<{
    isVideoPlaying: boolean;
    pauseButtonTitle?: string;
    playButtonTitle?: string;
    disabled?: boolean;
    onPlayClick(): void;
    onPauseClick(): void;
}>;

export function TogglePlayButton(
    props: TogglePlayButtonProps
): React.ReactElement {
    const {
        disabled,
        isVideoPlaying,
        onPauseClick,
        onPlayClick,
        pauseButtonTitle,
        playButtonTitle
    } = props;

    const onClickEvent = isVideoPlaying ? onPauseClick : onPlayClick;
    const title = isVideoPlaying ? pauseButtonTitle : playButtonTitle;
    const cyTitle = isVideoPlaying
        ? 'videoPauseButton'
        : 'videoSmallPlayButton';
    const icon = isVideoPlaying ? (
        <Pause variant="default" ariaHidden />
    ) : (
        <Play variant="default" ariaHidden />
    );

    return (
        <SmallControlButton
            onClick={onClickEvent}
            dataCy={cyTitle}
            title={title}
            disabled={disabled}
        >
            {icon}
        </SmallControlButton>
    );
}

export type ToggleFullscreenButtonProps = Readonly<{
    isInFullScreen: boolean;
    disableFullScreenButtonTitle?: string;
    enableFullScreenButtonTitle?: string;
    disabled?: boolean;
    onToggleFullscreen(): void;
}>;

export function ToggleFullscreenButton(
    props: ToggleFullscreenButtonProps
): React.ReactElement {
    return props.isInFullScreen ? (
        <DisableFullScreenButton
            title={props.disableFullScreenButtonTitle}
            onClick={props.onToggleFullscreen}
            disabled={props.disabled}
        />
    ) : (
        <EnableFullScreenButton
            title={props.enableFullScreenButtonTitle}
            onClick={props.onToggleFullscreen}
            disabled={props.disabled}
        />
    );
}

export const TranscriptDownloadButton: React.FunctionComponent<VideoButtonProps> = (
    props: VideoButtonProps
) => (
    <SmallControlButton
        title={props.title}
        disabled={props.disabled}
        data-cy="videoTranscriptButton"
        download
        linkReference={props.linkReference}
    >
        <DownloadTranscript variant="default" ariaHidden />
    </SmallControlButton>
);
