import {isInBrowser} from './isInBrowser';

const ORIENTATION = {
    HORIZONTAL: 'h',
    VERTICAL: 'v'
};

export function getBrowserOrientation(): string {
    if (isInBrowser() && window.matchMedia) {
        return window.matchMedia('(orientation: portrait)').matches
            ? ORIENTATION.VERTICAL
            : ORIENTATION.HORIZONTAL;
    }

    return '';
}
