import {styled} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import React, {FunctionComponent, ReactNode} from 'react';
import {css, keyframes} from 'styled-components';
import {useGlobalConfig, useRegistry} from '../../../../context';
import {ModelStore} from '../../../../context/model/ModelStore';
import {ValueFactory} from '../../../../utils/contextUtils';
import {ModelContext} from '../../../../infrastructure/compatibility/ModelContext';
import {modelToProps} from '../../../../infrastructure/api/modelToProps';
import {BBO_VARIANT} from '../../../../context/bbo/BboService';
import {
    ActionScriptReport,
    TrackingService
} from '../../../../context/tracking/TrackingService';
import {useContextTrackingData} from '../../../../hooks/useContextTrackingData';
import {ComponentExporter} from '../../../../infrastructure/ComponentExporter';
import {PersonalizationContext} from '../../../../context/personalization/PersonalizationContext';
import {BBOContext} from '../../../../context/bbo/BBOContext';
import {makeClip} from '../../../../d6/components/helpers';

export const BBO_PERSONALIZATION_GROUP = 'BBO';
export const BBO_PERSONALIZATION_TYPE = 'BBO-Campaign';
const ANIMATION_DURATION = '0.4s';

/**
 * Used to forward props from Swapping to a component. Component has to extend this interface.
 **/
export interface SwappableProps {
    onAssetLoad?: () => void;
    actionScriptReports?: ActionScriptReport[];
}

/**
 * Additional Properties that are used by the Swapping. For typesafe usage use this when
 * loading or writing to model store. It is not necessarily used within the component itself.
 * Used to forward props from Swapper API Endpoint to the Swapping.
 * */
export interface SwappingProps {
    contentId: string;
    actionScriptReports: ActionScriptReport[];
}

export function swappable<T extends ComponentExporter & SwappableProps>(
    component: FunctionComponent<T>
) {
    return (props: T) => <Swapping props={props} Component={component} />;
}

const StyledAnimationWrapper = styled.div`
    position: relative;
`;

const animateOpacity = (start: number, end: number) => keyframes`
    0% {
		opacity: ${start};
	}
	100% {
		opacity: ${end};
	}
`;

// Note: 0.1s delay for this animation to minimize showing the white background when both components animate opacity
const StyledOriginalComponent = styled.div<{
    assetLoaded: boolean;
    hasAnimationEnded: boolean;
}>`
    ${props =>
        props.assetLoaded &&
        css`
            position: absolute;
            top: 0;
            width: 100%;
            animation: ${ANIMATION_DURATION} ${animateOpacity(1, 0)} forwards;
        `};
    ${props => props.hasAnimationEnded && 'display: none'}
`;

const StyledPersonalizedComponent = styled.div<{assetLoaded: boolean}>`
    display: none;
    ${props =>
        props.assetLoaded &&
        css`
            display: block;
            animation: ${ANIMATION_DURATION} ${animateOpacity(0, 1)} forwards;
        `}
`;

const StyledNotificationForScreenReaders = styled.caption`
    ${makeClip()}
`;

const OriginalComponent: React.FunctionComponent<{
    assetLoaded: boolean;
}> = props => {
    const {assetLoaded} = props;

    // has original component fade-out animation ended?
    const [hasAnimationEnded, setHasAnimationEnded] = React.useState(false);

    return (
        <StyledOriginalComponent
            assetLoaded={assetLoaded}
            hasAnimationEnded={hasAnimationEnded}
            onAnimationEnd={() => setHasAnimationEnded(true)}
        >
            {!hasAnimationEnded && props.children}
        </StyledOriginalComponent>
    );
};

const Swapping = observer(function IS<
    T extends ComponentExporter & SwappableProps
>(mainProps: {props: T; Component: FunctionComponent<T>}) {
    const {Component, props} = mainProps;

    // stores and services
    const modelStore: ModelStore = useRegistry().getSingleton('ModelStore');
    const trackingService: TrackingService = useRegistry().getSingleton(
        'TrackingService'
    );
    const spaGlobalConfig = useGlobalConfig();
    const modelContextValueFactory: ValueFactory<string> = React.useRef(
        new ValueFactory<string>()
    ).current;

    // has custom content media loaded?
    const [assetLoaded, setAssetLoaded] = React.useState(false);

    // get path for custom data (swapped content)
    const personalizedPath = modelStore.getCustomContentPath(
        BBO_VARIANT,
        props.cqPath
    );

    // get custom data (swapped content)
    const newModel = modelStore.getCustomContent(personalizedPath);
    const newProps = newModel
        ? ((modelToProps(newModel, personalizedPath) as unknown) as T &
              SwappingProps)
        : undefined;
    const trackingData = useContextTrackingData(newModel?.contentLabels);

    // track after custom content has been loaded
    React.useMemo(() => {
        if (newProps && assetLoaded) {
            // section and personalization context is missing in this place, so create it here
            trackingService.trackStageSectionLoad(
                newProps.contentId,
                newProps.actionScriptReports,
                {
                    ...trackingData,
                    sectionId: newProps.contentId,
                    personalizationPersonalizable: true,
                    personalizationPersonalized: true,
                    personalizationGroup: BBO_PERSONALIZATION_GROUP,
                    personalizationType: BBO_PERSONALIZATION_TYPE,
                    recommendationId: undefined
                }
            );
        }
    }, [newProps, assetLoaded, trackingService, trackingData]);

    let swapped: ReactNode;
    if (newProps) {
        const contextData = modelContextValueFactory.create(personalizedPath);

        // create necessary context and place component inside
        //, personalizationGroup: BBO_PERSONALIZATION_GROUP, personalizationType: BBO_PERSONALIZATION_TYPE
        swapped = (
            <ModelContext.Provider value={contextData}>
                <PersonalizationContext.Provider
                    value={{wasPersonalized: true, isPersonalizable: true}}
                >
                    <BBOContext.Provider
                        value={{
                            personalizationGroup: BBO_PERSONALIZATION_GROUP,
                            personalizationType: BBO_PERSONALIZATION_TYPE
                        }}
                    >
                        <Component
                            key={undefined}
                            {...newProps}
                            actionScriptReports={newProps.actionScriptReports}
                            onAssetLoad={() => {
                                setAssetLoaded(true);
                            }}
                        />
                    </BBOContext.Provider>
                </PersonalizationContext.Provider>
            </ModelContext.Provider>
        );
    }

    // return custom component + default component (one of them is hidden)
    return (
        <StyledAnimationWrapper>
            <OriginalComponent {...props} assetLoaded={assetLoaded}>
                <Component key={undefined} {...props} />
            </OriginalComponent>
            <StyledPersonalizedComponent assetLoaded={assetLoaded}>
                {swapped}
            </StyledPersonalizedComponent>
            <StyledNotificationForScreenReaders aria-live={'polite'}>
                {assetLoaded && spaGlobalConfig.newContentNotification}
            </StyledNotificationForScreenReaders>
        </StyledAnimationWrapper>
    );
});
