import * as React from 'react';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';
import {
    Breadcrumbs as LsgBreadcrumbs,
    BreadcrumbLinkInner
} from '../../../d6/components/breadcrumbs-onehub';

import {observer} from 'mobx-react-lite';
import {Breadcrumb} from '../../../context/navigation/BreadcrumbStore';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {
    useBreadcrumbStore,
    useGlobalConfig,
    useTrackingService
} from '../../../context';
import {
    ContextTrackingData,
    LinkData
} from '../../../context/tracking/TrackingService';
import {noop} from '../../../utils/noop';
import {TrackingActionOverride} from '../../../infrastructure/TrackableLinkProps';

export interface BreadcrumbsProps {
    topSpacing?: string;
    isBreadcrumbsBelowTopbar?: boolean;
}

interface BreadcrumbProps {
    readonly link: Breadcrumb;
    readonly index: number;
    readonly lastIndex: number;
    readonly homeLabel: string;
    readonly siteUrl: string;
    readonly trackBreadcrumbClick: TrackingActionOverride;
    readonly lastPageIsHidden?: boolean;
}

const BreadcrumbLink: React.FunctionComponent<BreadcrumbProps> = props => {
    const {
        link,
        index,
        lastIndex,
        homeLabel,
        siteUrl,
        trackBreadcrumbClick,
        lastPageIsHidden
    } = props;

    const title = (!index && homeLabel) || link.title;
    const isLast = index === lastIndex - 1;
    const isLastBreadcrumbEntry = isLast && !lastPageIsHidden;
    const linkUrl = link.url.startsWith('/') ? link.url : '/' + link.url;
    const absoluteUrl = siteUrl + linkUrl;
    const linkClickAction = (ev: React.SyntheticEvent) => {
        ev.preventDefault();
        if (link.onClick) {
            link.onClick();
        }
    };
    const onClick = link.onClick ? linkClickAction : undefined;
    const trackingActionOverride = link.onClick ? noop : trackBreadcrumbClick;

    const item = (
        <BreadcrumbLinkInner
            title={title}
            index={index}
            absoluteUrl={absoluteUrl}
        />
    );

    const currPageLabel = (
        <Text appearance={TokenTextAppearance.copy100} bold key={index}>
            {item}
        </Text>
    );

    const breadcrumbLink = (
        <Text appearance={TokenTextAppearance.copy100} key={index}>
            <CmsTextLink
                href={link.url}
                trackingActionOverride={trackingActionOverride}
                onClick={onClick}
                emphasis="tertiary"
            >
                {item}
            </CmsTextLink>
        </Text>
    );

    return isLastBreadcrumbEntry ? currPageLabel : breadcrumbLink;
};

export const StyledBreadcrumbsWrapper = styled.nav<BreadcrumbsProps>`
    padding-top: ${props =>
        props.topSpacing ? props.topSpacing : props.theme.size.dynamic0100};

    padding-bottom: ${props =>
        props.isBreadcrumbsBelowTopbar ? props.theme.size.dynamic0100 : 0};

    @media (max-width: ${Breakpoints.b560 - 1}px) {
        display: none;
    }
`;

const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = observer(
    function BC(props: BreadcrumbsProps): JSX.Element | null {
        const breadcrumbStore = useBreadcrumbStore();
        const trackingService = useTrackingService();
        const globalConfig = useGlobalConfig();

        let links = breadcrumbStore.links;
        const homeLabel = breadcrumbStore.homeLabel;
        const ariaLabel = breadcrumbStore.ariaLabel;
        const lastIndex = links.length;

        if (links.length === 0) {
            return null;
        }

        let lastPageIsHidden = false;
        if (links[lastIndex - 1].hidden) {
            links = links.slice(0, lastIndex - 1);
            lastPageIsHidden = true;
        }

        const trackBreadcrumbClick = (
            href: string,
            linkName: string,
            contextData: ContextTrackingData
        ): void => {
            const linkData: LinkData = {
                url: href,
                name: linkName
            };

            trackingService.trackNavigationBreadcrumbClick(
                'navigation-breadcrumb',
                linkData,
                contextData
            );
        };

        return (
            <StyledBreadcrumbsWrapper
                topSpacing={props.topSpacing}
                aria-label={ariaLabel}
                isBreadcrumbsBelowTopbar={props.isBreadcrumbsBelowTopbar}
            >
                <LsgBreadcrumbs>
                    {links.map((link: Breadcrumb, index: number) => (
                        <BreadcrumbLink
                            key={link.title}
                            link={link}
                            index={index}
                            lastIndex={lastIndex}
                            homeLabel={homeLabel}
                            siteUrl={globalConfig.siteUrl}
                            trackBreadcrumbClick={trackBreadcrumbClick}
                            lastPageIsHidden={lastPageIsHidden}
                        />
                    ))}
                </LsgBreadcrumbs>
            </StyledBreadcrumbsWrapper>
        );
    }
);
Breadcrumbs.displayName = 'Breadcrumbs';

export {Breadcrumbs};
