import * as React from 'react';
import {MediaAspectRatio} from '../../generated/core';
import {MediaContext} from '../context/media/MediaContext';
import {useIsClientOnlyRendering} from '../utils/useIsClientOnlyRendering';

/**
 * @param mediaQuery    mediaQuery to test
 * @param ifTrue        what to do with image if media query pass. Show or hide, default is show
 * @param aspectRatio   aspect ratio of image
 */
export interface ResponsiveMediaRendererConf {
    mediaQuery?: string;
    sizes?: string;
    aspectRatio?: MediaAspectRatio;
    ifTrue?: 'show' | 'hide';
    portray?: boolean;
}

export type ResponsiveMediaRendererProps = React.PropsWithChildren<{
    configs: ResponsiveMediaRendererConf[];
    matchParent?: boolean;
}>;

const getMatchingConfigEntry = (config: ResponsiveMediaRendererConf[]) =>
    config.find(
        ratio => window?.matchMedia(ratio.mediaQuery as string).matches
    );

function ClientResponsiveRendererV2(
    props: ResponsiveMediaRendererProps
): JSX.Element {
    const {configs} = props;
    const [matchingConf, setMatchingConf] = React.useState<
        ResponsiveMediaRendererConf | undefined
    >(getMatchingConfigEntry(configs));

    React.useEffect(() => {
        const match = window?.matchMedia(matchingConf?.mediaQuery as string);
        match.addEventListener('change', () =>
            setMatchingConf(getMatchingConfigEntry(configs))
        );
        return () => {
            match.removeEventListener('change', () =>
                setMatchingConf(getMatchingConfigEntry(configs))
            );
        };
    }, [configs, matchingConf]);

    return (
        <MediaContext.Provider
            value={{
                ratio: matchingConf?.aspectRatio
            }}
        >
            {props.children}
        </MediaContext.Provider>
    );
}

export function ResponsiveMediaRenderer(
    props: ResponsiveMediaRendererProps
): JSX.Element {
    const isClient = useIsClientOnlyRendering();

    return isClient ? (
        <ClientResponsiveRendererV2 {...props} />
    ) : (
        <>{props.children}</>
    );
}
