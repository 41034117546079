import {action, computed, observable} from 'mobx';
import {SpaGlobalConfig} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {GLOBAL_NAMESPACE} from '../../utils/getGlobal';
import {LoginFlyoutService} from './LoginFlyoutService';
import {AuthProxyError, ErrorStatus, LoginStatus} from './LoginStatus';
import {LoginStore} from './LoginStore';
import {UserData} from './UserData';
import {Logger} from '../logger/Logger';
import {TrackingService} from '../tracking/TrackingService';
import {OrganizationData} from './OrganizationData';
import {CustomerType} from '@volkswagen-onehub/authservice-config';
import {
    CIDK_LOGIN,
    CIDK_LOGOUT,
    IDK_LOGIN,
    IDK_LOGOUT
} from '../../modules/structure/login/constants';
import {OrganizationInfoData} from './OrganizationInfoData';
import {OrganizationUserData} from './OrganizationUserData';
import {
    IAuthServiceProviderV2,
    IAuthServiceV2
} from '@volkswagen-onehub/authservice';
import {GfaAuthServiceRequestInitV2} from '@volkswagen-onehub/gfa-auth-service';
import {getCookie} from '../../utils/getCookie';
import {LoginSessionTimeoutService} from './LoginSessionTimeoutService';
import {isSessionTimeoutEnabled} from '../../utils/vwId/isSessionTimeoutEnabled';
import {getEncodedRedirectUrl} from './getEncodedRedirectUrl';

// flag only to mark the integrator/cms auth trigger for page state reason (e.g. login flyout)
export const CMS_REDIRECT_STATE_KEY =
    GLOBAL_NAMESPACE + '_loginstore_authproxy_redirect';
// flag to mark all the auth triggers (e.g. also feature apps) for tracking reason
export const AUTH_STATE_KEY = GLOBAL_NAMESPACE + '_auth_state';

export interface AuthState {
    isLoggedIn: boolean;
    customerType: CustomerType | null;
}

export const SESSION_TIMEOUT_COOKIE_NAME = 'authproxy_session_timeout';
export const REMEMBER_ME_COOKIE_NAME = 'authproxy_remember_me';
export const SESSION_TIMEOUT_EXPIRED = 'vwa_d6_session_timeout_expired';

/**
 * As long as we have two ways of authentication (one for One Hub and one for ID Hub) this class
 * will delegate all method calls to the correct login store implementation.
 */
@singleton('LoginStore', {env: 'client'})
export class ClientLoginStore implements LoginStore {
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private loginFlyoutService!: LoginFlyoutService;
    @inject()
    private loginSessionTimeoutService!: LoginSessionTimeoutService;
    @inject() private logger!: Logger;
    @inject() private authServiceProvider?: IAuthServiceProviderV2;
    @inject() private authServiceProviderCommercial?: IAuthServiceProviderV2;
    @inject() private trackingService!: TrackingService;
    private authService!: IAuthServiceV2;
    private authServiceCommercial!: IAuthServiceV2;
    private timers = new Set<number>();

    @observable
    private _loginStatus: LoginStatus = {
        statusResolved: false,
        isLoggedIn: false,
        userData: null,
        organizationData: null,
        authenticatedCustomerType: null
    };

    @postConstruct()
    public async initialize(): Promise<void> {
        const cmsLegalEntity = this.spaGlobalConfig.loginModel.cmsLegalEntity;

        if (
            !this.spaGlobalConfig.loginModel.enabled ||
            !cmsLegalEntity ||
            !this.authServiceProvider
        ) {
            return Promise.resolve();
        }

        try {
            this.authService = this.authServiceProvider.register(
                cmsLegalEntity
            );
            if (
                this.multipleCustomerTypes &&
                this.authServiceProviderCommercial
            ) {
                this.authServiceCommercial = this.authServiceProviderCommercial.register(
                    cmsLegalEntity
                );
            }
        } catch (e) {
            this.logger.general.error(
                'Error occurred by authservice registration. Caused by: ',
                e
            );
            return Promise.resolve();
        }

        if (!this.authService && !this.authServiceCommercial) {
            return Promise.resolve();
        }

        if (await this.authService.isAuthenticated()) {
            this._loginStatus.authenticatedCustomerType = 'private';
            this.registerSessionTimeout();
            this.loadUserData();
        } else {
            if (
                this.multipleCustomerTypes &&
                (await this.authServiceCommercial.isAuthenticated())
            ) {
                this._loginStatus.authenticatedCustomerType = 'commercial';
                this.registerSessionTimeout();
                this.loadOrganizationData();
            }
        }

        const isAuthenticated = Boolean(
            this._loginStatus.authenticatedCustomerType
        );
        this.statusRequested(isAuthenticated);

        this.handleRedirect();

        this.trackAuthState(isAuthenticated);

        this.subscribe();

        this.validateSessionExpired();
    }

    /**
     * @throws AuthProxyError
     */
    @action
    public async handleLogin(
        customerType: CustomerType = 'private'
    ): Promise<void> {
        try {
            window.sessionStorage.setItem(CMS_REDIRECT_STATE_KEY, 'true');

            if (!this._loginStatus.authenticatedCustomerType) {
                if (customerType === 'private') {
                    await this.authService.login(
                        'login',
                        getEncodedRedirectUrl()
                    );
                } else {
                    await this.authServiceCommercial.login(
                        'login',
                        getEncodedRedirectUrl()
                    );
                }
            } else {
                // if already logged in (in different tab), load user / company data
                if (this.isPrivateCustomer()) {
                    this.loadUserData();
                } else {
                    this.loadOrganizationData();
                }
            }
        } catch (e) {
            throw new AuthProxyError(ErrorStatus.unknownError);
        }
    }

    @action
    public async handleLogout(): Promise<void> {
        if (this._loginStatus.authenticatedCustomerType) {
            window.sessionStorage.setItem(CMS_REDIRECT_STATE_KEY, 'true');

            // private
            if (
                this.isPrivateCustomer() &&
                (await this.authService.isAuthenticated())
            ) {
                return this.authService.logout(getEncodedRedirectUrl());
            }
            // commercial
            if (
                !this.isPrivateCustomer() &&
                (await this.authServiceCommercial.isAuthenticated())
            ) {
                return this.authServiceCommercial.logout(
                    getEncodedRedirectUrl()
                );
            }
        }

        // edge case: already logged out in different tab
        // edge case: automated logout via session timeout after inactivity (e.g. OS standby)
        window.location.reload();
    }

    @computed
    public get isLogInStatusResolved(): boolean {
        return this._loginStatus.statusResolved;
    }

    @computed
    public get isLoggedIn(): boolean {
        return this._loginStatus.isLoggedIn;
    }

    public get userData(): UserData | null {
        return this._loginStatus.userData;
    }

    public get organizationData(): OrganizationData | null {
        return this._loginStatus.organizationData;
    }

    public openProfileTab(): void {
        if (!this.spaGlobalConfig.loginModel.profileUrl) {
            this.logger.general.error('no profile url configured');
            return;
        }

        const url = new URL(this.spaGlobalConfig.loginModel.profileUrl);
        const email = this.userData?.email;
        if (email) {
            url.searchParams.append('login_hint', email);
        }
        window.open(url.toString(), '_blank');
    }

    public openCommercialProfileTab(): void {
        if (!this.spaGlobalConfig.loginModel.profileCommercialUrl) {
            this.logger.general.error('no profile commercial url configured');
            return;
        }

        const url = new URL(
            this.spaGlobalConfig.loginModel.profileCommercialUrl
        );
        const email = this.userData?.email;
        if (email) {
            url.searchParams.append('login_hint', email);
        }
        window.open(url.toString(), '_blank');
    }

    public hasCommercialProfileUrl(): boolean {
        return Boolean(this.spaGlobalConfig.loginModel.profileCommercialUrl);
    }

    public listenToLogout(listener: () => Promise<void>): void {
        if (this.authService) {
            this.authService.subscribe('logout', listener);
        }
    }

    public async fetch(
        resource: string,
        init?: GfaAuthServiceRequestInitV2
    ): Promise<Response> {
        return this.isPrivateCustomer()
            ? this.authService.fetch(resource, init)
            : this.authServiceCommercial.fetch(resource, init);
    }

    public get multipleCustomerTypes(): boolean {
        const legalEntityMappings = this.spaGlobalConfig.loginModel
            .legalEntityMapping;
        return (
            legalEntityMappings &&
            Boolean(
                legalEntityMappings.find(
                    mapping =>
                        !mapping.customerType ||
                        mapping.customerType === 'private'
                )
            ) &&
            Boolean(
                legalEntityMappings.find(
                    mapping => mapping.customerType === 'commercial'
                )
            )
        );
    }

    public registerSessionTimeout() {
        if (isSessionTimeoutEnabled(this.spaGlobalConfig.loginModel)) {
            const timeoutCookie = getCookie(SESSION_TIMEOUT_COOKIE_NAME);
            if (timeoutCookie != null) {
                const layerTiming =
                    this.spaGlobalConfig.loginModel.layerTiming * 1000;
                const sessionTimeout =
                    new Date(Number(timeoutCookie)).getTime() -
                    new Date().getTime();
                const layerSessionTimeout = sessionTimeout - layerTiming;
                this.timers.add(
                    setTimeout(() => {
                        const layerId = this.loginSessionTimeoutService.getLayerId();
                        if (layerId) {
                            this.loginSessionTimeoutService.openLayer(layerId);
                        }
                    }, layerSessionTimeout)
                );
                this.timers.add(
                    setTimeout(() => {
                        sessionStorage.setItem(SESSION_TIMEOUT_EXPIRED, 'true');
                        this.handleLogout();
                    }, sessionTimeout)
                );
            }
        }
    }

    public async continueSession(rememberMe?: boolean): Promise<boolean> {
        const result = this.isPrivateCustomer()
            ? await this.authService.continueSession(rememberMe)
            : await this.authServiceCommercial.continueSession(rememberMe);

        if (result) {
            this.timers.forEach(timer => clearTimeout(timer));
            this.timers.clear();
            this.registerSessionTimeout();
        }

        return result;
    }

    private async statusRequested(isAuthenticated: boolean): Promise<void> {
        this._loginStatus = {
            ...this._loginStatus,
            statusResolved: true,
            isLoggedIn: isAuthenticated,
            userData: null,
            organizationData: null
        };
    }

    private async loadUserData(): Promise<void> {
        const user = await this.getUser();
        this._loginStatus = {
            ...this._loginStatus,
            statusResolved: true,
            isLoggedIn: true,
            userData: user,
            organizationData: null
        };
    }

    private async getUser(): Promise<UserData> {
        const userResponse = await this.authService.fetch('../user');
        if (userResponse) {
            return userResponse.json();
        }

        throw new AuthProxyError(ErrorStatus.authenticationFailed);
    }

    private async loadOrganizationData(): Promise<void> {
        const organizationData = await this.getOrganizationData();
        this._loginStatus = {
            ...this._loginStatus,
            statusResolved: true,
            isLoggedIn: true,
            userData: null,
            organizationData
        };
    }

    private async getOrganizationData(): Promise<OrganizationData> {
        const organizationInfoResponse = await this.authServiceCommercial.fetch(
            '/comid/organisations',
            {resourceHostId: 'cidk'}
        );
        const organizationInfo = (await organizationInfoResponse.json()) as OrganizationInfoData;
        if (organizationInfo?.legalConfirmationUserId) {
            const organizationUserResponse = await this.authServiceCommercial.fetch(
                `/comid/users/${organizationInfo?.legalConfirmationUserId}`,
                {resourceHostId: 'cidk'}
            );
            const organizationUser = (await organizationUserResponse.json()) as OrganizationUserData;
            if (organizationUser) {
                return {
                    ...organizationUser,
                    companyName: organizationInfo.companyName
                };
            }
        }

        throw new AuthProxyError(ErrorStatus.authenticationFailed);
    }

    private async onLogout(): Promise<void> {
        this._loginStatus = {
            statusResolved: true,
            isLoggedIn: false,
            userData: null,
            organizationData: null,
            authenticatedCustomerType: null
        };
    }

    private getTrackingContentId(authState: AuthState): string | null {
        if (this._loginStatus.isLoggedIn) {
            return this.isPrivateCustomer() ? IDK_LOGIN : CIDK_LOGIN;
        }

        if (authState.customerType != null) {
            return authState.customerType === 'private'
                ? IDK_LOGOUT
                : CIDK_LOGOUT;
        }

        return null;
    }

    private isPrivateCustomer(): boolean {
        return this._loginStatus.authenticatedCustomerType === 'private';
    }

    private handleRedirect() {
        const cmsRedirect = window.sessionStorage.getItem(
            CMS_REDIRECT_STATE_KEY
        );
        if (cmsRedirect) {
            window.sessionStorage.removeItem(CMS_REDIRECT_STATE_KEY);
            this.loginFlyoutService.openFlyout();
        }
    }

    private trackAuthState(isAuthenticated: boolean) {
        const authState = this.getAuthState();
        if (authState != null) {
            sessionStorage.removeItem(AUTH_STATE_KEY);
            const statusChanged = isAuthenticated !== authState.isLoggedIn;
            if (statusChanged) {
                this.trackingService.trackLoginStatusChange(
                    this.getTrackingContentId(authState)
                );
            }
        }
    }

    private subscribe() {
        this.authService.subscribe('saveState', this.saveAuthState.bind(this));
        this.authService.subscribe('logout', this.saveAuthState.bind(this));
        if (this.multipleCustomerTypes) {
            this.authServiceCommercial.subscribe(
                'saveState',
                this.saveAuthState.bind(this)
            );
            this.authServiceCommercial.subscribe(
                'logout',
                this.saveAuthState.bind(this)
            );
        }

        this.authService.subscribe('logout', this.onLogout.bind(this));
        if (this.multipleCustomerTypes) {
            this.authServiceCommercial.subscribe(
                'logout',
                this.onLogout.bind(this)
            );
        }
    }

    private async saveAuthState(): Promise<void> {
        const value: AuthState = {
            isLoggedIn: this._loginStatus.isLoggedIn,
            customerType: this._loginStatus.authenticatedCustomerType
        };

        sessionStorage.setItem(AUTH_STATE_KEY, JSON.stringify(value));
    }

    private getAuthState(): AuthState | null {
        const stringifiedValue = sessionStorage.getItem(AUTH_STATE_KEY);
        if (!stringifiedValue) {
            return null;
        }

        return JSON.parse(stringifiedValue);
    }

    public validateSessionExpired(): void {
        if (sessionStorage.getItem(SESSION_TIMEOUT_EXPIRED)) {
            this.loginSessionTimeoutService.openLayer(
                'loginSessionTimeoutExpired'
            );
            sessionStorage.removeItem(SESSION_TIMEOUT_EXPIRED);
        }
    }
}
