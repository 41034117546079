import * as React from 'react';
import {ThemeContext} from 'styled-components';

import {
    BreakpointWrapper,
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    FixedWidthContainer,
    styled
} from '@volkswagen-onehub/components-core';
import {Direction, getContainerPadding} from '../helpers';

export interface QuoteProps {
    readonly cite?: JSX.Element;
}

interface IconProps {
    readonly type?: 'classic' | 'straight';
}

interface QuoteIconProps {
    readonly width: number;
    readonly type: 'classic' | 'straight';
}

const smallIconWidth = 19;
const largeIconWidth = 29;
const separatorWidth = '2px';

const StyledStatement = styled.blockquote`
    margin: 0;
    display: flex;
`;
StyledStatement.displayName = 'StyledStatement';

const StyledCite = styled.cite`
    display: block;
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
    font-style: normal;
`;
StyledCite.displayName = 'StyledCite';

const StyledSeparator = styled.div`
    border-top: solid ${props => props.theme.colors.border.divider}
        ${separatorWidth};
`;
StyledSeparator.displayName = 'StyledSeparator';

const StyledQuoteIcon = styled.svg`
    margin: 0 auto;
    display: block;
    path {
        fill: ${props => props.theme.text.color.primary};
    }
`;
StyledQuoteIcon.displayName = 'StyledQuoteIcon';

const QuoteIcon: React.FunctionComponent<QuoteIconProps> = ({width, type}) => (
    <StyledQuoteIcon
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 24"
    >
        <path
            d={
                type === 'classic'
                    ? 'M12.889 2.662c-1.364.798-2.468 1.486-3.31 2.063-.841.576-1.698 1.338-2.568 2.284-.813.887-1.437 1.82-1.873 2.795-.435.976-.74 2.233-.914 3.77h2.09c1.8 0 3.215.452 4.245 1.354 1.03.902 1.546 2.225 1.546 3.97 0 1.242-.457 2.403-1.372 3.483C9.82 23.46 8.563 24 6.967 24c-2.497 0-4.282-.835-5.356-2.506C.537 19.823 0 17.627 0 14.906c0-1.923.406-3.675 1.22-5.257a18.626 18.626 0 0 1 2.96-4.237 21.482 21.482 0 0 1 3.788-3.238A145.927 145.927 0 0 1 11.321 0l1.568 2.662zm16.111 0c-1.364.798-2.467 1.486-3.31 2.063-.841.576-1.698 1.338-2.568 2.284-.842.917-1.474 1.856-1.895 2.817-.42.961-.718 2.211-.892 3.749h2.09c1.8 0 3.215.45 4.245 1.353 1.031.902 1.546 2.225 1.546 3.97 0 1.242-.457 2.403-1.371 3.483C25.93 23.46 24.675 24 23.078 24c-2.496 0-4.282-.835-5.356-2.506-1.074-1.671-1.61-3.867-1.61-6.588 0-1.923.406-3.675 1.218-5.257a18.626 18.626 0 0 1 2.961-4.237 21.482 21.482 0 0 1 3.789-3.238A145.927 145.927 0 0 1 27.432 0L29 2.662z'
                    : 'M7.5167.4855,5.0692,18.21H.4934v-.4855L4.1713,0H7.5167Zm10.99,0L16.0591,18.21H11.4833v-.4855L15.1612,0h3.3454Z'
            }
            fillRule="evenodd"
        />
    </StyledQuoteIcon>
);

// @todo: refactor => add "mobileColumns" to `FixedWidthContainer`
const Icon: React.FunctionComponent<IconProps> = ({type = 'classic'}) => (
    <>
        <BreakpointWrapper min={Breakpoints.b560}>
            <FixedWidthContainer columns={1}>
                <QuoteIcon width={largeIconWidth} type={type} />
            </FixedWidthContainer>
        </BreakpointWrapper>
        <BreakpointWrapper max={Breakpoints.b560}>
            <FixedWidthContainer columns={2}>
                <QuoteIcon width={smallIconWidth} type={type} />
            </FixedWidthContainer>
        </BreakpointWrapper>
    </>
);

// @todo: refactor => add "mobileColumns" to `FixedWidthContainer`
const separator = (
    <>
        <BreakpointWrapper min={Breakpoints.b560}>
            <FixedWidthContainer columns={1}>
                <StyledSeparator />
            </FixedWidthContainer>
        </BreakpointWrapper>
        <BreakpointWrapper max={Breakpoints.b560}>
            <FixedWidthContainer columns={2}>
                <StyledSeparator />
            </FixedWidthContainer>
        </BreakpointWrapper>
    </>
);

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);

export const Quote: React.FunctionComponent<QuoteProps> = (
    props: React.PropsWithChildren<QuoteProps>
): JSX.Element => (
    <StyledStatement>
        <ThemeContext.Consumer>
            {value => (
                <Icon
                    type={
                        value.direction === Direction.RTL
                            ? 'straight'
                            : 'classic'
                    }
                />
            )}
        </ThemeContext.Consumer>
        <Container
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.dynamic0150}
            padding={{
                inlineEnd: CONTAINER_PADDING_2_1,
                top: ContainerPadding.static100
            }}
        >
            <>{props.children}</>
            <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.static150}
            >
                {separator}
                {props.cite && <StyledCite>{props.cite}</StyledCite>}
            </Container>
        </Container>
    </StyledStatement>
);
