var formatRegExp = /%(\.\d)?[sdfijc]/g;

export function format(message: string, ...optionalParams: unknown[]) {
    let {message: currentMessage, remainingParams} = expandPlaceholders(
        message,
        optionalParams
    );
    return currentMessage + appendRemainingParams(remainingParams);
}
function appendRemainingParams(remainingParams: unknown[]) {
    return remainingParams
        .map(param => {
            if (param instanceof Error) {
                return `${param.stack ? '\n' + param.stack : ''}`;
            } else {
                return ` ${param}`;
            }
        })
        .join();
}

function expandPlaceholders(message: string, optionalParams: unknown[]) {
    let i = -1;
    const str = String(message).replace(formatRegExp, (z: string): string => {
        const lastLetter = z.substring(z.length - 1, z.length);
        i++;
        const param = optionalParams[i];
        switch (lastLetter) {
            case 'i':
            case 'd':
                return typeof param === 'number'
                    ? Number(param).toFixed(0)
                    : String(param);
            case 's':
            case 'f':
                return String(optionalParams[i]);
            case 'j':
                return JSON.stringify(optionalParams[i]);
            case 'c':
            default:
                return '';
        }
    });
    return {message: str, remainingParams: optionalParams.slice(i + 1)};
}
