import * as React from 'react';
import styled from 'styled-components';

import {C} from '../registries/compatibilty';
import {AlertBox} from './AlertBox';

export interface AuthoringWrapperProps {
    readonly anchorId?: string;
    readonly title?: string;
    readonly bgColor?: string;
    readonly tagTypeBroken?: boolean;
}
const StyledWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const authoringTitleHeight = '20px';
export const authorContentHeight = `calc(100% - ${authoringTitleHeight})`;

const StyledContent = styled.div`
    position: relative;
    height: ${authorContentHeight};
`;

// use label to avoid conflicts in styling for arbitrary divs
const StyledLabel = styled.label`
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: ${authoringTitleHeight};
    margin-top: 0;
    padding: 3px;
    border-bottom: 2px solid #fff;
    font-size: 10px;
    text-align: center;
    color: #ffffff;
    background-color: ${(props: AuthoringWrapperProps) => props.bgColor};
    white-space: nowrap;

    span {
        text-transform: uppercase;
    }
`;

StyledLabel.displayName = 'StyledLabel';

export class AuthoringWrapper extends React.Component<AuthoringWrapperProps> {
    public static BG_COLOR_SECTION_GROUP: string = 'rgba(51, 9, 99,0.5)';
    public static BG_COLOR_SECTION: string = 'rgba(0,67,122,0.35)';
    public static BG_COLOR_ITEM: string = 'rgba(0,138,201,0.35)';
    public static BG_COLOR_ELEMENT: string = 'rgba(0,0,0,0.15)';

    public render(): JSX.Element | {} | null | undefined {
        const {
            anchorId,
            title = 'untitled component',
            bgColor = AuthoringWrapper.BG_COLOR_ELEMENT,
            tagTypeBroken = false
        } = this.props;

        return !C.isInEditor() ? (
            this.props.children
        ) : (
            <StyledWrapper>
                <StyledLabel className="authoring-label" bgColor={bgColor}>
                    <span>{title}</span>
                    {anchorId && <>&nbsp;- Anchor ID: {anchorId}</>}
                </StyledLabel>
                <StyledContent>
                    {tagTypeBroken && this.alertBox()}
                    {this.props.children}
                </StyledContent>
            </StyledWrapper>
        );
    }

    private alertBox = (): JSX.Element => {
        return (
            <AlertBox>
                <div>
                    The content labels of this section are broken. Please save
                    them again.
                </div>
            </AlertBox>
        );
    };
}
