import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

const LOGO_TEXT_SIZES: {[key in Breakpoints]: number} = {
    [Breakpoints.default]: 8,
    [Breakpoints.b560]: 10,
    [Breakpoints.b960]: 12,
    [Breakpoints.b1280]: 12,
    [Breakpoints.b1600]: 12,
    [Breakpoints.b1920]: 14,
    [Breakpoints.b2560]: 14,
    Infinity: 14
};

/**
 * Provides logo text size in pixels for given breakpoint.
 */
export function getLogoTextSize(breakpoint: Breakpoints): number {
    return LOGO_TEXT_SIZES[breakpoint];
}

function getClaim(claim?: string): string[] {
    return claim ? claim.trim().split(' ') : [];
}

function createLogoClaimTypo(breakpoint: Breakpoints): string {
    const textSize = `${getLogoTextSize(breakpoint)}px`;

    return `
		font-size: ${textSize};
		line-height: ${textSize};
	`;
}

const StyledLogoClaimWrapper = styled.div``;
StyledLogoClaimWrapper.displayName = 'StyledLogoClaimWrapper';

const StyledLogoClaim = styled.div`
    font-family: vw-head, Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: ${props => props.theme.colors.button.primary.default};

    // move text a slightly up
    padding-bottom: 0.2em;

    ${createLogoClaimTypo(Breakpoints.default)};
    @media (min-width: ${Breakpoints.b560}px) {
        ${createLogoClaimTypo(Breakpoints.b560)};
    }
    @media (min-width: ${Breakpoints.b960}px) {
        ${createLogoClaimTypo(Breakpoints.b960)};
    }
    @media (min-width: ${Breakpoints.b1920}px) {
        ${createLogoClaimTypo(Breakpoints.b1920)};
    }
`;
StyledLogoClaim.displayName = 'StyledLogoClaim';

interface ClaimProps {
    children: string;
}

export function LogoClaim(props: ClaimProps): React.ReactElement {
    const claimParts = getClaim(props.children);

    return (
        <StyledLogoClaimWrapper>
            {claimParts.map((part, index) => (
                <StyledLogoClaim key={index}>{part}</StyledLogoClaim>
            ))}
        </StyledLogoClaimWrapper>
    );
}
