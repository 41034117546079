import {ProfileV2} from '../smartDigital/SmartDigitalService';

export function equalProfiles(a: ProfileV2, b?: ProfileV2): boolean {
    if (!b) {
        return false;
    }
    if (b.personalization && a.personalization) {
        const personalizationChanged = Object.keys(a.personalization).some(
            (key: string) => {
                return b.personalization[key] !== a.personalization[key];
            }
        );
        if (personalizationChanged) {
            return false;
        }
    }
    if (a.metadata && b.metadata) {
        return a.metadata.recommendationId === b.metadata.recommendationId;
    }
    return false;
}
