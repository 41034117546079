import * as React from 'react';
import {
    ReferenceBadgeProps,
    DisclaimerReferenceBadge
} from '@volkswagen-onehub/components-core';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';

export interface CmsReferenceBadgeProps extends ReferenceBadgeProps {
    reference?: string;
}

export function CmsReferenceBadge(
    props: React.PropsWithChildren<CmsReferenceBadgeProps>
) {
    return (
        <CyAttributeAppender name={`ref-disclaimer-${props.reference}`}>
            <span>
                <DisclaimerReferenceBadge {...props}>
                    {props.children}
                </DisclaimerReferenceBadge>
            </span>
        </CyAttributeAppender>
    );
}
