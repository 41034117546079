import * as React from 'react';
import styled from 'styled-components';

import {
    Text,
    TokenTextColor,
    TextTag,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import {
    Disclaimer,
    RtElement,
    RtLinkElement,
    RtNodeUnion,
    RtTextNode
} from '../../generated/core';
import {InteractiveDisclaimerBadge} from '../components/disclaimers/InteractiveDisclaimerBadge';
import {
    useFeatureToggles,
    useGlobalConfig,
    useSpaAsyncConfig
} from '../context';
import {
    DISCLAIMER_REFERENCE_SEPARATOR,
    useDisclaimerReferences
} from '../context/disclaimer/useDisclaimerReferences';
import {OrderedList, UnorderedList} from '../d6/components/richtext-list';
import {DropCap} from '../d6/components/drop-cap';
import {getStartDirection} from '../d6/components/helpers';
import {
    Richtext as LSGRichText,
    RichtextProps as LSGRichtextProps
} from '../d6/components/richtext';
import {C} from '../registries/compatibilty';
import {CyAttributeAppender} from '../test/CyAttributeAppender';
import {
    getHtmlStringWithNonBreakingSafewords,
    getRegExp,
    TextWithNonBreakingSafewords
} from '../utils/TextWithNonBreakingSafewords';
import {processContentId} from '../utils/processContentId';
import {CmsReferenceBadge} from './disclaimers/CmsReferenceBadge';
import {CmsButtonLink} from './links/CmsButtonLink';
import {CmsTextLink} from './links/CmsTextLink';
import {Emphasis} from './links/types';
import {HtmlText} from './richtext/HtmlText';

export interface RichtextExtraProps extends LSGRichtextProps {
    readonly useDropCap?: boolean;
    readonly placeholder?: string;
    readonly cqPath?: string;
    readonly hideItemDisclaimers?: boolean;
}

export interface RichtextModuleProps extends RichtextExtraProps {
    readonly richtext: RtNodeUnion[];
    readonly disableFormatting?: boolean;
}

export const RICHTEXT_AUTHOR_MIN_WIDTH = '120px';

const LIST_NODES = ['ol', 'ul'];
const VOID_NODES = ['br', 'hr'];
const PLACEHOLDER_TEXT = 'Type your text here...';

const StyledSup = styled.sup<{addSpace?: boolean}>`
    margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.addSpace ? props.theme.size.static100 : ''};
    margin-inline-start: ${props =>
        props.addSpace ? props.theme.size.static100 : ''};
    white-space: nowrap;
    top: 0;
    vertical-align: super;
`;
StyledSup.displayName = 'StyledSup';

const StyledAuthorRichtext = styled.span`
    min-width: ${RICHTEXT_AUTHOR_MIN_WIDTH};
`;

export interface RichtextDisclaimerProps {
    readonly disclaimers?: Disclaimer[];
    readonly addSpace?: boolean;
}

// Note: refactor to use helper function to get htmlTextWithSafewords
export function InlineDisclaimerText(props: {text: string}): JSX.Element {
    const {text} = props;
    const featureToggles = useFeatureToggles();
    const globalConfig = useGlobalConfig();
    const safewords: Record<string, string> = React.useMemo(
        () => globalConfig.safeWords || {},
        [globalConfig]
    );
    const regex = React.useMemo(() => getRegExp([...Object.keys(safewords)]), [
        safewords
    ]);

    const contentArray = React.useMemo(() => text.split(regex), [regex, text]);
    const overrideTag = 'abbr';
    const htmlTextWithSafewords = getHtmlStringWithNonBreakingSafewords(
        text,
        safewords,
        contentArray,
        overrideTag
    );

    return featureToggles?.enableRichtextDisclaimers ? (
        <HtmlText html={htmlTextWithSafewords} />
    ) : (
        <TextWithNonBreakingSafewords
            __content_is_html_and_i_know_for_sure_it_is_safe_and_wont_cause_xss_vulnerability
        >
            {text}
        </TextWithNonBreakingSafewords>
    );
}

export function RichtextDisclaimer(
    props: RichtextDisclaimerProps
): JSX.Element {
    const {disclaimers = [], addSpace = false} = props;
    const disclaimerReferences = useDisclaimerReferences(disclaimers);
    const {disclaimerLabels} = useGlobalConfig();
    const {enablePageInteractiveDisclaimers} = useSpaAsyncConfig();

    const inlineDisclaimers = disclaimers.filter(
        d => d.displayType === 'T5_INLINE'
    );
    const nonInteractiveDisclaimers = !enablePageInteractiveDisclaimers
        ? disclaimerReferences.filter(
              disclaimer =>
                  disclaimer.reference !== undefined &&
                  disclaimer.displayType === 'T2_PAGE_BASED'
          )
        : [];
    const nonOpenableDisclaimers = disclaimerReferences.filter(
        disclaimer =>
            disclaimer.reference !== undefined &&
            disclaimer.displayType !== 'T6_OPENABLE' &&
            ((!enablePageInteractiveDisclaimers &&
                disclaimer.displayType !== 'T2_PAGE_BASED') ||
                enablePageInteractiveDisclaimers)
    );
    const openableDisclaimers = disclaimerReferences.filter(
        disclaimer =>
            disclaimer.reference !== undefined &&
            disclaimer.displayType === 'T6_OPENABLE' &&
            disclaimer.toggleOpen
    );

    return (
        <React.Fragment>
            {nonInteractiveDisclaimers.length > 0 && (
                <StyledSup
                    addSpace={addSpace}
                    data-component="disclaimer-reference-badge"
                >
                    {nonInteractiveDisclaimers
                        .map(disclaimer => disclaimer.reference)
                        .join(DISCLAIMER_REFERENCE_SEPARATOR)}
                </StyledSup>
            )}
            {nonOpenableDisclaimers.length > 0 &&
                nonOpenableDisclaimers.map(disclaimer => (
                    <StyledSup
                        addSpace={addSpace}
                        key={`disclaimer-${disclaimer.reference}`}
                    >
                        &#8288;
                        <InteractiveDisclaimerBadge
                            disclaimer={disclaimer}
                            badgeToggleLabel={disclaimerLabels.badgeToggleLabel}
                        />
                    </StyledSup>
                ))}
            {openableDisclaimers.length > 0 &&
                openableDisclaimers.map(disclaimer => (
                    <StyledSup addSpace={addSpace} key={disclaimer.reference}>
                        &#8288;
                        <CmsReferenceBadge
                            reference={disclaimer.reference}
                            onClick={disclaimer.toggleOpen}
                            ariaLabel={
                                disclaimerLabels.badgeToggleLabel +
                                ' ' +
                                disclaimer.reference
                            }
                        >
                            {disclaimer.reference}
                        </CmsReferenceBadge>
                    </StyledSup>
                ))}
            {inlineDisclaimers.map((disclaimer, idx) => (
                <React.Fragment key={disclaimer.text}>
                    {addSpace && ' '}
                    <CyAttributeAppender
                        name="richtextInlineDisclaimer"
                        addTag={'span'}
                    >
                        <InlineDisclaimerText text={disclaimer.text} />
                    </CyAttributeAppender>
                    {idx !== inlineDisclaimers.length - 1 && ' '}
                </React.Fragment>
            ))}
        </React.Fragment>
    );
}

export function Richtext(props: RichtextModuleProps): JSX.Element | null {
    let firstDropCap: boolean;

    const contentId = processContentId(props.cqPath);

    const {
        richtext,
        useDropCap = false,
        appearance,
        tag,
        textAlign,
        bold,
        inheritColor,
        disableFormatting
    } = props;
    firstDropCap = useDropCap;

    if ((!richtext || richtext.length === 0) && !C.isInEditor()) {
        return null;
    }

    const items =
        richtext && richtext.length !== 0
            ? richtext.map(renderNode)
            : getPlaceholder();

    const formattedItems = disableFormatting ? (
        <>{items}</>
    ) : (
        <LSGRichText
            appearance={appearance}
            tag={tag}
            textAlign={textAlign}
            inheritColor={inheritColor}
            bold={bold}
        >
            {items}
        </LSGRichText>
    );

    let richTextElement = (
        <CyAttributeAppender name="richTextElement">
            {formattedItems}
        </CyAttributeAppender>
    );

    if (C.isInEditor()) {
        richTextElement = (
            <StyledAuthorRichtext>{richTextElement}</StyledAuthorRichtext>
        );
    }

    return richTextElement;

    function renderNode(node: RtNodeUnion, key: number): JSX.Element | string {
        switch (node.kind) {
            case 'htmlElement':
                return renderElementNode(node, key);
            case 'linkElement':
                return renderLinkNode(node, key);
            case 'disclaimer':
                return (
                    <RichtextDisclaimer
                        disclaimers={node.disclaimers}
                        key={key}
                    />
                );
            case 'textNode':
                return renderTextNode(node, key);
        }
    }

    function renderElementNode(node: RtElement, key: number): JSX.Element {
        if (LIST_NODES.indexOf(node.tagName) !== -1) {
            return renderListNode(node, key);
        } else if (VOID_NODES.indexOf(node.tagName) !== -1) {
            return renderVoidNode(node, key);
        } else {
            return renderDefaultNode(node, key);
        }
    }

    function renderDefaultNode(node: RtElement, key: number): JSX.Element {
        const tagName = node.tagName;

        return React.createElement(
            tagName,
            {key},
            node.children.map(renderNode)
        );
    }

    function renderVoidNode(node: RtElement, key: number): JSX.Element {
        return <node.tagName key={key} />;
    }

    function renderLinkNode(node: RtLinkElement, key: number): JSX.Element {
        const linkProps = {
            target: node.target,
            href: node.href,
            showExternalIcon: true,
            isLayerLink: node.layerLink,
            layerProps: {layerType: node.layerType},
            contentId: contentId,
            emphasis: 'tertiary' as Emphasis
        };

        return (
            <Text tag={TextTag.span} appearance={appearance} key={key}>
                {node.layerLink ? (
                    <CmsButtonLink {...linkProps} isRichTextLink>
                        {node.children.map(renderNode)}
                    </CmsButtonLink>
                ) : (
                    <CmsTextLink {...linkProps}>
                        {node.children.map(renderNode)}
                    </CmsTextLink>
                )}
            </Text>
        );
    }

    function renderTextNode(node: RtTextNode, key: number): JSX.Element {
        let dropCappedFirstLetter;
        let text = node.value;

        if (firstDropCap) {
            firstDropCap = false;

            if (node.value.length > 0) {
                dropCappedFirstLetter = (
                    <DropCap key="dropCap">{text.charAt(0)}</DropCap>
                );
                text = text.substring(1);
            }
        }

        return (
            <React.Fragment key={key}>
                {dropCappedFirstLetter}
                <TextWithNonBreakingSafewords>
                    {text}
                </TextWithNonBreakingSafewords>
            </React.Fragment>
        );
    }

    function renderListNode(node: RtElement, key: number): JSX.Element {
        const ListComponent =
            node.tagName === 'ol' ? OrderedList : UnorderedList;
        const listItems = node.children as RtElement[];

        return (
            <ListComponent key={key}>
                {listItems.map((li: RtElement, index) => {
                    if (!li.children) {
                        return null;
                    }

                    return (
                        <Text
                            key={index}
                            appearance={TokenTextAppearance.copy200}
                            color={TokenTextColor.inherit}
                        >
                            {li.children.map(renderNode)}
                        </Text>
                    );
                })}
            </ListComponent>
        );
    }

    function getPlaceholder(): string | undefined {
        return props.placeholder || PLACEHOLDER_TEXT;
    }
}
