import {
    Text,
    TokenTextAppearance,
    TokenTextColor,
    ThemeDefinition,
    styled
} from '@volkswagen-onehub/components-core';
import {FastForward} from '@volkswagen-onehub/icons-core';
import React from 'react';
import {
    Direction,
    makeClip,
    resetButtonStyles
} from '../../../../d6/components/helpers';
import {useResizeObserver} from '../../../../d6/hooks/useResizeObserver';
import {skipToNextContent} from './Video';
import {ScrollytellingContext} from './ScrollLogic';
import {ThemedStyledProps} from 'styled-components';

const ID = 'scrollytelling-progress';

//the color black-000-transparent-20 doesnt exist
const StyledSkipButton = styled.button`
    ${resetButtonStyles}
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;
    color: ${props => props.theme.text.color.secondary};
    background: rgba(0, 0, 0, 0.2);
    border-radius: 500px;
    gap: ${props => props.theme.size.dynamic0020};
    padding-block: ${props => props.theme.size.static100};
    padding-inline: ${props => props.theme.size.static150}
        ${props => props.theme.size.static200};
    border: 1px solid ${props => props.theme.colors.border.default};
    height: ${props => props.theme.size.static400};
    svg {
        transform: rotateY(
            ${props =>
                props.theme.direction === Direction.RTL ? '180deg' : '0'}
        );
    }

    &:hover,
    &:focus {
        background: ${props => props.theme.colors.focus.accent};
        color: ${props => props.theme.colors.content.inverted};
    }
    &:focus {
        outline: 2px solid ${props => props.theme.colors.focus.main};
        outline-offset: 4px;
    }
`;

const StyledLabel = styled.label.attrs(
    (props: ThemedStyledProps<{scrollProgress: number}, ThemeDefinition>) => ({
        style: {
            transform: `translateX(${
                props.theme.direction === Direction.RTL
                    ? -props.scrollProgress
                    : props.scrollProgress
            }px) translateX(-50%) ${
                props.theme.direction === Direction.RTL
                    ? 'translateX(100%)'
                    : ''
            }`
        }
    })
)<{scrollProgress: number}>`
    position: absolute;
    top: -${props => props.theme.size.static250};
    color: ${props => props.theme.colors.content.primary};
`;

const StyledProgress = styled.progress`
    width: 100%;
    height: ${props => props.theme.size.static150};
    border-radius: 100px;
    overflow: hidden;
    background-color: ${props => props.theme.colors.overlay.default};
    border: 1px solid transparent;

    &[value]::-webkit-progress-bar {
        background-color: transparent;
    }
    &[value]::-webkit-progress-value {
        background-color: ${props => props.theme.colors.content.primary};
        border-radius: 100px;
    }
    &[value]::-moz-progress-bar {
        background-color: ${props => props.theme.colors.content.primary};
        border-radius: 100px;
    }
`;

export const StyledSectionSkipper = styled.div`
    display: grid;
    grid-template-columns: 1fr max-content;
    gap: ${props => props.theme.size.static250};
    align-items: center;

    & span {
        ${makeClip}
    }
`;

export interface SkipButtonProps {
    skipButtonLabel: string;
    trackOnClick: (progress: number) => void;
}

export const SkipButton = (props: SkipButtonProps) => {
    const {skipButtonLabel, trackOnClick} = props;
    const [progress, setProgress] = React.useState(0);
    const [currentWidth, setCurrentWidth] = React.useState(0);
    const widthRef = React.useRef<HTMLProgressElement>(null);
    const {subscribe} = React.useContext(ScrollytellingContext);

    React.useEffect(() => {
        subscribe(scrollAmount => {
            setProgress(scrollAmount.video);
        });
    }, [subscribe]);

    useResizeObserver(widthRef, (_, width) => {
        setCurrentWidth(width);
    });

    return (
        <StyledSectionSkipper>
            <StyledLabel
                htmlFor={ID}
                scrollProgress={Math.max(
                    0,
                    Math.min(currentWidth, Math.round(progress * currentWidth))
                )}
            >
                {Math.max(0, Math.min(100, Math.round(progress * 100)))}%
            </StyledLabel>
            <StyledProgress
                value={progress * 100}
                max={100}
                id={ID}
                ref={widthRef}
            ></StyledProgress>
            <StyledSkipButton
                onClick={event => {
                    skipToNextContent(event);
                    trackOnClick(progress);
                }}
            >
                <FastForward variant="small" />
                <Text
                    color={TokenTextColor.inherit}
                    appearance={TokenTextAppearance.label150}
                >
                    {skipButtonLabel ?? 'Skip'}
                </Text>
            </StyledSkipButton>
        </StyledSectionSkipper>
    );
};
