import * as React from 'react';
import styled from 'styled-components';

import {BaseCmsLinkProps, useCmsLink} from './CmsLink';

export interface CmsPlainLinkProps
    extends React.PropsWithChildren<any>,
        BaseCmsLinkProps {
    tabIndex?: number;
}

const StyledPlainLink = styled.a`
    text-decoration: none;
`;

export function CmsPlainLink(props: CmsPlainLinkProps): React.ReactElement {
    const ref = React.useRef(null);
    const [onCmsPlainLink, href] = useCmsLink(ref, props);

    return (
        <StyledPlainLink
            {...props}
            href={href}
            ref={ref}
            onClick={onCmsPlainLink}
        >
            {props.children}
        </StyledPlainLink>
    );
}
