import {getGlobal} from '../getGlobal';

const createRegExpForKey = (key: string) => {
    const regExpString = `%%${key}`;

    return new RegExp(regExpString);
};

export const replaceTranslationPlaceholders = (
    originalString: string,
    replacements: {[key: string]: string}
): string => {
    let result = originalString;

    if (!originalString) {
        return originalString;
    }

    for (const key in replacements) {
        if (replacements[key]) {
            const regExpForKey = createRegExpForKey(key);
            const highlight = key === 'searchPhrase';

            if (originalString.match(regExpForKey) === null) {
                getGlobal().log.layer.debug(
                    `No placeholder found for translation replacement key: ${key} in string: ${originalString}`
                );
            }

            if (highlight) {
                result = result.replace(
                    regExpForKey,
                    `&ldquo;<strong>${replacements[key]}</strong>&rdquo;`
                );
            } else {
                result = result.replace(regExpForKey, replacements[key]);
            }
        }
    }

    return result;
};
