import * as React from 'react';
import {VideoPlayerProxy} from '../../../../../services/video/VideoPlayerProxy';

function handleLoadedMetaData(
    playerProxy: VideoPlayerProxy,
    wasPlaying: boolean,
    lastTime: number
): void {
    const videoElement = playerProxy.getPlayer()?.getVideoElement()?.current;

    if (!videoElement) {
        return;
    }

    videoElement.addEventListener(
        'loadedmetadata',
        () => {
            playerProxy.setTime(lastTime);
            if (!wasPlaying) {
                return;
            }
            playerProxy.play();
        },
        {once: true}
    );
}

export function useVideoRenditionTransition(
    rendition: string | undefined,
    videoPlayerProxy: React.RefObject<VideoPlayerProxy>
): void {
    const [prevRendition, setPrevRendition] = React.useState<
        string | undefined | null
    >(rendition);

    const playerProxy = videoPlayerProxy.current;
    let lastTime = 0;

    if (prevRendition !== rendition && playerProxy) {
        lastTime = playerProxy.getTime() || 0;
    }

    React.useEffect(() => {
        if (
            !playerProxy?.isInitialized ||
            !prevRendition ||
            rendition === prevRendition
        ) {
            return;
        }
        const wasPlaying = playerProxy.isPlaying;

        playerProxy.pause();
        playerProxy.load();
        handleLoadedMetaData(playerProxy, wasPlaying, lastTime);
    }, [playerProxy, lastTime, rendition, prevRendition]);

    React.useEffect(() => {
        if (rendition !== prevRendition) {
            setPrevRendition(rendition);
        }
    }, [rendition, prevRendition]);
}
