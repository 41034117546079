import {
    IObservableArray,
    action,
    computed,
    observable,
    ObservableMap,
    observe
} from 'mobx';
import React from 'react';

import {
    ReferenceDisclaimerKind,
    ScopedDisclaimerManager
} from '@volkswagen-onehub/disclaimer-manager';
import {LayerHandleV2, LayerManagerV24} from '@volkswagen-onehub/layer-manager';
import {
    Disclaimer as GeneratedDisclaimer,
    GlobalDisclaimerRegistryModel
} from '../../../generated/core';
import {OpenableDisclaimer} from '../../components/disclaimers/OpenableDisclaimer';
import {getDistanceToMoveElementIntoView} from '../../utils/isElementInView';
import {smoothScrollTo} from '../../utils/smoothScroll';
import {mapLegallyReferences} from './helpers';
import {RouterService} from '../route/RouterService';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {Subscription} from '@volkswagen-onehub/feature-hub-utils';
import {NavigationStore} from '../navigation/NavigationStore';
import {ReducedFullTextCallback} from '../../components/disclaimers/InteractiveDisclaimerBadge';

export interface DisclaimerWithReference extends GeneratedDisclaimer {
    reference?: string;
    legalEntity?: string;
    namedReference?: string[];
}

export interface GroupedDisclaimersWithReference {
    readonly legalEntity?: string;
    readonly legalEntityLabel?: string;
    readonly globalDisclaimers: DisclaimerWithReference[];
    readonly referenceDisclaimers: DisclaimerWithReference[];
}

export interface DisclaimerReference {
    readonly reference?: string;

    unregister(): void;
}

export type RegisteredDisclaimerListener = (event: 'unregister') => void;

export interface RegisteredDisclaimer
    extends GeneratedDisclaimer,
        DisclaimerReference {
    toggleOpen?: () => void;
    referenceClickHandler?(
        interactiveRef: React.RefObject<HTMLButtonElement>,
        fullTextRef?: React.RefObject<HTMLDivElement>,
        fullTextCallback?: ReducedFullTextCallback,
        scrollableElement?: HTMLElement,
        disableScroll?: boolean
    ): void;
    subscribe(cb: RegisteredDisclaimerListener): () => void;
}

export interface DisclaimerAttributes extends DisclaimerWithReference {
    references: number;
}

export interface DisclaimerStore {
    footerDisclaimers: GroupedDisclaimersWithReference[];
    openableDisclaimer?: RegisteredDisclaimer;

    add(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string,
        disableScroll?: boolean,
        keepWhenUnregistered?: boolean
    ): RegisteredDisclaimer;
}

interface OpenableDisclaimerState {
    key: string;
    reference: string;
    text: string;
}

/**
 * TYPE_1 - Global - Reference at the asset + full text in the footer
 * TYPE_2 - Page based - Reference at the asset + full text at the module
 * TYPE_3 - Section based - Reference number + displayed bellow section
 * TYPE_4 - Item based - Reference number + displayed bellow the item
 * TYPE_5 - Inline - Full-Text within the text item
 * TYPE_6 - Openable - Reference toggle button + displayed fixed at the bottom of the display
 */
export class StandardDisclaimerStore implements DisclaimerStore {
    private disclaimerManager!: ScopedDisclaimerManager;

    private layerManager!: LayerManagerV24;

    private disclaimerLayerHandle?: LayerHandleV2<OpenableDisclaimerState>;

    /**
     * Should be handled without openableDisclaimerUniqueNumber
     * by disabling of automatic focus handling from the `FocusTrap` (NGWD6-13250)
     */
    private openableDisclaimerUniqueNumber: number = 0;

    @observable private groupedDisclaimers: IObservableArray<
        GroupedDisclaimersWithReference
    > = observable.array([]);

    @observable public openableDisclaimers: ObservableMap<
        string,
        RegisteredDisclaimer
    > = observable.map(new Map());

    @observable public activeOpenableDisclaimerKey: string | null = null;
    private routerObserveHandle?: () => void;
    private navigationObserveHandle?: () => void;
    private disclaimerSubscription?: Subscription;

    public constructor(
        disclaimerManager: ScopedDisclaimerManager,
        layerManager: LayerManagerV24,
        globalDisclaimerRegistryModel: GlobalDisclaimerRegistryModel,
        private readonly routerService: RouterService,
        private readonly navigationStore: NavigationStore
    ) {
        this.disclaimerManager = disclaimerManager;
        this.disclaimerSubscription = this.disclaimerManager.subscribe(() => {
            this.groupedDisclaimers.replace(
                this.disclaimerManager
                    .getLegallyGroupedDisclaimers()
                    .map(mapLegallyReferences)
            );
        });

        this.layerManager = layerManager;

        globalDisclaimerRegistryModel.globalDisclaimers.forEach(disclaimer =>
            disclaimerManager.registerGlobalDisclaimer(disclaimer.text)
        );
        if (isInBrowser()) {
            const closeLayer = () => {
                if (this.disclaimerLayerHandle) {
                    this.disclaimerLayerHandle.close();
                    this.disclaimerLayerHandle = undefined;
                }
            };

            this.navigationObserveHandle = observe(
                this.navigationStore,
                'isFlyoutMenuOpen',
                closeLayer
            );
            this.routerObserveHandle = observe(
                this.routerService,
                'pagePath',
                closeLayer
            );
        }
    }

    @computed
    public get footerDisclaimers(): GroupedDisclaimersWithReference[] {
        return this.groupedDisclaimers;
    }

    @computed
    public get openableDisclaimer(): RegisteredDisclaimer | undefined {
        if (this.activeOpenableDisclaimerKey) {
            return this.openableDisclaimers.get(
                this.activeOpenableDisclaimerKey
            );
        }

        return undefined;
    }

    @action
    public add(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string,
        disableScroll?: boolean
    ): RegisteredDisclaimer {
        switch (disclaimer.displayType) {
            case 'T1_GLOBAL': {
                this.addGlobalDisclaimer(disclaimer, legalEntity);
                const unregister = () =>
                    this.unregister(disclaimer, legalEntity);

                return {
                    ...disclaimer,
                    unregister,
                    subscribe: _cb => () => 0
                };
            }
            case 'T2_PAGE_BASED':
            case 'T3_SECTION_BASED':
            case 'T4_ITEM_BASED':
                return this.addInteractiveDisclaimer(
                    disclaimer,
                    legalEntity,
                    disableScroll
                );
            case 'T5_INLINE': {
                const unregister = () => void 0;

                return {
                    ...disclaimer,
                    unregister,
                    subscribe: _cb => () => 0
                };
            }
            case 'T6_OPENABLE':
                return this.addOpenableDisclaimer(disclaimer, legalEntity);
            default:
                throw new Error('unsupported type');
        }
    }

    @action
    public unregister(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string
    ): void {
        switch (disclaimer.displayType) {
            case 'T1_GLOBAL':
                this.unregisterGlobalDisclaimer(disclaimer.text, legalEntity);
                break;
            case 'T2_PAGE_BASED':
                this.unregisterReferenceDisclaimer(
                    disclaimer.text,
                    'footer-reference',
                    legalEntity
                );
                break;
            case 'T3_SECTION_BASED':
            case 'T4_ITEM_BASED':
                this.unregisterReferenceDisclaimer(
                    disclaimer.text,
                    'module-reference',
                    legalEntity
                );
                break;

            case 'T5_INLINE':
                return;
        }
    }

    public addGlobalDisclaimer(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string
    ): void {
        this.disclaimerManager.registerGlobalDisclaimer(
            disclaimer.text,
            legalEntity
        );
    }

    private addReferenceDisclaimer(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string
    ): RegisteredDisclaimer {
        const kind =
            disclaimer.displayType === 'T2_PAGE_BASED'
                ? 'footer-reference'
                : 'module-reference';
        const ref = this.disclaimerManager.registerReferenceDisclaimer(
            kind,
            disclaimer.text,
            undefined,
            legalEntity
        );
        return {
            ...disclaimer,
            reference: ref,

            ...createSubscribableDisclaimer(
                disclaimer,
                this.disclaimerManager,
                kind,
                legalEntity
            )
        };
    }

    @action
    private addInteractiveDisclaimer(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string,
        disableScroll?: boolean
    ): RegisteredDisclaimer {
        const registeredDisclaimer = this.addReferenceDisclaimer(
            disclaimer,
            legalEntity
        );

        const {reference} = registeredDisclaimer;

        const interactiveDisclaimer = {
            ...registeredDisclaimer,
            referenceClickHandler: (
                interactiveRef: React.RefObject<HTMLButtonElement>,
                fullTextRef?: React.RefObject<HTMLDivElement>,
                fullTextCallback?: (
                    scrollPosition: number,
                    disableScroll: boolean
                ) => void,
                scrollableElement?: HTMLElement
            ): void => {
                this.handleInteractiveDisclaimerClick(
                    interactiveRef,
                    fullTextRef,
                    fullTextCallback,
                    scrollableElement,
                    reference,
                    disableScroll
                );
            }
        };

        return {
            ...interactiveDisclaimer
        };
    }

    @action
    private handleInteractiveDisclaimerClick(
        interactiveRef: React.RefObject<HTMLButtonElement>,
        textRef?: React.RefObject<HTMLDivElement>,
        fullTextCallback?: ReducedFullTextCallback,
        scrollableElement: HTMLElement = document.documentElement,
        disclaimerReference?: string,
        disableScroll?: boolean
    ): void {
        if (
            !textRef?.current ||
            !interactiveRef?.current ||
            !disclaimerReference
        ) {
            return;
        }

        const currentScrollPosition = scrollableElement.scrollTop;
        const offset =
            (textRef.current.getBoundingClientRect()?.top || 0) -
            window.innerHeight * 0.33; // scrolls to the center top position where the most focus is
        const newScrollPosition = currentScrollPosition + offset;

        const distanceToMoveReferenceBadgeIntoView = interactiveRef.current
            ? getDistanceToMoveElementIntoView(interactiveRef.current, {
                  safeGuardingDistance: window.innerHeight * 0.15,
                  customScrollingDistance: window.innerHeight * 0.15
              })
            : 0;

        const scrollPositionWithSafeGuards =
            currentScrollPosition + distanceToMoveReferenceBadgeIntoView;

        const focusToTargetElement = () => {
            if (fullTextCallback) {
                fullTextCallback(
                    scrollPositionWithSafeGuards,
                    Boolean(disableScroll)
                );
            }

            return textRef.current?.focus();
        };

        if (!disableScroll) {
            smoothScrollTo({
                distance: newScrollPosition,
                scrollableElement: scrollableElement,
                onScrollEnd: focusToTargetElement
            });
        } else {
            focusToTargetElement();
        }
    }

    @action
    private addOpenableDisclaimer(
        disclaimer: DisclaimerWithReference,
        legalEntity?: string
    ): RegisteredDisclaimer {
        const registeredDisclaimer = this.addReferenceDisclaimer(
            disclaimer,
            legalEntity
        );
        const {reference, text} = registeredDisclaimer;

        this.openableDisclaimerUniqueNumber++;
        const key = `openable-disclaimer-${this.openableDisclaimerUniqueNumber}`;

        const openableDisclaimer = {
            ...registeredDisclaimer,
            toggleOpen: () => {
                this.toggleActiveOpenableDisclaimer(
                    reference as string,
                    text,
                    key
                );
            }
        };
        this.openableDisclaimers.set(key, openableDisclaimer);

        const unregister = () => {
            if (this.activeOpenableDisclaimerKey === key) {
                this.toggleActiveOpenableDisclaimer(
                    reference as string,
                    text,
                    key
                );
            }
            openableDisclaimer.unregister();
        };

        return {
            ...openableDisclaimer,
            unregister
        };
    }

    @action
    private toggleActiveOpenableDisclaimer(
        reference: string,
        text: string,
        key: string
    ): void {
        if (reference) {
            if (this.activeOpenableDisclaimerKey === key) {
                this.activeOpenableDisclaimerKey = null;

                if (this.disclaimerLayerHandle) {
                    this.disclaimerLayerHandle.close();
                    this.disclaimerLayerHandle = undefined;
                }
            } else {
                if (this.disclaimerLayerHandle) {
                    this.disclaimerLayerHandle.close();
                    this.disclaimerLayerHandle = undefined;
                }

                this.activeOpenableDisclaimerKey = key;
                const openableDisclaimerState = {
                    key: key,
                    reference: reference,
                    text: text
                };

                // this will close the layer independent of its current state
                const closeOpenableDisclaimer = () => {
                    const openableDisclaimer = this.openableDisclaimer;
                    if (
                        openableDisclaimer &&
                        openableDisclaimer.reference === reference &&
                        openableDisclaimer.toggleOpen
                    ) {
                        openableDisclaimer.toggleOpen();
                    }
                };

                const renderOpenableDisclaimer = (
                    state: OpenableDisclaimerState
                ) => (
                    <OpenableDisclaimer
                        text={state.text}
                        reference={state.reference}
                        closeButtonClick={closeOpenableDisclaimer}
                    />
                );

                this.disclaimerLayerHandle = this.layerManager.openDisclaimerLayer(
                    renderOpenableDisclaimer,
                    openableDisclaimerState,
                    {
                        userCloseable: true,
                        onClose: closeOpenableDisclaimer
                    }
                );
            }
        }
    }

    @action
    private unregisterReferenceDisclaimer(
        text: string,
        kind: ReferenceDisclaimerKind,
        legalEntity?: string
    ): void {
        this.disclaimerManager.unregisterReferenceDisclaimer(
            kind,
            text,
            undefined,
            legalEntity
        );
    }

    @action
    private unregisterGlobalDisclaimer(
        text: string,
        legalEntity?: string
    ): void {
        this.disclaimerManager.unregisterGlobalDisclaimer(text, legalEntity);
    }

    public cleanup(): void {
        if (this.navigationObserveHandle) {
            this.navigationObserveHandle();
        }
        if (this.routerObserveHandle) {
            this.routerObserveHandle();
        }
        if (this.disclaimerSubscription) {
            this.disclaimerSubscription.unsubscribe();
        }
    }
}

const createSubscribableDisclaimer = (
    disclaimer: GeneratedDisclaimer,
    disclaimerManager: ScopedDisclaimerManager,
    kind: ReferenceDisclaimerKind,
    legalEntity?: string
) => {
    const listeners: RegisteredDisclaimerListener[] = [];
    return {
        unregister() {
            listeners.forEach((cb: RegisteredDisclaimerListener) =>
                cb('unregister')
            );
            disclaimerManager.unregisterReferenceDisclaimer(
                kind,
                disclaimer.text,
                undefined,
                legalEntity
            );
        },
        subscribe(cb: RegisteredDisclaimerListener) {
            listeners.push(cb);
            return () => {
                const index = listeners.indexOf(cb);
                if (index >= 0) {
                    listeners.splice(index, 1);
                }
            };
        }
    };
};
