import urljoin from 'url-join';
import {isAbsoluteUrl} from './isAbsoluteUrl';

/**
 * Prepends the given `base` - which can be relative or absolute - to the `url`,
 * unless the `url` is absolute.
 */
export function prependBaseUrl(base: string | undefined, url: string): string {
    if (!base || isAbsoluteUrl(url)) {
        return url;
    }

    return urljoin(base, url);
}
