// Temporary icons extracted from sketch. Get from icon project when ready.
import {
    Text,
    TokenTextAppearance,
    TokenTextColor,
    getGridColumn,
    styled
} from '@volkswagen-onehub/components-core';

import {Menu} from '@volkswagen-onehub/icons-core';
import React from 'react';

import {
    Direction,
    buttonFocusStateCSS,
    getStartDirection,
    increasedIconTabArea
} from '../helpers';
import {resetButtonStyles} from '../helpers/css';
import {NAVIGATION_FLYOUT_ID} from '../navigation-flyout-onehub/constants';

const elementMaxSize = 24;

interface StyledMenuProps {
    customTabArea?: number;
}

export const StyledTopBarButton = styled.button<StyledMenuProps>`
    ${resetButtonStyles()};
    ${props => buttonFocusStateCSS(props)};
    ${props => increasedIconTabArea(props)};
    position: relative;

    display: flex;
    align-items: center;
    width: auto;
    height: ${elementMaxSize}px;
    text-align: ${props => getStartDirection(props.theme.direction)};

    // NOTE: get rid off inline element space under
    font-size: 0;

    color: ${props => props.theme.colors.button.primary.default};
`;

const StyledMenuIcon = styled.div`
    display: inline-block;
    ${props =>
        props.theme.direction === Direction.RTL && 'transform: scaleX(-1);'}
`;

const StyledMenuLabel = styled.div`
    display: inline-block;
    box-sizing: border-box;
    padding-top: 1px; /* vertical align fix */
    padding-${props => getStartDirection(props.theme.direction)}: 10px;
    max-width: calc(${getGridColumn(6)});
    overflow: hidden;
`;

const StyledShoppingIconWrapper = styled.div`
    ${props =>
        props.theme.direction === Direction.RTL && 'transform: scaleX(-1);'}
`;

export interface ButtonProps {
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
    readonly menuLabel?: string;
    readonly ariaLabel?: string;
    readonly buttonRef?: React.RefObject<HTMLButtonElement>;
    readonly ariaExpanded?: boolean;
}

export function MenuButton(props: ButtonProps): React.ReactElement {
    const {onClick, menuLabel, ariaLabel, buttonRef, ariaExpanded} = props;

    return (
        <nav aria-label={ariaLabel}>
            <StyledTopBarButton
                onClick={onClick}
                aria-controls={NAVIGATION_FLYOUT_ID}
                aria-expanded={ariaExpanded}
                aria-label={ariaLabel}
                ref={buttonRef}
            >
                <StyledMenuIcon>
                    <Menu ariaHidden />
                </StyledMenuIcon>
                {menuLabel && (
                    <StyledMenuLabel>
                        <Text
                            appearance={TokenTextAppearance.copy100}
                            bold
                            staticSize
                            color={TokenTextColor.inherit}
                        >
                            {menuLabel}
                        </Text>
                    </StyledMenuLabel>
                )}
            </StyledTopBarButton>
        </nav>
    );
}

export function SearchButton(props: ButtonProps): React.ReactElement {
    const {onClick, ariaLabel} = props;

    // NOTE: Custom SVGs with 2px lines. "Magnifier" and "Shopping cart" from icons-core has 1.33px width and is used on other places by different teams, therefore this is added manually here as exception.

    return (
        <StyledTopBarButton onClick={onClick} aria-label={ariaLabel}>
            <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                role="img"
                aria-hidden="true"
            >
                <path d="M22.771 21.601l-5.591-5.979a9.06 9.06 0 001.837-5.624 8.723 8.723 0 00-9-9 8.699 8.699 0 00-9 9 8.69 8.69 0 009 9 9.18 9.18 0 005.764-1.944l5.53 5.913zm-12.754-4.603a6.697 6.697 0 01-7-7 6.697 6.697 0 017-7 6.697 6.697 0 017 7 6.697 6.697 0 01-7 7z" />
            </svg>
        </StyledTopBarButton>
    );
}

export function ShoppingIconButton(props: ButtonProps): React.ReactElement {
    const {onClick, ariaLabel} = props;

    return (
        <StyledTopBarButton onClick={onClick} aria-label={ariaLabel}>
            <StyledShoppingIconWrapper>
                <svg
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    role="img"
                    aria-hidden="true"
                >
                    <path d="M0,3l2.4,0l3.8,17H20v-2H7.8l-0.7-3h13.4c0.3,0,0.6-0.3,0.7-0.6L23,4.9c0,0,0-0.1,0-0.1C23,4.4,22.7,4,22.3,4H4.7L4,1H0  C0,1,0,3,0,3z M6.7,13L5.1,6h15.6l-1.3,7H6.7z" />
                    <circle cx="9.8" cy="22.5" r="1.5" />
                    <circle cx="18.8" cy="22.5" r="1.5" />
                    <g>
                        <polygon points="16,8 16,11 18,11 18,8 16,8  " />
                        <polygon points="12,8 12,11 14,11 14,8 12,8  " />
                        <polygon points="8,8 8,11 10,11 10,8 8,8  " />
                    </g>
                </svg>
            </StyledShoppingIconWrapper>
        </StyledTopBarButton>
    );
}
