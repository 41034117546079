import {
    IntegratorRoot,
    ThemeContextOptions,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import styled from 'styled-components';

import {DisplayType} from '../../../generated/core';
import {
    useContentStore,
    useFeatureToggles,
    useGlobalConfig,
    useGlobalDisclaimerStore,
    useRouterService
} from '../../context';
import {DisclaimerStore} from '../../context/disclaimer/DisclaimerStore';
import {DisclaimerStoreContext} from '../../context/disclaimer/DisclaimerStoreContext';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';
import {MediaQueryContextProvider} from '../../context/mediaQueries/mediaQueryContext';
import {useBrowserWindowHeight} from '../../d6/components/helpers/useBrowserWindowHeight';
import {useDirection} from '../../hooks/useDirection';
import {C} from '../../registries/compatibilty';
import {ValueFactory} from '../../utils/contextUtils';
import {convertLocationToPath} from '../../utils/convertLocationToPath';
import {isTemplateEditor} from '../../utils/isTemplateEditor';
import {isXfPreview} from '../../utils/xfUtils';
import {ZIndex} from '../../utils/zIndex';
import {FeatureAppLayers} from './FeatureAppLayers';
import {PageLoader} from './PageLoader';
import {Footer} from './footer/Footer';
import {AnnouncementBar} from './navigation/AnnouncementBar';
import {SkiplinkContainer} from './navigation/SkipLinks';
import {TopBar} from './navigation/TopBar';

const THEME_CONTEXT_OPTIONS: ThemeContextOptions = {useNewTokens: true};

export const PAGE_ROOT_TYPE = 'vwa-ngw18/components/structure/pageroot';

interface StyledMainContentWrapperProps {
    readonly fullHeight?: boolean;
}

const StyledContentWrapper = styled.div`
    // NOTE: NGWD6-34354 - Temporary background for TikTok in-app browser before merge of core-components update with NGWD6-34293. Afterwards this can be removed again.
    background: white;
`;
StyledContentWrapper.displayName = 'StyledContentWrapper';

const StyledMainContentWrapper = styled.main<StyledMainContentWrapperProps>`
    ${({fullHeight}) => fullHeight && 'min-height: 100vh;'}
    z-index: ${ZIndex.main.z};
    position: relative;
`;
StyledMainContentWrapper.displayName = 'StyledMainContentWrapper';

const PageRoot: React.FunctionComponent = observer(function PR() {
    const routerService = useRouterService();
    const globalDisclaimerStore = useGlobalDisclaimerStore();
    const spaGlobalConfig = useGlobalConfig();
    const featureToggles = useFeatureToggles();
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const direction = useDirection();
    useBrowserWindowHeight();

    const disclaimerContextValueFactory: ValueFactory<DisclaimerStore> = React.useRef(
        new ValueFactory<DisclaimerStore>()
    ).current;
    const globalDisclaimerContextValueFactory: ValueFactory<DisclaimerStore> = React.useRef(
        new ValueFactory<DisclaimerStore>()
    ).current;

    const Page = React.useCallback(
        (props: RouteComponentProps): JSX.Element => {
            const path = convertLocationToPath(props.location.pathname);

            if (path !== null) {
                const store = globalDisclaimerStore.getOrCreateStore(path);
                const value = disclaimerContextValueFactory.create(store);

                return (
                    <DisclaimerStoreContext.Provider value={value}>
                        <PageLoader
                            path={path}
                            loadingLabel={spaGlobalConfig.pageLoadingLabel}
                        />
                    </DisclaimerStoreContext.Provider>
                );
            }

            return <span>invalid url</span>;
        },
        [globalDisclaimerStore, disclaimerContextValueFactory, spaGlobalConfig]
    );

    function renderRouter(): JSX.Element {
        const xfPreview = isXfPreview(routerService.pagePath);
        const xfTemplateEditor = isTemplateEditor(routerService.pagePath);
        const toggles = pageRootModel ? pageRootModel.togglesModel : undefined;
        const showNavigation = !toggles || !toggles.hideNavigation;
        const showFooter = !toggles || !toggles.hideFooter;
        const isNotXf = !xfPreview && !xfTemplateEditor;
        const fullHeight =
            featureToggles.enableFAContainerMinHeightFull && !C.isInEditor();
        const permanentStore = globalDisclaimerContextValueFactory.create(
            globalDisclaimerStore.permanent
        );

        // for xf preview we just allow all disclaimers to be registered even if their text is not displayed anywhere
        const displayTypes: DisplayType[] = xfPreview
            ? ['T2_PAGE_BASED', 'T3_SECTION_BASED', 'T4_ITEM_BASED']
            : ['T2_PAGE_BASED'];

        return routerService.wrapWithRouter(
            <>
                <DisclaimerStoreContext.Provider value={permanentStore}>
                    <GeneralDisclaimerProvider displayTypes={displayTypes}>
                        <StyledContentWrapper>
                            {isNotXf && <SkiplinkContainer />}
                            {isNotXf &&
                                pageRootModel &&
                                pageRootModel.xfAnnouncement && (
                                    <AnnouncementBar />
                                )}
                            {isNotXf && showNavigation && (
                                <GeneralDisclaimerProvider
                                    displayTypes={['T2_PAGE_BASED']}
                                    keepWhenUnregistered
                                    allowNested
                                >
                                    <TopBar />
                                </GeneralDisclaimerProvider>
                            )}
                            <StyledMainContentWrapper fullHeight={fullHeight}>
                                <Route path="/" component={Page} />
                            </StyledMainContentWrapper>
                        </StyledContentWrapper>
                        {isNotXf && showFooter && <Footer />}
                    </GeneralDisclaimerProvider>
                </DisclaimerStoreContext.Provider>
                {isNotXf && <FeatureAppLayers />}
            </>
        );
    }

    return (
        <ThemeProvider
            themeContextOptions={THEME_CONTEXT_OPTIONS}
            direction={direction}
            useNewTokens={true}
        >
            <IntegratorRoot>
                <MediaQueryContextProvider>
                    {renderRouter()}
                </MediaQueryContextProvider>
            </IntegratorRoot>
        </ThemeProvider>
    );
});
PageRoot.displayName = 'PageRoot';

export {PageRoot};
