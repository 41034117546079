import * as React from 'react';

import {TokenTextAppearance} from '@volkswagen-onehub/components-core';
import {Statement} from '../../../d6/components/statement';

import {StatementItemModel as StatementItemProps} from '../../../../generated/core';
import {AlertBox} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {RichtextSimpleElement} from '../elements/RichtextElement';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';

/**
 * Statement Item
 */
function InternalStatementItem(props: StatementItemProps): JSX.Element | null {
    const {validationError, validationMessages} = props;

    if (validationError && !C.isInEditor()) {
        return null;
    }

    const statement = (
        <RichtextSimpleElement
            path="statement"
            appearance={TokenTextAppearance.headline500}
        />
    );

    const alertBox =
        validationError && C.isInEditor() ? (
            <AlertBox>
                {validationMessages.map((validationMessage, idx) => (
                    <div key={idx}>{validationMessage}</div>
                ))}
            </AlertBox>
        ) : null;

    return (
        <AuthoringWrapper
            title="I103 Statement Item"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            {alertBox}
            <CyAttributeAppender name="statementItem" addTag="div">
                <SimpleDisclaimerWrapper>
                    <Statement>{statement}</Statement>
                </SimpleDisclaimerWrapper>
            </CyAttributeAppender>
        </AuthoringWrapper>
    );
}

export const StatementItem = MapTo(
    'vwa-ngw18/components/editorial/items/statementItem',
    InternalStatementItem
);
