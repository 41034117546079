import {FeatureHub} from '@feature-hub/core';
import {HistoryServiceV2} from '@feature-hub/history-service';
import {
    OpenedLayerModel,
    XfCarFeatureModel,
    XfContentLayerMainModel,
    XfInteractionLayerMainModel
} from '../../../generated/core';
import {createCustomHistoryService} from '../../infrastructure/hub/createCustomHistoryService';
import {
    LAYER_KEY_AND_EXT,
    SHOWROOM_NAVIGATION_CONSUMER_ID
} from '../../infrastructure/hub/initializeHub';
import {RouterService} from '../route/RouterService';

export const LAYER_CONSUMER_ID = 'cms-layer-store';
export const CONTENT_ROOT_MAIN = '/jcr:content/root/main';
export const HISTORY_ID = 's2:history';

export type XfLayerModel =
    | XfContentLayerMainModel
    | XfCarFeatureModel
    | XfInteractionLayerMainModel;

export interface LayerData {
    model?: XfLayerModel;
    includePath: string;
}

export const createLayerHistoryService = (featureHub: FeatureHub) => {
    return createCustomHistoryService(featureHub, LAYER_CONSUMER_ID);
};

export const appendRootMainIfNecessary = (path: string) => {
    return !path.endsWith(CONTENT_ROOT_MAIN)
        ? `${path}${CONTENT_ROOT_MAIN}`
        : path;
};

export const getResourcePath = (
    path: string,
    openedLayerModel: OpenedLayerModel
) => {
    return `${openedLayerModel.basePath}${path.substring(
        0,
        path.length - ('.' + LAYER_KEY_AND_EXT).length
    )}`;
};

export const getLayerHref = (
    layer: string,
    layerHistoryService: HistoryServiceV2,
    routerService: RouterService
) => {
    const layerLocation = {
        pathname: layer
    };
    const cmsLocation = routerService.getLocation();
    // create the url for cms and layer locations.
    const rootLocation = layerHistoryService.createNewRootLocationForMultipleConsumers(
        {
            historyKey: SHOWROOM_NAVIGATION_CONSUMER_ID,
            location: cmsLocation
        },
        {historyKey: LAYER_CONSUMER_ID, location: layerLocation}
    );

    return layerHistoryService.rootHistory.createHref(rootLocation);
};

export interface LayerStore {
    /**
     * Opens a new content layer with the data specified by the resource path. If the data is not present yet, it is fetched from the server first.
     * @param path The resource path of the layer
     */
    openLayer(path: string): Promise<LayerData | undefined>;

    /**
     * Closes the layer specified by the path.
     * @param path The resource path or the include path of the layer
     */
    closeLayer(path: string): void;

    /**
     * Closes all currently open layers.
     */
    closeAllLayers(): void;

    createLayerHref(layer: string): string;

    /**
     * has opened layer on SSR
     */
    hasOpenedLayer(): boolean;
}
