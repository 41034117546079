import {useEffect, useRef, useCallback} from 'react';
import {useSectionGroupContext, useTrackingService} from '../../../context';
import {
    ActionScriptReport,
    ReportingSourceId
} from '../../../context/tracking/TrackingService';
import {SectionNbabTrackingData} from './getActions';
import {MergeNextBestActionModel} from './mergePersonalizedActions';

export const useDisplayTracking = (
    isNbabVisible: boolean | undefined,
    actions: MergeNextBestActionModel[],
    pageActions: boolean,
    trackingData?: SectionNbabTrackingData,
    sensitivityTimeout: number = 300
) => {
    const trackingService = useTrackingService();
    const trackedActions = useRef<MergeNextBestActionModel[]>([]);
    const {sectionGroupContentId} = useSectionGroupContext();
    const noop = () => {
        // this function is intentionally left blank
    };
    const handleTrackingRef = useRef(noop);

    const handleTracking = useCallback(() => {
        if (!isEqualArray(trackedActions.current, actions)) {
            const actionScriptReports = actions.map((action, index) => {
                const source: ReportingSourceId = pageActions
                    ? 'CMS-Page'
                    : 'CMS-Section';
                const actionScriptReport: ActionScriptReport = {
                    displayOrder: index + 1,
                    eventType: 'Display',
                    version: '1.0',
                    userGroup: 'CMS',
                    intent: action.intent,
                    priority: action.priority,
                    source,
                    ...action.personalizedReporting
                };
                return actionScriptReport;
            });
            trackingService.trackNextBestActionDisplay(
                actionScriptReports,
                {
                    sectionId: trackingData?.sectionId,
                    sectionGroupContentId,
                    contentLabels: trackingData?.contentLabels
                },
                {
                    environment: {
                        featureAppId:
                            trackingData?.additionalTrackingData?.featureAppId,
                        featureAppIdAppVersion:
                            trackingData?.additionalTrackingData
                                ?.featureAppVersion
                    }
                }
            );
            trackedActions.current = actions;
        }
    }, [
        actions,
        pageActions,
        trackingData,
        trackingService,
        sectionGroupContentId
    ]);

    // Re-assign the ref on every render to make sure we always have the latest
    handleTrackingRef.current = handleTracking;

    useEffect(() => {
        if (!isNbabVisible) {
            trackedActions.current = [];
            return;
        }

        // Wait for X ms to ensure that the NBAB is still visible and not just skipped over
        const id = window.setTimeout(() => {
            handleTrackingRef.current();
        }, sensitivityTimeout);

        return () => {
            window.clearTimeout(id);
        };
    }, [
        trackedActions,
        isNbabVisible,
        handleTracking,
        handleTrackingRef,
        sensitivityTimeout
    ]);
};

const isEqualArray = (
    actions1: MergeNextBestActionModel[],
    actions2: MergeNextBestActionModel[]
) => {
    if (actions1.length !== actions2.length) {
        return false;
    }
    return actions1.reduce((acc, a1, index) => {
        acc = acc && isEqual(a1, actions2[index]);
        return acc;
    }, true);
};

const isEqual = (
    a1: MergeNextBestActionModel,
    a2: MergeNextBestActionModel
) => {
    return (
        a1.icon === a2.icon && a1.intent === a2.intent && a1.label === a2.label
    );
};
