import * as React from 'react';

import {
    styled,
    Container,
    ContainerWrap,
    Layout,
    TextAlignment
} from '@volkswagen-onehub/components-core';
import {
    hasVisibleFAs,
    useFeatureAppsVisibilityListener
} from '../../context/featureApp/useFeatureAppsVisibilityListener';
import {getContainerPadding} from '../../d6/components/helpers';
import {SectionGroupModel} from '../../../generated/core';
import {AuthoringWrapper} from '../../components/AuthoringWrapper';
import {HeadingSectionDisclaimerContainer} from '../../components/disclaimers/HeadingSectionDisclaimerContainer';
import {MovingFrame} from '../../components/MovingFrame';
import {LegalEntityProvider} from '../../context/legal/LegalEntityProvider';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {C} from '../../registries/compatibilty';
import {HeadingElement} from '../editorial/elements/HeadingElement';
import {SectionWrapper} from '../editorial/sections/SectionWrapper';
import {Parsys, ParsysType} from './Parsys';
import {SectionGroupContext} from './SectionGroupContext';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';
import {
    useFeatureAppVisibilityService,
    useNavigationStore
} from '../../context';
import {useSectionVisibility} from '../../infrastructure/compatibility/useSectionVisibility';
import {useIsClientOnlyRendering} from '../../utils/useIsClientOnlyRendering';

const StyledBody = styled.div`
    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.dynamic0130};
`;

const CONTAINER_PADDING_2_4 = getContainerPadding([2, 4]);

const headlineContainerPadding = {
    left: CONTAINER_PADDING_2_4,
    right: CONTAINER_PADDING_2_4
};

//the section groups heading is also affected by the order change, so it needs to be one lower than it's acutal order
const Heading: React.FunctionComponent = () => (
    <Container
        padding={headlineContainerPadding}
        stretchContent={true}
        wrap={ContainerWrap.always}
    >
        <HeadingElement
            placeholder={'Type your headline here...'}
            textAlign={TextAlignment.center}
            style="H1"
            order="H1"
            path="heading"
        />
    </Container>
);

export interface AuthorView {
    authorTitle?: string;
}
export interface AdditionalMovingFrameProps extends SectionGroupModel {
    invisible?: boolean;
    movingFrameLabelIcon?: string;
}
/**
 * S116 Section Group
 */
export const InternalSectionGroup = (
    props: SectionGroupModel & AuthorView & AdditionalMovingFrameProps
): JSX.Element | null => {
    const {
        anchorId,
        mboxId,
        contentId: sectionId,
        contentName,
        contentLabels,
        movingFrameEnabled,
        groupLegalEntity,
        movingFrameLabel,
        movingFrameLabelIcon,
        movingFrameLinkTarget,
        movingFrameLinkText,
        movingFrameLinkUrl,
        cqItems,
        authorTitle,
        invisible
    } = props;
    const hasHeadline = C.isInEditor() || !Boolean(cqItems?.heading?.empty);
    const title = authorTitle || 'S116 Section Group';
    const visibilityService = useFeatureAppVisibilityService();
    const navigationStore = useNavigationStore();
    const {setVisible} = useSectionVisibility();

    const sections = props.cqItems?.groupParsys?.cqItems;
    const faSectionConsumerIds = React.useMemo(
        () =>
            Object.keys(sections || {})
                .filter(key =>
                    Boolean(
                        sections[key].cqType ===
                            'vwa-ngw18/components/editorial/sections/featureAppSection'
                    )
                )
                .map(key => sections[key].consumerId),
        [sections]
    );

    const onlyFaSections =
        faSectionConsumerIds.length > 0 &&
        Object.keys(sections || {}).length === faSectionConsumerIds.length;

    const [hasFaWithContent, setHasFaWithContent] = React.useState<boolean>(
        hasVisibleFAs(faSectionConsumerIds, visibilityService)
    );
    useFeatureAppsVisibilityListener(faSectionConsumerIds, setHasFaWithContent);

    const hideGroup = invisible || (onlyFaSections && !hasFaWithContent);
    React.useEffect(() => {
        // set visibility of section
        if (!C.isInEditor()) setVisible(!hideGroup);
    }, [hideGroup, setVisible, navigationStore.inPageNavigationItems]);

    // do not show Group with no Content for SSR or on first render (to be in sync with SSR on first render)
    const isClientOnlyRendering = useIsClientOnlyRendering();
    if (!isClientOnlyRendering && hideGroup) {
        return null;
    }

    const body = (
        <Layout allowOverflowingContent>
            <LegalEntityProvider legalEntity={groupLegalEntity}>
                <StyledBody>
                    <GeneralDisclaimerProvider
                        displayTypes={['T3_SECTION_BASED']}
                    >
                        {hasHeadline && <Heading />}
                        <HeadingSectionDisclaimerContainer
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </GeneralDisclaimerProvider>
                    <Parsys
                        path="groupParsys"
                        parsysType={ParsysType.SECTION_GROUP_PARSYS}
                    />
                </StyledBody>
            </LegalEntityProvider>
        </Layout>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title={title}
            bgColor={AuthoringWrapper.BG_COLOR_SECTION_GROUP}
        >
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
                sectionGroup
            >
                <SectionGroupContext.Provider
                    value={{
                        sectionGroupAnchorId: anchorId,
                        sectionGroupContentId: sectionId,
                        isWithinGroup: true,
                        hasSectionGroupHeading: hasHeadline
                    }}
                >
                    {movingFrameEnabled ? (
                        <MovingFrame
                            label={movingFrameLabel}
                            labelIcon={movingFrameLabelIcon}
                            linkText={movingFrameLinkText}
                            url={movingFrameLinkUrl}
                            target={movingFrameLinkTarget}
                        >
                            {body}
                        </MovingFrame>
                    ) : (
                        body
                    )}
                </SectionGroupContext.Provider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const SectionGroup = MapTo(
    'vwa-ngw18/components/structure/sectionGroup',
    InternalSectionGroup
);
