import * as React from 'react';

import {inject, singleton} from '../../infrastructure/di/annotations';
import {ModelStore} from '../model/ModelStore';
import {
    FocusLayerSizeV2,
    LayerHandleV2,
    LayerManagerV2
} from '@volkswagen-onehub/layer-manager';
import {LoginInfoFocusLayer} from '../../modules/structure/login/LoginInfoFocusLayer';
import {ModelClient} from '../../infrastructure/compatibility/ModelClient';
import {Logger} from '../logger/Logger';
import {TrackingService} from '../tracking/TrackingService';

export const LOGIN_INFO_FOCUS_LAYER_CONTEND_ID = 'login-info-focus-layer';

@singleton('LoginInfoFocusLayerService')
export class LoginInfoFocusLayerService {
    @inject() private readonly layerManager!: LayerManagerV2;
    @inject() private readonly modelStore!: ModelStore;
    @inject() private modelClient!: ModelClient;
    @inject() private logger!: Logger;
    @inject() private trackingService!: TrackingService;

    private layerModelPath!: string;
    private path!: string;
    private layerHandle: LayerHandleV2<void> | null = null;

    public async openLayer(href: string): Promise<void> {
        this.path = href + '/jcr:content/root/main/mainParsys/focus.model.json';
        const model = await this.modelClient.fetch(this.path);
        if (!model) {
            this.logger.layer.warn(
                "couldn't fetch login info focus layer data: path=%s",
                this.path
            );
            return;
        }
        this.layerModelPath = this.modelStore.insertGlobalContent(
            'loginInfoFocusLayer',
            model
        );
        this.layerHandle = this.layerManager.openFocusLayer(
            this.renderLayer,
            undefined,
            {
                userCloseable: true,
                size: FocusLayerSizeV2.A,
                onClose: () => this.closeLayer(true)
            }
        );
    }

    public closeLayer(shouldTrack: boolean): void {
        if (this.layerHandle) {
            this.layerHandle.close();
        }
        if (shouldTrack) {
            this.trackingService.trackLayerCloseClick(
                LOGIN_INFO_FOCUS_LAYER_CONTEND_ID
            );
        }
        this.layerHandle = null;
    }

    private readonly renderLayer = (): JSX.Element => {
        return (
            <LoginInfoFocusLayer
                path={this.layerModelPath}
                onClose={() => this.closeLayer(false)}
            />
        );
    };
}
