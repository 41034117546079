import * as React from 'react';

import {AlertBox, AlertBoxSeverity} from '../../../../components/AlertBox';
import {AuthoringWrapper} from '../../../../components/AuthoringWrapper';
import {HeadingElement} from '../../elements/HeadingElement';

import {TextAlignment} from '@volkswagen-onehub/components-core';

export const teaserSectionHeading = (
    <HeadingElement
        path="heading"
        textAlign={TextAlignment.center}
        style="H2"
        order="H2"
    />
);

export const TeaserSectionAuthoringView: React.FunctionComponent<{
    anchorId: string;
    emptyLabel: string;
    validationMessages?: string[];
    validationError?: boolean;
    tagTypeBroken?: boolean;
}> = props => (
    <AuthoringWrapper
        anchorId={props.anchorId}
        title={props.emptyLabel}
        bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        tagTypeBroken={props.tagTypeBroken}
    >
        <div id={props.anchorId}>
            {props.validationMessages && (
                <AlertBox
                    severity={
                        props.validationError
                            ? AlertBoxSeverity.ERROR
                            : AlertBoxSeverity.WARNING
                    }
                >
                    {props.validationMessages.map((msg, idx) => (
                        <span key={idx}>{msg}</span>
                    ))}
                </AlertBox>
            )}
            {props.children}
        </div>
    </AuthoringWrapper>
);
