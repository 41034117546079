import {AspectRatio} from '@volkswagen-onehub/components-core';
import {MediaAspectRatio} from '../../generated/api';

/**
 * Provides mapping between LSG enum and value saved in AEM for aspect ratio.
 */
export const aspectRatioMap: {[key in MediaAspectRatio]: AspectRatio} = {
    r_2_3: AspectRatio['2:3'],
    r_1_1: AspectRatio['1:1'],
    r_16_9: AspectRatio['16:9'],
    r_9_16: AspectRatio['9:16'],
    r_4_3: AspectRatio['4:3'],
    r_3_4: AspectRatio['3:4'],
    r_2_1: AspectRatio['2:1'],
    r_10_6: AspectRatio['10:6'],
    r_3_2: AspectRatio['3:2'],
    r_4_5: AspectRatio['4:5']
};
