import {
    Breakpoints,
    styled,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {FeatureAppNotifications} from '../../../modules/structure/FeatureAppNotifications';
import {getLogoLayerTopPosition} from '../navigation-top-bar-one-hub-helpers';
import {
    NavigationTopBarProps,
    StyledLeftItemsWrapper,
    StyledStaticLogoWrapper
} from '../navigation-top-bar-one-hub';

const StyledNavigationTopBarStatic = styled.header`
    width: 100%;
    height: auto;
    color: ${props => props.theme.colors.button.primary.default};
    background-color: ${props => props.theme.colors.background.primary};
`;
StyledNavigationTopBarStatic.displayName = 'StyledNavigationTopBarStatic';

const StyledNavigationTopBarPrimaryContentStatic = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(24, ${100 / 24}%);
    pointer-events: auto;

    grid-template-rows: ${props => props.theme.size.static200} 1fr ${props =>
            props.theme.size.static200};

    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-rows: ${props => props.theme.size.static250} ${props =>
                props.theme.size.static400} ${props =>
                props.theme.size.static250};
    }
`;
StyledNavigationTopBarPrimaryContentStatic.displayName =
    'StyledNavigationTopBarPrimaryContentStatic';

const StyledLogoLayer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    top: 0;

    ${props =>
        [
            Breakpoints.default,
            Breakpoints.b560,
            Breakpoints.b960,
            Breakpoints.b1920
        ]
            .map(
                breakpoint => `
					@media (min-width: ${breakpoint}px) {
						transform: translateY(
							${getLogoLayerTopPosition(props.theme, breakpoint, false)}
						);
					}
				`
            )
            .join('\n')}
`;
StyledLogoLayer.displayName = 'StyledLogoLayer';

const StyledStaticLogoLayer = styled.div<{hasClaim?: boolean}>`
    display: flex;
    grid-row: 2/3;
    grid-column: 10/16;
    justify-content: center;

    svg {
        fill: ${props => props.theme.colors.button.primary.default};
        width: ${props => props.theme.size.static400};
        height: ${props => props.theme.size.static400};
        margin-inline-end: ${props =>
            props.hasClaim ? props.theme.size.static150 : 'initial'};
    }
`;
StyledStaticLogoLayer.displayName = 'StyledStaticLogoLayer';

const StyledPageNameLinkStatic = styled.div`
    display: none;
    padding-inline-start: ${props => props.theme.size.dynamic0130};

    @media (min-width: ${Breakpoints.b960}px) {
        display: initial;
    }

    & span {
        @media (min-width: ${Breakpoints.b960}px) {
            font-size: ${props => props.theme.fonts.copy[200].fontSize};
        }
    }
`;
StyledPageNameLinkStatic.displayName = 'StyledPageNameLinkStatic';

const StyledLeftItemsWrapperStatic = styled(StyledLeftItemsWrapper)`
    visibility: visible;
`;
StyledLeftItemsWrapperStatic.displayName = 'StyledLeftItemsWrapperStatic';

// the nav content is not centered, it has more top- than bottom-padding, except if the static version of the topBar is active.
const StyledRightItemsWrapperStatic = styled.div`
    grid-row: 2/3;
    grid-column: 16/24;
    display: flex;
    gap: ${props => props.theme.size.static300};
    justify-content: flex-end;
    align-items: center;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.static100};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-column: 18/24;
        padding-left: ${props => props.theme.size.static250};
    }
`;

export function NavigationTopBarStatic(
    props: NavigationTopBarProps
): React.ReactElement {
    const {visibleRightItems, menuButton, logo, claim} = props;

    return (
        <ThemeProvider>
            <StyledNavigationTopBarStatic role="banner">
                <StyledNavigationTopBarPrimaryContentStatic>
                    <StyledLeftItemsWrapperStatic>
                        {menuButton}
                    </StyledLeftItemsWrapperStatic>
                    <StyledStaticLogoLayer hasClaim={Boolean(claim)}>
                        <StyledStaticLogoWrapper data-testid="StyledStaticLogoWrapper">
                            {logo}
                            {claim}
                        </StyledStaticLogoWrapper>
                    </StyledStaticLogoLayer>
                    <StyledRightItemsWrapperStatic>
                        {visibleRightItems}
                    </StyledRightItemsWrapperStatic>
                </StyledNavigationTopBarPrimaryContentStatic>
                <FeatureAppNotifications />
            </StyledNavigationTopBarStatic>
        </ThemeProvider>
    );
}
