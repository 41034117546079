import * as React from 'react';

export const AnimationIcon: React.FunctionComponent = () => (
    <svg
        width="57px"
        height="44px"
        viewBox="0 0 57 44"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="animation_icon" fill="#001E50" fillRule="nonzero">
                <path d="M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,42 C1.5,42.2761424 1.72385763,42.5 2,42.5 L55,42.5 C55.2761424,42.5 55.5,42.2761424 55.5,42 L55.5,2 C55.5,1.72385763 55.2761424,1.5 55,1.5 L2,1.5 Z M2,0 L55,0 C56.1045695,-2.02906125e-16 57,0.8954305 57,2 L57,42 C57,43.1045695 56.1045695,44 55,44 L2,44 C0.8954305,44 1.3527075e-16,43.1045695 0,42 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M24.33,15.412 L24.33,28.588 L35.516,22 L24.33,15.412 Z M24.197,13.979 C24.403,13.979 24.609,14.034 24.797,14.144 L36.403,20.979 C36.783,21.202 37,21.574 37,22 C37,22.426 36.782,22.798 36.402,23.022 L24.797,29.857 C24.61,29.967 24.403,30.022 24.197,30.022 C23.995,30.022 23.793,29.969 23.608,29.863 C23.227,29.645 23,29.25 23,28.807 L23,15.194 C23,14.75 23.227,14.355 23.608,14.138 C23.793,14.032 23.995,13.979 24.197,13.979 Z" />
            </g>
        </g>
    </svg>
);

export const ImageIcon: React.FunctionComponent = () => (
    <svg
        width="57px"
        height="41px"
        viewBox="0 0 57 41"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="image_icon" fill="#001E50" fillRule="nonzero">
                <path d="M1.5,35.4300422 L15.5631022,21.2401823 L23.5765298,29.3192008 L40.3217797,11.8997401 L55.5,28.7191457 L55.5,2 C55.5,1.72385763 55.2761424,1.5 55,1.5 L2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,35.4300422 Z M1.5,37.4955335 L1.5,39 C1.5,39.2761424 1.72385763,39.5 2,39.5 L55,39.5 C55.2761424,39.5 55.5,39.2761424 55.5,39 L55.5,30.8438942 L55.4432008,30.8951511 L40.2871028,14.1002599 L23.5933363,31.4661645 L15.5635397,23.370643 L1.53270406,37.5279454 L1.5,37.4955335 Z M57,2 L57,39 C57,40.1045695 56.1045695,41 55,41 L2,41 C0.8954305,41 -2.17492691e-13,40.1045695 -2.17492691e-13,39 L-2.10387263e-13,2 C-2.10387263e-13,0.8954305 0.8954305,-1.12881261e-14 2,-1.108522e-14 L55,-1.34927791e-15 C56.1045695,-1.14637179e-15 57,0.8954305 57,2 Z M15.5,17.75 C12.0482203,17.75 9.25,14.9517797 9.25,11.5 C9.25,8.04822031 12.0482203,5.25 15.5,5.25 C18.9517797,5.25 21.75,8.04822031 21.75,11.5 C21.75,14.9517797 18.9517797,17.75 15.5,17.75 Z M15.5,16.25 C18.1233526,16.25 20.25,14.1233526 20.25,11.5 C20.25,8.87664744 18.1233526,6.75 15.5,6.75 C12.8766474,6.75 10.75,8.87664744 10.75,11.5 C10.75,14.1233526 12.8766474,16.25 15.5,16.25 Z" />
            </g>
        </g>
    </svg>
);

export const VideoIcon: React.FunctionComponent = () => (
    <svg
        width="57px"
        height="44px"
        viewBox="0 0 57 44"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="video_icon" fill="#001E50" fillRule="nonzero">
                <path d="M36.1144506,36.75 C36.4330165,35.7357233 37.3805896,35 38.5,35 C39.6194104,35 40.5669835,35.7357233 40.8855494,36.75 L50.4852313,36.75 C50.8994449,36.75 51.2352313,37.0857864 51.2352313,37.5 C51.2352313,37.9142136 50.8994449,38.25 50.4852313,38.25 L40.8855494,38.25 C40.5669835,39.2642767 39.6194104,40 38.5,40 C37.3805896,40 36.4330165,39.2642767 36.1144506,38.25 L6,38.25 C5.58578644,38.25 5.25,37.9142136 5.25,37.5 C5.25,37.0857864 5.58578644,36.75 6,36.75 L36.1144506,36.75 Z M2,1.5 C1.72385763,1.5 1.5,1.72385763 1.5,2 L1.5,42 C1.5,42.2761424 1.72385763,42.5 2,42.5 L55,42.5 C55.2761424,42.5 55.5,42.2761424 55.5,42 L55.5,2 C55.5,1.72385763 55.2761424,1.5 55,1.5 L2,1.5 Z M2,0 L55,0 C56.1045695,0 57,0.8954305 57,2 L57,42 C57,43.1045695 56.1045695,44 55,44 L2,44 C0.8954305,44 0,43.1045695 0,42 L0,2 C0,0.8954305 0.8954305,0 2,0 Z M24.33,13.412 L24.33,26.588 L35.516,20 L24.33,13.412 Z M24.197,11.979 C24.403,11.979 24.609,12.034 24.797,12.144 L36.403,18.979 C36.783,19.202 37,19.574 37,20 C37,20.426 36.782,20.798 36.402,21.022 L24.797,27.857 C24.61,27.967 24.403,28.022 24.197,28.022 C23.995,28.022 23.793,27.969 23.608,27.863 C23.227,27.645 23,27.25 23,26.807 L23,13.194 C23,12.75 23.227,12.355 23.608,12.138 C23.793,12.032 23.995,11.979 24.197,11.979 Z" />
            </g>
        </g>
    </svg>
);
