import * as React from 'react';

export interface EditableComponentContextValue {
    // set visibility of current component - visibility is handled within Editable Component
    setVisible: (visible: boolean) => void;

    // visibility: visibility of the component by also inherting from parents. if one of  parents is invisible,
    // current component will for sure be invisible as well (and will not be shown in page navigation)
    visible: boolean;
}

export const EditableComponentContext = React.createContext<
    EditableComponentContextValue
>({
    setVisible: (_visible: boolean) => {},
    visible: true
});

export const useSectionVisibility = () => {
    return React.useContext(EditableComponentContext);
};
