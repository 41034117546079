import {observer} from 'mobx-react-lite';
import * as React from 'react';

import {
    InPageNavBar,
    InPageNavBarV2
} from '../../../d6/components/inpage-navigation-bar';

import {useFeatureToggles, useNavigationStore} from '../../../context';
import {InPageNavigationLink} from './InPageNavigationLink';
import {styled} from '@volkswagen-onehub/components-core';

interface InPageNavigationProps {
    readonly inPageNavigationLabel: string;
    readonly pageNameLink?: JSX.Element | null;
}

interface StyledInPageNavProps {
    readonly isInPageNavHidden: boolean;
}

const StyledInPageNavBarWrap = styled.nav<StyledInPageNavProps>`
    width: 100%;

    ${props => props.isInPageNavHidden && 'display: none;'};

    span {
        font-size: ${props => props.theme.fonts.copy[100].fontSize};
    }
`;
StyledInPageNavBarWrap.displayName = 'StyledInPageNavBarWrap';

const InPageNavigationV2: React.FunctionComponent<InPageNavigationProps> = observer(
    function IPN(props: InPageNavigationProps) {
        const {inPageNavigationLabel, pageNameLink} = props;

        const navigationStore = useNavigationStore();
        const {
            isInPageNavigationOpen,
            isInPageNavigationVisible,
            activeInPageNavigationItemTitle,
            activeInPageNavigationItemUrl,
            visibleInPageNavigationItems
        } = navigationStore;

        const {enableInpageNavigationV2} = useFeatureToggles();

        const inPageNavBar = !enableInpageNavigationV2 && (
            <InPageNavBar
                isOpen={isInPageNavigationOpen}
                activeItemTitle={activeInPageNavigationItemTitle}
                activeItemUrl={activeInPageNavigationItemUrl}
            >
                {visibleInPageNavigationItems.map(item => (
                    <InPageNavigationLink key={item.id} item={item} />
                ))}
            </InPageNavBar>
        );

        const inPageNavBarV2 = enableInpageNavigationV2 && (
            <InPageNavBarV2
                isOpen={isInPageNavigationOpen}
                activeItemTitle={activeInPageNavigationItemTitle}
                activeItemUrl={activeInPageNavigationItemUrl}
                pageNameLink={pageNameLink}
            >
                {visibleInPageNavigationItems.map(item => (
                    <InPageNavigationLink key={item.id} item={item} />
                ))}
            </InPageNavBarV2>
        );

        return (
            <StyledInPageNavBarWrap
                isInPageNavHidden={!isInPageNavigationVisible}
                aria-label={inPageNavigationLabel}
            >
                {inPageNavBar}
                {inPageNavBarV2}
            </StyledInPageNavBarWrap>
        );
    }
);

InPageNavigationV2.displayName = 'InPageNavigationV2';
export {InPageNavigationV2};
