import React, {MouseEvent} from 'react';
import {observer} from 'mobx-react-lite';

import {IconBadgeWrapper} from '../../../d6/components/icon-badge-wrapper';

import {MiniCartModel} from '../../../../generated/core';
import {
    useRegistry,
    useTrackingService,
    useContentStore
} from '../../../context';
import {NavigationServiceV1} from '@volkswagen-onehub/navigation-service';
import {ContentStore} from '../../../context/content/ContentStore';
import {ShoppingIconButton} from '../../../d6/components/navigation-top-bar-one-hub-helpers';
import {CartStore} from '../../../context/cart/CartStore';

const CHECKOUT_PAGE_ID = 'os:checkout';

export const MiniCartIcon = observer(function MCI(props: {
    config: MiniCartModel;
}) {
    const navigationService: NavigationServiceV1 = useRegistry().getSingleton(
        'FeaturehubNavigationService'
    );
    const cartStore: CartStore = useRegistry().getSingleton('CartStore');
    const productCount = (cartStore.cart?.lineItems || []).length;
    const contentStore: ContentStore = useContentStore();

    const trackingService = useTrackingService();

    const {iconLabel} = props.config;
    const pageModel = contentStore.getCurrentPageRootModel();
    const inCheckOut =
        pageModel && pageModel.featurehubPageId === CHECKOUT_PAGE_ID;

    const target = navigationService.navigateTo(CHECKOUT_PAGE_ID, {});
    if (target && !inCheckOut) {
        const navigate = (event: MouseEvent<HTMLElement>) => {
            const href = target.createHref();
            trackingService.trackQuickAccessLinkClick(
                href,
                iconLabel,
                {
                    sectionId: 'top-bar'
                },
                'basket',
                {
                    product: {
                        Quantity: productCount
                    }
                }
            );
            target.push();
            event.preventDefault();
        };

        return (
            <IconBadgeWrapper badgeValue={productCount}>
                <ShoppingIconButton onClick={navigate} ariaLabel={iconLabel} />
            </IconBadgeWrapper>
        );
    }

    return (
        <IconBadgeWrapper badgeValue={productCount}>
            <ShoppingIconButton
                onClick={() => {
                    // This is intentionally added for SQ
                }}
                ariaLabel={iconLabel}
            />
        </IconBadgeWrapper>
    );
});
