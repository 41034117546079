import {AlertBox, AlertBoxSeverity} from '../components/AlertBox';
import * as React from 'react';

export const getTableWarnings = (
    validationMessages: string[],
    columnsCount: number,
    MIN_COLUMNS: number
) => (
    <>
        {columnsCount < MIN_COLUMNS && (
            <AlertBox>
                <span>
                    It is required to set at least 2 columns, otherwise table
                    will not be displayed.
                </span>
            </AlertBox>
        )}
        {Boolean(validationMessages.length) && (
            <AlertBox severity={AlertBoxSeverity.WARNING}>
                {validationMessages.map((msg, idx) => (
                    <span key={idx}>{msg}</span>
                ))}
            </AlertBox>
        )}
    </>
);
