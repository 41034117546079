import * as React from 'react';

export interface PersonalizationContextData {
    isPersonalizable?: boolean;
    isLoading?: boolean;
    contentId?: string;
    placeholderId?: string;
    wasPersonalized?: boolean;
    recommendationId?: string;
}

export interface InjectedPersonalizedContext {
    personalizationContext?: PersonalizationContextData;
}

export const PersonalizationContext: React.Context<PersonalizationContextData> = React.createContext(
    {}
);
