import {observer} from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import {NavigationLinkModel, PageRootModel} from '../../../../generated/core';
import {LayerWrapper, ShimLayer} from '@volkswagen-onehub/components-core';
import {Logo} from '@volkswagen-onehub/icons-core';
import {CmsPlainLink} from '../../../components/links/CmsPlainLink';
import {
    useContentStore,
    useFeatureToggles,
    useGlobalConfig,
    useLogoStore,
    useNavigationModel,
    useNavigationStore,
    useSearchLayerService,
    useSearchStore,
    useSpaAsyncConfig,
    useTopBarModel,
    useTrackingService
} from '../../../context';
import {ImageLoadingContext} from '../../../context/image/ImageLoadingContext';
import {buttonFocusStateCSS} from '../../../d6/components/helpers';
import {
    LogoClaim,
    MenuButton,
    SearchButton
} from '../../../d6/components/navigation-top-bar-one-hub-helpers';
import {NavigationTopBar} from '../../../d6/components/navigation-top-bar-one-hub';
import {NavigationTopBarStatic} from '../../../d6/components/navigation-top-bar-one-hub-static';
import {NavigationTopBarV2} from '../../../d6/components/navigation-top-bar-one-hub-v2';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {ZIndex} from '../../../utils/zIndex';
import {EDITORIAL_STAGE_TYPE} from '../../editorial/sections/EditorialStageSection';
import {FA_SECTION_RESOURCETYPE} from '../../editorial/sections/FeatureAppSection';
import {SIMPLE_STAGE_TYPE} from '../../editorial/sections/SimpleStageSection';
import {InPageNavigationV2} from './InPageNavigationV2';
import {InPageNavigationDropdown} from './InPageNavigationDropdown';
import {MiniCartIcon} from './MiniCartIcon';
import {MydealerStateInfo} from './MydealerStateInfo';
import {PageNameLink} from './PageNameLink';
import {SSRNavigation} from './SSRNavigation';
import {TopBarLoginButton} from './TopBarLoginButton';
import {TopBarShim} from './TopBarShim';
import {isExpandedTopbar} from './helpers';
import {useMediaQueries} from '../../../context/mediaQueries/mediaQueryContext';

const imageLoadingContextValue = {lazyload: false};

// we need to change z-index here in case when layer is open, so topbar and layer are not on the same level
const TopBarWrapper = styled.div`
    position: sticky;
    z-index: ${ZIndex.header.z};
    top: 0;
`;

const StyledTopbarWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    :where(&) {
        pointer-events: none;
    }

    & button,
    & a {
        pointer-events: auto;
    }
`;
StyledTopbarWrapper.displayName = 'StyledTopbarWrapper';

function useOnLoadEffect(): void {
    const navigationStore = useNavigationStore();
    React.useEffect(() => {
        navigationStore.onLoad();
    }, [navigationStore]);
}

function useScrollEffect(): void {
    const navigationStore = useNavigationStore();

    React.useEffect(() => {
        function handleScroll(): void {
            navigationStore.updateScrollPositionOnAnimationFrame();
        }

        navigationStore.updateScrollPositionOnAnimationFrame();
        document.addEventListener('scroll', handleScroll);

        return function cleanup(): void {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [navigationStore]);
}

function useEscapeEffect(): void {
    const trackingService = useTrackingService();
    const navigationStore = useNavigationStore();

    React.useEffect(() => {
        function handleOpenMenuClick(): void {
            trackingService.trackMainNavigationOpenClick();
            navigationStore.openFlyoutMenu();
        }

        function handleKeyDown(event: any): void {
            const isFlyoutMenuOpen = navigationStore.isFlyoutMenuOpen;
            if (event.keyCode === 27 && isFlyoutMenuOpen) {
                handleOpenMenuClick();
            }
        }
        document.addEventListener('keydown', handleKeyDown);

        return function cleanup(): void {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [navigationStore, trackingService]);
}

function useTransparentBackground(): boolean {
    const contentStore = useContentStore();
    const navigationStore = useNavigationStore();
    const globalConfig = useGlobalConfig();

    if (Boolean(globalConfig.integratorTemplateModel)) {
        return !globalConfig.integratorTemplateModel.whiteTopBar;
    }

    const type = contentStore.getStageType();
    const isAboveStage = navigationStore.isTopBarAboveStage;

    const isWhiteBgAboveStage =
        !type || type === EDITORIAL_STAGE_TYPE || type === SIMPLE_STAGE_TYPE;

    if (type === FA_SECTION_RESOURCETYPE) {
        return (
            (contentStore.getCurrentPageRootModel()?.transparentTopbarForFA ||
                false) &&
            isAboveStage
        );
    }

    return isWhiteBgAboveStage ? false : isAboveStage;
}

function getLogoUrl(pageRootModel?: PageRootModel): string | undefined {
    return pageRootModel ? pageRootModel.logoUrl : undefined;
}

/**
 * Trigger event with state of the top bar to provide this information to application within
 * the integrator template (NGWD6-7891)
 */
function triggerEvent(isMenuOpen: boolean, isExpanded: boolean) {
    // Create the event
    const event = new CustomEvent('menuChanged', {
        detail: {
            navigation: {
                menu: {
                    open: isMenuOpen
                },
                logo: {
                    visible: isExpanded
                }
            }
        }
    });
    document.dispatchEvent(event);
}

// Moved outside to be able to test it
export function focusMenuButton(
    menuButtonRef: React.RefObject<HTMLButtonElement>,
    flyoutClosedByKeyboard: boolean
): void {
    if (flyoutClosedByKeyboard) {
        // Without timeout focus is returned to <body> after flyout is closed
        setTimeout(() => {
            if (menuButtonRef.current) {
                // @ts-ignore Cannot be null, it is tested just one row above
                menuButtonRef.current.focus();
            }
        }, 0);
    }
}

function compileRightItems(
    isInPageNavigationVisible: boolean,
    inPageNav: JSX.Element | undefined,
    miniCartIcon: JSX.Element | undefined,
    searchButton: JSX.Element | undefined,
    loginButton: JSX.Element | undefined,
    dealerState: JSX.Element
) {
    const topbarButtons = [
        miniCartIcon,
        searchButton,
        loginButton,
        dealerState
    ];

    if (isInPageNavigationVisible) {
        return {
            visibleRightItems: [inPageNav],
            hiddenRightItems: topbarButtons
        };
    }
    return {
        visibleRightItems: topbarButtons,
        hiddenRightItems: [inPageNav]
    };
}

const StyledLogoWrapper = styled.span`
    position: relative;
    display: inline-block;
    line-height: 0;

    & a {
        ${buttonFocusStateCSS}
    }
`;

function compileLogo(
    homepage: NavigationLinkModel,
    href: string | undefined,
    handleLogoClickTrack: () => void,
    logoComponent: JSX.Element
) {
    return homepage ? (
        <StyledLogoWrapper>
            <CmsPlainLink
                href={href}
                trackingActionOverride={handleLogoClickTrack}
            >
                {logoComponent}
            </CmsPlainLink>
        </StyledLogoWrapper>
    ) : (
        <StyledLogoWrapper>{logoComponent}</StyledLogoWrapper>
    );
}

function compilePageNameLink(
    pageNameNavigationLink: string | undefined
): JSX.Element | null {
    return (
        (pageNameNavigationLink && (
            <PageNameLink pageName={pageNameNavigationLink} />
        )) ||
        null
    );
}

function checkHasCustomContent(
    isInPageNavigationVisible: boolean,
    isInPageNavigationOpen: boolean
) {
    return isInPageNavigationVisible && isInPageNavigationOpen;
}

function staticTopbarCheck(
    isAboveStage: boolean,
    shouldRenderInpageNav: boolean
) {
    return shouldRenderInpageNav && !isAboveStage;
}

const menuIsNotOpenAndInPageNavigationIsNotHidden = (
    isMenuOpen: boolean,
    isInPageNavigationVisible: boolean
) => !isMenuOpen && isInPageNavigationVisible;

const inPageNavigationIsOpenAndIsNotAboveStage = (
    isAboveStage: boolean,
    isInPageNavigationOpen: boolean
) => !isAboveStage && isInPageNavigationOpen;

const getClaim = (claimText: string): JSX.Element | undefined =>
    claimText ? <LogoClaim>{claimText}</LogoClaim> : undefined;

/**
 * One Hub Top Bar.
 */
const TopBar: React.FunctionComponent = observer(
    function ObservedTopBar(): JSX.Element | null {
        useEscapeEffect();
        useScrollEffect();
        useOnLoadEffect();
        const {isMobile} = useMediaQueries();
        const hasTransparentBackground = useTransparentBackground();

        const globalConfig = useGlobalConfig();
        const spaAsyncConfig = useSpaAsyncConfig();
        const trackingService = useTrackingService();
        const navigationStore = useNavigationStore();
        const searchLayerService = useSearchLayerService();
        const searchStore = useSearchStore();
        const navigationModel = useNavigationModel();
        const contentStore = useContentStore();
        const pageRootModel = contentStore.getCurrentPageRootModel();
        const {
            showShoppingIcon,
            enableInpageNavigationV2
        } = useFeatureToggles();
        const toggles = pageRootModel?.togglesModel;
        const topBarModel = useTopBarModel();
        const menuButtonRef = React.useRef(null);
        let flyoutClosedByKeyboard = true;
        const logoStore = useLogoStore();
        const {homepage} = navigationModel;
        const href = getLogoUrl(pageRootModel);
        const pageNameNavigationLink =
            pageRootModel?.inPageNavigationModel?.pageNameLink;
        const claimText = logoStore.logoClaim;
        const logoComponent = (
            <Logo
                variant="default"
                title={topBarModel.logoTitle}
                ariaLabel={topBarModel.logoTitle}
            />
        );

        // reads values from store - destructuring doesn't work
        const isMenuOpen = navigationStore.isFlyoutMenuOpen;
        const isInPageNavigationOpen = navigationStore.isInPageNavigationOpen;
        const isAboveStage = navigationStore.isTopBarAboveStage;
        const showMenuLabel = navigationStore.isMenuLabelVisible;
        const isInPageNavigationVisible =
            navigationStore.isInPageNavigationVisible;

        const isShimVisible =
            menuIsNotOpenAndInPageNavigationIsNotHidden(
                isMenuOpen,
                isInPageNavigationVisible
            ) &&
            inPageNavigationIsOpenAndIsNotAboveStage(
                isAboveStage,
                isInPageNavigationOpen
            );

        const isStaticTopbar = toggles?.enableStaticNavigation;

        const shouldRenderInpageNav =
            navigationStore?.visibleInPageNavigationItems.filter(
                item => item.title
            ).length > 0;

        const isExpanded = isExpandedTopbar(
            shouldRenderInpageNav,
            navigationStore?.lastScrollDirection,
            navigationStore?.isAnnouncementBarVisible,
            isAboveStage
        );

        React.useEffect(() => {
            triggerEvent(isMenuOpen, isExpanded);
        }, [isMenuOpen, isExpanded]);

        const showInpageNavigationAndStaticTopbar =
            !isAboveStage &&
            isInPageNavigationVisible &&
            navigationStore?.lastScrollDirection === 'up';

        if (C.isInEditor()) return null;

        const handleSearchButtonClick = (e: React.MouseEvent) => {
            trackingService.trackSearchLayerOpenClick();
            searchLayerService.openLightbox(e);
        };

        const loginButton = elseUndefined(
            globalConfig.loginModel.enabled,
            <TopBarLoginButton key="topbar-login-button" />
        );

        const miniCartIcon = elseUndefined(
            showShoppingIcon && spaAsyncConfig.minicartModel.enabled,
            <MiniCartIcon
                config={spaAsyncConfig.minicartModel}
                key="topbar-shopping-icon"
            />
        );

        const searchButton = elseUndefined(
            searchStore.searchEnabled,
            <SearchButton
                onClick={handleSearchButtonClick}
                key="one-hub-search-button"
                ariaLabel={topBarModel.searchLabel}
            />
        );

        const dealerState = <MydealerStateInfo key="topbar-dealer-state" />;

        const pageNameLink = compilePageNameLink(pageNameNavigationLink);

        const inPageNavigationProps = {
            key: 'one-hub-in-page-navigation',
            pageNameLink: pageNameLink,
            inPageNavigationLabel: topBarModel.inPageNavigationLabel,
            dropdownButtonLabel: topBarModel.inPageNavigationButtonLabel,
            dropdownCloseButtonLabel:
                topBarModel.inPageNavigationCloseButtonLabel
        };

        const inPageNav = <InPageNavigationV2 {...inPageNavigationProps} />;

        const inPageNavV2 = isMobile ? (
            <InPageNavigationDropdown {...inPageNavigationProps} />
        ) : (
            <InPageNavigationV2 {...inPageNavigationProps} />
        );

        const {visibleRightItems, hiddenRightItems} = compileRightItems(
            isInPageNavigationVisible,
            inPageNav,
            miniCartIcon,
            searchButton,
            loginButton,
            dealerState
        );

        const rightItemsV2 = [
            miniCartIcon,
            searchButton,
            loginButton,
            dealerState
        ];

        const closeInPageNav = (): void => {
            navigationStore.closeInPageNavigation();
        };

        const handleOpenMenuClick = () => {
            trackingService.trackMainNavigationOpenClick();
            navigationStore.openFlyoutMenu();
        };

        const menuLabel = showMenuLabel ? topBarModel.menuLabel : undefined;

        const menuButton = (
            <MenuButton
                key="one-open-menu-btn"
                onClick={handleOpenMenuClick}
                menuLabel={menuLabel}
                ariaLabel={topBarModel.menuLabel}
                buttonRef={menuButtonRef}
                ariaExpanded={isMenuOpen}
            />
        );

        function handleLogoClickTrack(): void {
            const url = homepage ? homepage.url : '';
            trackingService.trackMainNavigationLogoClick(url);
        }

        function handleClose(firedByCloseButtonClick: boolean): void {
            flyoutClosedByKeyboard = firedByCloseButtonClick;
        }

        const logo = compileLogo(
            homepage,
            href,
            handleLogoClickTrack,
            logoComponent
        );

        const claim = getClaim(claimText);

        const hasCustomContent = checkHasCustomContent(
            isInPageNavigationVisible,
            isInPageNavigationOpen
        );

        const shouldShowStaticTopBar = staticTopbarCheck(
            isAboveStage,
            shouldRenderInpageNav
        );

        const renderStaticTopBar = isStaticTopbar && (
            <NavigationTopBarStatic
                menuButton={menuButton}
                logo={logo}
                claim={claim}
                pageNameLink={pageNameLink}
                visibleRightItems={visibleRightItems}
                isExpanded={false}
            />
        );

        // The real one
        const renderNavigationTopBar = !enableInpageNavigationV2 &&
            !isStaticTopbar && (
                <NavigationTopBar
                    menuButton={menuButton}
                    logo={logo}
                    claim={claim}
                    pageNameLink={pageNameLink}
                    visibleRightItems={visibleRightItems}
                    hiddenRightItems={hiddenRightItems}
                    hasTransparentBackground={hasTransparentBackground}
                    hasCustomContent={hasCustomContent}
                    isExpanded={isExpanded}
                    isInPageNavigationVisible={isInPageNavigationVisible}
                />
            );

        const FlyoutNavigation = React.lazy(() =>
            import(/*  webpackChunkName: "flyout"  */ './FlyoutNavigation')
        );

        const renderInPageNavigationV2 = enableInpageNavigationV2 &&
            !isStaticTopbar && (
                <NavigationTopBarV2
                    menuButton={menuButton}
                    logo={logo}
                    claim={claim}
                    visibleRightItems={rightItemsV2}
                    hasTransparentBackground={hasTransparentBackground}
                    hasCustomContent={hasCustomContent}
                    shouldRenderInpageNav={shouldRenderInpageNav}
                    inPageNav={inPageNavV2}
                    isExpanded={isExpanded}
                    isInPageNavigationVisible={isInPageNavigationVisible}
                    isStatic={shouldShowStaticTopBar}
                    showInpageNavigationAndStaticTopbar={
                        showInpageNavigationAndStaticTopbar
                    }
                />
            );

        const nav = isMenuOpen ? (
            <React.Suspense fallback={<span></span>}>
                <FlyoutNavigation
                    onClose={handleClose}
                    onExited={() =>
                        focusMenuButton(menuButtonRef, flyoutClosedByKeyboard)
                    }
                />
            </React.Suspense>
        ) : (
            <></>
        );

        return (
            <TopBarWrapper>
                <LayerWrapper key="one-bub-top-bar-shim">
                    {isShimVisible && <ShimLayer onClick={closeInPageNav} />}
                </LayerWrapper>
                <StyledTopbarWrapper key="one-hub-top-bar">
                    <TopBarShim
                        showShim={hasTransparentBackground}
                        isStaticTopbar={isStaticTopbar}
                    />
                    <CyAttributeAppender name="topbar">
                        <>
                            {renderStaticTopBar}

                            {renderInPageNavigationV2}

                            {renderNavigationTopBar}
                        </>
                    </CyAttributeAppender>
                </StyledTopbarWrapper>
                <ImageLoadingContext.Provider value={imageLoadingContextValue}>
                    <LayerWrapper key="one-hub-navigation-flyout">
                        {nav}
                    </LayerWrapper>
                </ImageLoadingContext.Provider>
                <SSRNavigation />
            </TopBarWrapper>
        );
    }
);
TopBar.displayName = 'TopBar';
export {TopBar};
