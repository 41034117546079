import * as React from 'react';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {
    FocusTeaserContent,
    FocusTeaserLayout,
    FocusTeaserSection as FocusTeaserLsg
} from '../../../d6/components/focus-teaser';

import {FocusTeaserSectionModel} from '../../../../generated/core';
import {AnchorTarget} from '../../../components/AnchorTarget';
import {TeaserCategoryLinkDisclaimerContainer} from '../../../components/disclaimers/TeaserCategoryLinkDisclaimerContainer';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {
    TeaserImageLink,
    TeaserMainLink
} from '../../../components/teaser-element/helpers';
import {useTrackingService} from '../../../context';
import {ContextTrackingData} from '../../../context/tracking/TrackingService';
import {TrackingActionOverride} from '../../../infrastructure/TrackableLinkProps';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {processContentId} from '../../../utils/processContentId';
import {isInXfStaticEditMode} from '../../../utils/xfUtils';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {RichtextSimpleElement} from '../elements/RichtextElement';
import {TeaserElementAdditionalLink} from '../elements/editorial-teaser-element/helpers';
import {SectionWrapper} from './SectionWrapper';
import {TeaserSectionAuthoringView} from './teasers/common';
import {Richtext} from '../../../components/Richtext';
import {observer} from 'mobx-react-lite';
import {LinkTarget} from '../../../d6/components/teaser-element';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const EMPTY_LABEL = 'S115 Focus Teaser Section';

const responsiveMediaConfiguration: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        sizes: '60vw',
        aspectRatio: 'r_16_9'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        sizes: '60vw',
        aspectRatio: 'r_16_9'
    }
];

const copy = (
    <RichtextSimpleElement
        path="item_0/abstract"
        appearance={TokenTextAppearance.copy200}
        inheritColor // used by link
        tag={TextTag.p}
    />
);

type FocusTeaserSectionProps = FocusTeaserSectionModel;

function createAdditionalLink(
    props: FocusTeaserSectionProps
): JSX.Element | undefined {
    const {
        showAdditionalLink,
        sectionLinkHref,
        sectionLinkText,
        sectionLinkTarget,
        sectionLinkLayerType,
        sectionLinkIsLayerLink,
        sectionLayerPath,
        cqPath,
        cqItems
    } = props;

    if (!showAdditionalLink || !sectionLinkText) {
        return undefined;
    }

    const canShowAdditionalLink = Boolean(
        (showAdditionalLink || isInXfStaticEditMode(cqPath)) && sectionLinkText
    );

    const href = sectionLinkIsLayerLink ? sectionLayerPath : sectionLinkHref;

    if (!cqItems || !cqItems.item_0) {
        return undefined;
    }

    const {item_0} = cqItems;
    const {trackingModel} = item_0;

    return elseUndefined(
        canShowAdditionalLink,
        <TeaserElementAdditionalLink
            contentId={processContentId(
                trackingModel.originalCqPath,
                trackingModel.contentName
            )}
            href={href}
            text={sectionLinkText}
            target={sectionLinkTarget as LinkTarget}
            isLayerLink={sectionLinkIsLayerLink}
            layerType={sectionLinkLayerType}
        />
    );
}

function getCategory(props: FocusTeaserSectionProps): JSX.Element | undefined {
    const {cqItems, showCategoryElement} = props;

    if (!showCategoryElement || !cqItems || !cqItems.item_0) {
        return undefined;
    }

    const {item_0} = cqItems;
    const {
        categoryLinkHref,
        categoryLinkRichText,
        categoryLinkTarget,
        categoryLayerLink,
        categoryLinkLayerType,
        trackingModel
    } = item_0;

    return (
        <CyAttributeAppender name="teaserCategory">
            <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
                <Text bold>
                    <CmsTextLink
                        href={categoryLinkHref}
                        contentId={processContentId(
                            trackingModel.originalCqPath,
                            trackingModel.contentName
                        )}
                        isLayerLink={categoryLayerLink}
                        layerProps={{
                            layerType: categoryLinkLayerType || 'CONTENT'
                        }}
                        target={categoryLinkTarget as LinkTarget}
                    >
                        <Richtext
                            richtext={categoryLinkRichText || []}
                            inheritColor
                            tag={TextTag.span}
                            placeholder={''}
                        />
                    </CmsTextLink>
                </Text>
                <TeaserCategoryLinkDisclaimerContainer
                    displayTypes={['T4_ITEM_BASED']}
                />
            </GeneralDisclaimerProvider>
        </CyAttributeAppender>
    );
}

const SINGLE_ITEM = 'item_0';
const InternalFocusTeaserSection = observer(function FTS(
    props: FocusTeaserSectionProps
): JSX.Element | null {
    const {
        anchorId,
        mboxId,
        sectionLinkHref,
        sectionLinkTarget,
        sectionLinkIsLayerLink,
        sectionLinkLayerType,
        sectionLayerPath,
        hasImageRight,
        contentId: sectionId,
        contentName,
        validationError,
        validationMessages,
        contentLabels,
        cqItems,
        teaserHeadingText,
        tagTypeBroken
    } = props;
    const trackingService = useTrackingService();
    const [isZoomedIn, setOpen] = React.useState(false);

    function handleMouseOver(): void {
        setOpen(!isZoomedIn);
    }

    const onClickTrack: TrackingActionOverride = (
        _href: string,
        _linkName: string,
        contextData: ContextTrackingData,
        layerName?: string
    ) => {
        if (props.sectionLinkIsLayerLink) {
            trackingService.trackTeaserClick(
                props.teaserPath,
                props.sectionLayerPath,
                teaserHeadingText,
                {
                    ...contextData,
                    itemCount: 1,
                    itemPosition: 1
                }
            );
            trackingService.trackLayerLoad(
                processContentId(props.sectionLayerPath, layerName),
                contextData
            );
        } else {
            trackingService.trackTeaserClick(
                props.teaserPath,
                props.sectionLinkHref,
                teaserHeadingText,
                {
                    ...contextData,
                    itemCount: 1,
                    itemPosition: 1
                }
            );
        }
    };

    // error in publish view, show nothing
    if (validationError && !C.isInEditor()) {
        return null;
    }

    const itemContentId =
        cqItems && cqItems[SINGLE_ITEM]
            ? cqItems[SINGLE_ITEM].contentId
            : undefined;

    const href = sectionLinkIsLayerLink ? sectionLayerPath : sectionLinkHref;

    const media = (
        <ResponsiveMediaRenderer configs={responsiveMediaConfiguration}>
            <TeaserImageLink
                href={href}
                contentId={itemContentId}
                trackingActionOverride={onClickTrack}
                isLayerLink={sectionLinkIsLayerLink}
                layerProps={{layerType: sectionLinkLayerType}}
                target={sectionLinkTarget as LinkTarget}
            >
                <MediaElement
                    path={`${SINGLE_ITEM}/media`}
                    responsiveMediaConfig={responsiveMediaConfiguration}
                    useParentAspectRatio
                />
            </TeaserImageLink>
        </ResponsiveMediaRenderer>
    );

    const additionalLink: JSX.Element | undefined = createAdditionalLink(props);

    const heading = (
        <HeadingElement path="item_0/heading" style="H3" order="H3" />
    );

    const InnerHeadingElement: JSX.Element = !additionalLink ? (
        <TeaserMainLink
            href={href}
            contentId={itemContentId}
            target={sectionLinkTarget as LinkTarget}
            trackingActionOverride={onClickTrack}
            isLayerLink={sectionLinkIsLayerLink}
            layerProps={{layerType: sectionLinkLayerType}}
        >
            {heading}
        </TeaserMainLink>
    ) : (
        heading
    );

    const layout = hasImageRight
        ? FocusTeaserLayout.RIGHT
        : FocusTeaserLayout.LEFT;
    const category = getCategory(props);

    const focusTeaser = (
        <FocusTeaserLsg
            mediaElement={media}
            layout={layout}
            linkElement={additionalLink}
            category={category}
        >
            <FocusTeaserContent headline={InnerHeadingElement} copy={copy} />
        </FocusTeaserLsg>
    );

    return C.isInEditor() ? (
        <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
            <TeaserSectionAuthoringView
                anchorId={anchorId}
                emptyLabel={EMPTY_LABEL}
                validationMessages={validationMessages}
                validationError={validationError}
                tagTypeBroken={tagTypeBroken}
            >
                <AnchorTarget anchorId={anchorId}>
                    {!validationError && focusTeaser}
                </AnchorTarget>
                <SectionDisclaimerContainer
                    displayTypes={['T3_SECTION_BASED']}
                />
            </TeaserSectionAuthoringView>
        </GeneralDisclaimerProvider>
    ) : (
        <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOver}>
                    <CyAttributeAppender name="focusTeaserSection">
                        {focusTeaser}
                    </CyAttributeAppender>
                </div>
                <SectionDisclaimerContainer
                    displayTypes={['T3_SECTION_BASED']}
                />
            </SectionWrapper>
        </GeneralDisclaimerProvider>
    );
});

export const FocusTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/focusTeaserSection',
    InternalFocusTeaserSection
);
