import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerHorizontalAlignment,
    ContainerPadding,
    ContainerWrap,
    Disclaimers,
    CTA,
    styled
} from '@volkswagen-onehub/components-core';
import {getContainerPadding, getEndDirection} from '../d6/components/helpers';
import {browserWindowHeight} from '../d6/components/helpers/useBrowserWindowHeight';
import {Close} from '@volkswagen-onehub/icons-core';

import {StyledDisclaimerReference} from './disclaimers/helpers';
import {AutoFocusInside} from '../utils/AutoFocusInLayer';

export interface LightboxProps {
    heading: JSX.Element;
    copy: JSX.Element;
    disclaimer?: JSX.Element;
    primaryButton: JSX.Element;
    secondaryButton?: JSX.Element;
    switchButtonOrder?: boolean;
    btnCloseLabel: string;
    onClose: () => void;
}

const StyledLightbox = styled.div`
    max-height: calc(
        ${browserWindowHeight} - (${props => props.theme.size.dynamic0250} * 2)
    );
    position: relative;
`;
StyledLightbox.displayName = 'StyledLightbox';

const StyledHeader = styled.header`
    display: flex;
    background: ${props => props.theme.colors.background.primary};
    justify-content: space-between;
`;

const StyledCTAWrapper = styled.div`
    position: absolute;
    ${props => getEndDirection(props.theme.direction)}: ${props =>
        props.theme.size.static350};
    top: 25px;
`;

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);

export const Lightbox: React.FunctionComponent<LightboxProps> = props => {
    const {
        heading,
        disclaimer,
        copy,
        primaryButton,
        secondaryButton,
        switchButtonOrder,
        onClose,
        btnCloseLabel
    } = props;

    return (
        <StyledLightbox>
            {/*
                Extra Container on purpose to prevent overflow problem,
                see https://github.com/volkswagen-onehub/components-core/issues/630
            */}
            <Container>
                <Container
                    wrap={ContainerWrap.always}
                    gutter={ContainerGutter.dynamic0100}
                    padding={{
                        top: ContainerPadding.dynamic0150,
                        bottom: ContainerPadding.dynamic0150,
                        left: CONTAINER_PADDING_2_1,
                        right: CONTAINER_PADDING_2_1
                    }}
                >
                    <StyledHeader>
                        {heading}
                        <StyledCTAWrapper>
                            <CTA
                                icon={<Close ariaHidden />}
                                tag="button"
                                key="close"
                                onClick={onClose}
                                emphasis="tertiary"
                                aria-label={btnCloseLabel}
                            />
                        </StyledCTAWrapper>
                    </StyledHeader>
                    {copy}
                    {disclaimer && (
                        <StyledDisclaimerReference>
                            {/* Safewords not supported, because of markdown */}
                            <Disclaimers disclaimers={[{text: disclaimer}]} />
                        </StyledDisclaimerReference>
                    )}
                    <Container
                        gutter={ContainerGutter.static300}
                        padding={ContainerPadding.dynamic0100}
                        horizontalAlign={ContainerHorizontalAlignment.center}
                        shrinkContent
                    >
                        {secondaryButton &&
                            switchButtonOrder &&
                            secondaryButton}
                        <AutoFocusInside cssDisplay="inline-block">
                            {primaryButton}
                        </AutoFocusInside>
                        {secondaryButton &&
                            !switchButtonOrder &&
                            secondaryButton}
                    </Container>
                </Container>
            </Container>
        </StyledLightbox>
    );
};
