export type HorizontalAlignment = 'right' | 'center' | 'left';
export type VerticalAlignment = 'top' | 'center' | 'bottom';

//the top and bottom values are wrong and need to be switched
export const verticalPercentages = {
    top: 66,
    center: 50,
    bottom: 33
};

export const horizontalPercentages = {
    left: 33,
    center: 50,
    right: 66
};

export interface FocalPoint {
    readonly x: HorizontalAlignment;
    readonly y: VerticalAlignment;
}
