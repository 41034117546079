const whiteSpaceColors: {matcher: RegExp; color: string}[] = [
    {matcher: /parsys/, color: 'lightgray'},
    {matcher: /item/, color: 'lightyellow'},
    {matcher: /element/, color: 'lightcyan'},
    {matcher: /section/, color: 'lightblue'}
];

export function getBackgroundColor(type?: string) {
    if (!type) {
        return undefined;
    }
    const config = whiteSpaceColors.find(c =>
        type.toLowerCase().match(c.matcher)
    );
    if (config) {
        return config.color;
    }

    return undefined;
}
