import * as React from 'react';

import {
    Text,
    TokenTextAppearance,
    TokenTextColor,
    styled
} from '@volkswagen-onehub/components-core';
import {Close} from '@volkswagen-onehub/icons-core';
import {
    handleDisableBodyScroll,
    handleEnableBodyScroll
} from '../../utils/bodyScrollLock';

const elementMaxSize = '44px';

const StyledFullscreenContainer = styled.div`
    top: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 5000;
    touch-action: pan-x;
`;
StyledFullscreenContainer.displayName = 'StyledFullscreenContainer';

const StyledCloseButtonContainer = styled.div`
    position: absolute;
    margin-top: ${props => props.theme.size.dynamic0050};
    margin-left: ${props => props.theme.size.grid001};
    top: 0;
    left: 0;
    z-index: 5001;
    color: white;
`;

const StyledButton = styled.button`
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    color: inherit;
    appearance: none;

    // NOTE: larger button for better accessibility
    width: ${elementMaxSize};
    height: ${elementMaxSize};

    // NOTE: get rid off inline element space under
    font-size: 0;

    &:hover,
    &:focus {
        color: ${props => props.theme.colors.focus.main};
        outline: 0;
    }
`;

interface FullscreenContainerProps {
    onCloseFullscreen(): void;
}

// todo: use Layer Manager -- currently doesn't support fullscreen and needs to be refactored
export class FullscreenLayerWrapper extends React.Component<
    FullscreenContainerProps
> {
    private readonly ref: React.RefObject<HTMLDivElement> = React.createRef();

    public componentDidMount(): void {
        handleDisableBodyScroll(this.ref.current);
    }

    public componentWillUnmount(): void {
        handleEnableBodyScroll();
    }

    public render(): JSX.Element {
        const {children} = this.props;

        return (
            <StyledFullscreenContainer ref={this.ref}>
                {children}
                <StyledCloseButtonContainer>
                    <StyledButton
                        onTouchEndCapture={this.handleFullscreenCloseClick}
                        onClickCapture={this.handleFullscreenCloseClick}
                    >
                        <Close ariaHidden />
                        <Text
                            appearance={TokenTextAppearance.copy100}
                            color={TokenTextColor.inherit}
                        >
                            Close
                        </Text>
                    </StyledButton>
                </StyledCloseButtonContainer>
            </StyledFullscreenContainer>
        );
    }

    private readonly handleFullscreenCloseClick = (
        e: React.SyntheticEvent<HTMLElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        const {onCloseFullscreen} = this.props;
        onCloseFullscreen();
    };
}
