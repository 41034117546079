import * as React from 'react';
import styled, {css} from 'styled-components';

import {getElectricTransitionAdvanced} from '../helpers/css';
import {TeaserLinkProps} from '../teaser-element/types';

interface StyledImageLinkProps {
    readonly disabled?: boolean;
    readonly isFocused?: boolean;
    readonly videoScaling?: boolean;
}

const TRANSITION_DURATION = '2.2s';

const disabledStyle = css`
    pointer-events: none;
    cursor: not-allowed;
`;

// apply style only to img element so it doesn't get applied to disclaimers
const hoverStyle = css`
    outline: 0;
    img {
        transform: scale(1.03);
    }
`;

const videoHoverStyle = css`
    video {
        transform: scale(1.03);
    }
`;

const hoverEffect = css`
    :hover,
    :focus {
        ${hoverStyle};
    }
`;

const StyledImageLink = styled.a<StyledImageLinkProps>`
    text-decoration: none;
    display: block;
    cursor: pointer;
    overflow: hidden;
    ${props => props.isFocused && hoverStyle};
    ${props => props.isFocused && props.videoScaling && videoHoverStyle};
    ${props => (props.disabled ? disabledStyle : hoverEffect)};
    img,
    video {
        transition: ${getElectricTransitionAdvanced({
            name: 'transform',
            duration: TRANSITION_DURATION
        })};
    }
`;

export const ImageLink: React.FunctionComponent<TeaserLinkProps> = props => {
    const {
        elementRef,
        href,
        disabled,
        onClick,
        title,
        target,
        rel,
        onMouseOut,
        onMouseOver,
        isFocused,
        videoScaling,
        children
    } = props;

    return (
        <StyledImageLink
            ref={elementRef}
            href={href}
            onClick={(!disabled && onClick) || undefined}
            target={target}
            rel={rel}
            title={title}
            disabled={disabled}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            isFocused={isFocused}
            tabIndex={-1}
            aria-hidden="true"
            videoScaling={videoScaling}
        >
            {children}
        </StyledImageLink>
    );
};
