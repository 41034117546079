import React from 'react';

import {Breakpoints} from '@volkswagen-onehub/components-core';

// necessary for the element to land in the bundle, as it is only included via Parsyses
import './IconBulletListElement';

import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {PowerTeaserPromoElementModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {ParsysProps, InternalParsys, ParsysType} from '../../structure/Parsys';
import {C} from '../../../registries/compatibilty';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {
    MediaContext,
    MediaContextData
} from '../../../context/media/MediaContext';
import {MediaElement} from './MediaElement';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {RichtextSimpleElement} from './RichtextElement';
import {HeadingElement} from './HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {elseUndefined} from '../../../utils/elseUndefined';
import {PowerTeaserPromo} from '../../../d6/components/power-teaser-promo';

export const PromoBulletsParsys = MapTo<ParsysProps>(
    'vwa-ngw18/components/editorial/elements/powerTeaserPromoElement/bulletsParsys',
    InternalParsys
);

export const PromoButtonsParsys = MapTo<ParsysProps>(
    'vwa-ngw18/components/editorial/elements/powerTeaserPromoElement/buttonsParsys',
    InternalParsys
);

type OwnProps = {
    lightMode: boolean;
};
type Props = OwnProps & PowerTeaserPromoElementModel;

const BUTTONS_PARSYS_PATH = 'buttonsParsys';
const BULLETS_PARSYS_PATH = 'bulletsParsys';

const responsiveMediaConfig: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        aspectRatio: 'r_1_1',
        sizes: 'calc(calc(100vw / 24) * 22)' // 22 grid columns
    },
    {
        mediaQuery: `(min-width: ${
            Breakpoints.b560
        }px) and (max-width: ${Breakpoints.b1280 - 1}px)`,
        aspectRatio: 'r_16_9',
        sizes: '100vw' // 24 columns
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b1280}px)`,
        aspectRatio: 'r_16_9',
        sizes: 'calc(calc(100vw / 24) * 14)' // 14 grid columns
    }
];

const authorMediaConfig: MediaContextData = {
    ratio: 'r_16_9',
    sizes: 'calc(calc(100vw / 24) * 14)'
};

const InternalPowerTeaserPromoElement: React.FunctionComponent<Props> = (
    props: Props
) => {
    const hasButtons =
        C.isInEditor() ||
        getChildItems(props.cqItems[BUTTONS_PARSYS_PATH]).length > 0;
    const hasBullets =
        C.isInEditor() ||
        getChildItems(props.cqItems[BULLETS_PARSYS_PATH]).length > 0;

    const mediaElement = (
        <ResponsiveMediaRenderer configs={responsiveMediaConfig}>
            <MediaElement
                path="media"
                responsiveMediaConfig={responsiveMediaConfig}
                useParentAspectRatio
            />
        </ResponsiveMediaRenderer>
    );

    const media = C.isInEditor() ? (
        // make sure that there is only one media for a path in dom,
        // otherwise aem author cannot set the edit overlay correctly
        <MediaContext.Provider value={authorMediaConfig}>
            {mediaElement}
        </MediaContext.Provider>
    ) : (
        mediaElement
    );

    const overline = <RichtextSimpleElement path="overline" />;

    const headline = <HeadingElement path="heading" order="H2" style="H2" />;

    const copy = <CopyItem path="copy" />;

    const bullets = elseUndefined(
        hasBullets,
        <PromoBulletsParsys
            key="bullets"
            path={BULLETS_PARSYS_PATH}
            parsysType={ParsysType.ITEM_PARSYS}
        />
    );

    const buttons = elseUndefined(
        hasButtons,
        <PromoButtonsParsys
            key="buttons"
            path={BUTTONS_PARSYS_PATH}
            parsysType={ParsysType.INLINE_BUTTON_PARSYS}
        />
    );

    return (
        <AuthoringWrapper
            title="Power Teaser Promo Element"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <PowerTeaserPromo
                media={media}
                overline={overline}
                headline={headline}
                copy={copy}
                bullets={bullets}
                buttons={buttons}
                lightMode={props.lightMode}
            />
        </AuthoringWrapper>
    );
};

export const PowerTeaserPromoElement = MapTo<OwnProps>(
    'vwa-ngw18/components/editorial/elements/powerTeaserPromoElement',
    InternalPowerTeaserPromoElement
);
