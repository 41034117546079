const vwa_components = (suffix: string) => `vwa-ngw18/components/${suffix}`;

export const rareModulesDefinition = [
    vwa_components('editorial/items/accordionItem'),
    vwa_components('editorial/items/carFeatureCategoryItem'),
    vwa_components('editorial/items/glossaryItem'),
    vwa_components('editorial/items/proContraItem'),
    vwa_components('editorial/items/stepByStepItem'),
    vwa_components('editorial/sections/plainHTMLSection'),
    vwa_components('editorial/sections/accordionSection'),
    vwa_components('editorial/sections/countdownSection'),
    vwa_components('editorial/sections/featureClusterSection'),
    vwa_components('editorial/sections/glossarySection'),
    vwa_components('editorial/sections/iframeIntegratorSection'),
    vwa_components('editorial/sections/newsletterSignupSection'),
    vwa_components('editorial/sections/proContraSection'),
    vwa_components('editorial/sections/stepByStepSection'),
    vwa_components('editorial/sections/tableSection')
];
