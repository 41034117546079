import {
    ImageRequest,
    ImageServiceV1,
    OptionalParams
} from '@volkswagen-onehub/image-service';
import {MediaAssetImageModel} from '../../generated/core';
import {aspectRatioMap} from './aspectRatioMap';
import {focalPointMap} from './focalPointMap';

export interface ImageResourceSet {
    readonly src: string;
    readonly srcSet: string;
    readonly srcSetWebP: string;
}

const DEFAULT_IMAGE_WIDTH = 800;

export function getImageResourceSet(
    imageService: ImageServiceV1,
    props: Partial<MediaAssetImageModel>,
    enableSmartImaging: boolean,
    transparent?: boolean
): ImageResourceSet {
    let src: string;
    let srcSet = '';
    let srcSetWebP = '';
    const {
        src: originalSrc = '',
        validScene7Asset,
        scene7File,
        scene7Domain,
        originalHeight = 0,
        originalWidth = 0,
        focalPoint,
        aspectRatio
    } = props;

    const useSmartImaging = enableSmartImaging && !transparent;

    if (validScene7Asset) {
        const imageRequest: ImageRequest = {
            scene7File,
            metaData: {
                originalHeight,
                originalWidth,
                scene7Domain
            },
            contentFile: originalSrc
        };

        const imageParams: OptionalParams = {
            aspectRatio: aspectRatio ? aspectRatioMap[aspectRatio] : undefined,
            focalPoint: focalPoint ? focalPointMap[focalPoint] : undefined,
            format: transparent ? 'png-alpha' : 'jpg',
            useSmartImaging
        };

        src = imageService.getImageURL(
            imageRequest,
            DEFAULT_IMAGE_WIDTH,
            imageParams
        );

        srcSet = imageService.getImageSrcSet(imageRequest, imageParams);
        if (!useSmartImaging) {
            srcSetWebP = imageService.getImageSrcSet(imageRequest, {
                ...imageParams,
                format: transparent ? 'webp-alpha' : 'webp'
            });
        }
    } else {
        src = originalSrc;
    }

    return {src, srcSet, srcSetWebP};
}
