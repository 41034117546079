import * as React from 'react';

import {
    NextBestActionButton as D6NextBestActionButton,
    NextBestAction as D6NextBestAction
} from '../../../d6/components/next-best-action-button';

import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getIcon} from '../../../utils/getMappedIcon';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {useScrollToSectionOfFocussedNbab} from './useScrollToSectionOfFocussedNbab';
import {SingleNbabTrackingData} from './useSingleNbabTracking';
import {useSingleNbabClick} from './useSingleNbabClick';
import {useExpandOnScroll} from './useExpandOnScroll';

export type NextBestActionButtonProps = Readonly<{
    actionModel: MergeNextBestActionModel;
    isHidden?: boolean;
    inaccessible?: boolean; // "true" value makes the button inaccessible for keyboard and screen-reader
    trackingData?: SingleNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
}>;

export function SingleMobileNextBestActionButton({
    // enabled,
    inaccessible,
    isHidden,
    actionModel,
    sectionRef,
    trackingData
}: NextBestActionButtonProps): JSX.Element | null {
    const [forceExpand, setForceExpand] = React.useState<boolean | undefined>(
        undefined
    );
    const ref = React.useRef(null);

    useScrollToSectionOfFocussedNbab(ref, sectionRef);

    // force expand only applies to nbab as single action. position is always 1
    useExpandOnScroll(setForceExpand);

    // create link
    const [linkClick, linkHref] = useSingleNbabClick(
        ref,
        actionModel,
        trackingData
    );

    // create next best action
    const href = linkHref;
    const action = ({
        content: actionModel.label,
        icon: getIcon(actionModel.icon, 'default'),
        tag: href && actionModel.linkType !== 'action' ? 'a' : 'button',
        href,
        onClick: linkClick,
        ariaLabel: actionModel.label,
        title: actionModel.title,
        target:
            actionModel.linkType === 'external'
                ? actionModel.externalLinkTarget
                : undefined
    } as unknown) as D6NextBestAction;

    return (
        <CyAttributeAppender name="NextBestActionButton">
            <D6NextBestActionButton
                action={action}
                ref={ref}
                tabIndex={inaccessible ? -1 : 0}
                hideIcon={isHidden}
                expandContent={forceExpand}
                title={actionModel.title}
                hideBadge
                isHidden={isHidden}
            />
        </CyAttributeAppender>
    );
}
