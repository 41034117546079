export function createSearchQuery(
    searchString: string,
    options: {distance: number; fields: {name: string; boost: number}[]}
): string {
    const {distance, fields} = options;
    const words = splitToWords(searchString);

    // search with prefix for last word
    const prefixClauses = createPrefixClauses(fields, words);

    const phraseClauses = fields.map(field => {
        const fieldName = field.name;
        const boost = field.boost * 2;

        return words.list
            .map(word => `(term field=${fieldName} boost=${boost} '${word}')`)
            .join(' ');
    });

    const nearClauses =
        words.list.length > 1
            ? fields.map(field => {
                  const boost = 4 * field.boost;
                  const fieldName = field.name;
                  return `(near field=${fieldName} distance=${distance} boost=${boost} '${words.list.join(
                      ' '
                  )}')`;
              })
            : [];

    const allQueries = prefixClauses.concat(phraseClauses).concat(nearClauses);
    return allQueries.length > 1
        ? `(or ${allQueries.join(' ')} )`
        : allQueries[0];
}

function createPrefixClauses(
    fields: {name: string; boost: number}[],
    words: {list: string[]; previous: string; last: string}
) {
    return fields.map(field => {
        const fieldName = field.name;
        const boost = field.boost;

        return words.list
            .map(word => `(prefix field=${fieldName} boost=${boost} '${word}')`)
            .join(' ');
    });
}

export function createSuggestQuery(
    searchString: string,
    options: {fields: {name: string; boost: number}[]}
): string {
    const {fields} = options;
    const words = splitToWords(searchString);

    // search with prefix for last word
    const prefixClauses = createPrefixClauses(fields, words);

    return combineAllClauses(prefixClauses);
}

function combineAllClauses(clauses: string[]): string {
    return clauses.length > 1 ? `(or ${clauses.join(' ')} )` : clauses[0];
}

export function splitToWords(searchString: string) {
    const list = searchString
        .split(' ')
        .map(str => str.trim())
        .filter(str => !!str);
    const last = list[list.length - 1];
    const previous = list
        .filter((_word, index) => index < list.length - 1)
        .join(' ');
    return {
        list,
        previous,
        last
    };
}
