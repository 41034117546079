import * as React from 'react';
import styled from 'styled-components';

const StyledLinkSeparator = styled.li`
    display: inline-block;

    :not(:last-child):after {
        content: '|';
        margin: 0 ${props => props.theme.size.static200};
        color: ${props => props.theme.text.color.primary};
    }

    & a {
        display: inline-block; /* Needed for Edge v44 */
    }
`;

export const LinksSeparator: React.FunctionComponent = props => (
    <>
        {React.Children.map(props.children, child => (
            <StyledLinkSeparator>{child}</StyledLinkSeparator>
        ))}
    </>
);
