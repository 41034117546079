import {FeatureAppConfigurationModel} from '../../../generated/core';
import {TrackingDTO} from '../../context/tracking/TrackingService';
import {getTrackingId} from './getTrackingId';

export function createFeatureAppSectionTrackingData(
    config: FeatureAppConfigurationModel
): Partial<TrackingDTO> | undefined {
    return config
        ? {
              environment: {
                  featureAppId: getTrackingId(config) || undefined,
                  featureAppIdAppVersion: config.featureAppVersion || undefined
              }
          }
        : undefined;
}
