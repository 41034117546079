import * as React from 'react';
import {FullTextCallback, FullTextDisclaimer} from '../DisclaimerContextApi';
import {LazyRef} from './LazyRef';

/**
 * The disclaimer badge and text can be rendered in different order so that full text disclaiemr is not available when badge is rendered.
 */
export class MutableFullTextDisclaimer implements FullTextDisclaimer {
    private _callback?: FullTextCallback;

    init(ftd: FullTextDisclaimer) {
        this.ref.delegate = ftd.ref;
        this._callback = ftd.callback;
    }

    callback(
        sourceReference: React.RefObject<HTMLButtonElement>,
        scrollPosition: number,
        disableScroll: boolean
    ) {
        return (
            this._callback &&
            this._callback(sourceReference, scrollPosition, disableScroll)
        );
    }
    ref = new LazyRef<HTMLDivElement>();
}
