export interface Value<T> {
    value?: T;
}

/**
 * this factory returns a wrapped value for usage in contexts. It will return the same wrapped instance for the same value.
 */
export class ValueFactory<T> {
    private cached?: Value<T>;
    public create(newValue: T): Value<T> {
        if (!this.cached || newValue !== this.cached.value) {
            this.cached = {value: newValue};
        }

        return this.cached;
    }
}
