import * as React from 'react';

import {
    BreakpointWrapper,
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    FixedWidthContainer,
    Layout,
    LayoutConfigurations,
    LayoutRowGap,
    styled
} from '@volkswagen-onehub/components-core';

import {getContainerPadding} from '../helpers';

export interface HighlightTeaserProps {
    readonly headlineElement?: JSX.Element;
    readonly highlightTeaser: JSX.Element;
    readonly sideTeasers: JSX.Element[];
}

const StyledSeparatorWrapper = styled.div`
    @media (max-width: ${Breakpoints.b560}px) {
        border-top: 2px solid ${props => props.theme.colors.border.divider};
        padding-top: ${props => props.theme.size.static350};
    }
`;

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);
const CONTAINER_PADDING_2_3 = getContainerPadding([2, 3]);
const CONTAINER_PADDING_2_0 = getContainerPadding([2, 0]);

const SideTeasersSection: React.FunctionComponent = props => (
    <Container
        gutter={ContainerGutter.dynamic0120}
        padding={{
            inlineStart: CONTAINER_PADDING_2_1,
            inlineEnd: CONTAINER_PADDING_2_3
        }}
        wrap={ContainerWrap.always}
    >
        {props.children}
    </Container>
);

const HighlightTeaserSection: React.FunctionComponent = props => (
    <Container
        gutter={ContainerGutter.dynamic0150}
        padding={{
            inlineStart: CONTAINER_PADDING_2_3,
            inlineEnd: CONTAINER_PADDING_2_0
        }}
        wrap={ContainerWrap.always}
    >
        {props.children}
    </Container>
);

const StyledHeadlineWrapper = styled.div`
    padding-bottom: ${props => props.theme.size.dynamic0150};
    text-align: center;
    padding-right: ${props => props.theme.size.grid001};
    padding-left: ${props => props.theme.size.grid001};
    @media (min-width: ${Breakpoints.b560}px) {
        padding-right: ${props => props.theme.size.grid006};
        padding-left: ${props => props.theme.size.grid006};
    }
`;

export const HighlightTeaser: React.FunctionComponent<HighlightTeaserProps> = props => {
    const {headlineElement, highlightTeaser, sideTeasers} = props;

    return (
        <>
            {headlineElement && (
                <Layout allowOverflowingContent>
                    <StyledHeadlineWrapper>
                        {headlineElement}
                    </StyledHeadlineWrapper>
                </Layout>
            )}
            <Layout
                allowOverflowingContent
                appearance={LayoutConfigurations.half}
                rowGap={LayoutRowGap.static350}
            >
                <HighlightTeaserSection>
                    {highlightTeaser}
                </HighlightTeaserSection>

                <SideTeasersSection>
                    {React.Children.map(sideTeasers, teaser => (
                        <StyledSeparatorWrapper>
                            {teaser}
                        </StyledSeparatorWrapper>
                    ))}
                </SideTeasersSection>
            </Layout>
        </>
    );
};

export const SideTeaserMedia: React.FunctionComponent = ({children}) => (
    <>
        <BreakpointWrapper min={Breakpoints.b560} max={Breakpoints.b1600}>
            <FixedWidthContainer columns={4}>{children}</FixedWidthContainer>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b1600}>
            <FixedWidthContainer columns={3}>{children}</FixedWidthContainer>
        </BreakpointWrapper>
    </>
);
