import {FeatureHub} from '@feature-hub/core';
import {HistoryServiceV2} from '@feature-hub/history-service';
import {inject, singleton} from '../infrastructure/di/annotations';
import {createCustomHistoryService} from '../infrastructure/hub/createCustomHistoryService';

@singleton('CustomHistoryManager')
export class CustomHistoryManager {
    @inject()
    private featureHub!: FeatureHub;
    private histories: Map<string, HistoryServiceV2> = new Map();

    public getHistoryService(consumerId: string): HistoryServiceV2 {
        let history = this.histories.get(consumerId);
        if (!history) {
            history = createCustomHistoryService(this.featureHub, consumerId);
            this.histories.set(consumerId, history);
        }
        return history;
    }
}
