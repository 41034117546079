import * as React from 'react';

export interface LinkHoverGroupProps {}

export interface LinkHoverGroupState {
    readonly activeGroupId: string;
    setActiveGroupId(groupId?: string): undefined;
}

export const HoverGroupContext: React.Context<LinkHoverGroupState> = React.createContext(
    {
        activeGroupId: '',
        setActiveGroupId: () => undefined
    }
);

export class HoverGroup extends React.PureComponent<
    LinkHoverGroupProps,
    LinkHoverGroupState
> {
    public constructor(props: LinkHoverGroupProps) {
        super(props);
        this.state = {
            activeGroupId: '',
            setActiveGroupId: this.setActiveHoverGroup
        };
    }

    private readonly setActiveHoverGroup = (groupId: string) => {
        this.setState({activeGroupId: groupId});

        return undefined;
    };

    public render(): JSX.Element {
        const {children} = this.props;

        return (
            <HoverGroupContext.Provider value={this.state}>
                {children}
            </HoverGroupContext.Provider>
        );
    }
}

export interface HoverGroupTargetProps {
    readonly isFocused?: boolean;
}

export function hoverGroupConsumer<P>(
    WrappedComponent: React.ComponentType<P & HoverGroupTargetProps>,
    hoverGroupId: string
): React.FunctionComponent<P & HoverGroupTargetProps> {
    const HoverGroupTarget: React.FunctionComponent<P &
        HoverGroupTargetProps> = props => (
        <HoverGroupContext.Consumer>
            {context => (
                <WrappedComponent
                    {...props}
                    onMouseOver={() => context.setActiveGroupId(hoverGroupId)}
                    onMouseOut={() => context.setActiveGroupId('')}
                    isFocused={hoverGroupId === context.activeGroupId}
                />
            )}
        </HoverGroupContext.Consumer>
    );

    HoverGroupTarget.displayName = 'HoverGroupTarget';

    return HoverGroupTarget;
}
