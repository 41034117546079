import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';

import {XfContentLayerMainModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {ContentLayer} from '../../../components/content-layer/ContentLayer';
import {
    LayerContext,
    LayerContextData
} from '../../../context/layer/LayerContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {RichtextSimpleElement} from '../../editorial/elements/RichtextElement';
import {Parsys, ParsysProps, ParsysType} from '../Parsys';

const parsysProps: ParsysProps = {
    parsysType: ParsysType.ITEM_PARSYS
};

const StyledFocusTrap = styled.div`
    outline: none;
`;

const CONTEXT_DATA: LayerContextData = {
    layerType: 'CONTENT'
};

interface LayerElements {
    overlineElement: JSX.Element;
    headingElement: JSX.Element;
    parsys: JSX.Element;
}

function AuthoringView(props: LayerElements): JSX.Element {
    const {overlineElement, headingElement, parsys} = props;

    return (
        <AuthoringWrapper title="Content Layer Main">
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={{
                        left: ContainerPadding.grid002,
                        right: ContainerPadding.grid002
                    }}
                    stretchContent
                >
                    {overlineElement}
                    {headingElement}
                    {parsys}
                </Container>
            </Layout>
        </AuthoringWrapper>
    );
}

function PublishView(
    props: XfContentLayerMainModel & LayerElements
): JSX.Element {
    const {headingElement, parsys, layerHeaderUpdaterModel} = props;

    return (
        <ContentLayer
            headline={headingElement}
            metaTagData={layerHeaderUpdaterModel}
        >
            {parsys}
        </ContentLayer>
    );
}

function InternalXfContentLayerMain(
    props: XfContentLayerMainModel
): JSX.Element {
    const headingElement = (
        <HeadingElement path="heading" style="H1" order="H1" />
    );

    const parsys = <Parsys path="contentLayerParsys" {...parsysProps} />;

    // We need to add "focus trap" otherwise layer will autofocus first inner link on open and will scroll down to it
    // Should be replaced with AutoFocusInside once components-core issue is resolved: https://github.com/volkswagen-onehub/components-core/issues/648
    const overlineElement = (
        <StyledFocusTrap tabIndex={0}>
            <Text bold appearance={TokenTextAppearance.headline100}>
                <RichtextSimpleElement path="overline" />
            </Text>
        </StyledFocusTrap>
    );

    const Layer = C.isInEditor() ? AuthoringView : PublishView;

    return (
        <LayerContext.Provider value={CONTEXT_DATA}>
            <Layer
                {...props}
                headingElement={headingElement}
                parsys={parsys}
                overlineElement={overlineElement}
            />
        </LayerContext.Provider>
    );
}

export const XfContentLayerMain = MapTo(
    'vwa-ngw18/components/structure/xfContentLayerMain',
    InternalXfContentLayerMain
);
