import {
    Breakpoints,
    ThemeProvider,
    getGridColumn,
    styled
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {ContentBlock} from '.';
import {DisplayType} from '../../../../../generated/core';
import {GeneralDisclaimerProvider} from '../../../../context/disclaimer/GeneralDisclaimerProvider';
import {MediaContext} from '../../../../context/media/MediaContext';
import {useLayoutEffect} from '../../../../utils/getIsomorphicLayoutEffect';
import {smoothScrollTo} from '../../../../utils/smoothScroll';
import {MediaElement} from '../../elements/MediaElement';
import {DisclaimerOverlay} from './DisclaimerOverlay';
import {ScrollytellingContext} from './ScrollLogic';
import {
    StyledContentDisclaimer,
    StyledVideoContentBlock,
    VideoContentBlock
} from './VideoContentBlock';
import {getIndexFromNumberRange, getScrollPosition, save} from './helper';

export const VIDEO_DISCLAIMER = 'video-disclaimer';
export const CONTENT_DISCLAIMER = 'content-disclaimer';
export const ACTIVE_SCROLLYTELLING_CONTENT = 'active-scrollytelling';
const TOPBAR_OFFSET = 100;
const INITIAL_INDEX = -1;

const StyledMediaWrapper = styled.div`
    position: relative;
`;

const StyledSkipButton = styled.div`
    position: relative;
    padding-block-end: ${props => props.theme.size.dynamic0150};
    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-end: ${getGridColumn(8)};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-inline-end: ${getGridColumn(12)};
    }
`;

const StyledBackground = styled.div<{isContentVisible: boolean}>`
    --background-size: ${props => (props.isContentVisible ? '25' : '100')}%;

    background-color: ${props => props.theme.colors.overlay.default};
    position: relative;
    pointer-events: none;
    transform-origin: bottom;

    &:before {
        content: '';
        position: absolute;
        inset-inline-start: 0;
        inset-block-start: calc(var(--background-size) * -1);
        height: var(--background-size);
        width: 100%;
        background: linear-gradient(
            to top,
            ${props => props.theme.colors.overlay.default},
            rgba(0, 0, 0, 0)
        );
        pointer-events: none;
    }
`;

//the clip-path is needed to stop the shim from overflowing the component
const StyledMedia = styled.div<{isContentVisible: boolean}>`
    display: grid;
    grid-template-rows: 1fr max-content max-content max-content max-content;
    grid-template-columns: ${props => props.theme.size.grid002} 1fr ${props =>
            props.theme.size.grid002};
    height: var(--full-viewport, 100%);
    position: relative;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid001};
    }

    & ${StyledBackground} {
        grid-column: 1 / 4;
        grid-row: ${props => (props.isContentVisible ? 2 : 3)} / 6;
    }

    & ${StyledMediaWrapper} {
        height: 100%;
        grid-column: 1/4;
        grid-row: 1/6;
    }

    & ${StyledContentDisclaimer}, & ${StyledVideoContentBlock} {
        grid-column: 2/3;
        grid-row: 2/3;
    }

    & ${StyledSkipButton} {
        grid-column: 2/3;
        grid-row: 3/4;
    }

    & .${VIDEO_DISCLAIMER} {
        grid-column: 2/4;
        grid-row: 4/5;
    }

    & ${StyledContentDisclaimer}.active {
        grid-column: 2/4;
        grid-row: 5/6;
    }
`;

export type Visibility =
    | 'hidden'
    | 'animating-in'
    | 'animating-out'
    | 'visible';

/*
    when the currentIndex changes, it will indicate a change of the active contentBlock and animate/fade the current one out.
    On animation end, we change the delayedIndex, which is the "real" index. Its change will start the fading in animation.
    */
export const calculateVisibility = ({
    contentBlockIndex,
    delayedIndex,
    currentIndex
}: {
    contentBlockIndex: number;
    delayedIndex: number;
    currentIndex: number;
}): Visibility => {
    //when all indices are the same, the animating-in took place and the element is visible
    if (delayedIndex === currentIndex && currentIndex === contentBlockIndex) {
        return 'visible';
    }
    //when the current index is like the contentBlockIndex, we are currently fading the contentBlock in and waiting for the callback to change the delayed Index
    if (delayedIndex !== currentIndex && currentIndex === contentBlockIndex) {
        return 'animating-in';
    }
    //when the delayed index is like the contentBlockIndex but not like the current index, then we are about to fade a new contentBlock in
    if (delayedIndex !== currentIndex && delayedIndex === contentBlockIndex) {
        return 'animating-out';
    }
    return 'hidden';
};
interface InvertedTransforms {
    x: number;
    y: number;
    scaleX: number;
    scaleY: number;
}

export const getInvertedTransform = (
    firstDimensions: DOMRect,
    lastDimensions: DOMRect
): InvertedTransforms => ({
    x: firstDimensions.x - lastDimensions.x,
    y: firstDimensions.y - lastDimensions.y,
    scaleX: save(firstDimensions.width / lastDimensions.width, 1),
    scaleY: save(firstDimensions.height / lastDimensions.height, 1)
});

export const calculateDimensionDifferences = (
    elements: HTMLElement[],
    dimensionMap: WeakMap<HTMLElement, DOMRect>
) => {
    const dimensionalChange = new Map<HTMLElement, InvertedTransforms>();
    elements.forEach(element => {
        const dimensions = element.getBoundingClientRect();

        if (dimensionMap.has(element)) {
            dimensionalChange.set(
                element,
                getInvertedTransform(dimensionMap.get(element)!, dimensions)
            );
        }
    });
    return dimensionalChange;
};

export const updateDimensions = (
    childElements: HTMLCollection,
    dimensionMap: WeakMap<HTMLElement, DOMRect>
) => {
    Array.from(childElements, childElement =>
        dimensionMap.set(
            childElement as HTMLElement,
            childElement.getBoundingClientRect()
        )
    );
};

export const updateLayoutOnDOMChange = (
    mediaElement: HTMLElement | null,
    dimensionMap: WeakMap<HTMLElement, DOMRect>,
    updateLayout: VoidFunction
) => {
    const childElements = mediaElement?.children;

    if (!childElements) {
        return;
    }
    updateDimensions(childElements, dimensionMap);
    updateLayout();
};

export const animateContentOnClick = (
    mediaElement: HTMLElement | null,
    dimensionMap: WeakMap<HTMLElement, DOMRect>
) => {
    const childElements = mediaElement?.querySelectorAll(
        `.${ACTIVE_SCROLLYTELLING_CONTENT}`
    );

    if (!childElements) {
        return;
    }
    calculateDimensionDifferences(
        Array.from(childElements) as HTMLElement[],
        dimensionMap
    ).forEach(({x, y}, element) => {
        const from = `translate(${x}px, ${y}px)`;
        const to = 'translate(0, 0)';
        element.animate({transform: [from, to]}, 300);
    });
};

export const animateBackgroundOnClick = (
    backgroundElement: HTMLElement | null,
    dimensionMap: WeakMap<HTMLElement, DOMRect>
) => {
    if (!backgroundElement) {
        return;
    }
    calculateDimensionDifferences([backgroundElement], dimensionMap).forEach(
        ({scaleX, scaleY}, element) => {
            const from = `scale(${scaleX}, ${scaleY})`;
            const to = 'scale(1, 1)';
            element.animate({transform: [from, to]}, 300);
        }
    );
};

export const skipToNextContent = (
    event: React.MouseEvent<HTMLButtonElement>
) => {
    const target = event.target as HTMLButtonElement;
    const currentID = target.closest('section')?.getAttribute('id')!;

    const sectionList = Array.from(
        document.querySelectorAll(`section[class^="StyledAnchorTarget"]`)
    );
    const currentIndex = sectionList.findIndex(element =>
        element.getAttribute('id')?.match(currentID)
    );

    const sectionsAfterScrollytelling = sectionList
        .concat(document.querySelector('footer')!)
        .slice(currentIndex === -1 ? currentIndex : currentIndex + 1);

    const followingSection = sectionsAfterScrollytelling[0] as HTMLElement;

    const distance =
        window.scrollY +
        followingSection.getBoundingClientRect().top -
        TOPBAR_OFFSET;

    smoothScrollTo({
        distance,
        onScrollEnd() {
            followingSection.focus();
        }
    });
};

export interface ScrollableVideoElementProps {
    content: ContentBlock[];
    skipButton: JSX.Element;
}

export const ScrollableVideoElement = (
    props: ScrollableVideoElementProps
): JSX.Element => {
    const {content, skipButton} = props;
    const [onLayoutChange, updateLayout] = React.useReducer(x => x + 1, 0);
    const [currentIndex, setCurrentIndex] = React.useState(INITIAL_INDEX);
    const [delayedIndex, setDelayedIndex] = React.useState(currentIndex);

    const disclaimerTypes: DisplayType[] = ['T4_ITEM_BASED'];
    const backgroundRef = React.useRef<HTMLDivElement>(null);
    const mediaRef = React.useRef<HTMLDivElement>(null);
    const dimensionRef = React.useRef(new WeakMap<HTMLElement, DOMRect>());

    const isContentVisible = currentIndex !== -1 || delayedIndex !== -1;

    const {subscribe, getCalculations} = React.useContext(
        ScrollytellingContext
    );

    React.useEffect(() => {
        subscribe(scrollAmount => {
            setCurrentIndex(
                getIndexFromNumberRange(scrollAmount.video, content)
            );
        });
    }, [subscribe, content]);

    useLayoutEffect(() => {
        animateContentOnClick(mediaRef.current, dimensionRef.current);
    }, [onLayoutChange]);

    useLayoutEffect(() => {
        animateBackgroundOnClick(backgroundRef.current, dimensionRef.current);
    }, [onLayoutChange]);

    const animateOnClose = () =>
        updateLayoutOnDOMChange(
            mediaRef.current,
            dimensionRef.current,
            updateLayout
        );

    const scrollCalculations = React.useMemo(getCalculations, [
        getCalculations
    ]);

    return (
        <ThemeProvider theme={'inverted'}>
            <StyledMedia ref={mediaRef} isContentVisible={isContentVisible}>
                <GeneralDisclaimerProvider
                    displayTypes={disclaimerTypes}
                    disableScroll
                >
                    <StyledMediaWrapper>
                        <MediaContext.Provider
                            value={{
                                sizes: '100vw',
                                reduceBrightness: true
                            }}
                        >
                            <MediaElement
                                path="media"
                                hideItemBasedDisclaimers
                                enableItemInteractiveDisclaimer
                                useParentAspectRatio
                            />
                        </MediaContext.Provider>
                    </StyledMediaWrapper>
                    <StyledBackground
                        ref={backgroundRef}
                        isContentVisible={isContentVisible}
                    />
                    <StyledSkipButton>{skipButton}</StyledSkipButton>
                    <DisclaimerOverlay
                        displayTypes={disclaimerTypes}
                        onClose={animateOnClose}
                        className={`${VIDEO_DISCLAIMER} ${ACTIVE_SCROLLYTELLING_CONTENT}`}
                    />
                </GeneralDisclaimerProvider>
                {content.map((contentBlock, contentBlockIndex) => (
                    <VideoContentBlock
                        key={contentBlock.id}
                        scrollPosition={getScrollPosition(
                            contentBlock.end,
                            scrollCalculations
                        )}
                        updateLayout={animateOnClose}
                        onAnimationEnd={() => setDelayedIndex(currentIndex)}
                        visibility={calculateVisibility({
                            contentBlockIndex,
                            delayedIndex,
                            currentIndex
                        })}
                        trackItem={contentBlock.track}
                    >
                        {contentBlock.headline}
                        {contentBlock.copy}
                    </VideoContentBlock>
                ))}
            </StyledMedia>
        </ThemeProvider>
    );
};
