import * as React from 'react';

import {styled, useTheme} from '@volkswagen-onehub/components-core';

import {GalleryContext} from '.';

const StyledScrollbarArea = styled.div`
    height: ${({theme}) => theme.size.static500};
    position: relative;

    display: flex;
    justify-content: center;
    flex-flow: column;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: ${({theme}) => theme.colors.background.secondary};
        border-radius: 2px;
    }
`;

type StyledScrollbarProps = {
    readonly x?: number;
    readonly isDragging: boolean;
    readonly slides: number;
};

const StyledScrollbar = styled.div.attrs<StyledScrollbarProps>(
    ({x, slides}) => ({
        style: {
            '--progress': `${x}px`,
            '--slides': slides
        }
    })
)<StyledScrollbarProps>`
    position: absolute;
    ${props => (props.theme.direction === 'rtl' ? 'right' : 'left')}: 0;

    transform: ${props =>
        props.theme.direction === 'rtl'
            ? `translateX(calc(-1 * var(--progress)))`
            : `translateX(var(--progress))`};

    height: 100%;
    width: 33%;

    // NOTE: this will be added later as option via prop,
    // but also width of bar needs to be updated by "slides per page":
    // width: calc(100% / var(--slides));
    // min-width: 100px;

    cursor: grab;
    user-select: none;

    display: flex;
    flex-flow: column;
    justify-content: center;

    // enable the transition animation only with dragging off
    ${props =>
        !props.isDragging &&
        `
		transition: transform 150ms ease-out;
	`}

    // bar line
	&:before {
        content: '';
        display: block;
        height: 3px;
        background: ${({theme}) => theme.colors.border.hover};
        border-radius: 3px;

        transition: background-color 150ms;
    }

    // hover/focus outline
    &:after {
        position: absolute;
        z-index: -1;
        content: '';

        box-sizing: border-box;
        display: block;
        height: ${({theme}) => theme.size.static350};
        left: -10px;
        right: -10px;

        background: ${({theme}) => theme.colors.background.tertiary};
        border-radius: ${({theme}) => theme.size.static300};
        opacity: 0;
        transition: opacity 150ms, background-color 150ms;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    &:active {
        cursor: grabbing;
    }

    &:focus {
        outline: none;
    }

    &:active,
    &:focus {
        &:after {
            opacity: 1;
            border: solid 3px ${({theme}) => theme.colors.focus.main};
        }
    }
`;

const activeSlideIndex: {[galleryId: string]: number} = {};
const isDragging: {[galleryId: string]: boolean} = {};

const TIMEOUT_TO_ENABLE_SNAPPING = 500;

export const NavigationScrollbar: React.FunctionComponent = () => {
    const {
        index,
        length,
        slidesPerPage,
        slidesRef,
        id: galleryID,
        triggerChange,
        disableScrollSnap
    } = React.useContext(GalleryContext);

    const theme = useTheme();

    const gallerySlides = slidesRef?.current;
    const scrollbarAreaRef = React.useRef<HTMLDivElement>(null);
    const scrollbarRef = React.useRef<HTMLDivElement>(null);

    const [scrollbarAreaWidth, setScrollbarAreaWidth] = React.useState(0);
    const [scrollbarWidth, setScrollbarWidth] = React.useState(0);
    const [scrollbarPosition, setScrollbarPosition] = React.useState(0);

    const scrollbarArea = scrollbarAreaRef.current;
    const scrollbar = scrollbarRef.current;
    const maxScrollbarPosition = scrollbarAreaWidth - scrollbarWidth;

    activeSlideIndex[galleryID] = index;

    const roundToNearest = (value: number, interval = 1) => {
        return Math.floor(value / interval) * interval;
    };

    const handleScrollbarPosition = React.useCallback(
        (value: number) => {
            if (value <= 0) {
                return 0;
            }

            if (value > maxScrollbarPosition) {
                return maxScrollbarPosition;
            }

            return roundToNearest(value);
        },
        [maxScrollbarPosition]
    );

    const handleScrollbarPositionByGalleryPosition = React.useCallback(() => {
        const scrollbarArea = scrollbarAreaWidth - scrollbarWidth;
        const newPositionPercentage =
            activeSlideIndex[galleryID] /
            ((length - slidesPerPage) / 100) /
            100;
        const newPosition = newPositionPercentage * scrollbarArea;

        return Math.round(newPosition);
    }, [galleryID, length, scrollbarAreaWidth, scrollbarWidth, slidesPerPage]);

    const getActuallScrollPercentage = (
        scrollbarPosition: number,
        maxScrollbarPosition: number
    ) => {
        const value = scrollbarPosition / (maxScrollbarPosition / 100);

        // at times the  calculation above returns NaN when both operands evaluate to 0
        if (value <= 0 || Number.isNaN(value)) {
            return 0;
        }

        if (value > 100) {
            return 100;
        }

        return value;
    };

    const setScrollbarSize = React.useCallback(() => {
        setScrollbarAreaWidth(scrollbarArea?.clientWidth || 0);
        setScrollbarWidth(scrollbar?.clientWidth || 0);
    }, [scrollbar?.clientWidth, scrollbarArea?.clientWidth]);

    React.useEffect(() => {
        !isDragging[galleryID] &&
            setScrollbarPosition(handleScrollbarPositionByGalleryPosition());
    }, [
        index,
        length,
        scrollbarAreaWidth,
        scrollbarWidth,
        handleScrollbarPositionByGalleryPosition,
        galleryID
    ]);

    React.useEffect(() => {
        let mouseDownClientX = 0;
        let slideWidth = 0;
        let slidePaddingRight = 0;
        let totalSlideMargin = 0;

        if (gallerySlides) {
            slideWidth = gallerySlides.children[0].clientWidth;
            slidePaddingRight = parseFloat(
                getComputedStyle(gallerySlides.children[0]).getPropertyValue(
                    `padding-${theme.direction === 'rtl' ? 'left' : 'right'}`
                )
            );

            totalSlideMargin =
                parseFloat(
                    getComputedStyle(
                        gallerySlides.children[0]
                    ).getPropertyValue(
                        `margin-${theme.direction === 'rtl' ? 'right' : 'left'}`
                    )
                ) * 2;
        }

        setScrollbarSize();

        const handleDragging = (clientX: number): void => {
            const deltaX = clientX - mouseDownClientX;

            const newScrollbarPosition =
                theme.direction === 'rtl'
                    ? scrollbarPosition - deltaX
                    : scrollbarPosition + deltaX;

            setScrollbarPosition(handleScrollbarPosition(newScrollbarPosition));

            const positionPercantage = getActuallScrollPercentage(
                newScrollbarPosition,
                maxScrollbarPosition
            );

            if (gallerySlides) {
                const scrollTo =
                    (positionPercantage / 100) *
                    (gallerySlides.scrollWidth -
                        slideWidth * slidesPerPage -
                        totalSlideMargin +
                        slidePaddingRight);

                gallerySlides.scrollTo({
                    left: roundToNearest(
                        theme.direction === 'rtl' ? scrollTo * -1 : scrollTo
                    ),
                    behavior: 'auto'
                });
            }
        };

        const handleDragEnd = (): void => {
            triggerChange(activeSlideIndex[galleryID]);
            setTimeout(() => {
                !isDragging[galleryID] && disableScrollSnap(false);
            }, TIMEOUT_TO_ENABLE_SNAPPING);
            setScrollbarPosition(handleScrollbarPositionByGalleryPosition());
        };

        const handleMouseDown = (event: MouseEvent): void => {
            isDragging[galleryID] = true;
            disableScrollSnap();
            mouseDownClientX = event.clientX;
            document.addEventListener('mousemove', handleMouseDrag);
            document.addEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'grabbing';
            document.body.style.userSelect = 'none';
            document.body.style.webkitUserSelect = 'none'; // still needed for Safari!
        };

        const handleMouseDrag = (event: MouseEvent): void => {
            handleDragging(event.clientX);
        };

        const handleMouseUp = (): void => {
            isDragging[galleryID] = false;
            document.removeEventListener('mousemove', handleMouseDrag);
            document.removeEventListener('mouseup', handleMouseUp);
            document.body.style.cursor = 'auto';
            handleDragEnd();
        };

        const handleTouchStart = (event: TouchEvent): void => {
            isDragging[galleryID] = true;
            disableScrollSnap();
            mouseDownClientX = event.touches[0].clientX;
            document.addEventListener('touchmove', handleTouchMove);
            document.addEventListener('touchend', handleTouchEnd);
        };

        const handleTouchMove = (event: TouchEvent): void => {
            handleDragging(event.touches[0].clientX);
        };

        const handleTouchEnd = (): void => {
            isDragging[galleryID] = false;
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
            handleDragEnd();
        };

        scrollbar?.addEventListener('mousedown', handleMouseDown);
        scrollbar?.addEventListener('touchstart', handleTouchStart);

        return () => {
            scrollbar?.removeEventListener('mousedown', handleMouseDown);
            scrollbar?.removeEventListener('touchstart', handleTouchStart);
        };
    }, [
        slidesRef,
        gallerySlides,
        index,
        slidesPerPage,
        scrollbarPosition,
        maxScrollbarPosition,
        disableScrollSnap,
        handleScrollbarPosition,
        handleScrollbarPositionByGalleryPosition,
        triggerChange,
        setScrollbarSize,
        scrollbar,
        theme.direction,
        galleryID
    ]);

    const handleResize = React.useCallback(() => {
        setScrollbarSize();
    }, [setScrollbarSize]);

    React.useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    const createKeyHandler = (
        triggerChange: (newIndex: number) => void,
        index: number
    ) => (event: React.KeyboardEvent) => {
        let newIndex: number | null;
        switch (event.key) {
            case 'ArrowLeft':
                newIndex = Math.max(
                    index + (theme.direction ?? 'ltr') === 'rtl' ? 1 : -1,
                    0
                );
                break;
            case 'ArrowRight':
                newIndex = Math.max(
                    index + (theme.direction ?? 'ltr') === 'rtl' ? -1 : 1,
                    0
                );
                break;
            case 'Home':
                newIndex = 0;
                break;
            case 'End':
                newIndex = length - 1;
                break;
            default:
                newIndex = null;
        }

        if (newIndex !== null) {
            event.stopPropagation();
            event.preventDefault();
            triggerChange(newIndex);
        }
    };

    if (length <= slidesPerPage) {
        return null;
    }

    const handleScrollAreaClick = (event: React.MouseEvent) => {
        const scrollbarAreaOffset =
            scrollbarArea?.getBoundingClientRect().left || 0;
        const deltaX = event.clientX - scrollbarAreaOffset;
        const newScrollbarPosition =
            theme.direction === 'rtl' ? scrollbarAreaWidth - deltaX : deltaX;
        const positionPercantage = getActuallScrollPercentage(
            newScrollbarPosition,
            maxScrollbarPosition
        );
        const slideToItem = ((length - 1) / 100) * positionPercantage;
        triggerChange(slideToItem);
    };

    const handleScrollbarClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const ariaScrolledPercentage = Math.round(
        getActuallScrollPercentage(scrollbarPosition, maxScrollbarPosition)
    );

    return (
        <StyledScrollbarArea
            ref={scrollbarAreaRef}
            onClick={e => handleScrollAreaClick(e)}
        >
            <StyledScrollbar
                ref={scrollbarRef}
                x={scrollbarPosition}
                isDragging={isDragging[galleryID]}
                slides={length}
                tabIndex={0}
                onKeyDown={createKeyHandler(triggerChange, index)}
                onClick={e => handleScrollbarClick(e)}
                role="scrollbar"
                aria-controls={galleryID}
                aria-labelledby={galleryID}
                aria-orientation="horizontal"
                aria-valuenow={ariaScrolledPercentage}
                aria-valuemin={0}
                aria-valuemax={100}
            />
        </StyledScrollbarArea>
    );
};
