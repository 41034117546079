import * as React from 'react';
import styled from 'styled-components';

import {BaseLinkProps} from '../../teaser-element/types';
import {NavBarLink} from './bar-link';

export interface StyledBarLinkProps {
    readonly isActive?: boolean;
}

const StyledListItem = styled.li`
    flex-shrink: 0;
    max-width: 100%;
`;
StyledListItem.displayName = 'StyledListItem';

export interface InPageNavBarLinkProps extends BaseLinkProps {
    readonly children: React.ReactNode;
    readonly isActive?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const InPageNavBarLink = (props: InPageNavBarLinkProps): JSX.Element => {
    return (
        <StyledListItem>
            <NavBarLink {...props} />
        </StyledListItem>
    );
};
