import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import * as React from 'react';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';

export interface FeatureAppContentExtraProps {
    name: string;
}

export function InternalFeatureAppContent(props: FeatureAppContentExtraProps) {
    return (
        <AuthoringWrapper
            title={'Feature App Content'}
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            <span>
                Feature app content of <b>{props.name}</b>
            </span>
        </AuthoringWrapper>
    );
}

export const FeatureAppContent = MapTo<FeatureAppContentExtraProps>(
    'vwa-ngw18/components/editorial/items/featureAppContentItem',
    InternalFeatureAppContent
);
