import {SearchModel, SpaAsyncConfig} from '../../../generated/core';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {Logger} from '../logger/Logger';
import {
    createSearchQuery,
    createSuggestQuery,
    splitToWords
} from './createSearchQuery';

export interface SearchDataFields {
    readonly metatag_og_title: string[];
    readonly id: string[];
    readonly url: string[];
    readonly title: string;
    readonly metatag_url_level1: string[];
    readonly h1: string[];
    readonly h2: string[];
    readonly h3: string[];
    readonly metatag_vw_breadcrumb?: string[];
    readonly metatag_description: string[];
    readonly metatag_twitter_image?: string[];
    readonly description?: string | string[];
    readonly keywords?: string | string[];
    readonly strippedcontent?: string | string[];
    readonly metatag_vw_search_image_scene7file?: string;
    readonly _score?: string;
}

type SearchFieldNames = keyof SearchDataFields;
interface SearchFieldBoost {
    name: SearchFieldNames;
    boost: number;
}
const searchFields: SearchFieldBoost[] = [
    {name: 'metatag_url_level1', boost: 3},
    {name: 'title', boost: 2},
    {name: 'h1', boost: 1},
    {name: 'h2', boost: 1},
    {name: 'metatag_description', boost: 1},
    {name: 'strippedcontent', boost: 1}
];

const suggestFields: SearchFieldBoost[] = [
    {name: 'title', boost: 2},
    {name: 'h1', boost: 1},
    {name: 'h2', boost: 1},
    {name: 'h3', boost: 1},
    {name: 'metatag_description', boost: 1}
];

export interface SearchDataHit {
    readonly id: string;
    readonly fields: SearchDataFields;
}

interface SearchResultHits {
    readonly found: number;
    readonly start: number;
    readonly hit: SearchDataHit[];
}

interface SearchResponseStatus {
    readonly rid: string;
    readonly 'time-ms': number;
}

export interface SearchApiResponse {
    readonly status: SearchResponseStatus;
    readonly hits: SearchResultHits;
}

@singleton('SearchStore')
export class SearchStore {
    @inject() private spaAsyncConfig!: SpaAsyncConfig;

    @inject() private searchModel!: SearchModel;

    @inject()
    private logger!: Logger;

    public get searchConfig(): SearchModel {
        return this.searchModel;
    }

    public get searchEnabled(): boolean {
        return this.searchModel && this.searchModel.enabled;
    }

    public async doSearch(searchString: string): Promise<SearchApiResponse> {
        const searchURL = this.createSearchURL(
            encodeURIComponent(
                createSearchQuery(searchString, {
                    distance: 10,
                    fields: searchFields
                })
            ),
            true,
            [
                '_score',
                'id',
                'url',
                'metatag_vw_breadcrumb',
                'metatag_og_title',
                'metatag_description',
                'metatag_twitter_image',
                'metatag_vw_search_image_scene7file'
            ]
        );

        return this.fetch(searchURL, this.sanitizeResult.bind(this));
    }

    public async doSuggest(searchString: string): Promise<string[]> {
        this.logger.search.debug('execute suggest search', searchString);
        const searchURL = this.createSearchURL(
            encodeURIComponent(
                createSuggestQuery(searchString, {
                    fields: suggestFields
                })
            ),
            true
        );

        // get search result for last word from cloudsearch
        const searchResult: SearchApiResponse = await this.fetch(
            searchURL,
            this.sanitizeResult.bind(this)
        );

        // collect texts of result from defined fields
        const searchResultTexts: string[] = [];
        suggestFields.forEach(field => {
            searchResult.hits.hit.forEach(hit => {
                const value = (hit.fields as any)[field.name];
                if (Array.isArray(value)) {
                    searchResultTexts.push(...value);
                } else {
                    searchResultTexts.push(value);
                }
            });
        });

        // find last word matches in title of search result and create suggest string from that
        const words = splitToWords(searchString);
        return this.findMatchesForWord(
            words.last,
            searchResultTexts
        ).map(word => (words.previous ? words.previous + ' ' + word : word));
    }

    private findMatchesForWord(search: string, resultSentences: string[]) {
        let result: Map<string, number> = new Map<string, number>();
        resultSentences
            .filter(sentences => sentences !== undefined)
            .forEach(sentence => {
                sentence
                    .split(/[ \u00A0]/)
                    .filter(word =>
                        word.toLowerCase().startsWith(search.toLowerCase())
                    )
                    .map(match => SearchStore.removeUnwantedChars(match))
                    .forEach(match => {
                        if (result.has(match)) {
                            const count = result.get(match) || 0;
                            result.set(match, count + 1);
                        } else {
                            result.set(match, 1);
                        }
                    });
            });

        // sort by occurrence
        return Array.from(result.keys()).sort(
            (a, b) => (result.get(b) || 0) - (result.get(a) || 0)
        );
    }

    private static removeUnwantedChars(word: string): string {
        const regex = /\w.*\w/;
        const match = word.match(regex);
        if (match != null) {
            return match[0];
        }
        return '';
    }

    private async fetch(
        searchURL: string,
        verify: (response: any) => SearchApiResponse | undefined
    ): Promise<any> {
        try {
            const {oneApiEndpointUrl} = this.searchModel;
            const {apiKey} = this.spaAsyncConfig.oneApiConfiguration;
            const header =
                oneApiEndpointUrl && apiKey ? {'x-api-key': apiKey} : undefined;

            return await fetch(searchURL, {
                method: 'get',
                headers: header
            }).then(async response => {
                const json = await response.json();
                const sanitizedResponse = verify(json);
                if (!sanitizedResponse) {
                    throw new Error(
                        `Invalid search engine response: ${JSON.stringify(
                            json
                        )}`
                    );
                }
                return sanitizedResponse;
            });
        } catch (e) {
            throw new Error(`The request for ${searchURL} failed. ${e}`);
        }
    }

    private sanitizeResult(response: any): SearchApiResponse | undefined {
        // Check if the whole response has valid format
        const hasValidResponse =
            response.status !== undefined &&
            'time-ms' in response.status &&
            'rid' in response.status &&
            response.hits !== undefined &&
            response.hits.start !== undefined &&
            response.hits.hit !== undefined &&
            typeof response.hits.found === 'number' &&
            typeof response.hits.start === 'number' &&
            Array.isArray(response.hits.hit);

        if (!hasValidResponse) {
            return undefined; // Invalid response
        }

        // Remove invalid records from json data
        const fieldsValid = (fields: any): boolean => {
            const validTitle =
                fields.metatag_og_title &&
                Array.isArray(fields.metatag_og_title) &&
                fields.metatag_og_title.length > 0 &&
                typeof fields.metatag_og_title[0] === 'string';
            const validId = // id field is used as a link
                fields.id &&
                Array.isArray(fields.id) &&
                fields.id.length > 0 &&
                typeof fields.id[0] === 'string';

            return validTitle && validId;
        };

        const itemIsValid = (item: SearchDataHit) =>
            item.id &&
            typeof item.id === 'string' &&
            item.fields &&
            fieldsValid(item.fields);

        // Filter only valid records
        const filteredHit: SearchDataHit[] = response.hits.hit.filter(
            (item: SearchDataHit) => itemIsValid(item)
        );

        // Find out if we actually removed some records
        if (filteredHit.length < response.hits.hit.length) {
            this.logger.search.warn(
                'There were some search results in invalid format which have been removed'
            );
        }

        const sortedHit = this.sortSearchResults(filteredHit);

        return {
            status: response.status,
            hits: {
                found: sortedHit.length,
                start: response.hits.start,
                hit: sortedHit
            }
        }; // Valid response although some records could be removed
    }

    private sortSearchResults(filteredHit: SearchDataHit[]) {
        return filteredHit
            .map(hit => {
                const score = parseFloat(hit.fields._score || '0');
                const url = hit.fields.id[0];

                const weight = score * getWeightFactor(url.split('/').length);

                return {...hit, weight};
            })
            .sort((hit1, hit2) => {
                return hit2.weight - hit1.weight;
            });
    }

    private createSearchURL(
        searchQuery: string,
        isStructured: boolean = false,
        returnFields?: SearchFieldNames[]
    ): string {
        const {
            endpointUrl,
            searchHost,
            searchLanguage,
            oneApiEndpointUrl
        } = this.searchModel;
        const url = oneApiEndpointUrl ? oneApiEndpointUrl : endpointUrl;

        const typeString = isStructured
            ? 'q.parser=' + encodeURIComponent('structured') + '&'
            : '';
        const filterQuery = encodeURIComponent(
            `(and host:'${searchHost}' metatag_language:'${searchLanguage}')`
        );

        const returnValues = !returnFields
            ? ''
            : `&return=${returnFields.join(',')}`;

        return `${url}search?q=${searchQuery}&${typeString}fq=${filterQuery}&size=100${returnValues}`;
    }
}

function getWeightFactor(length: number) {
    const weight = 2;
    if (!weight) {
        return 1;
    }
    if (length < 3) {
        return 1;
    }
    return weight / (weight + length - 2);
}
