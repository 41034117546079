import {ModelManager, TransferModel} from '@adobe/cq-spa-page-model-manager';
import {observable} from 'mobx';
import {singleton} from '../../infrastructure/di/annotations';

const PERSONALIZED_CONTENT_PATH = '/personalized-content';
const PERSONALIZED_CONTENT_BEGIN = '/__personalized__';
export const CUSTOM_CONTENT_PATH = '/custom-content';
const CUSTOM_CONTENT_BEGIN = '/__custom__';
const GLOBAL_CONTENT = '/global-content';

export function getContent(
    relPath: string,
    model: TransferModel
): TransferModel {
    const itemPaths = relPath.split('/');
    let data = model;
    itemPaths.forEach((itemPath: string) => {
        if (!data || !data[':items']) {
            return;
        }
        if (itemPath.length > 0) {
            data = data[':items'][itemPath];
        }
    });

    return data;
}

export interface ExtendedModelStore {
    getData(path: string, immutable?: boolean): TransferModel | undefined;

    insertPersonalizedData(
        absPath: string,
        modelData: TransferModel,
        type: 'global' | 'mbox'
    ): string;

    insertCustomContent(
        id: string,
        absPath: string,
        modelData: TransferModel
    ): void;

    getCustomContentPath(id: string, absPath: string): string;

    getCustomContent(path: string): TransferModel | undefined;

    insertGlobalContent(key: string, modelData: TransferModel): string;
}

@singleton('ModelStore')
export class ModelStore implements ExtendedModelStore {
    private personalizedContent: {[path: string]: TransferModel} = {};
    private customContent: Map<string, TransferModel> = observable.map();
    private globalContent: TransferModel = {
        ':items': {},
        ':itemsOrder': [],
        ':type': ''
    };

    public getData(path: string, immutable?: boolean): TransferModel {
        if (path.startsWith(GLOBAL_CONTENT)) {
            const subPath = path.substring(GLOBAL_CONTENT.length);

            return getContent(subPath, this.globalContent);
        } else if (path.startsWith(PERSONALIZED_CONTENT_PATH)) {
            const paths = path.split(PERSONALIZED_CONTENT_BEGIN);
            if (paths.length > 1) {
                const data = this.personalizedContent[paths[0]];
                if (data) {
                    return getContent(paths[1], data);
                }
            } else {
                console.error('cannot find personalized content');
            }
        } else if (path.startsWith(CUSTOM_CONTENT_PATH)) {
            const customContent = this.getCustomContent(path);
            if (customContent) {
                return customContent;
            }
        }

        let model = ModelManager.modelStore.getData(path, immutable || false);
        if (model && model[':type'] === 'virtualRootNode') {
            // fix: for current path is '/', the virtual root node will be returned.
            // in spa.tsx the resource is set to the current path in children node
            model = model[':children'][path];
        }

        return model;
    }

    public getCustomContent(path: string): TransferModel | undefined {
        const paths = path.split(CUSTOM_CONTENT_BEGIN);
        if (paths.length > 1) {
            const data = this.customContent.get(paths[0]);
            if (data) {
                return getContent(paths[1], data);
            }
        } else {
            console.error('cannot find custom content');
        }
        return undefined;
    }

    public insertPersonalizedData(
        absPath: string,
        modelData: TransferModel,
        type: 'global' | 'mbox'
    ): string {
        const personalizedPath = `${PERSONALIZED_CONTENT_PATH}/${type}${absPath}`;
        const root = modelData[':items']['root'];
        this.personalizedContent[personalizedPath] = root;

        return personalizedPath + PERSONALIZED_CONTENT_BEGIN;
    }

    public insertCustomContent(
        id: string,
        absPath: string,
        modelData: TransferModel
    ): void {
        const newAbsPath = getCustomContentKey(absPath, id);
        this.customContent.set(newAbsPath, modelData);
    }

    public getCustomContentPath(id: string, absPath: string): string {
        // '/custom-contentpager_/content/path/magazineteasergridsection/1'

        const newAbsPath = getCustomContentKey(absPath, id);
        return appendCustomContentMarker(newAbsPath);
    }

    public insertGlobalContent(key: string, modelData: TransferModel): string {
        key = key.replace(/^\/+/, ''); // remove leading slash
        const itemPaths = key.split('/');
        const l = itemPaths.length;
        let data = this.globalContent;

        for (let i = 0; i < l; i++) {
            const path = itemPaths[i];
            if (i < l - 1) {
                data[':items'][path] = data[':items'][path] || {
                    ':items': {},
                    ':itemsOrder': [],
                    ':type': ''
                };
                data = data[':items'][path];
            } else {
                data[':items'][path] = modelData;
            }
        }

        return `${GLOBAL_CONTENT}/${key}`;
    }
}
function appendCustomContentMarker(newAbsPath: string): string {
    return `${newAbsPath}${CUSTOM_CONTENT_BEGIN}`;
}

function getCustomContentKey(absPath: string, id: string) {
    return `${CUSTOM_CONTENT_PATH}${absPath}/${id}`;
}
