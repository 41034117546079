import * as React from 'react';
import {styled, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import {DisplayType, XfInfoLayerMainModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {DataInfoLayer} from '../../../d6/components/technical-data/DataInfoLayer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {RichtextFullElement} from '../../editorial/elements/RichtextElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {useGlobalDisclaimerStore} from '../../../context';
import {ValueFactory} from '../../../utils/contextUtils';
import {DisclaimerStore} from '../../../context/disclaimer/DisclaimerStore';
import {DisclaimerStoreContext} from '../../../context/disclaimer/DisclaimerStoreContext';
import {LayerCloseButtonV2} from '../../../components/LayerCloseButton';

const DISCLAIMER_TYPES: DisplayType[] = ['T3_SECTION_BASED', 'T4_ITEM_BASED'];

const StyledAuthorView = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0100};
    padding: 0 ${props => props.theme.size.grid002};
`;

interface LayerElements {
    copyElement: JSX.Element;
    headingElement: JSX.Element;
}

function PublishView(props: XfInfoLayerProps & LayerElements): JSX.Element {
    const {copyElement, headingElement, btnCloseLabel, close} = props;

    return (
        <GeneralDisclaimerProvider displayTypes={DISCLAIMER_TYPES}>
            <DataInfoLayer
                heading={headingElement}
                copyElement={copyElement}
                closeButton={
                    <LayerCloseButtonV2
                        key="close"
                        onClick={close}
                        btnCloseLabel={btnCloseLabel}
                    />
                }
                disclaimers={
                    <BellowItemDisclaimerContainer
                        displayTypes={DISCLAIMER_TYPES}
                    />
                }
            />
        </GeneralDisclaimerProvider>
    );
}

function AuthoringView(props: LayerElements): JSX.Element {
    const {copyElement, headingElement} = props;

    return (
        <AuthoringWrapper title="Info Layer Main">
            <GeneralDisclaimerProvider displayTypes={DISCLAIMER_TYPES}>
                <StyledAuthorView>
                    {headingElement}
                    {copyElement}
                    <BellowItemDisclaimerContainer
                        displayTypes={DISCLAIMER_TYPES}
                    />
                </StyledAuthorView>
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
}

interface XfInfoLayerExtraProps {
    close: VoidFunction;
}

interface XfInfoLayerProps
    extends XfInfoLayerMainModel,
        XfInfoLayerExtraProps {}

function InternalXfInfoLayerMain(props: XfInfoLayerProps): JSX.Element {
    const disclaimerStore = useGlobalDisclaimerStore();
    const store = disclaimerStore.getLayeredStore(props.cqPath);
    const value = React.useMemo(() => {
        const disclaimerFactory = new ValueFactory<DisclaimerStore>();
        return disclaimerFactory.create(store);
    }, [store]);

    const copyElement = (
        <RichtextFullElement
            path="copy"
            appearance={TokenTextAppearance.copy200}
            hideItemDisclaimers
        />
    );

    const headingElement = (
        <HeadingElement
            path="heading"
            style="H3"
            order="H3"
            hideItemDisclaimers
        />
    );

    const Layer = C.isInEditor() ? AuthoringView : PublishView;

    return (
        <DisclaimerStoreContext.Provider value={value}>
            <Layer
                {...props}
                headingElement={headingElement}
                copyElement={copyElement}
            />
        </DisclaimerStoreContext.Provider>
    );
}

export const XfInfoLayerMain = MapTo<XfInfoLayerExtraProps>(
    'vwa-ngw18/components/structure/xfInfoLayerMain',
    InternalXfInfoLayerMain
);
