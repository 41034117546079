import * as React from 'react';
import styled from 'styled-components';

import {Text, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import {AnswersStage} from '../../../d6/components/answers-stage';

import {AnswersStageSectionModel} from '../../../../generated/core';
import {AlertBox} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {WrappingOverlaySectionDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {ArticleMetaInfoElement} from '../elements/ArticleMetaInfoElement';
import {HeadingElement} from '../elements/HeadingElement';
import {ImageIllustrationElement} from '../elements/ImageIllustrationElementComponent';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {ReadingTimeBox} from '../../../d6/components/reading-timebox';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const StyledImageWrapper = styled.div<{isEditMode: boolean}>`
    display: flex;
    flex: 1;

    & > * {
        flex: 1;
    }

    img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const StyledStageWrapper = styled.div`
    position: relative;
`;

const CategoryElement: React.FunctionComponent<{category?: string}> = props =>
    props.category ? (
        <CyAttributeAppender name="categoryElement">
            <Text appearance={TokenTextAppearance.copy100} bold>
                {props.category}
            </Text>
        </CyAttributeAppender>
    ) : null;

const ReadingTimeElement: React.FunctionComponent<{
    readingTime: number;
    minWord: string;
    readWord: string;
}> = props => {
    const readElement = (
        <>
            <CyAttributeAppender name="answersStageReadingTime">
                <strong>
                    {props.readingTime} {props.minWord}
                </strong>
            </CyAttributeAppender>{' '}
            {props.readWord}
        </>
    );

    // empty element is needed for correct height calculations of reading time box
    const emptyReadElement = <>&zwnj;</>;

    return (
        <ReadingTimeBox
            readingTime={
                props.readingTime >= 0 ? readElement : emptyReadElement
            }
        />
    );
};

const ReadingTimeAlertBox: React.FunctionComponent = () => (
    <AlertBox>
        <div>
            {
                'Reading Time must be set in page properties in the content meta tab'
            }
        </div>
    </AlertBox>
);

/**
 * S109 Answers Stage Section
 */
const InternalAnswersStageSection: React.FunctionComponent<AnswersStageSectionModel> = (
    props: AnswersStageSectionModel
) => {
    const isEditMode = C.isInEditor();
    const {
        anchorId,
        mboxId,
        contentMetaData,
        contentLabels,
        contentName,
        hasMediaForPublishMode,
        tagTypeBroken,
        contentId: sectionId
    } = props;

    const heading = <HeadingElement path="heading" order="H1" style="H0" />;

    const copyItem = <CopyItem path="copy" />;

    const imgIllustration = (
        <StyledImageWrapper isEditMode={isEditMode}>
            <MediaContext.Provider
                value={{sizes: '(min-width: 768px) 50vw, 100vw'}}
            >
                <ImageIllustrationElement path="imageIllustration" />
            </MediaContext.Provider>
        </StyledImageWrapper>
    );

    const metaInfo = (
        <CyAttributeAppender name="answersStageMetaInfo">
            <ArticleMetaInfoElement {...contentMetaData} />
        </CyAttributeAppender>
    );

    const answersStage = (
        <AnswersStage
            category={<CategoryElement category={contentMetaData.category} />}
            heading={heading}
            metaInfo={metaInfo}
            copy={copyItem}
            readingTimeBox={
                C.isInEditor() && contentMetaData.readingTime < 0 ? (
                    <ReadingTimeAlertBox />
                ) : (
                    <ReadingTimeElement
                        readingTime={contentMetaData.readingTime}
                        minWord={contentMetaData.minutesLabel}
                        readWord={contentMetaData.readLabel}
                    />
                )
            }
            media={
                isEditMode || hasMediaForPublishMode
                    ? imgIllustration
                    : undefined
            }
            fixedHeight={isEditMode}
        />
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S109 Answers Stage"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    {C.isInEditor() ? (
                        <>
                            {answersStage}
                            <SectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        </>
                    ) : (
                        <StyledStageWrapper>
                            <WrappingOverlaySectionDisclaimers
                                displayTypes={['T3_SECTION_BASED']}
                                color="light"
                                sticky
                            >
                                {answersStage}
                            </WrappingOverlaySectionDisclaimers>
                        </StyledStageWrapper>
                    )}
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const ANSWERS_STAGE_TYPE =
    'vwa-ngw18/components/editorial/sections/answersStageSection';
export const AnswersStageSection = MapTo(
    ANSWERS_STAGE_TYPE,
    InternalAnswersStageSection
);
