import {observable} from 'mobx';
import * as React from 'react';
import {Disclaimer, DisplayType} from '../../../../generated/core';
import {ConfigurableConsole} from '../../logger/ConfigurableConsole';
import {FullTextCallback, FullTextDisclaimer} from '../DisclaimerContextApi';
import {DisclaimerStore, RegisteredDisclaimer} from '../DisclaimerStore';
import {GeneralDisclaimerContextData} from './GeneralDisclaimerContextData';
import {MutableFullTextDisclaimer} from './LazyFullTextDisclaimer';

export class Scope {
    @observable
    private changeCount = 0;
    private _disclaimers: RegisteredDisclaimer[] = [];

    constructor(private readonly logger: ConfigurableConsole) {}
    private getInternalDisclaimers() {
        // WARNING: we must also read this.changeCount to ensure events being fired asynchronuously.
        return {changeCount: this.changeCount, disclaimers: this._disclaimers};
    }
    get disclaimers() {
        // the disclaimers must be available synchronuously for ssr and during hydration. Events must be asynchronuously otherwise state changes during rendering will happen
        // WARNING: DONT RETURN _disclaimers directly
        return this.getInternalDisclaimers().disclaimers;
    }
    count: {[text: string]: number} = {};
    add(disclaimer: RegisteredDisclaimer) {
        const existingCount = this.count[disclaimer.text];
        if (existingCount) {
            this.count[disclaimer.text] = existingCount + 1;
        } else {
            this._disclaimers.push(disclaimer);
            this.count[disclaimer.text] = 1;
        }
        // need this asyncronicity to not change state while rendering
        setTimeout(() => {
            this.changeCount++;
        });
        this.logger.debug(
            'add disclaimer',
            disclaimer.reference,
            this.count[disclaimer.text]
        );
    }
    remove(disclaimer: RegisteredDisclaimer) {
        const existingCount = this.count[disclaimer.text];
        if (existingCount) {
            this.count[disclaimer.text] = existingCount - 1;
            if (existingCount - 1 === 0) {
                delete this.count[disclaimer.text];

                const index = this._disclaimers.findIndex(
                    r => r.reference === disclaimer.reference
                );
                this._disclaimers.splice(index, 1);
            }
            setTimeout(() => {
                this.changeCount++;
            });
        }
        this.logger.debug(
            'remove disclaimer',
            disclaimer.reference,
            this.count[disclaimer.text]
        );
    }
}

export class GeneralDisclaimerContextImpl
    implements GeneralDisclaimerContextData {
    private pageScope = new Scope(this.logger);
    private sectionScope = new Scope(this.logger);
    private itemScope = new Scope(this.logger);
    public constructor(
        private readonly path: string,
        private readonly logger: ConfigurableConsole,
        private readonly displayTypes: DisplayType[],
        public readonly store: DisclaimerStore,
        private readonly parent?: GeneralDisclaimerContextData,
        private readonly disableScroll?: boolean,
        private readonly keepWhenUnregistered?: boolean
    ) {
        this.logger.debug(
            'create disclaimer context %s parent:%s for %s',
            this.path,
            !!this.parent,
            this.displayTypes.join(', ')
        );
    }
    private readonly fullTextDisclaimers: Map<
        string,
        MutableFullTextDisclaimer
    > = new Map();
    public getFullTextDisclaimer(
        reference: string
    ): FullTextDisclaimer | undefined {
        if (this.parent && !this.hasDisclaimer(reference)) {
            return this.parent.getFullTextDisclaimer(reference);
        }
        const ftd = this.fullTextDisclaimers.get(reference);
        if (ftd) {
            return ftd;
        }
        const mutableFtd = new MutableFullTextDisclaimer();
        this.fullTextDisclaimers.set(reference, mutableFtd);
        return mutableFtd;
    }

    public hasDisclaimer(reference: string) {
        return Boolean(
            this.displayTypes.find(dt =>
                this.getScope(dt)?.disclaimers.find(
                    d => d.reference === reference
                )
            )
        );
    }

    public handleAddedRef(
        reference: string,
        disclaimerType: DisplayType,
        ref: React.RefObject<HTMLDivElement>,
        callback: FullTextCallback
    ): void {
        const registeredDisclaimer = this.getDisclaimer(
            reference,
            disclaimerType
        );
        if (registeredDisclaimer) {
            const existingFtd = this.fullTextDisclaimers.get(reference);
            if (existingFtd) {
                existingFtd.init({callback, ref});
            } else {
                const ftd = new MutableFullTextDisclaimer();
                this.fullTextDisclaimers.set(reference, ftd);
                ftd.init({callback, ref});
            }
        }
    }

    public getAllDisclaimers(
        displayTypes: DisplayType[]
    ): RegisteredDisclaimer[] {
        return displayTypes.reduce((prev, curr) => {
            return prev.concat(this.getDisclaimers(curr));
        }, [] as RegisteredDisclaimer[]);
    }
    public getDisclaimers(displayType: DisplayType): RegisteredDisclaimer[] {
        const scope = this.getScope(displayType);
        if (scope) {
            return scope.disclaimers;
        }

        return this.parent?.getDisclaimers(displayType) || [];
    }

    public create(
        path: string,
        logger: ConfigurableConsole,
        displayTypes: DisplayType[] = ['T3_SECTION_BASED'],
        allowNested = false,
        disableScroll?: boolean,
        keepWhenUnregistered?: boolean
    ): GeneralDisclaimerContextData {
        if (this.displayTypes && !allowNested) {
            const overlap = this.displayTypes.filter(
                d => displayTypes.indexOf(d) >= 0
            );
            if (overlap.length > 0) {
                logger.warn(
                    'there is a display type overlap %s > %s : %s',
                    this.path,
                    path,
                    overlap.join(', ')
                );
            }
        }
        return new GeneralDisclaimerContextImpl(
            path,
            logger,
            displayTypes,
            this.store,
            this,
            disableScroll,
            keepWhenUnregistered
        );
    }
    public addDisclaimer(
        disclaimer: Disclaimer
    ): RegisteredDisclaimer | undefined {
        if (!this.store) {
            throw new Error('no store defined');
        }
        const wrongDisplayType =
            this.displayTypes.indexOf(disclaimer.displayType) < 0 &&
            disclaimer.displayType !== 'T6_OPENABLE';
        if (this.parent && wrongDisplayType) {
            this.logger.debug(
                'delegate addition to parent %s to %s',
                disclaimer.text,
                this.path
            );
            if (!this.parent) {
                throw new Error('no context to hold disclaimer');
            }
            return this.parent.addDisclaimer(disclaimer);
        } else if (wrongDisplayType) {
            return undefined;
        }

        const registeredDisclaimer = this.store.add(
            disclaimer,
            undefined,
            this.disableScroll,
            this.keepWhenUnregistered
        );

        this.logger.debug('add %s to %s', disclaimer.text, this.path);
        const scope = this.getScope(disclaimer.displayType);
        if (scope) {
            scope.add(registeredDisclaimer);
            const cleanup = registeredDisclaimer.subscribe(() => {
                this.removeDisclaimer(registeredDisclaimer);
                cleanup();
            });
        } else {
            this.logger.warn(
                'no scope for disclaimer %s of type %s',
                disclaimer.text,
                disclaimer.displayType
            );
        }

        return registeredDisclaimer;
    }

    private getDisclaimer(reference: string, disclaimerType: DisplayType) {
        return this.getDisclaimers(disclaimerType).find(
            r => r.reference === reference
        );
    }
    private getScope(displayType: DisplayType): Scope | undefined {
        if (!this.displayTypes.includes(displayType)) {
            return undefined;
        }
        switch (displayType) {
            case 'T3_SECTION_BASED':
                return this.sectionScope;
            case 'T4_ITEM_BASED':
                return this.itemScope;
            case 'T2_PAGE_BASED':
                return this.pageScope;
            case 'T6_OPENABLE':
                return this.pageScope;
        }
        return undefined;
    }
    private removeDisclaimer(disclaimer: RegisteredDisclaimer) {
        if (!this.store) {
            throw new Error('no store defined');
        }
        const scope = this.getScope(disclaimer.displayType);
        if (scope) {
            scope.remove(disclaimer);
        }
        this.logger.debug(
            'remove disclaimer %s from %s - total %s',
            disclaimer.text,
            this.path,
            scope?.disclaimers.length
        );
    }
}
