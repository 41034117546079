import * as React from 'react';

interface StyledPropsExtractorProps {
    children(props: any): JSX.Element;
}

export const StyledPropsExtractor: React.FunctionComponent<StyledPropsExtractorProps> = ({
    children,
    ...other
}) => children(other);
StyledPropsExtractor.displayName = 'StyledPropsExtractor';
