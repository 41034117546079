import * as React from 'react';
import {Direction, getEndDirection} from '../helpers';
import {styledCompatRef} from '../helpers/styled-compat-ref';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';

const StyledPaginationWrapper = styled.div`
	direction: ltr;

	button:${props =>
        props.theme.direction !== Direction.RTL
            ? 'last-child'
            : 'first-child'} {
		@media (min-width: ${Breakpoints.b960}px) {
			padding-${props => getEndDirection(props.theme.direction)}: 0;
		}
	}
`;

const StyledButton = styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`;

const noop = () => undefined;

interface PaginationProps {
    readonly activeItemIndex: number;
    readonly itemCount: number;
    onSlideChanged(index: number): void;
    onPaginationClick?(delta: number): void;
}

export class GalleryPagination extends React.Component<PaginationProps> {
    private readonly paginationRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    private readonly handleSlideChange = (delta: number): void => {
        const {
            activeItemIndex,
            itemCount,
            onSlideChanged,
            onPaginationClick = noop
        } = this.props;
        const newIndex = (activeItemIndex + itemCount + delta) % itemCount;
        onSlideChanged(newIndex);
        onPaginationClick(delta);
    };

    public render(): JSX.Element {
        const {activeItemIndex, itemCount} = this.props;

        // not controllable with keyboard or screenreader because same
        // controls exist on the preview row, so this is redundant and
        // adds annoying extra tab stops.

        return (
            <StyledPaginationWrapper
                aria-hidden
                ref={styledCompatRef(this.paginationRef)}
                tabIndex={-1}
            >
                <CyAttributeAppender name="previousPage">
                    <StyledButton
                        onClick={() => this.handleSlideChange(-1)}
                        tabIndex={-1}
                    >
                        <ChevronLeft variant="small" />
                    </StyledButton>
                </CyAttributeAppender>
                <span>{`${activeItemIndex + 1} / ${itemCount}`}</span>

                <CyAttributeAppender name="nextPage">
                    <StyledButton
                        onClick={() => this.handleSlideChange(1)}
                        tabIndex={-1}
                    >
                        <ChevronRight variant="small" />
                    </StyledButton>
                </CyAttributeAppender>
            </StyledPaginationWrapper>
        );
    }
}
