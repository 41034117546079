import {
    StyledBadge,
    StyledBadgeText,
    StyledDecorativeIconWrapper,
    StyledIconWrapper,
    StyledInnerIconWrapper,
    StyledOverflowWrapper,
    StyledTextWrapper
} from './styles';
import {
    Text,
    TokenTextAppearance,
    TokenTextColor
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {NBAContentProps} from './types';

export const NextBestActionButtonContent = (props: NBAContentProps) => {
    const {action, subActions, hideBadge, expandContent, hideIcon} = props;

    return (
        <>
            <StyledOverflowWrapper>
                <StyledTextWrapper expanded={expandContent}>
                    <Text
                        appearance={TokenTextAppearance.label200}
                        staticSize
                        color={TokenTextColor.inherit}
                    >
                        {action.content}
                    </Text>
                </StyledTextWrapper>
            </StyledOverflowWrapper>
            {hideIcon ? (
                <></>
            ) : (
                <StyledIconWrapper>
                    <StyledDecorativeIconWrapper>
                        <StyledInnerIconWrapper>
                            {action.icon}
                        </StyledInnerIconWrapper>
                    </StyledDecorativeIconWrapper>
                </StyledIconWrapper>
            )}
            {subActions?.length && !hideBadge && (
                <StyledBadge>
                    <StyledBadgeText>
                        <bdi>{`+${subActions?.length}`}</bdi>
                    </StyledBadgeText>
                </StyledBadge>
            )}
        </>
    );
};
