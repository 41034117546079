import {
    ExternalsValidator,
    FeatureAppManager,
    FeatureServiceConsumerDependencies,
    FeatureServiceProviderDefinition,
    FeatureServiceRegistry,
    ModuleLoader,
    OnBindParams,
    ProvidedExternals,
    RequiredExternals,
    SharedFeatureService
} from '@feature-hub/core';
import semverSatisfies from 'semver/functions/satisfies';
import {ConfigurableConsole} from '../../context/logger/ConfigurableConsole';

export interface CmsFeatureHubOptions {
    readonly featureServiceDefinitions: FeatureServiceProviderDefinition<
        SharedFeatureService
    >[];
    readonly featureServiceDependencies: FeatureServiceConsumerDependencies;
    readonly optionalFeatureServiceDependencies?: FeatureServiceConsumerDependencies;
    readonly providedExternals: ProvidedExternals;
    readonly moduleLoader: ModuleLoader;
    readonly logger: ConfigurableConsole;
    readonly onBind: (params: OnBindParams) => void;
}

export const createFeaturehubWithExternalsValidator = (
    integratorId: string,
    options: CmsFeatureHubOptions
) => {
    const externalsValidator = new LoggingExternalsValidator(
        options.providedExternals,
        options.logger
    );
    const featureServiceRegistry = new FeatureServiceRegistry({
        externalsValidator,
        logger: options.logger
    });

    featureServiceRegistry.registerFeatureServices(
        options.featureServiceDefinitions || [],
        integratorId
    );

    const featureAppManager = new FeatureAppManager(featureServiceRegistry, {
        externalsValidator,
        moduleLoader: options.moduleLoader,
        logger: options.logger,
        onBind: options.onBind
    });

    const {featureServices} = featureServiceRegistry.bindFeatureServices(
        {
            dependencies: {featureServices: options.featureServiceDependencies},
            optionalDependencies: {
                featureServices: options.optionalFeatureServiceDependencies
            }
        },
        integratorId
    );

    return {featureAppManager, featureServiceRegistry, featureServices};
};

export class LoggingExternalsValidator extends ExternalsValidator {
    constructor(
        private readonly providedExternals2: ProvidedExternals,
        private readonly logger: ConfigurableConsole
    ) {
        super(providedExternals2);
    }

    validate(requiredExternals: RequiredExternals): void {
        for (const [externalName, versionRange] of Object.entries(
            requiredExternals
        )) {
            const providedVersion = this.providedExternals2[externalName];

            if (!providedVersion && this.logger) {
                const message = `The external dependency ${JSON.stringify(
                    externalName
                )} in the required version range ${JSON.stringify(
                    versionRange
                )} is not satisfied. The provided version is ${JSON.stringify(
                    providedVersion
                )}.`;

                this.logger.error(message);
            }

            if (
                !semverSatisfies(providedVersion, versionRange) &&
                this.logger
            ) {
                this.logger.error(
                    `The external dependency ${JSON.stringify(
                        externalName
                    )} in the required version range ${JSON.stringify(
                        versionRange
                    )} is not satisfied. The provided version is ${JSON.stringify(
                        providedVersion
                    )}.`
                );
            }
        }
    }
}
