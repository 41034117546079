import {observer} from 'mobx-react-lite';
import * as React from 'react';

import {Fade} from '@volkswagen-onehub/components-core';

import {InPageNavDropdown} from '../../../d6/components/inpage-navigation-dropdown';

import {useNavigationStore} from '../../../context';
import {InPageNavigationDropdownLink} from './InPageNavigationDropdownLink';

interface InPageNavigationDropdownProps {
    readonly inPageNavigationLabel: string;
    readonly dropdownButtonLabel: string;
    readonly dropdownCloseButtonLabel: string;
}

const InPageNavigationDropdown: React.FunctionComponent<InPageNavigationDropdownProps> = observer(
    function IPND(props: InPageNavigationDropdownProps) {
        const {
            inPageNavigationLabel,
            dropdownButtonLabel,
            dropdownCloseButtonLabel
        } = props;

        const navigationStore = useNavigationStore();
        const {
            isInPageNavigationOpen,
            activeInPageNavigationItemTitle,
            visibleInPageNavigationItems
        } = navigationStore;

        const openDropdown = () => {
            navigationStore.openInPageNavigation();
        };

        const hideDropdown = () => {
            navigationStore.closeInPageNavigation();
        };

        const onClick = () => {
            if (isInPageNavigationOpen) {
                hideDropdown();
            } else {
                openDropdown();
            }
        };

        const activeInPageNavigationItemTitleWithFade = (
            <Fade duration={400} key={activeInPageNavigationItemTitle}>
                {activeInPageNavigationItemTitle}
            </Fade>
        );

        const inPageNavDropdown = (
            <InPageNavDropdown
                onClick={onClick}
                isOpen={isInPageNavigationOpen}
                activeItemTitle={activeInPageNavigationItemTitleWithFade}
                dropdownButtonLabel={
                    isInPageNavigationOpen
                        ? dropdownCloseButtonLabel
                        : dropdownButtonLabel
                }
            >
                {visibleInPageNavigationItems.map(item => (
                    <InPageNavigationDropdownLink key={item.id} item={item} />
                ))}
            </InPageNavDropdown>
        );

        return (
            <nav aria-label={inPageNavigationLabel}>{inPageNavDropdown}</nav>
        );
    }
);

InPageNavigationDropdown.displayName = 'InPageNavigationDropdown';
export {InPageNavigationDropdown};
