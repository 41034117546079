import {getSessionStorageKey} from './getSessionStorageKey';
import {LoginServiceConfig} from './defineLoginService';
import {AuthServiceConfig} from './AuthServiceConfig';
import {
    Continuation,
    CustomerType,
    LoginOptions,
    LoginServiceV1
} from '@volkswagen-onehub/login-service';
import {MultipleCustomerLoginFeatureService} from '../../context/vwid/MultipleCustomerLoginFeatureService';

export class LoginServiceV1Impl<T> implements LoginServiceV1<T> {
    constructor(
        private readonly authServices: AuthServiceConfig[],
        private readonly config: LoginServiceConfig,
        private readonly consumerId: string
    ) {}
    get mainLegalEntity(): string {
        return this.service.mainLegalEntity;
    }

    async login(
        legalEntity: string | undefined,
        options?: LoginOptions<T>
    ): Promise<void> {
        if (this.multipleCustomerTypes) {
            this.pushContinuation(options);
            this.config
                .serviceFactory()
                .loginMultipleCustomerType(legalEntity, options);
        }
        if (this.authServices.length === 1) {
            this.pushContinuation(options);
            return this.config
                .serviceFactory()
                .login(this.authServices[0].customerType, legalEntity, options);
        }
    }
    private pushContinuation(options?: LoginOptions<T>) {
        const continuation = {
            custom: options ? options.continuation : undefined
        };
        sessionStorage.setItem(
            getSessionStorageKey(this.consumerId),
            JSON.stringify(continuation)
        );
    }

    private get service(): MultipleCustomerLoginFeatureService {
        return this.config.serviceFactory();
    }

    async isAuthenticated(
        legalEntity?: string
    ): Promise<CustomerType | undefined> {
        const authServiceConfig = (
            await Promise.all(
                this.authServices.map(async a => {
                    const authenticated = await a.authService
                        .register(legalEntity || this.service.mainLegalEntity)
                        .isAuthenticated();
                    return {authenticated, customerType: a.customerType};
                })
            )
        ).find(a => a.authenticated);
        return authServiceConfig ? authServiceConfig.customerType : undefined;
    }
    get multipleCustomerTypes(): boolean {
        return this.authServices.length > 1;
    }
    popContinuation(): Continuation<T> | undefined {
        const data = sessionStorage.getItem(
            getSessionStorageKey(this.consumerId)
        );
        if (data) {
            sessionStorage.removeItem(getSessionStorageKey(this.consumerId));
        }
        if (data) {
            try {
                return JSON.parse(data);
            } catch (e) {
                return undefined;
            }
        }
        return undefined;
    }
}
