import * as React from 'react';

import {Text, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import {Quote} from '../../../d6/components/quote';

import {QuoteItemModel} from '../../../../generated/core';
import {AlertBox} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {RichtextSimpleElement} from '../elements/RichtextElement';

/**
 * Quote Item
 */
function InternalQuoteItem(props: QuoteItemModel): JSX.Element | null {
    const {validationError, validationMessages} = props;

    if (validationError && !C.isInEditor()) {
        return null;
    }

    const statement = (
        <RichtextSimpleElement
            path="statement"
            appearance={TokenTextAppearance.headline500}
        />
    );

    const authorName = (
        <>
            <CyAttributeAppender name="quoteAuthorName">
                <Text bold>
                    <RichtextSimpleElement path="authorName" />
                </Text>
            </CyAttributeAppender>
            <CyAttributeAppender name="quoteAuthorTitle">
                <RichtextSimpleElement path="authorTitle" />
            </CyAttributeAppender>
        </>
    );

    const alertBox =
        validationError && C.isInEditor() ? (
            <AlertBox>
                {validationMessages.map(validationMessage => (
                    <div key={validationMessage}>{validationMessage}</div>
                ))}
            </AlertBox>
        ) : null;

    return (
        <AuthoringWrapper
            title="I102 Quote Item"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            {alertBox}
            <CyAttributeAppender name="quoteItem" addTag="div">
                <SimpleDisclaimerWrapper>
                    <Quote cite={authorName}>{statement}</Quote>
                </SimpleDisclaimerWrapper>
            </CyAttributeAppender>
        </AuthoringWrapper>
    );
}

export const QuoteItem = MapTo(
    'vwa-ngw18/components/editorial/items/quoteItem',
    InternalQuoteItem
);
