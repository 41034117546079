import * as React from 'react';
import styled, {css, keyframes} from 'styled-components';

// NOTE: load from core once fixed
// import {
//     Gallery,
//     GallerySlide,
//     GalleryContext,
//     NavigationScrollbar
// } from '@volkswagen-onehub/components-core';
import {Gallery, GallerySlide, GalleryContext} from '../gallery-core-v67.18';
import {NavigationScrollbar} from '../gallery-core-v67.18/navigation-scrollbar';

import {useMatchMediaWithDefaultQueries} from '../../../context/mediaQueries/useMatchMedia';
import {Fade} from '../../../utils/Fade';

import {ArrowButtons} from './arrow-buttons';
import {ContentFilterChips, createFilters} from './content-filter';
import {
    getAnimationDelay,
    getSlidesPerPage,
    isElementOutOfViewport,
    slidesPerPageConfig,
    SCROLL_RESET_TIMEOUT
} from './helpers';

export interface ContentSliderProps {
    readonly id: string;
    readonly ariaLabel: string;
    readonly previousSlideAriaLabel: string;
    readonly nextSlideAriaLabel: string;
    readonly slides: ContentSlide[];
    readonly allChipLabel: string;
    readonly chipAriaLabel: string;
    readonly hideChips?: boolean;
    readonly onSlideChange?: (
        filteredSlides: ContentSlide[],
        index: number
    ) => void;
    readonly onFilterChange?: (filter: string, slidesCount: number) => void;
    readonly innerRef?: React.RefObject<HTMLDivElement>;
}

export interface ContentSlide {
    readonly key: string;
    readonly category: string;
    readonly element: React.ReactElement;
    readonly ariaLabel: string;
}

const SLIDE_FADE_DURATION = 300;
const SLIDE_FADE_DISTANCE = '24px';

const noop = () => undefined;

const StyledContentSlider = styled.div`
    div[class^='StyledContainedImageWrapper'] {
        // NOTE: we need to resize content to full width of the slide, and not just for mobile, but also due server side render for all breakpoints
        width: 100%;
    }

    [class^='StyledSlide-'] {
        // NOTE: force fading edges even when not so many slides in viewport to keep same dimensions
        // NOTE2: the css var was removed in upcoming gallery versions
        // this can be later overrided on slides wrapper via padding & scroll-padding, or a new prop needs to be itroduced for gallery to keep fading edges layout after filltering and "slides <== slides per page"
        --gallery-hasFadingEdges: 1;
    }
`;

const switchOpacity = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`;

const StyledElementWrap = styled.div<{
    isHidden: boolean;
}>`
    position: relative;

    ${props =>
        props.isHidden &&
        css`
            animation: ${switchOpacity} ${SCROLL_RESET_TIMEOUT}ms step-end;
        `}
`;

const StyledEdge = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
`;

const StyledScrollbarWrap = styled.div`
    margin: 0 ${props => props.theme.size.grid002};
`;

export const ContentSlider: React.FunctionComponent<ContentSliderProps> = props => {
    const {
        id,
        ariaLabel,
        previousSlideAriaLabel,
        nextSlideAriaLabel,
        slides,
        allChipLabel,
        chipAriaLabel,
        hideChips,
        onFilterChange = noop,
        onSlideChange = noop,
        innerRef
    } = props;

    const [disableAnimation, setDisableAnimation] = React.useState(true);
    const [selectedFilter, setSelectedFilter] = React.useState(allChipLabel);
    const filteredSlides = filterSlides(slides, selectedFilter, allChipLabel);
    const updatedFilters = createFilters(slides, allChipLabel);
    const mediaQueries = useMatchMediaWithDefaultQueries();

    const clickFilter = React.useCallback(
        (filter: string) => {
            const slidesCount = filterSlides(slides, filter, allChipLabel)
                .length;
            onFilterChange(filter, slidesCount);
            setSelectedFilter(filter);
            setDisableAnimation(false);
        },
        [allChipLabel, onFilterChange, slides]
    );

    const handleSlideChange = React.useCallback(
        (index: number): void => {
            if (onSlideChange) {
                onSlideChange(
                    filteredSlides,
                    Math.max(index, Math.min(filteredSlides.length - 1, 0))
                );
            }
        },
        [onSlideChange, filteredSlides]
    );

    const fadingEdgesNavigation = (
        index: number,
        activeIndex: number,
        triggerPrevious: () => void,
        triggerNext: () => void
    ) => {
        const slidesPerPage = getSlidesPerPage(mediaQueries);
        const prev = index === activeIndex - 1;
        const next = index === activeIndex + slidesPerPage;

        return (
            <>
                {prev && <StyledEdge onClick={triggerPrevious}></StyledEdge>}
                {next && <StyledEdge onClick={triggerNext}></StyledEdge>}
            </>
        );
    };

    return (
        <StyledContentSlider ref={innerRef}>
            <Gallery
                id={id}
                ariaLabel={ariaLabel}
                fadingEdges
                slidesPerPage={slidesPerPageConfig}
                gutter="static250"
                topElements={
                    !hideChips && (
                        <ContentFilterChips
                            chipAriaLabel={chipAriaLabel}
                            filters={updatedFilters}
                            selectedFilter={selectedFilter || allChipLabel}
                            setSelectedFilter={clickFilter}
                        />
                    )
                }
                bottomElements={
                    <>
                        <StyledScrollbarWrap>
                            <NavigationScrollbar />
                        </StyledScrollbarWrap>
                        <ArrowButtons
                            buttonPreviousAriaLabel={previousSlideAriaLabel}
                            buttonNextAriaLabel={nextSlideAriaLabel}
                            hideChips={hideChips}
                        />
                    </>
                }
                handleChange={handleSlideChange}
            >
                {filteredSlides.map(
                    ({element, ariaLabel: slideAriaLabel, key}, index) => (
                        <GallerySlide
                            key={key + selectedFilter}
                            ariaLabel={slideAriaLabel}
                        >
                            <Fade
                                easing={'ease-out'}
                                directionFrom="left"
                                distance={SLIDE_FADE_DISTANCE}
                                duration={SLIDE_FADE_DURATION}
                                delay={getAnimationDelay(mediaQueries, index)}
                                disableAnimation={
                                    disableAnimation ||
                                    isElementOutOfViewport(mediaQueries, index)
                                }
                            >
                                <StyledElementWrap
                                    isHidden={isElementOutOfViewport(
                                        mediaQueries,
                                        index
                                    )}
                                >
                                    <GalleryContext.Consumer>
                                        {({
                                            index: activeIndex,
                                            triggerPrevious,
                                            triggerNext
                                        }) => (
                                            <>
                                                {element}
                                                {fadingEdgesNavigation(
                                                    index,
                                                    activeIndex,
                                                    triggerPrevious,
                                                    triggerNext
                                                )}
                                            </>
                                        )}
                                    </GalleryContext.Consumer>
                                </StyledElementWrap>
                            </Fade>
                        </GallerySlide>
                    )
                )}
            </Gallery>
        </StyledContentSlider>
    );
};
function filterSlides(
    slides: ContentSlide[],
    selectedFilter: string,
    allChipLabel: string
): ContentSlide[] {
    return slides.filter(
        ({category}) =>
            !selectedFilter ||
            selectedFilter === allChipLabel ||
            category === selectedFilter
    );
}
