import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    styled
} from '@volkswagen-onehub/components-core';

const StyledDisclaimerGroup = styled.section`
    :not(:last-child) {
        margin-bottom: ${props => props.theme.size.static200};
        @media (min-width: ${Breakpoints.b560}px) {
            margin-bottom: ${props => props.theme.size.static550};
        }
    }
`;
StyledDisclaimerGroup.displayName = 'StyledDisclaimerGroup';

const StyledFooterDisclaimerLabels = styled.h2`
    font-size: ${props => props.theme.fonts.copy[200].fontSize};
    font-weight: ${props => props.theme.text.weight.bold};
    color: ${props => props.theme.text.color.secondary};
    margin-bottom: ${props => props.theme.size.static200};
`;
StyledFooterDisclaimerLabels.displayName = 'StyledFooterDisclaimerLabels';

export interface DisclaimerGroupProps {
    label: string;
    referenceDisclaimers: React.ReactNode;
    globalDisclaimers: React.ReactNode;
}

export function DisclaimerGroup(props: DisclaimerGroupProps): JSX.Element {
    const {referenceDisclaimers, globalDisclaimers, label} = props;

    return (
        <StyledDisclaimerGroup key={label}>
            <StyledFooterDisclaimerLabels>{label}</StyledFooterDisclaimerLabels>
            <Container
                wrap={ContainerWrap.always}
                gutter={ContainerGutter.static200}
            >
                {referenceDisclaimers}
                {globalDisclaimers}
            </Container>
        </StyledDisclaimerGroup>
    );
}
