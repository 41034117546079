import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {ObservableMap, action, observable} from 'mobx';
import {
    FeatureTogglesModel,
    PersonalizationConfig,
    SpaGlobalConfig,
    SpaModel
} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {PAGE_ROOT_TYPE} from '../../modules/structure/PageRoot';
import {timeoutPromise} from '../../utils/getTimeoutPromise';
import {isInVec} from '../../utils/personalization/isInVec';
import {reportPersonalizationException} from '../../utils/personalization/reportPersonalizationException';
import {splitPath} from '../../utils/splitPath';
import {
    AdobeTargetService,
    HtmlOffer,
    MboxedOffers,
    SetContentOffer
} from '../adobeTarget/AdobeTargetService';
import {ContentStore} from '../content/ContentStore';
import {Logger} from '../logger/Logger';
import {ModelStore} from '../model/ModelStore';
import {RouterService} from '../route/RouterService';
import {
    MetaData,
    ProfileV2,
    SmartDigitalService
} from '../smartDigital/SmartDigitalService';
import {equalProfiles} from './equalProfiles';
import {
    InsertContent,
    PersonalizationStore,
    PersonalizedContent
} from './PersonalizationStore';
import {PriorityPersonalizationLoader} from './PriorityPersonalizationLoader';
import {createSelector} from './SelectorUtils';
import {isVecOrNotOptIn, isOptIn, loadProfile} from './utils';

export const extractContent = (
    offer: SetContentOffer | HtmlOffer
): TransferModel => {
    const html: HTMLElement = document.createElement('html');
    html.innerHTML = offer.content;
    const element = html.querySelector('.fragmentModel');
    if (!element) {
        throw new Error('cannot find fragment model in XF');
    }
    const content: SpaModel = JSON.parse(element.innerHTML);
    const pages = content.model[':children'];
    const pageKey = Object.keys(pages)[0];
    const pathData = {
        ':path': pageKey
    };
    const result = {
        ...pages[pageKey],
        ...pathData
    };

    return result;
};

class PageLoading {
    @observable public loaded: boolean = false;
    public pendingContents: string[] = [];

    public reset(): void {
        this.loaded = false;
        this.pendingContents = [];
    }
}

@singleton('PersonalizationStore', {env: 'client'})
export class ClientPersonalizationStore implements PersonalizationStore {
    @inject() private adobeTargetService!: AdobeTargetService;
    @inject() private personalizationConfig!: PersonalizationConfig;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private routerService!: RouterService;
    @inject() private smartDigitalService!: SmartDigitalService;
    @inject() private modelStore!: ModelStore;
    @inject() private personalizationLoader!: PriorityPersonalizationLoader;
    @inject() private contentStore!: ContentStore;
    @inject() private logger!: Logger;
    @inject() private featureToggles!: FeatureTogglesModel;

    private _sectionVisitsChecked = observable.box(false);
    private _visited = observable.array<string>([]);

    @action
    public onVisited(...sectionId: string[]): void {
        if (this.sectionVisitsChecked) {
            return;
        }
        sectionId.forEach(s => {
            if (!this._visited.includes(s)) {
                this._visited.push(s);
            }
        });
        // wait another 300 ms until we are sure all intersection observers have fired
        if (!this._sectionVisitsChecked.get()) {
            setTimeout(() => {
                this._sectionVisitsChecked.set(true);
            }, 300);
        }
    }

    /**
     * true if section visited or viewed by user are determined
     */
    get sectionVisitsChecked() {
        return this._sectionVisitsChecked.get();
    }
    public isVisited(sectionId?: string) {
        return !!sectionId && this._visited.includes(sectionId);
    }

    private mboxedContentMap: ObservableMap<
        string,
        PersonalizedContent
    > = observable.map({});
    private insertMap: ObservableMap<string, InsertContent[]> = observable.map(
        {},
        {deep: false}
    );
    private currentLocalProfile?: ProfileV2;
    private loadedPagesMap: Map<string, PageLoading> = new Map();
    private currentLocation: string | undefined;

    @postConstruct()
    public async init(): Promise<void> {
        this.currentLocation = this.routerService.pagePath;
        this.routerService.listen(this.onHistoryChange);

        if (!this.personalizationConfig.enabled || isInVec()) {
            this.logger.personalization.debug(
                'no personalization enabled:%s, vec:%s',
                this.personalizationConfig.enabled,
                isInVec()
            );
            return;
        }

        const location: string = this.routerService.pagePath || '';

        const startTime = new Date().getTime();
        try {
            const data = await timeoutPromise(
                this.personalizationLoader.getData(),
                this.personalizationConfig.timeout,
                'getting personalization'
            );

            const endTime = new Date().getTime();
            this.logger.personalization.info(
                'successfully loaded personalization after %s s',
                Math.round((endTime - startTime) / 100) / 10
            );

            this.currentLocalProfile = {
                personalization: data.localProfile.personalization,
                metadata: data.localProfile.metadata
            };
            this.processOffers(data.mboxedOffers);
        } catch (e) {
            this.showDefault();
            const generalError =
                'cannot get personalisation on initialisation.';
            const errorMessage =
                e instanceof Error
                    ? `${generalError} cause: \n${e.message}`
                    : generalError;

            this.logger.personalization.warn(errorMessage);
            reportPersonalizationException(
                'ClientPersonalizationInit',
                errorMessage
            );
        } finally {
            this.setPageLoaded(location);
        }
    }

    public getInserts(path: string): InsertContent[] {
        if (
            !this.personalizationConfig.enabled ||
            isVecOrNotOptIn(this.spaGlobalConfig.featureToggles)
        ) {
            return [];
        }

        const inserts = this.insertMap.get(path) || [];
        this.logger.personalization.debug(
            'found %s inserts for %s ',
            inserts ? inserts.length : 0,
            path
        );

        return inserts;
    }

    public getMetaData(): MetaData | undefined {
        return this.currentLocalProfile?.metadata;
    }

    public getContent(
        path: string,
        mboxId: string,
        _slot?: number
    ): PersonalizedContent | undefined {
        if (
            !this.personalizationConfig.enabled ||
            isVecOrNotOptIn(this.spaGlobalConfig.featureToggles)
        ) {
            return undefined;
        }
        if (this.featureToggles.enableSwapSectionPersonalization) {
            // check if all section visits have been determined
            if (!this.sectionVisitsChecked) {
                return {loaded: false};
            }
            // if the section was visited then it shouldn't be personalized
            if (this.isVisited(mboxId)) {
                return {loaded: true};
            }
        }

        let pContent = this.mboxedContentMap.get(mboxId);
        const pageLoaded = this.isPageLoaded(this.routerService.pagePath);

        if (!pContent && !pageLoaded) {
            pContent = {loaded: false};
            this.logger.personalization.debug(
                'pending personalisation %s ',
                path
            );

            this.getPageLoading(path).pendingContents.push(
                createSelector(path)
            );
        } else if (!pContent && pageLoaded) {
            this.logger.personalization.debug(
                'no personalization for %s ',
                path
            );
            pContent = {loaded: true};
        }

        return pContent;
    }

    private async executeGlobalOffer(localProfile: ProfileV2): Promise<void> {
        this.logger.personalization.debug(
            'ClientPersonalizationStore.executeGlobalOffer',
            localProfile.personalization
        );
        const currentPageRootModel = await this.contentStore.getCurrentPageRootModelAsPromise();

        const mboxedOffers = await this.adobeTargetService.getOffers(
            currentPageRootModel.availableMboxesOnPage,
            localProfile.personalization
        );

        this.processOffers(mboxedOffers);
    }

    private readonly onHistoryChange = async (): Promise<void> => {
        if (!isOptIn(this.spaGlobalConfig.featureToggles)) {
            return;
        }

        // make sure it is sectionTrackingStarted is true
        this._sectionVisitsChecked.set(true);
        this._visited.clear();

        const newLocation = this.routerService.pagePath;
        if (newLocation === this.currentLocation) {
            return;
        }
        this.currentLocation = newLocation;

        const pagePath = this.routerService.pagePath;
        const resourcePagePath = this.routerService.pageResourcePath;

        try {
            await timeoutPromise(
                (async () => {
                    const localProfile = await loadProfile(
                        this.smartDigitalService,
                        this.logger,
                        resourcePagePath
                    );

                    const changedProfile = this.updateProfile(localProfile);
                    if (changedProfile) {
                        this.clear();
                    }

                    if (!this.isPageLoaded(pagePath)) {
                        await this.executeGlobalOffer(localProfile);
                    }
                })(),
                this.personalizationConfig.timeout,
                'waiting for personalization after navigation'
            );
        } catch (e) {
            const generalError = 'cannot get personalisation on navigation.';
            const errorMessage =
                e instanceof Error
                    ? `${generalError} cause: \n${e.message}`
                    : generalError;

            this.logger.personalization.warn(errorMessage);
            reportPersonalizationException(
                'ClientPersonalizationNavigationEvent',
                errorMessage
            );
        } finally {
            this.setPageLoaded(pagePath);
        }
    };

    private updateProfile(localProfile: ProfileV2): boolean {
        const changed = !equalProfiles(localProfile, this.currentLocalProfile);
        this.currentLocalProfile = localProfile;

        if (changed) {
            this.logger.personalization.debug('profile changed');
        }

        return changed;
    }

    @action
    private processOffers(mboxedOffers: MboxedOffers[]): void {
        this.logger.personalization.debug(
            'ClientPersonalizationStore.processOffers: got %s global offers and %s offers for mboxes',
            mboxedOffers ? mboxedOffers.length : 0
        );

        mboxedOffers.forEach((mbox: MboxedOffers) => {
            if (mbox && mbox.offers) {
                mbox.offers.forEach((offer: HtmlOffer) => {
                    switch (offer.type) {
                        case 'html': {
                            this.handleHtmlOffer(offer, mbox.name);
                            break;
                        }
                        default:
                            this.logger.personalization.info('unknown type');
                    }
                });
            }
        });
    }

    private handleHtmlOffer(offer: HtmlOffer, mbox: string): void {
        try {
            this.logger.personalization.debug(
                'ClientPersonalizationStore.handleHtmlOffer',
                offer,
                mbox
            );
            const content = extractContent(offer);
            const root: TransferModel = content[':items']['root'];
            let type: string = root[':type'];

            let relativePath = '';
            if (type === PAGE_ROOT_TYPE) {
                relativePath = '/main';
                type = root[':items']['main'][':type'];
            }

            const absolutePath = this.modelStore.insertPersonalizedData(
                mbox,
                content,
                'mbox'
            );
            const contentId: string = content['contentId'];
            const xfCmsVersion: string = content['cmsVersion'];

            if (xfCmsVersion !== this.spaGlobalConfig.cmsVersion) {
                this.logger.personalization.warn(
                    "won't personalized content %s for mbox %s due to old cms version (%s) of XF",
                    contentId,
                    mbox,
                    xfCmsVersion
                );
                return;
            }

            this.logger.personalization.debug(
                'replace personalized content %s for mbox %s',
                contentId,
                mbox
            );
            const pContent: PersonalizedContent = {
                resource: {
                    path: absolutePath + relativePath,
                    resourceType: type,
                    contentId
                },
                loaded: true,
                type: this.currentLocalProfile?.metadata.personalizationType,
                group: this.currentLocalProfile?.metadata.personalizationGroup,
                recommendationId: this.currentLocalProfile?.metadata
                    .recommendationId
            };

            this.mboxedContentMap.set(mbox, pContent);
        } catch (e) {
            const generalError = 'content is not valid json in html offer.';
            const errorMessage =
                e instanceof Error
                    ? `${generalError} Reason:\n${e.message}`
                    : generalError;

            this.logger.personalization.warn(errorMessage);

            return;
        }
    }

    private clear(): void {
        this.mboxedContentMap.clear();

        // Don't clear map, keep the original observable instances!
        // In the past there was a race condition when .getContent() was
        // accessed before the clear() call.
        // In that case .getContent() accessed the old instance's `loaded`
        // value, leading to the observer() component not reacting to changes.
        this.loadedPagesMap.forEach(pageLoading => pageLoading.reset());
    }

    private setPageLoaded(pagePath: string): void {
        this.logger.personalization.info('content loaded for %s', pagePath);
        const pageLoading = this.getPageLoading(pagePath);
        if (!pageLoading.loaded) {
            pageLoading.loaded = true;
            pageLoading.pendingContents = [];
        }
    }

    private isPageLoaded(pagePath: string): boolean {
        return this.getPageLoading(pagePath).loaded;
    }

    private getPageLoading(path: string): PageLoading {
        const page = splitPath(path).page;
        let pageLoading = this.loadedPagesMap.get(page);
        if (!pageLoading) {
            pageLoading = new PageLoading();
            this.loadedPagesMap.set(page, pageLoading);
        }

        return pageLoading;
    }

    private showDefault(): void {
        PriorityPersonalizationLoader.showDefault();
    }
}
