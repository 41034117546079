import * as React from 'react';
import styled from 'styled-components';

const icon = (
    <svg
        width="50"
        height="50"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path d="M25 50C11.193 50 0 38.807 0 25S11.193 0 25 0s25 11.193 25 25-11.193 25-25 25zm5.84-28.507l6.425 3.742a.09.09 0 0 1-.001.115l-6.424 3.746.894 1.396 6.485-3.78.077-.052c.792-.585.937-1.671.324-2.427a1.776 1.776 0 0 0-.323-.308l-.079-.053-6.485-3.776-.893 1.397zm-11.68 7.602l-6.425-3.742a.09.09 0 0 1 0-.115l6.424-3.746-.893-1.396-6.485 3.78-.078.052c-.791.585-.936 1.671-.324 2.427.093.115.202.218.323.308l.08.053 6.484 3.776.893-1.397zM25 48c12.703 0 23-10.297 23-23S37.703 2 25 2 2 12.297 2 25s10.297 23 23 23z" />
    </svg>
);

const SIZE = 50;
const TRANSLATE = SIZE / 4;
const SIZE_WITH_ANIM = SIZE + 2 * TRANSLATE;

const StyledDragButton = styled.div`
    @keyframes drag-btn-float {
        from {
            transform: translateX(-${TRANSLATE}px);
        }
        to {
            transform: translateX(${TRANSLATE}px);
        }
    }
    width: ${SIZE}px;
    height: ${SIZE}px;
    cursor: pointer;
    user-select: none;
    pointer-events: none;
    animation: drag-btn-float 1s ease-in-out infinite alternate;
    fill: ${props => props.theme.text.color.primary};
`;

const StyledDragButtonContainer = styled.div`
    width: ${SIZE_WITH_ANIM}px;
    height: ${SIZE}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DragButton = () => (
    <StyledDragButtonContainer>
        <StyledDragButton>{icon}</StyledDragButton>
    </StyledDragButtonContainer>
);
