import {
    SharedHistoryService,
    defineHistoryService
} from '@feature-hub/history-service';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {ServiceRegistration} from './ServiceRegistration';
import {
    CmsRootLocationTransformer,
    createRootLocationTransformer
} from './history/CmsRootLocationTransformer';
export const HistoryServiceId = 'HistoryService';

const rootLocationTransformer = (
    primaryConsumerHistoryKey: string,
    secondaryExtension: string,
    getSecondaryConsumerId: (pathname: string) => {[consumerId: string]: string}
) =>
    createRootLocationTransformer({
        consumerPathsQueryParamName: '---',
        primaryConsumerHistoryKey,
        secondaryExtension,
        getSecondaryConsumerId
    });

export function createHistoryService(
    primaryConsumerHistoryKey: string,
    secondaryExtension: string,
    cb: (rootLocationTransformer: CmsRootLocationTransformer) => void,
    getSecondaryConsumerId: (pathname: string) => {[consumerId: string]: string}
): ServiceRegistration<SharedHistoryService> {
    const transformer = rootLocationTransformer(
        primaryConsumerHistoryKey,
        secondaryExtension,
        getSecondaryConsumerId
    );
    if (cb) {
        cb(transformer);
    }

    const mode = isInBrowser() ? 'browser' : 'static';

    return {
        definition: defineHistoryService(transformer, {mode}),
        service: {typeName: HistoryServiceId, version: '2.0.0'}
    };
}

export {rootLocationTransformer};
