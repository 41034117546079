import * as React from 'react';

import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {XfContentSliderModel} from '../../../../generated/core';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';

import {OpenInLayer, ExternalLink} from '@volkswagen-onehub/icons-core';

import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {MediaContext} from '../../../context/media/MediaContext';
import {MediaElement} from '../../editorial/elements/MediaElement';
import {AlertBox} from '../../../components/AlertBox';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';

import {
    TeaserImageLink,
    TeaserMainLink
} from '../../../components/teaser-element/helpers';
import {HoverGroup} from '../../../d6/components/hover-group';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useTrackSliderClick} from '../../../utils/tracking/useTrackSliderClick';
import {
    ContextTrackingData,
    Filter,
    FocusCategory
} from '../../../context/tracking/TrackingService';
import {LinkTarget} from '../../../d6/components/teaser-element';
import {isAbsoluteUrl} from '../../../utils/link/isAbsoluteUrl';

export const XF_CONTENT_SLIDER_RESOURCE_TYPE =
    'vwa-ngw18/components/structure/xfContentSlider';

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H4" order="H3" />
);

const media = (
    <MediaContext.Provider value={{ratio: 'r_1_1'}}>
        <MediaElement path="media" actsAsTeaser />
    </MediaContext.Provider>
);

const StyledSlideWrap = styled.div`
    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.dynamic0050};
    padding-bottom: ${props =>
        props.theme.size.static100}; // safe area for teaser focus outline
`;

const StyledHeadingWrap = styled.div<{
    isExternalLink?: boolean;
    isInternalLink?: boolean;
}>`
    position: relative;
    padding-inline-start: ${props =>
        props.isExternalLink || props.isInternalLink
            ? '0'
            : props.theme.size.static400};

    svg {
        ${props =>
            !props.isExternalLink &&
            `
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
        `}

        ${props =>
            props.isExternalLink &&
            `
            width: ${props.theme.size.static200};
            height: ${props.theme.size.static200};
            vertical-align: text-top;
            margin-left: ${props.theme.size.static150};
        `}
    }

    ${props =>
        props.isExternalLink &&
        `
          h3 {
              display: inline;
          }
      `}
`;

export const responsiveSliderMediaConfig: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        aspectRatio: 'r_1_1',
        sizes: '100vw'
    },
    {
        mediaQuery: `(min-width: ${
            Breakpoints.b560
        }px) and (max-width: ${Breakpoints.b1280 - 1}px)`,
        aspectRatio: 'r_1_1',
        sizes: '33vw'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b1280}px)`,
        aspectRatio: 'r_1_1',
        sizes: '25vw'
    }
];

export interface ExtraXfContentSliderProps {
    itemCount: number;
    itemPosition: number;
    focusCategories: FocusCategory[];
    activeCategorylist: Filter[];
}

const InternalXfContentSlider: React.FunctionComponent<XfContentSliderModel &
    ExtraXfContentSliderProps> = (
    props: XfContentSliderModel & ExtraXfContentSliderProps
) => {
    const {
        category,
        contentLayerLink,
        focusCategories,
        activeCategorylist,
        itemCount,
        itemPosition,
        contentId,
        linkTarget,
        linkUrl,
        layerLink
    } = props;

    const alertBox = props.validationError && C.isInEditor() && (
        <AlertBox>
            {props.validationMessages.map((msg, idx) => (
                <span key={idx}>{msg}</span>
            ))}
        </AlertBox>
    );

    const mediaElement = (
        <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']} allowNested>
            <MediaElement
                path="media"
                responsiveMediaConfig={responsiveSliderMediaConfig}
                actsAsTeaser
            />
        </GeneralDisclaimerProvider>
    );

    const trackSliderClick = useTrackSliderClick();
    const trackingActionOverride = (
        href: string,
        linkName: string,
        contextData: ContextTrackingData,
        layerName?: string
    ) => {
        trackSliderClick(
            href,
            contentId,
            linkName,
            layerName,
            layerLink,
            contextData,
            activeCategorylist,
            itemCount,
            itemPosition,
            focusCategories
        );
    };

    const href = layerLink ? contentLayerLink : linkUrl;
    const externalLink = !layerLink && isAbsoluteUrl(linkUrl);
    const isInternalLink = Boolean(!layerLink && linkUrl);

    const teaserMediaElement = (
        <MediaContext.Provider value={{ratio: 'r_1_1'}}>
            <TeaserImageLink
                href={href}
                contentId={contentId}
                trackingActionOverride={trackingActionOverride}
                isLayerLink={layerLink}
                target={linkTarget as LinkTarget}
                layerProps={{layerType: 'CONTENT'}}
                linkName={props.linkName}
                videoScaling
            >
                {mediaElement}
            </TeaserImageLink>
        </MediaContext.Provider>
    );

    const teaserMainLinkElement = (
        <TeaserMainLink
            href={href}
            contentId={contentId}
            trackingActionOverride={trackingActionOverride}
            isLayerLink={layerLink}
            target={linkTarget as LinkTarget}
            layerProps={{layerType: 'CONTENT'}}
            linkName={props.linkName}
        >
            <StyledHeadingWrap
                isExternalLink={Boolean(externalLink)}
                isInternalLink={isInternalLink}
            >
                {layerLink && <OpenInLayer ariaHidden />}
                {headingElement}
                {externalLink && <ExternalLink ariaHidden />}
            </StyledHeadingWrap>
        </TeaserMainLink>
    );

    const authorView = (
        <AuthoringWrapper title="Content Slider XF">
            <Container
                gutter={ContainerGutter.dynamic0100}
                wrap={ContainerWrap.always}
                padding={{
                    left: ContainerPadding.grid008,
                    right: ContainerPadding.grid008
                }}
                stretchContent
            >
                {alertBox}
                {category ? (
                    <Text appearance={TokenTextAppearance.copy100}>
                        {category}
                    </Text>
                ) : (
                    undefined
                )}
                {headingElement}
                {media}
            </Container>
        </AuthoringWrapper>
    );

    const publishView = (
        <CyAttributeAppender name="contentSliderItem" addTag={'div'}>
            <HoverGroup>
                <StyledSlideWrap>
                    {teaserMediaElement}
                    {teaserMainLinkElement}
                </StyledSlideWrap>
            </HoverGroup>
        </CyAttributeAppender>
    );

    return C.isInEditor() ? authorView : publishView;
};

export const XfContentSlider = MapTo<ExtraXfContentSliderProps>(
    'vwa-ngw18/components/structure/xfContentSlider',
    InternalXfContentSlider,
    {noElementRecursively: true}
);
