import * as React from 'react';

import {CTA, CTAProps, Text} from '@volkswagen-onehub/components-core';
import {C} from '../../registries/compatibilty';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {AlertBox, AlertBoxSeverity} from '../AlertBox';

import {BaseCmsLinkProps, useCmsLink} from './CmsLink';
import {NO_BUTTON_ELEMENT_HREF} from './constants';

type ExtraCmcButtonLinkProps = {
    isRichTextLink?: boolean;
};

export type CmsButtonLinkProps = BaseCmsLinkProps &
    Omit<CTAProps, 'tag'> &
    ExtraCmcButtonLinkProps;

export function CmsButtonLink(
    props: React.PropsWithChildren<CmsButtonLinkProps>
): JSX.Element | null {
    const {
        target,
        children,
        title,
        emphasis,
        isLayerLink,
        isRichTextLink
    } = props;

    const ref = React.useRef(null);
    const [onCmsLinkClick, href] = useCmsLink(ref, props);

    const alertBox = !href && (
        <AlertBox severity={AlertBoxSeverity.WARNING}>
            <span>{NO_BUTTON_ELEMENT_HREF}</span>
        </AlertBox>
    );

    const ctaProps = {
        emphasis,
        onClick: onCmsLinkClick,
        ref,
        title
    };

    const linkProps: CTAProps = {
        tag: 'a',
        href,
        target
    };

    const cta = (
        <CyAttributeAppender name="buttonElement">
            <CTA {...ctaProps} {...linkProps}>
                <CyAttributeAppender name="buttonElementLabel">
                    {isLayerLink && isRichTextLink ? (
                        <Text bold>{children}</Text>
                    ) : (
                        <>{children}</>
                    )}
                </CyAttributeAppender>
            </CTA>
        </CyAttributeAppender>
    );

    return C.isInEditor() ? (
        <>
            {alertBox}
            {cta}
        </>
    ) : (
        cta
    );
}
