import {ActionParameters} from '@volkswagen-onehub/user-interaction-service';

interface Parameters {
    [key: string]: string | string[];
}

export function createStringParams(params: URLSearchParams): ActionParameters {
    const keys = new Set<string>();
    params.forEach((_, key) => keys.add(key));
    return Array.from(keys).reduce((prev: Parameters, key: string) => {
        const values = params.getAll(key);
        if (values.length > 1) {
            prev[key] = values;
        } else {
            const value = params.get(key);
            if (value) {
                prev[key] = value;
            }
        }

        return prev;
    }, {} as Parameters);
}
