import {
    SharedServiceConfigEndpointProvider,
    defineServiceConfigEndpointProvider
} from '@volkswagen-onehub/service-config-endpoint-provider';
import {
    ServiceConfigEndpointToken,
    SpaGlobalConfig
} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';

export function createServiceConfigEndpointProvider(
    endpoint: ServiceConfigEndpointToken,
    spaGlobalConfig: SpaGlobalConfig
): ServiceRegistration<SharedServiceConfigEndpointProvider> {
    return {
        definition: defineServiceConfigEndpointProvider(
            endpoint,
            spaGlobalConfig.env !== 'PROD' || spaGlobalConfig.author
        ),
        service: {
            typeName: 'ServiceConfigEndpointProvider',
            version: '^1.0.0'
        }
    };
}
