import * as React from 'react';
import {MagazineCategoryLinksSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {usePersonalizationContext, useTrackingService} from '../../../context';
import {ContextTrackingData} from '../../../context/tracking/TrackingService';
import {getEndDirection} from '../../../d6/components/helpers';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {SectionWrapper} from './SectionWrapper';
import {styled, Breakpoints} from '@volkswagen-onehub/components-core';
import {C} from '../../../registries/compatibilty';
import {AlertBox} from '../../../components/AlertBox';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';

const MAX_LINKS_AMOUNT = 6;

const StyledMagazineNav = styled.nav`
    margin: 0 ${props => props.theme.size.grid003};

    @media (min-width: ${Breakpoints.b960}px) {
        margin: 0 ${props => props.theme.size.grid002};
    }
`;
StyledMagazineNav.displayName = 'StyledMagazineNav';

const StyledLinkList = styled.ul`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
`;
StyledLinkList.displayName = 'StyledLinkList';

const StyledLinkItem = styled.li<{isCurrent: boolean}>`
    text-align: center;
    list-style-type: none;
    font-size: ${props => props.theme.size.static250};
    margin-bottom: ${props => props.theme.size.static300};
    margin-block-end: ${props => props.theme.size.static300};
    font-weight: ${props => props.theme.text.weight.light};
    font-family: ${props => props.theme.fonts.headline[400].fontFamily};

    ${props =>
        props.isCurrent &&
        `
            font-weight: ${props.theme.text.weight.bold};
            color: ${props.theme.text.color.primary};
        `}

    &:not(:last-of-type) {
        margin-${props =>
            getEndDirection(props.theme.direction)}: max(${props =>
    props.theme.size.static300}, var(--size-grid001));
        margin-inline-end: max(${props =>
            props.theme.size.static300}, var(--size-grid001));
    }

    @media (min-width: ${Breakpoints.b960}px) {
        font-size: ${props => props.theme.size.static350};
    }
`;
StyledLinkItem.displayName = 'StyledLinkItem';

interface CategoryLink {
    label: string;
    internalLink: string;
}

/**
 * S133 Magazine Category Links Section
 */
function InternalMagazineCategoryLinksSection(
    props: MagazineCategoryLinksSectionModel
): JSX.Element {
    const {
        anchorId,
        mboxId,
        contentName,
        contentLabels,
        contentId: sectionId,
        tagTypeBroken,
        categoryLinks,
        translations,
        currentPageLink,
        overviewPageLink,
        validationError,
        validationMessages,
        currentStageSectionId
    } = props;
    const trackingService = useTrackingService();
    const personalizationContext = usePersonalizationContext();

    const alertBox = validationError && C.isInEditor() && (
        <AlertBox>
            {validationMessages.map((msg, idx) => (
                <span key={idx}>{msg}</span>
            ))}
        </AlertBox>
    );

    const trackingAction = React.useCallback(
        (linkText: string, url: string, position: number) => {
            return () => {
                const allLinks = [overviewPageLink, ...categoryLinks];
                const itemCount =
                    categoryLinks.length > MAX_LINKS_AMOUNT
                        ? MAX_LINKS_AMOUNT + 1
                        : allLinks.length;
                const contextData: ContextTrackingData = {
                    sectionId: currentStageSectionId,
                    contentLabels,
                    itemCount: itemCount,
                    itemPosition: position,
                    ...personalizationContext
                };

                trackingService.trackContentTabClick(
                    sectionId,
                    url,
                    linkText,
                    contextData
                );
            };
        },
        [
            categoryLinks,
            overviewPageLink,
            contentLabels,
            sectionId,
            personalizationContext,
            trackingService,
            currentStageSectionId
        ]
    );

    const allCategoriesLabel =
        translations['magazineCategoryLinks.allCategories'];
    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S133 Magazine Category Links Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {alertBox}
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                {overviewPageLink && (
                    <StyledMagazineNav
                        aria-label={
                            translations['magazineCategoryLinks.navAriaLabel']
                        }
                    >
                        <StyledLinkList>
                            <CyAttributeAppender name="allCategoriesLink">
                                <StyledLinkItem
                                    key={overviewPageLink.label}
                                    isCurrent={
                                        overviewPageLink.internalLink ===
                                        currentPageLink.internalLink
                                    }
                                >
                                    <CmsTextLink
                                        linkName={allCategoriesLabel}
                                        emphasis={
                                            overviewPageLink.internalLink ===
                                            currentPageLink.internalLink
                                                ? 'tertiary'
                                                : 'none'
                                        }
                                        href={overviewPageLink.internalLink}
                                        trackingActionOverride={trackingAction(
                                            allCategoriesLabel,
                                            overviewPageLink.internalLink,
                                            1
                                        )}
                                    >
                                        {allCategoriesLabel}
                                    </CmsTextLink>
                                </StyledLinkItem>
                            </CyAttributeAppender>
                            {categoryLinks
                                .map(
                                    (
                                        categoryLink: CategoryLink,
                                        idx: number
                                    ) => (
                                        <CyAttributeAppender
                                            name="categoryLink"
                                            key={categoryLink.label}
                                        >
                                            <StyledLinkItem
                                                key={categoryLink.label}
                                                isCurrent={
                                                    categoryLink.internalLink ===
                                                    currentPageLink.internalLink
                                                }
                                            >
                                                <CmsTextLink
                                                    linkName={
                                                        categoryLink.label
                                                    }
                                                    emphasis={
                                                        categoryLink.internalLink ===
                                                        currentPageLink.internalLink
                                                            ? 'tertiary'
                                                            : 'none'
                                                    }
                                                    href={
                                                        categoryLink.internalLink
                                                    }
                                                    trackingActionOverride={trackingAction(
                                                        categoryLink.label,
                                                        categoryLink.internalLink,
                                                        idx + 2
                                                    )}
                                                >
                                                    {categoryLink.label}
                                                </CmsTextLink>
                                            </StyledLinkItem>
                                        </CyAttributeAppender>
                                    )
                                )
                                .slice(0, MAX_LINKS_AMOUNT)}
                        </StyledLinkList>
                    </StyledMagazineNav>
                )}
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/sections/magazineCategoryLinksSection';

export const MagazineCategoryLinksSection = MapTo(
    RESOURCE_TYPE,
    InternalMagazineCategoryLinksSection
);
