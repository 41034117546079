import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    Text,
    TokenTextAppearance,
    ThemeProvider,
    css,
    styled
} from '@volkswagen-onehub/components-core';

export interface PowerTeaserProps {
    media: JSX.Element;
    overline: JSX.Element;
    headline: JSX.Element;
    copy: JSX.Element;
    bullets?: JSX.Element;
    buttons?: JSX.Element;
    lightMode?: boolean;
}

// Grid of with 24fr columns, as in Core Layout
// Own setup needed for complex layout with specified row sizes and stacked grid areas.

const GridLayout = styled.div`
    display: grid;
    margin: 0;
    max-width: ${Breakpoints.b2560}px;

    grid-template-columns:
        [background-start] repeat(2, 1fr)
        [heading-start media-start copy-start bullets-start buttons-start] repeat(
            20,
            1fr
        )
        [heading-end media-end copy-end bullets-end buttons-end] repeat(2, 1fr)
        [background-end];

    grid-template-rows:
        [heading-start] auto
        [heading-end] ${props => props.theme.size.dynamic0130}
        [media-start] ${props => props.theme.size.dynamic0270}
        [background-start] auto
        [media-end] ${props => props.theme.size.dynamic0100}
        [copy-start] auto
        [copy-end bullets-start] auto
        [bullets-end buttons-start] auto
        [buttons-end] ${props => props.theme.size.dynamic0270}
        [background-end];

    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-columns:
            [media-start background-start] repeat(2, 1fr)
            [heading-start buttons-start copy-start] repeat(9, 1fr)
            [heading-end buttons-end copy-end] repeat(2, 1fr)
            [bullets-start] repeat(9, 1fr)
            [bullets-end] repeat(2, 1fr)
            [background-end media-end];

        grid-template-rows:
            [background-start] ${props => props.theme.size.dynamic0100}
            [media-start] auto
            [media-end] ${props => props.theme.size.dynamic0100}
            [bullets-start heading-start] auto
            [heading-end copy-start] auto
            [copy-end buttons-start] auto
            [buttons-end bullets-end] ${props => props.theme.size.dynamic0250}
            [background-end];
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-template-columns:
            [media-start] repeat(1, 1fr)
            [background-start] repeat(2, 1fr)
            [heading-start buttons-start copy-start] repeat(8, 1fr)
            [heading-end buttons-end copy-end] repeat(2, 1fr)
            [bullets-start] repeat(8, 1fr)
            [bullets-end] repeat(2, 1fr)
            [background-end] repeat(1, 1fr)
            [media-end];

        grid-template-rows:
            [background-start] ${props => props.theme.size.dynamic0100}
            [media-start] auto
            [media-end] ${props => props.theme.size.dynamic0100}
            [bullets-start heading-start] auto
            [heading-end copy-start] auto
            [copy-end buttons-start] auto
            [buttons-end bullets-end] ${props => props.theme.size.dynamic0250}
            [background-end];
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-template-columns:
            repeat(1, 1fr)
            [background-start] repeat(1, 1fr)
            [heading-start copy-start bullets-start buttons-start] repeat(
                6,
                1fr
            )
            [heading-end copy-end bullets-end buttons-end] repeat(1, 1fr)
            [media-start] repeat(13, 1fr)
            [background-end] repeat(1, 1fr)
            [media-end] repeat(1, 1fr);

        grid-template-rows:
            [background-start] ${props => props.theme.size.dynamic0150}
            [heading-start media-start] auto
            [heading-end] ${props => props.theme.size.dynamic0130}
            [copy-start] auto
            [copy-end bullets-start] auto
            [bullets-end buttons-start] auto
            [buttons-end] auto
            [media-end] ${props => props.theme.size.dynamic0270}
            [background-end];
    }
`;

const Background = styled.div`
    grid-area: background;
    background-color: ${props => props.theme.colors.background.primary};
`;

const HeadingArea = styled.div<{
    showMobile: boolean;
}>`
    grid-area: heading;
    display: none;

    @media (max-width: ${Breakpoints.b560 - 1}px) {
        ${props =>
            props.showMobile &&
            css`
                display: initial;
                text-align: center;
            `}
    }

    @media (min-width: ${Breakpoints.b560}px) {
        ${props =>
            !props.showMobile &&
            css`
                display: initial;
            `}
    }
`;

const MediaArea = styled.div`
    grid-area: media;
`;

const CopyArea = styled.div<{altTabletLayout?: boolean}>`
    grid-area: copy;

    @media (min-width: ${Breakpoints.b560}px) and (max-width: ${Breakpoints.b1280 -
            1}px) {
        margin-top: ${props => props.theme.size.dynamic0100};
        ${props =>
            props.altTabletLayout &&
            css`
                grid-area: bullets;
                margin-top: 0;
            `};
    }
`;

const BulletsArea = styled.div`
    grid-area: bullets;
    margin-top: ${props => props.theme.size.dynamic0130};

    @media (min-width: ${Breakpoints.b560}px) and (max-width: ${Breakpoints.b1280 -
            1}px) {
        margin-top: 0;
    }
`;

const ButtonsArea = styled.div`
    grid-area: buttons;
    align-self: end;

    padding: ${props => props.theme.size.static200} 0;

    margin-top: ${props => props.theme.size.dynamic0150};
`;

export const PowerTeaserPromo: React.FunctionComponent<PowerTeaserProps> = ({
    media,
    overline,
    headline,
    copy,
    bullets,
    buttons,
    lightMode
}: PowerTeaserProps) => {
    const heading = (
        <Container
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.dynamic0020}
            stretchContent
        >
            <Text appearance={TokenTextAppearance.headline100}>{overline}</Text>
            {headline}
        </Container>
    );

    // Alternative Tablet layout means, that the Copy Text will be
    // at the position of the bullets, if no bullets are set
    const altTabletLayout = !bullets;

    return (
        <GridLayout>
            <HeadingArea showMobile>{heading}</HeadingArea>
            <ThemeProvider theme={lightMode ? 'main' : 'inverted'}>
                <Background />
                <HeadingArea showMobile={false}>{heading}</HeadingArea>
                <MediaArea>{media}</MediaArea>
                <CopyArea altTabletLayout={altTabletLayout}>
                    <Text appearance={TokenTextAppearance.copy200}>{copy}</Text>
                </CopyArea>

                {bullets && <BulletsArea>{bullets}</BulletsArea>}
                {buttons && <ButtonsArea> {buttons} </ButtonsArea>}
            </ThemeProvider>
        </GridLayout>
    );
};
