import {
    SharedDisclaimerManager,
    defineDisclaimerManager,
    defineGfaDisclaimerManager,
    defineDisclaimerManagerFactory,
    SharedDisclaimerManagerFactory,
    SanitizeFunction
} from '@volkswagen-onehub/disclaimer-manager';
import {SharedGfaDisclaimerManager} from '@volkswagen-onehub/gfa-disclaimer-manager';
import {SpaGlobalConfig} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';

export function createDisclaimerManager(
    config: SpaGlobalConfig,
    sanitizeFunction: SanitizeFunction
): [
    ServiceRegistration<SharedDisclaimerManagerFactory>,
    ServiceRegistration<SharedDisclaimerManager>,
    ServiceRegistration<SharedGfaDisclaimerManager>
] {
    const factoryDefinition = defineDisclaimerManagerFactory({
        legalEntityLabels: config.legalEntityLabels,
        defaultLegalEntity: config.defaultLegalEntity,
        sanitize: config.featureToggles.disableSanitizeHTML
            ? text => text
            : sanitizeFunction
    });
    const definition = defineDisclaimerManager();
    const gfaDefinition = defineGfaDisclaimerManager();
    return [
        {
            definition: factoryDefinition
        },
        {
            definition: definition,
            service: {
                typeName: 'DisclaimerManager',
                version: '^1.4.0'
            }
        },
        {
            definition: gfaDefinition
        }
    ];
}
