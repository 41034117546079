export interface SlotSelector {
    path: string;
    index: undefined;
}

export interface InsertSelector {
    path: string;
    index: number;
}

export type Selector = SlotSelector | InsertSelector;

export interface SelectorOptions {
    index: number;
    insert: boolean;
}
export const fullSelectorPath = (slot: Selector) => {
    if (typeof slot.index === 'number') {
        return `${slot.path}_${slot.index}`;
    }

    return slot.path;
};
export const parseSelector = (selector: string): Selector | null => {
    const match = selector.match(/^#vwa_cms_(slot|(insert)_(\d+))_([^ ]+)?/);
    if (match !== null && match.length === 5) {
        if (match[1] === 'slot') {
            const path = match[4].split('\\').join('');

            return {
                path,
                index: undefined
            };
        } else if (match[2] === 'insert') {
            const path = match[4].split('\\').join('');
            const index = parseInt(match[3], 10);

            return {
                path,
                index
            };
        }
    }

    return null;
};

export const createSelector = (path: string, index?: number): string => {
    const sanitizedPath = path;

    if (typeof index !== 'undefined') {
        return `vwa_cms_insert_${index}_${sanitizedPath}`;
    }

    return `vwa_cms_slot_${sanitizedPath}`;
};
