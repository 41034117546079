import * as React from 'react';

import {useNavigationStore, useSectionGroupContext} from '../../../../context';

function useSectionIOHandler(
    sectionId?: string
): (entries: IntersectionObserverEntry[]) => void {
    const oneHubNavigationStore = useNavigationStore();
    const {sectionGroupContentId} = useSectionGroupContext();

    return function handleSectionIOEvent(
        entries: IntersectionObserverEntry[]
    ): void {
        if (entries.length > 0) {
            const [entry] = entries;
            const isIntersecting = entry.intersectionRatio > 0;

            if (!sectionId) {
                return;
            }

            const handleSetActiveSection = () => {
                if (isIntersecting) {
                    oneHubNavigationStore.setActiveSection(
                        sectionId,
                        sectionGroupContentId
                    );
                    return;
                }

                if (sectionId === oneHubNavigationStore.activeSectionId) {
                    oneHubNavigationStore.setActiveSection();
                }
            };

            if (sectionGroupContentId) {
                // Timeout added to keep a correct order for nested items in section group
                setTimeout(() => {
                    handleSetActiveSection();
                }, 0);
            } else {
                handleSetActiveSection();
            }
        }
    };
}

export function useSectionUpdater(
    ref: React.RefObject<HTMLDivElement>,
    sectionId: string
): void {
    const [currentSectionId, setCurrentSectionId] = React.useState<string>(
        sectionId
    );
    const [currentRef, setCurrentRef] = React.useState<HTMLDivElement | null>(
        null
    );
    const handleSectionIOEvent = useSectionIOHandler(currentSectionId);

    React.useEffect(() => {
        const optionsSection = {
            rootMargin: '-40%',
            threshold: 0
        };

        setCurrentRef(ref.current);
        setCurrentSectionId(sectionId);

        const io = new IntersectionObserver(
            handleSectionIOEvent,
            optionsSection
        );

        if (currentRef) {
            io.observe(currentRef);
        }

        return function cleanUp(): void {
            io.disconnect();
        };
        // there should'nt be a ref inside of here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSectionId, currentRef]);
}
