import {DealerConfigurationV1} from '@volkswagen-onehub/mydealer-configuration-service';
import {useEffect, useState} from 'react';
import {useMydealerConfigurationService} from '../context';
import {makeCancelable} from './makeCancelable';

export interface DealerConfigState {
    loaded: boolean;
    result?: DealerConfigurationV1;
}

export const useDealerConfig = (dealerId?: string): DealerConfigState => {
    const [dealerConfigState, setDealerConfigState] = useState<
        DealerConfigState
    >({loaded: !dealerId});

    const service = useMydealerConfigurationService();
    useEffect(() => {
        setDealerConfigState({loaded: false});
        if (!dealerId) {
            return;
        }
        const promise = service.getDealer(dealerId);
        const result = makeCancelable(promise);
        result.promise
            .then(d => setDealerConfigState({loaded: true, result: d}))
            .catch(() =>
                setDealerConfigState({loaded: true, result: undefined})
            );
        return result.cancel;
    }, [dealerId, service]);

    return dealerConfigState;
};
