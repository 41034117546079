import * as React from 'react';
import {css} from 'styled-components';

import {Breakpoints, Text, styled} from '@volkswagen-onehub/components-core';

import {XfCarFeatureModel} from '../../../generated/core';
import {setTopBarHeight} from '../../d6/components/navigation-top-bar-one-hub-helpers';
import {ContainerItem} from '../../infrastructure/ContainerExporter';
import {HeadingElement} from '../../modules/editorial/elements/HeadingElement';
import {RichtextSimpleElement} from '../../modules/editorial/elements/RichtextElement';
import {Accordion} from '../../modules/editorial/sections/table/Accordion';
import {C} from '../../registries/compatibilty';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {AlertBox} from '../AlertBox';
import {PersonalizationInclude} from '../PersonalizationInclude';

const COPY_PARSYS = 'copyParsys';

export interface HighlightAccordionItemProps {
    readonly feature: XfCarFeatureModel;
    readonly path: string;
    readonly itemIdx: number;
    readonly anchorId: string;
    readonly prevAnchorId?: string;
    readonly nextAnchorId?: string;
    readonly isActive: boolean;
    readonly gallery: JSX.Element;
    readonly sectionRef?: React.RefObject<HTMLDivElement>;
    handleItemClick(
        id: number,
        title?: string,
        scrollItemRef?: React.RefObject<HTMLElement>
    ): void;
}

interface StyledGalleryWrapperProps {
    readonly enableDesktopLayout?: boolean;
    readonly isActive?: boolean;
}

const StyledStickyWrapper = styled.div<StyledGalleryWrapperProps>`
    @media (min-width: ${Breakpoints.b960}px) {
        ${props =>
            /*
On Desktop, we render the Gallery in the left column of the
HighlightFeatureSection, by breaking out of the Accordions,
using position: absolute.
This is mainly, so the logical order of the HTML is maintained,
as the galleries are technically still accordion body content.
*/
            props.enableDesktopLayout &&
            css`
                position: absolute;
                top: 0;
                inset-inline-start: 0;
                width: ${props.theme.size.grid014};
                height: 100%;
                display: ${props.isActive ? 'block' : 'none'};
                overflow: unset;
            `}
    }
`;

const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0100};
    padding-top: ${props => props.theme.size.dynamic0050};

    // Note: remove once the copy element has no marign
    & [class*='StyledBodyWrapper'] {
        margin-bottom: 0;
    }
`;

const StyledCopyItem = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: ${props => props.theme.size.grid001};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        padding-inline-start: 0;
    }
`;

StyledCopyItem.displayName = 'StyledCopyItem';

const CSS_VAR_TOP_BAR_HEIGHT = '--s2-one-hub-navigation-top-bar-height';

const StyledGalleryWrapper = styled.div<StyledGalleryWrapperProps>`
    display: block;
    margin: ${props => props.theme.size.dynamic0100}
        calc(-1 * ${props => props.theme.size.grid002}); /* delete paddings from the gallery on mobile  devices */

    @media (min-width: ${Breakpoints.b560}px) {
        margin: ${props => props.theme.size.dynamic0100}
            calc(-1 * ${props => props.theme.size.grid005}); /* delete paddings from the gallery on mobile  devices */
    }

    @media (min-width: ${Breakpoints.b960}px) {
        margin: 0;

        ${props =>
            props.enableDesktopLayout &&
            css`
                width: 100%;
                ${setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, false)}
                overflow: unset;
                position: sticky;
                top: var(${CSS_VAR_TOP_BAR_HEIGHT});
                display: block;
            `}
    }
`;
StyledGalleryWrapper.displayName = 'StyledGalleryWrapper';

export function HighlightAccordionItem(
    props: HighlightAccordionItemProps
): JSX.Element | null {
    const {
        anchorId,
        prevAnchorId,
        nextAnchorId,
        feature,
        path,
        isActive,
        gallery
    } = props;

    const accordionItem: React.RefObject<HTMLButtonElement> = React.useRef(
        null
    );

    const onItemClick = () => {
        const {handleItemClick, itemIdx} = props;
        const accordionItemTitleText =
            accordionItem &&
            accordionItem.current &&
            accordionItem.current.innerText &&
            accordionItem.current.innerText.trim();
        const accordionItemTitle = accordionItemTitleText
            ? accordionItemTitleText
            : undefined;
        handleItemClick(itemIdx, accordionItemTitle, accordionItem);
    };

    const title = (
        <Text bold>
            <RichtextSimpleElement path={`${path}/title`} />
        </Text>
    );

    const heading = <HeadingElement path={`${path}/heading`} order="H2" />;

    const alertBox = feature.validationError && C.isInEditor() && (
        <AlertBox>
            {feature.validationMessages.map((msg, idx) => (
                <span key={idx}>{msg}</span>
            ))}
        </AlertBox>
    );

    if (feature.validationError && !C.isInEditor()) {
        return null;
    }

    const copyItems = getChildItems(feature.cqItems[COPY_PARSYS]);

    const enableDesktopLayout = !C.isInEditor();

    return (
        <>
            {alertBox}
            <Accordion
                id={anchorId}
                prevId={prevAnchorId}
                nextId={nextAnchorId}
                isOpen={C.isInEditor() || isActive}
                headline={title}
                onClick={onItemClick}
                hideHeadIcon
                showAccordionBorder
                hideHeadBorder
                expandedItemDisabled
                accordionItemRef={accordionItem}
            >
                {heading}
                <StyledStickyWrapper
                    enableDesktopLayout={enableDesktopLayout}
                    isActive={isActive}
                >
                    <StyledGalleryWrapper
                        enableDesktopLayout={enableDesktopLayout}
                    >
                        {gallery}
                    </StyledGalleryWrapper>
                </StyledStickyWrapper>

                <StyledContentWrapper>
                    {copyItems.map((child: ContainerItem) => (
                        <StyledCopyItem key={child.key}>
                            <PersonalizationInclude
                                path={`${path}/${COPY_PARSYS}/${child.key}`}
                                resourceType={child.model.cqType}
                            />
                        </StyledCopyItem>
                    ))}
                </StyledContentWrapper>
            </Accordion>
        </>
    );
}
