import * as React from 'react';

import {RichtextFullElementModel} from '../../../../generated/core';
import {
    Richtext,
    RichtextExtraProps,
    RichtextModuleProps
} from '../../../components/Richtext';
import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

export type RichtextElementProps = RichtextFullElementModel &
    RichtextModuleProps;

export class RichtextElement extends React.PureComponent<RichtextElementProps> {
    public render(): JSX.Element {
        const {hideItemDisclaimers} = this.props;
        const richtext = <Richtext {...this.props} />;

        return hideItemDisclaimers ? (
            richtext
        ) : (
            <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
                {richtext}
                <BellowItemDisclaimerContainer
                    displayTypes={['T4_ITEM_BASED']}
                />
            </GeneralDisclaimerProvider>
        );
    }
}

export const RichtextFullElement = MapTo<RichtextExtraProps>(
    'vwa-ngw18/components/editorial/elements/richtextFullElement',
    RichtextElement
);

export const RichtextSimpleElement = MapTo<RichtextExtraProps>(
    'vwa-ngw18/components/editorial/elements/richtextSimpleElement',
    RichtextElement
);
