import * as React from 'react';

import {
    css,
    DecorativeImageProps,
    ImageProps,
    styled
} from '@volkswagen-onehub/components-core';

import {
    FocalPoint,
    horizontalPercentages,
    verticalPercentages
} from '../focal-point';

export interface ImageWithFocalPointProps {
    focalPoint: FocalPoint;
    children: React.ReactElement<DecorativeImageProps | ImageProps>;
    responsiveFocalPoints?: {mediaQuery: string; focalPoint: FocalPoint}[];
}

const getFocalPoint = (focalPoint: FocalPoint) =>
    css`
        object-position: ${horizontalPercentages[focalPoint.x]}%
            ${verticalPercentages[focalPoint.y]}%;
    `;

export const getResponsiveFocalPoints = (
    responsiveFocalPoints?: {mediaQuery: string; focalPoint: FocalPoint}[]
) => {
    if (!responsiveFocalPoints) {
        return;
    }

    return responsiveFocalPoints.map(
        entry => css`
            @media (${entry.mediaQuery}) {
                ${getFocalPoint(entry.focalPoint)}
            }
        `
    );
};

const StyledImageWithFocalPoint = styled.div<ImageWithFocalPointProps>`
    width: 100%;
    height: 100%;

    & img {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        ${props => getFocalPoint(props.focalPoint)}
        ${props => getResponsiveFocalPoints(props.responsiveFocalPoints)};
    }
`;

StyledImageWithFocalPoint.displayName = 'StyledImageWithFocalPoint';

export const ImageWithFocalPoint = (props: ImageWithFocalPointProps) => (
    <StyledImageWithFocalPoint {...props} />
);

ImageWithFocalPoint.displayName = 'ImageWithFocalPoint';
