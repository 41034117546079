import * as React from 'react';
import {AnimationElementModel} from '../../../../../generated/core';
import {ResponsiveMediaRendererConf} from '../../../../components/ResponsiveMediaRenderer';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {MediaDisclaimerHolder} from '../MediaElement';
import {videoElementPropsEqual} from '../videoElement/helpers';
import {VideoElementAuthor} from '../videoElement/VideoElementAuthor';
import {AnimationElementPublish} from './AnimationElementPublish';
import {SchemaVideoMicrodata} from '../videoElement/SchemaVideoMicrodata';
import {CountdownState} from '../../../../context/tracking/TrackingService';

const ANIMATION_ELEMENT_LABEL = 'Animation Element';

export type AnimationElementExtraProps = MediaDisclaimerHolder &
    Readonly<{
        onCoverImageLoad?: () => void;
        matchParent?: boolean;
        responsiveMediaConfig?: ResponsiveMediaRendererConf[];
        actsAsTeaser?: boolean;
        countdownState?: CountdownState;
    }>;

export type AnimationElementProps = AnimationElementModel &
    AnimationElementExtraProps;

function InternalAnimationElement(
    props: AnimationElementProps
): JSX.Element | null {
    if (C.isInEditor()) {
        return (
            <VideoElementAuthor
                {...props}
                authoringLabel={ANIMATION_ELEMENT_LABEL}
            />
        );
    }

    if (!C.isInEditor() && !props.fileReference) {
        return null;
    }

    return (
        <>
            <SchemaVideoMicrodata {...props.schemaVideoModel} />
            <AnimationElementPublish {...props} />
        </>
    );
}

const MemoizedAnimationElement = React.memo(
    InternalAnimationElement,
    videoElementPropsEqual
);

export const AnimationElement = MapTo<AnimationElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/animationElement',
    MemoizedAnimationElement
);
