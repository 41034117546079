import {Config} from '../context/config/Config';
import {Logger} from '../context/logger/Logger';
import {VwaD6Global} from '../types/global';
import {isInBrowser} from './browser/isInBrowser';

export const GLOBAL_NAMESPACE = 'vwa_d6_cms';

export function getGlobal(): VwaD6Global {
    if (isInBrowser()) {
        return window[GLOBAL_NAMESPACE];
    }

    return global[GLOBAL_NAMESPACE];
}

const createGlobal = (config: Config, log: Logger): Partial<VwaD6Global> => {
    return {
        config,
        log,
        disableLsgLog(): void {
            window.sessionStorage.setItem(
                'disableComponentsCoreLogging',
                'true'
            );
        }
    };
};

export const initGlobal = (config: Config, log: Logger) => {
    if (isInBrowser()) {
        window[GLOBAL_NAMESPACE] = {
            ...createGlobal(config, log),
            ...window[GLOBAL_NAMESPACE]
        };
    }

    global[GLOBAL_NAMESPACE] = {
        ...createGlobal(config, log),
        ...global[GLOBAL_NAMESPACE]
    };
};
