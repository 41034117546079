import * as React from 'react';

import {NavigationLinkModel} from '../../../../generated/api';
import {FooterCategoryModel} from '../../../../generated/core';
import {FooterLink} from '../../../components/FooterLink';
import {
    DisclaimerWithReference,
    GroupedDisclaimersWithReference,
    RegisteredDisclaimer
} from '../../../context/disclaimer/DisclaimerStore';
import {
    useContentStore,
    useDisclaimerStoreContext,
    useGlobalDisclaimerStore,
    useIntegratorTemplateModel,
    useLayerContext
} from '../../../context';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {DisclaimersWithSafewords} from '../../../components/disclaimers/DisclaimersWithSafewords';
import {BottomNav, DisclaimerGroup} from '../../../d6/components/footer';
import {FooterMainNavList} from '../../../d6/components/footer-main-nav-list';

export function prepareFooterDisclaimers(
    disclaimers: DisclaimerWithReference[],
    hasGroupTitle?: boolean
): JSX.Element {
    return (
        <DisclaimersWithSafewords
            disclaimers={disclaimers}
            withoutLabeledSection={hasGroupTitle}
        />
    );
}

export function compareDisclaimers(
    a: DisclaimerWithReference,
    b: DisclaimerWithReference
): number {
    let result = compare(a.reference || undefined, b.reference || undefined);
    if (result === 0) {
        result = compare(
            (a.namedReference?.length && a.namedReference[0]) || undefined,
            (b.namedReference?.length && b.namedReference[0]) || undefined
        );
    }
    return result;
}

function compare(a: string | undefined, b: string | undefined): number {
    let intA = Number(a);
    let intB = Number(b);
    if (!isNaN(intA) && !isNaN(intB)) {
        return intA - intB;
    } else if (typeof a !== 'undefined' && typeof b !== 'undefined') {
        return a.localeCompare(b);
    } else if (typeof a !== 'undefined') {
        return 1;
    } else if (typeof b !== 'undefined') {
        return -1;
    }
    return 0;
}

export function comparePageBasedDisclaimers(
    scopedDisclaimers: DisclaimerWithReference[],
    pageDisclaimers: RegisteredDisclaimer[]
): (RegisteredDisclaimer | DisclaimerWithReference)[] {
    const pageDisclaimersToRender: RegisteredDisclaimer[] = [];
    const scopedDisclaimersMap = new Map();

    for (let scopedDisclaimer of scopedDisclaimers) {
        scopedDisclaimersMap.set(
            scopedDisclaimer.reference || scopedDisclaimer.namedReference,
            scopedDisclaimer
        );
    }

    pageDisclaimers.forEach(item => {
        if (
            scopedDisclaimersMap.has(item.reference) &&
            scopedDisclaimersMap.get(item.reference).text === item.text
        ) {
            pageDisclaimersToRender.push(item);
            scopedDisclaimersMap.delete(item.reference);
        }
    });

    return [
        ...pageDisclaimersToRender,
        ...(Array.from(
            scopedDisclaimersMap,
            ([_key, value]) => value
        ) as DisclaimerWithReference[])
    ];
}

export function compareDisclaimerGroups(
    a: GroupedDisclaimersWithReference,
    b: GroupedDisclaimersWithReference,
    firstLegalEntity?: string
): number {
    if (!a.legalEntityLabel && !b.legalEntityLabel) {
        return 0;
    }

    if (!a.legalEntityLabel) {
        return -1;
    }

    if (!b.legalEntityLabel) {
        return 1;
    }

    if (firstLegalEntity) {
        if (a.legalEntity === firstLegalEntity) {
            return 1;
        }
        if (b.legalEntity === firstLegalEntity) {
            return -1;
        }
    }

    return a.legalEntityLabel.localeCompare(b.legalEntityLabel);
}

interface UseFooterDisclaimersProps {
    pageDisclaimers: RegisteredDisclaimer[];
}

export function useFooterDisclaimers(
    props: UseFooterDisclaimersProps
): JSX.Element | null {
    const contentStore = useContentStore();
    const integratorTemplateModel = useIntegratorTemplateModel();

    // extract all disclaimer stores and layer info
    const globalDisclaimerStore = useGlobalDisclaimerStore();
    const scopedDisclaimerStoreContext = useDisclaimerStoreContext();
    const scopedDisclaimerStore = scopedDisclaimerStoreContext.value;
    const layerContext = useLayerContext();

    let disclaimerGroups =
        layerContext.layerType && scopedDisclaimerStore
            ? scopedDisclaimerStore.footerDisclaimers
            : globalDisclaimerStore.footerDisclaimers;
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const firstLegalEntity = pageRootModel
        ? pageRootModel.mainLegalEntity
        : undefined;

    // slice used to supress mobx warning
    disclaimerGroups = disclaimerGroups
        .slice()
        .sort((a, b) => compareDisclaimerGroups(a, b, firstLegalEntity))
        .reverse();

    if (
        integratorTemplateModel?.hideGlobalDisclaimers ||
        pageRootModel?.togglesModel.hideGlobalDisclaimers
    ) {
        disclaimerGroups = disclaimerGroups
            .filter(
                group =>
                    Array.isArray(group.referenceDisclaimers) &&
                    group.referenceDisclaimers.length
            )
            .map(group => {
                return {
                    legalEntityLabel: group.legalEntityLabel,
                    globalDisclaimers: [],
                    referenceDisclaimers: group.referenceDisclaimers
                };
            });
    }

    const groups = disclaimerGroups
        .filter(group => group.globalDisclaimers || group.referenceDisclaimers)
        .map((group, index) => (
            <DisclaimerGroup
                key={group.legalEntityLabel || index}
                label={group.legalEntityLabel || ''}
                globalDisclaimers={prepareFooterDisclaimers(
                    group.globalDisclaimers,
                    Boolean(group.legalEntityLabel)
                )}
                referenceDisclaimers={prepareFooterDisclaimers(
                    comparePageBasedDisclaimers(
                        group.referenceDisclaimers,
                        props.pageDisclaimers
                    ),
                    Boolean(group.legalEntityLabel)
                )}
            />
        ));

    if (!groups) {
        return null;
    }

    return (
        <CyAttributeAppender name="footerDisclaimers">
            <>{groups}</>
        </CyAttributeAppender>
    );
}

export function prepareMainNav(
    categoryItems: FooterCategoryModel[]
): JSX.Element[] {
    return categoryItems.map(
        (item, index): JSX.Element => {
            const nbsp = '\u00A0';

            return prepareCategoryNav(
                item.category ? item.category : nbsp,
                item.links,
                `col-${index}`,
                item.hideHeadline
            );
        }
    );
}

export function prepareLinkItem(
    link: NavigationLinkModel,
    key?: string | number
): JSX.Element {
    return <FooterLink key={key} link={link} />;
}

export function prepareCategoryNav(
    category: string,
    items: NavigationLinkModel[],
    categoryIdx: number | string,
    hideHeadline: boolean
): JSX.Element {
    return (
        <FooterMainNavList
            key={categoryIdx}
            headline={hideHeadline ? '' : category}
            mainNavAriaLabel={category}
        >
            {items.map(prepareLinkItem)}
        </FooterMainNavList>
    );
}

export function prepareBottomNav(
    items: NavigationLinkModel[],
    bottomNavAriaLabel: string,
    copyright?: string,
    marketLogo?: JSX.Element,
    langSwitcher?: JSX.Element
): JSX.Element {
    const linkItems = items ? items.map(prepareLinkItem) : null;

    return (
        <BottomNav
            copyright={copyright}
            marketLogoMedia={marketLogo}
            langSwitcher={langSwitcher}
            bottomNavAriaLabel={bottomNavAriaLabel}
        >
            {linkItems}
        </BottomNav>
    );
}
