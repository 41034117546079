import React from 'react';

export function isInUserAgent(matchString: string): boolean {
    const userAgentData = window.navigator.userAgent;

    return userAgentData.toLowerCase().includes(matchString.toLowerCase());
}

export function useIsInUserAgent(matchString: string): boolean {
    const [_isInUserAgent, setIsInUserAgent] = React.useState(false);

    React.useEffect(() => {
        if (matchString) {
            setIsInUserAgent(isInUserAgent(matchString));
        }
    }, [matchString]);

    return _isInUserAgent;
}
