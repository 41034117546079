import {
    Text,
    TokenTextAppearance,
    TextTag,
    ThemeProvider,
    styled
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {CSSTransition} from 'react-transition-group';
import {css} from 'styled-components';

import {
    getElectricTransitionAdvanced,
    getStartDirection,
    isIOS
} from '../helpers';
import {StyledAspectRatioContainer} from '../helpers/aspect-ratio-container';
import {styledCompatRef} from '../helpers/styled-compat-ref';
import {
    INIT_VOLUME_VALUE,
    MIN_VOLUME_VALUE
} from '../video-player-helpers/constants';
import {ArrowKeys} from '../video-player-helpers/types';
import {
    HorizontalProgressBar,
    VideoPlayerProgressBarSelectedValue
} from '../video-player-progress-bar';
import {SoundControls} from '../video-player-sound-controls';
import {formatTime, VideoPlayerTime} from '../video-player-time';
import {
    BigPlayButton,
    ToggleFullscreenButton,
    TogglePlayButton,
    TranscriptDownloadButton
} from './buttons';
import {
    APPEAR_ANIM_DURATION,
    TOUCH_START_VS_END_MAX_PIXEL_DIFF
} from './constants';
import {
    calcVideoAspectRatio,
    enterFullscreen,
    exitFullscreen,
    fullScreenChangeEventName,
    fullScreenElementName,
    fullscreenEnabled,
    getMaxDuration,
    getTheme,
    noop,
    sanitizeVolume,
    volumeFromMuted
} from './helpers';
import {
    VideoPlayerV2AspectRatio,
    VideoPlayerV2CalculatedAspectRatio,
    VideoPlayerV2DisclaimerProps,
    VideoPlayerV2DisplayMode,
    VideoPlayerV2PlayState
} from './types';
import {
    VideoComponent,
    VideoComponentFactoryProps
} from '../../../components/VideoComponent';

export type VideoPlayerV2Props = Readonly<{
    aspectRatio?: VideoPlayerV2AspectRatio;
    videoComponentFactoryProps: VideoComponentFactoryProps;
    autoPlay?: boolean;
    startUnmuted?: boolean;
    showTimeline?: boolean;
    showFullscreenBtn?: boolean;
    showSoundControls?: boolean;
    forceOriginalAspectRatio?: boolean;
    title?: string;
    pauseButtonTitle?: string;
    playButtonTitle?: string;
    soundControlTitle?: string;
    disableFullScreenButtonTitle?: string;
    enableFullScreenButtonTitle?: string;
    timeProgressBarAriaLabel?: string;
    soundProgressBarAriaLabel?: string;
    downloadTranscriptButtonTitle?: string;
    transcriptFileReference?: string;
    disclaimer?: VideoPlayerV2DisclaimerProps;
    actsAsTeaser?: boolean;

    onStateChanged?(state: VideoPlayerV2State, startedByIO: boolean): void;
    onFullScreenEntered?(): void;
    onFullScreenExited?(): void;
    onPlayClick?(): void;
    onPauseClick?(): void;
    onMute?(): void;
    onUnmute?(): void;
}>;

export type VideoPlayerV2State = Readonly<{
    videoState: VideoPlayerV2PlayState;
    currentTime: number;
    timelineDragInProgress: boolean;
    volumeDragInProgress: boolean;
    showOverlay: boolean;
    displayMode: VideoPlayerV2DisplayMode;
    duration?: number;
    volume?: number;
    isMuted?: boolean;
    initialPlayByIO?: boolean;
    loadedData?: boolean;
}>;

type OverlayProps = Readonly<{
    isTimelineVisible?: boolean;
    isVisible?: boolean;
}>;

interface OverlayBackgroundProps {
    readonly isTimelineVisible?: boolean;
}

interface DisclaimerWrapperProps {
    readonly isToolbarVisible?: boolean;
}

interface StyledVideoPlayerProps {
    readonly aspectRatio?: VideoPlayerV2CalculatedAspectRatio;
}

interface StyledVideoPlayerInnerProps {
    readonly aspectRatio?: VideoPlayerV2CalculatedAspectRatio;
    readonly videoElementHeight?: number;
    readonly isInFullScreen: boolean;
}

const TOOLBAR_HEIGHT = 60;
const OVERLAY_VISIBILITY_DURATION = 2000; // time in milliseconds

const StyledOverlayBackground = styled.div<OverlayBackgroundProps>`
    ${props =>
        props.isTimelineVisible &&
        `
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: ${TOOLBAR_HEIGHT}px;
		background: rgba(0, 0, 0, 0.55);
		pointer-events: none;
	`};
`;
StyledOverlayBackground.displayName = 'StyledOverlayBackground';

const StyledToolbar = styled.div<OverlayProps>`
    position: absolute;
    bottom: 0;
    direction: ltr;

    width: 100%;
    height: ${TOOLBAR_HEIGHT}px;

    display: flex;
    align-items: center;

    box-sizing: border-box;
    padding: 0 ${props => props.theme.size.static350};

    ${props =>
        !props.isTimelineVisible &&
        `
		width: auto;
		height: ${props.theme.size.static450};
		bottom: 9px;
		left: 9px;
		background: rgba(0, 0, 0, 0.55);
		border-radius: 500px;
		padding: 0 ${props.theme.size.static150};
	`};
`;
StyledToolbar.displayName = 'StyledToolbar';

const StyledToolbarItem = styled.div`
    :not(:last-child) {
        margin-right: ${props => props.theme.size.static250};
    }
`;
StyledToolbarItem.displayName = 'StyledToolbarItem';

const StyledVideoDisclaimerReferences = styled.span<DisclaimerWrapperProps>`
	position: absolute;
	${props => getStartDirection(props.theme.direction)}: 0;
	bottom: ${props =>
        props.isToolbarVisible
            ? `calc((${TOOLBAR_HEIGHT}px + ${props.theme.size.static350}))`
            : props.theme.size.static250};
	transition: ${getElectricTransitionAdvanced({name: 'bottom'})};
	margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static350};
	display: flex;
	justify-content: flex-start;
	min-width: ${TOOLBAR_HEIGHT}px;
`;
StyledVideoDisclaimerReferences.displayName = 'StyledVideoDisclaimerReferences';

const fadeClassName = 'fadeClassName';

const StyledOverlayContainer = styled.div<OverlayProps>`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;
StyledOverlayContainer.displayName = 'StyledOverlayContainer';

const StyledOverlayWithToolbar = styled(StyledOverlayContainer)`
    opacity: 0;
    transition: ${getElectricTransitionAdvanced({name: 'opacity'})};

    &.${fadeClassName}-enter {
        opacity: 0;
    }

    &.${fadeClassName}-enter-done {
        opacity: 1;
    }

    &.${fadeClassName}-exit {
        opacity: 0;
    }

    &.${fadeClassName}-exit-done {
        opacity: 0;
    }
`;
StyledOverlayWithToolbar.displayName = 'StyledOverlayWithToolbar';

const StyledOverlayContainerCentered = styled(StyledOverlayContainer)`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
`;
StyledOverlayContainerCentered.displayName = 'StyledOverlayContainerCentered';

const fullscreen = css`
    display: flex;
    justify-content: center;
    align-items: center;

    // NOTE: fixes desktop Safari fullscreen
    width: 100%;
    height: 100%;

    video {
        width: 100%;
    }
`;

// fullscreen pseudo classes can't be chained with commas
// NOTE: background is "black" due to none available video fullscreen color in core
const StyledVideoPlayer = styled.div<StyledVideoPlayerProps>`
    overflow: hidden;
    background: black;
    ${props => props.aspectRatio === 'matchParentHeight' && 'height: 100%'};
    ${props => props.aspectRatio === 'matchParentWidth' && 'width: 100%'};

    &:fullscreen {
        ${fullscreen}
    }

    :-webkit-full-screen {
        ${fullscreen}
    }

    :-moz-full-screen {
        ${fullscreen}
    }

    :-ms-fullscreen {
        ${fullscreen}
    }
`;
StyledVideoPlayer.displayName = 'StyledVideoPlayer';

const StyledVideoPlayerInner = styled.div<StyledVideoPlayerInnerProps>`
    position: ${props =>
        props.isInFullScreen &&
        props.videoElementHeight &&
        props.videoElementHeight > window.innerHeight
            ? ''
            : 'relative'};
    flex: 1;
    ${props => props.aspectRatio === 'matchParentHeight' && 'height: 100%'};
    ${props => props.aspectRatio === 'matchParentWidth' && 'width: 100%'};
    video {
        display: block;
        max-height: 100%;
        max-width: 100%;
        pointer-events: none;

        &::cue {
            font-size: ${props => props.theme.fonts.bigcopy[100].fontSize};
            line-height: ${props => props.theme.fonts.bigcopy[100].lineHeight};
            color: ${props => props.theme.colors.content.primary};
            opacity: 1;
            background-color: ${props => props.theme.colors.background.primary};
        }
    }
`;
StyledVideoPlayerInner.displayName = 'StyledVideoPlayerInner';

export class VideoPlayerV2 extends React.PureComponent<
    VideoPlayerV2Props,
    VideoPlayerV2State
> {
    private readonly videoPlayer: React.RefObject<HTMLVideoElement>;
    private readonly selfRef: React.RefObject<HTMLDivElement>;
    private readonly overlayRef: React.RefObject<HTMLDivElement>;
    private readonly actsAsTeaser: boolean = false;

    // Following variables are used for preserving the state between different
    // event renders. They don't need to be part of the component state and cause
    // re-render. That's why they are created as instance variables and readonly
    // check needs to be disabled.
    private videoStateBeforeDrag?: VideoPlayerV2PlayState;
    private nextHandleOverlayClickedDisabled: boolean = false;
    private startedByIO: boolean = false;

    private overlayTimeoutId?: number;
    private registeredTouches?: React.TouchEvent<HTMLDivElement>;
    private overlayTouchY?: number;

    public constructor(props: VideoPlayerV2Props) {
        super(props);
        this.state = {
            videoState: VideoPlayerV2PlayState.NOT_STARTED,
            currentTime: 0,
            duration: undefined,
            timelineDragInProgress: false,
            volumeDragInProgress: false,
            showOverlay: Boolean(props.actsAsTeaser),
            displayMode: VideoPlayerV2DisplayMode.NORMAL,
            isMuted: true,
            initialPlayByIO: false,
            loadedData: false
        };
        this.videoPlayer = React.createRef();
        this.selfRef = React.createRef();
        this.overlayRef = React.createRef();
        this.actsAsTeaser = Boolean(props.actsAsTeaser);
    }

    public static getDerivedStateFromProps(
        props: VideoPlayerV2Props,
        state: VideoPlayerV2State
    ): VideoPlayerV2State | null {
        if (
            state.videoState !== VideoPlayerV2PlayState.NOT_STARTED ||
            props.startUnmuted !== state.isMuted
        ) {
            return null;
        }

        return {
            ...state,
            volume: props.startUnmuted ? INIT_VOLUME_VALUE : MIN_VOLUME_VALUE,
            isMuted: !props.startUnmuted
        };
    }

    private readonly fireStateChanged = () => {
        const {onStateChanged} = this.props;
        if (onStateChanged) {
            onStateChanged({...this.state}, this.startedByIO);
        }
    };

    private readonly handleVideoPlay = () => {
        const {videoState} = this.state;
        const canPlay =
            videoState === VideoPlayerV2PlayState.NOT_STARTED ||
            videoState === VideoPlayerV2PlayState.PAUSED ||
            videoState === VideoPlayerV2PlayState.ENDED;
        if (canPlay) {
            this.setState({
                videoState: VideoPlayerV2PlayState.PLAYING
            });
        }
    };

    private readonly handleVideoPause = () => {
        const {videoState} = this.state;
        if (videoState === VideoPlayerV2PlayState.PLAYING) {
            this.setState({
                videoState: VideoPlayerV2PlayState.PAUSED
            });
        }
    };

    private readonly handleVideoEnded = () => {
        const {videoState} = this.state;
        if (
            videoState === VideoPlayerV2PlayState.PLAYING ||
            videoState === VideoPlayerV2PlayState.PAUSED
        ) {
            this.setState({
                videoState: VideoPlayerV2PlayState.ENDED
            });
        }
    };

    private readonly handleVideoTimeUpdateEvent = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            const {duration, currentTime = 0} = player;
            this.setState({
                duration,
                currentTime
            });
        }
    };

    private readonly handleVideoDurationChange = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            const {duration} = player;
            this.setState({
                duration
            });
        }
    };

    private readonly handleTimelineDragStarted = () => {
        this.setState({
            timelineDragInProgress: true
        });
        if (this.videoPlayer) {
            this.videoStateBeforeDrag = this.state.videoState;
            this.pause();
        }
    };

    private readonly handleTimelineDrag = (
        data: VideoPlayerProgressBarSelectedValue
    ) => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            player.currentTime = data.value;
        }
    };

    private readonly handleTimelineDragEnded = () => {
        this.setState({
            timelineDragInProgress: false
        });
        this.nextHandleOverlayClickedDisabled = true;
        const player = this.videoPlayer.current;
        if (
            player &&
            this.isVideoElement(player) &&
            player.currentTime < player.duration &&
            this.videoStateBeforeDrag === VideoPlayerV2PlayState.PLAYING
        ) {
            this.play();
        }
        this.videoStateBeforeDrag = undefined;
    };

    private readonly handleVolumeDragStarted = () => {
        this.setState({
            volumeDragInProgress: true
        });
    };

    private readonly handleVolumeDragEnded = () => {
        window.clearTimeout(this.overlayTimeoutId);
        this.nextHandleOverlayClickedDisabled = true;
        this.setState({
            volumeDragInProgress: false
        });
        // hide with delay
        this.resetOverlayTimeout();
    };

    private readonly handleOverlayClicked = (
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        if (this.actsAsTeaser) {
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        const {timelineDragInProgress, volumeDragInProgress} = this.state;
        if (
            !timelineDragInProgress &&
            !volumeDragInProgress &&
            !this.nextHandleOverlayClickedDisabled &&
            this.videoPlayer
        ) {
            if (this.state.videoState === VideoPlayerV2PlayState.PLAYING) {
                const {onPauseClick = noop} = this.props;
                this.pause();
                onPauseClick();
            } else {
                const {onPlayClick = noop} = this.props;
                this.play();
                onPlayClick();
            }
        }
        this.nextHandleOverlayClickedDisabled = false;
    };

    private readonly invertOverlayVisibility = () => {
        // used when tapping or mouse movement occurs
        window.clearTimeout(this.overlayTimeoutId);
        const shouldBeVisible = !this.state.showOverlay;
        this.registeredTouches = undefined;

        this.setState({
            showOverlay: shouldBeVisible
        });

        if (shouldBeVisible) {
            this.resetOverlayTimeout();
        }
    };

    private readonly resetOverlayTimeout = () => {
        if (this.actsAsTeaser) {
            return;
        }
        window.clearTimeout(this.overlayTimeoutId);
        this.overlayTimeoutId = window.setTimeout(
            this.hideOverlay,
            OVERLAY_VISIBILITY_DURATION
        );
    };

    private readonly hideOverlay = () => {
        const {volumeDragInProgress, timelineDragInProgress} = this.state;
        if (!volumeDragInProgress && !timelineDragInProgress) {
            this.setState({
                showOverlay: false
            });
        } else {
            // try again later
            this.resetOverlayTimeout();
        }
    };

    private readonly handleMouseMove = () => {
        const {videoState} = this.state;

        if (
            videoState === VideoPlayerV2PlayState.PLAYING ||
            (videoState === VideoPlayerV2PlayState.NOT_STARTED &&
                !this.props.showTimeline)
        ) {
            // Clear previous timeout for hiding overlay and set new one
            window.clearTimeout(this.overlayTimeoutId);
            this.setState({
                showOverlay: true
            });
            this.resetOverlayTimeout();
        }
    };

    private readonly handleTapStart = (e: React.TouchEvent<HTMLDivElement>) => {
        this.registeredTouches = {...e};
    };

    private readonly handleTapEnd = (
        e: React.TouchEvent<HTMLDivElement | HTMLButtonElement>
    ) => {
        const touchStartItem = this.registeredTouches
            ? this.registeredTouches.changedTouches.item(0)
            : null;
        const touchEndItem = e.changedTouches.item(0);
        if (
            touchStartItem &&
            Math.abs(touchStartItem.clientX - touchEndItem.clientX) <
                TOUCH_START_VS_END_MAX_PIXEL_DIFF &&
            Math.abs(touchStartItem.clientY - touchEndItem.clientY) <
                TOUCH_START_VS_END_MAX_PIXEL_DIFF &&
            !(e.target instanceof HTMLButtonElement)
        ) {
            if (this.actsAsTeaser) {
                return;
            }
            // prevent mouse click event on some devices
            e.preventDefault();
            e.stopPropagation();
            // perform click
            this.invertOverlayVisibility();
        }
    };

    private readonly handleFinalStateOverlayTouchStart = (
        e: React.TouchEvent<HTMLDivElement>
    ) => {
        if (e.touches.length > 0) {
            const touch = e.touches.item(0);
            this.overlayTouchY = touch.pageY;
        }
    };

    private readonly handleFinalStateOverlayTouchEnd = (
        e: React.TouchEvent<HTMLDivElement>
    ) => {
        if (
            !this.state.timelineDragInProgress &&
            this.overlayTouchY &&
            e.changedTouches.length > 0
        ) {
            const touch = e.changedTouches.item(0);
            const deltaY = Math.abs(touch.pageY - this.overlayTouchY);
            if (deltaY < TOUCH_START_VS_END_MAX_PIXEL_DIFF) {
                // prevent mouse click event on some devices
                e.preventDefault();
                e.stopPropagation();
                // perform the same action as click
                this.handlePlayBtnClick();
            }
        }
        this.overlayTouchY = undefined;
    };

    private readonly fullscreenEventListener = () => {
        if (document[fullScreenElementName()] === this.selfRef.current) {
            const {onFullScreenEntered = noop} = this.props;
            this.setState({
                displayMode: VideoPlayerV2DisplayMode.FULLSCREEN
            });
            onFullScreenEntered();
        } else {
            const {onFullScreenExited = noop} = this.props;
            this.setState({
                displayMode: VideoPlayerV2DisplayMode.NORMAL
            });
            onFullScreenExited();
        }
    };

    private readonly enterFullscreenIOSEventListener = () => {
        const {onFullScreenEntered = noop} = this.props;
        this.setState({
            displayMode: VideoPlayerV2DisplayMode.FULLSCREEN
        });

        onFullScreenEntered();
    };

    private readonly exitFullscreenIOSEventListener = () => {
        const {onFullScreenExited = noop} = this.props;
        this.setState({
            displayMode: VideoPlayerV2DisplayMode.NORMAL
        });

        onFullScreenExited();
    };

    private readonly registerLoadeddataListener = () => {
        const player = this.videoPlayer.current;
        player?.addEventListener('loadeddata', this.playByIOFromListener, true);

        this.setState({initialPlayByIO: true});
    };

    private readonly removeLoadeddataListeners = () => {
        const player = this.videoPlayer.current;
        player?.removeEventListener(
            'loadeddata',
            this.playByIOFromListener,
            true
        );
    };

    private readonly registerFullScreenEventListener = () => {
        const self = this.selfRef.current;
        const {showFullscreenBtn} = this.props;
        if (self instanceof HTMLDivElement && showFullscreenBtn) {
            const eventName = fullScreenChangeEventName();
            // in Mozzila this needs to be set on document only
            if (eventName === 'mozfullscreenchange') {
                document.addEventListener(
                    eventName,
                    this.fullscreenEventListener
                );
            } else if (isIOS() && this.videoPlayer.current) {
                const player = this.videoPlayer.current;
                if (player) {
                    player.addEventListener(
                        'webkitbeginfullscreen',
                        this.enterFullscreenIOSEventListener
                    );
                    player.addEventListener(
                        'webkitendfullscreen',
                        this.exitFullscreenIOSEventListener
                    );
                }
            } else {
                self.addEventListener(eventName, this.fullscreenEventListener);
            }
        }
    };

    private readonly removeFullScreenEventListener = () => {
        const self = this.selfRef.current;
        if (self instanceof HTMLDivElement) {
            const eventName = fullScreenChangeEventName();
            // in Mozzila this needs to be set on document only
            if (eventName === 'mozfullscreenchange') {
                document.removeEventListener(
                    eventName,
                    this.fullscreenEventListener
                );
            } else {
                self.removeEventListener(
                    eventName,
                    this.fullscreenEventListener
                );
            }
        }
    };

    // iOS doesn't support setting volume via JS it's possible only to toggle between muted and unmuted state
    // and volume value from player is always returned as 1
    private readonly handleVolumeChange = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            const muted = player.muted;
            this.setState({
                isMuted: isIOS() ? muted : player.volume === 0,
                volume: isIOS() ? volumeFromMuted(muted) : player.volume
            });
            this.resetOverlayTimeout();
        }
    };

    private readonly retainFocusOnVideoElement = () => {
        const videoWrapper = this.selfRef.current;
        (videoWrapper as HTMLElement).focus();
    };

    private readonly handlePlayBtnClick = () => {
        const {onPlayClick = noop} = this.props;
        this.play();
        onPlayClick();
        this.resetOverlayTimeout();
        this.retainFocusOnVideoElement();
    };

    private readonly handlePauseBtnClick = () => {
        const {onPauseClick = noop} = this.props;
        this.pause();
        onPauseClick();
        this.resetOverlayTimeout();
    };

    private readonly getVideoElementHeight = () => {
        const player = this.videoPlayer.current;
        // we want to avoid calling this.isVideoElement on server-side, otherwise it crashes on ReferenceError: HTMLVideoElement is not defined
        if (player && this.isVideoElement(player)) {
            return player.getBoundingClientRect().height;
        } else {
            return undefined;
        }
    };

    private readonly isOverlayVisible = () => {
        const {
            videoState,
            timelineDragInProgress,
            volumeDragInProgress,
            showOverlay,
            displayMode
        } = this.state;
        const focusInOverlay = this.overlayRef.current?.contains(
            document.activeElement
        );

        return (
            timelineDragInProgress ||
            volumeDragInProgress ||
            showOverlay ||
            focusInOverlay ||
            videoState === VideoPlayerV2PlayState.PAUSED ||
            (displayMode === VideoPlayerV2DisplayMode.FULLSCREEN &&
                videoState === VideoPlayerV2PlayState.ENDED)
        );
    };

    private readonly isToolbarVisible = () => {
        if (this.actsAsTeaser) {
            return true;
        }

        const {videoState, displayMode} = this.state;
        const isOverlayVisible = this.isOverlayVisible();

        return (
            isOverlayVisible &&
            (videoState !== VideoPlayerV2PlayState.ENDED ||
                displayMode === VideoPlayerV2DisplayMode.FULLSCREEN)
        );
    };

    private readonly shouldDisplayDisclaimers = () => {
        const {displayMode} = this.state;
        const {disclaimer = {}} = this.props;
        const {staticReferences, interactiveReferences} = disclaimer;

        return (
            displayMode === VideoPlayerV2DisplayMode.NORMAL &&
            ((staticReferences && staticReferences.length !== 0) ||
                interactiveReferences)
        );
    };

    private readonly handleProgressBarKeyDown = (
        key: ArrowKeys.ArrowRight | ArrowKeys.ArrowLeft
    ): void => {
        const player = this.videoPlayer.current;
        const DURATION = 1; // seconds
        if (player && this.isVideoElement(player)) {
            player.currentTime +=
                key === ArrowKeys.ArrowRight ? DURATION : -DURATION;
        }
    };

    private readonly handleVolumeBarKeyDown = (
        key: ArrowKeys.ArrowUp | ArrowKeys.ArrowDown
    ): void => {
        const player = this.videoPlayer.current;
        const VOLUME_STEP = 0.1;
        if (player && this.isVideoElement(player)) {
            let newVolume;
            if (player.muted && key === ArrowKeys.ArrowUp) {
                newVolume = INIT_VOLUME_VALUE;
            } else {
                newVolume = sanitizeVolume(
                    player.volume +
                        (key === ArrowKeys.ArrowUp ? VOLUME_STEP : -VOLUME_STEP)
                );
            }
            this.changeVolume(newVolume);
        }
    };

    // This check needs to be done this way because patternplate does not allow a simple "element instanceof HTMLVideoElement" check
    private readonly isVideoElement = (
        element: Element | Text | null
    ): element is HTMLVideoElement => element instanceof HTMLVideoElement;

    public readonly load = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player) && !this.state.loadedData) {
            player.addEventListener(
                'loadeddata',
                () => {
                    this.setState({loadedData: true});
                },
                {once: true}
            );
            player.load();
        }
    };

    public readonly play = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            this.startedByIO = false;
            // tslint:disable-next-line:no-floating-promises
            player.play();
        }
    };

    public readonly playByIOFromListener = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            this.startedByIO = true;
            // tslint:disable-next-line:no-floating-promises
            player.play();
        }
    };

    public readonly playByIO = () => {
        const player = this.videoPlayer.current;

        if (this.isVideoElement(player)) {
            this.startedByIO = true;

            if (!this.state.initialPlayByIO && !this.state.loadedData) {
                // Note: added loadeddata listener due to promise error if video hasn't been loaded before the initial play
                this.registerLoadeddataListener();
            } else {
                player.play();
            }
        }
    };

    public readonly pause = () => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            player.pause();
        }
    };

    public readonly setTime = (time: number) => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            // duration might be undefined
            // also don't allow jumping right to the end to prevent problems in iOS
            const duration = (player.duration || 0.01) - 0.01;
            player.currentTime = Math.max(0, Math.min(duration, time));
        }
    };

    public readonly getTime = (): number | undefined => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            return player.currentTime;
        }

        return undefined;
    };

    public readonly toggleFullScreen = async () => {
        const player = this.videoPlayer.current;
        const self = this.selfRef.current;
        if (
            isIOS() &&
            this.isVideoElement(player) &&
            player.webkitEnterFullscreen
        ) {
            // in case when on iOS device use for fullscreen native player
            player.webkitEnterFullscreen();
        } else if (self instanceof HTMLDivElement && fullscreenEnabled()) {
            const {displayMode} = this.state;
            if (displayMode === VideoPlayerV2DisplayMode.FULLSCREEN) {
                exitFullscreen();
            } else {
                enterFullscreen(self);
            }
        }
        this.resetOverlayTimeout();
    };

    /**
     * Changes volume of video player
     * @param value - volume level between 0 and 1
     */
    public readonly changeVolume = (value: number) => {
        const player = this.videoPlayer.current;
        if (this.isVideoElement(player)) {
            const volume = sanitizeVolume(value);
            // keep volume and muted state in sync
            player.muted = !volume;
            player.volume = volume;
            const prevVolume = this.state.volume;
            if (volume !== prevVolume) {
                this.handleVolumeChange();

                if (player.muted) {
                    // eslint-disable-next-line no-unused-expressions
                    this.props.onMute?.();
                }

                if (!prevVolume && volume) {
                    // eslint-disable-next-line no-unused-expressions
                    this.props.onUnmute?.();
                }
            }
        }
    };

    public getVideoElement(): React.RefObject<HTMLVideoElement> {
        return this.videoPlayer;
    }

    public componentDidMount(): void {
        this.registerFullScreenEventListener();
    }

    public componentWillUnmount(): void {
        this.removeFullScreenEventListener();
        window.clearTimeout(this.overlayTimeoutId);
        this.removeLoadeddataListeners();
    }

    public componentDidUpdate(
        _prevProps: VideoPlayerV2Props,
        prevState: VideoPlayerV2State
    ): void {
        if (this.state !== prevState) {
            this.fireStateChanged();
        }
    }

    public render(): JSX.Element {
        const {
            autoPlay,
            aspectRatio,
            forceOriginalAspectRatio,
            showTimeline = true,
            showFullscreenBtn = false,
            videoComponentFactoryProps,
            showSoundControls,
            disclaimer = {},
            title,
            pauseButtonTitle,
            playButtonTitle,
            soundControlTitle,
            disableFullScreenButtonTitle,
            enableFullScreenButtonTitle,
            downloadTranscriptButtonTitle,
            transcriptFileReference,
            soundProgressBarAriaLabel,
            timeProgressBarAriaLabel,
            actsAsTeaser
        } = this.props;
        const {
            videoState,
            currentTime,
            duration,
            displayMode,
            volume,
            isMuted
        } = this.state;

        const {
            staticReferences = [],
            interactiveReferences = '',
            color = 'dark'
        } = disclaimer;

        const remainingTimeInSeconds = duration
            ? duration - currentTime
            : undefined;

        const showOverlayWithBigPlayButton = isShowOverlayWithBigButton(
            actsAsTeaser,
            videoState,
            displayMode,
            autoPlay
        );

        const maxDuration = getMaxDuration(duration);

        const isInFullScreen =
            displayMode === VideoPlayerV2DisplayMode.FULLSCREEN;
        const calculatedAspectRatio = calcVideoAspectRatio(
            isInFullScreen,
            aspectRatio
        );

        const isToolbarVisible = this.isToolbarVisible();

        const isVideoPlaying = videoState === VideoPlayerV2PlayState.PLAYING;
        const hasStaticReferences = staticReferences?.length !== 0;

        const horizontalProgressBarValueText = formatTime(currentTime);

        return (
            <StyledVideoPlayer
                ref={styledCompatRef(this.selfRef)}
                aspectRatio={calculatedAspectRatio}
                onMouseMove={this.handleMouseMove}
                onTouchEnd={this.handleTapEnd}
                onTouchStart={this.handleTapStart}
                tabIndex={isVideoPlaying ? 0 : -1}
            >
                <ThemeProvider theme="inverted">
                    <StyledVideoPlayerInner
                        aspectRatio={calculatedAspectRatio}
                        videoElementHeight={this.getVideoElementHeight()}
                        isInFullScreen={isInFullScreen}
                    >
                        <StyledAspectRatioContainer
                            aspectRatio={calculatedAspectRatio}
                        >
                            <VideoComponent
                                // video component must either be HTML video, or use styledCompatRef
                                innerRef={this.videoPlayer}
                                title={title ?? ''}
                                onPlay={this.handleVideoPlay}
                                onPause={this.handleVideoPause}
                                onEnded={this.handleVideoEnded}
                                onTimeUpdate={this.handleVideoTimeUpdateEvent}
                                onLoadedMetadata={
                                    this.handleVideoDurationChange
                                }
                                {...videoComponentFactoryProps}
                                onDurationChange={
                                    this.handleVideoDurationChange
                                }
                                muted={Boolean(isMuted)}
                                controls={false}
                                keepOriginalAspectRatio={
                                    forceOriginalAspectRatio || isInFullScreen
                                }
                            />
                        </StyledAspectRatioContainer>
                        <CSSTransition
                            classNames={fadeClassName}
                            in={isToolbarVisible}
                            appear
                            timeout={APPEAR_ANIM_DURATION}
                        >
                            <StyledOverlayWithToolbar
                                onClick={this.handleOverlayClicked}
                            >
                                <StyledOverlayBackground
                                    isTimelineVisible={showTimeline}
                                />
                                <StyledToolbar
                                    isVisible={isToolbarVisible}
                                    ref={this.overlayRef}
                                    isTimelineVisible={showTimeline}
                                >
                                    <StyledToolbarItem>
                                        <TogglePlayButton
                                            pauseButtonTitle={pauseButtonTitle}
                                            playButtonTitle={playButtonTitle}
                                            isVideoPlaying={isVideoPlaying}
                                            onPlayClick={
                                                this.handlePlayBtnClick
                                            }
                                            onPauseClick={
                                                this.handlePauseBtnClick
                                            }
                                            disabled={
                                                showOverlayWithBigPlayButton
                                            }
                                        />
                                    </StyledToolbarItem>
                                    {showTimeline && (
                                        <>
                                            <StyledToolbarItem
                                                style={{flexGrow: 1}}
                                            >
                                                <HorizontalProgressBar
                                                    value={currentTime}
                                                    valueMin={MIN_VOLUME_VALUE}
                                                    valueMax={maxDuration}
                                                    progressBarAriaLabel={
                                                        timeProgressBarAriaLabel
                                                    }
                                                    onDragStart={
                                                        this
                                                            .handleTimelineDragStarted
                                                    }
                                                    onDragChange={
                                                        this.handleTimelineDrag
                                                    }
                                                    onDragEnd={
                                                        this
                                                            .handleTimelineDragEnded
                                                    }
                                                    hideScrubberOnLostHover
                                                    onProgressBarKeyDown={
                                                        this
                                                            .handleProgressBarKeyDown
                                                    }
                                                    scrubberDisabled={
                                                        showOverlayWithBigPlayButton
                                                    }
                                                    horizontalProgressBarValueText={
                                                        horizontalProgressBarValueText
                                                    }
                                                />
                                            </StyledToolbarItem>
                                            <StyledToolbarItem>
                                                <VideoPlayerTime
                                                    timeInSeconds={
                                                        remainingTimeInSeconds
                                                    }
                                                />
                                            </StyledToolbarItem>
                                        </>
                                    )}
                                    {showSoundControls && (
                                        <StyledToolbarItem>
                                            <SoundControls
                                                value={volume || 0}
                                                title={soundControlTitle}
                                                soundProgressBarAriaLabel={
                                                    soundProgressBarAriaLabel
                                                }
                                                onVolumeChange={
                                                    this.changeVolume
                                                }
                                                onDragStart={
                                                    this.handleVolumeDragStarted
                                                }
                                                onDragEnd={
                                                    this.handleVolumeDragEnded
                                                }
                                                onFocusReset={
                                                    this.resetOverlayTimeout
                                                }
                                                onVolumeBarKeyDown={
                                                    this.handleVolumeBarKeyDown
                                                }
                                                disableButtons={
                                                    showOverlayWithBigPlayButton
                                                }
                                            />
                                        </StyledToolbarItem>
                                    )}
                                    {transcriptFileReference && (
                                        <StyledToolbarItem>
                                            <TranscriptDownloadButton
                                                title={
                                                    downloadTranscriptButtonTitle
                                                }
                                                linkReference={
                                                    transcriptFileReference
                                                }
                                                disabled={
                                                    showOverlayWithBigPlayButton
                                                }
                                            />
                                        </StyledToolbarItem>
                                    )}
                                    {showFullscreenBtn && (
                                        <StyledToolbarItem>
                                            <ToggleFullscreenButton
                                                disableFullScreenButtonTitle={
                                                    disableFullScreenButtonTitle
                                                }
                                                enableFullScreenButtonTitle={
                                                    enableFullScreenButtonTitle
                                                }
                                                isInFullScreen={isInFullScreen}
                                                onToggleFullscreen={
                                                    this.toggleFullScreen
                                                }
                                                disabled={
                                                    showOverlayWithBigPlayButton
                                                }
                                            />
                                        </StyledToolbarItem>
                                    )}
                                </StyledToolbar>
                            </StyledOverlayWithToolbar>
                        </CSSTransition>
                        {showOverlayWithBigPlayButton && (
                            <StyledOverlayContainerCentered
                                onClick={this.handlePlayBtnClick}
                                onTouchStart={
                                    this.handleFinalStateOverlayTouchStart
                                }
                                onTouchEnd={
                                    this.handleFinalStateOverlayTouchEnd
                                }
                                isVisible
                            >
                                <BigPlayButton title={playButtonTitle} />
                            </StyledOverlayContainerCentered>
                        )}

                        {this.shouldDisplayDisclaimers() && (
                            <ThemeProvider theme={getTheme(color)}>
                                <StyledVideoDisclaimerReferences
                                    isToolbarVisible={isToolbarVisible}
                                    data-cy="videoDisclaimerReferences"
                                >
                                    {hasStaticReferences && (
                                        <Text
                                            appearance={
                                                TokenTextAppearance.copy100
                                            }
                                            tag={TextTag.span}
                                        >
                                            {staticReferences}
                                        </Text>
                                    )}
                                    {interactiveReferences}
                                </StyledVideoDisclaimerReferences>
                            </ThemeProvider>
                        )}
                    </StyledVideoPlayerInner>
                </ThemeProvider>
            </StyledVideoPlayer>
        );
    }
}
function isShowOverlayWithBigButton(
    actsAsTeaser: boolean | undefined,
    videoState: VideoPlayerV2PlayState,
    displayMode: VideoPlayerV2DisplayMode,
    autoPlay: boolean | undefined
) {
    if (actsAsTeaser) {
        return false;
    }
    return (
        (videoState === VideoPlayerV2PlayState.ENDED &&
            displayMode === VideoPlayerV2DisplayMode.NORMAL) ||
        (videoState === VideoPlayerV2PlayState.NOT_STARTED && !autoPlay)
    );
}
