import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';

export const BASIC_STAGE_HEIGHT_VAR = '--basic-stage-height';

const StyledMedia = styled.div<{size: string}>`
    ${BASIC_STAGE_HEIGHT_VAR}: 65vh;
    overflow: hidden;

    height: ${props => props.size};

    @media (min-width: ${Breakpoints.b560}px) {
        --basic-stage-height: 70vh;
    }
    @media (min-width: ${Breakpoints.b960}px) {
        --basic-stage-height: 75vh;
    }
    @media (min-width: ${Breakpoints.b1600}px) {
        --basic-stage-height: 65vh;
    }
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.theme.size.static250};

    @media (max-width: ${Breakpoints.b560}px) {
        gap: ${props => props.theme.size.static200};

        width: 100%;

        & .buttonElement,
        & .buttonElement a,
        & .buttonElement button {
            width: 100%;
        }
    }
    @media (min-width: ${Breakpoints.b1280}px) {
        flex-wrap: nowrap;
        flex-shrink: 0;
        padding-top: ${props => props.theme.size.static150};
    }
`;

const StyledCopy = styled.div`
    width: 100%;
    padding-bottom: ${props => props.theme.size.static250};
    padding-inline: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b960}px) {
        padding-inline: ${props => props.theme.size.grid002}
            ${props => props.theme.size.grid010};
    }
`;

const StyledInteractiveArea = styled.div`
    padding-block: ${props => props.theme.size.static250};
    padding-inline: ${props => props.theme.size.grid002};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: ${props => props.theme.size.static250};

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-block: ${props => props.theme.size.static350};
    }
    @media (min-width: ${Breakpoints.b1280}px) {
        flex-wrap: nowrap;
        align-items: start;
    }
`;

const StyledContentWrapper = styled.div`
    &:after {
        display: block;
        width: 100%;
        content: '';
        border-block-end: 1px solid
            ${props => props.theme.colors.border.divider};
    }
`;

const StyledDisclaimerWrapper = styled.div`
    width: 100%;
    padding-inline: ${props => props.theme.size.grid002};
`;

type BasicStageProps = Readonly<{
    media: React.ReactNode;
    mediaSize: string;
    heading: React.ReactNode;
    copy?: React.ReactNode;
    primaryButton?: React.ReactNode;
    secondaryButton?: React.ReactNode;
    disclaimers?: React.ReactNode;
}>;

export const BasicStage = (props: BasicStageProps): React.ReactElement => {
    const {
        media,
        mediaSize,
        heading,
        copy,
        primaryButton,
        secondaryButton,
        disclaimers
    } = props;

    return (
        <StyledContentWrapper>
            <StyledMedia size={mediaSize}>{media}</StyledMedia>
            <StyledInteractiveArea>
                {heading}
                {primaryButton ? (
                    <StyledButtonWrapper>
                        {secondaryButton}
                        {primaryButton}
                    </StyledButtonWrapper>
                ) : null}
            </StyledInteractiveArea>
            {copy && <StyledCopy>{copy}</StyledCopy>}
            <StyledDisclaimerWrapper>{disclaimers}</StyledDisclaimerWrapper>
        </StyledContentWrapper>
    );
};
