import * as React from 'react';
import {useContentStore} from '../../../context';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {Parsys, ParsysType} from '../Parsys';
import {MainBase} from './MainBase';

export function InternalIdhubEntryMain(): JSX.Element {
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();

    const stageParsys = (
        <Parsys path="stageParsys" parsysType={ParsysType.STAGE_PARSYS} />
    );

    return (
        <MainBase stage={stageParsys}>
            <Parsys
                path="mainParsys"
                showPersonalizablePlaceholders={
                    pageRootModel && pageRootModel.personalizablePlaceholders
                }
                parsysType={ParsysType.MAIN_PARSYS}
            />
        </MainBase>
    );
}

export const IdhubEntryMain = MapTo(
    'vwa-ngw18/components/structure/idhubentrymain',
    InternalIdhubEntryMain
);
