import {styled} from '@volkswagen-onehub/components-core';

type Props = {
    cssDisplay?: string;
};

// We use "react-focus-lock" in layers
// the "data-autofocus-inside" attribute is part of its API to autofocus a child element.

export const AutoFocusInside = styled.div.attrs(() => ({
    'data-autofocus-inside': true
}))<Props>`
    display: ${props => props.cssDisplay || 'block'};
`;
