import {Model} from '@adobe/cq-react-editable-components';
import {filterTeaser} from './useTeaserList';

/**
 *
 * Calling functional component must be observed with mobx because loading state of personalized conttent is observed
 */
export function useTeaserMboxIds(
    personalizableChildren: boolean,
    cqItems: {
        [key: string]: Model;
    }
): string[] {
    if (personalizableChildren) {
        return Object.keys(cqItems)
            .filter(key => filterTeaser(key, cqItems))
            .map(key => {
                const item = cqItems[key];
                return item.mboxId;
            });
    }
    return [];
}
