import * as React from 'react';
import {useTrackingService} from '../../context';
import {useContextTrackingData} from '../../hooks/useContextTrackingData';

export function useTrackFilterChange() {
    const trackingService = useTrackingService();
    const contextTrackingData = useContextTrackingData();
    return React.useCallback(
        (filter: string, slidesCount: number) => {
            trackingService.trackContentSliderFilterClick(
                slidesCount,
                [filter],
                contextTrackingData
            );
        },
        [contextTrackingData, trackingService]
    );
}
