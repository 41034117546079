import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    Text,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import {EditorialStage} from '../../../d6/components/editorial-stage';
import {EditorialStageSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {OverlayDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {ArticleMetaInfoElement} from '../elements/ArticleMetaInfoElement';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {FullScreenContainer} from '../../../d6/components/full-screen-container';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {ReadingTimeBox} from '../../../d6/components/reading-timebox';

// Temporal solution, see below.
const StyledEditModeWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const StyledEditModeImageWrapper = styled.div`
    position: relative;
    min-width: 40vw;
    min-height: 300px;
    max-width: 50vw;
    max-height: 800px;

    & > * {
        height: 100%;
    }

    * {
        max-width: 100%;
        max-height: 100%;
    }
`;

const StyledEditModeTextWrapper = styled.div`
    padding: 50px;
    display: flex;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    text-align: left;
`;

interface StyledMediaWrapperProps {
    readonly disableOverlayFilter?: boolean;
}

const StyledPublishModeMediaOverlay = styled.div<StyledMediaWrapperProps>`
    /*
     * Reduced brightness would be better done by CSS filter property, but that creates
     * a new z-index context, which messes up the layout on the Z axis. The correct
     * layout is: media in the bottom layer, stage overlay in the middle layer and
     * item/section disclaimers in the upper layer. Hence this workaround.
     */
    @media (max-width: ${Breakpoints.b560 - 1}px) {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.2;
        pointer-events: none;
    }
`;

// Sets height 100% on the child of child so the media is visible
const StyledMediaContainer = styled.div`
    height: 100%;
    > div > div {
        height: 100%;
    }
`;

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H1" order="H1" />
);

const CopyElement: React.FunctionComponent = () => (
    <CopyItem path="copy" hideItemDisclaimers />
);

const responsiveMediaConfigurationV2: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 -
            1}px), (max-aspect-ratio: 4/3)`,
        aspectRatio: 'r_9_16'
    },
    {
        mediaQuery: '(min-aspect-ratio: 4/3) and (max-aspect-ratio: 21/9)',
        aspectRatio: 'r_1_1'
    },
    {
        mediaQuery: '(min-aspect-ratio: 21/9) and (max-aspect-ratio: 26/9)',
        aspectRatio: 'r_4_3'
    },
    {
        mediaQuery: '(min-aspect-ratio: 26/9)',
        aspectRatio: 'r_16_9'
    }
];

export const CategoryElement: React.FunctionComponent<{
    readonly category: string;
}> = ({category}) =>
    category ? (
        <CyAttributeAppender name="categoryElement">
            <Text appearance={TokenTextAppearance.headline100} bold>
                {/* category is not a link for MVP */}
                {category}
            </Text>
        </CyAttributeAppender>
    ) : null;

/**
 * S106 Editorial Stage Section
 */
function InternalEditorialStageSection(
    props: EditorialStageSectionModel
): JSX.Element {
    const renderEditMode = (): JSX.Element => {
        const {anchorId, contentMetaData, tagTypeBroken} = props;

        return (
            <AuthoringWrapper
                anchorId={anchorId}
                title="S106 Editorial Stage"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
                tagTypeBroken={tagTypeBroken}
            >
                <GeneralDisclaimerProvider
                    displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                >
                    <>
                        <StyledEditModeWrapper id={anchorId}>
                            <StyledEditModeImageWrapper>
                                <MediaElement
                                    path="media"
                                    hideItemBasedDisclaimers
                                    enableItemInteractiveDisclaimer
                                    useParentAspectRatio
                                />
                                <OverlayDisclaimers
                                    displayTypes={[
                                        'T3_SECTION_BASED',
                                        'T4_ITEM_BASED'
                                    ]}
                                    color="light"
                                    type="item"
                                />
                            </StyledEditModeImageWrapper>
                            <StyledEditModeTextWrapper>
                                <CategoryElement
                                    category={contentMetaData.category}
                                />
                                {headingElement}
                                <ArticleMetaInfoElement {...contentMetaData} />
                                <CopyElement />
                            </StyledEditModeTextWrapper>
                        </StyledEditModeWrapper>
                    </>
                </GeneralDisclaimerProvider>
            </AuthoringWrapper>
        );
    };

    const renderPublishMode = (): JSX.Element => {
        const {
            contentMetaData,
            contentLabels,
            contentName,
            contentId: sectionId,
            anchorId,
            mediaType,
            mboxId
        } = props;

        const disableOverlayFilter = mediaType === 'ANIMATION';
        const responsiveMediaConfiguration = responsiveMediaConfigurationV2;

        const media = (
            <FullScreenContainer>
                <MediaContext.Provider
                    value={{
                        sizes: '(min-width: 768px) 50vw, 100vw',
                        useParentAspectRatio: true
                    }}
                >
                    <StyledMediaContainer>
                        <ResponsiveMediaRenderer
                            configs={responsiveMediaConfiguration}
                            matchParent
                        >
                            <MediaElement
                                enableItemInteractiveDisclaimer
                                hideItemBasedDisclaimers
                                path="media"
                                responsiveMediaConfig={
                                    responsiveMediaConfiguration
                                }
                                useParentAspectRatio
                                containerProps={{matchParent: true}}
                            />
                        </ResponsiveMediaRenderer>
                    </StyledMediaContainer>
                    <StyledPublishModeMediaOverlay
                        disableOverlayFilter={disableOverlayFilter}
                    />
                </MediaContext.Provider>
            </FullScreenContainer>
        );

        const overlay = (
            <Container
                gutter={ContainerGutter.static200}
                wrap={ContainerWrap.always}
            >
                <CategoryElement category={contentMetaData.category} />
                {headingElement}
            </Container>
        );

        const readingTime = contentMetaData.readingTime > 0 && (
            <ReadingTimeBox
                readingTime={
                    <>
                        <strong>
                            {`${contentMetaData.readingTime} ${contentMetaData.minutesLabel}`}
                        </strong>{' '}
                        {contentMetaData.readLabel}
                    </>
                }
            />
        );

        return (
            <SectionWrapper
                sectionId={sectionId}
                anchorId={anchorId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
            >
                <GeneralDisclaimerProvider
                    displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                >
                    <EditorialStage
                        disclaimersOverlay={
                            <OverlayDisclaimers
                                displayTypes={[
                                    'T3_SECTION_BASED',
                                    'T4_ITEM_BASED'
                                ]}
                                color="light"
                                type="item"
                            />
                        }
                        media={media}
                        overlay={overlay}
                        metaInfo={
                            <ArticleMetaInfoElement {...contentMetaData} />
                        }
                        copy={<CopyElement />}
                        readingTimeBox={readingTime || undefined}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        );
    };

    return (
        // This is a temporal solution because <EditorialStageForAEMEditor> from LSG made the richtexts unusable. We want to reuse as much as
        // possible from the LSG component.
        C.isInEditor() ? renderEditMode() : renderPublishMode()
    );
}

export const EDITORIAL_STAGE_TYPE =
    'vwa-ngw18/components/editorial/sections/editorialStageSection';

export const EditorialStageSection = MapTo(
    EDITORIAL_STAGE_TYPE,
    InternalEditorialStageSection
);
