import React from 'react';
import {
    useLoginAlertService,
    useLoginStore,
    useSpaAsyncConfig,
    useTrackingService
} from '../../../context';
import {
    styled,
    Text,
    TokenTextAppearance,
    TokenTextColor,
    TextTag
} from '@volkswagen-onehub/components-core';
import {createBtnLoginClick} from './createBtnLoginClick';
import {CmsButton} from '../../../components/links/CmsButton';

const StyledFooter = styled.div`
    display: grid;
    padding: ${props => props.theme.size.static400} 0;
    gap: ${props => props.theme.size.static150};
    justify-items: center;
    color: ${props => props.theme.colors.button.secondary.default};

    & > button:first-child {
        width: 100%;
    }
`;

export const UtilityLoggedOutFooter = () => {
    const {utilityNavigationModel, serviceConfigs} = useSpaAsyncConfig();
    const {
        loginLabel,
        signupLabel,
        signupDescription,
        btnCloseLabel
    } = utilityNavigationModel;
    const trackingService = useTrackingService();
    const loginStore = useLoginStore();
    const loginAlertService = useLoginAlertService();
    const handleBtnLoginClick = createBtnLoginClick(
        loginStore,
        loginAlertService,
        btnCloseLabel
    );
    const loginBtnId = 'utility-nav-login';
    const signupBtnId = 'utility-nav-signup';

    function trackButtonClick(contentId: string, buttonLabel: string): void {
        const url = serviceConfigs['auth-proxy'].urlOrigin + '/login';
        trackingService.trackButtonClick(contentId, url, buttonLabel);
    }

    return (
        <StyledFooter>
            <CmsButton
                tag="button"
                emphasis="primary"
                key={loginBtnId}
                onClick={handleBtnLoginClick}
                trackingActionOverride={() =>
                    trackButtonClick(loginBtnId, loginLabel)
                }
            >
                {loginLabel}
            </CmsButton>
            {signupLabel && (
                <Text
                    appearance={TokenTextAppearance.copy100}
                    tag={TextTag.span}
                    color={TokenTextColor.secondary}
                >
                    {signupDescription}
                </Text>
            )}
            <CmsButton
                tag="button"
                emphasis="tertiary"
                key={signupBtnId}
                onClick={handleBtnLoginClick}
                trackingActionOverride={() =>
                    trackButtonClick(signupBtnId, signupLabel)
                }
            >
                {signupLabel}
            </CmsButton>
        </StyledFooter>
    );
};
