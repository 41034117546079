import {action, observable} from 'mobx';

import * as React from 'react';
import {Scene7VideoRendition} from '../../../generated/core';
import {DragData} from '../../components/draggable-video/DraggableVideo';
import {DragDirection} from '../../components/draggable-video/helpers';
import {MediaStore, MediaStoreType} from './MediaContext';

export const Degree180Context = React.createContext<MediaStore | null>(null);

export class Degree180Store implements MediaStore {
    public readonly type: MediaStoreType = MediaStoreType.DEGREE_180;

    @observable
    public videoSrc: string = '';

    @observable
    public isFullscreen: boolean = false;

    @observable
    public dragDirection: DragDirection = 'none';

    @observable
    public isProgressBarFocused: boolean | undefined = false;

    @observable
    public currentTime: number = 0;

    @observable
    public duration: number = 0;

    @action
    public updateDrag(dragData?: DragData | null): void {
        if (!dragData) {
            return;
        }
        this.dragDirection = dragData.direction;
        this.currentTime = dragData.currentTime;
    }

    @action
    public updateProgressBarFocusState(isProgressBarFocused?: boolean): void {
        this.isProgressBarFocused = isProgressBarFocused;
    }

    @action
    public updateSrc(rend?: Scene7VideoRendition | null): void {
        if (!rend) {
            return;
        }
        this.videoSrc = rend.scene7Src;
    }

    @action
    public updateDuration(duration: number): void {
        this.duration = duration;
    }

    @action
    public openFullscreen(): void {
        this.isFullscreen = true;
    }

    @action
    public closeFullscreen(): void {
        this.isFullscreen = false;
    }
}
