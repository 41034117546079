import {PersonalizationConfig, SpaGlobalConfig} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {isInVec} from '../../utils/personalization/isInVec';
import {reportPersonalizationException} from '../../utils/personalization/reportPersonalizationException';
import {
    AdobeTargetService,
    MboxedOffers
} from '../adobeTarget/AdobeTargetService';
import {Logger} from '../logger/Logger';
import {
    ProfileV2,
    SmartDigitalService
} from '../smartDigital/SmartDigitalService';
import {isVecOrNotOptIn, isOptIn, loadOffers, loadProfile} from './utils';

export interface InitialPersonalizationResult {
    localProfile: ProfileV2;
    mboxedOffers: MboxedOffers[];
}

export const JS_PERSONALIZATION_SHOW_DEFAULT =
    'js-personalization-show-default';

@singleton('PersonalizationLoader', {env: 'priority'})
export class PriorityPersonalizationLoader {
    public static hideDefault(): void {
        document.body.classList.remove(JS_PERSONALIZATION_SHOW_DEFAULT);
    }
    public static showDefault(): void {
        document.body.classList.add(JS_PERSONALIZATION_SHOW_DEFAULT);
    }

    @inject() private adobeTargetService!: AdobeTargetService;
    @inject() private personalizationConfig!: PersonalizationConfig;
    @inject() private smartDigitalService!: SmartDigitalService;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private logger!: Logger;

    private data!: Promise<InitialPersonalizationResult>;

    @postConstruct()
    public async init(): Promise<void> {
        this.logger.personalization.info('loading personalization');
        this.logger.personalization.debug(
            'personalisation is enabled = %s',
            this.personalizationConfig.enabled
        );

        this.logger.personalization.debug(
            'personalisation is in vec = %s',
            isInVec()
        );

        this.logger.personalization.debug(
            'personalisation is opt-ed in = %s',
            isOptIn(this.spaGlobalConfig.featureToggles)
        );

        if (
            !this.personalizationConfig.enabled ||
            isVecOrNotOptIn(this.spaGlobalConfig.featureToggles)
        ) {
            return;
        }
        this.logger.personalization.debug('load personalisation');

        this.data = new Promise(async (resolve, reject) => {
            try {
                const pagePath = this.spaGlobalConfig.aemResourcePath;
                const localProfile = await loadProfile(
                    this.smartDigitalService,
                    this.logger,
                    pagePath
                );

                this.logger.personalization.debug('loaded profile');

                const mboxedOffers = await loadOffers(
                    this.adobeTargetService,
                    localProfile.personalization,
                    this.spaGlobalConfig.initialPersonalizableMboxes,
                    this.logger
                );

                resolve({
                    mboxedOffers,
                    localProfile
                });
            } catch (e) {
                const generalError =
                    'cannot get personalisation on initialisation.';
                const errorMessage =
                    e instanceof Error
                        ? `${generalError} cause: \n${e.message}`
                        : generalError;

                this.logger.personalization.warn(errorMessage);
                reportPersonalizationException(
                    'PriorityPersonalisationLoaderInit',
                    errorMessage
                );
                reject(e);
            }
        });
    }

    public async getData(): Promise<InitialPersonalizationResult> {
        return this.data;
    }
}
