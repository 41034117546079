import {Close} from '@volkswagen-onehub/icons-core';
import * as React from 'react';
import {withTheme} from 'styled-components';
import {NextBestActionButtonContent} from './NextBestActionButtonContent';
import {SubactionsButtons} from './SubactionsButtons';
import {
    NextBestAction,
    NextBestActionButtonProps,
    NextBestActionCommon,
    NextBestLinkAction
} from './types';
import {
    StyledNextBestActionButtonWrapper,
    StyledMainNextBestActionButton,
    StyledMainNextBestActionLink,
    StyledCloseButton
} from './styles';

export * from './types';

const isLink = (
    action: NextBestAction
): action is NextBestActionCommon & NextBestLinkAction =>
    Boolean(action.tag === 'a');

const NextBestActionButtonInternal = React.forwardRef<
    HTMLButtonElement | HTMLAnchorElement,
    NextBestActionButtonProps
>((props, forwardRef) => {
    const {
        action,
        closeButtonAriaLabel,
        disabled,
        tabIndex,
        displaySubActions,
        expandContent,
        fullWidth,
        hideIcon,
        hideBadge,
        subActions,
        title,
        theme,
        showStylesForLandscape,
        onMouseEnter,
        onMouseLeave,
        closeSubactions
    } = props;
    const closeButtonRef = React.useRef<HTMLButtonElement>(null);
    const nbabWrapperRef = React.useRef<HTMLDivElement>(null);
    const timeout = parseInt(theme.animation.duration.nba || '300', 10);

    React.useEffect(() => {
        if (displaySubActions) {
            // there is a focus delay due to animation
            setTimeout(() => {
                return closeButtonRef.current?.focus();
            }, timeout);
        }
    }, [displaySubActions, timeout]);

    /**
     *  there is no sane type for the ref of both StyledLink and StyledButton using "as"
     *  that works with both components and both tags, so we have to use "any" instead
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = forwardRef as any;

    const handleOnClick: React.MouseEventHandler<HTMLButtonElement &
        HTMLAnchorElement> = evt => {
        action.onClick && action.onClick(evt);
    };

    React.useEffect(() => {
        const handleClickOutside = (evt: MouseEvent): void => {
            if (
                nbabWrapperRef.current &&
                !nbabWrapperRef.current.contains(evt.target as Node)
            ) {
                closeSubactions && closeSubactions();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [nbabWrapperRef, displaySubActions, closeSubactions]);

    const CorrectMainNextBestActionElement: React.ElementType = isLink(action)
        ? StyledMainNextBestActionLink
        : StyledMainNextBestActionButton;

    return (
        <StyledNextBestActionButtonWrapper
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={nbabWrapperRef}
            expanded={expandContent}
        >
            {subActions?.length && (
                <StyledCloseButton
                    aria-label={closeButtonAriaLabel}
                    onClick={handleOnClick}
                    show={displaySubActions}
                    ref={closeButtonRef}
                    data-testid="nbabCloseButton"
                >
                    <Close ariaHidden />
                </StyledCloseButton>
            )}

            {subActions?.length && (
                <SubactionsButtons
                    subActions={subActions}
                    show={displaySubActions || false}
                    showStylesForLandscape={showStylesForLandscape}
                />
            )}

            <CorrectMainNextBestActionElement
                onClick={isLink(action) ? action.onClick : handleOnClick}
                show={isLink(action) ? true : !displaySubActions}
                href={action.href}
                ref={ref}
                expanded={expandContent}
                fullWidth={fullWidth}
                ariaLabel={action.ariaLabel}
                disabled={disabled}
                tabIndex={tabIndex}
                title={title}
                data-testid="nbabOpenButton"
                target={isLink(action) ? action.target : undefined}
            >
                <NextBestActionButtonContent
                    action={action}
                    subActions={subActions}
                    hideIcon={hideIcon}
                    timeout={timeout}
                    expandContent={expandContent}
                    hideBadge={hideBadge}
                />
            </CorrectMainNextBestActionElement>
        </StyledNextBestActionButtonWrapper>
    );
});

NextBestActionButtonInternal.displayName = 'NextBestActionButtonInternal';

export const NextBestActionButton = withTheme(NextBestActionButtonInternal);
