import * as React from 'react';
import styled from 'styled-components';
import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap
} from '@volkswagen-onehub/components-core';
import {HoverGroup} from '../hover-group';

export enum FocusTeaserLayout {
    LEFT,
    RIGHT
}

export interface FocusTeaserProps {
    readonly layout?: FocusTeaserLayout;
    readonly mediaElement: JSX.Element;
    readonly category?: JSX.Element;
    readonly linkElement?: JSX.Element;
    readonly children: JSX.Element;
}

export interface StyledFocusTeaserWrapperProps {
    readonly layout: FocusTeaserLayout;
}

export interface FocusTeaserContentProps {
    readonly headline: JSX.Element;
    readonly copy?: JSX.Element;
    readonly readingTime?: JSX.Element;
}

const layoutDirection = (layout: FocusTeaserLayout) => {
    if (FocusTeaserLayout.LEFT === layout) {
        return 'row';
    }

    return 'row-reverse';
};

const StyledFocusTeaserWrapper = styled.div<StyledFocusTeaserWrapperProps>`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 auto;
    max-width: ${Breakpoints.b2560}px;

    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: ${props => layoutDirection(props.layout)};
    }
`;

const StyledMediaElementWrapper = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid014};
    }
`;

const StyledTeaserTextWrapper = styled.div<{
    isLeftLayout: boolean;
}>`
    padding-bottom: ${props => props.theme.size.dynamic0100};
    padding-inline-start: ${props => props.theme.size.grid002};
    padding-inline-end: ${props => props.theme.size.grid002};

    div:last-child > a {
        display: block;
        margin-top: ${props => props.theme.size.static300};
    }

    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid010};
        padding-bottom: 0;
        padding-inline-start: ${props =>
            props.isLeftLayout
                ? props.theme.size.grid001
                : props.theme.size.grid002};
        padding-inline-end: ${props =>
            props.isLeftLayout
                ? props.theme.size.grid002
                : props.theme.size.grid001};
    }
`;

export function FocusTeaserContent(
    props: FocusTeaserContentProps
): JSX.Element {
    const {headline, copy, readingTime} = props;

    return (
        <Container
            gutter={ContainerGutter.dynamic0100}
            wrap={ContainerWrap.always}
        >
            {headline}
            {copy}
            {readingTime}
        </Container>
    );
}

export function FocusTeaserSection(props: FocusTeaserProps): JSX.Element {
    const {layout, mediaElement, category, children, linkElement} = props;

    const isLeftLayout = layout !== FocusTeaserLayout.RIGHT;

    return (
        <HoverGroup>
            <StyledFocusTeaserWrapper layout={layout || 0}>
                <StyledMediaElementWrapper>
                    {mediaElement}
                </StyledMediaElementWrapper>
                <StyledTeaserTextWrapper isLeftLayout={isLeftLayout}>
                    {category}
                    {children}
                    {linkElement}
                </StyledTeaserTextWrapper>
            </StyledFocusTeaserWrapper>
        </HoverGroup>
    );
}
