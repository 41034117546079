import * as React from 'react';

import {
    Container,
    ContainerPadding,
    ThemeDefinition,
    styled
} from '@volkswagen-onehub/components-core';
import {getContainerPadding, getStartDirection} from '../helpers';

interface StatementBorderProps {
    readonly theme: ThemeDefinition;
}

const createStatementBorder = (props: StatementBorderProps) => {
    const separatorSize = '2px';

    const {theme} = props;

    return `border-${getStartDirection(theme.direction)}: solid ${
        theme.colors.border.divider
    } ${separatorSize};`;
};

const StyledStatement = styled.blockquote`
    display: flex;
    margin: 0;
    ${props => createStatementBorder(props)}
`;

const CONTAINER_PADDING_2_1 = getContainerPadding([2, 1]);

export const Statement: React.FunctionComponent = ({children}): JSX.Element => (
    <StyledStatement>
        <Container
            padding={{
                left: CONTAINER_PADDING_2_1,
                right: CONTAINER_PADDING_2_1,
                top: ContainerPadding.dynamic0020,
                bottom: ContainerPadding.dynamic0100
            }}
        >
            {children}
        </Container>
    </StyledStatement>
);
