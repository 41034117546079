import * as React from 'react';
import {withTheme} from 'styled-components';

import {NextBestActionPanelButtonContent} from './index';
import {
    NextBestActionV2,
    NextBestActionCommonV2,
    NextBestLinkActionV2,
    NextBestActionPanelButtonProps
} from './types';
import {
    StyledNextBestActionPanelLink,
    StyledNextBestActionPanelButton
} from './styles';

const isLink = (
    action: NextBestActionV2
): action is NextBestActionCommonV2 & NextBestLinkActionV2 =>
    Boolean(action.tag === 'a');

const NextBestActionPanelButtonInternal: React.FunctionComponent<NextBestActionPanelButtonProps> = (
    props: NextBestActionPanelButtonProps
) => {
    const {
        action,
        type,
        disabled,
        tabIndex,
        title,
        dropdownExpanded,
        testId
    } = props;

    const CorrectMainNextBestActionElement: React.ElementType = isLink(action)
        ? StyledNextBestActionPanelLink
        : StyledNextBestActionPanelButton;

    const handleOnClick: React.MouseEventHandler<HTMLButtonElement &
        HTMLAnchorElement> = evt => {
        action.onClick && action.onClick(evt);
    };

    return (
        <>
            <CorrectMainNextBestActionElement
                type={type}
                onClick={isLink(action) ? action.onClick : handleOnClick}
                href={action.href}
                ariaLabel={action.ariaLabel}
                disabled={disabled}
                tabIndex={tabIndex}
                title={title}
                dropdownExpanded={dropdownExpanded}
                data-testid={testId ? testId : 'horizontalNbabButton'}
                data-expanded={dropdownExpanded}
                target={isLink(action) ? action.target : undefined}
            >
                <NextBestActionPanelButtonContent action={action} />
            </CorrectMainNextBestActionElement>
        </>
    );
};

NextBestActionPanelButtonInternal.displayName =
    'NextBestActionPanelButtonInternal';

export const NextBestActionPanelButton = withTheme(
    NextBestActionPanelButtonInternal
);
