import * as React from 'react';

import {useLayers} from './useLayers';

export function useActiveLayerScrollableElement() {
    const {layers} = useLayers();
    const [
        activeLayerScrollableElement,
        setActiveLayerScrollableElement
    ] = React.useState<HTMLElement>();

    React.useEffect(() => {
        const scrollableElement =
            layers.length > 0
                ? layers[layers.length - 1].layer.element
                : undefined;
        setActiveLayerScrollableElement(scrollableElement);
    }, [layers]);

    return {activeLayerScrollableElement};
}
