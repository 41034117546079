import {IValueDidChange, Lambda, action, observable, observe} from 'mobx';
import {topBarScrolledHeight} from '../../components/AnchorTarget';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {ContentStore} from '../content/ContentStore';
import {Logger} from '../logger/Logger';
import {NavigationService, NavigationState} from './NavigationService';
import {RouterService} from './RouterService';
import {smoothScroll} from '../../utils/smoothScroll';
import {FeatureTogglesModel} from '../../../generated/core';

/**
 * keeps track of navigation. This service combines the render events  and router events.
 * It also implements the default link scrolling with and without anchors for 'PUSH' actions.
 */
@singleton('NavigationService', {env: 'client'})
export class ClientNavigationService implements NavigationService {
    /**
     * call this to update the state
     */
    @observable public state: NavigationState = {
        path: '',
        rendered: false,
        initialRender: true,
        pathChanged: true
    };
    @observable public loading: boolean = false;
    @observable public isSmoothScrollRunning: boolean = false;

    @inject() private routerService!: RouterService;
    @inject() private contentStore!: ContentStore;
    @inject() private logger!: Logger;
    @inject() private featureToggles!: FeatureTogglesModel;

    @postConstruct()
    public init(): void {
        this.state = {
            path: this.routerService.pagePath,
            rendered: true,
            initialRender: true,
            action: 'PUSH',
            pathChanged: true
        };
        this.routerService.listen(this.onRouteEvent);
        this.listen(this.defaultNavigationAction);
    }

    @action
    public onRender(path: string): void {
        this.loading = !this.contentStore.getCurrentPageRootModel();
        if (this.state.path !== path) {
            this.logger.navigation.error(
                'render event changed path to %s from %s',
                path,
                this.state.path
            );
        }
        // change rendered state if changed
        if (this.state.initialRender || !this.state.rendered) {
            this.state = {
                path,
                rendered: true,
                initialRender: this.state.initialRender,
                action: this.state.action,
                pathChanged: this.state.pathChanged
            };
        }
    }

    public listen(
        listener: (change: IValueDidChange<NavigationState>) => void
    ): Lambda {
        return observe(this, 'state', listener);
    }

    @action
    private onRouteEvent = () => {
        // on route events the page is not rendered initially. if the page was rendered and path doesn't change, then it is still rendered
        this.loading = !this.contentStore.getCurrentPageRootModel();
        const newState = this.routerService.state;

        const rendered =
            this.state.rendered && newState.pagePath === this.state.path;

        const pathChanged = newState.pagePath !== this.state.path;

        this.state = {
            action: newState.action,
            path: newState.pagePath,
            initialRender: false,
            rendered,
            pathChanged
        };
    };

    private findTargetElement(id: string): Element | null {
        try {
            const escapedId = id.replace(/([.:@])/g, '\\$1');
            const target = document.querySelector('#' + escapedId);
            if (!target) {
                this.logger.navigation.warn(
                    'no scroll because cannot find element with id %s',
                    id
                );
            }
            return target;
        } catch (e) {
            this.logger.navigation.warn(
                "no scroll because id isn't properly formatted %s",
                id
            );
        }
        return null;
    }

    private handleAnchorScroll(element: Element, offset: number): void {
        const {enableInpageNavigationV2} = this.featureToggles;

        const focusToTargetElement = () => {
            this.isSmoothScrollRunning = false;
            (element as HTMLElement).focus();
        };
        this.isSmoothScrollRunning = true;
        // for scroll up, more offset due expanded Topbar with InpageNavV2
        const offsetForExpandedTopbar =
            offset < 0 ? offset - topBarScrolledHeight : offset;
        smoothScroll({
            distance: enableInpageNavigationV2
                ? offsetForExpandedTopbar
                : offset,
            onScrollEnd: focusToTargetElement
        });
        window.dispatchEvent(new Event('hashchange'));
    }

    private defaultNavigationAction = (
        change: IValueDidChange<NavigationState>
    ) => {
        const initialRender = this.state.initialRender;
        const isRendered = change.newValue.rendered;
        const isPushOrReplace =
            change.newValue.action === 'PUSH' ||
            change.newValue.action === 'REPLACE';
        const isPathChanged =
            !change.oldValue || change.newValue.path !== change.oldValue.path;
        const hash = window.location.hash;

        if (!isPushOrReplace) {
            // no scroll navigation if not a push or replace
            return;
        }

        if (isRendered && hash && !initialRender) {
            // navigate to anchor on same or other page when elements are rendered
            const id = unescape(hash.substr(1)).replace(/[^\w\-\\:\\.]/g, '_');

            const element = this.findTargetElement(id);
            if (!element) return;

            const offset =
                element.getBoundingClientRect().top - topBarScrolledHeight;
            if (!isPathChanged) {
                this.handleAnchorScroll(element, offset);
            } else {
                window.scrollBy(0, offset);
            }

            this.logger.navigation.debug(
                'scroll into view %s - %s',
                hash,
                offset
            );
        } else if (!hash && isPathChanged) {
            // navigate to top of page if path has changed and there is no anchor
            this.logger.navigation.debug('scroll to top of new page');
            window.scrollTo(0, 0);
            // set focus on page navigation to first focusable element
            const firstFocusableWrapper = document.getElementById('cookiemgmt');
            return (firstFocusableWrapper as HTMLElement)?.focus();
        }
    };
}
