import {css, styled} from '@volkswagen-onehub/components-core';
import {keyframes} from 'styled-components';

import * as React from 'react';
import {ON_TIMER_END_EVENT} from './countdown';

const move = keyframes`
	0% {
		transform: translateY(0%);
	}
	100% {
		transform: translateY(-100%);
	}
`;

const StyledTransitionWrapper = styled.div<{
    state: 'before' | 'transition' | 'after';
}>`
    ${props =>
        props.state === 'transition' &&
        css`
            animation: ${move} 1000ms ease-in-out;
        `}
`;

const StyledBefore = styled.div<{state: TransitionState}>`
    ${props => props.state === 'after' && `display:none`}
`;

const StyledAfter = styled.div<{state: TransitionState}>`
    display: ${props => (props.state === 'before' ? 'none' : '')};

    ${props =>
        props.state !== 'after' &&
        `position: absolute;
    inset: 0;
    top: 100%;`}
`;

const StyledPremiereSection = styled.div`
    overflow: hidden;
    height: 100%;
`;

interface PremiereSectionProps {
    beforeReveal: JSX.Element;
    afterReveal: JSX.Element;
    date: number;
}

type TransitionState = 'before' | 'transition' | 'after';

export const PremiereSection = (props: PremiereSectionProps) => {
    const {beforeReveal, afterReveal, date} = props;

    const [state, setState] = React.useState<TransitionState>(() =>
        date <= 0 ? 'after' : 'before'
    );
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const element = ref.current;

        // eslint-disable-next-line no-unused-expressions
        element?.addEventListener(ON_TIMER_END_EVENT, () =>
            setState('transition')
        );
        return () => {
            // eslint-disable-next-line no-unused-expressions
            element?.removeEventListener(ON_TIMER_END_EVENT, () =>
                setState('transition')
            );
        };
    }, []);

    return (
        <StyledPremiereSection key="premiere" ref={ref}>
            <StyledTransitionWrapper
                state={state}
                onAnimationEnd={() => setState('after')}
            >
                <StyledBefore state={state}>{beforeReveal}</StyledBefore>
                <StyledAfter state={state}>{afterReveal}</StyledAfter>
            </StyledTransitionWrapper>
        </StyledPremiereSection>
    );
};
