import * as React from 'react';
import styled from 'styled-components';
import {Breakpoints} from '@volkswagen-onehub/components-core';

export interface FirstLevelTeaserProps {
    readonly headlineElement?: JSX.Element;
    readonly teaserItems: JSX.Element[];
    readonly quoteItem?: JSX.Element;
}

const StyledFirstLevelTeaserWrapper = styled.div`
    max-width: ${Breakpoints.b2560}px;

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0150};
    }
`;
StyledFirstLevelTeaserWrapper.displayName = 'StyledFirstLevelTeaserWrapper';

const StyledFirstLevelHeadlineWrapper = styled.div`
    padding: 0 ${({theme}) => theme.size.grid006};
`;
StyledFirstLevelHeadlineWrapper.displayName = 'StyledFirstLevelHeadlineWrapper';

const StyledTeaserWrapper = styled.div`
    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0250};
    }

    @media (min-width: ${Breakpoints.b560}px) {
        > div:not(:last-child) {
            margin-bottom: ${({theme}) => theme.size.dynamic0150};
        }
    }
`;
StyledTeaserWrapper.displayName = 'StyledTeaserWrapper';

const StyledTeaserTopRightContainer = styled.div`
    padding-inline-start: ${({theme}) => theme.size.grid002};
    padding-inline-end: 0;
    padding-bottom: ${({theme}) => theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: 0;
        padding-inline-end: ${({theme}) => theme.size.grid002};
        padding-bottom: 0;
    }

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0150};
    }
`;
StyledTeaserTopRightContainer.displayName = 'StyledTeaserTopRightContainer';

const StyledTeaserTopLeftContainer = styled.div`
    padding-inline-start: 0;
    padding-inline-end: ${({theme}) => theme.size.grid004};
    padding-top: 0;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: ${({theme}) => theme.size.grid002};
        padding-inline-end: ${({theme}) => theme.size.grid003};
        padding-top: ${({theme}) => theme.size.grid002};
    }

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0150};
    }
`;
StyledTeaserTopLeftContainer.displayName = 'StyledTeaserTopLeftContainer';

export const StyledTeaserBottomRightContainer = styled.div<{
    hasPaddingBottom?: boolean;
}>`
    padding-left: ${({theme}) => theme.size.grid002};
    padding-right: ${({theme}) => theme.size.grid002};
    padding-bottom: ${({hasPaddingBottom, theme}) =>
        hasPaddingBottom ? theme.size.grid002 : undefined};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-bottom: ${({hasPaddingBottom}) =>
            hasPaddingBottom ? 0 : undefined};
    }

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0150};
    }
`;
StyledTeaserBottomRightContainer.displayName =
    'StyledTeaserBottomRightContainer';

export const StyledTeaserBottomLeftContainer = styled.div<{
    hasPaddingTop?: boolean;
}>`
    padding-inline: ${({theme}) => theme.size.grid002};
    padding-top: ${({hasPaddingTop}) => (hasPaddingTop ? 0 : undefined)};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: ${props => props.theme.size.grid003};
        padding-inline-end: 0;
        padding-top: ${({hasPaddingTop, theme}) =>
            hasPaddingTop ? theme.size.grid004 : undefined};
    }

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0150};
    }
`;
StyledTeaserBottomLeftContainer.displayName = 'StyledTeaserBottomLeftContainer';

const StyledQuoteLeftContainer = styled.div`
    padding-inline: ${({theme}) => theme.size.grid002};
    padding-top: ${({theme}) => theme.size.dynamic0300};
    padding-bottom: ${({theme}) => theme.size.grid004};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: ${({theme}) => theme.size.grid004};
        padding-inline-end: 0;
        padding-bottom: 0;
    }
`;
StyledQuoteLeftContainer.displayName = 'StyledQuoteLeftContainer';

const StyledQuoteRightContainer = styled.div`
    padding-inline: ${({theme}) => theme.size.grid002};
    padding-top: ${({theme}) => theme.size.grid004};
    padding-bottom: ${({theme}) => theme.size.grid004};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: 0;
        padding-inline-end: ${({theme}) => theme.size.grid004};
        padding-top: 0;
        padding-bottom: 0;
    }
`;
StyledQuoteRightContainer.displayName = 'StyledQuoteRightContainer';

const StyledWrapperTwoItems = styled.div`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-template-areas:
        'a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0'
        'b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0';

    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-areas: 'b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0';
    }

    > :first-child {
        display: block;
        grid-area: a0;
    }

    > :last-child {
        display: block;
        grid-area: b0;
    }
`;

const StyledWrapperHasQouteItem = styled(StyledWrapperTwoItems)`
    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-columns: repeat(12, 1fr);
    }
`;

const StyledWrapperNoQouteItem = styled(StyledWrapperTwoItems)`
    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0250};
    }
`;

const StyledWrapperThreeOrMoreItemsAndHasQouteItem = styled(
    StyledWrapperTwoItems
)`
    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-areas: 'a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 a0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0 b0';
    }

    > div:not(:last-child) {
        margin-bottom: ${({theme}) => theme.size.dynamic0250};
    }
`;

export const FirstLevelTeaser: React.FunctionComponent<FirstLevelTeaserProps> = (
    props: FirstLevelTeaserProps
): JSX.Element | null => {
    const {headlineElement, teaserItems, quoteItem} = props;
    const numItems = teaserItems.length;
    const hasQuoteItem = quoteItem !== undefined;

    return (
        <StyledFirstLevelTeaserWrapper>
            {props.headlineElement && (
                <StyledFirstLevelHeadlineWrapper>
                    {headlineElement}
                </StyledFirstLevelHeadlineWrapper>
            )}

            <StyledTeaserWrapper>
                <StyledWrapperTwoItems>
                    {numItems > 0 && (
                        <StyledTeaserTopRightContainer>
                            {teaserItems[0]}
                        </StyledTeaserTopRightContainer>
                    )}
                    {numItems > 1 && (
                        <StyledTeaserTopLeftContainer>
                            {teaserItems[1]}
                        </StyledTeaserTopLeftContainer>
                    )}
                </StyledWrapperTwoItems>
                {numItems === 2 && hasQuoteItem && (
                    <StyledWrapperHasQouteItem>
                        <StyledQuoteRightContainer>
                            {quoteItem}
                        </StyledQuoteRightContainer>
                        <div />
                    </StyledWrapperHasQouteItem>
                )}
                {numItems > 2 && !hasQuoteItem && (
                    <StyledWrapperNoQouteItem>
                        <StyledTeaserBottomRightContainer
                            hasPaddingBottom={numItems > 3}
                        >
                            {teaserItems[2]}
                        </StyledTeaserBottomRightContainer>
                        {numItems > 3 ? (
                            <StyledTeaserBottomLeftContainer hasPaddingTop>
                                {teaserItems[3]}
                            </StyledTeaserBottomLeftContainer>
                        ) : (
                            <div />
                        )}
                    </StyledWrapperNoQouteItem>
                )}
                {numItems > 2 && hasQuoteItem && (
                    <StyledWrapperThreeOrMoreItemsAndHasQouteItem>
                        <StyledQuoteLeftContainer>
                            {quoteItem}
                        </StyledQuoteLeftContainer>
                        <StyledTeaserBottomRightContainer>
                            {teaserItems[2]}
                        </StyledTeaserBottomRightContainer>
                    </StyledWrapperThreeOrMoreItemsAndHasQouteItem>
                )}
                {numItems > 3 && hasQuoteItem && (
                    <StyledWrapperThreeOrMoreItemsAndHasQouteItem>
                        <StyledTeaserBottomLeftContainer>
                            {teaserItems[3]}
                        </StyledTeaserBottomLeftContainer>
                        <div />
                    </StyledWrapperThreeOrMoreItemsAndHasQouteItem>
                )}
            </StyledTeaserWrapper>
        </StyledFirstLevelTeaserWrapper>
    );
};
