import {FeatureHub, FeatureServiceConsumerDefinition} from '@feature-hub/core';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as styled from 'styled-components';
import {PersonalizedNbabStore} from '../context/nbab/PersonalizedNbabStore';
import {PersonalizedNbab} from '../modules/structure/nbab/PersonalizedNbab';
import {getGlobal} from '../utils/getGlobal';
import {Registry} from './di/Registry';
import * as cqReactEditableComponents from '@adobe/cq-react-editable-components';
import * as cqSpaPageModelManager from '@adobe/cq-spa-page-model-manager';
import {MapTo} from './compatibility/MapTo';
import {NBAB_STORE_API_NAME, NbabApiStore} from '../context/nbab/NbabApiStore';
import {Logger} from '../context/logger/Logger';
import {BboService} from '../context/bbo/BboService';
import {BasicStageData} from '../context/bbo/BasicStageData';

const AdobeMapTo = (resourceType: string) => (
    component: React.ComponentType<any>
) => {
    MapTo(resourceType, component);
};

export const exposeGlobalApi = (featurehub: FeatureHub, registry: Registry) => {
    if (typeof window === 'undefined') {
        return;
    }
    (window as any).__vwad6_featurehub__react = React;
    (window as any).__vwad6_featurehub__react_dom = ReactDom;
    (window as any).__vwad6_featurehub__styled = styled;

    (window as any).__vwad6_cq_react_editable_components = cqReactEditableComponents;
    (window as any).__vwad6_cq_spa_component_mapping = {MapTo: AdobeMapTo};
    (window as any).__vwad6_cq_spa_page_model_manager = cqSpaPageModelManager;

    getGlobal().api = {
        bindFeatureServices: (
            consumerDefinition: FeatureServiceConsumerDefinition,
            consumerId: string,
            consumerName?: string
        ) => {
            return featurehub.featureServiceRegistry.bindFeatureServices(
                consumerDefinition,
                consumerId,
                consumerName
            );
        },
        pushNbab: (personalizedNbab: PersonalizedNbab) => {
            const store: PersonalizedNbabStore = registry.getSingleton(
                'PersonalizedNbabStore'
            );
            store && store.pushNbab(personalizedNbab);
        },
        swapBasicStage: (data: BasicStageData) => {
            const service: BboService = registry.getSingleton('BboService');
            service && service.swapBasicStage(data);
        },
        get nbabType() {
            const store: NbabApiStore = registry.getSingleton(
                NBAB_STORE_API_NAME
            );
            return store ? store.nbabType : undefined;
        },
        listenToNbab: (listener: () => void) => {
            const store: NbabApiStore = registry.getSingleton(
                NBAB_STORE_API_NAME
            );
            return store && store.listen(listener);
        }
    };
};

export const notifyInitialListeners = (registry: Registry) => {
    const logger: Logger = registry.getSingleton('Logger');
    if (logger) {
        logger.general.info('api: notify initial listeners');
    }
    (getGlobal().initListeners || []).forEach(listener => listener());
};
