import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {FeatureAppSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    CmsFeatureAppLoader,
    FeatureAppLoaderError
} from '../../../components/feature-app/CmsFeatureAppLoader';
import {getError} from '../../../components/feature-app/helpers';
import {
    useFeatureAppVisibilityService,
    useGlobalConfig,
    useGlobalDisclaimerStore,
    useLogger
} from '../../../context';
import {
    LegalEntityContext,
    LegalEntityContextData
} from '../../../context/legal/LegalEntityProvider';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {isNotEmpty} from '../../../utils/string';
import {createFeatureAppSectionTrackingData} from '../../../utils/tracking/createFeatureAppSectionTrackingData';
import {
    ColumnLayoutChildType,
    ColumnLayoutWrapper
} from '../../structure/ColumnLayoutWrapper';
import {useIntersectionTrigger} from './section-wrapper-utils/intersectionTrigger';
import {SectionWrapper} from './SectionWrapper';
import {FeatureAppContent} from './FeatureAppContent';
import {
    hasVisibleFAs,
    useFeatureAppsVisibilityListener
} from '../../../context/featureApp/useFeatureAppsVisibilityListener';
import {useSectionVisibility} from '../../../infrastructure/compatibility/useSectionVisibility';
import {useIsClientOnlyRendering} from '../../../utils/useIsClientOnlyRendering';

const EMPTY_LABEL = 'S122 Feature App Section';

function getFeatureAppId(consumerId: string): string {
    if (C.isInEditor()) {
        // to rerender the feature app in author mode we must provide a new feature app id.
        return consumerId + '_' + new Date().getTime();
    }
    return consumerId;
}

export function useViewportCheck() {
    const [sectionRefInitialized, setSectionRefInitialized] = React.useState(
        false
    );
    const sectionWrapperRef = React.useRef<HTMLDivElement | null>(null);
    const refCallback = React.useCallback(
        (callBackRef: React.RefObject<HTMLDivElement>) => {
            if (
                callBackRef.current &&
                callBackRef.current !== sectionWrapperRef.current
            ) {
                sectionWrapperRef.current = callBackRef.current;
                setSectionRefInitialized(true);
            }
        },
        [sectionWrapperRef]
    );

    const [loadFA, setLoadFA] = React.useState(false);
    useIntersectionTrigger(
        sectionWrapperRef,
        (_entries: IntersectionObserverEntry[]) => {
            if (!Boolean(loadFA)) {
                setLoadFA(true);
            }
        },
        (entry: IntersectionObserverEntry) => {
            return entry.isIntersecting;
        },
        0,
        0.01,
        sectionRefInitialized
    );

    return {
        setElementRef: refCallback,
        elementInViewport: loadFA
    };
}

const InternalFeatureAppSection = observer(function ObservedFeatureAppSection(
    props: FeatureAppSectionModel & LegalEntityContextData
): JSX.Element | null {
    const disclaimerStore = useGlobalDisclaimerStore();
    const visibilityService = useFeatureAppVisibilityService();
    const spaGlobalConfig = useGlobalConfig();
    const {setVisible} = useSectionVisibility();
    const logger = useLogger();
    const legalEntityContext = React.useContext(LegalEntityContext);
    const {elementInViewport, setElementRef} = useViewportCheck();
    const [hasError, setFeatAppHasError] = React.useState(false);
    const {
        consumerId,
        anchorId,
        config,
        contentId: sectionId,
        contentLabels,
        contentName,
        layout,
        tagTypeBroken,
        content,
        mboxId
    } = props;

    React.useEffect(() => {
        logger.featureApps.debug('FeatureAppSection [mounted]');

        return () => {
            logger.featureApps.debug('FeatureAppSection [unmounted]');
        };
    }, [logger.featureApps]);

    // check if FA has content
    const [hasContent, setHasContent] = React.useState<boolean>(
        hasVisibleFAs([consumerId], visibilityService)
    );
    useFeatureAppsVisibilityListener(consumerId, setHasContent);

    React.useEffect(() => {
        // set visibility of section
        if (!C.isInEditor()) setVisible(hasContent);
    }, [hasContent, setVisible]);

    // do not show FA with no Content for SSR or on first render (to be in sync with SSR on first render)
    const isClientOnlyRendering = useIsClientOnlyRendering();

    // handle error
    const error = getError(hasError, isNotEmpty(config ? config.url : ''));
    const renderEmpty = renderErrorAsBlankSection(error);
    React.useEffect(() => {
        // set to "no content" if there is an error to handle section group and in page nav visibility correctly
        if (isClientOnlyRendering && renderEmpty) {
            visibilityService.tune(consumerId).set({visible: false});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientOnlyRendering, hasError]);

    if (renderEmpty) {
        logger.featureApps.debug('FeatureAppSection has error');
        return null;
    }

    // handle no content
    if (!isClientOnlyRendering && !hasContent) {
        logger.featureApps.debug('FeatureAppSection invisible');
        return null;
    }

    logger.featureApps.debug('FeatureAppSection [re-render]');

    const sectionTrackingData = createFeatureAppSectionTrackingData(config);
    const featureAppId = getFeatureAppId(consumerId);
    const {
        name,
        validInstanceConfig,
        hideLoadingIndicator,
        url,
        ssrUrl,
        ssrEnabled,
        baseUrl,
        css,
        instanceConfig,
        featureAppSize,
        legalEntity
    } = config || {};

    /**
     * NOTE to {...config as any}:
     * this workaround is necessary because of a storybook dependency, which adds a `css` prop to react's
     * IntrinsicAttributes interface, so effectively  to all react components. This prop's type does not match
     * the type of FeatureAppLoaderProps' css prop and causes webpack build to fail. This workaround maintains type
     * checking for FeatureAppLoaderProps and passes webpack build.
     */
    const lazyLoadingActive =
        spaGlobalConfig.featureToggles.enableFeatureAppLazyLoading;
    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title={EMPTY_LABEL}
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {C.isInEditor() && config && (
                <FeatureAppContent path="content" name={config.name} />
            )}

            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                additionalTrackingData={sectionTrackingData}
                contentName={contentName}
                customDataAttribute={{
                    key: 'data-component',
                    value: 'feature-app-section'
                }}
                refCallback={setElementRef}
                mboxId={mboxId}
            >
                <ColumnLayoutWrapper
                    childType={ColumnLayoutChildType.MEDIA}
                    layout={layout}
                >
                    <CmsFeatureAppLoader
                        disclaimerLegalEntity={legalEntityContext.legalEntity}
                        featureAppId={featureAppId}
                        anchorId={anchorId}
                        error={error}
                        sectionId={sectionId}
                        contentLabels={contentLabels}
                        featureAppTrackingData={sectionTrackingData}
                        globalDisclaimerStore={disclaimerStore}
                        onErrorStateChange={setFeatAppHasError}
                        logger={logger}
                        content={content}
                        featureAppName={name}
                        validInstanceConfig={validInstanceConfig}
                        hideLoadingIndicator={hideLoadingIndicator}
                        url={url}
                        ssrUrl={ssrUrl}
                        ssrEnabled={ssrEnabled}
                        baseUrl={baseUrl}
                        css={css}
                        instanceConfig={instanceConfig}
                        featureAppSize={featureAppSize}
                        legalEntity={legalEntity}
                        featureAppInView={
                            C.isInEditor() ||
                            !lazyLoadingActive ||
                            elementInViewport
                        }
                    />
                </ColumnLayoutWrapper>
            </SectionWrapper>
        </AuthoringWrapper>
    );
});

function renderErrorAsBlankSection(error?: FeatureAppLoaderError): boolean {
    return Boolean(!C.isInEditor() && error !== undefined);
}

export const FA_SECTION_RESOURCETYPE =
    'vwa-ngw18/components/editorial/sections/featureAppSection';
export const FeatureAppSection = MapTo(
    FA_SECTION_RESOURCETYPE,
    InternalFeatureAppSection
);
