import {ModelManager, TransferModel} from '@adobe/cq-spa-page-model-manager';
import {defineExternals, loadAmdModule} from '@feature-hub/module-loader-amd';
import {createSanitizeForBrowser} from '@volkswagen-onehub/disclaimer-manager';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import {
    AsyncConfigServlet,
    LoginModel,
    SpaAsyncConfig,
    SpaModel
} from '../generated/core';
import {CmsRoot} from './components/CmsRoot';
import {ModelClient} from './infrastructure/compatibility/ModelClient';
import {Registry} from './infrastructure/di/Registry';
import {initializeHub} from './infrastructure/hub/initializeHub';
import {C} from './registries/compatibilty';
import {convertLocationToPath} from './utils/convertLocationToPath';
import {isDevMode} from './utils/devModeUtils';
import {monkeyPatchModelStore} from './utils/monkeyPatchModelStore';

import {FeatureHub, FeatureServiceProviderDefinition} from '@feature-hub/core';
import {
    FeatureHubContextProvider,
    FeatureHubContextProviderValue
} from '@feature-hub/react';
import {Logger} from './context/logger/Logger';
import {providedExternalsImports} from './infrastructure/hub/externals';
import {
    getSerializedStatesFromDom,
    getUrlsForHydrationFromDom
} from './infrastructure/hub/hubMetaUtils';
import './registries/generalModules';
import {domReady} from './utils/domReady';
import {getGlobal} from './utils/getGlobal';
import {registerAsyncConfig} from './utils/registerConfiguration';

import './registries/services';
import {SharedFeatureService} from '@feature-hub/core';
import {
    CLIENT_SERVICES,
    ServiceRegistration
} from './infrastructure/hub/ServiceRegistration';
import {
    createAuthServiceConfig,
    createAuthServiceConfigV2
} from './infrastructure/hub/createAuthServiceConfig';
import {defineAuthServiceConfig} from '@volkswagen-onehub/authservice-config';
import {readyForHydration} from './readyForHydration';
import {PriorityPersonalizationLoader} from './context/personalization/PriorityPersonalizationLoader';
import {createTrackingServiceConfig} from './infrastructure/hub/createTrackingServiceConfig';
import {getPageRootModel} from './utils/getPageRootModel';
import {assignLoggerForErrorEvents} from './utils/assignLoggerForErrorEvents';
import {isInBrowser} from './utils/browser/isInBrowser';
import {notifyInitialListeners} from './infrastructure/exposeGlobalApi';

type ClientBundleResponse = {
    bundle?: string;
    definitions: FeatureServiceProviderDefinition<SharedFeatureService>[];
};
export function renderSpa(
    model: SpaModel,
    registry: Registry,
    AppComponent: React.ComponentClass | React.FunctionComponent,
    targetElementId: string,
    logger: Logger
): void {
    const modelClient = new ModelClient();
    const rootPath = model.model[':path'];
    C.setIsInEditor(Boolean(model.wcmmode && model.wcmmode !== 'disabled'));

    assignLoggerForErrorEvents(logger.uncaught);

    const currentPath = convertLocationToPath(window.location.pathname);
    if (currentPath !== null && currentPath !== model.path) {
        // the current browser path is not the same as the resourcePath. AEM internal redirect is one such a situation
        // if '/content/onehub/de/de/mypage' is in the children, also add mapped path '/de/mypage'
        const childPages = model.model[':children'];
        childPages[currentPath] = childPages[model.path];
    }

    // init model manager
    const modelManager = ModelManager.initialize({
        modelClient,
        path: rootPath,
        model: model.model as TransferModel
    });

    // get configuration
    let asyncConfigPromise: Promise<SpaAsyncConfig>;
    if (model.spaGlobalConfig.integrator || C.isInEditor()) {
        // for integrator template config is in the spa model in dom
        asyncConfigPromise = Promise.resolve(model.asyncConfig);
    } else {
        // load configuration asynchronously
        const asyncConfigResponsePromise: Promise<Response> | undefined =
            getGlobal().asyncConfigPromise === undefined
                ? fetch(model.spaGlobalConfig.asyncConfigUrl)
                : getGlobal().asyncConfigPromise;

        if (!asyncConfigResponsePromise) {
            throw new Error('cannot configure application');
        }

        asyncConfigPromise = asyncConfigResponsePromise.then(async response => {
            const servlet = (await response.json()) as AsyncConfigServlet;

            return Promise.resolve(servlet.spaAsyncConfig);
        });
    }

    Promise.all([asyncConfigPromise, modelManager]).then(
        (result: [SpaAsyncConfig, TransferModel]) => {
            // fix access to root path in modelStore
            monkeyPatchModelStore();

            defineExternals(providedExternalsImports);

            const spaAsyncConfig: SpaAsyncConfig = result[0];
            const featureServicesConfig =
                spaAsyncConfig.featureHubConfiguration;

            registerAsyncConfig(registry, spaAsyncConfig);
            registry.addSingleton('ModelManager', ModelManager);
            registry.addSingleton('ModelClient', modelClient);

            // load client bundles
            const loadClientBundlePromises: Promise<unknown>[] = [];
            if (featureServicesConfig?.clientBundleUrl) {
                loadClientBundlePromises.push(
                    loadAmdModule(featureServicesConfig.clientBundleUrl).then(
                        (result: any) => ({
                            bundle: 'bundle',
                            definitions: result.definitions
                        })
                    )
                );
            }
            if (featureServicesConfig?.marketClientBundleUrl) {
                loadClientBundlePromises.push(
                    loadAmdModule(
                        featureServicesConfig.marketClientBundleUrl
                    ).then((result: any) => ({
                        bundle: 'marketBundle',
                        definitions: result.definitions
                    }))
                );
            }
            if (loadClientBundlePromises.length === 0) {
                loadClientBundlePromises.push(
                    Promise.resolve({definitions: []})
                );
            }

            // wait until loading has been finished and then initialize feature hub
            Promise.all(loadClientBundlePromises).then(responses => {
                const moduleDefinitionsMap: {
                    moduleDef: FeatureServiceProviderDefinition<
                        SharedFeatureService
                    >;
                    bundle: string;
                }[] = [];
                const existingIds: string[] = [];
                for (const response of responses) {
                    const clientBundleResponse = response as ClientBundleResponse;
                    if (clientBundleResponse.definitions) {
                        clientBundleResponse.definitions.forEach(d => {
                            if (existingIds.indexOf(d.id) < 0) {
                                moduleDefinitionsMap.push({
                                    moduleDef: d,
                                    bundle: clientBundleResponse.bundle || ''
                                });
                                existingIds.push(d.id);
                            }
                        });
                    }
                }
                initialize(
                    model,
                    spaAsyncConfig,
                    registry,
                    moduleDefinitionsMap,
                    logger,
                    targetElementId,
                    AppComponent
                );
            });
        }
    );
}

function initialize(
    model: SpaModel,
    spaAsyncConfig: SpaAsyncConfig,
    registry: Registry,
    moduleDefinitionsMap: {
        moduleDef: FeatureServiceProviderDefinition<SharedFeatureService>;
        bundle: string;
    }[],
    logger: Logger,
    targetElementId: string,
    AppComponent: React.ComponentClass | React.FunctionComponent
) {
    const moduleDefinitions = moduleDefinitionsMap.map(
        ({moduleDef}) => moduleDef
    );
    const moduleDefinitionBundles = moduleDefinitionsMap.map(
        ({moduleDef, bundle}) => ({id: moduleDef.id, bundle: bundle})
    );

    // find feature services from loaded bundle, that are required by integrator/CMS (registered in registry)
    let serviceRegistrations = findServiceRegistrations(moduleDefinitions);

    const loginConfig = model.spaGlobalConfig.loginModel;

    // find feature services from loaded bundle, that are not required by integrator/CMS (not registered in registry)
    const services = findServices(moduleDefinitions, serviceRegistrations);

    // add all services that are only provided by integrator/CMS
    addServices(loginConfig, services, spaAsyncConfig, registry);

    const hydration = {hydrating: false};

    const pageRootModel = getPageRootModel(model);

    const serializedStates = getSerializedStatesFromDom();

    initializeHub(
        model.spaGlobalConfig,
        spaAsyncConfig,
        registry,
        loadAmdModule,
        'client',
        serviceRegistrations,
        services,
        moduleDefinitionBundles,
        logger,
        undefined,
        pageRootModel,
        {sanitize: createSanitizeForBrowser()},
        hydration,
        serializedStates
    );

    const mockEnabled = initializeDevMode(model, logger);
    registry.initSingletons('client', mockEnabled);

    // inform external listeners that the singletons are ready to receive calls.
    notifyInitialListeners(registry);

    const featureHub: FeatureHub = registry.getSingleton('FeatureHub');

    const waitForLoadables = readyForHydration(model, logger);
    loadAdditionalScript(spaAsyncConfig).then(() => {
        waitForLoadables(() => {
            const el = document.getElementById(targetElementId);

            if (el) {
                const featureHubContextData: FeatureHubContextProviderValue = {
                    featureAppManager: featureHub.featureAppManager
                };

                if (el.innerText.length > 0) {
                    Promise.all([
                        ...getUrlsForHydrationFromDom().map((url: string) =>
                            featureHub.featureAppManager.preloadFeatureApp(url)
                        ),
                        domReady()
                    ]).then(() => {
                        if (
                            model.spaGlobalConfig.clientsideMockEnabled &&
                            getGlobal().config.getBoolean('noHydration')
                        ) {
                            logger.general.warn('no hydration');
                            return;
                        }
                        logger.general.debug('start hydration');
                        hydration.hydrating = true;
                        ReactDom.hydrate(
                            <CmsRoot registry={registry}>
                                <FeatureHubContextProvider
                                    value={featureHubContextData}
                                >
                                    <AppComponent />
                                </FeatureHubContextProvider>
                            </CmsRoot>,
                            el,
                            () => {
                                hydration.hydrating = false;

                                // need to load from singleton because it might be overwritten by mock.
                                const timeout =
                                    model.spaGlobalConfig.personalizationConfig
                                        .timeout;
                                startTimeoutForPersonalization(logger, timeout);
                            }
                        );
                    });
                } else {
                    ReactDom.render(
                        <CmsRoot registry={registry}>
                            <FeatureHubContextProvider
                                value={featureHubContextData}
                            >
                                <AppComponent />
                            </FeatureHubContextProvider>
                        </CmsRoot>,
                        el
                    );
                }
            } else {
                logger.general.error('cannot find react mount point');
            }
        });
    });
}

function initializeDevMode(model: SpaModel, logger: Logger) {
    const mockEnabled = isDevMode(model.spaGlobalConfig);
    if (mockEnabled) {
        logger.general.warn('mocks are enabled');
    }
    return mockEnabled;
}

function startTimeoutForPersonalization(logger: Logger, timeout: number) {
    const initialPathname = window.location.pathname;
    setTimeout(() => {
        // escape hatch to show default if something goes wrong with personalization
        if (window.location.pathname === initialPathname) {
            PriorityPersonalizationLoader.showDefault();
            logger.personalization.debug('removing flicker defender');
        }
    }, timeout);
}

function loadAdditionalScript(spaAsyncConfig: SpaAsyncConfig) {
    return new Promise<void>(resolve => {
        const externalScript =
            spaAsyncConfig.featureHubModel.additionalScriptUrl;
        if (externalScript && isInBrowser()) {
            const script = document.createElement('script');
            script.src = externalScript;
            script.onload = () => resolve();
            script.onerror = () => {
                console.error('failed to load', externalScript);
                resolve();
            };
            document.head.appendChild(script);
        } else {
            resolve();
        }
    });
}

function addServices(
    loginConfig: LoginModel,
    services: FeatureServiceProviderDefinition<SharedFeatureService>[],
    spaAsyncConfig: SpaAsyncConfig,
    registry: Registry
) {
    if (loginConfig.enabled) {
        const authServiceConfig = createAuthServiceConfig(loginConfig);
        const authServiceConfigV2 = createAuthServiceConfigV2(loginConfig);
        if (authServiceConfig && authServiceConfigV2)
            services.push(
                defineAuthServiceConfig(authServiceConfig, authServiceConfigV2)
            );
    }
    services.push(
        createTrackingServiceConfig(
            spaAsyncConfig.applicationTrackingData,
            registry
        )
    );
}

function findServiceRegistrations(
    moduleDefinitions: FeatureServiceProviderDefinition<SharedFeatureService>[]
): ServiceRegistration<SharedFeatureService>[] {
    return moduleDefinitions
        .filter(moduleDefinition => CLIENT_SERVICES[moduleDefinition.id])
        .map(moduleDefinition => {
            return {
                definition: moduleDefinition,
                service: CLIENT_SERVICES[moduleDefinition.id],
                env: 'client'
            };
        }) as ServiceRegistration<SharedFeatureService>[];
}

function findServices(
    moduleDefinitions: FeatureServiceProviderDefinition<SharedFeatureService>[],
    serviceRegistrations: ServiceRegistration<SharedFeatureService>[]
): FeatureServiceProviderDefinition<SharedFeatureService>[] {
    return moduleDefinitions.filter(
        definition =>
            serviceRegistrations.find(
                serviceRegistration =>
                    serviceRegistration.definition.id === definition.id
            ) === undefined
    );
}
