import {loadableReady} from '@loadable/component';
import {SpaModel} from '../generated/core';
import {Logger} from './context/logger/Logger';
export function readyForHydration(model: SpaModel, logger: Logger) {
    if (model.spaGlobalConfig.clientsideMockEnabled) {
        // if mocks are enabled via OSGI then defer hydration for a specified time in session storage. This can be used to test timing issues related to hydration.

        const extraWaitTimeAsString = window.localStorage.getItem(
            'vwa_d6_cms.config.extra_wait_before_render'
        );

        if (extraWaitTimeAsString && extraWaitTimeAsString.match(/^\d+$/)) {
            const extraWaitTime = parseInt(extraWaitTimeAsString, 10);
            if (extraWaitTime > 0) {
                logger.general.debug(
                    'waiting an extra %s ms to hydrate application',
                    extraWaitTime
                );
                return (done?: () => any) => {
                    return new Promise(resolve => {
                        setTimeout(() => {
                            loadableReady(done);
                            resolve({});
                        }, extraWaitTime);
                    });
                };
            }
        }
    }
    return loadableReady;
}
