import {Model} from '@adobe/cq-react-editable-components';
import {usePersonalizationStore} from '../../context';
import {PersonalizationStore} from '../../context/personalization/PersonalizationStore';
import {TeaserPersonalization} from './TeaserPersonalization';
import {TeaserPersonalizationList} from './TeaserPersonalizationList';

function getTeaser(
    personalizationStore: PersonalizationStore,
    item: Model
): TeaserPersonalization | undefined {
    // FIXME for segment based personalization the path is not correct. For 1:1 the mboxId is significant and correct.
    const content = personalizationStore.getContent(
        item.originalCqPath,
        item.mboxId
    );
    if (typeof content == 'undefined') {
        return {
            personalizable: false,
            status: false,
            placeholderId: item.placeholderId,
            contentId: item.contentId
        };
    } else if (content.loaded && content.resource) {
        const model = content.resource;
        return {
            personalizable: true,
            status: true,
            placeholderId: item.placeholderId,
            contentId: model ? model.contentId : 'unknown'
        };
    } else if (content.loaded && !content.resource) {
        return {
            personalizable: true,
            status: false,
            placeholderId: item.placeholderId,
            contentId: item.contentId
        };
    } else {
        return undefined;
    }
}
export function filterTeaser(key: string, cqItems: {[key: string]: Model}) {
    return key.match(/item_\d+/) && cqItems.hasOwnProperty(key);
}

/**
 *
 * Calling functional component must be observed with mobx because loading state of personalized conttent is observed
 */
export function useTeaserList(
    personalizableChildren: boolean,
    cqItems: {
        [key: string]: Model;
    }
): TeaserPersonalizationList {
    const personalizationStore = usePersonalizationStore();

    if (!personalizableChildren) {
        const teasers = Object.keys(cqItems)
            .filter(key => filterTeaser(key, cqItems))
            .map(key => {
                const item = cqItems[key];
                return {
                    personalizable: false,
                    status: false,
                    placeholderId: item.placeholderId,
                    contentId: item.contentId
                };
            });
        return {
            loading: false,
            teasers
        };
    }

    const teaserListIncludingUndefined = Object.keys(cqItems)
        .filter(key => filterTeaser(key, cqItems))
        .map(key => getTeaser(personalizationStore, cqItems[key]));

    const teaserList = teaserListIncludingUndefined.filter(
        t => typeof t !== 'undefined'
    );
    return {
        loading: teaserList.length < teaserListIncludingUndefined.length,
        teasers: teaserList as TeaserPersonalization[]
    };
}
