import {BreadcrumbLinkModel} from '../../../generated/api';

export const BreadcrumbStoreId = 'BreadcrumbStore';

export interface Breadcrumb extends BreadcrumbLinkModel {
    onClick?: () => void;
}

export interface BreadcrumbStore {
    links: Breadcrumb[];
    homeLabel: string;
    ariaLabel: string;
}
