import {observer} from 'mobx-react-lite';
import * as React from 'react';

import {Degree180ElementModel} from '../../../../../generated/core';
import {
    AuthoringMediaInfoBox,
    MediaInfo
} from '../../../../components/AuthoringMediaInfoBox';
import {AuthoringWrapper} from '../../../../components/AuthoringWrapper';
import {
    DragData,
    DraggableVideo
} from '../../../../components/draggable-video/DraggableVideo';

import {
    Degree180Context,
    Degree180Store
} from '../../../../context/media/Degree180Store';
import {MediaContextData} from '../../../../context/media/MediaContext';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {ImagePlaceHolderElement} from '../ImagePlaceHolderElement';
import {MediaDisclaimerHolder} from '../MediaElement';
import {VideoIcon} from '../media/icons';
import {getElementSize, getVideoRendition} from '../videoElement/helpers';
import {ResponsiveMediaRendererConf} from '../../../../components/ResponsiveMediaRenderer';

const LABEL = '180 Degree Element';

export interface Degree180ElementExtraProps {
    readonly storeId?: string;
    readonly matchParent?: boolean;
    readonly interactive?: boolean;
    readonly responsiveMediaConfig?: ResponsiveMediaRendererConf[];
}

export interface Degree180ElementProps
    extends Degree180ElementModel,
        Degree180ElementExtraProps,
        MediaDisclaimerHolder {
    readonly mediaContext?: MediaContextData;
}

function useDegree180Context(): Degree180Store {
    return React.useContext(Degree180Context) as Degree180Store;
}

const InternalDegree180Element: React.FunctionComponent<Degree180ElementProps> = observer(
    function D180E(props: Degree180ElementProps) {
        const {
            coverImage,
            aspectRatio,
            responsiveMediaConfig,
            videoRenditions,
            disclaimers,
            fileReference,
            interactive = true,
            matchParent,
            progressBarAriaLabel,
            title,
            videoMediaInfo,
            videoImageSrc
        } = props;

        const isInEditor = C.isInEditor();

        const draggableVideo = React.useRef(null);
        const degree180Store: Degree180Store = useDegree180Context();

        function updateVideoSrc(): void {
            const size = getElementSize(draggableVideo);
            const rend = getVideoRendition(videoRenditions, aspectRatio, size);

            // this will cause re-render only when it gets updated
            degree180Store.updateSrc(rend);
        }

        function handleDragChanged(data?: DragData): void {
            degree180Store.updateDrag(data);
        }

        React.useEffect(updateVideoSrc);

        const videoState = degree180Store;
        const isFullscreen = videoState.isFullscreen;
        const dragDirection = videoState.dragDirection;
        const currentTime = videoState.currentTime;
        const videoSrc = videoState.videoSrc;
        const animateOnLoad = !isInEditor && !isFullscreen;

        if (!isInEditor && !fileReference) {
            return null;
        }
        const component = (
            <DraggableVideo
                innerRef={draggableVideo}
                currentTime={currentTime}
                isFullscreen={isFullscreen}
                src={videoSrc}
                dragDirection={dragDirection}
                onDragChanged={handleDragChanged}
                animateOnLoad={animateOnLoad}
                interactive={!isInEditor && interactive}
                disclaimers={disclaimers}
                matchParent={matchParent}
                title={title}
                progressBarAriaLabel={progressBarAriaLabel}
                coverImage={{
                    ...coverImage,
                    src: coverImage?.src || videoImageSrc,
                    focalPoint: 'CENTER_CENTER',
                    aspectRatio
                }}
                responsiveMediaConfig={responsiveMediaConfig}
            />
        );

        return !isInEditor ? (
            component
        ) : (
            <AuthoringWrapper
                title={LABEL}
                bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
            >
                <AuthoringMediaInfoBox>
                    {coverImage?.mediaInfo && (
                        <MediaInfo {...coverImage.mediaInfo} />
                    )}
                    {videoMediaInfo && <MediaInfo {...videoMediaInfo} />}
                </AuthoringMediaInfoBox>
                {!fileReference ? (
                    <ImagePlaceHolderElement>
                        <VideoIcon />
                    </ImagePlaceHolderElement>
                ) : (
                    component
                )}
            </AuthoringWrapper>
        );
    }
);

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/degree180Element';

export const Degree180Element = MapTo<Degree180ElementExtraProps>(
    RESOURCE_TYPE,
    InternalDegree180Element
);
