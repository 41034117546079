import {SpaGlobalConfig} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export const WHITE_SPACE_MANAGER = 'WhitespaceManager';

@singleton(WHITE_SPACE_MANAGER)
export class WhitespaceManager {
    public debugging: boolean = false;

    @inject()
    private spaGlobalConfig?: SpaGlobalConfig;

    @postConstruct()
    public initialize(): void {
        const mockEnabled =
            this.spaGlobalConfig?.clientsideMockEnabled || false;
        this.debugging =
            isInBrowser() &&
            mockEnabled &&
            new URLSearchParams(window.location.search).has('whitespace');
    }
}
