type distanceToMoveElementIntoViewOptions = {
    safeGuardingDistance?: number;
    customScrollingDistance?: number;
};

type elementPositionDimensions = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
};

export enum ElementPositions {
    ABOVE_VIEWPORT = 'aboveViewport',
    RIGHT_OF_VIEWPORT = 'rightOfViewport',
    BELOW_VIEWPORT = 'belowViewport',
    LEFT_OF_VIEWPORT = 'leftOfViewport',
    IN_VIEWPORT = 'inViewport'
}

export const getElementPositionRelativeToViewport = (
    {top, right, bottom, left}: elementPositionDimensions,
    safeGuardingDistance = 0
): ElementPositions => {
    if (top && top < safeGuardingDistance) {
        return ElementPositions.ABOVE_VIEWPORT;
    }

    if (right && right > window.innerWidth - safeGuardingDistance) {
        return ElementPositions.RIGHT_OF_VIEWPORT;
    }

    if (bottom && bottom > window.innerHeight - safeGuardingDistance) {
        return ElementPositions.BELOW_VIEWPORT;
    }

    if (left && left < safeGuardingDistance) {
        return ElementPositions.LEFT_OF_VIEWPORT;
    }
    return ElementPositions.IN_VIEWPORT;
};

const distanceToGetIntoViewPortFrom = (
    currentPosition: ElementPositions,
    {top, right, bottom, left}: Required<elementPositionDimensions>,
    {customScrollingDistance = 0}: distanceToMoveElementIntoViewOptions
): number =>
    ({
        [ElementPositions.ABOVE_VIEWPORT]: -(
            Math.abs(top) + customScrollingDistance
        ),
        [ElementPositions.RIGHT_OF_VIEWPORT]:
            right - window.innerWidth + customScrollingDistance,
        [ElementPositions.BELOW_VIEWPORT]:
            bottom - window.innerHeight + customScrollingDistance,
        [ElementPositions.LEFT_OF_VIEWPORT]: -(
            Math.abs(left) + customScrollingDistance
        ),
        [ElementPositions.IN_VIEWPORT]: 0
    }[currentPosition]);

export const getDistanceToMoveElementIntoView = (
    element: HTMLElement,
    options: distanceToMoveElementIntoViewOptions = {}
) => {
    const {top, right, bottom, left} = element.getBoundingClientRect();

    const relativePosition = getElementPositionRelativeToViewport(
        {top, right, bottom, left},
        options.safeGuardingDistance
    );
    const valueToScrollByToBeInViewAgain = distanceToGetIntoViewPortFrom(
        relativePosition,
        {top, right, bottom, left},
        options
    );

    return valueToScrollByToBeInViewAgain;
};
