import {styled} from '@volkswagen-onehub/components-core';
import React from 'react';
import {ScrollytellingSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {SectionWrapper} from './SectionWrapper';
import {ContentBlock, ScrollableVideo} from './scrollytelling';
import {Intro} from './scrollytelling/Intro';
import {ScrollableVideoElement} from './scrollytelling/Video';

const AUTHOR_PLACEHOLDER_CONTENT: ContentBlock[] = [];
const AUTHOR_PLACEHOLDER_SKIPBUTTON = <></>;

const StyledScrollableVideo = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0150};
`;

function PublishView(props: ScrollytellingSectionModel): JSX.Element | null {
    const {duration, cqItems} = props;

    const showHeadline = !Boolean(cqItems?.heading?.empty);
    const showCopy = !Boolean(cqItems?.copy?.empty);

    if (!duration) {
        return null;
    }

    return (
        <>
            <Intro showHeadline={showHeadline} showCopy={showCopy} />
            <ScrollableVideo {...props} />
        </>
    );
}

function AuthorView(): JSX.Element {
    return (
        <>
            <Intro showHeadline showCopy />
            <ScrollableVideoElement
                content={AUTHOR_PLACEHOLDER_CONTENT}
                skipButton={AUTHOR_PLACEHOLDER_SKIPBUTTON}
            />
            <Parsys
                path="scrollytellingSectionParsys"
                parsysType={ParsysType.ITEM_PARSYS}
            />
        </>
    );
}

function InternalScrollytellingSection(
    props: ScrollytellingSectionModel
): JSX.Element {
    const {
        anchorId,
        contentName,
        contentLabels,
        contentId: sectionId,
        mboxId
    } = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S138 Scrollytelling Section"
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <StyledScrollableVideo>
                        {C.isInEditor() ? (
                            <AuthorView />
                        ) : (
                            <PublishView {...props} />
                        )}
                        <BelowSectionDisclaimerContainer
                            color={'light'}
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </StyledScrollableVideo>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const ScrollytellingSection = MapTo(
    'vwa-ngw18/components/editorial/sections/scrollytellingSection',
    InternalScrollytellingSection,
    {noElementRecursively: true}
);
