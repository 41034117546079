import {RootLocationDescriptorObject} from '@feature-hub/history-service';
import {parsePath, parseSuffix} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';
import {URLSearchParams} from '../../../utils/UrlSearchParams';
export function getSecondaryConsumerPath(
    rootLocation: RootLocationDescriptorObject,
    secondaryKey: string,
    paramSuffix: string
): string {
    const secondaryParams = new URLSearchParams();
    const searchParams = new URLSearchParams(rootLocation.search);
    const path = parsePath(rootLocation.pathname || '/');
    searchParams.forEach((value, key) => {
        if (key.endsWith(paramSuffix)) {
            const normalizedKey = key.substring(
                0,
                key.length - paramSuffix.length
            );
            secondaryParams.append(normalizedKey, value);
        }
    });
    const search = secondaryParams.toString();
    if (path.suffix || search) {
        const pathNameWithoutExtension = path.suffix
            ? parseSuffix(path.suffix)[secondaryKey]
            : '/';

        return history.createPath({
            pathname: pathNameWithoutExtension,
            search
        });
    }

    return '';
}
