import * as React from 'react';

import {
    NextBestActionButton as D6NextBestActionButton,
    NextBestAction as D6NextBestAction
} from '../../../d6/components/next-best-action-button';

import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getIcon} from '../../../utils/getMappedIcon';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {useScrollToSectionOfFocussedNbab} from './useScrollToSectionOfFocussedNbab';
import {useSingleNbabTracking} from './useSingleNbabTracking';
import {MobileNextBestActionButton} from './MobileNextBestActionButton';
import {useExpandOnScroll} from './useExpandOnScroll';
import {SectionNbabTrackingData} from './getActions';

export type NextBestActionButtonProps = Readonly<{
    label: string;
    actions?: MergeNextBestActionModel[];
    isHidden?: boolean;
    inaccessible?: boolean; // "true" value makes the button inaccessible for keyboard and screen-reader
    trackingData?: SectionNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
    icon: string;
    title?: string;
}>;

export function MobileShortcutButton({
    // enabled,
    inaccessible,
    isHidden,
    label,
    icon,
    title,
    sectionRef,
    actions,
    trackingData
}: NextBestActionButtonProps): JSX.Element | null {
    const [displayActions, setDisplayActions] = React.useState<boolean>(false);
    const [forceExpand, setForceExpand] = React.useState<boolean | undefined>(
        undefined
    );
    const ref = React.useRef(null);

    const itemsLength = actions?.length;
    const itemCount = (itemsLength || 0) + 1;

    useScrollToSectionOfFocussedNbab(ref, sectionRef);

    // show / hide actions
    React.useEffect(() => {
        if (isHidden) {
            setDisplayActions(false);
        }
    }, [isHidden]);

    useExpandOnScroll(setForceExpand, setDisplayActions);

    const trackNextBestActionButtonClick = useSingleNbabTracking(label);

    // action handling
    const actionClick = () => {
        setDisplayActions(!displayActions);

        if (!displayActions) {
            trackNextBestActionButtonClick(
                'action:open-sub-nbabs',
                'open-sub-nbabs'
            );
        }
    };

    // create next best action
    const href = undefined;
    const action = {
        content: label,
        icon: getIcon(icon, 'default'),
        tag: 'button',
        href,
        onClick: actionClick,
        ariaLabel: label,
        title: title
    } as D6NextBestAction;

    // create actions
    const renderActions = (
        actionModels: MergeNextBestActionModel[]
    ): JSX.Element[] => {
        return actionModels.map(
            (nbabModel: MergeNextBestActionModel, index: number) => (
                <MobileNextBestActionButton
                    actionModel={nbabModel}
                    key={index}
                    inaccessible={!displayActions}
                    trackingData={{
                        ...trackingData,
                        itemCount: itemCount,
                        itemPosition: actionModels.indexOf(nbabModel) + 2,
                        personalizedReporting: nbabModel.personalizedReporting
                    }}
                />
            )
        );
    };

    const subActionsRender = renderActions(actions || []);

    return (
        <CyAttributeAppender name="NextBestActionButton">
            <D6NextBestActionButton
                subActions={subActionsRender}
                action={action}
                ref={ref}
                tabIndex={inaccessible ? -1 : 0}
                displaySubActions={displayActions}
                hideIcon={isHidden}
                expandContent={forceExpand && !displayActions}
                title={title}
                hideBadge
                isHidden={isHidden}
                closeSubactions={() => setDisplayActions(false)}
            />
        </CyAttributeAppender>
    );
}
