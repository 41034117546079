import * as React from 'react';

import {
    CTAProps,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';

import {LinkElementModel, LinkWindowTarget} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {CmsButtonLink} from '../../../components/links/CmsButtonLink';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {Richtext} from '../../../components/Richtext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {processContentId} from '../../../utils/processContentId';

export class LinkElementComponent extends React.PureComponent<
    Partial<LinkElementModel> & Omit<CTAProps, 'tag'>
> {
    public render(): JSX.Element | null {
        const {
            linkUrl,
            linkTarget = '_self',
            linkTitle,
            linkLabelRichtext,
            layerLink,
            layerPath,
            documentPath,
            downloadLink,
            layerType = 'CONTENT',
            originalCqPath,
            validationMessages
        } = this.props;

        const contentId = processContentId(originalCqPath);
        const href =
            !downloadLink && !layerLink
                ? linkUrl
                : layerLink
                ? layerPath
                : documentPath;

        const alertBox = validationMessages && C.isInEditor() && (
            <AlertBox severity={AlertBoxSeverity.WARNING}>
                {validationMessages.map((msg, idx) => (
                    <span key={idx}>{msg}</span>
                ))}
            </AlertBox>
        );

        const isValidLink = Boolean(href && linkLabelRichtext);
        const showLink = C.isInEditor() || isValidLink;

        return showLink ? (
            <Text appearance={TokenTextAppearance.copy200} bold>
                {alertBox}
                <CyAttributeAppender name="linkElement">
                    {layerLink ? (
                        <CmsButtonLink
                            href={href}
                            title={linkTitle}
                            target={linkTarget as LinkWindowTarget}
                            isLayerLink
                            layerProps={{layerType}}
                            contentId={contentId}
                            emphasis="tertiary"
                        >
                            <Richtext
                                richtext={linkLabelRichtext || []}
                                inheritColor
                                tag={TextTag.span}
                                placeholder="Type your link label..."
                            />
                        </CmsButtonLink>
                    ) : (
                        <CmsTextLink
                            href={href}
                            title={linkTitle}
                            target={linkTarget as LinkWindowTarget}
                            isDownloadLink={downloadLink}
                            layerProps={{layerType}}
                            showExternalIcon
                            contentId={contentId}
                            emphasis="tertiary"
                        >
                            <Richtext
                                richtext={linkLabelRichtext || []}
                                inheritColor
                                tag={TextTag.span}
                                placeholder="Type your link label..."
                            />
                        </CmsTextLink>
                    )}
                </CyAttributeAppender>
            </Text>
        ) : null;
    }
}

export const LinkElement = MapTo(
    'vwa-ngw18/components/editorial/elements/linkElement',
    LinkElementComponent
);
