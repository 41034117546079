import * as React from 'react';

import {
    CTA,
    Container,
    ContainerGutter,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';

import {FlyoutLoggedInModel} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {createLinkHandler} from '../../../utils/link/createLinkHandler';
import {elseUndefined} from '../../../utils/elseUndefined';
import {processContentId} from '../../../utils/processContentId';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {
    useLoginAlertService,
    useLoginFlyoutService,
    useLoginStore,
    useRouterService,
    useTrackingService
} from '../../../context';
import {LOGIN_BTN_LABEL, LOGIN_FLYOUT_AUTHOR_MSG_LOG_IN} from './constants';
import {observer} from 'mobx-react-lite';
import {FlyoutLoggedInPublish} from './FlyoutLoggedInPublish';
import {createBtnSettingsClick} from './createBtnSettingsClick';
import {createBtnLogoutClick} from './createBtnLogoutClick';

const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

const ObservedFlyoutLoggedIn = observer(function InternalFlyoutLoggedIn(
    props: FlyoutLoggedInModel
): JSX.Element {
    const loginStore = useLoginStore();
    const loginFlyoutService = useLoginFlyoutService();
    const routerService = useRouterService();
    const loginAlertService = useLoginAlertService();
    const trackingService = useTrackingService();

    const {userData, organizationData} = loginStore;
    const hasCommercialProfileUrl = loginStore.hasCommercialProfileUrl();
    const addCommercialSettingsHandle = Boolean(
        organizationData && hasCommercialProfileUrl
    );

    const nextActionHref = props.btnNextActionHref;

    // property provided by server can produce null
    const nextActionLabel = props.btnNextActionLabel || LOGIN_BTN_LABEL;

    const headingElement = <HeadingElement path="heading" order="H2" />;
    const copyElement = <CopyItem path="copy" />;

    const handleBtnNextActionClick = (e: React.MouseEvent) => {
        const {originalCqPath, btnNextActionLabel, btnNextActionHref} = props;
        createLinkHandler(routerService, btnNextActionHref)(e);
        loginFlyoutService.closeFlyout(true);
        trackingService.trackButtonClick(
            processContentId(originalCqPath, 'Login Flyout - Logged In'),
            btnNextActionHref,
            btnNextActionLabel
        );
    };

    const buttonElement = elseUndefined(
        nextActionHref,
        <CTA
            tag="a"
            href={nextActionHref}
            emphasis="primary"
            onClick={handleBtnNextActionClick}
        >
            {nextActionLabel}
        </CTA>
    );

    const handleBtnLogoutClick = createBtnLogoutClick(
        loginStore,
        loginFlyoutService,
        loginAlertService,
        props.btnCloseLabel
    );

    const handleBtnSettingsClick = createBtnSettingsClick(
        userData,
        loginStore,
        addCommercialSettingsHandle
    );

    const authorTitle = props.cqPath.includes('flyoutLoggedInCommercial')
        ? 'Login Flyout - Logged In - Commercial Customer'
        : 'Login Flyout - Logged In';

    const renderAuthor = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode
    ): JSX.Element => (
        <AuthoringWrapper
            title={authorTitle}
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <AlertBox severity={AlertBoxSeverity.INFO}>
                <div>{LOGIN_FLYOUT_AUTHOR_MSG_LOG_IN}</div>
            </AlertBox>
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={{
                        left: CONTAINER_PADDING_2_6,
                        right: CONTAINER_PADDING_2_6
                    }}
                    stretchContent
                >
                    {heading}
                    {copy}
                    {button}
                    <div>Logout i18n label: {props.btnLogoutLabel}</div>
                    <div>Settings i18n label: {props.btnSettingsLabel}</div>
                </Container>
            </Layout>
        </AuthoringWrapper>
    );

    const renderPublish = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode
    ): JSX.Element => (
        <FlyoutLoggedInPublish
            heading={heading}
            copy={copy}
            button={button}
            handleBtnLogoutClick={handleBtnLogoutClick}
            handleBtnSettingsClick={handleBtnSettingsClick}
            {...props}
        />
    );

    return C.isInEditor()
        ? renderAuthor(headingElement, copyElement, buttonElement)
        : renderPublish(headingElement, copyElement, buttonElement);
});

export const FlyoutLoggedIn = MapTo(
    'vwa-ngw18/components/structure/login/flyoutLoggedIn',
    ObservedFlyoutLoggedIn
);
