import * as React from 'react';

interface CarouselItemContextData {
    readonly active: boolean;
}

export const CarouselItemContextV2: React.Context<CarouselItemContextData> = React.createContext<
    CarouselItemContextData
>({active: true});

export class CarouselItemContextProvider extends React.PureComponent<
    CarouselItemContextData
> {
    public render(): JSX.Element {
        const {active, children} = this.props;

        return (
            <CarouselItemContextV2.Provider value={{active}}>
                {children}
            </CarouselItemContextV2.Provider>
        );
    }
}
