import * as React from 'react';
import {action, computed, observable} from 'mobx';

import {SkipLink, SkipLinksStore, SkipLinksStoreId} from './SkipLinksStore';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {SkipLinksConfig, SpaAsyncConfig} from '../../../generated/core';

export type SkipLinkKey = keyof SkipLinksConfig;

type SkipLinkClickHandlers = {
    [key in SkipLinkKey]?: React.MouseEventHandler<HTMLElement>;
};

@singleton(SkipLinksStoreId)
export class ClientSkipLinksStore implements SkipLinksStore {
    @inject()
    private spaAsyncConfig!: SpaAsyncConfig;

    @observable
    private skipLinkClickHandlers: SkipLinkClickHandlers;

    public skipLinksWrapperLabel: string;

    public constructor() {
        this.skipLinkClickHandlers = {};
        this.skipLinksWrapperLabel = '';
    }

    @postConstruct()
    public init() {
        this.skipLinksWrapperLabel = this.spaAsyncConfig.skipLinkModel.skipLinksWrapperLabel;
    }

    @computed
    public get skipLinks(): SkipLink[] {
        const skipLinks = [];
        for (const key in this.spaAsyncConfig.skipLinkModel.skipLinks) {
            const skipLinkItem = this.spaAsyncConfig.skipLinkModel.skipLinks[
                key as SkipLinkKey
            ];

            const skipLinkClickHandler = this.skipLinkClickHandlers[
                key as SkipLinkKey
            ];

            skipLinks.push({...skipLinkItem, onClick: skipLinkClickHandler});
        }

        return skipLinks;
    }

    @action
    public setSkipLinkClickHandler(
        key: SkipLinkKey,
        ref?: React.RefObject<HTMLElement>,
        customClickHandler?: React.MouseEventHandler<HTMLElement>
    ): void {
        let defaultClickHandler: React.MouseEventHandler<HTMLElement>;
        if (ref) {
            defaultClickHandler = this.createDefaultClickHandler(ref);
        }

        this.skipLinkClickHandlers[key] = (
            e: React.MouseEvent<HTMLElement>
        ): void => {
            e.preventDefault();
            defaultClickHandler && defaultClickHandler(e);
            customClickHandler && customClickHandler(e);
        };
    }

    private createDefaultClickHandler(
        ref: React.RefObject<HTMLElement>
    ): React.MouseEventHandler<HTMLElement> {
        return () => {
            const element = ref.current;
            if (element !== null) {
                element.scrollIntoView();
                element.focus();
            }
        };
    }
}
