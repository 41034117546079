import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';
import {EditorialTeaser} from '../../../d6/components/editorial-teaser';
import {MobileCarouselV2} from '../../../d6/components/mobile-carousel-v2';

import {EditorialTeaserSectionModel} from '../../../../generated/core';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {
    MediaContext,
    MediaContextData
} from '../../../context/media/MediaContext';
import {useCarouselState} from '../../../hooks/useCarouselState';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {useTeaserList} from '../../../utils/tracking/useTeaserList';
import {EditorialTeaserElement} from '../elements/editorial-teaser-element/EditorialTeaserElement';
import {SectionWrapper} from './SectionWrapper';
import {getAspectRatioByIndex} from './editorial-teaser/helpers';
import {
    TeaserSectionAuthoringView,
    teaserSectionHeading
} from './teasers/common';
import {TeaserPersonalizationList} from '../../../utils/tracking/TeaserPersonalizationList';
import {observer} from 'mobx-react-lite';
import {useXOfYLabel} from '../../../components/highlight-feature/HighlightGallery';
import {MoodGalleryMobile} from '../../../d6/components/mood-gallery-mobile';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useTeaserMboxIds} from '../../../utils/tracking/useTeaserMboxIds';

export const StyledMobileWrapper = styled.div`
    display: block;

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;
StyledMobileWrapper.displayName = 'StyledMobileWrapper';

const StyledTabletWrapper = styled.div`
    display: none;

    @media (min-width: ${Breakpoints.b560}px) {
        display: block;
    }
`;
StyledTabletWrapper.displayName = 'StyledTabletWrapper';

interface TeaserSectionPublishViewProps {
    anchorId: string;
    heading?: JSX.Element;
    teaserItems: JSX.Element[];
    sectionId: string;
    bigImageInFirstRow: boolean;
    teaserList?: TeaserPersonalizationList;
    translations: {[key: string]: string};
    contentName: string;
    contentLabels: string;
    paginationLabels: string[];
    mboxId: string;
    teaserMboxIds: string[];
}

const mobileTeaserMediaSizes: MediaContextData = {
    ratio: 'r_1_1',
    sizes: '100vw'
};

function TeaserSectionPublishView(
    props: TeaserSectionPublishViewProps
): JSX.Element {
    const {
        anchorId,
        mboxId,
        sectionId,
        contentLabels,
        teaserList,
        contentName,
        heading,
        teaserItems,
        paginationLabels,
        bigImageInFirstRow,
        translations,
        teaserMboxIds
    } = props;
    const [activeItem, handleSlideChange] = useCarouselState(0);

    const getXOfYLabel = useXOfYLabel(translations['carousel.slideXOfYLabel']);

    return (
        <CyAttributeAppender name="editorialTeaserSection">
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                teaserList={teaserList}
                contentName={contentName}
                teaserMboxIds={teaserMboxIds}
            >
                <StyledTabletWrapper>
                    <EditorialTeaser
                        headlineElement={heading}
                        items={React.Children.map(
                            teaserItems,
                            (teaserItem, idx) => (
                                <MediaContext.Provider
                                    value={{
                                        ratio: getAspectRatioByIndex(
                                            idx,
                                            bigImageInFirstRow
                                        ),
                                        sizes: '30vw'
                                    }}
                                >
                                    {teaserItem}
                                </MediaContext.Provider>
                            )
                        )}
                    />
                </StyledTabletWrapper>
                <StyledMobileWrapper>
                    <MediaContext.Provider value={mobileTeaserMediaSizes}>
                        <MoodGalleryMobile claim={heading}>
                            <MobileCarouselV2
                                activeSlide={activeItem}
                                onSlideChanged={handleSlideChange}
                                paginationItemLabels={paginationLabels}
                                paginationDefaultItemLabel={
                                    translations[
                                        'moodGallery.carouselItemLabel'
                                    ]
                                }
                                getXOfYLabel={getXOfYLabel}
                                galleryLabel={
                                    translations['carousel.galleryLabel']
                                }
                                paginationLabel={
                                    translations['carousel.gallerySlidesLabel']
                                }
                                carouselId={`${sectionId}-carousel`}
                            >
                                {teaserItems}
                            </MobileCarouselV2>
                        </MoodGalleryMobile>
                    </MediaContext.Provider>
                </StyledMobileWrapper>
            </SectionWrapper>
        </CyAttributeAppender>
    );
}

const InternalEditorialTeaserSection = observer(function ETS(
    props: EditorialTeaserSectionModel
) {
    const {
        anchorId,
        paginationLabels,
        validationError,
        validationMessages,
        contentId: sectionId,
        cqItems,
        tagTypeBroken,
        personalizableChildren,
        translations,
        mboxId
    } = props;

    const teaserList = useTeaserList(personalizableChildren, cqItems);
    const teaserMboxIds = useTeaserMboxIds(personalizableChildren, cqItems);

    // error in publish view, show nothing
    if (validationError && !C.isInEditor()) {
        return null;
    }

    if (C.isInEditor()) {
        // authoring view
        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <TeaserSectionAuthoringView
                    anchorId={anchorId}
                    emptyLabel={'S108 Editorial Teaser Section'}
                    validationMessages={validationMessages}
                    validationError={validationError}
                    tagTypeBroken={tagTypeBroken}
                >
                    <EditorialTeaser
                        headlineElement={teaserSectionHeading}
                        items={renderItems(props)}
                    />
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </TeaserSectionAuthoringView>
            </GeneralDisclaimerProvider>
        );
    } else {
        // publish view

        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <TeaserSectionPublishView
                    anchorId={anchorId}
                    heading={elseUndefined(
                        !cqItems.heading.empty,
                        teaserSectionHeading
                    )}
                    teaserItems={renderItems(props)}
                    sectionId={sectionId}
                    teaserList={teaserList}
                    contentLabels={props.contentLabels}
                    contentName={props.contentName}
                    bigImageInFirstRow={props.bigImageInFirstRow}
                    paginationLabels={paginationLabels}
                    translations={translations}
                    mboxId={mboxId}
                    teaserMboxIds={teaserMboxIds}
                />
                <SectionDisclaimerContainer
                    displayTypes={['T3_SECTION_BASED']}
                />
            </GeneralDisclaimerProvider>
        );
    }
});

function renderItems(props: EditorialTeaserSectionModel): JSX.Element[] {
    const {
        numberOfValidElements,
        showAdditionalLink,
        showCategoryElement,
        personalizable,
        personalizableChildren
    } = props;
    const items: JSX.Element[] = [];

    for (let i = 0; i < numberOfValidElements; i++) {
        const itemId = `item_${i}`;

        items.push(
            <EditorialTeaserElement
                path={itemId}
                key={itemId}
                personalizable={!personalizable && personalizableChildren}
                displayType="DEFAULT"
                showAbstractElement={false}
                showAdditionalLink={showAdditionalLink}
                showCategoryElement={showCategoryElement}
                itemCount={numberOfValidElements}
                itemPosition={i + 1}
            />
        );
    }

    return items;
}

export const EditorialTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/editorialTeaserSection',
    InternalEditorialTeaserSection
);
