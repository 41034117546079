import * as React from 'react';
import {getEndDirection} from '../helpers';
import {styled} from '@volkswagen-onehub/components-core';

// TODO: use Text with type "bodyBold200" instead
const StyledBullet = styled.div`
	font-weight: bold;
	padding-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.static250};
	vertical-align: middle;
	font-size: ${props => props.theme.fonts.copy[200].fontSize};
	line-height: ${props => props.theme.fonts.copy[200].lineHeight};
	display: inline-block;
`;

const ContentWrapper = styled.div`
    display: table-row;
`;

const StyledOrderedList = styled.ol`
    list-style-type: none;
    counter-reset: orderedlistcounter;

    & > li {
        counter-increment: orderedlistcounter;
    }

    & > li > ${ContentWrapper} > ${StyledBullet}:before {
        content: counter(orderedlistcounter, decimal-leading-zero);
        word-break: normal;

        &:lang(ko) {
            word-break: keep-all;
        }
    }
`;

const StyledUnorderedList = styled.ul`
    list-style-type: none;

    & > li > ${ContentWrapper} > ${StyledBullet}:before {
        content: ' ';
        display: block;
        height: 2px;
        width: 1.125em;
        background-color: ${props => props.theme.text.color.primary};
    }
`;

const StyledListItem = styled.li`
    margin-bottom: calc(${props => props.theme.size.dynamic0020} * 2);
`;

const TextWrapper = styled.div`
    display: table-cell;
`;

export interface InternalListProps {
    readonly children: React.ReactNode;
    readonly component: React.ComponentType;
}

const List: React.FunctionComponent<InternalListProps> = props => {
    const {children, component: Component} = props;
    const childElements: React.ReactElement<unknown>[] = React.Children.toArray(
        children
    ).filter(React.isValidElement);

    if (!childElements.length) {
        return null;
    }

    return (
        <Component>
            {childElements.map(child => {
                const key = child.key === null ? undefined : child.key;

                return (
                    <StyledListItem key={key}>
                        <ContentWrapper>
                            <StyledBullet />
                            <TextWrapper>{child}</TextWrapper>
                        </ContentWrapper>
                    </StyledListItem>
                );
            })}
        </Component>
    );
};

export const OrderedList: React.FunctionComponent = props => {
    const {children} = props;

    return <List component={StyledOrderedList}>{children}</List>;
};

export const UnorderedList: React.FunctionComponent = props => {
    const {children} = props;

    return <List component={StyledUnorderedList}>{children}</List>;
};
