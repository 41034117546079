import {FeatureServiceConsumerDefinition} from '@feature-hub/core';
import {Logger} from './Logger';

export const monitoringHelper = (logger: Logger) => ({
    navigation: (
        consumerId: string,
        consumerName: string | undefined,
        pageId: string
    ) => {
        if (!consumerId) {
            return;
        }
        logger.monitorEvent(
            {
                type: 'monitor',
                monitorType: 'navigation',
                consumerId,
                consumerName,
                pageId
            },
            consumerId + consumerName + pageId,
            10
        );
    },
    embedFeatureApp: (
        consumerId: string,
        consumerName: string | undefined,
        featureAppName: string
    ) => {
        if (!consumerId) {
            return;
        }
        logger.monitorEvent(
            {
                type: 'monitor',
                monitorType: 'embed-feature-app',
                consumerId,
                consumerName,
                featureAppName
            },
            consumerId + consumerName + featureAppName,
            10
        );
    },
    useServiceConfiguration: (
        consumerId: string,
        consumerName: string | undefined,
        serviceConfigKey: string
    ) => {
        if (!consumerId) {
            return;
        }
        logger.monitorEvent(
            {
                type: 'monitor',
                monitorType: 'use-service-config',
                consumerId,
                consumerName,
                serviceConfigKey
            },
            consumerId + consumerName + serviceConfigKey,
            1
        );
    },
    createFeatureApp: (
        featureAppName: string,
        featureServiceConsumerDefinition: FeatureServiceConsumerDefinition
    ) => {
        if (!featureAppName || !featureServiceConsumerDefinition) {
            return;
        }
        logger.monitorEvent(
            {
                type: 'monitor',
                monitorType: 'create-feature-app',
                featureAppName,
                featureServiceConsumerDefinition
            },
            featureAppName,
            10
        );
    },
    initializeIntegrator: (
        integratorTemplate: string,
        isWhiteListed: boolean
    ) => {
        if (!integratorTemplate) {
            return;
        }
        logger.monitorEvent(
            {
                type: 'monitor',
                monitorType: 'integrator-page',
                integratorTemplate,
                isWhiteListed
            },
            integratorTemplate,
            100
        );
    }
});
