import * as React from 'react';

import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {Parsys, ParsysType} from '../Parsys';
import {MainBase} from '../main/MainBase';

export class InternalLoginMain extends React.Component {
    public render(): JSX.Element {
        return (
            <MainBase>
                <Parsys parsysType={ParsysType.MAIN_PARSYS} path="mainParsys" />
            </MainBase>
        );
    }
}

export const LoginMain = MapTo(
    'vwa-ngw18/components/structure/login/loginMain',
    InternalLoginMain
);
