import * as React from 'react';

import {BaseCmsLinkProps, ClickTargetElement} from './CmsLink';
import {Registry} from '../../infrastructure/di/Registry';
import {
    ContextTrackingData,
    TrackingService
} from '../../context/tracking/TrackingService';
import {actionHandler} from './action/actionHandler';
import {configureHandler} from './configure/configureHandler';
import {liflHandler} from './lifl/liflHandler';

export const CONFIGURE_PROTOCOL = 'configure:/';
export const LIFL_PROTOCOL = 'lifl:/';
export const ACTION_PROTOCOL = 'action:';

export type LinkHandlerType = (
    href: string,
    props: LinkHandlerProps
) => [React.EventHandler<React.SyntheticEvent>, string] | undefined;

export type LinkHandlerProps = {
    registry: Registry;
    trackingService: TrackingService;
    baseLinkProps: BaseCmsLinkProps;
    trackingData?: ContextTrackingData;
    linkRef?: React.MutableRefObject<ClickTargetElement | null>;
};

export const LinkHandlers: Map<string, LinkHandlerType> = new Map([
    [CONFIGURE_PROTOCOL, configureHandler],
    [LIFL_PROTOCOL, liflHandler],
    [ACTION_PROTOCOL, actionHandler]
]);
