import React from 'react';
import {DesktopNextBestActionButton} from './DesktopNextBestActionButton';
import {SectionNbabTrackingData} from './getActions';
import {MergeNextBestActionModel} from './mergePersonalizedActions';

export interface GroupedDesktopNextBestActionButtonsProps {
    actions: MergeNextBestActionModel[];
    isHidden?: boolean;
    trackingData?: SectionNbabTrackingData;
    itemCount: number;
}

export function GroupedDesktopNextBestActionButtons(
    props: GroupedDesktopNextBestActionButtonsProps
) {
    const {actions, itemCount, isHidden, trackingData} = props;
    const [expandButtons, updateExpandButtons] = React.useState(true);

    React.useEffect(() => {
        const handleOnScroll = () => updateExpandButtons(false);

        document.addEventListener('scroll', handleOnScroll, {
            passive: true,
            once: true
        });

        return () => {
            document.removeEventListener('scroll', handleOnScroll);
        };
    }, []);

    return (
        <>
            {(actions || []).map(
                (nbabModel: MergeNextBestActionModel, index: number) => (
                    <DesktopNextBestActionButton
                        expandButtons={expandButtons}
                        actionModel={nbabModel}
                        no={index + 1}
                        key={index}
                        isHidden={isHidden || false}
                        trackingData={{
                            ...trackingData,
                            itemCount: itemCount,
                            itemPosition: index + 1,
                            personalizedReporting:
                                nbabModel.personalizedReporting
                        }}
                    />
                )
            )}
        </>
    );
}
