import {Disclaimer as GeneratedDisclaimer} from '../../../generated/core';
import {RegisteredDisclaimer} from './DisclaimerStore';
import {useNewDisclaimerReferences} from './useNewDisclaimerReferences';

export const DISCLAIMER_REFERENCE_SEPARATOR = ',';

export function useDisclaimerReferences(
    disclaimers: GeneratedDisclaimer[]
): RegisteredDisclaimer[] {
    const newDisclaimers = useNewDisclaimerReferences(disclaimers);
    return newDisclaimers;
}
