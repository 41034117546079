import * as React from 'react';
import ReactDOM from 'react-dom';

import {
    Breakpoints,
    BreakpointConfigType,
    ContainerBreakpointConfig,
    ContainerPadding,
    DesignTokenSizeEnum,
    styled,
    ThemeDefinition
} from '@volkswagen-onehub/components-core';
import {getElectricTransition, makeClip} from './index';

export * from './css';
export * from './flippin-icon';

export const inBrowser = () =>
    typeof window === 'object' && typeof document === 'object';

const navigator: Navigator = global.navigator;

export const isIOS = () =>
    Boolean(navigator && navigator.userAgent) &&
    (/iPad|iPhone|iPod/i.test(navigator.userAgent) ||
        // iPad detection (it works - it was tested on real iPad Mini)
        // https://developer.apple.com/forums/thread/119186?page=2
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document));

export function isEDGE(): boolean {
    if (!inBrowser()) {
        return false;
    }

    return /Edge/i.test(navigator.userAgent);
}

export function getHeight(ref: React.RefObject<HTMLElement>): number {
    const refElem = ReactDOM.findDOMNode(ref.current);

    return refElem instanceof Element ? refElem.clientHeight : 0;
}

export function getPaddingBreakpointKey(index: number): number {
    switch (index) {
        case 0:
            return Breakpoints.default;
        case 1:
            return Breakpoints.b560;
        case 2:
            return Breakpoints.b1600;
        default:
            throw new Error('Invalid breakpoint config');
    }
}

export function getPaddingGridValue(value: number): ContainerPadding {
    if (value === 0) {
        return ContainerPadding.static0;
    } else {
        const prepend = value > 9 ? '0' : '00';
        const fullValue = prepend.concat(value.toString());
        const a = `grid${fullValue}` as DesignTokenSizeEnum;

        return ContainerPadding[a];
    }
}

type containerPaddingConfigType = number | null;

/**
 * Get Container padding config for 3 main breakpoints
 * @param config Array with max 3 numbers to represent 3 main breakpoints. End values can be omitted. To skip breakpoint, you can use 'null' instead.
 */
export function getContainerPadding(
    config: containerPaddingConfigType[]
): ContainerBreakpointConfig<DesignTokenSizeEnum> {
    const x: {[key: string]: string} = {};
    config.forEach((item, index) => {
        if (item === null || item === undefined) {
            return;
        }

        const key = getPaddingBreakpointKey(index);
        const value = getPaddingGridValue(item);
        const typedKey = `${key}`;
        x[typedKey] = value;
    });

    return x as ContainerBreakpointConfig<DesignTokenSizeEnum>;
}

/**
 * Create CSS with media queries by breakpoint config
 *
 * Example:
 * const PADDING_INLINE_CONFIG = {
 *    [Breakpoints.default]: theme.size.grid002,
 *    [Breakpoints.b560]: theme.size.grid006,
 *    [Breakpoints.b1600]: theme.size.grid007
 * };
 *
 * In styled component with passed config then:
 * ${props => createCSSByBreakpoints('padding-inline', props.paddingInlineConfig)}
 */

export const createCSSByBreakpoints = (
    property: string,
    breakpointConfig: BreakpointConfigType
) => {
    return Object.keys(breakpointConfig).map(breakpoint => {
        if (breakpoint === '0') {
            return `
                ${property}: ${breakpointConfig[breakpoint]};
            `;
        } else {
            return `
                @media (min-width: ${breakpoint}px) {
                    ${property}: ${breakpointConfig[breakpoint]};
                }
            `;
        }
    });
};

export enum Direction {
    LTR = 'ltr',
    RTL = 'rtl'
}

// Necessary for core-components theme.direction interop
// You cannot pass 'ltr'|'rtl' (as used in core-components) to Direction enum
// type, but you can pass Direction to 'ltr'|'rtl' type.
//
// const x: Direction = 'ltr'                   // Error
// const x: ('ltr'|'rtl') = Direction.LTR       // Fine
type DirectionValues = 'ltr' | 'rtl';

export enum CSSDirection {
    RIGHT = 'right',
    LEFT = 'left'
}

export interface PropsWithDirection {
    readonly direction: Direction;
}

export function getStartDirection(
    direction: DirectionValues = Direction.LTR
): CSSDirection {
    return direction === Direction.RTL ? CSSDirection.RIGHT : CSSDirection.LEFT;
}

export function getEndDirection(
    direction: DirectionValues = Direction.LTR
): CSSDirection {
    return direction === Direction.RTL ? CSSDirection.LEFT : CSSDirection.RIGHT;
}

type ItemType = string | null | undefined;

function concatTruthyStrings(items: ItemType[], delimiter: string): string {
    return items.filter(Boolean).join(delimiter);
}

export function createCSSFourValuesPaddingByDirection(
    top: string | 0,
    right: string | 0,
    bottom: string | 0,
    left: string | 0,
    direction: DirectionValues = Direction.LTR
): string {
    const directionalPaddingRight = direction !== Direction.RTL ? right : left;
    const directionalPaddingLeft = direction !== Direction.RTL ? left : right;

    return `${top} ${directionalPaddingRight} ${bottom} ${directionalPaddingLeft}`;
}

export function createCSSMarginByDirection(
    direction: DirectionValues = Direction.LTR,
    marginStart?: string,
    marginEnd?: string
): string {
    const marginStartString = marginStart
        ? `margin-${getStartDirection(direction)}: ${marginStart};`
        : '';
    const marginEndString = marginEnd
        ? `margin-${getEndDirection(direction)}: ${marginEnd};`
        : '';

    return concatTruthyStrings([marginStartString, marginEndString], '\n');
}

export const resetListStyles = () => `
	margin: 0;
	padding: 0;
	list-style-type: none;
`;

interface ButtonFocusStateCSSProps {
    theme: ThemeDefinition;
}

// this is the equivalent of 'padding: 6px' because 'box-sizing: border-box' includes padding and the border on each side
const BUTTON_FOCUS_PADDING = 8;

export const buttonFocusStateCSS = (props: ButtonFocusStateCSSProps) => `
	&:focus {
		&:before {
			content: '';
			box-sizing: border-box;
			position: absolute;
			top: 50%;
			${getStartDirection(props.theme.direction)}: 50%;
			width: calc(100% + ${BUTTON_FOCUS_PADDING * 2}px);
			height: calc(100% + ${BUTTON_FOCUS_PADDING * 2}px);
            border: solid 2px ${props.theme.colors.border.active};
			transform: translate3d(${
                getStartDirection(props.theme.direction) === CSSDirection.LEFT
                    ? '-'
                    : ''
            }50%, -50%, 0);
			${getElectricTransition('width')};
		}
	}

	&:hover,
	&:focus {
        color: ${props.theme.colors.button.primary.hover};
		& > div {
			color: ${props.theme.colors.button.primary.hover};
		}
		outline: 0;
	}
`;

interface IncreasedIconTabAreaProps {
    customTabArea?: number;
}

const MINIMAL_ICON_TAB_AREA = 44;

export const increasedIconTabArea = ({
    customTabArea = MINIMAL_ICON_TAB_AREA
}: IncreasedIconTabAreaProps) => `
	position: relative;

	&:after {
		content: '';
		box-sizing: border-box;
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: max(100%, ${customTabArea}px);
		min-height: max(100%, ${customTabArea}px);
		transform: translate(-50%, -50%);
	}
`;

export const StyledAccessibleLabel = styled.div`
    ${makeClip}
`;
StyledAccessibleLabel.displayName = 'StyledAccessibleLabel';
