import * as React from 'react';

export interface SectionGroupContextData {
    sectionGroupAnchorId?: string; // ~ anchorId in section
    sectionGroupContentId?: string; // ~ sectionId in section
    isWithinGroup?: boolean;
    hasSectionGroupHeading?: boolean;
}

export const SectionGroupContext = React.createContext<SectionGroupContextData>(
    {}
);
