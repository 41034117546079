// URL encode part has to be fulfilled from the FS authservice. For now it's part of the consumer
export function getEncodedRedirectUrl(): string {
    // origin and pathname are already provided as URL encoded
    let redirectUrl = window.location.origin + window.location.pathname;
    // search is already URL encoded too but needs to be encoded twice
    // Reason: authproxy uses tomcat as web container that does URL decoding implicitly
    // So, the search has to be encoded twice - this way the authproxy are able to handle the params correctly
    if (window.location.search) {
        redirectUrl += encodeURIComponent(window.location.search);
    }

    return redirectUrl;
}
