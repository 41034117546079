import {useCallback, useEffect} from 'react';

export const browserWindowHeight = 'var(--100vh, 100vh)'; // where --100vh is window.innerHeight and 100vh is the fallback value
export const browserBarHeightValue = `calc(100vh - ${browserWindowHeight})`; // height of the mobile navigation bar

export function useBrowserWindowHeight() {
    const isClient = typeof window === 'object';

    const getWindowHeight = useCallback(() => {
        return isClient ? window.innerHeight : undefined;
    }, [isClient]);

    useEffect(() => {
        if (!isClient) {
            return undefined;
        }

        function handleResize() {
            const prevHeight = getComputedStyle(
                document.documentElement
            )?.getPropertyValue('--100vh');
            const newHeight = `${getWindowHeight()}px`;

            if (prevHeight !== newHeight) {
                document.documentElement.style.setProperty(
                    '--100vh',
                    newHeight
                );
            }
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [getWindowHeight, isClient]);
}
