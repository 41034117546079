import * as React from 'react';
import styled from 'styled-components';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {DisclaimerContainerProps} from './helpers';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';

const StyledBellowItemDisclaimerReferences = styled.div`
    padding-top: ${props => props.theme.size.static100};
    padding-bottom: ${props => props.theme.size.static250};
`;

export const TeaserCategoryLinkDisclaimerContainer = observer(function TCLDC(
    props: DisclaimerContainerProps
) {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const hasReferences = disclaimers && disclaimers.length !== 0;

    return (
        <>
            {hasReferences && (
                <StyledBellowItemDisclaimerReferences>
                    <DisclaimersWithSafewords disclaimers={disclaimers} />
                </StyledBellowItemDisclaimerReferences>
            )}
        </>
    );
});
