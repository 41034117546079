import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    ThemeDefinition,
    ThemeDefinitionSizeType
} from '@volkswagen-onehub/components-core';
import styled, {ThemeContext} from 'styled-components';
import {
    useContentStore,
    useFeatureToggles,
    useLayerStore,
    useNavigationStore,
    useSkipLinksStore
} from '../../../context';
import {ImageLoadingContext} from '../../../context/image/ImageLoadingContext';
import {SkipLinkTargetWrapper} from '../navigation/SkipLinks';
import {NextBestAction} from '../nbab/NextBestAction';
import {
    CSS_VAR_TOP_BAR_HEIGHT,
    setTopBarHeight
} from '../../../d6/components/navigation-top-bar-one-hub-helpers';
import {isExpandedTopbar} from '../navigation/helpers';
import {C} from '../../../registries/compatibilty';
import {Breadcrumbs} from '../navigation/Breadcrumbs';
import {elseUndefined} from '../../../utils/elseUndefined';

interface MainBaseProps {
    readonly stage?: JSX.Element;
    readonly isSimpleStage?: boolean;
    readonly isFaStage?: boolean;
    readonly isBreadcrumbsBelowTopbar?: boolean;
    readonly hasBreadcrumbs?: boolean;
}

const calculateTopBarSafezone = (
    breakpoint: Breakpoints,
    size: ThemeDefinitionSizeType,
    isStaticTopbar: boolean,
    isStageBelowTopbar: boolean
) => {
    const staticTopbarPadding =
        breakpoint === Breakpoints.default ? size.static520 : size.static530;

    const topBarSize = isStaticTopbar
        ? staticTopbarPadding
        : `var(${CSS_VAR_TOP_BAR_HEIGHT})`;

    return !C.isInEditor() && isStageBelowTopbar ? topBarSize : '0';
};

// Note: special padding-top needs to be set for pages with Simple Stage on mobile
// Otherwise the Stage would start right beneath the topbar because of missing breadcrumbs
// static625 (topbar height + default value of dynamic0050) is used to bypass any calculations with var(${CSS_VAR_TOP_BAR_HEIGHT})
const StyledMainContainer = styled.div<{
    isTopbarExpanded: boolean;
    isSimpleStage?: boolean;
    isStaticTopbar: boolean;
    isStageBelowTopbar: boolean;
}>`
    ${props => setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, props.isTopbarExpanded)};

    padding-top: ${props =>
        props.isSimpleStage
            ? props.theme.size.static625
            : calculateTopBarSafezone(
                  Breakpoints.default,
                  props.theme.size,
                  props.isStaticTopbar,
                  props.isStageBelowTopbar
              )};
    padding-bottom: ${props => props.theme.size.dynamic0270};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-top: ${props =>
            calculateTopBarSafezone(
                Breakpoints.b560,
                props.theme.size,
                props.isStaticTopbar,
                props.isStageBelowTopbar
            )};
    }
`;

const getBreadcrumbPaddingTop = (
    theme: ThemeDefinition,
    isBreadcrumbsBelowTopbar?: boolean
) => {
    return !C.isInEditor() && isBreadcrumbsBelowTopbar
        ? theme.size.dynamic0050
        : undefined;
};

const getBreadcrumbs = (
    hasBreadcrumbs?: boolean,
    isBreadcrumbsBelowTopbar?: boolean
): JSX.Element | undefined => {
    return elseUndefined(
        hasBreadcrumbs,
        <ThemeContext.Consumer>
            {theme => (
                <Breadcrumbs
                    topSpacing={getBreadcrumbPaddingTop(
                        theme,
                        isBreadcrumbsBelowTopbar
                    )}
                    isBreadcrumbsBelowTopbar={isBreadcrumbsBelowTopbar}
                />
            )}
        </ThemeContext.Consumer>
    );
};

export function MainBase(
    mainProps: React.PropsWithChildren<MainBaseProps>
): JSX.Element {
    const navigationStore = useNavigationStore();
    const featureToggles = useFeatureToggles();
    const skipLinksStore = useSkipLinksStore();
    const mainParsysRef = React.useRef(null);
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const clientLayerStore = useLayerStore();

    React.useEffect(() => {
        skipLinksStore.setSkipLinkClickHandler(
            'mainContentSkipLink',
            mainParsysRef
        );
    }, [mainParsysRef, skipLinksStore]);

    const {
        stage,
        isSimpleStage,
        isFaStage,
        isBreadcrumbsBelowTopbar,
        hasBreadcrumbs
    } = mainProps;

    const childContainer = createChildContainer(mainProps);

    const shouldRenderInpageNav =
        navigationStore?.visibleInPageNavigationItems.filter(item => item.title)
            .length > 0;

    const isTopbarExpanded = React.useMemo(
        () =>
            isExpandedTopbar(
                shouldRenderInpageNav,
                navigationStore?.lastScrollDirection,
                navigationStore?.isTopBarAboveStage
            ),
        [shouldRenderInpageNav, navigationStore]
    );

    const isStaticTopbar = Boolean(
        pageRootModel?.togglesModel?.enableStaticNavigation
    );

    const isStageBelowTopbar = Boolean(
        isBreadcrumbsBelowTopbar ||
            (isFaStage && !pageRootModel?.transparentTopbarForFA)
    );

    const breadcrumbs = getBreadcrumbs(
        hasBreadcrumbs,
        isBreadcrumbsBelowTopbar
    );

    const main = (
        <StyledMainContainer
            isTopbarExpanded={isTopbarExpanded}
            isSimpleStage={Boolean(isSimpleStage)}
            isStageBelowTopbar={isStageBelowTopbar}
            isStaticTopbar={isStaticTopbar}
        >
            {isBreadcrumbsBelowTopbar && breadcrumbs}
            <NextBestAction key="nbab" />
            <ImageLoadingContext.Provider
                key="image-loading-context-provider"
                value={{lazyload: !featureToggles?.disableLazyLoading}}
            >
                {stage}
            </ImageLoadingContext.Provider>
            {!isBreadcrumbsBelowTopbar && breadcrumbs}
            <SkipLinkTargetWrapper
                key="skip-link-target-wrapper"
                ref={mainParsysRef}
            >
                {childContainer}
            </SkipLinkTargetWrapper>
        </StyledMainContainer>
    );

    /* add attribute for search indexer on pages that are opened with layer,
       This way main content can be ignored by search when opened with layer (NGWD6-9126) */
    return clientLayerStore.hasOpenedLayer() ? (
        <div className={'NoSearch'} data-testid="no-search-wrapper">
            {main}
        </div>
    ) : (
        main
    );
}

function createChildContainer({
    stage,
    children,
    isSimpleStage
}: React.PropsWithChildren<MainBaseProps>): JSX.Element {
    return stage || isSimpleStage ? (
        <Container
            key="child-container"
            padding={{
                top: ContainerPadding.dynamic0270
            }}
            wrap={ContainerWrap.always}
        >
            {children}
        </Container>
    ) : (
        <Container
            key="child-container"
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.dynamic0270}
        >
            {children}
        </Container>
    );
}
