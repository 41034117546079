import React from 'react';
import {
    ClickTargetElement,
    useCmsLink
} from '../../../components/links/CmsLink';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {
    SingleNbabTrackingData,
    useSingleNbabTracking
} from './useSingleNbabTracking';

export const useSingleNbabClick = (
    ref: React.RefObject<ClickTargetElement>,
    action: MergeNextBestActionModel,
    trackingData?: SingleNbabTrackingData
) => {
    const trackNextBestActionButtonClick = useSingleNbabTracking(
        action.label,
        action.intent,
        action.priority,
        trackingData
    );
    const onClick = (_: React.MouseEvent<HTMLButtonElement>) => {
        if (action.linkType === 'callback') {
            action.callback && action.callback();
        }
    };
    return useCmsLink(ref, {
        href: action.path,
        linkName: action.label,
        target: action.externalLinkTarget as
            | '_self'
            | '_blank'
            | '_parent'
            | '_top'
            | undefined,
        onClick: onClick,
        isLayerLink: action.linkType === 'layer',
        trackingActionOverride(_href, _linkName, _contextData, layerName?) {
            trackNextBestActionButtonClick(action.path, undefined, layerName);
        }
    });
};
