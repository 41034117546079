import * as React from 'react';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {TopLineTextPosition} from '../../../modules/structure/main/XfTextOnlyTeaser';

export const TOPLINE_CY_ATTRIBUTE = 'topline';

const StyledTeaserWrapper = styled.div<{hasSiblings: boolean}>`
    position: relative;
    border: 2px solid ${props => props.theme.colors.border.divider};
    padding: ${props => props.theme.size.dynamic0130}
        ${props => props.theme.size.dynamic0100};
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    height: 100%;
`;
StyledTeaserWrapper.displayName = 'StyledTeaserWrapper';

const StyledContentWrapper = styled.div<{
    hasSiblings: boolean;
}>`
    display: grid;
    grid-auto-rows: min-content;
    grid-gap: ${props => props.theme.size.static350};
    align-content: start;
    width: 100%;

    @media (min-width: ${Breakpoints.b960}px) {
        grid-template-columns: ${props =>
            props.hasSiblings ? 'auto' : '50% 50%'};
    }
`;
StyledContentWrapper.displayName = 'StyledContentWrapper';

const StyledTopLine = styled.div<{topLineTextPosition: 'left' | 'right'}>`
    position: absolute;
    top: 0;
    ${props => props.topLineTextPosition}: ${props =>
        props.theme.size.dynamic0100};
    transform: translateY(-50%);
    background-color: ${props => props.theme.colors.background.primary};
    padding: 0 ${props => props.theme.size.static250};
    margin: 0 -${props => props.theme.size.static250};
`;
StyledTopLine.displayName = 'StyledTopLine';
//TODO: find a better way than negative margins => topLine text should be aligned with the headline

const StyledDisclaimersWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.static350};

    & > div {
        // NOTE: StyledDisclaimersWrapper is rendered even when no disclaimers are available, due empty object without children. That's why padding is added on inner disclaimer wrapper here
        padding-top: ${props => props.theme.size.dynamic0100};
    }
`;
StyledDisclaimersWrapper.displayName = 'StyledDisclaimersWrapper';

interface TextOnlyTeaserItemProps {
    readonly headline: JSX.Element;
    readonly copy?: JSX.Element;
    readonly linkList?: JSX.Element;
    readonly topLine?: string;
    readonly disclaimers: JSX.Element | null;
    readonly hasSiblings?: boolean;
    readonly topLineTextPosition: TopLineTextPosition;
}

export const TextOnlyTeaserItem = (
    props: TextOnlyTeaserItemProps
): JSX.Element => {
    const {
        disclaimers,
        headline,
        copy,
        linkList,
        topLine,
        hasSiblings = true,
        topLineTextPosition
    } = props;

    return (
        <StyledTeaserWrapper hasSiblings={hasSiblings}>
            {topLine ? (
                <StyledTopLine
                    data-cy={TOPLINE_CY_ATTRIBUTE}
                    topLineTextPosition={topLineTextPosition}
                >
                    {topLine}
                </StyledTopLine>
            ) : null}
            <StyledContentWrapper hasSiblings={hasSiblings}>
                {headline}
                {copy}
                {linkList}
            </StyledContentWrapper>
            {disclaimers && (
                <StyledDisclaimersWrapper>
                    {disclaimers}
                </StyledDisclaimersWrapper>
            )}
        </StyledTeaserWrapper>
    );
};
