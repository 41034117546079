import * as React from 'react';

import {TeaserElement} from '../../d6/components/teaser-element';

import {LinkWindowTarget} from '../../../generated/core';
import {EditorialTeaserElementProps} from '../../modules/editorial/elements/editorial-teaser-element/EditorialTeaserElement';
import {
    TeaserElementMediaLink,
    SmallTeaserElementHeading
} from '../../modules/editorial/elements/editorial-teaser-element/helpers';
import {TeaserMainLink} from './helpers';

export interface FlyoutTeaserElementProps extends EditorialTeaserElementProps {
    trackingActionOverride(section: string): void;
}

export function FlyoutTeaserElement(
    props: FlyoutTeaserElementProps
): React.ReactElement {
    const {
        teaserElementLinkHref,
        teaserElementLinkTarget,
        teaserLayerLink,
        trackingActionOverride,
        contentId
    } = props;

    return (
        <TeaserElement
            mediaElement={
                <TeaserElementMediaLink
                    href={teaserElementLinkHref}
                    contentId={contentId}
                    target={teaserElementLinkTarget as LinkWindowTarget}
                    isLayerLink={teaserLayerLink}
                    trackingActionOverride={() =>
                        trackingActionOverride('teaser image')
                    }
                />
            }
        >
            <TeaserMainLink
                href={teaserElementLinkHref}
                contentId={contentId}
                target={teaserElementLinkTarget as LinkWindowTarget}
                isLayerLink={teaserLayerLink}
                trackingActionOverride={() =>
                    trackingActionOverride('teaser text')
                }
            >
                <SmallTeaserElementHeading />
            </TeaserMainLink>
        </TeaserElement>
    );
}
