import * as React from 'react';
import {SyntheticEvent} from 'react';
import {Fade} from '@volkswagen-onehub/components-core';
import {
    NextBestActionButton as D6NextBestActionButton,
    NextBestAction as D6NextBestAction
} from '../../../d6/components/next-best-action-button';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getIcon} from '../../../utils/getMappedIcon';
import {useScrollToSectionOfFocussedNbab} from './useScrollToSectionOfFocussedNbab';
import {SingleNbabTrackingData} from './useSingleNbabTracking';
import {useSingleNbabClick} from './useSingleNbabClick';
import {MergeNextBestActionModel} from './mergePersonalizedActions';

export interface StyledNextBestActionButtonWrapperProps {
    readonly isHidden?: boolean;
    readonly isExpanded?: boolean;
    readonly no: number;
}

export type NextBestActionButtonProps = Readonly<{
    no: number;
    actionModel: MergeNextBestActionModel;
    isHidden: boolean;
    inaccessible?: boolean; // "true" value makes the button inaccessible for keyboard and screen-reader
    trackingData?: SingleNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
    expandButtons: boolean;
}>;

export function DesktopNextBestActionButton({
    inaccessible,
    actionModel,
    trackingData,
    isHidden,
    sectionRef,
    expandButtons
}: NextBestActionButtonProps): JSX.Element | null {
    const ref = React.useRef(null);

    // NOTE: do we need this?
    useScrollToSectionOfFocussedNbab(ref, sectionRef);

    const [linkClick, linkHref] = useSingleNbabClick(
        ref,
        actionModel,
        trackingData
    );

    // click handling
    const onClick = (e: SyntheticEvent<Element, Event>) => {
        linkClick(e);
    };

    // create next best action

    const action = {
        content: actionModel.label,
        icon: getIcon(actionModel.icon, 'default'),
        tag: linkHref && actionModel.linkType !== 'action' ? 'a' : 'button',
        href: linkHref,
        onClick,
        ariaLabel: actionModel.label,
        target:
            actionModel.linkType === 'external'
                ? actionModel.externalLinkTarget
                : undefined
    } as D6NextBestAction;

    const tabIndex = inaccessible ? -1 : 0;

    return isHidden ? (
        <></>
    ) : (
        <CyAttributeAppender name="NextBestActionButton">
            <Fade
                duration={600}
                distance={'30px'}
                directionFrom={'bottom'}
                easing={'ease-out'}
            >
                <D6NextBestActionButton
                    expandContent={expandButtons}
                    action={action}
                    ref={ref}
                    tabIndex={tabIndex}
                    hideIcon={false}
                    title={actionModel.title}
                    isHidden={isHidden}
                />
            </Fade>
        </CyAttributeAppender>
    );
}
