import 'reflect-metadata';
import {Logger} from '../../context/logger/Logger';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {GLOBAL_NAMESPACE, getGlobal} from '../../utils/getGlobal';
import {ReflectionUtils} from './ReflectionUtils';
import {Environment, FieldMetadata, SingletonOptions} from './annotations';

export interface SingletonDefinition<T> {
    options: SingletonOptions;
    name: string;
    key: string;
    type(): T;
}

export interface ComparableSingletonDefinition<T>
    extends SingletonDefinition<T> {
    idx: number;
    additional: boolean;
}

export type NoArgsConstructor<T> = new () => T;

export function shouldSingletonBeInstantiated(
    def: SingletonDefinition<any>,
    env: Environment
): boolean {
    if (def.options.env && def.options.env !== env) {
        // if singleton env defined then it must be the same as env
        return false;
    }
    if (env === 'priority') {
        // only priority singletons
        return def.options && def.options.env === 'priority';
    }

    return true;
}

const createSingletonKey = (className: string, options?: SingletonOptions) => {
    if (!!options) {
        if (options.env) {
            className = `${className}.${options.env}`;
        }
        if (options.devMode) {
            className = `${className}.DEV`;
        }
    }

    return className;
};

export class MetaRegistry {
    public singletonDefinitions: {
        [key: string]: SingletonDefinition<object>;
    } = {};

    public prototypesNeedInjection: object[] = [];

    public injectedPrototypes: object[] = [];

    public regSingleton<T extends object>(
        singletonClass: NoArgsConstructor<T>,
        className: string,
        options?: SingletonOptions
    ): void {
        this.injectedPrototypes.push(singletonClass.prototype);
        const origClassName = className;

        className = createSingletonKey(className, options);

        this.singletonDefinitions[className] = {
            key: className,
            name: origClassName,
            options: options || {},
            type: () => new singletonClass()
        };
    }
    /**
     * remove a definition from registry. Useful for testing.
     * @param type
     */
    public removeSingletonDefinition(type: string): void {
        delete this.singletonDefinitions[type];
    }

    public regReact<T extends {new (...args: any[]): {}}>(
        constructor: T
    ): void {
        this.injectedPrototypes.push(constructor.prototype);
    }

    public isPrototypeRegistered(proto: any): boolean {
        return this.injectedPrototypes.indexOf(proto) >= 0;
    }
    public markAsInjected(target: object): void {
        this.prototypesNeedInjection.push(target);
    }
}

let metaRegistry = new MetaRegistry();
if (isInBrowser()) {
    if (!getGlobal()) {
        // meta registry is created as a side effect, so this is required for tests
        // because jest mocks browsers, but the global namespace is not ready there yet
        window[GLOBAL_NAMESPACE] = {} as any;
    }
    metaRegistry = getGlobal().metaRegistry || metaRegistry;
    getGlobal().metaRegistry = metaRegistry;
}

export class Registry {
    private logger: Logger;
    private singletonInstances: {[key: string]: object} = {};
    private initializedSingletons: any[] = [];
    private additionalSingletonDefinitions: SingletonDefinition<any>[] = [];

    public constructor(logger: Logger) {
        this.logger = logger;
    }

    /**
     * Clear only instances and set the iniStatus to false
     */
    public clearInstances(): void {
        this.singletonInstances = {};
        this.initializedSingletons = [];
    }

    public addSingleton<T extends object>(
        className: string,
        singleton: T,
        options?: SingletonOptions
    ): void {
        this.additionalSingletonDefinitions.push({
            key: createSingletonKey(className, options),
            type: () => singleton,
            name: className,
            options: options || {}
        });
    }

    public getSingleton<T extends object | undefined>(name: string): T {
        return this.singletonInstances[name] as T;
    }

    public isSingletonInitialized(name: string): boolean {
        return !!this.singletonInstances[name];
    }

    public initSingletons(env: Environment, devMode: boolean): void {
        this.logger.general.debug('start singletons %s', env);
        this.checkInjection();
        const compareTo = (
            s1: ComparableSingletonDefinition<object>,
            s2: ComparableSingletonDefinition<object>
        ): number => {
            if (s1.name === s2.name) {
                if (s1.options.devMode === s2.options.devMode) {
                    if (s1.additional === s2.additional) {
                        // singletons added later have a higher precedence
                        return s2.idx - s1.idx;
                    } else {
                        // additional singletons have a higher precedence.
                        if (s1.additional) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }
                }
                return s1.options.devMode ? -1 : 1;
            }

            return s1.name.localeCompare(s2.name);
        };

        Object.keys(metaRegistry.singletonDefinitions)
            .map(key => metaRegistry.singletonDefinitions[key]) //
            .filter(s => (s.options.devMode ? devMode : true)) //
            .map((s, idx) => ({...s, idx, additional: false}))
            .concat(
                this.additionalSingletonDefinitions.map((s, idx) => ({
                    ...s,
                    idx,
                    additional: true
                }))
            )
            .sort(compareTo) //
            .forEach(singletonDef => {
                this.initSingleton(singletonDef, env);
            });
        Object.keys(this.singletonInstances) //
            .forEach((key: string) => {
                const singleton: any = this.singletonInstances[key];
                this.injectSingletons(singleton, key);
            });
        this.logger.general.debug('end singletons %s', env);
    }

    public injectSingletons(target: any, name?: string): void {
        if (
            target === null ||
            typeof target === 'undefined' ||
            typeof target !== 'object'
        ) {
            return;
        }
        if (!name || this.initializedSingletons.indexOf(name) < 0) {
            // prevent infinite recursion by marking as initialized before actually doing it
            if (name) {
                this.initializedSingletons.push(name);
            }
            const singletons: FieldMetadata<any>[] =
                ReflectionUtils.getAllFieldMetaData('injects', target) || [];
            singletons.forEach((meta: FieldMetadata<any>) => {
                const singleton: object = this.getSingleton(meta.type);
                if (!singleton) {
                    console.error(`cannot find ${meta.type}. `);
                }

                target[meta.field] = singleton;
                this.injectSingletons(singleton, meta.type);
            }, this);
            ReflectionUtils.callPostConstruct(target);
        }
    }

    private initSingleton(
        def: SingletonDefinition<object>,
        env: Environment
    ): void {
        if (!shouldSingletonBeInstantiated(def, env)) {
            this.logger.general.debug(
                'skip instantiation %s, env=%s',
                def.name,
                env
            );

            return;
        }

        try {
            this.logger.general.debug(
                'adding singleton %s, o-env=%s, env=%s, devMode=%s, name=%s',
                def.name,
                def.options && def.options.env ? def.options.env : 'any',
                env,
                Boolean(def.options && def.options.devMode),
                def.key
            );
            if (def.name in this.singletonInstances) {
                this.logger.general.info(
                    'Singleton with name %s already exists. Registration skipped.',
                    def.name
                );
            } else {
                const singleton = def.type();
                this.singletonInstances[def.name] = singleton;
            }
        } catch (e) {
            if (console) {
                const generalError = `Some error occurred in the init() of ${def.name}.`;

                if (e instanceof Error) {
                    console.error(
                        generalError + ' Exception:',
                        e,
                        e.stack ? e.stack : ''
                    );
                } else {
                    console.error(generalError);
                }
            }
        }
    }

    private checkInjection(): void {
        const notProperlyInjected = metaRegistry.prototypesNeedInjection.filter(
            (proto: object) =>
                metaRegistry.injectedPrototypes.indexOf(proto) < 0
        );
        if (notProperlyInjected.length > 0) {
            throw new Error(
                'need @singleton annotation\n' +
                    notProperlyInjected
                        .map((proto: object) =>
                            proto.constructor && proto.constructor.name
                                ? proto.constructor.name
                                : 'noname'
                        )
                        .join('\n')
            );
        }
    }
}

export function getOrCreateRegistry(logger: Logger): Registry {
    if (!isInBrowser()) {
        throw new Error("don't attach registry to global in server");
    }
    const registry = getGlobal().browserRegistry || new Registry(logger);
    getGlobal().browserRegistry = registry;

    return registry;
}

export {metaRegistry};
