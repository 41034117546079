import {FeatureAppVisibilityService} from '@volkswagen-onehub/feature-app-visibility-service';
import {LayerManagerV2} from '@volkswagen-onehub/layer-manager';
import {UserInteractionServiceV21} from '@volkswagen-onehub/user-interaction-service';
import * as React from 'react';

import {FeatureHub} from '@feature-hub/core';
import {ImageServiceV1} from '@volkswagen-onehub/image-service';

import {
    FeatureTogglesModel,
    IntegratorTemplateModel,
    LocaleModel,
    FooterModel,
    NavigationModel,
    PersonalizationConfig,
    SearchModel,
    SpaGlobalConfig,
    TopBarModel,
    SpaAsyncConfig
} from '../../generated/core';
import {CarouselItemContextV2} from '../d6/components/mobile-carousel-v2/context';
import {ModelContext} from '../infrastructure/compatibility/ModelContext';
import {Registry} from '../infrastructure/di/Registry';
import {RegistryContext} from '../infrastructure/di/RegistryContext';
import {
    SectionGroupContext,
    SectionGroupContextData
} from '../modules/structure/SectionGroupContext';
import {Value} from '../utils/contextUtils';
import {ContentStore} from './content/ContentStore';
import {DisclaimerStore} from './disclaimer/DisclaimerStore';
import {DisclaimerStoreContext} from './disclaimer/DisclaimerStoreContext';
import {GlobalDisclaimerStore} from './disclaimer/GlobalDisclaimerStore';
import {ExternalLinkLightboxService} from './externalLinkLightbox/ExternalLinkLightboxService';
import {ImageLoadingContext} from './image/ImageLoadingContext';
import {LayerContext, LayerContextData} from './layer/LayerContext';
import {LayerStore} from './layer/LayerStore';
import {
    LegalEntityContext,
    LegalEntityContextData
} from './legal/LegalEntityProvider';
import {Logger} from './logger/Logger';
import {MediaContext, MediaContextData} from './media/MediaContext';
import {ModelStore} from './model/ModelStore';
import {BreadcrumbStore} from './navigation/BreadcrumbStore';
import {NavigationStore} from './navigation/NavigationStore';
import {ClientPersistentStorageService} from './persistentStorage/PriorityPersistentStorageService';
import {
    PersonalizationContext,
    PersonalizationContextData
} from './personalization/PersonalizationContext';
import {PersonalizationStore} from './personalization/PersonalizationStore';
import {NavigationService} from './route/NavigationService';
import {RouterService} from './route/RouterService';
import {SearchLayerService} from './search/SearchLayerService';
import {SearchStore} from './search/SearchStore';
import {TrackingService} from './tracking/TrackingService';
import {LoginAlertService} from './vwid/LoginAlertService';
import {LoginFlyoutService} from './vwid/LoginFlyoutService';
import {LoginStore} from './vwid/LoginStore';
import {
    SectionContextData,
    SectionContext
} from '../modules/structure/SectionContext';
import {SkipLinksStore} from './navigation/SkipLinksStore';
import {LogoStore} from './navigation/LogoStore';
import {ReactRenderServiceV1} from '@volkswagen-onehub/react-render-service';
import {CustomHistoryManager} from './CustomHistoryManager';
import {MyDealerServiceV1} from '@volkswagen-onehub/mydealer-service';
import {MyDealerConfigurationFeatureServiceV2_2} from '@volkswagen-onehub/mydealer-configuration-service';
import {MydealerStore} from './mydealer/MydealerStore';
import {PageInfoServiceV1} from '@volkswagen-onehub/page-info-service';
import {FeatureappServiceV1} from '@volkswagen-onehub/featureapp-service';
import {MyDealerContentServiceV1} from '@volkswagen-onehub/mydealer-content-service';
import {ModelClient} from '@adobe/cq-spa-page-model-manager';
import {SmartDigitalService} from './smartDigital/SmartDigitalService';
import {BBOContext, BBOContextData} from './bbo/BBOContext';

export function useRegistry(): Registry {
    const registry = React.useContext(RegistryContext);
    if (!registry) {
        throw new Error('Registry is not available!');
    }

    return registry;
}

export function useGlobalConfig(): SpaGlobalConfig {
    const registry = useRegistry();

    return registry.getSingleton('SpaGlobalConfig');
}

export function useGlobalDisclaimerStore(): GlobalDisclaimerStore {
    const registry = useRegistry();

    return registry.getSingleton('GlobalDisclaimerStore');
}

export function useNavigationStore(): NavigationStore {
    const registry = useRegistry();

    return registry.getSingleton('NavigationStore');
}
export function useNavigationModel(): NavigationModel {
    const registry = useRegistry();

    return registry.getSingleton('NavigationModel');
}

export function useFooterModel(): FooterModel {
    const registry = useRegistry();

    return registry.getSingleton('FooterModel');
}

export function useContentStore(): ContentStore {
    const registry = useRegistry();

    return registry.getSingleton('ContentStore');
}

export function useBreadcrumbStore(): BreadcrumbStore {
    const registry = useRegistry();

    return registry.getSingleton('BreadcrumbStore');
}

export function useSkipLinksStore(): SkipLinksStore {
    const registry = useRegistry();

    return registry.getSingleton('SkipLinksStore');
}

export function useSearchModel(): SearchModel {
    const registry = useRegistry();

    return registry.getSingleton('SearchModel');
}

export function useSearchStore(): SearchStore {
    const registry = useRegistry();

    return registry.getSingleton('SearchStore');
}

export function useSearchLayerService(): SearchLayerService {
    const registry = useRegistry();

    return registry.getSingleton('SearchLayerService');
}

export function useSpaAsyncConfig(): SpaAsyncConfig {
    const registry = useRegistry();

    return registry.getSingleton('SpaAsyncConfig');
}

export function useTrackingService(): TrackingService {
    const registry = useRegistry();

    return registry.getSingleton('TrackingService');
}

export function useImageService(): ImageServiceV1 {
    const registry = useRegistry();

    return registry.getSingleton('ImageService');
}

export function useMediaContext(): MediaContextData {
    return React.useContext(MediaContext);
}

export function useLayerStore(): LayerStore {
    const registry = useRegistry();

    return registry.getSingleton('LayerStore');
}

export function useRouterService(): RouterService {
    const registry = useRegistry();

    return registry.getSingleton('RouterService');
}

export function useExternalLinkLightboxService(): ExternalLinkLightboxService {
    const registry = useRegistry();

    return registry.getSingleton('ExternalLinkLightboxService');
}

export function usePersonalizationContext(): PersonalizationContextData {
    return React.useContext(PersonalizationContext);
}

export function useBBOContext(): BBOContextData {
    return React.useContext(BBOContext);
}

export function useSectionGroupContext(): SectionGroupContextData {
    return React.useContext(SectionGroupContext);
}

export function useDisclaimerStoreContext(): Value<DisclaimerStore> {
    return React.useContext(DisclaimerStoreContext);
}

export function useLegalEntityContext(): LegalEntityContextData {
    return React.useContext(LegalEntityContext);
}

export function useLoginAlertService(): LoginAlertService {
    const registry = useRegistry();

    return registry.getSingleton('LoginAlertService');
}

export function useLoginStore(): LoginStore {
    const registry = useRegistry();

    return registry.getSingleton('LoginStore');
}

export function useFeatureHub(): FeatureHub {
    const registry = useRegistry();

    return registry.getSingleton('FeatureHub');
}

export function useCustomHistoryManager(): CustomHistoryManager {
    const registry = useRegistry();

    return registry.getSingleton('CustomHistoryManager');
}

export function useIntegratorTemplateModel(): IntegratorTemplateModel {
    const registry = useRegistry();

    return registry.getSingleton('IntegratorTemplateModel');
}

export function usePersonalizationConfig(): PersonalizationConfig {
    const registry = useRegistry();

    return registry.getSingleton('PersonalizationConfig');
}

export function useModelContext(): Value<string> {
    return React.useContext(ModelContext);
}

export function usePersonalizationStore(): PersonalizationStore {
    const registry = useRegistry();

    return registry.getSingleton('PersonalizationStore');
}

export function useModelStore(): ModelStore {
    const registry = useRegistry();

    return registry.getSingleton('ModelStore');
}

export function useNavigationService(): NavigationService {
    const registry = useRegistry();

    return registry.getSingleton('NavigationService');
}

export function useLogoStore(): LogoStore {
    const registry = useRegistry();

    return registry.getSingleton('LogoStore');
}

export function useRenderService(): ReactRenderServiceV1 {
    return useRegistry().getSingleton(
        'ReactRenderService'
    ) as ReactRenderServiceV1;
}

export function useSessionStorage(): ClientPersistentStorageService {
    return useRegistry().getSingleton('SessionStorage');
}

export function useLocaleModel(): LocaleModel {
    const registry = useRegistry();

    return registry.getSingleton('LocaleModel');
}

export function useLoginFlyoutService(): LoginFlyoutService {
    const registry = useRegistry();

    return registry.getSingleton('LoginFlyoutService');
}

export function useTopBarModel(): TopBarModel {
    const registry = useRegistry();

    return registry.getSingleton('TopBarModel');
}

export function useLayerContext(): LayerContextData {
    return React.useContext(LayerContext);
}

export function useLayerManager(): LayerManagerV2 {
    const registry = useRegistry();

    return registry.getSingleton('LayerManager');
}

export function useUserInteractionService(): UserInteractionServiceV21 {
    const registry = useRegistry();

    return registry.getSingleton('UserInteractionService');
}

export function useFeatureAppVisibilityService(): FeatureAppVisibilityService {
    const registry = useRegistry();

    return registry.getSingleton('FeatureAppVisibilityService');
}

export function useLogger(): Logger {
    const registry = useRegistry();

    return registry.getSingleton('Logger');
}

interface CarouselItemContextData {
    active: boolean;
}

export function useCarouselItemContext(): CarouselItemContextData {
    return React.useContext(CarouselItemContextV2);
}

export function useFeatureToggles(): FeatureTogglesModel {
    const {featureToggles} = useGlobalConfig();

    return featureToggles;
}

export function useSectionContext(): SectionContextData {
    return React.useContext(SectionContext);
}

export function useImageLazyLoad(): boolean {
    const imageLoadingContext = React.useContext(ImageLoadingContext);

    // Return lazy loading if there is no context provided
    return !imageLoadingContext || imageLoadingContext.lazyload;
}

export function useMydealerService(): MyDealerServiceV1 {
    const registry = useRegistry();

    return registry.getSingleton('MydealerService');
}

export function useMyDealerStore(): MydealerStore {
    const registry = useRegistry();

    return registry.getSingleton('MydealerStore');
}

export function useMydealerConfigurationService(): MyDealerConfigurationFeatureServiceV2_2 {
    const registry = useRegistry();

    return registry.getSingleton('MydealerConfigurationService');
}
export function useMyDealerContentService(): MyDealerContentServiceV1 {
    const registry = useRegistry();

    return registry.getSingleton('MyDealerContentService');
}

export function usePageInfoService(): PageInfoServiceV1 {
    const registry = useRegistry();

    return registry.getSingleton('PageInfoService');
}

export function useFeatureAppService(): FeatureappServiceV1 {
    const registry = useRegistry();

    return registry.getSingleton('FeatureAppService');
}

export function useModelClient(): ModelClient {
    const registry = useRegistry();

    return registry.getSingleton('ModelClient');
}

export function useSmartDigitalService(): SmartDigitalService {
    return useRegistry().getSingleton('SmartDigitalService');
}
