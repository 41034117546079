import * as React from 'react';
import {action, IObservableArray, observable} from 'mobx';
import {observer} from 'mobx-react-lite';

import {DisplayType} from '../../../generated/core';
import {RegisteredDisclaimer} from './DisclaimerStore';
import {areDisclaimersEqual} from './helpers';
import {
    DisclaimerContextApi,
    FullTextCallback,
    FullTextDisclaimer
} from './DisclaimerContextApi';

/**
 * This functional component renders when the disclaimers change by observing the moby store.
 * During ssr and hydration the mutable disclaimer array is passed to children to allow the addition of disclaimers.
 * Otherwise an immutable array is passed to force a re rendering. This is not a standard mechanism but used here for keeping changes to a minimum.
 * TODO find a better way without passing disclaimers to children and using a solution that works in ssr and client side.
 */
export const RenderChildrenWithDisclaimers = observer(function RCWD(props: {
    disclaimerStore: ScopedDisclaimerStore;
    children: (disclaimers: RegisteredDisclaimer[]) => JSX.Element;
}) {
    const [immutable, setImmutable] = React.useState(false);
    React.useEffect(() => {
        setImmutable(true);
    }, []);
    const disclaimers = props.disclaimerStore.disclaimers;
    return props.children(immutable ? disclaimers.slice() : disclaimers);
});

export class ScopedDisclaimerStore {
    public constructor(private readonly displayTypes: DisplayType[]) {}

    public disclaimers: IObservableArray<
        RegisteredDisclaimer
    > = observable.array([]);
    public fullTextDisclaimers: Map<
        string,
        FullTextDisclaimer
    > = observable.map({});

    @action
    public handleRegisteredDisclaimers(
        registeredDisclaimers: RegisteredDisclaimer[]
    ) {
        // newly added disclaimers should be filtered and checked if there is no duplicate in original ones
        const toBeMergedFiltered = registeredDisclaimers.filter(
            dis =>
                (this.displayTypes.length <= 0 ||
                    (this.displayTypes.length > 0 &&
                        this.displayTypes.indexOf(dis.displayType) >= 0)) &&
                !this.disclaimers.find(original =>
                    areDisclaimersEqual(original, dis)
                )
        );
        if (toBeMergedFiltered.length > 0) {
            toBeMergedFiltered.forEach(d => {
                this.disclaimers.push(d);
            });
        }
    }

    @action
    public handleUnregisteredDisclaimers(
        registeredDisclaimers: RegisteredDisclaimer[]
    ) {
        registeredDisclaimers.forEach(dis => {
            if (
                this.displayTypes.length <= 0 ||
                (this.displayTypes.length > 0 &&
                    this.displayTypes.indexOf(dis.displayType) >= 0)
            ) {
                const disclaimerToBeRemoved = this.disclaimers.find(original =>
                    areDisclaimersEqual(original, dis)
                );
                if (disclaimerToBeRemoved) {
                    this.disclaimers.remove(disclaimerToBeRemoved);
                }
            }
        });
    }

    public handleAddedRef(
        reference: string,
        ref: React.RefObject<HTMLDivElement>,
        callback: (
            sourceReference: React.RefObject<HTMLButtonElement>,
            scrollPosition: number,
            disableScroll: boolean
        ) => void
    ) {
        this.fullTextDisclaimers.set(reference, {ref, callback});
    }
}

/** default disclaimer context that provides method to update the registered disclaimers. */
export interface DisclaimerContextData extends DisclaimerContextApi {
    handleRegisteredDisclaimers(
        registeredDisclaimers: RegisteredDisclaimer[]
    ): void;
    handleUnregisterDisclaimers(
        registeredDisclaimers: RegisteredDisclaimer[]
    ): void;
}

export interface DisclaimerProviderProps {
    /** the context - should be created with `createDisclaimerContext` */
    ContextType: React.Context<DisclaimerContextData>;

    /** disclaimer types the registered disclaimers should be filtered for */
    disclaimerTypes: DisplayType[];

    /** child function */
    children(disclaimers: ScopedDisclaimerStore): JSX.Element;
}

/**
 * Creates Context.Provider and holds the registered disclaimers of a specified disclaimer type.
 *
 * The React.Context has to be provided via property `createDisclaimerContext`.
 *
 * The registered disclaimers will be updated by calling the `useDisclaimerReferences` method
 * (this will call the `handleRegisteredDisclaimers` method).
 *
 * The full text refs and callbacks will be updated within `DisclaimersWithSafewords` by calling
 * the `handleAddedRef` and `handleAddedFulltextCallback` methods.
 */

export class DisclaimerProvider extends React.PureComponent<
    DisclaimerProviderProps
> {
    private readonly store: ScopedDisclaimerStore;
    public constructor(props: DisclaimerProviderProps) {
        super(props);

        this.store = new ScopedDisclaimerStore(props.disclaimerTypes);
    }

    public render(): JSX.Element {
        const {ContextType} = this.props;

        const store = this.store;

        return (
            <ContextType.Provider
                value={{
                    handleRegisteredDisclaimers: this
                        .handleRegisteredDisclaimers,
                    handleUnregisterDisclaimers: this
                        .handleUnregisterDisclaimers,
                    handleAddedRef: this.handleAddedRef,
                    getFullTextDisclaimer: (reference: string) => {
                        return store.fullTextDisclaimers.get(reference);
                    }
                }}
            >
                {this.props.children(this.store)}
            </ContextType.Provider>
        );
    }

    /**
     * will add new full text refs to the full text ref list
     */
    private readonly handleAddedRef = (
        reference: string,
        disclaimerType: DisplayType,
        ref: React.RefObject<HTMLDivElement>,
        callback: FullTextCallback
    ) => {
        const {disclaimerTypes} = this.props;
        if (disclaimerTypes.indexOf(disclaimerType) < 0) {
            return;
        }

        this.store.handleAddedRef(reference, ref, callback);
    };

    /**
     * will add newly registered disclaimers to the disclaimer list
     */
    private readonly handleRegisteredDisclaimers = (
        registeredDisclaimers: RegisteredDisclaimer[]
    ) => {
        this.store.handleRegisteredDisclaimers(registeredDisclaimers);
    };

    /**
     * will add newly registered disclaimers to the disclaimer list
     */
    private readonly handleUnregisterDisclaimers = (
        registeredDisclaimers: RegisteredDisclaimer[]
    ) => {
        this.store.handleUnregisteredDisclaimers(registeredDisclaimers);
    };
}
