import * as React from 'react';

import {
    VideoPlayerV2PlayState,
    VideoPlayerV2State
} from '../../d6/components/video-player-v2';

import {
    TrackingEventAction,
    TrackingService,
    VideoPlayerData,
    ContextTrackingData
} from '../../context/tracking/TrackingService';
import {
    VideoTrackingState,
    createInitTrackingState,
    createMilestoneTimes,
    createMilestoneVideoTrackingData,
    getMilestoneIndex
} from '../../modules/editorial/elements/videoElement/helpers';

export class VideoPlayerTrackingService {
    private contentId: string;
    private fileReference: string;
    private title: string;
    private trackingService?: TrackingService;
    private videoTrackingState: VideoTrackingState = createInitTrackingState();
    private trackingData: ContextTrackingData;

    public constructor(
        contentId: string,
        title: string,
        fileReference: string,
        trackingData: ContextTrackingData,
        trackingService?: TrackingService
    ) {
        this.trackingService = trackingService;
        this.trackingData = trackingData;
        this.contentId = contentId;
        this.title = title;
        this.fileReference = fileReference;
    }

    public readonly setTrackingService = (
        trackingService: TrackingService
    ): void => {
        this.trackingService = trackingService;
    };

    public readonly trackVideoStateChange = (
        data: VideoPlayerV2State,
        startedByIO: boolean
    ) => {
        if (
            data.videoState === VideoPlayerV2PlayState.PLAYING &&
            !this.videoTrackingState.playEventFired
        ) {
            this.firePlayTrackingEvent(startedByIO);
        }
        if (data.currentTime >= this.videoTrackingState.nextMilestoneTime) {
            this.fireMilestoneTrackingEvent(data.currentTime);
        }
    };

    public readonly trackPlayClick = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_VideoPlay_Click,
                this.contentId,
                {
                    ...this.createGeneralVideoTrackingData(),
                    playReason: 'click'
                },
                this.trackingData
            );
        }
    };

    public readonly trackPauseClick = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_VideoPause_Click,
                this.contentId,
                {
                    ...this.createGeneralVideoTrackingData(),
                    pauseReason: 'click'
                },
                this.trackingData
            );
        }
    };

    public readonly trackAutomaticPause = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_VideoPause_Click,
                this.contentId,
                {
                    ...this.createGeneralVideoTrackingData(),
                    pauseReason: 'auto'
                },
                this.trackingData
            );
        }
    };

    public readonly trackFullScreenEntered = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_Fullscreen_Open,
                this.contentId,
                this.createGeneralVideoTrackingData(),
                this.trackingData
            );
        }
    };

    public readonly trackFullScreenExited = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_Fullscreen_Close,
                this.contentId,
                this.createGeneralVideoTrackingData(),
                this.trackingData
            );
        }
    };

    public readonly trackMute = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_Mute_Click,
                this.contentId,
                this.createGeneralVideoTrackingData(),
                this.trackingData
            );
        }
    };

    public readonly trackUnmute = () => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_Unmute_Click,
                this.contentId,
                this.createGeneralVideoTrackingData(),
                this.trackingData
            );
        }
    };

    public readonly initMilestones = (
        e: React.SyntheticEvent<HTMLVideoElement>
    ) => {
        const {duration} = e.currentTarget;
        if (duration !== this.videoTrackingState.duration) {
            const milestoneTimes = createMilestoneTimes(duration);
            this.videoTrackingState = {
                ...this.videoTrackingState,
                milestoneTimes,
                duration,
                nextMilestoneIndex: 0,
                nextMilestoneTime: milestoneTimes[0]
            };
        }
    };

    private readonly increaseMilestone = (milestoneIndex: number) => {
        this.videoTrackingState.nextMilestoneIndex = milestoneIndex + 1;
        this.videoTrackingState.nextMilestoneTime = this.videoTrackingState.milestoneTimes[
            this.videoTrackingState.nextMilestoneIndex
        ];
    };

    private readonly createGeneralVideoTrackingData = (): VideoPlayerData => {
        const {title, fileReference} = this;

        return {
            playerName: 's2-video-player',
            id: 's2-video-player',
            platform: 'aem',
            title,
            url: fileReference
        };
    };

    private readonly firePlayTrackingEvent = (startedByIO: boolean) => {
        if (this.trackingService) {
            this.trackingService.trackVideoEvent(
                TrackingEventAction.VideoPlayer_Video_Start,
                this.contentId,
                {
                    ...this.createGeneralVideoTrackingData(),
                    playReason: startedByIO ? 'auto' : 'click'
                },
                this.trackingData
            );
        }
        this.videoTrackingState.playEventFired = true;
    };

    private readonly fireMilestoneTrackingEvent = (currentTime: number) => {
        if (this.trackingService) {
            const milestoneIndex = getMilestoneIndex(
                currentTime,
                this.videoTrackingState.milestoneTimes
            );
            this.trackingService.trackVideoMilestoneEvent(
                this.contentId,
                {
                    ...this.createGeneralVideoTrackingData(),
                    ...createMilestoneVideoTrackingData(
                        milestoneIndex,
                        this.videoTrackingState
                    )
                },
                this.trackingData
            );
            this.increaseMilestone(milestoneIndex);
        }
    };
}
