import {SerializedStateManagerV1} from '@feature-hub/serialized-state-manager';
import * as React from 'react';
import {Registry} from '../di/Registry';

export const getUrlsForHydrationFromDom = (): string[] => {
    const scriptElement = document.querySelector(
        'script[type="x-feature-hub/urls-for-hydration"]'
    );

    if (!scriptElement || !scriptElement.textContent) {
        return [];
    }

    return JSON.parse(scriptElement.textContent);
};

export const getSerializedStatesFromDom = (): string | undefined => {
    const scriptElement = document.querySelector(
        'script[type="x-feature-hub/serialized-states"]'
    );

    return (scriptElement && scriptElement.textContent) || undefined;
};

export interface SerializedStatesScriptTagProps {
    registry: Registry;
}

export function SerializedStatesScriptTag(
    props: SerializedStatesScriptTagProps
): JSX.Element {
    const manager: SerializedStateManagerV1 = props.registry.getSingleton(
        'SerializedStateManager'
    );

    return (
        <script
            type="x-feature-hub/serialized-states"
            dangerouslySetInnerHTML={{__html: manager.serializeStates()}}
            async
        />
    );
}

export interface UrlHydrationScriptTagProps {
    urls: Set<string>;
}

export function UrlHydrationScriptTags(
    props: UrlHydrationScriptTagProps
): JSX.Element {
    const urlArray = Array.from(props.urls);

    const scriptTags = urlArray.map((url, idx) => (
        <link
            key={idx}
            rel="preload"
            href={url}
            as="script"
            crossOrigin="anonymous"
        />
    ));

    return (
        <>
            <script
                type="x-feature-hub/urls-for-hydration"
                dangerouslySetInnerHTML={{__html: JSON.stringify(urlArray)}}
                async
            />
            {scriptTags}
        </>
    );
}
