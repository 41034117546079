import {
    FeatureServiceProviderDefinition,
    FeatureServices
} from '@feature-hub/core';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import {
    defineTrackingServiceConfig,
    SharedTrackingServiceConfig,
    TrackingServiceOptions
} from '@volkswagen-onehub/tracking-service-config';
import {ApplicationTrackingData} from '../../../generated/core';
import {ContentStore, ContentStoreId} from '../../context/content/ContentStore';
import {TrackEventHolder} from '../../context/logger/TrackEventHolder';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {Registry} from '../di/Registry';

export function createTrackingServiceOptions(
    trackingData: ApplicationTrackingData,
    registry: Registry
): TrackingServiceOptions {
    const vwdata = isInBrowser() ? window.vwdata : undefined;
    const provider = {
        push(data: any): void {
            const contentStore: ContentStore = registry.getSingleton(
                ContentStoreId
            );
            const pageModel = contentStore.getCurrentPageRootModel();

            trackIntoLog(registry, data);
            if (
                vwdata &&
                !trackingData.disableTrackingEvents &&
                (!pageModel ||
                    !pageModel.mydealerPageOwner ||
                    !pageModel.mydealerPageOwner.trackingDisabled)
            ) {
                vwdata.push(data);
            }
        }
    };

    return {
        breakPoints: [
            Breakpoints.b560 as number,
            Breakpoints.b960 as number,
            Breakpoints.b1280 as number,
            Breakpoints.b1600 as number,
            Breakpoints.b1920 as number,
            Breakpoints.b2560 as number
        ],
        environmentData: {
            country: trackingData.countryCode,
            language: trackingData.languageCode,
            applicationId: trackingData.trackingApplicationId
        },
        provider,
        navigationDataFn: () => {
            const contentStore: ContentStore = registry.getSingleton(
                ContentStoreId
            );
            const pageModel = contentStore.getCurrentPageRootModel();
            if (pageModel) {
                return {
                    pageName: pageModel.pageTrackingModel.trackingPageName,
                    pageCategory: pageModel.pageTrackingModel.category
                };
            }

            return {};
        }
    };
}

function trackIntoLog(registry: Registry, data: any) {
    const trackEventHolder: TrackEventHolder = registry.getSingleton(
        'TrackEventHolder'
    );
    if (trackEventHolder) {
        trackEventHolder.track(data);
    }
}

export function createTrackingServiceConfig(
    config: ApplicationTrackingData,
    registry: Registry
): FeatureServiceProviderDefinition<
    SharedTrackingServiceConfig,
    FeatureServices
> {
    const definition = defineTrackingServiceConfig(
        createTrackingServiceOptions(config, registry)
    );

    return definition;
}
