import {
    SharedSerializedStateManager,
    defineSerializedStateManager
} from '@feature-hub/serialized-state-manager';
import {ServiceRegistration} from './ServiceRegistration';

export function createSerializedStateManager(
    state?: string
): ServiceRegistration<SharedSerializedStateManager> {
    return {
        definition: defineSerializedStateManager(state),
        service: {
            typeName: 'SerializedStateManager',
            version: '^1.0.0'
        }
    };
}
