import {isInBrowser} from './browser/isInBrowser';

export async function domReady(): Promise<Event | void> {
    return new Promise<Event | void>((resolve, reject): void => {
        if (!isInBrowser()) {
            reject(new Error('DOM is available only in browser'));
        } else {
            if (document.readyState !== 'loading') {
                resolve();
            } else {
                document.addEventListener('DOMContentLoaded', resolve);
            }
        }
    });
}
