import React, {useEffect} from 'react';
import {useFeatureAppVisibilityService} from '../index';
import {FeatureAppVisibilityService} from '@volkswagen-onehub/feature-app-visibility-service';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export const useFeatureAppsVisibilityListener = (
    consumer: string | string[],
    callback: (visible: boolean) => void
) => {
    const consumerIds: string[] =
        typeof consumer === 'string' ? [consumer] : consumer;

    // if only feature apps and none of them is shown
    const visibilityService = useFeatureAppVisibilityService();

    // use ref to have an object that is all time valid within the listener function
    const visibilityState = React.useRef<boolean>(true);

    const checkAndUpdateVisibility = React.useCallback(() => {
        const visibility = hasVisibleFAs(consumerIds, visibilityService);
        if (visibility !== visibilityState.current) {
            callback(visibility);
            visibilityState.current = visibility;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consumer, callback]);

    useEffect(() => {
        // initial state
        checkAndUpdateVisibility();

        // listener
        const deleteListenerMap: any = {};
        consumerIds.forEach((consumerId: string) => {
            const appChannelService = visibilityService.tune(consumerId);
            deleteListenerMap[consumerId] = appChannelService.listen(
                (data, oldData) => {
                    if (
                        data !== undefined &&
                        data.visible !== oldData?.visible
                    ) {
                        checkAndUpdateVisibility();
                    }
                }
            );
        });

        return () => {
            // remove listeners
            consumerIds.forEach(consumerId => {
                deleteListenerMap[consumerId]();
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkAndUpdateVisibility]);

    // set visibility for SSR
    if (!isInBrowser()) {
        // get the visibility that was set after the promise from the feature app was resolved.
        // Setting the visibility will only work for feature apps that use asyncSsrManager to trigger another render.
        checkAndUpdateVisibility();
    }
};

export function hasVisibleFAs(
    consumerIds: string[],
    visibilityService: FeatureAppVisibilityService
): boolean {
    const visibilities = getVisibilities(consumerIds, visibilityService);
    return (
        consumerIds
            .map(consumerId => visibilities[consumerId]) // undefined/true/false
            .filter(visible => visible === undefined || visible).length > 0
    );
}

function getVisibilities(
    consumerIds: string[],
    visibilityService: FeatureAppVisibilityService
): {[key: string]: boolean} {
    return consumerIds.reduce((acc, consumerId) => {
        const appChannelService = visibilityService.tune(consumerId);
        const visible = appChannelService.get()?.visible;
        acc[consumerId] = visible === undefined ? true : visible;

        return acc;
    }, {} as {[key: string]: boolean});
}
