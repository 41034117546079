import * as React from 'react';
import {css} from 'styled-components';

import {getElectricTransition} from '../helpers';
import {TeaserLinkProps} from './types';
import {styled} from '@volkswagen-onehub/components-core';

interface StyledTeaserElementLinkProps {
    isFocused?: boolean;
}

const hoverStyle = css`
    color: ${props => props.theme.colors.content.active};
    outline: 0;
`;

const StyledTeaserElementLink = styled.a<StyledTeaserElementLinkProps>`
	display: block;
	text-decoration: none;
	cursor: pointer;
	color: ${props => props.theme.colors.content.primary};
	${props => props.isFocused && hoverStyle};
	:hover{
		${hoverStyle}
	}
	:focus {
		${hoverStyle}
		outline: solid 2px ${props => props.theme.colors.focus.main};
	}
	${getElectricTransition('color')};
`;

export const TeaserElementLink: React.FunctionComponent<TeaserLinkProps> = props => {
    const {
        elementRef,
        href,
        disabled,
        onClick,
        onMouseOver,
        onMouseOut,
        title,
        target,
        rel,
        children,
        isFocused
    } = props;

    return (
        <StyledTeaserElementLink
            tabIndex={0}
            ref={elementRef}
            href={href}
            onClick={(!disabled && onClick) || undefined}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            target={target}
            rel={rel}
            title={title}
            isFocused={isFocused}
        >
            {children}
        </StyledTeaserElementLink>
    );
};
