import * as React from 'react';

export type SectionContextData = null | {
    sectionId: string;
    contentName?: string;
    skipWrapper?: boolean;
    contentLabels?: string;
};

export const SectionContext = React.createContext<SectionContextData>(null);
