import {
    ConsumerLocation,
    RootLocationDescriptorObject,
    RootLocationTransformer as RootLocationTransformerFS,
    createRootLocationForOtherConsumer,
    getConsumerPath
} from '@feature-hub/history-service';
import {parsePath} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';
import {URLSearchParams} from '../../../utils/UrlSearchParams';
import {createRootLocationForPrimaryConsumer} from './createRootLocationForPrimaryConsumer';
import {createRootLocationForSecondaryConsumer} from './createRootLocationForSecondaryConsumer';
import {getPrimaryConsumerPath} from './getPrimaryConsumerPath';
import {getSecondaryConsumerPath} from './getSecondaryConsumerPath';
import {isNewPrimaryPage} from './isNewPrimaryPage';

//

export const SECONDARY_PARAM_SUFFIX = '-2nd';

export interface RootLocationOptions {
    readonly consumerPathsQueryParamName: string;
    readonly primaryConsumerHistoryKey: string;
    readonly secondaryExtension: string;
    getSecondaryConsumerId(pathname: string): {[consumerId: string]: string};
}

export interface RootLocationTransformer {
    getConsumerPathFromRootLocation(
        rootLocation: history.Location,
        consumerId: string
    ): string | undefined;

    createRootLocation(
        consumerLocation: history.Location | undefined,
        currentRootLocation: history.Location,
        consumerId: string
    ): history.LocationDescriptorObject;
}

function getPathname(path?: string): string {
    return path ? parsePath(new URL('http://ddd.de' + path).pathname).base : '';
}

export function createSearchParams(
    location: history.LocationDescriptorObject | undefined
): URLSearchParams {
    return new URLSearchParams(location && location.search);
}

function getConsumerLocation(
    historyKey: string,
    consumerLocations: ConsumerLocation[]
): ConsumerLocation | undefined {
    const primaryConsumerLocations = consumerLocations.filter(
        consumerLocation => consumerLocation.historyKey === historyKey
    );
    if (primaryConsumerLocations.length === 1) {
        return primaryConsumerLocations[0];
    }

    return undefined;
}

export class CmsRootLocationTransformer implements RootLocationTransformerFS {
    private options: RootLocationOptions;

    public constructor(options: RootLocationOptions) {
        this.options = options;
    }

    public createNewRootLocationForMultipleConsumers(
        ...consumerLocations: ConsumerLocation[]
    ): RootLocationDescriptorObject {
        let start: RootLocationDescriptorObject = {pathname: '/'};

        let primaryConsumerLocation = getConsumerLocation(
            this.options.primaryConsumerHistoryKey,
            consumerLocations
        );
        if (!primaryConsumerLocation) {
            primaryConsumerLocation = {
                historyKey: this.options.primaryConsumerHistoryKey,
                location: {pathname: '/'}
            };
        }

        const secondaries = this.options.getSecondaryConsumerId(
            parsePath(primaryConsumerLocation.location.pathname || '/').base
        );
        start = createRootLocationForPrimaryConsumer(
            start,
            primaryConsumerLocation.location,
            this.options.consumerPathsQueryParamName,
            true,
            secondaries
        );
        Object.keys(secondaries).forEach(consumerId => {
            const secondaryConsumerLocation = getConsumerLocation(
                consumerId,
                consumerLocations
            );
            const secondaryKey = secondaries[consumerId];
            if (secondaryConsumerLocation) {
                start = createRootLocationForSecondaryConsumer(
                    start,
                    secondaryConsumerLocation.location,
                    secondaryKey,
                    this.options.secondaryExtension,
                    '-' + secondaryKey
                );
            }
        });

        consumerLocations
            .filter(
                consumerLocation =>
                    ![
                        ...Object.keys(secondaries),
                        this.options.primaryConsumerHistoryKey
                    ].includes(consumerLocation.historyKey)
            )
            .forEach(
                consumerLocation =>
                    (start = createRootLocationForOtherConsumer(
                        start,
                        consumerLocation.location,
                        consumerLocation.historyKey,
                        this.options.consumerPathsQueryParamName
                    ) as RootLocationDescriptorObject)
            );

        return start;
    }

    public getConsumerPathFromRootLocation(
        rootLocation: RootLocationDescriptorObject,
        historyKey: string
    ): string | undefined {
        const {
            consumerPathsQueryParamName,
            primaryConsumerHistoryKey
        } = this.options;

        const rootLocationPathname = rootLocation.pathname || '/';
        const path = parsePath(rootLocationPathname);
        const primaryPathname = path.base;

        const secondaries = this.options.getSecondaryConsumerId(
            primaryPathname
        );
        const secondaryKey = secondaries[historyKey];

        const isPrimaryConsumer = historyKey === primaryConsumerHistoryKey;
        const searchParams = createSearchParams(rootLocation);

        if (isPrimaryConsumer) {
            return getPrimaryConsumerPath(
                rootLocation,
                consumerPathsQueryParamName,
                secondaries
            );
        }

        if (secondaryKey) {
            return getSecondaryConsumerPath(
                rootLocation,
                secondaryKey,
                '-' + secondaryKey
            );
        }

        const consumerPaths = searchParams.get(consumerPathsQueryParamName);

        if (!consumerPaths) {
            return undefined;
        }

        return getConsumerPath(consumerPaths, historyKey);
    }

    public createRootLocation(
        currentRootLocation: RootLocationDescriptorObject,
        consumerLocation: history.LocationDescriptorObject,
        historyKey: string
    ): RootLocationDescriptorObject {
        const {
            consumerPathsQueryParamName,
            primaryConsumerHistoryKey,

            secondaryExtension
        } = this.options;
        const isPrimaryConsumer = historyKey === primaryConsumerHistoryKey;
        const primaryConsumerLocation = this.getConsumerPathFromRootLocation(
            currentRootLocation,
            this.options.primaryConsumerHistoryKey
        );
        const secondaries = this.options.getSecondaryConsumerId(
            getPathname(primaryConsumerLocation)
        );
        const secondaryKey = secondaries[historyKey];

        if (isPrimaryConsumer) {
            const newPage = isNewPrimaryPage(
                currentRootLocation,
                consumerLocation
            );

            return createRootLocationForPrimaryConsumer(
                currentRootLocation,
                consumerLocation,
                consumerPathsQueryParamName,
                newPage,
                secondaries
            );
        }

        if (secondaryKey) {
            return createRootLocationForSecondaryConsumer(
                currentRootLocation,
                consumerLocation,
                secondaryKey,
                secondaryExtension,
                '-' + secondaryKey
            );
        }

        return createRootLocationForOtherConsumer(
            currentRootLocation,
            consumerLocation,
            historyKey,
            consumerPathsQueryParamName
        ) as RootLocationDescriptorObject;
    }
}

export function createRootLocationTransformer(
    options: RootLocationOptions
): CmsRootLocationTransformer {
    return new CmsRootLocationTransformer(options);
}
