import BezierEasing from 'bezier-easing';

type animateMotionOptions = {
    animationDuration: number;
    easingFunction: Function;
    movingFunction: Function;
    callbackFunction?: Function;
};

export function animateMotion({
    animationDuration,
    easingFunction,
    movingFunction,
    callbackFunction
}: animateMotionOptions) {
    const startTime = performance.now();

    function animate(time: number) {
        const currentTime = Math.min((time - startTime) / animationDuration, 1);
        const val = easingFunction(currentTime);

        movingFunction(val);

        return currentTime < 1
            ? window.requestAnimationFrame(animate)
            : callbackFunction?.();
    }
    animate(performance.now());
}

const P0 = 0.42;
const P1 = 0.01;
const P2 = 0.22;
const P3 = 0.99;

export const ANIMATION_DURATION = 1200;
const EASING_FUNCTION = BezierEasing(P0, P1, P2, P3);

type SmoothScrollProps = {
    distance: number;
    scrollableElement?: HTMLElement;
    animationDuration?: number;
    onScrollEnd?: () => void;
};

export const smoothScroll = ({
    distance,
    scrollableElement = document.documentElement,
    animationDuration = ANIMATION_DURATION,
    onScrollEnd
}: SmoothScrollProps): void => {
    const startScroll = scrollableElement.scrollTop;
    const movingFunction = (value: number) => {
        scrollableElement.scrollTo &&
            scrollableElement.scrollTo(0, startScroll + distance * value);
    };

    animateMotion({
        animationDuration,
        easingFunction: EASING_FUNCTION,
        movingFunction,
        callbackFunction: onScrollEnd
    });
};

export const smoothScrollTo = ({
    distance,
    scrollableElement = document.documentElement,
    animationDuration = ANIMATION_DURATION,
    onScrollEnd
}: SmoothScrollProps): void => {
    const currentScrollPosition = scrollableElement.scrollTop;
    const movingFunction = (value: number) => {
        scrollableElement.scrollTo &&
            scrollableElement.scrollTo(
                0,
                currentScrollPosition +
                    (distance - currentScrollPosition) * value
            );
    };

    animateMotion({
        animationDuration,
        easingFunction: EASING_FUNCTION,
        movingFunction,
        callbackFunction: onScrollEnd
    });
};

export const smoothScrollToX = ({
    distance,
    scrollableElement = document.documentElement,
    animationDuration = ANIMATION_DURATION,
    onScrollEnd
}: SmoothScrollProps): void => {
    const currentScrollPosition = scrollableElement.scrollLeft;
    const movingFunction = (value: number) => {
        scrollableElement.scrollTo &&
            scrollableElement.scrollTo(
                currentScrollPosition +
                    (distance - currentScrollPosition) * value,
                0
            );
    };

    animateMotion({
        animationDuration,
        easingFunction: EASING_FUNCTION,
        movingFunction,
        callbackFunction: onScrollEnd
    });
};
