import {TokenTextAppearance} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {SimpleStageSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleStage} from '../../../d6/components/simple-stage';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';

const copyElement = (
    <CopyItem path="copy" appearance={TokenTextAppearance.headline300} />
);

/**
 * S120 Simple Stage Section
 */
export function SimpleStageSection(
    props: SimpleStageSectionModel
): JSX.Element {
    const {
        contentLabels,
        contentId: sectionId,
        anchorId,
        contentName,
        tagTypeBroken,
        mboxId
    } = props;

    const headingElement = (
        <HeadingElement path="heading" order="H1" style="H1" />
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S120 Simple Stage"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                sectionId={sectionId}
                anchorId={anchorId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
            >
                <SimpleStage
                    heading={headingElement}
                    subheading={copyElement}
                />
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const SIMPLE_STAGE_TYPE =
    'vwa-ngw18/components/editorial/sections/simpleStageSection';

MapTo(SIMPLE_STAGE_TYPE, SimpleStageSection);
