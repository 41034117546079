import {
    TokenTextAppearance,
    TextTag,
    styled,
    Text,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {MediaDisclaimerHolder} from '../MediaElement';

export const StyledDisclaimerBadges = styled.span`
    padding: ${props => props.theme.size.static250};
`;

export const DisclaimerBadges = (props: MediaDisclaimerHolder) => {
    if (!props.disclaimers) {
        return null;
    }

    const {interactiveReferences, staticReferences, color} = props.disclaimers;
    if (
        [interactiveReferences, staticReferences].every(
            reference => !Boolean(reference)
        )
    ) {
        return null;
    }

    return (
        <ThemeProvider theme={color === 'dark' ? 'main' : 'inverted'}>
            <StyledDisclaimerBadges>
                {staticReferences && (
                    <Text
                        appearance={TokenTextAppearance.copy100}
                        tag={TextTag.span}
                    >
                        {staticReferences}
                    </Text>
                )}
                {Boolean(interactiveReferences) && interactiveReferences}
            </StyledDisclaimerBadges>
        </ThemeProvider>
    );
};
