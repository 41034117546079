import * as React from 'react';
import styled from 'styled-components';

import {getStartDirection} from '../d6/components/helpers';
import {
    Text,
    TextTag,
    ThemeProvider,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';

import {MediaElementDisclaimerData} from '../modules/editorial/elements/MediaElement';
import {CyAttributeAppender} from '../test/CyAttributeAppender';

const StyledImageDisclaimerReferences = styled.span`
    position: absolute;
    ${props => getStartDirection(props.theme.direction)}: 0;
    bottom: 0;
    padding: ${props => props.theme.size.static250};
    display: flex;
    gap: ${props => props.theme.size.static100};
`;
StyledImageDisclaimerReferences.displayName = 'StyledImageDisclaimerReferences';

const StyledImageDisclaimerWrapper = styled.div`
    height: 100%;
    width: 100%;
`;

// TODO: Should be moved to LSG as estabilished pattern
export function ImageDisclaimerWrapper(
    props: React.PropsWithChildren<MediaElementDisclaimerData>
): JSX.Element {
    // NOTE:
    // zoom effect removed -> now handled as part of the image-link component
    const {
        children,
        staticReferences = [],
        interactiveReferences,
        color = 'dark'
    } = props;
    const hasStaticReferences =
        staticReferences && staticReferences.length !== 0;

    // NOTE:
    // - DIV wrapper added due positioning inheritance conflicts from image aspect ratio padding wrapper
    return (
        <StyledImageDisclaimerWrapper>
            {children}
            <ThemeProvider theme={color === 'dark' ? 'main' : 'inverted'}>
                <CyAttributeAppender name="imageDisclaimerReferences">
                    <StyledImageDisclaimerReferences>
                        {hasStaticReferences && (
                            <Text
                                appearance={TokenTextAppearance.copy100}
                                tag={TextTag.span}
                            >
                                {staticReferences}
                            </Text>
                        )}
                        {interactiveReferences ?? ''}
                    </StyledImageDisclaimerReferences>
                </CyAttributeAppender>
            </ThemeProvider>
        </StyledImageDisclaimerWrapper>
    );
}
