import {Model} from '@adobe/cq-react-editable-components';
import {ModelManager, TransferModel} from '@adobe/cq-spa-page-model-manager';
import {ObservableMap, action, observable, when} from 'mobx';
import {PageRootModel} from '../../../generated/core';
import {modelToProps} from '../../infrastructure/api/modelToProps';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {Logger} from '../logger/Logger';
import {ModelStore} from '../model/ModelStore';
import {PersonalizationStore} from '../personalization/PersonalizationStore';
import {RouterService} from '../route/RouterService';
import {
    ContentStore,
    ContentStoreId,
    ROOT_PROP,
    getCurrentPageRootModel
} from './ContentStore';

@singleton(ContentStoreId, {env: 'client'})
export class ClientContentStore implements ContentStore {
    private pageData!: ObservableMap<string, PageRootModel>;

    @inject()
    private routerService!: RouterService;

    @inject()
    private personalizationStore!: PersonalizationStore;

    @inject()
    private modelStore!: ModelStore;

    @inject()
    private logger!: Logger;

    public constructor() {
        this.pageData = observable.map({}, {deep: false});
    }

    @postConstruct()
    public initialize(): void {
        // initial page model must be able synchronuously
        const pagePath = this.routerService.pagePath;
        const initialModel = this.modelStore.getData(pagePath);
        if (initialModel) {
            this.pageData.set(
                pagePath,
                modelToProps(initialModel, pagePath) as PageRootModel
            );
        }
        this.routerService.listen(() => {
            const newPagePath = this.routerService.pagePath;
            const componentData = this.pageData.get(newPagePath);
            if (!componentData) {
                ModelManager.getData(newPagePath).then(
                    (model: TransferModel) => {
                        this.onLoad(newPagePath, model);
                    }
                );
            }
        });
    }

    public getPage(pagePath: string): Model | undefined {
        return this.pageData.get(pagePath);
    }

    public getCurrentPageRootModel(): PageRootModel | undefined {
        return getCurrentPageRootModel(this, this.routerService.pagePath || '');
    }

    public async getCurrentPageRootModelAsPromise(): Promise<PageRootModel> {
        const path = this.routerService.pagePath || '';

        const promise: Promise<PageRootModel> = new Promise(
            (resolve, reject) => {
                when(
                    () => getCurrentPageRootModel(this, path) !== undefined,
                    () => {
                        const loadedPageRootModel = getCurrentPageRootModel(
                            this,
                            path
                        );
                        if (loadedPageRootModel) {
                            resolve(loadedPageRootModel);
                        } else {
                            reject(`page ${path} was not loaded`);
                        }
                    }
                );
            }
        );

        return promise;
    }

    public getStageType(): string | undefined {
        const pageRootModel = getCurrentPageRootModel(
            this,
            this.routerService.pagePath || ''
        );
        if (pageRootModel) {
            const stageType = pageRootModel.stageType;
            const stagePath = pageRootModel.stagePath;
            const absStagePath = `${this.routerService.pagePath}/jcr:content/${ROOT_PROP}/${stagePath}`;
            const stageModel = this.modelStore.getData(absStagePath);
            const content = this.personalizationStore.getContent(
                absStagePath,
                stageModel && stageModel.mboxId
            );
            if (content && content.loaded && content.resource) {
                const path = content.resource.path;
                const transferModel = this.modelStore.getData(path);
                if (transferModel) {
                    const model = modelToProps(transferModel, path);
                    // get first component
                    const stage = model.cqItems[model.cqItemsOrder[0]];
                    return stage ? stage.cqType : undefined;
                }
            } else {
                // make sure it returns same default stageType on hydration
                return stageType;
            }
        }
        return undefined;
    }

    @action
    private onLoad(pagePath: string, transferModel: TransferModel): void {
        this.logger.general.info('update content store after page navigation');
        if (this.pageData.has(pagePath)) {
            return;
        }

        const model: Model = modelToProps(transferModel, pagePath);
        const pageRootModel = model as PageRootModel;
        this.pageData.set(pagePath, pageRootModel);
    }
}
