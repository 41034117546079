import {ParsysModel} from '../../../generated/core';
import {ExpandCollapseModule} from '../../d6/components/expand-collapse-module';
import * as React from 'react';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {getDnDContainer} from '../../utils/container/getDnDContainer';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {ContainerItem} from '../../infrastructure/ContainerExporter';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {C} from '../../registries/compatibilty';

interface ExpandCollapseSectionParsysExtraProps {
    hasThreeColumnLayout: boolean;
    hasSquaredRatio: boolean;
    disableImages: boolean;
    isInLayer?: boolean;
}

type ExpandCollapseSectionParsysProps = ParsysModel &
    ExpandCollapseSectionParsysExtraProps;

export function InternalExpandCollapseSectionParsys(
    props: ExpandCollapseSectionParsysProps
): JSX.Element {
    const {
        hasThreeColumnLayout,
        hasSquaredRatio,
        disableImages,
        isInLayer
    } = props;

    return (
        <ExpandCollapseModule
            isThreeColumnLayout={hasThreeColumnLayout}
            isInLayer={isInLayer}
        >
            {getChildItems(props).map((child: ContainerItem) => (
                <PersonalizationInclude
                    key={child.key}
                    resourceType={child.model.cqType}
                    path={child.key}
                    extraProps={{
                        hasSquaredRatio: hasSquaredRatio,
                        disableImages: disableImages
                    }}
                />
            ))}
            {C.isInEditor() &&
                getDnDContainer(
                    'vwa-ngw18/components/structure/expandCollapseSectionParsys',
                    props
                )}
        </ExpandCollapseModule>
    );
}

export const ExpandCollapseSectionParsys = MapTo<
    ExpandCollapseSectionParsysExtraProps
>(
    'vwa-ngw18/components/structure/expandCollapseSectionParsys',
    InternalExpandCollapseSectionParsys
);
