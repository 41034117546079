import {
    NavigationService,
    defineGfaNavigationService,
    NavigationServiceOptions,
    defineNavigationService
} from '@volkswagen-onehub/navigation-service';
import {SharedGfaNavigationService} from '@volkswagen-onehub/gfa-navigation-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createNavigationService(
    options: NavigationServiceOptions
): [
    ServiceRegistration<NavigationService>,
    ServiceRegistration<SharedGfaNavigationService>
] {
    const definition = defineNavigationService(options);
    const gfaDefinition = defineGfaNavigationService();
    return [
        {
            definition: definition,
            service: {
                typeName: 'FeaturehubNavigationService',
                version: '^1.2.0'
            }
        },
        {
            definition: gfaDefinition
        }
    ];
}
