import {Registry} from '../di/Registry';
import {ServiceRegistration} from './ServiceRegistration';
import {
    SharedLoginService,
    defineLoginService
} from '../../services/loginservice/defineLoginService';

export const createLoginService = (
    registry: Registry
): ServiceRegistration<SharedLoginService> => {
    return {
        env: 'client',
        service: {
            typeName: 'LoginFeatureService',
            version: '1.0.0'
        },
        definition: defineLoginService({
            serviceFactory() {
                return registry.getSingleton(
                    'MultipleCustomerLoginFeatureService'
                );
            }
        })
    };
};
