import {
    ShowroomNavigationManagerOptions,
    ShowroomNavigationService,
    defineShowroomNavigationManager
} from '@volkswagen-onehub/showroom-navigation';
import {ServiceRegistration} from './ServiceRegistration';

export const ShowroomNavigationManagerId = 'ShowroomNavigationManager';

export function createShowroomNavigationManager(
    navigationConfig: ShowroomNavigationManagerOptions
): ServiceRegistration<ShowroomNavigationService> {
    return {
        definition: defineShowroomNavigationManager(navigationConfig),
        service: {
            typeName: 'ShowroomNavigationManager',
            version: '^1.1.0'
        }
    };
}
