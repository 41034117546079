import {RootLocationDescriptorObject} from '@feature-hub/history-service';
import {
    createPath,
    parsePath,
    parseSuffix,
    updateSuffix
} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';
import {createSearchParams} from './CmsRootLocationTransformer';

export function createRootLocationForPrimaryConsumer(
    currentRootLocation: history.LocationDescriptorObject,
    primaryConsumerLocation: history.LocationDescriptorObject | undefined,
    consumerPathsQueryParamName: string,
    newPage: boolean,
    secondaries: {[consumerId: string]: string}
): RootLocationDescriptorObject {
    // secondary consumer can be ignored now. Actually all of the other search params as well. This will change later
    const originalPathname = currentRootLocation.pathname || '/';
    const allSearchParams = createSearchParams(currentRootLocation);
    const primarySearchParams = createSearchParams(primaryConsumerLocation);
    if (primarySearchParams.has(consumerPathsQueryParamName)) {
        throw new Error(
            `Primary consumer tried to set query parameter ${JSON.stringify(
                consumerPathsQueryParamName
            )} which is reserverd for consumer paths.`
        );
    }
    const consumerPaths = allSearchParams.get(consumerPathsQueryParamName);
    let search: string | undefined;
    // drop consumer paths if navigating to new primary path
    // TODO we currently ignore primary consumer completely as the default is that this is a new page navigation
    if (!newPage && consumerPaths) {
        primarySearchParams.set(consumerPathsQueryParamName, consumerPaths);
        search = primarySearchParams.toString();
    } else {
        search = primaryConsumerLocation ? primaryConsumerLocation.search : '';
    }
    let pathname =
        primaryConsumerLocation && primaryConsumerLocation.pathname
            ? primaryConsumerLocation.pathname
            : '/';
    if (!newPage && originalPathname) {
        // keep the full path with the secondary consumer path if primary path is not changed
        const originalPath = parsePath(originalPathname);
        const originalSuffix = originalPath.suffix
            ? parseSuffix(originalPath.suffix)
            : {};

        const newPath = parsePath(pathname);

        let mergedSuffix = newPath.suffix || '';
        Object.keys(secondaries).forEach(consumerId => {
            mergedSuffix = updateSuffix(
                mergedSuffix,
                secondaries[consumerId],
                originalSuffix[secondaries[consumerId]]
            );
        });

        pathname = createPath({
            base: originalPath.base,
            suffix: mergedSuffix
        });
    }
    const hash = primaryConsumerLocation
        ? primaryConsumerLocation.hash
        : undefined;

    return {pathname, search, hash};
}
