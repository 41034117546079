import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {
    ContentSliderSectionModel,
    CopyItemModel,
    HeadingElementModel,
    XfContentSliderModel
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {ContentSliderGroup} from './ContentSliderGroup';
import {SectionWrapper} from './SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {Model} from '@adobe/cq-react-editable-components';
import {AlertBox} from '../../../components/AlertBox';

const StyledHeadingWrapper = styled.div`
    padding-bottom: ${({theme}) => theme.size.dynamic0100};
    padding-inline: ${({theme}) => theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-start: ${({theme}) => theme.size.grid005};
        padding-inline-end: ${({theme}) => theme.size.grid008};
    }
    @media (min-width: ${Breakpoints.b1600}px) {
        padding-inline-start: ${({theme}) => theme.size.grid006};
    }
`;

const StyledCopyWrapper = styled.div`
    padding-inline: ${({theme}) => theme.size.grid002};
    margin-bottom: ${({theme}) => theme.size.dynamic0050};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${({theme}) => theme.size.grid006};
    }
    @media (min-width: ${Breakpoints.b1600}px) {
        padding-inline: ${({theme}) => theme.size.grid007};
    }
`;

const MIN_VALID_XF = 3;

type TypeWitItems = Pick<Model, 'cqItems' | 'cqItemsOrder'>;

interface HeadingElementModelExtended
    extends HeadingElementModel,
        TypeWitItems {}
interface CopyItemModelExtended extends CopyItemModel, TypeWitItems {}

export interface ContentSliderSectionModelExtended
    extends ContentSliderSectionModel {
    cqItems: {
        heading: HeadingElementModelExtended;
        copy: CopyItemModelExtended;
        [item: string]:
            | XfContentSliderModel
            | HeadingElementModelExtended
            | CopyItemModelExtended;
    };
}

export function InternalContentSliderSection(
    props: ContentSliderSectionModelExtended
): JSX.Element {
    const {
        anchorId,
        mboxId,
        cqItems,
        contentId: sectionId,
        tagTypeBroken,
        contentLabels,
        contentName,
        numberOfValidElements,
        validationError,
        validationMessages
    } = props;
    const showHeadline = C.isInEditor() || !cqItems.heading.empty;
    const showCopy = C.isInEditor() || !cqItems.copy.empty;
    const showSection = numberOfValidElements >= MIN_VALID_XF;

    const alertBox = validationError && C.isInEditor() && (
        <AlertBox>
            {validationMessages.map((msg, idx) => (
                <span key={idx}>{msg}</span>
            ))}
        </AlertBox>
    );

    const mainView = (
        <CyAttributeAppender name="contentSliderSection">
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider
                    displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                >
                    {showHeadline && (
                        <StyledHeadingWrapper>
                            <HeadingElement
                                path="heading"
                                order="H2"
                                newDisclaimer
                            />
                        </StyledHeadingWrapper>
                    )}
                    {showCopy && (
                        <StyledCopyWrapper>
                            <CopyItem
                                path="copy"
                                useDropCap={false}
                                newDisclaimer
                            />
                        </StyledCopyWrapper>
                    )}
                    {alertBox}
                    <ContentSliderGroup {...props} />
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </CyAttributeAppender>
    );

    const authorView = (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S137 Content Slider Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {mainView}
        </AuthoringWrapper>
    );

    const publishView = showSection ? mainView : <></>;

    return C.isInEditor() ? authorView : publishView;
}

export const ContentSliderSection = MapTo(
    'vwa-ngw18/components/editorial/sections/contentSliderSection',
    InternalContentSliderSection,
    {noElementRecursively: true}
);
