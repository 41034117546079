import {
    ContainerExporter,
    ContainerItem
} from '../../infrastructure/ContainerExporter';

export function getChildItems(props: ContainerExporter): ContainerItem[] {
    if (!props || !props.cqItems || !props.cqItemsOrder) {
        return [];
    }

    return props.cqItemsOrder.reduce((result, key) => {
        if (props.cqItems) {
            result.push({key, model: props.cqItems[key]});
        }

        return result;
    }, [] as ContainerItem[]);
}
