import * as React from 'react';

import {
    AuthoringMediaInfoBox,
    MediaInfo
} from '../../../../components/AuthoringMediaInfoBox';
import {
    AuthoringWrapper,
    authorContentHeight
} from '../../../../components/AuthoringWrapper';
import {DEFAULT_VIDEO_FOCAL_POINT} from '../../../../components/VideoComponent';
import {ImagePlaceHolderElement} from '../ImagePlaceHolderElement';
import {AnimationElementProps} from '../animationElement/AnimationElement';
import {VideoIcon} from '../media/icons';
import {VideoElementProps} from './VideoElement';
import {VideoWithFocalPoint} from '../../../../d6/components/video-with-focal-point';
import {ThemeProvider, styled} from '@volkswagen-onehub/components-core';
import {BigPlayButton} from '../../../../d6/components/video-player-v2/buttons';

const VIDEO_ELEMENT_LABEL = 'Video Element';

export type VideoElementAuthorProps = {authoringLabel?: string} & (
    | VideoElementProps
    | AnimationElementProps
);

const StyledVideoContainer = styled.div`
    position: relative;
    height: ${authorContentHeight};

    & button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export function VideoElementAuthor(
    props: VideoElementAuthorProps
): JSX.Element | null {
    const {coverImage, fileReference, videoMediaInfo, authoringLabel} = props;

    return (
        <AuthoringWrapper
            title={authoringLabel || VIDEO_ELEMENT_LABEL}
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <ThemeProvider theme="inverted">
                <AuthoringMediaInfoBox>
                    {coverImage?.mediaInfo && (
                        <MediaInfo {...coverImage.mediaInfo} />
                    )}
                    {videoMediaInfo && <MediaInfo {...videoMediaInfo} />}
                </AuthoringMediaInfoBox>
                {!fileReference ? (
                    <ImagePlaceHolderElement>
                        <VideoIcon />
                    </ImagePlaceHolderElement>
                ) : (
                    <StyledVideoContainer>
                        <VideoWithFocalPoint
                            poster={coverImage.src}
                            focalPoint={DEFAULT_VIDEO_FOCAL_POINT}
                            src={fileReference}
                        />
                        <BigPlayButton />
                    </StyledVideoContainer>
                )}
            </ThemeProvider>
        </AuthoringWrapper>
    );
}
