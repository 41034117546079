import {
    Logout as LogoutIcon,
    Settings as SettingsIcon
} from '@volkswagen-onehub/icons-core';
import React from 'react';
import styled from 'styled-components';
import {LoginFlyoutFooterLink} from '../../../components/LoginFlyout';
import {
    useLoginAlertService,
    useLoginFlyoutService,
    useLoginStore,
    useSpaAsyncConfig
} from '../../../context';
import {createBtnLogoutClick} from './createBtnLogoutClick';
import {createBtnSettingsClick} from './createBtnSettingsClick';
import {Spinner} from '@volkswagen-onehub/components-core';

const StyledIconRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.size.static400} 0;
`;

export const UtilityLoggedInFooter = () => {
    const {utilityNavigationModel} = useSpaAsyncConfig();
    const loginStore = useLoginStore();
    const loginFlyoutService = useLoginFlyoutService();
    const loginAlertService = useLoginAlertService();
    const userData = loginStore.userData;
    const handleBtnLogoutClick = createBtnLogoutClick(
        loginStore,
        loginFlyoutService,
        loginAlertService,
        utilityNavigationModel.btnCloseLabel
    );
    const handleBtnSettingsClick = createBtnSettingsClick(
        userData,
        loginStore,
        false
    );

    const settings = userData ? (
        <LoginFlyoutFooterLink
            key="flyoutSettingsLink"
            href="#"
            label={utilityNavigationModel.btnSettingsLabel}
            icon={<SettingsIcon ariaHidden />}
            onClick={handleBtnSettingsClick}
            contentId={'utility-settings'}
        />
    ) : (
        <Spinner />
    );

    return (
        <StyledIconRow>
            {settings}
            <LoginFlyoutFooterLink
                key="flyoutLogoutLink"
                href="#"
                label={utilityNavigationModel.btnLogoutLabel}
                icon={<LogoutIcon ariaHidden />}
                onClick={handleBtnLogoutClick}
                contentId={'utility-logout'}
            />
        </StyledIconRow>
    );
};
