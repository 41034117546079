import {Breakpoints} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import {NavBarLink} from '../../../d6/components/inpage-navigation-bar';
import {HORIZONTAL_FADE_WIDTH} from '../../../d6/components/inpage-navigation-bar/helpers';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {smoothScroll} from '../../../utils/smoothScroll';

const StyledPageNameLinkWrapper = styled.div`
    cursor: pointer;
    display: none;

    @media (min-width: ${Breakpoints.b1280}px) {
        display: inline-block;
    }

    a:hover {
        color: initial;
    }

    & a:after {
        visibility: hidden;
    }

    & a:hover:after {
        visibility: hidden;
    }

    & span {
        max-width: calc(
            ${props => props.theme.size.grid003} - ${HORIZONTAL_FADE_WIDTH}px
        );
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const PageNameLink: React.FunctionComponent<{
    pageName: string | undefined;
}> = observer(function PNL(props: {
    pageName: string | undefined;
}): JSX.Element | null {
    const {pageName} = props;

    const handlePageNavigationLinkClick = (
        e: React.MouseEvent<HTMLElement>
    ): void => {
        e.preventDefault();
        const scrollDistance = document.documentElement.scrollTop;
        smoothScroll({
            distance: -scrollDistance
        });
    };

    if (!pageName) {
        return null;
    }

    return (
        <CyAttributeAppender name="in-page-navigation-name">
            <StyledPageNameLinkWrapper>
                <NavBarLink onClick={handlePageNavigationLinkClick} href="#">
                    {pageName}
                </NavBarLink>
            </StyledPageNameLinkWrapper>
        </CyAttributeAppender>
    );
});
PageNameLink.displayName = 'InPageNavigationPageNameLink';
export {PageNameLink};
