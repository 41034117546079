import * as React from 'react';

import {
    BreakpointWrapper,
    Breakpoints,
    Container,
    ContainerPadding,
    FixedWidthContainer,
    Layout,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {LinkTarget} from '../../../../d6/components/teaser-element';

import {LayerType} from '../../../../../generated/api';
import {AuthoringWrapper} from '../../../../components/AuthoringWrapper';
import {CmsTextLink} from '../../../../components/links/CmsTextLink';
import {
    TeaserImageLink,
    TeaserMainLinkProps
} from '../../../../components/teaser-element/helpers';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {HeadingElement} from '../HeadingElement';
import {MediaElement} from '../MediaElement';
import {RichtextSimpleElement} from '../RichtextElement';

export const TeaserElementMediaLink: React.FunctionComponent<TeaserMainLinkProps> = props => (
    <TeaserImageLink
        {...props}
        layerProps={{layerType: props.layerType || 'CONTENT'}}
    >
        <MediaElement path={'media'} />
    </TeaserImageLink>
);

export const SmallTeaserElementMediaLink: React.FunctionComponent<TeaserMainLinkProps> = props => {
    const media = <TeaserElementMediaLink {...props} />;

    return (
        <>
            <BreakpointWrapper min={Breakpoints.b560} max={Breakpoints.b1600}>
                <FixedWidthContainer columns={4}>{media}</FixedWidthContainer>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b1600}>
                <FixedWidthContainer columns={3}>{media}</FixedWidthContainer>
            </BreakpointWrapper>
        </>
    );
};

export const TeaserElementExperienceFragmentView: React.FunctionComponent = ({
    children
}) => (
    <Layout
        appearance={{
            [Breakpoints.default]: [{name: 'a', columns: 24}], // on desktop 1 column
            [Breakpoints.b560]: [{name: 'a', columns: 8}] // on mobile 3 columns
        }}
    >
        <Container
            padding={{
                left: ContainerPadding.grid001,
                right: ContainerPadding.grid001
            }}
            stretchContent
        >
            {children}
        </Container>
    </Layout>
);

export function TeaserElementHeading(): JSX.Element {
    return <HeadingElement path="heading" style="H3" order="H3" />;
}

export const teaserElementAbstract = (
    <CyAttributeAppender name="editorialTeaserElementCopy">
        <RichtextSimpleElement
            path="abstract"
            appearance={TokenTextAppearance.copy200}
            inheritColor // used by teaser link hover effect
            tag={TextTag.p}
        />
    </CyAttributeAppender>
);

export function SmallTeaserElementHeading(): JSX.Element {
    return <HeadingElement path="heading" style="H4" order="H3" />;
}

export const TeaserElementAuthoringWrapper: React.FunctionComponent<{
    emptyLabel: string;
    tagTypeBroken: boolean;
}> = props => (
    <AuthoringWrapper
        title={props.emptyLabel}
        bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        tagTypeBroken={props.tagTypeBroken}
    >
        {props.children}
    </AuthoringWrapper>
);

export interface TeaserElementAditionalLinkProps {
    readonly contentId?: string;
    readonly href?: string;
    readonly text?: string;
    readonly isLayerLink?: boolean;
    readonly layerType?: LayerType;
    readonly target?: LinkTarget;
}

export const TeaserElementAdditionalLink: React.FunctionComponent<TeaserElementAditionalLinkProps> = props => (
    <Text appearance={TokenTextAppearance.copy200} bold>
        <CyAttributeAppender name="additionalTeaserLink">
            <CmsTextLink
                href={props.href}
                contentId={props.contentId}
                isLayerLink={props.isLayerLink}
                layerProps={{layerType: props.layerType || 'CONTENT'}}
                target={props.target}
                showExternalIcon
                emphasis="tertiary"
            >
                {props.text}
            </CmsTextLink>
        </CyAttributeAppender>
    </Text>
);
