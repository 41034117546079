export function parseActionUrl(href: string) {
    // old user interactions could contain '::' - this cannot be validated with URL class
    const withoutProtocol = href.replace(/^action:/, '');
    if (withoutProtocol.indexOf('::') >= 0) {
        // @ts-ignore
        const path = withoutProtocol.replace(/'\\?.*$/, '');
        const search = withoutProtocol.replace(path, '');
        return {actionId: path, search: search};
    }

    try {
        const url = new URL(href);
        const search = url.search;
        const actionId = url.pathname;
        return {actionId, search};
    } catch (e) {
        return {};
    }
}
