import * as React from 'react';
import styled from 'styled-components';

import {Direction} from '../helpers';

const StyledFlippinIcon = styled.div`
    svg {
        transform: rotateY(
            ${props =>
                props.theme.direction === Direction.RTL ? '180deg' : '0'}
        );
    }
`;

export const FlippinIcon: React.FunctionComponent = props => (
    <StyledFlippinIcon>{props.children}</StyledFlippinIcon>
);
