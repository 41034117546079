import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {
    AnimationElementData,
    BasicStageData,
    ButtonElementData,
    ImageElementData,
    MediaElementData,
    VideoElementData
} from './BasicStageData';
import {
    AnimationElementModel,
    BasicStageSectionModel,
    RtNodeUnion,
    Scene7VideoRendition,
    SpaAsyncConfig,
    VideoElementModel
} from '../../../generated/core';
import {processContentId} from '../../utils/processContentId';
import {ImageServiceV1} from '@volkswagen-onehub/image-service';
import {BasicStageSectionProps} from '../../modules/editorial/sections/basicstage/BasicStageSection';
import {SwappingProps} from '../../modules/editorial/sections/basicstage/swappable';
import {HeadingElementProps} from '../../modules/editorial/elements/HeadingElement';
import {ButtonElementProps} from '../../modules/editorial/elements/buttonElement/ButtonElement';
import {MediaElementProps} from '../../modules/editorial/elements/MediaElement';
import {ImageRenderElementProps} from '../../modules/editorial/elements/ImageElement';
import {VideoElementProps} from '../../modules/editorial/elements/videoElement/VideoElement';
import {AnimationElementProps} from '../../modules/editorial/elements/animationElement/AnimationElement';

export const convertBboStageData = (
    imageService: ImageServiceV1,
    spaAsyncConfig: SpaAsyncConfig,
    data: BasicStageData,
    originalModel: BasicStageSectionModel,
    originalCqPath: string
): TransferModel => {
    const model: TransferModel = {
        ...getStage(data, originalModel, originalCqPath),
        ...getContainerExporterProps(
            'vwa-ngw18/components/editorial/sections/basicStageSection'
        )
    };

    addItem(model, 'heading', {
        ...getHeading(data.heading),
        ...getContainerExporterProps(
            'vwa-ngw18/components/editorial/elements/headingElement'
        )
    });

    addItem(model, 'media', {
        ...getMedia(data.media),
        ...getContainerExporterProps(
            'vwa-ngw18/components/editorial/elements/mediaElement'
        )
    });

    if (data.media.mediaType === 'IMAGE') {
        addItem(model[':items'].media, 'image', {
            ...getImage(data.media.image),
            ...getContainerExporterProps(
                'vwa-ngw18/components/editorial/elements/imageElement'
            )
        });
    }

    if (data.media.mediaType === 'VIDEO') {
        addItem(model[':items'].media, 'video', {
            ...getVideo(
                data.media.video,
                originalCqPath + '/media',
                imageService,
                spaAsyncConfig
            ),
            ...getContainerExporterProps(
                'vwa-ngw18/components/editorial/elements/videoElement'
            )
        });
    }

    if (data.media.mediaType === 'ANIMATION') {
        addItem(model[':items'].media, 'animation', {
            ...getAnimation(
                data.media.animation,
                originalCqPath + '/media',
                imageService,
                spaAsyncConfig
            ),
            ...getContainerExporterProps(
                'vwa-ngw18/components/editorial/elements/animationElement'
            )
        });
    }

    if (data.primaryButton) {
        addItem(model, 'primaryButton', {
            ...getButton(data.primaryButton, 'primary', originalCqPath),
            ...getContainerExporterProps(
                'vwa-ngw18/components/editorial/elements/buttonElement'
            )
        });
    }

    if (data.secondaryButton) {
        addItem(model, 'secondaryButton', {
            ...getButton(data.secondaryButton, 'secondary', originalCqPath),
            ...getContainerExporterProps(
                'vwa-ngw18/components/editorial/elements/buttonElement'
            )
        });
    }

    return model;
};

const getStage = (
    data: BasicStageData,
    originalModel: BasicStageSectionModel,
    originalCqPath: string
): BasicStageSectionProps & SwappingProps => {
    const contentId = processContentId(originalCqPath, 'bbo');

    return {
        contentId,
        originalCqPath,
        anchorId: originalModel.anchorId,
        anchorModel: originalModel.anchorModel,
        enabledSectionInPageNav: originalModel.enabledSectionInPageNav,
        inPageNavigationTitle: originalModel.inPageNavigationTitle,
        showPrimaryButton: Boolean(data.primaryButton),
        showSecondaryButton: Boolean(data.secondaryButton),
        ...data.trackingModel,
        actionScriptReports: data.actionScriptReports || []
    } as BasicStageSectionProps & SwappingProps;
};

const getHeading = (heading: string): HeadingElementProps => {
    return {
        richtext: [{kind: 'textNode', value: heading} as RtNodeUnion],
        style: 'H2',
        order: 'H2'
    } as HeadingElementProps;
};

const getButton = (
    buttonData: ButtonElementData,
    type: 'primary' | 'secondary',
    originalCqPathParent: string
): ButtonElementProps => {
    return {
        ...buttonData,
        buttonStyle: type,
        originalCqPath: `${originalCqPathParent}/${type}Button`
    } as ButtonElementProps;
};

const getMedia = (media: MediaElementData): MediaElementProps => {
    return {
        ...media
    } as MediaElementProps;
};

const getImage = (image: ImageElementData): ImageRenderElementProps => {
    return {
        ...image,
        src: 'must be set', // see not loaded prop in ImageElement
        validScene7Asset: true
    } as ImageRenderElementProps;
};

const getVideo = (
    video: VideoElementData,
    originalCqPathParent: string,
    imageService: ImageServiceV1,
    spaAsyncConfig: SpaAsyncConfig
): VideoElementProps => {
    return {
        ...((video as unknown) as VideoElementModel),
        ...(video.trackingModel || {}),
        ...spaAsyncConfig.labels.videoElementLabels,
        fileReference: 'must be set',
        videoRenditions: getVideoRenditions(video.scene7File, imageService),
        coverImage: {
            ...video.coverImage,
            src: 'must be set',
            validScene7Asset: true
        },
        originalCqPath: originalCqPathParent + '/video'
    } as VideoElementProps;
};

const getAnimation = (
    animation: AnimationElementData,
    originalCqPathParent: string,
    imageService: ImageServiceV1,
    spaAsyncConfig: SpaAsyncConfig
): AnimationElementProps => {
    return {
        ...((animation as unknown) as AnimationElementModel),
        ...(animation.trackingModel || {}),
        ...spaAsyncConfig.labels.animationElementLabels,
        fileReference: 'must be set',
        originalCqPath: originalCqPathParent + '/animation',
        videoRenditions: getVideoRenditions(animation.scene7File, imageService),
        coverImage: {
            ...animation.coverImage,
            src: 'must be set', // see not loaded prop in ImageElement
            validScene7Asset: true
        }
    } as AnimationElementProps;
};

const getVideoRenditions = (
    scene7FileName: string,
    imageService: ImageServiceV1
): Scene7VideoRendition[] => {
    const configs = [
        {
            width: 640,
            height: 360,
            bitrate: 730000
        },
        {
            width: 960,
            height: 540,
            bitrate: 2000000
        },
        {
            width: 1280,
            height: 720,
            bitrate: 3000000
        }
    ];

    return configs.map(config => ({
        ...config,
        scene7Src: imageService.getVideoRenditionURL({
            scene7File: scene7FileName,
            metaData: config
        })
    }));
};

const getContainerExporterProps = (type: string) => ({
    ':items': {},
    ':itemsOrder': [],
    ':type': type
});

const addItem = (parent: TransferModel, name: string, child: TransferModel) => {
    parent[':items'][name] = child;
    parent[':itemsOrder'].push(name);
};
