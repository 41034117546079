import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    Fieldset,
    Layout,
    RadioButton,
    Text,
    TokenTextAppearance,
    TokenTextColor,
    styled
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {ButtonElementComponent} from '../../editorial/elements/buttonElement/ButtonElement';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {
    CIDK_LOGIN,
    IDK_LOGIN,
    LOGIN_BTN_LABEL,
    LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT_MULTIPLE_CUSTOMER_TYPES
} from './constants';
import {FlyoutLoggedOutMultipleCustomerTypesModel} from '../../../../generated/core';
import {CustomerType} from '@volkswagen-onehub/authservice-config';
import {LoginFlyoutViewV2} from '../../../components/LoginFlyoutV2';
import {
    useLoginAlertService,
    useLoginStore,
    useTrackingService
} from '../../../context';
import {getErrorStatus} from './FlyoutLoggedOut';
import {TextWithNonBreakingSafewords} from '../../../utils/TextWithNonBreakingSafewords';

const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

const TextWrapper = styled.div`
    color: ${props => props.theme.colors.content.primary};
`;
export interface ExtraLoginProps {
    login: (customerType: CustomerType) => Promise<void>;
}
function InternalFlyoutLoggedOutMultipleCustomerTypes(
    props: FlyoutLoggedOutMultipleCustomerTypesModel & ExtraLoginProps
): JSX.Element {
    const [selectedCustomerType, setSelectedCustomerType] = React.useState<
        CustomerType
    >('private');

    const loginStore = useLoginStore();
    const loginAlertService = useLoginAlertService();
    const trackingService = useTrackingService();

    const headingElement = <HeadingElement path="heading" order="H2" />;
    const copyItem = <CopyItem path="copy" />;

    // property provided by server can produce null
    const btnLoginLabel = props.btnLoginLabel || LOGIN_BTN_LABEL;

    const trackLoginBtnClick = (href: string, linkName: string) =>
        trackingService.trackButtonClick(
            selectedCustomerType === 'commercial' ? CIDK_LOGIN : IDK_LOGIN,
            href,
            linkName
        );

    const handleBtnLoginClick = async (e: React.MouseEvent) => {
        // please don't remove: required to avoid page load if the login request tooks long
        e.preventDefault();
        if (!loginStore.isLoggedIn) {
            try {
                await props.login(selectedCustomerType);
            } catch (error) {
                const status = getErrorStatus(error);
                loginAlertService.openAlert(status, props.btnCloseLabel);
            }
        }
    };

    const buttonElement = (
        <ButtonElementComponent
            buttonLabel={btnLoginLabel}
            buttonStyle="primary"
            trackingActionOverride={trackLoginBtnClick}
            layerButton
            onClick={handleBtnLoginClick}
            buttonTitle={btnLoginLabel}
            ignoreExternalLinkLightbox
        />
    );
    const privateCustomerRadioButton = (
        <RadioButton
            value="private"
            label={
                <TextWrapper>
                    <Text
                        appearance={TokenTextAppearance.copy200}
                        color={TokenTextColor.inherit}
                    >
                        <TextWithNonBreakingSafewords>
                            {props.privateRadioButtonLabel ?? ''}
                        </TextWithNonBreakingSafewords>
                    </Text>
                </TextWrapper>
            }
            name="customerType"
            checked={selectedCustomerType === 'private'}
            onClick={() => setSelectedCustomerType('private')}
        />
    );

    const commercialCustomerRadioButton = (
        <RadioButton
            value="commercial"
            label={
                <TextWrapper>
                    <Text
                        appearance={TokenTextAppearance.copy200}
                        color={TokenTextColor.inherit}
                    >
                        <TextWithNonBreakingSafewords>
                            {props.commercialRadioButtonLabel ?? ''}
                        </TextWithNonBreakingSafewords>
                    </Text>
                </TextWrapper>
            }
            name="customerType"
            checked={selectedCustomerType === 'commercial'}
            onClick={() => setSelectedCustomerType('commercial')}
        />
    );

    const copyWithRadioButtons = (
        <Container
            wrap={ContainerWrap.always}
            gutter={ContainerGutter.static350}
        >
            {copyItem}
            <div aria-label={props.radioGroupAriaLabel}>
                <Fieldset>
                    {privateCustomerRadioButton}
                    {commercialCustomerRadioButton}
                </Fieldset>
            </div>
        </Container>
    );

    const renderAuthor = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode
    ): JSX.Element => (
        <AuthoringWrapper
            title="Login Flyout - Logged Out - Multiple Customer Types"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <AlertBox severity={AlertBoxSeverity.INFO}>
                <div>
                    {LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT_MULTIPLE_CUSTOMER_TYPES}
                </div>
            </AlertBox>
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    padding={{
                        left: CONTAINER_PADDING_2_6,
                        right: CONTAINER_PADDING_2_6
                    }}
                    wrap={ContainerWrap.always}
                    stretchContent
                >
                    {heading}
                    {copy}
                    <div>{props.privateRadioButtonLabel}</div>
                    <div>{props.commercialRadioButtonLabel}</div>
                    {button}
                </Container>
            </Layout>
        </AuthoringWrapper>
    );

    const renderPublish = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode
    ): JSX.Element => (
        <LoginFlyoutViewV2 heading={heading} copy={copy} button={button} />
    );

    return C.isInEditor()
        ? renderAuthor(headingElement, copyItem, buttonElement)
        : renderPublish(headingElement, copyWithRadioButtons, buttonElement);
}

export const FlyoutLoggedOutMultipleCustomerTypes = MapTo<ExtraLoginProps>(
    'vwa-ngw18/components/structure/login/flyoutLoggedOutMultipleCustomerTypes',
    InternalFlyoutLoggedOutMultipleCustomerTypes
);
