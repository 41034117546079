import {useGlobalConfig, useSpaAsyncConfig} from '../context';

export function useEnableSmartImaging(): boolean {
    const {
        author,
        atOffer
    } = useSpaAsyncConfig().featureHubModel.featureServices.imageService;
    const enableSmartImaging = useGlobalConfig().featureToggles
        .enableSmartImaging;

    // only use it on publisher or for generation of offer XF. Image-service uses liveDomain in those cases.
    return (!author || atOffer) && enableSmartImaging;
}
