import {isInBrowser} from './isInBrowser';

// inspired by https://www.npmjs.com/package/supports-webp
export function supportsWebP(testing: boolean = false): boolean {
    if (!isInBrowser() && !testing) {
        return true;
    }

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = canvas.height = 1;

    return canvas.toDataURL && canvas.toDataURL('image/webp')
        ? canvas.toDataURL('image/webp').indexOf('image/webp') === 5
        : false;
}
