import {singleton, inject} from '../../infrastructure/di/annotations';
import {LoginStore} from './LoginStore';
import {
    IdkServiceConnector,
    MergeConsentStatus,
    UpdateMergeConsent
} from './IdkServiceConnector';
import {ConsentConfiguration, LocaleModel} from '../../../generated/core';

@singleton('IdkServiceConnector', {env: 'client'})
export class IdkServiceConnectorImpl implements IdkServiceConnector {
    @inject()
    private loginStore!: LoginStore;
    @inject()
    private consentConfiguration!: ConsentConfiguration;
    @inject()
    private localeModel!: LocaleModel;

    public async getMergeConsentStatus(): Promise<MergeConsentStatus> {
        const {documentKey, resourceHostId} = this.consentConfiguration;
        const {countryCode, language} = this.localeModel;

        const path = `api/v1/consents/${documentKey}/__userId__?locale=${language}-${countryCode}`;
        const data = await this.loginStore.fetch(path, {
            headers: {'Content-Type': 'application/json'},
            resourceHostId
        });

        if (!data.ok) {
            const text = await data.text();
            throw new Error('error in merge consent: ' + text);
        }
        return data.json();
    }

    public async setMergeConsentStatus(
        update: UpdateMergeConsent
    ): Promise<void> {
        const userId = this.loginStore.userData!.sub;
        const {resourceHostId} = this.consentConfiguration;
        const path = `api/v1/consents`;
        const body = JSON.stringify({...update, userId});
        const response = await this.loginStore.fetch(path, {
            headers: {'Content-Type': 'application/json'},
            resourceHostId,
            method: 'POST',
            body
        });
        if (!response.ok) {
            const text = await response.text();
            throw new Error('cannot update merge consent: ' + text);
        }
    }
}
