import {MediaAspectRatio} from '../../../../../generated/api';

const ASPECT_RATIOS_FIRST_BIG: MediaAspectRatio[] = [
    'r_1_1',
    'r_3_4',
    'r_4_3',
    'r_1_1',
    'r_4_3',
    'r_3_4'
];

const ASPECT_RATIOS_FIRST_SMALL: MediaAspectRatio[] = [
    'r_1_1',
    'r_4_3',
    'r_3_4',
    'r_1_1',
    'r_3_4',
    'r_4_3'
];

export function getAspectRatioByIndex(
    idx: number,
    bigImageInFirstRow: boolean
): MediaAspectRatio {
    return bigImageInFirstRow
        ? ASPECT_RATIOS_FIRST_BIG[idx]
        : ASPECT_RATIOS_FIRST_SMALL[idx];
}
