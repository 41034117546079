import * as React from 'react';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    LayoutConfiguration,
    LayoutConfigurations,
    LayoutRowGap
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';

import {
    HeadingSectionLayout,
    HeadingSectionModel
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {HeadingSectionDisclaimerContainer} from '../../../components/disclaimers/HeadingSectionDisclaimerContainer';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {ContainerPaddingPropType} from '@volkswagen-onehub/components-core/dist/container/helpers';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const CONTAINER_PADDING_2_0 = getContainerPadding([2, 0]);
const CONTAINER_PADDING_2_6_7 = getContainerPadding([2, 6, 7]);
const CONTAINER_PADDING_2_5_6 = getContainerPadding([2, 5, 6]);
const CONTAINER_PADDING_2_8 = getContainerPadding([2, 8]);

export function InternalHeadingSection(
    props: HeadingSectionModel
): JSX.Element {
    const {
        anchorId,
        mboxId,
        contentLabels,
        cqItems,
        contentId: sectionId,
        contentName,
        layout,
        tagTypeBroken
    } = props;
    const showHeadline = C.isInEditor() || !cqItems.headline.empty;
    const showCopy = C.isInEditor() || !cqItems.copy.empty;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S101 Heading Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <>
                        <Layout
                            appearance={getLayoutConfiguration(layout)}
                            rowGap={LayoutRowGap.dynamic0100}
                            allowOverflowingContent
                        >
                            {showHeadline && (
                                <Container
                                    padding={getFirstContainerPadding(layout)}
                                    wrap={ContainerWrap.always}
                                >
                                    <HeadingElement
                                        path="headline"
                                        order="H2"
                                    />
                                </Container>
                            )}
                            <Container
                                wrap={ContainerWrap.always}
                                gutter={ContainerGutter.static300}
                            >
                                {showCopy && (
                                    <Container
                                        wrap={ContainerWrap.always}
                                        padding={getSecondContainerPadding(
                                            layout
                                        )}
                                    >
                                        <CopyItem
                                            path="copy"
                                            useDropCap={false}
                                        />
                                    </Container>
                                )}
                            </Container>
                        </Layout>
                        {layout === 'HEADING_TOP' ? (
                            <HeadingSectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        ) : (
                            <SectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        )}
                    </>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

function getLayoutConfiguration(
    layout: HeadingSectionLayout
): LayoutConfiguration {
    switch (layout) {
        case 'HEADING_LEFT':
            return {
                [Breakpoints.default]: [
                    {name: 'a', columns: 24},
                    {name: 'b', columns: 24}
                ],
                [Breakpoints.b560]: [
                    {name: 'a', columns: 8},
                    {name: 'b', columns: 16}
                ],
                [Breakpoints.b1600]: [
                    {name: 'a', columns: 10},
                    {name: 'b', columns: 14}
                ]
            };
        case 'HEADING_RIGHT':
            return {
                [Breakpoints.default]: [
                    {name: 'a', columns: 24},
                    {name: 'b', columns: 24}
                ],
                [Breakpoints.b560]: [
                    {name: 'b', columns: 16},
                    {name: 'a', columns: 8}
                ],
                [Breakpoints.b1600]: [
                    {name: 'b', columns: 14},
                    {name: 'a', columns: 10}
                ]
            };
        case 'HEADING_TOP':
        default:
            return LayoutConfigurations.full;
    }
}

export function getFirstContainerPadding(
    layout: HeadingSectionLayout
): ContainerPaddingPropType {
    let paddingStart, paddingEnd;
    switch (layout) {
        case 'HEADING_LEFT':
            paddingStart = ContainerPadding.grid002;
            paddingEnd = CONTAINER_PADDING_2_0;
            break;
        case 'HEADING_RIGHT':
            paddingStart = CONTAINER_PADDING_2_0;
            paddingEnd = ContainerPadding.grid002;
            break;
        case 'HEADING_TOP':
        default:
            paddingStart = CONTAINER_PADDING_2_5_6;
            paddingEnd = CONTAINER_PADDING_2_8;
    }

    return {inlineStart: paddingStart, inlineEnd: paddingEnd};
}

export function getSecondContainerPadding(
    layout: HeadingSectionLayout
): ContainerPaddingPropType {
    let paddingStart, paddingEnd;
    switch (layout) {
        case 'HEADING_LEFT':
        case 'HEADING_RIGHT':
            paddingStart = ContainerPadding.grid002;
            paddingEnd = ContainerPadding.grid002;
            break;
        case 'HEADING_TOP':
        default:
            paddingStart = CONTAINER_PADDING_2_6_7;
            paddingEnd = CONTAINER_PADDING_2_6_7;
    }

    return {
        inlineStart: paddingStart,
        inlineEnd: paddingEnd
    };
}

export const HeadingSection = MapTo(
    'vwa-ngw18/components/editorial/sections/headingSection',
    InternalHeadingSection
);
