import * as React from 'react';

import {RegisteredDisclaimer} from '../../context/disclaimer/DisclaimerStore';
import {useActiveLayerScrollableElement} from '../../hooks/useActiveLayerScrollableElement';
import {CmsReferenceBadge} from './CmsReferenceBadge';
import {observer} from 'mobx-react-lite';
import {GeneralDisclaimerContext} from '../../context/disclaimer/GeneralDisclaimerProvider';
import {DisclaimerContextApi} from '../../context/disclaimer/DisclaimerContextApi';

export interface InteractiveDisclaimerBadgeProps {
    readonly disclaimer: RegisteredDisclaimer;
    readonly badgeToggleLabel: string;
    readonly badgeVariant?: 'default' | 'large';
}

export type ReducedFullTextCallback = (
    scollPosition: number,
    disableScroll: boolean
) => void;
/**
 * observing the disclaimer to update once callbacks are added
 */
export const InteractiveDisclaimerBadge = observer(function IDB(
    props: InteractiveDisclaimerBadgeProps
): React.ReactElement {
    const {disclaimer, badgeToggleLabel, badgeVariant} = props;

    const interactiveRef = React.useRef<HTMLButtonElement>(null);

    let fullTextDisclaimerRef: React.RefObject<HTMLDivElement>;
    let fullTextCallback: ReducedFullTextCallback;

    const {activeLayerScrollableElement} = useActiveLayerScrollableElement();
    const generalDisclaimerContext = React.useContext(GeneralDisclaimerContext);

    if (generalDisclaimerContext && disclaimer.reference) {
        const reference: string = disclaimer.reference;
        const disclaimerContexts: DisclaimerContextApi[] = [
            generalDisclaimerContext // !!this also contains disclaimer from prev. pages
        ];

        // find first disclaimer for reference
        // !! pageDisclaimers could contain disclaimer with same id from prev. page, so
        // !! it should have lowest priority when searching for matching full text disclaimer
        // generalDisclaimerContext supports lazy binding. We need to check if there is a page disclaimer and then otherwise get the lazy one.
        const fullTextDisclaimer =
            disclaimerContexts
                .map(dc => dc.getFullTextDisclaimer(reference))
                .find(ftd => !!ftd && !!ftd?.ref.current) ||
            generalDisclaimerContext?.getFullTextDisclaimer(reference);

        if (fullTextDisclaimer) {
            fullTextDisclaimerRef = fullTextDisclaimer.ref;
            fullTextCallback = (
                scrollPosition: number,
                disableScroll: boolean
            ) =>
                fullTextDisclaimer.callback(
                    interactiveRef,
                    scrollPosition,
                    disableScroll
                );
        }
    }

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        if (disclaimer.referenceClickHandler) {
            // click handler is defined in Disclaimer Store for interactive disclaimers only
            // - will call the handleInteractiveDisclaimerClick
            return disclaimer.referenceClickHandler(
                interactiveRef, // React Disclaimer Badge Element
                fullTextDisclaimerRef, // React Disclaimer Text Element
                fullTextCallback, // Callback after scrolling to disclaimer text
                activeLayerScrollableElement
            );
        }
    };

    return (
        <span ref={interactiveRef}>
            <CmsReferenceBadge
                reference={disclaimer.reference}
                onClick={onClick}
                ariaLabel={badgeToggleLabel + ' ' + disclaimer.reference}
                variant={badgeVariant}
            >
                {disclaimer.reference}
            </CmsReferenceBadge>
        </span>
    );
});
