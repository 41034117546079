import {inject, singleton} from '../../infrastructure/di/annotations';
import {getGlobal} from '../../utils/getGlobal';
import {AuthService} from './AuthServiceProviderMock';
import {
    IAuthServiceProviderV2,
    IAuthServiceV2
} from '@volkswagen-onehub/authservice';
import {OrganizationUserData} from './OrganizationUserData';
import {OrganizationInfoData} from './OrganizationInfoData';

const DEFAULT_ORG_DATA: OrganizationUserData & OrganizationInfoData = {
    firstName: 'Max',
    lastName: 'Mustermann',
    companyName: 'my company',
    email: 'mock@email.com',
    verificationLevel: 1,
    legalConfirmationUserId: 'mock'
};

const LOGGED_IN_FLAG = 'logged in mock as commercial';

@singleton('AuthServiceProviderCommercial', {devMode: true})
export class AuthServiceProviderCommercialMock
    implements IAuthServiceProviderV2 {
    @inject() private authServiceCommercial!: AuthServiceCommercial;

    register(): IAuthServiceV2 {
        return this.authServiceCommercial;
    }
}

@singleton('AuthServiceCommercial', {devMode: true})
export class AuthServiceCommercial extends AuthService {
    public isAuthenticated(): Promise<boolean> {
        return Promise.resolve(!!window.sessionStorage.getItem(LOGGED_IN_FLAG));
    }

    public login(_prompt?: string, _redirectUrl?: string): Promise<void> {
        window.sessionStorage.setItem(LOGGED_IN_FLAG, 'true');
        setTimeout(() => window.location.reload(), 100);
        this.saveStateListeners.forEach(l => l());
        return Promise.resolve();
    }

    public logout(_redirectUrl: string): Promise<void> {
        window.sessionStorage.removeItem(LOGGED_IN_FLAG);
        setTimeout(() => window.location.reload(), 100);
        this.logoutListeners.forEach(l => l());
        return Promise.resolve();
    }

    public async fetch(resource: string): Promise<Response> {
        if (resource.endsWith('organisations') || resource.endsWith('mock')) {
            const org =
                getGlobal().config.getJson('organizationData') ||
                DEFAULT_ORG_DATA;
            return Promise.resolve({json: () => org} as any);
        }

        return Promise.resolve({} as any);
    }
}
