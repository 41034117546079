import * as React from 'react';

import styled from 'styled-components';

export interface AnchorTargetProps {
    readonly anchorId?: string;
    readonly className?: string;
    readonly innerRef?: React.RefObject<HTMLDivElement>;
    readonly isSection?: boolean;
    readonly customDataAttribute?: {key: string; value: string};
}

export const topBarScrolledHeight = 68; // px

const StyledAnchorTarget = styled.div`
    // NOTE: Anchor scroll jump vs topbar overlap
    // - "target" is triggered with ID anchor on this element on click
    // - in that case, the invisible element on "before" is moved out of the area in height of topbar
    // - browser scrolls to this offset element
    // - there are no margin issues if used this way
    // - this technique works reliably only on "display: block" elements
    // - any use on inline, inline-block, even flex or tables works differently or not at all in most browsers

    outline: none;

    &:target::before {
        display: block;
        content: '';
        margin-top: -${topBarScrolledHeight}px;
        height: ${topBarScrolledHeight}px;
        visibility: hidden;
    }
`;
StyledAnchorTarget.displayName = 'StyledAnchorTarget';

export class AnchorTarget extends React.Component<AnchorTargetProps> {
    public render(): JSX.Element | (string | JSX.Element)[] | null {
        const {
            anchorId,
            innerRef,
            className,
            children,
            isSection,
            customDataAttribute
        } = this.props;

        return (
            <StyledAnchorTarget
                as={isSection ? 'section' : 'div'}
                id={anchorId}
                ref={innerRef}
                className={className}
                tabIndex={-1}
                {...(customDataAttribute && {
                    [customDataAttribute.key]: customDataAttribute.value
                })}
            >
                {children}
            </StyledAnchorTarget>
        );
    }
}
