import * as React from 'react';
import {ScrollytellingDataState} from './IntroOutroAnimation';
import {ScrollAmounts} from './ScrollLogic';

export const animationProgress = (
    dataState: ScrollytellingDataState,
    scrollAmounts: ScrollAmounts
) => {
    const {fadeIn, fadeOut} = scrollAmounts;
    if (dataState === ScrollytellingDataState.SCROLLING) {
        return 1;
    }

    if (dataState === ScrollytellingDataState.ANIMATING_IN) {
        return Math.min(1, Math.max(0, fadeIn));
    }

    if (dataState === ScrollytellingDataState.ANIMATING_OUT) {
        return Math.min(1, Math.max(0, 1 - fadeOut));
    }
    return 0;
};

export const useAnimations = (
    target: React.RefObject<HTMLDivElement | null>
) => {
    const animationRef = React.useRef<Animation[]>([]);

    const progressAnimations = (progress: number) => {
        animationRef.current.forEach(animation => {
            animation.currentTime = progress;
        });
    };

    const finishAnimations = () => {
        animationRef.current.forEach(animation => animation.finish());
    };

    React.useEffect(() => {
        const stickyElement = target.current;
        const mediaElement = stickyElement?.firstElementChild;
        if (!stickyElement || !mediaElement) {
            return;
        }

        const videoContentAnimation = new Animation(
            new KeyframeEffect(
                mediaElement,
                {opacity: [0, 1]},
                {duration: 1, fill: 'forwards'}
            )
        );

        const overlayAnimation = new Animation(
            new KeyframeEffect(
                stickyElement,
                {transform: ['scaleY(1)', 'scaleY(0)']},
                {duration: 1, pseudoElement: '::before', fill: 'forwards'}
            )
        );

        animationRef.current = [videoContentAnimation, overlayAnimation];

        return () => finishAnimations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {progressAnimations, finishAnimations};
};
