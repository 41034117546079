import {styled, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {ScrollytellingItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {CopyItem} from './CopyItem';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const StyledContentWrapper = styled.div`
    padding-inline: ${props => props.theme.size.grid002};
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0050};
`;

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers order="H3" />
);

const copy = (
    <CopyItem
        path="copy"
        appearance={TokenTextAppearance.copy200}
        hideItemDisclaimers
    />
);

function AuthorView(props: ScrollytellingItemModel): JSX.Element {
    const {anchorId} = props;

    return (
        <AuthoringWrapper anchorId={anchorId} title="I138 Scrollytelling Item">
            <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
                <StyledContentWrapper>
                    {headingElement}
                    {copy}
                </StyledContentWrapper>
                <BelowSectionDisclaimerContainer
                    color={'light'}
                    displayTypes={['T4_ITEM_BASED']}
                />
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
}

function PublishView(): JSX.Element {
    return <div>Read me...</div>;
}

function InternalScrollytellingItem(
    props: ScrollytellingItemModel
): JSX.Element {
    return C.isInEditor() ? <AuthorView {...props} /> : <PublishView />;
}

export const ScrollytellingItem = MapTo(
    'vwa-ngw18/components/editorial/items/scrollytellingItem',
    InternalScrollytellingItem
);
