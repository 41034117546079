import * as React from 'react';

import {HomepageStageSliderSectionModel} from '../../../../generated/core';
import {AnchorTarget} from '../../../components/AnchorTarget';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {TeaserSectionAuthoringView} from './teasers/common';
import {observer} from 'mobx-react-lite';
import {XfHomepageSlider} from '../../structure/main/XfHomepageSlider';
import {Layout} from '@volkswagen-onehub/components-core';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const EMPTY_LABEL = 'S131 Homepage Stage Slider Section';

type HomepageStageSliderSectionProps = HomepageStageSliderSectionModel;

const InternalHomepageStageSliderSection = observer(function HPSS(
    props: HomepageStageSliderSectionProps
): JSX.Element | null {
    const {
        anchorId,
        validationError,
        validationMessages,
        tagTypeBroken,
        numberOfValidElements,
        personalizable,
        personalizableChildren
    } = props;

    // error and publish view, show nothing
    if (validationError && !C.isInEditor()) {
        return null;
    }

    const slides: JSX.Element[] = [];

    for (let i = 0; i < numberOfValidElements; i++) {
        const itemId = `item_${i}`;

        slides.push(
            <XfHomepageSlider
                path={itemId}
                key={itemId}
                personalizable={!personalizable && personalizableChildren}
            />
        );
    }

    return C.isInEditor() ? (
        <GeneralDisclaimerProvider
            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
        >
            <TeaserSectionAuthoringView
                anchorId={anchorId}
                emptyLabel={EMPTY_LABEL}
                validationMessages={validationMessages}
                validationError={validationError}
                tagTypeBroken={tagTypeBroken}
            >
                <Layout>
                    <AnchorTarget anchorId={anchorId}>
                        {!validationError && slides}
                    </AnchorTarget>
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    />
                </Layout>
            </TeaserSectionAuthoringView>
        </GeneralDisclaimerProvider>
    ) : (
        <></>
    );
});

export const HomepageStageSliderSection = MapTo(
    'vwa-ngw18/components/editorial/sections/homepageStageSliderSection',
    InternalHomepageStageSliderSection
);
