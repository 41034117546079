import {
    ModelClient as AEMModelClient,
    TransferModel
} from '@adobe/cq-spa-page-model-manager';
import {isInBrowser} from '../../utils/browser/isInBrowser';

// We have to override ModelClient provided by AEM because original implementation
// do not include credentials to fetch request in EDGE browser, so we have to set
// credentials: 'include' directly
export class ModelClient extends AEMModelClient {
    public async fetch(modelPath: string): Promise<TransferModel> {
        if (!modelPath) {
            const err = 'Fetching model rejected for path: ' + modelPath;

            return Promise.reject(new Error(err));
        }

        // Either the API host has been provided or we make an absolute request relative to the current host
        const url = `${this._apiHost}${modelPath}`;

        if (!isInBrowser()) {
            console.error(`cannot fetch on server ${modelPath}`);
        }

        return fetch(url, {
            credentials: 'include'
        })
            .then(
                async (response: Response): Promise<TransferModel> => {
                    if (
                        (response.status >= 200 && response.status < 300) ||
                        (response.status >= 400 && response.status < 500)
                    ) {
                        return response.json();
                    }

                    throw new Error(response.toString());
                }
            )
            .catch(async error => {
                return Promise.reject(error);
            });
    }
}
