import * as React from 'react';
import styled from 'styled-components';

import {resetListStyles} from '../helpers';
import {LinksSeparator} from '../helpers/links-separator';

const StyledLanguageSwitcherList = styled.ul`
    ${resetListStyles}
`;

export function LanguageSwitcher(
    props: React.PropsWithChildren<{}>
): React.ReactElement {
    return (
        <StyledLanguageSwitcherList>
            <LinksSeparator>{props.children}</LinksSeparator>
        </StyledLanguageSwitcherList>
    );
}

const StyledLanguageSwitcherItem = styled.span<LanguageSwitcherItemProps>`
    display: inline-block;

    ${props =>
        Boolean(props.isActive) &&
        `font-weight: bold; border-bottom: solid 1px ${props.theme.text.color.primary};`};
`;

interface LanguageSwitcherItemProps {
    readonly isActive?: boolean;
    readonly lang?: string;
}

export function LanguageSwitcherItem(
    props: React.PropsWithChildren<LanguageSwitcherItemProps>
): React.ReactElement {
    const {children, isActive, lang} = props;

    return (
        <StyledLanguageSwitcherItem isActive={isActive} lang={lang}>
            {children}
        </StyledLanguageSwitcherItem>
    );
}
