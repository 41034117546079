import * as React from 'react';

interface SetAttributeOnChildrenProps {
    attributeValue: string;
    attributeKey?: string;
}

export const DEFAULT_ATTRIBUTE_KEY = 'layout';

export const SetAttributeOnChildren = ({
    children,
    attributeKey = DEFAULT_ATTRIBUTE_KEY,
    attributeValue
}: React.PropsWithChildren<SetAttributeOnChildrenProps>): JSX.Element => (
    <>
        {React.Children.map(children, child => {
            if (!React.isValidElement(child)) {
                return child;
            }

            return React.cloneElement(child, {
                [`data-${attributeKey}`]: attributeValue
            });
        })}
    </>
);
