import {
    NextBestActionDataModel,
    NextBestActionModel,
    NextBestPageActionModel,
    NextBestSectionActionListModel,
    NextBestSectionActionTrackingData
} from '../../../../generated/core';
import {ConfigurableConsole} from '../../../context/logger/ConfigurableConsole';
import {MergeNextBestActionModel} from './mergePersonalizedActions';

export function getActions(
    nbab?: NextBestActionDataModel,
    sectionId?: string,
    sectionGroup?: boolean,
    activeSectionId?: string,
    activeSectionGroupId?: string,
    logger?: ConfigurableConsole,
    contentLabels?: string
):
    | {
          isShown: boolean;
          enabledActions: MergeNextBestActionModel[];
          trackingData?: SectionNbabTrackingData;
          pageActions: boolean;
          noIconForHorizontalView: boolean;
          disabled: boolean;
      }
    | undefined {
    if (!nbab) {
        return undefined;
    }
    if (!sectionId) {
        return getPageActions(
            nbab,
            activeSectionId,
            activeSectionGroupId,
            logger
        );
    }

    if (sectionGroup) {
        return getSectionGroupActions(
            nbab,
            sectionId,
            contentLabels,
            activeSectionId,
            activeSectionGroupId,
            logger
        );
    }
    return getSectionActions(
        nbab,
        sectionId,
        contentLabels,
        activeSectionId,
        logger
    );
}

function getSectionGroupActions(
    nbab: NextBestActionDataModel,
    sectionId: string,
    contentLabels?: string,
    activeSectionId?: string,
    activeSectionGroupId?: string,
    logger?: ConfigurableConsole
) {
    const {sectionModels} = nbab;

    const nbabData = sectionModels[sectionId];

    if (!nbabData) {
        return undefined;
    }

    const isSectionShown = activeSectionId === sectionId;
    const isSectionGroupShown =
        !hasNBAB(sectionModels, activeSectionId) &&
        sectionId === activeSectionGroupId;
    const isShown = isSectionShown || isSectionGroupShown;

    const trackingData = getNBABTrackingData(
        nbabData,
        sectionId,
        contentLabels
    );
    const enabledActions = setPriorities(nbabData.actions, true);

    enabledActions &&
        logger &&
        logger.debug(
            'group action - shown: ',
            isShown,
            ', sectionId:',
            sectionId,
            ', actions:',
            enabledActions.map(a => a.label).join(',')
        );

    return {
        isShown,
        enabledActions,
        trackingData,
        pageActions: false,
        noIconForHorizontalView: false,
        disabled: nbabData.nbabDisabled
    };
}

function getSectionActions(
    nbab: NextBestActionDataModel,
    sectionId: string,
    contentLabels?: string,
    activeSectionId?: string,
    logger?: ConfigurableConsole
) {
    const {sectionModels} = nbab;

    const nbabData = sectionModels[sectionId];

    if (!nbabData) {
        return undefined;
    }

    const isShown = activeSectionId === sectionId;

    const trackingData = getNBABTrackingData(
        nbabData,
        sectionId,
        contentLabels
    );
    const enabledActions = setPriorities(nbabData.actions, true);

    const noIconForHorizontalView = nbabData.noIconForHorizontalView;

    enabledActions &&
        logger &&
        logger.debug(
            'section action - shown: ',
            isShown,
            ', sectionId:',
            sectionId,
            ', actions:',
            enabledActions.map(a => a.label).join(',')
        );
    return {
        isShown,
        enabledActions,
        trackingData,
        pageActions: false,
        noIconForHorizontalView,
        disabled: nbabData.nbabDisabled
    };
}

function setPriorities(
    actions: NextBestActionModel[],
    section: boolean
): MergeNextBestActionModel[] {
    const maxPriority = section ? 5 : 3;
    return actions.map((a, index) => {
        const priority = Math.max(maxPriority - index, 1);
        return {...a, priority};
    });
}

function getPageActions(
    nbab: NextBestActionDataModel,
    activeSectionId?: string,
    activeSectionGroupId?: string,
    logger?: ConfigurableConsole
) {
    const {pageModel, sectionModels} = nbab;

    const nbabData = pageModel;

    const isShownSectionNBAB =
        activeSectionId && activeSectionId in sectionModels;
    const isShownSectionGroupNBAB =
        activeSectionGroupId && activeSectionGroupId in sectionModels;
    const isShown = !isShownSectionNBAB && !isShownSectionGroupNBAB;

    const enabledActions = setPriorities(nbabData.actions, false);

    const noIconForHorizontalView = nbabData.noIconForHorizontalView;

    enabledActions &&
        logger &&
        logger.debug(
            'page action - shown: ',
            isShown,
            ', actions:',
            enabledActions.map(a => a.label).join(',')
        );

    return {
        isShown,
        enabledActions,
        pageActions: true,
        noIconForHorizontalView,
        disabled: pageModel.nbabDisabled
    };
}
const hasNBAB = (
    sectionModels: {[id: string]: NextBestSectionActionListModel},
    localSectionId?: string
): boolean => !!localSectionId && localSectionId in sectionModels;

export interface SectionNbabTrackingData {
    sectionId?: string;
    contentLabels?: string;
    additionalTrackingData?: NextBestSectionActionTrackingData;
}

export const getNBABTrackingData = (
    NBABModel: NextBestSectionActionListModel | NextBestPageActionModel,
    sectionId?: string,
    contentLabels?: string
): SectionNbabTrackingData => {
    if (NBABModel && 'trackingData' in NBABModel && NBABModel.trackingData) {
        // these only exist for NextBestSectionActionListModel
        return {
            sectionId,
            contentLabels,
            additionalTrackingData: NBABModel.trackingData
        };
    }

    return {
        sectionId,
        contentLabels
    };
};
