import * as React from 'react';

import styled from 'styled-components';
import {RICHTEXT_AUTHOR_MIN_WIDTH} from '../../../../../components/Richtext';
import {MapTo} from '../../../../../infrastructure/compatibility/MapTo';
import {ThemeDefinition} from '@volkswagen-onehub/components-core';

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/emptyCellElement';

export interface BaseCellProps {
    isNarrow?: boolean;
}

export function getCellWidth(
    theme: ThemeDefinition,
    isNarrow?: boolean
): string {
    if (isNarrow) {
        return theme.size.grid003;
    } else {
        return theme.size.grid004;
    }
}

export const StyledBaseCell = styled.div<BaseCellProps>`
    min-width: ${RICHTEXT_AUTHOR_MIN_WIDTH};
    width: ${props => getCellWidth(props.theme, props.isNarrow)};
    padding: ${props => props.theme.size.static100};
`;

class EmptyCellElementComponentClass extends React.PureComponent<
    BaseCellProps
> {
    public render(): React.ReactNode {
        return (
            <StyledBaseCell isNarrow={this.props.isNarrow}>
                This cell is empty
            </StyledBaseCell>
        );
    }
}

export const EmptyCellElement = MapTo(
    RESOURCE_TYPE,
    EmptyCellElementComponentClass
);
