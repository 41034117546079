import * as React from 'react';

import {ImageLink} from '../../d6/components/image-link';
import {TeaserLinkProps} from '../../d6/components/teaser-element';
import {useFirefoxScrollHack} from '../../utils/browser/useFirefoxScrollHack';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {BaseCmsLinkProps, useCmsLink} from './CmsLink';

export type CmsImageLinkProps = BaseCmsLinkProps & TeaserLinkProps;

export function CmsImageLink(props: CmsImageLinkProps): JSX.Element {
    const ref = React.useRef<HTMLAnchorElement>(null);
    const [onCmsLinkClick, href] = useCmsLink(ref, props);

    useFirefoxScrollHack(ref);

    return (
        <CyAttributeAppender name="imageLink">
            <ImageLink
                {...props}
                href={href}
                elementRef={ref}
                onClick={onCmsLinkClick}
            />
        </CyAttributeAppender>
    );
}
