import * as React from 'react';
import ReactDOM from 'react-dom';
import {ClickTargetElement} from './CmsLink';

export function createLinkName(
    linkRef: React.MutableRefObject<ClickTargetElement | null>
): string | undefined {
    const link = ReactDOM.findDOMNode(linkRef.current) as HTMLElement | null;

    if (!link) {
        return undefined;
    }
    // innerHTML is used only in tests because innerText is not available
    return typeof link.innerText === 'undefined'
        ? link?.innerHTML
        : link?.innerText.trim();
}
