import * as React from 'react';
import {Close} from '@volkswagen-onehub/icons-core';
import {ThemeProvider, styled} from '@volkswagen-onehub/components-core';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {
    createCSSFourValuesPaddingByDirection,
    getEndDirection,
    resetButtonStyles
} from '../../d6/components/helpers';
import {DisplayType} from '../../../generated/core';

export const StyledDisclaimerReference = styled.div`
    color: ${props => props.theme.text.color.secondary};
    &:not(:last-child) {
        padding-bottom: ${props => props.theme.size.static300};
    }
`;
StyledDisclaimerReference.displayName = 'StyledDisclaimerReference';

export interface DisclaimersContainerState {
    isShown: boolean;
}

export interface BaseDisclaimerContainerProps {
    color?: string;
    sticky?: boolean;
    children?: React.ReactNode;
}

export interface DisclaimerContainerProps extends BaseDisclaimerContainerProps {
    displayTypes: DisplayType[];
}

export interface DisclaimerProps {
    displayTypes: DisplayType[];
}

const StyledDisclaimersWrapper = styled.div`
    overflow-y: auto;
    padding: ${props => props.theme.size.static250}
        ${props => props.theme.size.static250} 0
        ${props => props.theme.size.static250};
    pointer-events: all;

    &:focus {
        outline: 2px solid ${props => props.theme.colors.border.active};
    }
`;
StyledDisclaimersWrapper.displayName = 'StyledDisclaimersWrapper';

const StyledLayerButton = styled.button`
    ${resetButtonStyles}

    position: absolute;
    ${props => getEndDirection(props.theme.direction)}: ${props =>
        props.theme.size.static250};
    top: ${props => props.theme.size.static250};
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.button.secondary.default};
    pointer-events: all;

    &:focus {
        color: ${props => props.theme.colors.button.secondary.active};
        outline: 2px solid
            ${props => props.theme.colors.button.secondary.active};
        outline-offset: 2px;
    }
`;
StyledLayerButton.displayName = 'StyledLayerButton';

interface CloseableDisclaimersContainerProps {
    readonly children: JSX.Element | JSX.Element[];
    readonly closeDisclaimerLabel: string;
    onClose?: (arg0: any) => void;
}

export const CloseableDisclaimersContainer = (
    props: CloseableDisclaimersContainerProps
) => {
    const {children, closeDisclaimerLabel, onClose} = props;

    const handleDisclaimersWrapperClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    };

    return (
        <ThemeProvider theme={'inverted'}>
            <StyledDisclaimersWrapper
                onClick={handleDisclaimersWrapperClick}
                aria-hidden="false"
            >
                {children}
            </StyledDisclaimersWrapper>
            <CyAttributeAppender name="disclaimerOverlayCloseButton">
                <StyledLayerButton
                    onClick={onClose}
                    aria-label={closeDisclaimerLabel}
                >
                    <Close ariaHidden />
                </StyledLayerButton>
            </CyAttributeAppender>
        </ThemeProvider>
    );
};

const StyledSingleOverlayDisclaimer = styled.div`
    display: flex;
    padding: ${props =>
        createCSSFourValuesPaddingByDirection(
            0,
            props.theme.size.static400,
            props.theme.size.static250,
            0,
            props.theme.direction
        )};
`;
StyledSingleOverlayDisclaimer.displayName = 'StyledSingleOverlayDisclaimer';

interface SingleOverlayDisclaimerContainerProps {
    readonly children: JSX.Element;
    readonly color?: string;
}

export const SingleOverlayDisclaimerContainer = (
    props: SingleOverlayDisclaimerContainerProps
) => {
    const {children, color = 'light'} = props;

    return (
        <StyledSingleOverlayDisclaimer>
            <ThemeProvider theme={color === 'dark' ? {} : 'inverted'}>
                {children}
            </ThemeProvider>
        </StyledSingleOverlayDisclaimer>
    );
};
