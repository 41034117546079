import {
    useBBOContext,
    usePersonalizationContext,
    usePersonalizationStore,
    useSectionContext,
    useSectionGroupContext,
    useTrackingService
} from '../../context';
import * as React from 'react';
import {createContextTrackingData} from './createContextTrackingData';
import {Filter, FocusCategory} from '../../context/tracking/TrackingService';

export function useTrackSliderLoad(): (
    contentId: string,
    contentLabels: string | undefined,
    itemCount: number | undefined,
    itemPosition: number | undefined,
    FocusCategories?: FocusCategory[] | undefined,
    filterlist?: Filter[]
) => void {
    const trackingService = useTrackingService();
    const personalizationContext = usePersonalizationContext();
    const personalizationStore = usePersonalizationStore();
    const sectionContext = useSectionContext();
    const sectionGroupContext = useSectionGroupContext();
    const bboContex = useBBOContext();

    return React.useCallback(
        (
            contentId,
            contentLabels,
            itemCount,
            itemPosition,
            FocusCategories,
            filterlist
        ): void => {
            trackingService.trackSliderElementLoad(
                contentId,
                createContextTrackingData(
                    personalizationContext,
                    personalizationStore.getMetaData(),
                    bboContex,
                    sectionContext,
                    sectionGroupContext.sectionGroupContentId,
                    contentLabels,
                    itemCount,
                    itemPosition
                ),
                {FocusCategories},
                filterlist
            );
        },
        [
            trackingService,
            personalizationStore,
            personalizationContext,
            sectionContext,
            sectionGroupContext.sectionGroupContentId,
            bboContex
        ]
    );
}
