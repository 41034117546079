import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    ThemeProvider,
    getGridColumn
} from '@volkswagen-onehub/components-core';

import {
    GradientBackground,
    StyledBackgroundProvider
} from '../gradient-background';

// Max fixed height is used to set components height inside AEM authoring environment
// because AEM is not able to work with vh units
const maxFixedHeight = '800px';

export interface HeightProps {
    readonly hasMedia?: boolean;
    readonly fixedHeight?: boolean;
    readonly hasReadingTimebox?: boolean;
}

const StyledContentContainer = styled.div<HeightProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.size.static200};
    padding-block-start: ${props => props.theme.size.static550};
    padding-inline: ${props => props.theme.size.grid002};
    min-height: ${props =>
        props.fixedHeight ? maxFixedHeight : props.hasMedia ? 'auto' : '100vh'};

    & > div:last-child {
        padding-block-start: ${props => props.theme.size.static400};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-inline: ${props => props.theme.size.grid003};
    }
`;
StyledContentContainer.displayName = 'StyledContentContainer';

const StyledAnimationWrapper = styled.div`
    position: relative;
    padding-block-end: ${props => props.theme.size.static400};
`;
StyledAnimationWrapper.displayName = 'StyledAnimationWrapper';

const StyledMediaWrapper = styled.div<HeightProps>`
    display: flex;

    @media (min-width: ${Breakpoints.b960}px) {
        ${props => (props.fixedHeight ? 'position: relative' : '')};
        height: ${props => (props.fixedHeight ? maxFixedHeight : '100%')};
        width: ${getGridColumn(11)};
    }
`;
StyledMediaWrapper.displayName = 'StyledMediaWrapper';

const StyledLayoutWrapper = styled.div<HeightProps>`
    display: grid;
    grid-template-columns: [full-start] 1fr [full-end];
    grid-template-rows: [full-start content-start] auto [content-end animation-start] max-content [animation-end media-start] auto [media-end full-end];
    min-height: ${props => (props.hasMedia ? 'auto' : '100vh')};

    & ${StyledContentContainer} {
        grid-column: full;
        grid-row: content;
    }

    & ${StyledAnimationWrapper} {
        grid-column: full;
        grid-row: animation;
    }

    & ${StyledMediaWrapper} {
        grid-column: full;
        grid-row: media;
    }

    & ${StyledBackgroundProvider} {
        grid-column: full;
        grid-row: full;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-template-columns: [full-start content-start] ${props =>
                props.theme.size
                    .grid013} [content-end media-start] auto [media-end full-end];
        grid-template-rows: [full-start] auto [animation-start] max-content [animation-end full-end];
        min-height: ${props => (props.fixedHeight ? maxFixedHeight : '100vh')};

        & ${StyledContentContainer} {
            grid-column: content;
            grid-row: full;
        }

        & ${StyledMediaWrapper} {
            grid-column: media;
            grid-row: full;
        }
    }
`;
StyledLayoutWrapper.displayName = 'StyledLayoutWrapper';

export interface AnswersStageProps {
    readonly category: React.ReactNode;
    readonly heading: React.ReactNode;
    readonly metaInfo?: React.ReactNode;
    readonly copy: React.ReactNode;
    readonly readingTimeBox?: JSX.Element;
    readonly media?: JSX.Element;
    readonly fixedHeight?: boolean;
}

export function AnswersStage(props: AnswersStageProps): JSX.Element {
    const {
        copy,
        category,
        heading,
        metaInfo,
        readingTimeBox,
        media,
        fixedHeight
    } = props;

    return (
        <ThemeProvider theme="inverted">
            <StyledLayoutWrapper
                fixedHeight={fixedHeight}
                hasMedia={Boolean(media)}
            >
                <GradientBackground />
                <StyledContentContainer
                    fixedHeight={fixedHeight}
                    hasMedia={Boolean(media)}
                    hasReadingTimebox={Boolean(readingTimeBox)}
                >
                    {category}
                    {heading}
                    {metaInfo}
                    {copy}
                </StyledContentContainer>
                {media ? (
                    <StyledMediaWrapper fixedHeight={fixedHeight}>
                        {media}
                    </StyledMediaWrapper>
                ) : null}
                {readingTimeBox ? (
                    <StyledAnimationWrapper>
                        {readingTimeBox}
                    </StyledAnimationWrapper>
                ) : null}
            </StyledLayoutWrapper>
        </ThemeProvider>
    );
}
