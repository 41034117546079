import * as React from 'react';

import {parseJson} from '../../utils/json';
import {prependBaseUrl} from '../../utils/link/prependBaseUrl';
import {isNotEmpty} from '../../utils/string';
import {FeatureAppLoaderError} from './CmsFeatureAppLoader';
import {
    FeatureAppLoadErrorAuthor,
    FeatureAppUrlValidationError
} from './errors';

export function getComponentForError(
    error?: FeatureAppLoaderError
): JSX.Element | undefined {
    switch (error) {
        case FeatureAppLoaderError.INVALID_URL:
            return <FeatureAppUrlValidationError />;
        case FeatureAppLoaderError.LOAD:
            return <FeatureAppLoadErrorAuthor />;
        default:
            return undefined;
    }
}

export function createFeatAppFullPath(baseUrl: string, url: string): string {
    const fullUrl = prependBaseUrl(baseUrl, url);

    return fullUrl.substring(0, fullUrl.lastIndexOf('/'));
}

export function getError(
    hasError: boolean,
    hasUrl: boolean
): FeatureAppLoaderError | undefined {
    let error;

    if (hasError) {
        error = FeatureAppLoaderError.LOAD;
    } else if (!hasUrl) {
        error = FeatureAppLoaderError.INVALID_URL;
    }

    return error;
}

export function createNewInstanceConfig(
    instanceConfig: string,
    baseUrl: string,
    url: string,
    legalEntity: string
): any {
    const newInstanceConfig = parseJson(instanceConfig) || {};
    if (isNotEmpty(url)) {
        newInstanceConfig.featureAppRootPath = createFeatAppFullPath(
            baseUrl,
            url
        );
    }
    // don't override the custom legal entity
    if (legalEntity && !newInstanceConfig.legalEntity) {
        newInstanceConfig.legalEntity = legalEntity;
    }

    return newInstanceConfig;
}
