import {
    FeatureAppConfiguration,
    FeatureappService,
    FeatureappServiceOptions,
    defineFeatureappService
} from '@volkswagen-onehub/featureapp-service';
import {
    FeatureAppConfigurationModel,
    SpaAsyncConfig
} from '../../../generated/core';
import {Logger} from '../../context/logger/Logger';
import {monitoringHelper} from '../../context/logger/monitoringHelper';
import {getTrackingId} from '../../utils/tracking/getTrackingId';
import {ServiceRegistration} from './ServiceRegistration';

function convertModelToProps(
    model: FeatureAppConfigurationModel,
    logger: Logger
): FeatureAppConfiguration {
    const serverSrc =
        model.ssrEnabled && model.ssrUrl ? model.ssrUrl : undefined;
    let config;
    try {
        config = model.instanceConfig
            ? JSON.parse(model.instanceConfig)
            : undefined;
    } catch (e) {
        logger.featurehub.error('cannot parse config for %s', model.name);
    }

    return {
        baseUrl: model.baseUrl,
        src: model.url,
        serverSrc,
        config,
        css: model.css,
        name: model.name,
        trackingId: getTrackingId(model),
        anchorId: model.anchorId,
        legalEntity: model.legalEntity,
        apiKey: model.featureAppApiKey,
        version: model.featureAppVersion
    };
}

export function createFeatureappService(
    serverSideAsyncConfig: SpaAsyncConfig,
    logger: Logger
): ServiceRegistration<FeatureappService> {
    const configs: FeatureappServiceOptions = {};

    const featureApps =
        serverSideAsyncConfig.featureHubModel.featureAppsForFeatureAppService;
    Object.keys(featureApps).forEach(id => {
        const featureApp = featureApps[id];
        configs[featureApp.name] = convertModelToProps(featureApps[id], logger);
    });

    const onAccess = (
        consumerId: string,
        featureAppName: string | undefined,
        featureApp: FeatureAppConfiguration
    ) => {
        if (!featureApp || !featureApp.name) {
            return;
        }
        monitoringHelper(logger).embedFeatureApp(
            consumerId,
            featureAppName,
            featureApp.name
        );
    };

    return {
        definition: defineFeatureappService(configs, {onAccess}),
        service: {
            typeName: 'FeatureAppService',
            version: '^1.4.0'
        }
    };
}
