import * as React from 'react';

import {styled} from '@volkswagen-onehub/components-core';

import {XfInteractionLayerMainModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {LayerCloseButtonV2} from '../../../components/LayerCloseButton';
import {InteractionLayer} from '../../../components/interaction-layer/InteractionLayer';
import {useLayerStore} from '../../../context';
import {
    LayerContext,
    LayerContextData
} from '../../../context/layer/LayerContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {Parsys, ParsysType} from '../Parsys';
import {getLayerMetaDataUpdate} from '../../../utils/layerHeaderUpdaterUtils';

const CONTEXT_DATA: LayerContextData = {
    layerType: 'INTERACTION'
};

interface LayerElements {
    copyItem: JSX.Element;
    headingElement: JSX.Element;
    customItemsParsys: JSX.Element;
}

const StyledLayerWrapper = styled.div<{isAuthorComp?: boolean}>`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0100};
    padding-bottom: ${props => props.theme.size.dynamic0150};
    ${props =>
        props.isAuthorComp && `padding-inline: ${props.theme.size.grid004};`}
`;

function AuthoringView(props: LayerElements): JSX.Element {
    const {headingElement, copyItem, customItemsParsys} = props;

    return (
        <AuthoringWrapper title="Interaction Layer Main">
            <StyledLayerWrapper isAuthorComp>
                {headingElement}
                {copyItem}
                {customItemsParsys}
            </StyledLayerWrapper>
        </AuthoringWrapper>
    );
}

function PublishView(
    props: XfInteractionLayerMainModel & LayerElements
): JSX.Element {
    const {
        headingElement,
        copyItem,
        customItemsParsys,
        btnCloseLabel,
        layerHeaderUpdaterModel
    } = props;

    const layerStore = useLayerStore();

    function closeLayer(): void {
        // Tracking is done in layer store.
        layerStore.closeLayer(props.cqPath);
    }

    const closeButton = (
        <LayerCloseButtonV2
            key="close"
            onClick={closeLayer}
            btnCloseLabel={btnCloseLabel}
        />
    );

    const layerHelmet = getLayerMetaDataUpdate(layerHeaderUpdaterModel);

    return (
        <InteractionLayer closeButton={closeButton}>
            <StyledLayerWrapper>
                {layerHelmet}
                {headingElement}
                {copyItem}
            </StyledLayerWrapper>
            {customItemsParsys}
        </InteractionLayer>
    );
}

function InternalXfInteractionLayerMain(
    props: XfInteractionLayerMainModel
): JSX.Element {
    const headingElement = <HeadingElement path="heading" order="H2" />;
    const copyItem = <CopyItem path="copy" />;

    const customItemsParsys = (
        <Parsys path="linkParsys" parsysType={ParsysType.LINK_PARSYS} />
    );

    const Layer = C.isInEditor() ? AuthoringView : PublishView;

    return (
        <LayerContext.Provider value={CONTEXT_DATA}>
            <Layer
                {...props}
                headingElement={headingElement}
                copyItem={copyItem}
                customItemsParsys={customItemsParsys}
            />
        </LayerContext.Provider>
    );
}

export const XfInteractionLayerMain = MapTo(
    'vwa-ngw18/components/structure/xfInteractionLayerMain',
    InternalXfInteractionLayerMain
);
