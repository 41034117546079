import * as React from 'react';
import {Image} from '@volkswagen-onehub/components-core';
import {useImageLazyLoad} from '../context';
import {supportsWebP} from '../utils/browser/supportsWebP';
import {ResponsiveMediaRendererConf} from './ResponsiveMediaRenderer';
import {ImageResourceSet} from '../utils/getImageResourceSet';
import {useOnImageLoad} from './useOnImageLoad';
export interface DecorativePictureProps {
    readonly src: string;
    readonly srcPlaceholder?: string;
    readonly srcSet?: string;
    readonly sizes?: string;
    readonly className?: string;
    readonly reduceBrightness?: boolean;
    readonly config?: (ResponsiveMediaRendererConf & ImageResourceSet)[];
    readonly onLoad?: () => void;
    readonly onError?: () => void;
}

export interface PictureProps extends DecorativePictureProps {
    readonly alt: string;
    onLoad?: () => void;
}

export const DynamicPicture = (props: PictureProps) => {
    const isLazyLoad = useImageLazyLoad();

    if (isLazyLoad) {
        return <LazyPicture {...props} />;
    }

    return <Picture {...props} />;
};

export const DynamicDecorativePicture = (props: DecorativePictureProps) => {
    const isLazyLoad = useImageLazyLoad();

    if (isLazyLoad) {
        return <DecorativeLazyPicture {...props} />;
    }

    return <DecorativePicture {...props} />;
};

export const mapPictureSource = (
    lazyLoading: boolean,
    config?: (ResponsiveMediaRendererConf & ImageResourceSet)[]
): JSX.Element[] | undefined => {
    const webPSupported = supportsWebP();

    return config?.map((configItem, index) => {
        const useWebP = webPSupported && configItem?.srcSetWebP;
        const sourceSet = useWebP ? configItem?.srcSetWebP : configItem?.srcSet;
        return (
            <source
                media={configItem?.mediaQuery}
                srcSet={!lazyLoading ? sourceSet : undefined}
                data-srcset={lazyLoading ? sourceSet : undefined}
                sizes={!lazyLoading ? configItem?.sizes : undefined}
                data-sizes={lazyLoading ? configItem?.sizes : undefined}
                key={configItem ? `${configItem.mediaQuery}-${index}` : index}
                type={useWebP ? 'image/webp' : undefined}
            />
        );
    });
};

export const DecorativePicture = React.forwardRef<
    HTMLImageElement,
    DecorativePictureProps
>((props, ref) => {
    const {srcPlaceholder, config, onLoad, ...others} = props;
    return (
        <picture>
            {mapPictureSource(false, config)}
            <Image
                alt=""
                aria-hidden
                {...others}
                ref={ref}
                src={srcPlaceholder || TRANSPARENT_SVG}
                onLoad={onLoad}
            />
        </picture>
    );
});

DecorativePicture.displayName = 'DecorativePicture';

const TRANSPARENT_SVG =
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' />";

export const Picture = (props: PictureProps) => {
    const {
        alt,
        className,
        config,
        reduceBrightness,
        srcPlaceholder,
        onError,
        onLoad
    } = props;
    const ref = React.useRef<HTMLImageElement>();
    useOnImageLoad(ref, onLoad, onError);

    return (
        <picture>
            {mapPictureSource(false, config)}
            <Image
                className={className}
                src={srcPlaceholder || TRANSPARENT_SVG}
                alt={alt}
                ref={ref as any}
                reduceBrightness={reduceBrightness}
            />
        </picture>
    );
};

export const LazyPicture = (props: PictureProps) => {
    const {
        alt,
        className,
        config,
        reduceBrightness,
        srcPlaceholder,
        onLoad,
        onError
    } = props;

    const ref = React.useRef<HTMLImageElement>();
    useOnImageLoad(ref, onLoad, onError);
    return (
        <>
            <noscript
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                }}
            >
                <Picture {...props} />
            </noscript>
            <picture>
                {mapPictureSource(true, config)}
                <Image
                    className={`lazyload ${className || ''}`}
                    src={srcPlaceholder || TRANSPARENT_SVG}
                    alt={alt}
                    ref={ref as any}
                    reduceBrightness={reduceBrightness}
                />
            </picture>
        </>
    );
};

LazyPicture.displayName = 'LazyPicture';

export const DecorativeLazyPicture = (props: DecorativePictureProps) => {
    const {...others} = props;

    return <LazyPicture alt="" aria-hidden {...others} />;
};

DecorativeLazyPicture.displayName = 'DecorativeLazyPicture';
