import * as React from 'react';
import styled, {css} from 'styled-components';

import {
    VolumeMaximum,
    VolumeMedium,
    VolumeMute
} from '@volkswagen-onehub/icons-core';

import {
    buttonFocusStateCSS,
    getElectricTransitionAdvanced,
    isIOS,
    resetButtonStyles
} from '../helpers';
import {
    INIT_VOLUME_VALUE,
    MAX_VOLUME_VALUE,
    MED_VOLUME_VALUE,
    MIN_VOLUME_VALUE
} from '../video-player-helpers/constants';
import {ArrowKeys} from '../video-player-helpers/types';
import {
    VerticalProgressBar,
    VideoPlayerProgressBarSelectedValue
} from '../video-player-progress-bar';

export interface SoundControlProps {
    readonly value: number;
    readonly title?: string;
    readonly soundProgressBarAriaLabel?: string;
    readonly disableButtons?: boolean;

    onVolumeChange?(volume: number): void;
    onDragStart?(): void;
    onDragEnd?(): void;
    onFocusReset?(): void;
    onVolumeBarKeyDown?(key: ArrowKeys): void;
}

export interface SoundControlState {
    readonly hasPermanentFocus: boolean;
    readonly isProgressBarVisible: boolean;
}

const SoundProgressBarHeight = '80px';
const SoundProgressBarWidth = '24px';
const FOCUS_DURATION = 2000;
const noop = () => undefined;

const StyledProgressBarWrapper = styled.div<{isProgressBarVisible: boolean}>`
    width: ${SoundProgressBarWidth};
    height: ${SoundProgressBarHeight};
    opacity: ${props => (props.isProgressBarVisible ? 1 : 0)};
    transition: ${getElectricTransitionAdvanced({name: 'height'})};
    position: relative;
    margin-bottom: ${props => props.theme.size.static200};

    @media (hover: hover) {
        &:hover {
                opacity: 1;
                height: ${SoundProgressBarHeight};
                transition: ${getElectricTransitionAdvanced({
                    name: 'height'
                })};

        }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 5px;
        height: calc(100% + 5px);
        width: 100%;
        background: rgba(0, 0, 0, 0.55);
    }
`;

const svgButtonHover = css`
    svg {
        path {
            fill: ${props => props.theme.colors.button.primary.default};
        }
        @media (hover: hover) {
            &:hover {
                path {
                    fill: ${props => props.theme.colors.button.primary.hover};
                }
            }
        }
    }
`;

const StyledSmallControlButton = styled.button`
    ${resetButtonStyles};
    ${props => buttonFocusStateCSS(props)};
    position: relative;

    width: ${SoundProgressBarWidth};
    height: ${SoundProgressBarWidth};
    ${svgButtonHover};
`;
StyledSmallControlButton.displayName = 'StyledSmallControlButton';

const StyledSoundControlsWrapper = styled.div`
    height: 22px;
    display: flex;
    align-items: flex-end;
`;

const StyledSoundControls = styled.div`
    align-self: flex-end;
`;

const StyledFlexReverseOrderWrapper = styled.div`
    display: flex;
    flex-direction: column-reverse;
`;

interface VideoButtonProps {
    readonly title?: string;
    readonly disabled?: boolean;

    onClick?(): void;
    onTouch?(): void;
}

interface SoundButtonProps extends VideoButtonProps {
    readonly soundButtonIcon: JSX.Element;
}

const SmallControlButton: React.FunctionComponent<VideoButtonProps> = props => (
    <StyledSmallControlButton
        aria-label={props.title}
        title={props.title}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (props.onClick) {
                props.onClick();
            }
        }}
        onTouchEnd={(e: React.TouchEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            if (props.onTouch) {
                props.onTouch();
            }
        }}
        disabled={props.disabled}
    >
        {props.children}
    </StyledSmallControlButton>
);

const ToggleSoundButton: React.FunctionComponent<SoundButtonProps> = props => (
    <SmallControlButton
        onClick={props.onClick}
        onTouch={props.onTouch}
        title={props.title}
        disabled={props.disabled}
    >
        {props.soundButtonIcon}
    </SmallControlButton>
);

export class SoundControls extends React.PureComponent<
    SoundControlProps,
    SoundControlState
> {
    // Following variables are used for preserving the state between different event renders.
    // They don't need to be part of the component state and cause re-render.
    // That's why they are created as instance variables and readonly check needs to be disabled.
    private capturedVolume?: number = INIT_VOLUME_VALUE;
    private clearFocusTimeout?: number;

    public constructor(props: SoundControlProps) {
        super(props);
        this.state = {
            hasPermanentFocus: false,
            isProgressBarVisible: false
        };
    }

    private readonly handleDragChange = (
        data: VideoPlayerProgressBarSelectedValue
    ) => {
        const {onVolumeChange = noop} = this.props;
        onVolumeChange(data.value);
    };

    // iOS doesn't support setting volume via JS it's possible only to toggle between muted and unmuted state
    // -> clicking max. sound btn. on iOS sets volume to 0
    private readonly handleMaximumSoundButtonClick = () => {
        const {value, onVolumeChange = noop} = this.props;
        this.capturedVolume = isIOS() ? MAX_VOLUME_VALUE : value;
        onVolumeChange(0);
        if (!isIOS()) {
            this.setState({
                isProgressBarVisible: true
            });
        }
        this.resetClearingFocus();
    };

    private readonly handleMaximumSoundButtonTouch = () => {
        const {hasPermanentFocus} = this.state;
        if (isIOS()) {
            this.handleMaximumSoundButtonClick();
        }
        if (!hasPermanentFocus) {
            this.setPermanentFocus();
        }

        this.handleMaximumSoundButtonClick();
    };

    // iOS doesn't support setting volume via JS it's possible only to toggle between muted and unmuted state
    // -> clicking max. sound btn. on iOS sets volume to 1
    private readonly handleMinimumSoundButtonClick = () => {
        const {onVolumeChange = noop} = this.props;
        const volume = isIOS() ? MAX_VOLUME_VALUE : this.capturedVolume || 0;
        onVolumeChange(volume);
        this.capturedVolume = undefined;
        this.resetClearingFocus();
    };

    private readonly handleMinimumSoundButtonTouch = () => {
        const {hasPermanentFocus} = this.state;
        if (isIOS()) {
            this.handleMinimumSoundButtonClick();
        }
        if (!hasPermanentFocus) {
            this.setPermanentFocus();
        }

        this.handleMinimumSoundButtonClick();
    };

    private readonly handleDragStart = (
        data: VideoPlayerProgressBarSelectedValue
    ) => {
        window.clearTimeout(this.clearFocusTimeout);
        this.capturedVolume = data.value;
        const {onDragStart = noop} = this.props;
        onDragStart();
    };

    private readonly handleDragEnd = () => {
        this.resetClearingFocus();
        const {onDragEnd = noop} = this.props;
        onDragEnd();
    };

    private readonly setPermanentFocus = () => {
        this.setState({
            hasPermanentFocus: false
        });
    };

    private readonly resetClearingFocus = () => {
        window.clearTimeout(this.clearFocusTimeout);
        this.clearFocusTimeout = window.setTimeout(() => {
            this.setState({
                hasPermanentFocus: false
            });
        }, FOCUS_DURATION);
        const {onFocusReset = noop} = this.props;
        onFocusReset();
    };

    private readonly handleSoundBarKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        const {key} = event;
        if (key === ArrowKeys.ArrowUp || key === ArrowKeys.ArrowDown) {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            return this.props.onVolumeBarKeyDown?.(key);
        }
    };

    public componentWillUnmount(): void {
        window.clearTimeout(this.clearFocusTimeout);
    }

    public render(): JSX.Element {
        const {
            disableButtons,
            value,
            title,
            soundProgressBarAriaLabel
        } = this.props;

        const showMaxSoundBtn = value > MED_VOLUME_VALUE;
        const showMediumSoundBtn = value > 0 && value <= MED_VOLUME_VALUE;
        const showProgressBar = !isIOS();

        let soundButtonClick: () => void;
        let SoundButtonTouch: () => void;
        let soundButtonIcon: JSX.Element;

        if (showMaxSoundBtn || showMediumSoundBtn) {
            soundButtonClick = this.handleMaximumSoundButtonClick;
            SoundButtonTouch = this.handleMaximumSoundButtonTouch;
            soundButtonIcon = showMaxSoundBtn ? (
                <VolumeMaximum variant="default" ariaHidden />
            ) : (
                <VolumeMedium variant="default" ariaHidden />
            );
        } else {
            soundButtonClick = this.handleMinimumSoundButtonClick;
            SoundButtonTouch = this.handleMinimumSoundButtonTouch;
            soundButtonIcon = <VolumeMute variant="default" ariaHidden />;
        }

        return (
            <StyledSoundControlsWrapper>
                <StyledSoundControls
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                    onTouchEnd={(e: React.TouchEvent) => e.stopPropagation()}
                    onFocus={this.setPermanentFocus}
                    onBlur={this.resetClearingFocus}
                >
                    <StyledFlexReverseOrderWrapper>
                        <ToggleSoundButton
                            title={title}
                            onClick={soundButtonClick}
                            onTouch={SoundButtonTouch}
                            disabled={disableButtons}
                            soundButtonIcon={soundButtonIcon}
                        />
                        {showProgressBar && (
                            <StyledProgressBarWrapper
                                isProgressBarVisible={
                                    this.state.isProgressBarVisible
                                }
                            >
                                <VerticalProgressBar
                                    value={value}
                                    valueMin={MIN_VOLUME_VALUE}
                                    valueMax={MAX_VOLUME_VALUE}
                                    progressBarAriaLabel={
                                        soundProgressBarAriaLabel
                                    }
                                    onDragChange={this.handleDragChange}
                                    onDragStart={this.handleDragStart}
                                    onDragEnd={this.handleDragEnd}
                                    disableHoverEffect
                                    onVolumeBarKeyDown={
                                        this.handleSoundBarKeyDown
                                    }
                                    scrubberDisabled={disableButtons}
                                />
                            </StyledProgressBarWrapper>
                        )}
                    </StyledFlexReverseOrderWrapper>
                </StyledSoundControls>
            </StyledSoundControlsWrapper>
        );
    }
}
