import {styled} from '@volkswagen-onehub/components-core';
import React from 'react';

const StyledHeader = styled.header``;
const StyledCopyItem = styled.div``;
const StyledDisclaimers = styled.div``;

const StyledLayerContainer = styled.div`
    padding: ${props => props.theme.size.dynamic0200}
        ${props => props.theme.size.dynamic0130};

    & ${StyledHeader} {
        margin-bottom: ${props => props.theme.size.dynamic0100};
    }
`;

interface DataInfoLayerProps {
    heading: JSX.Element;
    closeButton: JSX.Element;
    copyElement?: JSX.Element;
    disclaimers?: JSX.Element;
}

export const DataInfoLayer = (props: DataInfoLayerProps) => {
    const {heading, copyElement, closeButton, disclaimers} = props;
    return (
        <StyledLayerContainer>
            {closeButton}
            <StyledHeader>{heading}</StyledHeader>
            {copyElement ? (
                <StyledCopyItem>{copyElement}</StyledCopyItem>
            ) : null}
            {disclaimers ? (
                <StyledDisclaimers>{disclaimers}</StyledDisclaimers>
            ) : null}
        </StyledLayerContainer>
    );
};
