import {
    Breakpoints,
    ThemeDefinition,
    css
} from '@volkswagen-onehub/components-core';

export const ANIMATION_DURATION = '0.3s';
export const MOVING_FRAME_THICKNESS = 2;

export const CSS_VAR_TOP_BAR_HEIGHT = '--s2-one-hub-navigation-top-bar-height';

const LOGO_SIZES: {[key in Breakpoints]: number} = {
    [Breakpoints.default]: 32,
    [Breakpoints.b560]: 40,
    [Breakpoints.b960]: 48,
    [Breakpoints.b1280]: 48,
    [Breakpoints.b1600]: 48,
    [Breakpoints.b1920]: 56,
    [Breakpoints.b2560]: 56,
    Infinity: 56
};

export function getLogoSize(breakpoint: Breakpoints): number {
    return LOGO_SIZES[breakpoint];
}

interface TopBarSizes {
    top: string;
    content?: string;
    bottom: string;
}

const getTopBarSizes = (
    theme: ThemeDefinition,
    breakpoint: Breakpoints
): TopBarSizes => {
    const topBarSizes: {[key: string]: TopBarSizes} = {
        [Breakpoints.default]: {
            top: theme.size.static250,
            content: theme.size.static350,
            bottom: theme.size.static200
        },
        [Breakpoints.b560]: {
            top: theme.size.static250,
            content: theme.size.static350,
            bottom: theme.size.static200
        },
        [Breakpoints.b960]: {
            top: theme.size.static250,
            content: theme.size.static350,
            bottom: theme.size.static200
        },
        [Breakpoints.b1920]: {
            top: theme.size.static350,
            content: theme.size.static350,
            bottom: theme.size.static200
        }
    };

    return topBarSizes[breakpoint];
};

function getTopBarHeight(
    theme: ThemeDefinition,
    breakpoint: Breakpoints,
    isExpanded: boolean
): string {
    const selectedSizes = getTopBarSizes(theme, breakpoint);
    const top = selectedSizes.top;
    const content = selectedSizes.content;
    const bottom = selectedSizes.bottom;
    const logoSize = isExpanded ? `${getLogoSize(breakpoint)}px` : '0px';

    return `calc(${top} + ${content || '0'} + ${bottom} + ${logoSize})`;
}

export const setTopBarHeight = (cssVarName: string, isExpanded: boolean) => css`
    ${props =>
        [
            Breakpoints.default,
            Breakpoints.b560,
            Breakpoints.b960,
            Breakpoints.b1920
        ]
            .map(
                breakpoint => `
					@media (min-width: ${breakpoint}px) {
						${cssVarName}: ${getTopBarHeight(props.theme, breakpoint, isExpanded)};
					}
				`
            )
            .join('\n')}
`;

export function getLogoLayerTopPosition(
    theme: ThemeDefinition,
    breakpoint: Breakpoints,
    isExpanded: boolean
): string {
    if (!isExpanded) {
        return `-${getLogoSize(breakpoint)}px`;
    }

    // get unexpanded height
    const framePosition = getTopBarHeight(theme, breakpoint, false);
    const logoSizeFactor = getLogoSize(breakpoint) / 2;
    const movingFrameFactor = MOVING_FRAME_THICKNESS / 2;

    // Nested calc is fine
    return `calc(${framePosition} - ${logoSizeFactor}px + ${movingFrameFactor}px)`;
}
