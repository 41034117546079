import {styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';

const BreadcrumbLinkInnerTitle = styled.span`
    white-space: nowrap;
`;
BreadcrumbLinkInnerTitle.displayName = 'BreadcrumbLinkInnerTitle';

export interface BreadcrumbLinkInnerProps {
    title: string;
    index: number;
    absoluteUrl: string;
}

export const BreadcrumbLinkInner: React.FunctionComponent<BreadcrumbLinkInnerProps> = (
    props: BreadcrumbLinkInnerProps
) => {
    const {title, index, absoluteUrl} = props;

    return (
        <>
            <BreadcrumbLinkInnerTitle itemProp="name">
                {title}
            </BreadcrumbLinkInnerTitle>
            <meta itemProp="position" content={(index + 1).toString()} />
            <meta itemProp="item" content={absoluteUrl} />
        </>
    );
};
