import {observer} from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';
import {
    useContentStore,
    useLogger,
    useModelContext,
    useModelStore,
    usePersonalizationConfig,
    usePersonalizationStore
} from '../context';
import {PersonalizationContext} from '../context/personalization/PersonalizationContext';
import {JS_PERSONALIZATION_SHOW_DEFAULT} from '../context/personalization/PriorityPersonalizationLoader';
import {createSelector} from '../context/personalization/SelectorUtils';
import {IncludeProps} from '../infrastructure/api/Compatibility';
import {Include} from '../infrastructure/compatibility/Include';
import {DynamicComponentLoader} from '../modules/structure/DynamicComponentLoader';
import {isInVec} from '../utils/personalization/isInVec';
import {StyledPropsExtractor} from '../utils/styledUtils';
import {useIsClientOnlyRendering} from '../utils/useIsClientOnlyRendering';
import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {useSwapSectionPersonalizationEnabled} from '../modules/editorial/sections/section-wrapper-utils/useSwapSectionPersonalizationEnabled';

interface StyledPersonalizationIncludeWrapperProps {
    hideDefault: boolean;
    isInVEC: boolean;
}

const StyledPersonalizationIncludeContainer = styled(StyledPropsExtractor)`
    display: inline-block;
    width: 100%;
`;

StyledPersonalizationIncludeContainer.displayName =
    'StyledPersonalizationIncludeContainer';

const StyledPersonalizationIncludeWrapper = styled(StyledPropsExtractor)<
    StyledPersonalizationIncludeWrapperProps
>`
    html:not(.js-enabled) & {
        opacity: 1;
    }
    body.${JS_PERSONALIZATION_SHOW_DEFAULT} & {
        opacity: 1;
    }
    display: inline-block;
    width: 100%;
    opacity: ${props => (props.hideDefault ? 0 : 1)};
    border: ${props =>
        // Add a small border for AT VEC so author is able to see that module is
        // personalizable and is able to select it
        props.isInVEC ? `6px solid ${props.theme.colors.signal.neutral}` : ''};
`;
StyledPersonalizationIncludeWrapper.displayName =
    'StyledPersonalizationIncludeWrapper';

/**
 * @param placeholderId is the contentId of the original content that will be exchanged with the personalized content.
 *      It's the path to the module with a part hashed and suffixed with the contentName of the section (if existing otherwise ~n/a).
 *      Its needed for proper tracking of personalization, to see which module got exchanged.
 * @param mboxId is the path of the original content that will be exchanged with the personalized content.
 *      First part is hashed and no contentName is added.
 *      It's used to find existing personalization in the personalizationStore
 *      where personalizations are stored with the mboxId as the to-be-exchanged content and a path to an XF that will be inserted.
 */
interface PersonalizationIncludeProps extends IncludeProps {
    contentId?: string;
    placeholderId: string;
    mboxId: string;
}

const InternalPersonalizationInclude: React.FunctionComponent<PersonalizationIncludeProps> = observer(
    function IPI(props: PersonalizationIncludeProps): JSX.Element {
        const personalizationStore = usePersonalizationStore();
        const logger = useLogger();

        // on the initial page the hydration is performed before mounting. No need to observe initial render.
        const isClient = useIsClientOnlyRendering();

        const {
            path: defaultContentPath,
            containerProps,
            extraProps,
            isNotEditable,
            personalizable,
            placeholderId,
            mboxId
        } = props;

        const data = personalizationStore.getContent(
            defaultContentPath,
            mboxId
        );

        if (data) {
            logger.personalization.debug(
                'perso include %s, client=%s, data loaded=%s, resource=%s',
                props.path,
                isClient,
                data.loaded,
                data.resource
            );
        }

        const {personalize, swapSection} = useSwapSectionPersonalizationEnabled(
            mboxId
        );

        // wait till hydration is completed before showing personalized content
        if (personalize && isClient && data && data.loaded && data.resource) {
            logger.personalization.info(
                'displaying personalized content for %s',
                props.path
            );

            const {
                resource: {path: resourcePath, resourceType, contentId}
            } = data;

            return (
                <PersonalizationContext.Provider
                    value={{
                        isPersonalizable: personalizable,
                        contentId: contentId,
                        placeholderId: placeholderId,
                        wasPersonalized: true
                    }}
                >
                    <DynamicComponentLoader path={resourcePath}>
                        <StyledPersonalizationIncludeContainer>
                            {styledProps => (
                                <Include
                                    key={resourcePath}
                                    path={resourcePath}
                                    resourceType={resourceType}
                                    extraProps={extraProps}
                                    isNotEditable={isNotEditable}
                                    containerProps={{
                                        ...containerProps,
                                        className: styledProps.className
                                    }}
                                    defaultContentPath={defaultContentPath}
                                />
                            )}
                        </StyledPersonalizationIncludeContainer>
                    </DynamicComponentLoader>
                </PersonalizationContext.Provider>
            );
        } else {
            const hideDefault =
                !swapSection && (!isClient || Boolean(data && !data.loaded));
            const id = createSelector(props.path);

            if (hideDefault) {
                logger.personalization.debug(
                    'hiding default content for %s: %s',
                    props.path,
                    hideDefault
                );
            }

            return (
                <PersonalizationContext.Provider
                    value={{
                        isPersonalizable: personalizable,
                        isLoading: hideDefault,
                        contentId: placeholderId,
                        placeholderId: placeholderId,
                        wasPersonalized: false
                    }}
                >
                    <StyledPersonalizationIncludeWrapper
                        hideDefault={hideDefault}
                        isInVEC={isClient && isInVec()}
                    >
                        {styledProps => (
                            <Include
                                {...props}
                                containerProps={{
                                    ...containerProps,
                                    className: styledProps.className,
                                    id
                                }}
                                extraProps={extraProps}
                            />
                        )}
                    </StyledPersonalizationIncludeWrapper>
                </PersonalizationContext.Provider>
            );
        }
    }
);

/**
 *
 * teaser content ids always reference a xf. That's why we need to use the special placeholderId generated on the server
 */
function getPlaceholderId(model?: TransferModel): string {
    if (!model) {
        return '';
    }
    if (model.placeholderId) {
        return model.placeholderId;
    }
    return model.contentId || '';
}

InternalPersonalizationInclude.displayName = 'InternalPersonalizationInclude';

export const PersonalizationInclude: React.FunctionComponent<IncludeProps> = (
    props: IncludeProps
): JSX.Element | null => {
    const modelStore = useModelStore();
    const personalizationConfig = usePersonalizationConfig();
    const modelContext = useModelContext();
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const rootPath = pageRootModel ? pageRootModel.aemResourcePath : '/';
    const contextPath = modelContext.value || rootPath;
    const {path} = props;

    if (!path) {
        return null;
    }

    const modelPath = path.startsWith('/') ? path : `${contextPath}/${path}`;
    const model = modelStore.getData(modelPath, false);
    const isPersonalizable = Boolean(
        personalizationConfig.enabled &&
            (props.personalizable || (model && model.personalizable))
    );

    // placeholderId and contentId differ for teasers.
    const placeholderId = getPlaceholderId(model);
    const contentId = model ? model.contentId : '';
    const mboxId = model ? model.mboxId : '';

    if (!isPersonalizable) {
        return (
            <DynamicComponentLoader path={modelPath}>
                <Include {...props} />
            </DynamicComponentLoader>
        );
    }

    return (
        <InternalPersonalizationInclude
            {...props}
            contentId={contentId}
            path={`${contextPath}/${props.path}`}
            personalizable={isPersonalizable}
            placeholderId={placeholderId}
            mboxId={mboxId}
        />
    );
};

PersonalizationInclude.displayName = 'PersonalizationInclude';
