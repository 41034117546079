const getTimeout = async <T>(timeout: number, service: string) => {
    return new Promise<T>((_resolve, reject) => {
        const wait = setTimeout(
            () => {
                clearTimeout(wait);
                reject({
                    timeout: true,
                    message: `timeout of ${timeout} ms for ${service}`
                });
            },
            timeout,
            service
        );
    });
};

export const timeoutPromise = async <T>(
    p: Promise<T>,
    timeout: number,
    service: string
): Promise<T> => {
    return Promise.race([p, getTimeout(timeout, service)]) as Promise<T>;
};
