import * as React from 'react';

import {
    Breakpoints,
    Image,
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';

import {LinkWindowTarget} from '../../generated/api';
import {useLayerContext} from '../context';
import {CmsTextLink} from './links/CmsTextLink';
import {ZIndex} from '../utils/zIndex';

export interface MovingFrameProps {
    readonly label: string;
    readonly linkText?: string;
    readonly url?: string;
    readonly target?: LinkWindowTarget;
    readonly labelIcon?: string;
}

interface StyledMovingFrameWrapProps {
    isLayerContext?: boolean;
}

const FRAME_TOP_POSITION = 0.85; // in EM by label line-height

const StyledMovingFrameWrap = styled.div<StyledMovingFrameWrapProps>`
    ${props =>
        !props.isLayerContext &&
        `
            overflow: hidden;
            padding-top: ${props.theme.size.static200}; // sadly due Chrome, otherwise causing inner scroll-y issues
        `}
`;

const StyledMovingFrame = styled.div<StyledMovingFrameWrapProps>`
    position: relative;
    max-width: ${Breakpoints.b1920}px;

    margin: 0 auto;
    padding-bottom: ${props => props.theme.size.dynamic0200};

    z-index: ${props =>
        props.isLayerContext
            ? ZIndex.layers.movingFrame.z
            : ZIndex.main.movingFrame.z};

    // frame
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        left: calc(-1 * ${props => props.theme.size.grid001});
        right: calc(-1 * ${props => props.theme.size.grid001});
        bottom: 0;

        top: ${FRAME_TOP_POSITION}em;
        font-size: ${props => props.theme.fonts.copy[100].fontSize};
        box-shadow: inset 0 0 0 2px ${props => props.theme.colors.focus.main};
    }
`;

const StyledLabel = styled.div`
    display: inline-flex;
    margin: 0 ${props => props.theme.size.grid002}
        ${props => props.theme.size.dynamic0200};
    padding: 0 ${props => props.theme.size.static150};
    background: ${props => props.theme.colors.background.primary};
`;

const StyledTextWrapper = styled.div`
    display: inline-block;
`;
const StyledLabelIconWrapper = styled.div`
    display: inline-block;
    margin: 0 ${props => props.theme.size.static150} -${props =>
            props.theme.size.static100} 0;
    width: 20px;
    min-width: 20px;
    img {
        aspect-ratio: 1 / 1;
        width: 100%;
    }
`;

const TRANSPARENT_SVG =
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 9' />";

export const MovingFrame: React.FunctionComponent<React.PropsWithChildren<
    MovingFrameProps
>> = (props): JSX.Element => {
    const {children, label, linkText, target, url, labelIcon} = props;
    const hasLink = linkText && url;

    const layerContext = useLayerContext();
    const isInLayer = Boolean(layerContext.layerType);

    return (
        <StyledMovingFrameWrap isLayerContext={isInLayer}>
            <StyledMovingFrame isLayerContext={isInLayer}>
                <StyledLabel>
                    {labelIcon && (
                        <StyledLabelIconWrapper>
                            <Image
                                src={labelIcon || TRANSPARENT_SVG}
                                alt={label}
                            />
                        </StyledLabelIconWrapper>
                    )}
                    <StyledTextWrapper>
                        {label && (
                            <Text
                                appearance={TokenTextAppearance.copy100}
                                tag={TextTag.span}
                            >
                                {label}
                            </Text>
                        )}
                        {label && hasLink && (
                            <Text
                                appearance={TokenTextAppearance.copy100}
                                tag={TextTag.span}
                            >
                                {' '}
                                |{' '}
                            </Text>
                        )}
                        {hasLink && (
                            <Text
                                appearance={TokenTextAppearance.copy100}
                                tag={TextTag.span}
                                bold
                            >
                                <CmsTextLink href={url} target={target}>
                                    {linkText}
                                </CmsTextLink>
                            </Text>
                        )}
                    </StyledTextWrapper>
                </StyledLabel>
                {children}
            </StyledMovingFrame>
        </StyledMovingFrameWrap>
    );
};
