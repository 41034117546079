import {Disclaimer} from '../../../generated/core';
import {Logger} from '../logger/Logger';
import {
    DisclaimerStore,
    GroupedDisclaimersWithReference,
    RegisteredDisclaimer,
    StandardDisclaimerStore
} from './DisclaimerStore';
import {observable} from 'mobx';

/**
 * This store delegates all additions and removals to the current StandardDisclaimerStore.
 * Additionally it remembers all disclaimers and transfers them to the new store when that is set.
 */
export class DisclaimerStoreProxy implements DisclaimerStore {
    @observable private _store: StandardDisclaimerStore;
    private logger: Logger;

    public constructor(store: StandardDisclaimerStore, logger: Logger) {
        this._store = store;
        this.logger = logger;
    }

    public get footerDisclaimers(): GroupedDisclaimersWithReference[] {
        return this.store.footerDisclaimers;
    }

    public set store(newStore: StandardDisclaimerStore) {
        this._store.cleanup();
        this._store = newStore;
    }

    public get store(): StandardDisclaimerStore {
        return this._store;
    }

    public add(
        disclaimer: Disclaimer,
        legalEntity?: string,
        disableScroll = false,
        keepWhenUnregistered = false
    ): RegisteredDisclaimer {
        const registeredDisclaimer = this._store.add(
            disclaimer,
            legalEntity,
            disableScroll
        );

        return {
            ...registeredDisclaimer,
            unregister: () => {
                if (keepWhenUnregistered) {
                    this.logger.disclaimer.debug(
                        'not removing disclaimer %s although component was unregistered',
                        registeredDisclaimer.reference
                    );
                    // will be removed on page change, because the whole store is exchanged
                    return;
                }
                registeredDisclaimer.unregister();
            }
        };
    }
}
