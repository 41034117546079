import * as React from 'react';
import styled, {
    ThemeContext,
    ThemedStyledProps,
    withTheme
} from 'styled-components';

import {
    Container,
    ContainerPadding,
    ContainerWrap,
    ThemeDefinition
} from '@volkswagen-onehub/components-core';
import {Layout} from '@volkswagen-onehub/components-core';
import {TextProps} from '@volkswagen-onehub/components-core';

import {DirectionalSpacing, MoodGalleryLayout, getLayout} from './layout';

export interface MoodGalleryProps {
    readonly claim?: React.ReactElement<TextProps>;
    readonly theme: ThemeDefinition;
}

enum LaneType {
    top,
    bottom
}

interface StyledMoodGalleryItemProps extends MoodGalleryProps {
    readonly lane?: LaneType;
    readonly padding?: DirectionalSpacing;
}

const getItemPadding = (
    props: ThemedStyledProps<
        React.ClassAttributes<HTMLDivElement> &
            React.HTMLAttributes<HTMLDivElement> &
            StyledMoodGalleryItemProps,
        // tslint:disable-next-line:no-any
        any
    >
): string => {
    const {padding} = props;
    if (padding) {
        const right = padding.right ? padding.right : '0';
        const left = padding.left ? padding.left : '0';

        return `0 ${right} 0 ${left}`;
    }

    return '0';
};

const StyledMoodGalleryItem = styled.div<StyledMoodGalleryItemProps>`
    display: flex;
    padding: ${p => getItemPadding(p)};
    height: 100%;
    align-items: ${(p: StyledMoodGalleryItemProps) =>
        p.lane === LaneType.top ? 'flex-end' : 'flex-start'};
`;
StyledMoodGalleryItem.displayName = 'StyledMoodGalleryItem';

const getItem = (index: number, children: React.ReactNode) => {
    if (index < 0 || index > React.Children.count(children) - 1) {
        return null;
    }
    const childrenArr = React.Children.toArray(children);

    return childrenArr[index];
};

const createItem = (
    index: number,
    children: React.ReactNode,
    layout: MoodGalleryLayout,
    laneType: LaneType
) => {
    const item = getItem(index, children);
    if (!item) {
        return null;
    }
    const itemSpacing = layout.itemSpacing[index];

    return (
        <StyledMoodGalleryItem
            key={index}
            lane={laneType}
            padding={itemSpacing}
        >
            {item}
        </StyledMoodGalleryItem>
    );
};

const MoodGalleryBase: React.FunctionComponent<MoodGalleryProps> = props => {
    const {claim, children, theme} = props;

    return (
        <ThemeContext.Consumer>
            {({direction}) => {
                const count = React.Children.count(children);
                const layout = getLayout(count, theme, direction);
                if (!children || count === 0 || !layout) {
                    return null;
                }

                return (
                    <>
                        <Layout
                            allowOverflowingContent
                            appearance={layout.topLane}
                        >
                            {layout.topLaneOrder.map(i =>
                                createItem(i, children, layout, LaneType.top)
                            )}
                        </Layout>
                        <Layout
                            allowOverflowingContent
                            appearance={layout.bottomLane}
                        >
                            <div>
                                {createItem(
                                    3,
                                    children,
                                    layout,
                                    LaneType.bottom
                                )}
                                {claim && (
                                    <Container
                                        padding={{
                                            left: ContainerPadding.grid002,
                                            right: ContainerPadding.grid002,
                                            top: ContainerPadding.dynamic0150
                                        }}
                                        wrap={ContainerWrap.always}
                                    >
                                        {claim}
                                    </Container>
                                )}
                            </div>
                            {createItem(1, children, layout, LaneType.bottom)}
                        </Layout>
                    </>
                );
            }}
        </ThemeContext.Consumer>
    );
};

export const MoodGallery = withTheme(MoodGalleryBase);
