import {ContentSlide} from '.';
import * as React from 'react';
import {horizontalScroll} from '../helpers';
import {SCROLL_RESET_TIMEOUT} from './helpers';

// NOTE: load from core once fixed
// import {CTA, GalleryContext, styled} from '@volkswagen-onehub/components-core';
import {CTA, styled} from '@volkswagen-onehub/components-core';
import {GalleryContext} from '../gallery-core-v67.18';

export interface ContentFilter {
    label: string;
    count: number;
}

const StyledFilterWrapper = styled.ul`
    display: flex;
    gap: ${props => props.theme.size.static150};
    padding: 0 ${props => props.theme.size.grid002};
    margin-bottom: ${props => props.theme.size.dynamic0050};
    list-style: none;
    font-size: ${props => props.theme.size.static250};
    ${horizontalScroll}

    // override colors for selected primary button
    [data-selected="true"] {
        background: ${props => props.theme.colors.button.primary.active};
        border-color: ${props => props.theme.colors.button.primary.active};
    }

    span {
        font-size: ${props => props.theme.fonts.label[150].fontSize};
    }
`;

const StyledFilterCountWrapper = styled.span`
    position: relative;
    display: inline-block;

    span {
        display: inline-block;
        transform: translateY(-1px);
    }
`;

export const ContentFilterChips = ({
    chipAriaLabel,
    filters,
    selectedFilter,
    setSelectedFilter
}: {
    chipAriaLabel: string;
    filters: ContentFilter[];
    selectedFilter: string;
    setSelectedFilter: (filter: string) => void;
}) => {
    const {triggerChange} = React.useContext(GalleryContext);

    const onFilterSelect = (label: string) => {
        setSelectedFilter(label);
        setTimeout(() => {
            triggerChange(0, false);
        }, SCROLL_RESET_TIMEOUT);
    };

    return (
        <StyledFilterWrapper>
            {filters.map(({label, count}, index) => (
                <li key={label}>
                    <CTA
                        data-selected={selectedFilter === label}
                        onClick={() => onFilterSelect(label)}
                        data-testid={`content-slider-filter-${
                            !index ? 'all' : label
                        }`}
                        tag="button"
                        size="small"
                        emphasis={
                            selectedFilter === label ? 'primary' : 'secondary'
                        }
                        aria-label={chipAriaLabel + ' ' + label}
                        key={label}
                    >
                        {label}{' '}
                        <StyledFilterCountWrapper>
                            <span>(</span>
                            {count}
                            <span>)</span>
                        </StyledFilterCountWrapper>
                    </CTA>
                </li>
            ))}
        </StyledFilterWrapper>
    );
};

export const createFilters = (
    slides: ContentSlide[],
    allChipLabel: string
): ContentFilter[] => {
    const filters = slides.reduce((acc, slide) => {
        const filter = acc.find(
            ({label}: {label: string}) => label === slide.category
        );
        if (filter) {
            filter.count++;
        } else {
            acc.push({label: slide.category, count: 1});
        }
        return acc;
    }, [] as ContentFilter[]);

    return [{label: allChipLabel, count: slides.length}, ...filters];
};
