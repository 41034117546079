import * as React from 'react';
import {
    MediaStateMachine,
    ObserverEventTrackingCallbacks
} from './MediaStateMachine';

export interface IntersectionObserverFactory {
    create(
        callback: IntersectionObserverCallback,
        options?: IntersectionObserverInit
    ): IntersectionObserver;
}

const defaultIntersectionObserverFactory: IntersectionObserverFactory = {
    create: (
        callback: IntersectionObserverCallback,
        options?: IntersectionObserverInit
    ) => {
        return new IntersectionObserver(callback, options);
    }
};

export interface MediaIntersectionObserverOptions {
    readonly mediaState: React.RefObject<MediaStateMachine>;
    readonly observedElement: React.RefObject<HTMLElement>;
    readonly inEditor: boolean;
    readonly observerFactory?: IntersectionObserverFactory;
    readonly trackingCallbacks?: ObserverEventTrackingCallbacks;
}

/**
 * Intersection observer for Animation and Video Element
 */
export class MediaIntersectionObserver {
    private readonly isInEditor: boolean = false;
    private readonly mediaState: React.RefObject<MediaStateMachine>;
    private observer?: IntersectionObserver;
    private observerFactory: IntersectionObserverFactory;
    private observedElement: React.RefObject<HTMLElement>;
    private autoPlayTimeout?: number;
    private trackingCallbacks?: ObserverEventTrackingCallbacks;

    public constructor(options: MediaIntersectionObserverOptions) {
        const {
            mediaState,
            observedElement,
            inEditor,
            observerFactory = defaultIntersectionObserverFactory,
            trackingCallbacks
        } = options;

        this.observerFactory = observerFactory;
        this.mediaState = mediaState;
        this.observedElement = observedElement;
        this.isInEditor = inEditor;
        this.trackingCallbacks = trackingCallbacks;
    }

    public start = () => {
        // only use automatic play and stop in publish view
        if (!this.isInEditor && this.mediaState && this.mediaState.current) {
            // initialize observer only once
            if (!this.observer) {
                this.observer = this.observerFactory.create(
                    this.intersectionObserverCallback,
                    {threshold: this.mediaState.current.threshold}
                );
            }
            const observedElement = this.observedElement.current;
            if (observedElement) {
                this.observer.observe(observedElement);
            }
        }
    };

    public startDelayed = (delayMs: number, started?: () => void) => {
        this.autoPlayTimeout = window.setTimeout(() => {
            this.start();
            if (started) {
                started();
            }
        }, delayMs);
    };

    public stop = () => {
        clearTimeout(this.autoPlayTimeout);
        // disconnect function still not available in some browsers
        if (!this.isInEditor && this.observer && this.observer.disconnect) {
            this.observer.disconnect();
        }
    };

    private intersectionObserverCallback: IntersectionObserverCallback = entries => {
        entries.forEach(entry => {
            const {intersectionRatio} = entry;
            if (this.mediaState && this.mediaState.current) {
                this.mediaState.current.handleObserverEvent(
                    intersectionRatio,
                    this.trackingCallbacks
                );
            }
        });
    };
}
