interface BreakpointColumnMap {
    readonly [key: number]: number;
}

export const copyStartColumnMap: BreakpointColumnMap = {
    1440: 7,
    1024: 7,
    768: 5
};

export const copyWidthMap: BreakpointColumnMap = {
    1440: 12,
    1024: 12,
    768: 16
};

export const fullWidthCopyStartColumnMap: BreakpointColumnMap = {
    1440: 4,
    1024: 4,
    768: 2
};

export const fullWidthCopyWidthMap: BreakpointColumnMap = {
    1440: 20,
    1024: 18,
    768: 20
};

interface GridConfiguration {
    readonly imageStartColumn: number;
    readonly imageWidthInColumns: number;
    readonly totalContentColumns: number;
}

interface AlignmentMap {
    readonly LEFT: GridConfiguration;
    readonly RIGHT: GridConfiguration;
}

interface AspectRatioMap {
    readonly [key: string]: AlignmentMap;
}

interface BreakpointAspectRatioMap {
    readonly [key: number]: AspectRatioMap;
}

const AspectRatioToColumnsMap1440: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 5,
            imageWidthInColumns: 4,
            totalContentColumns: 14
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 4,
            totalContentColumns: 14
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 6,
            imageWidthInColumns: 3,
            totalContentColumns: 13
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 3,
            totalContentColumns: 13
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 4,
            imageWidthInColumns: 5,
            totalContentColumns: 15
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 15
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 6,
            imageWidthInColumns: 3,
            totalContentColumns: 13
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 3,
            totalContentColumns: 13
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 5,
            imageWidthInColumns: 4,
            totalContentColumns: 14
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 4,
            totalContentColumns: 14
        }
    }
};

const AspectRatioToColumnsMap1024: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 5,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 5,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 4,
            imageWidthInColumns: 6,
            totalContentColumns: 15
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 6,
            totalContentColumns: 15
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 6,
            imageWidthInColumns: 4,
            totalContentColumns: 13
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 4,
            totalContentColumns: 13
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 5,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 14
        }
    }
};

const AspectRatioToColumnsMap768: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 3,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 3,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 18
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 2,
            imageWidthInColumns: 7,
            totalContentColumns: 19
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 7,
            totalContentColumns: 19
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 4,
            imageWidthInColumns: 5,
            totalContentColumns: 17
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 17
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 3,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        }
    }
};

const FullWidthAspectRatioToColumnsMap1440: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 6,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 20,
            imageWidthInColumns: 6,
            totalContentColumns: 21
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 5,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 19,
            imageWidthInColumns: 5,
            totalContentColumns: 21
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 8,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 16,
            imageWidthInColumns: 8,
            totalContentColumns: 21
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 6,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 16,
            imageWidthInColumns: 6,
            totalContentColumns: 21
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 6,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 12,
            imageWidthInColumns: 6,
            totalContentColumns: 21
        }
    }
};

const FullWidthAspectRatioToColumnsMap1024: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 7,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 16,
            imageWidthInColumns: 7,
            totalContentColumns: 21
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 7,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 14,
            imageWidthInColumns: 7,
            totalContentColumns: 21
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 10,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 12,
            imageWidthInColumns: 10,
            totalContentColumns: 21
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 6,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 15,
            imageWidthInColumns: 6,
            totalContentColumns: 21
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 6,
            totalContentColumns: 24
        },
        RIGHT: {
            imageStartColumn: 15,
            imageWidthInColumns: 7,
            totalContentColumns: 21
        }
    }
};

const FullWidthAspectRatioToColumnsMap768: AspectRatioMap = {
    '4:3': {
        LEFT: {
            imageStartColumn: 1,
            imageWidthInColumns: 5,
            totalContentColumns: 22
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 20
        }
    },
    '3:4': {
        LEFT: {
            imageStartColumn: 3,
            imageWidthInColumns: 6,
            totalContentColumns: 22
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 18
        }
    },
    '16:9': {
        LEFT: {
            imageStartColumn: 2,
            imageWidthInColumns: 7,
            totalContentColumns: 22
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 7,
            totalContentColumns: 19
        }
    },
    '9:16': {
        LEFT: {
            imageStartColumn: 4,
            imageWidthInColumns: 5,
            totalContentColumns: 22
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 5,
            totalContentColumns: 17
        }
    },
    '1:1': {
        LEFT: {
            imageStartColumn: 3,
            imageWidthInColumns: 6,
            totalContentColumns: 22
        },
        RIGHT: {
            imageStartColumn: 17,
            imageWidthInColumns: 6,
            totalContentColumns: 18
        }
    }
};

export const FullWidthAspectRatioToColumnsMap: BreakpointAspectRatioMap = {
    1440: FullWidthAspectRatioToColumnsMap1440,
    1024: FullWidthAspectRatioToColumnsMap1024,
    768: FullWidthAspectRatioToColumnsMap768
};

export const AspectRatioToColumnsMap: BreakpointAspectRatioMap = {
    1440: AspectRatioToColumnsMap1440,
    1024: AspectRatioToColumnsMap1024,
    768: AspectRatioToColumnsMap768
};
