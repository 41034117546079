import * as React from 'react';
import styled, {css} from 'styled-components';

import {
    Breakpoints,
    CTA,
    ThemeDefinition,
    ThemeName,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import {Close} from '@volkswagen-onehub/icons-core';
import {CSSTransition} from 'react-transition-group';

export enum LayerButtonPosition {
    FLYOUT = 'FLYOUT',
    FLYOUT_V2 = 'FLYOUT_V2',
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT'
}

export enum LayerButtonColor {
    TextPrimary,
    BackgroundPrimary
}

export type LayerButtonProps = Readonly<{
    in?: boolean;
    position?: LayerButtonPosition;
    buttonColor?: LayerButtonColor;
    btnCloseLabel?: string;
    theme?: ThemeName;
    onExited?(): void;
    onClick(): void;
}>;

interface LayerButtonPositionDef {
    top?: string;
    right?: string;
    left?: string;
    bottom?: string;
}

function getPosition(
    theme: ThemeDefinition,
    position: LayerButtonPosition
): LayerButtonPositionDef {
    switch (position) {
        case LayerButtonPosition.FLYOUT:
        case LayerButtonPosition.TOP_RIGHT:
            return {
                top: theme.size.dynamic0050,
                right: theme.size.dynamic0050
            };

        case LayerButtonPosition.TOP_LEFT:
            return {
                top: theme.size.dynamic0050,
                left: theme.size.dynamic0050
            };
        case LayerButtonPosition.FLYOUT_V2:
            return {
                top: theme.size.dynamic0050
            };
    }
}

const getFlyoutV2Style = (theme: ThemeDefinition) => css`
    all: unset;
    padding-inline-start: ${theme.size.static100};

    & button {
        color: ${theme.colors.button.secondary.default};
    }
`;

type StyledPositioningProps = Readonly<{
    position: LayerButtonPosition;
}>;

const StyledPositioning = styled.div<StyledPositioningProps>`
    position: fixed;
    top: ${props => getPosition(props.theme, props.position).top};
    right: ${props => getPosition(props.theme, props.position).right};
    left: ${props => getPosition(props.theme, props.position).left};
    bottom: ${props => getPosition(props.theme, props.position).bottom};
    z-index: 1;

    @media (max-width: ${Breakpoints.b560 - 1}px) {
        ${props =>
            props.position === LayerButtonPosition.FLYOUT &&
            css`
                right: auto;
                padding: 0
                    calc(
                        (
                                ${props.theme.size.grid002} -
                                    ${props.theme.size.static350}
                            ) / 2
                    );
            `}
    }

    @media (min-width: ${Breakpoints.b560}px) {
        ${props =>
            props.position === LayerButtonPosition.FLYOUT &&
            css`
                right: calc(
                    ${props.theme.size.grid009} +
                        ${getPosition(props.theme, props.position).top}
                );
            `}
    }

    ${props =>
        props.position === LayerButtonPosition.FLYOUT_V2 &&
        getFlyoutV2Style(props.theme)}
`;

export const LayerCloseButton: React.FunctionComponent<LayerButtonProps> = ({
    onExited,
    in: inProp,
    position,
    theme,
    onClick,
    btnCloseLabel
}) => (
    // Transition currently needed without using it, because layer manager
    // puts this as a direct child into LayerWrapper, which expects
    // Transitions as children.
    // Can be removed if we stop using the closeButton prop on layers,
    // or if the rendering is adjusted in the layer manager.
    // Because the Transition needs to be the direct child in the Layer
    // Manager, we need to pass in the theme, instead of wrapping the
    // component inside a ThemeProvider, as we usually would.
    <CSSTransition onExited={onExited} in={inProp} timeout={0}>
        <ThemeProvider theme={theme}>
            <StyledPositioning
                position={position || LayerButtonPosition.TOP_RIGHT}
            >
                <CTA
                    tag="button"
                    onClick={onClick}
                    ariaLabel={btnCloseLabel}
                    emphasis="tertiary"
                    icon={<Close ariaHidden />}
                />
            </StyledPositioning>
        </ThemeProvider>
    </CSSTransition>
);
LayerCloseButton.displayName = 'LayerCloseButton';

const StyledPositioningWrapper = styled.div<StyledPositioningProps>`
    position: absolute;
    inset-inline-end: 0;
    ${props =>
        props.position === LayerButtonPosition.TOP_RIGHT &&
        `top: ${props.theme.size.static150}`};
    padding-inline-end: ${props => props.theme.size.static150};
`;

export const LayerCloseButtonV2: React.FunctionComponent<LayerButtonProps> = ({
    onExited,
    in: inProp,
    position,
    theme,
    onClick,
    btnCloseLabel
}) => (
    // Transition currently needed without using it, because layer manager
    // puts this as a direct child into LayerWrapper, which expects
    // Transitions as children.
    // Can be removed if we stop using the closeButton prop on layers,
    // or if the rendering is adjusted in the layer manager.
    // Because the Transition needs to be the direct child in the Layer
    // Manager, we need to pass in the theme, instead of wrapping the
    // component inside a ThemeProvider, as we usually would.
    <CSSTransition onExited={onExited} in={inProp} timeout={0}>
        <ThemeProvider theme={theme}>
            <StyledPositioningWrapper
                position={position || LayerButtonPosition.TOP_RIGHT}
            >
                <CTA
                    icon={<Close ariaHidden variant="small" />}
                    tag="button"
                    type="button"
                    key="close"
                    onClick={onClick}
                    emphasis="secondary"
                    aria-label={btnCloseLabel}
                    size="small"
                />
            </StyledPositioningWrapper>
        </ThemeProvider>
    </CSSTransition>
);
