export interface CancelablePromise<T> {
    readonly promise: Promise<T>;
    readonly cancel: () => void;
}

export function makeCancelable<T>(
    promise: PromiseLike<T>
): CancelablePromise<T> {
    let cancelled = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise.then(
            value => !cancelled && resolve(value),
            reason => !cancelled && reject(reason)
        );
    });

    return {
        promise: wrappedPromise,
        cancel: () => {
            cancelled = true;
        }
    };
}
