import * as React from 'react';

import styled from 'styled-components';
import {TableParsysModel} from '../../../generated/core';
import {ContainerItem} from '../../infrastructure/ContainerExporter';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {getDnDContainer} from '../../utils/container/getDnDContainer';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {TableRowElement} from '../editorial/elements/table/TableRowElement';

export const RESOURCE_TYPE = 'vwa-ngw18/components/structure/tableParsys';

interface StyledTableParsysProps {
    childrenCount: number;
}
const StyledTableParsys = styled.div<StyledTableParsysProps>`
    display: flex;
    flex-direction: column;
    border: 2px solid ${props => props.theme.border.color.secondary};
`;

export interface TableParsysExtraProps {
    columnsCount: number;
    narrowColumns?: boolean;
    updateTableInAuthorCallback?(): void;
}

class TableParsysClass extends React.PureComponent<
    TableParsysExtraProps & TableParsysModel
> {
    public render(): React.ReactNode {
        const dndContainer = getDnDContainer(RESOURCE_TYPE, this.props);
        const rows = getChildItems(this.props);

        return (
            <StyledTableParsys childrenCount={rows.length}>
                {rows.map((child: ContainerItem) => {
                    return (
                        <TableRowElement
                            key={child.key}
                            path={child.key}
                            columnsCount={this.props.columnsCount}
                            updateTableInAuthorCallback={
                                this.props.updateTableInAuthorCallback
                            }
                            narrowColumns={this.props.narrowColumns}
                        />
                    );
                })}
                {dndContainer}
            </StyledTableParsys>
        );
    }
}

export const TableParsys = MapTo<TableParsysExtraProps>(
    RESOURCE_TYPE,
    TableParsysClass
);
