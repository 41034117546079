import * as React from 'react';
import ReactDOM from 'react-dom';
import {css, keyframes} from 'styled-components';

import {
    buttonFocusStateCSS,
    getElectricTransition,
    makeClip,
    resetButtonStyles
} from '../helpers';
import {ArrowKeys} from '../video-player-helpers/types';
import * as horizontal from './horizontal';
import {ProgressBarEventData} from './utils';
import * as vertical from './vertical';
import {styled} from '@volkswagen-onehub/components-core';

export interface VideoPlayerProgressBarSelectedValue {
    readonly value: number;
}

export interface VideoPlayerProgressBarProps {
    readonly value: number;
    readonly valueMax: number;
    readonly valueMin: number;
    readonly disableHoverEffect?: boolean;
    readonly hideScrubberOnLostHover?: boolean;
    readonly progressBarAriaLabel?: string;
    readonly scrubberDisabled?: boolean;
    readonly horizontalProgressBarValueText?: string;
    readonly fillProgress?: boolean;
    readonly scrubberBaseSize?: number;
    readonly scrubberDraggedSize?: number;
    readonly enablePulsing?: boolean;
    onDragChange?(data: VideoPlayerProgressBarSelectedValue): void;
    onDragStart?(data: VideoPlayerProgressBarSelectedValue): void;
    onDragEnd?(data: VideoPlayerProgressBarSelectedValue): void;
    onProgressBarKeyDown?(key: ArrowKeys, shiftKey: boolean): void;
    onVolumeBarKeyDown?(e: React.KeyboardEvent<HTMLDivElement>): void;
    onFocus?(): void;
    onBlur?(): void;
}

export interface VideoPlayerProgressBarState {
    readonly playProgressPercentagePosition?: number;
    readonly hoverPercentagePosition?: number;
    readonly scrubberIsBeingDragged?: boolean;
}

interface StyledProgressBarProps {
    readonly scrubberIsBeingDragged?: boolean;
    readonly hideScrubberOnLostHover?: boolean;
    readonly scrubberBaseSize?: number;
    readonly scrubberDraggedSize?: number;
}

type Orientation = 'vertical' | 'horizontal';

interface ProgressBarElementProps {
    readonly percentagePosition?: number;
    readonly fillProgress?: boolean;
}

interface StyledHoverProgressProps extends ProgressBarElementProps {
    readonly visible: boolean;
}

interface StyledScrubberButtonProps extends ProgressBarElementProps {
    readonly orientation: Orientation;
    readonly isBeingDragged: boolean;
    readonly enablePulsing?: boolean;
    readonly scrubberBaseSize?: number;
}

const HOVER_PROGRESS_Z_INDEX = 1;
const PLAY_PROGRESS_Z_INDEX = HOVER_PROGRESS_Z_INDEX + 1;
const SCRUBBER_BTN_Z_INDEX = PLAY_PROGRESS_Z_INDEX + 1;
const MIN_PERCENTAGE_POSITION = 0;
const MAX_PERCENTAGE_POSITION = 100;

const PROGRESS_BAR_SIZE = '24px';
const PROGRESS_BAR_LINE_SIZE = '2px';
const SCRUBBER_BASE_SIZE = 12;
const SCRUBBER_DRAGGED_SIZE = 16;

export const getScrubberBaseSize = (
    isDragged: boolean = false,
    lostOnHover: boolean = false,
    scrubberBaseSize?: number,
    scrubberDraggedSize?: number
) => {
    const currentScrubberBaseSize = scrubberBaseSize || SCRUBBER_BASE_SIZE;
    const currentScrubberDraggedSize =
        scrubberDraggedSize || SCRUBBER_DRAGGED_SIZE;

    if (isDragged) {
        return currentScrubberDraggedSize / currentScrubberBaseSize;
    }

    if (lostOnHover) {
        return 1 / currentScrubberBaseSize;
    }

    return 1;
};

const progressBarHover = css<StyledProgressBarProps>`
    &:hover {
        --scrubber-btn-size: ${props =>
            getScrubberBaseSize(
                props.scrubberIsBeingDragged,
                props.hideScrubberOnLostHover,
                props.scrubberBaseSize,
                props.scrubberDraggedSize
            )};
    }
`;

const StyledProgressBar = styled.div<StyledProgressBarProps>`
    :not(:focus-within) {
        --scrubber-btn-size: ${props =>
            getScrubberBaseSize(
                props.scrubberIsBeingDragged,
                props.hideScrubberOnLostHover,
                props.scrubberBaseSize,
                props.scrubberDraggedSize
            )};
    }
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    ${props => props.hideScrubberOnLostHover && progressBarHover};
    touch-action: pan-x;
`;

const StyledHorizontalProgressBar = styled(StyledProgressBar)`
    width: 100%;
    height: ${PROGRESS_BAR_SIZE};
    justify-content: flex-start;
`;
StyledHorizontalProgressBar.displayName = 'StyledHorizontalProgressBar';

const StyledVerticalProgressBar = styled(StyledProgressBar)`
    width: ${PROGRESS_BAR_SIZE};
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
`;

StyledVerticalProgressBar.displayName = 'StyledVerticalProgressBar';

const StyledProgressList = styled.div`
    flex: 1;
    background: ${props => props.theme.colors.button.primary.default};
    pointer-events: none;
`;
StyledProgressList.displayName = 'StyledProgressList';

const StyledHorizontalProgressList = styled(StyledProgressList)`
    height: ${PROGRESS_BAR_LINE_SIZE};
    display: flex;
    justify-content: flex-start;
`;

const StyledVerticalProgressList = styled(StyledProgressList)`
    width: ${PROGRESS_BAR_LINE_SIZE};
    display: flex;
    align-items: flex-end;
`;

const StyledPlayProgress = styled.div<ProgressBarElementProps>`
    position: relative;
    z-index: ${PLAY_PROGRESS_Z_INDEX};
    ${props =>
        props.fillProgress &&
        `background: ${props.theme.colors.button.primary.active};`}
`;

const StyledHorizontalPlayProgress = styled(StyledPlayProgress).attrs(
    ({percentagePosition}: ProgressBarElementProps) => ({
        style: {
            width: `${percentagePosition}%`
        }
    })
)<ProgressBarElementProps>`
    height: 100%;
`;

const StyledVerticalPlayProgress = styled(StyledPlayProgress).attrs(
    ({percentagePosition}: StyledHoverProgressProps) => ({
        style: {
            height: `${percentagePosition}%`
        }
    })
)<ProgressBarElementProps>`
    width: 100%;
`;

const StyledHoverProgress = styled.div<StyledHoverProgressProps>`
    position: absolute;
    z-index: ${HOVER_PROGRESS_Z_INDEX};
    display: ${props => (props.visible ? 'block' : 'none')};
    pointer-events: none;
    ${props =>
        props.fillProgress &&
        `background: ${props.theme.colors.button.primary.hover};`}
`;

const StyledHorizontalHoverProgress = styled(StyledHoverProgress).attrs(
    ({percentagePosition}: StyledHoverProgressProps) => ({
        style: {
            width: `${percentagePosition}%`
        }
    })
)<StyledHoverProgressProps>`
    height: ${PROGRESS_BAR_LINE_SIZE};
`;

const StyledVerticalHoverProgress = styled(StyledHoverProgress).attrs(
    ({percentagePosition}: StyledHoverProgressProps) => ({
        style: {
            height: `${percentagePosition}%`
        }
    })
)<StyledHoverProgressProps>`
    width: ${PROGRESS_BAR_LINE_SIZE};
`;

const getScrubberPosition = (
    orientation: Orientation,
    percentagePosition: number = 0
) =>
    orientation === 'vertical'
        ? {bottom: `${percentagePosition}%`}
        : {left: `${percentagePosition}%`};

const getScrubberTranslate = (orientation: Orientation) =>
    `translate3d(${orientation !== 'vertical' ? '-50%' : '0'}, ${
        orientation === 'vertical' ? '50%' : '0'
    }, 0)`;

const pulse = keyframes`
  from {
    transform: scale(1);
    opacity: .2;
  }
  to {
    transform: scale(2.5);
    opacity: 0;
  }
`;

// Note: It's important for Safari to scale down not up in order to avoid blurry effect and use translateZ(0) to remain
// performing well
const StyledScrubberButton = styled.button.attrs(
    ({orientation, percentagePosition}: StyledScrubberButtonProps) => ({
        style: {
            ...getScrubberPosition(orientation, percentagePosition)
        }
    })
)<StyledScrubberButtonProps>`
    ${resetButtonStyles}
    position: absolute;
    z-index: ${SCRUBBER_BTN_Z_INDEX};
    width: ${props => props.scrubberBaseSize || SCRUBBER_BASE_SIZE}px;
    height: ${props => props.scrubberBaseSize || SCRUBBER_BASE_SIZE}px;
    border-radius: 50%;
    transform: ${props => getScrubberTranslate(props.orientation)}
        scale(var(--scrubber-btn-size), var(--scrubber-btn-size));
    transform-origin: center center;
    backface-visibility: hidden;
    ${getElectricTransition('transform')};
    ${props => buttonFocusStateCSS(props)}
    ${props =>
        props.fillProgress
            ? `background: ${props.theme.colors.button.primary.active};`
            : `background: ${props.theme.colors.button.primary.default};`}
    ${props =>
        props.enablePulsing &&
        css`
            &:not(:active)::before {
                animation: 2s linear infinite ${pulse};
                background-color: ${props.theme.colors.button.primary.default};
                border-radius: 50%;
                box-sizing: border-box;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: ${props.scrubberBaseSize || SCRUBBER_BASE_SIZE}px;
                width: ${props.scrubberBaseSize || SCRUBBER_BASE_SIZE}px;
                transform-origin: 50% 50%;
                transition: background-color
                    ${props.theme.animation.duration.duration300}
                    ${props.theme.animation.timingFunction.in};
            }
        `}
`;

const StyledValueText = styled.span`
    ${makeClip()}
`;

export interface VideoPlayerProgressBarStateProviderProps
    extends VideoPlayerProgressBarProps {
    children(
        playProgressPercentagePosition: number,
        hoverPercentagePosition: number,
        scrubberIsBeingDragged: boolean,
        progressBarElement: React.RefObject<HTMLDivElement>,
        handleMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void,
        handleMouseOut: () => void,
        handleProgressBarMouseDown: (
            e: React.MouseEvent<HTMLDivElement>
        ) => void,
        handleProgressBarTouchStart: (
            e: React.TouchEvent<HTMLDivElement>
        ) => void,
        handleProgressOnKeyDown: (
            e: React.KeyboardEvent<HTMLDivElement>
        ) => void
    ): JSX.Element;
    calcOffsetSizeRatio(e: React.MouseEvent<HTMLDivElement>): number;
    getProgressBarMouseDownEventData(
        e: React.MouseEvent<HTMLDivElement>,
        rect: ClientRect
    ): ProgressBarEventData;
    getClientValue(e: MouseEvent): number;
    getClientTouchValue(e: TouchEvent): number;
    getProgressBarSize(el: Element): number;
    isBelowMin(clientValue: number, rect: ClientRect): boolean;
    isAboveMax(clientValue: number, rect: ClientRect): boolean;
    getTouchData(e: React.TouchEvent<HTMLElement>): ProgressBarEventData;
    calcDelta(clientValue: number, prevClientValue: number): number;
}

export class VideoPlayerProgressBarStateProvider extends React.Component<
    VideoPlayerProgressBarStateProviderProps,
    VideoPlayerProgressBarState
> {
    private readonly progressBarElement: React.RefObject<HTMLDivElement>;

    // Following variables are used for preserving the state between different
    // event renders. They don't need to be part of the component state and cause
    // re-render. That's why they are created as instance variables.
    private capturedClientValue?: number;

    public constructor(props: VideoPlayerProgressBarStateProviderProps) {
        super(props);
        this.state = {
            playProgressPercentagePosition: undefined,
            hoverPercentagePosition: undefined,
            scrubberIsBeingDragged: false
        };
        this.progressBarElement = React.createRef();
    }

    private readonly handleMouseMove = (
        e: React.MouseEvent<HTMLDivElement>
    ): void => {
        const {calcOffsetSizeRatio} = this.props;
        const hoverPercentagePosition = calcOffsetSizeRatio(e) * 100;
        if (!this.state.scrubberIsBeingDragged) {
            this.setState({
                hoverPercentagePosition
            });
        }
    };

    private readonly registerMouseEventListeners = () => {
        // listeners are bound to document so that element can be dragged while mouse out of container
        document.addEventListener(
            'mousemove',
            this.handleScrubberMouseDrag,
            true
        );
        document.addEventListener('mouseup', this.handleScrubberMouseUp, true);
    };

    private readonly removeMouseEventListeners = () => {
        document.removeEventListener(
            'mousemove',
            this.handleScrubberMouseDrag,
            true
        );
        document.removeEventListener(
            'mouseup',
            this.handleScrubberMouseUp,
            true
        );
    };

    private readonly registerTouchEventListeners = () => {
        // listeners are bound to document so that element can be dragged while finger out of container
        document.addEventListener(
            'touchmove',
            this.handleScrubberTouchDrag,
            true
        );
        document.addEventListener(
            'touchend',
            this.handleScrubberTouchEnd,
            true
        );
    };

    private readonly removeTouchEventListeners = () => {
        document.removeEventListener(
            'touchmove',
            this.handleScrubberTouchDrag,
            true
        );
        document.removeEventListener(
            'touchend',
            this.handleScrubberTouchEnd,
            true
        );
    };

    private readonly handleProgressBarMouseDown = (
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const el = ReactDOM.findDOMNode(this.progressBarElement.current);
        if (el instanceof Element) {
            const rect = el.getBoundingClientRect();
            const {
                clientValue,
                offsetValue
            } = this.props.getProgressBarMouseDownEventData(e, rect);

            this.handleScrubberDragStart(
                offsetValue,
                clientValue,
                this.registerMouseEventListeners
            );
        }
    };

    private readonly handleProgressBarKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        const {key, shiftKey} = event;

        if (key === ArrowKeys.ArrowLeft || key === ArrowKeys.ArrowRight) {
            event.preventDefault();
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            return this.props.onProgressBarKeyDown?.(key, shiftKey);
        }
    };

    private readonly calcValue = (fraction: number) => {
        const {valueMin, valueMax} = this.props;

        return (valueMax - valueMin) * fraction;
    };

    private readonly calcValueFromPercentage = (percentage: number) =>
        this.calcValue(percentage / 100);

    private readonly getPlayProgressPosition = () => {
        const {value, valueMax} = this.props;
        const {playProgressPercentagePosition} = this.state;

        const temp = playProgressPercentagePosition || (value / valueMax) * 100;

        if (temp <= 0) {
            return 0;
        }

        if (temp >= 100) {
            return 100;
        }

        return temp;
    };

    private readonly getPlayProgressValue = () =>
        this.calcValue(this.getPlayProgressPosition() / 100);

    private readonly handleMouseOut = (): void => {
        this.setState({
            hoverPercentagePosition: undefined
        });
    };

    private readonly handleScrubberMouseDrag = (e: MouseEvent) => {
        e.preventDefault();
        const clientValue = this.props.getClientValue(e);
        this.handleScrubberDrag(clientValue);
    };

    private readonly handleScrubberMouseUp = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.handleScrubberDragEnd(this.removeMouseEventListeners);
    };

    private readonly handleProgressBarTouchStart = (
        e: React.TouchEvent<HTMLDivElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();

        const {offsetValue, clientValue} = this.props.getTouchData(e);
        this.handleScrubberDragStart(
            offsetValue,
            clientValue,
            this.registerTouchEventListeners
        );
    };

    private readonly handleScrubberTouchDrag = (e: TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const clientValue = this.props.getClientTouchValue(e);
        this.handleScrubberDrag(clientValue);
    };

    private readonly handleScrubberTouchEnd = (e: TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.handleScrubberDragEnd(this.removeTouchEventListeners);
    };

    private readonly handleScrubberDragStart = (
        offsetValue: number,
        clientValue: number,
        registerEventListeners: () => void
    ) => {
        const progressBarElement = ReactDOM.findDOMNode(
            this.progressBarElement.current
        );
        if (
            progressBarElement instanceof HTMLDivElement &&
            !this.state.scrubberIsBeingDragged
        ) {
            // jump to cursor position first
            const size = this.props.getProgressBarSize(progressBarElement);
            const position = (offsetValue / size) * 100;
            this.setState({
                scrubberIsBeingDragged: true,
                playProgressPercentagePosition: position
            });

            // initialize drag helper variables
            this.capturedClientValue = clientValue;

            registerEventListeners();

            if (this.props.onDragStart) {
                this.props.onDragStart({
                    value: this.calcValueFromPercentage(position)
                });
            }

            // execute callback when set
            if (this.props.onDragChange) {
                this.props.onDragChange({
                    value: this.calcValueFromPercentage(position)
                });
            }
        }
    };

    private readonly handleScrubberDrag = (clientValue: number) => {
        const progressBarElement = ReactDOM.findDOMNode(
            this.progressBarElement.current
        );
        if (
            progressBarElement instanceof HTMLDivElement &&
            this.capturedClientValue &&
            this.state.scrubberIsBeingDragged
        ) {
            // calculate new progress prosition
            const rect = progressBarElement.getBoundingClientRect();
            const currentPlayProgressPosition = this.getPlayProgressPosition();

            if (this.props.isBelowMin(clientValue, rect)) {
                // when quickly dragging to left set min. value
                if (currentPlayProgressPosition > MIN_PERCENTAGE_POSITION) {
                    this.setState({
                        playProgressPercentagePosition: MIN_PERCENTAGE_POSITION
                    });
                    if (this.props.onDragChange) {
                        this.props.onDragChange({
                            value: this.props.valueMin
                        });
                    }

                    return;
                }
                // when cursor or finger far to the left ignore value change
                if (currentPlayProgressPosition === MIN_PERCENTAGE_POSITION) {
                    return;
                }
            }

            if (this.props.isAboveMax(clientValue, rect)) {
                // when quickly dragging to right set max. value
                if (currentPlayProgressPosition < MAX_PERCENTAGE_POSITION) {
                    this.setState({
                        playProgressPercentagePosition: MAX_PERCENTAGE_POSITION
                    });
                    if (this.props.onDragChange) {
                        this.props.onDragChange({
                            value: this.props.valueMax
                        });
                    }

                    return;
                }
                // when cursor or finger far to the right ignore value change
                if (currentPlayProgressPosition === MAX_PERCENTAGE_POSITION) {
                    return;
                }
            }

            // calculate new play progress percentage position
            const size = this.props.getProgressBarSize(
                ReactDOM.findDOMNode(this.progressBarElement.current) as Element
            );
            const deltaProgress =
                (this.props.calcDelta(clientValue, this.capturedClientValue) /
                    size) *
                100;
            const newProgressPosition =
                currentPlayProgressPosition + deltaProgress;

            // only update position when something changed to prevent unnecessary redraws
            if (
                newProgressPosition >= MIN_PERCENTAGE_POSITION &&
                newProgressPosition <= MAX_PERCENTAGE_POSITION &&
                currentPlayProgressPosition !== newProgressPosition
            ) {
                this.setState({
                    playProgressPercentagePosition: newProgressPosition
                });
                if (this.props.onDragChange) {
                    this.props.onDragChange({
                        value: this.calcValue(newProgressPosition / 100)
                    });
                }
            }

            // update temporary variables
            this.capturedClientValue = clientValue;
        }
    };

    private readonly handleScrubberDragEnd = (
        removeEventListeners: () => void
    ) => {
        if (this.state.scrubberIsBeingDragged) {
            this.setState({
                scrubberIsBeingDragged: false,
                playProgressPercentagePosition: undefined
            });

            // clean drag helper variables
            this.capturedClientValue = undefined;

            if (this.props.onDragEnd) {
                this.props.onDragEnd({
                    value: this.getPlayProgressValue()
                });
            }

            // remove listeners
            removeEventListeners();
        }
    };

    public componentWillUnmount(): void {
        // clear event listeners on un-mount to avoid memory leak
        this.removeMouseEventListeners();
        this.removeTouchEventListeners();
    }

    public render(): JSX.Element {
        const {
            hoverPercentagePosition = 0,
            scrubberIsBeingDragged = false
        } = this.state;
        const playProgressPercentagePosition = this.getPlayProgressPosition();

        return this.props.children(
            playProgressPercentagePosition,
            hoverPercentagePosition,
            scrubberIsBeingDragged,
            this.progressBarElement,
            this.handleMouseMove,
            this.handleMouseOut,
            this.handleProgressBarMouseDown,
            this.handleProgressBarTouchStart,
            this.handleProgressBarKeyDown
        );
    }
}

export type VideoPlayerHorizontalProgressBarState = Readonly<{
    isProgressBarFocused: boolean;
}>;

export class HorizontalProgressBar extends React.PureComponent<
    VideoPlayerProgressBarProps,
    VideoPlayerHorizontalProgressBarState
> {
    public constructor(props: VideoPlayerProgressBarProps) {
        super(props);
        this.state = {
            isProgressBarFocused: false
        };
    }

    private onProgressBarFocus() {
        this.setState({isProgressBarFocused: true});
        return this.props.onFocus?.();
    }

    private onProgressBarBlur() {
        this.setState({isProgressBarFocused: false});
        return this.props.onBlur?.();
    }

    public render(): JSX.Element {
        const {
            disableHoverEffect = false,
            fillProgress = true,
            hideScrubberOnLostHover,
            horizontalProgressBarValueText,
            progressBarAriaLabel,
            scrubberBaseSize,
            scrubberDisabled,
            scrubberDraggedSize,
            enablePulsing,
            value,
            valueMax,
            valueMin,
            onDragChange,
            onDragEnd,
            onDragStart,
            onProgressBarKeyDown
        } = this.props;

        return (
            <VideoPlayerProgressBarStateProvider
                value={value}
                valueMin={valueMin}
                valueMax={valueMax}
                onDragChange={onDragChange}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                calcOffsetSizeRatio={horizontal.calcOffsetSizeRatio}
                getProgressBarMouseDownEventData={
                    horizontal.getProgressBarMouseDownEventData
                }
                getClientValue={horizontal.getClientValue}
                getClientTouchValue={horizontal.getClientTouchValue}
                getProgressBarSize={horizontal.getProgressBarSize}
                isBelowMin={horizontal.isBelowMin}
                isAboveMax={horizontal.isAboveMax}
                getTouchData={horizontal.getTouchData}
                calcDelta={horizontal.calcDelta}
                onProgressBarKeyDown={onProgressBarKeyDown}
            >
                {(
                    playProgressPercentagePosition,
                    hoverPercentagePosition,
                    scrubberIsBeingDragged,
                    progressBarElement,
                    handleMouseMove,
                    handleMouseOut,
                    handleProgressBarMouseDown,
                    handleProgressBarTouchStart,
                    handleProgressBarKeyDown
                ) => (
                    <StyledHorizontalProgressBar
                        hideScrubberOnLostHover={hideScrubberOnLostHover}
                        ref={progressBarElement}
                        scrubberIsBeingDragged={scrubberIsBeingDragged}
                        scrubberBaseSize={scrubberBaseSize}
                        scrubberDraggedSize={scrubberDraggedSize}
                        onMouseMove={handleMouseMove}
                        onMouseOut={handleMouseOut}
                        onMouseDown={handleProgressBarMouseDown}
                        onTouchStart={handleProgressBarTouchStart}
                        onKeyDown={handleProgressBarKeyDown}
                    >
                        <StyledHorizontalProgressList>
                            <StyledHorizontalPlayProgress
                                fillProgress={fillProgress}
                                percentagePosition={
                                    playProgressPercentagePosition
                                }
                            />
                            {!disableHoverEffect && (
                                <StyledHorizontalHoverProgress
                                    fillProgress={fillProgress}
                                    visible={Boolean(
                                        hoverPercentagePosition &&
                                            hoverPercentagePosition >
                                                playProgressPercentagePosition
                                    )}
                                    percentagePosition={hoverPercentagePosition}
                                />
                            )}
                        </StyledHorizontalProgressList>
                        <StyledScrubberButton
                            orientation="horizontal"
                            role="slider"
                            aria-label={progressBarAriaLabel}
                            aria-valuemin={valueMin}
                            aria-valuemax={valueMax}
                            aria-valuenow={value}
                            aria-valuetext={horizontalProgressBarValueText}
                            enablePulsing={enablePulsing}
                            fillProgress={fillProgress}
                            scrubberBaseSize={scrubberBaseSize}
                            percentagePosition={playProgressPercentagePosition}
                            isBeingDragged={scrubberIsBeingDragged}
                            disabled={scrubberDisabled}
                            onFocus={() => this.onProgressBarFocus()}
                            onBlur={() => this.onProgressBarBlur()}
                        />
                        {this.state.isProgressBarFocused && (
                            <StyledValueText aria-live="polite">
                                {horizontalProgressBarValueText}
                            </StyledValueText>
                        )}
                    </StyledHorizontalProgressBar>
                )}
            </VideoPlayerProgressBarStateProvider>
        );
    }
}

export class VerticalProgressBar extends React.PureComponent<
    VideoPlayerProgressBarProps
> {
    public render(): JSX.Element {
        const {
            value,
            valueMin,
            valueMax,
            disableHoverEffect = false,
            onDragChange,
            onDragEnd,
            onDragStart,
            hideScrubberOnLostHover,
            progressBarAriaLabel,
            onVolumeBarKeyDown,
            scrubberDisabled
        } = this.props;

        return (
            <VideoPlayerProgressBarStateProvider
                value={value}
                valueMin={valueMin}
                valueMax={valueMax}
                onDragChange={onDragChange}
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                calcOffsetSizeRatio={vertical.calcOffsetSizeRatio}
                getProgressBarMouseDownEventData={
                    vertical.getProgressBarMouseDownEventData
                }
                getClientValue={vertical.getClientValue}
                getClientTouchValue={vertical.getClientTouchValue}
                getProgressBarSize={vertical.getProgressBarSize}
                isBelowMin={vertical.isBelowMin}
                isAboveMax={vertical.isAboveMax}
                getTouchData={vertical.getTouchData}
                calcDelta={vertical.calcDelta}
            >
                {(
                    playProgressPercentagePosition,
                    hoverPercentagePosition,
                    scrubberIsBeingDragged,
                    progressBarElement,
                    handleMouseMove,
                    handleMouseOut,
                    handleProgressBarMouseDown,
                    handleProgressBarTouchStart
                ) => (
                    <StyledVerticalProgressBar
                        scrubberIsBeingDragged={scrubberIsBeingDragged}
                        ref={progressBarElement}
                        onMouseMove={handleMouseMove}
                        onMouseOut={handleMouseOut}
                        onMouseDown={handleProgressBarMouseDown}
                        onTouchStart={handleProgressBarTouchStart}
                        hideScrubberOnLostHover={hideScrubberOnLostHover}
                        aria-label={progressBarAriaLabel}
                        onKeyDown={onVolumeBarKeyDown}
                    >
                        <StyledVerticalProgressList>
                            <StyledVerticalPlayProgress
                                percentagePosition={
                                    playProgressPercentagePosition
                                }
                            />
                        </StyledVerticalProgressList>
                        <StyledScrubberButton
                            disabled={scrubberDisabled}
                            orientation="vertical"
                            percentagePosition={playProgressPercentagePosition}
                            isBeingDragged={scrubberIsBeingDragged}
                        />
                        {!disableHoverEffect && (
                            <StyledVerticalHoverProgress
                                visible={Boolean(
                                    hoverPercentagePosition &&
                                        hoverPercentagePosition >
                                            playProgressPercentagePosition
                                )}
                                percentagePosition={hoverPercentagePosition}
                            />
                        )}
                    </StyledVerticalProgressBar>
                )}
            </VideoPlayerProgressBarStateProvider>
        );
    }
}
