import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {MediaGalleryItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {HighlightGallery} from '../../../components/highlight-feature/HighlightGallery';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {Parsys, ParsysType} from '../../structure/Parsys';

const MEDIA_PARSYS = 'mediaGalleryParsys';

function AuthorView(): JSX.Element {
    return (
        <AuthoringWrapper
            title="I107 Media Gallery Item"
            bgColor={AuthoringWrapper.BG_COLOR_ITEM}
        >
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={{
                        left: ContainerPadding.grid002,
                        right: ContainerPadding.grid002
                    }}
                    stretchContent
                >
                    <Parsys
                        path={MEDIA_PARSYS}
                        parsysType={ParsysType.ITEM_PARSYS}
                    />
                </Container>
            </Layout>
        </AuthoringWrapper>
    );
}

function PublishView(props: MediaGalleryItemModel): JSX.Element {
    const {cqPath, contentName, contentLabels, translations, contentId} = props;

    return (
        <HighlightGallery
            carouselId={`${contentId}-carousel`}
            mediaParsysPath={`${cqPath}/${MEDIA_PARSYS}`}
            contentName={contentName}
            contentLabels={contentLabels}
            isShown={true}
            previewAriaLabel={translations['carFeature.previewAriaLabel']}
            slideXOfYLabel={translations['carousel.slideXOfYLabel']}
            galleryLabel={translations['carousel.galleryLabel']}
            goToNextSlideLabel={translations['carousel.goToNextSlideLabel']}
            goToPreviousSlideLabel={
                translations['carousel.goToPreviousSlideLabel']
            }
            carouselItemLabel={translations['moodGallery.carouselItemLabel']}
        />
    );
}

function InternalMediaGalleryItem(props: MediaGalleryItemModel): JSX.Element {
    const MediaGallery = C.isInEditor() ? AuthorView : PublishView;

    return <MediaGallery {...props} />;
}

export const MediaGalleryItem = MapTo(
    'vwa-ngw18/components/editorial/items/mediaGalleryItem',
    InternalMediaGalleryItem
);
