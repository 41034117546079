export function processMboxId(
    cqPath?: string | null,
    usedAsContentId: boolean = false
): string {
    if (!cqPath) {
        return '';
    }

    // (mostly) unique hash from full resource path
    let resultId = hashCode(cqPath) + '/';
    if (cqPath.indexOf('experience-fragments') >= 0) {
        // shortened XF path for readability
        resultId += processXfPath(cqPath);
    } else {
        // shortened static content path for readability
        resultId += processStaticPath(cqPath);
    }

    // contentId is restricted to max 149 chars. Reason: Adobe Analytics doesn't accept strings longer than
    // 250 chars and contentId might be concatenated with a content name of max. 100 chars + delimiter
    resultId = restrictStringLength(resultId, usedAsContentId ? 149 : 250);

    return resultId;
}

export function processContentId(
    cqPath?: string | null,
    contentName?: string | null
): string {
    if (!cqPath) {
        return '';
    }

    // contentId is restricted to max 149 chars. Reason: Adobe Analytics doesn't accept strings longer than
    // 250 chars and contentId might be concatenated with a content name of max. 100 chars + delimiter
    const resultId = processMboxId(cqPath, true);

    // if no contentName is specified, return 'n/a'
    const resultName = contentName
        ? restrictStringLength(contentName, 100)
        : 'n/a';

    return resultId + '~' + resultName;
}

function processXfPath(path: string): string {
    return path
        .replace('/content/experience-fragments/', 'xf/') // remove redundant part
        .replace('/static/', '/') // remove redundant part
        .replace('/personalized/', '/') // remove redundant part
        .replace('/Importers/', '/') // remove redundant part
        .replace('/jcr:content/root/', '/') // remove redundant part
        .replace('.model.json', '') // remove redundant part
        .replace('?wcmmode=disabled', '') // remove redundant part
        .replace(/^\/+/, ''); // remove leading slash
}

function processStaticPath(path: string): string {
    return path
        .replace(/.*jcr:content\/root\/main\//, '') // remove everything up to main comp
        .replace(/content/, '/') // remove redundant part
        .replace('.model.json', '') // remove redundant part
        .replace('?wcmmode=disabled', '') // remove redundant part
        .replace(/^\/+/, ''); // remove leading slash
}

// implementation of Java's String.hashCode()
function hashCode(str: string): string {
    let hash = 0,
        i,
        chr;
    if (str.length === 0) {
        return hash.toString();
    }
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }

    return hash.toString().replace('-', '_');
}

function restrictStringLength(str: string, l: number): string {
    return str.length > l ? str.substr(0, l) : str;
}
