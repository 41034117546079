import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

export type ExpandCollapseElementProps = React.PropsWithChildren<{
    readonly isThreeColumnLayout?: boolean;
    readonly isInLayer?: boolean;
}>;

const StyledItemWrapper = styled.li<
    ExpandCollapseElementProps & {indexRemainder: number}
>`
    grid-column: ${props => (props.isInLayer ? '1/25' : '3/23')};

    @media (min-width: ${Breakpoints.b560}px) {
        grid-column: ${props => `content-${props.indexRemainder}`};
    }
`;

// Explanation of the grid layout
// As the layout consists of either 1, 2 or 3 columns of content, the index is "moduloed" by 6 to derive different namings for the placement of the item in the layout.
// 6 as the modulo value is used because it is the lowest common denominator for 3 or 2 items per row.
// The placement is done via tracks of the naming strucutre "content-x-start/end", where "x" is the indexRemainder (number between 0 - 5) that results from the modulo calculation of the child's index.
// Even though you would not need more than 0 & 1 to name the different columns in the two colum layout, we still need 0-5 to make use of the same calculation for the three column layout.

// Index & modulo result examples for two/three-column layout
// two-column:
// 0 % 6 = 0 | 1 % 6 = 1
// 2 % 6 = 2 | 3 % 6 = 3
// 4 % 6 = 4 | 5 % 6 = 5
// 6 % 6 = 0 | 7 % 6 = 1 // starts again at 0 for the 6th index due to the % 6 calculation

// three-column:
// 0 % 6 = 0 | 1 % 6 = 1 | 2 % 6 = 2
// 3 % 6 = 3 | 4 % 6 = 4 | 5 % 6 = 5
// 6 % 6 = 0 | 7 % 6 = 1 | 8 % 6 = 2

const StyledList = styled.ul<ExpandCollapseElementProps>`
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-row-gap: ${props => props.theme.size.dynamic0130};

    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-columns: ${props =>
            !props.isInLayer
                ? `
                1fr
                [content-0-start content-2-start content-4-start] 10fr [content-0-end content-2-end content-4-end]
                2fr
                [content-1-start content-3-start content-5-start] 10fr [content-1-end content-3-end content-5-end]
                1fr`
                : `
                [content-0-start content-2-start content-4-start] 11fr [content-0-end content-2-end content-4-end]
                2fr
                [content-1-start content-3-start content-5-start] 11fr [content-1-end content-3-end content-5-end]`};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-template-columns: ${props =>
            props.isThreeColumnLayout
                ? !props.isInLayer
                    ? `
                    2fr
                    [content-0-start content-3-start] 6fr [content-0-end content-3-end]
                    1fr
                    [content-1-start content-4-start] 6fr [content-1-end content-4-end]
                    1fr
                    [content-2-start content-5-start] 6fr [content-2-end content-5-end]
                    2fr`
                    : `
                    [content-0-start content-3-start] 8fr [content-0-end content-3-end]
                    1fr
                    [content-1-start content-4-start] 8fr [content-1-end content-4-end]
                    1fr
                    [content-2-start content-5-start] 8fr [content-2-end content-5-end]`
                : !props.isInLayer
                ? `
                    5fr
                    [content-0-start content-2-start content-4-start] 6fr [content-0-end content-2-end content-4-end]
                    2fr
                    [content-1-start content-3-start content-5-start] 6fr [content-1-end content-3-end content-5-end]
                    5fr`
                : `
                    [content-0-start content-2-start content-4-start] 11fr [content-0-end content-2-end content-4-end]
                    2fr
                    [content-1-start content-3-start content-5-start] 11fr [content-1-end content-3-end content-5-end]`};
    }
`;

export const ExpandCollapseModule: React.FunctionComponent<ExpandCollapseElementProps> = (
    props: ExpandCollapseElementProps
) => {
    const {isThreeColumnLayout, isInLayer} = props;

    const childrenArray = React.Children.toArray(props.children);

    return (
        <StyledList
            isThreeColumnLayout={isThreeColumnLayout}
            isInLayer={isInLayer}
        >
            {React.Children.map(childrenArray, (child, index) => (
                <StyledItemWrapper
                    isInLayer={isInLayer}
                    indexRemainder={index % 6}
                >
                    {child}
                </StyledItemWrapper>
            ))}
        </StyledList>
    );
};
