import * as React from 'react';

import {Container, ContainerWrap} from '@volkswagen-onehub/components-core';

import {ExpandCollapseSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {SectionWrapper} from './SectionWrapper';
import {ExpandCollapseSectionParsys} from '../../structure/ExpandCollapseSectionParsys';
import {C} from '../../../registries/compatibilty';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

export function InternalExpandCollapseSection(
    props: ExpandCollapseSectionModel
): JSX.Element {
    const {
        anchorId,
        mboxId,
        contentLabels,
        contentId: sectionId,
        contentName,
        hasThreeColumnLayout,
        emptyImage,
        hasSquaredRatio,
        disableImages
    } = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S132 Expand Collapse Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <SectionWrapper
                    mboxId={mboxId}
                    anchorId={anchorId}
                    sectionId={sectionId}
                    contentLabels={contentLabels}
                    contentName={contentName}
                >
                    {(C.isInEditor() || disableImages || !emptyImage) && (
                        <Container wrap={ContainerWrap.always}>
                            <ExpandCollapseSectionParsys
                                path={'expandCollapseSectionParsys'}
                                hasThreeColumnLayout={hasThreeColumnLayout}
                                hasSquaredRatio={hasSquaredRatio}
                                disableImages={disableImages}
                            />
                        </Container>
                    )}
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </SectionWrapper>
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
}

export const ExpandCollapseSection = MapTo(
    'vwa-ngw18/components/editorial/sections/expandCollapseSection',
    InternalExpandCollapseSection
);
