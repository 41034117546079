import {LoginModel} from '../../../generated/core';
import {
    CustomerType,
    IAuthServiceOptions,
    IAuthServiceOptionsV2,
    IFagScopeMapping,
    IFagScopeMappingV2
} from '@volkswagen-onehub/authservice-config';
import {isRememberMeEnabled} from '../../utils/vwId/isRememberMeEnabled';
import {isSessionTimeoutEnabled} from '../../utils/vwId/isSessionTimeoutEnabled';

// convert cms legal entity mapping to auth service config API
export function createAuthServiceConfig(
    config: LoginModel
): IAuthServiceOptions {
    const legalEntityMappingModel = config.legalEntityMapping;
    const fagMainLegalEntity = config.fagMainLegalEntity;
    const legalEntityMapping: Record<string, IFagScopeMapping> = {};
    if (legalEntityMappingModel) {
        legalEntityMappingModel
            .filter(
                item => !item.customerType || item.customerType === 'private'
            )
            .forEach(
                item =>
                    // market: {vw-de: [scope, ...]}
                    (legalEntityMapping[item.legalEntity] = {
                        fag: item.fag,
                        scopes: item.scopes,
                        mainEntity: item.legalEntity === fagMainLegalEntity
                    })
            );
    }

    return {
        legalEntityMapping,
        sessionTimeout: isSessionTimeoutEnabled(config)
            ? config.sessionTimeout
            : undefined,
        rememberMeSessionTimeout: isRememberMeEnabled(config)
            ? config.rememberMeSessionTimeout
            : undefined
    };
}

// convert cms legal entity mapping to auth service config API v2
export function createAuthServiceConfigV2(
    config: LoginModel
): IAuthServiceOptionsV2 | undefined {
    const legalEntityMappingModel = config.legalEntityMapping;

    // There should be at least one configuration for private customer type. Otherwise the configuration is not valid.
    if (
        !legalEntityMappingModel?.find(
            mapping =>
                !mapping.customerType || mapping.customerType === 'private'
        )
    ) {
        return undefined;
    }
    const fagMainLegalEntity = config.fagMainLegalEntity;
    const legalEntityMapping: Record<string, IFagScopeMappingV2[]> = {};
    if (legalEntityMappingModel) {
        legalEntityMappingModel.forEach(
            item =>
                // market: [{vw-de: [scope, ...]}, {vw-cidk: [scope, ...]}]
                (legalEntityMapping[item.legalEntity] = [
                    ...(legalEntityMapping[item.legalEntity] ?? []),
                    {
                        fag: item.fag,
                        scopes: item.scopes,
                        // for commercial customer type we deactivate the consent precondition verifier by setting the main entity to false. In CIDK there is no consent at all.
                        mainEntity: item.legalEntity === fagMainLegalEntity,
                        customerType: (item.customerType ??
                            'private') as CustomerType
                    }
                ])
        );
    }

    return {
        legalEntityMapping,
        sessionTimeout: isSessionTimeoutEnabled(config)
            ? config.sessionTimeout
            : undefined,
        rememberMeSessionTimeout: isRememberMeEnabled(config)
            ? config.rememberMeSessionTimeout
            : undefined
    };
}
