import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {styled} from '@volkswagen-onehub/components-core';
import {ProfileCircle as ProfileIcon} from '@volkswagen-onehub/icons-core';
import {OrganizationData} from '../../../context/vwid/OrganizationData';
import {UserData} from '../../../context/vwid/UserData';

import {
    useGlobalConfig,
    useLoginFlyoutService,
    useLoginStore,
    useTopBarModel,
    useTrackingService
} from '../../../context';
import {stripEmailDomain} from '../../../utils/stripEmailDomain';
import {StyledTopBarButton} from '../../../d6/components/navigation-top-bar-one-hub-helpers';

const TOPBARICON_INITITALS_FONTSIZE = 10;

const capitalized = (letters: string): string => letters.toUpperCase();

const getUserInitialsFrom = (
    userData: UserData | null,
    organizationData: OrganizationData | null
) => {
    if (userData?.given_name && userData?.family_name) {
        return `${userData.given_name[0]}${userData.family_name[0]}`;
    }

    if (organizationData?.firstName && organizationData?.lastName) {
        return `${organizationData.firstName[0]}${organizationData.lastName[0]}`;
    }

    if (userData?.email) {
        return stripEmailDomain(userData.email)[0];
    }

    if (organizationData?.email) {
        return stripEmailDomain(organizationData.email)[0];
    }

    return;
};

const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.theme.size.static350};
    width: ${props => props.theme.size.static350};
    font-size: ${TOPBARICON_INITITALS_FONTSIZE}px;
    font-weight: bold;
    border-radius: 50%;
    border: 2px solid currentColor;
`;

export const TopBarLoginButton = observer(function TPLB(): JSX.Element | null {
    const spaGlobalConfig = useGlobalConfig();
    const topBarModel = useTopBarModel();
    const trackingService = useTrackingService();
    const {userData, organizationData, isLoggedIn} = useLoginStore();
    const loginFlyoutService = useLoginFlyoutService();

    function handleLoginButtonClick(
        e: React.MouseEvent<HTMLButtonElement>
    ): void {
        e.preventDefault();
        trackingService.trackLoginFlyoutOpenClick(isLoggedIn);
        loginFlyoutService.openFlyout();
    }

    const userInitials = getUserInitialsFrom(userData, organizationData);

    return spaGlobalConfig.loginModel.enabled ? (
        <StyledTopBarButton
            onClick={handleLoginButtonClick}
            aria-label={topBarModel.loginButtonLabel}
        >
            {isLoggedIn && userInitials ? (
                <StyledSpan>{capitalized(userInitials)}</StyledSpan>
            ) : (
                <ProfileIcon ariaHidden />
            )}
        </StyledTopBarButton>
    ) : null;
});
