import * as React from 'react';

import {styled, Breakpoints} from '@volkswagen-onehub/components-core';

import {WrappedLayerFooter} from '../layer-footer/WrappedLayerFooter';
import {LayerHeaderUpdaterModel} from '../../../generated/core';
import {getLayerMetaDataUpdate} from '../../utils/layerHeaderUpdaterUtils';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';

export type ContentLayerProps = React.PropsWithChildren<{
    headline?: React.ReactNode;
    hideFooter?: boolean;
    metaTagData?: LayerHeaderUpdaterModel;
}>;

const ContentLayerWrapper = styled.div`
    padding: 0 ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b960}px) {
        padding: 0 ${props => props.theme.size.grid004};
    }

    // heading
    > div:first-child {
        margin-bottom: ${props => props.theme.size.dynamic0100};
    }
`;

export const ContentLayer: React.FunctionComponent<ContentLayerProps> = (
    props: ContentLayerProps
) => {
    const {children, headline, hideFooter, metaTagData} = props;

    const layerHelmet = getLayerMetaDataUpdate(metaTagData);

    return (
        <GeneralDisclaimerProvider displayTypes={['T2_PAGE_BASED']}>
            <ContentLayerWrapper>
                {layerHelmet}
                {headline}
                {children}
                {!hideFooter && <WrappedLayerFooter />}
            </ContentLayerWrapper>
        </GeneralDisclaimerProvider>
    );
};
