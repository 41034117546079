import {
    defineReactRenderService,
    ShareReactRenderService
} from '@volkswagen-onehub/react-render-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createReactRenderService(hydration: {
    hydrating: boolean;
}): ServiceRegistration<ShareReactRenderService> {
    return {
        definition: defineReactRenderService(() => hydration.hydrating),
        service: {
            typeName: 'ReactRenderService',
            version: '^1.0.0'
        }
    };
}
