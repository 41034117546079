import {
    FeatureServiceProviderDefinition,
    SharedFeatureService
} from '@feature-hub/core';
import {Environment} from '../di/annotations';

export interface Service {
    // typeName used to register service in dependecy injection container
    readonly typeName: string;
    // semver string for getting the correct version of the feature service
    readonly version: string;
    readonly optional?: boolean;
}

export interface ServiceRegistration<T extends SharedFeatureService> {
    // definition for registering service
    readonly definition: FeatureServiceProviderDefinition<T>;

    service?: Service;
    readonly env?: Environment;
}

export const CLIENT_SERVICES: Record<string, Service> = {
    'vw:authService': {
        typeName: 'AuthServiceProvider',
        version: '^2.0.0',
        optional: true
    },
    'vw:authServiceCommercial': {
        typeName: 'AuthServiceProviderCommercial',
        version: '^2.0.0',
        optional: true
    },
    tracking: {
        typeName: 'TrackingManager',
        version: '^1.0.0'
    },
    'user-location-manager': {
        typeName: 'UserLocationManager',
        version: '^1.0.0'
    },
    'mydealer-service': {
        typeName: 'MydealerService',
        version: '^1.0.0'
    },
    'mydealer-content-service': {
        typeName: 'MyDealerContentService',
        version: '^1.0.0'
    },
    'gfa:cart-service': {
        typeName: 'CartFeatureService',
        version: '^1.0.0',
        optional: true
    }
};
