import {createBrowserHistory} from 'history';
import {observable} from 'mobx';
import * as React from 'react';
import {Router} from 'react-router-dom';
import {RouterService} from '../RouterService';

export class ClientRouterService implements Partial<RouterService> {
    @observable
    public pagePath: string = '/de';

    public isAppLink = (url: string): boolean => {
        return !url.startsWith('http');
    };

    public pageResourcePath: string = '/content/onehub_pkw/de/de';

    public wrapWithRouter(element: JSX.Element): JSX.Element {
        return React.createElement(
            Router,
            {history: createBrowserHistory()},
            element
        );
    }
}
