import {TextTag, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {
    HeadingElementModel,
    HeadingOrder,
    HeadingStyle
} from '../../generated/core';
import {Richtext} from './Richtext';

interface HeadingProps extends HeadingElementModel {
    readonly emptyLabel?: string;
    readonly bold?: boolean;
}

/* order defines HTML H1-H5 tag */
const getTag = (order: HeadingOrder): TextTag => {
    switch (order) {
        case 'H1':
            return TextTag.h1;
        case 'H3':
            return TextTag.h3;
        case 'H4':
            return TextTag.h4;
        case 'H5':
            return TextTag.h5;
        case 'H2':
        default:
            return TextTag.h2;
    }
};

/* keeps sematic order on the page - H1 -> H2 -> H3 -> H4 -> H5 -> H6 */
export const getOrderIncreasedByOne = (order: HeadingOrder): HeadingOrder => {
    switch (order) {
        case 'H1':
            return 'H2';
        case 'H2':
            return 'H3';
        case 'H3':
            return 'H4';
        case 'H4':
        case 'H5':
        case 'H6':
        default:
            return 'H5';
    }
};

/* style defines HTML tag font-size */
/* - we can have two H3 with different font-size for example */
const getAppearance = (
    order: HeadingOrder,
    style: HeadingStyle
): TokenTextAppearance => {
    switch (style) {
        /* H0 is for special and very rare styling only - not used as a HTML tag anywhere */
        case 'H0':
            return TokenTextAppearance.headline600;
        case 'H1':
            return TokenTextAppearance.headline500;
        case 'H2':
            return TokenTextAppearance.headline400;
        case 'H3':
            return TokenTextAppearance.headline300;
        case 'H4':
            return TokenTextAppearance.headline200;
        case 'H5':
            return TokenTextAppearance.headline200;
        case 'H6':
            return TokenTextAppearance.headline200;
        case 'NONE':
        default:
            return getAppearance(order, order);
    }
};

export const Heading = (props: HeadingProps): JSX.Element => {
    const {order, style, emptyLabel, bold} = props;
    const tag = getTag(order);
    const appearance = getAppearance(order, style);

    return (
        <Richtext
            {...props}
            placeholder={emptyLabel}
            tag={tag}
            appearance={appearance}
            bold={bold}
        />
    );
};
