import * as React from 'react';
import {useTrackingService} from '../context';
import {useContextTrackingData} from '../hooks/useContextTrackingData';

export interface AccordionStateProviderProps {
    readonly defaultIsOpen?: boolean;
    readonly contentId: string;
    children(isOpen: boolean, onClick: () => void): JSX.Element;
}

export function AccordionStateProvider(
    props: AccordionStateProviderProps
): JSX.Element {
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData();
    const [isOpen, setIsOpen] = React.useState(props.defaultIsOpen || false);

    function onClick(): void {
        const {contentId} = props;
        setIsOpen(!isOpen);
        trackingService.trackAccordionClick(
            contentId,
            !isOpen,
            window.location.pathname,
            undefined,
            trackingData
        );
    }

    return props.children(isOpen, onClick);
}
