import * as React from 'react';

import {Helmet} from 'react-helmet';
import {
    PageMetaDataModel,
    SchemaFaqPageModel,
    SchemaNewsArticleModel,
    SchemaVehicleModel,
    SpaGlobalConfig,
    TwitterModel
} from '../../../generated/core';
import {
    useContentStore,
    useGlobalConfig,
    useImageService,
    usePageInfoService,
    useRouterService
} from '../../context';
import {RouterService} from '../../context/route/RouterService';
import {C} from '../../registries/compatibilty';
import {
    OgModel,
    PageInfo,
    PageInfoServiceV1,
    SeoModel,
    TwitterModel as ApiTwitterModel
} from '@volkswagen-onehub/page-info-service';
import {AspectRatio, ImageServiceV1} from '@volkswagen-onehub/image-service';

type MetaElement = React.ReactElement<'meta'>;
type LinkElement = React.ReactElement<'link'>;

function getSeoTags(seoModel: SeoModel): MetaElement[] {
    const {keywords, description} = seoModel;

    return filterDefined(
        getMetaTagName('keywords', keywords),
        getMetaTagName('description', description)
    );
}

function getUrlLevelTags(
    urlLevels: {[key: string]: string} = {}
): MetaElement[] {
    return Object.keys(urlLevels)
        .map(key => getMetaTagName(key, urlLevels[key]))
        .filter(t => !!t) as MetaElement[];
}

function getMetaTagName(
    key: string,
    content?: string
): MetaElement | undefined {
    if (content) {
        return <meta name={key} key={key} content={content} />;
    }
    return undefined;
}

function getMetaTagProperty(
    key: string,
    content?: string
): MetaElement | undefined {
    if (content) {
        return <meta property={key} key={key} content={content} />;
    }
    return undefined;
}

function getOpenGraphTags(seoModel: SeoModel): MetaElement[] {
    const {og} = seoModel;
    if (!og) {
        return [];
    }

    return filterDefined(
        getMetaTagProperty('og:title', og.ogTitle),
        getMetaTagProperty('og:type', og.ogType),
        getMetaTagProperty('og:url', og.ogUrl),
        getMetaTagProperty('og:image', og.ogImage),
        getMetaTagProperty('og:description', og.ogDescription)
    );
}

function getTwitterTags(twitter?: ApiTwitterModel): MetaElement[] {
    if (!twitter) {
        return [];
    }

    const twitterCardType = twitter.twitterImage
        ? twitter.twitterCard || 'summary_large_image'
        : 'summary';

    return filterDefined(
        getMetaTagName('twitter:title', twitter.twitterTitle),
        getMetaTagName('twitter:card', twitterCardType),
        getMetaTagName('twitter:description', twitter.twitterDescription),
        getMetaTagName('twitter:image', twitter.twitterImage)
    );
}

function getSearchImageTags(twitter: TwitterModel): MetaElement[] {
    if (!twitter) {
        return [];
    }

    return filterDefined(
        getMetaTagName(
            'vw:search:image:scene7file',
            twitter.twitterImageScene7File
        ),
        // not used
        getMetaTagName(
            'vw:search:image:scene7domain',
            twitter.twitterImageScene7Domain
        )
    );
}

function getWcmModeTags(
    routerService: RouterService,
    spaGlobalConfig: SpaGlobalConfig
): MetaElement[] {
    const tags = [
        getMetaTagProperty('cq:page_model_url', routerService.pagePath)
    ];

    if (spaGlobalConfig.author) {
        const dataType = getMetaTagProperty('cq:datatype', 'JSON');
        tags.push(dataType);

        const wcmMode = getMetaTagProperty(
            'cq:wcmmode',
            C.isInEditor() ? 'edit' : 'preview'
        );
        tags.push(wcmMode);
    }

    return filterDefined(...tags);
}

function getSearchMetaTags(
    headerDataModel: PageMetaDataModel,
    seoModel: SeoModel,
    secondaryConsumer: boolean
): MetaElement[] {
    const {vwGsaTitle, vwBreadcrumb} = headerDataModel;

    const gsaTitle = secondaryConsumer ? seoModel.pageTitle : vwGsaTitle;
    const tags = [
        getMetaTagProperty('VW.gsaTitle', gsaTitle),
        getMetaTagProperty('VW:gsaTitle', gsaTitle),
        getMetaTagProperty('VW.breadcrumb', vwBreadcrumb),
        getMetaTagProperty('VW:breadcrumb', vwBreadcrumb)
    ];

    if (seoModel.og?.ogImage) {
        tags.push(getMetaTagProperty('VW.thumbnail', seoModel.og.ogImage));
        tags.push(getMetaTagProperty('VW:thumbnail', seoModel.og.ogImage));
    }

    return filterDefined(...tags);
}

function getPrefetchTags(featureAppsUrls: string[]): LinkElement[] {
    const tags: LinkElement[] = [];

    if (featureAppsUrls.length) {
        featureAppsUrls.forEach(featureAppUrl => {
            const featureAppLink = (
                <link
                    rel="preload"
                    href={featureAppUrl}
                    key={featureAppUrl}
                    as="script"
                    crossOrigin="anonymous"
                />
            );
            tags.push(featureAppLink);
        });
    }

    return tags;
}

function getSchemaSecondaryConsumer(
    schemaSecondaryConsumer: string
): JSX.Element {
    return (
        <script type="application/ld+json">{schemaSecondaryConsumer}</script>
    );
}

function getSchemaFaqPageTag(schemaFaqPage: SchemaFaqPageModel): JSX.Element {
    return (
        <script type="application/ld+json">{`${JSON.stringify(
            schemaFaqPage
        )}`}</script>
    );
}

function getSchemaOrganizationTag(organizationJson: String): JSX.Element {
    return <script type="application/ld+json">{`${organizationJson}`}</script>;
}

function getSchemaVehicleTag(schemaVehicle: SchemaVehicleModel): JSX.Element {
    return (
        <script type="application/ld+json">{`${JSON.stringify(
            schemaVehicle
        )}`}</script>
    );
}

function getSchemaNewsArticleTag(
    schemaNewsArticle: SchemaNewsArticleModel
): JSX.Element {
    return (
        <script type="application/ld+json">{`${JSON.stringify(
            schemaNewsArticle
        )}`}</script>
    );
}

function convertOg(headerDataModel: PageMetaDataModel): OgModel {
    return {
        ogTitle: headerDataModel.ogTitle || headerDataModel.pageTitle,
        ogDescription:
            headerDataModel.ogDescription || headerDataModel.description,
        ogType: headerDataModel.ogType || 'website',
        ogImage: headerDataModel.ogImage,
        ogUrl: headerDataModel.ogUrl || headerDataModel.canonicalUrl
    };
}

function convertTwitter(
    headerDataModel: PageMetaDataModel,
    imageService: ImageServiceV1
): TwitterModel {
    const twitterImageUrl = headerDataModel.twitterImageScene7File
        ? imageService.getImageURL(
              {
                  contentFile: '',
                  scene7File: headerDataModel.twitterImageScene7File,
                  metaData: {
                      originalHeight: 0,
                      originalWidth: 0,
                      scene7Domain: headerDataModel.twitterImageScene7Domain
                  }
              },
              1600,
              {aspectRatio: AspectRatio['16:9']}
          )
        : headerDataModel.twitterImage;

    return {
        twitterTitle: headerDataModel.twitterTitle || headerDataModel.pageTitle,
        twitterDescription:
            headerDataModel.twitterDescription || headerDataModel.description,
        twitterImage: twitterImageUrl,
        twitterCard: headerDataModel.twitterCard,
        twitterImageScene7Domain: headerDataModel.twitterImageScene7Domain,
        twitterImageScene7File: headerDataModel.twitterImageScene7File
    };
}

export function convertHeaderToSeoModel(
    headerDataModel: PageMetaDataModel,
    imageService: ImageServiceV1
): SeoModel {
    return {
        pageTitle: headerDataModel.pageTitle,
        canonicalUrl: headerDataModel.canonicalUrl,
        og: convertOg(headerDataModel),
        keywords: headerDataModel.keywords,
        twitter: convertTwitter(headerDataModel, imageService),
        description: headerDataModel.description
    };
}

function getInitialPageInfo(pageInfoService: PageInfoServiceV1): PageInfo {
    return pageInfoService.getPageInfo();
}

export function HeaderUpdater(props: {server?: boolean}): JSX.Element | null {
    const contentStore = useContentStore();
    const spaGlobalConfig = useGlobalConfig();
    const routerService = useRouterService();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const pageInfoService = usePageInfoService();
    const imageService = useImageService();

    const [pageInfo, setPageInfo] = React.useState<PageInfo>(
        getInitialPageInfo(pageInfoService)
    );

    React.useEffect(() => {
        return pageInfoService.subscribe(() => {
            setPageInfo(pageInfoService.getPageInfo());
        });
    }, [pageInfoService, setPageInfo]);

    if (!pageRootModel) {
        return null;
    }

    const secondaryConsumer = pageInfoService.getSource() === 'secondary';

    if (props.server && !secondaryConsumer) {
        // Helmet rendering is necessary at the end of ssr rendering to ensure that secondary consumer pageInfo is added to the page.
        // If there is no secondary consumer this would overwrite layer meta tags.
        return null;
    }

    const {headerDataModel, featureAppsUrls} = pageRootModel;

    const {
        referrer,
        robotsIndexFollow,
        alternateHrefs,
        schemaFaqPage,
        organizationJson,
        schemaVehicle,
        schemaNewsArticle,
        urlLevels
    } = headerDataModel;

    const seoModel =
        pageInfo && pageInfo.seo && secondaryConsumer
            ? pageInfo.seo
            : convertHeaderToSeoModel(headerDataModel, imageService);

    return (
        <Helmet>
            <title>{seoModel.pageTitle}</title>
            <link rel="canonical" href={seoModel.canonicalUrl} />
            {alternateHrefs &&
                alternateHrefs.map(href => (
                    <link
                        key={href.url}
                        rel="alternate"
                        href={href.url}
                        hrefLang={href.hrefLang}
                    />
                ))}
            <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
            <meta name="viewport" content="width=device-width" />
            <meta name="robots" content="noodp, noydir" />
            <meta name="robots" content={robotsIndexFollow} />
            {Boolean(referrer) && <meta name="referrer" content={referrer} />}
            {getSeoTags(seoModel)}
            {getMetaTagName('language', headerDataModel.language)}
            {getOpenGraphTags(seoModel)}
            {getTwitterTags(seoModel.twitter)}
            {getSearchImageTags(headerDataModel)}
            {getWcmModeTags(routerService, spaGlobalConfig)}
            {getSearchMetaTags(headerDataModel, seoModel, secondaryConsumer)}
            {getPrefetchTags(featureAppsUrls)}
            <meta
                property="cq:page_root_url"
                content={spaGlobalConfig.appRootPath}
            />
            {schemaFaqPage &&
                schemaFaqPage.mainEntity.length > 0 &&
                getSchemaFaqPageTag(schemaFaqPage)}
            {organizationJson && getSchemaOrganizationTag(organizationJson)}
            {getUrlLevelTags(urlLevels)}
            {getMetaTagName('pageType', headerDataModel.pageType)}
            {schemaVehicle &&
                schemaVehicle.name &&
                schemaVehicle.offers &&
                schemaVehicle.offers.lowPrice &&
                schemaVehicle.offers.priceCurrency &&
                getSchemaVehicleTag(schemaVehicle)}
            {schemaNewsArticle &&
                schemaNewsArticle.headline &&
                schemaNewsArticle.image &&
                getSchemaNewsArticleTag(schemaNewsArticle)}
            {seoModel.schema && getSchemaSecondaryConsumer(seoModel.schema)}
        </Helmet>
    );
}
function filterDefined(
    ...metaElements: (MetaElement | undefined)[]
): MetaElement[] {
    return metaElements.filter(m => !!m) as MetaElement[];
}
