import * as React from 'react';
import {ConfigurableConsole} from '../context/logger/ConfigurableConsole';

export function isFinished(phase: LoadingPhase, loaded: boolean) {
    return phase !== 'delayed' && loaded;
}

export type LoadingPhase = 'pending' | 'delayed' | 'completed';

export interface DelayedStateConfig {
    readonly threshold?: number;
    readonly delay?: number;
    readonly loaded?: boolean;
    readonly logger: ConfigurableConsole;
}

export function useDelayedState({
    threshold = 300,
    delay = 1000,
    loaded = false,
    logger
}: DelayedStateConfig): LoadingPhase {
    const [phase, setPhase] = React.useState<LoadingPhase>(
        loaded ? 'completed' : 'pending'
    );

    const pendingTimeoutHandle = React.useRef<number>(0);
    const delayedTimeoutHandle = React.useRef<number>(0);

    logger.debug(
        'usedDelayedState p-t:%s, d-t:%s, loaded: %s, phase:%s',
        pendingTimeoutHandle.current,
        delayedTimeoutHandle.current,
        loaded,
        phase
    );

    React.useEffect(() => {
        logger.debug(
            'useEffect pending timeout:%s, delayed timeout:%s, phase:%s, loaded:%s',
            pendingTimeoutHandle.current,
            delayedTimeoutHandle.current,
            phase,
            loaded
        );
        if (isFinished(phase, loaded)) {
            logger.debug(
                'clear pending timeout ',
                pendingTimeoutHandle.current
            );
            window.clearTimeout(pendingTimeoutHandle.current);
        }
        if (phase === 'pending' && pendingTimeoutHandle.current === 0) {
            pendingTimeoutHandle.current = window.setTimeout(() => {
                logger.debug('execute pending timeout');
                setPhase('delayed');
                logger.debug('after set delayed phase');
            }, threshold);
            logger.debug(
                'start pending timeout %s',
                pendingTimeoutHandle.current
            );
        }
        if (phase === 'delayed' && delayedTimeoutHandle.current === 0) {
            logger.debug('start delayed timeout');
            delayedTimeoutHandle.current = window.setTimeout(() => {
                logger.debug('execute delayed timeout');
                setPhase('completed');
                logger.debug('after set completed phase');
            }, delay);
        }
    }, [
        phase,
        delay,
        threshold,
        loaded,
        logger,
        pendingTimeoutHandle,
        delayedTimeoutHandle
    ]);

    React.useEffect(
        () => () => {
            window.clearTimeout(pendingTimeoutHandle.current);
            window.clearTimeout(delayedTimeoutHandle.current);
        },
        []
    );

    return phase;
}
