import {DealerType} from '@volkswagen-onehub/tracking-service';
import {VehicleTrackingData} from '../../../generated/core';
import {TeaserPersonalization} from '../../utils/tracking/TeaserPersonalization';
import {AccordionOpeningType} from '../../modules/editorial/items/AccordionItem';

export interface EventInfoData {
    eventAction: TrackingEventAction;
    pageId: string;
    pageName?: string;
    contentId?: string;
    contentLabels?: {[key: string]: string[]};
    pageCategory?: string;
    sectionName?: string;
    templateType?: string;
    brand?: string;
    abTestFeatures?: {[key: string]: boolean};
}

export interface EnvironmentData {
    applicationId?: string;
    applicationIdVersion?: string;
    featureAppId?: string;
    featureAppIdAppVersion?: string;
    language?: string;
    country?: string;
}

export interface UserAgentData {
    loginStatus: boolean | undefined;
    dealerInformationBnr?: string;
    dealerInformationName?: string;
    dealerInformationType?: DealerType;
    dealerStatus?: boolean;
    browserResolutionHeight?: string;
    browserResolutionWidth?: string;
    browserResolutionOrientation?: string;
    browserResolutionBreakpoint?: string;
}
export type ReportingUserGroupId =
    | 'CMS'
    | 'BBO-UX'
    | 'BBO'
    | 'BBO-Control'
    | 'BBO-Training';
export type ReportingSourceId =
    | 'CMS-Page'
    | 'CMS-Section'
    | 'BBO-UX'
    | 'BBO-LSTM'
    | 'BBO-Campaign';

export interface PersonalizedActionReporting {
    actionId?: string;
    source: ReportingSourceId;
    userActionId?: string;
    userGroup: ReportingUserGroupId;
    productid?: string;
    contentId?: string;
    triggerTrackingId?: string;
    testMode?: string;
    buttonId?: string;
    customInfo?: string;
}

export interface ActionScriptReport extends PersonalizedActionReporting {
    version: '1.0';
    eventType: 'Display' | 'Click';
    displayOrder: number;
    intent?: string;
    priority?: number;
}

export interface PersonalizationData {
    status: boolean;
    personalizable?: boolean;
    recommendationId?: string;
    placeholderId?: string;
    aemResourcePath?: string;
    contentId?: string;
    teaserList?: TeaserPersonalization[];
    moduleId?: string;
    actionScriptReports?: ActionScriptReport[];
    group?: string;
    type?: string;
}

export interface PartnerData {
    informationBnr?: string;
    informationName?: string;
}

export interface LinkData {
    url?: string;
    name?: string;
}

export interface TabData {
    name?: string;
}

export interface FocusCategory {
    Name: string;
    NumberOfElements: number;
}

export interface ContentData {
    ItemPosition?: number;
    ItemCount?: number;
    modifiedByAction?: string;
    FocusCategories?: FocusCategory[];
    CountdownState?: CountdownState;
    DeviceSpecific?: DeviceSpecific[];
}

export interface SearchData {
    SearchTerm: string;
    NumberOfResults: number;
}

export interface VideoPlayerData {
    readonly playerName: string;
    readonly title: string;
    readonly id: string;
    readonly platform: string;
    readonly url: string;
    readonly milestone?: number;
    readonly time?: string;
    readonly length?: string;
    readonly playReason?: 'auto' | 'click';
    readonly pauseReason?: 'auto' | 'click';
    readonly timeplayed?: string;
}

export interface ErrorPageData {
    code?: string;
    message?: string;
    referringUrl?: string;
}

export interface Filter {
    name: 'CA';
    type: 'multi_value';
    numberOfResults: number;
    values: string[];
}

export interface Filterlist {
    filterlist?: Filter[];
}

export enum CountdownState {
    PRE = 'pre-countdown',
    POST = 'post-countdown'
}

export interface DeviceSpecific {
    sectionName: string;
    assetType: string[];
    displayed: string;
}

// TODO Change undefined props when more events are integrated
export interface TrackingDTO {
    eventInfo?: EventInfoData;
    environment?: EnvironmentData;
    user?: UserAgentData;
    personalization?: PersonalizationData;
    link?: LinkData;
    video?: VideoPlayerData;
    partner?: PartnerData;
    file?: undefined;
    error?: ErrorPageData;
    configuration?: VehicleTrackingData;
    page?: PageData;
    search?: SearchData;
    content?: ContentData;
    tab?: TabData;
    accordion?: AccordionOpeningType;
    filter?: Filterlist | Filter;
}

/** Page level tracking data like it's category, name, etc... */
export interface PageTrackingData {
    readonly brand?: string;
    readonly contentLabels?: string;
    readonly pageName?: string;
    readonly trackingPageName?: string;
    readonly category?: string;
    readonly errorPageCode?: string;
    readonly referringUrl?: string;
    readonly errorPage?: boolean;
    readonly templateType?: string;
}

/** Context level data for e.g. linkEvents, teaser events, personalization ... */
export interface ContextTrackingData {
    // General
    readonly contentLabels?: string;
    readonly sectionId?: string;
    readonly contentName?: string;
    readonly sectionGroupContentId?: string;
    // Content position
    readonly itemPosition?: number;
    readonly itemCount?: number;
    // Personalization
    readonly personalizationPlaceholderId?: string;
    readonly personalizationContentId?: string;
    readonly personalizationPersonalizable?: boolean;
    readonly personalizationPersonalized?: boolean;
    readonly personalizationGroup?: string;
    readonly personalizationType?: string;
    readonly recommendationId?: string;
    // Countdown State for S140 Premiere Section
    readonly countdownState?: CountdownState;
}

export interface PageData {
    readonly publicationDate?: string;
}

export enum TrackingEventAction {
    VideoPlayer_Fullscreen_Close = 'VideoPlayer_Fullscreen_Close',
    VideoPlayer_Fullscreen_Open = 'VideoPlayer_Fullscreen_Open',
    VideoPlayer_Mute_Click = 'VideoPlayer_Mute_Click',
    VideoPlayer_Unmute_Click = 'VideoPlayer_Unmute_Click',
    VideoPlayer_Video_Start = 'VideoPlayer_Video_Start',
    VideoPlayer_VideoMilestone_Reached = 'VideoPlayer_VideoMilestone_Reached',
    VideoPlayer_VideoPause_Click = 'VideoPlayer_VideoPause_Click',
    VideoPlayer_VideoPlay_Click = 'VideoPlayer_VideoPlay_Click',
    VWBasic_AccordionClose_Click = 'VWBasic_AccordionClose_Click',
    VWBasic_AccordionOpen_Click = 'VWBasic_AccordionOpen_Click',
    VWBasic_AnnouncementBar_Load = 'VWBasic_AnnouncementBar_Load',
    VWBasic_AnnouncementBarClose_Click = 'VWBasic_AnnouncementBarClose_Click',
    VWBasic_Anchor_Click = 'VWBasic_Anchor_Click',
    VWBasic_Asset_Load = 'VWBasic_Assetload',
    VWBasic_Button_Click = 'VWBasic_Button_Click',
    VWBasic_ContentSection_EnterViewport = 'VWBasic_ContentSection_EnterViewport',
    VWBasic_StageSection_Load = 'VWBasic_StageSection_Load',
    VWBasic_Download_Click = 'VWBasic_Download_Click',
    VWBasic_Error_Pageload = 'VWBasic_Error_Pageload',
    VWBasic_FooterLink_Click = 'VWBasic_FooterLink_Click',
    VWBasic_FeatureApp_Load = 'VWBasic_FeatureApp_Load',
    VWBasic_Layer_Load = 'VWBasic_Layer_Load',
    VWBasic_LayerClose_Click = 'VWBasic_LayerClose_Click',
    VWBasic_LayerItem_Click = 'VWBasic_LayerItem_Click',
    VWBasic_Link_Click = 'VWBasic_Link_Click',
    VWBasic_Login_Click = 'VWBasic_Login_Click',
    VWBasic_LoginStatus_Change = 'VWBasic_LoginStatus_Change',
    VWBasic_MainNavigation_Click = 'VWBasic_MainNavigation_Click',
    VWBasic_Pageload = 'VWBasic_Pageload',
    VWBasic_Integrator_Pageload = 'VWBasic_Integrator_Pageload',
    VWBasic_QuickAccess_Click = 'VWBasic_QuickAccess_Click',
    VWBasic_SliderElement_Load = 'VWBasic_SliderElement_Load',
    VWBasic_Element_Swipe = 'VWBasic_Element_Swipe',
    VWBasic_FilterCheck_Click = 'VWBasic_FilterCheck_Click',
    VWBasic_SubNavigation_Click = 'VWBasic_SubNavigation_Click',
    VWBasic_Teaser_Click = 'VWBasic_Teaser_Click',
    VWBasic_UserProfileOpen_Click = 'VWBasic_UserProfileOpen_Click',
    VWBasic_NavigationBarLink_Click = 'VWBasic_NavigationBarLink_Click',
    VWBasic_NavigationBreadcrumb_Click = 'VWBasic_NavigationBreadcrumb_Click',
    VWBasic_NavigationTopLink_Click = 'VWBasic_NavigationTopLink_Click',
    VWBasic_NavigationalTeaser_Click = 'VWBasic_NavigationalTeaser_Click',
    VWBasic_SearchResultlist_Load = 'VWBasic_SearchResultlist_Load',
    VWBasic_NBAButton_Click = 'VWBasic_NBAButton_Click',
    VWBasic_ContentTab_Click = 'VWBasic_ContentTab_Click',
    VWBasic_NBAButton_Load = 'VWBasic_NBAButton_Load',
    VWBasic_DealerState_Change = 'VWBasic_DealerState_Change',
    VWBasic_InfoButton_Click = 'VWBasic_InfoButton_Click'
}

export interface TrackingService {
    trackContentSliderFilterClick(
        slidesItemCount: number,
        filters: string[],
        contextData?: ContextTrackingData
    ): unknown;
    trackNextBestActionDisplay(
        actions: ActionScriptReport[],
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>
    ): void;
    trackEnterViewport(
        id: string,
        teaserList?: TeaserPersonalization[],
        contextData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO
    ): void;

    trackStageSectionLoad(
        id: string,
        actionScriptReports: ActionScriptReport[],
        contextData?: ContextTrackingData
    ): void;

    trackAssetLoad(
        id: string,
        deviceSpecific: DeviceSpecific[],
        contextData?: ContextTrackingData
    ): void;

    trackButtonClick(
        id: string,
        url: string,
        linkName: string,
        contextData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO,
        contentModifiedByAction?: string
    ): void;

    trackLinkClick(
        url: string,
        contentId?: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO,
        contentModifiedByAction?: string
    ): void;

    trackLinkClickExternal(
        url: string,
        contentId?: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData
    ): void;

    trackQuickAccessLinkClick(
        url: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        contentId?: string,
        trackingData?: any
    ): void;

    trackFooterLinkClick(url: string, linkName: string): void;

    trackTeaserClick(
        id: string,
        url: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        tab?: TabData
    ): void;

    trackPageLoad(id: string): void;

    trackVideoEvent(
        action: TrackingEventAction,
        id: string,
        data: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void;

    trackVideoMilestoneEvent(
        id: string,
        data: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void;

    trackAccordionClick(
        id: string,
        isOpen: boolean,
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData
    ): void;

    trackAccordionClickV2({
        id,
        openClick,
        url,
        linkName,
        contextData,
        accordion
    }: {
        id: string;
        openClick: boolean;
        url: string;
        linkName?: string;
        contextData?: ContextTrackingData;
        accordion: AccordionOpeningType;
    }): void;

    trackLoginClick(id: string, url: string, linkName?: string): void;

    trackMainNavigationLogoClick(url: string): void;

    trackMainNavigationClick(id: string, url: string, linkName?: string): void;

    trackMainNavigationClose(): void;

    trackMainNavigationOpen(): void;

    trackMainNavigationOpenClick(): void;

    trackFeatureAppLoad(
        id: string | null,
        contextData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO
    ): void;

    trackLoginFlyoutOpenClick(isLoggedIn: boolean): void;

    trackLoginFlyoutOpen(): void;

    trackLoginFlyoutClose(): void;

    trackLayerLoad(id: string | null, contextData?: ContextTrackingData): void;

    trackLayerCloseClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void;

    trackLayerItemClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void;

    trackAnnouncementBarLoad(
        id: string | null,
        contextData?: ContextTrackingData
    ): void;

    trackAnnouncementBarCloseClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void;

    trackSliderElementLoad(
        id: string | null,
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<ContentData>,
        filterlist?: Filter[]
    ): void;

    trackElementSwipe(
        id: string,
        videoData: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void;

    trackSkipButtonClick(
        id: string,
        videoData: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void;

    trackNavigationBarLinkClick(
        id: string | null,
        link: LinkData,
        contextData?: ContextTrackingData
    ): void;

    trackNavigationBreadcrumbClick(
        id: string | null,
        link: LinkData,
        contextData: ContextTrackingData
    ): void;

    trackNavigationTopLinkClick(url: string, linkTitle: string): void;

    trackNavigationTeaserClick(
        id: string,
        url: string,
        linkName?: string,
        sectionName?: string,
        contextTrackingData?: ContextTrackingData
    ): void;

    trackSearchLayerOpenClick(): void;

    trackSearchResultListLoad(
        searchTerm: string,
        numberOfResults: number
    ): void;

    trackInPageNavigationOpen(): void;

    trackInPageNavigationLoad(): void;

    trackInPageNavigationClose(): void;

    trackNextBestActionButtonClick(
        url: string,
        contentId?: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>
    ): void;

    trackLoginStatusChange(contentId: string | null): void;

    trackDealerStatusChange(): void;

    trackDealerSelectFlyoutOpen(): void;

    trackContentTabClick(
        id: string,
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData
    ): void;

    trackDataInfoButtonClick(
        id: string,
        linkName: string,
        contextData?: ContextTrackingData
    ): void;
}
