import * as React from 'react';
import {styled} from '@volkswagen-onehub/components-core';

import {WrappedLayerFooter} from '../layer-footer/WrappedLayerFooter';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {LayerHeaderUpdaterModel} from '../../../generated/core';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';

export type InteractionLayerProps = React.PropsWithChildren<{
    readonly closeButton: JSX.Element;
    layerHeaderUpdaterModel?: LayerHeaderUpdaterModel;
}>;

const StyledInteractionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.size.dynamic0200}
        ${props => props.theme.size.dynamic0130};
    flex-direction: column;
    height: 100vh;
`;
StyledInteractionWrapper.displayName = 'StyledInteractionWrapper';

export function InteractionLayer(props: InteractionLayerProps): JSX.Element {
    const {children, closeButton} = props;

    return (
        <GeneralDisclaimerProvider displayTypes={['T2_PAGE_BASED']}>
            <StyledInteractionWrapper>
                <CyAttributeAppender name="closeButton">
                    {closeButton}
                </CyAttributeAppender>
                {children}
                <WrappedLayerFooter />
            </StyledInteractionWrapper>
        </GeneralDisclaimerProvider>
    );
}
