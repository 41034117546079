import {
    Breakpoints,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from 'styled-components';
import {TeaserElementModel} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {
    TeaserElementCategory,
    TeaserImageLink,
    TeaserMainLink
} from '../../../components/teaser-element/helpers';
import {useContentStore, useTrackingService} from '../../../context';
import {elseUndefined} from '../../../utils/elseUndefined';
import {processContentId} from '../../../utils/processContentId';
import {MediaElement} from './MediaElement';
import {RichtextSimpleElement} from './RichtextElement';
import {RESOURCE_TYPE} from './editorial-teaser-element/EditorialTeaserElement';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {
    MagazineTeaserGridElement,
    SecondLevelTeaserElement
} from '../../../d6/components/teaser-element';

const TeaserOriginWrapper: React.FunctionComponent<{origin: string}> = ({
    origin,
    children
}) => (
    <div>
        <AlertBox severity={AlertBoxSeverity.INFO}>
            <span>Teaser is created from {origin}</span>
        </AlertBox>
        {children}
    </div>
);

const StyledTeaserLinkWrapper = styled.div`
    margin-bottom: ${props => props.theme.size.dynamic0100};
`;
StyledTeaserLinkWrapper.displayName = 'StyledTeaserLinkWrapper';

export interface TrackedSecondLevelTeaserProps {
    showCategory: boolean;
    teaserData: {
        itemCount: number;
        itemPosition: number;
        itemId: string;
        teaser: TeaserElementModel;
    };
    showOriginInfo: boolean;
    teaserTrackingName: string;
    readingTimeElement?: JSX.Element;
}

const responsiveMediaConfiguration: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        sizes: '95vw',
        aspectRatio: 'r_16_9'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        sizes: '30vw',
        aspectRatio: 'r_16_9'
    }
];

export function TrackedSecondLevelTeaserElement(
    props: TrackedSecondLevelTeaserProps
): JSX.Element {
    const trackingService = useTrackingService();
    const categoryName = useContentStore().getCurrentPageRootModel()
        ?.pageTrackingModel.category;

    const {
        showCategory,
        teaserData: {
            teaser: {
                teaserElementLinkHref: linkHref,
                categoryLinkHref,
                categoryLinkRichText,
                cqType: teaserCqType,
                mediaExisting,
                categoryLayerLink,
                teaserLayerLink,
                originalCqPath,
                contentName,
                contentLabels
            },
            itemId,
            itemCount,
            itemPosition
        },
        showOriginInfo,
        teaserTrackingName,
        readingTimeElement
    } = props;

    const contentId = processContentId(originalCqPath, contentName);
    const trackingData = useContextTrackingData(
        contentLabels,
        itemCount,
        itemPosition
    );

    function onClickTrack(): void {
        trackingService.trackTeaserClick(
            contentId,
            linkHref,
            teaserTrackingName,
            trackingData,
            {name: categoryName}
        );
    }

    const media = elseUndefined(
        mediaExisting,
        <TeaserImageLink
            href={linkHref}
            contentId={contentId}
            trackingActionOverride={onClickTrack}
        >
            <ResponsiveMediaRenderer configs={responsiveMediaConfiguration}>
                <MediaElement
                    containerProps={{matchParent: true}}
                    path={`${itemId}/media`}
                    responsiveMediaConfig={responsiveMediaConfiguration}
                    useParentAspectRatio
                />
            </ResponsiveMediaRenderer>
        </TeaserImageLink>
    );

    const teaserLink = (
        <StyledTeaserLinkWrapper>
            <TeaserMainLink
                href={linkHref}
                contentId={contentId}
                isLayerLink={teaserLayerLink}
                trackingActionOverride={onClickTrack}
            >
                <RichtextSimpleElement
                    path={`${itemId}/heading`}
                    appearance={
                        mediaExisting
                            ? TokenTextAppearance.headline200
                            : TokenTextAppearance.headline300
                    }
                    inheritColor
                />
            </TeaserMainLink>
        </StyledTeaserLinkWrapper>
    );

    const categoryElement = elseUndefined(
        !!categoryLinkRichText && showCategory,
        <TeaserElementCategory
            href={categoryLinkHref}
            contentId={contentId}
            text={categoryLinkRichText || []}
            isLayerLink={categoryLayerLink}
        />
    );

    const teaserItem = readingTimeElement ? (
        <MagazineTeaserGridElement key={itemId} mediaElement={media}>
            {categoryElement}
            {teaserLink}
            {readingTimeElement}
        </MagazineTeaserGridElement>
    ) : (
        <SecondLevelTeaserElement key={itemId} mediaElement={media}>
            {categoryElement}
            {teaserLink}
        </SecondLevelTeaserElement>
    );

    return showOriginInfo ? (
        <TeaserOriginWrapper
            origin={teaserCqType === RESOURCE_TYPE ? 'XF' : 'Stage'}
            key={itemId}
        >
            {teaserItem}
        </TeaserOriginWrapper>
    ) : (
        teaserItem
    );
}
