import {Action, Location, LocationListener, UnregisterCallback} from 'history';
import {action, computed, observable, Lambda} from 'mobx';
import * as React from 'react';
import {Router} from 'react-router-dom';

import {HistoryServiceV2} from '@feature-hub/history-service';
import {ShowroomNavigationManagerV1 as ShowroomNavigationManager} from '@volkswagen-onehub/showroom-navigation';

import {SpaGlobalConfig} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {convertLocationToPath} from '../../utils/convertLocationToPath';
import {PriorityPersonalizationLoader} from '../personalization/PriorityPersonalizationLoader';
import {RouterService} from './RouterService';
import {isAppLink} from './isAppLink';

export interface RouterState {
    action: Action;
    pagePath: string;
}

@singleton('RouterService', {env: 'client'})
export class ClientRouterService implements RouterService {
    public state: RouterState;

    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private showroomNavigationManager!: ShowroomNavigationManager;
    @inject() private historyService!: HistoryServiceV2;

    private navigationListeners: Lambda[] = [];

    public constructor() {
        this.state = observable<RouterState>({
            action: 'PUSH',
            pagePath: convertLocationToPath(window.location.pathname) || ''
        });
    }

    @postConstruct()
    public init(): void {
        this.listen(this.onRouteEvent.bind(this));
    }

    public wrapWithRouter(element: JSX.Element): JSX.Element {
        return React.createElement(
            Router,
            {history: this.showroomNavigationManager.history},
            element
        );
    }

    public getLocation(): Location {
        return this.showroomNavigationManager.history.location;
    }

    public listen(listener: LocationListener): UnregisterCallback {
        return this.showroomNavigationManager.history.listen(listener);
    }

    public onNavigate(listener: () => void): void {
        this.navigationListeners.push(listener);
    }

    public navigate(path: string): void {
        this.navigationListeners.forEach(listener => listener());

        const location = this.showroomNavigationManager.history.location;

        if (path.startsWith('#')) {
            const newRootLocation = {
                pathname: location.pathname,
                search: location.search,
                hash: path
            };
            // use local history to conserve other feature apps navigation state
            this.showroomNavigationManager.history.push(newRootLocation);
        } else {
            // use root history to reset other feature apps navigation state. Especially important when clicking on the current page in the navigation flyout
            try {
                const fullUrl = new URL('http://www.domain.de' + path);
                const newRootLocation = {
                    pathname: fullUrl.pathname,
                    search: fullUrl.search,
                    hash: fullUrl.hash
                };
                this.historyService.rootHistory.push(newRootLocation);
            } catch (e) {
                console.error(
                    'cannot navigate because path is not a properly formatted'
                );
                return;
            }
        }
    }

    public get appRootPath(): string {
        return this.spaGlobalConfig.appRootPath;
    }

    public isAppLink(href: string): boolean {
        return isAppLink(href, this.spaGlobalConfig, this.appRootPath);
    }

    @computed
    public get pagePath(): string {
        return this.state.pagePath;
    }

    public get pageResourcePath(): string {
        const marketPath = this.spaGlobalConfig.marketPath;
        return this.pagePath.startsWith(marketPath)
            ? this.pagePath
            : marketPath + this.pagePath;
    }

    public replace(path: string): void {
        this.showroomNavigationManager.history.replace(path);
    }

    @action
    private onRouteEvent(location: Location, newAction: Action): void {
        this.state.action = newAction;
        const newPagePath = convertLocationToPath(location.pathname) || '';
        if (this.state.pagePath !== newPagePath) {
            this.state.pagePath = newPagePath;
        }
        PriorityPersonalizationLoader.hideDefault();
    }
}
