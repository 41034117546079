import * as React from 'react';

import {MapTo} from '../../../../../infrastructure/compatibility/MapTo';
import {RichtextFullElement} from '../../RichtextElement';
import {BaseCellProps, StyledBaseCell} from './EmptyCellElement';

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/richtextCellElement';

class RichtextCellElementComponentClass extends React.PureComponent<
    BaseCellProps
> {
    public render(): React.ReactNode {
        return (
            <StyledBaseCell isNarrow={this.props.isNarrow}>
                <RichtextFullElement path="richtext" />
            </StyledBaseCell>
        );
    }
}

export const RichtextCellElement = MapTo(
    RESOURCE_TYPE,
    RichtextCellElementComponentClass
);
