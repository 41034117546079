import * as React from 'react';
import {
    NotificationBar as AnnouncementBarLSG,
    styled
} from '@volkswagen-onehub/components-core';
import {animateMotion} from '../../../utils/smoothScroll';
import BezierEasing from 'bezier-easing';
import {
    useContentStore,
    useNavigationStore,
    useTrackingService
} from '../../../context';
import {XfAnnouncement} from '../main/XfAnnouncement';
import {processContentId} from '../../../utils/processContentId';
import {getIsomorphicLayoutEffect} from '../../../utils/getIsomorphicLayoutEffect';

export const ANNOUCEMENTBAR_CLOSE_EVENT = 'closeannouncementbar';

const P0 = 0.4;
const P1 = 0.0;
const P2 = 0.2;
const P3 = 1.0;

const StyledAnnouncementBarWrapper = styled.div`
    overflow: hidden;

    & > * {
        transition: opacity 200ms cubic-bezier(${P0}, ${P1}, ${P2}, ${P3});
    }
`;

const EASING_FUNCTION = BezierEasing(P0, P1, P2, P3);

const animateElementClosing = (
    element: HTMLElement,
    height: number,
    callbackFunction: VoidFunction
) => {
    const movingFunction = (value: number) =>
        (element.style.height = `${(1 - value) * height}px`);

    const children = Array.from(element.children) as HTMLElement[];

    children.forEach(child => (child.style.opacity = '0'));

    animateMotion({
        animationDuration: 400,
        easingFunction: EASING_FUNCTION,
        movingFunction,
        callbackFunction
    });
};

const AnnouncementBarResizeObserver = (setter: (height: number) => void) =>
    new ResizeObserver(entries => {
        if (entries.length === 0) {
            return;
        }
        setter(entries[0].contentRect.height);
    });

interface InnerAnnouncementBarProps {
    unmount: VoidFunction;
}

const InnerAnnouncementBar = (props: InnerAnnouncementBarProps) => {
    const {unmount} = props;
    const [height, setHeight] = React.useState(0);
    const navigationStore = useNavigationStore();
    const ref = React.useRef<HTMLDivElement | null>(null);
    const resizeObserver = React.useRef<ResizeObserver | null>(null);
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const trackingService = useTrackingService();

    React.useEffect(() => {
        if (!ref.current) {
            return;
        }

        const closeAnnouncementBar = () => {
            // eslint-disable-next-line no-unused-expressions
            resizeObserver.current?.disconnect();
            animateElementClosing(ref.current as HTMLDivElement, height, () => {
                navigationStore.setAnnouncementBarHeight(0);
                unmount();
            });
        };

        document.addEventListener(
            ANNOUCEMENTBAR_CLOSE_EVENT,
            closeAnnouncementBar,
            {
                once: true
            }
        );

        return () =>
            document.removeEventListener(
                ANNOUCEMENTBAR_CLOSE_EVENT,
                closeAnnouncementBar
            );
    }, [height, unmount, navigationStore]);

    getIsomorphicLayoutEffect()(() => {
        if (!ref.current) {
            return;
        }
        resizeObserver.current = AnnouncementBarResizeObserver(
            (elementHeight: number) => setHeight(elementHeight)
        );

        // eslint-disable-next-line no-unused-expressions
        resizeObserver.current?.observe(ref.current);

        return () => resizeObserver.current?.disconnect();
    }, []);

    React.useEffect(() => {
        navigationStore.setAnnouncementBarHeight(height);
        navigationStore.updateScrollPositionOnAnimationFrame();
    }, [navigationStore, height]);

    React.useEffect(() => {
        if (!isAnnouncementAvailable()) {
            return;
        }

        setTimeout(() => {
            if (!ref.current) {
                return;
            }
            trackingService.trackAnnouncementBarLoad(
                processContentId(
                    pageRootModel!.xfAnnouncement.path,
                    pageRootModel!.xfAnnouncement.name
                )
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.target.dispatchEvent(
            new CustomEvent(ANNOUCEMENTBAR_CLOSE_EVENT, {bubbles: true})
        );
        isAnnouncementAvailable() &&
            trackingService.trackAnnouncementBarCloseClick(
                processContentId(
                    pageRootModel!.xfAnnouncement.path,
                    pageRootModel!.xfAnnouncement.name
                )
            );
    };

    const isAnnouncementAvailable = () => {
        return pageRootModel && pageRootModel.xfAnnouncement;
    };

    return (
        <StyledAnnouncementBarWrapper ref={ref} data-testid="announcement-bar">
            <AnnouncementBarLSG
                closeButtonLabel={
                    pageRootModel?.xfAnnouncement?.btnCloseLabel ??
                    'close announcement bar'
                }
                onClose={closeHandler}
            >
                {isAnnouncementAvailable() && (
                    <XfAnnouncement
                        path={pageRootModel!.xfAnnouncement.exportedPath}
                    />
                )}
            </AnnouncementBarLSG>
        </StyledAnnouncementBarWrapper>
    );
};

export const AnnouncementBar = () => {
    const [isMounted, unmount] = React.useReducer(() => false, true);

    return isMounted ? <InnerAnnouncementBar unmount={unmount} /> : null;
};
