import * as React from 'react';

import {
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {ArticleMetaInfo} from '../../../d6/components/article-meta-info';

import {ContentMetaDataModel} from '../../../../generated/core';
import {useLocaleModel} from '../../../context';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {FormattedDate} from '../../../utils/FormattedDate';

interface TextBlockWithLabelProps {
    text: string;
    label?: string;
}

interface PublishDateProps {
    formattedDate: React.ReactNode;
}

const TextBlockWithLabel: React.FunctionComponent<TextBlockWithLabelProps> = ({
    label,
    text
}) => (
    <Text tag={TextTag.span} appearance={TokenTextAppearance.label150}>
        <Text tag={TextTag.span}>{label}</Text>
        <Text tag={TextTag.span} bold>
            &nbsp;{text}
        </Text>
    </Text>
);
TextBlockWithLabel.displayName = 'ArticleMetaInfo.TextBlockWithLabel';

const PublishDateElement: React.FunctionComponent<PublishDateProps> = ({
    formattedDate
}) => (
    <CyAttributeAppender name="publishDateElement">
        <Text tag={TextTag.span} appearance={TokenTextAppearance.label150}>
            {formattedDate}
        </Text>
    </CyAttributeAppender>
);
PublishDateElement.displayName = 'ArticleMetaInfo.PublishDateElement';

export function ArticleMetaInfoElement(
    props: ContentMetaDataModel
): JSX.Element | null {
    const localeModel = useLocaleModel();

    const {
        authorNames,
        photographerNames,
        publishDateTS,
        authorLabel,
        photographyLabel
    } = props;

    if (authorNames || photographerNames || publishDateTS) {
        return (
            <ArticleMetaInfo>
                {authorNames && (
                    <CyAttributeAppender name="authorElement">
                        <TextBlockWithLabel
                            key="author"
                            text={authorNames}
                            label={authorLabel}
                        />
                    </CyAttributeAppender>
                )}
                {photographerNames && (
                    <CyAttributeAppender name="photographerElement">
                        <TextBlockWithLabel
                            key="photographer"
                            text={photographerNames}
                            label={photographyLabel}
                        />
                    </CyAttributeAppender>
                )}
                {publishDateTS && (
                    <PublishDateElement
                        key="publish-date"
                        formattedDate={
                            <FormattedDate
                                language={localeModel.language}
                                country={localeModel.countryCode}
                                timestamp={publishDateTS}
                            />
                        }
                    />
                )}
            </ArticleMetaInfo>
        );
    }

    return null;
}
