import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {MydealerMovingFrameModel} from '../../../generated/core';
import {
    useMyDealerContentService,
    useMyDealerStore,
    useSpaAsyncConfig
} from '../../context';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {useDealerConfig} from '../../utils/useDealerConfig';
import {getLabelsByDealerType} from './navigation/MydealerStateInfo';
import {
    InternalSectionGroup,
    AuthorView,
    AdditionalMovingFrameProps
} from './SectionGroup';

import {useState} from 'react';
import {MydealerStore} from '../../context/mydealer/MydealerStore';
import {DealerConfigurationV1} from '@volkswagen-onehub/mydealer-configuration-service';

function isMyDealerMovingFrameVisible(
    mydealerStore: MydealerStore,
    dealerLoaded: boolean,
    dealerModel?: DealerConfigurationV1
) {
    return (
        mydealerStore !== undefined &&
        mydealerStore.dealerState === true &&
        dealerLoaded &&
        dealerModel !== undefined &&
        !dealerModel.trackingDisabled
    );
}

/**
 * S136 Mydealer Moving Frame
 */
const InternalMydealerMovingFrame = observer(function MDMF(
    props: MydealerMovingFrameModel
): JSX.Element | null {
    const mydealerStore = useMyDealerStore();
    const dealerId = mydealerStore?.dealerId;
    const {loaded: dealerLoaded, result: dealerModel} = useDealerConfig(
        dealerId
    );

    const name = dealerModel?.legalName || dealerModel?.displayName || '';

    const spaAsyncConfig = useSpaAsyncConfig();
    const labelTemplate = spaAsyncConfig.mydealerState.movingFrameLabelTemplate;
    const label = labelTemplate
        ? labelTemplate.replace(`\${dealerCompanyName}`, name)
        : name;

    const {movingFrameLinkLabel} = getLabelsByDealerType(
        spaAsyncConfig,
        dealerModel?.dealerType
    );

    const myDealerContentService = useMyDealerContentService();
    const [logo, setLogo] = useState<string | undefined>(undefined);
    React.useEffect(() => {
        setLogo(undefined);
        if (dealerId && myDealerContentService) {
            myDealerContentService
                .fetchDealerIcon(dealerId)
                .then((result: string | undefined) => setLogo(result));
        }
    }, [myDealerContentService, dealerId]);

    const isMovingFrameVisible = isMyDealerMovingFrameVisible(
        mydealerStore,
        dealerLoaded,
        dealerModel
    );

    // do not render My Dealer Moving Frame for SSR
    if (!isInBrowser()) {
        return null;
    }

    const mydealerProps: AdditionalMovingFrameProps & AuthorView = {
        ...props,
        invisible: !isMovingFrameVisible,
        movingFrameEnabled: true,
        movingFrameLabel: label,
        movingFrameLabelIcon: logo,
        movingFrameLinkTarget: '_self',
        movingFrameLinkText: movingFrameLinkLabel,
        movingFrameLinkUrl:
            dealerModel?.createHomeNavigation('imprint').createHref() || '#',
        authorTitle: 'S136 Mydealer Moving Frame',
        groupLegalEntity: dealerModel
            ? 'mydealer_' + dealerModel.id
            : 'unknnown'
    };

    return <InternalSectionGroup {...mydealerProps} />;
});

export const MydealerMovingFrame = MapTo(
    'vwa-ngw18/components/structure/mydealerMovingFrame',
    InternalMydealerMovingFrame
);
