import * as React from 'react';
import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {Include} from '../../compatibility/Include';
import {SectionContext} from '../../../modules/structure/SectionContext';
import {useModelStore, useSectionContext} from '../../../context';
import {ModelStore} from '../../../context/model/ModelStore';
export interface ModelRef {
    path: string;
    resourceType: string;
}

interface CmsComponentProps {
    consumerId: string;
    id: string;
    model: TransferModel;
}

class CmsComponentWrapper extends React.Component<
    CmsComponentProps,
    {error: boolean}
> {
    public render() {
        if (this.state && this.state.error) {
            return null;
        }
        return <CmsComponent {...this.props} />;
    }

    public static getDerivedStateFromError() {
        return {error: true};
    }

    public componentDidCatch(e: Error) {
        console.error(e);
    }
}

const CmsComponent = (props: CmsComponentProps) => {
    const {consumerId, id, model} = props;
    const modelStore = useModelStore();

    let totalPath = getOrCreateModelPath(modelStore, consumerId, id, model);

    const sectionValue = useSectionContext() || {
        sectionId: `generated_${consumerId}`
    };
    if (totalPath && model) {
        return (
            <SectionContext.Provider
                value={{...sectionValue, skipWrapper: true}}
            >
                <Include resourceType={model[':type']} path={totalPath} />
            </SectionContext.Provider>
        );
    } else return null;
};

export const renderCmsComponent = (
    consumerId: string,
    id: string,
    model: TransferModel
): React.ReactNode => (
    <CmsComponentWrapper consumerId={consumerId} id={id} model={model} />
);

function getOrCreateModelPath(
    modelStore: ModelStore,
    consumerId: string,
    id: string,
    model: TransferModel
) {
    const totalPath = modelStore.getCustomContentPath(consumerId, id);
    const existingModel = modelStore.getData(totalPath);
    if (!existingModel) {
        modelStore.insertCustomContent(consumerId, id, model);
    }
    return totalPath;
}
