import * as React from 'react';
import {useLayerContext} from '..';
import {DisplayType} from '../../../generated/core';
import {SimpleDisclaimerContainer} from '../../components/disclaimers/SimpleDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../disclaimer/GeneralDisclaimerProvider';

export interface SimpleDisclaimerWrapperProps {
    children: JSX.Element;
    getItemDisclaimers?: boolean;
}

export function SimpleDisclaimerWrapper(
    props: SimpleDisclaimerWrapperProps
): JSX.Element {
    const {children, getItemDisclaimers} = props;
    const layerContext = useLayerContext();
    const isLayerContext = Boolean(layerContext.layerType);
    const displayTypes: DisplayType[] = ['T3_SECTION_BASED'];
    if (Boolean(getItemDisclaimers)) {
        displayTypes.push('T4_ITEM_BASED');
    }

    return isLayerContext ? (
        <GeneralDisclaimerProvider displayTypes={displayTypes}>
            {children}
            <SimpleDisclaimerContainer displayTypes={displayTypes} />
        </GeneralDisclaimerProvider>
    ) : (
        children
    );
}
