import {ReactNode} from 'react';
import * as React from 'react';

import {RegisteredDisclaimer} from '../../../context/disclaimer/DisclaimerStore';
import {useGlobalConfig} from '../../../context';
import {CmsReferenceBadge} from '../../../components/disclaimers/CmsReferenceBadge';
import {InteractiveDisclaimerBadge} from '../../../components/disclaimers/InteractiveDisclaimerBadge';
import {compareDisclaimerReferences} from '../../../context/disclaimer/helpers';
import {DISCLAIMER_REFERENCE_SEPARATOR} from '../../../context/disclaimer/useDisclaimerReferences';
import {Direction} from '../../../d6/components/helpers';
import {useDirection} from '../../../hooks/useDirection';
import {FocalPoint} from '@volkswagen-onehub/image-service';
import {MediaFocalPoint} from '../../../../generated/core';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {focalPointMap} from '../../../utils/focalPointMap';

export function useInteractiveDisclaimerBadges(
    openableDisclaimers: RegisteredDisclaimer[],
    interactiveDisclaimers: RegisteredDisclaimer[]
): JSX.Element | undefined {
    const {disclaimerLabels} = useGlobalConfig();
    const direction = useDirection();

    const handleReferenceBadgeClick = (disclaimer: RegisteredDisclaimer) => (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.preventDefault();
        e.stopPropagation();

        return disclaimer.toggleOpen?.();
    };

    const map = new Map<RegisteredDisclaimer, JSX.Element>();

    interactiveDisclaimers.forEach(disclaimer =>
        map.set(
            disclaimer,
            <InteractiveDisclaimerBadge
                disclaimer={disclaimer}
                badgeToggleLabel={disclaimerLabels.badgeToggleLabel}
                badgeVariant="large"
                key={`disclaimer-badge-${disclaimer.reference}`}
            />
        )
    );

    openableDisclaimers.forEach(disclaimer =>
        map.set(
            disclaimer,
            <CmsReferenceBadge
                key={disclaimer.reference}
                reference={disclaimer.reference}
                ariaLabel={
                    disclaimerLabels.badgeToggleLabel +
                    ' ' +
                    disclaimer.reference
                }
                onClick={handleReferenceBadgeClick(disclaimer)}
                variant="large"
            >
                {disclaimer.reference}
            </CmsReferenceBadge>
        )
    );

    if (map.size === 0) {
        return undefined;
    }

    const sortedEntries = [...map.entries()]
        .sort((a, b) =>
            direction === Direction.RTL
                ? compareDisclaimerReferences(b[0], a[0])
                : compareDisclaimerReferences(a[0], b[0])
        )
        .map(value => value[1]);

    return <>{sortedEntries}</>;
}

export function useStaticDisclaimerReferenceElements(
    disclaimers: RegisteredDisclaimer[]
): JSX.Element[] {
    const {disclaimerLabels} = useGlobalConfig();
    const direction = useDirection();

    const sortedDisclaimers = disclaimers.sort((a, b) =>
        direction === Direction.RTL
            ? compareDisclaimerReferences(b, a)
            : compareDisclaimerReferences(a, b)
    );

    const nonOpenableDisclaimers = sortedDisclaimers.filter(
        disclaimer => disclaimer.displayType !== 'T6_OPENABLE'
    );

    const nonOpenableReferences = nonOpenableDisclaimers
        .map<React.ReactNode>(disclaimer => (
            <span
                key={disclaimer.reference}
                aria-label={
                    disclaimerLabels.disclaimerNrReferenceLabel +
                    ' ' +
                    disclaimer.reference
                }
                data-component="disclaimer-reference-badge"
            >
                {disclaimer.reference}
            </span>
        ))
        .reduce(
            (prev: ReactNode[], curr: ReactNode) =>
                prev?.length
                    ? [prev, DISCLAIMER_REFERENCE_SEPARATOR, curr]
                    : [curr],
            []
        );

    const nonOpenableFragment: JSX.Element = (
        <React.Fragment key={'disclaimer-non-openable-references'}>
            {nonOpenableReferences}
        </React.Fragment>
    );

    return [nonOpenableFragment];
}

export const getResponsiveFocalPoints = (
    responsiveMediaConfig: ResponsiveMediaRendererConf[],
    focalPoint: MediaFocalPoint,
    portraitFocalPoint: MediaFocalPoint | undefined
):
    | {
          mediaQuery: string;
          focalPoint: FocalPoint;
      }[]
    | undefined => {
    const filteredConfig = responsiveMediaConfig.filter(
        entry => entry.mediaQuery
    );

    if (
        !portraitFocalPoint ||
        portraitFocalPoint === 'CENTER_CENTER' ||
        filteredConfig.length === 0
    ) {
        return;
    }
    return filteredConfig.map(entry => ({
        mediaQuery: entry.mediaQuery!,
        focalPoint:
            focalPointMap[entry.portray ? portraitFocalPoint : focalPoint]
    }));
};
