import * as React from 'react';

import {
    AspectRatio,
    AspectRatioContainer,
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerVerticalAlignment,
    ContainerWrap,
    DirectionalContainerPadding,
    styled
} from '@volkswagen-onehub/components-core';
import {getContainerPadding, getStartDirection} from '../helpers';
import {HoverGroup} from '../hover-group';

export * from './link';
export * from './types';

export interface TeaserElementProps {
    readonly mediaElement?: JSX.Element;
    readonly copyPadding?: DirectionalContainerPadding;
}

const CONTAINER_PADDING_2_0 = getContainerPadding([2, 0]);
const CONTAINER_PADDING_4_0 = getContainerPadding([4, 0]);

const OverflowWrapper = styled.div`
    overflow: hidden;
`;
OverflowWrapper.displayName = 'OverflowWrapper';

const StyledImageLinkWrapper = styled(OverflowWrapper)`
    @media (max-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;
StyledImageLinkWrapper.displayName = 'StyledRealLinkWrapper';

export class TeaserElement extends React.Component<TeaserElementProps> {
    public static hoverGroupId = 'cms-teaser-element';

    public render(): JSX.Element {
        const {mediaElement, copyPadding, children} = this.props;

        return (
            <HoverGroup>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                >
                    <OverflowWrapper>{mediaElement}</OverflowWrapper>
                    <Container
                        gutter={ContainerGutter.dynamic0020}
                        wrap={ContainerWrap.always}
                        padding={copyPadding}
                    >
                        {children}
                    </Container>
                </Container>
            </HoverGroup>
        );
    }
}

export const SmallTeaserElement: React.FunctionComponent<TeaserElementProps> = ({
    mediaElement,
    children
}): JSX.Element => (
    <HoverGroup>
        <Container
            gutter={ContainerGutter.static250}
            wrap={ContainerWrap.never}
            shrinkContent
            verticalAlign={ContainerVerticalAlignment.center}
        >
            <StyledImageLinkWrapper>{mediaElement}</StyledImageLinkWrapper>
            <Container
                gutter={ContainerGutter.dynamic0020}
                wrap={ContainerWrap.always}
            >
                {children}
            </Container>
        </Container>
    </HoverGroup>
);
SmallTeaserElement.displayName = 'SmallTeaserElement';

export const FirstLevelATeaserElement: React.FunctionComponent<TeaserElementProps> = props => (
    <TeaserElement
        copyPadding={{inlineEnd: CONTAINER_PADDING_4_0}}
        {...props}
    />
);
FirstLevelATeaserElement.displayName = 'FirstLevelATeaserElement';

export const FirstLevelBTeaserElement: React.FunctionComponent<TeaserElementProps> = props => (
    <TeaserElement
        copyPadding={{inlineStart: CONTAINER_PADDING_2_0}}
        {...props}
    />
);
FirstLevelBTeaserElement.displayName = 'FirstLevelBTeaserElement';

interface StyledSecondLevelTeaserItemProps {
    hasMedia: boolean;
}

const StyledSecondLevelTeaserItem = styled.div<
    StyledSecondLevelTeaserItemProps
>`
    height: 100%;
    ${props =>
        !props.hasMedia &&
        `padding: 0 ${props.theme.size.grid001};
			border-${getStartDirection(props.theme.direction)}: solid ${
            props.theme.colors.border.divider
        } 2px;`}
`;

export const SecondLevelTeaserElement: React.FunctionComponent<TeaserElementProps> = props => {
    const hasMedia = !!props.mediaElement;
    const media = props.mediaElement && (
        <AspectRatioContainer ratio={AspectRatio['16:9']}>
            {props.mediaElement}
        </AspectRatioContainer>
    );

    return (
        <StyledSecondLevelTeaserItem hasMedia={hasMedia}>
            <TeaserElement {...props} mediaElement={media} />
        </StyledSecondLevelTeaserItem>
    );
};
SecondLevelTeaserElement.displayName = 'SecondLevelTeaserElement';

interface StyledMagazineTeaserGridItemProps {
    hasMedia: boolean;
}

const StyledMagazineTeaserGridItem = styled.div<
    StyledMagazineTeaserGridItemProps
>`
    height: 100%;
    ${props =>
        !props.hasMedia &&
        `padding: 0 ${props.theme.size.grid001};
			border-${getStartDirection(props.theme.direction)}: solid ${
            props.theme.colors.border.divider
        } 2px;`}
`;

export const MagazineTeaserGridElement: React.FunctionComponent<TeaserElementProps> = props => {
    const hasMedia = !!props.mediaElement;
    const media = props.mediaElement && (
        <AspectRatioContainer ratio={AspectRatio['16:9']}>
            {props.mediaElement}
        </AspectRatioContainer>
    );

    return (
        <StyledMagazineTeaserGridItem hasMedia={hasMedia}>
            <TeaserElement {...props} mediaElement={media} />
        </StyledMagazineTeaserGridItem>
    );
};
MagazineTeaserGridElement.displayName = 'MagazineTeaserGridElement';
