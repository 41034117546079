import React, {Fragment} from 'react';

import {
    AspectRatio,
    AspectRatioContainer,
    BreakpointWrapper,
    Breakpoints,
    ThemeProvider,
    styled
} from '@volkswagen-onehub/components-core';

import {
    Direction,
    createCSSFourValuesPaddingByDirection,
    getStartDirection
} from '../helpers';
import {ImageWithFocalPoint} from '../image-with-focal-point';
import {useMediaQueries} from '../../../context/mediaQueries/mediaQueryContext';
import {radialGradient} from '../gradient-background';

const StyledHeadlineWrapper = styled.div`
    margin-bottom: ${props => props.theme.size.static350};
`;
StyledHeadlineWrapper.displayName = 'StyledHeadlineWrapper';

const StyledCopyWrapper = styled.div`
    margin-bottom: ${props => props.theme.size.static350};
`;
StyledCopyWrapper.displayName = 'StyledCopyWrapper';

const StyledLeftTextWrapper = styled.div`
    padding: ${props => props.theme.size.dynamic0100}
        ${props => props.theme.size.grid001} 0;

    @media (min-width: ${Breakpoints.b1920}px) {
        padding: ${props =>
            createCSSFourValuesPaddingByDirection(
                props.theme.size.dynamic0100,
                0,
                0,
                props.theme.size.grid002,
                props.theme.direction
            )};
    }
`;
StyledLeftTextWrapper.displayName = 'StyledLeftTextWrapper';

const StyledRightTextWrapper = styled.div`
    padding: ${props => props.theme.size.dynamic0100}
        ${props => props.theme.size.grid001} 0;
`;
StyledRightTextWrapper.displayName = 'StyledRightTextWrapper';

const GridContainer = styled.div`
    display: grid;
    grid-template-rows: auto ${props => props.theme.size.dynamic0200} auto 1fr; // Most height for last row
    grid-template-columns: repeat(24, 1fr);

    @media (min-width: ${Breakpoints.b1920}px) {
        grid-template-rows: auto ${props => props.theme.size.dynamic0300} auto auto auto;
    }
`;
GridContainer.displayName = 'GridContainer';

const MainImageArea = styled.div`
    grid-area: 1 / 2 / span 1 / span 13;
    margin-bottom: ${props => props.theme.size.dynamic0200};

    @media (min-width: ${Breakpoints.b960}px) {
        grid-area: 1 / 3 / span 1 / span 12;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-area: 1 / 4 / span 1 / span 11;
    }

    @media (min-width: ${Breakpoints.b1920}px) {
        grid-area: 1 / 4 / span 1 / span 10;
        margin-bottom: 0;
    }
`;
MainImageArea.displayName = 'MainImageArea';

const MainTextArea = styled.div`
	grid-area: 1 / 16 / span 1 / span 8;
	position: relative;
	margin-top: ${props => props.theme.size.dynamic0130};
	margin-bottom: ${props => props.theme.size.dynamic0200};

	@media (min-width: ${Breakpoints.b960}px) {
		grid-area: 1 / 16 / span 1 / span 7;
		margin-top: calc(
			${props => props.theme.size.dynamic0130} +
				${props => props.theme.size.dynamic0100}
		);
		// original margin d0130 + margin of blue rectangle d0100
	}

	@media (min-width: ${Breakpoints.b1280}px) {
		grid-area: 1 / 16 / span 1 / span 6;
	}

	@media (min-width: ${Breakpoints.b1920}px) {
		grid-area: 2 / 6 / span 3 / span 5;
		margin-top: ${props => props.theme.size.dynamic0130};
		margin-bottom calc(2*${props => props.theme.size.dynamic0200});
		// original margin d0200 + margin of the blue rectangle d0200
	}
`;
MainTextArea.displayName = 'MainTextArea';

const LeftTeaserArea = styled.div`
    grid-area: 2 / 2 / span 3 / span 10;

    @media (min-width: ${Breakpoints.b960}px) {
        grid-area: 2 / 3 / span 3 / span 9;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-area: 2 / 4 / span 3 / span 8;
    }

    @media (min-width: ${Breakpoints.b1920}px) {
        grid-area: 1 / 15 / span 3 / span 7;
    }
`;
LeftTeaserArea.displayName = 'LeftTeaserArea';

const RightTeaserUpperArea = styled.div`
    grid-area: 2 / 14 / span 2 / span 10;

    @media (min-width: ${Breakpoints.b960}px) {
        grid-area: 2 / 14 / span 2 / span 9;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-area: 2 / 14 / span 2 / span 8;
    }

    @media (min-width: ${Breakpoints.b1920}px) {
        grid-area: 4 / 13 / span 1 / span 7;
    }
`;
RightTeaserUpperArea.displayName = 'RightTeaserUpperArea';

const RightTeaserBottomArea = styled.div`
    grid-area: 4 / 14 / span 1 / span 10;

    @media (min-width: ${Breakpoints.b960}px) {
        grid-area: 4 / 14 / span 1 / span 9;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-area: 4 / 14 / span 1 / span 8;
    }

    @media (min-width: ${Breakpoints.b1920}px) {
        display: block;
        grid-area: 5 / 13 / span 1 / span 7;
    }
`;
RightTeaserBottomArea.displayName = 'RightTeaserBottomArea';

const StyledHideFrom1920 = styled.div`
    @media (min-width: ${Breakpoints.b1920}px) {
        display: none;
    }
`;
StyledHideFrom1920.displayName = 'StyledHideFrom1920';

export const DarkRectangle = styled.div`
	position: relative;
	background: ${radialGradient}%;
	margin:
		calc(-1*${props => props.theme.size.static350})
		${props => props.theme.size.grid002}
		${props => props.theme.size.dynamic0200}
		0
	}; // NOTE: In RTL design is preserved as in LTR because the mobile carousel should look and work the same as in LTR

	@media (max-width: ${Breakpoints.b560 - 1}px) {
        padding-bottom: ${props =>
            props.theme.size
                .static350}; // safe area for mobile slider bullet points

		&:before {
			content: '';
			width: 100%;
			height: calc(
				${props => props.theme.size.grid020} -
					${props => props.theme.size.static350}
			);
			background-color: white;
			position: absolute;
			left: 0;
		}
	}

	@media (min-width: ${Breakpoints.b560}px) {
		grid-area: 1 / 3 / span 2 / span 22;
		margin: 0;
		padding: 0;
	}

	@media (min-width: ${Breakpoints.b960}px) {
		grid-area: 1 / 4 / span 2 / span 20;
		margin-top: ${props => props.theme.size.dynamic0100};
	}

	@media (min-width: ${Breakpoints.b1280}px) {
		grid-area: 1 / 5 / span 2 / span 18;
		margin-top: ${props => props.theme.size.dynamic0100};
	}

	@media (min-width: ${Breakpoints.b1920}px) {
		grid-area: 1 / 5 / span 4 / span 11;
		margin: ${props => props.theme.size.dynamic0200} 0;
	}
`;
DarkRectangle.displayName = 'DarkRectangle';

const InsideDarkRectangle = styled.div`
    margin-right: calc(-1 * ${props => props.theme.size.grid002}); // Always LTR
`;
InsideDarkRectangle.displayName = 'InsideDarkRectangle';

interface StyledMobileTextWrapperProps {
    readonly useRightPadding?: boolean;
}

const StyledMobileTextWrapper = styled.div<StyledMobileTextWrapperProps>`
    margin: ${props => props.theme.size.static350}
        ${props => props.theme.size.static250};

    @media (min-width: ${Breakpoints.b560}px) {
        margin: ${props => props.theme.size.static350};
    }
`;
StyledMobileTextWrapper.displayName = 'StyledMobileTextWrapper';

const StyledBulletPaginationWrapper = styled.div`
	position: absolute;
	top: ${props => props.theme.size.grid020};
	padding-top: ${props => props.theme.size.static150};
	padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.direction !== Direction.RTL
        ? props.theme.size.grid002
        : props.theme.size.grid004};
	direction: ltr;
`;
StyledBulletPaginationWrapper.displayName = 'StyledBulletPaginationWrapper';

interface UspModuleProps {
    mainCopy: JSX.Element;
    mainHeadline: JSX.Element;
    mainImage: JSX.Element;
    mainMediaInteractionElement: (isHidden?: boolean) => JSX.Element;
    mainLink: JSX.Element;
    leftImage: JSX.Element;
    leftHeadline: JSX.Element;
    leftCopy: JSX.Element;
    leftLink: JSX.Element;
    leftImageAspectRatio?: AspectRatio;
    rightImage: JSX.Element;
    rightHeadline: JSX.Element;
    rightCopy: JSX.Element;
    rightImageAspectRatio?: AspectRatio;
    rightLink: JSX.Element;
    mobileCarousel(children: JSX.Element[]): JSX.Element;
}

export const UspModule: React.FunctionComponent<UspModuleProps> = (
    props: UspModuleProps
): JSX.Element | null => {
    const {
        mainCopy,
        mainHeadline,
        mainImage,
        mainMediaInteractionElement,
        mainLink,
        leftImage,
        leftHeadline,
        leftCopy,
        leftLink,
        leftImageAspectRatio = AspectRatio['3:2'],
        rightImage,
        rightHeadline,
        rightCopy,
        rightLink,
        rightImageAspectRatio = AspectRatio['3:2'],
        mobileCarousel
    } = props;
    const {isMobile} = useMediaQueries();

    const imageWrapper = (children: JSX.Element, aspectRatio: AspectRatio) => (
        <AspectRatioContainer ratio={aspectRatio}>
            <ImageWithFocalPoint focalPoint={{x: 'center', y: 'center'}}>
                {children}
            </ImageWithFocalPoint>
        </AspectRatioContainer>
    );

    // Main media is always 3:2
    const mainImageElement = imageWrapper(mainImage, AspectRatio['3:2']);

    const mainTextElement = (
        <>
            {mainMediaInteractionElement(!isMobile)}
            <StyledHeadlineWrapper>{mainHeadline}</StyledHeadlineWrapper>
            <StyledCopyWrapper>{mainCopy}</StyledCopyWrapper>
            {mainMediaInteractionElement(isMobile)}
            {mainLink}
        </>
    );

    const leftTeaserElement = (
        <>
            {imageWrapper(leftImage, leftImageAspectRatio)}
            <StyledLeftTextWrapper>
                <StyledHeadlineWrapper>{leftHeadline}</StyledHeadlineWrapper>
                <StyledCopyWrapper>{leftCopy}</StyledCopyWrapper>
                <StyledCopyWrapper>{leftLink}</StyledCopyWrapper>
            </StyledLeftTextWrapper>
        </>
    );

    const rightTeaserImage = imageWrapper(rightImage, rightImageAspectRatio);

    const rightTeaserText = (
        <StyledRightTextWrapper>
            <StyledHeadlineWrapper>{rightHeadline}</StyledHeadlineWrapper>
            <StyledCopyWrapper>{rightCopy}</StyledCopyWrapper>
            <StyledCopyWrapper>{rightLink}</StyledCopyWrapper>
        </StyledRightTextWrapper>
    );

    // Media in mobile carousel are always 1:1
    const mobileImageWrapper = (children: JSX.Element) => (
        <AspectRatioContainer ratio={AspectRatio['1:1']}>
            <ImageWithFocalPoint focalPoint={{x: 'center', y: 'center'}}>
                {children}
            </ImageWithFocalPoint>
        </AspectRatioContainer>
    );

    const mobileCarouselElement = mobileCarousel([
        <Fragment key="mobile-carousel-item-0">
            {mobileImageWrapper(mainImage)}
            <StyledMobileTextWrapper>{mainTextElement}</StyledMobileTextWrapper>
        </Fragment>,
        <Fragment key="mobile-carousel-item-1">
            {mobileImageWrapper(leftImage)}
            <StyledMobileTextWrapper>
                <StyledHeadlineWrapper>{leftHeadline}</StyledHeadlineWrapper>
                <StyledCopyWrapper>{leftCopy}</StyledCopyWrapper>
                {leftLink}
            </StyledMobileTextWrapper>
        </Fragment>,
        <Fragment key="mobile-carousel-item-2">
            {mobileImageWrapper(rightImage)}
            {/* useRightPadding is quick fix of last slide, but the look of it needs change */}
            <StyledMobileTextWrapper useRightPadding>
                <StyledHeadlineWrapper>{rightHeadline}</StyledHeadlineWrapper>
                <StyledCopyWrapper>{rightCopy}</StyledCopyWrapper>
                {rightLink}
            </StyledMobileTextWrapper>
        </Fragment>
    ]);

    return (
        <>
            <BreakpointWrapper max={Breakpoints.b560}>
                <ThemeProvider theme="inverted">
                    <DarkRectangle>
                        <InsideDarkRectangle>
                            {mobileCarouselElement}
                        </InsideDarkRectangle>
                    </DarkRectangle>
                </ThemeProvider>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b560}>
                <GridContainer>
                    <ThemeProvider theme="inverted">
                        <DarkRectangle />
                        <MainImageArea>{mainImageElement}</MainImageArea>
                        <MainTextArea>{mainTextElement}</MainTextArea>
                    </ThemeProvider>
                    <LeftTeaserArea>{leftTeaserElement}</LeftTeaserArea>
                    <RightTeaserUpperArea>
                        {rightTeaserImage}
                    </RightTeaserUpperArea>
                    <RightTeaserBottomArea>
                        {rightTeaserText}
                    </RightTeaserBottomArea>
                </GridContainer>
            </BreakpointWrapper>
        </>
    );
};
