import {useLoginStore, useSpaAsyncConfig} from '../../../context';

export const useUtilityNavigationItems = () => {
    const {utilityNavigationModel} = useSpaAsyncConfig();
    const loggedIn = useLoginStore().isLoggedIn;

    if (loggedIn) {
        return {
            activeItems: utilityNavigationModel.utilityNavItems,
            inactiveItems: []
        };
    }
    const activeItems = utilityNavigationModel.utilityNavItems.filter(
        i => !i.loginRequired
    );
    const inactiveItems = utilityNavigationModel.utilityNavItems.filter(
        i => i.loginRequired
    );
    return {activeItems, inactiveItems};
};
