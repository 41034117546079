import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {Direction} from '../helpers';

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${props =>
        `${props.theme.size.dynamic0250} ${props.theme.size.dynamic0200}`};
    gap: ${props => props.theme.size.dynamic0200};
`;
StyledContent.displayName = 'StyledContent';

export const StyledTextElements = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.dynamic0100};
`;
StyledTextElements.displayName = 'StyledTextElements';

export const StyledButtons = styled.div`
    display: flex;
    gap: ${props => props.theme.size.static250};

    @media (max-width: ${Breakpoints.b560}px) {
        flex-direction: column;
        width: 100%;

        & .buttonElement,
        & .buttonElement a,
        & .buttonElement button {
            width: 100%;
        }
    }
`;
StyledButtons.displayName = 'StyledButtons';

export const StyledMedia = styled.div<{isCutout: boolean}>`
    --gradient-rotation: 360deg;
    --gradient-crossing: 48.72%;
    background: linear-gradient(
        var(--gradient-rotation),
        #001e50 0%,
        #001e50 var(--gradient-crossing),
        rgba(255, 255, 255, 0) var(--gradient-crossing),
        rgba(255, 255, 255, 0) 100%
    );
    padding-block: 0 ${props => props.theme.size.dynamic0130};
    ${props =>
        !props.isCutout ? `padding-left: ${props.theme.size.dynamic0130};` : ''}
    padding-right: ${props => props.theme.size.dynamic0130};

    @media (min-width: ${Breakpoints.b960}px) {
        --gradient-rotation: 90deg;
        --gradient-crossing: 74.47%;
        padding-block: ${props => props.theme.size.dynamic0130};
        padding-inline: 0;
    }
`;
StyledMedia.displayName = 'StyledMedia';

export const StyledDisclaimers = styled.div`
    padding: ${props => props.theme.size.dynamic0130};
`;
StyledDisclaimers.displayName = 'StyledDisclaimers';

export const StyledLayout = styled.div`
    display: grid;
    grid-template-columns: [content-start media-start disclaimers-start] auto [content-end media-end disclaimers-end];
    grid-template-rows: [content-start] auto [content-end media-start] 1fr [media-end disclaimers-start] auto [disclaimers-end];

    & ${StyledContent} {
        grid-area: content;
    }

    & ${StyledMedia} {
        grid-area: media;
    }

    & ${StyledDisclaimers} {
        grid-area: disclaimers;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        --media-width: ${props => props.theme.size.grid012};
        --content-width: ${props => props.theme.size.grid012};
        grid-template-columns:
            [disclaimers-start media-start] var(--media-width)
            [media-end content-start] var(--content-width)
            [content-end disclaimers-end];
        grid-template-rows: [media-start content-start] auto [media-end content-end disclaimers-start] auto [disclaimers-end];

        ${props =>
            props.theme.direction === Direction.RTL
                ? `
                grid-template-columns:
                [disclaimers-start content-start] var(--content-width)
                [content-end media-start] var(--media-width)
                [media-end disclaimers-end];`
                : ''}
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        --media-width: ${props => props.theme.size.grid014};
        --content-width: ${props => props.theme.size.grid010};
    }

    @media (min-width: ${Breakpoints.b2560}px) {
        --media-width: auto;
    }
`;
StyledLayout.displayName = 'StyledLayout';
