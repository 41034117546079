import * as React from 'react';

import {ExpandCollapseLayerItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {ExpandCollapseSectionParsys} from '../../structure/ExpandCollapseSectionParsys';

function InternalExpandCollapseLayerItem(
    props: ExpandCollapseLayerItemModel
): JSX.Element {
    const {
        hasThreeColumnLayout,
        emptyImage,
        hasSquaredRatio,
        disableImages
    } = props;

    return (
        <AuthoringWrapper
            title="I113 Expand-Collapse Layer Item"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            {(C.isInEditor() || disableImages || !emptyImage) && (
                <SimpleDisclaimerWrapper>
                    <ExpandCollapseSectionParsys
                        path={'expandCollapseSectionParsys'}
                        hasThreeColumnLayout={hasThreeColumnLayout}
                        hasSquaredRatio={hasSquaredRatio}
                        disableImages={disableImages}
                        isInLayer
                    />
                </SimpleDisclaimerWrapper>
            )}
        </AuthoringWrapper>
    );
}

export const ExpandCollapseLayerItem = MapTo(
    'vwa-ngw18/components/editorial/items/expandCollapseLayerItem',
    InternalExpandCollapseLayerItem
);
