import * as React from 'react';

import {Text, styled} from '@volkswagen-onehub/components-core';

const StyledPersonalizedPlaceholder = styled.div`
    padding: ${props => props.theme.size.dynamic0100};
    border: 6px solid ${props => props.theme.colors.signal.neutral};
`;

export const PersonalizedPlaceholderElement: React.FunctionComponent<{
    id: string;
}> = props => (
    <StyledPersonalizedPlaceholder id={props.id}>
        <Text>Placeholder for personalized modules.</Text>
    </StyledPersonalizedPlaceholder>
);
