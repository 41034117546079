import {OrganizationData} from '../../../context/vwid/OrganizationData';
import {UserData} from '../../../context/vwid/UserData';
import {stripEmailDomain} from '../../../utils/stripEmailDomain';
export const getEmailUserName = (greeting: string, email: string) => {
    return `${greeting} ${stripEmailDomain(email)}`.trim();
};

export const getFallbackUserName = (
    greeting: string,
    fallbackUserName: string
): string => {
    return `${greeting} ${fallbackUserName}`.trim();
};

type UserNameProps = {
    greeting: string;
    fallbackUserName: string;
    userData: UserData | null;
    organizationData: OrganizationData | null;
    isLoggedIn: boolean;
};

export const getUserName = ({
    greeting,
    fallbackUserName,
    userData,
    organizationData,
    isLoggedIn
}: UserNameProps): string => {
    const organizationName = `${organizationData?.firstName ||
        organizationData?.lastName}, ${organizationData?.companyName}`;
    const privateName =
        userData?.given_name || userData?.family_name || fallbackUserName;
    const name = organizationData ? organizationName : privateName;
    const isUserNameAvailable = !isLoggedIn || userData || organizationData;

    return `${greeting} ${isUserNameAvailable ? name : ''}`.trim();
};
