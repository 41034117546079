import * as React from 'react';

import {
    Breakpoints,
    CTA as Cta,
    styled
} from '@volkswagen-onehub/components-core';
import {Clock} from '@volkswagen-onehub/icons-core';
import {LoginSessionTimeoutModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    useLoginAlertService,
    useLoginStore,
    useSpaAsyncConfig,
    useTrackingService
} from '../../../context';
import {SESSION_TIMEOUT_COOKIE_NAME} from '../../../context/vwid/ClientLoginStore';
import {ErrorStatus} from '../../../context/vwid/LoginStatus';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {getCookie} from '../../../utils/getCookie';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {C} from '../../../registries/compatibilty';

const LOGIN_SESSION_TIMEOUT_AUTHOR_MSG =
    'Continue Session Layer - This content is shown if session timeout is enabled and regular session timeout (aka variant 1) is maintained.';
const LOGIN_SESSION_TIMEOUT_EXTENDED_AUTHOR_MSG =
    'Extend Session Layer - This content is shown if extended session timeout (aka variant 2) is maintained in addition to regular session timeout.';
const LOGIN_SESSION_TIMEOUT_ENFORCED_AUTHOR_MSG =
    'Enforced Logout Layer - This content is shown if extended session timeout is maintained and the session can no longer be extended.';
const LOGIN_SESSION_TIMEOUT_EXPIRED_AUTHOR_MSG =
    'Expired Session Layer - This content is shown if the maintained session timeout has expired and the user was logged out.';

export enum LayerId {
    LOGIN_SESSION_TIMEOUT = 'loginSessionTimeout',
    LOGIN_SESSION_TIMEOUT_EXTENDED = 'loginSessionTimeoutExtended',
    LOGIN_SESSION_TIMEOUT_ENFORCED = 'loginSessionTimeoutEnforced',
    LOGIN_SESSION_TIMEOUT_EXPIRED = 'loginSessionTimeoutExpired'
}

type ExtraProps = {
    onClose: () => void;
};

export type LoginSessionTimeoutLayerProps = LoginSessionTimeoutModel &
    ExtraProps;

const StyledCounter = styled.time`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${props => props.theme.size.static100};
    font-size: ${props => props.theme.fonts.bigcopy[100].fontSize};
    line-height: ${props => props.theme.fonts.bigcopy[100].lineHeight};
    font-weight: bold;
`;

const StyledCTAs = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${props => props.theme.size.static150};

    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: row;
    }
`;

const StyledLayerContainer = styled.div`
    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.dynamic0100};
    text-align: center;

    padding: ${props =>
        `${props.theme.size.static350} ${props.theme.size.dynamic0130} ${props.theme.size.static400}`};

    // Note: remove once the copy element has no margin
    & [class*='StyledBodyWrapper'] {
        margin-bottom: 0;
    }
`;

export const formatTime = (time: number): string[] => {
    let seconds = Math.floor(time / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    const [formattedMin, formattedSec] = [minutes, seconds].map(unit => {
        return unit.toString().padStart(2, '0');
    });

    return [`${formattedMin}:${formattedSec}`, `PT0H${minutes}M${seconds}S`];
};

const getAuthorDescription = (layerId: string) => {
    let message;
    switch (layerId) {
        case LayerId.LOGIN_SESSION_TIMEOUT:
            message = LOGIN_SESSION_TIMEOUT_AUTHOR_MSG;
            break;
        case LayerId.LOGIN_SESSION_TIMEOUT_EXTENDED:
            message = LOGIN_SESSION_TIMEOUT_EXTENDED_AUTHOR_MSG;
            break;
        case LayerId.LOGIN_SESSION_TIMEOUT_ENFORCED:
            message = LOGIN_SESSION_TIMEOUT_ENFORCED_AUTHOR_MSG;
            break;
        case LayerId.LOGIN_SESSION_TIMEOUT_EXPIRED:
            message = LOGIN_SESSION_TIMEOUT_EXPIRED_AUTHOR_MSG;
    }
    return message;
};

export function InternalLoginSessionTimeoutLayer(
    props: LoginSessionTimeoutLayerProps
): JSX.Element {
    const {layerId} = props;
    const loginStore = useLoginStore();
    const loginAlertService = useLoginAlertService();
    const trackingService = useTrackingService();
    const {serviceConfigs} = useSpaAsyncConfig();

    const timeoutCookie = getCookie(SESSION_TIMEOUT_COOKIE_NAME);
    const endTime = new Date(Number(timeoutCookie)).getTime() ?? 0;
    const [time, setTime] = React.useState(Math.max(endTime - Date.now(), 0));
    const [formattedTime, dateTime] = formatTime(time);

    const heading = <HeadingElement order={'H2'} path={'heading'} style="H3" />;
    const copy = <CopyItem path={'copy'} />;

    const handleContinueSessionClick = async (rememberMe?: boolean) => {
        if (!(await loginStore.continueSession(rememberMe))) {
            loginAlertService.openAlert(
                ErrorStatus.unknownError,
                props.btnCloseLabel
            );
            setTimeout(() => window.location.reload(), 1000);
        }
        props.onClose();
        const url = serviceConfigs['auth-proxy'].urlOrigin + '/continueSession';
        trackingService.trackButtonClick(layerId, url, props.btnPrimaryLabel);
    };

    const handleLogoutClick = async () => {
        const url = serviceConfigs['auth-proxy'].urlOrigin + '/logout';
        trackingService.trackButtonClick(layerId, url, props.btnLogoutLabel);
        await loginStore.handleLogout();
    };

    React.useEffect(() => {
        if (time <= 0) {
            return;
        }

        const timeout = setTimeout(() => {
            setTime(Math.max(endTime - Date.now(), 0));
        }, 1000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [time, endTime]);

    let handleOnClick;
    switch (layerId) {
        case LayerId.LOGIN_SESSION_TIMEOUT:
            handleOnClick = () => handleContinueSessionClick();
            break;
        case LayerId.LOGIN_SESSION_TIMEOUT_EXTENDED:
            handleOnClick = () => handleContinueSessionClick(true);
            break;
        case LayerId.LOGIN_SESSION_TIMEOUT_ENFORCED:
        case LayerId.LOGIN_SESSION_TIMEOUT_EXPIRED:
            handleOnClick = () => props.onClose();
    }

    return (
        <AuthoringWrapper
            title="Login Session Timeout Layer"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            {C.isInEditor() && (
                <AlertBox severity={AlertBoxSeverity.INFO}>
                    <div>{getAuthorDescription(layerId)}</div>
                </AlertBox>
            )}
            <StyledLayerContainer>
                {heading}
                {copy}
                {layerId !== LayerId.LOGIN_SESSION_TIMEOUT_EXPIRED ? (
                    <StyledCounter dateTime={dateTime}>
                        <Clock ariaHidden />
                        {formattedTime}&nbsp;
                        {props.minutesLabel}
                    </StyledCounter>
                ) : null}
                <StyledCTAs>
                    <Cta
                        tag="button"
                        emphasis="primary"
                        type="button"
                        size="large"
                        onClick={handleOnClick}
                    >
                        {props.btnPrimaryLabel}
                    </Cta>
                    {layerId !== LayerId.LOGIN_SESSION_TIMEOUT_EXPIRED ? (
                        <Cta
                            tag="button"
                            emphasis="secondary"
                            type="button"
                            size="large"
                            onClick={handleLogoutClick}
                        >
                            {props.btnLogoutLabel}
                        </Cta>
                    ) : null}
                </StyledCTAs>
            </StyledLayerContainer>
        </AuthoringWrapper>
    );
}

export const LoginSessionTimeoutLayer = MapTo<ExtraProps>(
    'vwa-ngw18/components/structure/login/loginSessionTimeout',
    InternalLoginSessionTimeoutLayer
);
