import * as React from 'react';
import {
    Fade as InternalFade,
    FadeProps
} from '@volkswagen-onehub/components-core';
import {useIsClientOnlyRendering} from './useIsClientOnlyRendering';
import {useMediaQueries} from '../context/mediaQueries/mediaQueryContext';

export const Fade = (
    props: React.PropsWithChildren<FadeProps>
): JSX.Element => {
    const isClient = useIsClientOnlyRendering();
    const {reducedAnimation} = useMediaQueries();

    const shouldNotAnimate = !isClient || reducedAnimation;

    const newProps = {
        ...props,
        disableAnimation: shouldNotAnimate || props.disableAnimation
    };

    return <InternalFade {...newProps} />;
};
