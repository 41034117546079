import {SpaAsyncConfig} from '../../../generated/core';
import {definePageInfoService} from '@volkswagen-onehub/page-info-service';
import {CMS_CONSUMER_ID} from './initializeHub';
import {ServiceRegistration} from './ServiceRegistration';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {RootLocationDescriptorObject} from '@feature-hub/history-service';
import {Registry} from '../di/Registry';
import {CmsRootLocationTransformer} from './history/CmsRootLocationTransformer';
import {
    SECONDARY_CONSUMER_SERVICE,
    getSecondaryConsumerForMydealer
} from './getSecondaryConsumerForMydealer';

export interface ServerPageInfoOptions {
    serverPath: string;
    url: string;
}

const BASE_DUMMY_URL = 'https://www.vw.de';

export function createPageInfoServiceDefinition(
    spaAsyncConfig: SpaAsyncConfig,
    registry: Registry,
    serverOptions?: ServerPageInfoOptions
): ServiceRegistration<any> {
    const definition = definePageInfoService(
        getNavigationState(spaAsyncConfig, registry, serverOptions),
        {logoCategory: 'private'},
        [SECONDARY_CONSUMER_SERVICE]
    );

    return {
        definition,
        service: {
            typeName: 'PageInfoService',
            version: '^1.0.0'
        }
    };
}

export function getNavigationState(
    spaAsyncConfig: SpaAsyncConfig,
    registry: Registry,
    serverOptions?: ServerPageInfoOptions
) {
    return () => {
        const pathWithExtension = isInBrowser()
            ? window.location.pathname
            : serverOptions?.serverPath || '/';
        const url = isInBrowser()
            ? getBrowserUrl()
            : removeParameters(serverOptions?.url);
        const pathname = getPathName(serverOptions);
        const path = getRootPath(pathWithExtension);
        const mydealerSecondaryConsumerId = getSecondaryConsumerForMydealer(
            spaAsyncConfig,
            serverOptions?.serverPath
        );
        const secondaryConsumerId =
            spaAsyncConfig.featureHubModel.secondaryHistoryKeysForPath[path] ||
            mydealerSecondaryConsumerId;

        const secondaryPath = getSecondaryPath(
            registry,
            {pathname},
            secondaryConsumerId
        );

        return {
            secondaryConsumerId,
            primaryConsumerId: CMS_CONSUMER_ID,
            currentCanonicalUrl: url,
            subPage: !!secondaryPath && secondaryPath !== '/'
        };
    };
}

const getSecondaryPath = (
    registry: Registry,
    url: RootLocationDescriptorObject,
    secondaryConsumerId?: string
): string | undefined => {
    if (!secondaryConsumerId) {
        return undefined;
    }
    const rootLocationTransformer: CmsRootLocationTransformer = registry.getSingleton(
        'RootLocationTransformer'
    );
    const path = rootLocationTransformer.getConsumerPathFromRootLocation(
        url,
        secondaryConsumerId
    );
    if (!path) {
        return undefined;
    }
    const fullUrl = new URL(path, BASE_DUMMY_URL);

    return fullUrl.pathname;
};

function getPathName(serverOptions: ServerPageInfoOptions | undefined) {
    return isInBrowser()
        ? window.location.pathname
        : new URL(serverOptions?.url || BASE_DUMMY_URL).pathname;
}

function getRootPath(path: string) {
    // remove everything after first dot starting the fiist extension
    const matchSelectors = path.match(/([^.]+)\./);
    if (matchSelectors && matchSelectors.length >= 1) {
        path = matchSelectors[1];
    }
    return path;
}
function removeParameters(urlWithExtension?: string) {
    if (!urlWithExtension) {
        return '';
    }
    try {
        const url = new URL(urlWithExtension);
        url.search = '';
        url.hash = '';
        return url.toString();
    } catch (e) {
        return '';
    }
}
function getBrowserUrl() {
    return removeParameters(window.location.href);
}
