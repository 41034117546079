import {
    styled,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {TechData} from '../../../../generated/core';
import {ANIMATION_DURATION} from '../../../utils/smoothScroll';
import {Direction, hyphens} from '../helpers';
import {useOrderedTileValues} from './useOrderedTileValues';

// even if the line clamp has a webkit prefix, it is also supported in firefox: https://caniuse.com/css-line-clamp
// flex-basis: max-content is only supported in safari 16, until then the 100% will be used
const StyledTechnicalDataTile = styled.li`
    margin: 0;

    flex: 1 0 100%;
    flex: 1 0 max-content;

    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.static150};

    max-width: clamp(240px, 70vw, 320px);

    padding: ${props => props.theme.size.static200};
    border: 1px solid ${props => props.theme.colors.border.default};
    border-radius: ${props => props.theme.size.static150};

    &:focus {
        outline: 2px solid ${props => props.theme.colors.border.active};
        outline-offset: -2px;
    }

    & > span {
        ${hyphens}
    }
`;
StyledTechnicalDataTile.displayName = 'StyledTechnicalDataTile';

const MAX_FRAME_DIFFERENCE = 10;

// this function is needed to allow disclaimer clicks and scroll effects happened slightly delayed
// otherwise the disclaimer click will not get registered
export const waitForScrollEnd = () => {
    let lastFrame = 0;
    let lastY = window.scrollY;

    return new Promise<string>(resolve => {
        const tick = (frames: number) => {
            if (
                frames >= ANIMATION_DURATION ||
                frames - lastFrame > MAX_FRAME_DIFFERENCE
            ) {
                return resolve('scroll ended');
            }

            if (window.scrollY !== lastY) {
                lastFrame = frames;
                lastY = window.scrollY;
            }
            requestAnimationFrame(() => tick(frames + 1));
        };
        tick(0);
    });
};

const centerTile = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const tile = event.currentTarget;
    if (!tile) {
        return;
    }

    const parentWrapper = tile.parentElement;

    if (parentWrapper) {
        const {x, width} = tile.getBoundingClientRect();
        const tileCenterPosition = x + width / 2;
        const scrollContainerCenter =
            parentWrapper.getBoundingClientRect().width / 2;
        const distanceToParentCenter =
            tileCenterPosition - scrollContainerCenter;

        parentWrapper.scrollBy({
            behavior: 'smooth',
            left: distanceToParentCenter
        });
    }
};

const handleFocus = async (event: React.FocusEvent) => {
    const element = event.target;
    if (!element) {
        return;
    }
    await waitForScrollEnd();

    try {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
        });
    } catch (_) {
        //Remove this when Safari/iOS 16 are the latest supported Versions in the browser matrix
        element.scrollIntoView(false);
    }
};

export interface TechnicalDataTileProps extends Omit<TechData, 'disclaimers'> {
    infoLabelButton?: JSX.Element | null;
    direction: Direction;
    disclaimers: JSX.Element | null;
}

export const TechnicalDataTile = (
    props: TechnicalDataTileProps
): JSX.Element => {
    const {label, infoLabelButton, direction, disclaimers} = props;
    const ref = React.useRef<HTMLLIElement>(null);

    const orderedValues = useOrderedTileValues({...props, direction});

    return (
        <StyledTechnicalDataTile
            aria-describedby={label}
            tabIndex={0}
            ref={ref}
            onFocus={handleFocus}
            onClick={centerTile}
        >
            {infoLabelButton ? (
                infoLabelButton
            ) : (
                <Text
                    appearance={TokenTextAppearance.copy100}
                    tag={TextTag.span}
                >
                    {label}
                </Text>
            )}
            <Text
                appearance={TokenTextAppearance.headline200}
                bold
                tag={TextTag.span}
            >
                {orderedValues.join(' ')}
            </Text>
            {disclaimers ? disclaimers : null}
        </StyledTechnicalDataTile>
    );
};
