import * as React from 'react';
import {
    Container,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {AlertBox, AlertBoxSeverity} from '../../components/AlertBox';
import {getContainerPadding} from '../../d6/components/helpers';
import {AuthoringWrapper} from '../../components/AuthoringWrapper';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {C} from '../../registries/compatibilty';
import {HeadingElement} from '../editorial/elements/HeadingElement';
import {CopyItem} from '../editorial/items/CopyItem';

const CONTAINER_PADDING_2_3_5 = getContainerPadding([2, 3, 5]);

const UOSNAlertBox: React.FunctionComponent = () => (
    <AlertBox severity={AlertBoxSeverity.INFO}>
        <div>
            {
                'This message is only shown to users with an unsupported OS version.'
            }
        </div>
    </AlertBox>
);

class InternalUnsupportedOSNotificationAuthoring extends React.Component {
    public render(): JSX.Element | null {
        if (!C.isInEditor()) {
            return null;
        }

        return (
            <AuthoringWrapper
                title="Unsupported OS notification"
                bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            >
                <UOSNAlertBox />
                <Layout>
                    <Container
                        stretchContent
                        wrap={ContainerWrap.always}
                        padding={{
                            left: CONTAINER_PADDING_2_3_5,
                            right: CONTAINER_PADDING_2_3_5
                        }}
                    >
                        <HeadingElement path="heading" order="H2" />
                        <CopyItem path="copy" useDropCap={false} />
                    </Container>
                </Layout>
            </AuthoringWrapper>
        );
    }
}

export const UnsupportedOSNotification = MapTo(
    'vwa-ngw18/components/structure/unsupportedOSNotification',
    InternalUnsupportedOSNotificationAuthoring
);
