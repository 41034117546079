import * as React from 'react';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';
import {TimeConversions} from './countdown';

const StyledCounter = styled.time`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.size.static250};

    @media (min-width: ${Breakpoints.b560}px) {
        justify-content: flex-start;
    }
`;

const StyledLabelCount = styled.div<{hiddenOnMobile: boolean}>`
    text-align: center;

    ${props =>
        props.hiddenOnMobile &&
        `
    @media (max-width: ${Breakpoints.b560 - 1}px) {
        display: none
    }
    `}
`;

const StyledCount = styled.div`
    --character-padding: ${props => props.theme.size.static150};
    --box-height: calc(
        ${props => props.theme.fonts.headline[400].fontSize} +
            (2 * var(--character-padding))
    );

    overflow: hidden;
    display: flex;
    height: var(--box-height);
    margin-block-end: ${props => props.theme.size.static150};
    gap: ${props => props.theme.size.static100};

    @media (min-width: ${Breakpoints.b560}px) {
        --character-padding: ${props => props.theme.size.static200};
    }
`;

const StyledBackground = styled.div`
    background: ${props => props.theme.colors.background.tertiary};
    border-radius: ${props => props.theme.size.static150};
`;

const StyledNumberColumn = styled.div<{index: number}>`
    width: ${props => props.theme.fonts.headline[400].fontSize};
    transform: translateY(
        -${props => (props.index === 0 ? '0' : props.index + '0')}%
    );
    transition: transform 0.5s ease 0.5s;

    ::before {
        content: '9 8 7 6 5 4 3 2 1 0';
        font-size: ${props => props.theme.fonts.headline[400].fontSize};
        font-family: ${props => props.theme.fonts.headline[400].fontFamily};
        font-weight: ${props => props.theme.text.weight.light};
        line-height: var(--box-height);
    }
`;

const NumberRoller = ({count}: {count: string}): JSX.Element => {
    const numberArr = ['9', '8', '7', '6', '5', '4', '3', '2', '1', '0'];
    const currIndex = numberArr.findIndex(value => value === count);

    return (
        <StyledBackground>
            <StyledNumberColumn index={currIndex} />
        </StyledBackground>
    );
};

export const CounterRow = (props: TimeConversions): JSX.Element => {
    const {counter, dateTime} = props;
    const daysExpired = counter[0].time.every(value => value === '0');

    return (
        <StyledCounter dateTime={dateTime}>
            {counter.map(({label, time}, index, array) => {
                const hideDays = index === 0 && daysExpired;
                const hideSeconds = index === array.length - 1 && !daysExpired;
                return (
                    <StyledLabelCount
                        hiddenOnMobile={hideDays || hideSeconds}
                        key={index}
                    >
                        <StyledCount>
                            {time.map((count, idx) => {
                                return <NumberRoller key={idx} count={count} />;
                            })}
                        </StyledCount>
                        <Text
                            tag={TextTag.p}
                            appearance={TokenTextAppearance.copy200}
                        >
                            {label}
                        </Text>
                    </StyledLabelCount>
                );
            })}
        </StyledCounter>
    );
};
