import React from 'react';
import ReactDOM from 'react-dom';
import {RenderPaginationProps} from './index';

export type TabPaginationConsumerProps = Readonly<{
    tabListKeyDownHandler(e: React.KeyboardEvent): void;
    getTabClickHandler(index: number): (e: React.MouseEvent) => void;
}>;

type TabPaginationProps = Omit<RenderPaginationProps, 'paginationLabel'> &
    Readonly<{
        tabListRef: React.RefObject<HTMLElement>;
        children(props: TabPaginationConsumerProps): React.ReactNode;
    }>;

export class TabPagination extends React.Component<TabPaginationProps> {
    private readonly focusTab = (index: number) => {
        const wrapperElement = ReactDOM.findDOMNode(
            this.props.tabListRef.current
        ) as null | HTMLDivElement;

        const tabElements = wrapperElement?.children;

        if (tabElements?.[index]) {
            // Use preventScroll in supported browsers, so our own scroll
            // (i.e. Highligh Gallery thumbnails) will not be disrupted.
            // Doesn't break anything if not supported, the animation will
            // just sometimes under/overshoot a little.
            (tabElements[index] as HTMLElement).focus({preventScroll: true});
        }
    };

    private readonly getTabClickHandler = (index: number) => (
        e: React.MouseEvent
    ) => {
        this.props.onSlideSelect(index);
        this.focusTab(index);
        e.preventDefault();
    };

    private readonly tabListKeyDownHandler = (e: React.KeyboardEvent): void => {
        const {activeIndex, paginationData, onSlideSelect} = this.props;

        let newIndex = activeIndex;

        switch (e.key) {
            case 'ArrowLeft': {
                // cycles through
                newIndex =
                    (paginationData.length + activeIndex - 1) %
                    paginationData.length;
                break;
            }
            case 'ArrowRight': {
                // cycles through
                newIndex = (activeIndex + 1) % paginationData.length;
                break;
            }
            case 'Home': {
                newIndex = 0;
                break;
            }
            case 'End': {
                newIndex = paginationData.length - 1;
            }
        }

        if (newIndex !== activeIndex) {
            onSlideSelect(newIndex);
            this.focusTab(newIndex);
            e.preventDefault();
        }
    };

    public render(): JSX.Element {
        const {children} = this.props;
        const {tabListKeyDownHandler, getTabClickHandler} = this;

        return (
            <>
                {children({
                    tabListKeyDownHandler,
                    getTabClickHandler
                })}
            </>
        );
    }
}
