import {
    SharedImageService,
    defineImageService
} from '@volkswagen-onehub/image-service';
import {FeatureHubModel} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';

export function createImageService(
    featureHubModel: FeatureHubModel
): ServiceRegistration<SharedImageService> {
    const fsImageModel = featureHubModel.featureServices.imageService;
    /** when atOffer == true (export to Adobe Target), we need to use live domains instead of preview ones
     * author has to be false, to be able to use live domains
     */
    const author = fsImageModel.atOffer ? false : fsImageModel.author;

    return {
        definition: defineImageService({...fsImageModel, author}),
        service: {
            typeName: 'ImageService',
            version: '^1.1.1'
        }
    };
}
