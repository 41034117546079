import {Model} from '@adobe/cq-react-editable-components';
import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import * as React from 'react';
import {AnchorLinkModel} from '../../../generated/core';
import {
    useModelContext,
    useModelStore,
    useNavigationStore
} from '../../context';
import {ModelStore} from '../../context/model/ModelStore';
import {
    InPageMenuItemModel,
    NavigationStore
} from '../../context/navigation/NavigationStore';
import {RenderContext} from '../../context/RenderContext';
import {ValueFactory} from '../../utils/contextUtils';
import {IncludeProps} from '../api/Compatibility';
import {modelToProps} from '../api/modelToProps';
import {getGlobalOptions} from './MapTo';
import {ComponentMapping, ModelContext} from './ModelContext';

const EMPTY_CONTENT = {};
const SECTION_GROUP_RESOURCE = 'vwa-ngw18/components/structure/sectionGroup';

export const getOriginalNavItemIndexesToReplace = (
    originalModel: TransferModel,
    inPageNavigationItems: InPageMenuItemModel[]
): number[] => {
    // get the anchor id from the default content
    const anchorId = originalModel['anchorId'];
    const itemToUpdate = inPageNavigationItems?.find(
        _item => _item.id === anchorId
    );
    const originalNavigationItemIndexes: number[] = [];

    if (itemToUpdate) {
        originalNavigationItemIndexes.push(itemToUpdate.index);

        if (
            originalModel[':type'] === SECTION_GROUP_RESOURCE &&
            originalModel[':items']['groupParsys'][':itemsOrder']
        ) {
            originalModel[':items']['groupParsys'][':itemsOrder'].forEach(
                (_item, _index) => {
                    const currentChildItem =
                        originalModel[':items']['groupParsys'][':items'][_item];

                    if (
                        currentChildItem?.inPageNavigationTitle &&
                        currentChildItem?.enabledSectionInPageNav
                    ) {
                        originalNavigationItemIndexes.push(
                            +itemToUpdate.index + _index + 1
                        );
                    }
                }
            );
        }
    }

    return originalNavigationItemIndexes;
};

export const getPersonalizedNavItems = (
    tModel: TransferModel
): AnchorLinkModel[] => {
    const personalizedMainItemKey = tModel[':itemsOrder']?.[0];
    const personalizedNavigationItems: AnchorLinkModel[] = [];

    if (personalizedMainItemKey) {
        const personalizedMainItem = tModel[':items'][personalizedMainItemKey];

        personalizedNavigationItems.push({
            anchorId: personalizedMainItem.anchorId,
            inPageNavigationTitle: personalizedMainItem.inPageNavigationTitle,
            enabledSectionInPageNav:
                personalizedMainItem.enabledSectionInPageNav
        });

        if (
            personalizedMainItem[':type'] === SECTION_GROUP_RESOURCE &&
            personalizedMainItem[':items']['groupParsys'][':itemsOrder']
        ) {
            personalizedMainItem[':items']['groupParsys'][
                ':itemsOrder'
            ].forEach((_item, _index) => {
                const personalizedChildItem =
                    personalizedMainItem[':items']['groupParsys'][':items'][
                        _item
                    ];

                personalizedNavigationItems.push({
                    anchorId: personalizedChildItem.anchorId,
                    inPageNavigationTitle:
                        personalizedChildItem.inPageNavigationTitle,
                    enabledSectionInPageNav:
                        personalizedChildItem.enabledSectionInPageNav
                });
            });
        }
    }

    return personalizedNavigationItems;
};

export const getUpdatedNavigationItems = (
    inPageNavigationItems: InPageMenuItemModel[],
    originalNavItemIndexesToReplace: number[],
    personalizedItems: AnchorLinkModel[]
): AnchorLinkModel[] => {
    const updatedInPageNavigationItems = inPageNavigationItems.map(
        (_item: InPageMenuItemModel) => {
            return {
                inPageNavigationTitle: _item.title,
                anchorId: _item.id,
                enabledSectionInPageNav: _item.visible
            } as AnchorLinkModel;
        }
    );

    if (!originalNavItemIndexesToReplace.length) {
        return updatedInPageNavigationItems;
    }

    originalNavItemIndexesToReplace.forEach((_index, _iteration) => {
        updatedInPageNavigationItems.splice(_index - _iteration, 1);
    });

    personalizedItems.forEach((_item, _index) => {
        updatedInPageNavigationItems.splice(
            originalNavItemIndexesToReplace[0] + _index,
            0,
            _item
        );
    });

    return updatedInPageNavigationItems;
};

export const getShouldUpdateNavigationItems = (
    inPageNavigationItems: InPageMenuItemModel[],
    updatedInPageNavigationItems: AnchorLinkModel[]
): boolean => {
    return (
        inPageNavigationItems.length !== updatedInPageNavigationItems.length ||
        inPageNavigationItems.some(
            (_item: InPageMenuItemModel) =>
                !Boolean(
                    updatedInPageNavigationItems.find(
                        (_updatedItem: AnchorLinkModel) =>
                            _item.id === _updatedItem.anchorId &&
                            _item.title ===
                                _updatedItem.inPageNavigationTitle &&
                            _item.visible ===
                                _updatedItem.enabledSectionInPageNav
                    )
                )
        )
    );
};
export const Include = (props: IncludeProps) => {
    const modelStore = useModelStore();
    const modelContext = useModelContext();
    const navigationStore = useNavigationStore();
    const modelContextValueFactory: ValueFactory<string> = React.useRef(
        new ValueFactory<string>()
    ).current;

    const IncludedComponent = ComponentMapping.get(props.resourceType);
    const options = getGlobalOptions(props.resourceType) || {};
    const noElementFromContext = React.useContext(RenderContext).noElement;
    // we need the aem div to add the hiding of personalized content. Can be removed once swapping is fully implemented
    const noElement =
        !props.personalizable &&
        (props.noElement ||
            options.noElement ||
            options.noElementRecursively ||
            noElementFromContext);

    const {
        defaultContentPath,
        path,
        resourceType,
        containerProps,
        extraProps,
        forceReload
    } = props;
    const contextPath: string = modelContext.value || '/';
    const absolutePath = path.match(/^\//);

    const absPath = !!absolutePath ? path : `${contextPath}/${path}`;

    const tModel = modelStore.getData(absPath, false);

    React.useEffect(() => {
        // if this is personalized content and a default content path s avalable
        updateInPageNavigation(
            defaultContentPath,
            modelStore,
            tModel,
            navigationStore
        );
    }, [defaultContentPath, modelStore, navigationStore, tModel]);

    if (!IncludedComponent) {
        return null;
    }

    if (!tModel) {
        console.warn(`cannot find data for ${absPath}`);

        return <span>Cannot find Data for {absPath}</span>;
    }

    const item: Model | {} = tModel
        ? modelToProps(tModel, absPath)
        : EMPTY_CONTENT;
    const resourceShortName = resourceType.split('/').pop();

    const classNameSuffix =
        containerProps && containerProps.className
            ? ` ${containerProps.className}`
            : '';
    const className = `${resourceShortName}${classNameSuffix}`;

    const contextData = modelContextValueFactory.create(absPath);

    const include = (
        <ModelContext.Provider value={contextData}>
            <IncludedComponent
                cqPath={absPath}
                {...item}
                extraProps={extraProps}
                containerProps={{
                    ...containerProps,
                    className
                }}
                forceReload={forceReload}
                noElement={noElement}
            />
        </ModelContext.Provider>
    );

    if (options.noElementRecursively && !noElementFromContext) {
        return (
            <RenderContext.Provider value={{noElement: true}}>
                {include}
            </RenderContext.Provider>
        );
    }
    return include;
};
function updateInPageNavigation(
    defaultContentPath: string | undefined,
    modelStore: ModelStore,
    tModel: TransferModel,
    navigationStore: NavigationStore
) {
    if (defaultContentPath) {
        const originalModel = modelStore.getData(defaultContentPath, false);

        // check anchorId in original model
        // check the first section in personalized content
        if (
            originalModel &&
            originalModel['anchorId'] &&
            tModel[':type'] === 'vwa-ngw18/components/structure/parsys' &&
            tModel[':itemsOrder'].length > 0 &&
            navigationStore.inPageNavigationType !== 'external'
        ) {
            const originalNavItemIndexesToReplace = getOriginalNavItemIndexesToReplace(
                originalModel,
                navigationStore.inPageNavigationItems
            );

            const personalizedNavigationItems = getPersonalizedNavItems(tModel);

            const updatedInPageNavigationItems = getUpdatedNavigationItems(
                navigationStore.inPageNavigationItems,
                originalNavItemIndexesToReplace,
                personalizedNavigationItems
            );

            if (
                getShouldUpdateNavigationItems(
                    navigationStore.inPageNavigationItems,
                    updatedInPageNavigationItems
                )
            ) {
                navigationStore.setInPageNavigationItems(
                    updatedInPageNavigationItems
                );
            }
        }
    }
}
