import {AbTestModel} from '../../generated/core';
import {useRegistry} from '../context';
import {AbTestService} from '../context/abtest/AbTestService';
import {useIsClientOnlyRendering} from '../utils/useIsClientOnlyRendering';

/**
 *
 * @param testName name of ab test
 * @returns undefined during ssr and hydration. Otherwise true for variant B and false for variant A
 */

export const useAbTest = (testName: keyof AbTestModel): boolean | undefined => {
    const client = useIsClientOnlyRendering();
    const abTestService: AbTestService = useRegistry().getSingleton(
        'AbTestService'
    );

    return client ? abTestService.getAbTestVariant(testName) : undefined;
};
