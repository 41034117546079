import * as React from 'react';
import {LoginStore} from '../../../context/vwid/LoginStore';
import {UserData} from '../../../context/vwid/UserData';

export function createBtnSettingsClick(
    userData: UserData | null,
    loginStore: LoginStore,
    addCommercialSettingsHandle: boolean
) {
    return (e: React.MouseEvent) => {
        e.preventDefault();
        if (userData) {
            loginStore.openProfileTab();
        } else if (addCommercialSettingsHandle) {
            loginStore.openCommercialProfileTab();
        }
    };
}
