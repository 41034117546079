import * as React from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

import {ChevronDown} from '@volkswagen-onehub/icons-core';
import {
    getElectricTransitionAdvanced,
    getHeight,
    resetButtonStyles
} from '../helpers';

import {ChildContainerTabIndexManager} from '../highlight-gallery-v3/tabindex-manager';

export interface ExpandableAreaProps {
    readonly children: React.ReactNode | React.ReactNode[];
    readonly visibleArea?: number;
    readonly ariaLabelExpand?: string;
    readonly ariaLabelCollapse?: string;
}

export interface StyledCollapsedProps {
    readonly isCollapsed?: boolean;
    readonly enabledCollapse?: boolean;
    readonly contentHeight?: number;
    readonly visibleArea?: number;
}

export interface StyledButtonProps {
    readonly isCollapsed?: boolean;
    readonly chevronRotation?: number;
}

const BUTTON_SIZE = 44;
const BUTTON_FOCUS_OFFSET = 4;
const DEFAULT_VISIBLE_AREA = 150;
const DEFAULT_OVERLAY_AREA = 100;
const ANIMATION_DURATION = 0.4;
const RESIZE_THROTTLE = 500; // ms

const StyledExpandableWrapper = styled.div<StyledCollapsedProps>`
    ${props =>
        props.enabledCollapse &&
        `
		position: relative;
        z-index: 0;
		padding-bottom: ${BUTTON_SIZE}px;
		overflow: hidden;
		height: calc(${props.contentHeight}px + ${props.theme.size.static250});
		transition: ${getElectricTransitionAdvanced({
            name: 'height',
            duration: `${ANIMATION_DURATION}s`
        })};

		${props.isCollapsed &&
            `
				height: ${props.visibleArea ? props.visibleArea : DEFAULT_VISIBLE_AREA}px;
                [data-content] {
                    pointer-events: none;
                }
			`};

		`};
`;
StyledExpandableWrapper.displayName = 'StyledExpandableWrapper';

const StyledButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    padding-bottom: ${BUTTON_FOCUS_OFFSET}px;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        height: ${BUTTON_SIZE / 2 + BUTTON_FOCUS_OFFSET / 2}px;
    }
`;
StyledButtonContainer.displayName = 'StyledButtonContainer';

const StyledLine = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -${BUTTON_FOCUS_OFFSET / 2};

    height: 2px;
    background: ${props => props.theme.colors.border.divider};
`;
StyledLine.displayName = 'StyledLine';

const StyledOverlay = styled.div<StyledCollapsedProps>`
    position: absolute;
    bottom: ${BUTTON_SIZE / 2 + BUTTON_FOCUS_OFFSET / 2}px;
    left: 0;
    right: 0;
    height: ${DEFAULT_OVERLAY_AREA}px;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    pointer-events: none;
    transition: ${getElectricTransitionAdvanced({
        name: 'opacity',
        duration: `${ANIMATION_DURATION * 2}s`
    })};
    opacity: 0;

    ${props =>
        props.isCollapsed &&
        `
			opacity: 1;
		`};
`;
StyledOverlay.displayName = 'StyledOverlay';

const StyledButtonInner = styled.span<StyledButtonProps>`
    position: relative;
    display: flex;
    align-items: center;

    margin: 0 auto;
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
    border-radius: 50%;
    background: ${props => props.theme.colors.button.primary.default};

    color: ${props => props.theme.colors.content.inverted};
    display: flex;
    justify-content: center;

    svg {
        transition: ${getElectricTransitionAdvanced({
            name: 'transform',
            duration: `${ANIMATION_DURATION}s`
        })};
        transform: translate3d(0, 0, 0)
            rotate(${props => props.chevronRotation}deg);
    }

    // focus
    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: none;
        border: solid 2px ${props => props.theme.colors.button.primary.hover};
        border-radius: 50%;
        margin: -${BUTTON_FOCUS_OFFSET}px;
    }
`;
StyledButtonInner.displayName = 'StyledButtonInner';

const StyledButton = styled.button<StyledButtonProps>`
    ${resetButtonStyles};

    position: relative;
    width: 100%;

    &:focus {
        outline: none;
    }

    &:hover ${StyledButtonInner}, &:focus ${StyledButtonInner} {
        background: ${props => props.theme.colors.button.primary.hover};
    }

    &:focus ${StyledButtonInner} {
        &:after {
            display: block;
        }
    }
`;
StyledButton.displayName = 'StyledButton';

export const ExpandableArea: React.FunctionComponent<ExpandableAreaProps> = (
    props: ExpandableAreaProps
) => {
    const {ariaLabelCollapse, ariaLabelExpand, children, visibleArea} = props;

    const refContent = React.useRef<HTMLDivElement>(null);

    const [isCollapsed, setIsCollapsed] = React.useState(true);
    const [enabledCollapse, setEnabledCollapse] = React.useState(false);
    const [contentHeight, setContentHeight] = React.useState(0);
    const [chevronRotation, setChevronRotation] = React.useState(0);

    const buttonLabel = isCollapsed ? ariaLabelExpand : ariaLabelCollapse;

    const handleResize = throttle(() => {
        dimensionsCheck();
    }, RESIZE_THROTTLE);

    React.useEffect(() => {
        dimensionsCheck(); // init
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // listeners set only for initial call, so no dependecy needed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dimensionsCheck = (): void => {
        if (refContent) {
            const elHeight = getHeight(refContent) + BUTTON_SIZE;
            const enable = elHeight > DEFAULT_VISIBLE_AREA;
            setContentHeight(elHeight);
            setEnabledCollapse(enable);
        }
    };

    const handleClick = React.useCallback((): void => {
        const elHeight = getHeight(refContent) + BUTTON_SIZE;
        setContentHeight(elHeight);

        if (!isCollapsed) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
        setChevronRotation(prev => prev + 180);
    }, [isCollapsed]);

    return (
        <StyledExpandableWrapper
            visibleArea={visibleArea}
            enabledCollapse={enabledCollapse}
            isCollapsed={isCollapsed}
            contentHeight={contentHeight}
        >
            {enabledCollapse ? (
                <>
                    <StyledButtonContainer data-cy="expandableButtonArea">
                        <StyledOverlay isCollapsed={isCollapsed} />
                        <StyledLine />
                        <StyledButton
                            aria-expanded={enabledCollapse && !isCollapsed}
                            aria-label={buttonLabel}
                            isCollapsed={isCollapsed}
                            onClick={handleClick}
                        >
                            <StyledButtonInner
                                chevronRotation={chevronRotation}
                            >
                                <ChevronDown ariaHidden />
                            </StyledButtonInner>
                        </StyledButton>
                    </StyledButtonContainer>
                    <ChildContainerTabIndexManager
                        childContainerSelector="[data-content]"
                        activeChildContainerSelector={`[data-expanded="${!isCollapsed}"]`}
                    >
                        <div
                            ref={refContent}
                            aria-hidden={isCollapsed}
                            data-content
                            data-expanded={
                                enabledCollapse && !isCollapsed
                                    ? !isCollapsed
                                    : undefined
                            }
                            data-cy="collapsableContent"
                        >
                            {children}
                        </div>
                    </ChildContainerTabIndexManager>
                </>
            ) : (
                <div ref={refContent} data-cy="collapsableContent">
                    {children}
                </div>
            )}
        </StyledExpandableWrapper>
    );
};
