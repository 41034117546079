import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {PageInfoServiceV1} from '@volkswagen-onehub/page-info-service';
import {ContentStore} from '../content/ContentStore';
import {PageRootModel, SpaAsyncConfig} from '../../../generated/core';
import {observable} from 'mobx';

@singleton('LogoStore')
export class LogoStore {
    @inject()
    protected pageInfoService!: PageInfoServiceV1;

    @inject()
    protected contentStore!: ContentStore;

    @inject()
    private spaAsyncConfig!: SpaAsyncConfig;

    @observable
    public logoClaim: string;

    public constructor() {
        this.logoClaim = '';
    }

    @postConstruct()
    public initialize(): void {
        this.pageInfoService.subscribe(() => {
            this.updateLogo();
        });
    }

    private updateLogo(): void {
        if (this.pageInfoService.getPageInfo().logoCategory === 'private') {
            this.logoClaim = '';
        } else {
            // commercial
            if (this.pageInfoService.getSource() === 'secondary') {
                this.logoClaim = this.spaAsyncConfig.featureAppCommercialClaim;
                return;
            }
            const claimText = getLogoClaim(
                this.contentStore.getCurrentPageRootModel()
            );
            this.logoClaim = claimText || '';
        }

        function getLogoClaim(
            pageRootModel?: PageRootModel
        ): string | undefined {
            const claim = pageRootModel ? pageRootModel.claim : undefined;
            if (!claim) {
                return undefined;
            }
            const [first, second] = claim.trim().split(' ');
            if (!first) {
                return undefined;
            }
            let temp = `${first}`;
            if (second) {
                temp += ` ${second}`;
            }

            return temp;
        }
    }
}
