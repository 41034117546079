import * as React from 'react';
import {useNavigationStore} from '../../context';
import {C} from '../../registries/compatibilty';
import {AlertBox, AlertBoxSeverity} from '../AlertBox';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export function handleFeatureAppError(error: Error): void {
    console.error('Error during startup of feature app', error);
}

export function RenderFeatureAppError(props: {
    anchorId: string;
}): JSX.Element | null {
    const {anchorId} = props;

    // set visibility to false fa has an error
    const navigationStore = useNavigationStore();
    React.useEffect(() => {
        if (anchorId) {
            navigationStore.setInPageItemsVisibility([anchorId], false);
        }
    }, [navigationStore, anchorId]);

    return !isInBrowser() || C.isInEditor() ? (
        <FeatureAppLoadErrorPublish />
    ) : null;
}

export function SimpleError(props: React.PropsWithChildren<{}>): JSX.Element {
    return (
        <AlertBox severity={AlertBoxSeverity.ERROR}>
            <span>{props.children}</span>
        </AlertBox>
    );
}

export function FeatureAppLoadErrorPublish(): JSX.Element {
    return (
        <SimpleError>Error during loading of feature application</SimpleError>
    );
}

export function FeatureAppLoadErrorAuthor(): JSX.Element {
    return (
        <SimpleError>
            Error occured while loading feature app. On publish instances,
            nothing will be shown.
        </SimpleError>
    );
}

export function FeatureAppUrlValidationError(): JSX.Element {
    return <SimpleError>Please choose a feature app.</SimpleError>;
}

export function FeatureAppConfigValidationError(): JSX.Element {
    return (
        <SimpleError>
            Feature app configuration has no valid json format.
        </SimpleError>
    );
}
