import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';
import {ErrorMessageModel} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';

const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

export type ErrorMessageProps = ErrorMessageModel & ErrorMessageExtraProps;
export interface ErrorMessageExtraProps {
    closeButton?: JSX.Element;
}

export class InternalErrorMessage extends React.PureComponent<
    ErrorMessageProps
> {
    public render(): React.ReactNode {
        const heading = <HeadingElement path="heading" order="H2" />;
        const copy = <CopyItem path="copy" />;
        const {closeButton} = this.props;

        return C.isInEditor()
            ? this.renderAuthor(heading, copy)
            : this.renderPublish(heading, copy, closeButton);
    }

    private readonly renderAuthor = (
        heading: React.ReactNode,
        copy: React.ReactNode
    ): React.ReactNode => {
        const {errorStatus = 'unknown'} = this.props;

        return (
            <AuthoringWrapper
                title="Login Error Message"
                bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
            >
                <AlertBox severity={AlertBoxSeverity.WARNING}>
                    <div>
                        {`This error message is shown for the error status "${errorStatus}"`}
                    </div>
                </AlertBox>
                <Layout>
                    <Container
                        gutter={ContainerGutter.dynamic0100}
                        wrap={ContainerWrap.always}
                        padding={{
                            left: CONTAINER_PADDING_2_6,
                            right: CONTAINER_PADDING_2_6
                        }}
                        stretchContent
                    >
                        {heading}
                        {copy}
                    </Container>
                </Layout>
            </AuthoringWrapper>
        );
    };

    private readonly renderPublish = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        closeButton: React.ReactNode
    ): React.ReactNode => {
        return (
            <ThemeProvider theme="inverted">
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={ContainerPadding.dynamic0200}
                    stretchContent
                >
                    {closeButton}
                    {heading}
                    {copy}
                </Container>
            </ThemeProvider>
        );
    };
}

export const ErrorMessage = MapTo<ErrorMessageExtraProps>(
    'vwa-ngw18/components/structure/login/errorMessage',
    InternalErrorMessage
);
