import * as React from 'react';
import {C} from '../../registries/compatibilty';

import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {PageRootModel} from '../../../generated/core';
import {useContentStore, useNavigationService} from '../../context';
import {Include} from '../../infrastructure/compatibility/Include';
import {DynamicComponentLoader} from './DynamicComponentLoader';
import {HeaderUpdater} from './HeaderUpdater';

const CenteredText = styled.div`
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
`;

const Loading = styled.div`
    height: 100vh;
    position: relative;
`;

const EditorModeLoading = styled(Loading)`
    height: 200px;
`;

export interface PageLoaderProps {
    path: string;
    loadingLabel: string;
}

const PAGE_ROOT_TYPE = 'vwa-ngw18/components/structure/pageroot';

function useNavigationServiceUpdater(
    path: string
): (el: HTMLSpanElement) => void {
    const navigationService = useNavigationService();

    function onRender(el: HTMLSpanElement | null): void {
        // when the el reference is available the page is rendered
        if (el !== null) {
            navigationService.onRender(path || '');
        }
    }

    return onRender;
}

interface LoadingElementProps {
    loadingLabel: string;
}

function LoadingElement(props: LoadingElementProps): JSX.Element {
    if (C.isInEditor()) {
        return (
            <EditorModeLoading>
                <CenteredText>{props.loadingLabel}</CenteredText>
            </EditorModeLoading>
        );
    } else {
        return (
            <Loading>
                <CenteredText>{props.loadingLabel}</CenteredText>
            </Loading>
        );
    }
}

export const PageLoader: React.FunctionComponent<PageLoaderProps> = observer(
    function PL(props: PageLoaderProps): JSX.Element {
        const {path} = props;
        const contentStore = useContentStore();
        const markerRef = useNavigationServiceUpdater(path);
        const data = contentStore.getPage(path);

        const loadingElement = (
            <LoadingElement loadingLabel={props.loadingLabel} />
        );

        if (data) {
            const root = data.cqItems.root as PageRootModel;
            if (root && root.cqType === PAGE_ROOT_TYPE) {
                return (
                    <DynamicComponentLoader
                        path={path}
                        fallback={loadingElement}
                    >
                        <HeaderUpdater />
                        <Include
                            key={path}
                            path={`${path}/jcr:content/root/main`}
                            resourceType={root.cqItems.main.cqType}
                        />
                        <span key={`marker_${path}`} ref={markerRef} />
                    </DynamicComponentLoader>
                );
            } else {
                const xfPath = `${path}/jcr:content/root`;
                const type = root ? root.cqType : PAGE_ROOT_TYPE;

                return (
                    <DynamicComponentLoader
                        path={xfPath}
                        fallback={loadingElement}
                    >
                        <Include path={xfPath} resourceType={type} />
                    </DynamicComponentLoader>
                );
            }
        } else {
            return loadingElement;
        }
    }
);
PageLoader.displayName = 'PageLoader';
