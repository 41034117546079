import * as React from 'react';
import {Disclaimer as GeneratedDisclaimer} from '../../../generated/core';

import {RegisteredDisclaimer} from './DisclaimerStore';
import {GeneralDisclaimerContext} from './GeneralDisclaimerProvider';
import {GeneralDisclaimerContextData} from './base/GeneralDisclaimerContextData';

export function useNewDisclaimerReferences(
    disclaimers: GeneratedDisclaimer[]
): RegisteredDisclaimer[] {
    const ctx = React.useContext(GeneralDisclaimerContext);
    const registeredDisclaimers = React.useRef<RegisteredDisclaimer[]>([]);

    React.useEffect(
        () => () => {
            registeredDisclaimers.current.forEach(r => r.unregister());
        },
        [ctx, registeredDisclaimers]
    );
    React.useEffect(() => {
        // must change disclaimers during render
        syncDisclaimers(disclaimers, registeredDisclaimers.current, ctx);
    }, [registeredDisclaimers, ctx, disclaimers]);

    syncDisclaimers(disclaimers, registeredDisclaimers.current, ctx);

    return registeredDisclaimers.current;
}

function syncDisclaimers(
    disclaimers: GeneratedDisclaimer[],
    registeredDisclaimers: RegisteredDisclaimer[],

    ctx?: GeneralDisclaimerContextData
) {
    if (!ctx) {
        return;
    }
    const registeredDislaimersTexts = registeredDisclaimers.map(r => r.text);
    const added = disclaimers.filter(
        d => !registeredDislaimersTexts.includes(d.text)
    );
    const disclaimersTexts = disclaimers.map(r => r.text);
    const removed = registeredDisclaimers.filter(
        d => !disclaimersTexts.includes(d.text)
    );
    added.forEach(r => {
        const registeredDisclaimer = ctx.addDisclaimer(r);
        registeredDisclaimer &&
            registeredDisclaimers.push(registeredDisclaimer);
    });
    removed.forEach(r => {
        r.unregister();
        const index = registeredDisclaimers.indexOf(r);
        registeredDisclaimers.splice(index, 1);
    });

    return registeredDisclaimers;
}
