import {
    FeatureHub,
    ModuleLoader,
    FeatureServiceProviderDefinition
} from '@feature-hub/core';
import {COMPONENTS_CORE_VERSION} from '@volkswagen-onehub/components-core';
import {SanitizeFunction} from '@volkswagen-onehub/disclaimer-manager';
import {NavigationFlyoutAdminV1Impl} from '@volkswagen-onehub/navigation-flyout-service';
import {createFeatureAppVisibilityService} from './createFeatureAppVisibilityService';
import {createSecondaryConsumerService} from './createSecondaryConsumerService';
import {createSingleFlowNavigator} from './createSingleFlowNavigator';
import {
    PageRootModel,
    SpaAsyncConfig,
    SpaGlobalConfig
} from '../../../generated/core';
import {LAYER_CONSUMER_ID} from '../../context/layer/LayerStore';
import {Logger} from '../../context/logger/Logger';
import {getGlobal} from '../../utils/getGlobal';
import {Registry} from '../di/Registry';
import {Environment} from '../di/annotations';
import {createBreadcrumbService} from './createBreadcrumbService';
import {createFilterService} from './createFilterService';
import {ServiceRegistration} from './ServiceRegistration';
import {createCarConfigurationService} from './createCarConfigurationService';
import {createDataVersionService} from './createDataVersionService';
import {createDisclaimerManager} from './createDisclaimerManager';
import {createFeatureappService} from './createFeatureappService';
import {createHistoryService} from './createHistoryService';
import {createImageService} from './createImageService';
import {createLayerManager} from './createLayerManager';
import {createLocaleService} from './createLocaleService';
import {createLogger} from './createLogger';
import {createNavigationFlyoutService} from './createNavigationFlyoutService';
import {createNavigationService} from './createNavigationService';
import {createSerializedStateManager} from './createSerializedStateManager';
import {createServiceConfigProvider} from './createServiceConfigProvider';
import {createShowroomNavigationManager} from './createShowroomNavigationManager';
import {providedExternalsVersions} from './externals';
import {
    FeatureServiceConsumerDependencies,
    SharedFeatureService
} from '@feature-hub/core/lib/cjs/feature-service-registry';
import {createFeatureAppContentService} from './createFeatureappContentService';
import {
    createPageInfoServiceDefinition,
    ServerPageInfoOptions
} from './createPageInfoServiceDefinition';
import {createReactRenderService} from './createReactRenderService';
import {primaryConsumerLocation} from './primaryConsumerLocation';
import {createUserInteractionService} from './createUserInteractionService';
import {PAGER_CONSUMER_ID} from '../../modules/editorial/sections/magazine/MagazineTeaserGridSection';
import {createApiKeyProvider} from './createApiKeyProvider';
import {createMydealerConfigurationService} from './createMydealerConfigurationService';
import {createCmsService} from './createCmsService';
import {exposeGlobalApi} from '../exposeGlobalApi';
import {onFeatureAppBind} from './onFeatureAppBind';
import {onNavigate} from './onNavigate';
import {createWebsiteSearchChannelService} from './createWebsiteSearchChannelService';
import {createIconService} from './createIconService';
import {getSecondaryConsumerForMydealer} from './getSecondaryConsumerForMydealer';
import {createFeaturehubWithExternalsValidator} from './createFeaturehubWithExternalsValidator';
import {createServiceConfigEndpointProvider} from './createServiceConfigEndpointProvider';
import {createLoginService} from './createLoginService';
import {createParameterManager} from './createParameterManager';

export const CMS_CONSUMER_ID = 's2:cms-integrator';
// We must share the history with the showroom navigation manager, which is a feature service built by the CMS.
// The order of initialization makes it easier for us to use the manager's history instead of the other way around.
export const SHOWROOM_NAVIGATION_CONSUMER_ID = 'showroom-navigation';
const SECONDARY_KEY = 'app';
const SECONDARY_EXT = 'app';
export const LAYER_KEY_AND_EXT = 'layer';
export const PAGER_KEY_AND_EXT = 'pager';

export const getSecondaries = (
    pathname: string,
    secondaryHistoryKeysForPath: {[index: string]: string}
) => {
    // remove extension and selectors to match pathnames
    const matchSelectors = pathname.match(/([^.]+)\./);
    if (matchSelectors && matchSelectors.length >= 1) {
        pathname = matchSelectors[1];
    }
    const secondaryConsumerId = secondaryHistoryKeysForPath[pathname];
    const secondaries: {[consumerId: string]: string} = {};
    if (secondaryConsumerId) {
        secondaries[secondaryConsumerId] = SECONDARY_KEY;
    }
    secondaries[LAYER_CONSUMER_ID] = LAYER_KEY_AND_EXT;
    secondaries[PAGER_CONSUMER_ID] = PAGER_KEY_AND_EXT;

    return secondaries;
};

/**
 * initialize the feature hub, register all services and add them to the registry.
 * @param spaGlobalConfig
 * @param spaAsyncConfig
 * @param registry
 * @param moduleLoader
 * @param env
 * @param envSpecificServiceRegistrations list of service registrations only for actual specific environment
 * @param logger
 */
export function initializeHub(
    spaGlobalConfig: SpaGlobalConfig,
    spaAsyncConfig: SpaAsyncConfig,
    registry: Registry,
    moduleLoader: ModuleLoader,
    env: Environment,
    envSpecificServiceRegistrations: ServiceRegistration<
        SharedFeatureService
    >[],
    envSpecificServices: FeatureServiceProviderDefinition<
        SharedFeatureService
    >[],
    moduleDefinitionsMap: {id: string; bundle: string}[],
    logger: Logger,
    serverOptions: ServerPageInfoOptions | undefined,
    pageRootModel: PageRootModel,
    sanitizationConfig: {sanitize: SanitizeFunction},
    hydration: {hydrating: boolean} = {hydrating: false},
    serialzedState?: string
): FeatureHub {
    // the list of services which are registered and exposed as singletons to the cms
    const navFlyoutAdmin = new NavigationFlyoutAdminV1Impl();
    registry.addSingleton('NavigationFlyoutAdmin', navFlyoutAdmin);

    const featureAppLogConfigs = spaAsyncConfig.featureHubModel.featureAppLogConfigurations.slice();

    const serviceRegistrations: ServiceRegistration<any>[] = [
        ...envSpecificServiceRegistrations,
        createHistoryService(
            SHOWROOM_NAVIGATION_CONSUMER_ID,
            SECONDARY_EXT,
            transformer =>
                registry.addSingleton('RootLocationTransformer', transformer),
            (pathname: string) => {
                const mydealerSecondaryConsumerId = getSecondaryConsumerForMydealer(
                    spaAsyncConfig,
                    serverOptions?.serverPath
                );
                const secondaries = getSecondaries(
                    pathname,
                    spaAsyncConfig.featureHubModel.secondaryHistoryKeysForPath
                );
                const appAlreadyExists = !!Object.values(secondaries).find(
                    s => s === SECONDARY_KEY
                );
                if (!appAlreadyExists && mydealerSecondaryConsumerId) {
                    secondaries[mydealerSecondaryConsumerId] = SECONDARY_KEY;
                }

                return secondaries;
            }
        ),
        createSerializedStateManager(serialzedState),
        createIconService(spaGlobalConfig),
        createSingleFlowNavigator(),
        createDataVersionService(spaAsyncConfig),
        createFeatureAppContentService(),
        createFeatureappService(spaAsyncConfig, logger),
        createLoginService(registry),
        createShowroomNavigationManager({
            navigationConfig: {
                ...spaAsyncConfig.showroomNavigationModel.config,
                configureAsAnchor: true
            },
            extension: '',
            replaceOnSync: false,
            noSuffix: spaGlobalConfig.featureToggles.enableNoSuffixShowrooms
        }),
        createCarConfigurationService(),
        createWebsiteSearchChannelService(),
        createNavigationFlyoutService(navFlyoutAdmin),
        createImageService(spaAsyncConfig.featureHubModel),
        createServiceConfigEndpointProvider(
            spaAsyncConfig.serviceConfigEndpoint,
            spaGlobalConfig
        ),
        createBreadcrumbService(),
        createFeatureAppVisibilityService(),
        createFilterService(),
        createApiKeyProvider(spaAsyncConfig.featureHubModel),
        createMydealerConfigurationService(
            spaAsyncConfig.mydealerServiceOptions,
            pageRootModel.mydealerPageOwner,
            registry,
            spaGlobalConfig
        ),
        createLogger(logger, featureAppLogConfigs),
        createPageInfoServiceDefinition(
            spaAsyncConfig,
            registry,
            serverOptions
        ),
        createReactRenderService(hydration),
        createUserInteractionService(),
        createSecondaryConsumerService(),
        createParameterManager(
            spaGlobalConfig.marketBasedFeatures?.activateParameterService
                ? spaAsyncConfig.featureHubModel.featureHubConfiguration
                      .urlParameterWhitelist
                : []
        )
    ]
        .concat(
            createServiceConfigProvider(
                logger,
                spaAsyncConfig.serviceConfigs,
                spaAsyncConfig.gfaServiceConfigs
            ),
            createLocaleService(
                spaAsyncConfig.localeModel,
                spaGlobalConfig.direction
            ),
            createNavigationService({
                primaryConsumerId: SHOWROOM_NAVIGATION_CONSUMER_ID,
                pages: spaAsyncConfig.navigablePages,
                primaryConsumerLocation: primaryConsumerLocation(registry),
                onNavigate: onNavigate(logger)
            }),
            createDisclaimerManager(
                spaGlobalConfig,
                sanitizationConfig.sanitize
            ),
            createLayerManager()
        )
        .filter(service => !service.env || service.env === env);

    const featureServiceDefinitions = [
        ...serviceRegistrations.map(serviceRegistration => {
            return serviceRegistration.definition;
        }),
        ...envSpecificServices,
        createCmsService(spaGlobalConfig, registry)
    ];

    const featureServiceDependencies = serviceRegistrations.reduce(
        (featureServices, registration) => {
            if (
                registration &&
                registration.service &&
                !registration.service.optional
            ) {
                featureServices[registration.definition.id] =
                    registration.service.version;
            }
            return featureServices;
        },
        {} as any
    ) as FeatureServiceConsumerDependencies;

    const optionalFeatureServiceDependencies = serviceRegistrations.reduce(
        (featureServices, registration) => {
            if (
                registration &&
                registration.service &&
                registration.service.optional
            ) {
                featureServices[registration.definition.id] =
                    registration.service.version;
            }
            return featureServices;
        },
        {} as any
    ) as FeatureServiceConsumerDependencies;

    serviceRegistrations.forEach(value => {
        featureAppLogConfigs.push({
            name: value.definition.id,
            logLevel: spaGlobalConfig.featureServicesLogLevel,
            team: 'feature-service',
            version: ''
        });
    });

    const featureHub = createFeaturehubWithExternalsValidator(CMS_CONSUMER_ID, {
        moduleLoader,
        featureServiceDefinitions,
        featureServiceDependencies,
        optionalFeatureServiceDependencies,
        providedExternals: providedExternalsVersions,
        logger: logger.featurehub,
        onBind: onFeatureAppBind(logger)
    });

    exposeGlobalApi(featureHub, registry);

    getGlobal().getFeatureService = (name: string, version?: string) => {
        const sharedFeatureServices = getFeatureServices(featureHub);
        const services = sharedFeatureServices.get(name);
        if (!services) {
            return undefined;
        }
        let definition;
        if (version) {
            const definitions = Object.keys(services)
                .filter(key => key.startsWith(version))
                .map(key => services[key]);
            if (definitions.length > 0) {
                definition = definitions[0];
            }
        } else {
            definition = Object.values(services)[0];
        }
        if (definition) {
            return definition().featureService;
        }
    };

    getGlobal().printFeatureHubDescription = () => {
        // sharedFeatureServices is currently a private property. It should be exposed for the sake of this method.
        const sharedFeatureServices = getFeatureServices(featureHub);
        const services: any[] = [];
        sharedFeatureServices.forEach((value, key) => {
            const versions = Object.keys(value)
                .sort()
                .reverse()
                .join(', ');
            const service = {
                id: key,
                versions,
                deployment:
                    moduleDefinitionsMap.find(e => e.id === key)?.bundle ||
                    'cms'
            };
            services.push(service);
        });

        const dependencies = {
            '@volkswagen-onehub/components-core': COMPONENTS_CORE_VERSION
        };

        console.group('Feature Services');
        console.table(services);
        console.groupEnd();

        console.group('FeatureHub Externals');
        console.table(providedExternalsVersions);
        console.groupEnd();

        console.group('CMS Dependencies');
        console.table(dependencies);
        console.groupEnd();
    };

    registry.addSingleton('FeatureHub', featureHub);
    serviceRegistrations.forEach(registration => {
        if (registration.service) {
            const service =
                featureHub.featureServices[registration.definition.id];

            if (service) {
                registry.addSingleton(
                    registration.service.typeName,
                    service as object,
                    {
                        env: registration.env
                    }
                );
            }
        }
    });
    return featureHub;
}

function getFeatureServices(
    featureHub: FeatureHub
): Map<string, {[version: string]: any}> {
    return (featureHub.featureServiceRegistry as any).sharedFeatureServices;
}
