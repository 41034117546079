import * as React from 'react';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {SingleColumnSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {SingleColumnSectionParsys} from '../../structure/SingleColumnSectionParsys';
import {HeadingElement} from '../elements/HeadingElement';
import {SectionWrapper} from './SectionWrapper';

const StyledSingleColumnSection = styled.div`
    --headline-start: ${props => props.theme.size.grid002};
    --headline-end: ${props => props.theme.size.grid002};

    width: 100%;
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns:
        [full-start] var(--headline-start) [headline-start] 1fr
        [headline-end] var(--headline-end) [full-end];

    row-gap: ${props => props.theme.size.dynamic0100};

    & > * {
        grid-column: full;
    }

    & .headingElement {
        grid-column: headline;
    }

    @media (min-width: ${Breakpoints.b560}px) {
        --headline-start: ${props => props.theme.size.grid005};
        --headline-end: ${props => props.theme.size.grid008};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        --headline-start: ${props => props.theme.size.grid006};
    }
`;

/**
 * S102 Single Column Section
 */
function InternalSingleColumnSection(
    props: SingleColumnSectionModel
): JSX.Element {
    const {
        anchorId,
        layout,
        contentId: sectionId,
        contentName,
        contentLabels,
        tagTypeBroken,
        mboxId
    } = props;
    const hasHeadline =
        C.isInEditor() || !Boolean(props?.cqItems?.headline?.empty);

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S102 Single Column Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <StyledSingleColumnSection>
                        {hasHeadline && (
                            <HeadingElement path="headline" order="H2" />
                        )}
                        <div>
                            <SingleColumnSectionParsys
                                path="singleColumnSectionParsys"
                                layout={layout}
                                noElement
                            />
                            <BelowSectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        </div>
                    </StyledSingleColumnSection>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const SingleColumnSection = MapTo(
    'vwa-ngw18/components/editorial/sections/singleColumnSection',
    InternalSingleColumnSection
);
