import * as React from 'react';
import styled from 'styled-components';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    TextAlignment
} from '@volkswagen-onehub/components-core';
import {TableItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {TableParsys} from '../../structure/TableParsys';
import {HeadingElement} from '../elements/HeadingElement';
import {DesktopTable} from '../sections/table/DesktopTable';
import {MobileTable} from '../sections/table/MobileTable';
import {
    getNumberOfColumns,
    getRows,
    updateTableInAuthor
} from '../sections/table/utils';
import {getTableWarnings} from '../../../utils/getTableWarnings';

const RESOURCE_TYPE = 'vwa-ngw18/components/editorial/items/tableItem';

const StyledHeadlineContainer = styled.div`
    padding-left: ${props => props.theme.size.grid002};
    padding-right: ${props => props.theme.size.grid002};
`;

const MIN_COLUMNS = 2;

class TableItemComponentClass extends React.PureComponent<TableItemModel> {
    public render(): React.ReactNode {
        const {validationMessages} = this.props;
        const rows = getRows(this.props);
        const columnsCount = getNumberOfColumns(rows);
        // It is not supported to render less then 2 columns
        if (!C.isInEditor() && columnsCount < MIN_COLUMNS) {
            return null;
        }
        const warnings = getTableWarnings(
            validationMessages,
            columnsCount,
            MIN_COLUMNS
        );

        return (
            <AuthoringWrapper
                title="S109 Table Item"
                bgColor={AuthoringWrapper.BG_COLOR_ITEM}
            >
                <SimpleDisclaimerWrapper>
                    <Container
                        gutter={ContainerGutter.dynamic0130}
                        wrap={ContainerWrap.always}
                        padding={{
                            top: ContainerPadding.dynamic0100,
                            bottom: ContainerPadding.dynamic0100
                        }}
                    >
                        {C.isInEditor() && warnings}
                        {this.hasHeadline() && (
                            <StyledHeadlineContainer>
                                <HeadingElement
                                    path="headline"
                                    textAlign={TextAlignment.center}
                                    order="H2"
                                />
                            </StyledHeadlineContainer>
                        )}
                        <Container
                            gutter={ContainerGutter.dynamic0150}
                            wrap={ContainerWrap.always}
                        >
                            {C.isInEditor() ? (
                                <TableParsys
                                    path="tableParsys"
                                    columnsCount={columnsCount}
                                    updateTableInAuthorCallback={
                                        this.updateTableInAuthorCallback
                                    }
                                    narrowColumns
                                />
                            ) : (
                                <>
                                    <DesktopTable {...this.props} />
                                    <MobileTable {...this.props} />
                                </>
                            )}
                        </Container>
                    </Container>
                </SimpleDisclaimerWrapper>
            </AuthoringWrapper>
        );
    }

    private hasHeadline(): boolean {
        return (
            !!this.props.cqItems &&
            !!this.props.cqItems.headline &&
            (C.isInEditor() || !this.props.cqItems.headline.empty)
        );
    }

    private updateTableInAuthorCallback = (): void => {
        updateTableInAuthor(this.props.cqPath);
    };
}

export const TableItem = MapTo(RESOURCE_TYPE, TableItemComponentClass);
