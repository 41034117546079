import {
    defineWebsiteSearchChannelService,
    SharedWebsiteSearchChannelService
} from '@volkswagen-onehub/website-search-channel-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createWebsiteSearchChannelService(): ServiceRegistration<
    SharedWebsiteSearchChannelService
> {
    const definition = defineWebsiteSearchChannelService();

    return {
        definition,
        env: 'client',
        service: {
            typeName: 'WebsiteSearchChannelService',
            version: '^1.0.0'
        }
    };
}
