import {FeatureAppContainer} from '@feature-hub/react';
import {styled} from '@volkswagen-onehub/components-core';
import layersFeatureAppDefinition from '@volkswagen-onehub/layer-feature-app';
import * as React from 'react';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {ZIndex} from '../../utils/zIndex';

const LayersWrapper = styled.div`
    z-index: ${ZIndex.layers.z};
    position: relative;
`;

export function FeatureAppLayers(): JSX.Element {
    return (
        <CyAttributeAppender name="layersWrapper">
            <LayersWrapper>
                <FeatureAppContainer
                    featureAppDefinition={layersFeatureAppDefinition}
                    featureAppId="s2:layer"
                />
            </LayersWrapper>
        </CyAttributeAppender>
    );
}
