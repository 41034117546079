import {
    SharedFeatureAppVisibilityService,
    featureAppVisibilityServiceDefinition
} from '@volkswagen-onehub/feature-app-visibility-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createFeatureAppVisibilityService(): ServiceRegistration<
    SharedFeatureAppVisibilityService
> {
    return {
        definition: featureAppVisibilityServiceDefinition,
        service: {
            typeName: 'FeatureAppVisibilityService',
            version: '^1.0.0'
        }
    };
}
