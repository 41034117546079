import {NextBestSectionActionTrackingData} from '../../../../generated/core';
import {useTrackingService} from '../../../context';
import {
    ActionScriptReport,
    PersonalizedActionReporting,
    ReportingSourceId
} from '../../../context/tracking/TrackingService';
import {processContentId} from '../../../utils/processContentId';

export interface SingleNbabTrackingData {
    itemCount?: number;
    itemPosition?: number;
    sectionId?: string;
    contentLabels?: string;
    additionalTrackingData?: NextBestSectionActionTrackingData;
    personalizedReporting?: PersonalizedActionReporting;
}

export function useSingleNbabTracking(
    label: string,
    intent?: string,
    priority?: number,
    trackingData?: SingleNbabTrackingData
) {
    const trackingService = useTrackingService();

    return (url: string, contentId?: string, layerName?: string) => {
        const source: ReportingSourceId = trackingData?.sectionId
            ? 'CMS-Section'
            : 'CMS-Page';
        const actionScriptReport: ActionScriptReport = {
            displayOrder: trackingData?.itemPosition || 1,
            eventType: 'Click',
            version: '1.0',
            userGroup: 'CMS',
            source,
            intent,
            priority,
            ...trackingData?.personalizedReporting
        };

        trackingService.trackNextBestActionButtonClick(
            url,
            contentId,
            label,
            {
                sectionId: trackingData?.sectionId,
                contentLabels: trackingData?.contentLabels,
                itemCount: trackingData?.itemCount,
                itemPosition: trackingData?.itemPosition
            },
            {
                environment: {
                    featureAppId:
                        trackingData?.additionalTrackingData?.featureAppId,
                    featureAppIdAppVersion:
                        trackingData?.additionalTrackingData?.featureAppVersion
                },
                personalization: {
                    status: false,
                    actionScriptReports: [actionScriptReport]
                }
            }
        );

        if (layerName) {
            trackingService.trackLayerLoad(processContentId(url, layerName));
        }
    };
}
