import {styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';

import {RenderPaginationProps} from './index';
import {resetButtonStyles, StyledAccessibleLabel} from '../helpers';
import {TabPagination} from './tab-pagination';

export const BULLET_BUTTON_HEIGHT = '4px';
export const BULLET_BUTTON_PADDING = '5px';

interface StyledBulletButtonProps {
    isActive?: boolean;
}

const StyledBulletPaginationWrapper = styled.div`
    direction: ltr;
    padding-block-start: ${props => props.theme.size.dynamic0100};
    text-align: center;

    // NOTE: added due cutter focus outline in mood gallery / editorial teaser section on mobile in carousel
    padding-bottom: 2px;
`;

export const StyledBulletButton = styled.button<StyledBulletButtonProps>`
    ${resetButtonStyles}
    display: inline-block;
    position: relative;
    padding: ${BULLET_BUTTON_PADDING}; //Spacing around points

    &::before {
        content: '';
        display: block;
        height: ${BULLET_BUTTON_HEIGHT}; //Size of the point
        width: ${BULLET_BUTTON_HEIGHT}; //Size of the point
        border-radius: 50%;
        background-color: ${props => props.theme.colors.button.primary.default};
        transition: transform 0.2s;
        ${props => (props.isActive ? 'transform: scale(2);' : '')};
    }

    &:focus {
        outline: none;

        &::before {
            transform: scale(1.6);
            ${props =>
                props.isActive
                    ? 'transform: scale(2);'
                    : 'transform: scale(1.6);'};
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            border: solid 2px ${props => props.theme.colors.focus.main};
            margin: -2px;
        }
    }
`;
StyledBulletButton.displayName = 'StyledBulletPagination';

export class BulletPagination extends React.Component<RenderPaginationProps> {
    private readonly wrapperRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    public render(): JSX.Element {
        const {paginationData, activeIndex, paginationLabel} = this.props;

        return (
            <TabPagination {...this.props} tabListRef={this.wrapperRef}>
                {({tabListKeyDownHandler, getTabClickHandler}) => (
                    <StyledBulletPaginationWrapper
                        role="tablist"
                        aria-label={paginationLabel}
                        onKeyDown={tabListKeyDownHandler}
                        ref={this.wrapperRef}
                    >
                        {paginationData.map(({label, id, panelId}, index) => (
                            <StyledBulletButton
                                isActive={index === activeIndex}
                                onClick={getTabClickHandler(index)}
                                key={index}
                                role="tab"
                                id={id}
                                aria-controls={panelId}
                                tabIndex={index === activeIndex ? 0 : -1}
                                aria-selected={
                                    index === activeIndex ? 'true' : 'false'
                                }
                            >
                                <StyledAccessibleLabel>
                                    {label}
                                </StyledAccessibleLabel>
                            </StyledBulletButton>
                        ))}
                    </StyledBulletPaginationWrapper>
                )}
            </TabPagination>
        );
    }
}
