import * as React from 'react';

import {
    StyledButtons,
    StyledContent,
    StyledLayout,
    StyledMedia,
    StyledTextElements
} from './styles';
import {CounterRow} from './counterRow';

export interface TimeConversions {
    counter: {
        label: string;
        time: string[];
    }[];
    dateTime: string;
}

export const convertTime = (
    milliseconds: number,
    daysLabel: string,
    hoursLabel: string,
    minutesLabel: string,
    secondsLabel: string
): TimeConversions => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    const days = Math.floor(hours / 24);
    hours = hours % 24;

    const counterArr = [
        {label: daysLabel, time: days},
        {label: hoursLabel, time: hours},
        {label: minutesLabel, time: minutes},
        {label: secondsLabel, time: seconds}
    ];
    const counter = counterArr.map(({label, time}) => ({
        label,
        time: time
            .toString()
            .padStart(2, '0')
            .split('')
    }));
    const dateTime = `PT${days}D${hours}H${minutes}M${seconds}S`;

    return {counter, dateTime};
};

export const ON_TIMER_END_EVENT = 'ontimerend';

export interface CountdownItemProps {
    endTime: number;
    media: JSX.Element;
    heading: JSX.Element;
    copy: JSX.Element;
    bullets: JSX.Element | undefined;
    buttons: JSX.Element | undefined;
    disclaimers: JSX.Element;
    isCutout: boolean;
    dayLabel: string;
    hourLabel: string;
    minutesLabel: string;
    secondsLabel: string;
}

export const CountdownItem = (props: CountdownItemProps) => {
    const {
        media,
        heading,
        copy,
        bullets,
        buttons,
        disclaimers,
        isCutout,
        dayLabel,
        hourLabel,
        minutesLabel,
        secondsLabel,
        endTime
    } = props;

    const now = Date.now();
    const [time, setTime] = React.useState(Math.max(endTime - now, 0));
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (time <= 0) {
            // eslint-disable-next-line no-unused-expressions
            ref.current?.dispatchEvent(
                new CustomEvent(ON_TIMER_END_EVENT, {bubbles: true})
            );
            return;
        }

        const timeout = setTimeout(() => {
            setTime(Math.max(endTime - Date.now(), 0));
        }, 1000);

        return () => {
            window.clearTimeout(timeout);
        };
    }, [time, endTime]);

    const {counter, dateTime} = convertTime(
        time,
        dayLabel,
        hourLabel,
        minutesLabel,
        secondsLabel
    );

    return (
        <StyledLayout ref={ref}>
            <StyledMedia isCutout={isCutout}>{media}</StyledMedia>
            <StyledContent>
                <StyledTextElements>
                    {heading}
                    {copy}
                    {bullets}
                </StyledTextElements>
                <StyledButtons>{buttons ? buttons : null}</StyledButtons>
                <CounterRow counter={counter} dateTime={dateTime} />
            </StyledContent>
            {disclaimers}
        </StyledLayout>
    );
};
