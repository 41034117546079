import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    Text,
    TokenTextColor
} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';

import {MediaAspectRatio, MediaFocalPoint} from '../../../generated/core';
import {
    useBBOContext,
    useModelStore,
    usePersonalizationContext,
    usePersonalizationStore,
    useSectionContext,
    useSectionGroupContext,
    useTrackingService
} from '../../context';
import {SimpleDisclaimerWrapper} from '../../context/layer/SimpleDisclaimerWrapper';
import {MediaContext, MediaContextData} from '../../context/media/MediaContext';
import {HighlightGalleryV3 as LSGHighlightGallery} from '../../d6/components/highlight-gallery-v3';
import {ImageWithFocalPoint} from '../../d6/components/image-with-focal-point';
import {
    CarouselItemContextV2,
    CarouselItemContextProvider
} from '../../d6/components/mobile-carousel-v2/context';
import {
    ContainerExporter,
    ContainerItem
} from '../../infrastructure/ContainerExporter';
import {modelToProps} from '../../infrastructure/api/modelToProps';
import {MediaElement} from '../../modules/editorial/elements/MediaElement';
import {RichtextSimpleElement} from '../../modules/editorial/elements/RichtextElement';
import {ThumbnailMediaElement} from '../../modules/editorial/elements/ThumbnailMediaElement';
import {MediaSingleItem} from '../../modules/editorial/items/MediaSingleItem';
import {C} from '../../registries/compatibilty';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {focalPointMap} from '../../utils/focalPointMap';
import {processContentId} from '../../utils/processContentId';
import {createContextTrackingData} from '../../utils/tracking/createContextTrackingData';
import {replaceTranslationPlaceholders} from '../../utils/translation/replaceTranslationPlaceholders';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../ResponsiveMediaRenderer';
import {useTrackSliderLoad} from '../../utils/tracking/useTrackSliderLoad';

enum buttonName {
    ImageButton_Prev = 'previous-image-button',
    ImageButton_Next = 'next-image-button',
    Pagination_Prev = 'previous-thumbnail-button',
    Pagination_Next = 'next-thumbnail-button'
}

export interface HighlightGalleryProps {
    readonly mediaParsysPath: string;
    readonly contentName?: string;
    readonly contentLabels?: string;
    readonly isShown?: boolean;
    readonly galleryLabel: string;
    readonly previewAriaLabel: string;
    readonly goToPreviousSlideLabel: string;
    readonly goToNextSlideLabel: string;
    readonly carouselItemLabel: string;
    readonly slideXOfYLabel: string;
    readonly itemCount?: number;
    readonly itemPosition?: number;
    readonly carouselId: string;
}

interface StyledWrapperGalleryProps {
    readonly isShown?: boolean;
}

interface MediaItemsPublish {
    readonly previewContainerDesktop: React.RefObject<HTMLDivElement>;
    readonly previewContainerMobile: React.RefObject<HTMLDivElement>;
    readonly mediaItemsPublish: JSX.Element[];
}

const StyledGalleryWrapper = styled.div<StyledWrapperGalleryProps>`
    @media (min-width: ${Breakpoints.b960}px) {
        display: ${props => (props.isShown ? 'block' : 'none')};
    }
`;
StyledGalleryWrapper.displayName = 'StyledGalleryWrapper';

const StyledAuthorWrapper = styled.div`
    width: ${props => props.theme.size.grid004};
    height: 100%;
    display: block;

    label {
        margin: 0;
    }
`;
StyledAuthorWrapper.displayName = 'StyledAuthorWrapper';

const CAPTION_PATH = 'caption';
const MEDIA_PATH = 'media';
const MOBILE_MEDIA_RATIO = 'r_3_4';
const DESKTOP_MEDIA_RATIO = 'r_4_3';
const MEDIA_RATIO_WITH_THUMBNAILS = 'r_10_6';
const FOCAL_POINT_CENTER = 'CENTER_CENTER';

const mediaContextValueDesktop: MediaContextData = {ratio: DESKTOP_MEDIA_RATIO};

const responsiveMediaRendererConf = (
    ratio: MediaAspectRatio
): ResponsiveMediaRendererConf[] => [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        aspectRatio: MOBILE_MEDIA_RATIO
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        aspectRatio: ratio
    }
];

const responsiveThumbnailRendererConf: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        aspectRatio: DESKTOP_MEDIA_RATIO
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        aspectRatio: MEDIA_RATIO_WITH_THUMBNAILS
    }
];

function renderMediaItemsAuthor(
    mediaItems: ContainerItem[],
    mediaParsysPath: string
): JSX.Element[] {
    return mediaItems.map((mediaItem: ContainerItem, idx: number) => (
        <StyledAuthorWrapper key={`${mediaItem.key}_${idx}`}>
            <MediaContext.Provider value={mediaContextValueDesktop}>
                <MediaSingleItem path={`${mediaParsysPath}/${mediaItem.key}`} />
            </MediaContext.Provider>
        </StyledAuthorWrapper>
    ));
}

function useResizeHack(isShown: boolean): void {
    React.useEffect(() => {
        if (isShown) {
            window.dispatchEvent(new Event('resize'));
        }
    }, [isShown]);
}

export const useXOfYLabel = (translation: string) =>
    React.useCallback(
        (slideIndex: number, slideAmount: number) =>
            replaceTranslationPlaceholders(translation, {
                slideIndex: String(slideIndex),
                slideAmount: String(slideAmount)
            }),
        [translation]
    );

function useRenderMediaItemsPublish(
    mediaItems: ContainerItem[],
    isShown: boolean,
    mediaParsysPath: string
): MediaItemsPublish {
    const previewContainerDesktop: React.RefObject<HTMLDivElement> = React.useRef(
        null
    );
    const previewContainerMobile: React.RefObject<HTMLDivElement> = React.useRef(
        null
    );
    useResizeHack(isShown);
    const ratio =
        mediaItems.length > 1
            ? MEDIA_RATIO_WITH_THUMBNAILS
            : DESKTOP_MEDIA_RATIO;

    const responsiveMediaConfiguration = responsiveMediaRendererConf(ratio);

    const mediaItemsPublish = React.useMemo(
        () =>
            mediaItems.map((mediaItem: ContainerItem) => {
                const isImage =
                    mediaItem?.model?.cqItems?.media?.mediaType === 'IMAGE';

                const media = (
                    <MediaElement
                        path={`${mediaParsysPath}/${mediaItem.key}/${MEDIA_PATH}`}
                        responsiveMediaConfig={responsiveMediaConfiguration}
                        containerProps={{matchParent: true}}
                        useParentAspectRatio={isImage}
                    />
                );

                return (
                    <CarouselItemContextV2.Consumer key={mediaItem.key}>
                        {({active}) => (
                            <CarouselItemContextProvider
                                active={active && Boolean(isShown)}
                            >
                                <ResponsiveMediaRenderer
                                    configs={responsiveMediaConfiguration}
                                    matchParent
                                >
                                    {media}
                                </ResponsiveMediaRenderer>
                            </CarouselItemContextProvider>
                        )}
                    </CarouselItemContextV2.Consumer>
                );
            }),
        [isShown, mediaItems, mediaParsysPath, responsiveMediaConfiguration]
    );

    return {previewContainerDesktop, previewContainerMobile, mediaItemsPublish};
}

function renderCaptions(
    mediaItems: ContainerItem[],
    mediaParsysPath: string
): JSX.Element[] {
    return mediaItems.map((mediaItem: ContainerItem, idx: number) => (
        <Text color={TokenTextColor.inherit} key={`caption_${idx}`}>
            <RichtextSimpleElement
                path={`${mediaParsysPath}/${mediaItem.key}/${CAPTION_PATH}`}
            />
        </Text>
    ));
}

function renderThumbnails(
    mediaItems: ContainerItem[],
    mediaParsysPath: string
): JSX.Element[] {
    return mediaItems.map((mediaItem: ContainerItem, mediaIndex: number) => {
        const mediaElement = mediaItem.model.cqItems.media;
        const imageElement = mediaElement.cqItems.image;
        const focalPoint: MediaFocalPoint = imageElement
            ? imageElement.focalPoint
            : FOCAL_POINT_CENTER;

        const containerExporterBaseProps: ContainerExporter = {
            cqItems: mediaElement.cqItems,
            cqItemsOrder: mediaElement.cqItemsOrder,
            cqType: mediaElement.cqType,
            cqPath: `${mediaParsysPath}/${mediaItem.key}/${MEDIA_PATH}`
        };

        const thumbnail = (
            <ThumbnailMediaElement
                mediaType={mediaElement.mediaType}
                darkDisclaimer={mediaElement.darkDisclaimer}
                containerExporterBaseProps={containerExporterBaseProps}
                responsiveMediaConfig={responsiveThumbnailRendererConf}
            />
        );

        return (
            <ResponsiveMediaRenderer
                key={`mediaThumbnail_${mediaIndex}`}
                configs={responsiveThumbnailRendererConf}
            >
                <ImageWithFocalPoint focalPoint={focalPointMap[focalPoint]}>
                    {thumbnail}
                </ImageWithFocalPoint>
            </ResponsiveMediaRenderer>
        );
    });
}

function useMediaItems(mediaParsysPath: string): ContainerItem[] {
    const modelStore = useModelStore();
    const parsysModel = modelStore.getData(mediaParsysPath);

    return React.useMemo(() => {
        if (parsysModel) {
            const parsysProps = modelToProps(parsysModel, mediaParsysPath);

            return getChildItems(parsysProps);
        }
        return [];
    }, [mediaParsysPath, parsysModel]);
}

function getActiveItemKey(
    index: number,
    mediaItems: ContainerItem[]
): string | null {
    if (index >= 0 && mediaItems.length > index) {
        return mediaItems[index].key;
    } else {
        return null;
    }
}

function useTrackButtonClick(): (
    buttonId: string,
    contentId: string,
    contentLabels: string | undefined,
    itemCount: number | undefined,
    itemPosition: number | undefined
) => void {
    const trackingService = useTrackingService();
    const personalizationContext = usePersonalizationContext();
    const sectionContext = useSectionContext();
    const bboContex = useBBOContext();
    const sectionGroupContext = useSectionGroupContext();
    const ps = usePersonalizationStore();

    return React.useCallback(
        (buttonId, contentId, contentLabels, itemCount, itemPosition): void => {
            trackingService.trackButtonClick(
                contentId,
                window.location.pathname,
                buttonId,
                createContextTrackingData(
                    personalizationContext,
                    ps.getMetaData(),
                    bboContex,
                    sectionContext,
                    sectionGroupContext.sectionGroupContentId,
                    contentLabels,
                    itemCount,
                    itemPosition
                )
            );
        },
        [
            trackingService,
            ps,
            personalizationContext,
            sectionContext,
            bboContex,
            sectionGroupContext.sectionGroupContentId
        ]
    );
}

export const HighlightGallery: React.FunctionComponent<HighlightGalleryProps> = observer(
    function HG(props: HighlightGalleryProps) {
        const {
            isShown = true,
            mediaParsysPath,
            contentName,
            contentLabels,
            previewAriaLabel,
            itemCount,
            itemPosition,
            carouselId,
            slideXOfYLabel,
            galleryLabel,
            goToNextSlideLabel,
            goToPreviousSlideLabel,
            carouselItemLabel
        } = props;
        const [activeItemIndex, setActiveItemIndex] = React.useState(0);

        const mediaItems = useMediaItems(mediaParsysPath);
        const {mediaItemsPublish} = useRenderMediaItemsPublish(
            mediaItems,
            isShown,
            mediaParsysPath
        );

        const trackSliderLoad = useTrackSliderLoad();
        const trackButtonClick = useTrackButtonClick();

        const handleSliderChanged = React.useCallback(
            (index: number): void => {
                const activeItemKey = getActiveItemKey(index, mediaItems);
                const contentId = processContentId(
                    `${mediaParsysPath}/${activeItemKey}`,
                    `${contentName || 'highlightGallery'} - media item ${index}`
                );

                if (isShown && index !== activeItemIndex) {
                    trackSliderLoad(
                        contentId,
                        contentLabels,
                        itemCount,
                        itemPosition,
                        undefined
                    );
                    setActiveItemIndex(index);
                }
            },
            [
                mediaItems,
                isShown,
                activeItemIndex,
                trackSliderLoad,
                mediaParsysPath,
                contentName,
                contentLabels,
                itemCount,
                itemPosition
            ]
        );

        const handleTrackingButtonClick = React.useCallback(
            (buttonId: string) => {
                const contentId = processContentId(
                    `${mediaParsysPath}/`,
                    `${contentName || 'highlightGallery'} - ${buttonId}`
                );
                trackButtonClick(
                    buttonId,
                    contentId,
                    contentLabels,
                    itemCount,
                    itemPosition
                );
            },
            [
                contentLabels,
                trackButtonClick,
                contentName,
                mediaParsysPath,
                itemCount,
                itemPosition
            ]
        );

        const useImageButtonId = (delta: number): void => {
            const buttonId =
                delta === 1
                    ? buttonName.ImageButton_Next
                    : buttonName.ImageButton_Prev;
            handleTrackingButtonClick(buttonId);
        };

        const usePaginationButtonId = (delta: number): void => {
            const buttonId =
                delta === 1
                    ? buttonName.Pagination_Next
                    : buttonName.Pagination_Prev;
            handleTrackingButtonClick(buttonId);
        };

        const getXOfYLabel = useXOfYLabel(slideXOfYLabel);

        return (
            <SimpleDisclaimerWrapper>
                <StyledGalleryWrapper isShown={C.isInEditor() || isShown}>
                    {C.isInEditor() ? (
                        <Container
                            gutter={ContainerGutter.dynamic0050}
                            wrap={ContainerWrap.never}
                        >
                            {renderMediaItemsAuthor(
                                mediaItems,
                                mediaParsysPath
                            )}
                        </Container>
                    ) : (
                        mediaItems.length > 0 && (
                            <CyAttributeAppender name="gallery">
                                <LSGHighlightGallery
                                    carouselId={carouselId}
                                    previewAriaLabel={previewAriaLabel}
                                    paginationDefaultItemLabel={
                                        carouselItemLabel
                                    }
                                    getXOfYLabel={getXOfYLabel}
                                    galleryLabel={galleryLabel}
                                    navigationLabelNext={goToNextSlideLabel}
                                    navigationLabelPrevious={
                                        goToPreviousSlideLabel
                                    }
                                    captions={renderCaptions(
                                        mediaItems,
                                        mediaParsysPath
                                    )}
                                    previewMediaElements={renderThumbnails(
                                        mediaItems,
                                        mediaParsysPath
                                    )}
                                    onSlideChange={handleSliderChanged}
                                    onButtonClick={useImageButtonId}
                                    onPaginationClick={usePaginationButtonId}
                                >
                                    {mediaItemsPublish}
                                </LSGHighlightGallery>
                            </CyAttributeAppender>
                        )
                    )}
                </StyledGalleryWrapper>
            </SimpleDisclaimerWrapper>
        );
    }
);
