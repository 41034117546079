import * as React from 'react';

import {TokenTextAppearance} from '@volkswagen-onehub/components-core';
import {CopyItemModel} from '../../generated/core';
import {Richtext} from './Richtext';

export interface CopyProps {
    readonly useDropCap?: boolean;
    readonly appearance?: TokenTextAppearance;
}

export class Copy extends React.Component<CopyProps & CopyItemModel> {
    public render(): JSX.Element {
        return <Richtext {...this.props} />;
    }
}
