import {URLSearchParams} from '../../../utils/UrlSearchParams';

export function filterParams(
    params: URLSearchParams,
    secondaryKey: string,
    removeSuffix?: boolean
): URLSearchParams {
    const newParams = new URLSearchParams();
    params.forEach((value, key) => {
        const suffix = `-${secondaryKey}`;
        if (key.endsWith(suffix)) {
            const normalizedKey = removeSuffix
                ? key.substring(0, key.length - suffix.length)
                : key;
            newParams.append(normalizedKey, value);
        }
    });

    return newParams;
}

export function removeParams(
    params: URLSearchParams,
    secondaryKey: string
): URLSearchParams {
    const suffix = `-${secondaryKey}`;
    const newParams = new URLSearchParams();
    params.forEach((value, key) => {
        if (!key.endsWith(suffix)) {
            newParams.append(key, value);
        }
    });

    return newParams;
}
