import * as React from 'react';
import {useNavigationStore, usePersonalizationStore} from '../../context';

import {Model} from '@adobe/cq-react-editable-components';
import {
    Container,
    ContainerGutter,
    ContainerWrap,
    styled,
    ThemeProviderProps
} from '@volkswagen-onehub/components-core';

import {observer} from 'mobx-react-lite';
import {ParsysModel} from '../../../generated/core';
import {AlertBox} from '../../components/AlertBox';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {NavigationStore} from '../../context/navigation/NavigationStore';
import {
    ContainerExporter,
    ContainerItem
} from '../../infrastructure/ContainerExporter';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {C} from '../../registries/compatibilty';
import {getDnDContainer} from '../../utils/container/getDnDContainer';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {isInVec} from '../../utils/personalization/isInVec';
import {insertPersonalizedPlaceholders} from '../../utils/parsysUtils';

const throttle = require('lodash/throttle');
const TOP_BAR_HEIGHT = 68;

export enum ParsysType {
    LINK_PARSYS,
    ITEM_PARSYS,
    MAIN_PARSYS,
    SECTION_PARSYS,
    SECTION_GROUP_PARSYS,
    STAGE_PARSYS,
    INLINE_BUTTON_PARSYS
}

export interface ParsysProps {
    readonly parsysType: ParsysType;
    readonly alertMessage?: string;
    readonly showPersonalizablePlaceholders?: boolean;
}

interface StyledAuthorParsysProps {
    parsysType: ParsysType;
}

type ThemedAuthorProps = ThemeProviderProps<StyledAuthorParsysProps>;

const StyledAuthorParsys = styled.div<ThemedAuthorProps>`
    & > *:not(:last-child) {
        margin-bottom: ${props =>
            props.theme.size[getContainerGutter(props.parsysType)]};
    }
`;

const RESOURCE_TYPE = 'vwa-ngw18/components/structure/parsys';

type InternalParsysProps = ParsysModel & ParsysProps;

function isInline(parsysType: ParsysType): boolean {
    switch (parsysType) {
        case ParsysType.INLINE_BUTTON_PARSYS:
            return true;
        default:
            return false;
    }
}

function getContainerGutter(parsysType: ParsysType): ContainerGutter {
    switch (parsysType) {
        case ParsysType.LINK_PARSYS:
            return ContainerGutter.static150;
        case ParsysType.INLINE_BUTTON_PARSYS:
            return ContainerGutter.dynamic0050;
        case ParsysType.ITEM_PARSYS:
            return ContainerGutter.dynamic0100;
        case ParsysType.STAGE_PARSYS:
            return ContainerGutter.dynamic0130;
        case ParsysType.SECTION_GROUP_PARSYS:
            return ContainerGutter.dynamic0130;
        case ParsysType.MAIN_PARSYS:
        case ParsysType.SECTION_PARSYS:
        default:
            return ContainerGutter.dynamic0270;
    }
}

function isParsysEmpty(props: InternalParsysProps): boolean {
    const children = getChildItems(props);

    return (
        !children ||
        children.length === 0 || // no children
        (props.parsysType === ParsysType.STAGE_PARSYS &&
            children[0].model.cqType.toLowerCase().indexOf('stage') < 0 &&
            children[0].model.cqType
                .toLowerCase()
                .indexOf('featureappsection') < 0) // no stage or FA as first stage parsys child
    );
}

function addStageParsysAlert(props: InternalParsysProps): JSX.Element {
    if ((props.errorMsgIsEmpty || props.alertMessage) && isParsysEmpty(props)) {
        const message = props.alertMessage || props.errorMsgIsEmpty;

        return (
            <AlertBox>
                <div>{message}</div>
            </AlertBox>
        );
    }

    return <></>;
}

function updateStageHeight(
    ref: React.RefObject<HTMLDivElement>,
    oneHubNavigationStore: NavigationStore
): void {
    if (ref.current) {
        oneHubNavigationStore.setStageHeight(
            ref.current.clientHeight - TOP_BAR_HEIGHT
        );
    }
}

function useStageHeightListener(
    ref: React.RefObject<HTMLDivElement>,
    parsysType: ParsysType
): void {
    const oneHubNavigationStore = useNavigationStore();

    React.useEffect(() => {
        const updateStageHeightThrottled = throttle(updateStageHeight, 100);
        if (parsysType === ParsysType.STAGE_PARSYS && !C.isInEditor()) {
            window.addEventListener('resize', updateStageHeightThrottled);
            updateStageHeight(ref, oneHubNavigationStore);
        }

        return function cleanUp(): void {
            window.removeEventListener('resize', updateStageHeightThrottled);
        };
    }, [parsysType, ref, oneHubNavigationStore]);

    React.useEffect(() => {
        if (parsysType === ParsysType.STAGE_PARSYS && !C.isInEditor()) {
            updateStageHeight(ref, oneHubNavigationStore);
        }
    });
}

function getRenderedChildItems(
    props: ContainerExporter,
    stageRef: React.RefObject<HTMLDivElement>
): JSX.Element[] {
    const childItems = getChildItems(props);

    return childItems
        .filter((child: ContainerItem) => {
            return child.model as Model;
        })
        .map((child: ContainerItem) => {
            return child.key === 'stage' ? (
                <div ref={stageRef} key={child.key}>
                    <PersonalizationInclude
                        key={child.key}
                        resourceType={child.model.cqType}
                        path={child.key}
                    />
                </div>
            ) : (
                <PersonalizationInclude
                    key={child.key}
                    resourceType={child.model.cqType}
                    path={child.key}
                />
            );
        });
}

function usePersonalizablePlaceholders(
    props: InternalParsysProps,
    stageRef: React.RefObject<HTMLDivElement>
): JSX.Element[] {
    const [afterMount, setAfterMount] = React.useState(false);
    const personalizationStore = usePersonalizationStore();
    const {showPersonalizablePlaceholders} = props;

    React.useEffect(() => {
        if (showPersonalizablePlaceholders) {
            if (isInVec() && !afterMount) {
                setAfterMount(true);
            }
        }
    }, [afterMount, showPersonalizablePlaceholders]);

    let _children = getRenderedChildItems(props, stageRef);
    const insertContents = personalizationStore.getInserts(props.cqPath);
    if (!isInVec() || afterMount) {
        _children = insertPersonalizedPlaceholders(
            props.cqPath,
            _children,
            insertContents
        );
    }

    if (
        !props.componentLength ||
        (C.isInEditor() && _children.length < props.componentLength)
    ) {
        const dndContainer = getDnDContainer(props.cqType, props);
        _children.push(dndContainer);
    }

    return _children;
}

export const InternalParsys: React.FunctionComponent<InternalParsysProps> = observer(
    function PS(props: InternalParsysProps): JSX.Element | null {
        const stageRef = React.useRef(null);
        useStageHeightListener(stageRef, props.parsysType);
        const _children = usePersonalizablePlaceholders(props, stageRef);

        // The Container component wraps it's children in StyledChildWrappers. This breaks the AEM editor drag&drop
        // insertAfter functionality because that relies on Node.nextSibling - which for a wrapped node is null.
        // The current workaround is to use Container only in the publish view and simulate the margin in the editor.
        if (!C.isInEditor() && isParsysEmpty(props)) {
            return null;
        }

        return C.isInEditor() ? (
            <>
                {addStageParsysAlert(props)}
                <StyledAuthorParsys parsysType={props.parsysType}>
                    {_children}
                </StyledAuthorParsys>
            </>
        ) : (
            <Container
                gutter={getContainerGutter(props.parsysType)}
                wrap={
                    isInline(props.parsysType)
                        ? ContainerWrap.auto
                        : ContainerWrap.always
                }
            >
                {_children}
            </Container>
        );
    }
);
InternalParsys.displayName = 'InternalParsys';

export const Parsys = MapTo<ParsysProps>(RESOURCE_TYPE, InternalParsys);
