import * as React from 'react';
import styled from 'styled-components';

import {MenuItemModel} from '../../../../generated/core';
import {CmsPlainLink} from '../../../components/links/CmsPlainLink';
import {useNavigationModel} from '../../../context';

const StyledNav = styled.nav`
    // NOTE: One of limited options to render visually hidden content, but still accessible even for screenreaders
    // https://a11yproject.com/posts/how-to-hide-content/
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
`;

function mapListItems(menuItems: MenuItemModel[]): React.ReactElement[] {
    return menuItems.map((item, index) => {
        const hasSubItems = item.menuItems.length > 0;

        // NOTE: for now links aren't focusable - that will change with full AA support later on
        return (
            <li key={index.toString(10)}>
                <CmsPlainLink href={item.url} tabIndex={-1}>
                    {item.title}
                </CmsPlainLink>
                {hasSubItems && mapList(item.menuItems)}
            </li>
        );
    });
}

function mapList(menuItems: MenuItemModel[]): React.ReactElement {
    return <ul>{mapListItems(menuItems)}</ul>;
}

/**
 * SSR navigation for One Hub Templates
 */
export function SSRNavigation(): JSX.Element {
    const {menuItems} = useNavigationModel();

    return (
        <StyledNav role="navigation" aria-hidden="true">
            {mapList(menuItems)}
        </StyledNav>
    );
}
