import * as React from 'react';
import {styled} from '@volkswagen-onehub/components-core';
import {playStateReducer} from './play-states';
import {ScrollytellingContext} from './ScrollLogic';
import {useAnimations, animationProgress} from './use-animation';
import {
    setTopBarHeight,
    CSS_VAR_TOP_BAR_HEIGHT
} from '../../../../d6/components/navigation-top-bar-one-hub-helpers';

export enum ScrollytellingDataState {
    ANIMATING_IN = 'animating-in',
    SCROLLING = 'scrolling',
    ANIMATING_OUT = 'animating-out',
    HIDDEN = 'hidden'
}

const StyledFixedWrapper = styled.div`
${setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, false)};
    width: 100%;
    top: var(${CSS_VAR_TOP_BAR_HEIGHT});
    display: grid;

    & > * {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    &:not([data-state='${ScrollytellingDataState.SCROLLING}']){
        z-index: -1;
    }
    &:not([data-state='${ScrollytellingDataState.HIDDEN}']){
        position: fixed;
    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: white;
        position: absolute;
        inset: 0;
        z-index: 1;
        pointer-events: none;
    }

    &[data-state='${ScrollytellingDataState.ANIMATING_IN}']::before{
        transform-origin: top;
    }

    &[data-state='${ScrollytellingDataState.ANIMATING_OUT}']::before{
        transform-origin: bottom;
  }
`;

export interface IntroOutroAnimationsProps {
    children: React.ReactNode;
}

export const IntroOutroAnimations = (props: IntroOutroAnimationsProps) => {
    const [playState, nextPlayState] = React.useReducer(
        playStateReducer,
        ScrollytellingDataState.HIDDEN
    );
    const {subscribe, forceUpdate} = React.useContext(ScrollytellingContext);
    const stickyRef = React.useRef<HTMLDivElement>(null);
    const {progressAnimations} = useAnimations(stickyRef);

    React.useEffect(() => {
        subscribe(scrollAmount => {
            nextPlayState(scrollAmount);
            progressAnimations(animationProgress(playState, scrollAmount));
        });
    }, [subscribe, playState, progressAnimations]);

    React.useEffect(() => {
        //this is needed to get the right playstate if the page is reloaded
        forceUpdate();
    }, [forceUpdate, playState]);

    return (
        <StyledFixedWrapper data-state={playState} ref={stickyRef}>
            {props.children}
        </StyledFixedWrapper>
    );
};
