import {
    FooterCategoryModel,
    NavigationLinkModel
} from '../../../../generated/core';
import {NavigationTopBarScrollDirection} from '../../../context/navigation/NavigationTopBarScrollDirection';

export interface FooterCategoryMap {
    [index: string]: FooterCategoryModel;
}

export function getLinksForCategory(
    category: string,
    map: FooterCategoryMap
): NavigationLinkModel[] {
    if (map.hasOwnProperty(category)) {
        return map[category].links;
    }

    return [];
}

/**
 * Returns footer links from IMPRINT category.
 * @param map
 */
export function getImprintLinks(map: FooterCategoryMap): NavigationLinkModel[] {
    return getLinksForCategory('IMPRINT', map);
}

/**
 * Returns footer links from LEGAL category.
 * @param map
 */
export function getLegalLinks(map: FooterCategoryMap): NavigationLinkModel[] {
    return getLinksForCategory('LEGAL', map);
}

export function computeLastScrollDirection(
    currentScrollPosition: number,
    lastScrollPosition: number,
    lastScrollDirection: NavigationTopBarScrollDirection
): NavigationTopBarScrollDirection {
    let _lastScrollDirection = lastScrollDirection;
    const scrollDiff = lastScrollPosition - currentScrollPosition;
    if (scrollDiff >= 0) {
        _lastScrollDirection = NavigationTopBarScrollDirection.UP;
    }
    if (scrollDiff < 0) {
        _lastScrollDirection = NavigationTopBarScrollDirection.DOWN;
    }

    return _lastScrollDirection;
}

export interface HasSecondLevelInfo {
    readonly menuItems?: any[];
    navFlyoutFeatureAppConfig?: any;
}

/**
 * Determines if folder has
 */
export function hasSecondLevel(menuItem?: HasSecondLevelInfo): boolean {
    if (!menuItem) {
        return false;
    }

    return (
        Boolean(menuItem.menuItems && menuItem.menuItems.length > 0) ||
        Boolean(menuItem.navFlyoutFeatureAppConfig)
    );
}

/**
 * Create list item key
 * @param name
 * @param index
 */
export function createLIKey(name: string, index: number): string {
    return `${name}-${index.toString(10)}`;
}

/**
 * Create list item id
 * @param name
 */
export function getLIId(name: string): string {
    return name.split(/\s+/).join('');
}

export function isExpandedTopbar(
    shouldRenderInpageNav: boolean,
    lastScrollDirection: NavigationTopBarScrollDirection,
    isAnnouncementBarVisible: boolean,
    isTopBarAboveStage?: boolean
): boolean {
    if (isAnnouncementBarVisible) {
        return true;
    }

    return shouldRenderInpageNav && !isTopBarAboveStage
        ? false
        : lastScrollDirection === 'up';
}
