import * as React from 'react';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {DisclaimerContainerProps} from './helpers';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';

interface UniversalDisclaimerProps {
    readonly customStylingWrapper?: React.ComponentType<any>;
}

const InternalUniversalDisclaimerContainer = observer(function UDC(
    props: DisclaimerContainerProps & UniversalDisclaimerProps
): JSX.Element | null {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const hasReferences = Boolean(disclaimers?.length);

    if (!hasReferences) {
        return null;
    }

    const CustomStylingWrapper = props.customStylingWrapper as React.ComponentType;

    return (
        <CyAttributeAppender name="disclaimersContainer">
            {props.customStylingWrapper ? (
                <CustomStylingWrapper>
                    <DisclaimersWithSafewords disclaimers={disclaimers} />
                </CustomStylingWrapper>
            ) : (
                <DisclaimersWithSafewords disclaimers={disclaimers} />
            )}
        </CyAttributeAppender>
    );
});

export const UniversalDisclaimerContainer = React.memo(
    InternalUniversalDisclaimerContainer
);
