import {AspectRatio} from '@volkswagen-onehub/components-core';
import * as React from 'react';

export type VideoPlayerV2MatchParent = 'matchParentHeight' | 'matchParentWidth';
export type VideoPlayerV2AspectRatio = AspectRatio | VideoPlayerV2MatchParent;
export type VideoPlayerV2CalculatedAspectRatio =
    | number
    | VideoPlayerV2MatchParent;

export enum VideoPlayerV2PlayState {
    NOT_STARTED,
    PLAYING,
    PAUSED,
    ENDED
}

export enum VideoPlayerV2DisplayMode {
    NORMAL,
    FULLSCREEN
}

export type VideoPlayerV2Color = 'dark' | 'light';

export interface VideoPlayerV2DisclaimerProps {
    readonly staticReferences?: JSX.Element[];
    readonly interactiveReferences?: JSX.Element;
    readonly color?: VideoPlayerV2Color;
}

export interface VideoPlayerV2VideoProps {
    onCoverImageLoad?: () => void;
    readonly controls?: boolean;
    readonly innerRef: React.RefObject<HTMLVideoElement>;
    readonly title?: string;
    readonly muted: boolean;
    readonly keepOriginalAspectRatio?: boolean;
    readonly hideAllButtons?: boolean;

    onPlay(): void;
    onPause(): void;
    onEnded(): void;
    onTimeUpdate(): void;
    onLoadedMetadata(): void;
    onDurationChange(): void;
}
