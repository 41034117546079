import {ThemeName, calcAspectRatio} from '@volkswagen-onehub/components-core';

import {
    MAX_VOLUME_VALUE,
    MIN_VOLUME_VALUE
} from '../video-player-helpers/constants';
import {
    VideoPlayerV2AspectRatio,
    VideoPlayerV2CalculatedAspectRatio
} from './types';

declare global {
    interface Element {
        mozRequestFullScreen?(): void;
        webkitRequestFullscreen?(): void;
        msRequestFullscreen?(): void;
    }

    interface Document {
        readonly fullscreenElement: Element | null;
        readonly mozFullScreenElement?: Element | null;
        readonly webkitFullscreenElement?: Element | null;
        readonly msFullscreenElement?: Element | null;
        readonly mozFullScreenEnabled?: boolean;
        readonly webkitFullscreenEnabled?: boolean;
        readonly msFullscreenEnabled?: boolean;

        mozCancelFullScreen?(): void;
        webkitExitFullscreen?(): void;
        msExitFullscreen?(): void;
    }
}

export function fullscreenEnabled(): boolean {
    if (document.fullscreenEnabled) {
        return document.fullscreenEnabled;
    }
    if (document.mozFullScreenEnabled) {
        return document.mozFullScreenEnabled;
    }
    if (document.webkitFullscreenEnabled) {
        return document.webkitFullscreenEnabled;
    }
    if (document.msFullscreenEnabled) {
        return document.msFullscreenEnabled;
    }

    return false;
}

type FullScreenChangeEventName =
    | 'mozfullscreenchange'
    | 'webkitfullscreenchange'
    | 'msfullscreenchange'
    | 'fullscreenchange';

export function fullScreenChangeEventName(): FullScreenChangeEventName {
    if (document.fullscreenEnabled) {
        return 'fullscreenchange';
    }
    if (document.mozFullScreenEnabled) {
        return 'mozfullscreenchange';
    }
    if (document.webkitFullscreenEnabled) {
        return 'webkitfullscreenchange';
    }
    if (document.msFullscreenEnabled) {
        return 'msfullscreenchange';
    }

    return 'fullscreenchange';
}

export type FullScreenElementName =
    | 'mozFullScreenElement'
    | 'webkitFullscreenElement'
    | 'msFullscreenElement'
    | 'fullscreenElement';

export function fullScreenElementName(): FullScreenElementName {
    if (document.fullscreenElement !== undefined) {
        return 'fullscreenElement';
    }
    if (document.mozFullScreenElement !== undefined) {
        return 'mozFullScreenElement';
    }
    if (document.webkitFullscreenElement !== undefined) {
        return 'webkitFullscreenElement';
    }
    if (document.msFullscreenElement !== undefined) {
        return 'msFullscreenElement';
    }

    return 'fullscreenElement';
}

export function enterFullscreen(element: Element): void {
    if (element.requestFullscreen) {
        // not every browser returns a promise
        // tslint:disable-next-line:no-floating-promises
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    }
}

export function exitFullscreen(): void {
    if (document.exitFullscreen) {
        // not every browser returns a promise
        // tslint:disable-next-line:no-floating-promises
        document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    }
}

export const sanitizeVolume = (value: number) => {
    if (value < 0) {
        return 0;
    }
    if (value > 1) {
        return 1;
    }

    return value;
};

// prevent setting maximum value because of Safari.
// Safari presents blank screen when reaching max value.
// value of DURATION_LIMIT_DELTA has been determined experimentally
export const getMaxDuration = (duration?: number) => {
    const DURATION_LIMIT_DELTA = 0.1;
    const maxDuration = duration ? duration - DURATION_LIMIT_DELTA : 0;

    return maxDuration;
};

export const noop = () => undefined;

export const volumeFromMuted = (muted: boolean) =>
    muted ? MIN_VOLUME_VALUE : MAX_VOLUME_VALUE;

export function calcVideoAspectRatio(
    isInFullscreen: boolean,
    videoAspectRatio?: VideoPlayerV2AspectRatio
): VideoPlayerV2CalculatedAspectRatio | undefined {
    if (isInFullscreen) {
        return 'matchParentHeight';
    }
    if (!videoAspectRatio) {
        return undefined;
    }
    if (
        videoAspectRatio === 'matchParentHeight' ||
        videoAspectRatio === 'matchParentWidth'
    ) {
        return videoAspectRatio;
    }

    return calcAspectRatio(videoAspectRatio);
}

export function getTheme(color: string = 'dark'): ThemeName {
    return color === 'dark' ? 'main' : 'inverted';
}
