import * as React from 'react';
import styled from 'styled-components';
import {Breakpoints} from '@volkswagen-onehub/components-core';

import {
    getEndDirection,
    makeClip,
    resetClipVisibility
} from '../../../d6/components/helpers';
import {ZIndex} from '../../../utils/zIndex';

import {isInBrowser} from '../../../utils/browser/isInBrowser';
import {QueryResults} from '../../../context/mediaQueries/useMatchMedia';
import {useGlobalConfig} from '../../../context';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {MobileShortcutButton} from './MobileShortcutButton';
import {SingleMobileNextBestActionButton} from './SingleMobileNextBestActionButton';
import {SingleMobileNextBestActionButtonV2} from './SingleMobileNextBestActionButtonV2';
import {GroupedDesktopNextBestActionButtons} from './GroupedDesktopNextBestActionButtons';
import {SectionNbabTrackingData} from './getActions';
import {useDisplayTracking} from './useDisplayTracking';
import {isTouchDevice} from '../../../utils/browser/isTouchDevice';
import {NextBestActionPanel} from '../../../d6/components/next-best-action-button-v2';
import {useMediaQueriesWithoutDefault} from '../../../context/mediaQueries/mediaQueryContext';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';

export interface StyledNextBestActionButtonWrapperProps {
    readonly isNbabVisible?: boolean;
    readonly isExpanded?: boolean;
}

const collapseAnimationHiddenStateStyles = () => `
    clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 0% 0%);
`;

/*
polygon: 50% added to support box-shadow on focusing child elements & fade-in animation
*/
const collapseAnimationVisibleStateStyles = () => `
    clip-path: polygon(-50% -50%, 150% -50%, 150% 150%, -50% 150%);
`;

/*
bottom: 58px; Value 58 is enough to be above the bottom safe area. Defined by creatives.
*/
export const StyledNextBestActionButtonWrapper = styled.div<
    StyledNextBestActionButtonWrapperProps
>`
    ${({isNbabVisible}) =>
        isNbabVisible
            ? collapseAnimationVisibleStateStyles()
            : collapseAnimationHiddenStateStyles()}
    ${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid001};
    bottom: 58px;
    pointer-events: none;
    position: fixed;
    transition: clip-path 1ms linear 150ms;
    z-index: ${ZIndex.main.nbab.z};

    @media (max-width: ${Breakpoints.b560 - 1}px) {
        bottom: 58px;
    }

    & > div > div {
        position: unset;
    }

    & > div {
        margin-top: ${props => props.theme.size.static150};
    }
`;

export const StyledNextBestActionPanelWrapper = styled.div<
    StyledNextBestActionButtonWrapperProps
>`
    ${makeClip}

    ${({isNbabVisible}) => isNbabVisible && resetClipVisibility};
`;

export type NextBestActionGroupProps = Readonly<{
    actions?: MergeNextBestActionModel[];
    isNbabVisible?: boolean;
    trackingData?: SectionNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
    showDesktopForTesting?: boolean;
    pageActions: boolean;
    isHorizontalView?: boolean;
    noIconForHorizontalView: boolean;
}>;

// Minimum delay before tracking if fired (300ms isnt enough if there are multiple sections slowing down performance)
const NBAB_TRACKING_DELAY = 500;

export function NextBestActionGroup(
    props: NextBestActionGroupProps
): JSX.Element {
    const {
        pageActions,
        isNbabVisible,
        sectionRef,
        actions,
        trackingData,
        isHorizontalView,
        noIconForHorizontalView
    } = props;

    const mediaQueries = useMediaQueriesWithoutDefault();
    const isMobileOrTabletOrLaptop =
        mediaQueries?.isMobile ||
        mediaQueries?.isTablet ||
        (mediaQueries?.isLaptop && isTouchDevice());

    const itemCount = actions?.length || 0;
    const spaGlobalConfig = useGlobalConfig();
    const mobileLabel = React.useMemo(() => spaGlobalConfig.nbabMobileLabel, [
        spaGlobalConfig
    ]);
    const openDropdownLabel = React.useMemo(
        () => spaGlobalConfig.nbabOpenDropdownLabel,
        [spaGlobalConfig]
    );
    const closeDropdownLabel = React.useMemo(
        () => spaGlobalConfig.nbabCloseDropdownLabel,
        [spaGlobalConfig]
    );

    useDisplayTracking(
        isNbabVisible,
        actions || [],
        pageActions,
        trackingData,
        NBAB_TRACKING_DELAY
    );

    const desktop = (
        <GroupedDesktopNextBestActionButtons
            itemCount={itemCount}
            trackingData={trackingData}
            isHidden={!isNbabVisible}
            actions={actions || []}
        />
    );

    const mobile =
        actions?.length === 1 ? (
            actions.map(
                (nbabModel: MergeNextBestActionModel, index: number) => (
                    <SingleMobileNextBestActionButton
                        actionModel={nbabModel}
                        key={index}
                        trackingData={{
                            ...trackingData,
                            itemCount: 1,
                            itemPosition: index + 1,
                            personalizedReporting:
                                nbabModel.personalizedReporting
                        }}
                        isHidden={!isNbabVisible}
                        // inaccessible={!isAccessibleNBAB}
                        sectionRef={sectionRef}
                    />
                )
            ) || []
        ) : (
            <MobileShortcutButton
                key="main"
                label={mobileLabel}
                icon="List"
                actions={actions}
                trackingData={trackingData}
                isHidden={!isNbabVisible}
                // inaccessible={!isAccessibleNBAB}
                sectionRef={sectionRef}
            />
        );

    const mobileV2 = (actions || []).map(
        (nbabModel: MergeNextBestActionModel, index: number) => (
            <SingleMobileNextBestActionButtonV2
                actionModel={nbabModel}
                key={index}
                trackingData={{
                    ...trackingData,
                    itemCount: itemCount,
                    itemPosition: index + 1,
                    personalizedReporting: nbabModel.personalizedReporting
                }}
                isHidden={!isNbabVisible}
                // inaccessible={!isAccessibleNBAB}
                sectionRef={sectionRef}
                type={index > 0 ? 'subaction' : 'main'}
                hideIcon={index === 0 && noIconForHorizontalView}
            />
        )
    );

    const horizontalPanel: JSX.Element = (
        <CyAttributeAppender name="NextBestActionV2">
            <NextBestActionPanel
                actions={mobileV2}
                isVisible={isNbabVisible}
                openDropdownLabel={openDropdownLabel}
                closeDropdownLabel={closeDropdownLabel}
            />
        </CyAttributeAppender>
    );

    const nbabContent: JSX.Element | JSX.Element[] | null = getNbabContent(
        mediaQueries,
        desktop,
        mobile,
        horizontalPanel,
        isHorizontalView,
        isMobileOrTabletOrLaptop
    );

    return (
        <>
            {!isHorizontalView && (
                <StyledNextBestActionButtonWrapper
                    isNbabVisible={isNbabVisible}
                    // aria-hidden={inaccessible}
                >
                    {nbabContent}
                </StyledNextBestActionButtonWrapper>
            )}

            {isHorizontalView && (
                <StyledNextBestActionPanelWrapper isNbabVisible={isNbabVisible}>
                    {nbabContent}
                </StyledNextBestActionPanelWrapper>
            )}
        </>
    );
}

function getNbabContent(
    mediaQueries: QueryResults | null,
    desktop: JSX.Element,
    mobile: JSX.Element | JSX.Element[],
    horizontalPanel: JSX.Element | JSX.Element[],
    isHorizontalView?: boolean,
    isMobileOrTabletOrLaptop?: boolean,
    showDesktopForTesting?: boolean
) {
    if (showDesktopForTesting !== undefined)
        return showDesktopForTesting ? desktop : mobile;

    if (!mediaQueries || !isInBrowser()) return null;

    if (isHorizontalView) return horizontalPanel;

    if (!isHorizontalView && isMobileOrTabletOrLaptop) {
        return mobile;
    }

    return desktop;
}
