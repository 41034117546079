import * as React from 'react';

import {
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {LinkTarget, TeaserElement} from '../../d6/components/teaser-element';

import {LayerType} from '../../../generated/api';
import {TrackingActionOverride} from '../../infrastructure/TrackableLinkProps';
import {TeaserCategoryLinkDisclaimerContainer} from '../disclaimers/TeaserCategoryLinkDisclaimerContainer';
import {CmsImageLink} from '../links/CmsImageLink';
import {CmsTeaserElementLink} from '../links/CmsTeaserElementLink';
import {CmsTextLink} from '../links/CmsTextLink';
import {RtNodeUnion} from '../../../generated/core';
import {Richtext} from '../Richtext';
import {hoverGroupConsumer} from '../../d6/components/hover-group';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';

export interface TeaserMainLinkProps {
    readonly href: string;
    readonly contentId?: string;
    readonly isLayerLink?: boolean;
    readonly layerType?: LayerType;
    readonly target?: LinkTarget;
    trackingActionOverride: TrackingActionOverride;
}

export const TeaserImageLink = hoverGroupConsumer(
    CmsImageLink,
    TeaserElement.hoverGroupId
);

export const TeaserMainLink = hoverGroupConsumer(
    CmsTeaserElementLink,
    TeaserElement.hoverGroupId
);

export interface TeaserElementCategoryProps {
    readonly href: string;
    readonly contentId?: string;
    readonly isLayerLink?: boolean;
    readonly layerType?: LayerType;
    readonly text?: RtNodeUnion[];
    readonly target?: LinkTarget;
}

export const TeaserElementCategory: React.FunctionComponent<TeaserElementCategoryProps> = props => (
    <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
        <Text appearance={TokenTextAppearance.copy200} bold>
            <CmsTextLink
                href={props.href}
                contentId={props.contentId}
                isLayerLink={props.isLayerLink}
                layerProps={{layerType: props.layerType || 'CONTENT'}}
                target={props.target}
            >
                <Richtext
                    richtext={props.text || []}
                    inheritColor
                    tag={TextTag.span}
                    placeholder={''}
                />
            </CmsTextLink>
        </Text>
        <TeaserCategoryLinkDisclaimerContainer
            displayTypes={['T4_ITEM_BASED']}
        />
    </GeneralDisclaimerProvider>
);
