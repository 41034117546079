import * as React from 'react';

import {FirstLevelTeaser} from '../../../d6/components/first-level-teaser';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import {FirstLevelTeaserSectionModel} from '../../../../generated/core';
import {
    ResponsiveMediaRenderer,
    ResponsiveMediaRendererConf
} from '../../../components/ResponsiveMediaRenderer';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {useTeaserList} from '../../../utils/tracking/useTeaserList';
import {EditorialTeaserElement} from '../elements/editorial-teaser-element/EditorialTeaserElement';
import {QuoteItem} from '../items/QuoteItem';
import {SectionWrapper} from './SectionWrapper';
import {
    getAspectRatioByIndex,
    getTeaserDisplayTypeByIndex
} from './first-level-teaser/helpers';
import {
    TeaserSectionAuthoringView,
    teaserSectionHeading
} from './teasers/common';
import {observer} from 'mobx-react-lite';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useTeaserMboxIds} from '../../../utils/tracking/useTeaserMboxIds';

const EMPTY_LABEL = 'S113 First Level Teaser Section';

const firstLevelTeaserResponsiveMediaConf: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: `(max-width: ${Breakpoints.b560 - 1}px)`,
        sizes: '100vw'
    },
    {
        mediaQuery: `(min-width: ${Breakpoints.b560}px)`,
        sizes: '50vw'
    }
];

const InternalFirstLevelTeaserSection = observer(function FLTS(
    props: FirstLevelTeaserSectionModel
): JSX.Element | null {
    const {
        anchorId,
        mboxId,
        cqItems,
        contentId: sectionId,
        validationError,
        validationMessages,
        hasQuoteItem,
        contentLabels,
        contentName,
        tagTypeBroken,
        personalizableChildren
    } = props;
    const teaserList = useTeaserList(personalizableChildren, cqItems);
    const teaserMboxIds = useTeaserMboxIds(personalizableChildren, cqItems);

    function renderItems(): JSX.Element[] {
        const {
            showAdditionalLink,
            showCategoryElement,
            personalizable,
            personalizableChildren,
            numberOfValidElements
        } = props;
        const items: JSX.Element[] = [];

        for (let i = 0; i < numberOfValidElements; i++) {
            const itemId = `item_${i}`;

            const firstLevelTeaser = (
                <EditorialTeaserElement
                    path={itemId}
                    key={itemId}
                    personalizable={!personalizable && personalizableChildren}
                    displayType={getTeaserDisplayTypeByIndex(i)}
                    showAbstractElement={true}
                    showCategoryElement={showCategoryElement}
                    showAdditionalLink={showAdditionalLink}
                    itemCount={numberOfValidElements}
                    itemPosition={i + 1}
                />
            );

            const contextValue = {
                ratio: getAspectRatioByIndex(i)
            };

            items.push(
                <MediaContext.Provider value={contextValue}>
                    <ResponsiveMediaRenderer
                        configs={firstLevelTeaserResponsiveMediaConf}
                    >
                        {firstLevelTeaser}
                    </ResponsiveMediaRenderer>
                </MediaContext.Provider>
            );
        }

        return items;
    }

    // error in publish view, show nothing
    if (validationError && !C.isInEditor()) {
        return null;
    }

    const quote = elseUndefined(hasQuoteItem, <QuoteItem path="quote" />);
    const headlineElement = elseUndefined(
        C.isInEditor() || !cqItems.heading.empty,
        teaserSectionHeading
    );

    return (
        <TeaserSectionAuthoringView
            anchorId={anchorId}
            emptyLabel={EMPTY_LABEL}
            validationMessages={C.isInEditor() ? validationMessages : []}
            validationError={validationError}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                teaserList={teaserList}
                className="vwa-cms-first-level-teaser"
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
                teaserMboxIds={teaserMboxIds}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <CyAttributeAppender name="firstLevelTeaserSection">
                        <>
                            <FirstLevelTeaser
                                headlineElement={headlineElement}
                                teaserItems={renderItems()}
                                quoteItem={quote}
                            />
                            <SectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        </>
                    </CyAttributeAppender>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </TeaserSectionAuthoringView>
    );
});

export const FirstLevelTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/firstLevelTeaserSection',
    InternalFirstLevelTeaserSection
);
