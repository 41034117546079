import {styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {ScrollytellingSectionModel} from '../../../../../generated/core';
import {useMediaQueries} from '../../../../context/mediaQueries/mediaQueryContext';
import {
    CSS_VAR_TOP_BAR_HEIGHT,
    setTopBarHeight
} from '../../../../d6/components/navigation-top-bar-one-hub-helpers';
import {useResizeObserver} from '../../../../d6/hooks/useResizeObserver';
import {save, useScrollSpeed} from './helper';

const StyledScrollContainer = styled.div<{
    height: number;
}>`
    position: relative;
    height: ${props => props.height}px;
`;

const StyledScrollElement = styled.div`
    ${setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, false)};
    --full-viewport: calc(var(--100vh, 100vh) - var(${CSS_VAR_TOP_BAR_HEIGHT}));
    height: var(--full-viewport);
    position: relative;
`;

export const INITIAL_SCROLL_CALCULATIONS: ScrollCalculations = {
    scrollBottom: -1,
    offsetTop: -1,
    maximumScrollHeight: -1,
    mediaHeight: -1
};
const TOPBAR_HEIGHT = 52;

export interface ScrollCalculations {
    scrollBottom: number;
    offsetTop: number;
    maximumScrollHeight: number;
    mediaHeight: number;
}

export const getScrollCalculations = (
    scrollContainerRef: React.RefObject<HTMLElement>,
    mediaHeight: number
): ScrollCalculations => {
    const scrollContainerElement = scrollContainerRef.current;
    if (!scrollContainerElement) {
        return INITIAL_SCROLL_CALCULATIONS;
    }

    const {height, top} = scrollContainerElement.getBoundingClientRect();
    const offsetTop = top + window.scrollY - TOPBAR_HEIGHT;

    return {
        scrollBottom: offsetTop + height,
        offsetTop,
        maximumScrollHeight: height - mediaHeight,
        mediaHeight
    };
};

export interface ScrollAmounts {
    video: number;
    fadeIn: number;
    fadeOut: number;
}

export const calculateScrollAmounts = (
    calculations: ScrollCalculations
): ScrollAmounts => {
    const {
        offsetTop,
        maximumScrollHeight,
        mediaHeight,
        scrollBottom
    } = calculations;

    const scrollStartPosition = window.scrollY - offsetTop;
    const video = save(scrollStartPosition / maximumScrollHeight, 0);
    const fadeIn = save((scrollStartPosition + mediaHeight) / mediaHeight, 0);
    const fadeOut = save(
        (window.scrollY - scrollBottom + mediaHeight) / mediaHeight,
        0
    );

    return {
        video,
        fadeIn,
        fadeOut
    };
};

export type ScrollyTellingSubscribe = (scrollAmounts: ScrollAmounts) => void;

export const ScrollytellingContext = React.createContext({
    subscribe(scrollFunction: ScrollyTellingSubscribe) {
        return () => console.log('subscribe', scrollFunction);
    },
    getCalculations() {
        return INITIAL_SCROLL_CALCULATIONS;
    },
    forceUpdate() {
        console.log('force update');
    }
});

export interface ScrollContainerProps extends ScrollytellingSectionModel {
    children: React.ReactNode;
}

export const ScrollLogicProvider = (props: ScrollContainerProps) => {
    const scrollHeight = useScrollSpeed(props);
    const {reducedAnimation} = useMediaQueries();

    const [calculations, setCalculations] = React.useState<ScrollCalculations>(
        INITIAL_SCROLL_CALCULATIONS
    );

    const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
    const scrollElementRef = React.useRef<HTMLDivElement | null>(null);
    const bodyRef = React.useRef<HTMLElement | null>(null);
    const subscriberRef = React.useRef(new Set<ScrollyTellingSubscribe>());

    const setElementsToRefs = (node: HTMLDivElement | null) => {
        if (!node) {
            return;
        }
        scrollContainerRef.current = node;
        bodyRef.current = document.body;
    };

    useResizeObserver(bodyRef, () => {
        setCalculations(prevState =>
            getScrollCalculations(scrollContainerRef, prevState.mediaHeight)
        );
    });

    useResizeObserver(scrollElementRef, height => {
        setCalculations(getScrollCalculations(scrollContainerRef, height));
    });

    const subscribe = (scrollFunction: ScrollyTellingSubscribe) => {
        subscriberRef.current.add(scrollFunction);
        return () => {
            subscriberRef.current.delete(scrollFunction);
        };
    };

    const getCalculations = React.useCallback(() => {
        return calculations;
    }, [calculations]);

    const forceUpdate = React.useCallback(() => {
        const scrollAmounts = calculateScrollAmounts(calculations);
        subscriberRef.current.forEach(callback => callback(scrollAmounts));
    }, [calculations]);

    React.useEffect(() => {
        if (reducedAnimation) {
            return;
        }
        const subscribers = subscriberRef.current;

        document.addEventListener('scroll', forceUpdate, {
            passive: true
        });
        forceUpdate();

        return () => {
            document.removeEventListener('scroll', forceUpdate);
            subscribers.clear();
        };
    }, [calculations, reducedAnimation, forceUpdate]);

    return (
        <ScrollytellingContext.Provider
            value={{subscribe, getCalculations, forceUpdate}}
        >
            <StyledScrollContainer
                height={scrollHeight}
                ref={setElementsToRefs}
            >
                <StyledScrollElement ref={scrollElementRef}>
                    {props.children}
                </StyledScrollElement>
            </StyledScrollContainer>
        </ScrollytellingContext.Provider>
    );
};
