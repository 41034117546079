import * as React from 'react';
import styled from 'styled-components';

import {Direction} from '../helpers';

const StyledIconBadgeWrapper = styled.div`
    position: relative;
`;

const BADGE_LINE_HEIGHT = 16;
const BADGE_VALUE_PADDING = 5;

StyledIconBadgeWrapper.displayName = 'StyledIconBadgeWrapper';

const StyledIconBadge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${BADGE_LINE_HEIGHT}px;
    min-width: ${BADGE_LINE_HEIGHT}px;
    width: auto;
    position: absolute;
    top: -8px;
    left: ${props =>
        props.theme.direction !== Direction.RTL
            ? '21px'
            : '2px'}; // design specific values
    line-height: ${BADGE_LINE_HEIGHT}px;
    border-radius: ${BADGE_LINE_HEIGHT / 2}px;
    transform: translate(-50%, 0);
    background: ${props => props.theme.colors.signal.neutral};
    pointer-events: none;
`;

StyledIconBadge.displayName = 'StyledIconBadge';

const StyledBadgeValue = styled.span`
    font-size: 12px !important; // design request
    font-weight: bold;
    vertical-align: top;
    color: ${props => props.theme.colors.content.inverted};
    padding: ${BADGE_VALUE_PADDING}px;
`;

StyledBadgeValue.displayName = 'StyledBadgeValue';

interface IconBadgeWrapperProps {
    children: React.ReactNode;
    badgeValue?: number;
}

export function IconBadgeWrapper(props: IconBadgeWrapperProps): JSX.Element {
    const {children, badgeValue} = props;

    return (
        <>
            {badgeValue && badgeValue !== 0 ? (
                <StyledIconBadgeWrapper>
                    {children}
                    <StyledIconBadge>
                        <StyledBadgeValue>{badgeValue}</StyledBadgeValue>
                    </StyledIconBadge>
                </StyledIconBadgeWrapper>
            ) : (
                children
            )}
        </>
    );
}
