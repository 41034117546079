import * as React from 'react';

import {ThemeDefinition, styled} from '@volkswagen-onehub/components-core';
import {ThemedStyledProps} from 'styled-components';

import {Direction} from '../helpers';

// NOTE: change color values to new tokens
export const radialGradient = `radial-gradient(
    circle at ${0}% ${0}%,
    #00437a 0%,
    #001e50 100%
);`;

// Position of center of circular gradient
const gradientCenter = {
    x: 75,
    y: 45
};

// NOTE: change color values to new tokens
const createGradientStyle = (
    props: ThemedStyledProps<GradientBackgroundProps, ThemeDefinition>
) => {
    const {centerX = gradientCenter.x, centerY = gradientCenter.y} = props;

    const calcXPosition =
        props.theme.direction === Direction.RTL ? 100 - centerX : centerX;
    const lightColor = '#00437a'; // blue500
    const darkColor = '#001e50'; // blue600

    return `
		radial-gradient(
			circle at ${calcXPosition}% ${centerY}%,
			${lightColor} 0%,
			${darkColor} 100%
		)
	`;
};

export interface GradientBackgroundProps {
    readonly centerX?: number;
    readonly centerY?: number;
    readonly fixed?: boolean;
}

export const StyledBackgroundProvider = styled.div.attrs<
    GradientBackgroundProps
>((props: ThemedStyledProps<GradientBackgroundProps, ThemeDefinition>) => ({
    style: {
        backgroundImage: createGradientStyle(props)
    }
}))<GradientBackgroundProps>`
    width: 100%;
    height: 100%;
    ${props => props.fixed && 'background-attachment: fixed;'};
`;
StyledBackgroundProvider.displayName = 'StyledBackgroundProvider';

export const GradientBackground: React.FunctionComponent<GradientBackgroundProps> = props => (
    <StyledBackgroundProvider
        centerX={props.centerX}
        centerY={props.centerY}
        fixed={props.fixed}
    >
        {props.children}
    </StyledBackgroundProvider>
);
