import {LoginModel} from '../../../generated/core';

/**
 * if sessionTimeout is turned on and its value is set, return true
 * @param loginModel
 * @returns
 */
export const isSessionTimeoutEnabled = (loginModel: LoginModel) => {
    return Boolean(
        loginModel.sessionTimeoutEnabled && loginModel.sessionTimeout
    );
};
