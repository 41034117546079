import * as React from 'react';

import {
    InteractionLayerSizeV2,
    LayerHandleV2,
    LayerManagerV27,
    ShimBackgroundTypeV2
} from '@volkswagen-onehub/layer-manager';
import {LoginContentModel, SpaGlobalConfig} from '../../../generated/core';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {TrackingService} from '../tracking/TrackingService';
import {closeButtonContext} from '../../components/LoginFlyoutV2';
import {LayerDisclaimerContext} from '../disclaimer/DisclaimerStoreContext';
import {FlyoutLoggedOutMultipleCustomerTypes} from '../../modules/structure/login/FlyoutLoggedOutMultipleCustomerTypes';
import {useLoginFlyoutService} from '..';
import {LoginFlyoutCloseButton} from './LoginFlyoutService';
import {AuthServiceProviderV2} from '@volkswagen-onehub/authservice';
import {getEncodedRedirectUrl} from './getEncodedRedirectUrl';
import {CustomerType, LoginOptions} from '@volkswagen-onehub/login-service';

@singleton('MultipleCustomerLoginFeatureService')
export class MultipleCustomerLoginFeatureService {
    public flyoutLoggedOutMultipleCustomerTypesPath!: string;

    @inject() private readonly layerManager!: LayerManagerV27;
    @inject() private trackingService!: TrackingService;
    @inject() private loginContentModel!: LoginContentModel;
    @inject() private readonly authServiceProvider!: AuthServiceProviderV2;
    @inject()
    private readonly authServiceProviderCommercial!: AuthServiceProviderV2;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;

    private flyoutLayer: LayerHandleV2<void> | null = null;
    get mainLegalEntity(): string {
        return this.spaGlobalConfig.loginModel.cmsLegalEntity;
    }

    public async login(
        customerType: CustomerType,
        legalEntity?: string,
        options?: LoginOptions<unknown>
    ) {
        const cmsLegalEntity = this.mainLegalEntity;
        const prompt = options && options.prompt;
        const actualLegalEntity = legalEntity || cmsLegalEntity;
        const redirectUrl =
            (options && options.redirectUrl) || getEncodedRedirectUrl();
        if (customerType === 'private') {
            const authService = this.authServiceProvider.register(
                actualLegalEntity
            );
            await authService.login(prompt, redirectUrl);
        } else {
            const authService = this.authServiceProviderCommercial.register(
                actualLegalEntity
            );
            await authService.login(options?.prompt, redirectUrl);
        }
    }

    public loginMultipleCustomerType(
        legalEntity?: string,
        options?: LoginOptions<unknown>
    ): void {
        const renderFlyout = this.getRenderFn(async customerType =>
            this.login(customerType, legalEntity, options)
        );

        if (!this.flyoutLayer) {
            this.flyoutLayer = this.layerManager.openInteractionLayer(
                renderFlyout,
                undefined,
                {
                    id: 'vwa-cms-login-flyout-external',
                    size: InteractionLayerSizeV2.C,
                    userCloseable: true,
                    onClose: () => {
                        this.closeFlyout(true);
                        options &&
                            options.cancellationListener &&
                            options.cancellationListener();
                    },
                    shimBackgroundType: ShimBackgroundTypeV2.OPAQUE
                }
            );

            this.trackingService.trackLoginFlyoutOpen();
        }
    }

    public readonly closeFlyout = (shouldTrack: boolean): void => {
        if (this.flyoutLayer) {
            this.flyoutLayer.close();

            if (shouldTrack) {
                this.trackingService.trackLoginFlyoutClose();
            }
        }

        this.flyoutLayer = null;
    };

    private readonly getRenderFn = (
        login: (customerType: CustomerType) => Promise<void>
    ) => {
        return () => {
            return (
                <MultipleCustomerFlyout
                    loginContentModel={this.loginContentModel}
                    closeFlyout={() => this.closeFlyout(false)}
                    login={login}
                />
            );
        };
    };
}

const MultipleCustomerFlyout = (props: {
    loginContentModel: LoginContentModel;
    closeFlyout: () => void;
    login: (customerType: CustomerType) => Promise<void>;
}) => {
    const flyoutService = useLoginFlyoutService();
    const closeButton = (
        <LoginFlyoutCloseButton
            btnCloseLabel={
                props.loginContentModel.flyoutLoggedOut.btnCloseLabel
            }
            onClick={() => props.closeFlyout()}
        />
    );
    return (
        <closeButtonContext.Provider value={closeButton}>
            <LayerDisclaimerContext
                path={flyoutService.flyoutLoggedOutMultipleCustomerTypesPath}
            >
                <FlyoutLoggedOutMultipleCustomerTypes
                    path={
                        flyoutService.flyoutLoggedOutMultipleCustomerTypesPath
                    }
                    login={props.login}
                />
            </LayerDisclaimerContext>
        </closeButtonContext.Provider>
    );
};
