import * as React from 'react';
import styled from 'styled-components';

// NOTE: load from core once fixed
// import {
//     Breakpoints,
//     CTA,
//     GalleryContext
// } from '@volkswagen-onehub/components-core';
import {Breakpoints, CTA} from '@volkswagen-onehub/components-core';
import {GalleryContext} from '../gallery-core-v67.18';

import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core';

import {Direction} from '../helpers';

const StyledArrowWrapper = styled.div<{
    hideChips?: boolean;
}>`
    --chips-height: calc(
        ${({theme}) => theme.size.static400} +
            ${({theme}) => theme.size.static300}
    );
    --height-2slides-per-page: calc(${({theme}) => theme.size.grid010} - 24px);
    --height-3slides-per-page: calc(${({theme}) => theme.size.grid007} - 40px);

    position: absolute;
    top: ${({hideChips}) => (hideChips ? '0' : 'var(--chips-height)')};
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${props => props.theme.size.static200};
    height: var(--height-2slides-per-page);
    pointer-events: none;

    button {
        pointer-events: initial;
    }

    button[disabled] {
        visibility: hidden;
        transition: none;
    }

    // NOTE: unify with "d6/components/helpers/flippin-icon.tsx" and export as CSS helper for both?
    svg {
        transform: rotateY(
            ${props =>
                props.theme.direction === Direction.RTL ? '180deg' : '0'}
        );
    }

    // fully hidden for mobile portrait
    @media (max-width: ${Breakpoints.b560 - 1}px) {
        display: none;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        height: var(--height-3slides-per-page);
    }
`;

export type ArrowButtonsProps = {
    buttonNextAriaLabel: string;
    buttonPreviousAriaLabel: string;
    hideChips?: boolean;
};
export const ARROWS_TEST_ID = 'content-slider-arrows';

export const ArrowButtons: React.FunctionComponent<ArrowButtonsProps> = ({
    buttonNextAriaLabel,
    buttonPreviousAriaLabel,
    hideChips
}) => {
    const {
        triggerNext,
        triggerPrevious,
        length,
        slidesPerPage,
        index,
        endlessScrolling
    } = React.useContext(GalleryContext);

    if (length <= slidesPerPage) {
        return null;
    }

    return (
        <StyledArrowWrapper data-testid={ARROWS_TEST_ID} hideChips={hideChips}>
            <CTA
                tag="button"
                emphasis="primary"
                icon={<ChevronLeft ariaHidden />}
                onClick={triggerPrevious}
                ariaLabel={buttonPreviousAriaLabel}
                disabled={index === 0 && !endlessScrolling}
            />
            <CTA
                tag="button"
                emphasis="primary"
                icon={<ChevronRight ariaHidden />}
                onClick={triggerNext}
                ariaLabel={buttonNextAriaLabel}
                disabled={index + slidesPerPage === length && !endlessScrolling}
            />
        </StyledArrowWrapper>
    );
};
