import {TechData} from '../../../../generated/core';
import {Direction} from '../helpers';

interface UseOrderedTileValuesProps
    extends Omit<TechData, 'value' | 'unit' | 'disclaimers'> {
    value: JSX.Element | string;
    unit: JSX.Element | string;
    direction: Direction;
}

export const useOrderedTileValues = (props: UseOrderedTileValuesProps) => {
    const {value, unit, unitBeforeValue, unitSuffix, direction} = props;

    const unitValueOrder = (unitBeforeValue ? [unit, value] : [value, unit])
        .concat(unitSuffix)
        .filter(Boolean);

    return direction === Direction.RTL
        ? [...unitValueOrder].reverse()
        : unitValueOrder;
};
