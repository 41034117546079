import {SpaGlobalConfig} from '../../../generated/core';
import {isAnchorLink} from '../../utils/link/isAnchorLink';
import {CONFIGURE_PROTOCOL} from '../../components/links/LinkHandlers';

export function isAppLink(
    href: string,
    spaGlobalConfig: SpaGlobalConfig,
    appRootPath: string
): boolean {
    if (href.startsWith(CONFIGURE_PROTOCOL)) {
        return true;
    }
    if (Boolean(spaGlobalConfig.integratorTemplateModel)) {
        return false;
    }

    return href.startsWith(appRootPath) || isAnchorLink(href);
}
