import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {observer} from 'mobx-react-lite';
import {TextOnlyTeaserSectionModel} from '../../../../generated/core';
import {C} from '../../../registries/compatibilty';
import {elseUndefined} from '../../../utils/elseUndefined';
import {TeaserSectionAuthoringView} from './teasers/common';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import * as React from 'react';
import {useTeaserList} from '../../../utils/tracking/useTeaserList';
import {
    XfTextOnlyTeaser,
    TopLineTextPosition
} from '../../structure/main/XfTextOnlyTeaser';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {SectionWrapper} from './SectionWrapper';
import {TextOnlyTeaserSection as TextOnlyTeaserSectionLSG} from '../../../d6/components/text-only-teaser-section';
import {HeadingElement} from '../elements/HeadingElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useTeaserMboxIds} from '../../../utils/tracking/useTeaserMboxIds';

const InternalTextOnlyTeaserSection = observer(function TOTS(
    props: TextOnlyTeaserSectionModel
) {
    const {
        anchorId,
        contentId: sectionId,
        tagTypeBroken,
        personalizableChildren,
        cqItems,
        validationMessages,
        validationError,
        contentLabels,
        contentName,
        mboxId
    } = props;

    const teaserList = useTeaserList(personalizableChildren, cqItems);

    const headline = <HeadingElement path="headline" order="H2" />;
    const teaserMboxIds = useTeaserMboxIds(personalizableChildren, cqItems);

    if (C.isInEditor()) {
        // authoring view
        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <TeaserSectionAuthoringView
                    anchorId={anchorId}
                    emptyLabel={'S135 Text Only Teaser Section'}
                    validationMessages={validationMessages}
                    validationError={validationError}
                    tagTypeBroken={tagTypeBroken}
                >
                    <TextOnlyTeaserSectionLSG headline={headline}>
                        {renderItems(props)}
                    </TextOnlyTeaserSectionLSG>
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </TeaserSectionAuthoringView>
            </GeneralDisclaimerProvider>
        );
    } else {
        // publish view

        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <CyAttributeAppender name="textOnlyTeaserSection">
                    <SectionWrapper
                        mboxId={mboxId}
                        anchorId={anchorId}
                        sectionId={sectionId}
                        contentLabels={contentLabels}
                        teaserList={teaserList}
                        contentName={contentName}
                        teaserMboxIds={teaserMboxIds}
                    >
                        <TextOnlyTeaserSectionLSG
                            headline={elseUndefined(
                                !cqItems.headline.empty,
                                headline
                            )}
                        >
                            {renderItems(props)}
                        </TextOnlyTeaserSectionLSG>
                        <SectionDisclaimerContainer
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </SectionWrapper>
                </CyAttributeAppender>
            </GeneralDisclaimerProvider>
        );
    }
});

function renderItems(props: TextOnlyTeaserSectionModel): JSX.Element[] {
    const {
        numberOfValidElements,
        personalizable,
        personalizableChildren,
        topLineTextPosition
    } = props;

    const mappedTeasers = [...Array(numberOfValidElements)].map((_, index) => {
        const itemId = `item_${index}`;
        return (
            <XfTextOnlyTeaser
                path={itemId}
                key={itemId}
                personalizable={!personalizable && personalizableChildren}
                topLineTextPosition={topLineTextPosition as TopLineTextPosition}
                hasSiblings={numberOfValidElements > 1}
            />
        );
    });

    return mappedTeasers;
}

export const TextOnlyTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/textOnlyTeaserSection',
    InternalTextOnlyTeaserSection
);
