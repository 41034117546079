import {
    Breakpoints,
    Fade,
    MovingFrame,
    ThemeProvider,
    styled
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {FeatureAppNotifications} from '../../../modules/structure/FeatureAppNotifications';
import {isEDGE} from '../helpers';
import {
    ANIMATION_DURATION,
    CSS_VAR_TOP_BAR_HEIGHT,
    setTopBarHeight
} from '../navigation-top-bar-one-hub-helpers';
import {
    NavigationTopBarProps,
    StyledLeftItemsWrapper,
    StyledLogoLayer,
    StyledNavigationTopBarProps,
    StyledRightItemsWrapperProps,
    StyledStaticLogoLayer,
    StyledStaticLogoWrapper,
    topBarHeightValue
} from '../navigation-top-bar-one-hub';

export const TOP_BAR_STATIC_HEIGHT_MOBILE = 56;
export const TOP_BAR_STATIC_HEIGHT_DESKTOP = 64;

const StyledNavigationTopBarV2 = styled.header<StyledNavigationTopBarProps>`
    width: 100%;
    ${props => setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, props.isExpanded)};
    height: ${props =>
        props.hasCustomHeight || props.isStatic ? 'auto' : topBarHeightValue};

    ${props =>
        !props.isEDGE && `transition: height ${ANIMATION_DURATION} ease-in`};

    color: ${props => props.theme.colors.content.primary};
    background-color: ${props =>
        props.hasTransparentBackground
            ? 'transparent'
            : props.theme.colors.background.primary};

    // show inpagenav only
    transform: translateY(0);

    ${props =>
        props.shouldRenderInpageNav &&
        props.isInPageNavigationVisible &&
        `
        transition: transform .3s;
        transform: translateY(-${TOP_BAR_STATIC_HEIGHT_MOBILE}px);

        @media (min-width: ${Breakpoints.b560}px) {
            transform: translateY(-${TOP_BAR_STATIC_HEIGHT_DESKTOP}px);
        }
        `};

    ${props =>
        props.shouldRenderInpageNav &&
        props.isInPageNavigationVisible &&
        props.showInpageNavigationAndStaticTopbar &&
        `
        transition: transform .3s;
        transform: translateY(0);

        // could be simplified, but curretnly needed to reset even for breakpoint as above
        @media (min-width: ${Breakpoints.b560}px) {
            transform: translateY(0);
        }
        `};
`;
StyledNavigationTopBarV2.displayName = 'StyledNavigationTopBarV2';

const StyledNavigationTopBarPrimaryContentV2 = styled.div<{isStatic?: boolean}>`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(24, ${100 / 24}%);
    pointer-events: auto;

    grid-template-rows: ${props => props.theme.size.static200} 1fr ${props =>
            props.theme.size.static200};

    ${props =>
        props.isStatic &&
        `
        @media (min-width: ${Breakpoints.b560}px) {
            grid-template-rows: ${props.theme.size.static250} ${props.theme.size.static400} ${props.theme.size.static250};
        }
        `}
`;
StyledNavigationTopBarPrimaryContentV2.displayName =
    'StyledNavigationTopBarPrimaryContentV2';

// the nav content is not centered, it has more top- than bottom-padding, except if the static version of the topbar is active.
const StyledRightItemsWrapperV2 = styled.div<StyledRightItemsWrapperProps>`
    grid-row: ${props => (props.isStatic ? '2/3' : '1/4')};
    grid-column: ${props =>
        props.isInPageNavigationVisible ? '5/24' : '16/24'};
    display: flex;
    gap: ${props => props.theme.size.static300};
    justify-content: flex-end;
    align-items: center;

    @media (min-width: ${Breakpoints.b560}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '4/24' : '16/24'};
        padding-left: ${props =>
            props.isInPageNavigationVisible ? '0' : props.theme.size.static100};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '4/24' : '18/24'};
        padding-left: ${props =>
            props.isInPageNavigationVisible ? '0' : props.theme.size.static250};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '6/24' : '18/24'};
    }
`;

const StyledNavigationTopBarSecondaryContent = styled.div`
    // NOTE: pointer-events re-set needs to be set on child components separatly
    height: 46px; // TODO: local VAR or computed? eg. like setTopBarHeight()
    border-bottom: solid 1px ${props => props.theme.colors.border.divider};

    @media (min-width: ${Breakpoints.b960}px) {
        height: 52px; // TODO: local VAR or computed? eg. like setTopBarHeight()
    }
`;

export function NavigationTopBarV2(
    props: NavigationTopBarProps
): React.ReactElement {
    const {
        isExpanded,
        isStatic,
        visibleRightItems,
        menuButton,
        hasTransparentBackground,
        logo,
        claim,
        shouldRenderInpageNav,
        inPageNav,
        hasCustomContent,
        isInPageNavigationVisible,
        showInpageNavigationAndStaticTopbar
    } = props;

    const mHasTransparentBackground = Boolean(hasTransparentBackground);
    const topBarTheme = mHasTransparentBackground ? 'inverted' : 'main';

    // NOTE: this check is used to omit "transition" for EDGE as it breaks animations due combination with calc()
    const isEdge = isEDGE();

    return (
        <ThemeProvider theme={topBarTheme}>
            <StyledNavigationTopBarV2
                hasCustomHeight={hasCustomContent}
                isExpanded={!isStatic && isExpanded}
                hasTransparentBackground={mHasTransparentBackground}
                isEDGE={isEdge}
                isStatic={isStatic}
                shouldRenderInpageNav={shouldRenderInpageNav}
                isInPageNavigationVisible={isInPageNavigationVisible}
                showInpageNavigationAndStaticTopbar={
                    showInpageNavigationAndStaticTopbar
                }
                role="banner"
            >
                <StyledNavigationTopBarPrimaryContentV2 isStatic={isStatic}>
                    <StyledLeftItemsWrapper isHidden={hasCustomContent}>
                        {menuButton}
                    </StyledLeftItemsWrapper>
                    {isStatic && (
                        <StyledStaticLogoLayer hasClaim={Boolean(claim)}>
                            <StyledStaticLogoWrapper data-testid="StyledStaticLogoWrapper">
                                {logo}
                                {claim}
                            </StyledStaticLogoWrapper>
                        </StyledStaticLogoLayer>
                    )}
                    <StyledRightItemsWrapperV2
                        isInPageNavigationVisible={isInPageNavigationVisible}
                        isStatic={isStatic}
                    >
                        {visibleRightItems}
                    </StyledRightItemsWrapperV2>
                </StyledNavigationTopBarPrimaryContentV2>
                <StyledLogoLayer isExpanded={isExpanded} isEDGE={isEdge}>
                    <MovingFrame logo={logo} claim={claim} />
                </StyledLogoLayer>
                {shouldRenderInpageNav && isInPageNavigationVisible && (
                    <Fade>
                        <StyledNavigationTopBarSecondaryContent>
                            {inPageNav}
                        </StyledNavigationTopBarSecondaryContent>
                    </Fade>
                )}
                <FeatureAppNotifications />
            </StyledNavigationTopBarV2>
        </ThemeProvider>
    );
}
