import * as React from 'react';

import {TextAlignment} from '@volkswagen-onehub/components-core';

import {HeadingElementModel, HeadingOrder} from '../../../../generated/core';
import {
    Heading as HeadingComp,
    getOrderIncreasedByOne
} from '../../../components/Heading';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {useSectionGroupContext} from '../../../context';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

export interface HeadingElementExtraProps
    extends Omit<Partial<HeadingElementModel>, 'order'> {
    readonly order: HeadingOrder;
    readonly textAlign?: TextAlignment;
    readonly hideItemDisclaimers?: boolean;
    readonly bold?: boolean;
    readonly newDisclaimer?: boolean;
}

export type HeadingElementProps = HeadingElementModel &
    HeadingElementExtraProps;

const RESOURCE_TYPE = 'vwa-ngw18/components/editorial/elements/headingElement';
const EMPTY_LABEL = 'Type your headline here...';

const InternalHeadingElement = (props: HeadingElementProps): JSX.Element => {
    const {hideItemDisclaimers, order} = props;
    const {isWithinGroup, hasSectionGroupHeading} = useSectionGroupContext();

    const getHeadlineOrderFromContext = Boolean(
        isWithinGroup && hasSectionGroupHeading
    )
        ? getOrderIncreasedByOne(order)
        : order;
    const heading = (
        <HeadingComp
            {...props}
            order={getHeadlineOrderFromContext}
            emptyLabel={EMPTY_LABEL}
        />
    );

    if (hideItemDisclaimers) {
        return heading;
    }

    if (props.newDisclaimer) {
        return (
            <GeneralDisclaimerProvider
                displayTypes={['T4_ITEM_BASED']}
                allowNested
            >
                {heading}
                <BellowItemDisclaimerContainer
                    displayTypes={['T4_ITEM_BASED']}
                />
            </GeneralDisclaimerProvider>
        );
    }

    return (
        <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']} allowNested>
            {heading}
            <BellowItemDisclaimerContainer displayTypes={['T4_ITEM_BASED']} />
        </GeneralDisclaimerProvider>
    );
};

export const HeadingElement = MapTo<HeadingElementExtraProps>(
    RESOURCE_TYPE,
    InternalHeadingElement
);
