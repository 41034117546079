import {
    Text,
    Breakpoints,
    Container,
    ContainerGutter,
    ContainerWrap,
    AspectRatioContainer,
    AspectRatio
} from '@volkswagen-onehub/components-core';

import * as React from 'react';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {RichtextFullElement} from '../elements/RichtextElement';

import {
    ExpandCollapseItemModel,
    LinkElementModel,
    MediaFocalPoint
} from '../../../../generated/core';

import {C} from '../../../registries/compatibilty';
import styled from 'styled-components';
import {MediaElement} from '../elements/MediaElement';
import {FocalPoint} from '@volkswagen-onehub/image-service';
import {LinkElement} from '../elements/LinkElementComponent';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {focalPointMap} from '../../../utils/focalPointMap';
import {ImageWithFocalPoint} from '../../../d6/components/image-with-focal-point';
import {ExpandableArea} from '../../../d6/components/expandable-area';
import {HeadingElement} from '../elements/HeadingElement';

const FOCAL_POINT_CENTER = 'CENTER_CENTER';

const StyledMediaWrapper = styled.div<{hasSquaredRatio?: boolean}>`
    width: ${props => props.hasSquaredRatio && props.theme.size.grid008};
    padding-bottom: ${props => props.theme.size.dynamic0100};

@media (min-width: ${Breakpoints.b560}px) {
	width ${props => props.hasSquaredRatio && props.theme.size.grid004};
    }
@media (min-width: ${Breakpoints.b1280}px) {
	width ${props => props.hasSquaredRatio && props.theme.size.grid002};
    }
`;

const StyledHeadlineWrapper = styled.div`
    padding-bottom: ${props => props.theme.size.static150};
`;

interface ExpandCollapseItemExtraProps {
    readonly hasSquaredRatio?: boolean;
    readonly focalPoint?: FocalPoint;
    readonly disableImages: boolean;
}

type ExpandCollapseItemProps = ExpandCollapseItemModel &
    ExpandCollapseItemExtraProps;

function validLink(link: LinkElementModel): boolean {
    if (link.downloadLink) {
        return Boolean(link.documentPath);
    }
    if (link.layerLink) {
        return Boolean(link.layerPath);
    }
    return Boolean(link.linkUrl);
}

function InternalExpandCollapseItem(
    props: ExpandCollapseItemProps
): JSX.Element {
    const {
        hasSquaredRatio,
        anchorId,
        cqItems,
        validationMessages,
        validationError,
        disableImages,
        buttonExpandedLabel,
        buttonCollapsedLabel
    } = props;
    const visibleArea = 100;
    const imageElement = cqItems.media.cqItems.image;
    const focalPoint: MediaFocalPoint = imageElement
        ? imageElement.focalPoint
        : FOCAL_POINT_CENTER;

    const linkModel = cqItems.link as any;
    const inEditor = C.isInEditor();
    const showHeading = C.isInEditor() || !cqItems.heading.empty;
    const showCopy = C.isInEditor() || !cqItems.copy.empty;
    const showLink = C.isInEditor() || validLink(linkModel as LinkElementModel);

    const media = (
        <MediaElement
            path="media"
            containerProps={{matchParent: true}}
            useParentAspectRatio={true}
        />
    );

    const heading = (
        <Text bold>
            <HeadingElement path="heading" style="H3" order="H3" />
        </Text>
    );

    const copy = <RichtextFullElement path="copy" />;

    const link = <LinkElement path="link" />;

    const alertBox = !disableImages && validationError && C.isInEditor() && (
        <AlertBox severity={AlertBoxSeverity.ERROR}>
            <span>
                {validationMessages.map((msg: string, idx: number) => (
                    <span key={idx}>{msg}</span>
                ))}
            </span>
        </AlertBox>
    );

    const content = (
        <Container
            gutter={ContainerGutter.static400}
            wrap={ContainerWrap.always}
        >
            {showCopy && copy}
            {showLink && link}
        </Container>
    );

    return (
        <>
            {alertBox}
            <AuthoringWrapper
                anchorId={anchorId}
                title="I132 Expand Collapse Item"
            >
                {!disableImages && media && (
                    <StyledMediaWrapper hasSquaredRatio={hasSquaredRatio}>
                        <AspectRatioContainer
                            ratio={
                                hasSquaredRatio
                                    ? AspectRatio['1:1']
                                    : AspectRatio['10:6']
                            }
                        >
                            <ImageWithFocalPoint
                                focalPoint={focalPointMap[focalPoint]}
                            >
                                {media}
                            </ImageWithFocalPoint>
                        </AspectRatioContainer>
                    </StyledMediaWrapper>
                )}
                {showHeading && (
                    <StyledHeadlineWrapper>{heading}</StyledHeadlineWrapper>
                )}
                {!inEditor ? (
                    <ExpandableArea
                        visibleArea={visibleArea}
                        ariaLabelExpand={buttonCollapsedLabel}
                        ariaLabelCollapse={buttonExpandedLabel}
                    >
                        {content}
                    </ExpandableArea>
                ) : (
                    content
                )}
            </AuthoringWrapper>
        </>
    );
}

export const ExpandCollapseItem = MapTo<ExpandCollapseItemExtraProps>(
    'vwa-ngw18/components/editorial/items/expandCollapseItem',
    InternalExpandCollapseItem
);
