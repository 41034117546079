import {reaction} from 'mobx';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {NbabType} from '../../types/global';
import {AbTestService} from '../abtest/AbTestService';
import {ContentStore} from '../content/ContentStore';
import {Logger} from '../logger/Logger';
import {PageRootModel} from '../../../generated/core';

export const NBAB_STORE_API_NAME = 'NbabApiStore';

@singleton('NbabApiStore', {env: 'client'})
export class NbabApiStore {
    @inject()
    private abTestService!: AbTestService;

    @inject()
    private logger!: Logger;

    @inject()
    private contentStore!: ContentStore;

    private listeners: (() => void)[] = [];

    private currentPath?: string;

    @postConstruct()
    public init(): void {
        // no need to notify listeners initially.
        // They should only start listening after all singletons are completely started.
        reaction(
            () => this.contentStore.getCurrentPageRootModel(),
            value => {
                this.onPathChanged(value);
            }
        );
    }

    private onPathChanged(value?: PageRootModel): void {
        if (value) {
            if (this.currentPath !== value?.aemResourcePath) {
                this.currentPath = value.aemResourcePath;
                this.logger.nbab.debug(
                    'nbab api: new path:',
                    this.currentPath,
                    ', type:',
                    this.nbabType
                );
                this.listeners.forEach(l => l());
            }
        }
    }

    public get nbabType(): NbabType {
        const pageRootModel = this.contentStore.getCurrentPageRootModel();
        if (!pageRootModel) {
            return undefined;
        }

        if (
            pageRootModel?.nextBestActionDataModel?.pageModel?.actions
                ?.length === 0 &&
            Object.keys(pageRootModel?.nextBestActionDataModel?.sectionModels)
                .length === 0
        ) {
            return 'none';
        }
        return this.abTestService.getAbTestVariant('nbabHorizontalView')
            ? 'horizontal'
            : 'vertical';
    }

    public listen(listener: () => void) {
        this.listeners.push(listener);
    }
}
