import {NoArgsConstructor, metaRegistry} from './Registry';
import {StringUtils} from './StringUtils';

export interface FieldMetadata<P> {
    type: string;
    field: string;
    props?: P;
}

export type Environment = 'server' | 'client' | 'test' | 'priority';

export type PropertyAnnotationFunc = (
    target: object,
    targetKey: string
) => void;

export function propertyAnnotationFactory(
    name: string,
    props?: object
): PropertyAnnotationFunc {
    return (target: object, targetKey: string): void => {
        metaRegistry.markAsInjected(target);
        const metaData: FieldMetadata<object>[] =
            (Reflect.getOwnMetadata(name, target) as FieldMetadata<object>[]) ||
            [];
        const typeName: string = StringUtils.firstUpperCase(targetKey);
        metaData.push({
            field: targetKey,
            props: props || {},
            type: typeName
        });
        Reflect.defineMetadata(name, metaData, target);
    };
}

export function inject(): (target: object, targetKey: string) => void {
    return propertyAnnotationFactory('injects');
}

export function postConstruct(): (target: object, targetKey: string) => void {
    return propertyAnnotationFactory('postConstructs');
}

export interface SingletonOptions {
    global?: boolean;
    env?: Environment;
    devMode?: boolean;
}

export function singleton(
    name: string,
    options?: SingletonOptions
): (target: NoArgsConstructor<object>) => void {
    return (target: NoArgsConstructor<object>): void => {
        metaRegistry.regSingleton(target, name, options);
    };
}
