import * as React from 'react';

import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {XfCarFeatureModel} from '../../../../generated/core';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import styled from 'styled-components';
import {ContentLayer} from '../../../components/content-layer/ContentLayer';
import {HighlightGallery} from '../../../components/highlight-feature/HighlightGallery';
import {
    LayerContext,
    LayerContextData
} from '../../../context/layer/LayerContext';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {RichtextSimpleElement} from '../../editorial/elements/RichtextElement';
import {Parsys, ParsysType} from '../Parsys';

const MEDIA_PARSYS = 'mediaParsys';

const StyledFocusTrap = styled.div`
    outline: none;
`;

const CONTEXT_DATA: LayerContextData = {
    layerType: 'CONTENT'
};

interface LayerElements {
    title: JSX.Element;
    heading: JSX.Element;
    copyParsys: JSX.Element;
    mediaParsys: JSX.Element;
}

function AuthoringView(props: LayerElements): JSX.Element {
    const {title, heading, copyParsys, mediaParsys} = props;

    return (
        <AuthoringWrapper title="Car feature XF">
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={{
                        left: ContainerPadding.grid004,
                        right: ContainerPadding.grid004
                    }}
                    stretchContent
                >
                    {title}
                    {heading}
                    {mediaParsys}
                    {copyParsys}
                </Container>
            </Layout>
        </AuthoringWrapper>
    );
}

function PublishView(props: LayerElements & XfCarFeatureModel): JSX.Element {
    const {
        heading,
        copyParsys,
        cqPath,
        contentName,
        contentId,
        translations,
        layerHeaderUpdaterModel
    } = props;

    return (
        <ContentLayer headline={heading} metaTagData={layerHeaderUpdaterModel}>
            <Container
                gutter={ContainerGutter.dynamic0100}
                wrap={ContainerWrap.always}
                stretchContent
            >
                <HighlightGallery
                    carouselId={`${contentId}-carousel`}
                    mediaParsysPath={`${cqPath}/${MEDIA_PARSYS}`}
                    contentName={`${contentName} gallery`}
                    previewAriaLabel={
                        translations['carFeature.previewAriaLabel']
                    }
                    slideXOfYLabel={translations['carousel.slideXOfYLabel']}
                    galleryLabel={translations['carousel.galleryLabel']}
                    goToNextSlideLabel={
                        translations['carousel.goToNextSlideLabel']
                    }
                    goToPreviousSlideLabel={
                        translations['carousel.goToPreviousSlideLabel']
                    }
                    carouselItemLabel={
                        translations['moodGallery.carouselItemLabel']
                    }
                />
                {copyParsys}
            </Container>
        </ContentLayer>
    );
}

function InternalXfCarFeature(props: XfCarFeatureModel): JSX.Element {
    // We need to add "focus trap" otherwise layer will autofocus first inner link on open and will scroll down to it
    const title = (
        <StyledFocusTrap tabIndex={0}>
            <RichtextSimpleElement path="title" />
        </StyledFocusTrap>
    );
    const heading = <HeadingElement path="heading" order="H2" />;
    const copyParsys = (
        <Parsys path="copyParsys" parsysType={ParsysType.ITEM_PARSYS} />
    );

    const mediaParsys = (
        <Parsys path={MEDIA_PARSYS} parsysType={ParsysType.ITEM_PARSYS} />
    );

    const CarFeature = C.isInEditor() ? AuthoringView : PublishView;

    return (
        <LayerContext.Provider value={CONTEXT_DATA}>
            <CarFeature
                {...props}
                title={title}
                heading={heading}
                copyParsys={copyParsys}
                mediaParsys={mediaParsys}
            />
        </LayerContext.Provider>
    );
}

export const XfCarFeature = MapTo(
    'vwa-ngw18/components/structure/xfCarFeature',
    InternalXfCarFeature
);
