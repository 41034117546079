import * as React from 'react';
import {RenderContext} from '../context/RenderContext';
import {Registry} from '../infrastructure/di/Registry';
import {RegistryContext} from '../infrastructure/di/RegistryContext';

export interface CmsRootProps {
    registry: Registry;
}
export class CmsRoot extends React.Component<CmsRootProps> {
    public render(): React.ReactNode {
        return (
            <RenderContext.Provider value={{noElement: false}}>
                <RegistryContext.Provider value={this.props.registry}>
                    {this.props.children}
                </RegistryContext.Provider>
            </RenderContext.Provider>
        );
    }
}
