import {DisclaimerProps} from './helpers';
import {useDisclaimers} from '../../context/disclaimer/useDisclaimers';
import {compareDisclaimerReferences} from '../../context/disclaimer/helpers';

/**
 * returns disclaimers in the new way or the old
 * @param props props
 * @returns
 */
export function useDisclaimersFromPropsOrNewContext(props: DisclaimerProps) {
    const newDisclaimers = useDisclaimers(...props.displayTypes);
    return newDisclaimers.sort(compareDisclaimerReferences);
}
