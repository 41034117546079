import * as React from 'react';

import {ExternalIcon} from '@volkswagen-onehub/components-core';
import {useGlobalConfig} from '../context';
import {getIconUrl} from './getIconUrl';

export type MappedIconVariant<Sizes> = Sizes | undefined;

interface IconMap {
    readonly [key: string]: string;
}

const smallIconsMap: IconMap = {
    CALENDAR: 'Calendar',
    CAR_FRONT: 'NewCarPrivate',
    CONTACT_DEALER: 'ContactDealer',
    DOWNLOAD: 'Download',
    MAIL: 'Mail',
    SELECTED: 'Selected',
    PROFILE: 'Profile',
    CLOCK: 'Clock',
    CONTACT: 'Contact',
    INFO: 'Info',
    PIN: 'Pin',
    STAROUTLINE: 'StarOutline'
};

const defaultIconsMap: IconMap = {
    BROCHURE: 'Brochure',
    CAR_FRONT: 'NewCarPrivate',
    CALENDAR: 'Calendar',
    CONFIGURE_CAR: 'ConfiguratorPrivate',
    CONTACT_DEALER: 'ContactDealer',
    DOWNLOAD: 'Download',
    FIND_CAR: 'FindCar',
    FIND_DEALER: 'StockLocatorPrivate',
    HELP: 'CustomerCenter',
    MAIL: 'Mail',
    OFFERS: 'Offers',
    TESTDRIVE: 'TestDrive',
    SELECTED: 'Selected',
    PROFILE: 'Profile',
    CLOCK: 'Clock',
    CONTACT: 'Contact',
    CUSTOMERCENTER: 'CustomerCenter',
    DATATIMEEXTENSION: 'DataTimeExtension',
    TIMER: 'Timer',
    INFO: 'Info',
    LIST: 'List',
    PIN: 'Pin',
    STAROUTLINE: 'StarOutline',
    CERTIFIEDRETAILER: 'CertifiedRetailer',
    DATA_SEARCH: 'DataSearch',
    FAQ: 'Faq',
    ROUTE: 'Route',
    STANDARD_EQUIPMENT: 'StandardEquipment',
    JOB_PORTAL: 'JobPortal'
};

export function getMappedIcon(
    icon?: string,
    variant: MappedIconVariant<'small' | 'default'> = 'default',
    iconProps?: {ariaLabel?: string; ariaHidden?: boolean; title?: string}
): JSX.Element | undefined {
    if (icon && variant === 'small' && icon in smallIconsMap) {
        const name = smallIconsMap[icon];

        return (
            <LinkedIcon
                iconProps={{
                    alt: iconProps?.ariaLabel || '',
                    title: iconProps?.title
                }}
                icon={name}
                variant={variant}
            />
        );
    }

    if (icon && variant === 'default' && icon in defaultIconsMap) {
        const name = defaultIconsMap[icon];

        return (
            <div>
                <LinkedIcon
                    iconProps={{
                        alt: iconProps?.ariaLabel || '',
                        title: iconProps?.title
                    }}
                    icon={name}
                    variant={variant}
                />
            </div>
        );
    }

    return undefined;
}

export function getIcon(
    icon: string,
    variant: 'default' | 'small' = 'default',
    iconProps: {alt: string; title?: string} = {alt: ''}
) {
    return (
        <LinkedIcon
            icon={icon}
            variant={variant}
            iconProps={iconProps}
        ></LinkedIcon>
    );
}

function LinkedIcon(props: {
    icon: string;
    variant: 'default' | 'small';
    iconProps: {alt: string; title?: string};
}): JSX.Element {
    const {icon, variant, iconProps} = props;
    const globalConfig = useGlobalConfig();
    const url = getIconUrl(icon, variant, globalConfig);

    return <ExternalIcon {...iconProps} variant={variant} url={url} />;
}
