import * as React from 'react';

import {
    MediaAspectRatio,
    Scene7VideoRendition
} from '../../../../../../generated/api';
import {VideoPlayerProxy} from '../../../../../services/video/VideoPlayerProxy';
import {ElementSize, getElementSize, getVideoRendition} from '../helpers';
import {useVideoRenditionTransition} from './useVideoRenditionTransition';

export function useVideoSource(
    videoRenditions: Scene7VideoRendition[],
    aspectRatio: MediaAspectRatio | undefined,
    videoPlayerProxy: React.RefObject<VideoPlayerProxy>,
    videoContainerRef: React.RefObject<HTMLDivElement>
): string | undefined {
    const isInFullScreen =
        videoPlayerProxy.current && videoPlayerProxy.current.isInFullScreen;
    const [dimensions, setDimensions] = React.useState<ElementSize>({
        width: 0,
        height: 0
    });

    const observerRef = React.useRef<ResizeObserver>();
    React.useEffect(() => {
        setDimensions(
            isInFullScreen
                ? {width: window.innerWidth, height: window.innerHeight}
                : getElementSize(videoContainerRef)
        );
        if (videoContainerRef.current) {
            observerRef.current = new ResizeObserver(() => {
                setDimensions(
                    isInFullScreen
                        ? {width: window.innerWidth, height: window.innerHeight}
                        : getElementSize(videoContainerRef)
                );
            });
            observerRef.current.observe(videoContainerRef.current);
        }
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = undefined;
            }
        };
    }, [isInFullScreen, videoContainerRef]);

    const rendition = React.useMemo(() => {
        if (dimensions.width === 0 && dimensions.height === 0) {
            return undefined;
        }

        const videoRendition = getVideoRendition(
            videoRenditions,
            aspectRatio || 'r_16_9',
            dimensions
        );

        return videoRendition ? videoRendition.scene7Src : undefined;
    }, [videoRenditions, aspectRatio, dimensions]);

    useVideoRenditionTransition(rendition, videoPlayerProxy);

    return rendition;
}
