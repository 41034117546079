import * as React from 'react';
import styled from 'styled-components';

import {
    AspectRatio,
    AspectRatioContainer,
    DecorativeImage,
    DecorativeImageProps,
    DecorativeLazyImage,
    Image,
    ImageProps,
    LazyImage
} from '@volkswagen-onehub/components-core';
import {ImageElementModel, MediaAspectRatio} from '../../../../generated/core';
import {
    AuthoringMediaInfoBox,
    MediaInfo
} from '../../../components/AuthoringMediaInfoBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {ImageDisclaimerWrapper} from '../../../components/ImageDisclaimerWrapper';
import {
    DecorativePicture,
    DynamicDecorativePicture,
    DynamicPicture,
    Picture,
    PictureProps
} from '../../../components/Picture';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {
    useGlobalConfig,
    useImageLazyLoad,
    useImageService,
    useMediaContext,
    useSpaAsyncConfig
} from '../../../context';
import {ImageWithFocalPoint} from '../../../d6/components/image-with-focal-point';
import {useEnableSmartImaging} from '../../../hooks/useEnableSmartImaging';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {aspectRatioMap} from '../../../utils/aspectRatioMap';
import {focalPointMap} from '../../../utils/focalPointMap';
import {getImageResourceSet} from '../../../utils/getImageResourceSet';
import {StyledPropsExtractor} from '../../../utils/styledUtils';
import {ImagePlaceHolderElement} from './ImagePlaceHolderElement';
import {MediaDisclaimerHolder} from './MediaElement';
import {getResponsiveFocalPoints} from './helpers';
import {ImageIcon} from './media/icons';

// Image alt-text gets covered by top bar in case of stages - vertically centering it deals with that issue.
// This is not possible on safari, which is unable to reasonably scale broken images, max-height at least makes sure it does not get covered by top bar.
const NotLoadedImage = styled(StyledPropsExtractor)`
    position: relative;
    top: 50%;
    height: auto !important;
    max-height: 50vh;
    transform: translateY(-50%);
`;

export const DynamicImage = (props: ImageProps) => {
    const isLazyLoad = useImageLazyLoad();

    if (isLazyLoad) {
        return <LazyImage {...props} />;
    }

    return <Image {...props} />;
};

export const DynamicDecorativeImage = (props: DecorativeImageProps) => {
    const isLazyLoad = useImageLazyLoad();

    if (isLazyLoad) {
        return <DecorativeLazyImage {...props} />;
    }

    return <DecorativeImage {...props} />;
};

// TODO: move `isDecorative` to ImageElementModel?
export type ImageRenderElementProps = ImageElementModel &
    MediaDisclaimerHolder &
    Readonly<{
        onLoad?: () => void;
        isDecorative?: boolean;
        reduceBrightness?: boolean;
        sizes?: string;
        responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    }>;

type ImageElementExtraProps = Readonly<{
    src?: string;
    useParentAspectRatio?: boolean;
    aspectRatio?: MediaAspectRatio;
    altText?: string;
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    onLoad?: () => void;
}>;

function ImageWithoutAspectRatioElement(
    props: Omit<ImageRenderElementProps, 'aspectRatio'>
): JSX.Element {
    const {
        altText = '',
        isDecorative,
        disclaimers = {},
        reduceBrightness,
        sizes,
        responsiveMediaConfig,
        onLoad
    } = props;
    const imageService = useImageService();
    const [notLoaded, setNotLoaded] = React.useState(!props.src);
    const adobeTargetExport = useGlobalConfig().adobeTargetExport;
    const enableSmartImaging = useEnableSmartImaging();
    const damSrc = props.src;
    const image = {
        ...props,
        focalPoint: props.focalPoint || 'CENTER_CENTER'
    };

    function handleError(): void {
        setNotLoaded(true);
    }

    const defaultSources = getImageResourceSet(
        imageService,
        image,
        enableSmartImaging
    );

    const {
        staticReferences = [],
        interactiveReferences,
        color = 'dark'
    } = disclaimers;

    const imageServiceEnabled = useSpaAsyncConfig().featureHubModel
        .featureServices.imageService.serviceEnabled;

    const renderPicture = () => {
        const pictureConfig = responsiveMediaConfig?.map(configItem => {
            const chosenImage =
                configItem.portray && props.portrayImage && !C.isInEditor()
                    ? props.portrayImage
                    : image;

            const imageResourceSet = getImageResourceSet(
                imageService,
                {
                    ...chosenImage,
                    aspectRatio: configItem.aspectRatio as MediaAspectRatio
                },
                enableSmartImaging
            );

            return {
                ...configItem,
                ...imageResourceSet
            };
        });

        return (
            <NotLoadedImage>
                {({className}) => {
                    const pictureBaseProps = {
                        src: defaultSources.src,
                        reduceBrightness,
                        className: notLoaded ? className : '',
                        onError: handleError,
                        config: pictureConfig,
                        alt: altText,
                        onLoad: onLoad
                    };

                    // For adobe target exports the damSrc is forced to be a scene7 prod src
                    const picture = getPicture(pictureBaseProps, {
                        adobeTargetExport,
                        damSrc,
                        defaultSrcSet: defaultSources.srcSet,
                        isDecorative,
                        sizes,
                        imageServiceEnabled
                    });

                    return (
                        <ImageWithFocalPoint
                            focalPoint={focalPointMap[image.focalPoint]}
                            responsiveFocalPoints={getResponsiveFocalPoints(
                                props.responsiveMediaConfig!,
                                image.focalPoint,
                                image?.portrayImage?.focalPoint
                            )}
                        >
                            {picture}
                        </ImageWithFocalPoint>
                    );
                }}
            </NotLoadedImage>
        );
    };

    const renderImage = () => (
        <NotLoadedImage>
            {({className}) => {
                const imageComp = getImage(
                    {
                        src: defaultSources.src,
                        reduceBrightness,
                        className: notLoaded ? className : '',
                        onError: handleError
                    },
                    {
                        adobeTargetExport,
                        srcSet: defaultSources.srcSet,
                        srcSetWebP: defaultSources.srcSetWebP,
                        isDecorative,
                        altText,
                        damSrc,
                        sizes
                    }
                );

                // For adobe target exports the damSrc is forced to be a scene7 prod src
                return (
                    <ImageWithFocalPoint
                        focalPoint={focalPointMap[image.focalPoint]}
                    >
                        {imageComp}
                    </ImageWithFocalPoint>
                );
            }}
        </NotLoadedImage>
    );

    let imageComponent: JSX.Element;
    if (C.isInEditor() && !damSrc) {
        imageComponent = (
            <ImagePlaceHolderElement>
                <ImageIcon />
            </ImagePlaceHolderElement>
        );
    } else if (responsiveMediaConfig) {
        imageComponent = renderPicture();
    } else {
        imageComponent = renderImage();
    }

    return (
        <ImageDisclaimerWrapper
            staticReferences={staticReferences}
            interactiveReferences={interactiveReferences}
            color={color}
        >
            {imageComponent}
        </ImageDisclaimerWrapper>
    );
}

class ImageWithAspectRatioElement extends React.PureComponent<
    ImageRenderElementProps
> {
    public render(): JSX.Element {
        const {aspectRatio} = this.props;

        return (
            <AspectRatioContainer
                ratio={aspectRatioMap[aspectRatio] || AspectRatio['16:9']}
            >
                <ImageWithoutAspectRatioElement {...this.props} />
            </AspectRatioContainer>
        );
    }
}

function InternalImageElement(
    props: ImageRenderElementProps & ImageElementExtraProps
): JSX.Element {
    const {useParentAspectRatio, mediaInfo, src} = props;
    const {ratio, reduceBrightness, sizes} = useMediaContext();

    return (
        <AuthoringWrapper
            title="Image"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            {C.isInEditor() && src && (
                <AuthoringMediaInfoBox>
                    {mediaInfo && <MediaInfo {...mediaInfo} />}
                </AuthoringMediaInfoBox>
            )}
            {useParentAspectRatio ? (
                <ImageWithoutAspectRatioElement
                    {...props}
                    reduceBrightness={reduceBrightness}
                    sizes={sizes}
                />
            ) : (
                <ImageWithAspectRatioElement
                    {...props}
                    reduceBrightness={reduceBrightness}
                    aspectRatio={ratio || props.aspectRatio}
                    sizes={sizes}
                />
            )}
        </AuthoringWrapper>
    );
}

export const ImageElement = MapTo<ImageElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/imageElement',
    InternalImageElement
);

const getPicture = (
    pictureBaseProps: PictureProps,
    options: {
        adobeTargetExport: boolean;
        isDecorative?: boolean;
        sizes?: string;
        defaultSrcSet: string;
        damSrc: string;
        imageServiceEnabled: boolean;
    }
) => {
    const {
        adobeTargetExport,
        isDecorative,
        sizes,
        defaultSrcSet,
        damSrc,
        imageServiceEnabled
    } = options;
    if (C.isInEditor() || adobeTargetExport) {
        // in the author we use fallback to dam src if images are not exported to dynamic media
        return isDecorative ? (
            <DecorativePicture {...pictureBaseProps} src={damSrc} />
        ) : (
            <Picture {...pictureBaseProps} src={damSrc} />
        );
    }

    // if imageService is disabled then display dam source (e.g. for Brandnet)
    const srcPlaceholder = !imageServiceEnabled ? damSrc : undefined;

    return isDecorative ? (
        <DynamicDecorativePicture
            {...pictureBaseProps}
            srcSet={defaultSrcSet}
            sizes={sizes}
            srcPlaceholder={srcPlaceholder}
        />
    ) : (
        <DynamicPicture
            {...pictureBaseProps}
            srcSet={defaultSrcSet}
            sizes={sizes}
            srcPlaceholder={srcPlaceholder}
        />
    );
};

const getImage = (
    imageProps: {
        src: string;
        reduceBrightness?: boolean;
        className: string;
        onError: () => void;
    },
    options: {
        adobeTargetExport: boolean;
        damSrc: string;
        altText: string;
        isDecorative?: boolean;
        sizes?: string;
        srcSet: string;
        srcSetWebP?: string;
    }
) => {
    const {
        adobeTargetExport,
        isDecorative,
        damSrc,
        altText,
        sizes,
        srcSet,
        srcSetWebP
    } = options;
    if (C.isInEditor() || adobeTargetExport) {
        // in the author we use fallback to dam src if images are not exported to dynamic media
        return isDecorative ? (
            <DecorativeImage {...imageProps} src={damSrc} />
        ) : (
            <Image {...imageProps} src={damSrc} alt={altText} />
        );
    }

    return isDecorative ? (
        <DynamicDecorativeImage
            {...imageProps}
            srcSet={srcSet}
            data-srcset-webp={srcSetWebP}
            sizes={sizes}
        />
    ) : (
        <DynamicImage
            {...imageProps}
            alt={altText}
            srcSet={srcSet}
            data-srcset-webp={srcSetWebP}
            sizes={sizes}
        />
    );
};
