import * as React from 'react';
import {
    styled,
    Text,
    TokenTextAppearance,
    Spinner,
    TextAlignment
} from '@volkswagen-onehub/components-core';
import {useGlobalConfig} from '../../context';

export enum ContainerSize {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE'
}

type SpinnerSize = React.ComponentProps<typeof Spinner>['variant'];

const ContainerHeightMap: Record<ContainerSize, string> = {
    [ContainerSize.SMALL]: '10vh',
    [ContainerSize.MEDIUM]: '40vh',
    [ContainerSize.LARGE]: '70vh'
};

const SpinnerSizeMap: Record<ContainerSize, SpinnerSize> = {
    [ContainerSize.SMALL]: 'large',
    [ContainerSize.MEDIUM]: 'x-large',
    [ContainerSize.LARGE]: 'x-large'
};

const LoadingWrapper = styled.div<{
    size: ContainerSize;
    hasBorder?: boolean;
}>`
    min-height: ${props => ContainerHeightMap[props.size]};
    margin: ${props => props.theme.size.dynamic0250};
    padding: ${props => props.theme.size.static450};

    display: grid;
    grid-template-rows: max-content max-content;
    justify-items: center;
    align-content: center;
    grid-gap: ${props => props.theme.size.dynamic0020};

    outline: 1px solid
        ${props =>
            props.hasBorder
                ? props.theme.colors.border.default
                : 'transparent'};
`;

interface FeatureAppLoadingContainerProps {
    size: ContainerSize;
    visualFeedback?: boolean;
}

/**
 * This component is the Loading UI that is displayed when a Feature App is
 * still loading.
 * It has two functions: Reserving space and showing a Spinner.
 * When `showSpinner` is false, there is no visual output, besides the space
 * reservation.
 */
export default function FeatureAppLoadingContainer({
    size,
    visualFeedback = true
}: FeatureAppLoadingContainerProps) {
    const spaGlobalConfig = useGlobalConfig();

    return (
        <LoadingWrapper size={size} hasBorder={visualFeedback}>
            {visualFeedback && (
                <>
                    <Spinner variant={SpinnerSizeMap[size]} />
                    <Text
                        appearance={TokenTextAppearance.label200}
                        textAlign={TextAlignment.center}
                        staticSize
                    >
                        {spaGlobalConfig.componentsLoadingLabel}
                    </Text>
                </>
            )}
        </LoadingWrapper>
    );
}
