import {designTokens} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from 'styled-components';

const StyledPlaceholder = styled.div`
    background-color: ${designTokens.color['grey-100']};
    color: ${designTokens.color['grey-400']};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export class ImagePlaceHolderElement extends React.Component<{}> {
    public render(): JSX.Element | null {
        const {children} = this.props;

        return <StyledPlaceholder>{children}</StyledPlaceholder>;
    }
}
