import * as React from 'react';

import {
    Text,
    TextTag,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import {useLoginFlyoutService, useLoginStore} from '../context';
import {LayerDisclaimerContext} from '../context/disclaimer/DisclaimerStoreContext';
import {getEndDirection} from '../d6/components/helpers';
import {FlyoutLoggedIn} from '../modules/structure/login/FlyoutLoggedIn';
import {FlyoutLoggedOut} from '../modules/structure/login/FlyoutLoggedOut';
import {FlyoutLoggedOutMultipleCustomerTypes} from '../modules/structure/login/FlyoutLoggedOutMultipleCustomerTypes';
import {ClickTargetElement} from './links/CmsLink';
import {CmsTextLink} from './links/CmsTextLink';

export interface LoginFlyoutProps {
    readonly heading?: React.ReactNode;
    readonly copy?: React.ReactNode;
    readonly button?: React.ReactNode;
    readonly userInfo?: React.ReactNode;
    readonly footer?: React.ReactNode;
}

export interface LoginFlyoutFooterLinkProps {
    readonly href: string;
    readonly label: string;
    readonly icon: React.ReactNode;
    readonly onClick: React.MouseEventHandler<ClickTargetElement>;
    readonly contentId?: string;
}

export interface LoginFlyoutUserInfoTextProps {
    readonly label: string;
    readonly icon: React.ReactNode;
}

const StyledFooterIconWrapper = styled.div`
    display: inline-block;
    vertical-align: top;
    height: ${props => props.theme.size.static350};
    margin-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.static150};
`;
StyledFooterIconWrapper.displayName = 'StyledFooterIconWrapper';

export const LoginFlyout: React.FunctionComponent = observer(
    function LF(): JSX.Element {
        const loginStore = useLoginStore();
        const flyoutService = useLoginFlyoutService();
        const isCommercialUser = Boolean(loginStore.organizationData);

        const loggedOutFlyout = loginStore.multipleCustomerTypes ? (
            <LayerDisclaimerContext
                path={flyoutService.flyoutLoggedOutMultipleCustomerTypesPath}
            >
                <FlyoutLoggedOutMultipleCustomerTypes
                    path={
                        flyoutService.flyoutLoggedOutMultipleCustomerTypesPath
                    }
                    login={customerType => loginStore.handleLogin(customerType)}
                />
            </LayerDisclaimerContext>
        ) : (
            <LayerDisclaimerContext path={flyoutService.flyoutLoggedOutPath}>
                <FlyoutLoggedOut path={flyoutService.flyoutLoggedOutPath} />
            </LayerDisclaimerContext>
        );
        const loggedInFlyout = isCommercialUser ? (
            <LayerDisclaimerContext
                path={flyoutService.flyoutLoggedInCommercialPath}
            >
                <FlyoutLoggedIn
                    path={flyoutService.flyoutLoggedInCommercialPath}
                />
            </LayerDisclaimerContext>
        ) : (
            <LayerDisclaimerContext path={flyoutService.flyoutLoggedInPath}>
                <FlyoutLoggedIn path={flyoutService.flyoutLoggedInPath} />
            </LayerDisclaimerContext>
        );
        return loginStore.isLoggedIn ? loggedInFlyout : loggedOutFlyout;
    }
);
LoginFlyout.displayName = 'LoginFlyout';

export interface LoginFlyoutUserInfoContainerProps {
    readonly name: string;
    readonly email?: string;
}

export function LoginFlyoutFooterLink(
    props: LoginFlyoutFooterLinkProps
): JSX.Element {
    return (
        <Text appearance={TokenTextAppearance.copy100} tag={TextTag.span}>
            <CmsTextLink
                href={props.href}
                linkName={props.label}
                onClick={props.onClick}
                ignoreExternalLinkLightbox
                contentId={props.contentId}
            >
                <StyledFooterIconWrapper>{props.icon}</StyledFooterIconWrapper>
                {props.label}
            </CmsTextLink>
        </Text>
    );
}

export function LoginFlyoutUserInfoText(
    props: LoginFlyoutUserInfoTextProps
): JSX.Element {
    return (
        <Text appearance={TokenTextAppearance.copy100} tag={TextTag.span}>
            <StyledFooterIconWrapper>{props.icon}</StyledFooterIconWrapper>
            {props.label}
        </Text>
    );
}
