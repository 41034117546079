export const XF_CONTENT_PATH = '/content/experience-fragments';

export function isXfPreview(path: string): boolean {
    // paths are mapped in target export on author and on publisher. Thus, xf paths are prepended with `idhub`.
    return path.includes(XF_CONTENT_PATH);
}
export function isTemplateEditor(path: string): boolean {
    return path.startsWith('/conf/vwa-ngw18/settings/wcm');
}

export function isInXfStaticEditMode(cqPath: string): boolean {
    return isXfPreview(cqPath) && cqPath.includes('static/editorial');
}
