import {
    FocusLayerSizeV2,
    LayerHandleV2,
    LayerManagerV2
} from '@volkswagen-onehub/layer-manager';
import * as React from 'react';
import {
    ExternalLinkLightboxContentModel,
    SpaGlobalConfig
} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {
    ExternalLinkConfig,
    ExternalLinkLightbox
} from '../../modules/structure/ExternalLinkLightbox';
import {isRelativeUrl} from '../../utils/link/isRelativeUrl';
import {ModelStore} from '../model/ModelStore';
import {ExternalLinkLightboxService} from './ExternalLinkLightboxService';

@singleton('ExternalLinkLightboxService', {env: 'client'})
export class ClientExternalLinkLightboxService
    implements ExternalLinkLightboxService {
    @inject()
    private readonly externalLinkLightboxContentModel!: ExternalLinkLightboxContentModel;
    @inject() private readonly spaGlobalConfig!: SpaGlobalConfig;
    @inject() private readonly layerManager!: LayerManagerV2;
    @inject() private readonly modelStore!: ModelStore;

    private lightboxLayer: LayerHandleV2<ExternalLinkConfig> | null = null;
    private lightboxPath!: string;

    @postConstruct()
    public init(): void {
        const model: any = this.externalLinkLightboxContentModel;
        this.lightboxPath = this.modelStore.insertGlobalContent(
            'externalLinkLightbox',
            model
        );
    }

    public openLightbox(href: string, target: string): void {
        if (this.lightboxLayer) {
            this.closeLightbox();
        }

        this.lightboxLayer = this.layerManager.openFocusLayer(
            this.renderLightbox,
            {
                href,
                target
            },
            {
                userCloseable: true,
                onClose: this.closeLightbox,
                size: FocusLayerSizeV2.A
            }
        );
    }

    public readonly closeLightbox = (): void => {
        if (this.lightboxLayer) {
            this.lightboxLayer.close();
        }
        this.lightboxLayer = null;
    };

    public readonly shouldOpenLightbox = (url: string): boolean => {
        const config = this.spaGlobalConfig.externalLinkLightboxConfigModel;
        if (!config.enabled) {
            return false;
        }

        if (!url || isRelativeUrl(url)) {
            return false;
        }

        const whitelistedDomain:
            | string
            | undefined = config.internalDomainsWhitelist.find(
            (domain: string) => {
                const regex = new RegExp(domain);

                return regex.test(url);
            }
        );

        return whitelistedDomain === undefined;
    };

    public readonly isOpened = (): boolean => {
        return Boolean(this.lightboxLayer && !this.lightboxLayer.isClosed());
    };

    private readonly renderLightbox = (
        state: ExternalLinkConfig
    ): JSX.Element => (
        <ExternalLinkLightbox
            {...state}
            path={this.lightboxPath}
            onClose={this.closeLightbox}
        />
    );
}
