import {styled} from '@volkswagen-onehub/components-core';
import {Close} from '@volkswagen-onehub/icons-core';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {DisplayType} from '../../../../../generated/core';
import {DisclaimersWithSafewords} from '../../../../components/disclaimers/DisclaimersWithSafewords';
import {useDisclaimersFromPropsOrNewContext} from '../../../../components/disclaimers/useDisclaimersFromPropsOrNewContext';
import {useGlobalConfig} from '../../../../context';
import {resetButtonStyles} from '../../../../d6/components/helpers';

const StyledLayerButton = styled.button`
    ${resetButtonStyles}
    position: sticky;
    inset-block-start: 0;

    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.content.primary};
    pointer-events: all;

    &:focus {
        color: ${props => props.theme.colors.focus.main};
        outline: 2px solid ${props => props.theme.colors.focus.main};
        outline-offset: 2px;
    }
`;
StyledLayerButton.displayName = 'StyledLayerButton';

const StyledDisclaimerWrapper = styled.div`
    & > * {
        padding-inline-end: ${props => props.theme.size.static250};
        padding-block-end: ${props => props.theme.size.static250};
    }
`;

const StyledOverlay = styled.div`
    position: relative;
    width: 100%;
    max-height: ${props => props.theme.size.static650};
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr max-content ${props => props.theme.size.grid001};
    grid-template-rows: min-content 1fr;
    padding-block-start: ${props => props.theme.size.static250};

    ${StyledLayerButton} {
        grid-row: 1/2;
        grid-column: 2/3;
    }

    ${StyledDisclaimerWrapper} {
        grid-row: 1/3;
        grid-column: 1/2;
    }
`;
StyledOverlay.displayName = 'StyledOverlay';

export interface DisclaimerOverlayProps {
    displayTypes: DisplayType[];
    className?: string;
    onClose?: VoidFunction;
}

export const DisclaimerOverlay = observer(function DO(
    props: DisclaimerOverlayProps
): JSX.Element | null {
    const {className, onClose} = props;
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const {disclaimerLabels} = useGlobalConfig();
    const [isShown, setIsShown] = React.useState(true);

    function closeDisclaimerOverlayEventListener(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void {
        e.preventDefault();
        e.stopPropagation();

        if (isShown) {
            setIsShown(false);
            // eslint-disable-next-line no-unused-expressions
            onClose?.();
        }
    }

    return isShown && disclaimers.length > 0 ? (
        <StyledOverlay className={className}>
            <StyledLayerButton
                onClick={closeDisclaimerOverlayEventListener}
                aria-label={disclaimerLabels.closeDisclaimerLabel}
            >
                <Close ariaHidden />
            </StyledLayerButton>
            <StyledDisclaimerWrapper>
                {disclaimers.map(disclaimer => (
                    <DisclaimersWithSafewords
                        disclaimers={[disclaimer]}
                        key={disclaimer.reference}
                    />
                ))}
            </StyledDisclaimerWrapper>
        </StyledOverlay>
    ) : null;
});
