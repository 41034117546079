import * as React from 'react';

import {ExternalLinkLightboxContentModel} from '../../../generated/core';
import {AuthoringWrapper} from '../../components/AuthoringWrapper';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {
    ButtonElement,
    ButtonElementComponent
} from '../editorial/elements/buttonElement/ButtonElement';
import {HeadingElement} from '../editorial/elements/HeadingElement';
import {CopyItem} from '../editorial/items/CopyItem';
import {Lightbox} from '../../components/Lightbox';

export interface ExternalLinkConfig {
    href: string;
    target: string;
}

type ExtraProps = {onClose: () => void};

export type ExternalLinkLightboxProps = ExternalLinkLightboxContentModel &
    ExternalLinkConfig &
    ExtraProps;

class ExternalLinkLightboxInternal extends React.PureComponent<
    ExternalLinkLightboxProps
> {
    public render(): React.ReactNode {
        const {
            target,
            href,
            primaryButtonLabel,
            showSecondaryButton,
            onClose,
            btnCloseLabel
        } = this.props;

        const secondaryButton = showSecondaryButton ? (
            <ButtonElement path="secondaryButton" />
        ) : (
            undefined
        );

        return (
            <AuthoringWrapper
                title="External Link Lightbox"
                bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
            >
                <Lightbox
                    onClose={onClose}
                    heading={<HeadingElement path="heading" order="H3" />}
                    copy={<CopyItem path="copy" />}
                    primaryButton={
                        <ButtonElementComponent
                            buttonLabel={primaryButtonLabel}
                            buttonStyle="primary"
                            buttonTarget={target}
                            buttonTitle={primaryButtonLabel}
                            buttonUrl={href}
                            ignoreExternalLinkLightbox
                        />
                    }
                    secondaryButton={secondaryButton}
                    btnCloseLabel={btnCloseLabel}
                />
            </AuthoringWrapper>
        );
    }
}

export const ExternalLinkLightbox = MapTo<ExtraProps>(
    'vwa-ngw18/components/structure/externalLinkLightbox',
    ExternalLinkLightboxInternal
);
