import {
    Disclaimer,
    MediaAspectRatio,
    MediaFocalPoint,
    SchemaVideoModel
} from '../../../generated/core';
import {ActionScriptReport} from '../tracking/TrackingService';

export const BASIC_STAGE_RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/sections/basicStageSection';

export interface BasicStageData {
    readonly heading: string;
    readonly media: MediaElementData;
    readonly primaryButton?: ButtonElementData;
    readonly secondaryButton?: ButtonElementData;
    readonly trackingModel?: TrackingData;
    readonly actionScriptReports?: ActionScriptReport[];
}

export type MediaElementData =
    | MediaImageElementData
    | MediaVideoElementData
    | MediaAnimationElementData;

export interface MediaImageElementData {
    readonly disclaimers: Disclaimer[];
    mediaType: 'IMAGE';
    readonly image: ImageElementData;
}

export interface MediaVideoElementData {
    readonly disclaimers: Disclaimer[];
    readonly mediaType: 'VIDEO';
    readonly video: VideoElementData;
}

export interface MediaAnimationElementData {
    readonly disclaimers: Disclaimer[];
    readonly mediaType: 'ANIMATION';
    readonly animation: AnimationElementData;
}

export interface ImageElementData {
    altText: string;
    portrayImage?: MediaAssetImageData;
    scene7File: string;
    originalHeight?: number;
    originalWidth?: number;
    focalPoint?: MediaFocalPoint;
    aspectRatio?: MediaAspectRatio;
}

export interface VideoElementData {
    title: string;
    autoPlay: boolean;
    autoPlayDelayed: boolean;
    schemaVideoModel?: SchemaVideoModel;
    coverImage: MediaAssetImageData;
    scene7File: string;
    aspectRatio?: MediaAspectRatio;
    trackingModel?: TrackingData;
}

export interface AnimationElementData {
    altText: string;
    showTimeline: boolean;
    playsInLoop: boolean;
    trackAnimationStart: boolean;
    schemaVideoModel?: SchemaVideoModel;
    coverImage: MediaAssetImageData;
    scene7File: string;
    aspectRatio?: MediaAspectRatio;
    trackingModel?: TrackingData;
}

export interface ButtonElementData {
    buttonLabel: string;
    buttonTitle?: string;
    layerButton: boolean;
    buttonTarget: '_self' | '_blank';
    buttonUrl?: string;
}

export interface MediaAssetImageData {
    scene7File: string;
    originalHeight?: number;
    originalWidth?: number;
    focalPoint?: MediaFocalPoint;
    aspectRatio?: MediaAspectRatio;
}

export interface TrackingData {
    contentLabels: string;
    contentName: string;
}
