import {FeatureHub} from '@feature-hub/core';
import {HistoryServiceV2} from '@feature-hub/history-service';

export const HISTORY_ID = 's2:history';

export const createCustomHistoryService = (
    featureHub: FeatureHub,
    consumerId: string
) => {
    const binding = featureHub.featureServiceRegistry.bindFeatureServices(
        {
            dependencies: {
                featureServices: {[HISTORY_ID]: '2.0.0'}
            }
        },
        consumerId
    );

    return binding.featureServices[HISTORY_ID] as HistoryServiceV2;
};
