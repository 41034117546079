import * as React from 'react';
import {ErrorMessage} from '../../modules/structure/login/ErrorMessage';
import {useLoginAlertService} from '../index';
import {LoginAlertService} from './LoginAlertService';
import {ErrorStatus} from './LoginStatus';

export interface LoginErrorProps {
    readonly errorStatus: ErrorStatus;
    readonly closeButton: JSX.Element;
}

export const LoginAlert: React.FunctionComponent<LoginErrorProps> = (
    props: LoginErrorProps
) => {
    const loginAlertService: LoginAlertService = useLoginAlertService();

    const {errorStatus, closeButton} = props;
    const contentPath = loginAlertService.contentPaths.get(errorStatus);

    return <ErrorMessage path={contentPath || ''} closeButton={closeButton} />;
};
