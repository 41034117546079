import * as React from 'react';
import styled from 'styled-components';

import {VideoPlayerV2} from '../../../../d6/components/video-player-v2';

import {MediaAspectRatio} from '../../../../../generated/api';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {aspectRatioMap} from '../../../../utils/aspectRatioMap';
import {VideoElementProps} from './VideoElement';
import {useVideoAspectRatio} from './hooks/useVideoAspectRatio';
import {useVideoStateChangeHandler} from './hooks/useVideoStateChangeHandler';
import {useVideoStateManagement} from './hooks/useVideoStateManagement';
import {useVideoTracking} from './hooks/useVideoTracking';

const StyledVideoElement = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
`;

export function VideoElementPublish(
    props: VideoElementProps
): JSX.Element | null {
    const {
        autoPlay,
        contentName,
        disclaimers,
        fileReference,
        matchParent,
        originalCqPath,
        title,
        videoRenditions,
        coverImage,
        videoImageSrc,
        autoPlayDelayed,
        soundControlTitle,
        pauseButtonTitle,
        playButtonTitle,
        disableFullScreenButtonTitle,
        enableFullScreenButtonTitle,
        soundProgressBarAriaLabel,
        timeProgressBarAriaLabel,
        subtitlesFileReference,
        transcriptFileReference,
        downloadTranscriptButtonTitle,
        forceOriginalAspectRatio,
        responsiveMediaConfig,
        countdownState,
        onCoverImageLoad
    } = props;

    const videoTracking = useVideoTracking({
        fileReference,
        title,
        contentName,
        originalCqPath,
        countdownState
    });
    const onStateChange = useVideoStateChangeHandler(
        videoTracking.onVideoStateChange
    );

    const {
        onPlay,
        onPause,
        onAutoPause,
        onFullScreenEntered,
        onFullScreenExited,
        onMilestoneInit,
        onMute,
        onUnmute
    } = videoTracking;

    const {
        videoContainerRef,
        videoPlayerProxy,
        videoPlayerRef,
        handleCoverImageLoadOrError
    } = useVideoStateManagement({
        autoPlay,
        autoPlayDelayed,
        autoPause: true,
        trackingCallbacks: {pause: onAutoPause}
    });

    const aspectRatio = useVideoAspectRatio(
        matchParent,
        props.aspectRatio,
        videoPlayerProxy
    ) as MediaAspectRatio;

    return (
        <CyAttributeAppender name="videoElement">
            <StyledVideoElement ref={videoContainerRef}>
                <VideoPlayerV2
                    videoComponentFactoryProps={{
                        videoPlayerProxy,
                        aspectRatio,
                        videoContainerRef,
                        title,
                        videoRenditions,
                        coverImageModel: {
                            ...coverImage,
                            src: coverImage?.src || videoImageSrc,
                            focalPoint: 'CENTER_CENTER',
                            aspectRatio
                        },
                        onDurationChange: videoTracking
                            ? onMilestoneInit
                            : undefined,
                        onCoverImageLoad: () => {
                            handleCoverImageLoadOrError();
                            if (onCoverImageLoad) onCoverImageLoad();
                        },
                        onCoverImageError: () => {
                            handleCoverImageLoadOrError();
                        },
                        muted: autoPlay,
                        subtitlesFileReference: subtitlesFileReference,
                        responsiveMediaConfig
                    }}
                    aspectRatio={
                        aspectRatioMap[aspectRatio as MediaAspectRatio] ||
                        'matchParentHeight'
                    }
                    onMute={onMute}
                    onUnmute={onUnmute}
                    autoPlay={autoPlay}
                    disclaimer={disclaimers}
                    onFullScreenEntered={onFullScreenEntered}
                    onFullScreenExited={onFullScreenExited}
                    onPauseClick={onPause}
                    onPlayClick={onPlay}
                    title={title}
                    soundControlTitle={soundControlTitle}
                    pauseButtonTitle={pauseButtonTitle}
                    playButtonTitle={playButtonTitle}
                    soundProgressBarAriaLabel={soundProgressBarAriaLabel}
                    timeProgressBarAriaLabel={timeProgressBarAriaLabel}
                    disableFullScreenButtonTitle={disableFullScreenButtonTitle}
                    enableFullScreenButtonTitle={enableFullScreenButtonTitle}
                    downloadTranscriptButtonTitle={
                        downloadTranscriptButtonTitle
                    }
                    transcriptFileReference={transcriptFileReference}
                    onStateChanged={onStateChange}
                    ref={videoPlayerRef}
                    showFullscreenBtn
                    showSoundControls
                    showTimeline
                    startUnmuted={!autoPlay}
                    forceOriginalAspectRatio={forceOriginalAspectRatio}
                />
            </StyledVideoElement>
        </CyAttributeAppender>
    );
}
