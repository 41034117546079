import {singleton, inject} from '../../infrastructure/di/annotations';
import {
    IdkServiceConnector,
    MergeConsentStatus,
    UpdateMergeConsent
} from './IdkServiceConnector';
import {LocaleModel} from '../../../generated/core';

@singleton('IdkServiceConnector', {env: 'client', devMode: true})
export class IdkServiceConnectorImpl implements IdkServiceConnector {
    @inject()
    private localeModel!: LocaleModel;

    public async getMergeConsentStatus(): Promise<MergeConsentStatus> {
        const {countryCode, language} = this.localeModel;

        const response = window.prompt(
            'response of idk:\n 1. failure: fails\n 2. yes: consent was given\n 3. no: consent was not given yet'
        );

        if (response === 'failure') {
            const text = 'test failure';
            throw new Error('error in merge consent: ' + text);
        }
        const userDecision = response === 'no' ? null : true;
        return {
            consentRequired: true,
            disclaimer: 'disclaimer',
            documentKey: 'dd',
            locale: countryCode + '_' + language,
            text: response + '',
            userDecision,
            title: '',
            consentedAt: '',
            consentedVersion: 1,
            version: 3
        };
    }

    public async setMergeConsentStatus(
        _update: UpdateMergeConsent
    ): Promise<void> {}
}
