import {
    ShareLocaleManager,
    defineGfaLocalService,
    defineLocaleService,
    LocaleServiceConfig
} from '@volkswagen-onehub/locale-service';
import {SharedGfaLocaleService} from '@volkswagen-onehub/gfa-locale-service';
import {DirectionType, LocaleModel} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';
import {BrandIdentifier} from '@volkswagen-onehub/gfa-dealer-context-service';

export function createLocaleService(
    localeModel: LocaleModel,
    direction: DirectionType
): [
    ServiceRegistration<ShareLocaleManager>,
    ServiceRegistration<SharedGfaLocaleService>
] {
    let marketType: 'mixed' | 'passenger' | 'commercial' | undefined;
    let brand: BrandIdentifier;
    switch (localeModel.marketType) {
        case 'COMMERCIAL':
            marketType = 'commercial';
            brand = 'N';
            break;
        case 'PASSENGER':
            marketType = 'passenger';
            brand = 'V';
            break;
        default:
            marketType = 'mixed';
            brand = 'V';
    }
    const config: LocaleServiceConfig = {
        language: localeModel.language,
        countryCode: localeModel.countryCode,
        market: localeModel.countryCodeLong,
        marketType: marketType,
        brand: brand,
        currency: localeModel.currency,
        direction
    };
    const definition = defineLocaleService(config);

    const gfaDefinition = defineGfaLocalService();

    return [
        {
            definition,
            service: {
                typeName: 'LocaleService',
                version: '^1.0.0'
            }
        },
        {
            definition: gfaDefinition
        }
    ];
}
