import {Breakpoints} from '@volkswagen-onehub/components-core';
import {IValueDidChange} from 'mobx';
import {
    ApplicationTrackingData,
    PageRootModel,
    SpaAsyncConfig,
    SpaGlobalConfig,
    TestAutomationConfigModel
} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {getBrowserOrientation} from '../../utils/browser/getBrowserOrientation';
import {getCurrentURL} from '../../utils/browser/getCurrentURL';
import {getGlobal} from '../../utils/getGlobal';
import {processContentId} from '../../utils/processContentId';
import {ContentStore} from '../content/ContentStore';
import {Logger} from '../logger/Logger';
import {MydealerStore} from '../mydealer/MydealerStore';
import {NavigationService, NavigationState} from '../route/NavigationService';
import {ClientLoginStore} from '../vwid/ClientLoginStore';
import {
    ActionScriptReport,
    ContentData,
    ContextTrackingData,
    DeviceSpecific,
    EnvironmentData,
    EventInfoData,
    Filter,
    LinkData,
    PageData,
    PageTrackingData,
    PartnerData,
    PersonalizationData,
    SearchData,
    TabData,
    TrackingDTO,
    TrackingEventAction,
    TrackingService,
    UserAgentData,
    VideoPlayerData
} from './TrackingService';
import {TeaserPersonalization} from '../../utils/tracking/TeaserPersonalization';
import {AccordionOpeningType} from '../../modules/editorial/items/AccordionItem';
import {TrackEventHolder} from '../logger/TrackEventHolder';
import {AbTestService} from '../abtest/AbTestService';

export interface CustomTrackingData {
    environment?: Partial<EnvironmentData>;
}

@singleton('TrackingService', {env: 'client'})
export class ClientTrackingService implements TrackingService {
    // Used for obtaining trackingConfigurationModel from it
    @inject() private applicationTrackingData!: ApplicationTrackingData;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private spaAsyncConfig!: SpaAsyncConfig;
    @inject() private navigationService!: NavigationService;
    @inject() private contentStore!: ContentStore;
    @inject() private mydealerStore!: MydealerStore;
    @inject() private loginStore!: ClientLoginStore;
    @inject() private logger!: Logger;
    @inject() private testAutomationConfigModel!: TestAutomationConfigModel;
    @inject() private trackEventHolder?: TrackEventHolder;
    @inject() private abTestService!: AbTestService;

    @postConstruct()
    public init(): void {
        this.log(
            'Tracking config data initialize: %s',
            JSON.stringify(this.applicationTrackingData)
        );
        this.navigationService.listen(this.navigationChangeListener);
        this.trackPageLoadNow();
    }

    //
    // Different methods for handling concrete events of tracking
    //

    public trackPageLoad(id: string | null): void {
        if (this.applicationTrackingData.disablePageLoadEvent) {
            return;
        }

        this.log(`pageload ${id}`);

        let toSend: TrackingDTO;
        const currentPageModel = this.contentStore.getCurrentPageRootModel();
        if (currentPageModel && currentPageModel.errorPage) {
            toSend = {
                ...this.collectGeneralData(
                    id,
                    TrackingEventAction.VWBasic_Error_Pageload
                ),
                error: {
                    code: currentPageModel.errorPageCode || undefined,
                    referringUrl: currentPageModel.referringUrl || undefined
                }
            };
        } else {
            const action = getGlobal().integratorSpaModel
                ? TrackingEventAction.VWBasic_Integrator_Pageload
                : TrackingEventAction.VWBasic_Pageload;
            toSend = {
                ...this.collectGeneralData(id, action),
                page: this.createPageData(currentPageModel)
            };
        }

        this.sendSingleTracking(toSend);
    }

    public trackEnterViewport(
        id: string,
        teaserList?: TeaserPersonalization[],
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>
    ): void {
        this.log(`entered viewport ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_ContentSection_EnterViewport,
                contextData,
                additionalTrackingData,
                teaserList
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackStageSectionLoad(
        id: string,
        actionScriptReports?: ActionScriptReport[],
        contextData?: ContextTrackingData
    ): void {
        this.log(`entered stage section load ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_StageSection_Load,
                contextData,
                {
                    personalization: {
                        actionScriptReports
                    } as any
                }
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackAssetLoad(
        id: string,
        deviceSpecific: DeviceSpecific[],
        contextData?: ContextTrackingData
    ): void {
        this.log(`entered asset load ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Asset_Load,
                contextData,
                {
                    content: {
                        DeviceSpecific: deviceSpecific
                    }
                }
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackLinkClickExternal(
        url: string,
        contentId?: string,
        linkName?: string,
        contextData?: ContextTrackingData
    ): void {
        this.log(`link click external ${url}`);

        const id = contentId ? contentId : null;
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Link_Click,
                contextData
            ),
            link: this.createLinkData(url, linkName || 'External Link Click'),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackLinkClick(
        url: string,
        contentId?: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO
    ): void {
        this.log(`link click ${url}`);

        const id = contentId ? contentId : null;
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Link_Click,
                contextTrackingData,
                additionalTrackingData
            ),
            link: this.createLinkData(url, linkName || 'Link Click'),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackQuickAccessLinkClick(
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData,
        contentId?: string,
        trackingData?: object
    ): void {
        this.log(`quick access link click ${url}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                contentId || null,
                TrackingEventAction.VWBasic_QuickAccess_Click,
                contextData
            ),
            link: this.createLinkData(
                url,
                linkName || 'Quick Access Link Click'
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            ),
            ...(trackingData || {})
        };

        this.sendSingleTracking(toSend);
    }

    public trackFooterLinkClick(url: string, linkName: string): void {
        this.log(`footer link click ${url}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                null,
                TrackingEventAction.VWBasic_FooterLink_Click
            ),
            link: this.createLinkData(url, linkName || 'Footer Link Click')
        };

        this.sendSingleTracking(toSend);
    }

    public trackButtonClick(
        id: string,
        url: string,
        linkName: string,
        contextData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO
    ): void {
        this.log(`button click ${id}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Button_Click,
                contextData,
                additionalTrackingData
            ),
            link: this.createLinkData(url, linkName || 'Button Click')
        };

        this.sendSingleTracking(toSend);
    }

    public trackNextBestActionButtonClick(
        url: string,
        contentId?: string,
        linkName?: string,
        contextTrackingData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>
    ): void {
        this.log(`nba button click ${url}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                contentId || null,
                TrackingEventAction.VWBasic_NBAButton_Click,
                contextTrackingData,
                additionalTrackingData
            ),
            link: this.createLinkData(url, linkName || 'NBAB Click'),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackNextBestActionDisplay(
        actionScriptReports: ActionScriptReport[],
        contextTrackingData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>
    ): void {
        this.log(`nba button display`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                null,
                TrackingEventAction.VWBasic_NBAButton_Load,
                contextTrackingData,
                {
                    ...additionalTrackingData,
                    personalization: {
                        actionScriptReports
                    } as any
                }
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackContentTabClick(
        id: string,
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData
    ): void {
        this.log(`content tab click ${linkName}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_ContentTab_Click,
                contextData
            ),
            link: this.createLinkData(url, linkName),
            tab: {name: linkName}
        };

        this.sendSingleTracking(toSend);
    }

    public trackTeaserClick(
        id: string,
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData,
        tab?: TabData
    ): void {
        this.log(`teaser click ${id} url ${url}`);

        let trackingDataToSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Teaser_Click,
                contextData
            ),
            link: this.createLinkData(url, linkName || 'Teaser Click')
        };

        if (tab && tab.name) {
            trackingDataToSend = {...trackingDataToSend, tab};
        }

        this.sendSingleTracking(trackingDataToSend);
    }

    public trackNavigationTeaserClick(
        id: string,
        url: string,
        linkName?: string,
        sectionName?: string,
        contextData?: ContextTrackingData
    ): void {
        this.log(`navigation teaser click ${id} url ${url}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_NavigationalTeaser_Click,
                contextData,
                {
                    eventInfo: {
                        eventAction:
                            TrackingEventAction.VWBasic_NavigationalTeaser_Click,
                        pageId: getCurrentURL(),
                        sectionName
                    }
                }
            ),
            link: this.createLinkData(url, linkName || 'Teaser Click')
        };

        this.sendSingleTracking(toSend);
    }

    public trackVideoEvent(
        action: TrackingEventAction,
        id: string,
        data: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void {
        this.log(`video event ${action} for ${id}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(id, action, contextData),
            video: data
        };
        this.sendSingleTracking(toSend);
    }

    public trackVideoMilestoneEvent(
        id: string,
        data: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void {
        this.log(`video milestone event for ${id}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VideoPlayer_VideoMilestone_Reached,
                contextData
            ),
            video: {
                ...data
            }
        };
        this.sendSingleTracking(toSend);
    }

    public trackAccordionClick(
        id: string,
        openClick: boolean,
        url: string,
        linkName?: string,
        contextData?: ContextTrackingData
    ): void {
        this.log(`accordion ${openClick ? 'open' : 'close'} click ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                openClick
                    ? TrackingEventAction.VWBasic_AccordionOpen_Click
                    : TrackingEventAction.VWBasic_AccordionClose_Click,
                contextData
            ),
            link: this.createLinkData(url, linkName || 'Accordion Click')
        };

        this.sendSingleTracking(toSend);
    }

    public trackAccordionClickV2({
        id,
        openClick,
        url,
        linkName,
        contextData,
        accordion
    }: {
        id: string;
        openClick: boolean;
        url: string;
        linkName?: string;
        contextData?: ContextTrackingData;
        accordion: AccordionOpeningType;
    }): void {
        this.log(`accordion ${openClick ? 'open' : 'close'} click ${id}`);
        this.log(`accordion ${accordion.groupItemState}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                openClick
                    ? TrackingEventAction.VWBasic_AccordionOpen_Click
                    : TrackingEventAction.VWBasic_AccordionClose_Click,
                contextData
            ),
            link: this.createLinkData(url, linkName || 'Accordion Click'),
            accordion
        };

        this.sendSingleTracking(toSend);
    }

    public trackLoginClick(id: string, url: string, linkName?: string): void {
        this.log(`login click ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Login_Click
            ),
            link: this.createLinkData(url, linkName || 'Login Click')
        };

        this.sendSingleTracking(toSend);
    }

    public trackMainNavigationLogoClick(url: string): void {
        this.log('main navigation logo click');
        const id = 'vw-logo';

        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_QuickAccess_Click
        );

        const toSend: TrackingDTO = {
            ...generalData,
            link: this.createLinkData(url, id)
        };

        this.sendSingleTracking(toSend);
    }

    public trackMainNavigationClick(
        id: string,
        url: string,
        linkName: string = 'Main Navigation Link Click'
    ): void {
        this.log(`main navigation item click ${id}`);

        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_MainNavigation_Click
        );

        const toSend: TrackingDTO = {
            ...generalData,
            link: this.createLinkData(url, linkName)
        };

        this.sendSingleTracking(toSend);
    }

    public trackMainNavigationClose(): void {
        this.log('main navigation close');
        const generalData = this.collectGeneralData(
            'navigation-flyout',
            TrackingEventAction.VWBasic_LayerClose_Click
        );
        const toSend: TrackingDTO = {
            ...generalData
        };
        this.sendSingleTracking(toSend);
    }

    public trackMainNavigationOpenClick(): void {
        this.log('navigation burger icon click');

        const generalData = this.collectGeneralData(
            'navigation-open-close-toggle-button',
            TrackingEventAction.VWBasic_QuickAccess_Click
        );
        const toSend: TrackingDTO = {
            ...generalData
        };
        this.sendSingleTracking(toSend);
    }

    public trackMainNavigationOpen(): void {
        this.log('main navigation open');
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                'navigation-flyout',
                TrackingEventAction.VWBasic_Layer_Load
            )
        };
        this.sendSingleTracking(toSend);
    }

    public trackLoginFlyoutOpenClick(isLoggedIn: boolean): void {
        this.log(`login flyout button click, user is logged in: ${isLoggedIn}`);
        const isUtilityNavEnabled = Boolean(
            this.spaAsyncConfig.utilityNavigationModel
        );
        const id = isUtilityNavEnabled
            ? 'utility-navigation-open--button'
            : 'login-flyout-open--button';
        const event = isLoggedIn
            ? TrackingEventAction.VWBasic_UserProfileOpen_Click
            : TrackingEventAction.VWBasic_QuickAccess_Click;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackDealerSelectFlyoutOpen(): void {
        this.log('dealer selector layer flyout button click');

        const id = 'dealer-selector-layer-open-button';
        const event = TrackingEventAction.VWBasic_QuickAccess_Click;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackLoginFlyoutOpen(): void {
        this.log('login flyout open');
        const isUtilityNavEnabled = Boolean(
            this.spaAsyncConfig.utilityNavigationModel
        );
        const id = isUtilityNavEnabled ? 'utility-navigation' : 'login-flyout';
        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_Layer_Load
        );
        const toSend: TrackingDTO = {
            ...generalData
        };
        this.sendSingleTracking(toSend);
    }

    public trackLoginFlyoutClose(): void {
        this.log('login flyout close');
        const isUtilityNavEnabled = Boolean(
            this.spaAsyncConfig.utilityNavigationModel
        );
        const id = isUtilityNavEnabled ? 'utility-navigation' : 'login-flyout';
        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_LayerClose_Click
        );
        const toSend: TrackingDTO = {
            ...generalData
        };
        this.sendSingleTracking(toSend);
    }

    public trackFeatureAppLoad(
        id: string | null,
        contextData?: ContextTrackingData,
        additionalTrackingData?: TrackingDTO
    ): void {
        this.log(`feature app load ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_FeatureApp_Load,
                contextData,
                additionalTrackingData
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackLayerLoad(
        id: string | null,
        contextData?: ContextTrackingData
    ): void {
        this.log(`layer load ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Layer_Load,
                contextData
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackLayerCloseClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void {
        this.log(`layer close ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_LayerClose_Click,
                contextData
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackLayerItemClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void {
        this.log(`layer item click ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_LayerItem_Click,
                contextData
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackAnnouncementBarLoad(
        id: string | null,
        contextData?: ContextTrackingData
    ): void {
        this.log(`announcement bar load ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_AnnouncementBar_Load,
                contextData
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackAnnouncementBarCloseClick(
        id: string | null,
        contextData?: ContextTrackingData
    ): void {
        this.log(`announcement bar close ${id}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_AnnouncementBarClose_Click,
                contextData
            ),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackContentSliderFilterClick(
        slidesItemCount: number,
        filters: string[],
        contextData?: ContextTrackingData
    ) {
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                null,
                TrackingEventAction.VWBasic_FilterCheck_Click,
                contextData
            ),
            filter: {
                name: 'CA',
                type: 'multi_value',
                numberOfResults: slidesItemCount,
                values: filters
            }
        };
        this.sendSingleTracking(toSend);
    }

    public trackSliderElementLoad(
        id: string | null,
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<ContentData>,
        filterlist?: Filter[]
    ): void {
        this.log('carousel element (re)load');
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_SliderElement_Load,
                contextData
            ),
            filter: {filterlist}
        };

        toSend.content = {...additionalTrackingData, ...toSend.content};
        this.sendSingleTracking(toSend);
    }

    public trackNavigationBarLinkClick(
        id: string | null,
        link: LinkData,
        contextData?: ContextTrackingData
    ): void {
        this.log('in-page navigation click');
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_NavigationBarLink_Click,
                contextData
            ),
            link
        };
        this.sendSingleTracking(toSend);
    }

    public trackNavigationBreadcrumbClick(
        id: string | null,
        link: LinkData,
        contextData: ContextTrackingData
    ): void {
        this.log('navigation breadcrumb click');
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_NavigationBreadcrumb_Click,
                contextData
            ),
            link
        };
        this.sendSingleTracking(toSend);
    }

    public trackNavigationTopLinkClick(url: string, linkTitle: string): void {
        this.log(`navigation top link click ${url}`);

        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                null,
                TrackingEventAction.VWBasic_NavigationTopLink_Click
            ),
            link: this.createLinkData(url, linkTitle),
            page: this.createPageData(
                this.contentStore.getCurrentPageRootModel()
            )
        };

        this.sendSingleTracking(toSend);
    }

    public trackSearchLayerOpenClick(): void {
        this.log('search layer open click');
        const id = 'search-layer-open-button';

        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_QuickAccess_Click
        );

        const toSend: TrackingDTO = {
            ...generalData
        };

        this.sendSingleTracking(toSend);
    }

    public trackSearchResultListLoad(
        searchTerm: string,
        numberOfResults: number
    ): void {
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                'search-layer-results-load',
                TrackingEventAction.VWBasic_SearchResultlist_Load
            ),
            search: this.createSearchData(searchTerm, numberOfResults)
        };

        this.sendSingleTracking(toSend);
    }

    public trackInPageNavigationOpen(): void {
        this.log(`inpage navigation dropdown open requested`);

        const id = 'in-page-navigation-open-close-toggle-button';
        const event = TrackingEventAction.VWBasic_QuickAccess_Click;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackInPageNavigationLoad(): void {
        this.log(`inpage navigation dropdown loaded and opened`);

        const id = 'in-page-navigation-flyout';
        const event = TrackingEventAction.VWBasic_Layer_Load;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackInPageNavigationClose(): void {
        this.log(`inpage navigation dropdown closed`);

        const id = 'in-page-navigation-open-close-toggle-button';
        const event = TrackingEventAction.VWBasic_LayerClose_Click;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackLoginStatusChange(contentId: string | null): void {
        this.log('user login status changed');

        const id = contentId;
        const event = TrackingEventAction.VWBasic_LoginStatus_Change;
        const toSend = this.collectGeneralData(id, event);
        this.sendSingleTracking(toSend);
    }

    public trackDealerStatusChange(): void {
        this.log('dealer status changed');

        const event = TrackingEventAction.VWBasic_DealerState_Change;
        const toSend = this.collectGeneralData(null, event);
        this.sendSingleTracking(toSend);
    }

    public trackElementSwipe(
        id: string,
        videoData: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void {
        this.log(`element swipe for ${id}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Element_Swipe,
                contextData
            ),
            video: videoData
        };
        this.sendSingleTracking(toSend);
    }

    public trackSkipButtonClick(
        id: string,
        videoData: VideoPlayerData,
        contextData?: ContextTrackingData
    ): void {
        this.log(`skip button click for ${id}`);
        const toSend: TrackingDTO = {
            ...this.collectGeneralData(
                id,
                TrackingEventAction.VWBasic_Button_Click,
                contextData
            ),
            video: videoData,
            link: {
                name: 'skipLink'
            }
        };
        this.sendSingleTracking(toSend);
    }

    public trackDataInfoButtonClick(
        id: string,
        linkName: string,
        contextData?: ContextTrackingData
    ): void {
        this.log(`technical data ${linkName} info button click`);
        const generalData = this.collectGeneralData(
            id,
            TrackingEventAction.VWBasic_InfoButton_Click,
            contextData
        );
        const toSend: TrackingDTO = {
            ...generalData,
            link: this.createLinkData(id, linkName || 'Info Button Click')
        };
        this.sendSingleTracking(toSend);
    }

    // //////////////////
    // Others
    // //////////////////
    private navigationChangeListener = (
        change: IValueDidChange<NavigationState>
    ) => {
        if (
            change.newValue.pathChanged &&
            change.newValue.rendered &&
            !change.newValue.initialRender
        ) {
            this.trackPageLoadNow();
        }
    };

    private trackPageLoadNow(): void {
        const path = this.navigationService.state.path;
        const currentPageModel = this.contentStore.getCurrentPageRootModel();
        const pageName =
            currentPageModel && currentPageModel.pageTrackingModel
                ? currentPageModel.pageTrackingModel.pageName
                : null;
        this.trackPageLoad(processContentId(path, pageName));
    }

    // //////////////////
    // Base methods
    // //////////////////

    private collectGeneralData(
        id: string | null,
        eventAction: TrackingEventAction,
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<TrackingDTO>,
        teaserList?: TeaserPersonalization[]
    ): TrackingDTO {
        const currentPageModel = this.contentStore.getCurrentPageRootModel();
        const {
            eventInfo = {},
            environment = {},
            personalization = {},
            filter = {},
            content = {}
        } = additionalTrackingData || {};

        if (!currentPageModel) {
            return {}; // This should never happen, it is here just because of tslint happiness
        }

        const finalEventInfo = this.createEventInfoData(
            id,
            eventAction,
            currentPageModel.pageTrackingModel,
            contextData,
            eventInfo
        );

        return {
            eventInfo: finalEventInfo,
            environment: this.createEnvironmentData(
                this.applicationTrackingData,
                environment
            ),
            user: this.createUserData(),
            partner: this.createPartnerData(),
            personalization: this.createPersonalizationData(
                finalEventInfo.contentId,
                contextData,
                personalization,
                teaserList
            ),
            configuration:
                currentPageModel.pageTrackingModel.vehicleData || undefined,
            content: {
                ...content,
                ...getContent(contextData)
            },
            filter
        };
    }

    private readonly sendSingleTracking = (data: TrackingDTO): void => {
        const currentPageModel = this.contentStore.getCurrentPageRootModel();
        if (currentPageModel?.mydealerPageOwner?.trackingDisabled) {
            return;
        }

        if (
            !this.hasEventAction(data, TrackingEventAction.VWBasic_Pageload) &&
            !this.hasEventAction(
                data,
                TrackingEventAction.VWBasic_Integrator_Pageload
            ) &&
            this.applicationTrackingData.disableTrackingEvents
        ) {
            return;
        }

        if (this.testAutomationConfigModel.enabled) {
            const global = getGlobal();
            if (!global.trackingEvents) {
                global.trackingEvents = [];
            }
            global.trackingEvents.push(data);
        }
        this.log(
            'tracking event ' +
                (data.eventInfo ? data.eventInfo.eventAction : 'unknown'),
            data
        );

        // this is the way that vw bootstrapper library works, right now, there is not any proper function call
        // @ts-ignore
        const vwdata = window.vwdata;
        if (this.trackEventHolder) {
            this.trackEventHolder.track(data);
        }
        if (vwdata && typeof vwdata.push === 'function') {
            vwdata.push(data);
        }
    };

    private hasEventAction = (
        data: TrackingDTO,
        action: TrackingEventAction
    ): boolean => {
        return (
            !!data && !!data.eventInfo && data.eventInfo.eventAction === action
        );
    };

    // //////////////////
    // Helper methods
    // //////////////////

    private createPageData(
        currentPageModel: PageRootModel | undefined
    ): PageData {
        return {
            publicationDate: currentPageModel
                ? currentPageModel.publicationDate
                : undefined
        };
    }

    private createEventInfoData(
        id: string | null,
        eventAction: TrackingEventAction,
        pageData: PageTrackingData,
        contextTrackingData: ContextTrackingData = {},
        additionalTrackingData?: Partial<EventInfoData>
    ): EventInfoData {
        // undefined values are returned because of SmartDigital's bootstrapper library which needs that
        return {
            abTestFeatures: this.abTestService.abTestFeatures,
            eventAction,
            brand: pageData.brand,
            pageId: getCurrentURL(),
            pageName: pageData.trackingPageName || undefined,
            contentId: id || undefined,
            contentLabels: this.getContentLabels(pageData, contextTrackingData),
            pageCategory: pageData.category || undefined,
            sectionName: contextTrackingData.sectionId || undefined,
            templateType: pageData.templateType || undefined,
            ...additionalTrackingData
        };
    }

    private getContentLabels(
        pageTrackingData: PageTrackingData,
        contextTrackingData: ContextTrackingData
    ): {} | undefined {
        const pageContentLabels = JSON.parse(
            pageTrackingData.contentLabels || '{}'
        );
        const sectionContentLabels = JSON.parse(
            contextTrackingData.contentLabels || '{}'
        );
        let cl =
            Object.keys(sectionContentLabels).length !== 0
                ? sectionContentLabels
                : pageContentLabels;
        // always inherit brand from page settings
        if (!cl.brand && pageContentLabels.brand) {
            cl.brand = pageContentLabels.brand;
        }
        return cl;
    }

    private createEnvironmentData(
        applicationTrackingConfig: ApplicationTrackingData,
        additionalTrackingData?: Partial<EnvironmentData>
    ): EnvironmentData {
        const data = {
            applicationId:
                applicationTrackingConfig.trackingApplicationId || undefined,
            applicationIdVersion: this.spaGlobalConfig.cmsVersion || undefined,
            language: applicationTrackingConfig.languageCode || undefined,
            country: applicationTrackingConfig.countryCode || undefined,
            ...additionalTrackingData
        };

        return data;
    }

    private createUserData(): UserAgentData {
        const myDealerData = this.mydealerStore.getMyDealerTrackingPayloadForUserSection();

        return {
            ...myDealerData.user,
            loginStatus: this.loginStore.isLogInStatusResolved
                ? this.loginStore.isLoggedIn
                : undefined,
            browserResolutionBreakpoint: this.getBreakpoint(),
            browserResolutionHeight: window.screen.height.toString(),
            browserResolutionOrientation: getBrowserOrientation(),
            browserResolutionWidth: window.screen.width.toString()
        };
    }

    private createPartnerData(): PartnerData | undefined {
        const data = this.mydealerStore.getMyDealerTrackingPayloadForPartnerSection();

        if (!data) {
            return undefined;
        }

        return data.partner;
    }

    private createPersonalizationData(
        eventInfoContentId?: string,
        contextData?: ContextTrackingData,
        additionalTrackingData?: Partial<PersonalizationData>,
        teaserList?: TeaserPersonalization[]
    ): PersonalizationData {
        const currentPageModel =
            this.contentStore && this.contentStore.getCurrentPageRootModel();
        const aemResourcePath =
            currentPageModel && currentPageModel.aemResourcePath;

        const personalizable: boolean = Boolean(
            contextData && Boolean(contextData.personalizationPersonalizable)
        );
        const personalizationDone: boolean = Boolean(
            contextData && Boolean(contextData.personalizationPersonalized)
        );

        const moduleId = contextData
            ? contextData.sectionGroupContentId
                ? contextData.sectionGroupContentId
                : contextData.sectionId
            : undefined;

        const placeholderId = personalizable
            ? contextData?.personalizationPlaceholderId
            : undefined;

        const group = contextData?.personalizationGroup;
        const type = contextData?.personalizationType;
        const recommendationId = contextData?.recommendationId;

        if (teaserList && personalizable) {
            teaserList = teaserList.map(t => ({
                contentId: t.contentId,
                personalizable: false,
                status: personalizationDone
            }));
        }

        return {
            status: personalizationDone,
            contentId: eventInfoContentId,
            personalizable:
                personalizable &&
                contextData?.personalizationContentId === eventInfoContentId,
            placeholderId,
            recommendationId,
            aemResourcePath,
            teaserList,
            moduleId,
            type,
            group,
            ...additionalTrackingData
        };
    }

    private createLinkData(url: string, linkName?: string): LinkData {
        const _url = url.indexOf('javascript:void') === -1 ? url : undefined;

        return {
            url: _url,
            name: linkName
        };
    }

    private createSearchData(
        searchTerm: string,
        numberOfResults: number
    ): SearchData {
        return {
            SearchTerm: searchTerm,
            NumberOfResults: numberOfResults
        };
    }

    private getBreakpoint(): string {
        if ((window as any).matchMedia) {
            if (this.matchMediaQuery(Breakpoints.b560, Breakpoints.b960)) {
                return String(Breakpoints.b560);
            } else if (
                this.matchMediaQuery(Breakpoints.b960, Breakpoints.b1280)
            ) {
                return String(Breakpoints.b960);
            } else if (
                this.matchMediaQuery(Breakpoints.b1280, Breakpoints.b1600)
            ) {
                return String(Breakpoints.b1280);
            } else if (
                this.matchMediaQuery(Breakpoints.b1600, Breakpoints.b1920)
            ) {
                return String(Breakpoints.b1600);
            } else if (
                this.matchMediaQuery(Breakpoints.b1920, Breakpoints.b2560)
            ) {
                return String(Breakpoints.b1920);
            } else if (this.matchMediaQuery(Breakpoints.b2560)) {
                return String(Breakpoints.b2560);
            }

            return '0';
        }

        return '';
    }

    private matchMediaQuery(minWidth: number, maxWidth?: number): boolean {
        if (!maxWidth) {
            return window.matchMedia(`screen and (min-width: ${minWidth}px)`)
                .matches;
        }

        return window.matchMedia(
            `screen and (max-width: ${maxWidth}px) and (min-width: ${minWidth}px)`
        ).matches;
    }

    private log(message: string, ...optionalParams: any[]): void {
        this.logger.tracking.info(message, optionalParams);
    }
}

function getContent(context?: ContextTrackingData): ContentData {
    const content: ContentData = {};
    if (!context) {
        return content;
    }
    if (context.itemCount !== undefined && context.itemPosition !== undefined) {
        content.ItemCount = context.itemCount;
        content.ItemPosition = context.itemPosition;
    }
    if (context.countdownState) {
        content.CountdownState = context.countdownState;
    }
    return content;
}
