import {SkipLinkItemModel} from '../../../generated/core';
import * as React from 'react';
import {SkipLinkKey} from './DefaultSkipLinksStore';

export const SkipLinksStoreId = 'SkipLinksStore';

export interface SkipLink extends SkipLinkItemModel {
    onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface SkipLinksStore {
    skipLinksWrapperLabel: string;
    skipLinks: SkipLink[];
    setSkipLinkClickHandler(
        key: SkipLinkKey,
        ref?: React.RefObject<HTMLElement>,
        customClickHandler?: React.MouseEventHandler<HTMLElement>
    ): void;
}
