import React from 'react';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {
    SetAttributeOnChildren,
    DEFAULT_ATTRIBUTE_KEY
} from '../helpers/setAttributeOnChildren';

export interface MagazineTeaserGridProps {
    readonly teaserItems: JSX.Element[];
    readonly headlineElement?: JSX.Element;
}

const HEADLINE_ATTRIBUTE = 'magazine-grid-headline';

const StyledMagazineTeaserGridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    grid-row-gap: ${props => props.theme.size.dynamic0150};
    grid-column-gap: ${props => props.theme.size.grid001};
    padding: 0 ${props => props.theme.size.grid001};

    @media (min-width: ${Breakpoints.b560}px) {
        grid-template-columns: repeat(3, 1fr);
        padding: 0 ${props => props.theme.size.grid002};
    }

    & [data-${DEFAULT_ATTRIBUTE_KEY}=${HEADLINE_ATTRIBUTE}] {
        justify-self: center;

        @media (min-width: ${Breakpoints.b560}px) {
            grid-column: 1/4;
        }
    }
`;

export const MagazineTeaserGrid: React.FunctionComponent<MagazineTeaserGridProps> = (
    props: MagazineTeaserGridProps
): JSX.Element | null => {
    const {teaserItems, headlineElement: headline} = props;

    return (
        <StyledMagazineTeaserGridContainer>
            {headline && (
                <SetAttributeOnChildren attributeValue={HEADLINE_ATTRIBUTE}>
                    {headline}
                </SetAttributeOnChildren>
            )}
            {teaserItems.map((teaserItem, index) => {
                const teaserKey = teaserItem.key
                    ? teaserItem.key
                    : `magazine_teaser_grid_item_${index}`;

                return <div key={teaserKey}>{teaserItem}</div>;
            })}
        </StyledMagazineTeaserGridContainer>
    );
};
