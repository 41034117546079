import {
    SharedServiceConfigProvider,
    defineServiceConfigProvider,
    defineGfaServiceConfigProvider
} from '@volkswagen-onehub/service-config-provider';
import {ServiceConfig, GfaServiceConfig} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';
import {SharedGfaServiceConfigProvider} from '@volkswagen-onehub/gfa-service-config-provider';
import {Logger} from '../../context/logger/Logger';
import {monitoringHelper} from '../../context/logger/monitoringHelper';

export function createServiceConfigProvider(
    logger: Logger,
    configs: {[key: string]: ServiceConfig} = {},
    gfaConfigs: {[brand: string]: {[key: string]: GfaServiceConfig}} = {}
): [
    ServiceRegistration<SharedServiceConfigProvider>,
    ServiceRegistration<SharedGfaServiceConfigProvider>
] {
    return [
        {
            definition: defineServiceConfigProvider(
                {
                    configs
                },
                {
                    onAccess: (
                        consumerId,
                        consumerName: string | undefined,
                        serviceConfigKey
                    ) => {
                        monitoringHelper(logger).useServiceConfiguration(
                            consumerId,
                            consumerName,
                            serviceConfigKey
                        );
                    }
                }
            ),
            service: {
                typeName: 'ServiceConfigProvider',
                version: '^1.0.0'
            }
        },
        {
            definition: defineGfaServiceConfigProvider({
                gfa: {},
                ...gfaConfigs
            })
        }
    ];
}
