import * as React from 'react';

import {
    BaseVideoElementModel,
    ImageElementModel,
    MediaAssetImageModel,
    MediaType
} from '../../../../generated/core';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {ContainerExporter} from '../../../infrastructure/ContainerExporter';
import {ImageElement} from './ImageElement';
import {Play as PlayButton} from '@volkswagen-onehub/icons-core';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {MediaDisclaimerHolder} from './MediaElement';
import {ThemeProvider, styled} from '@volkswagen-onehub/components-core';

const IMAGE_PATH = 'image';
const VIDEO_PATH = 'video';
const ANIMATION_PATH = 'animation';
const DEGREE_180_PATH = 'degree180';

type ThumbnailMediaElementProps = Readonly<{
    mediaType: MediaType;
    darkDisclaimer: boolean;
    containerExporterBaseProps: ContainerExporter;
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
}>;

const StyledThumbnailElement = styled.div`
    position: relative;
`;

StyledThumbnailElement.displayName = 'StyledThumbnailElement';

const StyledPlayButton = styled.div`
    pointer-events: none;
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        fill: ${props => props.theme.colors.button.primary.default};
    }
`;

StyledPlayButton.displayName = 'StyledPlayButton';

export function ThumbnailMediaElement(
    props: ThumbnailMediaElementProps
): JSX.Element {
    const {
        containerExporterBaseProps,
        darkDisclaimer,
        mediaType,
        responsiveMediaConfig
    } = props;

    const mediaDisclaimerProps: MediaDisclaimerHolder = {
        disclaimers: {
            color: darkDisclaimer ? 'dark' : 'light'
        }
    };

    // default ratio is used, if there is no media context (SSR)
    const DEFAULT_RATIO = 'r_16_9';

    let mediaElement;
    switch (mediaType) {
        case 'ANIMATION':
            mediaElement = containerExporterBaseProps.cqItems[ANIMATION_PATH];
            break;
        case 'VIDEO':
            mediaElement = containerExporterBaseProps.cqItems[VIDEO_PATH];
            break;
        case 'DEGREE_180':
            mediaElement = containerExporterBaseProps.cqItems[DEGREE_180_PATH];
            break;
        default:
            mediaElement = containerExporterBaseProps.cqItems[IMAGE_PATH];
    }

    const isImageElement = mediaType === 'IMAGE';
    let imageElement: MediaAssetImageModel;
    if (isImageElement) {
        imageElement = (mediaElement as unknown) as ImageElementModel;
    } else {
        const videoElement = (mediaElement as unknown) as BaseVideoElementModel;
        imageElement = videoElement.coverImage;
    }

    return (
        <CyAttributeAppender name="thumbnail">
            <ThemeProvider theme="inverted">
                <StyledThumbnailElement>
                    <ImageElement
                        path={containerExporterBaseProps.cqPath}
                        altText={mediaElement.altText || mediaElement.title}
                        containerProps={{matchParent: true}}
                        responsiveMediaConfig={responsiveMediaConfig}
                        {...imageElement}
                        aspectRatio={DEFAULT_RATIO}
                        {...mediaDisclaimerProps}
                    />
                    {!isImageElement && (
                        <StyledPlayButton>
                            <PlayButton ariaHidden />
                        </StyledPlayButton>
                    )}
                </StyledThumbnailElement>
            </ThemeProvider>
        </CyAttributeAppender>
    );
}
