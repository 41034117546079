import {VideoPlayerV2State} from '../../d6/components/video-player-v2';
import * as React from 'react';

import {MediaAspectRatio} from '../../../generated/api';
import {MediaType} from '../../../generated/core';

export interface MediaInteraction {
    readonly type: MediaType;
    readonly data: object;
}

// todo: rework this towards the store approach for video
export interface VideoInteraction extends MediaInteraction {
    readonly type: 'VIDEO';
    readonly data: VideoPlayerV2State;
    readonly startedByIO: boolean;
}

export enum MediaStoreType {
    VIDEO, // tbd
    DEGREE_180
}

/**
 * Implement media store to provide shared functionality.
 */
export interface MediaStore {
    readonly type: MediaStoreType;
}

export interface MediaContextData {
    ratio?: MediaAspectRatio;
    useParentAspectRatio?: boolean;
    sizes?: string;
    reduceBrightness?: boolean;
    isZoomedIn?: boolean;
    store?: MediaStore;
    interactionData?: MediaInteraction;
    setInteractionData?(data?: MediaInteraction | VideoInteraction): void;
}

export interface InjectedMediaContext {
    mediaContext?: MediaContextData;
}

export const MediaContext: React.Context<MediaContextData> = React.createContext(
    {}
);

export function withMediaContext<P extends object>(
    WrappedComponent: React.ComponentClass<P & InjectedMediaContext>
): React.ComponentClass<P & InjectedMediaContext> {
    return class MediaContextConsumer extends React.Component<
        P & InjectedMediaContext
    > {
        public render(): JSX.Element {
            return (
                <MediaContext.Consumer>
                    {contextData => (
                        <WrappedComponent
                            {...this.props}
                            mediaContext={contextData}
                        />
                    )}
                </MediaContext.Consumer>
            );
        }
    };
}
