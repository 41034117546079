import {styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {isDragEvent} from '../../../components/draggable-video/helpers';
import {useResizeObserver} from '../../hooks/useResizeObserver';

enum DraggingState {
    IDLE = 'idle',
    DRAGGING = 'dragging',
    NONE = 'none;'
}

const StyledTechnicalDataList = styled.ul`
    display: flex;
    gap: var(--gap);
    margin: 0;
    padding: 0 var(--padding-inline);

    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;

    scroll-padding-inline: var(--gap);
    scroll-snap-type: inline proximity;

    & > li {
        scroll-snap-align: center;
    }

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &[data-dragging-state='${DraggingState.IDLE}'] {
        cursor: grab;
    }

    &[data-dragging-state='${DraggingState.DRAGGING}'] {
        cursor: grabbing;
        user-select: none;
        scroll-snap-type: none;
        scroll-behavior: auto;
    }
`;

const isListScrollable = (listItem: HTMLUListElement) =>
    listItem.scrollWidth > listItem.clientWidth;

interface TechnicalDataProps {
    children: React.ReactNode;
}

export const TechnicalDataList = (props: TechnicalDataProps) => {
    const {children} = props;
    const listRef = React.useRef<HTMLUListElement>(null);

    useResizeObserver(listRef, () => {
        const list = listRef.current;

        if (!list) {
            return;
        }

        if (!isListScrollable(list)) {
            list.setAttribute('data-dragging-state', DraggingState.NONE);
            return;
        }
        list.setAttribute('data-dragging-state', DraggingState.IDLE);
    });

    React.useEffect(() => {
        const list = listRef.current;
        let listPosition = {left: 0, x: 0};

        if (!list) {
            return;
        }

        const handleMouseDown = (event: MouseEvent): void => {
            if (!isListScrollable(list)) {
                return;
            }

            event.preventDefault();
            listPosition = {
                left: list.scrollLeft,
                x: event.clientX
            };

            // listeners are added to the document to not loose drag functionality when accidentally leaving the target area
            document.addEventListener('mousemove', handleMouseDrag);
            document.addEventListener('mouseup', handleMouseUp);

            list.setAttribute('data-dragging-state', DraggingState.DRAGGING);
        };

        const handleMouseDrag = (event: MouseEvent): void => {
            if (!isDragEvent(event.clientX, listPosition.x)) {
                return;
            }

            const deltaX = event.clientX - listPosition.x;
            list.scrollLeft = listPosition.left - deltaX;
        };

        const handleMouseUp = (): void => {
            document.removeEventListener('mousemove', handleMouseDrag);
            document.removeEventListener('mouseup', handleMouseUp);

            list.setAttribute('data-dragging-state', DraggingState.IDLE);
        };

        const handleKeydown = (event: KeyboardEvent): void => {
            if (!['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                return;
            }

            event.preventDefault();
            let nextElement = list.firstElementChild as HTMLLIElement | null;

            if (list.contains(document.activeElement)) {
                const activeListElement = document.activeElement?.closest('li');

                nextElement =
                    event.key === 'ArrowRight'
                        ? (activeListElement?.nextElementSibling as HTMLLIElement | null)
                        : (activeListElement?.previousElementSibling as HTMLLIElement | null);
            }

            // eslint-disable-next-line no-unused-expressions
            nextElement?.focus?.();
        };

        list.addEventListener('keydown', handleKeydown);
        list.addEventListener('mousedown', handleMouseDown);

        return () => {
            list.removeEventListener('keydown', handleKeydown);
            list.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    return (
        <StyledTechnicalDataList
            ref={listRef}
            data-testid="technical-data"
            data-dragging-state={DraggingState.IDLE}
        >
            {children}
        </StyledTechnicalDataList>
    );
};
