import * as React from 'react';
import {useGlobalDisclaimerStore} from '..';
import {Value} from '../../utils/contextUtils';
import {DisclaimerStore} from './DisclaimerStore';

export const DisclaimerStoreContext = React.createContext<
    Value<DisclaimerStore>
>({});

export const LayerDisclaimerContext = (props: {
    path: string;
    children: JSX.Element | JSX.Element[];
}) => {
    const {path, children} = props;
    const globalDisclaimerStore = useGlobalDisclaimerStore();
    const store = React.useMemo(() => {
        return {value: globalDisclaimerStore.getLayeredStore(path)};
    }, [path, globalDisclaimerStore]);
    return (
        <DisclaimerStoreContext.Provider value={store}>
            {children}
        </DisclaimerStoreContext.Provider>
    );
};
