import {
    UserInteractionService,
    defineUserInteractionService
} from '@volkswagen-onehub/user-interaction-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createUserInteractionService(): ServiceRegistration<
    UserInteractionService
> {
    const definition = defineUserInteractionService();
    return {
        definition: definition,
        service: {
            typeName: 'UserInteractionService',
            version: '^2.0.0'
        }
    };
}
