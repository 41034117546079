import {isInBrowser} from '../browser/isInBrowser';
import {getGlobal} from '../getGlobal';

const isMockedVec = () => {
    return Boolean(
        isInBrowser() && getGlobal().config.getBoolean('mock', 'vec')
    );
};

let isInVecValue: boolean | undefined;

/**
 * Check if this is run in the vec
 * @param useCache should be cached as it never changes during the lifetime of the SPA
 */
export const isInVec = (useCache: boolean = true) => {
    if (useCache && typeof isInVecValue !== 'undefined') {
        return isInVecValue;
    }
    if (typeof window !== 'undefined' && 'Cypress' in window) {
        isInVecValue = isMockedVec();
        return isInVecValue;
    }
    isInVecValue =
        isMockedVec() ||
        (typeof window !== 'undefined' &&
            window.parent &&
            window.parent !== window);

    return isInVecValue;
};
