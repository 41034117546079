import * as React from 'react';

import {
    StyledButtons,
    StyledContent,
    StyledLayout,
    StyledMedia,
    StyledTextElements
} from './styles';

interface RevealItemProps {
    media: JSX.Element;
    heading: JSX.Element;
    copy: JSX.Element;
    buttons: JSX.Element | undefined;
    disclaimers: JSX.Element;
    isCutout: boolean;
}

export const RevealItem = (props: RevealItemProps) => {
    const {media, heading, copy, buttons, disclaimers, isCutout} = props;

    return (
        <StyledLayout>
            <StyledMedia isCutout={isCutout}>{media}</StyledMedia>
            <StyledContent>
                <StyledTextElements>
                    {heading}
                    {copy}
                </StyledTextElements>
                <StyledButtons>{buttons ? buttons : null}</StyledButtons>
            </StyledContent>
            {disclaimers}
        </StyledLayout>
    );
};
