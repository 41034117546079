import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';
import {Copyright, MarketLogo} from '../index';

import {getStartDirection, resetListStyles} from '../../helpers';

const StyledBottomNav = styled.nav`
    display: flex;
    flex-direction: column;

    @media (min-width: ${Breakpoints.b560}px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;
StyledBottomNav.displayName = 'StyledBottomNav';

const StyledBottomNavCopyright = styled.div`
    margin-bottom: ${props => props.theme.size.static400};
    margin-top: ${props => props.theme.size.static300};

    @media (min-width: ${Breakpoints.b560}px) {
        margin-bottom: 0;
        white-space: nowrap;
    }
`;
StyledBottomNavCopyright.displayName = 'StyledBottomNavCopyright';

const StyledBottomNavLangSwitcher = styled.div`
	margin-top: ${props => props.theme.size.static500};
	order: 1;

	@media (min-width: ${Breakpoints.b560}px) {
		margin-top: 0;
		padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static500};
		white-space: nowrap;
		}
`;
StyledBottomNavLangSwitcher.displayName = 'StyledBottomNavLangSwitcher';

const StyledCopyrightWrapper = styled.div`
    border-top: solid 1px ${props => props.theme.colors.border.divider};
    text-align: ${props => getStartDirection(props.theme.direction)};
    width: ${props => props.theme.size.grid010};
    margin-top: ${props => props.theme.size.static500};

    @media (min-width: ${Breakpoints.b560}px) {
        margin-top: ${props => props.theme.size.static530};
        width: ${props => props.theme.size.grid005};
    }
`;
StyledCopyrightWrapper.displayName = 'StyledCopyrightWrapper';

const StyledCustomListContainer = styled.ul`
    ${resetListStyles}

    display: flex;
    flex-wrap: wrap;
`;
StyledCustomListContainer.displayName = 'StyledCustomListContainer';

const StyledBottomNavChild = styled.li`
    display: flex;

    :not(:last-child):after {
        content: '|';
        margin: 0 ${props => props.theme.size.static200};
    }
`;
StyledBottomNavChild.displayName = 'StyledBottomNavChild';

export interface BottomNavProps {
    readonly hideCopyright?: boolean;
    readonly copyright?: string;
    readonly marketLogoMedia?: JSX.Element;
    readonly langSwitcher?: JSX.Element;
    readonly bottomNavAriaLabel: string;
}

export function BottomNav(
    props: React.PropsWithChildren<BottomNavProps>
): React.ReactElement {
    const {
        children,
        copyright,
        hideCopyright,
        langSwitcher,
        marketLogoMedia,
        bottomNavAriaLabel
    } = props;

    return (
        <>
            <StyledBottomNav aria-label={bottomNavAriaLabel}>
                {langSwitcher && (
                    <StyledBottomNavLangSwitcher>
                        {langSwitcher}
                    </StyledBottomNavLangSwitcher>
                )}
                <StyledCustomListContainer role="list">
                    {React.Children.map(children, child => (
                        <StyledBottomNavChild role="listitem">
                            {child}
                        </StyledBottomNavChild>
                    ))}
                </StyledCustomListContainer>
            </StyledBottomNav>
            {!hideCopyright && (
                <StyledCopyrightWrapper>
                    {marketLogoMedia && (
                        <MarketLogo>{marketLogoMedia}</MarketLogo>
                    )}
                    <StyledBottomNavCopyright>
                        <Copyright copyright={copyright} />
                    </StyledBottomNavCopyright>
                </StyledCopyrightWrapper>
            )}
        </>
    );
}
