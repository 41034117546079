import * as React from 'react';
import styled from 'styled-components';

import {LazyImage} from '@volkswagen-onehub/components-core';
import {ImageIllustrationElementModel} from '../../../../generated/core';
import {
    AuthoringMediaInfoBox,
    MediaInfo
} from '../../../components/AuthoringMediaInfoBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {useImageService} from '../../../context';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {getImageResourceSet} from '../../../utils/getImageResourceSet';
import {ImagePlaceHolderElement} from './ImagePlaceHolderElement';
import {ImageIcon} from './media/icons';
import {useEnableSmartImaging} from '../../../hooks/useEnableSmartImaging';

const StyledLazyImage = styled(LazyImage)`
    max-width: 100%;
    max-height: 100%;
`;

export function ImageIllustrationElementComponent(
    props: ImageIllustrationElementModel
): JSX.Element {
    const {altText = '', mediaInfo} = props;
    const enableSmartImaging = useEnableSmartImaging();

    const imageService = useImageService();
    const {src, srcSet, srcSetWebP} = getImageResourceSet(
        imageService,
        {
            ...props,
            focalPoint: undefined,
            aspectRatio: undefined
        },
        enableSmartImaging,
        true
    );

    return (
        <AuthoringWrapper
            title="ImageIllustrationElementComponent"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            {C.isInEditor() && src && (
                <AuthoringMediaInfoBox>
                    {mediaInfo && <MediaInfo {...mediaInfo} />}
                </AuthoringMediaInfoBox>
            )}
            {C.isInEditor() && !src ? (
                <ImagePlaceHolderElement>
                    <ImageIcon />
                </ImagePlaceHolderElement>
            ) : (
                <MediaContext.Consumer>
                    {context => (
                        <StyledLazyImage
                            src={src}
                            srcSet={srcSet}
                            data-srcset-webp={srcSetWebP}
                            sizes={context.sizes}
                            alt={altText}
                        />
                    )}
                </MediaContext.Consumer>
            )}
        </AuthoringWrapper>
    );
}

export const ImageIllustrationElement = MapTo(
    'vwa-ngw18/components/editorial/elements/imageIllustrationElement',
    ImageIllustrationElementComponent
);
