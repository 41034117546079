import * as React from 'react';
import styled from 'styled-components';

import {LanguageSwitcherModel} from '../../../../generated/core';
import {LanguageLinkProps} from './LanguageSwitcher';

const MAX_NUM_OF_VISIBLE_LINKS = 3;

const StyledLangLinkWrapper = styled.span``;

export function mapLanguageLinks(
    languageSwitcherModel: LanguageSwitcherModel,
    LinkComponent: React.FunctionComponent<LanguageLinkProps>
): React.ReactElement[] | null {
    const {languageLinks, currentLink} = languageSwitcherModel;
    const maxVisibleLinksIndex = MAX_NUM_OF_VISIBLE_LINKS - 1;

    if (
        !languageLinks ||
        Object.keys(languageLinks).length < maxVisibleLinksIndex
    ) {
        return null;
    }

    return Object.keys(languageLinks).map((lang: string) => {
        return (
            <StyledLangLinkWrapper key={lang} lang={lang}>
                <LinkComponent
                    lang={languageLinks[lang].displayLanguage}
                    linkHref={languageLinks[lang].path}
                    key={lang}
                    locale={lang}
                    currentLink={currentLink}
                    title={languageLinks[lang].title}
                    ariaLabel={languageLinks[lang].title}
                />
            </StyledLangLinkWrapper>
        );
    });
}
