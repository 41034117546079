import {
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {NavigationLinkModel} from '../../generated/api';
import {useTrackingService} from '../context';
import {CmsTextLink} from './links/CmsTextLink';

export interface FooterLinkProps {
    link: NavigationLinkModel;
}

export function FooterLink(props: FooterLinkProps): JSX.Element {
    const trackingService = useTrackingService();
    const {link} = props;

    function onClick(): void {
        const {url, title} = link;
        trackingService.trackFooterLinkClick(url, title);
    }

    return (
        <Text appearance={TokenTextAppearance.copy100} tag={TextTag.span}>
            <CmsTextLink
                href={link.url}
                target={link.linkWindowTarget}
                trackingActionOverride={onClick}
            >
                {link.title}
            </CmsTextLink>
        </Text>
    );
}
