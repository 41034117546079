import {Footer as LsgFooter} from '../../../d6/components/footer';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {
    useNavigationService,
    useFooterModel,
    useSkipLinksStore
} from '../../../context';
import {LanguageSwitcher} from '../navigation/LanguageSwitcher';
import {
    prepareBottomNav,
    prepareMainNav,
    useFooterDisclaimers
} from './helpers';

import {DecorativeImage} from '../../../components/Image';
import {SkipLinkTargetWrapper} from '../navigation/SkipLinks';
import {ZIndex} from '../../../utils/zIndex';
import {useDisclaimers} from '../../../context/disclaimer/useDisclaimers';
import {useAbTest} from '../../../hooks/useAbTest';

const Footer: React.FunctionComponent<any> = observer(
    function F(): JSX.Element {
        const navigationService = useNavigationService();
        const footerModel = useFooterModel();

        const allDisclaimers = useDisclaimers('T2_PAGE_BASED');

        const disclaimers = useFooterDisclaimers({
            pageDisclaimers: allDisclaimers
        });
        const skipLinksStore = useSkipLinksStore();
        const isHorizontalView = useAbTest('nbabHorizontalView');

        const footerRef = React.useRef<HTMLDivElement>(null);

        React.useEffect(() => {
            skipLinksStore.setSkipLinkClickHandler('footerSkipLink', footerRef);
        }, [footerRef, skipLinksStore]);

        const {
            copyright,
            legalAndImprintLinks,
            linkGroups,
            thirdPartyLogoSrc,
            thirdPartyLogoAlt,
            bottomNavAriaLabel
        } = footerModel;

        const thirdPartyLogo = thirdPartyLogoSrc ? (
            <DecorativeImage src={thirdPartyLogoSrc} alt={thirdPartyLogoAlt} />
        ) : (
            undefined
        );

        const langSwitcher = <LanguageSwitcher />;

        const mainNav = prepareMainNav(linkGroups);
        const copyrightText = `© ${copyright}`;
        const mergedLegalAndImprintLinks = legalAndImprintLinks.IMPRINT.links.concat(
            legalAndImprintLinks.LEGAL.links
        );
        const bottomNav = prepareBottomNav(
            mergedLegalAndImprintLinks,
            bottomNavAriaLabel,
            copyrightText,
            thirdPartyLogo,
            langSwitcher
        );
        const visible = !navigationService.loading;

        return (
            <SkipLinkTargetWrapper ref={footerRef}>
                <LsgFooter
                    visible={visible}
                    mainNav={mainNav}
                    bottomNav={bottomNav}
                    disclaimers={disclaimers}
                    zIndex={ZIndex.footer.z}
                    isHorizontalView={Boolean(isHorizontalView)}
                />
            </SkipLinkTargetWrapper>
        );
    }
);
Footer.displayName = 'Footer';

export {Footer};
