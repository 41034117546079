// Add z-index values here according to page structure.
// Reference them and re-assign when necessary.
//
// Make sure that every "parent" always is a valid stacking context, to have a
// predictable outcome.
// e.g.
// <main> could have css
//   z-index: ${ZIndex.main.z};
//   position: relative;
// or
//   z-index: 'auto';
//   position: 'fixed';
// but never just
//   z-index: 'auto';
//
// Ideally all children values should only be inside the stacking context that
// is the listed parent, to have a predictable outcome.

export const ZIndex = {
    skipLinks: {z: 3},
    header: {z: 2},
    main: {
        z: 1,
        movingFrame: {z: 'unset'},
        overlay: {z: 0},
        nbab: {z: 1}
    },
    footer: {z: 0},
    layers: {z: 3, movingFrame: {z: 0}},
    notifications: {z: -1}
};
