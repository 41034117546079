import {PersonalizationContextData} from '../../context/personalization/PersonalizationContext';
import {MetaData} from '../../context/smartDigital/SmartDigitalService';
import {
    ContextTrackingData,
    CountdownState
} from '../../context/tracking/TrackingService';
import {SectionContextData} from '../../modules/structure/SectionContext';
import {MutableType} from '../../types/MutableType';
import {BBOContextData} from '../../context/bbo/BBOContext';

export function createContextTrackingData(
    pc?: PersonalizationContextData | null,
    personalizationMeta?: MetaData,
    bboc?: BBOContextData | null,
    sc?: SectionContextData | null,
    sectionGroupContentId?: string,
    contentLabels?: string | null,
    itemCount?: number,
    itemPosition?: number,
    countdownState?: CountdownState
): ContextTrackingData {
    const personalizationTrackingData =
        !pc || !pc.isPersonalizable
            ? {}
            : {
                  personalizationContentId: pc.contentId,
                  personalizationPlaceholderId: pc.placeholderId,
                  personalizationPersonalizable: pc.isPersonalizable,
                  personalizationPersonalized: pc.wasPersonalized
              };
    const personalizationMetaData = {} as any;
    if (bboc) {
        personalizationMetaData.personalizationType = bboc.personalizationType;
        personalizationMetaData.personalizationGroup =
            bboc.personalizationGroup;
    } else if (personalizationMeta) {
        personalizationMetaData.personalizationType =
            personalizationMeta.personalizationType;
        personalizationMetaData.personalizationGroup =
            personalizationMeta.personalizationGroup;
        personalizationMetaData.recommendationId =
            personalizationMeta?.recommendationId;
    }
    const sectionTrackingData = !sc
        ? {}
        : {
              sectionId: sc.sectionId,
              contentName: sc.contentName
          };

    let contentLabelsData = {};
    if (contentLabels) {
        contentLabelsData = {contentLabels};
    } else if (sc?.contentLabels) {
        contentLabelsData = {contentLabels: sc.contentLabels};
    }

    const contentData = getContentData(itemPosition, itemCount, countdownState);

    return {
        ...personalizationTrackingData,
        ...personalizationMetaData,
        ...sectionTrackingData,
        ...contentLabelsData,
        ...contentData,
        sectionGroupContentId
    };
}

function getContentData(
    itemPosition: number | undefined,
    itemCount: number | undefined,
    countdownState: CountdownState | undefined
): ContextTrackingData {
    const data: MutableType<ContextTrackingData> = {};
    if (itemPosition !== undefined && itemCount !== undefined) {
        data.itemPosition = itemPosition;
        data.itemCount = itemCount;
    }
    if (countdownState) {
        data.countdownState = countdownState;
    }
    return data as ContextTrackingData;
}
