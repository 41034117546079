import {
    SharedCarConfigurationService,
    defineCarConfigurationService
} from '@volkswagen-onehub/car-configuration-feature-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createCarConfigurationService(): ServiceRegistration<
    SharedCarConfigurationService
> {
    return {
        definition: defineCarConfigurationService(),
        service: {
            typeName: 'CarConfigurationService',
            version: '^2.0.0'
        }
    };
}
