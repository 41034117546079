import * as React from 'react';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';

import {getEndDirection, hyphens} from '../helpers';

export interface FooterMainNavListProps {
    readonly headline?: string;
    readonly mainNavAriaLabel: string;
}

const StyledWrapper = styled.nav`
    ${hyphens}

	@media (min-width: ${Breakpoints.b560}px) {
		width: ${props => props.theme.size.grid004};

		:last-child {
			margin-${props => getEndDirection(props.theme.direction)}: calc(-1 * ${props =>
    props.theme.size.grid001});
		}
	}
`;
StyledWrapper.displayName = 'StyledWrapper';

const StyledHeadline = styled.h4`
    /* disable default h4 styles */
    margin: 0;
    margin-bottom: ${props => props.theme.size.static350};
`;
StyledHeadline.displayName = 'StyledHeadline';

const StyledList = styled.ul`
    margin: 0 0 ${props => props.theme.size.static500};
    padding: 0;
    list-style: none;

    a {
        display: inline-block;
        padding: ${props => props.theme.size.static100} 0;
    }
`;
StyledList.displayName = 'StyledList';

export const FooterMainNavList: React.FunctionComponent<FooterMainNavListProps> = props => {
    const {headline, children, mainNavAriaLabel} = props;
    const childElements: React.ReactElement<unknown>[] = React.Children.toArray(
        children
    ).filter(React.isValidElement);

    if (!childElements.length) {
        return null;
    }

    return (
        <StyledWrapper aria-label={mainNavAriaLabel}>
            {Boolean(headline) && (
                <StyledHeadline aria-hidden="true">
                    <Text appearance={TokenTextAppearance.headline100} bold>
                        {headline}
                    </Text>
                </StyledHeadline>
            )}
            <StyledList role="list">
                {childElements.map(child => {
                    const key = child.key === null ? undefined : child.key;

                    return <li key={key}>{child}</li>;
                })}
            </StyledList>
        </StyledWrapper>
    );
};
FooterMainNavList.displayName = 'FooterMainNavList';
