import React from 'react';
import {Breakpoints} from '@volkswagen-onehub/components-core';

export enum QueryNames {
    isMobile = 'isMobile',
    isTablet = 'isTablet',
    isLaptop = 'isLaptop',
    isDesktop = 'isDesktop',
    reducedAnimation = 'reducedAnimation'
}
export type QueryResults = Record<QueryNames, boolean>;

type QueryStrings = Record<QueryNames, string>;

export const globalQueries = {
    isMobile: `(max-width: ${Breakpoints.b560}px)`,
    isTablet: `(min-width: ${
        Breakpoints.b560
    }px) and (max-width: ${Breakpoints.b960 - 1}px)`,
    isLaptop: `(min-width: ${
        Breakpoints.b960
    }px) and (max-width:${Breakpoints.b1280 - 1}px)`,
    isDesktop: `(min-width: ${Breakpoints.b1280}px)`,
    reducedAnimation: '(prefers-reduced-motion: reduce)'
};

export const emptyGlobalQueries: QueryResults = {
    isMobile: true,
    isTablet: false,
    isLaptop: false,
    isDesktop: false,
    reducedAnimation: false
};

export const iterateQueries = (queries: QueryStrings): QueryResults =>
    Object.entries(queries).reduce(
        (accumulator, [key, value]) => ({
            ...accumulator,
            [key]: window.matchMedia(value).matches
        }),
        {} as QueryResults
    );

export const addOnChange = (
    queries: QueryStrings,
    mappingFn: Function
): void => {
    for (const query of Object.entries(queries)) {
        const match = window.matchMedia(query[1]);
        mappingFn(match);
    }
};

export const useMatchMedia = (queries: QueryStrings) => {
    const [queryMatch, setQueryMatch] = React.useState<Record<
        QueryNames,
        boolean
    > | null>(null);

    React.useEffect(() => {
        if (!queryMatch) {
            setQueryMatch(iterateQueries(queries));

            addOnChange(queries, (match: MediaQueryList) =>
                match.addEventListener('change', () =>
                    setQueryMatch(iterateQueries(queries))
                )
            );
        }

        return () => {
            addOnChange(queries, (match: MediaQueryList) =>
                match.removeEventListener('change', () =>
                    setQueryMatch(iterateQueries(queries))
                )
            );
        };
        // should only run once or on query change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queries]);

    return queryMatch;
};

export const useMatchMediaWithDefaultQueries = () =>
    useMatchMedia(globalQueries);
