import {observer} from 'mobx-react-lite';
import * as React from 'react';

import {InPageNavBarLink} from '../../../d6/components/inpage-navigation-bar/parts/in-page-link';
import {
    useExternalLinkLightboxService,
    useNavigationStore,
    useRouterService,
    useTrackingService
} from '../../../context';
import {InPageMenuItemModel} from '../../../context/navigation/NavigationStore';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {processContentId} from '../../../utils/processContentId';
import {ExternalLinkIndicator} from '@volkswagen-onehub/components-core';
import {getCurrentURL} from '../../../utils/browser/getCurrentURL';
import {StyledLinkIconWrapper} from './InPageNavigationDropdownLink';

const REDIRECT_LINK_TARGET = '_blank'; // There is an optimization ticket for this point: https://sso.volkswagen.de/bctbt/browse/NGWD6-1976

export const isLinkExternal = (link?: string) =>
    Boolean(link?.includes('http') || link?.includes('www'));

export interface InPageNavigationLinkProps {
    readonly item: InPageMenuItemModel;
}

const InPageNavigationLink: React.FunctionComponent<InPageNavigationLinkProps> = observer(
    function IPNL(props: InPageNavigationLinkProps) {
        const {url, id, title} = props.item;

        const navigationStore = useNavigationStore();
        const trackingService = useTrackingService();
        const trackingData = useContextTrackingData();
        const routerService = useRouterService();
        const externalLinkLightboxService = useExternalLinkLightboxService();

        const activeItemId = navigationStore.activeInPageNavigationItemId;
        const currentUrl = getCurrentURL();
        const isActive = Boolean(id === activeItemId);

        React.useEffect(() => {
            const formattedItemUrl = url.split('?')[0];

            if (currentUrl?.includes(formattedItemUrl)) {
                navigationStore.setActiveInPageNavigationItem(url);
            }
            // navigationStore is not a required dependency here
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentUrl, url, activeItemId]);

        function handleInPageNavigationLinkClick(
            e: React.MouseEvent<HTMLElement>
        ): void {
            trackingService.trackNavigationBarLinkClick(
                processContentId(url),
                {
                    name: title,
                    url
                },
                trackingData
            );

            // otherwise perform navigation via router
            if (routerService.isAppLink(url)) {
                e.preventDefault();
                // lock scrolling event so that in page navigation stays visible - used for inPageNavigation v1 only
                navigationStore.withScrollLock(() => {
                    navigationStore.setActiveInPageNavigationItem(id);
                });

                routerService.navigate(url);
            } else if (externalLinkLightboxService.shouldOpenLightbox(url)) {
                e.preventDefault();
                // open lightbox if an external link
                externalLinkLightboxService.openLightbox(
                    url,
                    REDIRECT_LINK_TARGET
                );
            }
        }
        const isExternalLinkDropdown = isLinkExternal(url);

        const linkTitleDropdown = isExternalLinkDropdown ? (
            <StyledLinkIconWrapper>
                {title}
                {<ExternalLinkIndicator />}
            </StyledLinkIconWrapper>
        ) : (
            <>{title}</>
        );

        return (
            <InPageNavBarLink
                href={url}
                isActive={isActive}
                onClick={handleInPageNavigationLinkClick}
            >
                {linkTitleDropdown}
            </InPageNavBarLink>
        );
    }
);
InPageNavigationLink.displayName = 'InPageNavigationLink';
export {InPageNavigationLink};
