import {
    Disclaimer as HubDisclaimer,
    LegallyGroupedDisclaimers as HubGroupedDisclaimers
} from '@volkswagen-onehub/disclaimer-manager';

import {
    DisclaimerWithReference,
    GroupedDisclaimersWithReference,
    RegisteredDisclaimer
} from './DisclaimerStore';

export const mapDisclaimer = (d: HubDisclaimer): DisclaimerWithReference => {
    const type = d.type === 'global' ? 'T1_GLOBAL' : 'T2_PAGE_BASED';

    return {
        displayType: type,
        text: d.text,
        reference: d.reference,
        legalEntity: d.legalEntity,
        namedReference: d.namedReference
    };
};

export const mapLegallyReferences = (
    d: HubGroupedDisclaimers
): GroupedDisclaimersWithReference => {
    return {
        legalEntity: d.legalEntity,
        legalEntityLabel: d.legalEntityLabel,
        globalDisclaimers: d.disclaimers.global.map(mapDisclaimer),
        referenceDisclaimers: d.disclaimers.reference.map(mapDisclaimer)
    };
};

export const areDisclaimersEqual = (
    a: RegisteredDisclaimer,
    b: RegisteredDisclaimer
): boolean => {
    return (
        a.reference === b.reference &&
        a.displayType === b.displayType &&
        a.text === b.text
    );
};

export const areDisclaimerListsEqual = (
    originalList: RegisteredDisclaimer[],
    listToCompare: RegisteredDisclaimer[]
): boolean => {
    if (!originalList?.length && !listToCompare?.length) {
        return true;
    }

    const equalItemsList =
        originalList?.length === listToCompare?.length &&
        originalList.filter((originalItem: RegisteredDisclaimer) =>
            listToCompare.find((itemToCompare: RegisteredDisclaimer) =>
                areDisclaimersEqual(originalItem, itemToCompare)
            )
        );

    return equalItemsList && originalList.length === equalItemsList.length;
};

export const compareDisclaimerReferences = (
    a: RegisteredDisclaimer,
    b: RegisteredDisclaimer
): number => {
    if (a.reference && b.reference) {
        const parsedA = parseInt(a.reference, 10);
        const parsedB = parseInt(b.reference, 10);

        if (!isNaN(parsedA) && !isNaN(parsedB)) {
            return parsedA - parsedB;
        }

        if (isNaN(parsedA) && !isNaN(parsedB)) {
            return -1;
        }

        if (!isNaN(parsedA) && isNaN(parsedB)) {
            return 1;
        }

        return a.reference.localeCompare(a.reference);
    }

    const checkB = b.reference ? 1 : 0;

    return a.reference ? -1 : checkB;
};
