import * as React from 'react';

import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {ResponsiveMediaRenderer} from '../../../components/ResponsiveMediaRenderer';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {CopyItem} from './CopyItem';

import {RevealItemModel} from '../../../../generated/core';
import {UniversalDisclaimerContainer} from '../../../components/disclaimers/UniversalDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {CountdownState} from '../../../context/tracking/TrackingService';
import {RevealItem as RevealItemLSG} from '../../../d6/components/premiere/reveal';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {elseUndefined} from '../../../utils/elseUndefined';
import {InternalParsys, ParsysProps, ParsysType} from '../../structure/Parsys';
import {
    PremiereButtonElements,
    premiereResponsiveMediaConfig
} from '../sections/PremiereSection';
import {StyledDisclaimers} from '../../../d6/components/premiere/styles';

export const RevealButtonsParsys = MapTo<ParsysProps>(
    'vwa-ngw18/components/editorial/items/revealItem/buttonsParsys',
    InternalParsys
);
const BUTTONS_PARSYS_PATH = 'buttonsParsys';

const InternalRevealItem = (props: RevealItemModel): JSX.Element => {
    const {isCutout} = props;
    const inEditor = C.isInEditor();
    const mediaElements = getChildItems(props.cqItems['media']);
    const isVideo = mediaElements[0].model?.coverImage;
    const buttonElements = getChildItems(props.cqItems[BUTTONS_PARSYS_PATH]);
    const hasButtons = inEditor || buttonElements.length > 0;

    const wrappedMediaElement = (
        <ResponsiveMediaRenderer configs={premiereResponsiveMediaConfig}>
            <MediaElement
                path="media"
                containerProps={{matchParent: true}}
                useParentAspectRatio={!isVideo}
                responsiveMediaConfig={premiereResponsiveMediaConfig}
                countdownState={CountdownState.POST}
            />
        </ResponsiveMediaRenderer>
    );

    const revealButtonsParsys = elseUndefined(
        hasButtons,
        <RevealButtonsParsys
            key="buttons"
            path={BUTTONS_PARSYS_PATH}
            parsysType={ParsysType.INLINE_BUTTON_PARSYS}
        />
    );

    return (
        <AuthoringWrapper
            title="Reveal Item"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <RevealItemLSG
                    isCutout={isCutout}
                    media={wrappedMediaElement}
                    heading={
                        <HeadingElement path="heading" style="H2" order="H2" />
                    }
                    copy={<CopyItem path="copy" />}
                    buttons={
                        inEditor ? (
                            revealButtonsParsys
                        ) : (
                            <PremiereButtonElements
                                buttons={buttonElements}
                                countdownState={CountdownState.POST}
                            />
                        )
                    }
                    disclaimers={
                        <UniversalDisclaimerContainer
                            customStylingWrapper={StyledDisclaimers}
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    }
                />
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
};

export const RevealItem = MapTo(
    'vwa-ngw18/components/editorial/items/revealItem',
    InternalRevealItem
);
