import {FieldMetadata} from './annotations';

export class ReflectionUtils {
    public static callPostConstruct<T extends object>(object: T): void {
        this.callMethod(object, 'postConstructs');
    }

    public static callMethod<T extends object>(
        target: T,
        metaField: string
    ): void {
        const metaData: FieldMetadata<T>[] =
            Reflect.getMetadata(metaField, target) || [];
        metaData.forEach((meta: FieldMetadata<T>) => {
            const tObject: any = target;
            const postConstruct: () => void | number = tObject[meta.field];
            if (typeof postConstruct === 'function') {
                postConstruct.bind(target)();
            } else {
                console.log(
                    meta.field + ' is not a function on type ' + meta.type
                );
            }
        }, this);
    }

    public static getAllFieldMetaData<T>(
        key: string,
        a: Function | object | number
    ): FieldMetadata<T>[] {
        if (typeof a === 'function') {
            return this.getAllFieldMetaDataFromPrototype(key, a.prototype);
        } else if (typeof a === 'object') {
            return this.getAllFieldMetaDataFromPrototype(
                key,
                Object.getPrototypeOf(a)
            );
        }

        return [];
    }

    private static getAllFieldMetaDataFromPrototype<T>(
        key: string,
        prototype: object
    ): FieldMetadata<T>[] {
        const own: FieldMetadata<T>[] =
            Reflect.getMetadata(key, prototype) || [];
        const parent: object = Object.getPrototypeOf(prototype);
        if (parent && parent !== prototype) {
            return own.concat(
                this.getAllFieldMetaDataFromPrototype(key, parent) || []
            );
        }

        return own;
    }
}
