import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {TwoColumnsItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {SectionWrapper} from '../sections/SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const StyledCol1 = styled.div``;
const StyledCol2 = styled.div``;

const gridColumnsByLayout = {
    SMALL_MEDIUM: {
        col1: '1 / 9',
        col2: '11 / 25'
    },
    MEDIUM_SMALL: {
        col1: '1 / 15',
        col2: '17 / 25'
    },
    SMALL_SMALL: {
        col1: '1 / 12',
        col2: '14 / 25'
    }
};

export interface StyledGridProps {
    readonly layout: string;
    readonly col1: string;
    readonly col2: string;
}
const StyledGrid = styled.div<StyledGridProps>`
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    row-gap: ${props => props.theme.size.dynamic0250};

    & ${StyledCol1}, & ${StyledCol2} {
        grid-column: 1 / 25;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        & ${StyledCol1} {
            grid-column: ${props => props.col1};
        }

        & ${StyledCol2} {
            grid-column: ${props => props.col2};
        }
    }
`;

// the sizes attribute for media components in the left column
const mediaSizesLeft = {
    SMALL_MEDIUM: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 25vw, 90vw`,
    MEDIUM_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 50vw, 90vw`,
    SMALL_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 38vw, 90vw`
};

// the sizes attribute for media components in the right column
const mediaSizesRight = {
    SMALL_MEDIUM: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 50vw, 90vw`,
    MEDIUM_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 25vw, 90vw`,
    SMALL_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 38vw, 90vw`
};

/**
 * I140 Two Columns Item
 */
function InternalTwoColumnsItem(props: TwoColumnsItemModel): JSX.Element {
    const {
        anchorId,
        mboxId,
        tagTypeBroken,
        contentLabels,
        contentName,
        layout,
        contentId: sectionId
    } = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="I140 Two Columns Item"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <StyledGrid
                        layout={layout}
                        col1={gridColumnsByLayout[layout].col1}
                        col2={gridColumnsByLayout[layout].col2}
                    >
                        <StyledCol1 data-testid="twoColumnsItemCol1">
                            <CyAttributeAppender name="twoColumnsSection1">
                                <MediaContext.Provider
                                    value={{
                                        sizes: mediaSizesLeft[layout]
                                    }}
                                >
                                    <Parsys
                                        path="twoColumnsItemParsys1"
                                        parsysType={ParsysType.ITEM_PARSYS}
                                    />
                                </MediaContext.Provider>
                            </CyAttributeAppender>
                        </StyledCol1>
                        <StyledCol2 data-testid="twoColumnsItemCol2">
                            <CyAttributeAppender name="twoColumnsSection2">
                                <MediaContext.Provider
                                    value={{
                                        sizes: mediaSizesRight[layout]
                                    }}
                                >
                                    <Parsys
                                        path="twoColumnsItemParsys2"
                                        parsysType={ParsysType.ITEM_PARSYS}
                                    />
                                </MediaContext.Provider>
                            </CyAttributeAppender>
                        </StyledCol2>
                    </StyledGrid>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const TwoColumnsItem = MapTo(
    'vwa-ngw18/components/editorial/items/twoColumnsItem',
    InternalTwoColumnsItem
);
