import * as React from 'react';
import {PreBookingListElementModel as IconBulletListModel} from '../../../../generated/core';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {getMappedIcon} from '../../../utils/getMappedIcon';
import {RichtextSimpleElement} from './RichtextElement';
import {getEndDirection} from '../../../d6/components/helpers';
import {styled} from '@volkswagen-onehub/components-core';

const StyledWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

const IconWrapper = styled.div`
        margin-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.dynamic0050};
        margin-inline-end: ${props => props.theme.size.dynamic0050};

    svg {
        color: ${props => props.theme.text.color.primary};
    }
`;

export function InternalIconBulletListElement(
    props: IconBulletListModel
): JSX.Element {
    const {icon} = props;

    const WrappedIcon = (
        <IconWrapper>
            {getMappedIcon(icon, 'default', {ariaHidden: true})}
        </IconWrapper>
    );

    const text = <RichtextSimpleElement path="text" />;

    return (
        <StyledWrapper>
            {WrappedIcon}
            {text}
        </StyledWrapper>
    );
}

export const IconBulletListElement = MapTo(
    'vwa-ngw18/components/editorial/elements/preBookingListElement',
    InternalIconBulletListElement
);
