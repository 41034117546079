import {
    StyledSubactionsNextBestActionButton,
    StyledSubActionWrapper
} from './styles';
import * as React from 'react';
import {StyledSubActionWrapperProps} from './types';

export const SubactionsButtons = (props: StyledSubActionWrapperProps) => {
    const {subActions, show, showStylesForLandscape} = props;
    return (
        <StyledSubActionWrapper
            isHidden={!show}
            showStylesForLandscape={showStylesForLandscape}
            data-testid="styled-sub-action-wrapper"
        >
            {subActions.map((actionItem: JSX.Element, actionIndex: number) => {
                const endIndex = subActions.length - actionIndex;

                return (
                    <StyledSubactionsNextBestActionButton
                        show={show}
                        endIndex={endIndex}
                        aria-hidden={!show}
                        key={actionIndex}
                    >
                        {actionItem}
                    </StyledSubactionsNextBestActionButton>
                );
            })}
        </StyledSubActionWrapper>
    );
};
