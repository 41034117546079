import {
    useFeatureToggles,
    useLogger,
    useNavigationService,
    usePersonalizationStore
} from '../../../../context';
import {isSectionPassingThresholds} from './enterViewportTracking';
import {useIntersectionTrigger} from './intersectionTrigger';

export const useSectionVisitedTracker = (
    ref: React.RefObject<HTMLDivElement>,
    ...sectionIds: string[]
) => {
    const enableSwapSectionPersonalization = useFeatureToggles()
        .enableSwapSectionPersonalization;
    const logger = useLogger().personalization;
    const initialRender = useNavigationService().state.initialRender;
    const personalizationStore = usePersonalizationStore();
    useIntersectionTrigger(
        ref,
        (_entries: IntersectionObserverEntry[]) => {
            logger.debug('visited', sectionIds.join(', '));
            personalizationStore.onVisited(...sectionIds);
        },
        entry => isSectionPassingThresholds(entry, 0.1, 0.1),
        200,
        0,
        !!ref && initialRender && enableSwapSectionPersonalization
    );
};
