import {HistoryServiceV2, RootHistory} from '@feature-hub/history-service';
import {
    SharedMyDealerConfigurationFeatureService,
    myDealerConfigurationFeatureServiceDefinition,
    MyDealerConfigurationOptions,
    DealerConfigurationV1,
    MyDealerConfigurationOptionsV2,
    DealerType,
    DealerConfig,
    MyDealerAsyncConfig
} from '@volkswagen-onehub/mydealer-configuration-service';
import {
    MydealerAsyncConfigServlet,
    MydealerConfiguration,
    MydealerServiceOptions,
    SpaGlobalConfig
} from '../../../generated/core';
import {Registry} from '../di/Registry';
import {HistoryServiceId} from './createHistoryService';
import {ServiceRegistration} from './ServiceRegistration';

interface DealerMap {
    [index: string]: MydealerConfiguration;
}

export function createMydealerConfigurationService(
    options: MydealerServiceOptions,
    initialPageOwner: MydealerConfiguration | undefined,
    registry: Registry,
    globalConfig: SpaGlobalConfig
): ServiceRegistration<SharedMyDealerConfigurationFeatureService> {
    const dealerMap = options && options.dealerMap ? options.dealerMap : {};
    const dealers = createDealerMap(dealerMap, registry, options);

    const fsOptions: MyDealerConfigurationOptions = {
        preview: options.preview,
        previewRequestParam: options.previewRequestParam,

        getDealerPageOwner: getDealerPageOwnerFnFactory(dealerMap),
        dealers
    };

    const dealerBasePath = options.dealerBasePath;
    const mydealerConfigUrl = globalConfig.integratorTemplateModel
        ? globalConfig.siteUrl + options.mydealerConfigIntegratorUrl
        : options.mydealerConfigUrl || '';

    const onehubHost = globalConfig.integratorTemplateModel
        ? globalConfig.siteUrl
        : undefined;

    const fsOptionsV2: MyDealerConfigurationOptionsV2 = {
        onehubHost,
        featureToggles: {
            enableZeroState: Boolean(
                globalConfig.featureToggles.enableMyDealerZeroState &&
                    options?.enableZeroState
            ),
            enablePersistDealerState: Boolean(options.enablePersistDealerState),
            enableMyDealerCookieNotification: Boolean(
                globalConfig.featureToggles.enableMyDealerCookieNotification &&
                    options.enableMyDealerCookieNotification
            )
        },
        departmentFallbackOrder: options.departmentFallbackOrder ?? [],
        serverTime: options.serverTime,
        preview: options.preview,
        previewRequestParam: options.previewRequestParam,
        dealerBasePath: dealerBasePath,
        fetchGlobalConfig: () => {
            if (mydealerConfigUrl) {
                return fetch(mydealerConfigUrl)
                    .then(d => d.json())
                    .then(getGlobalConfig());
            } else {
                // mydealer not active return empty map of dealers
                return Promise.resolve({dealerMap: {}});
            }
        },
        pageIdToSubPagePath: options?.pageIdToSubPagePath || {},
        initialPageOwner: initialPageOwner
            ? {
                  dealerId: initialPageOwner.id,
                  config: initialPageOwner as DealerConfig
              }
            : undefined
    };
    return {
        definition: myDealerConfigurationFeatureServiceDefinition({
            ...fsOptions,
            ...fsOptionsV2
        }),
        service: {
            typeName: 'MydealerConfigurationService',
            version: '^2.0.0'
        }
    };
}

function createNavigation(pathname: string, rootHistory: RootHistory) {
    const location = {pathname};
    if (!pathname.startsWith('/')) {
        // this is the integrator template
        return {
            createHref: () => rootHistory.createHref(location),
            push: () => (window.location.href = pathname),
            replace: () => (window.location.href = pathname)
        };
    }
    return {
        createHref: () => rootHistory.createHref(location),
        push: () => rootHistory.push(location),
        replace: () => rootHistory.replace(location)
    };
}

function getDealerPageOwnerFnFactory(
    dealerMap: DealerMap
): (pathname: string) => string | undefined {
    return (rawPathname: string) => {
        const pathname = rawPathname.split('.html')[0];
        const matchingDealers = Object.values(dealerMap).filter(dealer => {
            const homePath = dealer.homePath.split('.html')[0];

            return pathname.startsWith(homePath);
        });
        if (matchingDealers.length === 0) {
            return undefined;
        }
        if (matchingDealers.length === 1) {
            return matchingDealers[0].id;
        }
        console.warn('multiple matching dealers for path %s found', pathname);

        return undefined;
    };
}

function createDealerMap(
    dealerMap: DealerMap,
    registry: Registry,
    options: MydealerServiceOptions
) {
    const dealers: {[id: string]: DealerConfigurationV1} = {};
    Object.values(dealerMap).forEach(dealer => {
        dealers[dealer.id] = {
            createHomeNavigation: (subpageId?: string) => {
                const historyService: HistoryServiceV2 = registry.getSingleton(
                    HistoryServiceId
                );
                const rootHistory = historyService.rootHistory;
                const pathname = buildPathname(subpageId, dealer, options);
                return createNavigation(pathname, rootHistory);
            },
            id: dealer.id,
            displayName: dealer.displayName,
            headlessPath: dealer.headlessPath,
            dealerType: (dealer.dealerType || 'FULLSTACK') as DealerType,
            legalName: dealer.legalName,
            topbarName: dealer.topbarName,
            firstLevelNavigationName: dealer.firstLevelNavigationName,
            trackingDisabled: dealer.trackingDisabled
        };
    });
    return dealers;
}

function buildPathname(
    subpageId: string | undefined,
    dealer: MydealerConfiguration,
    options: MydealerServiceOptions
) {
    if (subpageId && options.pageIdToSubPagePath[subpageId]) {
        return (
            dealer.appendableHomePath + options.pageIdToSubPagePath[subpageId]
        );
    }

    return dealer.homePath;
}

function getGlobalConfig(): (value: any) => MyDealerAsyncConfig {
    return (value: MydealerAsyncConfigServlet) =>
        value.mydealerServiceOptions as MyDealerAsyncConfig;
}
