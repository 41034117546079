import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Text,
    TextTag,
    designTokens
} from '@volkswagen-onehub/components-core';
import {TableItemModel, TableSectionModel} from '../../../../../generated/core';
import {AccordionStateProvider} from '../../../../components/AccordionStateProvider';
import {ContainerItem} from '../../../../infrastructure/ContainerExporter';
import * as EmptyCellElement from '../../elements/table/cells/EmptyCellElement';
import * as MergeColumnCellElement from '../../elements/table/cells/MergeColumnCellElement';
import * as MergeRowCellElement from '../../elements/table/cells/MergeRowCellElement';
import {Accordion} from './Accordion';
import {
    getCellContent,
    getCellType,
    getCells,
    getCqPath,
    getNumberOfColumns,
    getRows
} from './utils';

const StyledMobileTable = styled.div`
    display: none;
    @media (max-width: ${Breakpoints.b560}px) {
        display: block;
    }
    padding: 0 ${props => props.theme.size.grid001};
`;
const StyledItemWrapper = styled.div<{isMerged: boolean; isSumItem?: boolean}>`
    border-bottom: ${props => (props.isMerged ? 0 : 1)}px solid
        ${props =>
            props.isSumItem
                ? designTokens.color['black-000']
                : designTokens.color['grey-100']};
    padding: ${props => props.theme.size.static150}
        ${props => props.theme.size.grid001}
        ${props => (props.isMerged ? 0 : props.theme.size.static350)};

    &:last-child {
        padding-bottom: ${props => props.theme.size.dynamic0200};
        border-bottom: 0;
    }
`;

enum tableLayout {
    headerCategory = 'HEADER_CATEGORY',
    category = 'CATEGORY',
    header = 'HEADER',
    headerCategorySum = 'HEADER_CATEGORY_SUM'
}

export interface GetCell {
    cell: ContainerItem;
    rows: ContainerItem[];
    rowIndex: number;
    columnIndex: number;
    neighbouringCells: ContainerItem[];
    props: TableSectionModel | TableItemModel;
}

export const getCell = ({
    cell,
    rows,
    rowIndex,
    columnIndex,
    neighbouringCells,
    props
}: GetCell) => {
    let content = getCellContent(cell, getCqPath(props, rows[rowIndex], cell));

    if (!content && getCellType(cell) === MergeRowCellElement.RESOURCE_TYPE) {
        let mergeIndex = rowIndex - 1;
        while (rows[mergeIndex] && !content) {
            const rowCells = getCells(rows[mergeIndex]);
            const mergedCell = rowCells[columnIndex];
            if (!mergedCell) {
                break;
            }
            content = getCellContent(
                mergedCell,
                getCqPath(props, rows[mergeIndex], mergedCell)
            );
            mergeIndex--;
        }
    } else if (
        !content &&
        getCellType(cell) === MergeColumnCellElement.RESOURCE_TYPE
    ) {
        let mergeIndex = columnIndex - 1;
        while (neighbouringCells[mergeIndex] && !content) {
            const mergedCell = neighbouringCells[mergeIndex];
            content = getCellContent(
                mergedCell,
                getCqPath(props, rows[rowIndex], mergedCell)
            );
            mergeIndex--;
        }
    }

    return content;
};

interface GetRowPriorityCellContent {
    cell: ContainerItem;
    rows: ContainerItem[];
    columnIndex: number;
    rowIndex: number;
    neighbouringCells: ContainerItem[];
    props: TableSectionModel | TableItemModel;
}

const getRowPriorityCellContent = ({
    cell,
    rows,
    columnIndex,
    rowIndex,
    neighbouringCells,
    props
}: GetRowPriorityCellContent) => {
    const nextCell = neighbouringCells[columnIndex + 1];
    if (
        nextCell &&
        getCellType(nextCell) === MergeColumnCellElement.RESOURCE_TYPE
    ) {
        return null;
    }

    return getCell({
        cell,
        rows,
        rowIndex,
        columnIndex,
        neighbouringCells,
        props
    });
};

interface RenderRowsPriorityCells {
    cells: ContainerItem[];
    rowIndex: number;
    props: TableSectionModel | TableItemModel;
}

const renderRowsPriorityCells = ({
    cells,
    rowIndex,
    props
}: RenderRowsPriorityCells) => {
    const {layout} = props;
    const rows = getRows(props);
    const headingCells =
        layout === tableLayout.headerCategory ? getCells(rows[0]) : [];

    return cells.map((cell, cellIndex) => {
        const isMerged = cells[cellIndex + 1]
            ? getCellType(cells[cellIndex + 1]) ===
              MergeColumnCellElement.RESOURCE_TYPE
            : false;
        return cellIndex === 0 ||
            getCellType(cell) === EmptyCellElement.RESOURCE_TYPE ? null : (
            <StyledItemWrapper key={cell.key} isMerged={isMerged}>
                {layout === tableLayout.headerCategory && (
                    <Text bold tag={TextTag.div}>
                        {getCellContent(
                            headingCells[cellIndex],
                            getCqPath(props, rows[0], headingCells[cellIndex])
                        )}
                    </Text>
                )}
                {getRowPriorityCellContent({
                    cell,
                    rows,
                    columnIndex: cellIndex,
                    rowIndex,
                    neighbouringCells: cells,
                    props
                })}
            </StyledItemWrapper>
        );
    });
};

const renderRowsPriority = (props: TableSectionModel | TableItemModel) => {
    const {contentId, layout} = props;
    const rows = getRows(props);

    return rows.map((row, rowIndex) => {
        if (layout === tableLayout.headerCategory && rowIndex === 0) {
            return null;
        }
        const cells = getCells(row);
        const heading = cells[0];
        const headingCells =
            layout === tableLayout.headerCategory ? getCells(rows[0]) : [];

        if (
            (cells.length !== headingCells.length &&
                layout === tableLayout.headerCategory) ||
            cells.length === 0
        ) {
            console.error(
                'Invalid table configuration. Each row should have same number of columns.'
            );

            return null;
        }

        return (
            <AccordionStateProvider
                defaultIsOpen={
                    rowIndex === 0 ||
                    (layout === tableLayout.headerCategory && rowIndex === 1)
                }
                key={`${row.key}_${heading.key}`}
                contentId={contentId}
            >
                {(isOpen, onClick) => (
                    <Accordion
                        id={heading.key}
                        isOpen={isOpen}
                        onClick={onClick}
                        expandedItemDisabled={false}
                        headline={
                            <Text bold>
                                {getCellContent(
                                    heading,
                                    getCqPath(props, row, heading)
                                )}
                            </Text>
                        }
                    >
                        {renderRowsPriorityCells({cells, rowIndex, props})}
                    </Accordion>
                )}
            </AccordionStateProvider>
        );
    });
};

interface CreateColPriorityCellNode {
    rows: ContainerItem[];
    rowIndex: number;
    columnIndex: number;
    props: TableSectionModel | TableItemModel;
}

const createColPriorityCellNode = ({
    rows,
    rowIndex,
    columnIndex,
    props
}: CreateColPriorityCellNode) => {
    const {layout} = props;
    const row = rows[rowIndex];
    const rowCells = getCells(row);
    const cell = rowCells[columnIndex];

    if (!cell) {
        console.error(
            'Invalid table configuration. Each row should have same number of columns.'
        );

        return null;
    }

    const headerCell = getCells(row)[0];
    const nextRow = rows[rowIndex + 1];
    let isMerged = false;
    let isSumRow = false;

    if (nextRow) {
        const nextCell = getCells(nextRow)[columnIndex];
        isMerged =
            nextCell &&
            getCellType(nextCell) === MergeRowCellElement.RESOURCE_TYPE;
    } else if (layout === tableLayout.headerCategorySum) {
        isSumRow = true;
    }

    if (getCellType(cell) === EmptyCellElement.RESOURCE_TYPE) {
        return null;
    }

    const colPriorityCellContent = getColPriorityCellContent({
        cell,
        rows,
        columnIndex,
        rowIndex,
        neighbouringCells: rowCells,
        props
    });

    return (
        <StyledItemWrapper
            key={cell.key}
            isMerged={isMerged}
            isSumItem={
                rowIndex === rows.length - 2 &&
                layout === tableLayout.headerCategorySum
            }
        >
            {layout !== tableLayout.header && (
                <Text bold tag={TextTag.div}>
                    {getCellContent(
                        headerCell,
                        getCqPath(props, row, headerCell)
                    )}
                </Text>
            )}
            {isSumRow ? (
                <Text bold>{colPriorityCellContent}</Text>
            ) : (
                colPriorityCellContent
            )}
        </StyledItemWrapper>
    );
};

interface GetColPriorityCellContent {
    cell: ContainerItem;
    rows: ContainerItem[];
    columnIndex: number;
    rowIndex: number;
    neighbouringCells: ContainerItem[];
    props: TableSectionModel | TableItemModel;
}

const getColPriorityCellContent = ({
    cell,
    rows,
    columnIndex,
    rowIndex,
    neighbouringCells,
    props
}: GetColPriorityCellContent) => {
    const nextRow = rows[rowIndex + 1];
    if (nextRow) {
        const nextRowCells = getCells(nextRow);
        const nextCell = nextRowCells[columnIndex];
        if (
            nextCell &&
            getCellType(nextCell) === MergeRowCellElement.RESOURCE_TYPE
        ) {
            return null;
        }
    }

    return getCell({
        cell,
        rows,
        rowIndex,
        columnIndex,
        neighbouringCells,
        props
    });
};

const renderColPriority = (props: TableSectionModel | TableItemModel) => {
    const {contentId, layout} = props;
    const cols: React.ReactNode[] = [];
    const rows = getRows(props);
    const columnsCount = getNumberOfColumns(rows);

    for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
        if (layout !== tableLayout.header && columnIndex === 0) {
            continue;
        }
        const itemHeading = getCells(rows[0])[columnIndex];
        const columnContent: React.ReactNode[] = [];

        for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
            const node = createColPriorityCellNode({
                rows,
                rowIndex,
                columnIndex,
                props
            });

            columnContent.push(node);
        }

        const colItem = (
            <AccordionStateProvider
                defaultIsOpen={
                    (layout !== tableLayout.header && columnIndex === 1) ||
                    (layout === tableLayout.header && columnIndex === 0)
                }
                key={`${rows[0].key}_${itemHeading.key}`}
                contentId={contentId}
            >
                {(isOpen, onClick) => (
                    <Accordion
                        headline={
                            <Text bold>
                                {getColPriorityCellContent({
                                    cell: itemHeading,
                                    rows,
                                    columnIndex,
                                    rowIndex: 0,
                                    neighbouringCells: getCells(rows[0]),
                                    props
                                })}
                            </Text>
                        }
                        id={itemHeading.key}
                        isOpen={isOpen}
                        onClick={onClick}
                        expandedItemDisabled={false}
                    >
                        {columnContent}
                    </Accordion>
                )}
            </AccordionStateProvider>
        );

        cols.push(colItem);
    }

    return cols;
};

export const MobileTable: React.FunctionComponent<
    TableSectionModel | TableItemModel
> = props => {
    const {layout, priority, caption} = props;

    return (
        <StyledMobileTable title={caption}>
            {layout === tableLayout.category ||
            (layout === tableLayout.headerCategory && priority === 'ROWS')
                ? renderRowsPriority(props)
                : renderColPriority(props)}
        </StyledMobileTable>
    );
};
