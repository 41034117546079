import * as React from 'react';

import {
    InteractionLayerSizeV2,
    LayerHandleV2,
    LayerManagerV27,
    ShimBackgroundTypeV2
} from '@volkswagen-onehub/layer-manager';
import {observable} from 'mobx';
import {
    FlyoutLoggedInModel,
    FlyoutLoggedOutModel,
    FlyoutLoggedOutMultipleCustomerTypesModel,
    LoginContentModel,
    SpaAsyncConfig,
    SpaGlobalConfig
} from '../../../generated/core';
import {
    LayerButtonPosition,
    LayerCloseButtonV2
} from '../../components/LayerCloseButton';
import {LoginFlyout} from '../../components/LoginFlyout';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {ModelStore} from '../model/ModelStore';
import {TrackingService} from '../tracking/TrackingService';
import {closeButtonContext} from '../../components/LoginFlyoutV2';
import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {
    UtilityNavigation,
    UtilityNavigationCloseButton,
    UtilityNavigationLayer,
    UtilityNavigationProps
} from '../../modules/structure/login/UtilityNavigation';
import {RouterService} from '../route/RouterService';

export type CloseButtonProps = Readonly<{
    btnCloseLabel?: string;
    onClick(): void;
}>;

export const LoginFlyoutCloseButton: React.FunctionComponent<CloseButtonProps> = ({
    btnCloseLabel,
    onClick
}): JSX.Element => (
    <LayerCloseButtonV2
        key="close"
        onClick={onClick}
        position={LayerButtonPosition.FLYOUT_V2}
        btnCloseLabel={btnCloseLabel}
    />
);

@singleton('LoginFlyoutService')
export class LoginFlyoutService {
    public get isOpened(): boolean {
        return Boolean(this.flyoutLayer && !this.flyoutLayer.isClosed());
    }

    @observable
    public flyoutLoggedInPath!: string;

    @observable
    public flyoutLoggedOutPath!: string;

    @observable
    public flyoutLoggedOutMultipleCustomerTypesPath!: string;

    @observable
    public flyoutLoggedInCommercialPath!: string;

    @inject() private readonly layerManager!: LayerManagerV27;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;
    @inject() private spaAsyncConfig!: SpaAsyncConfig;
    @inject() private trackingService!: TrackingService;
    @inject() private loginContentModel!: LoginContentModel;
    @inject() private readonly modelStore!: ModelStore;
    @inject() private routerService!: RouterService;

    private flyoutLayer:
        | LayerHandleV2<void>
        | LayerHandleV2<UtilityNavigationProps>
        | null = null;

    @postConstruct()
    public initialize(): void {
        const loggedInModel: FlyoutLoggedInModel = this.loginContentModel
            .flyoutLoggedIn;
        this.flyoutLoggedInPath = this.modelStore.insertGlobalContent(
            'flyoutLoggedIn',
            (loggedInModel as unknown) as TransferModel
        );
        const loggedOutModel: FlyoutLoggedOutModel = this.loginContentModel
            .flyoutLoggedOut;
        this.flyoutLoggedOutPath = this.modelStore.insertGlobalContent(
            'flyoutLoggedOut',
            (loggedOutModel as unknown) as TransferModel
        );

        const loggedOutMultipleCustomerTypesModel: FlyoutLoggedOutMultipleCustomerTypesModel = this
            .loginContentModel.flyoutLoggedOutMultipleCustomerTypes;
        this.flyoutLoggedOutMultipleCustomerTypesPath = this.modelStore.insertGlobalContent(
            'flyoutLoggedOutMultipleCustomerTypes',
            (loggedOutMultipleCustomerTypesModel as unknown) as TransferModel
        );

        const loggedInCommercialModel: FlyoutLoggedInModel = this
            .loginContentModel.flyoutLoggedInCommercial;
        this.flyoutLoggedInCommercialPath = this.modelStore.insertGlobalContent(
            'flyoutLoggedInCommercial',
            (loggedInCommercialModel as unknown) as TransferModel
        );
    }

    public openFlyout(): void {
        if (this.spaAsyncConfig.utilityNavigationModel) {
            const closeButton = (
                <UtilityNavigationCloseButton
                    btnCloseLabel={
                        this.loginContentModel.flyoutLoggedOut.btnCloseLabel
                    }
                    onClick={() => this.closeFlyout(false)}
                />
            );
            this.flyoutLayer = this.layerManager.openCustomLayer(
                state => <UtilityNavigation {...state} />,
                {closeButton},
                {
                    id: 'vwa-cms-login-flyout',
                    userCloseable: true,
                    onClose: () => this.closeFlyout(true),
                    shimBackgroundType: ShimBackgroundTypeV2.OPAQUE,
                    animationTimeout: 0,
                    layerComponent: UtilityNavigationLayer
                }
            );
            this.routerService.onNavigate(() => {
                this.closeFlyout(false);
            });
            this.trackingService.trackLoginFlyoutOpen();
        }
        if (!this.spaGlobalConfig.loginModel.enabled) {
            return;
        }

        const renderFlyout = this.getRenderFn();

        if (!this.flyoutLayer) {
            this.flyoutLayer = this.layerManager.openInteractionLayer(
                renderFlyout,
                undefined,
                {
                    id: 'vwa-cms-login-flyout',
                    size: InteractionLayerSizeV2.C,
                    userCloseable: true,
                    onClose: () => this.closeFlyout(true),
                    shimBackgroundType: ShimBackgroundTypeV2.OPAQUE
                }
            );

            this.trackingService.trackLoginFlyoutOpen();
        }
    }

    public readonly closeFlyout = (shouldTrack: boolean): void => {
        if (this.flyoutLayer) {
            this.flyoutLayer.close();

            if (shouldTrack) {
                this.trackingService.trackLoginFlyoutClose();
            }
        }

        this.flyoutLayer = null;
    };

    private readonly getRenderFn = () => {
        const closeButton = (
            <LoginFlyoutCloseButton
                btnCloseLabel={
                    this.loginContentModel.flyoutLoggedOut.btnCloseLabel
                }
                onClick={() => this.closeFlyout(false)}
            />
        );

        return () => (
            <closeButtonContext.Provider value={closeButton}>
                <LoginFlyout />
            </closeButtonContext.Provider>
        );
    };
}
