import {
    RootLocationDescriptorObject,
    createSearchParams
} from '@feature-hub/history-service';
import {createPath, parsePath, parseSuffix} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';
import {URLSearchParams} from '../../../utils/UrlSearchParams';

export function getPrimaryConsumerPath(
    rootLocation: RootLocationDescriptorObject,
    consumerPathsQueryParamName: string,
    secondaries: {[consumerId: string]: string}
): string {
    const searchParams = createSearchParams(rootLocation);
    const path = parsePath(rootLocation.pathname || '/');
    const primaryParams = new URLSearchParams();
    const secondarySuffixes = Object.keys(secondaries).map(
        key => '-' + secondaries[key]
    );
    searchParams.forEach((value, key) => {
        const secondaryParam = secondarySuffixes.some(suffix =>
            key.endsWith(suffix)
        );
        if (!secondaryParam && key !== consumerPathsQueryParamName) {
            primaryParams.append(key, value);
        }
    });

    let newPath = path.base;
    if (path.suffix) {
        const newSuffix = parseSuffix(path.suffix).default;

        newPath = createPath({
            base: newPath,
            suffix: newSuffix
        });
    }

    const search = primaryParams.toString();

    return history.createPath({
        pathname: newPath,
        search,
        hash: rootLocation.hash
    });
}
