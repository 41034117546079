import {
    defineIconService,
    SharedIconService
} from '@volkswagen-onehub/icon-service';
import {SpaGlobalConfig} from '../../../generated/core';
import {getIconUrl} from '../../utils/getIconUrl';
import {ServiceRegistration} from './ServiceRegistration';
import icons from '../../../generated/icons.json';

interface Icons {
    [iconName: string]: string[];
}

export function createIconService(
    globalConfig: SpaGlobalConfig
): ServiceRegistration<SharedIconService> {
    return {
        definition: defineIconService({
            getIconUrl(iconName, variant) {
                const variants = (icons as Icons)[iconName];
                if (
                    variants &&
                    (variant === 'default' || variants.includes(variant))
                ) {
                    return getIconUrl(iconName, variant, globalConfig);
                }
                return undefined;
            }
        })
    };
}
