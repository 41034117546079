import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {ContentStore} from '../content/ContentStore';
import {RouterService} from '../route/RouterService';
import {when} from 'mobx';
import {PageInfoServiceV1} from '@volkswagen-onehub/page-info-service';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {Logger} from '../logger/Logger';
import {convertHeaderToSeoModel} from '../../modules/structure/HeaderUpdater';
import {ImageServiceV1} from '@volkswagen-onehub/image-service';

@singleton('PageInfoManager')
export class PageInfoManager {
    @inject()
    protected contentStore!: ContentStore;

    @inject()
    protected pageInfoService!: PageInfoServiceV1;

    @inject()
    protected routerService!: RouterService;

    @inject()
    protected imageService!: ImageServiceV1;

    private lastPath!: string;

    @inject()
    private logger!: Logger;

    @postConstruct()
    public initialize(): void {
        this.pageInfoService.subscribe(() =>
            this.logger.navigation.debug(
                `logo: ${this.pageInfoService.getPageInfo().logoCategory}`
            )
        );
        if (isInBrowser()) {
            this.routerService.listen(async () => {
                if (this.routerService.pagePath !== this.lastPath) {
                    this.lastPath = this.routerService.pagePath;
                    await when(
                        () => !!this.contentStore.getCurrentPageRootModel()
                    );
                    this.setPageInfo();
                }
            });
        }
        this.setPageInfo();
        // serverside and initial rendering
    }

    private setPageInfo() {
        const pageModel = this.contentStore.getCurrentPageRootModel();
        if (!!pageModel) {
            const seo = convertHeaderToSeoModel(
                pageModel.headerDataModel,
                this.imageService
            );
            const logoCategory = !!pageModel.claim ? 'commercial' : 'private';
            this.pageInfoService.setPageInfo({logoCategory, seo});
        }
    }
}
