import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {FeatureHub} from '@feature-hub/core';
import {HistoryServiceV2} from '@feature-hub/history-service';
import {
    LayerEventPayload,
    LayerHandleV2,
    LayerManagerV27
} from '@volkswagen-onehub/layer-manager';
import {ObservableMap, action, observable, observe} from 'mobx';
import {
    FeatureTogglesModel,
    OpenedLayerModel,
    XfCarFeatureModel,
    XfContentLayerMainModel,
    XfInteractionLayerMainModel
} from '../../../generated/core';
import {modelToProps} from '../../infrastructure/api/modelToProps';
import {ModelClient} from '../../infrastructure/compatibility/ModelClient';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {SHOWROOM_NAVIGATION_CONSUMER_ID} from '../../infrastructure/hub/initializeHub';
import {
    handleDisableBodyScroll,
    handleEnableBodyScroll
} from '../../utils/bodyScrollLock';
import {processContentId} from '../../utils/processContentId';
import {Logger} from '../logger/Logger';
import {ModelStore} from '../model/ModelStore';
import {RouterService} from '../route/RouterService';
import {LayerRenderer, LayerState} from './LayerRenderer';
import {
    LAYER_CONSUMER_ID,
    LayerData,
    LayerStore,
    appendRootMainIfNecessary,
    createLayerHistoryService,
    getResourcePath,
    XfLayerModel
} from './LayerStore';
import {TrackingService} from '../tracking/TrackingService';
import {NavigationStore} from '../navigation/NavigationStore';

@singleton('LayerStore', {env: 'client'})
export class ClientLayerStore implements LayerStore {
    @inject() private modelStore!: ModelStore;
    @inject() private layerRenderer!: LayerRenderer;
    @inject() private openedLayerModel!: OpenedLayerModel;
    @inject() private featureHub!: FeatureHub;
    @inject() private routerService!: RouterService;
    @inject() private modelClient!: ModelClient;
    @inject() private navigationStore!: NavigationStore;
    @inject() private layerManager!: LayerManagerV27;
    @inject() private logger!: Logger;
    @inject() private trackingService!: TrackingService;
    @inject() private featureToggles!: FeatureTogglesModel;
    private pathMap!: ObservableMap<string, string>;
    private handleMap!: ObservableMap<string, LayerHandleV2<LayerState>>;
    private layerHistoryService!: HistoryServiceV2;

    public constructor() {
        this.pathMap = observable.map({}, {deep: false});
        this.handleMap = observable.map({}, {deep: false});
    }

    @postConstruct()
    public initialize(): void {
        this.layerManager.subscribe((event: LayerEventPayload) => {
            if (event === 'afterRender') {
                this.disableBodyScrollOnLayer();
            }
            if (event === 'beforeClose') {
                this.enableBodyScrollOnLayer();
            }
        });

        this.layerHistoryService = createLayerHistoryService(this.featureHub);
        this.layerHistoryService.history.listen(() => {
            const path = this.layerHistoryService.history.location.pathname;
            this.onOpenLayer(path);
        });
        this.handleServerSideRenderedLayer();
        observe(this.routerService, 'pagePath', () => {
            this.closeAllLayers(true);
        });
        observe(this.navigationStore, 'isFlyoutMenuOpen', () => {
            if (this.navigationStore.isFlyoutMenuOpen) {
                this.closeAllLayers(true);
            }
        });
    }

    public createLayerHref(layer: string): string {
        const layerLocation = {
            pathname: layer
        };
        const cmsLocation = this.routerService.getLocation();
        // create the url for cms and layer locations.
        const rootLocation = this.layerHistoryService.createNewRootLocationForMultipleConsumers(
            {
                historyKey: SHOWROOM_NAVIGATION_CONSUMER_ID,
                location: cmsLocation
            },
            {historyKey: LAYER_CONSUMER_ID, location: layerLocation}
        );

        return this.layerHistoryService.rootHistory.createHref(rootLocation);
    }

    @action
    public async onOpenLayer(path: string): Promise<void> {
        if (path === '/') {
            this.closeAllLayers();

            return Promise.resolve();
        }

        return new Promise<void>((resolve, reject) => {
            this.loadLayerData(path)
                .then(data => {
                    if (data && data.model) {
                        this.openLayerInternally(data.includePath, data.model);
                        this.logger.layer.info(
                            'open content layer: path=%s, includePath=%s',
                            path,
                            data.includePath
                        );
                        resolve();
                    } else {
                        this.logger.layer.warn(
                            "couldn't open content layer: path=%s",
                            path
                        );
                        reject();
                    }
                })
                .catch(e => {
                    this.logger.layer.warn(
                        "couldn't open content layer: path=%s, message:%s",
                        path,
                        e.toString()
                    );
                    reject(e);
                });
        });
    }
    public async openLayer(path: string): Promise<LayerData | undefined> {
        const data = await this.loadLayerData(path);
        if (data) {
            if (
                this.layerHistoryService.history.location.pathname.length <= 1
            ) {
                this.layerHistoryService.history.replace(path);
            } else {
                if (data.model) {
                    this.openLayerInternally(data.includePath, data.model);
                }
            }
        }

        return data;
    }

    public onCloseLayerClick(
        path: string,
        originalCqPath: string,
        contentName: string
    ): void {
        const locationResourcePath =
            getResourcePath(
                this.layerHistoryService.history.location.pathname,
                this.openedLayerModel
            ) + '/jcr:content/root/main';
        const locationIncludePath = this.getIncludePath(locationResourcePath);
        if (path === locationIncludePath) {
            this.layerHistoryService.history.replace('/');
        }

        this.trackingService.trackLayerCloseClick(
            processContentId(originalCqPath, contentName)
        );
    }

    @action
    public closeLayer(path: string): void {
        this.doCloseLayer(path);
    }

    @action
    public closeAllLayers(closeAllNonCmsLayers: boolean = false): void {
        this.handleMap.forEach((handle, path) => {
            this.logger.layer.info('close layer: path=%s', path);
            handle.update({forceClose: true});
            handle.close();
            this.handleMap.delete(path);
        });
        let disclaimer = this.layerManager.getDisclaimerLayer();
        if (!!disclaimer) {
            disclaimer.layer.close();
        }
        if (closeAllNonCmsLayers && this.featureToggles.enableCloseAllLayers) {
            this.layerManager.getLayers().forEach(l => l.layer.close());
        }
    }

    public disableBodyScrollOnLayer(): void {
        const layers = this.layerManager.getLayers();
        if (layers.length > 0) {
            const toplayer = layers[layers.length - 1];
            if (toplayer.layer.element) {
                handleDisableBodyScroll(toplayer.layer.element);
                this.logger.layer.info(
                    'disable scroll lock %s',
                    toplayer.layer.id
                );
            }
        }
    }

    public enableBodyScrollOnLayer(): void {
        const layers = this.layerManager.getLayers();
        if (layers.length > 0) {
            const toplayer = layers[layers.length - 1];
            if (toplayer.layer.element) {
                handleEnableBodyScroll(toplayer.layer.element);
                this.logger.layer.info(
                    'enable scroll lock %s',
                    toplayer.layer.id
                );
            }
        }
        if (layers.length > 1) {
            const newToplayer = layers[layers.length - 2];
            if (newToplayer.layer.element) {
                handleDisableBodyScroll(newToplayer.layer.element);
                this.logger.layer.info(
                    'disable scroll lock %s',
                    newToplayer.layer.id
                );
            }
        }
    }

    @action
    private async loadLayerData(path: string): Promise<LayerData | undefined> {
        if (path === '/') {
            return Promise.resolve(undefined);
        }

        path = appendRootMainIfNecessary(
            getResourcePath(path, this.openedLayerModel)
        );
        const modelPath = path + '.model.json';

        let includePath = this.pathMap.get(path);
        if (includePath) {
            this.logger.layer.info(
                'serve existing layer data: path=%s, includePath=%s',
                path,
                includePath
            );

            const model = this.getLayerData(includePath);

            return Promise.resolve({includePath, model});
        } else {
            return new Promise<LayerData | undefined>((resolve, reject) => {
                this.modelClient
                    .fetch(modelPath)
                    .then((tModel: TransferModel) => {
                        // sanitize path
                        // store new data as global content
                        includePath = this.modelStore.insertGlobalContent(
                            path,
                            tModel
                        );
                        // point to Main component instead of XF page
                        includePath = appendRootMainIfNecessary(includePath);
                        // store path mapping
                        this.pathMap.set(path, includePath);
                        this.logger.layer.info(
                            'new layer data stored: path=%s, includePath=%s',
                            path,
                            includePath
                        );

                        const model = this.getLayerData(includePath);

                        resolve({includePath, model});
                    })
                    .catch(e => {
                        this.logger.layer.warn(
                            "couldn't fetch new layer data: path=%s, message:%s",
                            path,
                            e.toString()
                        );
                        reject(e);
                    });
            });
        }
    }

    private doCloseLayer(includePath: string): void {
        const handle = includePath
            ? this.handleMap.get(includePath)
            : undefined;

        if (handle) {
            this.logger.layer.info('close layer: path=%s', includePath);
            handle.close();
            this.handleMap.delete(includePath);
        } else {
            this.logger.layer.warn(
                "couldn't find layer to close: path=%s",
                includePath
            );
        }
    }

    /**
     * Returns the include path from original paths and include paths alike. Should be used in places where it's not clear which path in given as input
     * @param path
     */
    private getIncludePath(path?: string | null): string | undefined {
        if (!path) {
            return undefined;
        }
        const includePath = this.pathMap.get(path);

        return includePath ? includePath : path;
    }

    private getLayerData(
        includePath?: string | null
    ): XfLayerModel | undefined {
        if (!includePath) {
            return undefined;
        }
        const data = this.modelStore.getData(includePath, true);

        return data
            ? ((modelToProps(data, includePath) as any) as XfLayerModel)
            : data;
    }

    private handleServerSideRenderedLayer(): void {
        if (this.openedLayerModel.contentResource) {
            const resourcePath = appendRootMainIfNecessary(
                getResourcePath(
                    this.openedLayerModel.path,
                    this.openedLayerModel
                )
            );
            const includePath = this.modelStore.insertGlobalContent(
                resourcePath,
                this.openedLayerModel.contentResource as any
            );

            this.pathMap.set(resourcePath, includePath);
            const data = this.getLayerData(includePath);

            if (!data) {
                return;
            }

            this.openLayerInternally(includePath, data);
        } else {
            const pathname = this.layerHistoryService.history.location.pathname;
            if (pathname && pathname.length > 1) {
                this.onOpenLayer(pathname);
            }
        }
    }

    private openLayerInternally(includePath: string, data: XfLayerModel): void {
        const onClose = (state: LayerState) => {
            if (!state.forceClose) {
                this.onCloseLayerClick(
                    includePath,
                    data.trackingModel.originalCqPath,
                    data.trackingModel.contentName
                );
            }
        };

        switch (data.layerType) {
            case 'CONTENT': {
                const contentLayerData = data as
                    | XfContentLayerMainModel
                    | XfCarFeatureModel;
                const headlinePath = contentLayerData.cqType.includes(
                    'xfContentLayerMain'
                )
                    ? '/overline'
                    : '/title';
                const handle = this.layerRenderer.renderContentLayer(
                    includePath,
                    contentLayerData.cqType,
                    contentLayerData.btnCloseLabel,
                    includePath + headlinePath,
                    onClose
                );

                this.handleMap.set(includePath, handle);
                break;
            }
            case 'INTERACTION': {
                const interactionLayerData = data as XfInteractionLayerMainModel;
                const handle = this.layerRenderer.renderInteractionLayer(
                    includePath,
                    interactionLayerData.cqType,
                    onClose
                );

                this.handleMap.set(includePath, handle);
                break;
            }
            default:
                return;
        }
    }

    // returns true if layer has been opened within SSR
    public hasOpenedLayer(): boolean {
        return Boolean(this.openedLayerModel?.contentResource);
    }
}
