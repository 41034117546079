import {
    ShareDataVersionManager,
    defineDataVersionService
} from '@volkswagen-onehub/data-version-service';
import {SpaAsyncConfig} from '../../../generated/core';
import {ServiceRegistration} from './ServiceRegistration';

export function createDataVersionService(
    asyncConfig: SpaAsyncConfig
): ServiceRegistration<ShareDataVersionManager> {
    return {
        definition: defineDataVersionService({
            modelStructureVersion:
                asyncConfig.dataVersions.modelStructureVersion,
            mofaCarDataVersion: asyncConfig.dataVersions.mofaCarDataVersion,
            serviceConfigsVersion:
                asyncConfig.dataVersions.serviceConfigsVersion,
            cacheBuster: String(asyncConfig.dataVersions.cacheBuster)
        }),
        service: {
            typeName: 'DataVersionService',
            version: '^1.0.0'
        }
    };
}
