import {
    BodyScrollOptions,
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll
} from 'body-scroll-lock';

export const handleDisableBodyScroll = (
    targetElement: HTMLElement | Element | null,
    options?: BodyScrollOptions | undefined
) => {
    if (targetElement instanceof HTMLElement) {
        disableBodyScroll(targetElement, {
            allowTouchMove: _el => true,
            ...options
        });

        if (document.body.style.overflow !== 'hidden') {
            document.body.style.overflow = 'hidden';
        }
    }
};

export const handleEnableBodyScroll = (
    targetElement?: HTMLElement | Element | null
) => {
    if (targetElement) {
        enableBodyScroll(targetElement);
    } else {
        clearAllBodyScrollLocks();
    }

    if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = '';
    }
};
