import * as React from 'react';

import {
    Breakpoints,
    styled,
    Text,
    TokenTextAppearance,
    TokenTextColor
} from '@volkswagen-onehub/components-core';

import {observer} from 'mobx-react-lite';
import {UserInteractionServiceV21} from '@volkswagen-onehub/user-interaction-service';

import {
    useGlobalConfig,
    useMydealerConfigurationService,
    useMyDealerStore,
    useRegistry,
    useSpaAsyncConfig,
    useTrackingService
} from '../../../context';
import {MydealerStore} from '../../../context/mydealer/MydealerStore';
import {useMediaQueries} from '../../../context/mediaQueries/mediaQueryContext';
import {getGlobal} from '../../../utils/getGlobal';
import {makeCancelable} from '../../../utils/makeCancelable';
import {useDealerConfig} from '../../../utils/useDealerConfig';
import {StyledTopBarButton} from '../../../d6/components/navigation-top-bar-one-hub-helpers';
import {MydealerStateLabels, SpaAsyncConfig} from '../../../../generated/core';
import {DealerType} from '@volkswagen-onehub/mydealer-configuration-service';
import {getStartDirection} from '../../../d6/components/helpers';
import {PinSelectable, PinSelected} from '@volkswagen-onehub/icons-core';

const StyledBlockWrapper = styled.div`
    height: ${props => props.theme.size.static350};

    & button {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;

        svg {
            min-width: ${props => props.theme.size.static350};
        }
    }

    & [class*='StyledTextComponent'] {
        margin: 0;
        padding-${props => getStartDirection(props.theme.direction)}: 5px;
        line-height: ${props => props.theme.size.static300};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        max-width: 199px;
    }
    @media (min-width: ${Breakpoints.b1600}px) {
        max-width: 288px;
    }
`;

export const getLabelsByDealerType = (
    spaAsyncConfig: SpaAsyncConfig,
    dealerType: DealerType = 'FULLSTACK'
): MydealerStateLabels => {
    const topbarButtonAriaLabel =
        spaAsyncConfig.mydealerState.topbarButtonAriaLabel;
    const topbarDealerLabel = spaAsyncConfig.mydealerState.topbarDealerLabel;
    const flyoutNavDealerLabel =
        spaAsyncConfig.mydealerState.flyoutNavDealerLabel;
    const movingFrameLinkLabel =
        spaAsyncConfig.mydealerState.movingFrameLinkLabel;

    const defaultResult = {
        flyoutNavDealerLabel,
        movingFrameLinkLabel,
        topbarButtonAriaLabel,
        topbarDealerLabel
    };

    if (!dealerType || dealerType !== 'FULLSTACK') {
        let id = dealerType.toLowerCase().replace('_', '') as
            | 'tradeport'
            | 'salesonly'
            | 'serviceonly';

        return {
            topbarButtonAriaLabel:
                spaAsyncConfig.mydealerState[id]?.topbarButtonAriaLabel ||
                topbarButtonAriaLabel,
            topbarDealerLabel:
                spaAsyncConfig.mydealerState[id]?.topbarDealerLabel ||
                topbarDealerLabel,
            movingFrameLinkLabel:
                spaAsyncConfig.mydealerState[id]?.movingFrameLinkLabel ||
                movingFrameLinkLabel,
            flyoutNavDealerLabel:
                spaAsyncConfig.mydealerState[id]?.flyoutNavDealerLabel ||
                flyoutNavDealerLabel
        };
    }

    return defaultResult;
};

export const MydealerStateInfo = observer(function MSI(): JSX.Element | null {
    const {isDesktop} = useMediaQueries();
    const myDealerConfigurationService = useMydealerConfigurationService();
    const logger = getGlobal().log.general;
    const spaAsyncConfig = useSpaAsyncConfig();
    const mydealerStateEnabled = useGlobalConfig().featureToggles
        .enableMyDealerState;
    const registry = useRegistry();
    const mydealerStore: MydealerStore = useMyDealerStore();
    const trackingService = useTrackingService();

    const userInteractionService: UserInteractionServiceV21 = registry.getSingleton(
        'UserInteractionService'
    );

    const action = userInteractionService?.get('mydealer::open-dealer-state');

    const dealerId = mydealerStore?.dealerId;

    const dealerConfigState = useDealerConfig(dealerId);

    const [marketHasDealers, setMarketHasDealers] = React.useState<boolean>(
        false
    );

    React.useEffect(() => {
        const promise = myDealerConfigurationService.getDealers();

        const result = makeCancelable(promise);
        result.promise
            .then(dealers => {
                setMarketHasDealers(Object.keys(dealers ?? {}).length > 0);
            })
            .catch(reject => {
                logger.error('Failed to get dealers', reject);
            });

        return result.cancel;
    }, [myDealerConfigurationService, dealerId, logger]);

    const enableMyDealerZeroState =
        myDealerConfigurationService?.getFeatureToggles()?.enableZeroState ??
        false;

    if (
        !mydealerStateEnabled ||
        !marketHasDealers ||
        (!dealerId && !enableMyDealerZeroState)
    ) {
        return null;
    }

    const {
        topbarSelectNewDealershipLabel,
        topbarSelectNewDealershipAriaLabel
    } = spaAsyncConfig.mydealerState;

    function handleDealerSelectButtonClick(
        e: React.MouseEvent<HTMLDivElement>
    ): void {
        e.preventDefault();
        if (action) {
            action.onActivate({}, new URLSearchParams());
        }
        trackingService.trackDealerSelectFlyoutOpen();
    }

    if (!dealerId && enableMyDealerZeroState) {
        return (
            <StyledBlockWrapper onClick={handleDealerSelectButtonClick}>
                <StyledTopBarButton
                    data-testid="mydealer-zero-state-info-button"
                    aria-label={topbarSelectNewDealershipAriaLabel}
                >
                    <PinSelectable />
                    {isDesktop && (
                        <Text
                            appearance={TokenTextAppearance.copy100}
                            color={TokenTextColor.inherit}
                        >
                            {topbarSelectNewDealershipLabel}
                        </Text>
                    )}
                </StyledTopBarButton>
            </StyledBlockWrapper>
        );
    }

    if (!dealerConfigState.loaded || !dealerConfigState.result) {
        return null;
    }

    const dealerConfig = dealerConfigState.result;

    const {topbarButtonAriaLabel} = getLabelsByDealerType(
        spaAsyncConfig,
        dealerConfigState?.result?.dealerType
    );

    return (
        <StyledBlockWrapper onClick={handleDealerSelectButtonClick}>
            <StyledTopBarButton
                data-testid="mydealer-state-info-button"
                aria-label={topbarButtonAriaLabel}
            >
                <PinSelected />
                {isDesktop && (
                    <Text
                        appearance={TokenTextAppearance.copy100}
                        color={TokenTextColor.inherit}
                    >
                        {dealerConfig.topbarName}
                    </Text>
                )}
            </StyledTopBarButton>
        </StyledBlockWrapper>
    );
});
