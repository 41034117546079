import * as React from 'react';

import {VideoPlayerProxy} from './VideoPlayerProxy';

export interface MediaStateMachineOptions {
    readonly autoPlay: boolean;
    readonly autoPause: boolean;
    readonly playerProxy: React.RefObject<VideoPlayerProxy>;
}

export interface ObserverEventTrackingCallbacks {
    readonly pause: () => void;
}

export class MediaStateMachine {
    protected get observerCanLoad(): boolean {
        return !this._startedLoading && this.autoPlay;
    }

    private get observerCanPlay(): boolean {
        return (
            (!this._startedPlaying && this.autoPlay) ||
            this._stoppedByIO ||
            this._stoppedByCarousel
        );
    }

    private get observerCanPause(): boolean {
        if (this.playerProxy && this.playerProxy.current) {
            const proxy = this.playerProxy.current;

            return proxy.isPlaying && this.autoPause && !proxy.isInFullScreen;
        } else {
            return false;
        }
    }

    public get isInitialized(): boolean {
        return Boolean(
            this.playerProxy &&
                this.playerProxy.current &&
                this.playerProxy.current.isInitialized
        );
    }

    public get threshold(): number[] {
        return [this.loadRatio, this.playRatio, this.pauseRatio];
    }

    private readonly loadRatio: number = 0;
    private readonly pauseRatio: number = 1 / 3;
    private readonly playRatio: number = 2 / 3;
    private readonly playerProxy: React.RefObject<VideoPlayerProxy>;
    private readonly autoPlay: boolean;
    private readonly autoPause: boolean;

    private _stoppedByIO: boolean = false;
    private _startedPlaying: boolean = false;
    private _startedLoading: boolean = false;
    private _stoppedByCarousel: boolean = false;

    public constructor(options: MediaStateMachineOptions) {
        const {autoPlay, autoPause, playerProxy} = options;
        this.autoPlay = autoPlay;
        this.autoPause = autoPause;
        this.playerProxy = playerProxy;
    }

    public readonly handleObserverEvent = (
        intersectionRatio: number,
        callback?: ObserverEventTrackingCallbacks
    ) => {
        // skip for slight performance boost
        if (!this.isInitialized) {
            return;
        }

        // only load for the first time when it reaches viewport
        if (intersectionRatio > this.loadRatio && this.observerCanLoad) {
            this.load();
            // don't return continue checking because eg. in case of the stage it might be desired to execute play as well
        }

        // only play for the first time and when stopped by intersection observer
        if (intersectionRatio > this.playRatio && this.observerCanPlay) {
            this.play();

            return;
        }
        // only auto-pause when video is playing and started via intersection observer
        if (intersectionRatio < this.pauseRatio && this.observerCanPause) {
            this.pause();
            // eslint-disable-next-line no-unused-expressions
            callback?.pause();

            return;
        }
    };

    public readonly handleCarouselEvent = (isActive: boolean) => {
        if (
            this.playerProxy &&
            this.playerProxy.current &&
            this.playerProxy.current.isPlaying &&
            !isActive
        ) {
            this.pause();
            this._stoppedByCarousel = true;
        }
    };

    private readonly load = () => {
        if (this.playerProxy && this.playerProxy.current) {
            this.playerProxy.current.load();
            this._startedLoading = true;
        }
    };

    private readonly play = () => {
        if (this.playerProxy && this.playerProxy.current) {
            this.playerProxy.current.playByIO();
            this._stoppedByIO = false;
            this._stoppedByCarousel = false;
            this._startedPlaying = true;
        }
    };

    private readonly pause = () => {
        if (this.playerProxy && this.playerProxy.current) {
            this.playerProxy.current.pause();
            this._stoppedByIO = true;
        }
    };
}

/**
 * Handles autoplay logic for Video Elements
 */
export class VideoStateMachine extends MediaStateMachine {
    public constructor(
        proxy: React.RefObject<VideoPlayerProxy>,
        autoPlay: boolean
    ) {
        super({
            autoPlay,
            autoPause: true,
            playerProxy: proxy
        });
    }
}

/**
 * Handles autoplay logic for Animation Elements
 */
export class AnimationStateMachine extends MediaStateMachine {
    public constructor(proxy: React.RefObject<VideoPlayerProxy>) {
        super({
            autoPlay: true,
            autoPause: true,
            playerProxy: proxy
        });
    }
}
