import {parsePath} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';

export function isNewPrimaryPage(
    currentRootLocation: history.LocationDescriptorObject,
    primaryConsumerLocation: history.LocationDescriptorObject | undefined
): boolean {
    if (!primaryConsumerLocation || !primaryConsumerLocation.pathname) {
        return false;
    }
    const oldPrimaryPagePath = parsePath(currentRootLocation.pathname || '')
        .base;
    const newPrimaryPagePath = parsePath(primaryConsumerLocation.pathname).base;

    return (
        !!primaryConsumerLocation && oldPrimaryPagePath !== newPrimaryPagePath
    );
}
