import {Subscription} from '@volkswagen-onehub/feature-hub-utils';
import {LayerDescriptionV2} from '@volkswagen-onehub/layer-manager';
import * as React from 'react';
import {useLayerManager} from '../context';

export function useLayers() {
    const layerManager = useLayerManager();
    const [
        layerListener,
        setLayerListener
    ] = React.useState<Subscription | null>(null);
    const [layers, setLayers] = React.useState<LayerDescriptionV2[]>(
        layerManager.getLayers()
    );

    React.useEffect(() => {
        setLayerListener(
            layerManager &&
                layerManager.subscribe(() => {
                    setLayers(layerManager.getLayers());
                })
        );

        return () => {
            if (layerListener) layerListener.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {layers};
}
