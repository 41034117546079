import {Model} from '@adobe/cq-react-editable-components';
import * as React from 'react';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {
    ContainerExporter,
    ContainerItem
} from '../../infrastructure/ContainerExporter';
import {getChildItems} from './getContainerChildItems';

export function getRenderedChildItems(props: ContainerExporter): JSX.Element[] {
    return getChildItems(props)
        .filter((child: ContainerItem) => {
            return child.model as Model;
        })
        .map((child: ContainerItem) => {
            return (
                <PersonalizationInclude
                    key={child.key}
                    resourceType={child.model.cqType}
                    path={child.key}
                />
            );
        });
}
