import {
    FeatureServiceBinder,
    FeatureServiceProviderDefinition,
    FeatureServices,
    SharedFeatureService
} from '@feature-hub/core';
import {AuthServiceProviderV2} from '@volkswagen-onehub/authservice';
import {LoginServiceV1Impl} from './LoginServiceV1Impl';
import {AuthServiceConfig} from './AuthServiceConfig';
import {MultipleCustomerLoginFeatureService} from '../../context/vwid/MultipleCustomerLoginFeatureService';
import {LoginServiceV1} from '@volkswagen-onehub/login-service';

export interface SharedLoginService extends SharedFeatureService {
    readonly '1.0.0': FeatureServiceBinder<LoginServiceV1<unknown>>;
}
interface LoginFeatureServices extends FeatureServices {
    readonly 'vw:authService'?: AuthServiceProviderV2;
    readonly 'vw:authServiceCommercial'?: AuthServiceProviderV2;
}

export interface LoginServiceConfig {
    serviceFactory: () => MultipleCustomerLoginFeatureService;
}

export const defineLoginService = (
    config: LoginServiceConfig
): FeatureServiceProviderDefinition<
    SharedLoginService,
    LoginFeatureServices
> => {
    return {
        id: 'login-service',
        optionalDependencies: {
            featureServices: {
                'vw:authService': '2.0.0',
                'vw:authServiceCommercial': '2.0.0'
            }
        },
        create: env => {
            const authService = env.featureServices['vw:authService'];
            const authServiceCommercial =
                env.featureServices['vw:authServiceCommercial'];

            const authServices: AuthServiceConfig[] = [];
            if (authService) {
                authServices.push({
                    customerType: 'private',
                    authService: authService
                });
            }
            if (authServiceCommercial) {
                authServices.push({
                    customerType: 'commercial',
                    authService: authServiceCommercial
                });
            }

            return {
                '1.0.0': (consumerId: string) => ({
                    featureService: new LoginServiceV1Impl(
                        authServices,
                        config,
                        consumerId
                    )
                })
            };
        }
    };
};
