import * as React from 'react';

import {
    styled,
    DesignTokenSize,
    BreakpointConfigType
} from '@volkswagen-onehub/components-core';

import {AriaLabelOrLabelledby} from '@volkswagen-onehub/components-core/dist/helpers/types';

import {GalleryContext} from './gallery';

export const StyledSlide = styled.div<{
    slidesPerPage: number | BreakpointConfigType<number>;
    fadingEdges: boolean | BreakpointConfigType<boolean>;
    gutter?: DesignTokenSize;
    fadeout?: boolean;
    withScrollSnap: boolean;
}>`
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: start;
    justify-content: center;
    scroll-snap-align: ${props => (props.withScrollSnap ? 'start' : 'none')};
    outline: none;

    padding-inline-end: ${props =>
        props.gutter ? props.theme.size[props.gutter] : '0px'};

    :first-child {
        margin-inline-start: calc(
            var(--gallery-fadingEdges) * var(--gallery-hasFadingEdges) / 2
        );
    }
    :last-child {
        padding-inline-end: 0;
        margin-inline-end: calc(
            var(--gallery-fadingEdges) * var(--gallery-hasFadingEdges) / 2
        );
    }

    opacity: ${props => props.fadeout && '0.5'};
    transition: opacity 0.5s ease-in-out;
`;

const StyledContainedImageWrapper = styled.div<{reduceImageSize: boolean}>`
    /*
   * create a "safe-zone" so the controls do not overlap the image
   * calculation: 100% - 2 (arrows) * width of the arrows(40px)
   */
    width: ${props =>
        props.reduceImageSize
            ? `calc(100% - 2 * ${props.theme.size.grid001})`
            : '100%'};
`;

export const GallerySlide: React.FunctionComponent<AriaLabelOrLabelledby & {
    readonly children?: React.ReactNode;
}> = ({ariaLabel, ariaLabelledby, children}) => {
    const {
        getSlideId,
        slidesPerPage,
        fadingEdges,
        gutter,
        accessibleExtendedIndices,
        visibleIndices,
        snappableExtendedIndices
    } = React.useContext(GalleryContext);
    const {index: slideIndex, id = NaN} = React.useContext(GallerySlideContext);

    // id (extendedIndex): -2 -1           5 6
    // slideIndex (index):  3  4 0 1 2 3 4 0 1
    const slideId = id || slideIndex;

    const isCopyOfASlide = !Number.isNaN(id);
    // To ensure that screenreaders read out the correct amount of slides in the gallery,
    // hide slide copies from aria when not in view and hide normal slides when their
    // copy gets in to view.
    const ariaHidden = isCopyOfASlide
        ? !accessibleExtendedIndices.includes(slideId)
        : visibleIndices.includes(slideIndex) &&
          !accessibleExtendedIndices.includes(slideIndex);

    return (
        <StyledSlide
            id={getSlideId(slideId)}
            slidesPerPage={slidesPerPage}
            fadingEdges={fadingEdges}
            gutter={gutter}
            fadeout={fadingEdges && !visibleIndices.includes(slideIndex)}
            data-accessible={accessibleExtendedIndices.includes(slideId)}
            role="group"
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            aria-roledescription="slide"
            aria-hidden={ariaHidden ? true : undefined}
            withScrollSnap={snappableExtendedIndices.includes(slideIndex)}
        >
            <StyledContainedImageWrapper reduceImageSize={slidesPerPage === 1}>
                {children}
            </StyledContainedImageWrapper>
        </StyledSlide>
    );
};

export const GallerySlideContext = React.createContext<{
    index: number;
    id?: number;
}>({
    index: 0
});
