import * as React from 'react';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {IntroCopySectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';

const StyledIntroCopy = styled.div`
    width: ${props => props.theme.size.grid020};
    margin-inline: auto;

    @media (min-width: ${Breakpoints.b560}px) {
        width: ${props => props.theme.size.grid012};
    }
`;

/**
 * S107 IntroCopySection
 */

const InternalIntroCopySection = (props: IntroCopySectionModel) => {
    const {
        useDropCap,
        anchorId,
        contentName,
        contentLabels,
        contentId: sectionId,
        tagTypeBroken,
        mboxId
    } = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S107 Intro Copy Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <StyledIntroCopy>
                        <CopyItem path="copyItem" useDropCap={useDropCap} />
                    </StyledIntroCopy>
                    <BelowSectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const IntroCopySection = MapTo(
    'vwa-ngw18/components/editorial/sections/introCopySection',
    InternalIntroCopySection
);
