import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap
} from '@volkswagen-onehub/components-core';
import {CopyInsetMedia} from '../../../d6/components/copy-inset-media';
import {CopyInsetMediaItemModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {aspectRatioMap} from '../../../utils/aspectRatioMap';
import {mediaAlignmentMap} from '../../../utils/mediaAlignmentMap';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {RichtextFullElement} from '../elements/RichtextElement';

const mediaContextValue = {sizes: '(min-width: 768px) 20vw, 90vw'};

function InternalCopyInsetMediaItem(
    props: CopyInsetMediaItemModel
): JSX.Element {
    const {alignment, cqItems, mediaAspectRatio} = props;

    const hasHeadline =
        C.isInEditor() ||
        (Boolean(cqItems) &&
            Boolean(cqItems.headline) &&
            !cqItems.headline.empty);

    const headline = <HeadingElement path="headline" order="H2" />;

    const media = (
        <MediaContext.Provider value={mediaContextValue}>
            <MediaElement path="media" />
        </MediaContext.Provider>
    );

    return (
        <AuthoringWrapper
            title="I110 Copy Inset Media Item"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
        >
            <SimpleDisclaimerWrapper>
                <Container
                    wrap={ContainerWrap.always}
                    gutter={ContainerGutter.dynamic0100}
                >
                    {hasHeadline && headline}
                    <CopyInsetMedia
                        aspectRatio={aspectRatioMap[mediaAspectRatio]}
                        media={media}
                        mediaAlignment={mediaAlignmentMap[alignment]}
                        isFullWidth
                    >
                        <RichtextFullElement path="copy" />
                    </CopyInsetMedia>
                </Container>
            </SimpleDisclaimerWrapper>
        </AuthoringWrapper>
    );
}

export const CopyInsetMediaItem = MapTo(
    'vwa-ngw18/components/editorial/items/copyInsetMediaItem',
    InternalCopyInsetMediaItem
);
