import {FeatureAppEnvironment, FeatureServices} from '@feature-hub/core';
import {FeatureAppLoader} from '@feature-hub/react';

import {TrackingManagerV1} from '@volkswagen-onehub/tracking-service';
import {useEffect} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {Css, FeatureAppSize} from '../../../generated/core';
import {
    useNavigationService,
    useTrackingService,
    useLogger,
    useNavigationStore
} from '../../context';
import {GlobalDisclaimerStore} from '../../context/disclaimer/GlobalDisclaimerStore';
import {Logger} from '../../context/logger/Logger';
import {useContextTrackingData} from '../../hooks/useContextTrackingData';
import {useDelayedState, isFinished} from '../../hooks/useDelayedState';
import {noop} from '../../utils/noop';
import {
    FeatureAppConfigValidationError,
    handleFeatureAppError,
    RenderFeatureAppError
} from './errors';
import FeatureAppLoadingContainer, {
    ContainerSize
} from './FeatureAppLoadingContainer';
import {createNewInstanceConfig, getComponentForError} from './helpers';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {css} from '@volkswagen-onehub/components-core';
import {FaContentServiceV1} from '@volkswagen-onehub/fa-content-service';

export interface CmsFeatureAppLoaderProps {
    readonly featureAppId: string;
    readonly anchorId: string;
    readonly disclaimerLegalEntity?: string;
    readonly sectionId?: string;
    readonly contentLabels?: string;
    readonly featureAppTrackingData: any;
    readonly globalDisclaimerStore: GlobalDisclaimerStore;
    readonly error?: FeatureAppLoaderError;
    readonly logger: Logger;
    readonly content?: string;
    readonly featureAppName?: string;
    readonly hideLoadingIndicator: boolean;
    readonly url: string;
    readonly ssrUrl: string;
    readonly ssrEnabled: boolean;
    readonly baseUrl: string;
    readonly css: Css[];
    readonly instanceConfig: string;
    readonly featureAppSize: FeatureAppSize;
    readonly legalEntity: string;
    readonly validInstanceConfig: boolean;
    readonly featureAppInView: boolean;

    onErrorStateChange?(error: boolean): void;
}

export enum FeatureAppLoaderError {
    INVALID_URL,
    LOAD
}

const LoaderLayout = styled.div`
    position: relative;
`;

const FeatureAppWrap = styled.div<{isHidden?: boolean}>`
    ${props =>
        props.isHidden &&
        css`
            // internal content flow should behave like when it's visible
            overflow-y: visible;
            max-height: 100%;
            max-width: 100%;
            display: block;
            position: absolute;

            visibility: hidden;

            // extra high specificity -> overrules children with "visibility: visible"
            &&&&& * {
                visibility: hidden !important;
            }
        `};
`;

function SsrDisabled(props: CmsFeatureAppLoaderProps): JSX.Element {
    if (props.hideLoadingIndicator) {
        return <React.Fragment />;
    }

    return (
        <LoaderLayout>
            <FeatureAppWrap isHidden />
            <FeatureAppLoadingContainer
                size={getContainerSize(props.featureAppSize)}
                visualFeedback={false}
            />
        </LoaderLayout>
    );
}

function SsrEnabled(props: {
    props: CmsFeatureAppLoaderProps;
    beforeCreate: (env: FeatureAppEnvironment<FeatureServices, any>) => void;
}): JSX.Element {
    const {
        featureAppId,
        anchorId,
        url = '',
        ssrUrl = '',
        ssrEnabled = false,
        baseUrl = '',
        css = [],
        instanceConfig = '',
        legalEntity = '',
        featureAppName
    } = props.props;

    const newInstanceConfig = createNewInstanceConfig(
        instanceConfig,
        baseUrl,
        url,
        legalEntity
    );

    return (
        <FeatureAppLoader
            baseUrl={baseUrl}
            src={url}
            serverSrc={ssrEnabled ? ssrUrl : undefined}
            css={css}
            featureAppId={featureAppId}
            config={newInstanceConfig}
            beforeCreate={props.beforeCreate}
            onError={handleFeatureAppError}
            featureAppName={featureAppName}
        >
            {({featureAppNode, error}) => {
                if (error) {
                    return <RenderFeatureAppError anchorId={anchorId} />;
                }
                return <>{featureAppNode}</>;
            }}
        </FeatureAppLoader>
    );
}

function Client(props: {
    props: CmsFeatureAppLoaderProps;
    beforeCreate: (env: FeatureAppEnvironment<FeatureServices, any>) => void;
}): JSX.Element {
    const {
        featureAppId,
        anchorId,
        sectionId,
        contentLabels,
        featureAppTrackingData,
        hideLoadingIndicator = false,
        url = '',
        ssrUrl = '',
        ssrEnabled = false,
        baseUrl = '',
        css = [],
        instanceConfig = '',
        featureAppSize,
        legalEntity = '',
        featureAppName,
        featureAppInView
    } = props.props;

    const newInstanceConfig = createNewInstanceConfig(
        instanceConfig,
        baseUrl,
        url,
        legalEntity
    );

    const trackingService = useTrackingService();

    const trackingData = useContextTrackingData(contentLabels);

    if (!featureAppInView) {
        return (
            <ClientLoader
                featureAppSize={featureAppSize}
                hideLoadingIndicator={hideLoadingIndicator}
                loaded={false}
                featureAppNode={undefined}
                trackFeatureAppLoad={noop}
            ></ClientLoader>
        );
    }

    const trackFeatureAppLoad = (): void => {
        trackingService.trackFeatureAppLoad(
            sectionId || null,
            trackingData,
            featureAppTrackingData
        );
    };

    return (
        <FeatureAppLoader
            baseUrl={baseUrl}
            src={url}
            serverSrc={ssrEnabled ? ssrUrl : undefined}
            css={css}
            featureAppId={featureAppId}
            config={newInstanceConfig}
            beforeCreate={props.beforeCreate}
            onError={handleFeatureAppError}
            key={featureAppId}
            featureAppName={featureAppName}
        >
            {({featureAppNode, error, loading}) => {
                if (error) {
                    return <RenderFeatureAppError anchorId={anchorId} />;
                }

                return (
                    <ClientLoader
                        featureAppSize={featureAppSize}
                        hideLoadingIndicator={hideLoadingIndicator}
                        loaded={!!featureAppNode && !loading}
                        featureAppNode={featureAppNode}
                        trackFeatureAppLoad={trackFeatureAppLoad}
                    ></ClientLoader>
                );
            }}
        </FeatureAppLoader>
    );
}

interface ClientLoaderProps {
    featureAppNode: React.ReactNode;
    loaded: boolean;
    trackFeatureAppLoad: any;
    featureAppSize: FeatureAppSize;
    hideLoadingIndicator: boolean;
}

function ClientLoader(props: ClientLoaderProps) {
    const {
        featureAppNode,
        loaded,
        trackFeatureAppLoad,
        featureAppSize,
        hideLoadingIndicator
    } = props;

    const logger = useLogger().featureapploader;
    const hasBeenTracked = React.useRef<boolean>(false);
    const phase = useDelayedState({
        threshold: 400,
        delay: 1000,
        loaded,
        logger
    });
    const finished = isFinished(phase, loaded);
    useEffect(() => {
        if (finished && !hasBeenTracked.current) {
            trackFeatureAppLoad();
            hasBeenTracked.current = true;
        }
    }, [trackFeatureAppLoad, hasBeenTracked, finished]);

    logger.debug(
        'loading phase:%s, node:%s, size:%s, loaded:%s, finished:%s',
        phase,
        Boolean(featureAppNode),
        featureAppSize,
        loaded,
        finished
    );

    if (hideLoadingIndicator) {
        return <>{featureAppNode}</>;
    }
    return (
        <LoaderLayout>
            <FeatureAppWrap isHidden={!finished} aria-hidden={!finished}>
                {featureAppNode}
            </FeatureAppWrap>
            {!finished && (
                <FeatureAppLoadingContainer
                    size={getContainerSize(featureAppSize)}
                    visualFeedback={phase !== 'pending'}
                />
            )}
        </LoaderLayout>
    );
}

function InternalCmsFeatureAppLoader(
    props: CmsFeatureAppLoaderProps
): JSX.Element {
    const {
        featureAppId,
        disclaimerLegalEntity,
        featureAppTrackingData,
        globalDisclaimerStore,
        error,
        ssrEnabled = false,
        instanceConfig = '',
        validInstanceConfig = false,
        content
    } = props;

    const navigationStore = useNavigationStore();
    const logger = useLogger();

    // identify if a feature app has been rendered on server side. then it should always been rendered that way
    const initialRender = React.useRef<boolean>(
        useNavigationService().state.initialRender
    );

    if (instanceConfig && !validInstanceConfig) {
        return <FeatureAppConfigValidationError />;
    }

    const errorComponent = getComponentForError(error);
    if (errorComponent) {
        // set visibility to false if there is an error from wrapping component (missing url)
        navigationStore.setInPageItemsVisibility([featureAppId], false);
        return errorComponent;
    }

    if (!isInBrowser() && !ssrEnabled) {
        return <SsrDisabled {...props} />;
    }

    const beforeCreate = (
        env: FeatureAppEnvironment<FeatureServices, any>
    ): void => {
        const trackManager = env.featureServices.tracking as TrackingManagerV1;
        if (trackManager) {
            trackManager.contextualEventData = {
                event: featureAppTrackingData
            };
        }
        globalDisclaimerStore.setScopedDisclaimerManagerToActualPageForFAConsumer(
            featureAppId,
            disclaimerLegalEntity
        );
        if (content) {
            const faContentService = env.featureServices[
                'fa-content-service'
            ] as FaContentServiceV1;
            if (faContentService) {
                try {
                    faContentService.content = JSON.parse(content);
                } catch (e) {
                    logger.featureApps.error(
                        `cannot get feature app content for ${featureAppId}`,
                        e
                    );
                }
            }
        } else {
            logger.featureApps.debug(`no content for ${featureAppId}`);
        }
    };

    if (ssrEnabled && (!isInBrowser() || initialRender.current)) {
        return <SsrEnabled props={props} beforeCreate={beforeCreate} />;
    }

    return <Client props={props} beforeCreate={beforeCreate} />;
}

function getContainerSize(featureAppSize: FeatureAppSize): ContainerSize {
    switch (featureAppSize) {
        case 'SMALL':
            return ContainerSize.SMALL;
        case 'MEDIUM':
            return ContainerSize.MEDIUM;
        case 'LARGE':
            return ContainerSize.LARGE;
        default:
            return ContainerSize.SMALL;
    }
}

// Wrapper to be able to use tracking service via hooks.
export interface CmsFeatureAppLoaderState {
    readonly featureAppError: boolean;
}

export class CmsFeatureAppLoader extends React.Component<
    CmsFeatureAppLoaderProps
> {
    public readonly state: Readonly<CmsFeatureAppLoaderState> = {
        featureAppError: false
    };

    public static getDerivedStateFromError(): CmsFeatureAppLoaderState {
        // Update state so the next render will show the fallback UI.
        return {featureAppError: true};
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.props.logger.featureApps.error(
            `Feature app loading failed. error: ${error}, errorInfo: ${errorInfo}`
        );
    }

    public componentDidUpdate(
        _prevProps: Readonly<CmsFeatureAppLoaderProps>,
        prevState: Readonly<CmsFeatureAppLoaderState>
    ): void {
        const {featureAppError} = this.state;

        if (featureAppError !== prevState.featureAppError) {
            const {onErrorStateChange = noop} = this.props;
            onErrorStateChange(featureAppError);
        }
    }

    render(): React.ReactElement<any> {
        return <InternalCmsFeatureAppLoader {...this.props} />;
    }
}
