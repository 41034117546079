import {OnBindParams} from '@feature-hub/core';
import {Logger} from '../../context/logger/Logger';
import {monitoringHelper} from '../../context/logger/monitoringHelper';

export function onFeatureAppBind(logger: Logger) {
    return (params: OnBindParams) => {
        if (params.featureAppName) {
            monitoringHelper(logger).createFeatureApp(params.featureAppName, {
                dependencies: params.featureAppDefinition.dependencies,
                optionalDependencies:
                    params.featureAppDefinition.optionalDependencies
            });
        }
    };
}
