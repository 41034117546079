import {
    InteractionLayerSizeV2,
    LayerHandleV2,
    LayerManagerV2,
    ShimBackgroundTypeV2,
    CloseHandleV2
} from '@volkswagen-onehub/layer-manager';
import * as React from 'react';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {RichtextSimpleElement} from '../../modules/editorial/elements/RichtextElement';
import {Value, ValueFactory} from '../../utils/contextUtils';
import {DisclaimerStore} from '../disclaimer/DisclaimerStore';
import {DisclaimerStoreContext} from '../disclaimer/DisclaimerStoreContext';
import {GlobalDisclaimerStore} from '../disclaimer/GlobalDisclaimerStore';

export type LayerState = {
    forceClose?: boolean;
};

@singleton('LayerRenderer')
export class LayerRenderer {
    @inject() private globalDisclaimerStore!: GlobalDisclaimerStore;
    @inject() private layerManager!: LayerManagerV2;
    private disclaimerContextValueFactory: ValueFactory<
        DisclaimerStore
    > = new ValueFactory<DisclaimerStore>();
    public renderInteractionLayer(
        includePath: string,
        cqType: string,
        onClose?: CloseHandleV2<LayerState, void>
    ): LayerHandleV2<LayerState> {
        const store = this.globalDisclaimerStore.getLayeredStore(includePath);
        const value = this.disclaimerContextValueFactory.create(store);

        return this.layerManager.openInteractionLayer(
            this.renderLayer(includePath, cqType, value),
            {},
            {
                size: InteractionLayerSizeV2.C,
                shimBackgroundType: ShimBackgroundTypeV2.OPAQUE,
                onClose,
                userCloseable: true
            }
        );
    }
    public renderContentLayer(
        includePath: string,
        cqType: string,
        closeButtonLabel: string,
        contentHeadlinePath: string,
        onClose?: CloseHandleV2<LayerState, void>
    ): LayerHandleV2<LayerState> {
        const store = this.globalDisclaimerStore.getLayeredStore(includePath);
        const value = this.disclaimerContextValueFactory.create(store);
        const contentHeadline = (
            <DisclaimerStoreContext.Provider value={value}>
                <RichtextSimpleElement
                    path={contentHeadlinePath}
                ></RichtextSimpleElement>
            </DisclaimerStoreContext.Provider>
        );

        return this.layerManager.openContentLayerV2(
            this.renderLayer(includePath, cqType, value),
            {},
            {
                closeButtonLabel,
                contentHeadline,
                disableDefaultPadding: false,
                onClose,
                userCloseable: true
            }
        );
    }

    private renderLayer = (
        includePath: string,
        cqType: string,
        disclaimerStoreValue: Value<DisclaimerStore>
    ) => (): JSX.Element => {
        return (
            <DisclaimerStoreContext.Provider value={disclaimerStoreValue}>
                <PersonalizationInclude
                    path={includePath}
                    resourceType={cqType}
                />
            </DisclaimerStoreContext.Provider>
        );
    };
}
