import * as React from 'react';
import styled, {ThemeContext} from 'styled-components';
import {useContentStore, useFeatureToggles} from '../../../context';

import {
    TechnicalData,
    TechnicalDataItem
} from '../../../d6/components/technical-data';
import {Direction, getEndDirection} from '../../../d6/components/helpers';
import {Disclaimer} from '../../../../generated/api';
import {
    CarTechnicalDataSectionModel,
    TechData
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {RichtextDisclaimer} from '../../../components/Richtext';
import {useDisclaimerReferences} from '../../../context/disclaimer/useDisclaimerReferences';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {elseUndefined} from '../../../utils/elseUndefined';
import {SectionWrapper} from './SectionWrapper';
import {Breadcrumbs} from '../../structure/navigation/Breadcrumbs';
import {DisclaimerWithSafeWordsByDisplayTypes} from '../../../components/disclaimers/DisclaimersWithSafewords';
import {CarTechnicalDataSectionV2} from './CarTechnicalDataSectionV2';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {LinkElement} from '../elements/LinkElementComponent';

const StyledTextWithSup = styled.span<{unitBeforeValue?: boolean}>`
    margin-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.unitBeforeValue ? props.theme.size.static100 : ''};
    margin-inline-end: ${props =>
        props.unitBeforeValue ? props.theme.size.static100 : ''};
`;
StyledTextWithSup.displayName = 'StyledTextWithSup';

// reduce additional spacing from stage parsys gutter
const StyledSpacingWrapper = styled.div<{noTechnicalData: boolean}>`
    ${props =>
        props.noTechnicalData
            ? `margin-top: calc(-1 * ${props.theme.size.dynamic0130});`
            : ''};
`;
StyledSpacingWrapper.displayName = 'StyledSpacingWrapper';

interface TextWithDisclaimersProps {
    text: string;
    disclaimers?: Disclaimer[];
    unitBeforeValue?: boolean;
}

function TextWithDisclaimers(props: TextWithDisclaimersProps): JSX.Element {
    const {disclaimers = [], text, unitBeforeValue} = props;
    const disclaimerReferences = useDisclaimerReferences(disclaimers);

    if (!disclaimers || disclaimers.length === 0) {
        return <>{text}</>;
    }

    return (
        <ThemeContext.Consumer>
            {({direction}) =>
                direction === Direction.RTL ? (
                    <StyledTextWithSup unitBeforeValue={unitBeforeValue}>
                        <RichtextDisclaimer
                            disclaimers={disclaimerReferences}
                            addSpace
                        />
                        {text}
                    </StyledTextWithSup>
                ) : (
                    <StyledTextWithSup unitBeforeValue={unitBeforeValue}>
                        {text}
                        <RichtextDisclaimer
                            disclaimers={disclaimerReferences}
                            addSpace
                        />
                    </StyledTextWithSup>
                )
            }
        </ThemeContext.Consumer>
    );
}

function renderItems(technicalData: TechData[]): JSX.Element[] {
    const items: JSX.Element[] = [];

    technicalData.forEach((data, idx) => {
        items.push(
            <TechnicalDataItem
                key={idx}
                name={<TextWithDisclaimers text={data.label} />}
                value={
                    <TextWithDisclaimers
                        text={data.value}
                        disclaimers={data.unit ? [] : data.disclaimers}
                    />
                }
                unit={
                    <TextWithDisclaimers
                        text={data.unit}
                        disclaimers={data.unit ? data.disclaimers : []}
                        unitBeforeValue={data.unitBeforeValue}
                    />
                }
                suffix={data.unitSuffix}
                unitBeforeValue={data.unitBeforeValue}
            />
        );
    });

    return items;
}

/**
 * S128 Car Technical Data Section
 */
function InternalCarTechnicalDataSection(
    props: CarTechnicalDataSectionModel
): JSX.Element | null {
    const {
        anchorId,
        mboxId,
        contentName,
        contentLabels,
        contentId: sectionId,
        showLink,
        technicalData,
        tagTypeBroken
    } = props;
    const link = elseUndefined(showLink, <LinkElement path="link" />);
    const themeContext = React.useContext(ThemeContext);
    const contentStore = useContentStore();
    const stageType = contentStore.getCurrentPageRootModel()?.stageType;
    const isSimpleStageUsed = Boolean(
        stageType?.includes('simpleStageSection')
    );
    const isTechnicalDataAvailable = technicalData.length;

    const breadcrumbsSpacingWithLink = showLink
        ? themeContext.size.static450
        : themeContext.size.static510;
    const breadcrumbsTopSpacing = !isTechnicalDataAvailable
        ? themeContext.size.static250
        : breadcrumbsSpacingWithLink;

    const breadcrumbs = !isSimpleStageUsed ? (
        <Breadcrumbs topSpacing={breadcrumbsTopSpacing} />
    ) : null;

    const content = (
        <SectionWrapper
            anchorId={anchorId}
            sectionId={sectionId}
            contentLabels={contentLabels}
            contentName={contentName}
            mboxId={mboxId}
        >
            <GeneralDisclaimerProvider
                displayTypes={['T4_ITEM_BASED', 'T3_SECTION_BASED']}
            >
                <TechnicalData
                    link={link}
                    disclaimers={
                        <DisclaimerWithSafeWordsByDisplayTypes
                            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                        />
                    }
                >
                    {isTechnicalDataAvailable
                        ? renderItems(technicalData)
                        : null}
                </TechnicalData>
            </GeneralDisclaimerProvider>
        </SectionWrapper>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S128 Car Technical Data Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <StyledSpacingWrapper
                noTechnicalData={!C.isInEditor() && !isTechnicalDataAvailable}
            >
                {isTechnicalDataAvailable || showLink ? content : null}
                {/* Breadcrumbs are normally placed under every stage, but in case of this section use, they are excluded from stage and rendered here. */}
                {breadcrumbs}
            </StyledSpacingWrapper>
        </AuthoringWrapper>
    );
}

const SectionDecider = (
    props: CarTechnicalDataSectionModel
): JSX.Element | null => {
    const {enableCarTechnicalDataRebrush} = useFeatureToggles();

    return enableCarTechnicalDataRebrush ? (
        <CarTechnicalDataSectionV2 {...props} />
    ) : (
        <InternalCarTechnicalDataSection {...props} />
    );
};

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/sections/carTechnicalDataSection';

export const CarTechnicalDataSection = MapTo(RESOURCE_TYPE, SectionDecider);
