import * as React from 'react';

import {VideoPlayerV2} from '../../../../../d6/components/video-player-v2';

import {useCarouselItemContext} from '../../../../../context';
import {C} from '../../../../../registries/compatibilty';
import {MediaIntersectionObserver} from '../../../../../services/video/MediaIntersectionObserver';
import {
    MediaStateMachine,
    ObserverEventTrackingCallbacks
} from '../../../../../services/video/MediaStateMachine';
import {DefaultVideoPlayerProxy} from '../../../../../services/video/VideoPlayerProxy';
import {useDelayedAutoPlay} from './useDelayedAutoPlay';
import {noop} from '../../../../../utils/noop';

export interface VideoStateManagement {
    videoContainerRef: React.RefObject<HTMLDivElement>;
    videoPlayerRef: React.RefObject<VideoPlayerV2>;
    videoPlayerProxy: React.RefObject<DefaultVideoPlayerProxy>;
    handleCoverImageLoadOrError(): void;
}

export interface UseVideoStateManagementProps {
    autoPlay: boolean;
    autoPlayDelayed: boolean;
    autoPause: boolean;
    trackingCallbacks?: ObserverEventTrackingCallbacks;
}

export function useVideoStateManagement({
    autoPlay,
    autoPlayDelayed,
    autoPause,
    trackingCallbacks
}: UseVideoStateManagementProps): VideoStateManagement {
    const videoContainerRef = React.useRef<HTMLDivElement>(null);
    const videoPlayerRef = React.useRef<VideoPlayerV2>(null);
    const videoPlayerProxy = React.useRef(
        new DefaultVideoPlayerProxy(videoPlayerRef)
    );
    const videoStateMachine = React.useRef(
        new MediaStateMachine({
            playerProxy: videoPlayerProxy,
            autoPlay,
            autoPause
        })
    );
    const {active} = useCarouselItemContext();
    videoStateMachine.current.handleCarouselEvent(active);

    const intersectionObserver = React.useRef(
        new MediaIntersectionObserver({
            mediaState: videoStateMachine,
            observedElement: videoContainerRef,
            inEditor: C.isInEditor(),
            trackingCallbacks: trackingCallbacks
        })
    );

    React.useEffect(() => {
        const observer = intersectionObserver.current;
        if ((!autoPlayDelayed || (autoPlayDelayed && !autoPlay)) && observer) {
            observer.start();
        }

        return function cleanUp(): void {
            if (observer) {
                observer.stop();
            }
        };
    }, [intersectionObserver, autoPlayDelayed, autoPlay]);

    const delayedAutoPlay = useDelayedAutoPlay(
        videoContainerRef,
        intersectionObserver
    );

    const handleCoverImageLoadOrError =
        autoPlay && autoPlayDelayed ? delayedAutoPlay : noop;

    return {
        videoContainerRef,
        videoPlayerRef,
        videoPlayerProxy,
        handleCoverImageLoadOrError
    };
}
