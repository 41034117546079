import * as React from 'react';

import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {LayerHandleV2, LayerManagerV2} from '@volkswagen-onehub/layer-manager';
import {ObservableMap, observable} from 'mobx';
import {LayerCloseButton} from '../../components/LayerCloseButton';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {ModelStore} from '../model/ModelStore';
import {LoginAlert} from './LoginAlert';
import {ErrorStatus} from './LoginStatus';

@singleton('LoginAlertService')
export class LoginAlertService {
    public contentPaths: ObservableMap<string, string> = observable.map({});

    @inject() private readonly layerManager!: LayerManagerV2;
    @inject() private readonly loginContentModel!: TransferModel;
    @inject() private readonly modelStore!: ModelStore;

    private alertLayer: LayerHandleV2<void> | null = null;

    public openAlert(errorStatus: ErrorStatus, btnCloseLabel: string): void {
        if (!this.contentPaths.has(errorStatus)) {
            const resourceName = this.getResourceName(errorStatus);
            const model: any = this.loginContentModel[resourceName];
            const contentPath = this.modelStore.insertGlobalContent(
                resourceName,
                model
            );
            this.contentPaths.set(errorStatus, contentPath);
        }

        const closeButton = (
            <LayerCloseButton
                key="close"
                onClick={this.closeAlert}
                btnCloseLabel={btnCloseLabel}
                theme="inverted"
            />
        );

        const renderFn = this.getRenderFn(errorStatus, closeButton);
        this.closeAlert();

        this.alertLayer = this.layerManager.openAlert(renderFn, undefined, {
            id: 'vwa-cms-login-alert',
            userCloseable: true
        });
    }

    public readonly closeAlert = (): void => {
        if (this.alertLayer) {
            this.alertLayer.close();
        }
        this.alertLayer = null;
    };

    private readonly getRenderFn = (
        errorStatus: ErrorStatus,
        closeButton: JSX.Element
    ) => {
        return () => (
            <LoginAlert errorStatus={errorStatus} closeButton={closeButton} />
        );
    };

    private readonly getResourceName = (errorStatus: ErrorStatus): string => {
        switch (errorStatus) {
            case ErrorStatus.authenticationFailed:
                return 'errorAuthFailed';
            case ErrorStatus.loginFailed:
                return 'errorLoginFailed';
            case ErrorStatus.popUpBlocked:
                return 'errorPopUpBlocked';
            default:
                return 'errorUnknownError';
        }
    };
}
