import {AnchorLinkModel, ExternalLinkModel} from '../../../generated/core';
import {InPageMenuItemModel} from './NavigationStore';
import {NavigationTopBarScrollDirection} from './NavigationTopBarScrollDirection';

const SCROLL_DIFF = 15;

export interface ScrollData {
    position: number;
    direction?: NavigationTopBarScrollDirection;
}

export function mapAnchorLinkToInPageItem(
    index: number,
    item: AnchorLinkModel
): InPageMenuItemModel {
    return {
        index,
        id: item.anchorId,
        title: item.inPageNavigationTitle,
        url: `#${item.anchorId}`,
        visible: item.enabledSectionInPageNav
    };
}

export function mapExternalLinkToInPageItem(
    index: number,
    item: ExternalLinkModel
): InPageMenuItemModel {
    return {
        index,
        id: item.url,
        title: item.title,
        url: item.url,
        visible: true
    };
}

export function canUpdateDirection(
    currentScrollPos: number,
    lastScrollData: ScrollData
): boolean {
    return Math.abs(lastScrollData.position - currentScrollPos) > SCROLL_DIFF;
}
