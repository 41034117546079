import {LoginModel} from '../../../generated/core';
import {isSessionTimeoutEnabled} from './isSessionTimeoutEnabled';

/**
 * if sessionTimeout is enabled and a value for rememberMe is set, return true
 * @param loginModel
 * @returns
 */
export const isRememberMeEnabled = (loginModel: LoginModel) => {
    return Boolean(
        isSessionTimeoutEnabled(loginModel) &&
            loginModel.rememberMeSessionTimeout
    );
};
