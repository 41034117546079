import * as React from 'react';

import {CTA, CTAProps} from '@volkswagen-onehub/components-core';

import {BaseCmsLinkProps, useCmsLink} from './CmsLink';

export type CmsButtonLinkProps = BaseCmsLinkProps &
    CTAProps & {children: React.ReactNode};

export function CmsButton(props: CmsButtonLinkProps): JSX.Element {
    const {children, emphasis} = props;

    const ref = React.useRef(null);
    const [onCmsLinkClick] = useCmsLink(ref, props);

    return (
        <CTA
            tag="button"
            emphasis={emphasis}
            onClick={onCmsLinkClick}
            ref={ref}
        >
            {children}
        </CTA>
    );
}
