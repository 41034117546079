import {
    Breakpoints,
    styled,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {useMediaQueries} from '../../../context/mediaQueries/mediaQueryContext';
import {radialGradient} from '../gradient-background';

const DISABLE_TRANSITION_THRESHOLD = 2;
const MEDIA_CONTENT_MATCH = 1;
const PAGE_TOP = 0;

interface StyledMediaWrapperProps {
    mediaDimensions: DOMRect;
}

const StyledMedia = styled.div<StyledMediaWrapperProps>`
    overflow: hidden;
    top: ${props => props.mediaDimensions.top}px;
    left: ${props => props.mediaDimensions.left}px;
    height: ${props =>
        props.mediaDimensions.height === 0
            ? '70vh'
            : props.mediaDimensions.height + 'px'};
    width: ${props =>
        props.mediaDimensions.width === 0
            ? '100vw'
            : props.mediaDimensions.width + 'px'};
`;
StyledMedia.displayName = 'StyledMedia';

const StyledMediaWrapper = styled.div`
    overflow: hidden;
`;

StyledMediaWrapper.displayName = 'StyledMediaWrapper';

const StyledButtonWrapper = styled.div`
    padding-top: calc(${props => props.theme.size.dynamic0020} * 2);
`;
StyledButtonWrapper.displayName = 'StyledButtonWrapper';

const StyledCopyDesktop = styled.div`
    display: none;

    @media (min-width: ${Breakpoints.b560}px) {
        display: initial;
    }
`;
StyledCopyDesktop.displayName = 'StyledCopy';

const StyledContentWrapper = styled.div`
    position: relative;
    background: ${radialGradient};
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: ${props => props.theme.size.dynamic0100};
    padding: ${props => props.theme.size.dynamic0100}
        ${props => props.theme.size.grid002}
        ${props => props.theme.size.dynamic0250};
    place-content: center;
    text-align: center;

    @media (min-width: ${Breakpoints.b560}px) {
        place-content: unset;
        text-align: unset;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        margin-top: unset;
    }
`;
StyledContentWrapper.displayName = 'StyledContentWrapper';

const StyledExtraContentContainer = styled.div`
    padding: ${props => props.theme.size.dynamic0100}
        ${props => props.theme.size.grid002}
        ${props => props.theme.size.dynamic0200}
        ${props => props.theme.size.grid002};

    & span {
        color: ${props => props.theme.colors.content.primary};
    }

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;
StyledExtraContentContainer.displayName = 'StyledExtraContentContainer';

const StyledStickyScrollContainer = styled.div`
    display: grid;
    grid-template-rows: 70vh;
    grid-auto-rows: minmax(min-content, max-content);
    grid-template-columns: repeat(24, 1fr);

    & ${StyledMediaWrapper} {
        grid-row: 1/2;
        grid-column: 1 / 25;
    }

    & ${StyledContentWrapper} {
        grid-row: 2 / 3;
        grid-column: 1 / 25;
    }

    & ${StyledExtraContentContainer} {
        grid-row: 3 / 4;
        grid-column: 1 / 25;
        position: relative;
    }

    @media (min-width: ${Breakpoints.b560}px) {
        & ${StyledContentWrapper} {
            grid-column: 5 / 21;
        }
    }

    @media (min-width: ${Breakpoints.b960}px) {
        & ${StyledContentWrapper} {
            grid-column: 1 / 13;
        }

        & ${StyledExtraContentContainer} {
            grid-row: 2 / 3;
            grid-column: 13 / 25;
        }
    }
`;
StyledStickyScrollContainer.displayName = 'StyledStickyScrollContainer';

interface PageScrollAmountProps {
    scrollTopRef: React.MutableRefObject<HTMLDivElement>;
    initialContentHeight: number;
    initialMediaHeight: number;
    topbarHeight: number;
    announcementBarHeight: number;
    isAnnouncementBarVisible: boolean;
}

export const onScrollMediaTransition = ({
    initialMediaHeight,
    initialContentHeight,
    scrollTopRef,
    topbarHeight,
    announcementBarHeight,
    isAnnouncementBarVisible
}: PageScrollAmountProps): void => {
    if (!scrollTopRef?.current?.style) {
        return;
    }
    const style: Partial<CSSStyleDeclaration> = {};
    const mediaBottom = initialMediaHeight - topbarHeight;
    const contentBottom =
        initialMediaHeight + initialContentHeight - topbarHeight;
    const pageScrollAmount =
        (window.scrollY - announcementBarHeight) / contentBottom;

    if (pageScrollAmount >= DISABLE_TRANSITION_THRESHOLD) {
        style.transform = `translateY(-${contentBottom *
            DISABLE_TRANSITION_THRESHOLD}px)`;
    } else if (pageScrollAmount >= MEDIA_CONTENT_MATCH) {
        style.transform = `translateY(-${window.scrollY -
            initialContentHeight}px)`;
    } else if (pageScrollAmount <= PAGE_TOP) {
        style.transform = 'translateY(0)';
    } else {
        style.transform = `translateY(-${pageScrollAmount * mediaBottom +
            announcementBarHeight}px)`;
    }

    if (isAnnouncementBarVisible) {
        style.position = 'sticky';
    } else {
        style.position = 'fixed';
    }

    Object.assign(scrollTopRef.current.style, style);
};

const TOPBAR_HEIGHT = 52;
const emptyDOMRect: DOMRect = {
    height: 0,
    width: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    x: 0,
    y: 0,
    toJSON: () => {
        //this is intentional
    }
};

type FullscreenStageProps = Readonly<{
    media: React.ReactNode;
    heading: React.ReactNode;
    copy?: React.ReactNode;
    copyMobile?: React.ReactNode;
    button?: React.ReactNode;
    disclaimersOverlay?: React.ReactNode;
    disclaimersInsert?: React.ReactNode;
    isServer: boolean;
    enableStaticLayout?: boolean;
    isAnnouncementBarVisible: boolean;
    announcementBarHeight: number;
}>;

export const FullscreenStage = observer(function FSS(
    props: FullscreenStageProps
): React.ReactElement {
    const {
        copy,
        copyMobile,
        media,
        heading,
        button,
        disclaimersOverlay,
        disclaimersInsert,
        isServer,
        enableStaticLayout = false,
        isAnnouncementBarVisible,
        announcementBarHeight
    } = props;

    const [disableParallax] = React.useState<boolean>(enableStaticLayout);
    const [mediaDimensions, setMediaDimensions] = React.useState(emptyDOMRect);

    const contentRef = React.useRef<HTMLDivElement>(null);
    const mediaRef = React.useRef<HTMLDivElement>(null);
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const initialElementHeightRef = React.useRef({content: 0, media: 0});

    const {isMobile} = useMediaQueries();
    const topbarHeight = isMobile ? 0 : TOPBAR_HEIGHT;

    const onScrollMediaTransitionCallback = () => {
        requestAnimationFrame(() =>
            onScrollMediaTransition({
                initialMediaHeight: initialElementHeightRef.current.media,
                initialContentHeight: initialElementHeightRef.current.content,
                scrollTopRef: scrollRef as React.MutableRefObject<
                    HTMLDivElement
                >,
                topbarHeight,
                announcementBarHeight,
                isAnnouncementBarVisible
            })
        );
    };

    const recalculateDOMPositions = () => {
        if (!mediaRef?.current || !contentRef?.current) {
            return;
        }

        const mediaElementDimensions = mediaRef.current.getBoundingClientRect();
        initialElementHeightRef.current.media = mediaElementDimensions.height;
        initialElementHeightRef.current.content = contentRef.current.getBoundingClientRect().height;

        //deep clone the DOMRect is necessary because it's not a real object
        const mediaElementDimensionsClone = JSON.parse(
            JSON.stringify(mediaElementDimensions)
        );

        //getting and setting the mediaElementWrapper's dimension is also needed so the mediaElement can be positioned like it was in the document flow
        setMediaDimensions({
            ...mediaElementDimensionsClone,
            top: mediaElementDimensionsClone.top + window.scrollY
        });
    };

    React.useEffect(() => {
        if (disableParallax) {
            return;
        }

        recalculateDOMPositions();
        onScrollMediaTransitionCallback();

        window.addEventListener('resize', recalculateDOMPositions);
        window.addEventListener('scroll', onScrollMediaTransitionCallback, {
            passive: true
        });

        return () => {
            window.removeEventListener('resize', recalculateDOMPositions);
            window.removeEventListener(
                'scroll',
                onScrollMediaTransitionCallback
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableParallax, isAnnouncementBarVisible]);

    return (
        <StyledStickyScrollContainer>
            <ThemeProvider theme="inverted">
                <StyledMediaWrapper ref={mediaRef} data-testid="media-wrapper">
                    <StyledMedia
                        style={{position: 'sticky'}}
                        mediaDimensions={mediaDimensions}
                        ref={scrollRef}
                    >
                        {media}
                        {disclaimersOverlay}
                    </StyledMedia>
                </StyledMediaWrapper>

                <StyledContentWrapper
                    ref={contentRef}
                    data-testid="content-wrapper"
                >
                    {heading}
                    <StyledCopyDesktop>
                        {(isServer || !isMobile) && copy}
                    </StyledCopyDesktop>
                    {button && (
                        <StyledButtonWrapper>{button}</StyledButtonWrapper>
                    )}
                    {disclaimersInsert}
                </StyledContentWrapper>
            </ThemeProvider>
            <StyledExtraContentContainer>
                {(isServer || isMobile) && copyMobile}
            </StyledExtraContentContainer>
        </StyledStickyScrollContainer>
    );
});
