import {SpaComponentProps} from '@adobe/cq-react-editable-components';
import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {LayoutSingleColumn} from '../../../generated/core';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {MediaContext} from '../../context/media/MediaContext';
import {ContainerItem} from '../../infrastructure/ContainerExporter';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {ColumnLayoutChildType} from './ColumnLayoutWrapper';
import {C} from '../../registries/compatibilty';
import {getDnDContainer} from '../../utils/container/getDnDContainer';

// contains the resource types of all components to which the media padding is applied (for publish mode)
const MEDIA_PATH = 'vwa-ngw18/components/editorial/items/mediaSingleItem';
const RESOURCE_PATH =
    'vwa-ngw18/components/structure/singleColumnSectionParsys';

const mediaSizes = {
    SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, 80vw`,
    MEDIUM: `(min-width: ${Breakpoints.b560}px) 66vw, 80vw`,
    LARGE: `(min-width: ${Breakpoints.b560}px) 80vw, 100vw`,
    FULL: '100vw'
};

export const StyledItemLayout = styled.div`
    --padding: ${props => props.theme.size.grid002};
    --image-size: 100vw;

    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns:
        [full-start] var(--padding)
        [content-start] 1fr [content-end]
        var(--padding) [full-end];
    row-gap: ${props => props.theme.size.dynamic0100};

    & [data-layout-type='text'] {
        grid-column: content;
    }

    & [data-layout-type='media'] {
        grid-column: full;
        margin-inline: auto;
        width: var(--image-size);
    }

    & [data-size='SMALL'],
    & [data-size='MEDIUM'] {
        --image-size: 80vw;
    }

    @media (min-width: ${Breakpoints.b560}px) {
        --padding: ${props => props.theme.size.grid006};

        & [data-size='SMALL'] {
            --image-size: 50vw;
        }

        & [data-size='MEDIUM'] {
            --image-size: 66vw;
        }

        & [data-size='LARGE'] {
            --image-size: 80vw;
        }
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        --padding: ${props => props.theme.size.grid007};
    }

    // Note: remove once the copy element has no marign
    & [class*='StyledBodyWrapper'] {
        margin-bottom: 0;
    }
`;

export interface ParsysProps extends SpaComponentProps {
    readonly layout: LayoutSingleColumn;
}

export interface InternalSingleColumnSectionParsysExtraProps {
    readonly layout?: LayoutSingleColumn;
    readonly noElement?: boolean;
}

export const InternalSingleColumnSection = (
    props: InternalSingleColumnSectionParsysExtraProps & ParsysProps
) => {
    const {layout} = props;

    const authorOverlay =
        C.isInEditor() && getDnDContainer(RESOURCE_PATH, props);

    return (
        <>
            <StyledItemLayout>
                {getChildItems(props).map((child: ContainerItem) => {
                    const type =
                        MEDIA_PATH === child.model.cqType
                            ? ColumnLayoutChildType.MEDIA
                            : ColumnLayoutChildType.TEXT;
                    const dataAttributes: Record<string, any> = {
                        'data-layout-type': type
                    };

                    if (type === ColumnLayoutChildType.MEDIA) {
                        dataAttributes['data-size'] = layout;
                    }

                    return (
                        <div {...dataAttributes} key={child.key}>
                            <MediaContext.Provider
                                value={{sizes: mediaSizes[layout]}}
                            >
                                <PersonalizationInclude
                                    resourceType={child.model.cqType}
                                    path={child.key}
                                />
                            </MediaContext.Provider>
                        </div>
                    );
                })}
            </StyledItemLayout>
            {authorOverlay}
        </>
    );
};

export const SingleColumnSectionParsys = MapTo<
    InternalSingleColumnSectionParsysExtraProps
>(RESOURCE_PATH, InternalSingleColumnSection);
