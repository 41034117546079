import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {
    IValueDidChange,
    ObservableMap,
    action,
    computed,
    observable
} from 'mobx';

import {NavigationFlyoutAdminV1} from '@volkswagen-onehub/navigation-flyout-service';

import {
    AnchorLinkModel,
    ExternalLinkModel,
    NavigationModel,
    TeaserElementModel
} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {computeLastScrollDirection} from '../../modules/structure/navigation/helpers';
import {ContentStore} from '../content/ContentStore';
import {ModelStore} from '../model/ModelStore';
import {NavigationService, NavigationState} from '../route/NavigationService';
import {RouterService} from '../route/RouterService';
import {TrackingService} from '../tracking/TrackingService';
import {
    canUpdateDirection,
    mapAnchorLinkToInPageItem,
    mapExternalLinkToInPageItem,
    ScrollData
} from './helpers';
import {
    InPageMenuItemModel,
    NavigationStore,
    NavigationStoreId
} from './NavigationStore';
import {NavigationTopBarScrollDirection} from './NavigationTopBarScrollDirection';

interface IntersectionInPageMenuItemModel {
    id: string;
    sectionGroupId?: string;
}

@singleton(NavigationStoreId, {env: 'client'})
export class NavigationStoreClient implements NavigationStore {
    @observable public isTopBarAboveStage: boolean = true;

    @observable
    public lastScrollDirection: NavigationTopBarScrollDirection =
        NavigationTopBarScrollDirection.UP;

    @observable
    public navFlyoutTeaserPath: string = '';

    @observable
    public navFlyoutTeaserSecondLevelPath: string = '';

    private _inPageNavigationItems: ObservableMap<
        string,
        InPageMenuItemModel
    > = observable.map({});

    private _intersectionInPageNavigationItems: ObservableMap<
        string,
        IntersectionInPageMenuItemModel
    > = observable.map({});

    @observable private _inPageNavigationType?: string;
    @observable private _isInPageNavigationOpen: boolean = false;
    @observable private _isFlyoutMenuOpen: boolean = false;
    @observable private _activeInPageNavigationItemId?: string;
    @observable private _activeSectionId?: string;
    @observable private _activeSectionGroupId?: string;
    @observable private _isStaticTopBarActive: boolean = false;
    @observable private _hiddenItems: string[] = [];
    private _stageHeight: number = window.innerHeight;

    @observable private _announcementBarHeight: number = 0;
    @observable private _isAnnouncementBarVisible: boolean = false;

    @inject() private navigationService!: NavigationService;
    @inject() private routerService!: RouterService;
    @inject() private contentStore!: ContentStore;
    @inject() private modelStore!: ModelStore;
    @inject() private navigationModel!: NavigationModel;
    @inject() private trackingService!: TrackingService;

    @inject() private navigationFlyoutAdmin!: NavigationFlyoutAdminV1;

    private ticking: boolean = false;
    private tempScrollData: ScrollData = {
        position: 0,
        direction: undefined
    };

    @postConstruct()
    public initialize(): void {
        this.navigationService.listen(
            (change: IValueDidChange<NavigationState>) => {
                if (
                    change.newValue.pathChanged ||
                    change.newValue.initialRender
                ) {
                    return this.onLoad();
                }
            }
        );
        if (this.navigationModel) {
            const {navFlyoutTeaser} = this.navigationModel;
            this.navFlyoutTeaserPath = this.modelStore.insertGlobalContent(
                'navFlyoutTeaser',
                (navFlyoutTeaser as unknown) as TransferModel
            );
        }
        this.routerService.onNavigate(() => {
            // close flyout if we are using cms links
            this.closeFlyoutMenu();
        });
    }

    @computed
    public get activeInPageNavigationItem(): InPageMenuItemModel | undefined {
        return this._activeInPageNavigationItemId
            ? this._inPageNavigationItems.get(
                  this._activeInPageNavigationItemId
              )
            : undefined;
    }

    @computed
    public get activeInPageNavigationItemTitle(): string {
        const item = this.activeInPageNavigationItem;

        return item ? item.title : '';
    }

    @computed
    public get activeInPageNavigationItemUrl(): string {
        const item = this.activeInPageNavigationItem;

        return item ? item.url : '#';
    }

    @computed
    public get activeInPageNavigationItemId(): string | undefined {
        const item = this.activeInPageNavigationItem;

        return item ? item.id : undefined;
    }

    @computed
    public get activeSectionId(): string | undefined {
        return this._activeSectionId;
    }

    @computed
    public get activeSectionGroupId(): string | undefined {
        return this._activeSectionGroupId;
    }

    @computed
    public get inPageNavigationType(): string | undefined {
        return this._inPageNavigationType;
    }

    @computed
    public get inPageNavigationItems(): InPageMenuItemModel[] {
        return Array.from(this._inPageNavigationItems.values()).map(item => {
            const isHidden = this._hiddenItems.indexOf(item.id) >= 0;
            return {...item, visible: item.visible && !isHidden};
        });
    }

    @computed
    public get visibleInPageNavigationItems(): InPageMenuItemModel[] {
        return this.inPageNavigationItems.filter(_item => _item.visible);
    }

    @computed
    public get isFlyoutMenuOpen(): boolean {
        return this._isFlyoutMenuOpen;
    }

    @computed
    public get isInPageNavigationVisible(): boolean {
        //this is needed here to react on personalization even if we are in a static topbar
        const hasInpageNavSize = this._inPageNavigationItems.size > 0;

        if (this._isStaticTopBarActive) {
            return false;
        }

        return (
            !this._isFlyoutMenuOpen &&
            !this.isTopBarAboveStage &&
            hasInpageNavSize
        );
    }

    @computed
    public get isInPageNavigationOpen(): boolean {
        return this._isInPageNavigationOpen;
    }

    @computed
    public get isMenuLabelVisible(): boolean {
        return (
            (!this._isFlyoutMenuOpen &&
                !this.isInPageNavigationVisible &&
                this.lastScrollDirection ===
                    NavigationTopBarScrollDirection.UP) ||
            (!this.isInPageNavigationVisible &&
                this.lastScrollDirection ===
                    NavigationTopBarScrollDirection.DOWN)
        );
    }

    /**
     * Updates scroll direction.
     */
    @action
    public updateScrollPositionOnAnimationFrame(): void {
        const {scrollY} = window;
        // ticking variable prevents two scroll events perform the changes concurrently at the same time
        if (!this.ticking) {
            window.requestAnimationFrame(() =>
                this.updateScrollPosition(scrollY, this._stageHeight)
            );
            this.lockScroll();
        }
    }

    @action
    public setSecondLevelTeaserData(teaser: TeaserElementModel): void {
        this.navFlyoutTeaserSecondLevelPath = this.modelStore.insertGlobalContent(
            'navFlyoutTeaserSecondLevel',
            (teaser as unknown) as TransferModel
        );
    }
    @action
    public setAnnouncementBarHeight(height: number): void {
        this._announcementBarHeight = height;
    }

    @computed
    public get getAnnouncementBarHeight(): number {
        return this._announcementBarHeight;
    }

    @computed
    public get isAnnouncementBarVisible(): boolean {
        return this._isAnnouncementBarVisible;
    }

    /**
     * In browser environment use exclusively `updateScrollPositionOnAnimationFrame`.
     *
     * @param scrollPosition
     * @param stageHeight
     * @see updateScrollPositionOnAnimationFrame
     */
    @action
    public updateScrollPosition(
        scrollPosition: number,
        stageHeight: number
    ): void {
        if (scrollPosition < 0) {
            // NOTE: due iDevices, where you can drag content out of viewport and get negative scroll position
            scrollPosition = 0;
        }

        const tempScrollData = this.tempScrollData;
        const scrollDirection = computeLastScrollDirection(
            scrollPosition,
            tempScrollData.position,
            this.lastScrollDirection
        );

        if (tempScrollData.direction !== scrollDirection) {
            this.tempScrollData = {
                ...tempScrollData,
                direction: scrollDirection
            };
        }

        // adds scroll buffer of SCROLL_DIFF size to prevent UI updates on tiny scroll changes
        if (canUpdateDirection(scrollPosition, tempScrollData)) {
            // these changes potentially update UI
            this.lastScrollDirection = scrollDirection;
            this.tempScrollData = {
                direction: this.lastScrollDirection,
                position: scrollPosition
            };
        }
        this._isAnnouncementBarVisible =
            scrollPosition <= this._announcementBarHeight;
        this.isTopBarAboveStage = scrollPosition < stageHeight;
        this.unlockScroll();
    }

    @action
    public openFlyoutMenu(): void {
        this._isFlyoutMenuOpen = true;
        this._isInPageNavigationOpen = false;
    }

    @action
    public closeFlyoutMenu(): void {
        this._isFlyoutMenuOpen = false;
        this._isInPageNavigationOpen = false;
    }

    @action
    public openInPageNavigation(): void {
        this._isInPageNavigationOpen = true;
        this.lockScroll();
    }

    @action
    public closeInPageNavigation(): void {
        this._isInPageNavigationOpen = false;
        this.unlockScroll();
    }

    @action
    public toggleInPageNavigation(): void {
        this._isInPageNavigationOpen = !this._isInPageNavigationOpen;
    }

    @action
    private findVisibleTargetItem(
        id: string
    ): [string, InPageMenuItemModel] | undefined {
        return [...this._inPageNavigationItems].find(
            _item => _item[1].id === id && _item[1].visible
        );
    }

    @action
    public findIntersectingTargetItem(
        id?: string
    ): [string, IntersectionInPageMenuItemModel] | undefined {
        return [...this._intersectionInPageNavigationItems].find(
            _item => _item[1].id === id
        );
    }

    @action
    public setInPageNavIntersectionItem(
        id: string,
        sectionGroupId?: string
    ): void {
        const targetItem = this.findVisibleTargetItem(id);

        if (!targetItem) {
            return;
        }

        const itemToUpdate: IntersectionInPageMenuItemModel = {
            id: id,
            sectionGroupId: sectionGroupId
        };

        this._intersectionInPageNavigationItems.set(
            targetItem[0],
            itemToUpdate
        );

        const activeIntersectionItem = this.findIntersectingTargetItem(
            this._activeInPageNavigationItemId
        );

        if (activeIntersectionItem?.[1].sectionGroupId !== id) {
            this.setActiveInPageNavigationItem(id);
        }
    }

    @action
    public removeInPageNavIntersectionItem(id: string): void {
        const targetItem = this.findVisibleTargetItem(id);

        if (!targetItem) {
            return;
        }

        const targetIntersectionItem = this.findIntersectingTargetItem(id);

        if (!targetIntersectionItem) {
            return;
        }
        this._intersectionInPageNavigationItems.delete(targetItem[0]);

        if (this._intersectionInPageNavigationItems.size > 1) {
            return;
        }

        if (this._intersectionInPageNavigationItems.size === 1) {
            const {
                sectionGroupId,
                id: remainingId
            } = this._intersectionInPageNavigationItems.values().next().value;
            this.setActiveInPageNavigationItem(sectionGroupId ?? remainingId);
            return;
        }

        this.setActiveInPageNavigationItem();
    }

    @action
    public setActiveInPageNavigationItem(id?: string): void {
        if (this._inPageNavigationItems.size <= 0 || !id) {
            this._activeInPageNavigationItemId = undefined;

            return;
        }

        const targetItem = this.findVisibleTargetItem(id);

        if (!targetItem) {
            this._activeInPageNavigationItemId = undefined;
        } else if (this._activeInPageNavigationItemId !== targetItem?.[0]) {
            this._activeInPageNavigationItemId = targetItem?.[0];
            this._isInPageNavigationOpen = false;
        }
    }

    @action
    public setActiveSection(sectionId?: string, sectionGroupId?: string) {
        if (this._activeSectionId !== sectionId) {
            this._activeSectionId = sectionId;
        }

        if (this._activeSectionGroupId !== sectionGroupId) {
            this._activeSectionGroupId = sectionGroupId;
        }
    }

    public withScrollLock(callback: () => void): void {
        this.lockScroll();
        try {
            callback();
        } finally {
            setTimeout(() => {
                this.unlockScroll();
            }, 50);
        }
    }

    @action
    public setInPageNavigationType(type?: string): void {
        this._inPageNavigationType = type;
    }

    @action
    public setInPageNavigationItems(
        items?: AnchorLinkModel[] | ExternalLinkModel[] | null
    ): void {
        const _items = items || [];
        const filteredItems = (_items as any).filter(
            (
                inPageItem: AnchorLinkModel | ExternalLinkModel,
                index: number
            ) => {
                const filterBy =
                    'anchorId' in inPageItem
                        ? inPageItem.anchorId
                        : inPageItem.url;
                const itemIndex = (_items as any).findIndex(
                    (entry: AnchorLinkModel | ExternalLinkModel) => {
                        const findIndexBy =
                            'anchorId' in entry ? entry.anchorId : entry.url;
                        return findIndexBy === filterBy;
                    }
                );

                return itemIndex === index;
            }
        );

        this._hiddenItems = [];
        this._inPageNavigationItems.clear();

        filteredItems.forEach(
            (item: AnchorLinkModel | ExternalLinkModel, index: number) => {
                this.setInPageNavigationItem(item, index);
            }
        );
    }

    @action
    public setInPageNavigationItem(
        item: AnchorLinkModel | ExternalLinkModel,
        index: number
    ): void {
        if ('anchorId' in item) {
            const mappedItem = mapAnchorLinkToInPageItem(index, item);
            this._inPageNavigationItems.set(item.anchorId, mappedItem);
        } else {
            const mappedItem = mapExternalLinkToInPageItem(index, item);
            this._inPageNavigationItems.set(item.url, mappedItem);
        }
    }

    @action
    public setInPageItemsVisibility(ids: string[], visible: boolean): void {
        if (!visible) {
            ids.forEach(value => {
                if (this._hiddenItems.indexOf(value) < 0)
                    this._hiddenItems.push(value);
            });
        } else {
            const newList = this._hiddenItems.filter(value => {
                return ids.indexOf(value) < 0;
            });
            if (this._hiddenItems.length !== newList.length) {
                this._hiddenItems = newList;
            }
        }
    }

    isInPageItemVisible(id: string): boolean {
        return this._hiddenItems.indexOf(id) < 0;
    }

    @action
    public onLoad(): void {
        // close flyout if we are going to another cms page (this doesn't handle the refresh the same page case.)
        this.closeFlyoutMenu();
        this.updateScrollPosition(window.scrollY, this._stageHeight);
        this._inPageNavigationItems.clear();
        if (!this.contentStore) {
            return;
        }

        const page = this.contentStore.getCurrentPageRootModel();
        if (!page?.inPageNavigationModel) {
            return;
        }

        this._isStaticTopBarActive = page?.togglesModel?.enableStaticNavigation;

        const {
            inPageLinksAnchor,
            inPageLinksExternal,
            inPageLinksType
        } = page.inPageNavigationModel;

        const isExternalLinks = inPageLinksType === 'external';
        const inPageLinksToSet = isExternalLinks
            ? inPageLinksExternal
            : inPageLinksAnchor;

        if (!inPageLinksToSet.length) {
            this._inPageNavigationItems.clear();
            return;
        }

        const areLinksEqual =
            this._inPageNavigationItems.size === inPageLinksToSet.length &&
            isExternalLinks
                ? (inPageLinksToSet as ExternalLinkModel[]).every(_item =>
                      this._inPageNavigationItems.has(_item.url)
                  )
                : (inPageLinksToSet as AnchorLinkModel[]).every(_item =>
                      this._inPageNavigationItems.has(_item.anchorId)
                  );

        if (!areLinksEqual) {
            this.setInPageNavigationItems(inPageLinksToSet);
        }
        this.setInPageNavigationType(inPageLinksType);
    }

    public setStageHeight(height: number = window.innerHeight): void {
        this._stageHeight = height;
    }

    /**
     * Second level back button click handler
     */
    @action.bound
    public onBackButtonClick(): boolean {
        this.trackingService.trackLinkClick('', 'back');
        if (!this.navigationFlyoutAdmin.hasBackButtonSubscription()) {
            return true;
        }

        const e = this.navigationFlyoutAdmin.fireOnBackButtonClick();

        return !e.isDefaultPrevented();
    }

    private lockScroll(): void {
        this.ticking = true;
    }

    private unlockScroll(): void {
        this.ticking = false;
    }
}
