import {
    CTA,
    CTAProps,
    styled,
    Text,
    TextProps,
    TextTag
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {useRegistry} from '../../context';
import {Registry} from '../../infrastructure/di/Registry';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {
    StyledSafeWord,
    TextWithNonBreakingSafewords
} from '../../utils/TextWithNonBreakingSafewords';

interface TagOveride<T> {
    tag: string;
    component: React.ComponentType<T> | React.ForwardRefExoticComponent<T>;
    propMappings?: string[];
    props?: Partial<T>;
}

const CTAOveride: TagOveride<CTAProps> = {
    tag: 'A',
    component: CTA,
    props: {emphasis: 'tertiary', tag: 'a'},
    propMappings: ['href', 'target']
};

const ParagraphOveride: TagOveride<TextProps> = {
    tag: 'P',
    component: Text,
    props: {tag: TextTag.p}
};

const SpanOveride: TagOveride<TextProps> = {
    tag: 'SPAN',
    component: Text,
    props: {tag: TextTag.span}
};

const SubOveride: TagOveride<TextProps> = {
    tag: 'SUB',
    component: Text,
    props: {sub: true}
};

const SupOveride: TagOveride<TextProps> = {
    tag: 'SUP',
    component: Text,
    props: {sup: true}
};

const StrongOveride: TagOveride<TextProps> = {
    tag: 'STRONG',
    component: Text,
    props: {bold: true}
};
const BoldOveride: TagOveride<TextProps> = {...StrongOveride, tag: 'B'};

const SafewordOveride: TagOveride<TextProps> = {
    tag: 'ABBR',
    component: StyledSafeWord
};

const StyledLinebreak = styled.span<TextProps>`
    ::before {
        // NOTE: fix for Safari linebreak!
        content: ' ';
    }
    ::after {
        content: '\\a';
        white-space: pre;
    }
`;

const LinebreakOveride: TagOveride<TextProps> = {
    tag: 'BR',
    component: StyledLinebreak
};

const defaultOptions: Options = {
    Overides: [
        CTAOveride,
        SpanOveride,
        SubOveride,
        SupOveride,
        LinebreakOveride,
        SafewordOveride,
        ParagraphOveride,
        StrongOveride,
        BoldOveride
    ],
    allowedTags: []
};

export interface Options {
    Overides: TagOveride<any>[];
    allowedTags: string[];
}

export interface Nodes {
    forEach: (
        cb: (
            node: Node,
            idx: number,
            parent: Node[] | NodeListOf<ChildNode>
        ) => void
    ) => void;
}

interface Attribute {
    name: string;
    value: string;
    nodeValue: undefined;
}

export type Node = TextNode | Element;

export interface TextNode {
    nodeName?: string;
    nodeType: number;
}
export interface Element {
    tagName: string;
    attributes: Attributes;
    nodeType: number;
    childNodes: Nodes;
}
export type Attributes = Attribute[] | NamedNodeMap;

export type ParseHtmlFunction = (html: string) => Nodes;

export function parseHtml(html: string, registry: Registry): Nodes {
    if (isInBrowser()) {
        const container = document.createElement('div');
        container.innerHTML = html;
        return container.childNodes;
    }

    const parseDocument: ParseHtmlFunction = registry.getSingleton(
        'ParseHtmlFunction'
    );
    return parseDocument(html);
}

export const HtmlText = (props: {html: string; options?: Options}) => {
    const {options, html} = props;
    const newOptions: Options = {
        Overides: [...defaultOptions.Overides, ...(options?.Overides || [])],
        allowedTags: options?.allowedTags || defaultOptions.allowedTags
    };

    const registry = useRegistry();
    const childNodes = parseHtml(html, registry);
    const elements: React.ReactNode[] = [];
    childNodes.forEach(c => appendNode(c, elements, newOptions));

    return <>{elements}</>;
};

function appendNode(
    node: Node,
    elements: React.ReactNode[],
    options?: Options
) {
    switch (node.nodeType) {
        case 1:
            appendElement(node as Element, elements, options);
            break;
        case 3:
            appendTextNode(node as Text, elements);
            break;
    }
}

function appendElement(
    node: Element,
    elements: React.ReactNode[],
    options?: Options
) {
    const nodeName = node.tagName.toUpperCase();
    const Overide: TagOveride<any> | undefined = options?.Overides?.find(
        o => o.tag === nodeName
    );
    if (Overide) {
        const children = createChildren(node, options);
        elements.push(
            React.createElement(
                Overide.component,
                convertProps(node.attributes, Overide, String(elements.length)),
                children
            )
        );
    } else if (options?.allowedTags.includes(nodeName)) {
        node.childNodes.forEach(c => appendNode(c, elements));
    }
}

function appendTextNode(node: Text, elements: React.ReactNode[]) {
    if (node.nodeValue) {
        const textWithSafeWords = (
            <TextWithNonBreakingSafewords
                key={'text_' + String(elements.length)}
            >
                {node.nodeValue}
            </TextWithNonBreakingSafewords>
        );
        elements.push(textWithSafeWords);
    }
}

function createChildren(node: Element, options?: Options) {
    const children: React.ReactNode[] = [];
    node.childNodes.forEach(c => appendNode(c, children, options));
    return children;
}

function convertProps(
    attributes: Attributes,
    Overide: TagOveride<any>,
    reactKey: string
): React.Attributes | null | undefined {
    const defaultProps: {[key: string]: string} = {
        ...Overide.props,
        key: reactKey
    };
    return (
        Overide.propMappings?.reduce((prev, key) => {
            const attrValue = getAttributeValue(attributes, key);
            if (key && attrValue) {
                prev[key] = attrValue;
            }
            return prev;
        }, defaultProps) || defaultProps
    );
}
function getAttributeValue(attributes: Attributes, key: string) {
    const attrValue =
        'getNamedItem' in attributes
            ? attributes.getNamedItem(key)
            : attributes.find(a => a.name === key);
    return !!attrValue ? attrValue.nodeValue || attrValue.value : undefined;
}
