import * as React from 'react';
import styled from 'styled-components';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {useGlobalConfig} from '../../context';
import {
    CloseableDisclaimersContainer,
    SingleOverlayDisclaimerContainer
} from './helpers';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';

const StyledDisclaimerContainer = styled.div`
    display: flex;
    flex-flow: column;
    position: relative;
    max-height: 100%;
`;
StyledDisclaimerContainer.displayName = 'StyledDisclaimerContainer';

export interface OpenableDisclaimerProps {
    readonly text: string;
    readonly reference?: string;
    readonly closeButtonClick?: () => void;
}

export function OpenableDisclaimer(
    props: OpenableDisclaimerProps
): React.ReactElement {
    const {text, reference, closeButtonClick} = props;
    const {disclaimerLabels} = useGlobalConfig();

    return (
        <StyledDisclaimerContainer>
            <CloseableDisclaimersContainer
                closeDisclaimerLabel={disclaimerLabels.closeDisclaimerLabel}
                onClose={closeButtonClick}
            >
                <CyAttributeAppender name="openableDisclaimer">
                    <SingleOverlayDisclaimerContainer>
                        <DisclaimersWithSafewords
                            disclaimers={[
                                {text, reference, displayType: 'T6_OPENABLE'}
                            ]}
                        />
                    </SingleOverlayDisclaimerContainer>
                </CyAttributeAppender>
            </CloseableDisclaimersContainer>
        </StyledDisclaimerContainer>
    );
}
