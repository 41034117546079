import * as React from 'react';
import {ErrorStatus} from '../../../context/vwid/LoginStatus';
import {LoginStore} from '../../../context/vwid/LoginStore';
import {LoginAlertService} from '../../../context/vwid/LoginAlertService';
import {LoginFlyoutService} from '../../../context/vwid/LoginFlyoutService';

export function createBtnLogoutClick(
    loginStore: LoginStore,
    loginFlyoutService: LoginFlyoutService,
    loginAlertService: LoginAlertService,
    btnCloseAlertLabel: string
) {
    return async (e: React.MouseEvent) => {
        e.preventDefault();
        try {
            await loginStore.handleLogout();
            loginFlyoutService.closeFlyout(true);
        } catch (error) {
            loginAlertService.openAlert(
                ErrorStatus.unknownError,
                btnCloseAlertLabel
            );
        }
    };
}
