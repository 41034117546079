import * as React from 'react';

import {
    FocusLayerSizeV2,
    LayerHandleV2,
    LayerManagerV2
} from '@volkswagen-onehub/layer-manager';
import {ModelClient} from '../../infrastructure/compatibility/ModelClient';
import {inject, singleton} from '../../infrastructure/di/annotations';
import {
    LayerId,
    LoginSessionTimeoutLayer
} from '../../modules/structure/login/LoginSessionTimeoutLayer';
import {Logger} from '../logger/Logger';
import {ModelStore} from '../model/ModelStore';
import {TrackingService} from '../tracking/TrackingService';
import {SpaGlobalConfig} from '../../../generated/core';
import {isSessionTimeoutEnabled} from '../../utils/vwId/isSessionTimeoutEnabled';
import {isRememberMeEnabled} from '../../utils/vwId/isRememberMeEnabled';
import {getCookie} from '../../utils/getCookie';
import {REMEMBER_ME_COOKIE_NAME} from './ClientLoginStore';

@singleton('LoginSessionTimeoutService')
export class LoginSessionTimeoutService {
    @inject() private readonly modelStore!: ModelStore;
    @inject() private readonly layerManager!: LayerManagerV2;
    @inject() private modelClient!: ModelClient;
    @inject() private trackingService!: TrackingService;
    @inject() private logger!: Logger;
    @inject() private spaGlobalConfig!: SpaGlobalConfig;

    private path!: string;
    private layerId!: string;
    private layerModelPath!: string;
    private layerHandle: LayerHandleV2<void> | null = null;

    public closeLayer(shouldTrack: boolean): void {
        if (this.layerHandle) {
            this.layerHandle.close();
        }
        if (shouldTrack) {
            this.trackingService.trackLayerCloseClick(this.layerId);
        }
        this.layerHandle = null;
    }

    public getLayerId(): string | undefined {
        const loginModel = this.spaGlobalConfig.loginModel;

        if (isRememberMeEnabled(loginModel)) {
            const rememberMeContext = getCookie(REMEMBER_ME_COOKIE_NAME);
            return rememberMeContext
                ? LayerId.LOGIN_SESSION_TIMEOUT_ENFORCED
                : LayerId.LOGIN_SESSION_TIMEOUT_EXTENDED;
        } else if (isSessionTimeoutEnabled(loginModel)) {
            return LayerId.LOGIN_SESSION_TIMEOUT;
        } else {
            this.logger.layer.warn(
                "couldn't identify session timeout context."
            );
            return;
        }
    }

    public async openLayer(id: string): Promise<void> {
        this.layerId = id;
        const href =
            this.spaGlobalConfig.appRootPath + '/tools/session-timeout';
        this.path = href + `/jcr:content/root/main/mainParsys/${id}.model.json`;

        const model = await this.modelClient.fetch(this.path);
        if (!model) {
            this.logger.layer.warn(
                "couldn't fetch login session timeout layer data: path=%s",
                this.path
            );
            return;
        }
        this.layerModelPath = this.modelStore.insertGlobalContent(id, model);
        this.layerHandle = this.layerManager.openFocusLayer(
            this.renderSessionTimeoutLayer,
            undefined,
            {
                userCloseable: false,
                size: FocusLayerSizeV2.A,
                onClose: () => this.closeLayer(true)
            }
        );
        this.trackingService.trackLayerLoad(id);
    }

    private readonly renderSessionTimeoutLayer = (): JSX.Element => {
        return (
            <LoginSessionTimeoutLayer
                path={this.layerModelPath}
                onClose={() => this.layerHandle?.close()}
            />
        );
    };
}
