import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeContext} from 'styled-components';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';

import {
    Direction,
    PropsWithDirection,
    createCSSFourValuesPaddingByDirection,
    getStartDirection
} from '../helpers';
import {ChildContainerTabIndexManager} from '../highlight-gallery-v3/tabindex-manager';
import {BulletPagination} from './bullet-pagination';
import {CarouselItemContextProvider} from './context';

type TouchMoveRecord = Readonly<{
    x: number;
    y: number;
    time: number;
    position?: number;
}>;

export type MobileCarouselPropsV2 = Readonly<{
    children?: React.ReactNode;
    activeSlide: number;
    dontShowNextSlide?: boolean;
    maxHeight?: string;
    useWiderSlides?: boolean;
    useDivider?: boolean;
    inactiveSlidesOpaque?: boolean;
    paginationDefaultItemLabel?: string;
    paginationItemLabels?: string[];
    paginationLabel: string;
    galleryLabel: string;
    carouselId: string;
    captions?: JSX.Element[];
    getXOfYLabel(index: number, length: number): string;
    renderPagination?(props: RenderPaginationProps): React.ReactNode;
    renderControls?(props: RenderPaginationProps): React.ReactNode;
    onSlideChanged?(activeSlide: number): void;
}>;

export type PaginationData = Readonly<{
    label: JSX.Element;
    id: string;
    panelId: string;
}>;

export type RenderPaginationProps = Readonly<{
    activeIndex: number;
    paginationData: PaginationData[];
    paginationLabel: string;
    onSlideSelect(index: number): void;
}>;

type MobileCarouselState = Readonly<{
    start?: TouchMoveRecord;
    position: number;
    isIntermediatePosition: boolean;
}>;

type CarouselItemProps = Readonly<{
    active?: boolean;
    numberOfSlides: number;
    dontShowNextSlide?: boolean;
    useWiderSlides?: boolean;
    inactiveSlidesOpaque?: boolean;
}>;

type CarouselInnerProps = Readonly<{
    numberOfSlides: number;
    position: number;
    showTransition: boolean;
    maxHeight?: string;
}>;

type StyledSimpleCarouselWrapperProps = Readonly<{
    useDivider?: boolean;
}>;

const width = (numberOfSlides: number, columns: number = 0) => {
    const fraction = 4.16667; // precomputed from 100 / 24 columns

    return (fraction * columns) / numberOfSlides;
};

// Columns based on Layout of 24 columns
const DEFAULT_SLIDE_WIDTH = 16; // columns
const WIDE_SLIDE_WIDTH = 20; // columns
const NO_PREVIEW_SLIDE_WIDTH = 24; // columns

const START_AND_END_GAP = 2; // columns - space before first / after last slide (for default and wide slides)
const SLIDE_GAP = 1; // columns - space between two slides (for default and wide slides)
const NO_PREVIEW_START_AND_END_GAP = 0; // columns
const NO_PREVIEW_SLIDE_GAP = 0; // columns

function getItemColumns(
    useWiderSlides?: boolean,
    dontShowNextSlide?: boolean
): number {
    const gap = dontShowNextSlide ? NO_PREVIEW_SLIDE_GAP : SLIDE_GAP;
    const slideWidth = !dontShowNextSlide
        ? useWiderSlides
            ? WIDE_SLIDE_WIDTH
            : DEFAULT_SLIDE_WIDTH
        : NO_PREVIEW_SLIDE_WIDTH;

    return slideWidth + gap;
}

const warnPosition = (slide: number) =>
    console.warn(`Can't set position of non existing slide <${slide}>.`);

const calcPosition = (
    slide: number,
    numberOfSlides: number,
    dontShowNextSlide: boolean = false,
    useWiderSlides: boolean = false,
    direction: Direction = Direction.LTR
): number => {
    const isLast = slide === numberOfSlides - 1;
    const slideWidth = getItemColumns(useWiderSlides, dontShowNextSlide);
    const slideMediaWidth = !dontShowNextSlide
        ? useWiderSlides
            ? WIDE_SLIDE_WIDTH
            : DEFAULT_SLIDE_WIDTH
        : NO_PREVIEW_SLIDE_WIDTH;

    // Discrepancy between first slide and all following slides
    const slideDiscrepancy =
        (24 - slideMediaWidth) / 2 -
        (dontShowNextSlide ? NO_PREVIEW_START_AND_END_GAP : START_AND_END_GAP);

    let position = 0;

    if (slide >= numberOfSlides) {
        warnPosition(slide);

        return calcPosition(numberOfSlides - 1, numberOfSlides);
    }

    if (slide < 0) {
        warnPosition(slide);

        return calcPosition(0, numberOfSlides);
    }

    if (direction === Direction.RTL) {
        const initialPositionRtl = 100 - 100 / numberOfSlides;

        if (slide === 0) {
            return initialPositionRtl;
        }

        position =
            initialPositionRtl -
            (slide * width(numberOfSlides, slideWidth) -
                width(numberOfSlides, slideDiscrepancy));

        return !isLast
            ? position
            : position + width(numberOfSlides, slideDiscrepancy);
    }

    if (slide === 0) {
        return position;
    }

    position =
        slide * width(numberOfSlides, slideWidth) -
        width(numberOfSlides, slideDiscrepancy);

    position = !isLast
        ? position
        : position - width(numberOfSlides, slideDiscrepancy);

    return -position;
};

const nextItem = (activeItem: number, numberOfSlides: number) =>
    activeItem < numberOfSlides - 1 ? activeItem + 1 : activeItem;

const prevItem = (activeItem: number) =>
    activeItem > 0 ? activeItem - 1 : activeItem;

const StyledSimpleCarouselWrapper = styled.div<
    StyledSimpleCarouselWrapperProps
>`
    // flex necessary to change order visually
    display: flex;
    flex-direction: column;

    position: relative;
    ${props =>
        props.useDivider &&
        `border-bottom: 1px solid ${props.theme.colors.border.divider};`}
`;

const StyledSimpleCarousel = styled.div`
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    position: relative;
    order: -1; // visually before pagination;
`;

StyledSimpleCarousel.displayName = 'StyledSimpleCarousel';

const StyledCarouselItemFocusWrap = styled.div`
    position: relative;
    height: 100%;

    &:before {
        content: '';
        border: solid 2px ${props => props.theme.colors.focus.main};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        display: none;
    }
`;

const CarouselItem = styled.li.attrs((props: CarouselItemProps) => ({
    style: {
        opacity:
            props.dontShowNextSlide ||
            props.active ||
            props.inactiveSlidesOpaque
                ? 1
                : 0.5
    }
}))<CarouselItemProps>`
    position: relative;
    overflow: hidden;
    width: ${props =>
        width(
            props.numberOfSlides,
            getItemColumns(props.useWiderSlides, props.dontShowNextSlide)
        )}%;
    padding-right: ${props =>
        props.dontShowNextSlide
            ? width(props.numberOfSlides, NO_PREVIEW_SLIDE_GAP)
            : width(props.numberOfSlides, SLIDE_GAP)}%;
    transition: opacity 0.3s cubic-bezier(0.14, 1.12, 0.67, 0.99);
    &:first-child {
        margin-left: ${props =>
            props.dontShowNextSlide
                ? width(props.numberOfSlides, NO_PREVIEW_START_AND_END_GAP)
                : width(props.numberOfSlides, START_AND_END_GAP)}%;
    }

    &:focus {
        outline: none;

        ${StyledCarouselItemFocusWrap} {
            &:before {
                display: block;
            }
        }
    }
`;

const CarouselInner = styled.ul.attrs((props: CarouselInnerProps) => ({
    style: {
        transform: `translate3d(${props.position}%, 0, 0)`,
        transition: `${
            props.showTransition
                ? '0.3s cubic-bezier(0.14, 1.12, 0.67, 0.99)'
                : 'none'
        }`
    }
}))<CarouselInnerProps>`
    list-style: none;
    display: flex;
    flex-direction: ${props =>
        props.theme.direction !== Direction.RTL ? 'row' : 'row-reverse'};
    margin: 0;
    padding: 0;
    position: relative;
    width: ${props => props.numberOfSlides * 100}%;
    overflow: hidden;
`;

const StyledCaptionWrapper = styled.div`
    margin-bottom: ${props => props.theme.size.static400};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 ${props => props.theme.size.static150};
`;
StyledCaptionWrapper.displayName = 'StyledCaptionWrapper';

const StyledCaption = styled.div`
    padding: 0 ${props => props.theme.size.grid003};

    @media (min-width: ${Breakpoints.b560}px) {
        padding: 0 ${props => props.theme.size.grid004};
        text-align: center;
    }

    @media (min-width: ${Breakpoints.b960}px) {
        text-align: ${props => getStartDirection(props.theme.direction)};

        padding: ${props =>
            createCSSFourValuesPaddingByDirection(
                0,
                props.theme.size.static150,
                0,
                props.theme.size.grid002,
                props.theme.direction
            )};
    }
`;
StyledCaption.displayName = 'StyledCaption';

type InternalMobileCarouselPropsV2 = MobileCarouselPropsV2 & PropsWithDirection;

class InternalMobileCarouselV2 extends React.Component<
    InternalMobileCarouselPropsV2,
    MobileCarouselState
> {
    private get numberOfSlides(): number {
        return React.Children.count(this.props.children);
    }

    private readonly carouselWrapper: React.RefObject<HTMLDivElement>;
    private readonly carousel: React.RefObject<HTMLDivElement>;

    private start?: TouchMoveRecord;
    private delta?: TouchMoveRecord;
    private isScrolling?: boolean;

    public constructor(props: InternalMobileCarouselPropsV2) {
        super(props);
        this.carouselWrapper = React.createRef();
        this.carousel = React.createRef();
        this.state = InternalMobileCarouselV2.getDerivedStateFromProps(props);
    }

    public static getDerivedStateFromProps(
        nextProps: InternalMobileCarouselPropsV2,
        prevState?: MobileCarouselState
    ): MobileCarouselState {
        const numberOfSlides = React.Children.count(nextProps.children);
        if (numberOfSlides <= 0) {
            return {
                position: 0,
                isIntermediatePosition: false
            };
        }

        return {
            position:
                prevState &&
                prevState.position &&
                prevState.isIntermediatePosition
                    ? prevState.position
                    : calcPosition(
                          nextProps.activeSlide,
                          numberOfSlides,
                          nextProps.dontShowNextSlide,
                          nextProps.useWiderSlides,
                          nextProps.direction
                      ),
            isIntermediatePosition: prevState
                ? prevState.isIntermediatePosition
                : false
        };
    }

    private readonly preventDefault = (e: Event) => {
        e.stopImmediatePropagation();
        e.returnValue = false;
    };

    private readonly disableScroll = () => {
        const options = {passive: false};
        window.addEventListener('scroll', this.preventDefault, options);
        window.addEventListener('touchmove', this.preventDefault, options);
        window.onwheel = this.preventDefault;
        window.ontouchmove = this.preventDefault;
    };

    private readonly enableScroll = () => {
        window.removeEventListener('scroll', this.preventDefault, false);
        window.removeEventListener('touchmove', this.preventDefault, false);
        window.onwheel = null;
        window.ontouchmove = null;
    };

    private readonly handleSlideStart = (e: React.TouchEvent<HTMLElement>) => {
        this.disableScroll();

        const {position} = this.state;
        const touches = e.touches[0];
        this.start = {
            // get initial touch coords
            x: touches.pageX,
            y: touches.pageY,
            position,
            // store time to determine touch duration
            time: Date.now()
        };
        this.isScrolling = undefined;
    };

    private readonly handleSlideMove = (e: React.TouchEvent<HTMLElement>) => {
        // ensure swiping with one touch and not pinching
        if (e.touches.length > 1 || !this.start) {
            return;
        }

        const {start, carousel, numberOfSlides} = this;
        const {activeSlide} = this.props;
        const touches = e.touches[0];

        const carouselNode = ReactDOM.findDOMNode(carousel.current);
        const carouselWidth =
            carouselNode instanceof Element ? carouselNode.clientWidth : 1;

        const startPosition = start.position || 0;

        let deltaX = touches.pageX - start.x;
        const deltaY = touches.pageY - start.y;

        if (this.isScrolling === undefined) {
            this.isScrolling = Math.abs(deltaX) < Math.abs(deltaY);
        }

        if (this.isScrolling) {
            this.enableScroll();

            this.isScrolling = undefined;
            return;
        }

        const firstAndSlidingLeft = activeSlide === 0 && deltaX > 0;
        const lastAndSlidingRight =
            activeSlide === numberOfSlides - 1 && deltaX < 0;
        const resistanceLevel =
            firstAndSlidingLeft || lastAndSlidingRight
                ? Math.abs(deltaX) / carouselWidth + 1
                : 1; // no resistance if false

        deltaX = deltaX / resistanceLevel;

        const deltaPosition =
            (deltaX / carouselWidth) *
            width(
                numberOfSlides,
                getItemColumns(
                    this.props.useWiderSlides,
                    this.props.dontShowNextSlide
                )
            );

        this.delta = {
            x: deltaX,
            y: deltaY,
            position: deltaPosition,
            time: Date.now()
        };

        const newPosition = startPosition + deltaPosition;
        this.setState(state => ({
            ...state,
            position: newPosition,
            isIntermediatePosition: true
        }));
    };

    private readonly handleSlideEnd = (e: React.TouchEvent<HTMLElement>) => {
        this.enableScroll();

        const {numberOfSlides, delta, start} = this;

        if (!delta || !start || this.isScrolling) {
            return;
        }

        // some sliding done => prevent content of item to be interacted with
        e.preventDefault();
        e.stopPropagation();

        const {
            activeSlide,
            onSlideChanged,
            dontShowNextSlide,
            useWiderSlides,
            direction
        } = this.props;
        const {position = 0} = delta;

        const duration = Date.now() - start.time;

        const absPositionDelta = Math.abs(position);
        const w = width(
            numberOfSlides,
            getItemColumns(useWiderSlides, dontShowNextSlide)
        );
        const nextSlideThreshold = w / 2;

        const isValidSlide =
            (duration < 250 && // if slide duration is less than 250ms
                absPositionDelta > w * 0.1) || // and if slide amt is greater than 10 of slide width%
            absPositionDelta > nextSlideThreshold; // or if slide amt is greater than half the width

        if (isValidSlide) {
            const nextActiveSlide =
                position > 0
                    ? prevItem(activeSlide)
                    : nextItem(activeSlide, numberOfSlides);
            this.setState(state => ({
                ...state,
                position: calcPosition(
                    nextActiveSlide,
                    numberOfSlides,
                    dontShowNextSlide,
                    useWiderSlides,
                    direction
                ),
                isIntermediatePosition: false
            }));
            if (onSlideChanged && activeSlide !== nextActiveSlide) {
                onSlideChanged(nextActiveSlide);
            }
        } else {
            if (Math.abs(delta.x) > 1) {
                this.setState(state => ({
                    ...state,
                    position: calcPosition(
                        activeSlide,
                        numberOfSlides,
                        dontShowNextSlide,
                        useWiderSlides,
                        direction
                    ),
                    isIntermediatePosition: false
                }));
            }
        }

        // clear move event data
        this.start = undefined;
        this.delta = undefined;
    };

    private readonly handleCarouselItemClick = (
        clickedSlideIndex: number,
        event: React.SyntheticEvent<HTMLElement>
    ) => {
        const {activeSlide, onSlideChanged} = this.props;
        if (
            clickedSlideIndex !== activeSlide &&
            onSlideChanged &&
            !this.isScrolling
        ) {
            event.preventDefault();
            event.stopPropagation();
            onSlideChanged(clickedSlideIndex);
        }
    };

    private readonly handlePaginationlItemClick = (
        clickedSlideIndex: number
    ) => {
        const {activeSlide, onSlideChanged} = this.props;

        if (
            clickedSlideIndex !== activeSlide &&
            onSlideChanged &&
            !this.isScrolling
        ) {
            onSlideChanged(clickedSlideIndex);
        }
    };

    /**
     * Handle touch event first for better performance on mobile.
     */
    private readonly handleCarouselItemTouchEnd = (
        clickedSlideIndex: number,
        event: React.TouchEvent<HTMLElement>
    ) => {
        if (!this.delta) {
            this.handleCarouselItemClick(clickedSlideIndex, event);
        }
    };

    private getPanelId(index: number): string {
        return `${this.props.carouselId}-panel-${index}`;
    }

    private getTabId(index: number): string {
        return `${this.props.carouselId}-tab-${index}`;
    }

    private getCaptionId(
        index: number,
        asReference?: boolean
    ): string | undefined {
        const {captions, carouselId} = this.props;

        const captionId = `${carouselId}-caption-${index}`;

        // we should only reference caption if it exists.
        return !asReference || captions?.[index] ? captionId : undefined;
    }

    private readonly getPaginationData = (): PaginationData[] => {
        const {
            paginationItemLabels,
            paginationDefaultItemLabel,
            getXOfYLabel
        } = this.props;

        return Array(this.numberOfSlides)
            .fill(undefined)
            .map((_, index) => {
                const label = (
                    <span key={index}>
                        {paginationItemLabels?.[index] ||
                            paginationDefaultItemLabel}
                        {', '}
                        {getXOfYLabel(index + 1, this.numberOfSlides)}
                    </span>
                );

                return {
                    label,
                    id: this.getTabId(index),
                    panelId: this.getPanelId(index)
                };
            });
    };

    public render(): JSX.Element | null {
        const {
            children,
            activeSlide,
            dontShowNextSlide,
            maxHeight,
            useWiderSlides,
            useDivider,
            inactiveSlidesOpaque,
            renderPagination,
            renderControls,
            captions,
            galleryLabel,
            paginationLabel
        } = this.props;
        const {position, isIntermediatePosition} = this.state;
        const {numberOfSlides} = this;
        if (numberOfSlides <= 0) {
            return null;
        }

        const paginationProps: RenderPaginationProps = {
            paginationData: this.getPaginationData(),
            paginationLabel,
            activeIndex: activeSlide,
            onSlideSelect: this.handlePaginationlItemClick
        };

        return (
            <StyledSimpleCarouselWrapper
                ref={this.carouselWrapper}
                useDivider={useDivider}
                role="group"
                aria-label={galleryLabel}
                aria-roledescription="carousel"
            >
                {/* Pagination is before Slides in HTML tree so pressing tab once will lead to slide. visually it's underneath */}
                {renderPagination ? (
                    renderPagination(paginationProps)
                ) : (
                    <BulletPagination {...paginationProps} />
                )}
                <StyledSimpleCarousel
                    ref={this.carousel}
                    onTouchStart={this.handleSlideStart}
                    onTouchMove={this.handleSlideMove}
                    onTouchEndCapture={this.handleSlideEnd}
                    tabIndex={-1}
                >
                    <ChildContainerTabIndexManager
                        childContainerSelector={'[role="tabpanel"]'}
                        activeChildContainerSelector={`[role="tabpanel"]:nth-child(${activeSlide +
                            1})`}
                    >
                        <CarouselInner
                            position={position}
                            numberOfSlides={numberOfSlides}
                            showTransition={!isIntermediatePosition}
                            maxHeight={maxHeight}
                        >
                            {React.Children.map(children, (child, index) => {
                                const active = index === activeSlide;

                                return (
                                    <CarouselItem
                                        dontShowNextSlide={dontShowNextSlide}
                                        numberOfSlides={numberOfSlides}
                                        active={active}
                                        onClickCapture={e =>
                                            this.handleCarouselItemClick(
                                                index,
                                                e
                                            )
                                        }
                                        onTouchEndCapture={e =>
                                            this.handleCarouselItemTouchEnd(
                                                index,
                                                e
                                            )
                                        }
                                        useWiderSlides={useWiderSlides}
                                        inactiveSlidesOpaque={
                                            inactiveSlidesOpaque
                                        }
                                        tabIndex={active ? 0 : -1}
                                        role={'tabpanel'}
                                        id={this.getPanelId(index)}
                                        aria-labelledby={this.getTabId(index)}
                                        aria-describedby={this.getCaptionId(
                                            index,
                                            true
                                        )}
                                        aria-hidden={!active}
                                    >
                                        <StyledCarouselItemFocusWrap>
                                            <CarouselItemContextProvider
                                                active={active}
                                            >
                                                {child}
                                            </CarouselItemContextProvider>
                                        </StyledCarouselItemFocusWrap>
                                    </CarouselItem>
                                );
                            })}
                        </CarouselInner>
                    </ChildContainerTabIndexManager>
                    {/* Navigation Buttons should come after the panel content for keyboard navigation */}
                    {renderControls && renderControls(paginationProps)}
                </StyledSimpleCarousel>
                {captions ? (
                    <StyledCaptionWrapper>
                        {captions.map((caption, index) => (
                            <StyledCaption
                                hidden={index !== activeSlide}
                                id={this.getCaptionId(index)}
                                key={this.getCaptionId(index)}
                            >
                                {caption}
                            </StyledCaption>
                        ))}
                    </StyledCaptionWrapper>
                ) : null}
            </StyledSimpleCarouselWrapper>
        );
    }
}

export function MobileCarouselV2(props: MobileCarouselPropsV2): JSX.Element {
    return (
        <ThemeContext.Consumer>
            {value => (
                <InternalMobileCarouselV2
                    {...props}
                    direction={value.direction}
                />
            )}
        </ThemeContext.Consumer>
    );
}
