import {PersistentStorageService} from '../context/persistentStorage/PersistentStorageService';
import {
    inject,
    postConstruct,
    singleton
} from '../infrastructure/di/annotations';

const PARAMS = ['campaignID', 'sourceID'];

// Retrieves campaign ID from HTTP get parameter and provides it in the session storage for the third parties (e.g. DCC).
@singleton('HttpParamCampaignService', {env: 'client'})
export class HttpParamCampaignService {
    @inject() private sessionStorage!: PersistentStorageService;

    @postConstruct()
    public init(): void {
        for (const name of PARAMS) {
            const value = this.getParam(name);
            if (value) {
                this.storeParam(name, value);
            }
        }
    }

    public getParam(name: string): string | null {
        const location = new URL(window.location.href);

        return location.searchParams.get(name);
    }

    public storeParam(name: string, value: string): void {
        this.sessionStorage.set(name, value);
    }
}
