import * as React from 'react';
import {TokenTextAppearance, styled} from '@volkswagen-onehub/components-core';
import {BasicStageSectionModel} from '../../../../../generated/core';
import {AuthoringWrapper} from '../../../../components/AuthoringWrapper';
import {ResponsiveMediaRendererConf} from '../../../../components/ResponsiveMediaRenderer';
import {GeneralDisclaimerProvider} from '../../../../context/disclaimer/GeneralDisclaimerProvider';
import {MediaContextData} from '../../../../context/media/MediaContext';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {ButtonElement} from '../../elements/buttonElement/ButtonElement';
import {HeadingElement} from '../../elements/HeadingElement';
import {CopyItem} from '../../items/CopyItem';
import {MediaElement} from '../../elements/MediaElement';
import {SectionWrapper} from '../SectionWrapper';
import {AuthorAlerts} from './AuthorAlerts';
import {
    BASIC_STAGE_HEIGHT_VAR,
    BasicStage
} from '../../../../d6/components/basic-stage';
import {SwappableProps, swappable} from './swappable';
import {BASIC_STAGE_RESOURCE_TYPE} from '../../../../context/bbo/BasicStageData';
import {
    ActionScriptReport,
    PersonalizationData
} from '../../../../context/tracking/TrackingService';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import {createCSSFourValuesPaddingByDirection} from '../../../../d6/components/helpers';
import {UniversalDisclaimerContainer} from '../../../../components/disclaimers/UniversalDisclaimerContainer';

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H1" order="H1" />
);

const copyItem = (
    <CopyItem
        path="copy"
        appearance={TokenTextAppearance.copy200}
        hideItemDisclaimers
    />
);
export interface BasicStageSectionProps
    extends BasicStageSectionModel,
        MediaContextData {}

export interface BasicStageSectionExternalProps extends SwappableProps {}

const responsiveMediaConfigurationV2: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: '(max-aspect-ratio: 1/1)',
        sizes: '100vw',
        aspectRatio: 'r_4_5',
        portray: true
    },
    {
        mediaQuery: '(min-aspect-ratio: 1/1) and (max-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_4_3'
    },
    {
        mediaQuery: '(min-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_16_9'
    }
];

const StyledBasicStageDisclaimerWrapper = styled.div`
    display: flex;
    padding: ${props =>
        createCSSFourValuesPaddingByDirection(
            0,
            props.theme.size.static400,
            props.theme.size.static250,
            0,
            props.theme.direction
        )};
`;

const InternalBasicStageSection = (
    props: BasicStageSectionProps & BasicStageSectionExternalProps
) => {
    const {
        contentLabels,
        contentId: sectionId,
        contentName,
        anchorId,
        showCopy,
        showPrimaryButton,
        showSecondaryButton,
        tagTypeBroken,
        mboxId,
        onAssetLoad,
        actionScriptReports = []
    } = props;

    const responsiveMediaConfiguration = responsiveMediaConfigurationV2;

    const authorAlerts = <AuthorAlerts {...props} />;

    const mediaSize = React.useMemo(() => {
        return C.isInEditor()
            ? window.parent.innerHeight * 0.65 + 'px'
            : `var(${BASIC_STAGE_HEIGHT_VAR})`;
    }, []);

    const media = (
        <CyAttributeAppender name="basicStageMedia">
            <MediaElement
                path="media"
                containerProps={{
                    matchParent: true
                }}
                allowNestedDisclaimers
                onAssetLoad={onAssetLoad}
                useParentAspectRatio
                responsiveMediaConfig={responsiveMediaConfiguration}
                portraitBreakpoints={Breakpoints.b960}
            />
        </CyAttributeAppender>
    );

    const personalizationData = (buttonId: number): PersonalizationData =>
        ({
            actionScriptReports: actionScriptReports.map(
                asr =>
                    ({
                        ...asr,
                        buttonId: buttonId.toString()
                    } as ActionScriptReport)
            )
        } as PersonalizationData);

    const primaryButtonElement = (
        <ButtonElement
            path="primaryButton"
            additionalTrackingData={{personalization: personalizationData(1)}}
        />
    );
    const secondaryButtonElement = (
        <ButtonElement
            path="secondaryButton"
            additionalTrackingData={{personalization: personalizationData(2)}}
        />
    );

    const primaryButton = showPrimaryButton ? primaryButtonElement : null;
    const secondaryButton =
        showPrimaryButton && showSecondaryButton
            ? secondaryButtonElement
            : null;

    const copy = showCopy ? copyItem : null;

    const disclaimers = (
        <UniversalDisclaimerContainer
            customStylingWrapper={StyledBasicStageDisclaimerWrapper}
            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
        />
    );

    const content = (
        <GeneralDisclaimerProvider
            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
        >
            <BasicStage
                media={media}
                mediaSize={mediaSize}
                heading={headingElement}
                copy={copy}
                primaryButton={primaryButton}
                secondaryButton={secondaryButton}
                disclaimers={disclaimers}
            />
        </GeneralDisclaimerProvider>
    );

    const authorView = (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S139 Basic Stage"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {authorAlerts}
            {content}
        </AuthoringWrapper>
    );

    const publishView = (
        <SectionWrapper
            mboxId={mboxId}
            sectionId={sectionId}
            anchorId={anchorId}
            contentLabels={contentLabels}
            contentName={contentName}
        >
            {content}
        </SectionWrapper>
    );

    return C.isInEditor() ? authorView : publishView;
};

export const BasicStageSection = MapTo<BasicStageSectionExternalProps>(
    BASIC_STAGE_RESOURCE_TYPE,
    swappable(InternalBasicStageSection)
);
