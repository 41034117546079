import * as React from 'react';

import {
    VideoPlayerV2,
    VideoPlayerV2DisplayMode,
    VideoPlayerV2PlayState
} from '../../d6/components/video-player-v2';

export interface VideoPlayerProxy {
    readonly isInitialized: boolean;
    readonly isPlaying: boolean;
    readonly isInFullScreen: boolean;
    load(): void;
    play(): void;
    playByIO(): void;
    pause(): void;
    setTime(time: number): void;
    getTime(): number | undefined;
    getPlayer(): VideoPlayerV2 | null;
}

export class DefaultVideoPlayerProxy implements VideoPlayerProxy {
    private player: React.RefObject<VideoPlayerV2>;

    public constructor(player: React.RefObject<VideoPlayerV2>) {
        this.player = player;
    }

    public get isInitialized(): boolean {
        return Boolean(this.player && this.player.current);
    }

    public get isPlaying(): boolean {
        const player = this.getPlayer();

        return (
            !!player &&
            player.state.videoState === VideoPlayerV2PlayState.PLAYING
        );
    }

    public get isInFullScreen(): boolean {
        const player = this.getPlayer();

        return (
            !!player &&
            player.state.displayMode === VideoPlayerV2DisplayMode.FULLSCREEN
        );
    }

    public load(): void {
        const player = this.getPlayer();
        if (player) {
            player.load();
        }
    }

    public play(): void {
        const player = this.getPlayer();
        if (player) {
            player.play();
        }
    }

    public playByIO(): void {
        const player = this.getPlayer();
        if (player) {
            player.playByIO();
        }
    }

    public pause(): void {
        const player = this.getPlayer();
        if (player) {
            player.pause();
        }
    }

    public setTime(time: number): void {
        const player = this.getPlayer();
        if (player) {
            player.setTime(time);
        }
    }

    public getTime(): number | undefined {
        const player = this.getPlayer();
        if (player) {
            return player.getTime();
        }

        return undefined;
    }

    public changeVolume(volume: number): void {
        const player = this.getPlayer();
        if (player) {
            player.changeVolume(volume);
        }
    }

    public getPlayer(): VideoPlayerV2 | null {
        return Boolean(this.player && this.player.current)
            ? this.player.current
            : null;
    }
}
