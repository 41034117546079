import {
    CmsServiceV1Spi,
    defineCmsService
} from '@volkswagen-onehub/cms-service';
import {SpaGlobalConfig} from '../../../generated/core';
import {renderCmsComponent} from './render/renderCmsComponent';
import {C} from '../../registries/compatibilty';
import {HistoryServiceV2} from '@feature-hub/history-service';
import {Registry} from '../di/Registry';
import {HistoryServiceId} from './createHistoryService';

export interface NavigationTarget {
    push: () => boolean;
    replace: () => boolean;
    createHref: () => string;
}

export const createNavigationTarget = (
    config: SpaGlobalConfig,
    registry: Registry
) => (href: string): NavigationTarget => {
    const result = analyseUrl(config, href);
    if (result.type === 'native') {
        return {
            push: () => {
                return false;
            },
            replace: () => {
                return false;
            },
            createHref: () => result.mappedUrl
        };
    }

    const {pathname, hash, search} = new URL(
        result.mappedUrl,
        'https://www.test.de'
    );

    const historyService: HistoryServiceV2 = registry.getSingleton(
        HistoryServiceId
    );
    if (result.type === 'anchor') {
        return {
            push: () => {
                historyService.rootHistory.replace({hash});
                return true;
            },
            replace: () => {
                historyService.rootHistory.replace({hash});
                return true;
            },
            createHref: () => result.mappedUrl
        };
    }

    return {
        push: () => {
            historyService.rootHistory.push({pathname, hash, search});
            return true;
        },
        replace: () => {
            historyService.rootHistory.replace({pathname, hash, search});
            return true;
        },
        createHref: () => result.mappedUrl
    };
};

export type LinkType = 'anchor' | 'spa' | 'native';

const analyseUrl = (
    config: SpaGlobalConfig,
    url: string,
    testScenarioId?: string
): {type: LinkType; mappedUrl: string} => {
    if (url.startsWith('#')) {
        return {
            type: 'anchor',
            mappedUrl: url
        };
    }

    const startsWithSingleSlash = Boolean(url.match(/^\/[^/]/));

    const spa = startsWithSingleSlash && !config.integrator;
    if (config.author && spa) {
        if (!url) {
            return {
                type: 'spa',
                mappedUrl: injectScenarioId(url, testScenarioId)
            };
        }
        if (url.match(/^\/[^/]/)) {
            return {
                type: 'spa',
                mappedUrl:
                    config.marketPath + injectScenarioId(url, testScenarioId)
            };
        }

        return {
            type: 'spa',
            mappedUrl: injectScenarioId(url, testScenarioId)
        };
    }
    return {mappedUrl: url, type: spa ? 'spa' : 'native'};
};

export const mapUrlFactory = (config: SpaGlobalConfig) => {
    return (url: string) =>
        analyseUrl(config, url, config.testScenarioId).mappedUrl;
};

function injectScenarioId(path: string, testScenarioId?: string) {
    if (!testScenarioId) {
        return path;
    }
    const [pathname, extension] = path.split('.');
    return (
        pathname
            .split('/')
            .map((segment, idx) =>
                idx === 1 ? `${segment}-${testScenarioId}` : segment
            )
            .join('/') + (extension ? `.${extension}` : '')
    );
}

export const createCmsService = (
    globalConfig: SpaGlobalConfig,
    registry: Registry
) => {
    const spi: CmsServiceV1Spi = {
        inEditor: C.isInEditor(),

        render: renderCmsComponent,
        mapUrl: mapUrlFactory(globalConfig),
        createNavigationTarget: createNavigationTarget(globalConfig, registry)
    };
    return defineCmsService(spi);
};
