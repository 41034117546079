import * as React from 'react';
import styled from 'styled-components';

const StyledFullScreenContainer = styled.div`
    width: 100%;
    height: 100%;
`;

StyledFullScreenContainer.displayName = 'StyledFullScreenContainer';

export const FullScreenContainer: React.FunctionComponent = props => (
    <StyledFullScreenContainer>{props.children}</StyledFullScreenContainer>
);
