import * as React from 'react';

import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {XfHomepageSliderModel} from '../../../../generated/core';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    Text,
    TokenTextAppearance
} from '@volkswagen-onehub/components-core';

import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {MediaContext} from '../../../context/media/MediaContext';
import {MediaElement} from '../../editorial/elements/MediaElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {ButtonElement} from '../../editorial/elements/buttonElement/ButtonElement';

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H0" order="H1" />
);

const copyDesktopItem = (
    <CopyItem
        path="copy"
        appearance={TokenTextAppearance.bigcopy100}
        hideItemDisclaimers
    />
);

const buttonElement = <ButtonElement path="button" />;

const media = (
    <MediaContext.Provider value={{sizes: '100vw', reduceBrightness: true}}>
        <MediaElement path="media" />
    </MediaContext.Provider>
);

const InternalXfHomepageSlider: React.FunctionComponent<XfHomepageSliderModel> = (
    props: XfHomepageSliderModel
) => {
    const {slideTitle} = props;
    const authorView = (
        <AuthoringWrapper title="Homepage Slider XF">
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    wrap={ContainerWrap.always}
                    padding={{
                        left: ContainerPadding.grid004,
                        right: ContainerPadding.grid004
                    }}
                    stretchContent
                >
                    {headingElement}
                    {copyDesktopItem}
                    {media}
                    {buttonElement}
                    {slideTitle ? (
                        <Text appearance={TokenTextAppearance.copy100}>
                            {slideTitle}
                        </Text>
                    ) : (
                        undefined
                    )}
                </Container>
            </Layout>
        </AuthoringWrapper>
    );

    const publishView = <></>;

    return C.isInEditor() ? authorView : publishView;
};

export const XfHomepageSlider = MapTo(
    'vwa-ngw18/components/structure/xfHomepageSlider',
    InternalXfHomepageSlider
);
