import {
    Breakpoints,
    LayoutConfiguration,
    LayoutConfigurations,
    ThemeDefinition
} from '@volkswagen-onehub/components-core';
import {Direction} from '../helpers';

export interface DirectionalSpacing {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
}

type MoodGalleryItemPadding = DirectionalSpacing | undefined;

export interface MoodGalleryLayout {
    readonly topLane: LayoutConfiguration;
    readonly topLaneOrder: number[];
    readonly bottomLane: LayoutConfiguration;
    readonly itemSpacing: MoodGalleryItemPadding[];
}

const topLaneOrder = [4, 0, 2];
const bottomLane = LayoutConfigurations.half;

export const getLayout = (
    numberOfImages: number,
    theme: ThemeDefinition,
    direction?: Direction
): MoodGalleryLayout | null => {
    switch (numberOfImages) {
        case 2:
            return {
                topLane: {
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24}
                    ],
                    [Breakpoints.b560]: [
                        {name: 'a', columns: 18},
                        {name: 'b', columns: 6}
                    ]
                },
                bottomLane,
                itemSpacing: [undefined, undefined],
                topLaneOrder
            };

        case 3:
            const threeItemsSpacing =
                direction !== Direction.RTL
                    ? {right: theme.size.grid003}
                    : {left: theme.size.grid003};

            return {
                topLane: {
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24}
                    ],
                    [Breakpoints.b560]: [
                        {name: 'a', columns: 16},
                        {name: 'b', columns: 8}
                    ]
                },
                bottomLane: LayoutConfigurations.half,
                itemSpacing: [undefined, threeItemsSpacing, undefined],
                topLaneOrder
            };

        case 4:
            const fourItemsSpacingRight =
                direction !== Direction.RTL
                    ? {right: theme.size.grid003}
                    : {left: theme.size.grid003};
            const fourItemsSpacingLeft =
                direction !== Direction.RTL
                    ? {left: theme.size.grid006}
                    : {right: theme.size.grid006};

            return {
                topLane: {
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24}
                    ],
                    [Breakpoints.b560]: [
                        {name: 'a', columns: 16},
                        {name: 'b', columns: 8}
                    ]
                },
                bottomLane: LayoutConfigurations.half,
                itemSpacing: [
                    undefined,
                    fourItemsSpacingRight,
                    undefined,
                    fourItemsSpacingLeft
                ],
                topLaneOrder
            };

        case 5:
            const fiveItemsSpacingRight =
                direction !== Direction.RTL
                    ? {right: theme.size.grid003}
                    : {left: theme.size.grid003};
            const fiveItemsSpacingLeft =
                direction !== Direction.RTL
                    ? {left: theme.size.grid004}
                    : {right: theme.size.grid004};

            return {
                topLane: {
                    [Breakpoints.default]: [
                        {name: 'a', columns: 24},
                        {name: 'b', columns: 24},
                        {name: 'c', columns: 24}
                    ],
                    [Breakpoints.b560]: [
                        {name: 'a', columns: 6},
                        {name: 'b', columns: 12},
                        {name: 'c', columns: 6}
                    ]
                },
                bottomLane: LayoutConfigurations.half,
                itemSpacing: [
                    undefined,
                    fiveItemsSpacingRight,
                    undefined,
                    fiveItemsSpacingLeft,
                    undefined
                ],
                topLaneOrder
            };

        default:
            console.warn(
                `It's not possible to create layout with #${numberOfImages} images.`
            );

            return null;
    }
};
