import * as React from 'react';
import styled from 'styled-components';

interface PaginationProps {
    currentPageIdx: number;
    navAriaLabel: string;
    previousPageButton: JSX.Element;
    nextPageButton: JSX.Element;
    paginationLinks: JSX.Element[];
}

//the padding here is inspired by the core badge where some magic numbers where used as well.
//these numbers are nearly equal to 32x32 px
const StyledPageNumberWrapper = styled.ul`
    display: flex;
    gap: ${props => props.theme.size.static300};
    padding: 0 ${props => props.theme.size.static200};
`;

const StyledPagination = styled.nav`
    display: inline-flex;
    padding: 0 ${props => props.theme.size.static200};
    align-items: center;
`;
StyledPagination.displayName = 'StyledPagination';

const ellipsisElement = <span aria-hidden="true">...</span>;
const BEFORE_CURRENT = 2;
const AFTER_CURRENT = 2;

export const Pagination = (props: PaginationProps): JSX.Element | null => {
    const [pageNumbers, setPageNumbers] = React.useState<JSX.Element[]>([]);
    const {
        currentPageIdx,
        paginationLinks,
        previousPageButton,
        nextPageButton,
        navAriaLabel
    } = props;

    const totalAmountOfLinks = paginationLinks.length;
    const isTotalAmountEqualEdgesSum = React.useCallback(
        (delta: number = 0) => {
            return (
                totalAmountOfLinks !== AFTER_CURRENT + BEFORE_CURRENT + delta
            );
        },
        [totalAmountOfLinks]
    );

    React.useEffect(() => {
        if (
            totalAmountOfLinks < BEFORE_CURRENT + AFTER_CURRENT + 1 &&
            isTotalAmountEqualEdgesSum()
        ) {
            setPageNumbers(paginationLinks);
        } else {
            const start = Math.max(currentPageIdx - BEFORE_CURRENT, 0);
            const finish = Math.min(
                currentPageIdx + AFTER_CURRENT + 1,
                totalAmountOfLinks
            );

            setPageNumbers(paginationLinks.slice(start, finish));
        }
    }, [
        currentPageIdx,
        totalAmountOfLinks,
        paginationLinks,
        isTotalAmountEqualEdgesSum
    ]);

    const renderLastPageNumber = (): JSX.Element | null => {
        if (
            currentPageIdx + AFTER_CURRENT + 1 < totalAmountOfLinks &&
            isTotalAmountEqualEdgesSum(-1)
        ) {
            return (
                <>
                    {currentPageIdx + AFTER_CURRENT <
                        totalAmountOfLinks - BEFORE_CURRENT && ellipsisElement}
                    {[...paginationLinks].pop()}
                </>
            );
        }

        return null;
    };

    const renderFirstPageNumber = (): JSX.Element | null => {
        if (currentPageIdx > BEFORE_CURRENT) {
            return (
                <>
                    {[...paginationLinks].shift()}
                    {currentPageIdx > BEFORE_CURRENT + 1 && ellipsisElement}
                </>
            );
        }

        return null;
    };

    const lastPageNumber = renderLastPageNumber();
    const firstPageNumber = renderFirstPageNumber();
    const isCurrentPageFirst = currentPageIdx === 0;
    const isCurrentPageLast = currentPageIdx + 1 >= totalAmountOfLinks;

    if (pageNumbers.length === 0) {
        return null;
    }

    return (
        <StyledPagination role="navigation" aria-label={navAriaLabel}>
            {!isCurrentPageFirst && previousPageButton}
            <StyledPageNumberWrapper>
                {firstPageNumber}
                {pageNumbers}
                {lastPageNumber}
            </StyledPageNumberWrapper>
            {!isCurrentPageLast && nextPageButton}
        </StyledPagination>
    );
};
