import * as React from 'react';
import {useRegistry, useRenderService} from '../../../context';
import {Registry} from '../../../infrastructure/di/Registry';
import {getUserInteraction} from './actionHandler';
import {
    ActionParameters,
    UserInteractionHandlerV21
} from '@volkswagen-onehub/user-interaction-service';

export function useActionEnabled(actionUrl: string): boolean {
    const actionsEnabled = useActionsEnabled([actionUrl]);
    return actionsEnabled.get(actionUrl) || false;
}

export function useActionsEnabled(actionUrls: string[]): Map<string, boolean> {
    // since we are only available in the client, we need to make sure to update the ui after hydration
    const hydrating = useRenderService().hydrating;
    const userInteractionHandlers = useUserInteractionHandlers(actionUrls);

    const [actionsEnabled, setActionsEnabled] = React.useState(
        getInitialState(hydrating, userInteractionHandlers)
    );

    React.useEffect(() => {
        for (let userInteractionHandler of userInteractionHandlers.values()) {
            userInteractionHandler.subscribe(() => {
                updateActionsEnabled(
                    userInteractionHandlers,
                    actionsEnabled,
                    setActionsEnabled
                );
            });
            updateActionsEnabled(
                userInteractionHandlers,
                actionsEnabled,
                setActionsEnabled
            );
        }

        return () => {
            userInteractionHandlers.forEach(userInteractionHandler =>
                userInteractionHandler.unsubscribe()
            );
        };
    }, [userInteractionHandlers, actionsEnabled]);
    return actionsEnabled;
}

function useUserInteractionHandlers(actionUrls: string[]) {
    const registry = useRegistry();
    const userInteractionHandlers = React.useRef(
        getUserInteractionHandler(registry, actionUrls)
    );
    return userInteractionHandlers.current;
}

function getUserInteractionHandler(registry: Registry, actionUrls: string[]) {
    return actionUrls.reduce((map, current) => {
        const userInteraction = getUserInteraction(registry, current);
        if (userInteraction) {
            map.set(current, userInteraction);
        }
        return map;
    }, new Map<string, UserInteractionHandlerV21<ActionParameters>>());
}

function getInitialState(
    hydrating: boolean,
    userInteractions: Map<string, UserInteractionHandlerV21<ActionParameters>>
): Map<string, boolean> {
    const result = new Map<string, boolean>();
    for (let action of userInteractions.keys()) {
        const userInteractionHandler = userInteractions.get(action);
        result.set(
            action,
            (!hydrating && userInteractionHandler?.enabled) || false
        );
    }
    return result;
}

function updateActionsEnabled(
    userInteractionHandler: Map<
        string,
        UserInteractionHandlerV21<ActionParameters>
    >,
    actionsEnabledMap: Map<string, boolean>,
    setActionsEnabled: React.Dispatch<
        React.SetStateAction<Map<string, boolean>>
    >
) {
    let update: boolean = false;
    const newActionsEnabledMap = new Map<string, boolean>();
    actionsEnabledMap.forEach((oldActionsEnabled, actionUrl) => {
        const handler = userInteractionHandler.get(actionUrl);
        const newActionsEnabled = handler?.enabled || false;
        newActionsEnabledMap.set(actionUrl, newActionsEnabled);
        update = update || oldActionsEnabled !== newActionsEnabled;
    });
    if (update) {
        setActionsEnabled(newActionsEnabledMap);
    }
}
