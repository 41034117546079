import {css, keyframes} from 'styled-components';
import {
    styled,
    ThemeDefinition,
    Breakpoints
} from '@volkswagen-onehub/components-core';
import {StyledMainNextBestActionElementTypes} from './types';
import {textOverflowEllipsis} from '../helpers';

const keyframesSubActionsShow = (percents: number, pixels: number) => keyframes`
0%, 50% { transform: translateY(${percents}%) translateY(+${pixels}px); }
100% { transform: translateY(0); }
`;

const keyframesSubActionsHide = (percents: number, pixels: number) => keyframes`
  0% { transform: translateY(0); }
50%, 100% { transform: translateY(${percents}%) translateY(+${pixels}px); }
`;

const TRANSITION_TIME = '0.2s';

// NOTE: handle how to re-use in keyframes
export const height = (props: {theme: ThemeDefinition}) =>
    `${props.theme.size.static510}`;

const borderRadius = (props: {theme: ThemeDefinition}) =>
    `${
        /* 1/2 height */
        parseInt(props.theme.size.static350, 10) / 2 +
            parseInt(props.theme.size.static300, 10)
    }px`;

const styledInnerIconWrapperHeight = (props: {theme: ThemeDefinition}) =>
    `${
        /* icon size plus top and bottom borders */
        parseInt(props.theme.size.static350, 10) +
            parseInt(props.theme.size.static200, 10) * 2
    }px`;

const borderRadiusInnerIconWrapper = (props: {theme: ThemeDefinition}) =>
    `${
        /* 1/2 height */
        parseInt(props.theme.size.static350, 10) / 2 +
            parseInt(props.theme.size.static200, 10)
    }px`;

export const StyledOverflowWrapper = styled.div`
    direction: ${props => props.theme.direction || 'ltr'};
    margin-inline-start: auto;
    overflow: hidden;
    /* Safari does not correctly cut off overflowing content with only "overflow: hidden;" */
    clip-path: inset(0 0 0 0);
    border-radius: ${borderRadius};
    width: 100%;
`;

export const StyledTextWrapper = styled.div<{
    readonly expanded?: boolean;
}>`
    > div {
        ${textOverflowEllipsis}
    }

    pointer-events: none;
    height: ${height};
    ${props =>
        props.theme.direction !== 'ltr'
            ? css`
                  padding-right: ${parseInt(props.theme.size.static350, 10) +
                      parseInt(props.theme.size.static200, 10) +
                      parseInt(
                          props.theme.size.static200,
                          10
                      )}px; /* icon size plus large outer and small inner border */
                  padding-left: ${props.theme.size.static250};
              `
            : css`
                  padding-left: ${parseInt(props.theme.size.static350, 10) +
                      parseInt(props.theme.size.static200, 10) +
                      parseInt(
                          props.theme.size.static200,
                          10
                      )}px; /* icon size plus large outer and small inner border */
                  padding-right: ${props.theme.size.static250};
              `}

    border-radius: ${borderRadius};
    background-color: transparent;
    flex: auto 0 0;
    display: flex;
    align-items: center;
    opacity: 0;
    margin-top: -1px;
`;

export const StyledIconWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: auto 0 0;
    height: ${height};
    justify-content: center;
    position: absolute;
    opacity: 1;
    pointer-events: none;

    ${({theme}) => css`
        border: ${theme.size.static300} solid;
        border-color: transparent;
        border-radius: ${borderRadius};
        top: -2px;
        left: ${theme.direction !== 'ltr' ? 'unset' : '-2px'};
        right: ${theme.direction !== 'ltr' ? 0 : 'unset'};
        // TODO:
        transition: border-color ${theme.animation.duration.nba}
            ${theme.animation.timingFunction.cubicOut};
        width: ${parseInt(theme.size.static350, 10) +
            parseInt(theme.size.static300, 10) * 2}px; /* same as height */
    `}
`;

export const StyledDecorativeIconWrapper = styled.div<{
    readonly hideIcon?: boolean;
}>`
    background-color: transparent;
    height: 100%;
    width: 100%;
    pointer-events: none;
`;

export const StyledInnerIconWrapper = styled.div`
    height: ${styledInnerIconWrapperHeight};
    width: ${styledInnerIconWrapperHeight};
    background-color: transparent;
    border: ${props => props.theme.size.static200} solid;
    border-radius: ${borderRadiusInnerIconWrapper};
    border-color: transparent;
    line-height: 0;
    position: absolute;
    ${props =>
        props.theme.direction === 'rtl'
            ? `
            right: -${props.theme.size.static300};
          `
            : `
            left: -${props.theme.size.static300};
          `}
    top: -${props => props.theme.size.static300};
    opacity: 1};
`;

const stylesForLandscapeNbabView = (theme: ThemeDefinition) => css`
    width: 60vw;
    direction: ${theme.direction === 'rtl' ? 'ltr' : 'rtl'};
    transform: translate(
        calc(
            ${theme.direction === 'rtl' ? '' : '-'} ${height}
                ${theme.direction === 'rtl' ? '+' : '-'} ${theme.size.static150}
        ),
        calc(${height} + ${theme.size.static150})
    );
`;

export const StyledSubActionWrapper = styled.div<{
    readonly isHidden?: boolean;
    readonly showStylesForLandscape?: boolean;
}>`
    position: absolute;
    bottom: ${height};

    @media only screen and (orientation: landscape) and (max-width: ${Breakpoints.b960}px) {
        ${({theme}) => stylesForLandscapeNbabView(theme)}
    }

    ${props =>
        props.showStylesForLandscape && stylesForLandscapeNbabView(props.theme)}

    ${props =>
        props.isHidden &&
        `
        a, button {
            pointer-events: none;
        }
        `}
`;

const generalMainNextBestActionButtonStyles = css<
    StyledMainNextBestActionElementTypes
>`
    appearance: none;
    background-color: transparent;
    border: 2px solid transparent;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    min-width: ${height};
    max-width: ${height};
    height: ${height};
    width: auto;
    padding: 0;
    position: relative;
    text-decoration: none;
    outline: 0;

    /* visibility transition animation */
    visibility: ${({show}) => (show ? 'visible' : 'hidden')};
    transition: visibility ${TRANSITION_TIME};

    ${({theme}) => css`
            transition: max-width ${TRANSITION_TIME} ease-in, width ${TRANSITION_TIME} ease-in; /* button open/close animation */
            color: ${theme.colors.content.invertedOnSurface};
            border-radius: ${borderRadius};
            ${
                theme.direction === 'rtl'
                    ? 'margin-right: auto;'
                    : 'margin-left: auto;'
            }
            background-color: ${props =>
                props.theme.colors.accent.nbab.default};
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.2);
        `}

    ${StyledTextWrapper} {${() =>
    css`
        transition: opacity ${TRANSITION_TIME} ease-in-out; /* text/button open/close animation */
    `};
    }

    ::before {
      content: '';
      position: absolute;
      ${({theme}) => (theme.direction === 'rtl' ? 'left: 0;' : 'right: 0;')}
      z-index: 1;
      width: ${height};
      height: 100%;
      border-radius: ${borderRadius};
      pointer-events: none;
    }

    /*  expanded button by default */
    ${({theme, expanded}) =>
        expanded &&
        css`
            max-width: calc(100vw - (2 * ${theme.size.dynamic0100}));
            transition: max-width ${TRANSITION_TIME} ease-in,
                width ${TRANSITION_TIME} ease-in; /* button open/close animation */

            ${StyledTextWrapper} {
                transition: opacity ${TRANSITION_TIME} ease-in-out; /* text/button open/close animation */
                opacity: 1;
            }

            :hover {
                background-color: ${theme.colors.accent.nbab.hover};
                color: ${theme.colors.content.inverted};

                ${StyledTextWrapper} {
                    color: ${theme.colors.content.inverted};
                }
            }
        `}
    }

    /* expand NBAB on icon hover if NBAB is not expanded */
    :hover,
    :focus {
        @media only screen and (min-width: ${Breakpoints.b560 + 1}px) {
            ${({theme, show, expanded}) =>
                show &&
                !expanded &&
                css`
                    max-width: calc(100vw - (2 * ${theme.size.dynamic0100}));
                    transition: max-width ${TRANSITION_TIME} ease-in,
                        width ${TRANSITION_TIME} ease-in; /* button open/close animation */
                    background-color: ${theme.colors.accent.nbab.hover};
                    color: ${theme.colors.content.inverted};
                `};
        }
    }

    ${StyledTextWrapper} {
      ${() =>
          css`
              transition: opacity ${TRANSITION_TIME} ease-in-out; /* text/button open/close animation */
              opacity: 1;
          `};
    }

    :focus {
        ${({theme}) => css`
            border: 2px solid ${theme.colors.focus.accent};
        `}
    }

    :active {
        background-color: ${({theme}) => theme.colors.accent.nbab.active}
    }
`;

export const StyledMainNextBestActionButton = styled.button<
    StyledMainNextBestActionElementTypes
>`
    ${generalMainNextBestActionButtonStyles}
`;

export const StyledMainNextBestActionLink = styled.a<
    StyledMainNextBestActionElementTypes
>`
    ${generalMainNextBestActionButtonStyles}
`;

export const StyledCloseButton = styled.button<{
    readonly show?: boolean;
}>`
    ${({theme}) => css`
        ${theme.direction === 'rtl' ? 'left: 0;' : 'right: 0;'}
        color: ${theme.colors.content.primary};
        background-color: ${theme.colors.background.primary};
        border-radius: ${borderRadius};
        border: solid 1px ${theme.colors.button.secondary.default};

        &:hover {
            background-color: ${theme.colors.button.primary.hover};
            color: ${theme.colors.content.inverted};
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 1px ${theme.colors.button.secondary.default};
        }
    `}

    ${({show}) => css`
        visibility: ${show ? 'visible' : 'hidden'};
        opacity: ${show ? '1' : '0'};
    `}

    appearance: none;
    box-shadow: none;
    cursor: pointer;
    pointer-events: auto;
    display: flex;
    min-height: ${height};
    min-width: ${height};
    padding: 0;
    position: absolute;
    text-decoration: none;
    align-items: center;
    justify-content: center;
`;

export const StyledSubactionsNextBestActionButton = styled.div<{
    readonly endIndex: number;
    readonly show?: boolean;
    readonly key: number;
}>`
    width: 100%;
    ${({theme}) => css`
        margin: ${theme.size.static150} 0 0 0;
        transition: opacity ${theme.animation.duration.nba}
            ${theme.animation.timingFunction.cubicOut};

        &:last-child {
            margin-bottom: ${theme.size.static150};
        }
    `}
    ${({show, theme}) => css`
        visibility: ${show ? 'visible' : 'hidden'};
        opacity: ${show ? '1' : '0'};
        transition-delay: ${show ? theme.animation.duration.nba : 0};

        & ${StyledMainNextBestActionButton} {
            pointer-events: ${show ? 'auto' : 'none'};
        }
    `}

    ${({show, endIndex, theme}) =>
        css`
            animation: ${(show
                    ? keyframesSubActionsShow
                    : keyframesSubActionsHide)(
                    endIndex * 100,
                    Number(theme.size.static100.replace('px', '') || 0) *
                        endIndex *
                        2
                )}
                ${theme.animation.duration.collapsible}
                ${theme.animation.timingFunction.cubicOut};
        `}


    @media only screen and (orientation:landscape) and (max-width: ${
        Breakpoints.b960
    }px) {
        display: inline-block;
        width: auto;
        margin-left: ${({theme}) => theme.size.static150};
    }
`;

export const StyledNextBestActionButtonWrapper = styled.div<{
    readonly expanded?: boolean;
}>`
    isolation: isolate;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${({theme}) =>
        theme.direction === 'rtl'
            ? 'margin-right: auto;'
            : 'margin-left: auto;'};
    width: auto;
    pointer-events: none;
`;

export const StyledBadge = styled.div`
    ${({theme}) => css`
        background-color: ${theme.colors.background.primary};
        border-color: ${theme.colors.button.primary.default};
        border: ${theme.size.border.heavy} solid;
        min-height: ${theme.size.static300};
        min-width: ${theme.size.static300};
        ${theme.direction === 'rtl' ? 'left: 0' : 'right: 0'};
    `}
    border-width: 1px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -6px;
    z-index: 1;
`;

export const StyledBadgeText = styled.div`
    ${({theme}) => css`
        color: ${theme.colors.content.primary};
        font-size: ${theme.size.static200};
        line-height: ${theme.size.static200};
    `}
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
