import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {getEndDirection} from '../../helpers';

export interface MarketLogoProps {
    readonly children?: React.ReactNode;
}

const LOGO_HEIGHT = 45;

const StyledMarketLogo = styled.div`
	margin-top: ${props => props.theme.size.static250};
	height: ${LOGO_HEIGHT}px;

	img {
		width: auto;
		height: 100%;

		@media (min-width: ${Breakpoints.b560}px) {
			margin-${props => getEndDirection(props.theme.direction)}: auto;
		}
	}
`;
StyledMarketLogo.displayName = 'StyledMarketLogo';

export function MarketLogo(props: MarketLogoProps): React.ReactElement {
    const {children} = props;

    return <StyledMarketLogo>{children}</StyledMarketLogo>;
}
