import {
    ActionParameters,
    UserInteractionHandlerV21,
    UserInteractionServiceV21
} from '@volkswagen-onehub/user-interaction-service';
import * as React from 'react';
import {Registry} from '../../../infrastructure/di/Registry';
import {createLinkName} from '../createLinkName';
import {createStringParams} from './createStringParams';
import {LinkHandlerProps} from '../LinkHandlers';
import {parseActionUrl} from './parseActionUrl';

export const getUserInteraction = (
    registry: Registry,
    href?: string
): UserInteractionHandlerV21<ActionParameters> | undefined => {
    if (!href) {
        return undefined;
    }

    const userInteractionService: UserInteractionServiceV21 = registry.getSingleton(
        'UserInteractionService'
    );

    const {interaction} = getActionByHref(userInteractionService, href);

    return interaction;
};

export const actionHandler = (
    href: string,
    props: LinkHandlerProps
): [React.EventHandler<React.SyntheticEvent>, string] | undefined => {
    const {registry} = props;
    const userInteractionService: UserInteractionServiceV21 = registry.getSingleton(
        'UserInteractionService'
    );

    // get user interaction
    const {search, actionId, interaction} = getActionByHref(
        userInteractionService,
        href
    );

    if (interaction) {
        // create parameters from search string
        const params = new URLSearchParams(search);
        const paramObject = createStringParams(params);

        // return event handler and user interaction href
        return [
            (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                if (interaction.enabled) {
                    const actionHref = getHref(
                        interaction,
                        href,
                        paramObject,
                        params
                    );
                    trackActionClick(props, actionId || '', actionHref);
                    interaction.onActivate(paramObject, params);
                }
            },
            getHref(interaction, href, paramObject, params)
        ];
    }

    return undefined;
};

function getHref(
    interaction: UserInteractionHandlerV21<ActionParameters>,
    href: string,
    paramObject: ActionParameters,
    params: URLSearchParams
) {
    return interaction.getHref(paramObject, params) || href || '#';
}

function trackActionClick(
    props: LinkHandlerProps,
    actionId: string,
    actionHref: string
) {
    const {baseLinkProps, linkRef, trackingService, trackingData} = props;
    const {contentId, trackingActionOverride} = baseLinkProps;

    const linkName =
        baseLinkProps.linkName ||
        (linkRef && createLinkName(linkRef)) ||
        actionId;
    if (trackingActionOverride) {
        trackingActionOverride(actionHref, linkName, props.trackingData || {});
    } else {
        trackingService.trackLinkClick(
            actionHref,
            contentId,
            linkName,
            trackingData
        );
    }
}

export function getActionByHref(
    userInteractionService: UserInteractionServiceV21,
    href?: string
) {
    if (!href) {
        return {};
    }

    const {actionId, search} = parseActionUrl(href);
    if (!actionId) {
        return {};
    }

    const interaction = userInteractionService.get(actionId);
    if (!interaction) {
        return {};
    }

    return {interaction, actionId, search};
}
