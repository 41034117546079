import {
    Breakpoints,
    css,
    getGridColumn,
    styled
} from '@volkswagen-onehub/components-core';
import {ArrowRight, OpenInLayer} from '@volkswagen-onehub/icons-core';
import * as React from 'react';
import {
    CarTechnicalDataSectionModel,
    Disclaimer,
    LinkElementModel
} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {RichtextDisclaimer} from '../../../components/Richtext';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useDisclaimerReferences} from '../../../context/disclaimer/useDisclaimerReferences';
import {
    Direction,
    getEndDirection,
    getStartDirection
} from '../../../d6/components/helpers';
import {HighlightedDataTile} from '../../../d6/components/technical-data/HighlightedDataTile';
import {TechnicalDataList} from '../../../d6/components/technical-data/TechnicalDataList';
import {TechnicalDataTile} from '../../../d6/components/technical-data/TechnicalDataTile';
import {useDirection} from '../../../hooks/useDirection';
import {C} from '../../../registries/compatibilty';
import {LinkElement} from '../elements/LinkElementComponent';
import {InfoButton} from './car-technical-data/InfoButton';
import {SectionWrapper} from './SectionWrapper';
import {useContentStore} from '../../../context';
import {Breadcrumbs} from '../../structure/navigation/Breadcrumbs';
import {UniversalDisclaimerContainer} from '../../../components/disclaimers/UniversalDisclaimerContainer';

const addScrollShadow = () => css`
    &:before,
    &:after {
        position: absolute;
        grid-row: 2/3;
        top: 0;
        content: '';
        min-width: calc(var(--padding-inline) - var(--gap));
        height: 100%;
        pointer-events: none;
    }

    &:before {
        inset-inline-start: 0;
        background: linear-gradient(
            to ${props => getEndDirection(props.theme.direction)},
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }

    &:after {
        inset-inline-end: 0;
        background: linear-gradient(
            to ${props => getStartDirection(props.theme.direction)},
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }
`;

const StyledLinkWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.size.static200};

    & > svg {
        fill: ${props => props.theme.border.color.primary};
        ${props =>
            props.theme.direction === Direction.RTL
                ? 'transform: rotate(180deg)'
                : ''}
    }
`;

const StyledHighlightContainer = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: ${props => props.theme.size.static300};
    margin: 0;
    padding: 0;
`;

// reduce additional spacing from stage parsys gutter
const StyledSpacingWrapper = styled.div<{noTechnicalData: boolean}>`
    ${props =>
        props.noTechnicalData
            ? `margin-top: calc(-1 * ${props.theme.size.dynamic0130});`
            : ''};
`;
StyledSpacingWrapper.displayName = 'StyledSpacingWrapper';

const StyledLayout = styled.div`
    --gap: ${props => props.theme.size.static200};
    --padding-inline: ${getGridColumn(2)};

    ${addScrollShadow()}

    & {
        position: relative;
        display: grid;
        grid-template-columns: var(--padding-inline) 1fr var(--padding-inline);
        grid-row-gap: ${props => props.theme.size.dynamic0100};
    }

    & > * {
        grid-column: 1/4;
    }

    & ${StyledLinkWrapper}, & ${StyledHighlightContainer} {
        grid-column: 2/3;
    }
`;

const StyledDisclaimerRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    flex-grow: 1;

    & sup {
        top: 0;
    }

    & sup:first-of-type {
        margin: 0;
    }

    & sup[data-component='disclaimer-reference-badge'] {
        padding-block: ${props => props.theme.size.static150};
    }
`;
StyledDisclaimerRow.displayName = 'StyledDisclaimerRow';

interface DisclaimerRowProps {
    disclaimers?: Disclaimer[];
}

export const DisclaimerRow = (
    props: DisclaimerRowProps
): JSX.Element | null => {
    const {disclaimers = []} = props;
    const disclaimerReferences = useDisclaimerReferences(disclaimers);

    if (disclaimers.length === 0) {
        return null;
    }

    return (
        <StyledDisclaimerRow>
            <RichtextDisclaimer disclaimers={disclaimerReferences} addSpace />
        </StyledDisclaimerRow>
    );
};

const StyledTechnicalDataDisclaimerWrapper = styled.div`
    padding-inline: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid006};
    }
`;

/**
 * S128 Car Technical Data Section
 */
export const CarTechnicalDataSectionV2 = (
    props: CarTechnicalDataSectionModel
): JSX.Element | null => {
    const {
        anchorId,
        mboxId,
        contentName,
        contentLabels,
        contentId: sectionId,
        showLink,
        technicalData,
        highlightedTechnicalData,
        tagTypeBroken,
        moreInfoLabel,
        infoErrorCloseLabel,
        infoErrorText
    } = props;
    const isTechnicalDataAvailable = Boolean(technicalData.length);
    const isSimpleStageUsed = useContentStore()
        .getCurrentPageRootModel()
        ?.stageType?.includes('simpleStageSection');
    const direction = useDirection();

    const linkItem = props.cqItems.link;
    const linkIcon = linkItem.layerLink ? (
        <OpenInLayer ariaHidden />
    ) : (
        <ArrowRight ariaHidden />
    );
    const isDownloadLink = linkItem.downloadLink as LinkElementModel;

    const content = (
        <SectionWrapper
            mboxId={mboxId}
            anchorId={anchorId}
            sectionId={sectionId}
            contentLabels={contentLabels}
            contentName={contentName}
        >
            <GeneralDisclaimerProvider
                displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
            >
                <StyledLayout>
                    {highlightedTechnicalData.length ? (
                        <StyledHighlightContainer>
                            {highlightedTechnicalData.map(tile => (
                                <HighlightedDataTile
                                    {...tile}
                                    key={tile.key}
                                    direction={direction}
                                />
                            ))}
                        </StyledHighlightContainer>
                    ) : null}

                    <TechnicalDataList>
                        {technicalData.map((data, index) => (
                            <TechnicalDataTile
                                {...data}
                                key={data.key}
                                direction={direction}
                                disclaimers={
                                    <DisclaimerRow
                                        disclaimers={data.disclaimers}
                                    />
                                }
                                infoLabelButton={
                                    data.infoLayerPath ? (
                                        <InfoButton
                                            {...data}
                                            id={data.key}
                                            dataCount={technicalData.length}
                                            dataItemPosition={index + 1}
                                            moreInfoLabel={moreInfoLabel}
                                            infoErrorCloseLabel={
                                                infoErrorCloseLabel
                                            }
                                            infoErrorText={infoErrorText}
                                        >
                                            {data.label}
                                        </InfoButton>
                                    ) : null
                                }
                            />
                        ))}
                    </TechnicalDataList>
                    {showLink ? (
                        <StyledLinkWrapper>
                            {!isDownloadLink ? linkIcon : null}
                            <LinkElement path="link" />
                        </StyledLinkWrapper>
                    ) : null}
                    <UniversalDisclaimerContainer
                        color={'light'}
                        customStylingWrapper={
                            StyledTechnicalDataDisclaimerWrapper
                        }
                        displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    />
                </StyledLayout>
            </GeneralDisclaimerProvider>
        </SectionWrapper>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S128 Car Technical Data Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <StyledSpacingWrapper
                noTechnicalData={!C.isInEditor() && !isTechnicalDataAvailable}
                data-testid="car-technical-data-section-v2"
            >
                {isTechnicalDataAvailable || showLink ? content : null}
                {/* Breadcrumbs are normally placed under every stage, but in case of this section use, they are excluded from stage and rendered here. */}
                {!isSimpleStageUsed ? <Breadcrumbs /> : null}
            </StyledSpacingWrapper>
        </AuthoringWrapper>
    );
};
