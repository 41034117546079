import * as React from 'react';
import styled from 'styled-components';

import {TableRowElementModel} from '../../../../../generated/core';
import {AlertBox} from '../../../../components/AlertBox';
import {PersonalizationInclude} from '../../../../components/PersonalizationInclude';
import {ContainerItem} from '../../../../infrastructure/ContainerExporter';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {getDnDContainer} from '../../../../utils/container/getDnDContainer';
import {getChildItems} from '../../../../utils/container/getContainerChildItems';
import {StyledPropsExtractor} from '../../../../utils/styledUtils';
import {getCellWidth} from './cells/EmptyCellElement';

const RESOURCE_TYPE = 'vwa-ngw18/components/editorial/elements/tableRowElement';

interface StyleTableRowProps {
    narrowColumns?: boolean;
}

const StyledTableRow = styled.div<StyleTableRowProps>`
    display: flex;
    flex-direction: row;
    border: 2px solid ${props => props.theme.border.color.secondary};
    position: relative;
    ${props =>
        props.narrowColumns ? `margin: 0 ${props.theme.size.grid001}` : null};
`;

interface StyledDndContainerProps {
    narrowColumns?: boolean;
    children(props: any): JSX.Element;
}

const StyledDndContainer = styled<
    React.FunctionComponent<StyledDndContainerProps>
>(StyledPropsExtractor)`
    width: ${props => getCellWidth(props.theme, props.narrowColumns)};
`;

export interface TableRowElementExtraProps {
    columnsCount: number;
    narrowColumns?: boolean;
    updateTableInAuthorCallback?(): void;
}

const MAX_COLUMNS = 6;

class TableRowElementComponentClass extends React.PureComponent<
    TableRowElementModel & TableRowElementExtraProps
> {
    public componentDidUpdate(
        prevProps: Readonly<TableRowElementModel & TableRowElementExtraProps>
    ): void {
        if (
            getChildItems(prevProps).length !==
                getChildItems(this.props).length &&
            this.props.updateTableInAuthorCallback
        ) {
            this.props.updateTableInAuthorCallback();
        }
    }

    public render(): React.ReactNode {
        const cells = getChildItems(this.props).map((child: ContainerItem) => (
            <PersonalizationInclude
                key={child.key}
                resourceType={child.model.cqType}
                path={child.key}
                extraProps={{
                    isNarrow: this.props.narrowColumns
                }}
            />
        ));

        return (
            <>
                {cells.length !== this.props.columnsCount && C.isInEditor() && (
                    <AlertBox>
                        <span>
                            Each row must have same number of cells as first one
                        </span>
                    </AlertBox>
                )}
                <StyledTableRow narrowColumns={this.props.narrowColumns}>
                    {cells}
                    <StyledDndContainer
                        narrowColumns={this.props.narrowColumns}
                    >
                        {({className}) =>
                            cells.length < MAX_COLUMNS ? (
                                getDnDContainer(
                                    RESOURCE_TYPE,
                                    this.props,
                                    className
                                )
                            ) : (
                                <></>
                            )
                        }
                    </StyledDndContainer>
                </StyledTableRow>
            </>
        );
    }
}

export const TableRowElement = MapTo<TableRowElementExtraProps>(
    RESOURCE_TYPE,
    TableRowElementComponentClass
);
