import {Model, ModelProviderProps} from '@adobe/cq-react-editable-components';
import {ModelManager, TransferModel} from '@adobe/cq-spa-page-model-manager';
import * as React from 'react';
import {modelToProps} from '../api/modelToProps';

const passableProps = ['cqPath', 'containerProps', 'forceReload'];

export interface ModelProviderState {
    model: Model | null;
}

export function mergeProps(model: any, props: any): any {
    const extraProps = props.extraProps;
    model = model || props;
    const newProps = {...model, ...extraProps};
    passableProps.forEach((key: string) => (newProps[key] = props[key]));
    delete newProps.extraProps;

    return newProps;
}

export class ModelProvider extends React.Component<
    ModelProviderProps,
    ModelProviderState
> {
    public static withModel(
        wrappedComponent: React.ComponentType<any>
    ): React.FunctionComponent<Model> {
        return (props: Model) => {
            const mpProps: ModelProviderProps = {...props, wrappedComponent};

            return React.createElement(ModelProvider, mpProps);
        };
    }

    public constructor(props: ModelProviderProps) {
        super(props);
        this.state = {
            model: null
        };
    }

    public componentWillUnmount(): void {
        ModelManager.removeListener(this.props.cqPath, this.updateData);
    }

    public componentDidMount(): void {
        ModelManager.addListener(this.props.cqPath, this.updateData);
    }

    public render(): JSX.Element {
        const newProps = mergeProps(this.state.model, this.props);

        return React.createElement(
            this.props.wrappedComponent,
            newProps as React.Attributes
        );
    }

    private onLoad = (data: TransferModel) => {
        this.setState({model: modelToProps(data, this.props.cqPath)});
    };

    private updateData = () => {
        ModelManager.getData({
            path: this.props.cqPath,
            forceReload: this.props.cqForceReload || false
        }).then(this.onLoad);
    };
}
