import React from 'react';
import {
    CTA,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {Lightbox} from '../../components/Lightbox';
import Markdown from 'markdown-to-jsx';
import {CmsTextLink} from '../../components/links/CmsTextLink';

export interface ConsentLayerProps {
    title: string;
    disclaimer: string;
    text: string;
    acceptLabel: string;
    declineLabel: string;
    btnCloseLabel: string;
    accept: () => void;
    decline: () => void;
    onClose: () => void;
}

export function ConsentLayer(props: ConsentLayerProps): JSX.Element {
    const {
        title,
        text,
        disclaimer,
        accept,
        acceptLabel,
        decline,
        declineLabel,
        onClose,
        btnCloseLabel
    } = props;

    const primaryCTA = (
        <CTA tag="button" emphasis="primary" onClick={accept}>
            {acceptLabel}
        </CTA>
    );

    const secondaryCTA = (
        <CTA tag="button" emphasis="secondary" onClick={decline}>
            {declineLabel}
        </CTA>
    );

    const disclaimerHeadline = ({...props}) => (
        <Text tag={TextTag.h3} {...props} />
    );
    const disclaimerCTA = ({...props}) => (
        <CmsTextLink emphasis="tertiary" target="_blank" {...props} />
    );

    return (
        <Lightbox
            onClose={onClose}
            heading={
                <Text
                    tag={TextTag.h3}
                    appearance={TokenTextAppearance.headline400}
                    bold
                >
                    {title}
                </Text>
            }
            copy={
                <Text tag={TextTag.p} appearance={TokenTextAppearance.copy200}>
                    {text}
                </Text>
            }
            primaryButton={primaryCTA}
            disclaimer={
                <Markdown
                    options={{
                        overrides: {
                            h3: disclaimerHeadline,
                            a: disclaimerCTA
                        }
                    }}
                >
                    {disclaimer}
                </Markdown>
            }
            secondaryButton={secondaryCTA}
            switchButtonOrder
            btnCloseLabel={btnCloseLabel}
        />
    );
}
