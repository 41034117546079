import React, {useState} from 'react';
import {observer, useLocalStore} from 'mobx-react-lite';

import {USPSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {useXOfYLabel} from '../../../components/highlight-feature/HighlightGallery';
import {
    Degree180Context,
    Degree180Store
} from '../../../context/media/Degree180Store';
import {MobileCarouselV2} from '../../../d6/components/mobile-carousel-v2';
import {UspModule} from '../../../d6/components/usp-module';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {aspectRatioMap} from '../../../utils/aspectRatioMap';
import {HeadingElement} from '../elements/HeadingElement';
import {LinkElement} from '../elements/LinkElementComponent';
import {MediaElement} from '../elements/MediaElement';
import {MediaInteractionElement} from '../elements/MediaInteractionElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

/**
 * S130 USP Section
 */
const InternalUspSection = observer(function USP(
    props: USPSectionModel
): JSX.Element | null {
    const {
        contentLabels,
        contentId,
        anchorId,
        contentName,
        tagTypeBroken,
        leftMediaAspectRatio,
        rightMediaAspectRatio,
        translations,
        plainMainHeading,
        plainLeftHeading,
        plainRightHeading,
        showMediaInteractionElement,
        mboxId
    } = props;
    const [activeIndex, setActiveIndex] = useState(0);

    const store = useLocalStore(() => new Degree180Store());

    const mainMediaElement = (
        <Degree180Context.Provider value={store}>
            <MediaElement
                path="superMedia"
                useParentAspectRatio={true}
                containerProps={{matchParent: true}}
                interactive={!showMediaInteractionElement}
            />
        </Degree180Context.Provider>
    );

    const mainMediaInteractionElement = (isHidden?: boolean) => (
        <Degree180Context.Provider value={store}>
            <MediaInteractionElement
                path="superMediaInteractionElement"
                isShown={showMediaInteractionElement && !isHidden}
            />
        </Degree180Context.Provider>
    );

    const mainHeadline = (
        <HeadingElement style="H4" path="superHeading" order="H2" />
    );

    const mainCopy = <CopyItem path="superCopy" />;

    const mainLink = <LinkElement path="superLink" />;

    const leftImage = (
        <MediaElement
            path="leftMedia"
            useParentAspectRatio={true}
            containerProps={{matchParent: true}}
        />
    );

    const leftHeadline = (
        <HeadingElement style="H4" path="leftHeading" order="H2" />
    );

    const leftCopy = <CopyItem path="leftCopy" />;

    const leftLink = <LinkElement path="leftLink" />;

    const rightImage = (
        <MediaElement
            path="rightMedia"
            useParentAspectRatio={true}
            containerProps={{matchParent: true}}
        />
    );

    const rightHeadline = (
        <HeadingElement style="H4" path="rightHeading" order="H2" />
    );

    const rightCopy = <CopyItem path="rightCopy" />;

    const rightLink = <LinkElement path="rightLink" />;

    const handleSlideChanged = (index: number) => {
        setActiveIndex(index);
    };

    const getXOfYLabel = useXOfYLabel(translations['carousel.slideXOfYLabel']);

    const labels = [plainMainHeading, plainLeftHeading, plainRightHeading];

    const carousel = (children: JSX.Element[]) => (
        <MobileCarouselV2
            carouselId={`${contentId}-carousel`}
            activeSlide={activeIndex}
            onSlideChanged={handleSlideChanged}
            useWiderSlides
            inactiveSlidesOpaque
            paginationItemLabels={labels}
            paginationDefaultItemLabel={
                translations['moodGallery.carouselItemLabel']
            }
            getXOfYLabel={getXOfYLabel}
            galleryLabel={translations['carousel.galleryLabel']}
            paginationLabel={translations['carousel.gallerySlidesLabel']}
        >
            {children}
        </MobileCarouselV2>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S130 Usp Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                sectionId={contentId}
                anchorId={anchorId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <UspModule
                        mainCopy={mainCopy}
                        mainHeadline={mainHeadline}
                        mainImage={mainMediaElement}
                        mainMediaInteractionElement={
                            mainMediaInteractionElement
                        }
                        mainLink={mainLink}
                        leftImage={leftImage}
                        leftHeadline={leftHeadline}
                        leftCopy={leftCopy}
                        leftLink={leftLink}
                        leftImageAspectRatio={
                            aspectRatioMap[leftMediaAspectRatio]
                        }
                        rightImage={rightImage}
                        rightHeadline={rightHeadline}
                        rightCopy={rightCopy}
                        rightLink={rightLink}
                        rightImageAspectRatio={
                            aspectRatioMap[rightMediaAspectRatio]
                        }
                        mobileCarousel={carousel}
                    />
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
});

export const USPSection = MapTo(
    'vwa-ngw18/components/editorial/sections/uspSection',
    InternalUspSection
);
