import {Logger as FeatureHubLogger} from '@feature-hub/logger';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {format} from './format';
import {LogLevel} from './Logger';
import {HttpLoggerOptions} from './options';
import {sendMessage} from './sendMessage';
import {LogTrackEvent} from './TrackEventHolder';

export interface LogEntry {
    type?: 'single' | 'batch' | 'monitor';
}

export interface SingleLogEntry extends LogEntry {
    type?: 'single';
    timestamp: number;
    name: string;
    level: string;
    message: string;
    featureAppId: string;
    featureAppVersion: string;
    url: string;
    country: string;
    language: string;
    env: string;
    author: boolean;
    team: string;
    server: boolean;
    agent: string;
    lastEvents?: LogTrackEvent[];
}

export interface MonitorLogEntry extends LogEntry {
    type: 'monitor';
    monitorType: string;
    [key: string]: unknown;
}

export class HttpLogger implements FeatureHubLogger {
    public constructor(private options: HttpLoggerOptions) {}

    private sendHttp(
        message: string | undefined,
        level: LogLevel,
        optionalParams: unknown[]
    ): void {
        if (!this.options.url) {
            console.error('no url for http logging configured');
            return;
        }

        const url = isInBrowser()
            ? window.location.href
            : this.options.serverPageUrl || '';

        if (message) {
            const lastEvents = this.options.trackEventHolder?.getLastEvents(3);
            const agent = isInBrowser() ? navigator.userAgent : 'SSR';
            const formattedMessage = format(message, ...optionalParams);

            const event: SingleLogEntry = {
                type: 'single',
                featureAppId: this.options.consumerName,
                name: this.options.loggerName,
                level,
                timestamp: new Date().getTime(),
                featureAppVersion: this.options.version,
                message: formattedMessage,
                url,
                country: this.options.country,
                language: this.options.language,
                env: this.options.env,
                author: this.options.author,
                team: this.options.team,
                server: !isInBrowser(),
                agent,
                lastEvents
            };

            if (this.options.queuedLogger) {
                this.scheduleSendMessage(event);
            } else {
                sendMessage(this.options.url, event);
            }
        }
    }

    private scheduleSendMessage(event: SingleLogEntry) {
        const logger = this.options.queuedLogger;
        if (logger) {
            logger.addLogMessage(event);
        }
    }

    debug(message?: string, ...optionalParams: unknown[]): void {
        this.sendHttp(message, 'debug', optionalParams);
    }

    error(message?: string, ...optionalParams: unknown[]): void {
        this.sendHttp(message, 'error', optionalParams);
    }

    info(message?: string, ...optionalParams: unknown[]): void {
        this.sendHttp(message, 'info', optionalParams);
    }

    trace(_message?: string, ..._optionalParams: unknown[]): void {
        // This is intentional
    }

    warn(message?: string, ...optionalParams: unknown[]): void {
        this.sendHttp(message, 'warn', optionalParams);
    }
}
