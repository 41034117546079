import {RootLocationDescriptorObject} from '@feature-hub/history-service';
import {
    createPath,
    parsePath,
    updateSuffix
} from '@volkswagen-onehub/cms-utils';
import * as history from 'history';
import {createSearchParams} from './CmsRootLocationTransformer';
import {removeParams} from './util';

export function createRootLocationForSecondaryConsumer(
    currentRootLocation: history.LocationDescriptorObject,
    secondaryConsumerLocation: history.LocationDescriptorObject | undefined,
    secondaryKey: string,
    secondaryExtension: string,
    paramSuffix: string
): RootLocationDescriptorObject {
    let pathname = currentRootLocation.pathname;
    if (secondaryConsumerLocation) {
        const path = parsePath(currentRootLocation.pathname || '/');

        let secondaryPathname = secondaryConsumerLocation.pathname;
        if (secondaryConsumerLocation.pathname === '/') {
            secondaryPathname = undefined;
        }

        const suffix = updateSuffix(
            path.suffix || '',
            secondaryKey,
            secondaryPathname,
            {defaultExtension: secondaryExtension}
        );
        pathname = createPath({
            base: path.base,
            suffix
        });
    }

    const secondarySearch = createSearchParams(secondaryConsumerLocation);
    const allSearch = removeParams(
        createSearchParams(currentRootLocation),
        secondaryKey
    );
    secondarySearch.forEach((value, key) => {
        allSearch.append(key + paramSuffix, value);
    });
    const {hash} = currentRootLocation;

    return {pathname, search: allSearch.toString(), hash};
}
