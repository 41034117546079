import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import loadable from '@loadable/component';
import * as React from 'react';
import {useModelStore} from '../../context';
import {rareModulesDefinition} from '../../registries/rareModulesDefinition';

export function loadRareModules(types: string[]): boolean {
    return rareModulesDefinition.some(name =>
        types.some(type => type === name)
    );
}

function getTypes(data?: TransferModel, types?: string[]): string[] {
    if (!data) {
        return types || [];
    }
    types = types || [];
    types.push(data[':type']);
    if (data[':items'] && data[':itemsOrder']) {
        data[':itemsOrder'].forEach(key => {
            getTypes(data[':items'][key], types);
        });
    }

    return types;
}

export interface DynamicComponentLoaderProps {
    path: string;
    fallback?: JSX.Element;
    children?: React.ReactNode;
}

const RareWrapper = loadable.lib(async () =>
    import(/* webpackChunkName: "raremodules" */ '../../registries/rareModules')
);

export function DynamicComponentLoader(
    props: DynamicComponentLoaderProps
): JSX.Element {
    const modelStore = useModelStore();

    const model = modelStore.getData(props.path);
    if (loadRareModules(getTypes(model))) {
        return (
            <RareWrapper fallback={props.fallback}>
                {() => props.children}
            </RareWrapper>
        );
    }

    return <>{props.children}</>;
}
