import * as React from 'react';

import {
    NextBestActionPanelButton as D6NextBestActionPanelButton,
    NextBestActionV2 as D6NextBestActionV2,
    ElementTypes as D6ElementTypes
} from '../../../d6/components/next-best-action-button-v2';

import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getIcon} from '../../../utils/getMappedIcon';
import {MergeNextBestActionModel} from './mergePersonalizedActions';
import {useScrollToSectionOfFocussedNbab} from './useScrollToSectionOfFocussedNbab';
import {SingleNbabTrackingData} from './useSingleNbabTracking';
import {useSingleNbabClick} from './useSingleNbabClick';

export type NextBestActionButtonProps = Readonly<{
    actionModel: MergeNextBestActionModel;
    isHidden?: boolean;
    inaccessible?: boolean; // "true" value makes the button inaccessible for keyboard and screen-reader
    trackingData?: SingleNbabTrackingData;
    sectionRef?: React.RefObject<HTMLDivElement>;
    type?: D6ElementTypes;
    hideIcon?: boolean;
}>;

export function SingleMobileNextBestActionButtonV2({
    type,
    inaccessible,
    isHidden,
    actionModel,
    sectionRef,
    trackingData,
    hideIcon
}: NextBestActionButtonProps): JSX.Element | null {
    const refV2 = React.useRef(null);

    useScrollToSectionOfFocussedNbab(refV2, sectionRef);

    // create link V2
    const [linkClick, linkHref] = useSingleNbabClick(
        refV2,
        actionModel,
        trackingData
    );

    // create next best action V2
    const href = linkHref;
    const actionV2 = ({
        content: actionModel.label,
        icon: !hideIcon && getIcon(actionModel.icon, 'default'),
        tag: href && actionModel.linkType !== 'action' ? 'a' : 'button',
        href,
        onClick: linkClick,
        ariaLabel: actionModel.label,
        title: actionModel.title,
        target:
            actionModel.linkType === 'external'
                ? actionModel.externalLinkTarget
                : undefined
    } as unknown) as D6NextBestActionV2;

    return (
        <CyAttributeAppender name="NextBestActionButton">
            <D6NextBestActionPanelButton
                action={actionV2}
                tabIndex={inaccessible ? -1 : 0}
                title={actionModel.title}
                isHidden={isHidden}
                type={type}
            />
        </CyAttributeAppender>
    );
}
