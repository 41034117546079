import {ModelManager} from '@adobe/cq-spa-page-model-manager';
import * as React from 'react';

import {styled} from '@volkswagen-onehub/components-core';
import {makeClip} from '../../../../d6/components/helpers';

import {
    LayoutTable,
    TableItemModel,
    TableSectionModel
} from '../../../../../generated/core';
import {ContainerItem} from '../../../../infrastructure/ContainerExporter';
import {getChildItems} from '../../../../utils/container/getContainerChildItems';
import {MediaElement} from '../../elements/MediaElement';
import {RichtextFullElement} from '../../elements/RichtextElement';
import * as ImageCellElement from '../../elements/table/cells/ImageCellElement';
import * as MergeColumnCellElement from '../../elements/table/cells/MergeColumnCellElement';
import * as MergeRowCellElement from '../../elements/table/cells/MergeRowCellElement';
import * as RichtextCellElement from '../../elements/table/cells/RichtextCellElement';
import {rowType} from './DesktopTable';

export const getRows = (
    props: TableSectionModel | TableItemModel
): ContainerItem[] => {
    const {cqItems} = props;
    const {tableParsys} = cqItems;

    return getChildItems(tableParsys);
};

export const getNumberOfColumns = (rows: ContainerItem[]): number => {
    if (rows.length === 0) {
        return 0;
    }
    const firstRow = rows[0].model;
    const columns = getChildItems(firstRow);

    return columns.length;
};

export const getCells = (row: ContainerItem): ContainerItem[] => {
    const {model} = row;

    return getChildItems(model);
};

export const getCellType = (cell: ContainerItem): string => {
    const {model} = cell;

    return model.cqType;
};

export const getCellContent = (
    cell: ContainerItem,
    cqPath: string
): React.ReactNode => {
    switch (getCellType(cell)) {
        case RichtextCellElement.RESOURCE_TYPE: {
            return <RichtextFullElement path={`${cqPath}/richtext`} />;
        }
        case ImageCellElement.RESOURCE_TYPE: {
            return <MediaElement path={`${cqPath}/mediaElement`} />;
        }
        default:
            return null;
    }
};

export const getColSpan = (
    cells: ContainerItem[],
    startIndex: number
): number => {
    let colSpan = 1;
    let idx = startIndex;

    while (
        cells[idx] &&
        getCellType(cells[idx]) === MergeColumnCellElement.RESOURCE_TYPE
    ) {
        colSpan++;
        idx++;
    }

    return colSpan;
};

export const getScope = (
    layout: LayoutTable,
    tableRowType?: rowType
): string | undefined => {
    let scope;

    switch (layout) {
        case 'CATEGORY':
            scope = 'row';
            break;
        case 'HEADER':
            scope = 'col';
            break;
        case 'HEADER_CATEGORY':
            scope = tableRowType === rowType.bodyRowType ? 'row' : 'col';
            break;
        case 'HEADER_CATEGORY_SUM':
            scope =
                tableRowType === rowType.bodyRowType
                    ? 'row'
                    : tableRowType === rowType.headRowType
                    ? 'col'
                    : '';
    }

    return scope;
};

export const getRowSpan = (
    rows: ContainerItem[],
    startIndex: number,
    col: number
): number => {
    let rowSpan = 1;
    let idx = startIndex;

    while (rows[idx]) {
        const cells = getCells(rows[idx]);
        const cell = cells[col];
        if (!cell || getCellType(cell) !== MergeRowCellElement.RESOURCE_TYPE) {
            break;
        }
        rowSpan++;
        idx++;
    }

    return rowSpan;
};

export const getCqPath = (
    props: TableSectionModel | TableItemModel,
    row: ContainerItem,
    cell: ContainerItem
): string => `${props.cqPath}/tableParsys/${row.key}/${cell.key}`;

export const updateTableInAuthor = (path: string): void => {
    ModelManager.getData({
        path,
        forceReload: true
    });
};

export const StyledCaptionForScreenReaders = styled.caption`
    ${makeClip()}
`;
StyledCaptionForScreenReaders.displayName = 'StyledCaptionForScreenReaders';
