import React from 'react';

const DEFAULT_THROTTLE = 10;

export const useResizeObserver = (
    targetRef: React.RefObject<HTMLElement>,
    setter: (height: number, width: number) => void,
    options?: {throttle: number}
) => {
    const resizeObserver = React.useRef<ResizeObserver | null>(null);
    const throttle = React.useRef<number>(0);

    React.useEffect(() => {
        const target = targetRef.current;
        if (!target) {
            return;
        }
        resizeObserver.current = new ResizeObserver(entries => {
            //there are edge-cases when these entries- and borderBoxSize-arrays dont have content or more than one
            //by looping we keep the complexity low
            entries.forEach(entry => {
                entry.borderBoxSize.forEach(borderBox => {
                    const {inlineSize, blockSize} = borderBox;
                    clearTimeout(throttle.current);
                    throttle.current = setTimeout(() => {
                        setter(blockSize, inlineSize);
                    }, options?.throttle ?? DEFAULT_THROTTLE);
                });
            });
        });
        // eslint-disable-next-line no-unused-expressions
        resizeObserver.current?.observe(target);

        return () => {
            // eslint-disable-next-line no-unused-expressions
            resizeObserver.current?.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
