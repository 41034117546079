import {
    Breakpoints,
    CTA,
    styled,
    Text,
    TokenTextAppearance,
    TokenTextColor,
    TextTag
} from '@volkswagen-onehub/components-core';
import {Close} from '@volkswagen-onehub/icons-core';
import React from 'react';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {
    useContentStore,
    useLoginStore,
    useNavigationStore,
    useSpaAsyncConfig,
    useTrackingService
} from '../../../context';
import {resetListStyles} from '../../../d6/components/helpers';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {getIcon} from '../../../utils/getMappedIcon';
import {getUserName} from './getUserName';
import {useUtilityNavigationItems} from './useUtilityNavigationItems';
import {UtilityLoggedInFooter} from './UtilityLoggedInFooter';
import {UtilityLoggedOutFooter} from './UtilityLoggedOutFooter';
import {observer} from 'mobx-react-lite';
import {UtilityNavigationItem} from '../../../../generated/core';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';

const TOPBAR_HEIGHT = 52;
const STATIC_TOPBAR_HEIGHT = 56;
const STATIC_TOPBAR_HEIGHT_DESKTOP = 64;
// Note: This calculation is needed to align the close button with the icons of the topbar, which are only 24px in width
const CLOSE_BTN_ALIGNMENT_CALC = (44 - 24) / 2;

const getInnerHeight = () => window.innerHeight + 'px';

const StyledLayer = styled.aside<{
    announcementBarHeight: number;
    isStaticTopbar: boolean;
}>`
    --offset-top: ${props =>
        (props.isStaticTopbar ? STATIC_TOPBAR_HEIGHT : TOPBAR_HEIGHT) +
        props.announcementBarHeight}px;

    position: absolute;
    inset-inline-end: 0;
    top: var(--offset-top);

    width: ${props => props.theme.size.grid020};
    max-height: calc(${getInnerHeight} - var(--offset-top));

    background-color: ${props => props.theme.colors.background.primary};
    padding: ${props => props.theme.size.static350}
        ${props => props.theme.size.grid002} 0;

    overflow-y: auto;

    @media (min-width: ${Breakpoints.b560}px) {
        top: ${props =>
            props.isStaticTopbar &&
            STATIC_TOPBAR_HEIGHT_DESKTOP + props.announcementBarHeight}px;
        width: ${props => props.theme.size.grid011};
        padding-inline: ${props => props.theme.size.grid001};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        width: ${props => props.theme.size.grid009};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        width: ${props => props.theme.size.grid008};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        width: ${props => props.theme.size.grid007};
    }
`;

const StyledGreeting = styled.div`
    padding: ${props => props.theme.size.static200} 0
        ${props => props.theme.size.static400};
`;

const StyledSeparationText = styled.div`
    display: grid;
    justify-items: center;
    position: relative;
    padding-block: ${props => props.theme.size.static350};
    color: ${props => props.theme.colors.content.active};

    & > span {
        background-color: ${props => props.theme.colors.background.primary};
        position: relative;
        padding-inline: ${props => props.theme.size.dynamic0020};
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        background-color: ${props => props.theme.border.color.primary};
        height: 1px;
        position: absolute;
        top: 45%;
    }
`;

const StyledListItem = styled.li`
    padding-block: ${props => props.theme.size.static200};
    border-block-end: 1px solid ${props => props.theme.colors.border.divider};
`;

const StyledList = styled.ul`
    ${resetListStyles}
    display: flex;
    flex-direction: column;
    & :first-child {
        padding-block-start: 0;
    }
`;

const StyledLayerContent = styled.div`
    display: grid;

    & > *:first-child {
        justify-self: end;
        transform: translate(${CLOSE_BTN_ALIGNMENT_CALC}px);
    }
`;

export const UtilityNavigationLayer = observer(function UNL(
    props: React.PropsWithChildren<{
        active: boolean;
        scrollElementRef(el: HTMLDivElement): void;
    }>
) {
    const {scrollElementRef} = props;
    const navigationStore = useNavigationStore();
    const contentStore = useContentStore();
    const pageRootModel = contentStore.getCurrentPageRootModel();
    const isStaticTopbar = Boolean(
        pageRootModel?.togglesModel.enableStaticNavigation
    );

    return (
        <StyledLayer
            announcementBarHeight={Math.max(
                navigationStore.getAnnouncementBarHeight - window.scrollY,
                0
            )}
            isStaticTopbar={isStaticTopbar}
            ref={scrollElementRef}
        >
            {props.children}
        </StyledLayer>
    );
});

interface UtilityNavigationCloseButtonProps {
    btnCloseLabel: string;
    onClick(): void;
}

export const UtilityNavigationCloseButton = (
    props: UtilityNavigationCloseButtonProps
) => {
    const {onClick, btnCloseLabel} = props;

    return (
        <CTA
            key="close"
            tag="button"
            onClick={onClick}
            ariaLabel={btnCloseLabel}
            emphasis="tertiary"
            icon={<Close ariaHidden />}
        />
    );
};

export interface UtilityNavigationProps {
    closeButton: JSX.Element;
}

export const UtilityNavigation = observer(function UnobservedUtilityNavigation(
    props: UtilityNavigationProps
) {
    const {closeButton} = props;
    const {utilityNavigationModel} = useSpaAsyncConfig();
    const {activeItems, inactiveItems} = useUtilityNavigationItems();
    const trackingService = useTrackingService();
    const contextTrackingData = useContextTrackingData();
    const loginStore = useLoginStore();

    const name: string = getUserName({
        greeting: utilityNavigationModel.greeting,
        fallbackUserName: utilityNavigationModel.fallbackUserName,
        userData: loginStore.userData,
        organizationData: loginStore.organizationData,
        isLoggedIn: loginStore.isLoggedIn
    });

    function trackLinkClick(
        link: UtilityNavigationItem,
        linkPosition: number
    ): void {
        const itemsCount = activeItems.length + inactiveItems.length;

        trackingService.trackLinkClick(
            link.linkHref,
            'utility-nav-link',
            link.linkLabel,
            {
                ...contextTrackingData,
                itemCount: itemsCount,
                itemPosition: linkPosition
            }
        );
    }

    const footer = loginStore.isLoggedIn ? (
        <UtilityLoggedInFooter />
    ) : (
        <UtilityLoggedOutFooter />
    );

    return (
        <StyledLayerContent>
            {closeButton}
            <StyledGreeting>
                <Text
                    bold
                    appearance={TokenTextAppearance.headline300}
                    tag={TextTag.h2}
                >
                    {name}
                </Text>
            </StyledGreeting>
            <CyAttributeAppender name="active">
                <StyledList>
                    {activeItems.map((item, index) => (
                        <StyledListItem key={item.linkLabel}>
                            <CmsTextLink
                                icon={getIcon(item.icon, 'default')}
                                href={item.linkHref}
                                ariaLabel={item.linkTitle}
                                target={item.linkTarget}
                                trackingActionOverride={() =>
                                    trackLinkClick(item, index + 1)
                                }
                            >
                                {item.linkLabel}
                            </CmsTextLink>
                        </StyledListItem>
                    ))}
                </StyledList>
            </CyAttributeAppender>
            {inactiveItems.length > 0 && (
                <>
                    <StyledSeparationText>
                        <Text
                            color={TokenTextColor.inherit}
                            appearance={TokenTextAppearance.label150}
                            tag={TextTag.span}
                        >
                            {utilityNavigationModel.inactiveItemsLabel}
                        </Text>
                    </StyledSeparationText>
                    <CyAttributeAppender name="inactive">
                        <StyledList>
                            {inactiveItems.map(item => (
                                <StyledListItem key={item.linkLabel}>
                                    <CmsTextLink
                                        disabled
                                        icon={getIcon(item.icon, 'default')}
                                        href={item.linkHref}
                                        ariaLabel={item.linkTitle}
                                        target={item.linkTarget}
                                    >
                                        {item.linkLabel}
                                    </CmsTextLink>
                                </StyledListItem>
                            ))}
                        </StyledList>
                    </CyAttributeAppender>
                </>
            )}
            {footer}
        </StyledLayerContent>
    );
});
