import {AspectRatio, calcAspectRatio} from '@volkswagen-onehub/components-core';
import {
    MediaAspectRatio,
    Scene7VideoRendition
} from '../../../../../generated/api';
import {aspectRatioMap} from '../../../../utils/aspectRatioMap';
import {AnimationElementProps} from '../animationElement/AnimationElement';
import {VideoElementProps} from './VideoElement';

export interface VideoState {
    videoSrc: string;
    readonly isPlaying: boolean;
    readonly currentTime: number;
}

export interface VideoTrackingState {
    playEventFired: boolean;
    milestoneTimes: number[];
    duration: number;
    nextMilestoneIndex: number;
    nextMilestoneTime: number;
}
const milestones = [0.1, 0.25, 0.5, 0.75, 0.9, 1];

export const createInitTrackingState = (): VideoTrackingState => ({
    playEventFired: false,
    milestoneTimes: [],
    duration: 0,
    nextMilestoneIndex: -1,
    nextMilestoneTime: Number.MAX_SAFE_INTEGER
});

export const createMilestoneTimes = (duration: number) => {
    return milestones.map(milestone => duration * milestone);
};

const closest = (num: number, arr: number[]) =>
    arr.reduce((prev, curr) => {
        return Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev;
    });

export const getMilestoneIndex = (
    currentTime: number,
    milestoneTimes: number[]
) => {
    const time = closest(currentTime, milestoneTimes);

    return milestoneTimes.indexOf(time);
};

export const getMilestone = (
    milestoneTime: number,
    milestoneTimes: number[]
) => {
    const i = getMilestoneIndex(milestoneTime, milestoneTimes);
    if (i === -1) {
        return -1;
    }

    return milestones[i] * 100;
};

const formatTimeGroup = (time: number) =>
    time < 10 ? `0${time.toFixed(0)}` : time.toFixed(0);

export const formatTime = (timeInSeconds: number) => {
    let time = Math.round(timeInSeconds);
    const hours = Math.floor(time / 3600);
    time = time - hours * 3600;
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;

    return `${formatTimeGroup(hours)}:${formatTimeGroup(
        minutes
    )}:${formatTimeGroup(seconds)}`;
};

export const createMilestoneVideoTrackingData = (
    milestoneIndex: number,
    trackingState: VideoTrackingState
) => {
    const {milestoneTimes, duration} = trackingState;
    const milestoneTime = milestoneTimes[milestoneIndex];
    const milestone = getMilestone(milestoneTime, milestoneTimes);

    return {
        length: formatTime(duration),
        milestone,
        timeplayed: formatTime(milestoneTime)
    };
};

/**
 * data structure to hold the width and height of a display element
 */
export interface ElementSize {
    readonly width: number;
    readonly height: number;
}

/**
 * Returns the element size of an HTMLElement
 * @param ref
 */
export function getElementSize(ref: React.RefObject<HTMLElement>): ElementSize {
    const rect = ref && ref.current && ref.current.getBoundingClientRect();

    return rect !== null
        ? {width: rect.width, height: rect.height}
        : {width: 0, height: 0};
}

/**
 * Returns the video rendition from the available list that best fits to the video container size.
 * @param videoRenditions
 * @param videoRatio
 * @param containerSize
 */
export function getVideoRendition(
    videoRenditions: Scene7VideoRendition[],
    videoRatio: MediaAspectRatio,
    containerSize: ElementSize
): Scene7VideoRendition | null {
    let rend = null;

    if (!videoRenditions || videoRenditions.length <= 0) {
        return rend;
    }

    const hasValidContainerSize =
        containerSize.width !== 0 && containerSize.height !== 0;

    // calculate actual container aspect ratio, default to 16:9
    const containerRatio = hasValidContainerSize
        ? containerSize.height / containerSize.width
        : 9 / 16;
    const videoRatioNum = calcAspectRatio(
        aspectRatioMap[videoRatio] || AspectRatio['16:9']
    );

    if (containerRatio >= videoRatioNum) {
        // compare height
        const maxHeight = containerSize.height;
        rend = [...videoRenditions] // do not modify original array
            .sort((a, b) => a.height - b.height) // sort by height ascending
            .reduce((p, c) => (c.height > maxHeight ? p : c)); // take largest src that is <= maxHeight
    } else {
        // compare width
        const maxWidth = containerSize.width;
        rend = [...videoRenditions] // do not modify original array
            .sort((a, b) => a.width - b.width) // sort by width ascending
            .reduce((p, c) => (c.width > maxWidth ? p : c)); // take largest src that is <= maxWidth
    }

    return rend;
}

export const videoElementPropsEqual = (
    prevProps: AnimationElementProps | VideoElementProps,
    nextProps: AnimationElementProps | VideoElementProps
): boolean => {
    if (prevProps.coverImage?.src !== nextProps.coverImage?.src) {
        return false;
    }

    if (prevProps.fileReference !== nextProps.fileReference) {
        return false;
    }

    if (
        prevProps.disclaimers &&
        nextProps.disclaimers &&
        (prevProps.disclaimers.staticReferences !==
            nextProps.disclaimers.staticReferences ||
            prevProps.disclaimers.interactiveReferences !==
                nextProps.disclaimers.interactiveReferences)
    ) {
        return false;
    }

    return true;
};
