export const ANIMATION_FUNCTION = 'cubic-bezier(0.14, 1.12, 0.67, 0.99)';
const ANIMATION_DURATION = '0.3s';

export const getElectricTransition = (...properties: string[]) => `
	transition: ${properties
        .map(prop => `${prop} ${ANIMATION_DURATION} ${ANIMATION_FUNCTION}`)
        .join(', ')};
`;

export interface ElectricTransition {
    readonly name: string;
    readonly delay?: string;
    readonly duration?: string;
}

const delay = (value?: string) => (value ? ` ${value}` : '');
const duration = (value?: string) => (value ? ` ${value}` : ANIMATION_DURATION);

export const getElectricTransitionAdvanced = (
    ...properties: ElectricTransition[]
) =>
    properties
        .map(
            prop =>
                `${prop.name} ${duration(
                    prop.duration
                )} ${ANIMATION_FUNCTION}${delay(prop.delay)}`
        )
        .join(', ');

export const resetButtonStyles = () => `
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	cursor: pointer;
`;

export const textOverflowEllipsis = () => `
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const makeClip = () => `
	// NOTE: One of limited options to render visually hidden content, but still accessible even for screenreaders
	// https://a11yproject.com/posts/how-to-hide-content/
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	width: 1px;
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
`;

export const resetClipVisibility = () => `
	width: auto;
	height: auto;
	clip: auto;
    clip-path: none;
	overflow: visible;
    white-space: initial;
`;

export const hyphens = () => `
	hyphens: auto;
	word-break: break-word;

	&:lang(ko) {
		word-break: keep-all;
	}
`;

export const horizontalScroll = () => `
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	outline: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;
