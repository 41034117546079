import {SpaGlobalConfig} from '../../../generated/core';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {PersistentStorageService} from '../persistentStorage/PersistentStorageService';
import {StorageWrapper} from '../persistentStorage/StorageWrapper';
import {calculateRemoteLoggingEnabled} from './calculateRemoteLoggingEnabled';
import {calculateRemoteMonitoringEnabled} from './calculateRemoteMonitoringEnabled';
import {ConfigurableConsole} from './ConfigurableConsole';
import {MonitorLogEntry} from './HttpLogger';
import {HttpQueuedLogger} from './HttpQueuedLogger';
import {LoggerConfiguration, LogOptions} from './options';
import {TrackEventHolder} from './TrackEventHolder';

export interface Log {
    readonly personalization: ConfigurableConsole;
    readonly tracking: ConfigurableConsole;
    readonly monitor: ConfigurableConsole;
    readonly general: ConfigurableConsole;
    readonly search: ConfigurableConsole;
    readonly featureApps: ConfigurableConsole;
    readonly navigation: ConfigurableConsole;
    readonly disclaimer: ConfigurableConsole;
    readonly vwid: ConfigurableConsole;
    readonly layer: ConfigurableConsole;
    readonly nbab: ConfigurableConsole;
    readonly featurehub: ConfigurableConsole;
    readonly consent: ConfigurableConsole;
    readonly featureapploader: ConfigurableConsole;
    readonly abtest: ConfigurableConsole;
    readonly uncaught: ConfigurableConsole;
    [name: string]: ConfigurableConsole;
}

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';
export const LOG_LEVELS: LogLevel[] = ['debug', 'info', 'warn', 'error'];
const USE_HTTP_LOGGER_KEY = 'useHttpLogger';

export function isEnabled(level: LogLevel, minLevel: LogLevel): boolean {
    return LOG_LEVELS.indexOf(level) >= LOG_LEVELS.indexOf(minLevel);
}

/**
 * create options for this logger manager
 */
export function createLogOptions(
    config: SpaGlobalConfig,
    trackEventHolder: TrackEventHolder,
    storage?: PersistentStorageService,
    serverPageUrl?: string
): LogOptions {
    const remoteLoggingEnabled = calculateRemoteLoggingEnabled(config);
    const remoteMonitoringEnabled = calculateRemoteMonitoringEnabled(config);

    return {
        cmsVersion: config.cmsVersion,
        cmsLogLevel: config.cmsLogLevel,
        url: config.faLoggingUrl,
        serverPageUrl,
        country: config.country,
        language: config.language,
        env: config.logEnv || config.env || 'UNKNOWN',
        author: config.author,
        remoteLoggingEnabled,
        remoteMonitoringEnabled,
        remoteLoggingQueuingDelay: config.remoteLoggingQueuingDelay,
        storage: remoteLoggingEnabled && !isInBrowser() ? undefined : storage,
        trackEventHolder
    };
}

/**
 * internal function to create cms specific logger options
 */
function createCmsLoggerOptions(
    name: string,
    logOptions: LogOptions,
    team: string = 'cms'
): LoggerConfiguration {
    return {
        version: logOptions.cmsVersion,
        team,
        consumerName: 'cms-integrator',
        loggerName: name,
        level: logOptions.cmsLogLevel || 'warn',
        useHttpLogger: Boolean(logOptions.cmsLogLevel)
    };
}

export class Logger {
    private readonly loggers: Log;
    private readonly storage?: PersistentStorageService;
    private readonly queuedLogger: HttpQueuedLogger | undefined;

    public readonly createLogger: (
        _id: string,
        _options: LoggerConfiguration
    ) => ConfigurableConsole;

    public constructor(private readonly logOptions: LogOptions) {
        this.loggers = {} as Log;
        this.queuedLogger = undefined;
        if (!isInBrowser()) {
            this.queuedLogger = new HttpQueuedLogger(logOptions.url, 0);
        } else if (logOptions.remoteLoggingQueuingDelay > 0) {
            this.queuedLogger = new HttpQueuedLogger(
                logOptions.url,
                logOptions.remoteLoggingQueuingDelay
            );
        }
        this.storage = logOptions.storage;

        this.createLogger = (id: string, options: LoggerConfiguration) => {
            // default options + logger specific options
            const console = new ConfigurableConsole({
                httpAllowed: logOptions.remoteLoggingEnabled,
                country: logOptions.country,
                language: logOptions.language,
                env: logOptions.env,
                author: logOptions.author,
                url: logOptions.url,
                serverPageUrl: logOptions.serverPageUrl,
                queuedLogger: this.queuedLogger,
                trackEventHolder: logOptions.trackEventHolder,
                ...options,
                storage:
                    logOptions.storage &&
                    new StorageWrapper(logOptions.storage, id),
                useHttpLogger:
                    options.useHttpLogger &&
                    logOptions.remoteLoggingEnabled &&
                    this.isUseHttp()
            });

            this.addScope(id, console);
            return console;
        };

        // create cms internal loggers
        [
            'featureapploader',
            'personalization',
            'tracking',
            'monitor',
            'featureApps',
            'general',
            'navigation',
            'disclaimer',
            'vwid',
            'layer',
            'search',
            'featurehub',
            'nbab',
            'consent',
            'abtest'
        ].forEach(name => {
            this.createLogger(name, createCmsLoggerOptions(name, logOptions));
        });
        this.createLogger(
            'uncaught',
            createCmsLoggerOptions('uncaught', logOptions, 'uncaught')
        );
    }

    /**
     * send monitoring events
     * @param event the event to send to the backend store
     * @param hash the hash or id is used to prevent duplicate events in the queue.
     */
    public monitorEvent(event: MonitorLogEntry, hash: string, factor?: number) {
        if (!this.logOptions.remoteMonitoringEnabled(factor)) {
            this.monitor.info('monitoring event', event);
            return;
        }
        try {
            const url = isInBrowser()
                ? window.location.href
                : this.logOptions.serverPageUrl;
            const fullEvent = {
                ...event,
                country: this.logOptions.country,
                language: this.logOptions.language,
                ssr: !isInBrowser(),
                url,
                timestamp: new Date().getTime(),
                env: this.logOptions.env,
                author: this.logOptions.author
            };
            if (this.queuedLogger)
                this.queuedLogger.addMonitorEvent(fullEvent, hash);
        } catch (e) {
            this.general.warn('error during remote monitor event', e);
        }
    }

    public get personalization(): ConfigurableConsole {
        return this.loggers.personalization;
    }

    public get tracking(): ConfigurableConsole {
        return this.loggers.tracking;
    }

    public get monitor(): ConfigurableConsole {
        return this.loggers.monitor;
    }

    public get featureApps(): ConfigurableConsole {
        return this.loggers.featureApps;
    }

    public get featureapploader(): ConfigurableConsole {
        return this.loggers.featureapploader;
    }

    public get general(): ConfigurableConsole {
        return this.loggers.general;
    }

    public get search(): ConfigurableConsole {
        return this.loggers.search;
    }

    public get abtest(): ConfigurableConsole {
        return this.loggers.abtest;
    }

    public get uncaught(): ConfigurableConsole {
        return this.loggers.uncaught;
    }

    public get navigation(): ConfigurableConsole {
        return this.loggers.navigation;
    }

    public get disclaimer(): ConfigurableConsole {
        return this.loggers.disclaimer;
    }

    public get vwid(): ConfigurableConsole {
        return this.loggers.vwid;
    }

    public get layer(): ConfigurableConsole {
        return this.loggers.layer;
    }

    public get featurehub(): ConfigurableConsole {
        return this.loggers.featurehub;
    }

    public get consent(): ConfigurableConsole {
        return this.loggers.consent;
    }

    public get nbab(): ConfigurableConsole {
        return this.loggers.nbab;
    }

    public getScope(id: string): ConfigurableConsole | undefined {
        return this.loggers[id];
    }

    public addScope(
        name: string,
        configurableConsole: ConfigurableConsole
    ): void {
        this.loggers[name] = configurableConsole;
    }

    /**
     * Set debug logging for whole application, for development purposes only
     */
    public setDebugLogging(): void {
        Object.keys(this.loggers).forEach(key => {
            const consoleName = key as keyof Log;
            if (this.loggers[consoleName] instanceof ConfigurableConsole) {
                this.loggers[consoleName].levelDebug();
            }
        });
    }

    public isUseHttp(): boolean {
        if (this.storage) {
            const useHttp = this.storage.get(USE_HTTP_LOGGER_KEY);
            return typeof useHttp === 'undefined' || useHttp === 'true';
        }
        return true;
    }

    public setUseHttp(httpEnabled: boolean): void {
        if (httpEnabled && !this.logOptions.remoteLoggingEnabled) {
            this.general.warn('http logging not possible');
            return;
        }

        if (this.storage) {
            this.storage.set(
                USE_HTTP_LOGGER_KEY,
                httpEnabled ? 'true' : 'false'
            );
        }

        Object.keys(this.loggers).forEach(key => {
            const consoleName = key as keyof Log;
            if (this.loggers[consoleName] instanceof ConfigurableConsole) {
                httpEnabled
                    ? this.loggers[consoleName].switchToHttpLogger()
                    : this.loggers[consoleName].switchToConsoleLogger();
            }
        });
    }

    public async flushQueue(): Promise<void> {
        const logger = this.queuedLogger;
        if (logger) {
            await logger.sendQueue();
        }
    }
}
