import {
    Logger as FeatureHubLogger,
    SharedLogger,
    defineLogger
} from '@feature-hub/logger';
import {FeatureAppLogConfiguration} from '../../../generated/core';
import {Logger} from '../../context/logger/Logger';
import {LoggerConfiguration} from '../../context/logger/options';
import {ServiceRegistration} from './ServiceRegistration';

export function createLogger(
    logger: Logger,
    featureAppLogConfigurations: FeatureAppLogConfiguration[]
): ServiceRegistration<SharedLogger> {
    const getOrCreateConsumerLogger = (
        consumerId: string,
        consumerName?: string
    ): FeatureHubLogger => {
        const id = 'hub-' + (consumerName || consumerId);
        let consumerLogger: any = logger.getScope(id);

        if (!consumerLogger) {
            const logConfiguration = getLogConfiguration(
                featureAppLogConfigurations,
                consumerName || consumerId
            );

            const options: LoggerConfiguration = {
                team: logConfiguration?.team || 'unknown',
                consumerName: consumerName || consumerId,
                version: logConfiguration?.version || '',
                loggerName: 'main',
                level: logConfiguration?.logLevel || 'warn',
                useHttpLogger: Boolean(logConfiguration?.logLevel)
            };
            consumerLogger = logger.createLogger(id, options);
        }

        return consumerLogger;
    };

    return {
        definition: defineLogger(getOrCreateConsumerLogger)
        // service not used in CMS and would collide with CMS Logger in Registry by Naming
    };
}
function getLogConfiguration(
    featureAppLogConfigurations: FeatureAppLogConfiguration[],
    consumerName: string | undefined
) {
    const matches = featureAppLogConfigurations.filter(
        f => f.name === consumerName
    );
    if (matches.length === 0) {
        return undefined;
    }
    return matches[0];
}
