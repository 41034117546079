import {getStartDirection} from '../../../../d6/components/helpers';
import * as React from 'react';
import {CSSTransition} from 'react-transition-group';

import {
    Breakpoints,
    Container,
    ContainerHorizontalAlignment,
    ContainerVerticalAlignment,
    ContainerWrap,
    styled
} from '@volkswagen-onehub/components-core';

import {ChevronDown as ChevronDownIcon} from '@volkswagen-onehub/icons-core';
import {getElementSize} from '../../elements/videoElement/helpers';

export interface AccordionProps {
    readonly isOpen?: boolean;
    readonly headline: React.ReactNode;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
    readonly id: string;
    readonly prevId?: string;
    readonly nextId?: string;
    readonly showAccordionBorder?: boolean;
    readonly hideHeadBorder?: boolean;
    readonly expandedItemDisabled?: boolean;
    readonly hideHeadIcon?: boolean;
    readonly accordionItemRef?: React.RefObject<HTMLButtonElement>;
}

interface AccordionStyledProps {
    readonly isOpen?: boolean;
    readonly showAccordionBorder?: boolean;
    readonly hideHeadBorder?: boolean;
    readonly hideHeadIcon?: boolean;
    readonly height?: number;
}

interface StyledAccordionBodyProps {
    readonly isOpen: boolean;
    readonly height: number;
}

// NOTE: anim definition taken from CMS helpers
const ANIMATION_DURATION = 350;
const ANIMATION_FUNCTION = `${ANIMATION_DURATION}ms linear`;
const animationClassName = 'animation';

const StyledIconWrapper = styled.div<AccordionStyledProps>`
    margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid001};
    margin-inline-start: ${props => props.theme.size.grid001};
    color: ${props => props.theme.colors.button.primary.default};

    svg {
        transition: transform ${ANIMATION_FUNCTION};
        display: ${props => props.isOpen && props.hideHeadIcon && 'none'};

        transform: ${props =>
            props.isOpen ? 'rotateX(180deg)' : 'rotateX(0)'};
        transform-origin: 50% 50%;
    }
`;

const StyledAccordion = styled.div<AccordionStyledProps>`
    border-bottom: ${props =>
        props.showAccordionBorder &&
        `solid 2px ${props.theme.border.color.secondary}`};

    padding-top: ${props => props.theme.size.dynamic0100};
    padding-bottom: ${props =>
        props.isOpen
            ? props.hideHeadBorder
                ? 0
                : props.theme.size.dynamic0040
            : props.theme.size.dynamic0100};

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-bottom: ${props =>
            props.isOpen && props.hideHeadBorder
                ? 0
                : props.theme.size.dynamic0050};
    }
`;

const StyledAccordionHead = styled.button<AccordionStyledProps>`
    padding: 0;
    border: 0;
    border-bottom: ${props =>
        props.hideHeadBorder
            ? 'none'
            : `1px solid ${props.theme.colors.content.primary}`};
    background: none;
    appearance: none;
    text-align: inherit;

    width: 100%;

    cursor: ${props =>
        props.hideHeadIcon && props.isOpen ? 'auto' : 'pointer'};
    user-select: none;

    :focus {
        outline: 2px solid ${props => props.theme.colors.focus.main};
        outline-offset: ${props => props.theme.size.static100};

        ${StyledIconWrapper} {
            color: ${props => props.theme.colors.button.primary.hover};
        }
    }}
`;

const StyledAccordionBody = styled.div<StyledAccordionBodyProps>`
    overflow: visible;
    transition: height ${ANIMATION_FUNCTION}, opacity ${ANIMATION_FUNCTION};

    /* setting default values before animation is applied */
    height: ${props => (props.isOpen ? 'auto' : '0')};
    /* visibility hidden needed so closed content is not focussable */
    visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

    @media (min-width: ${Breakpoints.b960}px) {
        overflow: ${props => (props.isOpen ? 'visible' : 'hidden')};
    }

    &.${animationClassName}-enter {
        opacity: 0;
        height: 0;
        visibility: visible;
    }

    &.${animationClassName}-enter-active {
        opacity: 1;
        height: ${props => props.height}px;
        visibility: visible;
    }

    &.${animationClassName}-enter-done {
        opacity: 1;
        height: auto;
        visibility: visible;
    }

    &.${animationClassName}-exit {
        opacity: 1;
        height: ${props => props.height}px;
        visibility: visible;
    }

    &.${animationClassName}-exit-active {
        opacity: 0;
        height: 0;
        visibility: visible;
    }

    &.${animationClassName}-exit-done {
        opacity: 0;
        height: 0;
        visibility: hidden;
    }
`;

const StyledAccordionBodyContent = styled.div<AccordionStyledProps>`
    margin-top: ${props => props.theme.size.dynamic0020};
    padding-bottom: ${props => props.theme.size.dynamic0100};
    padding-right: ${props =>
        !props.hideHeadIcon &&
        !props.showAccordionBorder &&
        props.theme.size.grid001};
`;

export const Accordion: React.FunctionComponent<AccordionProps> = props => {
    const {
        accordionItemRef,
        expandedItemDisabled,
        id,
        isOpen = false,
        headline,
        children,
        onClick,
        showAccordionBorder,
        hideHeadBorder,
        hideHeadIcon,
        prevId,
        nextId
    } = props;
    const headId = id + '_head';
    const bodyId = id + '_body';
    const accordionBodyContentRef = React.useRef(null);
    const {height} = getElementSize(accordionBodyContentRef);

    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.key) {
                case 'ArrowUp': {
                    const previousAccordion =
                        prevId && document.getElementById(prevId + '_head');
                    if (previousAccordion) {
                        e.preventDefault();
                        previousAccordion.focus();
                    }
                    break;
                }
                case 'ArrowDown': {
                    const nextAccordion =
                        nextId && document.getElementById(nextId + '_head');
                    if (nextAccordion) {
                        e.preventDefault();
                        nextAccordion.focus();
                    }
                    break;
                }
            }
        },
        [prevId, nextId]
    );

    return (
        <StyledAccordion id={id} showAccordionBorder={showAccordionBorder}>
            <StyledAccordionHead
                id={headId}
                tabIndex={0}
                isOpen={isOpen}
                onClick={onClick}
                onKeyDown={onKeyDown}
                aria-expanded={isOpen}
                aria-controls={bodyId}
                aria-disabled={isOpen && expandedItemDisabled}
                hideHeadBorder={hideHeadBorder}
                hideHeadIcon={hideHeadIcon}
                ref={accordionItemRef}
                data-testid={headId}
            >
                <Container
                    wrap={ContainerWrap.never}
                    shrinkContent
                    horizontalAlign={ContainerHorizontalAlignment.spaceBetween}
                    verticalAlign={ContainerVerticalAlignment.center}
                >
                    {headline}
                    <StyledIconWrapper
                        isOpen={isOpen}
                        hideHeadIcon={hideHeadIcon}
                    >
                        <ChevronDownIcon ariaHidden />
                    </StyledIconWrapper>
                </Container>
            </StyledAccordionHead>
            <CSSTransition
                timeout={ANIMATION_DURATION}
                in={isOpen}
                classNames={animationClassName}
            >
                <StyledAccordionBody
                    id={bodyId}
                    isOpen={isOpen}
                    aria-hidden={!isOpen}
                    aria-labelledby={headId}
                    height={height}
                    role="region"
                    data-testid={bodyId}
                >
                    <StyledAccordionBodyContent
                        ref={accordionBodyContentRef}
                        showAccordionBorder={showAccordionBorder}
                    >
                        {children}
                    </StyledAccordionBodyContent>
                </StyledAccordionBody>
            </CSSTransition>
        </StyledAccordion>
    );
};
Accordion.displayName = 'Accordion';
