import * as React from 'react';
import styled from 'styled-components';

import {useFooterModel} from '../../context';
import {
    prepareLinkItem,
    useFooterDisclaimers
} from '../../modules/structure/footer/helpers';
import {LayerFooter} from './LayerFooter';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {useDisclaimersFromPropsOrNewContext} from '../disclaimers/useDisclaimersFromPropsOrNewContext';

const StyledDisclaimersContainer = styled.div`
    border-top: solid 2px ${props => props.theme.colors.border.divider};
    padding-top: ${props => props.theme.size.dynamic0150};
    margin-top: ${props => props.theme.size.dynamic0150};
`;

export function WrappedLayerFooter(): JSX.Element {
    const oneHubFooterModel = useFooterModel();
    const pageDisclaimers = useDisclaimersFromPropsOrNewContext({
        displayTypes: ['T2_PAGE_BASED']
    });
    const footerDisclaimers = useFooterDisclaimers({
        pageDisclaimers
    });

    const {legalAndImprintLinks, bottomNavAriaLabel} = oneHubFooterModel;
    const mergedLegalAndImprintLinks = legalAndImprintLinks.IMPRINT.links.concat(
        legalAndImprintLinks.LEGAL.links
    );

    const linkItems =
        mergedLegalAndImprintLinks &&
        mergedLegalAndImprintLinks.map(prepareLinkItem);

    const disclaimers = (
        <StyledDisclaimersContainer>
            {footerDisclaimers}
        </StyledDisclaimersContainer>
    );

    return (
        <CyAttributeAppender name="footer">
            <LayerFooter
                linkItems={linkItems}
                disclaimers={disclaimers}
                bottomNavAriaLabel={bottomNavAriaLabel}
            />
        </CyAttributeAppender>
    );
}
