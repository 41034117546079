import * as React from 'react';
import styled from 'styled-components';

import {
    CTA,
    DisclaimersContainer,
    SingleDisclaimer
} from '@volkswagen-onehub/components-core';
import {ArrowUp} from '@volkswagen-onehub/icons-core';
import {compareDisclaimers} from '../../modules/structure/footer/helpers';

import {smoothScrollTo} from '../../utils/smoothScroll';
import {TextWithNonBreakingSafewords} from '../../utils/TextWithNonBreakingSafewords';
import {useFeatureToggles, useGlobalConfig} from '../../context';
import {DisplayType as DisclaimerDisplayType} from '../../../generated/core';
import {DisclaimerWithReference} from '../../context/disclaimer/DisclaimerStore';
import {useActiveLayerScrollableElement} from '../../hooks/useActiveLayerScrollableElement';
import {getStartDirection, hyphens} from '../../d6/components/helpers';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {GeneralDisclaimerContext} from '../../context/disclaimer/GeneralDisclaimerProvider';
import {HtmlText} from '../richtext/HtmlText';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';

const StyledCTAWrapper = styled.span`
    padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static100};
    padding-inline-start: ${props => props.theme.size.static100};

    // NOTE: temporary fix for CTA link focus - if the link gets wrapped into second line,
    // the focus of link is not visible at all (Chrome) or visible just on the text before wraping (Firefox)
    white-space: nowrap;
`;

const StyledWrap = styled.div`
    ${hyphens}
`;

export type DisclaimerTypeProps = Pick<
    DisclaimerWithReference,
    'reference' | 'namedReference' | 'text' | 'displayType'
>;

export type DisclaimersTypeProps = DisclaimerTypeProps[];

type DisclaimerListProps = {
    disclaimers: DisclaimersTypeProps;
    disclaimersType?: DisclaimerDisplayType;
    withoutLabeledSection?: boolean;
};

type SingleDisclaimerWrapperProps = {
    disclaimer: DisclaimerTypeProps;
    hasReferences: boolean;
};

const getReference = (
    numberReference?: string,
    namedReferences: string[] = []
) =>
    (numberReference
        ? [`${numberReference}.`].concat(namedReferences)
        : namedReferences
    ).join(',\u00a0');

export interface DisclaimerTextWithSafeWordsProps {
    readonly text: string;
    readonly reference?: string;
    readonly buttonText: string;
    readonly disclaimerType?: DisclaimerDisplayType;
    readonly disclaimerWrapperRef: React.RefObject<HTMLDivElement>;
}

function DisclaimerTextWithSafeWords(
    props: DisclaimerTextWithSafeWordsProps
): JSX.Element {
    const {
        buttonText,
        disclaimerType,
        reference,
        text,
        disclaimerWrapperRef
    } = props;

    const featureToggles = useFeatureToggles();

    const [
        sourceReference,
        setSourceReference
    ] = React.useState<React.RefObject<HTMLButtonElement> | null>(null);
    const [originalScrollPosition, setOriginalScrollPosition] = React.useState<
        number
    >(0);
    const [showScrollBackButton, setShowScrollBackButton] = React.useState(
        false
    );
    const [disableScroll, setDisableScroll] = React.useState(false);
    const {activeLayerScrollableElement} = useActiveLayerScrollableElement();
    const generalContext = React.useContext(GeneralDisclaimerContext);

    const autoScrolledToFullTextCallback = (
        receivedSourceReference: React.RefObject<HTMLButtonElement>,
        scrollPosition: number,
        disableScrolling: boolean
    ) => {
        setShowScrollBackButton(true);
        setOriginalScrollPosition(scrollPosition);
        setSourceReference(receivedSourceReference);
        setDisableScroll(disableScrolling);
    };

    const onScrollBackButtonClick = (e: React.MouseEvent) => {
        e.preventDefault();

        if (sourceReference && sourceReference.current) {
            // TODO: must be fixed with forwardRef in core-components
            const referenceBadgeButton = sourceReference.current.querySelector(
                'button'
            );

            if (disableScroll) {
                if (referenceBadgeButton) {
                    referenceBadgeButton.focus();
                }
            } else {
                smoothScrollTo({
                    distance: originalScrollPosition,
                    scrollableElement: activeLayerScrollableElement,
                    onScrollEnd: () => referenceBadgeButton?.focus()
                });
            }
        }
        setShowScrollBackButton(false);
    };

    React.useEffect(() => {
        if (reference && disclaimerType && generalContext) {
            // add fullTextDisclaimers to scoped disclaimer store
            generalContext.handleAddedRef(
                reference, // for example: 1.
                disclaimerType, // for example: S3_SECTION_BASED
                disclaimerWrapperRef, // React Disclaimer Text Element
                autoScrolledToFullTextCallback // Callback after scrolling to disclaimer text
            );
        }
        // should not execute on context change, because it updates the context itself
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reference, disclaimerType]);

    const disclaimerText = featureToggles?.enableRichtextDisclaimers ? (
        <HtmlText html={text} />
    ) : (
        <TextWithNonBreakingSafewords
            __content_is_html_and_i_know_for_sure_it_is_safe_and_wont_cause_xss_vulnerability
        >
            {text}
        </TextWithNonBreakingSafewords>
    );

    return (
        <CyAttributeAppender name={`disclaimer-text-${props.reference}`}>
            <StyledWrap>
                {disclaimerText}
                {showScrollBackButton && (
                    <StyledCTAWrapper>
                        <CTA
                            tag="a"
                            emphasis="tertiary"
                            href="#"
                            onClick={e => onScrollBackButtonClick(e)}
                        >
                            <ArrowUp variant="small" />
                            {buttonText}
                        </CTA>
                    </StyledCTAWrapper>
                )}
            </StyledWrap>
        </CyAttributeAppender>
    );
}

export interface WrappedDisclaimerWithSafeWordsProps {
    withoutLabeledSection?: boolean;
    displayTypes: DisclaimerDisplayType[];
}

export const DisclaimerWithSafeWordsByDisplayTypes = observer(function DWSWBD(
    props: WrappedDisclaimerWithSafeWordsProps
) {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    if (disclaimers.length === 0) {
        return null;
    }
    return (
        <DisclaimersWithSafewords
            disclaimers={disclaimers}
            withoutLabeledSection={props.withoutLabeledSection}
        />
    );
});

export const SingleDisclaimerWrapper = ({
    disclaimer,
    hasReferences
}: SingleDisclaimerWrapperProps) => {
    const {disclaimerLabels} = useGlobalConfig();

    const ref = React.useRef<HTMLDivElement>(null);

    const reference = getReference(
        disclaimer.reference,
        disclaimer.namedReference
    );
    // global disclaimers don't get reference

    const text = (
        <DisclaimerTextWithSafeWords
            text={disclaimer.text}
            reference={disclaimer.reference}
            disclaimerType={disclaimer.displayType}
            buttonText={disclaimerLabels.scrollToDisclaimerRefNumberButtonText}
            disclaimerWrapperRef={ref}
        />
    );
    const d = {
        reference,
        text
    };

    return (
        <SingleDisclaimer
            ref={ref}
            disclaimer={d}
            hasReferences={hasReferences}
        />
    );
};

const checkReferences = (disclaimers: DisclaimerTypeProps[]) =>
    Boolean(
        disclaimers.find(
            disclaimer =>
                Boolean(disclaimer.reference) ||
                Boolean(disclaimer.namedReference)
        )
    );

export const DisclaimersWithSafewords = ({
    disclaimers,
    withoutLabeledSection
}: DisclaimerListProps) => {
    if (!disclaimers.length) return null;

    const hasReferences = checkReferences(disclaimers);

    const disclaimerContainer = (
        <DisclaimersContainer
            disclaimers={disclaimers}
            hasReferences={hasReferences}
        >
            {disclaimers
                .slice()
                .sort(compareDisclaimers)
                .map((disclaimer, idx) => {
                    const key =
                        disclaimer.reference ||
                        `${disclaimer.text.substring(
                            0,
                            Math.min(30, disclaimer.text.length)
                        )}_${idx}`;

                    return (
                        <SingleDisclaimerWrapper
                            disclaimer={disclaimer}
                            hasReferences={hasReferences}
                            key={key}
                        />
                    );
                })}
        </DisclaimersContainer>
    );

    return withoutLabeledSection ? (
        disclaimerContainer
    ) : (
        <section>{disclaimerContainer}</section>
    );
};
