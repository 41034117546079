import * as React from 'react';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {DisclaimerContainerProps} from './helpers';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';
import {createCSSFourValuesPaddingByDirection} from '../../d6/components/helpers';
import {ThemeProvider, styled} from '@volkswagen-onehub/components-core';

const StyledBelowItemDisclaimerContainer = styled.div`
    display: flex;
    padding: ${props =>
        createCSSFourValuesPaddingByDirection(
            props.theme.size.dynamic0100,
            0,
            props.theme.size.static250,
            0,
            props.theme.direction
        )};
`;

export const BellowItemDisclaimerContainer = observer(function BIDC(
    props: DisclaimerContainerProps
) {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const {color} = props;
    const hasReferences = disclaimers && disclaimers.length !== 0;

    return (
        <>
            {hasReferences && (
                <CyAttributeAppender name={'itemDisclaimers'}>
                    <StyledBelowItemDisclaimerContainer>
                        <ThemeProvider
                            theme={color === 'dark' ? 'inverted' : {}}
                        >
                            <DisclaimersWithSafewords
                                disclaimers={disclaimers}
                            />
                        </ThemeProvider>
                    </StyledBelowItemDisclaimerContainer>
                </CyAttributeAppender>
            )}
        </>
    );
});
