import * as React from 'react';

import {
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';

import {getStartDirection} from '../helpers';

// margin-top is used to align the top of the dropcap with the top of
// the first line of text.
const StyledDropCap = styled.span`
    float: ${props => getStartDirection(props.theme.direction)};
    min-width: ${props => props.theme.size.dynamic0250};
    margin-right: ${props => props.theme.size.static150};
    margin-top: -0.75em;
    text-align: center;
`;

export const DropCap: React.FunctionComponent = ({children}) => (
    <StyledDropCap>
        <Text appearance={TokenTextAppearance.super200} tag={TextTag.span}>
            {children}
        </Text>
    </StyledDropCap>
);
