import * as React from 'react';

import {
    ContextTrackingData,
    CountdownState
} from '../context/tracking/TrackingService';

import {createContextTrackingData} from '../utils/tracking/createContextTrackingData';
import {
    useBBOContext,
    usePersonalizationContext,
    usePersonalizationStore,
    useSectionContext,
    useSectionGroupContext
} from '../context';

export function useContextTrackingData(
    contentLabels?: string | null,
    itemCount?: number,
    itemPosition?: number,
    countdownState?: CountdownState
): ContextTrackingData {
    const ps = usePersonalizationStore();
    const pc = usePersonalizationContext();
    const bboc = useBBOContext();
    const sc = useSectionContext();
    const sgc = useSectionGroupContext();
    const sectionGroupContentId = sgc ? sgc.sectionGroupContentId : undefined;
    const metaData = ps.getMetaData();
    return React.useMemo(
        () =>
            createContextTrackingData(
                pc,
                metaData,
                bboc,
                sc,
                sectionGroupContentId,
                contentLabels,
                itemCount,
                itemPosition,
                countdownState
            ),
        [
            pc,
            metaData,
            bboc,
            sc,
            contentLabels,
            itemCount,
            itemPosition,
            sectionGroupContentId,
            countdownState
        ]
    );
}
