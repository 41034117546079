import * as React from 'react';

import {useNavigationStore, useSectionGroupContext} from '../../../../context';

function useAnchorIOHandler(
    anchorId?: string
): (entries: IntersectionObserverEntry[]) => void {
    const oneHubNavigationStore = useNavigationStore();
    const {sectionGroupAnchorId} = useSectionGroupContext();
    const [isIntersecting, setIsIntersecting] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!anchorId || !oneHubNavigationStore) {
            return;
        }

        if (isIntersecting) {
            oneHubNavigationStore.setInPageNavIntersectionItem(
                anchorId,
                sectionGroupAnchorId
            );
        } else {
            oneHubNavigationStore.removeInPageNavIntersectionItem(anchorId);
        }
    }, [isIntersecting, anchorId, sectionGroupAnchorId, oneHubNavigationStore]);

    return function handleInPageIOEvent(
        entries: IntersectionObserverEntry[]
    ): void {
        if (anchorId && entries.length > 0) {
            const [entry] = entries;

            setIsIntersecting(entry.intersectionRatio > 0);
        }
    };
}

export function useAnchorUpdater(
    ref: React.RefObject<HTMLDivElement>,
    anchorId?: string,
    inPageNavigationType?: string
): void {
    const [currentAnchorId, setCurrentAnchorId] = React.useState<
        string | undefined
    >(undefined);
    const [currentRef, setCurrentRef] = React.useState<HTMLDivElement | null>(
        null
    );

    const handleAnchorIOEvent = useAnchorIOHandler(currentAnchorId);

    React.useEffect(() => {
        if (inPageNavigationType === 'external') {
            return;
        }

        setCurrentRef(ref.current);
        setCurrentAnchorId(anchorId);

        const optionsItem = {
            // detecting when comes into top 25% of viewport, include 68px of topBar height
            rootMargin: `-68px 0px -75% 0px`,
            threshold: 0.00001
        };

        const io = new IntersectionObserver(handleAnchorIOEvent, optionsItem);

        if (currentAnchorId && currentRef) {
            io.observe(currentRef);
        }

        return function cleanUp(): void {
            io.disconnect();
        };
        // there should'nt be a ref inside of here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRef, currentAnchorId, inPageNavigationType]);
}
