// NOTE: this is "gallery" from components-core v67.18.0
// temporary transition here due refactoring bugs in core since v67.19.0+
import * as React from 'react';

import {
    css,
    DesignTokenSize,
    DesignTokenSizeEnum,
    styled,
    ThemeDefinition,
    useTheme,
    BreakpointConfigType,
    getValueByScreenSize
} from '@volkswagen-onehub/components-core';

import {createStylesForBreakpoints} from '@volkswagen-onehub/components-core/dist/helpers/breakpoint';

import {AriaLabelOrLabelledby} from '@volkswagen-onehub/components-core/dist/helpers/types';

import {
    getCommonProps,
    CommonProps
} from '@volkswagen-onehub/components-core/dist/helpers/common-props';

import {ChildContainerTabIndexManager} from './tabindex-manager';
import {StyledSlide, GallerySlideContext} from './slide';

export type GalleryProps = {
    readonly id: string;
    readonly handleChange?: (activeIndex: number, animate?: boolean) => void;
    readonly endlessScrolling?: boolean;
    readonly increment?: 'slide' | 'page';
    readonly children:
        | React.ReactElement<AriaLabelOrLabelledby & {key: React.Key}>
        | React.ReactElement<AriaLabelOrLabelledby & {key: React.Key}>[];

    readonly slidesPerPage?: number | BreakpointConfigType<number>;
    readonly fadingEdges?: boolean | BreakpointConfigType<boolean>;
    readonly topElements?: React.ReactNode;
    readonly bottomElements?: React.ReactNode;
    readonly gutter?: DesignTokenSize;
} & AriaLabelOrLabelledby &
    CommonProps;

// The index is the index in the range `0 .. length - 1`, it corresponds to the
// number of the slides.
//
// index: 0 1 2 3 4
//
// If there is endless scrolling, slides are duplicated and the extendedIndex
// goes from `(1 - slidesPerPage) .. (length + slidesPerPage - 1)`.
//
// extendedIndex: -2 -1 0 1 2 3 4 5 6
// index:          3  4 0 1 2 3 4 0 1
//
// If there is no endless scrolling, the extendedIndex is the same as the index.

const StyledCarousel = styled.section`
    overflow: none;
    width: 100%;
    position: relative;

    :focus {
        outline: none;
    }
`;

const getSlideWidth = (
    theme: ThemeDefinition,
    gutter?: DesignTokenSize,
    lastChild = false
) => {
    const slidesPerPage = 'var(--gallery-slidesPerPage)';
    const fadingEdgesSize = 'var(--gallery-fadingEdges)';
    const hasFadingEdges = 'var(--gallery-hasFadingEdges)';
    const gutterSize = gutter ? theme.size[gutter] : '0px';
    const stageWidth = '100%';

    return `calc((${stageWidth} - ${fadingEdgesSize} * ${hasFadingEdges} + ${gutterSize}) / ${slidesPerPage} - ${
        lastChild ? gutterSize : '0px'
    })`;
};

const getSlidesPerPageCSSVariable = (
    slidesPerPage: number | BreakpointConfigType<number>
) =>
    createStylesForBreakpoints<number>(
        slidesPerPage,
        slidesPerPage => `
				--gallery-slidesPerPage: ${slidesPerPage};
			`
    );
const getFadingEdgesCSSVariable = (
    fadingEdges: boolean | BreakpointConfigType<boolean>,
    theme: ThemeDefinition
) =>
    createStylesForBreakpoints<boolean>(
        fadingEdges,
        fadingEdges =>
            `--gallery-fadingEdges: ${
                fadingEdges ? theme.size.grid004 : '0px'
            };`
    );

const getHasFadingEdgesCSSVariable = (
    childrenLength: number,
    slidesPerPage: number | BreakpointConfigType<number>
) =>
    createStylesForBreakpoints<number>(
        slidesPerPage,
        slidesPerPage =>
            `--gallery-hasFadingEdges: ${
                childrenLength > slidesPerPage ? '1' : '0'
            };`
    );

const getScrollPadding = (
    fadingEdges: boolean | BreakpointConfigType<boolean>,
    theme: ThemeDefinition
) =>
    createStylesForBreakpoints<boolean>(
        fadingEdges,
        fadingEdges =>
            `scroll-padding: 0 calc(${
                fadingEdges ? theme.size.grid002 : '0px'
            });`
    );

const getScrollPaddingReset = (
    childrenLength: number,
    slidesPerPage: number | BreakpointConfigType<number>
) =>
    createStylesForBreakpoints<number>(slidesPerPage, slidesPerPage =>
        childrenLength <= slidesPerPage ? 'scroll-padding: 0;' : ''
    );

const StyledSlides = styled.div<{
    slidesPerPage: number | BreakpointConfigType<number>;
    fadingEdges: boolean | BreakpointConfigType<boolean>;
    gutter?: DesignTokenSize;
    childrenLength: number;
    disableSnap?: boolean;
}>`
	display: flex;
	${props => !props.disableSnap && `scroll-snap-type: x mandatory;`}
	overscroll-behavior-x: contain;
	overflow: auto;
	::-webkit-scrollbar {
		display: none;
	}
	scrollbar-width: none;
	:focus {
		outline: none;
		box-shadow: 0 0 0 2px ${({theme}) => theme.colors.border.hover};
	}

	${props => getSlidesPerPageCSSVariable(props.slidesPerPage)}
	${props => getFadingEdgesCSSVariable(props.fadingEdges, props.theme)}
	${props =>
        getHasFadingEdgesCSSVariable(props.childrenLength, props.slidesPerPage)}

	${props => getScrollPadding(props.fadingEdges, props.theme)}
	${props => getScrollPaddingReset(props.childrenLength, props.slidesPerPage)}

	::after {
		/* This is needed for safari to keep the margin of the last element */
		content: '';
		padding-right: 0.02px; /* smallest size that is cross browser */
	}

	${StyledSlide} {
		${props => css`
            flex-basis: ${getSlideWidth(props.theme, props.gutter)};

            max-width: ${getSlideWidth(props.theme, props.gutter)};
        `};

		${props => css`
            :last-child {
                flex-basis: ${getSlideWidth(props.theme, props.gutter, true)};
                max-width: ${getSlideWidth(props.theme, props.gutter, true)};
            }
        `};
	}
`;

export type GalleryContextValue = {
    index: number;
    extendedIndex: number;
    endlessScrolling: boolean;
    triggerChange: (newIndex: number, animate?: boolean) => void;
    triggerNext: () => void;
    triggerPrevious: () => void;
    getSlideId: (index: number) => string;
    disableScrollSnap: (disabled?: boolean) => void;
    slidesPerPage: number;
    fadingEdges: boolean;
    length: number;
    gutter?: DesignTokenSize;
    id: string;
    accessibleExtendedIndices: number[];
    visibleIndices: number[];
    snappableExtendedIndices: number[];
    slidesRef?: React.RefObject<HTMLDivElement>;
};

const noop = () => {
    // this function is intentionally left blank
};

export const GalleryContext = React.createContext<GalleryContextValue>({
    index: 0,
    extendedIndex: 0,
    endlessScrolling: false,
    triggerChange: noop,
    triggerNext: noop,
    triggerPrevious: noop,
    getSlideId: () => '',
    disableScrollSnap: noop,
    slidesPerPage: 1,
    fadingEdges: false,
    length: 1,
    gutter: undefined,
    id: '',
    accessibleExtendedIndices: [],
    visibleIndices: [],
    snappableExtendedIndices: [],
    slidesRef: undefined
});

const DEFAULT_MULTI_SLIDE_GUTTER = DesignTokenSizeEnum.grid001;

export const Gallery: React.FunctionComponent<GalleryProps> = props => {
    const {
        slidesPerPage = 1,
        fadingEdges = false,
        endlessScrolling,
        increment = 'slide',
        handleChange: externalHandleChange,
        children,
        id,
        ariaLabel,
        ariaLabelledby,
        topElements,
        bottomElements,
        gutter
    } = props;
    const theme = useTheme();
    const commonProps = getCommonProps(props);

    const [extendedIndex, setExtendedIndex] = React.useState(0);
    const [currentSlidesPerPage, setCurrentSlidesPerPage] = React.useState(1);
    const [currentFadingEdges, setCurrentFadingEdges] = React.useState(false);
    const [disableSnap, setDisableSnap] = React.useState(false);

    // A consumer-defined gutter always takes effect, but by default we only
    // have a gutter when there is more than one slide per page.
    const currentGutter = React.useMemo(
        () =>
            gutter ??
            (currentSlidesPerPage > 1 ? DEFAULT_MULTI_SLIDE_GUTTER : undefined),
        [currentSlidesPerPage, gutter]
    );

    const extendedIndexRef = React.useRef(extendedIndex);
    const slides = React.useRef<HTMLDivElement>(null);

    const childrenLength = React.useMemo(() => React.Children.count(children), [
        children
    ]);

    const updateSlidesPerPage = React.useCallback(() => {
        const width =
            typeof window !== 'undefined' ? window.innerWidth || 0 : 0;
        const newCurrentSlidesPerPage = getValueByScreenSize(
            width,
            slidesPerPage
        );
        setCurrentSlidesPerPage(newCurrentSlidesPerPage);
    }, [slidesPerPage]);

    const updateFadingEdges = React.useCallback(() => {
        const width =
            typeof window !== 'undefined' ? window.innerWidth || 0 : 0;
        const newCurrentFadingEdges = getValueByScreenSize(width, fadingEdges);
        setCurrentFadingEdges(newCurrentFadingEdges);
    }, [fadingEdges]);

    const changeSlide = React.useCallback(
        (nextIndex: number, animate = true) => {
            const slidesElement = slides.current;
            if (!slidesElement) {
                return;
            }

            // prevent jsdom based tests from failing because of missing scroll function stubs
            if (!slidesElement.scrollTo) {
                return;
            }

            const firstSlideElement = slidesElement.querySelector(
                ':first-child'
            );
            if (!firstSlideElement) {
                return;
            }
            const slideWidth = firstSlideElement.getBoundingClientRect().width;
            const targetPosition =
                (endlessScrolling
                    ? nextIndex + currentSlidesPerPage + 1
                    : (nextIndex + childrenLength) % childrenLength) *
                slideWidth;
            const left =
                theme.direction === 'rtl'
                    ? -1 * targetPosition
                    : targetPosition;
            slidesElement.scrollTo({
                left,
                behavior: animate ? 'smooth' : 'auto'
            });
        },
        [
            childrenLength,
            currentSlidesPerPage,
            endlessScrolling,
            theme.direction
        ]
    );

    const handleResize = React.useCallback(() => {
        updateSlidesPerPage();
        updateFadingEdges();

        changeSlide(extendedIndexRef.current, false);
    }, [updateSlidesPerPage, updateFadingEdges, changeSlide]);

    const snappableExtendedIndices = React.useMemo(() => {
        const incrementValue = increment === 'slide' ? 1 : currentSlidesPerPage;
        const snappableSlides = Array.from({
            length: childrenLength / incrementValue
        }).map((_, index) => index * incrementValue);

        snappableSlides.push(childrenLength - incrementValue);
        snappableSlides.push(childrenLength);

        for (let index = 1; index <= currentSlidesPerPage; index++) {
            const indexOfSlideCopy = 0 - incrementValue * index;

            // Ignore all indices without slides or copies of slides
            if (currentSlidesPerPage + indexOfSlideCopy >= 0) {
                snappableSlides.push(indexOfSlideCopy);
            }
        }

        snappableSlides.sort((a, b) => a - b);
        return snappableSlides;
    }, [currentSlidesPerPage, childrenLength, increment]);

    const slideToNextSlide = React.useCallback(() => {
        const shouldOverscroll = endlessScrolling;

        // Prevent scrolling while on a slide where the scroll behaviour should
        // navigate the user to the beginning of the gallery because of overflow at the end.
        if (extendedIndex > childrenLength - 1) {
            return;
        }

        if (
            !shouldOverscroll &&
            extendedIndex === childrenLength - currentSlidesPerPage
        ) {
            changeSlide(0);
        } else {
            // find next snappableIndex counting from extendedIndex
            const snappableTargetIndex =
                snappableExtendedIndices.find(value => value > extendedIndex) ||
                0;
            changeSlide(snappableTargetIndex);
        }
    }, [
        changeSlide,
        childrenLength,
        currentSlidesPerPage,
        endlessScrolling,
        extendedIndex,
        snappableExtendedIndices
    ]);

    const slideToPreviousSlide = React.useCallback(() => {
        const shouldOverscroll = endlessScrolling;

        // Prevent scrolling while on a slide where the scroll behaviour should
        // navigate the user to the end of the gallery because of overflow at the beginning.
        if (extendedIndex < 1 - currentSlidesPerPage) {
            return;
        }

        if (!shouldOverscroll && extendedIndex === 0) {
            changeSlide(childrenLength - currentSlidesPerPage);
        } else {
            // find previous snappableIndex counting from extendedIndex
            const snappableArrayIndex =
                (snappableExtendedIndices.findIndex(
                    value => value === extendedIndex
                ) -
                    1 +
                    childrenLength) %
                childrenLength;

            const snappableTargetIndex =
                snappableExtendedIndices[snappableArrayIndex] || 0;
            changeSlide(snappableTargetIndex);
        }
    }, [
        changeSlide,
        childrenLength,
        currentSlidesPerPage,
        endlessScrolling,
        extendedIndex,
        snappableExtendedIndices
    ]);

    const handleScroll = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const slidesElement = e.currentTarget;

            const scrollPosition =
                theme.direction === 'rtl'
                    ? -1 * slidesElement.scrollLeft
                    : slidesElement.scrollLeft;
            const slideScrollWidth = slidesElement.scrollWidth;
            const firstSlideElement = slidesElement.querySelector(
                ':first-child'
            );
            if (!firstSlideElement) {
                return;
            }
            const slideWidth = firstSlideElement.getBoundingClientRect().width;

            if (endlessScrolling) {
                // Overflow on the left side
                if (scrollPosition <= Math.ceil(slideWidth)) {
                    changeSlide(childrenLength - currentSlidesPerPage, false);
                    return;
                }

                // Overflow on the right side
                if (
                    scrollPosition >=
                    Math.floor(
                        (childrenLength + currentSlidesPerPage + 1) * slideWidth
                    )
                ) {
                    changeSlide(0, false);
                    return;
                }
            }

            const scrollIndex =
                Math.round(scrollPosition / Math.floor(slideWidth)) -
                (endlessScrolling ? currentSlidesPerPage + 1 : 0);

            // Update extendedIndex
            if (
                scrollIndex !== extendedIndex &&
                // this prevents scrolling to the first slide when resizing the window
                slideScrollWidth !== Math.round(slideWidth)
            ) {
                setExtendedIndex(scrollIndex);
                extendedIndexRef.current = scrollIndex;
            }
        },
        [
            changeSlide,
            childrenLength,
            currentSlidesPerPage,
            endlessScrolling,
            extendedIndex,
            theme.direction
        ]
    );

    React.useEffect(() => {
        if (externalHandleChange) {
            externalHandleChange(extendedIndex);
        }
    }, [extendedIndex, externalHandleChange]);

    // this is called when control elements change the index
    const handleChange = React.useCallback(
        (nextIndex: number, animate?: boolean) => {
            // find previous snappableIndex counting from nextIndex,
            // i.e. beginning of page containing nextIndex
            const snappableArrayIndex =
                snappableExtendedIndices.findIndex(value => value > nextIndex) -
                1;
            const snappableTargetIndex =
                snappableExtendedIndices[snappableArrayIndex] || 0;

            changeSlide(snappableTargetIndex, animate);
        },
        [changeSlide, snappableExtendedIndices]
    );

    // reset scroll position when basic props change
    React.useEffect(() => {
        const slidesElement = slides.current;
        if (!slidesElement) {
            return;
        }
        changeSlide(extendedIndex, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps -- should not update on changes to extendedIndex
    }, [changeSlide, childrenLength, currentSlidesPerPage, endlessScrolling]);

    // When endlessScrolling is set scroll to starting position.
    // Add and remove resize listeners
    React.useEffect(() => {
        if (endlessScrolling) {
            changeSlide(0, false);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [
        changeSlide,
        endlessScrolling,
        fadingEdges,
        handleResize,
        slidesPerPage
    ]);

    React.useEffect(() => {
        updateSlidesPerPage();
    }, [updateSlidesPerPage, slidesPerPage]);

    React.useEffect(() => {
        updateFadingEdges();
    }, [updateFadingEdges, fadingEdges]);

    const index = React.useMemo(
        () => (extendedIndex + childrenLength) % childrenLength,
        [extendedIndex, childrenLength]
    );

    // accessibleExtendedIndices,
    // i.e. those that are fully visible
    const accessibleExtendedIndices = React.useMemo(() => {
        const firstAccessibleIndex = endlessScrolling
            ? extendedIndex
            : Math.min(extendedIndex, childrenLength - currentSlidesPerPage);
        return Array.from({length: currentSlidesPerPage}).map(
            (_, index) => firstAccessibleIndex + index
        );
    }, [endlessScrolling, extendedIndex, childrenLength, currentSlidesPerPage]);

    // visibleIndices include accessibleExtendedIndices
    // slides of accessible duplicated slides.
    //
    // extendedIndex: -2 -1 0 1 2 3 4 5 6
    // index:          3  4 0 1 2 3 4 0 1
    //
    // e.g. if slide with (extended) index 5 is in the view, slides 0 is visible.
    // The slide component will render slide 5 (copy of slide 0) visible, too.
    const visibleIndices = React.useMemo(
        () =>
            Array.from({length: currentSlidesPerPage}).map(
                (_, index) =>
                    (extendedIndex + index + childrenLength) % childrenLength
            ),
        [currentSlidesPerPage, extendedIndex, childrenLength]
    );

    const galleryContextValue = React.useMemo(() => {
        return {
            index,
            extendedIndex,
            endlessScrolling: endlessScrolling || false,
            triggerChange: handleChange,
            triggerPrevious: slideToPreviousSlide,
            triggerNext: slideToNextSlide,
            getSlideId: (index: number) => `${id}__slide-${index}`,
            disableScrollSnap: (disabled = true) => setDisableSnap(disabled),
            slidesPerPage: currentSlidesPerPage,
            fadingEdges: currentFadingEdges,
            length: childrenLength,
            gutter: currentGutter,
            id: id,
            accessibleExtendedIndices,
            visibleIndices,
            snappableExtendedIndices,
            slidesRef: slides
        };
    }, [
        accessibleExtendedIndices,
        childrenLength,
        currentFadingEdges,
        currentSlidesPerPage,
        endlessScrolling,
        extendedIndex,
        currentGutter,
        handleChange,
        id,
        index,
        slideToNextSlide,
        slideToPreviousSlide,
        snappableExtendedIndices,
        visibleIndices,
        slides
    ]);

    interface SlideData {
        key: React.Key | null;
        value: {
            index: number;
            id?: number;
        };
        child: React.ReactElement;
    }

    const slideData = React.useMemo(() => {
        const leadingSlides: SlideData[] = [];
        const slides: SlideData[] = [];
        const trailingSlides: SlideData[] = [];

        React.Children.toArray(children)
            .filter(React.isValidElement)
            .forEach((child, index) => {
                slides.push({
                    key: child.key,
                    value: {
                        index
                    },
                    child
                });

                // Only render copies of slides when endlessScrolling is enabled and
                // we have more children than can be show on a single page
                if (endlessScrolling && childrenLength > currentSlidesPerPage) {
                    if (index < currentSlidesPerPage + 1) {
                        const id = childrenLength + index;
                        trailingSlides.push({
                            key: `${child.key}-${id}`,
                            value: {
                                index: index,
                                id: id
                            },
                            child
                        });
                    }

                    if (index > childrenLength - currentSlidesPerPage - 2) {
                        const id = index - childrenLength;
                        leadingSlides.push({
                            key: `${child.key}-${id}`,
                            value: {
                                index: index,
                                id: id
                            },

                            child
                        });
                    }
                }
            });
        return [...leadingSlides, ...slides, ...trailingSlides];
    }, [children, childrenLength, currentSlidesPerPage, endlessScrolling]);

    return (
        <GalleryContext.Provider value={galleryContextValue}>
            <StyledCarousel
                id={id}
                tabIndex={-1}
                aria-label={ariaLabel}
                role="region"
                aria-roledescription="carousel"
                aria-labelledby={ariaLabelledby}
                {...commonProps}
            >
                {topElements}
                <ChildContainerTabIndexManager
                    childContainerSelector={'[data-accessible]'}
                    activeChildContainerSelector={'[data-accessible="true"]'}
                >
                    <StyledSlides
                        ref={slides}
                        onScroll={handleScroll}
                        aria-atomic="false"
                        aria-live="polite"
                        slidesPerPage={slidesPerPage || 1}
                        fadingEdges={fadingEdges || false}
                        gutter={currentGutter}
                        tabIndex={0}
                        childrenLength={childrenLength}
                        disableSnap={disableSnap}
                    >
                        {slideData.map(({key, value, child}) => (
                            <GallerySlideContext.Provider
                                key={key ? key : undefined}
                                value={value}
                            >
                                {child}
                            </GallerySlideContext.Provider>
                        ))}
                    </StyledSlides>
                </ChildContainerTabIndexManager>

                {bottomElements}
            </StyledCarousel>
        </GalleryContext.Provider>
    );
};
