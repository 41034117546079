import {
    Breakpoints,
    getGridColumn,
    styled
} from '@volkswagen-onehub/components-core';
import React from 'react';
import {DisplayType} from '../../../../../generated/core';
import {GeneralDisclaimerProvider} from '../../../../context/disclaimer/GeneralDisclaimerProvider';
import {useLayoutEffect} from '../../../../utils/getIsomorphicLayoutEffect';
import {smoothScrollTo} from '../../../../utils/smoothScroll';
import {DisclaimerOverlay} from './DisclaimerOverlay';
import {
    VIDEO_DISCLAIMER,
    CONTENT_DISCLAIMER,
    Visibility,
    ACTIVE_SCROLLYTELLING_CONTENT
} from './Video';

export const StyledContentDisclaimer = styled.div`
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: initial;
    }
`;

export const StyledVideoContentBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-self: end;
    gap: ${props => props.theme.size.dynamic0050};
    position: relative;
    opacity: 0;
    pointer-events: none;

    &.active {
        opacity: 1;
        pointer-events: initial;
    }
    padding-block: ${props =>
        `${props.theme.size.dynamic0150} ${props.theme.size.dynamic0200}`};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-end: ${getGridColumn(8)};
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-inline-end: ${getGridColumn(12)};
    }
`;

const finishCurrentAnimations = (...elements: (HTMLElement | null)[]) => {
    elements
        .filter(Boolean)
        .forEach(element =>
            element!.getAnimations().forEach(animation => animation.finish())
        );
};

const animateMotion = (
    contentElement: HTMLElement,
    visibility: Visibility,
    callback: VoidFunction
) => {
    if (visibility === 'animating-in') {
        const animation = new Animation(
            new KeyframeEffect(
                contentElement,
                {
                    transform: ['translateY(20px)', 'translateY(0px)'],
                    opacity: [0, 1]
                },
                {duration: 300}
            )
        );
        animation.onfinish = callback;
        animation.play();
    }

    if (visibility === 'animating-out') {
        const animation = new Animation(
            new KeyframeEffect(
                contentElement,
                {
                    transform: ['translateY(0px)', 'translateY(20px)'],
                    opacity: [1, 0]
                },
                {duration: 300}
            )
        );
        animation.onfinish = callback;
        animation.play();
    }
};

const animateOpacity = (
    disclaimerElement: HTMLElement,
    visibility: Visibility
) => {
    if (visibility === 'animating-in') {
        disclaimerElement.animate(
            {
                opacity: [0, 1]
            },
            {duration: 300}
        );
    }

    if (visibility === 'animating-out') {
        disclaimerElement.animate(
            {
                opacity: [1, 0]
            },
            {duration: 300}
        );
    }
};

interface VideoContentBlockProps {
    children: React.ReactNode;
    scrollPosition: number;
    visibility: Visibility;
    updateLayout: VoidFunction;
    onAnimationEnd: VoidFunction;
    trackItem: VoidFunction;
}

export const VideoContentBlock = (props: VideoContentBlockProps) => {
    const {
        children,
        scrollPosition,
        onAnimationEnd,
        updateLayout,
        visibility,
        trackItem
    } = props;
    const disclaimerTypes: DisplayType[] = ['T4_ITEM_BASED'];
    const contentRef = React.useRef<HTMLDivElement>(null);
    const disclaimerRef = React.useRef<HTMLDivElement>(null);

    const isActive = visibility !== 'hidden';
    const activeClass = isActive
        ? `${ACTIVE_SCROLLYTELLING_CONTENT} active`
        : '';

    React.useEffect(() => {
        const content = contentRef.current;
        const disclaimer = disclaimerRef.current;
        if (!content || !disclaimer) {
            return;
        }
        const handleFocusIn = () => {
            smoothScrollTo({distance: scrollPosition});
        };

        content.addEventListener('focusin', handleFocusIn, {
            once: true
        });
        disclaimer.addEventListener('focusin', handleFocusIn, {
            once: true
        });

        if (isActive) {
            content.removeEventListener('focusin', handleFocusIn);
            disclaimer.removeEventListener('focusin', handleFocusIn);
            trackItem();
        }

        return () => {
            content.removeEventListener('focusin', handleFocusIn);
            disclaimer.removeEventListener('focusin', handleFocusIn);
        };
    }, [isActive, scrollPosition, trackItem]);

    useLayoutEffect(() => {
        const contentElement = contentRef.current;
        const disclaimerElement = disclaimerRef.current;
        const videoDisclaimerElement = contentElement?.parentElement?.querySelector(
            `.${VIDEO_DISCLAIMER}`
        );

        if (!contentElement) {
            return;
        }

        finishCurrentAnimations(contentElement, disclaimerElement);
        animateMotion(contentElement, visibility, onAnimationEnd);

        if (!disclaimerElement) {
            return;
        }
        videoDisclaimerElement
            ? animateOpacity(disclaimerElement, visibility)
            : animateMotion(disclaimerElement, visibility, onAnimationEnd);
    }, [visibility, onAnimationEnd]);

    return (
        <GeneralDisclaimerProvider displayTypes={disclaimerTypes} disableScroll>
            <StyledVideoContentBlock ref={contentRef} className={activeClass}>
                {children}
            </StyledVideoContentBlock>
            <StyledContentDisclaimer
                ref={disclaimerRef}
                className={`${CONTENT_DISCLAIMER} ${activeClass}`}
            >
                <DisclaimerOverlay
                    displayTypes={disclaimerTypes}
                    onClose={updateLayout}
                />
            </StyledContentDisclaimer>
        </GeneralDisclaimerProvider>
    );
};
