import {ReactRenderServiceV1} from '@volkswagen-onehub/react-render-service';
import {useLogger, useRegistry} from '../context';
import * as React from 'react';
import {isInBrowser} from './browser/isInBrowser';

/**
 * returns true if the rendering is happening in the client as opposed to hydration or ssr rendering.
 */
export function useIsClientOnlyRendering(): boolean {
    const logger = useLogger().general;
    const registry = useRegistry();

    const service: ReactRenderServiceV1 = registry.getSingleton(
        'ReactRenderService'
    );

    const [isClient, setIsClient] = React.useState(
        service.hydrating ? false : isInBrowser()
    );

    React.useEffect(() => {
        setIsClient(true);
    }, []);

    logger.debug('client-only rendering: %s', isClient);

    return isClient;
}
