import * as React from 'react';
import {LoginAlertService} from '../../../context/vwid/LoginAlertService';
import {LoginStore} from '../../../context/vwid/LoginStore';
import {getErrorStatus} from './FlyoutLoggedOut';

export function createBtnLoginClick(
    loginStore: LoginStore,
    loginAlertService: LoginAlertService,
    btnCloseAlertLabel: string
) {
    return async (e: React.MouseEvent) => {
        // please don't remove: required to avoid page load if the login request tooks long
        e.preventDefault();
        if (!loginStore.isLoggedIn) {
            try {
                await loginStore.handleLogin();
            } catch (error) {
                const status = getErrorStatus(error);
                loginAlertService.openAlert(status, btnCloseAlertLabel);
            }
        }
    };
}
