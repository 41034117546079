import * as React from 'react';

import {MediaIntersectionObserver} from '../../../../../services/video/MediaIntersectionObserver';
import {usePointerEventsHack} from './usePointerEventsHack';
import {noop} from '../../../../../utils/noop';

const AUTO_PLAY_DELAY = 2000;

export function useDelayedAutoPlay(
    videoContainerRef: React.RefObject<HTMLDivElement>,
    intersectionObserver: React.RefObject<MediaIntersectionObserver>
): () => void {
    const togglePointerEvents = usePointerEventsHack(videoContainerRef);

    return React.useMemo(() => {
        let innerResolve: () => void = noop;
        const promise = new Promise<void>(resolve => {
            innerResolve = resolve;
        });

        promise.then(() => {
            if (intersectionObserver.current) {
                intersectionObserver.current.startDelayed(
                    AUTO_PLAY_DELAY,
                    () => {
                        togglePointerEvents(true);
                    }
                );
            }
        });

        return innerResolve;
    }, [intersectionObserver, togglePointerEvents]);
}
