import * as React from 'react';
import {css} from 'styled-components';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap,
    Layout,
    LayoutConfigurations,
    styled
} from '@volkswagen-onehub/components-core';

export * from './item';

const StyledTechnicalDataWrapper = styled.div`
    padding: 0 ${props => props.theme.size.grid001};
`;
// There is a different spacing between items in second row and link
// so we need to set margin-top for items to create correct spacing between wrapped
// items in second row and first row and same but negative margin for container
// so it compensate spacing for first row
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items
const StyledTechnicalData = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: calc(-1 * (${props => props.theme.size.dynamic0100} / 2))
        calc(-1 * (${props => props.theme.size.dynamic0150} / 2));
`;

const StyledLinkContainer = styled.div`
    position: relative;
    margin-top: ${props => props.theme.size.dynamic0150};
    display: flex;
    justify-content: center;
`;

const StyledLinkBackgroundWrapper = styled.div`
    position: relative;
    background: ${props => props.theme.colors.background.primary};
    padding: 0 ${props => props.theme.size.static200};
`;

const StyledLine = styled.div<{
    readonly isCentered?: boolean;
}>`
    ${props =>
        props.isCentered &&
        css`
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        `};
    height: 2px;
    width: 100%;
    background: ${props => props.theme.colors.border.divider};
`;

export interface TechnicalDataProps {
    readonly children?: React.ReactNode | React.ReactNode[];
    readonly disclaimers?: React.ReactNode | React.ReactNode[];
    readonly link?: React.ReactNode;
}

export function TechnicalData(props: TechnicalDataProps): JSX.Element {
    return (
        <Layout appearance={LayoutConfigurations.full} allowOverflowingContent>
            <Container
                wrap={ContainerWrap.always}
                padding={{
                    left: ContainerPadding.grid001,
                    right: ContainerPadding.grid001
                }}
            >
                {props.children && (
                    <StyledTechnicalDataWrapper>
                        <StyledTechnicalData>
                            {props.children}
                        </StyledTechnicalData>
                    </StyledTechnicalDataWrapper>
                )}
                {props.disclaimers && (
                    <Container
                        wrap={ContainerWrap.always}
                        gutter={ContainerGutter.static200}
                        padding={{
                            left: ContainerPadding.grid001,
                            right: ContainerPadding.grid001,
                            top: ContainerPadding.dynamic0150
                        }}
                    >
                        {props.disclaimers}
                    </Container>
                )}
                <StyledLinkContainer>
                    <StyledLine isCentered={!!props.link} />
                    {props.link && (
                        <StyledLinkBackgroundWrapper>
                            {props.link}
                        </StyledLinkBackgroundWrapper>
                    )}
                </StyledLinkContainer>
            </Container>
        </Layout>
    );
}
