import * as React from 'react';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';

import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import {
    HighlightFeatureModel,
    XfCarFeatureModel
} from '../../../../generated/core';
import {AlertBox} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {
    FakeDesktopGallery,
    StyledFakeGalleryWrapper
} from '../../../components/highlight-feature/FakeDesktopGallery';
import {HighlightAccordionItem} from '../../../components/highlight-feature/HighlightAccordionItem';
import {HighlightGallery} from '../../../components/highlight-feature/HighlightGallery';
import {useTrackingService} from '../../../context';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {ContainerItem} from '../../../infrastructure/ContainerExporter';
import {C} from '../../../registries/compatibilty';
import {isInBrowser} from '../../../utils/browser/isInBrowser';
import {getChildItems} from '../../../utils/container/getContainerChildItems';
import {processContentId} from '../../../utils/processContentId';
import {smoothScroll} from '../../../utils/smoothScroll';
import {AccordionGroupState} from '../items/AccordionItem';
import {SectionWrapper} from './SectionWrapper';

const MEDIA_PARSYS = 'mediaParsys';
const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/sections/highlightFeatureSection';

export const getSectionOffsetTop = (section: HTMLElement): number => {
    return Math.round(section.getBoundingClientRect().top);
};

export const getSectionItemsHeight = (
    idx: number,
    section: HTMLElement
): number => {
    let totalHeight: number = 0;

    if (window?.innerWidth < Breakpoints.b960 && idx > 0) {
        for (let i = 0; i < idx; i++) {
            const accordionItem = section.querySelector(
                `#highlightfeaturesect_item_${i}`
            );

            if (accordionItem) {
                const accordionItemStyles = getComputedStyle(accordionItem);

                const accordionItemPaddingTop = accordionItemStyles.getPropertyValue(
                    'padding-top'
                );
                const accordionItemPaddingBottom = accordionItemStyles.getPropertyValue(
                    'padding-bottom'
                );

                const accordionPaddingY =
                    Number(accordionItemPaddingTop.replace('px', '')) +
                    Number(accordionItemPaddingBottom.replace('px', ''));

                const accordionItemButtonHeight = accordionItem.querySelector(
                    'button'
                )?.clientHeight;

                totalHeight =
                    totalHeight +
                    accordionPaddingY +
                    (accordionItemButtonHeight || 0);
            }
        }
    }

    return totalHeight;
};

export const getDistanceCorrectedByNavbarHeight = (
    initialDistance: number
): number => {
    const COLLAPSED_NAVBAR_HEIGHT = 52;
    const MOBILE_NAVBAR_HEIGHT = 84;
    const TABLET_NAVBAR_HEIGHT = 92;
    const DESKTOP_NAVBAR_HEIGHT = 100;
    const DESKTOP_CORRECTION_DISTANCE = 6;

    let distance = initialDistance;

    if (window) {
        if (window.innerWidth < Breakpoints.b560) {
            if (distance >= MOBILE_NAVBAR_HEIGHT) {
                // check scroll direction;
                distance = distance - COLLAPSED_NAVBAR_HEIGHT;
            } else {
                distance = distance - MOBILE_NAVBAR_HEIGHT;
            }
        } else if (window.innerWidth < Breakpoints.b960) {
            if (distance >= TABLET_NAVBAR_HEIGHT) {
                // check scroll direction;
                distance = distance - COLLAPSED_NAVBAR_HEIGHT;
            } else {
                distance = distance - TABLET_NAVBAR_HEIGHT;
            }
        } else {
            distance =
                distance - DESKTOP_NAVBAR_HEIGHT - DESKTOP_CORRECTION_DISTANCE;
        }
    }

    return distance;
};

const StyledAccordionWrapper = styled.div<{isInEditor?: boolean}>`
    padding-inline: ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid005};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        margin-top: ${props =>
            `calc(${props.isInEditor ? 1 : -1} * ${
                props.theme.size.dynamic0100
            })`};
        padding-inline: ${props =>
            `${props.theme.size.grid001} ${props.theme.size.grid002}`};
    }

    & :target {
        scroll-margin-top: 10vh;
    }

    & :not(:first-child):target {
        scroll-margin-top: 90vh;
    }
`;
StyledAccordionWrapper.displayName = 'StyledAccordionWrapper';

const StyledContentWrapper = styled.div`
    position: relative;

    @media (min-width: ${Breakpoints.b960}px) {
        display: grid;
        grid-template-columns: repeat(24, ${100 / 24}%);
        grid-template-areas: 'media media media media media media media media media media media media media media content content content content content content content content content content';

        & ${StyledFakeGalleryWrapper} {
            grid-area: media;
        }

        & ${StyledAccordionWrapper} {
            grid-area: content;
        }
    }
`;

interface HighlightFeatureProps {
    readonly itemIndex: number;
}

const getItemProps = (
    props: HighlightFeatureProps & HighlightFeatureModel,
    index: number
): XfCarFeatureModel | null => {
    const {cqItems} = props;
    return cqItems[`item_${index}`] as XfCarFeatureModel;
};

const getAccordionItemName = (
    props: HighlightFeatureProps & HighlightFeatureModel,
    index: number
): string => {
    const itemProps = getItemProps(props, index);

    return `accordion item ${index} (${
        itemProps ? itemProps.contentName : ''
    })`;
};

interface RenderGalleryItems {
    props: HighlightFeatureProps & HighlightFeatureModel;
    activeItemIndex: number;
    galleryRef: React.RefObject<HTMLDivElement>;
}

const renderGalleryItems = ({
    props,
    activeItemIndex,
    galleryRef
}: RenderGalleryItems): JSX.Element[] => {
    const {cqPath, translations} = props;
    const features = getChildItems(props);

    return features
        .map((feature: ContainerItem, idx: number) => {
            const itemId = `item_${idx}`;
            const mediaParsysPath = `${cqPath}/${itemId}/${MEDIA_PARSYS}`;
            const isActive = idx === activeItemIndex;
            const isGalleryRef = isActive ? galleryRef : undefined;

            if (feature.model.validationError && !C.isInEditor()) {
                return null;
            }

            return (
                <div ref={isGalleryRef} key={itemId}>
                    <HighlightGallery
                        carouselId={`${itemId}-carousel`}
                        key={itemId}
                        isShown={isActive}
                        mediaParsysPath={mediaParsysPath}
                        contentName={getAccordionItemName(props, idx)}
                        itemCount={features.length}
                        itemPosition={idx + 1}
                        previewAriaLabel={
                            translations['carFeature.previewAriaLabel']
                        }
                        slideXOfYLabel={translations['carousel.slideXOfYLabel']}
                        galleryLabel={translations['carousel.galleryLabel']}
                        goToNextSlideLabel={
                            translations['carousel.goToNextSlideLabel']
                        }
                        goToPreviousSlideLabel={
                            translations['carousel.goToPreviousSlideLabel']
                        }
                        carouselItemLabel={
                            translations['moodGallery.carouselItemLabel']
                        }
                    />
                </div>
            );
        })
        .filter(item => item !== null) as JSX.Element[];
};

interface RenderAccordionItems {
    props: HighlightFeatureProps & HighlightFeatureModel;
    activeItemIndex: number;
    clickHandler: (idx: number, title?: string | undefined) => void;
    galleries: JSX.Element[];
}

const renderAccordionItems = ({
    props,
    activeItemIndex,
    clickHandler,
    galleries
}: RenderAccordionItems): JSX.Element[] => {
    const {anchorId, cqPath} = props;
    const features = getChildItems(props);

    const accordionIds = features.map(
        (_, idx: number) => `${anchorId}_item_${idx}`
    );

    return features.map((feature: ContainerItem, idx: number) => {
        const itemAnchorModel = feature.model as XfCarFeatureModel;
        const itemAnchorId = itemAnchorModel?.anchorId;
        const accordionItemAnchorId =
            itemAnchorId && itemAnchorId !== 'main'
                ? `${anchorId}_${itemAnchorId}`
                : accordionIds[idx];

        return (
            <HighlightAccordionItem
                path={`${cqPath}/${feature.key}`}
                itemIdx={idx}
                anchorId={accordionItemAnchorId}
                prevAnchorId={idx > 0 ? accordionIds[idx - 1] : undefined}
                nextAnchorId={
                    idx < features.length - 1
                        ? accordionIds[idx + 1]
                        : undefined
                }
                feature={feature.model as XfCarFeatureModel}
                gallery={galleries[idx]}
                handleItemClick={clickHandler}
                isActive={activeItemIndex === idx}
                key={feature.key}
            />
        );
    });
};

export const getActiveAnchorIdIndex = (
    features: ContainerItem[],
    anchorId: string
) => {
    const currentUrlHash = isInBrowser()
        ? window.location.hash.slice(1)
        : undefined;

    if (!currentUrlHash) {
        return;
    }

    const activeDefaultIndex = features.findIndex((feature: ContainerItem) => {
        const itemAnchorId = (feature.model as XfCarFeatureModel)?.anchorId;
        return currentUrlHash === `${anchorId}_${itemAnchorId}`;
    });

    if (activeDefaultIndex === -1) {
        return;
    }

    return activeDefaultIndex;
};

const smoothScrollToItem = (idx: number, section: HTMLElement): void => {
    const sectionHeightAndOffset =
        getSectionOffsetTop(section) + getSectionItemsHeight(idx, section);
    const scrollDistance = getDistanceCorrectedByNavbarHeight(
        sectionHeightAndOffset
    );

    smoothScroll({distance: scrollDistance, animationDuration: 350});
};

const InternalHighlightFeatureSection = (
    props: HighlightFeatureProps & HighlightFeatureModel
) => {
    const {
        anchorId,
        mboxId,
        contentLabels,
        validationError,
        validationMessages,
        contentId: sectionId,
        contentName,
        tagTypeBroken
    } = props;
    const trackingService = useTrackingService();
    const contextTrackingData = useContextTrackingData(contentLabels);
    const features = getChildItems(props);
    const galleryRef: React.RefObject<HTMLDivElement> = React.useRef(null);

    const [activeItemIndex, setActiveItemIndex] = React.useState(0);
    const sectionRef: React.RefObject<HTMLDivElement> = React.useRef(null);

    function trackHashChange(activeDefaultIndex: number) {
        const itemProps = getItemProps(props, activeDefaultIndex);

        if (!itemProps) {
            return;
        }
        trackingService.trackAccordionClickV2({
            id: processContentId(
                itemProps.originalCqPath,
                getAccordionItemName(props, activeDefaultIndex)
            ),
            openClick: true,
            url: window.location.pathname,
            linkName: getAccordionItemName(props, activeDefaultIndex),
            contextData: contextTrackingData,
            accordion: {groupItemState: AccordionGroupState.deepLink}
        });
    }

    React.useEffect(() => {
        function handleHashChange() {
            const activeDefaultIndex = getActiveAnchorIdIndex(
                features,
                anchorId
            );
            if (activeDefaultIndex) {
                setActiveItemIndex(activeDefaultIndex);
                trackHashChange(activeDefaultIndex);
            }
        }
        window.addEventListener('hashchange', handleHashChange);
        handleHashChange();

        return function cleanUp() {
            window.removeEventListener('hashchange', handleHashChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const alertBox = validationError && C.isInEditor() && (
        <AlertBox>
            {validationMessages.map((msg, idx) => (
                <span key={idx}>{msg}</span>
            ))}
        </AlertBox>
    );

    const galleries = renderGalleryItems({props, activeItemIndex, galleryRef});

    const handleItemClick = (idx: number, title?: string): void => {
        if (sectionRef.current) {
            setActiveItemIndex(idx);
            smoothScrollToItem(idx, sectionRef.current);
        }
        const itemProps = getItemProps(props, idx);
        if (idx === activeItemIndex || !itemProps) {
            return;
        }
        trackingService.trackAccordionClickV2({
            id: processContentId(
                itemProps.originalCqPath,
                getAccordionItemName(props, idx)
            ),
            openClick: true,
            url: window.location.pathname,
            linkName: title,
            contextData: contextTrackingData,
            accordion: {groupItemState: AccordionGroupState.click}
        });
    };

    const accordionItems = renderAccordionItems({
        props,
        clickHandler: handleItemClick,
        activeItemIndex,
        galleries
    });

    if (validationError && !C.isInEditor()) {
        return null;
    }

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S125 HighLight Feature Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {alertBox}
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    {C.isInEditor() ? (
                        <StyledAccordionWrapper isInEditor>
                            {accordionItems}
                        </StyledAccordionWrapper>
                    ) : (
                        <StyledContentWrapper ref={sectionRef}>
                            <FakeDesktopGallery
                                activeGalleryIndex={activeItemIndex}
                                galleryRef={galleryRef}
                            >
                                {/* Server has to render a duplicate gallery hidden, to determine first height */}
                                {!isInBrowser() && galleries[activeItemIndex]}
                            </FakeDesktopGallery>
                            <StyledAccordionWrapper>
                                {accordionItems}
                            </StyledAccordionWrapper>
                        </StyledContentWrapper>
                    )}
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const HighlightFeatureSection = MapTo(
    RESOURCE_TYPE,
    InternalHighlightFeatureSection
);
