import {FocalPoint} from '../d6/components/focal-point';
import {MediaFocalPoint} from '../../generated/api';

/**
 * Provides mapping between LSG enum and value saved in AEM for image focal point.
 */

export const focalPointMap: {[key in MediaFocalPoint]: FocalPoint} = {
    TOP_LEFT: {x: 'left', y: 'top'},
    TOP_RIGHT: {x: 'right', y: 'top'},
    TOP_CENTER: {x: 'center', y: 'top'},
    BOTTOM_LEFT: {x: 'left', y: 'bottom'},
    BOTTOM_RIGHT: {x: 'right', y: 'bottom'},
    BOTTOM_CENTER: {x: 'center', y: 'bottom'},
    CENTER_CENTER: {x: 'center', y: 'center'},
    CENTER_LEFT: {x: 'left', y: 'center'},
    CENTER_RIGHT: {x: 'right', y: 'center'}
};
