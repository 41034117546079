import {ICart, ICartFeatureService} from './ICartFeatureService';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {observable} from 'mobx';

@singleton('CartStore')
export class CartStore {
    @observable
    public cart?: ICart;

    @inject()
    private cartFeatureService?: ICartFeatureService;

    @postConstruct()
    public initialize(): void {
        if (!this.cartFeatureService) {
            return;
        }

        this.cartFeatureService.cartService.subscribe(
            'cart',
            (value: ICart) => {
                this.cart = value;
            }
        );
    }
}
