import {C} from '../../registries/compatibilty';
import {PersistentStorageService} from '../persistentStorage/PersistentStorageService';
import {ConfigurableConsoleOptions} from './options';
import {HttpLogger} from './HttpLogger';
import {isEnabled, LOG_LEVELS, LogLevel} from './Logger';

export class ConfigurableConsole {
    private readonly storage?: PersistentStorageService;
    private readonly httpAllowed: boolean;
    private readonly httpLogger?: HttpLogger;
    private useHttpLogger: boolean = true;
    private level: LogLevel = 'warn';

    private static noop = () => {
        // no-op
    };

    public get currentLevel(): LogLevel {
        return this.level;
    }

    public set currentLevel(level: LogLevel) {
        this.level = level;
        this.initAndPersist();
    }

    public switchToConsoleLogger(): void {
        this.useHttpLogger = false;
        this.setup();
    }

    public switchToHttpLogger(): void {
        if (!this.httpAllowed) {
            return;
        }
        this.useHttpLogger = true;
        this.setup();
    }

    public constructor(options: ConfigurableConsoleOptions) {
        this.storage = options.storage;
        this.level = options.level;
        if (options.httpAllowed && !C.isInEditor()) {
            this.httpLogger = new HttpLogger(options);
            this.useHttpLogger = options.useHttpLogger;
            this.httpAllowed =
                typeof options.httpAllowed === 'undefined'
                    ? options.useHttpLogger
                    : options.httpAllowed;
        } else {
            this.useHttpLogger = false;
            this.httpAllowed = false;
        }

        this.loadAndInitConfigs();
    }

    public log(_message?: any, ..._optionalParams: any[]): void {
        console.log(_message, ..._optionalParams);
    }
    public debug(_message?: any, ..._optionalParams: any[]): void {
        // no-op
    }
    public info(_message?: any, ..._optionalParams: any[]): void {
        // no-op
    }
    public warn(_message?: any, ..._optionalParams: any[]): void {
        // no-op
    }
    public error(_message?: any, ..._optionalParams: any[]): void {
        // no-op
    }
    public trace(_message?: any, ..._optionalParams: any[]): void {
        // no-op
    }

    public levelError(): void {
        this.currentLevel = 'error';
    }
    public levelWarning(): void {
        this.currentLevel = 'warn';
    }
    public levelInfo(): void {
        this.currentLevel = 'info';
    }
    public levelDebug(): void {
        this.currentLevel = 'debug';
    }

    private loadAndInitConfigs = (): void => {
        if (this.storage) {
            const storageLevel = this.storage.get('level');
            if (
                storageLevel &&
                LOG_LEVELS.indexOf(storageLevel as LogLevel) >= 0
            ) {
                this.level = storageLevel as LogLevel;
            }
        }
        this.setup();
    };

    private initAndPersist(): void {
        this.setup();
        if (this.storage) {
            this.storage.set('level', this.level);
        }
    }

    private setup(): void {
        LOG_LEVELS.forEach(name => {
            this.bindConsole(name);
        });
    }

    private bindConsole(name: LogLevel): void {
        const delegate: HttpLogger | Console =
            this.httpAllowed && this.useHttpLogger && this.httpLogger
                ? this.httpLogger
                : console;

        if (name in delegate) {
            if (isEnabled(name, this.level)) {
                this[name] = delegate[name].bind(delegate);

                if (name === 'debug') {
                    this.trace = delegate.trace.bind(delegate);
                }
            } else {
                this[name] = ConfigurableConsole.noop;

                if (name === 'debug') {
                    this.trace = ConfigurableConsole.noop;
                }
            }
        }
    }
}
