import {HistoryServiceV2} from '@feature-hub/history-service';
import {
    Spinner,
    styled,
    ButtonNext,
    ButtonPrevious,
    css
} from '@volkswagen-onehub/components-core';
import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {CmsTextLink} from '../../../../components/links/CmsTextLink';
import {
    useModelContext,
    useModelStore,
    useRegistry,
    useRouterService
} from '../../../../context';
import {Pagination} from '../../../../d6/components/pagination';
import {ModelClient} from '../../../../infrastructure/compatibility/ModelClient';
import {PAGER_KEY_AND_EXT} from '../../../../infrastructure/hub/initializeHub';
import {smoothScroll} from '../../../../utils/smoothScroll';
import {
    MagazineTeaserGridSection,
    PAGE_PATH_PREFIX
} from './MagazineTeaserGridSection';
import {buttonFocusStateCSS} from '../../../../d6/components/helpers';

export function generatePath(pageIndex: number) {
    return `${PAGE_PATH_PREFIX}${pageIndex}`;
}

const TOP_BAR_HEIGHT = 100;

export interface MagazinePageAndPaginationProps {
    historyService: HistoryServiceV2;
    preloadedPageIndex: number;
    currentPageIndex: number;
    pageCount: number;
    translations: {[index: string]: string};
}

const StyledSpinnerWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: middle;
`;
StyledSpinnerWrapper.displayName = 'StyledSpinnerWrapper';

const StyledPaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: ${props => props.theme.size.dynamic0250}
        ${props => props.theme.size.grid002};
`;
StyledPaginationWrapper.displayName = 'StyledPaginationWrapper';

const StyledPageNumberWrapper = styled.span<{isActive: boolean}>`
    display: inline-block;
    font-weight: ${props =>
        props.isActive
            ? props.theme.text.weight.bold
            : props.theme.text.weight.regular};
`;
StyledPageNumberWrapper.displayName = 'StyledPageNumberWrapper';

const StyledPageLinkFocusWrapper = styled.li<{isActive: boolean}>`
    list-style: none;

    position: relative;
    z-index: 1;

    & a {
        ${props => buttonFocusStateCSS(props)}
    }

    & a::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        padding: 0.44em 0.73em;
        font-size: ${props => props.theme.fonts.copy[200].fontSize};
        background-color: ${props => props.theme.border.color.secondary};
        border-radius: 100px;
        opacity: ${props => (props.isActive ? 1 : 0)};
        z-index: -1;
    }

    &&& a:focus span {
        background-color: unset;
        color: unset;
    }

    ${props =>
        props.isActive &&
        css`
            & a:focus::before {
                padding: 0 1em;
            }
        `}
`;

StyledPageLinkFocusWrapper.displayName = 'StyledPageLinkFocusWrapper';

export const MagazineGridPage = observer(function MGP(
    props: MagazinePageAndPaginationProps
) {
    const {
        preloadedPageIndex,
        currentPageIndex,
        historyService,
        pageCount,
        translations
    } = props;

    const magazineRef = React.useRef<HTMLDivElement>(null);
    const modelClient: ModelClient = useRegistry().getSingleton('ModelClient');
    const routerService = useRouterService();
    const modelStore = useModelStore();

    const path = useModelContext().value || '/jcr:content/';
    const relPath = path.split('jcr:content')[1];
    const customDataKey = 'pager_' + path;

    const customDataPath =
        preloadedPageIndex === currentPageIndex
            ? path
            : modelStore.getCustomContentPath(
                  `${currentPageIndex}`,
                  customDataKey
              );
    const data = modelStore.getData(customDataPath);

    React.useEffect(() => {
        const pagePath = routerService.pagePath;
        const modelPath = `${pagePath}/jcr:content${relPath}.model.json/__${PAGER_KEY_AND_EXT}/${generatePath(
            currentPageIndex
        )}`;
        if (!data) {
            modelClient.fetch(modelPath).then(customContent => {
                modelStore.insertCustomContent(
                    `${currentPageIndex}`,
                    customDataKey,
                    customContent
                );
            });
        }
    }, [
        data,
        customDataKey,
        relPath,
        path,
        modelStore,
        routerService,
        modelClient,
        currentPageIndex
    ]);

    const navigateTo = (index: number) => {
        smoothScroll({
            animationDuration: 400,
            distance:
                (magazineRef?.current?.getBoundingClientRect().top || 0) -
                TOP_BAR_HEIGHT,
            onScrollEnd: () => {
                historyService.history.push({
                    pathname: index === 0 ? '/' : generatePath(index)
                });
            }
        });
    };

    const pageLinks = [...Array(pageCount)].map((_, idx) => (
        <StyledPageLinkFocusWrapper
            key={idx}
            isActive={currentPageIndex === idx}
        >
            <CmsTextLink
                onClick={() => navigateTo(idx)}
                emphasis="none"
                key={idx}
                href="javascript:void(0);"
                rel={
                    currentPageIndex > idx
                        ? 'prev'
                        : currentPageIndex < idx
                        ? 'next'
                        : undefined
                }
                aria-label={`${translations['magazine.pageLink']} ${idx + 1}`}
                aria-current={currentPageIndex === idx}
            >
                <StyledPageNumberWrapper isActive={currentPageIndex === idx}>
                    {idx + 1}
                </StyledPageNumberWrapper>
            </CmsTextLink>
        </StyledPageLinkFocusWrapper>
    ));

    const nextPageLink = (
        <ButtonNext
            onClick={() => navigateTo(currentPageIndex + 1)}
            href="javascript:void(0);"
            aria-label={translations['magazine.nextPage']}
            tag="a"
            size="small"
        />
    );

    const prvPageLink = (
        <ButtonPrevious
            onClick={() => navigateTo(currentPageIndex - 1)}
            href="javascript:void(0);"
            aria-label={translations['magazine.prevPage']}
            tag="a"
            size="small"
        />
    );

    if (data) {
        return (
            <div ref={magazineRef}>
                <MagazineTeaserGridSection path={customDataPath} renderPage />
                {pageLinks.length > 0 && pageCount > 1 && (
                    <StyledPaginationWrapper>
                        <Pagination
                            navAriaLabel={translations['magazine.navigation']}
                            paginationLinks={pageLinks}
                            currentPageIdx={currentPageIndex}
                            nextPageButton={nextPageLink}
                            previousPageButton={prvPageLink}
                        />
                    </StyledPaginationWrapper>
                )}
            </div>
        );
    }
    return (
        <StyledSpinnerWrapper>
            <Spinner variant="large" />
        </StyledSpinnerWrapper>
    );
});
