import {parsePath} from 'history';
import {LocationDescriptorObjectV1} from '@volkswagen-onehub/navigation-service';
import {Registry} from '../di/Registry';
import {CmsRootLocationTransformer} from './history/CmsRootLocationTransformer';
import {SHOWROOM_NAVIGATION_CONSUMER_ID} from './initializeHub';

export const primaryConsumerLocation = (
    registry: Registry
) => (): LocationDescriptorObjectV1 => {
    const rootLocationTransformer: CmsRootLocationTransformer = registry.getSingleton(
        'RootLocationTransformer'
    );
    const path = rootLocationTransformer.getConsumerPathFromRootLocation(
        window.location,
        SHOWROOM_NAVIGATION_CONSUMER_ID
    );
    return path ? parsePath(path) : {};
};
