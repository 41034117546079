import {useContext} from 'react';
import {DisplayType} from '../../../generated/core';
import {RegisteredDisclaimer} from './DisclaimerStore';
import {GeneralDisclaimerContext} from './GeneralDisclaimerProvider';

export const useDisclaimers = (
    ...displayTypes: DisplayType[]
): RegisteredDisclaimer[] => {
    const ctx = useContext(GeneralDisclaimerContext);
    return ctx?.getAllDisclaimers(displayTypes) || [];
};
