import * as React from 'react';
import {LoginFlyoutProps} from './LoginFlyout';
import {styled, Breakpoints} from '@volkswagen-onehub/components-core';

//the transform is needed because the user profile icon and its text are not correctly aligned
const StyledUserInfo = styled.div`
    max-width: ${props => props.theme.size.grid014};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translateY(3px);

    @media (min-width: ${Breakpoints.b560}px) {
        max-width: ${props => props.theme.size.grid006};
    }

    @media (min-width: ${
        Breakpoints.b960
    }px and max-width: ${Breakpoints.b1280 - 1}px) {
        max-width: ${props => props.theme.size.grid007};
    }

`;
StyledUserInfo.displayName = 'StyledUserInfo';

const StyledTopbar = styled.div`
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colors.background.primary};
    border-bottom: 1px solid ${props => props.theme.colors.border.divider};
    padding-inline-start: ${props => props.theme.size.dynamic0130};
    padding-inline-end: ${props => props.theme.size.static510};
    overflow: hidden;
`;
StyledTopbar.displayName = 'StyledTopbar';

const StyledGreeting = styled.div`
    font-weight: ${props => props.theme.text.weight.bold};
    margin-bottom: ${props => props.theme.size.static100};
`;
StyledGreeting.displayName = 'StyledGreeting';

const StyledHeadline = styled.div`
    align-self: end;
    margin-bottom: ${props => props.theme.size.dynamic0100};
`;
StyledHeadline.displayName = 'StyledHeadline';

const StyledButtons = styled.div`
    display: grid;
    height: fit-content;
    grid-row-gap: ${props => props.theme.size.static250};
    margin-top: ${props => props.theme.size.dynamic0100};
    justify-self: center;
    justify-items: center;
`;
StyledButtons.displayName = 'StyledButtons';

const StyledContent = styled.div`
    align-self: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    display: grid;
    grid-template-rows: auto min-content;
    padding: ${props => props.theme.size.dynamic0200}
        ${props => props.theme.size.dynamic0130};
`;
StyledContent.displayName = 'StyledContent';

const StyledFooter = styled.div`
    justify-self: center;
    align-self: end;
    display: flex;
    flex-wrap: wrap;
    gap: var(--size-dynamic0100);
    justify-content: center;
    padding-top: ${props => props.theme.size.dynamic0100};
`;
StyledFooter.displayName = 'StyledFooter';

//height is set to visually look like the height of the topbar
//which is 52px but looks a little bigger because of the moving frame border
const StyledFlyout = styled.div`
    height: var(--100vh, 100vh);
    display: grid;
    grid-template-rows: 55px 1fr;
    background-color: ${props => props.theme.colors.background.primary};

    @media (min-width: ${Breakpoints.b1920}px) {
        grid-template-rows: 63px 1fr;
    }
`;
StyledFlyout.displayName = 'StyledFlyout';

export const closeButtonContext = React.createContext<JSX.Element>(<></>);

interface LoginFlyoutPropsV2 extends LoginFlyoutProps {
    readonly signupLink?: React.ReactNode;
    readonly greeting?: React.ReactNode;
    readonly footerIcons?: React.ReactNode[];
}

export const LoginFlyoutViewV2 = (props: LoginFlyoutPropsV2) => {
    const {
        heading,
        copy,
        button,
        footer,
        footerIcons,
        signupLink,
        userInfo: email,
        greeting
    } = props;
    const closeButton = React.useContext(closeButtonContext);

    const greetingElement = greeting ? (
        <StyledGreeting>{greeting}</StyledGreeting>
    ) : null;

    const emailElement = email ? (
        <StyledUserInfo>{email}</StyledUserInfo>
    ) : null;

    const footerElements = (footerIcons || [footer]).filter(element =>
        Boolean(element)
    );

    const buttonElement =
        button || signupLink ? (
            <StyledButtons>
                {button}
                {signupLink}
            </StyledButtons>
        ) : null;

    const headingElement =
        greetingElement || heading ? (
            <StyledHeadline>
                {greetingElement}
                {heading}
            </StyledHeadline>
        ) : null;

    return (
        <StyledFlyout>
            <StyledTopbar>
                {closeButton}
                {emailElement}
            </StyledTopbar>
            <StyledContent>
                {headingElement}
                {copy}
                {buttonElement}
                {footerElements.length > 0 ? (
                    <StyledFooter>{footerElements}</StyledFooter>
                ) : null}
            </StyledContent>
        </StyledFlyout>
    );
};
LoginFlyoutViewV2.displayName = 'LoginFlyoutV2';
