import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {InPageNavDropdownLink} from '../../../d6/components/inpage-navigation-dropdown/';
import {
    useExternalLinkLightboxService,
    useNavigationStore,
    useRouterService,
    useTrackingService
} from '../../../context';
import {InPageMenuItemModel} from '../../../context/navigation/NavigationStore';
import {useContextTrackingData} from '../../../hooks/useContextTrackingData';
import {processContentId} from '../../../utils/processContentId';
import {
    ExternalLinkIndicator,
    styled
} from '@volkswagen-onehub/components-core';
import {getCurrentURL} from '../../../utils/browser/getCurrentURL';

const REDIRECT_LINK_TARGET = '_blank'; // There is an optimization ticket for this point: https://sso.volkswagen.de/bctbt/browse/NGWD6-1976

export const isLinkExternal = (link?: string) =>
    Boolean(link?.includes('http') || link?.includes('www'));

export const StyledLinkIconWrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: baseline;
`;

export interface InPageNavigationLinkProps {
    readonly item: InPageMenuItemModel;
}

const InPageNavigationDropdownLink: React.FunctionComponent<InPageNavigationLinkProps> = observer(
    function IPNDL(props: InPageNavigationLinkProps) {
        const {url, id, title} = props.item;

        const navigationStoreDropdown = useNavigationStore();
        const trackingServiceDropdown = useTrackingService();
        const trackingDataDropdown = useContextTrackingData();
        const routerServiceDropdown = useRouterService();
        const externalLinkLightboxServiceDropdown = useExternalLinkLightboxService();

        const activeItemId =
            navigationStoreDropdown.activeInPageNavigationItemId;
        const currentUrl = getCurrentURL();
        const isActive = Boolean(id === activeItemId);

        React.useEffect(() => {
            const formattedItemUrl = url.split('?')[0];

            if (currentUrl?.includes(formattedItemUrl)) {
                navigationStoreDropdown.setActiveInPageNavigationItem(url);
            }
            // navigationStore is not a required dependency here
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentUrl, url, activeItemId]);

        function handleInPageNavigationLinkClick(
            e: React.MouseEvent<HTMLElement>
        ): void {
            trackingServiceDropdown.trackNavigationBarLinkClick(
                processContentId(url),
                {
                    name: title,
                    url
                },
                trackingDataDropdown
            );

            // otherwise perform navigation via router
            if (routerServiceDropdown.isAppLink(url)) {
                e.preventDefault();
                // lock scrolling event so that in page navigation stays visible - used for inPageNavigation v1 only
                navigationStoreDropdown.withScrollLock(() =>
                    navigationStoreDropdown.setActiveInPageNavigationItem(id)
                );

                routerServiceDropdown.navigate(url);
            } else if (
                externalLinkLightboxServiceDropdown.shouldOpenLightbox(url)
            ) {
                e.preventDefault();
                // open lightbox if an external link
                externalLinkLightboxServiceDropdown.openLightbox(
                    url,
                    REDIRECT_LINK_TARGET
                );
            }
        }
        const isExternalLink = isLinkExternal(url);

        const linkTitle = isExternalLink ? (
            <StyledLinkIconWrapper>
                {title}
                {<ExternalLinkIndicator />}
            </StyledLinkIconWrapper>
        ) : (
            <>{title}</>
        );

        return (
            <InPageNavDropdownLink
                href={url}
                isActive={isActive}
                onClick={handleInPageNavigationLinkClick}
            >
                {linkTitle}
            </InPageNavDropdownLink>
        );
    }
);
InPageNavigationDropdownLink.displayName = 'InPageNavigationDropdownLink';
export {InPageNavigationDropdownLink};
