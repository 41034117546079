import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';
import {FlyoutLoggedOutModel} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {
    useLoginAlertService,
    useLoginStore,
    useTrackingService
} from '../../../context';
import {AuthProxyError, ErrorStatus} from '../../../context/vwid/LoginStatus';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {ButtonElementComponent} from '../../editorial/elements/buttonElement/ButtonElement';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';
import {
    IDK_LOGIN,
    LOGIN_BTN_LABEL,
    LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT
} from './constants';
import {observer} from 'mobx-react-lite';
import {LoginFlyoutViewV2} from '../../../components/LoginFlyoutV2';
import {CmsTextLink} from '../../../components/links/CmsTextLink';
import {createBtnLoginClick} from './createBtnLoginClick';

const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

const ObservedFlyoutLoggedOut = observer(function InternalFlyoutLoggedOut(
    props: FlyoutLoggedOutModel
): JSX.Element {
    const loginStore = useLoginStore();
    const loginAlertService = useLoginAlertService();
    const trackingService = useTrackingService();

    const headingElement = <HeadingElement path="heading" order="H2" />;
    const copyItem = <CopyItem path="copy" />;

    const trackLoginBtnClick = (href: string, linkName: string) =>
        trackingService.trackButtonClick(IDK_LOGIN, href, linkName);

    const handleBtnLoginClick = createBtnLoginClick(
        loginStore,
        loginAlertService,
        props.btnCloseLabel
    );

    // property provided by server can produce null
    const btnLoginLabel = props.btnLoginLabel || LOGIN_BTN_LABEL;

    const buttonElement = (
        <ButtonElementComponent
            buttonLabel={btnLoginLabel}
            buttonStyle="primary"
            trackingActionOverride={trackLoginBtnClick}
            layerButton
            onClick={handleBtnLoginClick}
            buttonTitle={btnLoginLabel}
            ignoreExternalLinkLightbox
        />
    );

    const additionalSignInLink = (
        <CmsTextLink
            ignoreExternalLinkLightbox
            onClick={handleBtnLoginClick}
            ariaLabel={props.btnSignupLabel || 'Don’t have an account yet?'}
        >
            {props.btnSignupLabel}
        </CmsTextLink>
    );

    const renderAuthor = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode,
        signupLink: React.ReactNode | null
    ): JSX.Element => (
        <AuthoringWrapper
            title="Login Flyout - Logged Out"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <AlertBox severity={AlertBoxSeverity.INFO}>
                <div>{LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT}</div>
            </AlertBox>
            <Layout>
                <Container
                    gutter={ContainerGutter.dynamic0100}
                    padding={{
                        left: CONTAINER_PADDING_2_6,
                        right: CONTAINER_PADDING_2_6
                    }}
                    wrap={ContainerWrap.always}
                    stretchContent
                >
                    {heading}
                    {copy}
                    {button}
                    {signupLink}
                </Container>
            </Layout>
        </AuthoringWrapper>
    );

    const renderPublish = (
        heading: React.ReactNode,
        copy: React.ReactNode,
        button: React.ReactNode,
        signupLink: React.ReactNode | null
    ): JSX.Element => (
        <LoginFlyoutViewV2
            heading={heading}
            copy={copy}
            button={button}
            signupLink={signupLink}
        />
    );

    return C.isInEditor()
        ? renderAuthor(
              headingElement,
              copyItem,
              buttonElement,
              additionalSignInLink
          )
        : renderPublish(
              headingElement,
              copyItem,
              buttonElement,
              additionalSignInLink
          );
});

export function getErrorStatus(error: any): ErrorStatus {
    if (error instanceof AuthProxyError) {
        return error.status;
    }

    return ErrorStatus.unknownError;
}

export const FlyoutLoggedOut = MapTo(
    'vwa-ngw18/components/structure/login/flyoutLoggedOut',
    ObservedFlyoutLoggedOut
);
