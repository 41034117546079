import * as React from 'react';
import {BaseLinkProps} from '../../teaser-element';
import {buttonFocusStateCSS} from '../../helpers';
import {styled} from '@volkswagen-onehub/components-core';

export interface StyledDropdownLinkProps {
    readonly isActive?: boolean;
}

const StyledDropdownLink = styled.a<StyledDropdownLinkProps>`
    position: relative;
    display: inline-flex;
    padding: 0 ${props => props.theme.size.static150};
    line-height: ${props => props.theme.fonts.copy[200].lineHeight};
    color: ${props => props.theme.colors.button.primary.default};
    font-weight: 400;
    text-decoration: none;

    ${props => buttonFocusStateCSS(props)}

    &:hover,
    &:focus {
        color: ${props => props.theme.colors.button.primary.hover};
    }

    &:focus:before {
        // overrided border offset
        width: calc(100% + ${props => props.theme.size.static150});
        height: calc(100% + ${props => props.theme.size.static150});
    }

    ${props =>
        props.isActive &&
        `
            text-decoration: underline;
            text-underline-offset: 6px;
            text-decoration-thickness: 1px;
        `};
`;

export interface InPageNavDropdownLinkProps extends BaseLinkProps {
    readonly isActive?: boolean;
    readonly isVisible?: boolean;
    readonly children?: React.ReactNode;
    readonly onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export const InPageNavDropdownLink: React.FunctionComponent<InPageNavDropdownLinkProps> = (
    props: InPageNavDropdownLinkProps
) => {
    const {children, href, rel, title, isActive, isVisible, onClick} = props;

    return (
        <li>
            <StyledDropdownLink
                href={href}
                onClick={onClick}
                rel={rel}
                title={title}
                isActive={isActive}
                aria-current={isActive}
                tabIndex={isVisible ? 0 : -1}
            >
                {children}
            </StyledDropdownLink>
        </li>
    );
};
