import {CascadeCheckpoint, Deferred, VwaD6Global} from '../types/global';
import {isInBrowser} from './browser/isInBrowser';
import {getGlobal} from './getGlobal';

export function resolveLoadingCascadeCheckpoint(
    checkpoint: CascadeCheckpoint,
    fn?: VoidFunction
): void {
    if (!isInBrowser()) {
        return;
    }
    const checkpointDeferred = getCheckpoint(checkpoint);
    if (checkpointDeferred) {
        checkpointDeferred.resolve(fn);
    }
}

function getCheckpoint(name: CascadeCheckpoint): Deferred<any> | undefined {
    return getGlobal()[name];
}

function getCheckpointPromise(
    checkpoint: CascadeCheckpoint
): Promise<VwaD6Global | undefined> | undefined {
    const deferred = getCheckpoint(checkpoint);

    return deferred ? deferred.promise : undefined;
}

type ExcludesUndefined = <T>(x: T | undefined) => x is T;

function getLoadingCascade(): Promise<any>[] {
    return [
        getCheckpointPromise('polyfillLoaded'),
        getCheckpointPromise('priorityLoaded'),
        getCheckpointPromise('priorityExecuted')
    ].filter((Boolean as unknown) as ExcludesUndefined);
}

export async function onLoadingCascadeFinished(
    callbackFn: () => void
): Promise<void> {
    if (!isInBrowser()) {
        // during SSR just execute the function
        callbackFn();

        return Promise.resolve();
    }

    const cascadePromises = getLoadingCascade();

    // in AEM author promises will be 'undefined' because all scripts are executed synchronously.
    // having 'undefined' promises will result in loading cascade resolved immediately
    return Promise.all(cascadePromises).then(callbackFn);
}
