import * as React from 'react';

import {Layout} from '@volkswagen-onehub/components-core';
import {HighlightTeaser} from '../../../d6/components/highlight-teaser';

import {MediaAspectRatio} from '../../../../generated/api';
import {
    HighlightTeaserSectionModel,
    TeaserDisplayType
} from '../../../../generated/core';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {useTeaserList} from '../../../utils/tracking/useTeaserList';
import {EditorialTeaserElement} from '../elements/editorial-teaser-element/EditorialTeaserElement';
import {SectionWrapper} from './SectionWrapper';
import {
    TeaserSectionAuthoringView,
    teaserSectionHeading
} from './teasers/common';
import {observer} from 'mobx-react-lite';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {useTeaserMboxIds} from '../../../utils/tracking/useTeaserMboxIds';

// todo: move into higlight-teaser folder in next refactoring round
const InternalHighlightTeaserSection = observer(function HTS(
    props: HighlightTeaserSectionModel
): JSX.Element | null {
    const {
        anchorId,
        mboxId,
        cqItems,
        validationError,
        validationMessages,
        contentId: sectionId,
        contentName,
        contentLabels,
        tagTypeBroken,
        personalizableChildren
    } = props;

    function renderItems(): JSX.Element[] {
        const {
            numberOfValidElements,
            showCategoryElement,
            personalizable,
            personalizableChildren
        } = props;
        const items: JSX.Element[] = [];

        for (let i = 0; i < numberOfValidElements; i++) {
            const itemId = `item_${i}`;

            const highlightTeaser = (
                <EditorialTeaserElement
                    path={itemId}
                    key={itemId}
                    personalizable={!personalizable && personalizableChildren}
                    displayType={getTeaserDisplayTypeByIndex(i)}
                    showAbstractElement={false}
                    showCategoryElement={showCategoryElement}
                    itemCount={numberOfValidElements}
                    itemPosition={i + 1}
                />
            );

            const contextValue = {
                ratio: getAspectRatioByIndex(i, numberOfValidElements),
                sizes: getSizesByIndex(i)
            };

            items.push(
                <MediaContext.Provider value={contextValue}>
                    {highlightTeaser}
                </MediaContext.Provider>
            );
        }

        return items;
    }
    const teaserList = useTeaserList(personalizableChildren, cqItems);
    const teaserMboxIds = useTeaserMboxIds(personalizableChildren, cqItems);

    // error in publish view, show nothing
    if (validationError && !C.isInEditor()) {
        return null;
    }

    const items = renderItems();
    let sideItems = items.slice(0);
    const highlightItem = (
        <CyAttributeAppender name="highlightTeaserHighlightItem">
            {sideItems.shift() || <React.Fragment />}
        </CyAttributeAppender>
    );

    sideItems = sideItems.map(item => {
        return (
            <CyAttributeAppender
                name="highlightTeaserSideItem"
                key={item.key || undefined}
            >
                {item}
            </CyAttributeAppender>
        );
    });

    if (C.isInEditor()) {
        // authoring view
        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <TeaserSectionAuthoringView
                    anchorId={anchorId}
                    emptyLabel={'S110 Highlight Teaser Section'}
                    validationMessages={validationMessages}
                    validationError={validationError}
                    tagTypeBroken={tagTypeBroken}
                >
                    <div id={anchorId}>
                        <Layout>
                            <HighlightTeaser
                                headlineElement={teaserSectionHeading}
                                sideTeasers={sideItems}
                                highlightTeaser={highlightItem}
                            />
                            <SectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        </Layout>
                    </div>
                </TeaserSectionAuthoringView>
            </GeneralDisclaimerProvider>
        );
    } else {
        // publish view
        return (
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                teaserList={teaserList}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
                teaserMboxIds={teaserMboxIds}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <Layout allowOverflowingContent>
                        <HighlightTeaser
                            headlineElement={elseUndefined(
                                !cqItems.heading.empty,
                                teaserSectionHeading
                            )}
                            sideTeasers={sideItems}
                            highlightTeaser={highlightItem}
                        />
                        <SectionDisclaimerContainer
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </Layout>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        );
    }
});

const getAspectRatioByIndex = (
    idx: number,
    numberOfValidElements: number
): MediaAspectRatio => {
    // aspect ratio 4:3 only for side teasers and only if more than 2 side teasers present
    return numberOfValidElements > 3 && idx > 0 ? 'r_4_3' : 'r_1_1';
};

const getSizesByIndex = (idx: number): string => {
    if (idx === 0) {
        return '50vw';
    }

    return '15vw';
};

const getTeaserDisplayTypeByIndex = (idx: number): TeaserDisplayType => {
    switch (idx) {
        case 0:
            return 'DEFAULT';
        default:
            return 'SMALL';
    }
};

export const HighlightTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/highlightTeaserSection',
    InternalHighlightTeaserSection
);
