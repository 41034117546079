import * as React from 'react';

export const calcOffsetSizeRatio = (e: React.MouseEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.getBoundingClientRect().bottom;
    const a = (bottom - e.clientY) / e.currentTarget.clientHeight;

    return a;
};

export const getProgressBarMouseDownEventData = (
    e: React.MouseEvent<HTMLDivElement>,
    rect: ClientRect
) => {
    const clientValue = e.clientY;

    return {
        clientValue,
        offsetValue: rect.bottom - clientValue
    };
};

export const getClientValue = (e: MouseEvent) => e.clientY;

export const getClientTouchValue = (e: TouchEvent) =>
    e.targetTouches[0].clientY;

export const getProgressBarSize = (el: Element) => el.clientHeight;

export const calcDelta = (clientValue: number, prevClientValue: number) =>
    prevClientValue - clientValue;

export const isBelowMin = (clientValue: number, rect: ClientRect) =>
    clientValue > rect.bottom;

export const isAboveMax = (clientValue: number, rect: ClientRect) =>
    clientValue < rect.top;

export const getTouchData = (e: React.TouchEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clientValue = e.targetTouches[0].clientY;
    const offsetValue = rect.bottom - clientValue;

    return {
        clientValue,
        offsetValue
    };
};
