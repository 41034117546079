import {
    FocalPoint,
    horizontalPercentages,
    verticalPercentages
} from '../focal-point';

interface VideoDimensions {
    readonly innerWidth: number;
    readonly innerHeight: number;
}

interface ElementDimensions {
    readonly clientWidth: number;
    readonly clientHeight: number;
}

export interface VideoWrapperStyle {
    readonly width: number;
    readonly height: number;
    readonly marginLeft?: number;
    readonly marginTop?: number;
}

export function getVideoDimensions(video: HTMLVideoElement): VideoDimensions {
    return {
        innerWidth: video.videoWidth,
        innerHeight: video.videoHeight
    };
}

export function getWrapperDimensions(element: HTMLElement): ElementDimensions {
    return {
        clientHeight: element.clientHeight,
        clientWidth: element.clientWidth
    };
}

export function getMarginLeftForFocusPoint(
    margin: number,
    focalPoint: FocalPoint
): number {
    if (focalPoint) {
        if (focalPoint.x === 'left') {
            return 0;
        }
        if (focalPoint.x === 'right') {
            return margin;
        }
    }

    return Math.round(margin / 2);
}

export function getMarginTopForFocusPoint(
    margin: number,
    focalPoint: FocalPoint
): number {
    if (focalPoint) {
        if (focalPoint.y === 'top') {
            return 0;
        }
        if (focalPoint.y === 'bottom') {
            return margin;
        }
    }

    return Math.round(margin / 2);
}

export function calculateVideoWrapperStyle(
    elemDim: ElementDimensions,
    videoDim: VideoDimensions,
    focalPoint: FocalPoint
): VideoWrapperStyle {
    // Gather container dimensions and aspect ratio
    const {clientWidth: outerWidth, clientHeight: outerHeight} = elemDim;
    const outerRatio = outerWidth / outerHeight;

    // Gather correct dimensions and aspect ratio of animation
    const {innerWidth, innerHeight} = videoDim;
    const innerRatio = innerWidth / innerHeight;

    if (innerRatio > outerRatio) {
        const newWidth = Math.round(outerHeight * innerRatio);
        const diff = outerWidth - newWidth;

        return {
            height: outerHeight,
            marginLeft: getMarginLeftForFocusPoint(diff, focalPoint),
            width: newWidth
        };
    } else {
        const newHeight = Math.round(outerWidth / innerRatio);
        const diff = outerHeight - newHeight;

        return {
            height: newHeight,
            marginTop: getMarginTopForFocusPoint(diff, focalPoint),
            width: outerWidth
        };
    }
}

/**
 * Focal point to object position
 */
export function fpToObjPos(focalPoint: FocalPoint): string {
    return `${horizontalPercentages[focalPoint.x]}% ${
        verticalPercentages[focalPoint.y]
    }%`;
}
