import * as React from 'react';
import {SchemaVideoModel} from '../../../../../generated/core';

export function SchemaVideoMicrodata(
    props: SchemaVideoModel
): JSX.Element | null {
    const {
        name,
        description,
        duration,
        uploadDate,
        thumbnailUrl,
        contentUrl
    } = props;

    return name && description && thumbnailUrl && uploadDate ? (
        <div itemScope itemType="http://schema.org/VideoObject">
            <meta itemProp="name" content={name} />
            <meta itemProp="description" content={description} />
            <meta itemProp="duration" content={duration} />
            <meta itemProp="uploadDate" content={uploadDate} />
            <meta itemProp="thumbnailUrl" content={thumbnailUrl} />
            <meta itemProp="contentUrl" content={contentUrl} />
        </div>
    ) : null;
}
