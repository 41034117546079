import {MediaAspectRatio} from '../../../../../generated/api';
import {TeaserDisplayType} from '../../../../../generated/core';

const ASPECT_RATIOS: MediaAspectRatio[] = [
    'r_10_6',
    'r_4_5',
    'r_1_1',
    'r_16_9'
];

export function getAspectRatioByIndex(idx: number): MediaAspectRatio {
    if (idx > ASPECT_RATIOS.length - 1) {
        return ASPECT_RATIOS[0];
    }

    return ASPECT_RATIOS[idx];
}

export function getTeaserDisplayTypeByIndex(idx: number): TeaserDisplayType {
    switch (idx) {
        case 0:
            return 'FIRST_LEVEL_A';
        case 1:
            return 'FIRST_LEVEL_B';
        default:
            return 'DEFAULT';
    }
}
