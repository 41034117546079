import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {CopyInsetMedia} from '../../../d6/components/copy-inset-media';

import {CopyInsetMediaSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {aspectRatioMap} from '../../../utils/aspectRatioMap';
import {mediaAlignmentMap} from '../../../utils/mediaAlignmentMap';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {getContainerPadding} from '../../../d6/components/helpers';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const mediaContextValue = {sizes: '(min-width: 768px) 20vw, 90vw'};

const CONTAINER_PADDING_2_5 = getContainerPadding([2, 5]);
const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);

/**
 * S103 Copy Inset Media Section
 */
const InternalCopyInsetMediaSection = (props: CopyInsetMediaSectionModel) => {
    const {
        alignment,
        anchorId,
        contentName,
        contentLabels,
        cqItems,
        mediaAspectRatio,
        contentId: sectionId,
        tagTypeBroken,
        mboxId
    } = props;

    const hasHeadline =
        C.isInEditor() ||
        (Boolean(cqItems) &&
            Boolean(cqItems.headline) &&
            !cqItems.headline.empty);

    const headline = (
        <Container
            padding={{
                inlineStart: CONTAINER_PADDING_2_5,
                inlineEnd: CONTAINER_PADDING_2_6
            }}
            wrap={ContainerWrap.always}
        >
            <HeadingElement path="headline" order="H2" />
        </Container>
    );

    const media = (
        <MediaContext.Provider value={mediaContextValue}>
            <MediaElement path="media" />
        </MediaContext.Provider>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S103 Copy Inset Media Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <Layout allowOverflowingContent>
                        <Container
                            gutter={ContainerGutter.dynamic0100}
                            wrap={ContainerWrap.always}
                        >
                            {hasHeadline && headline}
                            <CopyInsetMedia
                                aspectRatio={aspectRatioMap[mediaAspectRatio]}
                                media={media}
                                mediaAlignment={mediaAlignmentMap[alignment]}
                            >
                                <CopyItem path="copy" />
                            </CopyInsetMedia>
                        </Container>
                        <BelowSectionDisclaimerContainer
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </Layout>
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const CopyInsetMediaSection = MapTo(
    'vwa-ngw18/components/editorial/sections/copyInsetMediaSection',
    InternalCopyInsetMediaSection
);
