import * as React from 'react';

import {
    Container,
    ContainerPadding,
    ContainerWrap,
    Layout
} from '@volkswagen-onehub/components-core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SimpleDisclaimerWrapper} from '../../../context/layer/SimpleDisclaimerWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {Parsys, ParsysType} from '../../structure/Parsys';

function InternalLinkListItem(): JSX.Element {
    const linkListParsys = (
        <Parsys path="linkListParsys" parsysType={ParsysType.LINK_PARSYS} />
    );

    return (
        <SimpleDisclaimerWrapper getItemDisclaimers>
            {C.isInEditor() ? (
                <AuthoringWrapper
                    title="I108 Link List Item"
                    bgColor={AuthoringWrapper.BG_COLOR_ITEM}
                >
                    <Layout>
                        <Container
                            wrap={ContainerWrap.always}
                            padding={{
                                left: ContainerPadding.grid001,
                                right: ContainerPadding.grid001
                            }}
                        >
                            {linkListParsys}
                        </Container>
                    </Layout>
                </AuthoringWrapper>
            ) : (
                <CyAttributeAppender name="linkListItem">
                    {linkListParsys}
                </CyAttributeAppender>
            )}
        </SimpleDisclaimerWrapper>
    );
}

export const LinkListItem = MapTo(
    'vwa-ngw18/components/editorial/items/linkListItem',
    InternalLinkListItem
);
