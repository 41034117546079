import * as React from 'react';
import {observer} from 'mobx-react-lite';

import {
    CTA,
    SkipLink as SkipLinkComponent,
    styled
} from '@volkswagen-onehub/components-core';

import {useSkipLinksStore} from '../../../context';
import {SkipLink} from '../../../context/navigation/SkipLinksStore';
import {ZIndex} from '../../../utils/zIndex';
import {setTopBarHeight} from '../../../d6/components/navigation-top-bar-one-hub-helpers';

const CSS_VAR_TOP_BAR_HEIGHT = '--s2-one-hub-navigation-top-bar-height';

const StyledSkiplinkWrapper = styled.nav`
    ${setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, true)}
    position: fixed;
    width: 100%;
    top: var(${CSS_VAR_TOP_BAR_HEIGHT});
    left: 0;
    z-index: ${ZIndex.skipLinks.z};
`;

export const StyledSkipLinkTargetWrapper = styled.div`
    outline: none;
`;

export const SkiplinkContainer = observer(function SL() {
    const {skipLinks, skipLinksWrapperLabel} = useSkipLinksStore();

    if (!skipLinks.length) {
        return null;
    }

    return (
        <StyledSkiplinkWrapper
            role="navigation"
            aria-label={skipLinksWrapperLabel}
        >
            {skipLinks.map(skipLink => (
                <SkipLinkItem
                    label={skipLink.label}
                    enabled={skipLink.enabled}
                    onClick={skipLink.onClick}
                    key={`skip-link-${skipLink.label}`}
                />
            ))}
        </StyledSkiplinkWrapper>
    );
});

const SkipLinkItem = ({
    label,
    enabled,
    onClick
}: SkipLink): JSX.Element | null =>
    enabled ? (
        <SkipLinkComponent visible={false}>
            <CTA tag="a" emphasis="tertiary" onClick={onClick} href="#">
                {label}
            </CTA>
        </SkipLinkComponent>
    ) : null;

export const SkipLinkTargetWrapper = React.forwardRef<
    HTMLDivElement,
    React.HTMLProps<HTMLDivElement>
>((props, ref) => {
    return (
        <StyledSkipLinkTargetWrapper ref={ref} tabIndex={-1}>
            {props.children}
        </StyledSkipLinkTargetWrapper>
    );
});
