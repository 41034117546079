import * as React from 'react';
import ReactDOM from 'react-dom';

import {useGlobalConfig} from '../context';

interface CyAtttributeAppenderProps {
    readonly name: string;
    readonly children: JSX.Element;
    readonly addTag?: 'div' | 'span';
    readonly asClassName?: boolean;
}

interface InternalCyAttributeAppenderProps extends CyAtttributeAppenderProps {
    readonly isEnabled: boolean;
}

class InternalCyAttributeAppender extends React.Component<
    InternalCyAttributeAppenderProps
> {
    public componentDidMount(): void {
        const {isEnabled, asClassName, name} = this.props;
        const element = isEnabled && ReactDOM.findDOMNode(this);
        if (element && element instanceof Element) {
            if (asClassName) {
                element.classList.add(name);
            } else {
                element.setAttribute('data-cy', name);
            }
        }
    }

    public render(): JSX.Element {
        return this.props.children;
    }
}

export const CyAttributeAppender = (
    props: CyAtttributeAppenderProps
): JSX.Element => {
    const {testAutomationConfigModel} = useGlobalConfig();

    if (testAutomationConfigModel.enabled && props.addTag) {
        return React.createElement(
            props.addTag,
            {'data-cy': props.name},
            props.children
        );
    }

    return (
        <InternalCyAttributeAppender
            {...props}
            isEnabled={testAutomationConfigModel.enabled}
        />
    );
};
