import * as React from 'react';
import {XfContentSliderModel} from '../../../../generated/core';
import {ContentSlider} from '../../../d6/components/content-slider';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {useTrackContentSliderLoad} from '../../../utils/tracking/useTrackContentSliderLoad';
import {
    XF_CONTENT_SLIDER_RESOURCE_TYPE,
    XfContentSlider
} from '../../structure/main/XfContentSlider';
import {ContentSliderSectionModelExtended} from './ContentSliderSection';
import {useTrackFilterChange} from '../../../utils/tracking/useTrackFilterChange';
import {Filter} from '../../../context/tracking/TrackingService';
import {isInBrowser} from '../../../utils/browser/isInBrowser';
import {VISIBILITY_TRACKING_TIMEOUT} from './constants/trackingTimeout';

export function ContentSliderGroup(
    props: ContentSliderSectionModelExtended
): JSX.Element {
    const {
        allCategoriesLabel,
        filterLabel,
        contentSliderLabel,
        goToNextSlideLabel,
        goToPreviousSlideLabel,
        anchorId,
        contentName,
        contentLabels,
        hideCategories,
        cqPath,
        personalizableChildren,
        cqItems,
        numberOfValidElements
    } = props;

    const ref = React.useRef(null);
    const trackFilterChange = useTrackFilterChange();
    const [activeCategory, setActiveCategory] = React.useState<Filter>({
        values: [allCategoriesLabel],
        numberOfResults: numberOfValidElements,
        name: 'CA',
        type: 'multi_value'
    });

    const handleFilterChange = (filter: string, slidesCount: number) => {
        setActiveCategory({
            values: [filter],
            numberOfResults: slidesCount,
            name: 'CA',
            type: 'multi_value'
        });
        trackFilterChange(filter, slidesCount);
    };

    const contentCategories = Object.keys(cqItems)
        .filter(key => key.startsWith('item_'))
        .map(key => cqItems[key] as XfContentSliderModel)
        .filter(xf => xf.cqType === XF_CONTENT_SLIDER_RESOURCE_TYPE);

    const categoriesCount = contentCategories
        .map(s => s.category)
        .reduce((acc: {[key: string]: number}, str) => {
            acc[str] = ++acc[str] || 1;
            return acc;
        }, {});

    const trackingFocusCategories = Object.entries(categoriesCount).map(
        ([category, count]) => ({
            Name: category,
            NumberOfElements: count
        })
    );
    const slides = contentCategories.map((xf, idx) => {
        const itemId = `item_${idx}`;
        return {
            key: itemId,
            category: xf.category,
            ariaLabel: '',
            element: (
                <XfContentSlider
                    personalizable={personalizableChildren}
                    path={itemId}
                    key={itemId}
                    itemCount={contentCategories.length}
                    itemPosition={idx + 1}
                    focusCategories={trackingFocusCategories}
                    activeCategorylist={[activeCategory]}
                />
            )
        };
    });
    const isUserInBrowser = isInBrowser();

    const [isVisible, setVisibility] = React.useState(false);
    const [hasTimerRunOut, setTimerRunOut] = React.useState(false);
    const enableTracking = React.useMemo(() => {
        if (hasTimerRunOut && isVisible) return isVisible;
        return false;
    }, [isVisible, hasTimerRunOut]);

    React.useEffect(() => {
        if (isUserInBrowser && isVisible) {
            // Make sure the user has been on the slider for at least 1 second before tracking
            const timer = setTimeout(() => {
                setTimerRunOut(true);
            }, VISIBILITY_TRACKING_TIMEOUT);

            return () => clearTimeout(timer);
        }

        return () => {
            // This comment is here to make SonarQube happy
        };
    }, [isVisible, isUserInBrowser]);

    React.useEffect(() => {
        if (isUserInBrowser && ref.current) {
            const observer = new IntersectionObserver(([entry]) =>
                setVisibility(entry.isIntersecting)
            );
            observer.observe(ref.current as any);

            return () => {
                observer.disconnect();
            };
        }

        return () => {
            // This comment is here to make SonarQube happy
        };
    }, [ref, isUserInBrowser]);

    const handleSliderChanged = useTrackContentSliderLoad({
        contentName,
        contentLabels,
        cqPath,
        sliderItemsLength: slides.length,
        trackingFocusCategories,
        filterlist: [activeCategory],
        enableTracking
    });

    let categories: string[] = [];
    categories.push(`${allCategoriesLabel} (${slides.length})`);
    categories = categories.concat(
        trackingFocusCategories.map(
            value => `${value.Name} (${value.NumberOfElements})`
        )
    );

    const id = `contentSlider${anchorId}`;

    if (C.isInEditor()) {
        const categoriesView = hideCategories ? '' : categories.join('|');
        return (
            <>
                {categoriesView}
                {slides.map(s => s.element)}
            </>
        );
    }

    return (
        <CyAttributeAppender name="contentSlider">
            <ContentSlider
                id={id}
                ariaLabel={contentSliderLabel}
                previousSlideAriaLabel={goToPreviousSlideLabel}
                nextSlideAriaLabel={goToNextSlideLabel}
                slides={slides}
                hideChips={hideCategories}
                allChipLabel={allCategoriesLabel}
                chipAriaLabel={filterLabel}
                onSlideChange={handleSliderChanged}
                onFilterChange={handleFilterChange}
                innerRef={ref}
            />
        </CyAttributeAppender>
    );
}
