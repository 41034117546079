import {Model} from '@adobe/cq-react-editable-components';
import {TransferModel} from '@adobe/cq-spa-page-model-manager';
import {C} from '../../registries/compatibilty';

let modelsCache: {[key: string]: {}} = {};
let itemsCache: {[key: string]: {}} = {};

export function clearModelsToPropsCache() {
    modelsCache = {};
    itemsCache = {};
}

export const modelToProps = (item: TransferModel, path: string): Model => {
    const keys = Object.getOwnPropertyNames(item);

    if (!modelsCache[path] || C.isInEditor()) {
        modelsCache[path] = {};
    }

    const props: Partial<Model> = modelsCache[path];

    keys.forEach(key => {
        let propKey = key;

        if (propKey === ':items') {
            if (!itemsCache[path] || C.isInEditor()) {
                itemsCache[path] = {};
            }
            const cqItems: {[key: string]: Model} = itemsCache[path];
            props.cqItems = cqItems;
            Object.keys(item[propKey]).forEach((subKey: string) => {
                const newItem = item[':items'][subKey];
                if (newItem) {
                    cqItems[subKey] = modelToProps(
                        newItem,
                        `${path}/${subKey}`
                    );
                }
            });
        } else {
            if (propKey.startsWith(':')) {
                // Transformation of internal properties namespaced with [:] to [cq]
                // :myProperty => cqMyProperty
                const tempKey = propKey.substr(1);
                propKey =
                    'cq' +
                    tempKey.substr(0, 1).toUpperCase() +
                    tempKey.substr(1);
            }

            props[propKey] = item[key];
        }
    });

    return props as Model;
};
