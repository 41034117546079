import * as React from 'react';
import {useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';
import ReactDOM from 'react-dom';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    TextTag,
    ThemeProvider,
    styled
} from '@volkswagen-onehub/components-core';
import {MediaAssetImageModel} from '../../../generated/core';
import {
    getElectricTransitionAdvanced,
    getStartDirection,
    Direction
} from '../../d6/components/helpers';
import {
    clearAllBodyScrollLocks,
    disableBodyScroll,
    enableBodyScroll
} from 'body-scroll-lock';
import {
    MediaDisclaimerHolder,
    MediaElementDisclaimerData
} from '../../modules/editorial/elements/MediaElement';
import {noop} from '../../utils/noop';
import {DragButton} from './DragButton';
import {
    DragDirection,
    DragIntention,
    DragStartEvent,
    SHOWCASE_PLAYBACK_RATE,
    determineDragIntention,
    getDragDirection,
    isDragEvent,
    isDragged
} from './helpers';
import {FocalPoint} from '../../d6/components/focal-point';
import {VideoWithFocalPoint} from '../../d6/components/video-with-focal-point';
import {
    Degree180Context,
    Degree180Store
} from '../../context/media/Degree180Store';
import {getMaxDuration} from '../../d6/components/video-player-v2/helpers';
import {
    HorizontalProgressBar,
    VideoPlayerProgressBarSelectedValue
} from '../../d6/components/video-player-progress-bar';
import {MIN_VOLUME_VALUE} from '../../d6/components/video-player-helpers/constants';
import {ArrowKeys} from '../../d6/components/video-player-helpers/types';
import {VideoPoster} from '../videoPoster';
import {ResponsiveMediaRendererConf} from '../ResponsiveMediaRenderer';
import {StyledAspectRatioContainer} from '../../d6/components/helpers/aspect-ratio-container';

export const VIDEO_PLAYER_CONTROLS_HEIGHT = 45;
export const KEYPRESS_TIMELINE_MOVE_DEFAULT = 0.1;
export const KEYPRESS_TIMELINE_MOVE_FASTER = 0.5;

export interface DragData {
    direction: DragDirection;
    currentTime: number;
}

export type DraggableVideoProps = MediaDisclaimerHolder &
    Readonly<{
        src: string;
        isFullscreen: boolean;
        currentTime: number;
        dragDirection?: DragDirection;
        animateOnLoad?: boolean;
        interactive?: boolean;
        matchParent?: boolean;
        innerRef: React.RefObject<HTMLDivElement>;
        onDragChanged?(data: DragData): void;
        title: string;
        progressBarAriaLabel: string;
        coverImage: MediaAssetImageModel;
        responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    }>;

type StyledVideoContainerProps = Readonly<{
    isDragged?: boolean;
    matchParent?: boolean;
    isProgressBarFocused?: boolean;
    interactive?: boolean;
}>;

// NOTE: background is "black" due to none available video background color in core
const StyledDraggableVideo = styled.div<StyledVideoContainerProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.matchParent && 'height: 100%'};
    ${props => props.matchParent && 'width: 100%'};
    ${props =>
        props.interactive &&
        `
        cursor: ${props.isDragged ? 'grabbing' : 'grab'};
        background: ${props.theme.colors.content.primary};
    `}
`;
StyledDraggableVideo.displayName = 'StyledDraggableVideo';

interface StyledDragButtonContainerProps {
    readonly dragDirection?: DragDirection;
}

const StyledDragButtonContainer = styled.div<StyledDragButtonContainerProps>`
    position: absolute;
    opacity: ${props => (props.dragDirection === 'none' ? '1' : '0')};
    ${props =>
        props.dragDirection === 'left' && 'transform: translateX(-150%)'};
    ${props =>
        props.dragDirection === 'right' && 'transform: translateX(150%)'};
    transition: ${getElectricTransitionAdvanced(
        {name: 'opacity'},
        {name: 'transform'}
    )};
`;

const DISCLAIMER_OFFSET = 24;
const fadeClassName = 'fadeAnimation';

const StyledDisclaimerReferences = styled.span<StyledVideoContainerProps>`
    ${props =>
        props.theme.direction === Direction.RTL &&
        `
    @media (max-width: ${Breakpoints.b560}px) {
        margin-bottom: calc(${props.theme.size.static250} + ${VIDEO_PLAYER_CONTROLS_HEIGHT}px);
    }`}

    position: absolute;
    ${props => getStartDirection(props.theme.direction)}: 0;
    bottom: 0;
    margin-bottom: ${props => props.theme.size.static250};
    margin-${props => getStartDirection(props.theme.direction)}:
        ${props => props.theme.size.static350};
    margin-inline-start: ${props => props.theme.size.static350};
    display: flex;
    justify-content: center;
    min-width: ${DISCLAIMER_OFFSET}px;

    transition: ${getElectricTransitionAdvanced(
        {name: 'opacity'},
        {name: 'transform'}
    )};

    transform: translateY(0);
    ${props =>
        props.isProgressBarFocused &&
        `
            transform: translateY(-${props.theme.size.static535});
        `}

    opacity: 1;

    &.${fadeClassName}-exit-active, &.${fadeClassName}-exit-done {
        opacity: 0;
    }

    &.${fadeClassName}-exit-done {
        visibility: hidden;
    }
`;
StyledDisclaimerReferences.displayName = 'StyledDisclaimerReferences';

const StyledToolbar = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    padding: ${props => props.theme.size.static350}
        ${props => props.theme.size.static500};
    background: rgba(0, 0, 0, 0.55);

    transition: ${getElectricTransitionAdvanced(
        {name: 'opacity'},
        {name: 'transform'}
    )};

    opacity: 0;
    // approx 35% - we can't move it more, as triggered focus would scroll a view a bit once the focused elements is out of visible box
    transform: translateY(35%);

    &:focus-within {
        opacity: 1;
        transform: translateY(0);
    }
`;

const focalPoint: FocalPoint = {x: 'center', y: 'center'};
const defaultStyle: React.CSSProperties = {position: 'absolute'};

// Following variables are used for preserving the state between different event renders.
// They don't need to be part of the component, that's why they are created as local variables.
let seekInProgress: boolean = false;
let showcasePlayInProgress: boolean = false;
let currentX: number | undefined;
let dragStart: DragStartEvent | undefined;
let moveIntention: DragIntention | undefined;

const lockSeeking = () => (seekInProgress = true);

const unlockSeeking = () => (seekInProgress = false);

function useDegree180Store(): Degree180Store {
    return React.useContext(Degree180Context) as Degree180Store;
}

export const getPlayer = (
    videoPlayer: React.RefObject<HTMLVideoElement>
): HTMLVideoElement | null => {
    const player = videoPlayer.current;

    if (player && player instanceof HTMLVideoElement) {
        return player;
    }

    return null;
};

const renderDisclaimerRefs = (
    disclaimers: MediaElementDisclaimerData,
    _isDragged?: boolean,
    isProgressBarFocused?: boolean
): JSX.Element | undefined => {
    const {
        staticReferences = [],
        interactiveReferences,
        color = 'dark'
    } = disclaimers;
    const hasDisclaimers = interactiveReferences || staticReferences.length > 0;

    if (!hasDisclaimers) {
        return;
    }

    return (
        <ThemeProvider theme={color === 'dark' ? 'main' : 'inverted'}>
            <CSSTransition
                classNames={fadeClassName}
                in={!_isDragged}
                timeout={300}
            >
                <StyledDisclaimerReferences
                    isProgressBarFocused={isProgressBarFocused}
                >
                    {staticReferences.length > 0 && (
                        <Text
                            appearance={TokenTextAppearance.copy100}
                            tag={TextTag.span}
                        >
                            {staticReferences}
                        </Text>
                    )}
                    {interactiveReferences ?? ''}
                </StyledDisclaimerReferences>
            </CSSTransition>
        </ThemeProvider>
    );
};

// todo: use "Layer Manager" for fullscreen
// todo: should be moved to ../../d6/draggable-video
export function DraggableVideo(props: DraggableVideoProps) {
    const {
        animateOnLoad,
        currentTime,
        disclaimers = {},
        dragDirection,
        interactive = true,
        isFullscreen,
        matchParent,
        onDragChanged = noop,
        progressBarAriaLabel,
        src,
        title,
        coverImage,
        responsiveMediaConfig
    } = props;

    const draggableVideo: React.RefObject<HTMLDivElement> = props.innerRef;
    const videoPlayer: React.RefObject<HTMLVideoElement> = React.useRef(null);
    const degree180Store: Degree180Store = useDegree180Store();
    const [isCoverShown, setIsCoverShown] = React.useState(
        videoPlayer && !showcasePlayInProgress && !isFullscreen
    );

    useEffect(() => {
        if (interactive) {
            const container = getDraggableVideo();
            if (container) {
                // events need to be marked as not passive to prevent scrolling when needed
                container.addEventListener('touchstart', handleTouchStart, {
                    passive: false
                });
                container.addEventListener('touchmove', handleTouchDrag, {
                    passive: false
                });
                container.addEventListener('touchend', handleTouchEnd, {
                    passive: false
                });
            }

            return () => {
                const container = getDraggableVideo();
                if (container) {
                    container.removeEventListener(
                        'touchstart',
                        handleTouchStart,
                        false
                    );
                    container.removeEventListener(
                        'touchmove',
                        handleTouchDrag,
                        false
                    );
                    container.removeEventListener(
                        'touchend',
                        handleTouchEnd,
                        false
                    );
                }
                clearAllBodyScrollLocks();
            };
        } else {
            return;
        }
        // should only execute once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interactive]);

    useEffect(() => {
        initSource();
        // should only execute on src change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    useEffect(() => {
        // synchronize time
        const player = getPlayer(videoPlayer);
        if (player) {
            if (currentTime !== player.currentTime) {
                player.currentTime = currentTime;
            }
        }
    }, [currentTime]);

    const handleTimeUpdate = () => {
        const player = getPlayer(videoPlayer);

        if (
            showcasePlayInProgress &&
            !seekInProgress &&
            player &&
            player.currentTime >= player.duration / 2
        ) {
            player.pause();
            showcasePlayInProgress = false;
            onDragChanged({
                direction: 'none',
                currentTime: player.currentTime
            });
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        currentX = e.clientX;
        dragStart = {
            x: e.clientX,
            y: e.clientY,
            time: e.timeStamp
        };
    };

    const handleMouseUp = () => {
        handleDragEnd();
    };

    const handleMouseDrag = (e: React.MouseEvent) => {
        const {clientX} = e;

        if (!isDragEvent(clientX, currentX)) {
            return;
        }

        updateTime(clientX);
    };

    const handleTouchStart = (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        e.stopPropagation();

        if (touch) {
            const clientX = touch.clientX;
            const clientY = touch.clientY;
            dragStart = {
                x: clientX,
                y: clientY,
                time: e.timeStamp
            };
            currentX = clientX;
        }
    };

    const handleTouchEnd = (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        e.stopPropagation();

        if (touch) {
            handleDragEnd();
        }
    };

    const handleTouchDrag = (e: TouchEvent) => {
        const touch = e.changedTouches[0];
        e.stopPropagation();

        if (!dragStart) {
            return;
        }

        // move intention
        if (!moveIntention) {
            moveIntention = determineDragIntention(touch, dragStart);
        }

        // skip when scrolling
        if (moveIntention !== 'horizontal') {
            return;
        }

        const {clientX} = touch;

        if (!isDragEvent(clientX, currentX)) {
            return;
        }

        updateTime(clientX);
    };

    const updateTime = (clientX: number) => {
        const player = getPlayer(videoPlayer);
        const container = getDraggableVideo();

        // skip too frequent updates
        if (player && container && !seekInProgress && currentX) {
            const dragCoefficient = player.duration / player.clientWidth;
            const playerTime =
                player.currentTime + (currentX - clientX) * dragCoefficient;

            // shortening video to prevent black screen in Safari
            player.currentTime = Math.min(
                Math.max(playerTime, 0),
                Math.trunc(player.duration * 100 - 5) / 100
            );

            // send onDragStarted only once with proper direction
            if (!isDragged(props.dragDirection)) {
                const direction = getDragDirection(currentX, clientX);
                disableBodyScroll(container);
                onDragChanged({
                    direction,
                    currentTime: player.currentTime
                });
            }

            currentX = clientX;
        }
    };

    const handleDragEnd = () => {
        const player = getPlayer(videoPlayer);
        if (!dragStart || !player) {
            return;
        }

        const {onDragChanged = noop} = props;
        onDragChanged({
            direction: 'none',
            currentTime: player.currentTime
        });

        const video = getDraggableVideo();
        if (video) {
            enableBodyScroll(video);
        }
        currentX = undefined;
        dragStart = undefined;
        moveIntention = undefined;
    };

    const handleProgressBarDragChange = (
        value: VideoPlayerProgressBarSelectedValue
    ) => {
        const player = getPlayer(videoPlayer);
        if (player) {
            player.currentTime = value.value;
            const {onDragChanged = noop} = props;
            onDragChanged({
                direction: 'none',
                currentTime: player.currentTime
            });
        }
    };

    const handleProgressBarKeyDown = (key: ArrowKeys, shiftKey: boolean) => {
        const player = getPlayer(videoPlayer);
        const KEYPRESS_TIMELINE_MOVE = shiftKey
            ? KEYPRESS_TIMELINE_MOVE_FASTER
            : KEYPRESS_TIMELINE_MOVE_DEFAULT;
        let newPlayerTime = 0;

        if (player) {
            if (key === ArrowKeys.ArrowLeft) {
                newPlayerTime = props.currentTime - KEYPRESS_TIMELINE_MOVE;
            }

            if (key === ArrowKeys.ArrowRight) {
                newPlayerTime = props.currentTime + KEYPRESS_TIMELINE_MOVE;
            }

            const currPlayerMaxDuration = getMaxDuration(player.duration);
            const isMinDurationReached = newPlayerTime <= 0;
            const isMaxDurationReached = newPlayerTime >= currPlayerMaxDuration;
            player.currentTime =
                isMaxDurationReached || isMinDurationReached
                    ? isMaxDurationReached
                        ? currPlayerMaxDuration
                        : 0
                    : newPlayerTime;

            const {onDragChanged = noop} = props;
            onDragChanged({
                direction: 'none',
                currentTime: player.currentTime
            });
        }
    };

    const handleProgressFocus = () => {
        const player = getPlayer(videoPlayer);
        if (player) {
            player.scrollIntoView();
        }
        degree180Store.updateProgressBarFocusState(true);
    };

    const handleProgressBlur = () => {
        degree180Store.updateProgressBarFocusState(false);
    };

    const startShowCasePlay = async (player: HTMLVideoElement) => {
        if (!interactive || !animateOnLoad) {
            return;
        }
        player.playbackRate = SHOWCASE_PLAYBACK_RATE;
        const promise = player.play();
        // not all browsers provide promise
        if (promise) {
            try {
                await promise;
            } catch (error) {
                return;
            }
        }
        setIsCoverShown(false);
        showcasePlayInProgress = true;
        const {onDragChanged = noop} = props;
        onDragChanged({
            direction: 'right',
            currentTime: player.currentTime
        });
    };

    const getDraggableVideo = (): HTMLDivElement | null => {
        const player = draggableVideo.current
            ? ReactDOM.findDOMNode(draggableVideo.current)
            : null;

        if (player && player instanceof HTMLDivElement) {
            return player;
        }

        return null;
    };

    const initSource = async () => {
        const player = getPlayer(videoPlayer);
        if (player) {
            // load the player as soon as the source is set
            player.load();
            player.onloadedmetadata = () => {
                const duration = getMaxDuration(player.duration);
                degree180Store.updateDuration(duration);
            };

            await startShowCasePlay(player);
        }
    };

    const _isDragged = isDragged(dragDirection);
    const isProgressBarFocused = degree180Store.isProgressBarFocused;

    const progressBarPercentageValue =
        degree180Store.duration > 0
            ? `${Math.round(
                  props.currentTime / (degree180Store.duration / 100)
              )}%`
            : '0%';
    const progressBarLabel = title
        ? title + ' - ' + progressBarAriaLabel
        : progressBarAriaLabel;

    const videoPosterElement = React.useMemo(
        () => (
            <StyledAspectRatioContainer aspectRatio={'matchParentHeight'}>
                <VideoPoster
                    image={coverImage}
                    onLoad={noop}
                    responsiveMediaConfig={responsiveMediaConfig}
                />
            </StyledAspectRatioContainer>
        ),
        [coverImage, responsiveMediaConfig]
    );

    const disclaimerReferences = renderDisclaimerRefs(
        disclaimers,
        _isDragged,
        isProgressBarFocused
    );

    return (
        <ThemeProvider theme="inverted">
            <StyledDraggableVideo
                ref={draggableVideo}
                isDragged={_isDragged}
                matchParent={matchParent}
                interactive={interactive}
                onMouseDown={interactive ? handleMouseDown : noop}
                onMouseMove={interactive ? handleMouseDrag : noop}
                onMouseUp={interactive ? handleMouseUp : noop}
            >
                <VideoWithFocalPoint
                    focalPoint={focalPoint}
                    innerRef={videoPlayer}
                    src={src}
                    muted
                    playsInline
                    preload="none"
                    disablePointerEvents
                    reduceBrightness={
                        !_isDragged && !isProgressBarFocused && interactive
                    }
                    keepOriginalAspectRatio={isFullscreen}
                    onTimeUpdate={handleTimeUpdate}
                    onSeeking={lockSeeking}
                    onSeeked={unlockSeeking}
                    style={defaultStyle} // using styled() breaks the innerRef
                />
                {isCoverShown && videoPosterElement}
                {interactive && !isProgressBarFocused && (
                    <StyledDragButtonContainer dragDirection={dragDirection}>
                        <DragButton />
                    </StyledDragButtonContainer>
                )}

                {interactive && (
                    <StyledToolbar>
                        <HorizontalProgressBar
                            valueMin={MIN_VOLUME_VALUE}
                            valueMax={degree180Store.duration}
                            value={props.currentTime}
                            progressBarAriaLabel={progressBarLabel}
                            onDragChange={handleProgressBarDragChange}
                            onProgressBarKeyDown={handleProgressBarKeyDown}
                            onFocus={handleProgressFocus}
                            onBlur={handleProgressBlur}
                            horizontalProgressBarValueText={
                                progressBarPercentageValue
                            }
                        />
                    </StyledToolbar>
                )}
                {disclaimerReferences}
            </StyledDraggableVideo>
        </ThemeProvider>
    );
}
