import * as React from 'react';

import {Text, TokenTextAppearance} from '@volkswagen-onehub/components-core';

interface CopyrightProps {
    readonly copyright?: string;
}

const year = new Date().getFullYear();
const copyrightFallback = `© Volkswagen ${year}`;

export const Copyright = (props: CopyrightProps): JSX.Element => {
    const {copyright} = props;

    return (
        <Text appearance={TokenTextAppearance.copy100}>
            {copyright ? copyright : copyrightFallback}
        </Text>
    );
};
