import * as React from 'react';

import {
    Container,
    ContainerBreakpointConfig,
    ContainerDirectionalConfig,
    DesignTokenSizeEnum,
    ContainerWrap
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../d6/components/helpers';
import {LayoutSingleColumn} from '../../../generated/core';

const CONTAINER_PADDING_0_2 = getContainerPadding([0, 2]);
const CONTAINER_PADDING_2_4 = getContainerPadding([2, 4]);
const CONTAINER_PADDING_2_6 = getContainerPadding([2, 6]);
const CONTAINER_PADDING_2_6_7 = getContainerPadding([2, 6, 7]);

// the padding for text components
export const TEXT_CONTAINER_PADDING: ContainerDirectionalConfig<ContainerBreakpointConfig<
    DesignTokenSizeEnum
>> = {
    left: CONTAINER_PADDING_2_6_7,
    right: CONTAINER_PADDING_2_6_7
};

// the padding for media components
const MEDIA_CONTAINER_PADDING = {
    SMALL: {
        left: CONTAINER_PADDING_2_6,
        right: CONTAINER_PADDING_2_6
    },
    MEDIUM: {
        left: CONTAINER_PADDING_2_4,
        right: CONTAINER_PADDING_2_4
    },
    LARGE: {
        left: CONTAINER_PADDING_0_2,
        right: CONTAINER_PADDING_0_2
    },
    FULL: {}
};

export enum ColumnLayoutChildType {
    MEDIA = 'media',
    TEXT = 'text'
}

export interface ColumnLayoutWrapperProps {
    readonly layout: LayoutSingleColumn;
    readonly childType: ColumnLayoutChildType;
}

export const getColumnLayoutPadding = (
    childType: ColumnLayoutChildType,
    layout: LayoutSingleColumn
):
    | ContainerDirectionalConfig<ContainerBreakpointConfig<DesignTokenSizeEnum>>
    | undefined => {
    if (childType === ColumnLayoutChildType.MEDIA) {
        const containerPadding = MEDIA_CONTAINER_PADDING[layout];

        return Object.keys(containerPadding).length > 0
            ? containerPadding
            : undefined;
    } else {
        return TEXT_CONTAINER_PADDING;
    }
};

export const ColumnLayoutWrapper: React.FunctionComponent<ColumnLayoutWrapperProps> = props => {
    const {children, childType, layout = 'LARGE'} = props;

    return (
        <Container
            padding={getColumnLayoutPadding(childType, layout)}
            stretchContent
            wrap={ContainerWrap.always}
        >
            {children}
        </Container>
    );
};
