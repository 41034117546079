import {Direction} from '../helpers';

export const HORIZONTAL_FADE_WIDTH = 32; // static400

export function findListItemXPosition(
    listWrapper: HTMLDivElement,
    listItemId: string,
    direction: Direction
): number {
    const listItemElement = listWrapper.querySelector(`[href='${listItemId}']`);

    if (listItemElement instanceof HTMLElement) {
        let offset = listItemElement.offsetLeft;

        if (direction === Direction.RTL) {
            offset =
                listWrapper.offsetWidth - offset - listItemElement.offsetWidth;
        }
        return offset;
    }
    return 0;
}

interface GetNewPositionProps {
    scrollableElement: HTMLDivElement;
    activeItem: string;
    spacing: number;
    direction: Direction;
}

export const getNewPosition = ({
    scrollableElement,
    activeItem,
    spacing,
    direction
}: GetNewPositionProps): number => {
    const {scrollWidth, offsetWidth} = scrollableElement;

    if (!scrollWidth || !offsetWidth) {
        return 0;
    }

    const xPosition = findListItemXPosition(
        scrollableElement,
        activeItem,
        direction
    );

    const threshold = offsetWidth / 2;
    const maxScrollDistance = scrollWidth - offsetWidth;
    const thresholdReached = xPosition >= threshold;

    const scrollDirectionFactor = direction === Direction.RTL ? -1 : 1;

    return thresholdReached
        ? ((xPosition + spacing) / (scrollWidth - spacing)) *
              maxScrollDistance *
              scrollDirectionFactor
        : 0;
};

interface GetLinkDistance {
    listWrapper: HTMLDivElement;
    previousPosition: string | null;
    newPosition: string | null;
}

let cachedListOfLinks: HTMLAnchorElement[];

export const getLinkDistance = ({
    listWrapper,
    previousPosition,
    newPosition
}: GetLinkDistance): number => {
    if (!previousPosition || !newPosition) {
        return 1;
    }

    const listOfLinks =
        cachedListOfLinks ||
        (cachedListOfLinks = Array.from(listWrapper.querySelectorAll('a')));
    const currentIndex = listOfLinks.findIndex(
        link => link.hash === previousPosition
    );
    const newIndex = listOfLinks.findIndex(link => link.hash === newPosition);

    return Math.abs(currentIndex - newIndex);
};

export const getFocusedListItemIndex = (
    list: NodeListOf<HTMLAnchorElement> | 0
) => {
    if (!list) {
        return -1;
    }

    return Array.from(list).findIndex(
        element => element && document.activeElement === element
    );
};

export const focusItem = (
    list: NodeListOf<HTMLAnchorElement> | 0,
    linkIndex: number
) => {
    if (list && linkIndex < list.length) {
        const element = list[linkIndex];

        if (element) {
            (element as HTMLElement).focus();
        }
    }
};

export const getItemURL = (
    list: NodeListOf<HTMLAnchorElement> | 0,
    linkIndex: number
) => {
    if (list && linkIndex < list.length) {
        const element = list[linkIndex];

        if (element) {
            return element.hash;
        }
    }

    return 0;
};

interface GetNextIndexProps {
    focusedItemIdx: number;
    listItemsLength: number;
    moveBack: boolean;
}

export const getNextIndex = ({
    focusedItemIdx,
    listItemsLength,
    moveBack
}: GetNextIndexProps) => {
    const ifFocusedItemIdxIsSmallerOrEqualZero =
        focusedItemIdx <= 0 ? listItemsLength - 1 : focusedItemIdx - 1;

    const isFocusedItemIdxInRange =
        focusedItemIdx < 0 || focusedItemIdx === listItemsLength - 1
            ? 0
            : focusedItemIdx + 1;

    return moveBack
        ? ifFocusedItemIdxIsSmallerOrEqualZero
        : isFocusedItemIdxInRange;
};

interface ShouldMoveBackwardsProps {
    isShiftKey: boolean;
    keyboardKey: string;
    direction: Direction;
}

export const shouldMoveBackwards = ({
    keyboardKey,
    direction,
    isShiftKey
}: ShouldMoveBackwardsProps) => {
    switch (keyboardKey) {
        case 'ArrowLeft': {
            return direction === Direction.LTR;
        }
        case 'ArrowRight': {
            return direction === Direction.RTL;
        }
        case 'Tab': {
            return isShiftKey;
        }
        default: {
            return false;
        }
    }
};
