import {Layout} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {
    MagazineTeaserGridSectionModel,
    TeaserElementModel
} from '../../../../../generated/core';
import {SectionDisclaimerContainer} from '../../../../components/disclaimers/SectionDisclaimerContainer';
import {MagazineTeaserGrid} from '../../../../d6/components/magazine-teaser-grid';
import {
    ReadingTimeBox,
    ReadingTimeTextAlign
} from '../../../../d6/components/reading-timebox';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {TrackedSecondLevelTeaserElement} from '../../elements/TrackedSecondLevelTeaserElement';
import {SectionWrapper} from '../SectionWrapper';
import {TeaserSectionAuthoringView} from '../teasers/common';
import {observer} from 'mobx-react-lite';
import {Route} from 'react-router';
import {Router} from 'react-router';
import {MagazineGridPage} from './MagazineGridPage';
import {useCustomHistoryManager} from '../../../../context';
import {GeneralDisclaimerProvider} from '../../../../context/disclaimer/GeneralDisclaimerProvider';

const EMPTY_LABEL = 'S134 Magazine Teaser Grid';
export const PAGE_PATH_PREFIX = 'page_';

type ExtendedMagazineTeaserGridSectionModel = ExtraMagazineGridSectionProps &
    MagazineTeaserGridSectionModel;

interface ExtraMagazineGridSectionProps {
    renderPage?: boolean;
}

function InternalMagazineGridTeaser(
    props: MagazineTeaserGridSectionModel
): JSX.Element {
    const {cqItems, cqItemsOrder, showCategory} = props;
    const teasersItems = cqItems as {[key: string]: TeaserElementModel};

    const teaserItemsWithHref = cqItemsOrder
        .filter(itemId => itemId !== 'heading')
        .map(itemId => {
            const teaser = teasersItems[itemId];

            return {itemId, teaser};
        })
        .filter(data => Boolean(data.teaser.teaserElementLinkHref));

    const teaserItems = teaserItemsWithHref.map((data, idx) => {
        const {
            teaser: {articleMeta},
            itemId
        } = data;

        const readElement = (
            <>
                <CyAttributeAppender name="magazineTeaserReadingTime">
                    <strong>
                        {articleMeta?.readingTime} {articleMeta?.minutesLabel}
                    </strong>
                </CyAttributeAppender>{' '}
                {articleMeta?.readLabel}
            </>
        );

        // empty element is needed for correct height calculations of reading time box
        const emptyReadElement = <>&zwnj;</>;

        const readingTimeElement = (
            <ReadingTimeBox
                textAlign={ReadingTimeTextAlign.left}
                noWormyAnimation
                readingTime={
                    articleMeta?.readingTime >= 0
                        ? readElement
                        : emptyReadElement
                }
            />
        );

        return (
            <CyAttributeAppender key={itemId} name="secondLevelTeaserElement">
                <TrackedSecondLevelTeaserElement
                    teaserTrackingName={data.teaser.headingPlainText}
                    showCategory={showCategory}
                    showOriginInfo={C.isInEditor()}
                    readingTimeElement={readingTimeElement}
                    teaserData={{
                        teaser: data.teaser as TeaserElementModel,
                        itemPosition: idx + 1,
                        itemId: data.itemId,
                        itemCount: teaserItemsWithHref.length
                    }}
                />
            </CyAttributeAppender>
        );
    });

    return <MagazineTeaserGrid teaserItems={teaserItems} />;
}

function AuthorView(props: MagazineTeaserGridSectionModel): JSX.Element {
    const {
        anchorId,
        mboxId,
        contentLabels,
        contentId: sectionId,
        contentName,
        tagTypeBroken
    } = props;

    return (
        <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
            <TeaserSectionAuthoringView
                anchorId={anchorId}
                emptyLabel={EMPTY_LABEL}
                tagTypeBroken={tagTypeBroken}
            >
                <SectionWrapper
                    anchorId={anchorId}
                    sectionId={sectionId}
                    contentLabels={contentLabels}
                    contentName={contentName}
                    mboxId={mboxId}
                >
                    <InternalMagazineGridTeaser {...props} />
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </SectionWrapper>
            </TeaserSectionAuthoringView>
        </GeneralDisclaimerProvider>
    );
}
export const PAGER_CONSUMER_ID = 'pager';

const PublishView = observer(function PV(
    props: ExtendedMagazineTeaserGridSectionModel
): JSX.Element {
    const historyManager = useCustomHistoryManager();
    const pagerHistoryService = historyManager.getHistoryService(
        PAGER_CONSUMER_ID
    );
    const {
        pageCount,
        pageIndex,
        anchorId,
        contentLabels,
        contentId: sectionId,
        contentName,
        translations,
        mboxId
    } = props;

    return (
        <Router history={pagerHistoryService.history}>
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <Layout allowOverflowingContent>
                    <TeaserSectionAuthoringView
                        anchorId={anchorId}
                        emptyLabel={EMPTY_LABEL}
                    >
                        <SectionWrapper
                            anchorId={anchorId}
                            sectionId={sectionId}
                            contentLabels={contentLabels}
                            contentName={contentName}
                            mboxId={mboxId}
                        >
                            <Route
                                path="/"
                                exact
                                render={_routeProps => {
                                    return (
                                        <MagazineGridPage
                                            translations={translations}
                                            historyService={pagerHistoryService}
                                            currentPageIndex={0}
                                            pageCount={pageCount}
                                            preloadedPageIndex={pageIndex}
                                        />
                                    );
                                }}
                            />
                            <Route
                                path={`/${PAGE_PATH_PREFIX}:index`}
                                exact
                                render={routeProps => {
                                    const currentPageIndex = parseInt(
                                        routeProps.match.params['index'],
                                        10
                                    );
                                    return (
                                        <MagazineGridPage
                                            translations={translations}
                                            historyService={pagerHistoryService}
                                            currentPageIndex={currentPageIndex}
                                            pageCount={pageCount}
                                            preloadedPageIndex={pageIndex}
                                        />
                                    );
                                }}
                            />

                            <SectionDisclaimerContainer
                                displayTypes={['T3_SECTION_BASED']}
                            />
                        </SectionWrapper>
                    </TeaserSectionAuthoringView>
                </Layout>
            </GeneralDisclaimerProvider>
        </Router>
    );
});

export const InternalMagazineTeaserGridSection = React.memo(
    (props: ExtendedMagazineTeaserGridSectionModel): JSX.Element => {
        if (props.renderPage) {
            return <InternalMagazineGridTeaser {...props} />;
        }
        return C.isInEditor() ? (
            <AuthorView {...props} />
        ) : (
            <PublishView {...props} />
        );
    }
);

const MAGAZIN_GRID_SECTION_RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/sections/magazineTeaserGridSection';
export const MagazineTeaserGridSection = MapTo<ExtraMagazineGridSectionProps>(
    MAGAZIN_GRID_SECTION_RESOURCE_TYPE,
    InternalMagazineTeaserGridSection
);
