import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    Layout,
    ThemeProvider
} from '@volkswagen-onehub/components-core';

export interface SimpleStageProps {
    readonly heading: React.ReactNode;
    readonly subheading: React.ReactNode;
}

const StyledWrapper = styled.div`
    padding: ${props => props.theme.size.dynamic0130}
        ${props => props.theme.size.grid002};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: 0;
        padding-right: 0;
    }

    text-align: center;
`;
StyledWrapper.displayName = 'StyledWrapper';

const StyledHeading = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid005};
        padding-right: ${props => props.theme.size.grid005};
    }
    margin-bottom: ${props => props.theme.size.dynamic0100};
`;
StyledHeading.displayName = 'StyledHeading';

const StyledSubHeading = styled.div`
    @media (min-width: ${Breakpoints.b560}px) {
        padding-left: ${props => props.theme.size.grid006};
        padding-right: ${props => props.theme.size.grid006};
    }
`;
StyledSubHeading.displayName = 'StyledSubHeading';

export const SimpleStage: React.FunctionComponent<SimpleStageProps> = props => {
    const {heading, subheading} = props;

    return (
        <ThemeProvider theme="main">
            <Layout>
                <StyledWrapper>
                    <StyledHeading>{heading}</StyledHeading>
                    <StyledSubHeading>{subheading}</StyledSubHeading>
                </StyledWrapper>
            </Layout>
        </ThemeProvider>
    );
};
SimpleStage.displayName = 'SimpleStage';
