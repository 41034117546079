import * as React from 'react';
import styled from 'styled-components';

import {BottomNav} from '../../d6/components/footer';

const StyledLayerFooter = styled.div`
    border-top: solid 2px ${props => props.theme.colors.border.hover};
    padding-top: ${props => props.theme.size.dynamic0150};
    margin-top: ${props => props.theme.size.dynamic0270};
`;

export interface LayerFooterProps {
    disclaimers?: React.ReactNode;
    linkItems?: React.ReactNode;
    bottomNavAriaLabel: string;
}

export function LayerFooter(props: LayerFooterProps): React.ReactElement {
    const {disclaimers, linkItems, bottomNavAriaLabel} = props;

    return (
        <StyledLayerFooter>
            <BottomNav bottomNavAriaLabel={bottomNavAriaLabel} hideCopyright>
                {linkItems}
            </BottomNav>
            {disclaimers}
        </StyledLayerFooter>
    );
}
