import * as React from 'react';

import {DisplayType, MediaElementModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {WrappingOverlayItemDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {RegisteredDisclaimer} from '../../../context/disclaimer/DisclaimerStore';
import {useDisclaimerReferences} from '../../../context/disclaimer/useDisclaimerReferences';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {AnimationElement} from './animationElement/AnimationElement';
import {Degree180Element} from './degree180Element/Degree180Element';
import {VideoElement} from './videoElement/VideoElement';
import {useTrackAssetType} from '../../../hooks/useTrackAssetType';
import {useSectionContext, useSpaAsyncConfig} from '../../../context';
import {
    useStaticDisclaimerReferenceElements,
    useInteractiveDisclaimerBadges
} from './helpers';
import {ImageElement} from './ImageElement';
import {ScrollytellingElement} from './scrollytellingElement/ScrollytellingElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {CountdownState} from '../../../context/tracking/TrackingService';
import {
    styled,
    BreakpointWrapper,
    BreakpointWrapperRenderingConfig,
    Breakpoints
} from '@volkswagen-onehub/components-core';
import {C} from '../../../registries/compatibilty';

export type MediaElementDisclaimerData = Readonly<{
    staticReferences?: JSX.Element[];
    interactiveReferences?: JSX.Element;
    color?: 'dark' | 'light';
    itemBasedDisclaimers?: RegisteredDisclaimer[];
}>;

export type MediaDisclaimerHolder = Readonly<{
    disclaimers?: MediaElementDisclaimerData;
}>;

export type MediaElementExtraProps = Readonly<{
    storeId?: string;
    useParentAspectRatio?: boolean;
    isDecorative?: boolean;
    hideItemBasedDisclaimers?: boolean; // do not show item-based discl. on overlay
    enableItemInteractiveDisclaimer?: boolean;
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    interactive?: boolean;
    actsAsTeaser?: boolean;
    countdownState?: CountdownState;
    onAssetLoad?: () => void;
    allowNestedDisclaimers?: boolean;
    portraitBreakpoints?: Breakpoints;
}>;

export type MediaElementProps = Readonly<Partial<MediaElementModel>> &
    MediaElementExtraProps &
    Readonly<{
        hideItemBasedDisclaimers?: boolean;
    }>;

const StyledWrapper = styled.div`
    display: flex;
    height: 100%;
    gap: 2px;

    & > div {
        position: relative;
        width: 50%;
    }
`;

const StyledLabel = styled.div`
    position: absolute;
    top: 0;
    left: 0;

    height: 18px;
    line-height: 18px;
    font-size: 10px;
    padding: 0 ${props => props.theme.size.static150};
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background: ${props => props.theme.colors.button.primary.default};
`;

function InternalMediaElement(props: MediaElementProps): JSX.Element {
    const {
        darkDisclaimer,
        disclaimers = [],
        enableItemInteractiveDisclaimer,
        hideItemBasedDisclaimers, // show/hide item base disclaimers in overlay
        interactive,
        mediaType,
        portraitMediaType,
        storeId,
        useParentAspectRatio,
        actsAsTeaser,
        countdownState,
        onAssetLoad,
        portraitBreakpoints,
        responsiveMediaConfig
    } = props;

    const sectionId = useSectionContext()?.sectionId;

    useTrackAssetType(
        mediaType,
        portraitMediaType,
        sectionId,
        portraitBreakpoints
    );

    const {enablePageInteractiveDisclaimers} = useSpaAsyncConfig();

    const disclaimerReferences = useDisclaimerReferences(disclaimers);

    // all disclaimers except openable & item based disclaimers
    const interactiveDisclaimers = disclaimerReferences
        .filter(d => d.reference !== undefined)
        .filter(
            disclaimer =>
                disclaimer.displayType !== 'T6_OPENABLE' &&
                (enablePageInteractiveDisclaimers ||
                    disclaimer.displayType !== 'T2_PAGE_BASED') &&
                (enableItemInteractiveDisclaimer ||
                    disclaimer.displayType !== 'T4_ITEM_BASED')
        );

    // not using already filtered item-based, to maintain references ordering
    const nonInteractiveDisclaimers = disclaimerReferences
        .filter(d => d.reference !== undefined)
        .filter(
            disclaimer =>
                (!enablePageInteractiveDisclaimers &&
                    disclaimer.displayType === 'T2_PAGE_BASED') ||
                (!hideItemBasedDisclaimers &&
                    disclaimer.displayType === 'T4_ITEM_BASED')
        );

    const openableDisclaimers = disclaimerReferences
        .filter(d => d.reference !== undefined)
        .filter(disclaimer => disclaimer.displayType === 'T6_OPENABLE');

    const staticReferences = useStaticDisclaimerReferenceElements(
        nonInteractiveDisclaimers
    );

    const interactiveReferences = useInteractiveDisclaimerBadges(
        openableDisclaimers,
        interactiveDisclaimers
    );

    // item-based are needed separately for fulltext overlay
    const overlayDisplayTypes: DisplayType[] = hideItemBasedDisclaimers
        ? []
        : ['T4_ITEM_BASED'];

    const mediaDisclaimerProps: MediaDisclaimerHolder = {
        disclaimers: {
            color: darkDisclaimer ? 'dark' : 'light',
            interactiveReferences,
            staticReferences
        }
    };

    const containerProps = {matchParent: useParentAspectRatio};

    const mediaComp = createMediaComponent({
        mediaType,
        portrait: false,
        mediaDisclaimerProps,
        useParentAspectRatio,
        containerProps,
        responsiveMediaConfig,
        actsAsTeaser,
        countdownState,
        onAssetLoad,
        storeId,
        interactive
    });

    const portraitMediaComp = portraitMediaType
        ? createMediaComponent({
              mediaType: portraitMediaType,
              portrait: true,
              mediaDisclaimerProps,
              useParentAspectRatio,
              containerProps,
              responsiveMediaConfig,
              actsAsTeaser,
              countdownState,
              onAssetLoad,
              storeId,
              interactive
          })
        : null;

    return (
        <AuthoringWrapper
            title="Media Element"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <CyAttributeAppender name="mediaElement">
                <WrappingOverlayItemDisclaimers
                    displayTypes={overlayDisplayTypes}
                    color="light"
                >
                    {!portraitMediaComp && mediaComp}
                    {portraitMediaComp && C.isInEditor() && (
                        <StyledWrapper>
                            <div>
                                <StyledLabel>Main Media</StyledLabel>
                                {mediaComp}
                            </div>
                            <div>
                                <StyledLabel>Mobile Potrait Media</StyledLabel>
                                {portraitMediaComp}
                            </div>
                        </StyledWrapper>
                    )}
                    {!C.isInEditor() && portraitMediaComp && (
                        <BreakpointWrapperRenderingConfig>
                            <BreakpointWrapper min={portraitBreakpoints}>
                                {mediaComp}
                            </BreakpointWrapper>
                            <BreakpointWrapper max={portraitBreakpoints}>
                                {portraitMediaComp}
                            </BreakpointWrapper>
                        </BreakpointWrapperRenderingConfig>
                    )}
                </WrappingOverlayItemDisclaimers>
            </CyAttributeAppender>
        </AuthoringWrapper>
    );
}

/**
 * Media Element
 */
const MediaElementWithDisclaimerProvider = (
    props: MediaElementModel & MediaElementExtraProps
) => {
    const allowNested = Boolean(props.allowNestedDisclaimers);
    if (props.hideItemBasedDisclaimers) {
        return <InternalMediaElement {...props} />;
    }
    return (
        <GeneralDisclaimerProvider
            allowNested={allowNested}
            displayTypes={['T4_ITEM_BASED']}
        >
            <InternalMediaElement {...props} />
        </GeneralDisclaimerProvider>
    );
};

export const MediaElement = MapTo<MediaElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/mediaElement',
    MediaElementWithDisclaimerProvider
);

function createMediaComponent(options: {
    mediaType?: string;
    portrait: boolean;
    mediaDisclaimerProps: Readonly<{
        disclaimers?: Readonly<{
            staticReferences?: JSX.Element[];
            interactiveReferences?: JSX.Element;
            color?: 'dark' | 'light';
            itemBasedDisclaimers?: RegisteredDisclaimer[];
        }>;
    }>;
    useParentAspectRatio?: boolean;
    containerProps: {matchParent?: boolean};
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    actsAsTeaser?: boolean;
    countdownState?: CountdownState;
    onAssetLoad: (() => void) | undefined;
    storeId: string | undefined;
    interactive: boolean | undefined;
}) {
    const {
        mediaType,
        mediaDisclaimerProps,
        portrait,
        containerProps,
        countdownState,
        actsAsTeaser,
        onAssetLoad,
        useParentAspectRatio,
        responsiveMediaConfig,
        storeId,
        interactive
    } = options;
    let mediaComp: JSX.Element | undefined;
    switch (mediaType) {
        case 'ANIMATION':
            mediaComp = (
                <AnimationElement
                    {...mediaDisclaimerProps}
                    path={mediaPath('animation', portrait)}
                    matchParent={useParentAspectRatio}
                    containerProps={containerProps}
                    responsiveMediaConfig={responsiveMediaConfig}
                    actsAsTeaser={actsAsTeaser}
                    countdownState={countdownState}
                    onCoverImageLoad={onAssetLoad}
                />
            );
            break;
        case 'IMAGE':
            mediaComp = (
                <ImageElement
                    {...mediaDisclaimerProps}
                    path={mediaPath('image', portrait)}
                    useParentAspectRatio={useParentAspectRatio}
                    containerProps={containerProps}
                    responsiveMediaConfig={responsiveMediaConfig}
                    onLoad={onAssetLoad}
                />
            );
            break;
        case 'VIDEO':
            mediaComp = (
                <VideoElement
                    {...mediaDisclaimerProps}
                    path={mediaPath('video', portrait)}
                    matchParent={useParentAspectRatio}
                    containerProps={containerProps}
                    responsiveMediaConfig={responsiveMediaConfig}
                    countdownState={countdownState}
                    onCoverImageLoad={onAssetLoad}
                />
            );
            break;
        case 'DEGREE_180':
            mediaComp = (
                <Degree180Element
                    {...mediaDisclaimerProps}
                    storeId={storeId}
                    path={mediaPath('degree180', portrait)}
                    matchParent={useParentAspectRatio}
                    containerProps={containerProps}
                    responsiveMediaConfig={responsiveMediaConfig}
                    interactive={interactive}
                />
            );
            break;
        case 'SCROLLYTELLING':
            mediaComp = (
                <ScrollytellingElement
                    {...mediaDisclaimerProps}
                    path={mediaPath('scrollytelling', portrait)}
                    matchParent={useParentAspectRatio}
                />
            );
    }
    return mediaComp;
}

function mediaPath(path: string, portait: boolean) {
    return portait ? 'portrait_' + path : path;
}
