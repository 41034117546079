import * as React from 'react';

export interface LegalEntityContextData {
    legalEntity?: string;
}

export const LegalEntityContext = React.createContext<LegalEntityContextData>(
    {}
);

export interface LegalEntityProviderProps {
    legalEntity?: string;
}

export class LegalEntityProvider extends React.PureComponent<
    LegalEntityProviderProps
> {
    public constructor(props: LegalEntityProviderProps) {
        super(props);
    }

    public render(): JSX.Element {
        return (
            <LegalEntityContext.Provider value={this.props}>
                {this.props.children}
            </LegalEntityContext.Provider>
        );
    }
}
