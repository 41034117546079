import * as React from 'react';

export function useIntersectionTrigger(
    ref: React.RefObject<HTMLDivElement>,
    onTrigger: (entries: IntersectionObserverEntry[]) => void,
    predicate: (entry: IntersectionObserverEntry) => boolean,
    timeoutMs: number,
    threshold: IntersectionObserverInit['threshold'],
    ready: boolean
): void {
    const clearTimeoutRef = React.useRef<null | (() => void)>(null);
    const lastPredicateResult = React.useRef(false);

    const handleIntersectionChange = React.useCallback(
        (entries: IntersectionObserverEntry[]): void => {
            const passedLastTime = lastPredicateResult.current;
            const passes = entries.length > 0 ? predicate(entries[0]) : false;

            if (passes && !passedLastTime) {
                const timer = window.setTimeout(() => {
                    onTrigger(entries);
                }, timeoutMs);

                clearTimeoutRef.current = () => window.clearTimeout(timer);
            } else if (!passes && clearTimeoutRef.current) {
                clearTimeoutRef.current();
                clearTimeoutRef.current = null;
            }

            lastPredicateResult.current = passes;
        },
        [predicate, onTrigger, timeoutMs]
    );

    React.useEffect(() => {
        if (!ready) {
            return;
        }
        const intersectionObserver = new IntersectionObserver(
            handleIntersectionChange,
            {
                threshold
            }
        );

        if (ref.current) {
            intersectionObserver.observe(ref.current);
        }

        return function cleanup(): void {
            intersectionObserver.disconnect();
        };
    }, [handleIntersectionChange, threshold, ref, ready]);
}
