import {
    SharedSingleConfigurationFlowNavigator,
    singleConfigurationFlowNavigatorDefinition
} from '@volkswagen-onehub/single-configuration-flow-navigator';

import {ServiceRegistration} from './ServiceRegistration';

export const SINGLE_CONFIGURATION_FLOW_NAVIGATOR =
    'SingleConfigurationFlowNavigator';

export function createSingleFlowNavigator(): ServiceRegistration<
    SharedSingleConfigurationFlowNavigator
> {
    const definition = singleConfigurationFlowNavigatorDefinition;

    return {
        definition,
        service: {
            typeName: SINGLE_CONFIGURATION_FLOW_NAVIGATOR,
            version: '^1.2.0'
        }
    };
}
