import * as React from 'react';

import {
    Breakpoints,
    BreakpointWrapper,
    CTA,
    Layout,
    styled,
    ThemeProvider
} from '@volkswagen-onehub/components-core';

import {EnterFullscreen as EnterFullscreenIcon} from '@volkswagen-onehub/icons-core';
import {
    getElectricTransitionAdvanced,
    getEndDirection,
    getStartDirection
} from '../../d6/components/helpers';
import {
    browserBarHeightValue,
    browserWindowHeight
} from '../../d6/components/helpers/useBrowserWindowHeight';
import {noop} from '../../utils/noop';
import {SectionDisclaimerContainer} from '../disclaimers/SectionDisclaimerContainer';
import {WrappingOverlaySectionDisclaimers} from '../disclaimers/OverlayDisclaimerContainers';
import {WormyAnimation} from '../../d6/components/wormy-animation';

export interface HeightContainerProps {
    readonly height?: string;
}

interface StyledFullscreenWrapperProps {
    readonly isVisible?: boolean;
}

function getDefaultHeight(height?: string | null): string {
    return height || browserWindowHeight;
}

function getMobileHeight(height?: string | null): string {
    return height || `calc(80vh - ${browserBarHeightValue})`;
}

const StyledStageContainer = styled.div<HeightContainerProps>`
    position: relative;
    min-height: ${props => getDefaultHeight(props.height)};
    @media (max-width: ${Breakpoints.b960}px) {
        min-height: ${props => getMobileHeight(props.height)};
    }
    width: 100vw;
`;
StyledStageContainer.displayName = 'StyledStageContainer';

const StyledFullscreenWrapper = styled.div<StyledFullscreenWrapperProps>`
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: ${props => (props.isVisible ? '1' : '0')};
    transition: ${getElectricTransitionAdvanced({name: 'opacity'})};
`;
StyledFullscreenWrapper.displayName = 'StyledFullscreenWrapper';

const StyledMediaWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;
StyledMediaWrapper.displayName = 'StyledMediaWrapper';

const StyledContentInner = styled.div<HeightContainerProps>`
    height: ${props => getDefaultHeight(props.height)};
    @media (max-width: ${Breakpoints.b960}px) {
        height: ${props => getMobileHeight(props.height)};
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;
StyledContentInner.displayName = 'StyledContentInner';

const StyledCopyContentDesktopWrapper = styled.div`
    padding-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid002};
    padding-inline-start: ${props => props.theme.size.grid002};
    padding-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid010};
    padding-inline-end: ${props => props.theme.size.grid010};

    & h1 {
        pointer-events: all;
        display: inline-block;
    }

    @media (min-width: ${Breakpoints.b1600}px) {
        padding-${props => getEndDirection(props.theme.direction)}: ${props =>
    props.theme.size.grid012};
        padding-inline-end: ${props => props.theme.size.grid012};
    }
`;
StyledCopyContentDesktopWrapper.displayName = 'StyledCopyContentDesktopWrapper';

const StyledCopyContentMobileWrapper = styled.div`
    padding: 0 ${props => props.theme.size.grid002};
`;
StyledCopyContentMobileWrapper.displayName = 'StyledCopyContentMobileWrapper';

const StyledBellowStageContentMobileWrapper = styled.div`
    padding: ${props => props.theme.size.dynamic0200} 0
        ${props => props.theme.size.dynamic0130};
`;
StyledBellowStageContentMobileWrapper.displayName =
    'StyledBellowStageContentMobileWrapper';

const VerticalLineSpacer = styled.div`
    height: ${props => props.theme.size.static500};
    max-height: ${props => props.theme.size.dynamic0350};
    margin: 0 auto;

    @media (min-width: ${Breakpoints.b560}px) {
        height: 12%;
    }
`;

const StyledAnimationWrapper = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 0;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-bottom: ${props => props.theme.size.static400};
    }
`;
StyledAnimationWrapper.displayName = 'StyledAnimationWrapper';

const FullscreenIconContainer = styled.div`
    position: absolute;
    right: 0;
    padding-right: ${props => props.theme.size.grid001};
    padding-bottom: ${props => props.theme.size.grid001};
    pointer-events: all;

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }

    color: ${props => props.theme.colors.button.secondary.default};
    fill: ${props => props.theme.colors.button.secondary.default};
`;

export interface ProductExperienceStageProps {
    readonly media: React.ReactNode;
    readonly heading: React.ReactNode;
    readonly height?: string;
    readonly overlayHidden?: boolean;
    onOpenFullscreen?(): void;
}

// todo: could be moved to ../../d6
export const ProductExperienceStage: React.FunctionComponent<ProductExperienceStageProps> = props => {
    const {
        media,
        heading,
        height,
        overlayHidden = false,
        onOpenFullscreen = noop
    } = props;

    return (
        <WrappingOverlaySectionDisclaimers
            displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
            color="light"
            minBreakpoint={Breakpoints.b960}
        >
            <ThemeProvider theme="inverted">
                <StyledStageContainer height={height}>
                    <StyledMediaWrapper>{media}</StyledMediaWrapper>
                    <StyledFullscreenWrapper isVisible={!overlayHidden}>
                        <Layout>
                            <StyledContentInner height={height}>
                                <BreakpointWrapper min={Breakpoints.b960}>
                                    <StyledCopyContentDesktopWrapper>
                                        {heading}
                                    </StyledCopyContentDesktopWrapper>
                                </BreakpointWrapper>
                                <VerticalLineSpacer />
                                <StyledAnimationWrapper>
                                    <WormyAnimation />
                                </StyledAnimationWrapper>
                                <FullscreenIconContainer>
                                    <CTA
                                        tag="a"
                                        emphasis="none"
                                        onClick={onOpenFullscreen}
                                    >
                                        <EnterFullscreenIcon ariaHidden />
                                    </CTA>
                                </FullscreenIconContainer>
                            </StyledContentInner>
                        </Layout>
                    </StyledFullscreenWrapper>
                </StyledStageContainer>
            </ThemeProvider>
            <BreakpointWrapper max={Breakpoints.b960}>
                <StyledBellowStageContentMobileWrapper>
                    <StyledCopyContentMobileWrapper>
                        {heading}
                    </StyledCopyContentMobileWrapper>
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    />
                </StyledBellowStageContentMobileWrapper>
            </BreakpointWrapper>
        </WrappingOverlaySectionDisclaimers>
    );
};
