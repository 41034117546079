import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {keyframes} from 'styled-components';

import {ANIMATION_FUNCTION} from '../helpers/css';

export const ScrollTriggerAnimationHeight = '30px';
export const ScrollTriggerAnimationHeightDesktop = '50px';

const move = keyframes`
	0% { transform: translateY(-200%); }
	50% { transform: translateY(-100%); }
	100% { transform: translateY(100%); }
`;

const StyledLineContainer = styled.div`
    position: relative;
    height: ${ScrollTriggerAnimationHeight};
    width: 2px;
    margin-bottom: ${props => props.theme.size.static300};
    border-radius: 3px;
    overflow: hidden;

    @media (min-width: ${Breakpoints.b560}px) {
        height: ${ScrollTriggerAnimationHeightDesktop};
    }
`;

StyledLineContainer.displayName = 'StyledLineContainer';
const StyledLine = styled.div`
    height: 100%;
    width: 100%;
    background: ${props => props.theme.colors.content.primary};
    border-radius: 3px;
    animation: ${move} 3s ${ANIMATION_FUNCTION} 0s infinite;
`;

StyledLine.displayName = 'StyledLine';

export const WormyAnimation: React.FunctionComponent = () => (
    <StyledLineContainer>
        <StyledLine />
    </StyledLineContainer>
);
WormyAnimation.displayName = 'WormyAnimation';
