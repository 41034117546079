import React from 'react';
import {onScrollEndedListener} from '../../../utils/browser/onScrollEndedListener';

const EXPAND_DELAY = 700;

export const useExpandOnScroll = (
    setForceExpand: (expand: boolean) => void,
    setDisplaySubActions?: (expand: boolean) => void
) => {
    // scroll handling
    React.useEffect(() => {
        const onScrollEnded = () => {
            setForceExpand(true);
        };

        const onScroll = () => {
            setForceExpand(false);
            if (setDisplaySubActions) setDisplaySubActions(false);
        };

        let scrollListener: () => void;
        setForceExpand(true);
        scrollListener = onScrollEndedListener({
            onScrollEndedCallback: onScrollEnded,
            onScrollCallback: onScroll,
            delay: EXPAND_DELAY,
            options: true
        });

        return () => {
            if (scrollListener) {
                scrollListener();
            }
        };
    }, [setForceExpand, setDisplaySubActions]);
};
