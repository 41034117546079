import {AbTestModel} from '../../../generated/core';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {isInBrowser} from '../../utils/browser/isInBrowser';
import {GLOBAL_NAMESPACE, getGlobal} from '../../utils/getGlobal';
import {random} from '../../utils/random';
import {Logger} from '../logger/Logger';

type AbTestVariant = {
    [Property in keyof AbTestModel]?: boolean;
};

const SESSION_STORAGE_KEY = GLOBAL_NAMESPACE + '.abtests';
const SESSION_STORAGE_KEY_MODEL = GLOBAL_NAMESPACE + '.abtestmodel';
@singleton('AbTestService')
export class AbTestService {
    @inject()
    private abTestModel!: AbTestModel;

    @inject()
    private logger!: Logger;

    private mock: boolean = false;

    getAbTestVariant(name: keyof AbTestModel) {
        return this.abTestVariant ? this.abTestVariant[name] : false;
    }

    private abTestVariant?: AbTestVariant;
    get abTestFeatures() {
        if (this.abTestVariant) {
            return Object.entries(this.abTestVariant).reduce(
                (acc, [key, value]) => {
                    const nameSpacedKey = 'cms-' + key;
                    acc[nameSpacedKey] = value;

                    return acc;
                },
                {} as {[key: string]: boolean}
            );
        }
        return {};
    }

    @postConstruct()
    public init() {
        // if the ab mock is turned on we will not delete the ab test variant if its percentage is 0%.
        // This is important for the cypresss test to change the percentage via session storage.
        this.mock = isInBrowser() && getGlobal().config.getBoolean('abmock');
        if (isInBrowser() && !this.abTestVariant) {
            this.selectVariants();
        }
    }
    selectVariants() {
        let persistedAbTestVariant: Partial<AbTestVariant> = this.loadVariants();
        let persistedAbTestModel: Partial<AbTestModel> = this.loadModel();

        const tests = Object.keys(this.abTestModel) as (keyof AbTestModel)[];

        this.abTestVariant = tests.reduce((variant, test) => {
            const percentage = this.abTestModel[test] || 0;
            if (test in variant && typeof variant[test] == 'boolean') {
                // remove variants with 0 percentage because they were turned off
                if (!this.mock && percentage === 0) {
                    delete variant[test];
                }
                // check if probability has not changed
                if (this.abTestModel[test] === persistedAbTestModel[test]) {
                    return variant;
                }
            }
            if (percentage === 0) {
                // ignore variants that have 0 percentage. The test is considered to be not started yet.
                return variant;
            }
            const variantB = random() * 100 < percentage;
            variant[test] = variantB;
            return variant;
        }, persistedAbTestVariant as AbTestVariant);

        this.storeVariants();
    }

    private storeVariants() {
        window.sessionStorage.setItem(
            SESSION_STORAGE_KEY,
            JSON.stringify(this.abTestVariant, undefined, 2)
        );
        window.sessionStorage.setItem(
            SESSION_STORAGE_KEY_MODEL,
            JSON.stringify(this.abTestModel, undefined, 2)
        );
    }

    private loadVariants() {
        let persistedAbTestVariant: Partial<AbTestVariant> = {};
        const data = window.sessionStorage.getItem(SESSION_STORAGE_KEY);
        if (data) {
            try {
                persistedAbTestVariant = JSON.parse(data);
            } catch (e) {
                this.logger.abtest.warn(
                    'cannot parse persisted ab test variants',
                    e
                );
            }
        }

        return persistedAbTestVariant;
    }
    private loadModel() {
        let persistedAbTestModel: Partial<AbTestModel> = {};
        const data = window.sessionStorage.getItem(SESSION_STORAGE_KEY_MODEL);
        if (data) {
            try {
                persistedAbTestModel = JSON.parse(data);
            } catch (e) {
                this.logger.abtest.warn(
                    'cannot parse persisted ab test model',
                    e
                );
            }
        }
        return persistedAbTestModel;
    }
}
