import * as React from 'react';
import ReactDOM from 'react-dom';
import {isInUserAgent} from './isInUserAgent';

function defaultDragHandler(e: Event): void {
    e.preventDefault();
}

export function useFirefoxScrollHack(
    ref: React.RefObject<HTMLAnchorElement>,
    dragHandler?: () => void
): void {
    // Prevents firefox dragging instead of scrolling
    const handler = dragHandler ? dragHandler : defaultDragHandler;
    React.useEffect(() => {
        const refElem = ref.current ? ReactDOM.findDOMNode(ref.current) : 0;

        if (isInUserAgent('firefox')) {
            refElem && refElem.addEventListener('dragstart', handler);
        }

        return function cleanup(): void {
            refElem && refElem.removeEventListener('dragstart', handler);
        };
    }, [ref, handler]);
}
