import {ContentBlock} from '.';
import {ScrollytellingSectionModel} from '../../../../../generated/core';
import {useMediaQueries} from '../../../../context/mediaQueries/mediaQueryContext';
import {isInBrowser} from '../../../../utils/browser/isInBrowser';
import {ScrollCalculations} from './ScrollLogic';

const THROTTLE_DURATION = 16;

export const save = (value: number, fallback: number) => {
    if (value === Infinity || value === -Infinity || isNaN(value)) {
        return fallback;
    }
    return value;
};

export const isFirefoxOnMac = () => {
    if (!isInBrowser() || !window.hasOwnProperty('UAParser')) {
        return false;
    }
    //@ts-expect-error UAParser is not added as a type
    const {os, browser} = window.UAParser();

    return os.name === 'Mac OS' && browser.name === 'Firefox';
};

export const throttle = (
    animationThrottled: React.MutableRefObject<boolean>,
    callback: VoidFunction
) => {
    if (animationThrottled.current) {
        return;
    }
    animationThrottled.current = true;

    setTimeout(() => {
        callback();
        animationThrottled.current = false;
    }, THROTTLE_DURATION);
};

export const getIndexFromNumberRange = (
    scrollAmount: number,
    computedContent: ContentBlock[]
) => {
    const shouldRenderFromTheStart =
        scrollAmount < 0 && computedContent[0]?.start === 0;
    const shouldRenderTillTheEnd =
        scrollAmount > 1 &&
        computedContent[computedContent.length - 1]?.end === 1;

    if (shouldRenderFromTheStart) {
        return 0;
    }

    if (shouldRenderTillTheEnd) {
        return computedContent.length - 1;
    }

    return computedContent.findIndex(
        entry => entry.start <= scrollAmount && entry.end >= scrollAmount
    );
};

export const getScrollPosition = (
    contentEnd: number,
    calculations: ScrollCalculations
) => contentEnd * calculations.maximumScrollHeight + calculations.offsetTop;

export const millisecondsToHHMMSS = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
};

export const useScrollSpeed = (props: ScrollytellingSectionModel) => {
    const {duration} = props;
    const {isMobile} = useMediaQueries();

    const SCROLL_FACTOR = 0.3;
    const TABLET_DESKTOP_SCROLL_FACTOR = 0.4;

    const scrollFactor = isMobile
        ? SCROLL_FACTOR
        : TABLET_DESKTOP_SCROLL_FACTOR;

    return duration * scrollFactor;
};
