import * as React from 'react';
import styled from 'styled-components';

import {XfTextOnlyTeaserModel} from '../../../../generated/core';

import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {useRouterService} from '../../../context';
import {TextOnlyTeaserItem} from '../../../d6/components/text-only-teaser-item';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';

import {C} from '../../../registries/compatibilty';
import {isXfPreview} from '../../../utils/xfUtils';
import {RichtextFullElement} from '../../editorial/elements/RichtextElement';
import {LinkListItem} from '../../editorial/items/LinkListItem';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const StyledAuthorWrapper = styled.div<{isExperienceFragment: boolean}>`
    max-width: ${props => (props.isExperienceFragment ? '50vw' : 'unset')};
    height: 100%;
`;

export type TopLineTextPosition = 'left' | 'right';
interface XfTextOnlyTeaserProps extends XfTextOnlyTeaserModel {
    topLineTextPosition: TopLineTextPosition;
    hasSiblings: boolean;
}

const InternalXfTextOnlyTeaser: React.FunctionComponent<XfTextOnlyTeaserProps> = props => {
    const routerService = useRouterService();
    const xfPreview = isXfPreview(routerService.pagePath);
    const {topLineText, topLineTextPosition, hasSiblings} = props;

    const headline = <HeadingElement path="headline" style="H3" order="H3" />;
    const copy = <RichtextFullElement path="copy" hideItemDisclaimers />;
    const linkList = <LinkListItem path="links" />;

    const teaser = (
        <GeneralDisclaimerProvider displayTypes={['T4_ITEM_BASED']}>
            <TextOnlyTeaserItem
                headline={headline}
                disclaimers={
                    <BellowItemDisclaimerContainer
                        displayTypes={['T4_ITEM_BASED']}
                    />
                }
                copy={copy}
                hasSiblings={hasSiblings}
                linkList={linkList}
                topLine={topLineText}
                topLineTextPosition={topLineTextPosition}
            />
        </GeneralDisclaimerProvider>
    );

    const authorView = (
        <AuthoringWrapper title="Text Only Teaser XF">
            <StyledAuthorWrapper isExperienceFragment={xfPreview}>
                {teaser}
            </StyledAuthorWrapper>
        </AuthoringWrapper>
    );

    const publishView = teaser;

    return C.isInEditor() ? authorView : publishView;
};

interface XfTextOnlyTeaserExtraProps {
    path: string;
    key: string;
    personalizable: boolean;
    topLineTextPosition: TopLineTextPosition;
    hasSiblings: boolean;
}

export const XfTextOnlyTeaser = MapTo<XfTextOnlyTeaserExtraProps>(
    'vwa-ngw18/components/structure/xfTextOnlyTeaser',
    InternalXfTextOnlyTeaser
);
