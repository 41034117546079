import {Model} from '@adobe/cq-react-editable-components';
import {PageRootModel} from '../../../generated/core';

export const ContentStoreId = 'ContentStore';

export interface ContentStore {
    /**
     * get the content of the page at the given resource path
     * @param {string} pagePath
     * @return {ComponentData | undefined}
     */
    getPage(pagePath: string): Model | undefined;

    getStageType(): string | undefined;

    getCurrentPageRootModel(): PageRootModel | undefined;

    getCurrentPageRootModelAsPromise(): Promise<PageRootModel>;
}

export const ROOT_PROP = 'root';
export const ROOT_RESOURCE_TYPE = 'vwa-ngw18/components/structure/pageroot';

export const getCurrentPageRootModel = (
    contentStore: ContentStore,
    path: string
) => {
    const pageModel = contentStore.getPage(path);
    if (
        pageModel &&
        pageModel.cqItems &&
        pageModel.cqItems[ROOT_PROP] &&
        ROOT_RESOURCE_TYPE === pageModel.cqItems[ROOT_PROP].cqType
    ) {
        return pageModel.cqItems[ROOT_PROP] as PageRootModel;
    }

    return undefined;
};
