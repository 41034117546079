import * as React from 'react';

import {
    Breakpoints,
    styled,
    TokenTextColor
} from '@volkswagen-onehub/components-core';

import {getEndDirection, getStartDirection} from '../helpers';

const ICON_HEIGHT = '0.25em';
const ICON_WIDTH = '0.4em';

interface StyledBreadcrumbsWrapperProps {
    margin?: boolean;
}

const StyledBreadcrumbsWrapper = styled.ol<StyledBreadcrumbsWrapperProps>`
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 ${props => (props.margin ? props.theme.size.grid002 : '')};

    @media (min-width: ${Breakpoints.b1280}px) {
        max-width: 50vw;
    }
`;
StyledBreadcrumbsWrapper.displayName = 'StyledBreadcrumbsWrapper';

interface StyledBreadcrumbProps {
    delimiterColor?: TokenTextColor;
    nonInteractive?: boolean;
}

const StyledBreadcrumb = styled.li<StyledBreadcrumbProps>`
	display: flex;
	align-items: baseline;
	margin-${props => getEndDirection(props.theme.direction)}:
	${props => props.theme.size.static150};

&:not(:first-child):before {
		content: '';
		margin-${props => getEndDirection(props.theme.direction)}:
		${props => props.theme.size.static150};

		/* CSS triangle */
		width: 0;
		height: 0;
		border-${props => getStartDirection(props.theme.direction)}:
		${ICON_WIDTH} solid ${props =>
    props.delimiterColor
        ? props.theme.colors.content.secondary
        : props.theme.colors.button.primary.default};

		border-top: ${ICON_HEIGHT} solid transparent;
		border-bottom: ${ICON_HEIGHT} solid transparent;
	}
`;
StyledBreadcrumb.displayName = 'StyledBreadcrumb';

export interface OnehubBreadcrumbsProps
    extends StyledBreadcrumbsWrapperProps,
        StyledBreadcrumbProps {
    children: JSX.Element[];
}

export const Breadcrumbs: React.FunctionComponent<OnehubBreadcrumbsProps> = (
    props: OnehubBreadcrumbsProps
) => {
    const {children, delimiterColor, margin = true, nonInteractive} = props;

    const breadcrumbs = children.map((breadcrumb, index, {length}) => {
        const isLast = index === length - 1;

        return (
            <StyledBreadcrumb
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
                key={`breadcrumb_${index}`}
                delimiterColor={delimiterColor}
                aria-current={isLast ? 'page' : undefined}
                nonInteractive={nonInteractive}
            >
                {breadcrumb}
            </StyledBreadcrumb>
        );
    });

    return (
        <StyledBreadcrumbsWrapper
            itemScope
            itemType="http://schema.org/BreadcrumbList"
            margin={margin}
        >
            {breadcrumbs}
        </StyledBreadcrumbsWrapper>
    );
};

export * from './item-inner';
