export const JCR_CONTENT = '/jcr:content';

interface Path {
    page: string;
    path?: string;
}

export function splitPath(absolutePath: string): Path {
    const paths = absolutePath.split(JCR_CONTENT);
    if (paths.length > 1 && paths[1].length > 0) {
        return {
            page: paths[0],
            path: paths[1]
        };
    }

    return {
        page: paths[0]
    };
}
