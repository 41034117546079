import {ServiceRegistration} from './ServiceRegistration';
import {
    SharedParameterManager,
    defineParameterManager
} from '@volkswagen-onehub/parameter-manager';

export function createParameterManager(
    whitelistParams: string[]
): ServiceRegistration<SharedParameterManager> {
    const definition = defineParameterManager(whitelistParams);

    return {
        definition,
        service: {
            typeName: 'ParameterManager',
            version: '^1.0.0'
        }
    };
}
