import * as React from 'react';

import {
    CTA,
    CTAProps,
    ExternalLinkIndicator
} from '@volkswagen-onehub/components-core';
import {
    Download,
    Mail,
    Phone,
    Fax,
    OpenInLayer,
    Info
} from '@volkswagen-onehub/icons-core';

import {useGlobalConfig, useRouterService} from '../../context';
import {BaseCmsLinkProps, useCmsLink} from './CmsLink';
import styled from 'styled-components';

type StyledLinkIconProps = Readonly<{
    isLeft?: boolean; // Icon is on the left (start) side of the link
}>;

const StyledLinkIconWrapper = styled.span<StyledLinkIconProps>`
    margin-inline-${props => (props.isLeft ? 'end' : 'start')}: ${props =>
    props.theme.size.static100};
`;

export type CmsTextLinkProps = BaseCmsLinkProps & Omit<CTAProps, 'tag'>;

export function CmsTextLink(
    props: React.PropsWithChildren<CmsTextLinkProps>
): JSX.Element {
    const routerService = useRouterService();
    const ref = React.useRef(null);
    const {linkLabels} = useGlobalConfig();
    const [onCmsLinkClick, href] = useCmsLink(ref, props);
    const {
        children,
        emphasis,
        isDownloadLink,
        isLayerLink,
        showExternalIcon,
        showLayerIcon
    } = props;

    const linkPrefix = href.split(':')[0];
    const isMailLink = linkPrefix === 'mailto';
    const isActionLink = linkPrefix === 'action';
    const isPhoneLink = linkPrefix === 'tel';
    const isFaxLink = linkPrefix === 'fax';
    const isLiflLink = href.includes('login-info-layer');

    const isExternalPath =
        href &&
        !routerService.isAppLink(href) &&
        !isLayerLink &&
        !isDownloadLink &&
        !isMailLink &&
        !isActionLink &&
        !isPhoneLink &&
        !isFaxLink &&
        !isLiflLink;

    return (
        <CTA
            tag="a"
            emphasis={emphasis || 'none'}
            {...props}
            href={href}
            ref={ref}
            onClick={
                props.disabled
                    ? e => {
                          e.preventDefault();
                      }
                    : onCmsLinkClick
            }
        >
            {isDownloadLink && (
                <StyledLinkIconWrapper isLeft>
                    <Download variant="small" title={linkLabels.downloadLink} />
                </StyledLinkIconWrapper>
            )}
            {children}
            {isExternalPath && showExternalIcon && (
                <StyledLinkIconWrapper>
                    <ExternalLinkIndicator title={linkLabels.externalLink} />
                </StyledLinkIconWrapper>
            )}
            {isMailLink && (
                <StyledLinkIconWrapper>
                    <Mail variant="small" title={linkLabels.mailLink} />
                </StyledLinkIconWrapper>
            )}
            {isPhoneLink && (
                <StyledLinkIconWrapper>
                    <Phone variant="small" title={linkLabels.phoneLink} />
                </StyledLinkIconWrapper>
            )}
            {isFaxLink && (
                <StyledLinkIconWrapper>
                    <Fax variant="small" title={linkLabels.faxLink} />
                </StyledLinkIconWrapper>
            )}
            {showLayerIcon && (
                <StyledLinkIconWrapper>
                    <OpenInLayer variant="small" />
                </StyledLinkIconWrapper>
            )}
            {isLiflLink && (
                <StyledLinkIconWrapper>
                    <Info variant="small" />
                </StyledLinkIconWrapper>
            )}
        </CTA>
    );
}
