import * as React from 'react';
import {VideoElementModel} from '../../../../../generated/core';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {ResponsiveMediaRendererConf} from '../../../../components/ResponsiveMediaRenderer';
import {MediaDisclaimerHolder} from '../MediaElement';
import {videoElementPropsEqual} from './helpers';
import {VideoElementAuthor} from './VideoElementAuthor';
import {VideoElementPublish} from './VideoElementPublish';
import {SchemaVideoMicrodata} from './SchemaVideoMicrodata';
import {CountdownState} from '../../../../context/tracking/TrackingService';

export type VideoElementExtraProps = MediaDisclaimerHolder &
    Readonly<{
        onCoverImageLoad?: () => void;
        matchParent?: boolean;
        responsiveMediaConfig?: ResponsiveMediaRendererConf[];
        countdownState?: CountdownState;
    }>;

export type VideoElementProps = VideoElementModel & VideoElementExtraProps;

function InternalVideoElement(props: VideoElementProps): JSX.Element | null {
    if (C.isInEditor()) {
        return <VideoElementAuthor {...props} />;
    }

    if (!C.isInEditor() && !props.fileReference) {
        return null;
    }

    return (
        <>
            <SchemaVideoMicrodata {...props.schemaVideoModel} />
            <VideoElementPublish {...props} />
        </>
    );
}

const MemoizedVideoElement = React.memo(
    InternalVideoElement,
    videoElementPropsEqual
);

export const VideoElement = MapTo<VideoElementExtraProps>(
    'vwa-ngw18/components/editorial/elements/videoElement',
    MemoizedVideoElement
);
