import * as React from 'react';

export type TogglePointerEvents = (enabled: boolean) => void;

export function usePointerEventsHack(
    videoContainerRef: React.RefObject<HTMLDivElement>
): TogglePointerEvents {
    const togglePointerEvents = React.useCallback(
        (enabled: boolean) => {
            const playerContainer = videoContainerRef.current;
            if (playerContainer) {
                playerContainer.style.pointerEvents = enabled ? 'all' : 'none';
            }
        },
        [videoContainerRef]
    );

    return togglePointerEvents;
}
