import {Breakpoints, styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {
    MediaAspectRatio,
    Scene7VideoRendition,
    ScrollytellingElementModel
} from '../../../../../generated/core';
import {
    AuthoringMediaInfoBox,
    MediaInfo
} from '../../../../components/AuthoringMediaInfoBox';
import {AuthoringWrapper} from '../../../../components/AuthoringWrapper';
import {VideoPoster} from '../../../../components/videoPoster';
import {useResizeObserver} from '../../../../d6/hooks/useResizeObserver';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {isInBrowser} from '../../../../utils/browser/isInBrowser';
import {useIsClientOnlyRendering} from '../../../../utils/useIsClientOnlyRendering';
import {ScrollytellingContext} from '../../sections/scrollytelling/ScrollLogic';
import {isFirefoxOnMac, throttle} from '../../sections/scrollytelling/helper';
import {ImagePlaceHolderElement} from '../ImagePlaceHolderElement';
import {MediaDisclaimerHolder} from '../MediaElement';
import {VideoIcon} from '../media/icons';
import {getVideoRendition} from '../videoElement/helpers';
import {useVideoAspectRatio} from '../videoElement/hooks/useVideoAspectRatio';
import {DisclaimerBadges, StyledDisclaimerBadges} from './DisclaimerBadges';

export interface ScrollytellingElementExtraProps {
    readonly matchParent?: boolean;
}

export interface ScrollytellingElementProps
    extends ScrollytellingElementModel,
        MediaDisclaimerHolder,
        ScrollytellingElementExtraProps {}

const StyledVideo = styled.video`
    height: 100%;
    width: 100%;
    object-fit: cover;
    pointer-events: none;
`;

const StyledScrollableVideo = styled.div`
    height: 100%;
    width: 100%;
    position: relative;

    display: grid;
    grid-template-rows: 1fr max-content;

    & > * {
        grid-column: 1/2;
        grid-row: 1/3;
        height: 100%;
        width: 100%;
    }

    & ${StyledDisclaimerBadges} {
        grid-row: 2/3;
    }
`;

const VideoPosterElement = (props: ScrollytellingElementProps) => {
    const {coverImage, matchParent, aspectRatio} = props;
    const videoAspectRatio = useVideoAspectRatio(
        matchParent,
        aspectRatio
    ) as MediaAspectRatio;

    return (
        <VideoPoster image={{...coverImage, aspectRatio: videoAspectRatio}} />
    );
};

function AuthorView(props: ScrollytellingElementProps): JSX.Element {
    const {coverImage, fileReference, videoMediaInfo} = props;
    return (
        <AuthoringWrapper
            title="Scrollytelling Element"
            bgColor={AuthoringWrapper.BG_COLOR_ELEMENT}
        >
            <AuthoringMediaInfoBox>
                {coverImage.mediaInfo && (
                    <MediaInfo {...coverImage.mediaInfo} />
                )}
                {videoMediaInfo && <MediaInfo {...videoMediaInfo} />}
            </AuthoringMediaInfoBox>
            {!fileReference ? (
                <ImagePlaceHolderElement>
                    <VideoIcon />
                </ImagePlaceHolderElement>
            ) : (
                <VideoPosterElement {...props} matchParent={false} />
            )}
        </AuthoringWrapper>
    );
}

const getInitialVideoRendition = (
    videoRenditions: Scene7VideoRendition[],
    aspectRatio: MediaAspectRatio
) => {
    const width = isInBrowser() ? window.innerWidth : Breakpoints.b560;
    const height = isInBrowser() ? window.innerHeight : Breakpoints.b560;
    return getVideoRendition(videoRenditions, aspectRatio, {
        width: width,
        height: height
    })?.scene7Src;
};

const setupVideo = async (videoElement: HTMLVideoElement) => {
    await videoElement.play();
    videoElement.pause();
};

const setVideoDuration = (
    videoElement: HTMLVideoElement,
    scrollAmount: number
) => {
    const videoDuration = videoElement.duration ? videoElement.duration : 0;

    videoElement.currentTime = videoDuration * scrollAmount;
};

const PublishView = (props: ScrollytellingElementProps) => {
    const {aspectRatio, videoRenditions, disclaimers} = props;

    const canRender = useIsClientOnlyRendering();
    const [rendition, setRendition] = React.useState(
        getInitialVideoRendition(videoRenditions, aspectRatio)
    );
    const [isCoverShowing, hideCover] = React.useReducer(() => false, true);
    const {subscribe} = React.useContext(ScrollytellingContext);
    const animationThrottled = React.useRef<boolean>(false);

    const scrollableVideoRef = React.useRef<HTMLVideoElement | null>(null);
    useResizeObserver(scrollableVideoRef, (height, width) => {
        const currentRendition = getVideoRendition(
            videoRenditions,
            aspectRatio,
            {
                width,
                height
            }
        );
        if (currentRendition?.scene7Src) {
            setRendition(currentRendition.scene7Src);
        }
    });

    const throttledHandleScroll = React.useCallback(
        (videoElement: HTMLVideoElement, scrollAmount: number) =>
            isFirefoxOnMac()
                ? throttle(animationThrottled, () =>
                      setVideoDuration(videoElement, scrollAmount)
                  )
                : setVideoDuration(videoElement, scrollAmount),
        []
    );

    React.useEffect(() => {
        const videoElement = scrollableVideoRef.current;
        if (!videoElement) {
            return;
        }

        const unsubscribe = subscribe(async scrollAmount => {
            if (scrollAmount.video < 0) {
                return;
            }
            if (isCoverShowing) {
                hideCover();
            }
            throttledHandleScroll(videoElement, scrollAmount.video);
        });

        return () => {
            unsubscribe();
        };
    }, [subscribe, throttledHandleScroll, isCoverShowing]);

    const pauseVideo = (videoElement: HTMLVideoElement | null) => {
        if (!videoElement) {
            return;
        }
        scrollableVideoRef.current = videoElement;

        if (!canRender || !videoElement.paused) {
            return;
        }
        setupVideo(scrollableVideoRef.current);
    };

    if (videoRenditions.length === 0) {
        return null;
    }

    return (
        <StyledScrollableVideo>
            <StyledVideo
                ref={pauseVideo}
                src={rendition}
                muted
                playsInline
                preload="none"
            />
            {isCoverShowing ? <VideoPosterElement {...props} /> : null}
            <DisclaimerBadges disclaimers={disclaimers} />
        </StyledScrollableVideo>
    );
};

function InternalScrollytellingElement(
    props: ScrollytellingElementProps
): JSX.Element {
    return C.isInEditor() ? (
        <AuthorView {...props} />
    ) : (
        <PublishView {...props} />
    );
}

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/scrollytellingElement';

export const ScrollytellingElement = MapTo<ScrollytellingElementExtraProps>(
    RESOURCE_TYPE,
    InternalScrollytellingElement
);
