import {
    breadcrumbServiceDefinition,
    SharedBreadcrumbService
} from '@volkswagen-onehub/breadcrumb-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createBreadcrumbService(): ServiceRegistration<
    SharedBreadcrumbService
> {
    return {
        definition: breadcrumbServiceDefinition,
        service: {
            typeName: 'BreadcrumbService',
            version: '^1.0.0'
        }
    };
}
