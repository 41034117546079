import React from 'react';
import {PowerTeaserSectionModel} from '../../../../generated/core';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {SectionWrapper} from './SectionWrapper';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BelowSectionDisclaimerContainer} from '../../../components/disclaimers/BelowSectionDisclaimerContainer';
import {PowerTeaserPromoElement} from '../elements/PowerTeaserPromoElement';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

type Props = PowerTeaserSectionModel;

const InternalPowerTeaserSection: React.FunctionComponent<Props> = ({
    anchorId,
    contentId: sectionId,
    contentLabels,
    tagTypeBroken,
    lightMode,
    mboxId
}: Props) => {
    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S129 Power Teaser Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <PowerTeaserPromoElement
                        lightMode={lightMode}
                        path="powerTeaserPromoElement"
                    />

                    <BelowSectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
};

export const PowerTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/powerTeaserSection',
    InternalPowerTeaserSection
);
