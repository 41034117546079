import {
    secondaryConsumerServiceDefinition,
    SharedSecondaryConsumerFeatureService
} from '@volkswagen-onehub/secondary-consumer-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createSecondaryConsumerService(): ServiceRegistration<
    SharedSecondaryConsumerFeatureService
> {
    return {
        definition: secondaryConsumerServiceDefinition,
        service: {
            typeName: 'SecondaryConsumerService',
            version: '^1.0.0'
        }
    };
}
