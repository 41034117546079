import {observer} from 'mobx-react-lite';
import * as React from 'react';
import {
    SecondLevelTeaserSectionModel,
    TeaserElementModel
} from '../../../../generated/core';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {TrackedSecondLevelTeaserElement} from '../elements/TrackedSecondLevelTeaserElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {TeaserSectionAuthoringView} from './teasers/common';
import {
    StyledSecondLevelTeaserIntro,
    StyledSecondLevelTeaserItems
} from '../../../d6/components/second-level-teaser';

const EMPTY_LABEL = 'S123 Second Level Teaser';

const getTeaserItems = (props: SecondLevelTeaserSectionModel) => {
    const {cqItemsOrder, cqItems, showCategory} = props;

    const teaserItemsWithHref = cqItemsOrder
        .filter(itemId => itemId !== 'heading')
        .map(itemId => ({itemId, teaser: cqItems[itemId]}))
        .filter(data => Boolean(data.teaser.teaserElementLinkHref));

    return teaserItemsWithHref.map((data, idx) => (
        <TrackedSecondLevelTeaserElement
            key={data.itemId}
            showCategory={showCategory}
            teaserTrackingName={data.teaser.headingPlainText}
            showOriginInfo={C.isInEditor()}
            teaserData={{
                itemCount: teaserItemsWithHref.length,
                itemPosition: idx + 1,
                itemId: data.itemId,
                teaser: data.teaser as TeaserElementModel
            }}
        />
    ));
};

const SecondLevelTeaserIntro = (props: SecondLevelTeaserSectionModel) => {
    const hasHeadline = C.isInEditor() || !props.cqItems.heading.empty;
    const hasCopy = C.isInEditor() || !props.cqItems.copy.empty;

    if (!hasHeadline && !hasCopy) {
        return null;
    }

    return (
        <StyledSecondLevelTeaserIntro>
            {hasHeadline ? (
                <HeadingElement path="heading" order="H2" style="H2" />
            ) : null}
            {hasCopy ? <CopyItem path="copy" useDropCap={false} /> : null}
        </StyledSecondLevelTeaserIntro>
    );
};

export const InternalSecondLevelTeaserSection = observer(
    function SecondLevelTeaser(
        props: SecondLevelTeaserSectionModel
    ): JSX.Element {
        const {
            anchorId,
            mboxId,
            contentLabels,
            contentId: sectionId,
            contentName,
            tagTypeBroken
        } = props;

        return (
            <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                <TeaserSectionAuthoringView
                    anchorId={anchorId}
                    emptyLabel={EMPTY_LABEL}
                    tagTypeBroken={tagTypeBroken}
                >
                    <SectionWrapper
                        mboxId={mboxId}
                        anchorId={anchorId}
                        sectionId={sectionId}
                        contentLabels={contentLabels}
                        contentName={contentName}
                    >
                        <SecondLevelTeaserIntro {...props} />
                        <StyledSecondLevelTeaserItems data-testid="secondLevelTeaserItems">
                            {getTeaserItems(props)}
                        </StyledSecondLevelTeaserItems>
                        <SectionDisclaimerContainer
                            displayTypes={['T3_SECTION_BASED']}
                        />
                    </SectionWrapper>
                </TeaserSectionAuthoringView>
            </GeneralDisclaimerProvider>
        );
    }
);

export const SecondLevelTeaserSection = MapTo(
    'vwa-ngw18/components/editorial/sections/secondLevelTeaserSection',
    InternalSecondLevelTeaserSection,
    {noElement: true}
);
