import {observer, useLocalStore} from 'mobx-react-lite';
import * as React from 'react';

import {ThemeProvider} from '@volkswagen-onehub/components-core';
import {ProductExperienceStageSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {FullscreenLayerWrapper} from '../../../components/product-experience-stage/FullscreenLayerWrapper';
import {ProductExperienceStage} from '../../../components/product-experience-stage/ProductExperienceStage';
import {
    Degree180Context,
    Degree180Store
} from '../../../context/media/Degree180Store';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {SectionWrapper} from './SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

const LABEL = 'S114 Product Experience Stage';

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H0" order="H1" />
);
/**
 * S114 Product Experience Section
 */

const mediaContextProviderValue = {
    sizes: '100vw',
    reduceBrightness: true
    // TODO store
};

function createMediaElement(store: Degree180Store): JSX.Element {
    return (
        <MediaContext.Provider value={mediaContextProviderValue}>
            <Degree180Context.Provider value={store}>
                <MediaElement
                    path="media"
                    useParentAspectRatio={true}
                    containerProps={{matchParent: true}}
                    enableItemInteractiveDisclaimer
                    hideItemBasedDisclaimers
                />
            </Degree180Context.Provider>
        </MediaContext.Provider>
    );
}

function EditMode(props: ProductExperienceStageSectionModel): JSX.Element {
    const store = useLocalStore(() => new Degree180Store());

    return (
        <AuthoringWrapper
            anchorId={props.anchorId}
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            title={LABEL}
            tagTypeBroken={props.tagTypeBroken}
        >
            <GeneralDisclaimerProvider
                displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
            >
                <ProductExperienceStage
                    media={createMediaElement(store)}
                    heading={headingElement}
                    height="800px"
                />
                <SectionDisclaimerContainer
                    displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                    color="light"
                />
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );
}

const PublishMode: React.FunctionComponent<ProductExperienceStageSectionModel> = observer(
    function InternalPublishMode(
        props: ProductExperienceStageSectionModel
    ): JSX.Element {
        const {
            contentLabels,
            contentId: sectionId,
            anchorId,
            contentName,
            mboxId
        } = props;
        const store = useLocalStore(() => new Degree180Store());

        const dragDirection = store.dragDirection;
        const isProgressBarFocused = store.isProgressBarFocused;
        const isFullscreen = store.isFullscreen;
        const isDragged = dragDirection !== 'none';

        return (
            <SectionWrapper
                mboxId={mboxId}
                sectionId={sectionId}
                anchorId={anchorId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider
                    displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
                >
                    {!isFullscreen ? (
                        <ProductExperienceStage
                            media={createMediaElement(store)}
                            heading={headingElement}
                            overlayHidden={isDragged || isProgressBarFocused}
                            onOpenFullscreen={() => store.openFullscreen()}
                        />
                    ) : (
                        <FullscreenLayerWrapper
                            onCloseFullscreen={() => store.closeFullscreen()}
                        >
                            <ThemeProvider theme="inverted">
                                {createMediaElement(store)}
                            </ThemeProvider>
                        </FullscreenLayerWrapper>
                    )}
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        );
    }
);

function InternalProductExperienceStageSection(
    props: ProductExperienceStageSectionModel
): JSX.Element {
    return C.isInEditor() ? (
        <EditMode {...props} />
    ) : (
        <PublishMode {...props} />
    );
}
export const ProductExperienceStageSection = MapTo(
    'vwa-ngw18/components/editorial/sections/productExperienceStageSection',
    InternalProductExperienceStageSection
);
