import {Logout as LogoutIcon} from '@volkswagen-onehub/icons-core';
import {ProfileCircle as ProfileIcon} from '@volkswagen-onehub/icons-core/dist/ProfileCircle';
import {Settings as SettingsIcon} from '@volkswagen-onehub/icons-core/dist/Settings';
import * as React from 'react';
import {FlyoutLoggedInModel} from '../../../../generated/core';
import {
    LoginFlyoutFooterLink,
    LoginFlyoutUserInfoText
} from '../../../components/LoginFlyout';
import {LoginFlyoutViewV2} from '../../../components/LoginFlyoutV2';
import {useLoginStore} from '../../../context';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../utils/elseUndefined';
import {getUserName} from './getUserName';

interface FlyoutLoggedInPublishProps extends FlyoutLoggedInModel {
    readonly heading: React.ReactNode;
    readonly copy: React.ReactNode;
    readonly button: React.ReactNode;
    readonly handleBtnLogoutClick: React.MouseEventHandler<HTMLButtonElement>;
    readonly handleBtnSettingsClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const FlyoutLoggedInPublish: React.FC<FlyoutLoggedInPublishProps> = props => {
    const {
        btnLogoutLabel,
        btnSettingsLabel,
        greeting,
        fallbackUserName,
        heading,
        copy,
        button,
        handleBtnLogoutClick,
        handleBtnSettingsClick
    } = props;

    const loginStore = useLoginStore();

    const {userData, organizationData, isLoggedIn} = loginStore;
    const hasCommercialProfileUrl = loginStore.hasCommercialProfileUrl();
    const addCommercialSettingsHandle = Boolean(
        organizationData && hasCommercialProfileUrl
    );
    const trackingContentId = userData ? 'IDK logout' : 'CIDK logout';

    const name: string = getUserName({
        greeting,
        fallbackUserName,
        userData,
        organizationData,
        isLoggedIn
    });
    const email =
        elseUndefined(userData, userData?.email) ??
        elseUndefined(organizationData, organizationData?.email);

    const userInfoLabel = email || name;

    const footerIcons = [
        <CyAttributeAppender key="flyoutFooterLink" name="logout">
            <LoginFlyoutFooterLink
                key="flyoutFooterLink"
                href="javascript:void(0)"
                label={btnLogoutLabel}
                icon={<LogoutIcon ariaHidden />}
                onClick={handleBtnLogoutClick}
                contentId={trackingContentId}
            />
        </CyAttributeAppender>
    ];

    if (userData || addCommercialSettingsHandle) {
        footerIcons.unshift(
            <LoginFlyoutFooterLink
                href="javascript:void(0)"
                label={btnSettingsLabel}
                icon={<SettingsIcon ariaHidden />}
                onClick={handleBtnSettingsClick}
            />
        );
    }

    const userInfoElement =
        organizationData && !hasCommercialProfileUrl ? (
            <LoginFlyoutUserInfoText
                label={userInfoLabel}
                icon={<ProfileIcon ariaHidden />}
            />
        ) : (
            <LoginFlyoutFooterLink
                href="javascript:void(0)"
                label={userInfoLabel}
                icon={<ProfileIcon ariaHidden />}
                onClick={handleBtnSettingsClick}
            />
        );

    return (
        <LoginFlyoutViewV2
            heading={heading}
            copy={copy}
            button={button}
            userInfo={userInfoElement}
            greeting={name}
            footerIcons={footerIcons}
        />
    );
};
