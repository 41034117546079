import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerPadding,
    ContainerWrap
} from '@volkswagen-onehub/components-core';
import {Layout} from '@volkswagen-onehub/components-core';
import {TextProps} from '@volkswagen-onehub/components-core';
import {MobileCarouselPropsV2} from '../mobile-carousel-v2';

export interface MoodGalleryMobileProps {
    readonly claim?: React.ReactElement<TextProps>;
    readonly children:
        | React.ReactElement<MobileCarouselPropsV2>
        | React.ReactElement<MobileCarouselPropsV2>[];
}

export const MoodGalleryMobile: React.FunctionComponent<MoodGalleryMobileProps> = props => {
    const {claim, children} = props;

    if (React.Children.count(children) === 0) {
        return null;
    }

    return (
        <Layout allowOverflowingContent>
            <Container
                gutter={ContainerGutter.dynamic0130}
                wrap={ContainerWrap.always}
            >
                {claim && (
                    <Container
                        padding={{
                            left: ContainerPadding.grid002,
                            right: ContainerPadding.grid002
                        }}
                        wrap={ContainerWrap.always}
                    >
                        {claim}
                    </Container>
                )}
                <Container stretchContent>{children}</Container>
            </Container>
        </Layout>
    );
};
