import * as React from 'react';

import {Model} from '@adobe/cq-react-editable-components';
import {useContentStore} from '../../context';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {C} from '../../registries/compatibilty';
import {getRenderedChildItems} from '../../utils/container/getContainerRenderedChildItems';
import {Parsys, ParsysType} from './Parsys';
import {MainBase} from './main/MainBase';
import {ContainerItem} from '../../infrastructure/ContainerExporter';
import {getChildItems} from '../../utils/container/getContainerChildItems';
import {RESOURCE_TYPE} from '../editorial/sections/CarTechnicalDataSection';
import {observer} from 'mobx-react-lite';
import {TemplateType} from '../../utils/templateType';
import {FA_SECTION_RESOURCETYPE} from '../editorial/sections/FeatureAppSection';

const PATH_STAGE_PARSYS = 'stageParsys';
const PATH_MAIN_PARSYS = 'mainParsys';

export const InternalPageMain = observer(function PM(
    props: Model
): JSX.Element {
    const contentStore = useContentStore();

    const allCqItems = [
        ...getChildItems(props.cqItems[PATH_STAGE_PARSYS]),
        ...getChildItems(props.cqItems[PATH_MAIN_PARSYS])
    ];
    const hasTechnicalCarDataSection = allCqItems.filter(
        (child: ContainerItem) => {
            return child.model.cqType === RESOURCE_TYPE;
        }
    ).length;

    const pageRootModel = contentStore.getCurrentPageRootModel();
    const template = pageRootModel
        ? pageRootModel.pageTrackingModel.templateType
        : null;

    const isHomepage = template === TemplateType.HOME;
    const stageParsys = props.cqItems[PATH_STAGE_PARSYS] && (
        <Parsys path={PATH_STAGE_PARSYS} parsysType={ParsysType.STAGE_PARSYS} />
    );
    const stageType = contentStore.getStageType();
    const isSimpleStage = Boolean(stageType?.includes('simpleStageSection'));

    const isFaPageWithoutStage =
        template === TemplateType.MODEL_OVERVIEW ||
        (template === TemplateType.FEATURE_APP && !stageParsys);

    const isBreadcrumbsBelowTopbar =
        isSimpleStage ||
        isFaPageWithoutStage ||
        pageRootModel?.breadcrumbAboveStage;

    const isFaStage = pageRootModel?.stageType === FA_SECTION_RESOURCETYPE;
    const isFaBelowTopbar = isFaStage || isFaPageWithoutStage;
    const hideBreadcrumb =
        isHomepage || (isFaBelowTopbar && pageRootModel?.hideBreadcrumbForFA);

    const hasBreadcrumbs =
        (!hasTechnicalCarDataSection || isSimpleStage) && !hideBreadcrumb;

    return (
        <>
            <MainBase
                stage={stageParsys}
                hasBreadcrumbs={hasBreadcrumbs}
                isSimpleStage={isSimpleStage}
                isFaStage={isFaStage}
                isBreadcrumbsBelowTopbar={isBreadcrumbsBelowTopbar}
            >
                {(C.isInEditor() ||
                    !isParsysEmpty(props.cqItems.mainParsys)) && (
                    <Parsys
                        path={PATH_MAIN_PARSYS}
                        parsysType={ParsysType.MAIN_PARSYS}
                        showPersonalizablePlaceholders={
                            pageRootModel &&
                            pageRootModel.personalizablePlaceholders
                        }
                    />
                )}
            </MainBase>
        </>
    );

    function isParsysEmpty(properties: any): boolean {
        return getRenderedChildItems(properties).length <= 0;
    }
});

export const PageMain = MapTo(
    'vwa-ngw18/components/structure/pagemain',
    InternalPageMain
);
MapTo('vwa-ngw18/components/structure/answersoverviewmain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/answerscategorymain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/answersmain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/idhubfamilyentrymain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/magazineoverviewmain', InternalPageMain);
MapTo(
    'vwa-ngw18/components/structure/idhubproductexperiencemain',
    InternalPageMain
);
MapTo('vwa-ngw18/components/structure/magazinecategorymain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/magazinemain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/errormain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/idhubentrymain', InternalPageMain);
MapTo('vwa-ngw18/components/structure/errormain', InternalPageMain);
