import React from 'react';
import {isInBrowser} from './browser/isInBrowser';

export const getIsomorphicLayoutEffect = () => {
    return isInBrowser()
        ? React.useLayoutEffect
        : (_effect: React.EffectCallback, _deps?: React.DependencyList) => {
              // skip layout effect on server to avoid messy warnings
          };
};

export const useLayoutEffect = getIsomorphicLayoutEffect();
