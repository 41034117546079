import {Text, TokenTextAppearance} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled from 'styled-components';

import {WormyAnimation} from '../wormy-animation';

const StyledReadingTimeBox = styled.div<{textAlign: ReadingTimeTextAlign}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${props => props.textAlign};
    flex: 1;
`;

StyledReadingTimeBox.displayName = 'StyledReadingTimeBox';

export enum ReadingTimeTextAlign {
    center = 'center',
    right = 'right',
    left = 'left'
}

export interface ReadingTimeBoxProps {
    readonly readingTime?: string | JSX.Element;
    readonly noWormyAnimation?: boolean;
    readonly textAlign?: ReadingTimeTextAlign;
}

export const ReadingTimeBox: React.FunctionComponent<ReadingTimeBoxProps> = props => {
    const {
        textAlign = ReadingTimeTextAlign.center,
        noWormyAnimation,
        readingTime
    } = props;
    return (
        <StyledReadingTimeBox textAlign={textAlign}>
            {!noWormyAnimation && <WormyAnimation />}
            {readingTime && (
                <Text appearance={TokenTextAppearance.copy100}>
                    {props.readingTime}
                </Text>
            )}
        </StyledReadingTimeBox>
    );
};
ReadingTimeBox.displayName = 'ReadingTimeBox';
