import {LayerHeaderUpdaterModel} from '../../generated/core';
import {Helmet} from 'react-helmet';
import React from 'react';

type MetaElement = React.ReactElement<'meta'>;

function getTitleTags(title?: string): MetaElement[] {
    const tags = [];

    if (title) {
        tags.push(<title>{title}</title>);
        tags.push(
            <meta name="twitter:title" key="twitter:title" content={title} />
        );
        tags.push(<meta property="og:title" key="og:title" content={title} />);
        tags.push(
            <meta property="VW.gsaTitle" key="VW.gsaTitle" content={title} />
        );
        tags.push(
            <meta property="VW:gsaTitle" key="VW:gsaTitle" content={title} />
        );
    }

    return tags;
}

function getDescriptionTags(description?: string): MetaElement[] {
    const tags = [];

    if (description) {
        tags.push(
            <meta key="description" name="description" content={description} />
        );
        tags.push(
            <meta
                name="twitter:description"
                key="twitter:description"
                content={description}
            />
        );
        tags.push(
            <meta
                property="og:description"
                key="og:description"
                content={description}
            />
        );
    }

    return tags;
}

function getImageTags(imagePath?: string): MetaElement[] {
    const tags = [];

    if (imagePath) {
        tags.push(
            <meta property="og:image" key="og:image" content={imagePath} />
        );
        tags.push(
            <meta
                name="twitter:image"
                key="twitter:image"
                content={imagePath}
            />
        );
        tags.push(
            <meta
                property="VW.thumbnail"
                key="VW.thumbnail"
                content={imagePath}
            />
        );
        tags.push(
            <meta
                property="VW:thumbnail"
                key="VW:thumbnail"
                content={imagePath}
            />
        );
    }

    return tags;
}

function getKeywords(keywords?: string): MetaElement[] {
    const tags = [];

    if (keywords) {
        tags.push(<meta key="keywords" name="keywords" content={keywords} />);
    }

    return tags;
}

function getBreadcrumbs(
    underlyingPageBreadcrumbs?: string,
    breadcrumbTitle?: string
): MetaElement[] {
    const tags = [];

    if (breadcrumbTitle) {
        if (!underlyingPageBreadcrumbs && typeof window !== 'undefined') {
            const oldBreadcrumbTag = document.head.querySelector(
                '[property~="VW.breadcrumb"][content]'
            );
            underlyingPageBreadcrumbs =
                (oldBreadcrumbTag &&
                    oldBreadcrumbTag.getAttribute('content') + ';') ||
                '';
        }
        underlyingPageBreadcrumbs = underlyingPageBreadcrumbs || '';

        tags.push(
            <meta
                property="VW.breadcrumb"
                key="VW.breadcrumb"
                content={underlyingPageBreadcrumbs + breadcrumbTitle}
            />
        );

        tags.push(
            <meta
                property="VW:breadcrumb"
                key="VW:breadcrumb"
                content={underlyingPageBreadcrumbs + breadcrumbTitle}
            />
        );
    }

    return tags;
}

/**
 * For layers opened on a page the meta tags in the header must be updated for the search crawler with layer content
 */
export function getLayerMetaDataUpdate(
    metaData?: LayerHeaderUpdaterModel
): JSX.Element | undefined {
    return metaData ? (
        <Helmet>
            {getTitleTags(metaData.pageTitle)}
            {getDescriptionTags(metaData.searchDescription)}
            {getImageTags(metaData.imageRefForSearch)}
            {getKeywords(metaData.keywords)}
            {metaData.canonicalUrl && (
                <link rel="canonical" href={metaData.canonicalUrl} />
            )}
            {getBreadcrumbs(
                metaData.underlyingPageBreadcrumbs,
                metaData.breadcrumbTitle
            )}
        </Helmet>
    ) : (
        undefined
    );
}
