import {
    styled,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {Disclaimer, TechData} from '../../../../generated/core';
import {RichtextDisclaimer} from '../../../components/Richtext';
import {useDisclaimerReferences} from '../../../context/disclaimer/useDisclaimerReferences';
import {Direction} from '../helpers';
import {useOrderedTileValues} from './useOrderedTileValues';

const StyledTextWithSup = styled.span`
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    & sup {
        top: 0;
    }

    & sup[data-component='disclaimer-reference-badge'] {
        font-size: ${props => props.theme.fonts.copy[100].fontSize};
        font-weight: ${props => props.theme.text.weight.regular};
    }
`;
StyledTextWithSup.displayName = 'StyledTextWithSup';

const StyledHighlightedTile = styled.li`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.size.static100};
`;

interface TextWithDisclaimersProps {
    text: string;
    disclaimers?: Disclaimer[];
}

export const TextWithDisclaimers = (
    props: TextWithDisclaimersProps
): JSX.Element => {
    const {disclaimers = [], text} = props;
    const disclaimerReferences = useDisclaimerReferences(disclaimers);
    if (disclaimers.length === 0) {
        return <>{text}</>;
    }
    return (
        <StyledTextWithSup>
            {text}
            <RichtextDisclaimer disclaimers={disclaimerReferences} addSpace />
        </StyledTextWithSup>
    );
};

interface HighlightedDataTileProps extends TechData {
    direction: Direction;
}

export const HighlightedDataTile = (props: HighlightedDataTileProps) => {
    const {label, value, unit, disclaimers, unitBeforeValue, direction} = props;
    const valueElement = (
        <Text
            appearance={TokenTextAppearance.headline300}
            bold
            tag={TextTag.span}
        >
            <TextWithDisclaimers
                text={value}
                disclaimers={unitBeforeValue ? disclaimers : []}
            />
        </Text>
    );

    const unitElement = (
        <Text appearance={TokenTextAppearance.headline300} tag={TextTag.span}>
            <TextWithDisclaimers
                text={unit}
                disclaimers={!unitBeforeValue ? disclaimers : []}
            />
        </Text>
    );

    const orderedValues = useOrderedTileValues({
        ...props,
        value: valueElement,
        unit: unitElement,
        direction
    });

    return (
        <StyledHighlightedTile>
            <Text appearance={TokenTextAppearance.label200} tag={TextTag.span}>
                {label}
            </Text>

            <Text
                appearance={TokenTextAppearance.headline300}
                tag={TextTag.span}
            >
                {orderedValues.map((text, index) => (
                    <React.Fragment key={index}>{text} </React.Fragment>
                ))}
            </Text>
        </StyledHighlightedTile>
    );
};
