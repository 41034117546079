import * as React from 'react';

import {withTheme} from 'styled-components';

import {useMatchMediaWithDefaultQueries} from '../../../context/mediaQueries/useMatchMedia';

import {NextBestActionPanelButton} from './NextBestActionPanelButton';

import {NextBestActionPanelProps} from './types';
import {
    StyledWrapper,
    StyledMainWrapper,
    StyledSubactionsWrapper
} from './styles';

import {Close, List} from '@volkswagen-onehub/icons-core';

export * from './types';
export * from './NextBestActionPanelButton';
export * from './NextBestActionPanelButtonContent';

const NextBestActionPanelInternal: React.FunctionComponent<NextBestActionPanelProps> = (
    props: NextBestActionPanelProps
) => {
    const {actions, openDropdownLabel, closeDropdownLabel, isVisible} = props;

    const [expanded, setExpanded] = React.useState(false);
    const [renderSubactions, setRenderSubactions] = React.useState(false);

    const nbabPanelRef = React.useRef<HTMLDivElement>(null);

    const itemsLenght = actions?.length;
    const mainButton = actions && actions[0];
    const subactions = itemsLenght && itemsLenght > 1 && actions?.slice(1);
    const mediaQueries = useMatchMediaWithDefaultQueries();
    const hasVerticalLayout = mediaQueries?.isMobile || mediaQueries?.isTablet;
    const hasHorizontalLayout =
        mediaQueries?.isLaptop || mediaQueries?.isDesktop;

    // NOTE: remove subactions on collapse to remove from DOM & TAB key flow
    const handleSubactionsTransitionEnd = (): void => {
        if (expanded) return;

        setRenderSubactions(false);
    };

    const handleDropdownButtonClick = (): void => {
        if (expanded) {
            setExpanded(false);
        } else {
            setRenderSubactions(true);
            setExpanded(true);
        }
    };

    React.useEffect(() => {
        const handleClickForClosing = (evt: MouseEvent): void => {
            if (!expanded || !nbabPanelRef.current) {
                return;
            }

            const targetNode = evt.target as Node;

            let type = (targetNode as any).type;
            if (!type && targetNode.parentNode) {
                type = (targetNode.parentNode as any).type;
            }
            if (!type && targetNode.parentNode?.parentNode) {
                type = (targetNode.parentNode.parentNode as any).type;
            }

            // handle click outside of panel
            if (!nbabPanelRef.current.contains(targetNode)) {
                setExpanded(false);
            }
            // handle link click
            else if (type === 'subaction' || type === 'submit') {
                // action should have been executed before closing panel
                setTimeout(() => {
                    setExpanded(false);
                }, 500);
            }
        };

        document.addEventListener('mousedown', handleClickForClosing);

        return () => {
            document.removeEventListener('mousedown', handleClickForClosing);
        };
    }, [nbabPanelRef, expanded]);

    React.useEffect(() => {
        const expandedAndNotVisible = expanded && !isVisible;

        if (expandedAndNotVisible) {
            setExpanded(false);
        }
    }, [nbabPanelRef, expanded, isVisible]);

    return (
        <StyledWrapper ref={nbabPanelRef}>
            <StyledMainWrapper>
                {mainButton}

                {hasVerticalLayout && subactions && (
                    <NextBestActionPanelButton
                        key="1"
                        action={{
                            content: expanded
                                ? closeDropdownLabel
                                : openDropdownLabel,
                            icon: expanded ? <Close /> : <List />,
                            onClick: handleDropdownButtonClick
                        }}
                        type="dropdown"
                        testId="horizontalNbabDropdownButton"
                        dropdownExpanded={expanded}
                    />
                )}

                {hasHorizontalLayout &&
                    subactions &&
                    subactions.map(item => item)}
            </StyledMainWrapper>

            {hasVerticalLayout && subactions && (
                <StyledSubactionsWrapper
                    expanded={expanded}
                    onTransitionEnd={handleSubactionsTransitionEnd}
                >
                    {/* NOTE: Items hidden from DOM also for accessibility reasons */}
                    {renderSubactions ? subactions.map(item => item) : null}
                </StyledSubactionsWrapper>
            )}
        </StyledWrapper>
    );
};

NextBestActionPanelInternal.displayName = 'NextBestActionPanelInternal';

export const NextBestActionPanel = withTheme(NextBestActionPanelInternal);
