import {ModelManager} from '@adobe/cq-spa-page-model-manager';
import {getFixPath} from './getFixPath';

export function monkeyPatchModelStore(): void {
    // monkey patch the broken getData implementation for paths ending in jcr:content/root

    const originalGetData = ModelManager.modelStore.getData;
    ModelManager.modelStore.getData = (path: string, immutable: boolean) => {
        const newPath = getFixPath(path);

        return originalGetData.call(
            ModelManager.modelStore,
            newPath as string,
            immutable
        );
    };
}
