import {HistoryServiceV2} from '@feature-hub/history-service';
import {LayerHandleV2, LayerManagerV2} from '@volkswagen-onehub/layer-manager';
import * as React from 'react';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {TrackingService} from '../tracking/TrackingService';
import {SearchStore} from './SearchStore';

@singleton('SearchLayerService', {env: 'client'})
export class SearchLayerService {
    @inject() private readonly layerManager!: LayerManagerV2;
    @inject() private readonly trackingService!: TrackingService;
    @inject() private readonly searchStore!: SearchStore;
    @inject() private readonly historyService!: HistoryServiceV2;

    private searchLayer: LayerHandleV2<void> | null = null;

    @postConstruct()
    public initialize(): void {
        this.historyService.rootHistory.listen(_ => {
            this.closeLightbox();
        });
    }

    public openLightbox = async (e: React.MouseEvent): Promise<void> => {
        e.preventDefault();
        if (this.searchLayer) {
            this.closeLightbox();
        }

        const {default: SearchLayerWrapper} = await import(
            /* webpackChunkName: "searchlayer" */ '../../components/search/SearchLayerWrapper'
        );
        let renderLightbox = () => {
            return <SearchLayerWrapper />;
        };

        const searchModel = this.searchStore.searchConfig;

        this.searchLayer = this.layerManager.openContentLayerV2(
            renderLightbox,
            undefined,
            {
                closeButtonLabel: searchModel.closeButtonLabel,
                contentHeadline: searchModel.searchLabel,
                disableDefaultPadding: false,
                userCloseable: true,
                onClose: this.onCloseLightbox
            }
        );
        this.trackingService.trackLayerLoad('search-layer-load');
    };

    private readonly onCloseLightbox = (): void => {
        this.trackingService.trackLayerCloseClick('search-layer-close-button');
    };

    public readonly closeLightbox = (): void => {
        if (this.searchLayer) {
            this.searchLayer.close();
        }
        this.searchLayer = null;
    };

    public readonly isOpened = (): boolean => {
        return Boolean(this.searchLayer && !this.searchLayer.isClosed());
    };
}
