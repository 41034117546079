import * as React from 'react';

import {styled, TextAlignment} from '@volkswagen-onehub/components-core';
import {ItemGroupModel} from '../../../generated/core';
import {AuthoringWrapper} from '../../components/AuthoringWrapper';
import {MovingFrame} from '../../components/MovingFrame';
import {HeadingSectionDisclaimerContainer} from '../../components/disclaimers/HeadingSectionDisclaimerContainer';
import {LegalEntityProvider} from '../../context/legal/LegalEntityProvider';
import {MapTo} from '../../infrastructure/compatibility/MapTo';
import {C} from '../../registries/compatibilty';
import {SectionWrapper} from '../editorial/sections/SectionWrapper';
import {Parsys, ParsysType} from './Parsys';
import {SectionGroupContext} from './SectionGroupContext';
import {HeadingElement} from '../editorial/elements/HeadingElement';
import {GeneralDisclaimerProvider} from '../../context/disclaimer/GeneralDisclaimerProvider';
import {Direction} from '../../d6/components/helpers';
import {useDirection} from '../../hooks/useDirection';

const StyledBody = styled.div`
    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.dynamic0130};
`;

export const getItemGroupHeadingAlignment = (
    direction: Direction,
    headingAlignedLeft: boolean
) => {
    const startDirection =
        direction === Direction.LTR ? TextAlignment.left : TextAlignment.right;

    return headingAlignedLeft ? startDirection : TextAlignment.center;
};

/**
 * I112 Item Group
 */
function InternalItemGroup(props: ItemGroupModel): JSX.Element {
    const {
        anchorId,
        contentId: sectionId,
        contentName,
        contentLabels,
        headingAlignedLeft,
        movingFrameEnabled,
        groupLegalEntity,
        movingFrameLabel,
        movingFrameLinkTarget,
        movingFrameLinkText,
        movingFrameLinkUrl,
        mboxId
    } = props;
    const isHeadline = C.isInEditor() || hasHeadline();
    const direction = useDirection();

    function hasHeadline(): boolean {
        const {cqItems} = props;
        if (!cqItems) {
            return false;
        }

        return cqItems.heading && !cqItems.heading.empty;
    }

    const headingAlignment = getItemGroupHeadingAlignment(
        direction,
        headingAlignedLeft
    );

    const body = (
        <LegalEntityProvider legalEntity={groupLegalEntity}>
            <StyledBody>
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    {isHeadline && (
                        // the heading of this ItemGroup should be an H2 but it is affected by an order change in the HeadingElement, so it needs to be one lower (H1 in this case)
                        <HeadingElement
                            path="heading"
                            style="H2"
                            order="H1"
                            textAlign={headingAlignment}
                        />
                    )}
                    <HeadingSectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </GeneralDisclaimerProvider>
                <Parsys path="itemParsys" parsysType={ParsysType.ITEM_PARSYS} />
            </StyledBody>
        </LegalEntityProvider>
    );

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="I112 Item Group"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION_GROUP}
        >
            <SectionWrapper
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
                mboxId={mboxId}
            >
                <SectionGroupContext.Provider
                    value={{
                        sectionGroupAnchorId: anchorId,
                        isWithinGroup: true
                    }}
                >
                    {movingFrameEnabled ? (
                        <MovingFrame
                            label={movingFrameLabel}
                            linkText={movingFrameLinkText}
                            url={movingFrameLinkUrl}
                            target={movingFrameLinkTarget}
                        >
                            {body}
                        </MovingFrame>
                    ) : (
                        body
                    )}
                </SectionGroupContext.Provider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

const memoizedItemGroup = React.memo(InternalItemGroup);

export const ItemGroup = MapTo(
    'vwa-ngw18/components/structure/itemGroup',
    memoizedItemGroup
);
