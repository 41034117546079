export const LOGIN_BTN_LABEL = 'Next Action Button';
export const LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT =
    'This content is shown in the login flyout to logged-out users.';
export const LOGIN_FLYOUT_AUTHOR_MSG_LOG_IN =
    'This content is shown in the login flyout to logged-in users.';
export const LOGIN_FLYOUT_AUTHOR_MSG_LOG_OUT_MULTIPLE_CUSTOMER_TYPES =
    'This content is shown in the login flyout to logged-out users when multiple customer types login is configured on the market.';
export const IDK_LOGIN = 'IDK login';
export const CIDK_LOGIN = 'CIDK login';
export const IDK_LOGOUT = 'IDK logout';
export const CIDK_LOGOUT = 'CIDK logout';
