import {ScrollytellingDataState} from './IntroOutroAnimation';
import {ScrollAmounts} from './ScrollLogic';

const hiddenCase = (scrollLogic: ScrollLogic) => {
    if (scrollLogic.isFadingOut) {
        return ScrollytellingDataState.ANIMATING_OUT;
    }
    //isVideoVisible here is an edge cases: if we reload on the video, we need to progress to the next state
    if (scrollLogic.isFadingIn || scrollLogic.isVideoVisible) {
        return ScrollytellingDataState.ANIMATING_IN;
    }

    return ScrollytellingDataState.HIDDEN;
};

const animatingInCase = (scrollLogic: ScrollLogic) => {
    if (scrollLogic.isVideoVisible) {
        return ScrollytellingDataState.SCROLLING;
    }
    if (scrollLogic.isFadingIn) {
        return ScrollytellingDataState.ANIMATING_IN;
    }

    return ScrollytellingDataState.HIDDEN;
};

const scrollingCase = (scrollLogic: ScrollLogic) => {
    if (scrollLogic.isFadingIn) {
        return ScrollytellingDataState.ANIMATING_IN;
    }
    if (scrollLogic.isFadingOut) {
        return ScrollytellingDataState.ANIMATING_OUT;
    }
    if (scrollLogic.isVideoVisible) {
        return ScrollytellingDataState.SCROLLING;
    }

    return ScrollytellingDataState.HIDDEN;
};

const animatingOutCase = (scrollLogic: ScrollLogic) => {
    if (scrollLogic.isVideoVisible) {
        return ScrollytellingDataState.SCROLLING;
    }
    if (scrollLogic.isFadingOut) {
        return ScrollytellingDataState.ANIMATING_OUT;
    }

    return ScrollytellingDataState.HIDDEN;
};

interface ScrollLogic {
    isVideoVisible: boolean;
    isFadingIn: boolean;
    isFadingOut: boolean;
}

const currentScrollLogic = (scrollAmounts: ScrollAmounts): ScrollLogic => {
    const {fadeIn, fadeOut, video} = scrollAmounts;

    return {
        isVideoVisible: video >= 0 && video <= 1,
        isFadingIn: fadeIn > 0 && fadeIn < 1,
        isFadingOut: fadeOut > 0 && fadeOut < 1
    };
};

//the reducer needed to be split because of SQ complexity, even if it lowers readability
export const playStateReducer = (
    state: ScrollytellingDataState,
    scrollAmounts: ScrollAmounts
): ScrollytellingDataState => {
    const scrollLogic = currentScrollLogic(scrollAmounts);

    switch (state) {
        case ScrollytellingDataState.HIDDEN:
            return hiddenCase(scrollLogic);

        case ScrollytellingDataState.ANIMATING_IN:
            return animatingInCase(scrollLogic);

        case ScrollytellingDataState.SCROLLING:
            return scrollingCase(scrollLogic);

        case ScrollytellingDataState.ANIMATING_OUT:
            return animatingOutCase(scrollLogic);

        default:
            return state;
    }
};
