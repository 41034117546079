import * as React from 'react';
import {ThemeContext} from 'styled-components';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    Text,
    TokenTextAppearance,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';

import {Direction, getStartDirection} from '../helpers';

export interface TechnicalDataItemProps {
    readonly name: React.ReactNode;
    readonly value: React.ReactNode;
    readonly unit: React.ReactNode;
    readonly unitBeforeValue: boolean;
    readonly suffix?: React.ReactNode;
}

const StyledTechnicalDataItem = styled.div`
    flex-grow: 0;
    margin: calc(${props => props.theme.size.dynamic0100} / 2)
        calc(${props => props.theme.size.dynamic0150} / 2);
`;

interface SecondaryDataProps {
    unitBeforeValue?: boolean;
    data?: React.ReactNode;
}

const StyledSecondaryDataWrapper = styled.span<SecondaryDataProps>`
margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.unitBeforeValue ? '0' : props.theme.size.static100};
`;

const SecondaryData: React.FunctionComponent<SecondaryDataProps> = props => (
    <>
        {props.data && (
            <StyledSecondaryDataWrapper unitBeforeValue={props.unitBeforeValue}>
                <Text appearance={TokenTextAppearance.label200} bold={false}>
                    {props.data}
                </Text>
            </StyledSecondaryDataWrapper>
        )}
    </>
);

export function TechnicalDataItem(props: TechnicalDataItemProps): JSX.Element {
    const {name, value, suffix, unit, unitBeforeValue} = props;

    const unitEl = (
        <SecondaryData data={unit} unitBeforeValue={unitBeforeValue} />
    );

    const suffixEl = <SecondaryData data={suffix} />;

    return (
        <ThemeContext.Consumer>
            {({direction}) => {
                const unitSuffixValuePair =
                    direction === Direction.RTL ? (
                        unitBeforeValue ? (
                            <>
                                {suffixEl}
                                {value}
                                {unitEl}
                            </>
                        ) : (
                            <>
                                {suffixEl}
                                {unitEl}
                                {value}
                            </>
                        )
                    ) : unitBeforeValue ? (
                        <>
                            {unitEl}
                            {value}
                            {suffixEl}
                        </>
                    ) : (
                        <>
                            {value}
                            {unitEl}
                            {suffixEl}
                        </>
                    );

                return (
                    <StyledTechnicalDataItem>
                        <Container
                            wrap={ContainerWrap.always}
                            gutter={ContainerGutter.dynamic0020}
                        >
                            <Text
                                appearance={TokenTextAppearance.label200}
                                tag={TextTag.div}
                            >
                                {name}
                            </Text>
                            <Text
                                appearance={TokenTextAppearance.headline300}
                                bold
                                tag={TextTag.div}
                            >
                                {unitSuffixValuePair}
                            </Text>
                        </Container>
                    </StyledTechnicalDataItem>
                );
            }}
        </ThemeContext.Consumer>
    );
}
