import * as React from 'react';
import styled from 'styled-components';

import {
    Breakpoints,
    BreakpointWrapper,
    ThemeProvider
} from '@volkswagen-onehub/components-core';

import {browserBarHeightValue} from '../../d6/components/helpers/useBrowserWindowHeight';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {
    CloseableDisclaimersContainer,
    DisclaimerContainerProps,
    SingleOverlayDisclaimerContainer
} from './helpers';
import {useGlobalConfig} from '../../context';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {ZIndex} from '../../utils/zIndex';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';
import {observer} from 'mobx-react-lite';

const StyledWrapper = styled.div`
    position: relative;
`;
StyledWrapper.displayName = 'StyledWrapper';

const StyledFullWidthContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    pointer-events: none;
`;
StyledFullWidthContainer.displayName = 'StyledFullWidthContainer';

interface StyledOverlayProps {
    readonly type: 'section' | 'item';
    readonly sticky?: boolean;
}

const StickyOverlayDisclaimerMaxHeight = '50vh';

const StyledOverlay = styled.div<StyledOverlayProps>`
    display: flex;
    flex-flow: column;
    align-self: flex-end;
    position: ${props => (props.sticky ? 'sticky' : 'relative')};
    ${props => (props.sticky ? 'bottom: 0' : null)};
    max-height: ${props =>
        props.sticky
            ? `calc(${StickyOverlayDisclaimerMaxHeight} - ${browserBarHeightValue})`
            : '50%'};
    width: 100%;

    @media (min-width: ${Breakpoints.b560}px) {
        margin: 0
            ${props =>
                props.type === 'section' ? props.theme.size.grid002 : 'auto'};
        max-width: ${props => (props.type === 'section' ? 'auto' : '80vw')};
    }
    background-color: ${props => props.theme.colors.overlay.default};
`;
StyledOverlay.displayName = 'StyledOverlay';

export interface OverlayDisclaimersProps {
    readonly type?: 'section' | 'item';
    readonly minBreakpoint?: Breakpoints;
}

export const OverlayDisclaimers = observer(function OD(
    props: OverlayDisclaimersProps & DisclaimerContainerProps
): JSX.Element | null {
    const [isShown, setIsShown] = React.useState(true);
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const {
        color = 'dark',
        type = 'section',
        sticky,
        minBreakpoint = Breakpoints.default
    } = props;
    const hasReferences = disclaimers && disclaimers.length !== 0;
    const {disclaimerLabels} = useGlobalConfig();

    return isShown && hasReferences ? (
        <BreakpointWrapper min={minBreakpoint}>
            <ThemeProvider theme={color === 'dark' ? 'main' : 'inverted'}>
                <CyAttributeAppender name="disclaimersContainer">
                    <StyledFullWidthContainer>
                        {renderDisclaimers()}
                    </StyledFullWidthContainer>
                </CyAttributeAppender>
            </ThemeProvider>
        </BreakpointWrapper>
    ) : null;

    function closeDisclaimerOverlayEventListener(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ): void {
        e.preventDefault();
        e.stopPropagation();

        if (isShown) {
            setIsShown(false);
        }
    }

    function renderDisclaimers(): JSX.Element | null {
        return isShown && disclaimers.length > 0 ? (
            <StyledOverlay type={type} sticky={sticky}>
                <CloseableDisclaimersContainer
                    closeDisclaimerLabel={disclaimerLabels.closeDisclaimerLabel}
                    onClose={closeDisclaimerOverlayEventListener}
                >
                    {disclaimers.map((disclaimer, index) => {
                        const disclaimerType =
                            disclaimer.displayType === 'T3_SECTION_BASED'
                                ? 'section'
                                : 'item';

                        return (
                            <CyAttributeAppender
                                name={`${disclaimerType}Disclaimers`}
                                key={index}
                            >
                                <SingleOverlayDisclaimerContainer color={color}>
                                    <DisclaimersWithSafewords
                                        disclaimers={[disclaimer]}
                                    />
                                </SingleOverlayDisclaimerContainer>
                            </CyAttributeAppender>
                        );
                    })}
                </CloseableDisclaimersContainer>
            </StyledOverlay>
        ) : null;
    }
});

function InternalWrappingOverlaySectionDisclaimers(
    props: DisclaimerContainerProps & OverlayDisclaimersProps
): JSX.Element {
    return (
        <>
            {props.children}
            <OverlayDisclaimers {...props} />
        </>
    );
}

export const WrappingOverlaySectionDisclaimers = React.memo(
    InternalWrappingOverlaySectionDisclaimers
);

const StyledItemOverlayWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    z-index: ${ZIndex.main.overlay.z};
`;

function InternalWrappingOverlayItemDisclaimers(
    props: DisclaimerContainerProps
): JSX.Element {
    return (
        <StyledItemOverlayWrapper>
            {props.children}
            <OverlayDisclaimers {...props} type="item" />
        </StyledItemOverlayWrapper>
    );
}

export const WrappingOverlayItemDisclaimers = React.memo(
    InternalWrappingOverlayItemDisclaimers
);
