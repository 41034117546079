import {Logger} from '../../context/logger/Logger';
import {monitoringHelper} from '../../context/logger/monitoringHelper';

export function onNavigate(
    logger: Logger
): (
    consumerId: string,
    consumerName: string | undefined,
    pageId: string
) => void {
    return (
        consumerId: string,
        consumerName: string | undefined,
        pageId: string
    ) => {
        monitoringHelper(logger).navigation(consumerId, consumerName, pageId);
    };
}
