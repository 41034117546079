import {Breakpoints, styled} from '@volkswagen-onehub/components-core';

const StyledSecondLevelTeaserBase = styled.div`
    padding-inline: ${props => props.theme.size.grid001};
    margin: auto;
    max-width: ${Breakpoints.b2560}px;

    display: grid;

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid002};
    }
`;

export const StyledSecondLevelTeaserIntro = styled(StyledSecondLevelTeaserBase)`
    padding-bottom: ${props => props.theme.size.dynamic0250};
    row-gap: ${props => props.theme.size.dynamic0100};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline-end: ${props => props.theme.size.grid009};

        .copyItem {
            padding-inline-start: ${props => props.theme.size.grid001};
        }
    }

    @media (min-width: ${Breakpoints.b2560}px) {
        padding-inline: ${props =>
            `${props.theme.size.grid003} ${props.theme.size.grid006}`};
    }

    // Note: remove once the copy element has no margin
    & [class*='StyledBodyWrapper'] {
        margin-bottom: 0;
    }
`;

export const StyledSecondLevelTeaserItems = styled(StyledSecondLevelTeaserBase)`
    row-gap: ${props => props.theme.size.static500};
    column-gap: ${props => props.theme.size.grid001};

    @media (min-width: ${Breakpoints.b560}px) {
        row-gap: ${props => props.theme.size.dynamic0150};
        grid-template-columns: repeat(3, 1fr);
    }
`;
