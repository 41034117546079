import * as React from 'react';

import {MapTo} from '../../../../../infrastructure/compatibility/MapTo';
import {BaseCellProps, StyledBaseCell} from './EmptyCellElement';

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/mergeRowCellElement';

class MergeRowCellElementComponentClass extends React.PureComponent<
    BaseCellProps
> {
    public render(): React.ReactNode {
        return (
            <StyledBaseCell isNarrow={this.props.isNarrow}>
                This cell will be merged with one above
            </StyledBaseCell>
        );
    }
}

export const MergeRowCellElement = MapTo(
    RESOURCE_TYPE,
    MergeRowCellElementComponentClass
);
