import {Direction} from '../d6/components/helpers';
import {useGlobalConfig} from '../context';
import {C} from '../registries/compatibilty';

export function useDirection(): Direction {
    const globalConfig = useGlobalConfig();

    return C.isInEditor()
        ? Direction.LTR
        : (globalConfig.direction as Direction);
}
