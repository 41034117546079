import * as React from 'react';

import {
    TeaserElementLink,
    TeaserLinkProps
} from '../../d6/components/teaser-element';
import {BaseCmsLinkProps, useCmsLink} from './CmsLink';

export type CmsTeaserElementLinkProps = BaseCmsLinkProps & TeaserLinkProps;

export function CmsTeaserElementLink(
    props: CmsTeaserElementLinkProps
): JSX.Element {
    const ref = React.useRef(null);
    const [onCmsLinkClick, href] = useCmsLink(ref, props);

    return (
        <TeaserElementLink
            {...props}
            href={href}
            elementRef={ref}
            onClick={onCmsLinkClick}
        />
    );
}
