import {useEffect, useState} from 'react';
import {
    useFeatureToggles,
    useLogger,
    useNavigationService,
    usePersonalizationStore
} from '../../../../context';

/**
 * checks if the section identified by the mbox should be personalized.
 * This also checks if swapping the section is or hiding the default first is activated.
 * @param mboxId the mboxId specifying the section
 * @returns personalize true means the section should be personalized. swapSection true means that default content is initially not hidden
 */
export const useSwapSectionPersonalizationEnabled = (mboxId?: string) => {
    const [personalize, setPersonalize] = useState(false);
    const enabled = useFeatureToggles().enableSwapSectionPersonalization;
    const swapSection = useNavigationService().state.initialRender;
    const ready = usePersonalizationStore().sectionVisitsChecked;

    const logger = useLogger().personalization;
    const visited = usePersonalizationStore().isVisited(mboxId);

    useEffect(() => {
        if (!personalize && enabled) {
            if (ready && !!mboxId && !visited) {
                setPersonalize(true);
            }
        }
    }, [personalize, setPersonalize, ready, mboxId, visited, enabled]);
    if (!enabled) {
        return {personalize: true, swapSection: false};
    }
    logger.debug(
        'personalize section:%s, personalize:%s, swap:%s',
        mboxId,
        personalize,
        swapSection
    );
    return {personalize, swapSection};
};
