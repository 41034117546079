import {
    DealerConfigurationV1,
    MyDealerConfigurationFeatureServiceV2
} from '@volkswagen-onehub/mydealer-configuration-service';
import {MyDealerServiceV1_2} from '@volkswagen-onehub/mydealer-service';
import {observable} from 'mobx';
import {
    inject,
    postConstruct,
    singleton
} from '../../infrastructure/di/annotations';
import {TrackingService} from '../tracking/TrackingService';
import {MydealerStore} from './MydealerStore';
import {MyDealerTracking} from '@volkswagen-onehub/tracking-service';
import {LocaleServiceV1} from '@volkswagen-onehub/locale-service';
import {Logger} from '../logger/Logger';

@singleton('MydealerStore', {env: 'client'})
export class ClientMydealerStore implements MydealerStore {
    @inject() private trackingService!: TrackingService;

    @observable _dealerName?: string;

    @inject()
    private mydealerService!: MyDealerServiceV1_2;

    @inject()
    private mydealerConfigurationService!: MyDealerConfigurationFeatureServiceV2;

    @inject()
    private localeService!: LocaleServiceV1;

    @inject()
    private logger!: Logger;

    private myDealerTracking!: MyDealerTracking;

    @observable _dealerId?: string;

    public get dealerId() {
        return this._dealerId;
    }

    public get dealerState(): boolean | undefined {
        return this.mydealerService.hasDealerState();
    }

    public get dealerName() {
        return this._dealerName;
    }

    @postConstruct()
    init(): void {
        // dealerId can be extracted from the configService before the mydealerService is ready
        if (this.mydealerConfigurationService?.initialPageOwnerConfig) {
            const config = this.mydealerConfigurationService
                ?.initialPageOwnerConfig;
            this._dealerId = config?.id;
            this._dealerName = config
                ? config.legalName || config.displayName || ''
                : undefined;
        }

        this.myDealerTracking = new MyDealerTracking(
            this.mydealerConfigurationService,
            this.localeService,
            this.mydealerService,
            this.logger.tracking
        );

        this.myDealerTracking.subscribe(dealerInfo => {
            if (this._dealerId !== dealerInfo?.id) {
                this._dealerId = dealerInfo?.id;
                this._dealerName = dealerInfo
                    ? dealerInfo.legalName || dealerInfo.displayName || ''
                    : undefined;
                // track when dealerId has been changed and dealerName was loaded
                this.trackingService.trackDealerStatusChange();
            }
        });
    }

    public getMyDealerTrackingPayloadForUserSection() {
        return this.myDealerTracking.getMyDealerTrackingPayloadForUserSection();
    }
    public getMyDealerTrackingPayloadForPartnerSection() {
        return this.myDealerTracking.getMyDealerTrackingPayloadForPartnerSection();
    }

    // added to enable proper unit testing. This is not part of the interface!
    public subscribe(
        callback: (config: DealerConfigurationV1 | undefined) => void
    ): () => void {
        return this.myDealerTracking.subscribe(callback);
    }
}
