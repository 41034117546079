import * as React from 'react';

import {css, styled} from '@volkswagen-onehub/components-core';
import {ChevronLeft, ChevronRight} from '@volkswagen-onehub/icons-core';

import {browserBarHeightValue} from '../helpers/useBrowserWindowHeight';
import {MobileCarouselV2, RenderPaginationProps} from '../mobile-carousel-v2';
import {PreviewRow} from './preview-row';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';

const StyledHighlightGallery = styled.div`
    overflow: hidden;
    width: 100%;
`;

StyledHighlightGallery.displayName = 'StyledHighlightGallery';

const CHEVRON_BTN_SIZE = '48px';

const StyledChevronButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${CHEVRON_BTN_SIZE};
    height: ${CHEVRON_BTN_SIZE};
    border-radius: 50%;
    color: ${props => props.theme.colors.content.inverted};
    border: 2px solid transparent;
`;
StyledChevronButton.displayName = 'StyledChevronButton';

type StyledCarouselButtonProps = Readonly<{
    alignRight?: boolean;
    alignLeft?: boolean;
}>;

const StyledCarouselButton = styled.button<StyledCarouselButtonProps>`
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	display: flex;
	align-items: center;
	width: auto;
	height: auto;
	cursor: pointer;
	padding: 0 0 0 ${props => props.theme.size.dynamic0020};
	background: none;
	border: none;
	outline: none;
	z-index: 1;
	${props => props.alignLeft && 'left: 0;'}
	${props =>
        props.alignRight &&
        css`
            right: 0;
            padding: 0 ${props.theme.size.dynamic0020} 0 0;
            justify-content: flex-end;
        `}
	&:hover > ${StyledChevronButton},
	&:focus > ${StyledChevronButton} {
        background-color: ${props => props.theme.colors.overlay.default};
	}

	&:focus > ${StyledChevronButton} {
		border: 2px solid ${props => props.theme.colors.content.inverted};
	}
`;
StyledCarouselButton.displayName = 'StyledCarouselButton';

interface HighlightGalleryStateV3 {
    readonly activeItemIndex: number;
}

export type HighlightGalleryPropsV3 = Readonly<{
    children: React.ReactNode;
    previewMediaElements: JSX.Element[];
    captions?: JSX.Element[];
    maxHeight?: string;
    galleryLabel: string;
    previewAriaLabel: string;
    navigationLabelPrevious: string;
    navigationLabelNext: string;
    paginationDefaultItemLabel?: string;
    carouselId: string;
    getXOfYLabel(x: number, y: number): string;
    onSlideChange?(activeItemIndex: number): void;
    onButtonClick?(delta: number): void;
    onPaginationClick?(delta: number): void;
}>;

const noop = () => undefined;

export class HighlightGalleryV3 extends React.Component<
    HighlightGalleryPropsV3,
    HighlightGalleryStateV3
> {
    public constructor(props: HighlightGalleryPropsV3) {
        super(props);

        this.state = {
            activeItemIndex: 0
        };
    }

    private readonly handleCarouselSlideChange = (index: number): void => {
        const {onSlideChange = noop} = this.props;

        onSlideChange(index);

        this.setState(state => ({
            ...state,
            activeItemIndex: index
        }));
    };

    private readonly renderPagination = (
        paginationProps: RenderPaginationProps
    ) => {
        const {previewMediaElements, onPaginationClick} = this.props;

        const isPreviewDisplayed = paginationProps.paginationData.length > 1;

        return isPreviewDisplayed ? (
            <PreviewRow
                {...paginationProps}
                onPaginationClick={onPaginationClick}
            >
                {previewMediaElements}
            </PreviewRow>
        ) : null;
    };

    private readonly renderControls = ({
        paginationData,
        onSlideSelect,
        activeIndex
    }: RenderPaginationProps) => {
        const {
            navigationLabelNext,
            navigationLabelPrevious,
            onButtonClick
        } = this.props;

        const handleNavigationClick = (delta: number) => () => {
            const numberOfItems = paginationData.length;
            const newIndex =
                (activeIndex + numberOfItems + delta) % numberOfItems;

            onSlideSelect(newIndex);
            return onButtonClick?.(delta);
        };

        const showNavigationButtons = paginationData.length > 1;

        return showNavigationButtons ? (
            <>
                <CyAttributeAppender name="previous">
                    <StyledCarouselButton
                        alignLeft
                        onClick={handleNavigationClick(-1)}
                        aria-label={navigationLabelPrevious}
                    >
                        <StyledChevronButton>
                            <ChevronLeft ariaHidden />
                        </StyledChevronButton>
                    </StyledCarouselButton>
                </CyAttributeAppender>
                <CyAttributeAppender name="next">
                    <StyledCarouselButton
                        alignRight
                        onClick={handleNavigationClick(1)}
                        aria-label={navigationLabelNext}
                    >
                        <StyledChevronButton>
                            <ChevronRight ariaHidden />
                        </StyledChevronButton>
                    </StyledCarouselButton>
                </CyAttributeAppender>
            </>
        ) : null;
    };

    public render(): JSX.Element {
        const {
            children,
            captions,
            carouselId,
            previewAriaLabel,
            getXOfYLabel,
            paginationDefaultItemLabel,
            galleryLabel
        } = this.props;
        const maxHeight = this.props.maxHeight
            ? `calc(${this.props.maxHeight} - ${browserBarHeightValue})`
            : undefined;

        const {activeItemIndex} = this.state;

        return (
            <StyledHighlightGallery>
                <MobileCarouselV2
                    carouselId={carouselId}
                    activeSlide={activeItemIndex}
                    onSlideChanged={this.handleCarouselSlideChange}
                    dontShowNextSlide
                    maxHeight={maxHeight}
                    renderPagination={this.renderPagination}
                    renderControls={this.renderControls}
                    captions={captions}
                    galleryLabel={galleryLabel}
                    paginationLabel={previewAriaLabel}
                    getXOfYLabel={getXOfYLabel}
                    paginationDefaultItemLabel={paginationDefaultItemLabel}
                >
                    {children}
                </MobileCarouselV2>
            </StyledHighlightGallery>
        );
    }
}

export * from './gallery-pagination';
