import * as React from 'react';
import styled from 'styled-components';

import {Breakpoints} from '@volkswagen-onehub/components-core';

import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {DisclaimersWithSafewords} from './DisclaimersWithSafewords';
import {observer} from 'mobx-react-lite';
import {DisclaimerContainerProps} from './helpers';
import {useDisclaimersFromPropsOrNewContext} from './useDisclaimersFromPropsOrNewContext';

const StyledSectionGroupHeadingDisclaimerReferences = styled.div`
    padding-inline: ${props => props.theme.size.grid002};
    padding-top: ${props => props.theme.size.dynamic0100};

    @media (min-width: ${Breakpoints.b560}px) {
        padding-inline: ${props => props.theme.size.grid006};
    }
`;

export const HeadingSectionDisclaimerContainer = observer(function HSDC(
    props: DisclaimerContainerProps
): JSX.Element | null {
    const disclaimers = useDisclaimersFromPropsOrNewContext(props);
    const hasReferences = disclaimers && disclaimers.length !== 0;
    return hasReferences ? (
        <CyAttributeAppender name="sectionDisclaimers">
            <StyledSectionGroupHeadingDisclaimerReferences>
                <DisclaimersWithSafewords disclaimers={disclaimers} />
            </StyledSectionGroupHeadingDisclaimerReferences>
        </CyAttributeAppender>
    ) : null;
});
