import {
    Breakpoints,
    styled,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {XfAnnouncementModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CopyItem, COPY_BODY_CLASS} from '../../editorial/items/CopyItem';

const StyledCopyItemWrapper = styled.div`
    & .${COPY_BODY_CLASS} {
        text-align: center;
        @media (min-width: ${Breakpoints.b560}px) {
            display: flex;
            align-items: center;
            gap: ${props => props.theme.size.static300};
            text-align: initial;
        }
    }
    @media (min-width: ${Breakpoints.b560}px) {
        & div[class^='StyledLinkContainer'] {
            margin-top: 0;
        }

        & div[class^='StyledTextComponent'] {
            width: max-content;
        }
    }
`;

const copy = (
    <StyledCopyItemWrapper>
        <CopyItem
            path="copy"
            appearance={TokenTextAppearance.label200}
            tag={TextTag.span}
            hideItemDisclaimers
        />
    </StyledCopyItemWrapper>
);

const InternalXfAnnouncement: React.FunctionComponent<XfAnnouncementModel> = () => {
    const authorView = (
        <AuthoringWrapper title="Announcement XF">{copy}</AuthoringWrapper>
    );

    const publishView = copy;

    return C.isInEditor() ? authorView : publishView;
};

export const XfAnnouncement = MapTo(
    'vwa-ngw18/components/structure/xfAnnouncement',
    InternalXfAnnouncement
);
