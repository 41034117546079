import {withEditorContext} from '@adobe/cq-react-editable-components';
import * as React from 'react';
import {PersonalizationInclude} from '../../components/PersonalizationInclude';
import {BasicIncludeProps} from '../api/Compatibility';
import {withEditable} from './EditableComponent';
import {ComponentMapping} from './ModelContext';
import {ModelProvider} from './ModelProvider';

const globalOptions: {[resourceType: string]: ComponentOptions} = {};

export interface ComponentOptions {
    noElement?: boolean;
    noElementRecursively?: boolean;
}

export const getGlobalOptions = (resourceType: string) => {
    return globalOptions[resourceType];
};

const MapTo = <ExtraProps>(
    resourceType: string,
    Component: React.ComponentType<any>,
    options: ComponentOptions = {noElement: false, noElementRecursively: false}
) => {
    let WrappedComponent = withEditable(Component);
    WrappedComponent = withEditorContext(WrappedComponent); // adds extra isInEditor to props.

    const compi = ModelProvider.withModel(WrappedComponent);

    ComponentMapping.map(resourceType, compi);
    globalOptions[resourceType] = options;

    return (props: ExtraProps & BasicIncludeProps) => {
        return React.createElement(PersonalizationInclude, {
            path: props.path,
            resourceType,
            personalizable: props.personalizable,
            containerProps: props.containerProps,
            extraProps: props,
            isNotEditable: props.isNotEditable,
            noElement: props.noElement
        });
    };
};

export {MapTo};
