import * as React from 'react';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import styled from 'styled-components';
import {setTopBarHeight} from '../../../d6/components/navigation-top-bar-one-hub-helpers';

const CSS_VAR_TOP_BAR_SHIM_HEIGHT =
    '--s2-one-hub-navigation-top-bar-shim-height';
const shimHeight = `var(${CSS_VAR_TOP_BAR_SHIM_HEIGHT})`;

const StyledBackgroundShim = styled.div<TopBarShimProps>`
    @media (max-width: ${Breakpoints.b560}px) {
        height: ${props =>
            props.isStaticTopbar ? props.theme.size.static520 : shimHeight};
    }
    position: absolute;
    top: 0;
    ${setTopBarHeight(CSS_VAR_TOP_BAR_SHIM_HEIGHT, true)};
    height: ${props =>
        props.isStaticTopbar ? props.theme.size.static530 : shimHeight};
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.6) 40%,
        rgba(0, 0, 0, 0)
    );
    display: ${props => (props.showShim ? 'block' : 'none')};
`;

interface TopBarShimProps {
    showShim: boolean;
    isStaticTopbar?: boolean;
}

export const TopBarShim = ({
    showShim,
    isStaticTopbar
}: TopBarShimProps): JSX.Element => {
    return (
        <StyledBackgroundShim
            showShim={showShim}
            isStaticTopbar={isStaticTopbar}
        />
    );
};
