import * as React from 'react';
import {MediaAssetImageModel} from '../../generated/core';

import {VideoPlayerV2VideoProps} from '../d6/components/video-player-v2';
import {MediaAspectRatio, Scene7VideoRendition} from '../../generated/api';
import {useVideoSource} from '../modules/editorial/elements/videoElement/hooks/useVideoSource';
import {DefaultVideoPlayerProxy} from '../services/video/VideoPlayerProxy';
import {VideoPoster} from './videoPoster';
import {VideoWithFocalPoint} from '../d6/components/video-with-focal-point';
import {FocalPoint} from '../d6/components/focal-point';
import {ResponsiveMediaRendererConf} from './ResponsiveMediaRenderer';

const noop = () => undefined;

export const DEFAULT_VIDEO_FOCAL_POINT: FocalPoint = {x: 'center', y: 'center'};

export interface VideoComponentFactoryProps {
    videoRenditions: Scene7VideoRendition[];
    videoPlayerProxy: React.RefObject<DefaultVideoPlayerProxy>;
    videoContainerRef: React.RefObject<HTMLDivElement>;
    coverImageModel: MediaAssetImageModel;
    aspectRatio?: MediaAspectRatio;
    playsInLoop?: boolean;
    scene7FileReference?: string;
    subtitlesFileReference?: string;
    title?: string;
    muted?: boolean;
    responsiveMediaConfig?: ResponsiveMediaRendererConf[];
    onDurationChange?(e: React.SyntheticEvent<HTMLVideoElement>): void;
    onCoverImageLoad?: () => void;
    onCoverImageError?: () => void;
}

export function VideoComponent(
    props: VideoPlayerV2VideoProps & VideoComponentFactoryProps
): JSX.Element {
    const {
        onPlay,
        innerRef,
        onDurationChange: propsOnDurationChange,
        onPause,
        onEnded,
        onTimeUpdate,
        onLoadedMetadata,
        muted: propsMuted,
        keepOriginalAspectRatio,
        videoRenditions,
        aspectRatio,
        coverImageModel,
        playsInLoop,
        subtitlesFileReference,
        title = '',
        muted: factoryMuted,
        onDurationChange = noop,
        onCoverImageLoad,
        onCoverImageError,
        videoPlayerProxy,
        videoContainerRef,
        responsiveMediaConfig
    } = props;

    const muted = factoryMuted || propsMuted;
    const videoElement = innerRef.current;
    const videoSource = useVideoSource(
        videoRenditions,
        aspectRatio,
        videoPlayerProxy,
        videoContainerRef
    );

    const coverImage = React.useMemo(
        () => (
            <VideoPoster
                image={coverImageModel}
                onLoad={onCoverImageLoad}
                onError={onCoverImageError}
                responsiveMediaConfig={responsiveMediaConfig}
            />
        ),
        [
            coverImageModel,
            onCoverImageError,
            onCoverImageLoad,
            responsiveMediaConfig
        ]
    );

    const [isCoverShown, setIsCoverShown] = React.useState(
        videoElement
            ? videoElement.paused &&
                  videoPlayerProxy.current &&
                  !videoPlayerProxy.current.isInFullScreen
            : true
    );

    const handlePlay = React.useCallback(() => {
        onPlay();
        setIsCoverShown(false);
    }, [onPlay]);

    React.useEffect(() => {
        // just to be safe
        if (isCoverShown && videoElement && !videoElement.paused) {
            setIsCoverShown(false);
        }
    }, [isCoverShown, videoElement]);

    const onDurationChangeCallback = React.useCallback(
        (e: React.SyntheticEvent<HTMLVideoElement>) => {
            onDurationChange(e);
            propsOnDurationChange();
        },
        [onDurationChange, propsOnDurationChange]
    );

    return (
        <>
            <VideoWithFocalPoint
                innerRef={innerRef}
                focalPoint={DEFAULT_VIDEO_FOCAL_POINT}
                onPlay={handlePlay}
                onPause={onPause}
                onEnded={onEnded}
                onTimeUpdate={onTimeUpdate}
                onLoadedMetadata={onLoadedMetadata}
                onDurationChange={onDurationChangeCallback}
                muted={muted}
                loop={playsInLoop}
                title={title}
                controls={false}
                playsInline
                src={videoSource}
                preload="metadata"
                keepOriginalAspectRatio={keepOriginalAspectRatio}
            >
                {subtitlesFileReference && (
                    <track
                        kind="subtitles"
                        src={subtitlesFileReference}
                        default
                    />
                )}
            </VideoWithFocalPoint>
            {isCoverShown && coverImage}
        </>
    );
}
