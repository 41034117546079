import React from 'react';

export const isTouchDevice = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0;

export function useIsTouchDevice(): boolean {
    const [isTouchable, setIsTouchable] = React.useState(false);
    const isClient = typeof window !== 'undefined';

    React.useEffect(() => {
        if (isClient) {
            setIsTouchable(isTouchDevice());
        }
    }, [isClient]);

    return isTouchable;
}
