import * as React from 'react';
import {Value} from '../../utils/contextUtils';

export const ModelContext = React.createContext<Value<string>>({
    value: '/'
});

export class ModelContextProvider extends React.Component<Value<string>> {
    public render(): JSX.Element {
        return (
            <ModelContext.Provider value={this.props}>
                {this.props.children}
            </ModelContext.Provider>
        );
    }
}

class MyComponentMapping {
    public readonly mapping: {[resourceType: string]: any} = {};
    public map(resourceType: string, component: any): void {
        this.mapping[resourceType] = component;
    }
    public get(resourceType: string): any {
        return this.mapping[resourceType];
    }
}

export const ComponentMapping = new MyComponentMapping();
