import * as React from 'react';
import styled from 'styled-components';

import {InsertContent} from '../context/personalization/PersonalizationStore';
import {createSelector} from '../context/personalization/SelectorUtils';
import {Include} from '../infrastructure/compatibility/Include';
import {PersonalizedPlaceholderElement} from '../modules/editorial/elements/PersonalizedPlaceholderElement';
import {DynamicComponentLoader} from '../modules/structure/DynamicComponentLoader';
import {isInVec} from './personalization/isInVec';
import {StyledPropsExtractor} from './styledUtils';
import {PersonalizationContext} from '../context/personalization/PersonalizationContext';
import {processContentId} from './processContentId';

const StyledInsertWrapper = styled(StyledPropsExtractor)`
    display: inline-block;
    width: 100%;
`;

StyledInsertWrapper.displayName = 'StyledInsertWrapper';

const createInsert = (id: string, resource: InsertContent) => {
    return (
        <PersonalizationContext.Provider
            key={id}
            value={{
                isPersonalizable: true,
                contentId: processContentId(resource.path),
                placeholderId: processContentId(id)
            }}
        >
            <DynamicComponentLoader path={resource.path} key={id}>
                <StyledInsertWrapper>
                    {styledProps => (
                        <Include
                            path={resource.path}
                            resourceType={resource.resourceType}
                            containerProps={{
                                className: styledProps.className
                            }}
                        />
                    )}
                </StyledInsertWrapper>
            </DynamicComponentLoader>
        </PersonalizationContext.Provider>
    );
};

const createChildren = (
    inserts: InsertContent[],
    children: JSX.Element[],
    parentPath: string
) => {
    const map = inserts.reduce(
        (
            result: {
                [idx: number]: InsertContent;
            },
            element: InsertContent
        ) => {
            result[element.index] = element;

            return result;
        },
        []
    );

    return children.reduce(
        (result: JSX.Element[], element: JSX.Element, index: number) => {
            result.push(element);
            if (map[index + 1]) {
                result.push(
                    createInsert(
                        createSelector(parentPath, index + 1),
                        map[index + 1]
                    )
                );
            }

            return result;
        },
        map[0] ? [createInsert(createSelector(parentPath, 0), map[0])] : []
    );
};

const createChildrenVec = (children: JSX.Element[], parentPath: string) => {
    const createPlaceholder = (id: string): JSX.Element => (
        <PersonalizedPlaceholderElement key={id} id={id} />
    );

    return children.reduce(
        (result: JSX.Element[], element: JSX.Element, index: number) => {
            result.push(
                element,
                createPlaceholder(createSelector(parentPath, index + 1))
            );

            return result;
        },
        [createPlaceholder(createSelector(parentPath, 0))]
    );
};

export const insertPersonalizedPlaceholders = (
    parentPath: string,
    children: JSX.Element[],
    inserts: InsertContent[]
): JSX.Element[] => {
    if (isInVec()) {
        return createChildrenVec(children, parentPath);
    } else {
        return createChildren(inserts, children, parentPath);
    }
};
