import * as React from 'react';
import ReactDOM from 'react-dom';

import FocusTrap from 'react-focus-lock';

import {
    Breakpoints,
    Text,
    TokenTextAppearance,
    TokenTextColor,
    TextTag,
    styled
} from '@volkswagen-onehub/components-core';
import {ChevronDown} from '@volkswagen-onehub/icons-core';
import {
    buttonFocusStateCSS,
    getElectricTransition,
    getStartDirection,
    resetButtonStyles,
    textOverflowEllipsis
} from '../helpers';

export * from './parts/dropdown-link';

const ICON_SIZE = 24;

const StyledButtonWrap = styled.div`
    display: flex;
    height: calc(${props => props.theme.size.static500} + 2px); //46px
    padding: ${props => props.theme.size.static150}
        ${props => props.theme.size.static350};
    border-bottom: solid 1px ${props => props.theme.colors.border.divider};
`;

const StyledButton = styled.button<StyledActionProps>`
    ${resetButtonStyles};
    ${props => buttonFocusStateCSS(props)}
    pointer-events: auto; // re-set due topbar unset
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 ${props => props.theme.size.static100};
    padding-right: ${props => props.theme.size.static150};
    width: 100%;
    max-width: 100%;
    color: ${props => props.theme.colors.button.primary.default};
    -webkit-tap-highlight-color: transparent;
    margin-left: calc(${props => props.theme.size.static370} * -1);

    &:hover,
    &:focus {
        color: ${props => props.theme.colors.button.primary.hover};
    }

    &:focus:before {
        // overrided border offset
        width: calc(100% + ${props => props.theme.size.static150});
        height: calc(100% + ${props => props.theme.size.static150});
    }

    @media (min-width: ${Breakpoints.b560}px) {
        width: auto;
    }
`;

const StyledLabel = styled.div<StyledActionProps>`
    ${textOverflowEllipsis};

    // NOTE: this due possible use of <Fade> component and keeping ellipsis
    div {
        display: inline;
    }
`;

const StyledIconHolder = styled.div<StyledActionProps>`
	position: relative;
	width: ${ICON_SIZE}px;
	height: ${ICON_SIZE}px;
	line-height: 1; // to remove space around the icon - especially for the anim rotate alignment
	margin-${props => getStartDirection(props.theme.direction)}: ${props =>
    props.theme.size.static150};
	flex-shrink: 0;
`;

const StyledIcon = styled.div<StyledActionProps>`
    position: absolute;
    top: 0;
    left: 0;

    ${getElectricTransition('transform')};
    ${props =>
        props.isOpen &&
        `
            transform: rotate(-180deg);
		`};
`;

export interface StyledActionProps {
    readonly isOpen?: boolean;
}

const StyledDropdownWrapper = styled.div`
    overflow: hidden;
    width: calc(100% + ${props => props.theme.size.static500});
    margin-left: calc(${props => props.theme.size.static500} * -1);
`;

const StyledListWrap = styled.div<StyledActionProps>`
    pointer-events: auto; // re-set due topbar unset
    background: ${props => props.theme.colors.background.primary};

    max-height: 60vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    ${getElectricTransition('transform')};
    transform: translateY(-100%);

    ${props =>
        props.isOpen &&
        `
            transform: translateY(0);
		`};
`;

const StyledWrap = styled.div`
    padding-left: ${props => props.theme.size.grid002};
`;

const StyledList = styled.ul<StyledActionProps>`
    overflow: hidden;
    list-style: none;
    display: flex;
    flex-flow: column;
    gap: ${props => props.theme.size.static200};
    margin: 0;
    padding: ${props => props.theme.size.static300}
        ${props => props.theme.size.static350};

    li {
        padding: 0 ${props => props.theme.size.static200};
    }
`;

const getFocusedListItemIndex = (list: HTMLUListElement) => {
    if (!list || !list.hasChildNodes()) {
        return -1;
    }

    return Array.from(list.childNodes).findIndex(element => {
        const listItem = element.firstChild;

        return (
            document.activeElement === ReactDOM.findDOMNode(listItem as Element)
        );
    });
};

const focusItem = (list: HTMLUListElement, linkIndex: number) => {
    if (list && linkIndex < list.childNodes.length) {
        const element = list.childNodes[linkIndex];
        if (element && element.firstChild) {
            (element.firstChild as HTMLElement).focus();
        }
    }
};

export interface InPageNavDropdownProps {
    readonly activeItemTitle?: string | JSX.Element;
    readonly isOpen?: boolean;
    readonly dropdownButtonLabel: string;
    readonly isInPageNavHidden?: boolean;
    readonly children?: React.ReactNode;
    onClick(): void;
}

export const InPageNavDropdown: React.FunctionComponent<InPageNavDropdownProps> = (
    props: InPageNavDropdownProps
) => {
    const {
        activeItemTitle,
        isOpen,
        dropdownButtonLabel,
        isInPageNavHidden,
        onClick,
        children
    } = props;

    const dropdownListRef = React.useRef<HTMLUListElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const dropdownButtonId = 'InPageNavDropdownButton';
    const dropdownListId = 'InPageNavDropdownList';

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>): void => {
        if (!isOpen || !dropdownListRef.current) {
            return;
        }

        const listItems = dropdownListRef?.current;
        const listItemsLength = listItems.childNodes.length;
        const focusedItemIdx = getFocusedListItemIndex(listItems);

        switch (e.key) {
            case 'Escape':
                onClick();
                if (buttonRef.current) {
                    buttonRef.current.focus();
                }
                e.preventDefault();
                break;
            case ' ': {
                const listItem =
                    listItems.childNodes[focusedItemIdx].firstChild;
                (listItem as HTMLLinkElement).click();
                e.preventDefault();
                break;
            }
            case 'ArrowUp': {
                const itemToFocus =
                    focusedItemIdx <= 0
                        ? listItemsLength - 1
                        : focusedItemIdx - 1;
                focusItem(listItems, itemToFocus);
                e.preventDefault();
                break;
            }
            case 'ArrowDown': {
                const itemToFocus =
                    focusedItemIdx < 0 || focusedItemIdx === listItemsLength - 1
                        ? 0
                        : focusedItemIdx + 1;
                focusItem(listItems, itemToFocus);
                e.preventDefault();
            }
        }
    };

    return (
        <FocusTrap
            autoFocus={false}
            disabled={(isInPageNavHidden && isOpen) || !isOpen}
        >
            <StyledWrap onKeyDown={handleKeyDown}>
                <StyledButtonWrap>
                    <StyledButton
                        id={dropdownButtonId}
                        isOpen={isOpen}
                        onClick={onClick}
                        aria-expanded={isOpen}
                        aria-controls={dropdownListId}
                        aria-label={dropdownButtonLabel}
                        aria-haspopup="listbox"
                        ref={buttonRef}
                    >
                        <StyledLabel>
                            <Text
                                tag={TextTag.span}
                                appearance={TokenTextAppearance.headline200}
                                color={TokenTextColor.inherit}
                                bold
                            >
                                {activeItemTitle}
                            </Text>
                        </StyledLabel>
                        <StyledIconHolder>
                            <StyledIcon isOpen={isOpen}>
                                <ChevronDown ariaHidden />
                            </StyledIcon>
                        </StyledIconHolder>
                    </StyledButton>
                </StyledButtonWrap>
                <StyledDropdownWrapper>
                    <Text
                        tag={TextTag.span}
                        appearance={TokenTextAppearance.headline200}
                        color={TokenTextColor.inherit}
                        staticSize
                    >
                        <StyledListWrap isOpen={isOpen} tabIndex={-1}>
                            <StyledList
                                id={dropdownListId}
                                aria-hidden={!isOpen}
                                aria-labelledby={dropdownButtonId}
                                ref={dropdownListRef}
                                hidden={!isOpen}
                            >
                                {children}
                            </StyledList>
                        </StyledListWrap>
                    </Text>
                </StyledDropdownWrapper>
            </StyledWrap>
        </FocusTrap>
    );
};
