import * as React from 'react';

import {
    Container,
    ContainerGutter,
    ContainerWrap,
    DirectionalContainerPadding
} from '@volkswagen-onehub/components-core';
import {getContainerPadding} from '../../../d6/components/helpers';
import {XfCarFeatureCategoryMainModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {HeadingElement} from '../../editorial/elements/HeadingElement';
import {CopyItem} from '../../editorial/items/CopyItem';

export interface XfCarFeatureCategoryMainProps {
    isEmbedded?: boolean;
}

const CONTAINER_PADDING_2_3_5 = getContainerPadding([2, 3, 5]);

const AuthoringContainerPadding = {
    left: CONTAINER_PADDING_2_3_5,
    right: CONTAINER_PADDING_2_3_5
};

class InternalXfCarFeatureCategoryMain extends React.PureComponent<
    XfCarFeatureCategoryMainProps & XfCarFeatureCategoryMainModel
> {
    public render(): JSX.Element {
        return (
            <AuthoringWrapper title="Car Feature Category Main">
                <Container
                    wrap={ContainerWrap.always}
                    gutter={ContainerGutter.static300}
                    padding={this.getContainerPadding()}
                    stretchContent
                >
                    <HeadingElement path="heading" style="H3" order="H3" />
                    <CopyItem path="copy" useDropCap={false} />
                </Container>
            </AuthoringWrapper>
        );
    }

    private getContainerPadding(): DirectionalContainerPadding | undefined {
        return !this.props.isEmbedded ? AuthoringContainerPadding : undefined;
    }
}

export const XfCarFeatureCategoryMain = MapTo<XfCarFeatureCategoryMainProps>(
    'vwa-ngw18/components/structure/xfCarFeatureCategoryMain',
    InternalXfCarFeatureCategoryMain
);
