import {EditConfig} from '@adobe/cq-react-editable-components';
import * as React from 'react';
import styled from 'styled-components';
import {C} from '../../registries/compatibilty';
import {CyAttributeAppender} from '../../test/CyAttributeAppender';
import {useLogger, useNavigationStore, useRegistry} from '../../context';
import {
    WHITE_SPACE_MANAGER,
    WhitespaceManager
} from '../../context/whitespace/WhiteSpaceManager';
import {getBackgroundColor} from './getBackgroundColor';
import {
    EditableComponentContext,
    useSectionVisibility
} from './useSectionVisibility';
import {useEditableComponentVisible} from './useEditableComponentVisible';
import {isInBrowser} from '../../utils/browser/isInBrowser';

/**
 * Class name used to identify the placeholder used to represent an empty component
 *
 * @private
 */
const PLACEHOLDER_CLASS_NAME = 'cq-placeholder';

export interface EditableComponentProps {
    wrappedComponent?: React.ComponentType;
    containerProps?: any;
    emptyPlaceholderProps?: boolean;
    cqPath?: string;
    cqType?: string;
    editConfig?: EditConfig;
    isNotEditable?: boolean;
    noElement?: boolean;
}

/**
 * remove all props that are meant for EditableComponent
 * @param props
 */
export function cleanProps(props: EditableComponentProps): any {
    const newProps = {...props};
    delete newProps.containerProps;
    delete newProps.wrappedComponent;
    delete newProps.editConfig;
    delete newProps.isNotEditable;
    delete newProps.noElement;

    return newProps;
}

interface StyledEditableComponentProps {
    readonly matchParent?: boolean;
    backgroundColor?: string;
    visible: boolean;
}

const StyledEditableComponent = styled.div<StyledEditableComponentProps>`
    // make editable component always match it's parent height
    ${props => props.matchParent && 'height: 100%'};
    position: relative;
    ${props =>
        props.backgroundColor
            ? 'background-color: ' +
              props.backgroundColor +
              '; border-color: gray; border:dashed; border-size:1px'
            : ''};
    ${props => (!props.visible ? 'display: none' : '')};

    // overwrites a padding the editor sets, which we don't want
    padding: 0;
`;
StyledEditableComponent.displayName = 'StyledEditableComponent';

/**
 * The EditableComponent extends components with editing capabilities
 */
const EditableComponent = (props: EditableComponentProps): JSX.Element => {
    const {wrappedComponent: WrappedComponent, containerProps} = props;
    const newProps = cleanProps(props);

    const navigationStore = useNavigationStore();
    const debugWhitespace = useRegistry().getSingleton<WhitespaceManager>(
        WHITE_SPACE_MANAGER
    ).debugging;

    const backgroundColor = debugWhitespace
        ? getBackgroundColor(props.cqType)
        : undefined;

    // component's and parent's visibility
    const [visible, setVisible] = useEditableComponentVisible(props.cqPath);
    const {visible: parentVisible} = useSectionVisibility();
    const inheritedVisibility = visible && parentVisible;

    const inEditor = C.isInEditor();
    const inBrowser = isInBrowser();
    const anchorId = (props as any).anchorId;

    React.useEffect(() => {
        if (inBrowser && !inEditor && anchorId) {
            // remove current section from in page navigation
            if (
                inheritedVisibility !==
                navigationStore.isInPageItemVisible(anchorId)
            ) {
                navigationStore.setInPageItemsVisibility(
                    [anchorId],
                    inheritedVisibility
                );
            }
        }
    }, [inBrowser, inEditor, anchorId, inheritedVisibility, navigationStore]);

    useLogger().general.debug('rendering editable component', visible);

    if (WrappedComponent && props.noElement && !inEditor) {
        const name = getCypressAttribute(props.cqType);
        return (
            <CyAttributeAppender name={name || ''} asClassName>
                <WrappedComponent {...newProps} />
            </CyAttributeAppender>
        );
    }

    return (
        <EditableComponentContext.Provider
            value={{setVisible, visible: inheritedVisibility}}
        >
            <StyledEditableComponent
                {...getEditProps(props)}
                {...containerProps}
                backgroundColor={backgroundColor}
                visible={visible}
            >
                {WrappedComponent && <WrappedComponent {...newProps} />}
                {getUseEmptyPlaceholder(props) && (
                    <div {...getEmptyPlaceholderProps(props)} />
                )}
            </StyledEditableComponent>
        </EditableComponentContext.Provider>
    );
};

/**
 * Properties related to the edition of the component
 */
function getEditProps(props: EditableComponentProps): any {
    const eProps: any = {};

    if (!C.isInEditor() || props.isNotEditable) {
        return eProps;
    }

    eProps['data-cq-data-path'] = props.cqPath;

    return eProps;
}

/**
 * HTMLElement representing the empty placeholder
 * @return {*}
 */
function getEmptyPlaceholderProps(
    props: EditableComponentProps
): {[key: string]: string | undefined} {
    if (!getUseEmptyPlaceholder(props)) {
        return {};
    }

    return {
        'data-emptytext': props.editConfig
            ? props.editConfig.emptyLabel
            : undefined,
        className: PLACEHOLDER_CLASS_NAME
    };
}

/**
 * Should an empty placeholder be added
 *
 * @return {boolean}
 */
function getUseEmptyPlaceholder(props: EditableComponentProps): boolean {
    return (
        C.isInEditor() && !!props.editConfig && props.editConfig.isEmpty(props)
    );
}

/**
 * Configuration object of the withEditable function
 *
 * @typedef {Object} EditConfig
 * @property {boolean} [emptyLabel] - Label to be displayed on the overlay when the component is empty
 * @property {function} [isEmpty] - Callback function to determine if the component is empty
 */

/**
 * Returns a composition that provides edition capabilities to the component
 *
 * @param {React.Component} WrappedComponent
 */
function withEditable(
    WrappedComponent: React.ComponentType
): React.ComponentType {
    /**
     * Wrapping Editable Component
     */
    return class CompositeEditableComponent extends React.Component {
        public render(): JSX.Element {
            return (
                <EditableComponent
                    {...this.props}
                    wrappedComponent={WrappedComponent}
                />
            );
        }
    };
}

function getCypressAttribute(resourceType = '') {
    const elements = resourceType.split('/');
    return elements[elements.length - 1];
}

export {withEditable, PLACEHOLDER_CLASS_NAME};
