import * as React from 'react';
import {ThemedStyledProps} from 'styled-components';

import {
    Text,
    TextAlignment,
    TokenTextColor,
    TextTag,
    ThemeDefinition,
    TokenTextAppearance,
    styled
} from '@volkswagen-onehub/components-core';

import {hyphens} from '../helpers';

export interface RichtextProps {
    readonly appearance?: TokenTextAppearance;
    readonly tag?: TextTag;
    readonly textAlign?: TextAlignment;
    readonly inheritColor?: boolean;
    readonly bold?: boolean;
}

const getReducedFontSize = (
    props: ThemedStyledProps<RichtextProps, ThemeDefinition>
) => {
    const apperance = props.appearance || '';
    const word = apperance.match(/\D+/g);
    const digits = apperance.match(/\d+/g);
    const type = (word && word[0]) || '';
    const size = Number(digits && digits[0]);

    return props.appearance && props.theme.fonts[type][size].reducedFontSize;
};

const StyledRichtextComponent = styled.span<RichtextProps>`
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    b,
    strong,
    a {
        font-weight: ${props => props.theme.text.weight.bold};
    }

    sup,
    sub {
        font-size: ${props => getReducedFontSize(props)};
    }

    ${hyphens}

    // defines typography inside richtext
    p + *,
    h3 + * {
        margin: ${props => props.theme.size.dynamic0100} 0 0 0;
    }

    * + h3,
    ol + *,
    ul + * {
        margin: ${props => props.theme.size.dynamic0150} 0 0 0;
    }
`;

StyledRichtextComponent.displayName = 'StyledRichtextComponent';

export const Richtext: React.FunctionComponent<RichtextProps> = props => {
    const {children, appearance, tag, textAlign, inheritColor, bold} = props;

    const textAppearance = appearance || TokenTextAppearance.copy200;

    return (
        <Text
            tag={tag}
            appearance={textAppearance}
            textAlign={textAlign}
            color={inheritColor ? TokenTextColor.inherit : undefined}
            bold={bold}
        >
            <StyledRichtextComponent appearance={textAppearance}>
                {children}
            </StyledRichtextComponent>
        </Text>
    );
};

Richtext.displayName = 'Richtext';
