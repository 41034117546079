import * as React from 'react';
import styled from 'styled-components';

import {FocalPoint} from '../focal-point';
import {isEDGE} from '../helpers';
import {getElectricTransitionAdvanced} from '../helpers';
import {styledCompatRef} from '../helpers/styled-compat-ref';
import {
    VideoWrapperStyle,
    calculateVideoWrapperStyle,
    fpToObjPos,
    getVideoDimensions,
    getWrapperDimensions
} from './helpers';

export interface VideoWithFocalPointProps
    extends React.VideoHTMLAttributes<HTMLVideoElement> {
    readonly focalPoint: FocalPoint;
    readonly disablePointerEvents?: boolean;
    readonly reduceBrightness?: boolean;
    readonly innerRef?: React.RefObject<HTMLVideoElement>;
    readonly keepOriginalAspectRatio?: boolean;
}

export interface VideoWithFocalPointState {
    readonly videoWrapperStyle?: VideoWrapperStyle;
}

interface StyledVideoWithFocalPointProps {
    readonly focalPoint: FocalPoint;
    readonly disablePointerEvents?: boolean;
    readonly reduceBrightness?: boolean;
    readonly keepOriginalAspectRatio?: boolean;
}

const StyledVideo = styled.video<StyledVideoWithFocalPointProps>`
    ${props => !props.keepOriginalAspectRatio && 'object-fit: cover'};
    ${props =>
        !props.keepOriginalAspectRatio &&
        `object-position: ${fpToObjPos(props.focalPoint)}`};
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    ${props => props.disablePointerEvents && 'pointer-events: none'};
    ${props => props.reduceBrightness && 'filter: brightness(80%)'};
    transition: ${getElectricTransitionAdvanced({name: 'filter'})};
`;
StyledVideo.displayName = 'StyledVideo';

interface StyledVideoWithFocalPointWrapperProps {
    readonly videoWrapperStyle?: VideoWrapperStyle;
    readonly keepOriginalAspectRatio?: boolean;
}

const StyledVideoContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const StyledVideoWrapper = styled.div.attrs(
    (props: StyledVideoWithFocalPointWrapperProps) => {
        const {videoWrapperStyle: ws, keepOriginalAspectRatio} = props;

        if (keepOriginalAspectRatio) {
            return {};
        }

        return !ws
            ? {}
            : {
                  style: {
                      width: `${ws.width}px`,
                      height: `${ws.height}px`,
                      marginLeft: ws.marginLeft
                          ? `${ws.marginLeft}px`
                          : undefined,
                      marginTop: ws.marginTop ? `${ws.marginTop}px` : undefined
                  }
              };
    }
)<StyledVideoWithFocalPointWrapperProps>`
    display: block;
    box-sizing: content-box;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
`;
StyledVideoWrapper.displayName = 'StyledVideoWrapper';

export class VideoWithFocalPoint extends React.PureComponent<
    VideoWithFocalPointProps,
    VideoWithFocalPointState
> {
    private readonly videoContainerRef: React.RefObject<
        HTMLDivElement
    > = React.createRef();

    // Following variables are used for preserving the state between different event renders.
    // They don't need to be part of the component state and cause re-render.
    // That's why they are created as instance variables and readonly check needs to be disabled.
    /* tslint:disable:readonly-keyword */
    private resizeTimeout: number | null = null;
    /* tslint:enable:readonly-keyword */

    public constructor(props: VideoWithFocalPointProps) {
        super(props);
        this.state = {};
    }

    private registerPolyfill(): void {
        this.setVideoElementObjectFitStyle();
        window.addEventListener('resize', this.resizeThrottler);
    }

    private readonly setVideoElementObjectFitStyle = (): void => {
        const {keepOriginalAspectRatio = false} = this.props;
        const wrapperElem = this.videoContainerRef.current;
        const videoElem = this.getVideoElement();
        if (
            !(wrapperElem instanceof HTMLDivElement) ||
            !videoElem ||
            keepOriginalAspectRatio
        ) {
            return;
        }
        const {focalPoint} = this.props;

        const videoWrapperStyle = calculateVideoWrapperStyle(
            getWrapperDimensions(wrapperElem),
            getVideoDimensions(videoElem),
            focalPoint
        );

        this.setState(state => ({...state, videoWrapperStyle}));
    };

    // it's recommended to throttle resize events since they can occur very often
    // see: https://developer.mozilla.org/en-US/docs/Web/Events/resize#Examples
    private readonly resizeThrottler = () => {
        // ignore resize events as long as an actualResizeHandler execution is in the queue
        if (!this.resizeTimeout) {
            this.resizeTimeout = window.setTimeout(() => {
                this.resizeTimeout = null;
                this.actualResizeHandler();

                // The actualResizeHandler will execute at a rate of 15fps
            }, 66);
        }
    };

    private readonly actualResizeHandler = () => {
        this.setVideoElementObjectFitStyle();
    };

    private readonly getVideoElement = (): HTMLVideoElement | null => {
        const video = this.props.innerRef && this.props.innerRef.current;

        return video || null;
    };

    public componentDidMount(): void {
        // For IE and EDGE we need workaround, because EDGE doesn't support object-fit for videos
        // and IE doesn't support object-fit at all but we DON'T support it
        // Images are fitted using background-image, videos are scaled and moved via negative margin
        if (this.videoContainerRef && isEDGE()) {
            this.registerPolyfill();
        }
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.resizeThrottler);
        if (this.resizeTimeout) {
            window.clearTimeout(this.resizeTimeout);
        }
    }

    public render(): JSX.Element {
        const {innerRef, ...forwardProps} = this.props;
        const {videoWrapperStyle} = this.state;

        return (
            <StyledVideoContainer ref={styledCompatRef(this.videoContainerRef)}>
                <StyledVideoWrapper videoWrapperStyle={videoWrapperStyle}>
                    <StyledVideo
                        {...forwardProps}
                        ref={styledCompatRef(innerRef)}
                    />
                </StyledVideoWrapper>
            </StyledVideoContainer>
        );
    }
}
