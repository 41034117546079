import * as React from 'react';
import {useRegistry} from '../../context';
import {ServerSectionVisibilityManager} from '../../context/content/ServerSectionVisibileManager';
import {isInBrowser} from '../../utils/browser/isInBrowser';

export const useEditableComponentVisible = (
    path?: string
): [boolean, (visible: boolean) => void] => {
    const [clientVisible, setClientVisible] = React.useState(true);
    const visibilityManager: ServerSectionVisibilityManager = useRegistry().getSingleton(
        'SectionVisibilityManager'
    );

    if (!path) {
        return [true, (_visible: boolean) => {}];
    }

    const setVisible = isInBrowser()
        ? setClientVisible
        : (localVisible: boolean) =>
              visibilityManager.setVisible(localVisible, path);

    const visible = isInBrowser()
        ? clientVisible
        : visibilityManager?.getVisible(path);
    return [visible, setVisible];
};
