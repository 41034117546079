import {
    NavigationFlyoutAdminV1,
    SharedNavigationFlyoutService,
    defineNavigationFlyoutService
} from '@volkswagen-onehub/navigation-flyout-service';
import {ServiceRegistration} from './ServiceRegistration';

export function createNavigationFlyoutService(
    flyoutAdmin: NavigationFlyoutAdminV1
): ServiceRegistration<SharedNavigationFlyoutService> {
    return {
        definition: defineNavigationFlyoutService({
            '1.0.0': {
                admin: flyoutAdmin
            }
        }),
        service: {
            typeName: 'NavigationFlyoutService',
            version: '^1.0.0'
        }
    };
}
