import {
    CTA,
    styled,
    Text,
    TokenTextAppearance,
    TextTag
} from '@volkswagen-onehub/components-core';
import {Close} from '@volkswagen-onehub/icons-core';
import {
    FocusLayerSizeV2,
    LayerHandleV2
} from '@volkswagen-onehub/layer-manager';
import React from 'react';
import {TechData} from '../../../../../generated/core';
import {
    useLayerManager,
    useLogger,
    useModelClient,
    useModelStore,
    useTrackingService
} from '../../../../context';
import {
    buttonFocusStateCSS,
    increasedIconTabArea,
    resetButtonStyles
} from '../../../../d6/components/helpers';
import {DataInfoLayer} from '../../../../d6/components/technical-data/DataInfoLayer';
import {useContextTrackingData} from '../../../../hooks/useContextTrackingData';
import {XfInfoLayerMain} from '../../../structure/main/XfInfoLayerMain';

const infoIcon = (
    <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M10,19.17C4.77,19.17,.83,15.23,.83,10S4.77,.83,10,.83s9.17,3.94,9.17,9.17-3.94,9.17-9.17,9.17Zm0-17c-4.47,0-7.83,3.37-7.83,7.83s3.29,7.83,7.83,7.83,7.83-3.29,7.83-7.83-3.37-7.83-7.83-7.83Z"
            fill="#001e50"
        />
        <path
            d="M10.21,5.41c.37,0,.64,.25,.64,.68s-.28,.68-.64,.68-.64-.25-.64-.68,.28-.68,.64-.68Z"
            fill="#001e50"
            fillRule="evenodd"
        />
        <polygon
            points="10.87 8.34 10.87 13.05 13.04 13.04 13.04 14.15 7.23 14.15 7.23 13.05 9.76 13.05 9.76 9.45 8.06 9.45 8.06 8.34 10.87 8.34"
            fill="#001e50"
            fillRule="evenodd"
        />
    </svg>
);

const StyledInfoLayerButton = styled.button`
    ${resetButtonStyles}
    ${buttonFocusStateCSS};
    ${increasedIconTabArea};
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${props => props.theme.size.static100};
    text-align: start;

    & svg {
        flex-shrink: 0;
    }

    &::before {
        border-radius: ${props => props.theme.size.static100};
    }

    &:focus {
        path,
        polygon {
            fill: ${props => props.theme.colors.button.secondary.hover};
        }
    }
`;

const layerOptions = {
    userCloseable: true,
    size: FocusLayerSizeV2.A
};

export const layerProps = (
    closeRef: React.MutableRefObject<LayerHandleV2<{}> | undefined>
) => ({
    close() {
        // eslint-disable-next-line no-unused-expressions
        closeRef.current?.close();
    }
});

export interface InfoButtonProps extends TechData {
    id: string;
    children: React.ReactNode;
    moreInfoLabel?: string;
    infoLabelButton?: JSX.Element;
    dataCount?: number;
    dataItemPosition?: number;
    infoErrorCloseLabel: string;
    infoErrorText: string;
}

export const InfoButton = (props: InfoButtonProps) => {
    const {
        id,
        label,
        infoLayerPath,
        moreInfoLabel,
        infoErrorCloseLabel,
        infoErrorText,
        children
    } = props;
    const closeRef = React.useRef<LayerHandleV2<{}>>();
    const focusRef = React.useRef<HTMLLIElement | null>(null);

    const trackingService = useTrackingService();
    const contextTrackingData = useContextTrackingData();
    const layerManager = useLayerManager();
    const logger = useLogger();
    const modelClient = useModelClient();
    const modelStore = useModelStore();

    const openFocusLayer = async () => {
        const path = infoLayerPath + '/jcr:content/root/main.model.json';
        try {
            const model = await modelClient.fetch(path);
            const insertedInfoLayerPath = modelStore.insertGlobalContent(
                `infoLayerPath_${id}`,
                model
            );
            // eslint-disable-next-line no-unused-expressions
            focusRef.current?.focus();
            closeRef.current = layerManager.openFocusLayer(
                state => {
                    return (
                        <XfInfoLayerMain
                            path={insertedInfoLayerPath}
                            close={state.close}
                        />
                    );
                },
                layerProps(closeRef),
                layerOptions
            );

            const linkName = `key: ${id} | label: ${label}`;
            trackingService.trackDataInfoButtonClick(infoLayerPath, linkName, {
                ...contextTrackingData,
                itemCount: props.dataCount,
                itemPosition: props.dataItemPosition
            });
        } catch (error) {
            logger.layer.warn("couldn't fetch model data: path=%s", path);

            // eslint-disable-next-line no-unused-expressions
            focusRef.current?.focus();
            closeRef.current = layerManager.openFocusLayer(
                state => {
                    return (
                        <DataInfoLayer
                            closeButton={
                                <CTA
                                    icon={<Close ariaHidden />}
                                    tag="button"
                                    key="close"
                                    onClick={state.close}
                                    emphasis="tertiary"
                                    aria-label={infoErrorCloseLabel}
                                />
                            }
                            heading={
                                <Text
                                    tag={TextTag.h3}
                                    appearance={TokenTextAppearance.headline100}
                                    bold
                                >
                                    {infoErrorText}
                                </Text>
                            }
                        />
                    );
                },
                layerProps(closeRef),
                layerOptions
            );
        }
    };

    //maybe we could ask components-core to make this exportable: createFontStylesForBreakpoints
    //so we dont need to add another span here
    return (
        <StyledInfoLayerButton
            id={label}
            aria-label={`${moreInfoLabel} ${label}`}
            onClick={event => {
                focusRef.current = (event.target as HTMLElement).closest(
                    'li'
                ) as HTMLLIElement;
                openFocusLayer();
            }}
        >
            <Text appearance={TokenTextAppearance.copy100} tag={TextTag.span}>
                {children}
            </Text>

            {infoIcon}
        </StyledInfoLayerButton>
    );
};
