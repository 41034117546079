import * as React from 'react';

import styled, {ThemeContext} from 'styled-components';
import {PropsWithDirection, horizontalScroll} from '../helpers';

import {INPAGE_BAR_HEIGHT} from './parts/bar-link';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import {smoothScrollToX} from '../../../utils/smoothScroll';
import {
    getNewPosition,
    getLinkDistance,
    getFocusedListItemIndex,
    focusItem,
    getItemURL,
    getNextIndex,
    shouldMoveBackwards
} from './helpers';
import {Fade} from '../../../utils/Fade';
import {useMatchMediaWithDefaultQueries} from '../../../context/mediaQueries/useMatchMedia';

const ANIMATION_DURATION = 300;
const ANIMATION_DISTANCE = `${INPAGE_BAR_HEIGHT * 2}px`;
const listId = 'InPageNavBarList';

const StyledPageTitleWrapper = styled.span`
    position: relative;
    display: grid;
    align-items: center;
    grid-column: 1/4;
    overflow: hidden;
    padding-left: ${props => props.theme.size.grid001};
    margin-right: calc(${props => props.theme.size.static520} * -1);
    margin-left: -1px;
    padding-right: ${props => props.theme.size.static200};
`;

const StyledInPageNavigationContainer = styled.div`
    display: flex;
    width: 100%;
    pointer-events: auto; // re-set due topbar unset

    @media (min-width: ${Breakpoints.b560}px) {
        // for tablet without title on the left
        display: grid;
        grid-template-columns: repeat(24, ${100 / 24}%);
    }
`;

const StyledWrap = styled.div<{pageNameLink: boolean}>`
    display: grid;
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
        position: absolute;
        top: 0;
        bottom: 1px; // to not overlap inpagenav bottom border
        content: '';
        width: ${props => props.theme.size.static400};
        pointer-events: none;
        z-index: 1;
    }

    &:before {
        left: 0;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }

    &:after {
        right: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }

    @media (min-width: ${Breakpoints.b560}px) {
        // for tablet without title on the left
        grid-column: 2/25;
        margin-left: calc(
            -1 * ${props => props.theme.size.dynamic0100} - ${props => props.theme.size.static100}
        );
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-column: 2/24;
    }

    ${props =>
        props.pageNameLink &&
        `@media (min-width: ${Breakpoints.b1280}px) {
            grid-column: 5/24;
            margin: 0;
        `}
`;

const StyledScrollWrap = styled.div`
    display: block;
    ${horizontalScroll}
`;
StyledScrollWrap.displayName = 'StyledScrollWrap';

const StyledList = styled.ul<{
    isInPageNavOverflowing: boolean;
}>`
    display: flex;
    width: 100%;
    gap: ${props => props.theme.size.dynamic0130};
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    margin: 0;
    padding: ${props => `0 ${props.theme.size.dynamic0100}`};
    list-style: none;
    height: 46px; // topbar secondary content wrap height

    @media (min-width: ${Breakpoints.b960}px) {
        height: 52px; // topbar secondary content wrap height
    }

    li {
        margin-left: ${props => props.theme.size.static100};
    }

    & li:last-of-type {
        padding-right: ${props => props.theme.size.static400};
    }
`;
StyledList.displayName = 'StyledList';

export interface InPageNavBarV2Props {
    readonly activeItemTitle?: string;
    readonly activeItemUrl?: string;
    readonly isOpen?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
    readonly pageNameLink?: JSX.Element | null;
}

export const InternalInPageNavBarV2 = (
    props: React.PropsWithChildren<InPageNavBarV2Props & PropsWithDirection>
): JSX.Element => {
    const mediaQueries = useMatchMediaWithDefaultQueries();
    const isDesktop = mediaQueries?.isDesktop;

    const {children, direction, pageNameLink, activeItemUrl} = props;

    const [isInPageNavOverflowing, setIsInPageNavOverflowing] = React.useState(
        true
    );

    const listRef: React.RefObject<HTMLDivElement> = React.useRef(null);
    const listPaddingRef: React.MutableRefObject<number> = React.useRef(0);
    const previousActiveItemRef: React.MutableRefObject<
        string | null
    > = React.useRef(null);
    const isScrollingRef: React.MutableRefObject<boolean> = React.useRef(false);
    const scrollingPositionRef: React.MutableRefObject<number> = React.useRef(
        0
    );

    const scrollToListItem = (
        listWrapper: HTMLDivElement,
        activeItem: string,
        options?: {duration: number}
    ): void => {
        if (isScrollingRef.current || activeItem === '#') {
            return;
        }

        isScrollingRef.current = true;
        const newPosition = getNewPosition({
            scrollableElement: listWrapper,
            activeItem,
            spacing: listPaddingRef.current,
            direction
        });

        const linkDistance = getLinkDistance({
            listWrapper,
            previousPosition: previousActiveItemRef.current,
            newPosition: activeItem
        });

        smoothScrollToX({
            distance: newPosition,
            scrollableElement: listWrapper,
            animationDuration: options?.duration || linkDistance * 300,
            onScrollEnd: () => {
                if (newPosition !== scrollingPositionRef.current) {
                    smoothScrollToX({
                        distance: scrollingPositionRef.current,
                        scrollableElement: listWrapper,
                        animationDuration: options?.duration || 150,
                        onScrollEnd: () => {
                            isScrollingRef.current = false;
                        }
                    });
                } else {
                    isScrollingRef.current = false;
                }
            }
        });

        previousActiveItemRef.current = activeItem;
        scrollingPositionRef.current = newPosition;
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        const target = e.target as HTMLElement;

        if (target) {
            const parentNode = target.parentNode as HTMLElement;
            if (target.tagName === 'A' || parentNode.tagName === 'A') {
                return;
            }

            const listWrapper = listRef.current;
            if (listWrapper) {
                // NOTE: without this workaroud, when clicked in-between the links,
                // user could then use arrows but whole scrollable container would be scrolled
                (listWrapper as HTMLElement).setAttribute('tabindex', '0');
                (listWrapper as HTMLElement).focus();
            }
        }
    };

    const focusElement = (element: HTMLAnchorElement) =>
        element && element.click();

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        const listWrapper = listRef.current;
        const listItems = listWrapper?.querySelectorAll('a');
        const listItemsLength = listItems?.length;

        if (!listWrapper || !listItems || !listItemsLength) {
            return;
        }

        if (
            [' ', 'ArrowLeft', 'ArrowRight', 'Tab'].every(
                keyName => keyName !== event.key
            )
        ) {
            return;
        }

        const focusedItemIdx = getFocusedListItemIndex(listItems);

        if (event.key === ' ' && focusedItemIdx >= 0) {
            focusElement(listItems[focusedItemIdx]);
            event.preventDefault();
            return;
        }

        const moveBack = shouldMoveBackwards({
            keyboardKey: event.key,
            direction,
            isShiftKey: event.shiftKey
        });

        const itemToFocus = getNextIndex({
            focusedItemIdx,
            listItemsLength,
            moveBack
        });
        const focusedItemUrl = getItemURL(listItems, itemToFocus);
        event.key !== 'Tab' && focusItem(listItems, itemToFocus);

        if (focusedItemUrl) {
            scrollToListItem(listWrapper, focusedItemUrl, {
                duration: 0
            });
        }
    };

    React.useEffect(() => {
        const listWrapper = listRef.current;
        if (listWrapper && activeItemUrl) {
            scrollToListItem(listWrapper, activeItemUrl);
        }

        // should not include scrollToListItem, otherwise it would run on every re-render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItemUrl]);

    React.useEffect(() => {
        let isMounted = true;
        const listWrapper = listRef.current;

        if (!listWrapper) {
            return;
        }
        const setListPadding = () => {
            const computedListStyles = window.getComputedStyle(
                listWrapper.children[0]
            );
            listPaddingRef.current =
                parseInt(computedListStyles.paddingRight) +
                parseInt(computedListStyles.paddingLeft);
        };

        const getInPageNavOverflowing = () => {
            if (isMounted) {
                setIsInPageNavOverflowing(
                    listWrapper.scrollWidth > listWrapper.clientWidth
                );
            }
        };

        getInPageNavOverflowing();
        setListPadding();

        window.addEventListener('resize', () => {
            getInPageNavOverflowing();
            setListPadding();
        });

        return function cleanUp() {
            isMounted = false;
            window.removeEventListener('resize', () => {
                getInPageNavOverflowing();
                setListPadding();
            });
        };
    }, []);

    const renderPageTitle = () =>
        isDesktop &&
        pageNameLink && (
            <StyledPageTitleWrapper data-cy="inPageNavPageName">
                {pageNameLink}
            </StyledPageTitleWrapper>
        );

    return (
        <Fade
            duration={ANIMATION_DURATION}
            distance={ANIMATION_DISTANCE}
            directionFrom={'bottom'}
        >
            <StyledInPageNavigationContainer>
                {renderPageTitle()}

                <StyledWrap pageNameLink={Boolean(pageNameLink)}>
                    <StyledScrollWrap
                        ref={listRef}
                        onKeyDown={handleKeyDown}
                        onClick={handleClick}
                    >
                        <StyledList
                            id={listId}
                            isInPageNavOverflowing={isInPageNavOverflowing}
                        >
                            {children}
                        </StyledList>
                    </StyledScrollWrap>
                </StyledWrap>
            </StyledInPageNavigationContainer>
        </Fade>
    );
};

export function InPageNavBarV2(
    props: React.PropsWithChildren<InPageNavBarV2Props>
): JSX.Element {
    return (
        <ThemeContext.Consumer>
            {({direction}) => (
                <InternalInPageNavBarV2 direction={direction} {...props}>
                    {props.children}
                </InternalInPageNavBarV2>
            )}
        </ThemeContext.Consumer>
    );
}
