import {StyledContentWrapper, StyledIconWrapper} from './styles';
import {
    Text,
    TokenTextAppearance,
    TokenTextColor,
    TextTag
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {NBAContentV2Props} from './types';

export const NextBestActionPanelButtonContent = (props: NBAContentV2Props) => {
    const {action} = props;

    return (
        <StyledContentWrapper>
            {action.icon && (
                <StyledIconWrapper>{action.icon}</StyledIconWrapper>
            )}
            <Text
                appearance={TokenTextAppearance.label200}
                staticSize
                color={TokenTextColor.inherit}
                tag={TextTag.span}
            >
                {action.content}
            </Text>
        </StyledContentWrapper>
    );
};
