import * as React from 'react';
import styled from 'styled-components';

export interface DecorativeImageProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {
    readonly className?: string;
    readonly reduceBrightness?: boolean;
}

export interface ImageProps extends DecorativeImageProps {
    readonly alt: string;
    readonly srcPlaceholder?: string;
}

export const Image = styled.img<ImageProps>`
    display: block;
    width: 100%;
    ${props => props.reduceBrightness && 'filter: brightness(80%)'};
`;

Image.displayName = 'Image';

export const DecorativeImage = React.forwardRef<
    HTMLImageElement,
    DecorativeImageProps
>((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {alt, ...others} = props;

    // `aria-hidden`is required to hide it from voiceover
    // ref as any due to bug in typings of styled-components
    return <Image alt="" aria-hidden {...others} ref={ref as any} />;
});

DecorativeImage.displayName = 'DecorativeImage';

const TRANSPARENT_GIF =
    'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

export const LazyImage = React.forwardRef<HTMLImageElement, ImageProps>(
    (props, ref) => {
        const {
            src,
            srcPlaceholder,
            srcSet,
            sizes,
            className,
            alt,
            reduceBrightness,
            ...others
        } = props;

        return (
            <>
                <noscript>
                    <Image
                        alt={alt}
                        className={className}
                        sizes={sizes}
                        src={src}
                        srcSet={srcSet}
                        reduceBrightness={reduceBrightness}
                        {...others}
                    />
                </noscript>
                <Image
                    // The class `lazyload` is requir
                    // máme za ed for our 3rd party lazyloading lib to work
                    className={`lazyload ${className || ''}`}
                    // If an alt attribute is used, a broken image symbol would be displayed
                    // if src would be left empty. Therefore we render a transparent 1px gif
                    // until lazyload has replaced the src/srcSet.
                    src={srcPlaceholder || TRANSPARENT_GIF}
                    data-src={src}
                    data-srcset={srcSet}
                    data-sizes={sizes}
                    alt={alt}
                    ref={ref as any} // bug in styled-components typings
                    reduceBrightness={reduceBrightness}
                    {...others}
                />
            </>
        );
    }
);

LazyImage.displayName = 'LazyImage';

export const DecorativeLazyImage = React.forwardRef<
    HTMLImageElement,
    DecorativeImageProps
>((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {alt, reduceBrightness, ...others} = props;

    return (
        <LazyImage
            reduceBrightness={reduceBrightness}
            alt=""
            {...others}
            ref={ref}
        />
    );
});

DecorativeLazyImage.displayName = 'DecorativeLazyImage';
