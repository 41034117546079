import {inject, singleton} from '../../infrastructure/di/annotations';
import {
    IAuthServiceProviderV2,
    IAuthServiceV2
} from '@volkswagen-onehub/authservice';
import {getGlobal} from '../../utils/getGlobal';
import {GfaAuthServiceEventV2} from '@volkswagen-onehub/gfa-auth-service';
import {
    REMEMBER_ME_COOKIE_NAME,
    SESSION_TIMEOUT_COOKIE_NAME
} from './ClientLoginStore';
import {SpaGlobalConfig} from '../../../generated/core';
import {isRememberMeEnabled} from '../../utils/vwId/isRememberMeEnabled';
import {isSessionTimeoutEnabled} from '../../utils/vwId/isSessionTimeoutEnabled';
import {removeCookie} from '../../utils/removeCookie';

const DEFAULT_USER_DATA = {
    given_name: 'Max',
    family_name: 'Mustermann',
    email: 'mock@email.com',
    sub: 'the-user-id'
};

@singleton('AuthServiceProvider', {devMode: true})
export class AuthServiceProviderMock implements IAuthServiceProviderV2 {
    @inject() private authService!: AuthService;

    register(): IAuthServiceV2 {
        return this.authService;
    }
}

@singleton('AuthService', {devMode: true})
export class AuthService implements IAuthServiceV2 {
    @inject() private spaGlobalConfig!: SpaGlobalConfig;

    protected saveStateListeners: (() => void)[] = [];
    protected logoutListeners: (() => void)[] = [];

    public isAuthenticated(): Promise<boolean> {
        return Promise.resolve(
            !!window.sessionStorage.getItem('logged in mock')
        );
    }

    public login(_prompt?: string, _redirectUrl?: string): Promise<void> {
        window.sessionStorage.setItem('logged in mock', 'true');
        this.createSessionTimeoutCookie();
        setTimeout(() => window.location.reload(), 100);
        this.saveStateListeners.forEach(l => l());
        return Promise.resolve();
    }

    public logout(_redirectUrl: string): Promise<void> {
        window.sessionStorage.removeItem('logged in mock');
        setTimeout(() => window.location.reload(), 100);
        this.logoutListeners.forEach(l => l());
        removeCookie(SESSION_TIMEOUT_COOKIE_NAME);
        removeCookie(REMEMBER_ME_COOKIE_NAME);
        return Promise.resolve();
    }

    public subscribe(event: GfaAuthServiceEventV2, cb: () => Promise<void>) {
        if (event === 'saveState') {
            this.saveStateListeners.push(cb);
        } else if (event === 'logout') {
            this.logoutListeners.push(cb);
        }
    }

    public async fetch(resource: string): Promise<Response> {
        if (resource.endsWith('user')) {
            const user =
                getGlobal().config.getJson('userData') || DEFAULT_USER_DATA;
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({json: () => user} as any);
                }, 1000);
            });
        }

        return Promise.resolve({} as any);
    }

    public async continueSession(rememberMe?: boolean): Promise<boolean> {
        this.createSessionTimeoutCookie(rememberMe);
        if (rememberMe) {
            document.cookie = REMEMBER_ME_COOKIE_NAME + '=true';
        }
        return Promise.resolve(true);
    }

    protected createSessionTimeoutCookie(rememberMe?: boolean) {
        const loginModel = this.spaGlobalConfig.loginModel;
        if (isSessionTimeoutEnabled(loginModel)) {
            const timeout =
                new Date().getTime() +
                Number(
                    isRememberMeEnabled(loginModel) && rememberMe
                        ? loginModel.rememberMeSessionTimeout
                        : loginModel.sessionTimeout
                ) *
                    1000;
            document.cookie = SESSION_TIMEOUT_COOKIE_NAME + '=' + timeout;
        }
    }

    delete(): Promise<Response> {
        return Promise.resolve({} as any);
    }

    get(): Promise<Response> {
        return Promise.resolve({} as any);
    }

    patch(): Promise<Response> {
        return Promise.resolve({} as any);
    }

    post(): Promise<Response> {
        return Promise.resolve({} as any);
    }

    put(): Promise<Response> {
        return Promise.resolve({} as any);
    }
}
