export function reportPersonalizationException(
    errorId: string,
    errorMessage: string
): void {
    // this is the way that vw bootstrapper library works, right now, there is not any proper function call
    // @ts-ignore
    const vwdata = window.vwdata;
    if (vwdata) {
        vwdata.reportException({name: errorId, message: errorMessage});
    }
}
