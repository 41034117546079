import {NextBestActionModel} from '../../../../generated/core';
import {PersonalizedActionReporting} from '../../../context/tracking/TrackingService';
import {PersonalizedAction} from './PersonalizedNbab';

const MAX_ITEMS = 5;

export interface MergeNextBestActionModel extends NextBestActionModel {
    priority: number;
    callback?: () => void; // linkType callback
    personalizedReporting?: PersonalizedActionReporting;
}

export const mergePersonalizedActions = (
    cmsActions: MergeNextBestActionModel[],
    actions: PersonalizedAction[]
): MergeNextBestActionModel[] => {
    const personalizedActions = convertPersonalizedActions(actions);
    const mergedActions = ([] as MergeNextBestActionModel[]).concat(
        personalizedActions,
        cmsActions
    );
    mergedActions.sort((a1, a2) => {
        const compare = a2.priority - a1.priority;
        if (compare === 0) {
            return cmsActions.includes(a1) ? 1 : -1;
        }
        return compare;
    });
    const mergedActionsWithoutDuplicateIntents = mergedActions.reduce(
        (actionArray, curr) => {
            const intentFound = actionArray.find(a => a.intent === curr.intent);
            if (!intentFound || curr.intent === 'custom') {
                actionArray.push(curr);
            }
            return actionArray;
        },
        [] as MergeNextBestActionModel[]
    );
    if (mergedActionsWithoutDuplicateIntents.length > MAX_ITEMS) {
        return mergedActionsWithoutDuplicateIntents.slice(0, MAX_ITEMS);
    }

    return mergedActionsWithoutDuplicateIntents;
};

function convertPersonalizedActions(actions: PersonalizedAction[]) {
    // eslint-disable-next-line array-callback-return
    return actions.map(a => {
        switch (a.actionType) {
            case 'callback':
                return {
                    ...commonProps(a),
                    path: '',
                    linkType: 'callback',
                    callback: a.callback
                } as MergeNextBestActionModel;
            case 'internal':
                return {
                    ...commonProps(a),
                    linkType: 'internal',
                    path: a.path
                } as MergeNextBestActionModel;
            case 'external':
                return {
                    ...commonProps(a),
                    linkType: 'external',
                    path: a.url,
                    externalLinkTarget: a.target
                } as MergeNextBestActionModel;
        }
    });
}
const commonProps = (a: PersonalizedAction) => {
    return {
        title: a.title,
        icon: a.icon,
        intent: a.intent,
        priority: a.priority,
        label: a.label,
        personalizedReporting: a.reporting,
        enabled: true
    };
};
