import {
    Breakpoints,
    MovingFrame,
    styled,
    ThemeProvider
} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {FeatureAppNotifications} from '../../../modules/structure/FeatureAppNotifications';
import {isEDGE} from '../helpers';
import {
    ANIMATION_DURATION,
    CSS_VAR_TOP_BAR_HEIGHT,
    getLogoLayerTopPosition,
    setTopBarHeight
} from '../navigation-top-bar-one-hub-helpers';

export interface StyledNavigationTopBarProps {
    readonly hasTransparentBackground?: boolean;
    readonly hasCustomHeight?: boolean;
    readonly isEDGE?: boolean;
    readonly isExpanded: boolean;
    readonly isStatic?: boolean;
    readonly shouldRenderInpageNav?: boolean;
    readonly isInPageNavigationVisible?: boolean;
    readonly showInpageNavigationAndStaticTopbar?: boolean;
}

export interface StyledLogoLayerProps {
    isEDGE?: boolean;
    isExpanded: boolean;
}

export interface StyledLeftItemsWrapperProps {
    readonly isHidden?: boolean;
}

export interface StyledRightItemsWrapperProps {
    readonly isInPageNavigationVisible?: boolean;
    readonly isStatic?: boolean;
}

export interface NavigationTopBarProps {
    readonly menuButton: JSX.Element;
    readonly logo: JSX.Element;
    readonly claim?: JSX.Element;
    readonly isExpanded: boolean;
    readonly visibleRightItems?: React.ReactNode;
    readonly hiddenRightItems?: React.ReactNode;
    readonly hasTransparentBackground?: boolean;
    readonly hasCustomContent?: boolean;
    readonly pageNameLink?: JSX.Element | null;
    readonly isInPageNavigationVisible?: boolean;
    readonly isStatic?: boolean;
    readonly showInpageNavigationAndStaticTopbar?: boolean;
    readonly shouldRenderInpageNav?: boolean;
    readonly inPageNav?: JSX.Element;
}

export const topBarHeightValue = `var(${CSS_VAR_TOP_BAR_HEIGHT})`;

const StyledNavigationTopBar = styled.header<StyledNavigationTopBarProps>`
    width: 100%;
    ${props => setTopBarHeight(CSS_VAR_TOP_BAR_HEIGHT, props.isExpanded)};

    height: ${props => (props.hasCustomHeight ? 'auto' : topBarHeightValue)};
    transform: translateZ(0);

    ${props =>
        !props.isEDGE && `transition: height ${ANIMATION_DURATION} ease-in`};

    color: ${props => props.theme.colors.content.primary};
    background-color: ${props =>
        props.hasTransparentBackground
            ? 'transparent'
            : props.theme.colors.background.primary};
`;
StyledNavigationTopBar.displayName = 'StyledNavigationTopBar';

const StyledNavigationTopBarPrimaryContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(24, ${100 / 24}%);
    pointer-events: auto;

    grid-template-rows: ${props => props.theme.size.static200} 1fr ${props =>
            props.theme.size.static200};
`;
StyledNavigationTopBarPrimaryContent.displayName =
    'StyledNavigationTopBarPrimaryContent';

export const StyledLogoLayer = styled.div<StyledLogoLayerProps>`
    width: 100%;
    display: flex;
    align-items: center;
    ${props =>
        !props.isEDGE && `transition: transform ${ANIMATION_DURATION} ease-in`};

    position: absolute;
    top: 0;

    ${props =>
        [
            Breakpoints.default,
            Breakpoints.b560,
            Breakpoints.b960,
            Breakpoints.b1920
        ]
            .map(
                breakpoint => `
					@media (min-width: ${breakpoint}px) {
						transform: translateY(
							${getLogoLayerTopPosition(props.theme, breakpoint, props.isExpanded)}
						);
					}
				`
            )
            .join('\n')}
`;
StyledLogoLayer.displayName = 'StyledLogoLayer';

export const StyledStaticLogoLayer = styled.div<{hasClaim?: boolean}>`
    display: flex;
    grid-row: 2/3;
    grid-column: 10/16;
    justify-content: center;

    svg {
        fill: ${props => props.theme.colors.button.primary.default};
        width: ${props => props.theme.size.static400};
        height: ${props => props.theme.size.static400};
        margin-inline-end: ${props =>
            props.hasClaim ? props.theme.size.static150 : 'initial'};
    }
`;
StyledStaticLogoLayer.displayName = 'StyledStaticLogoLayer';

const StyledPageNameLink = styled.div`
    display: none;
    padding-inline-start: ${props => props.theme.size.dynamic0130};

    @media (min-width: ${Breakpoints.b960}px) {
        display: initial;
    }

    & span {
        @media (min-width: ${Breakpoints.b960}px) {
            font-size: ${props => props.theme.fonts.copy[200].fontSize};
        }
    }
`;
StyledPageNameLink.displayName = 'StyledPageNameLink';

export const StyledLeftItemsWrapper = styled.div<StyledLeftItemsWrapperProps>`
    ${props => props.isHidden && 'visibility: hidden'};
    grid-row: 2/3;
    grid-column: 2/6;
    display: flex;
`;
StyledLeftItemsWrapper.displayName = 'StyledLeftItemsWrapper';

export const StyledStaticLogoWrapper = styled.div`
    display: flex;
    align-items: center;
    height: ${props => props.theme.size.static400};
`;
StyledStaticLogoWrapper.displayName = 'StyledStaticLogoWrapper';

// the nav content is not centered, it has more top- than bottom-padding, except if the static version of the topbar is active.
export const StyledRightItemsWrapper = styled.div<StyledRightItemsWrapperProps>`
    grid-row: 1/4;
    grid-column: ${props =>
        props.isInPageNavigationVisible ? '5/25' : '16/24'};
    display: flex;
    gap: ${props => props.theme.size.static300};
    justify-content: ${props =>
        props.isInPageNavigationVisible ? 'flex-start' : 'flex-end'};
    align-items: center;

    @media (min-width: ${Breakpoints.b560}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '4/25' : '16/24'};
        padding-left: ${props =>
            props.isInPageNavigationVisible ? '0' : props.theme.size.static100};
    }

    @media (min-width: ${Breakpoints.b960}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '4/25' : '18/24'};
        padding-left: ${props =>
            props.isInPageNavigationVisible ? '0' : props.theme.size.static250};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        grid-column: ${props =>
            props.isInPageNavigationVisible ? '6/25' : '18/24'};
    }
`;

export const StyledScreenReaderOnly = styled.div`
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
`;
StyledScreenReaderOnly.displayName = 'StyledScreenReaderOnly';

export function NavigationTopBar(
    props: NavigationTopBarProps
): React.ReactElement {
    const {
        isExpanded,
        visibleRightItems,
        hiddenRightItems,
        menuButton,
        hasTransparentBackground,
        logo,
        claim,
        hasCustomContent,
        pageNameLink,
        isInPageNavigationVisible
    } = props;

    const mHasTransparentBackground = Boolean(hasTransparentBackground);
    const topBarTheme = mHasTransparentBackground ? 'inverted' : 'main';

    // NOTE: this check is used to omit "transition" for EDGE as it breaks animations due combination with calc()
    const isEdge = isEDGE();

    return (
        <ThemeProvider theme={topBarTheme}>
            <StyledNavigationTopBar
                hasCustomHeight={hasCustomContent}
                isExpanded={isExpanded}
                hasTransparentBackground={mHasTransparentBackground}
                isEDGE={isEdge}
                isInPageNavigationVisible={isInPageNavigationVisible}
                role="banner"
            >
                <StyledNavigationTopBarPrimaryContent>
                    <StyledLeftItemsWrapper isHidden={hasCustomContent}>
                        {menuButton}
                        {pageNameLink && (
                            <StyledPageNameLink>
                                {pageNameLink}
                            </StyledPageNameLink>
                        )}
                    </StyledLeftItemsWrapper>
                    <StyledRightItemsWrapper
                        isInPageNavigationVisible={isInPageNavigationVisible}
                    >
                        {visibleRightItems}
                    </StyledRightItemsWrapper>
                    <StyledScreenReaderOnly>
                        {hiddenRightItems}
                    </StyledScreenReaderOnly>
                </StyledNavigationTopBarPrimaryContent>
                <StyledLogoLayer isExpanded={isExpanded} isEDGE={isEdge}>
                    <MovingFrame logo={logo} claim={claim} />
                </StyledLogoLayer>
                <FeatureAppNotifications />
            </StyledNavigationTopBar>
        </ThemeProvider>
    );
}
