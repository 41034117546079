import * as React from 'react';

import {CTA, CTAProps} from '@volkswagen-onehub/components-core';
import {C} from '../../registries/compatibilty';

import {getMappedIcon} from '../../utils/getMappedIcon';
import {AlertBox, AlertBoxSeverity} from '../AlertBox';
import {BaseCmsLinkProps, useCmsLink} from './CmsLink';
import {NO_BUTTON_ELEMENT_HREF} from './constants';
import {Emphasis} from './types';

export interface CmsActionButtonLinkProps extends BaseCmsLinkProps {
    readonly icon?: string;
}

export function CmsActionButtonLink(
    props: React.PropsWithChildren<CmsActionButtonLinkProps>
): JSX.Element | null {
    const {children, icon, isLayerLink} = props;

    const buttonIcon = icon
        ? getMappedIcon(icon, 'small', {ariaHidden: true})
        : undefined;
    const ref = React.useRef(null);
    const [onCmsLinkClick, href] = useCmsLink(ref, props);

    const alertBox = !href && (
        <AlertBox severity={AlertBoxSeverity.WARNING}>
            <span>{NO_BUTTON_ELEMENT_HREF}</span>
        </AlertBox>
    );

    const ctaProps = {
        emphasis: 'secondary' as Emphasis,
        icon: buttonIcon,
        onClick: onCmsLinkClick,
        ref
    };

    const linkProps: CTAProps = {
        tag: 'a',
        href
    };

    const buttonProps: CTAProps = {
        tag: 'button'
    };

    const cta = (
        <CTA {...ctaProps} {...(isLayerLink ? buttonProps : linkProps)}>
            {children}
        </CTA>
    );

    return C.isInEditor() ? (
        <>
            {alertBox}
            {cta}
        </>
    ) : (
        cta
    );
}
