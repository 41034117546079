import * as React from 'react';
import styled from 'styled-components';

import {VideoPlayerV2} from '../../../../d6/components/video-player-v2';

import {MediaAspectRatio} from '../../../../../generated/api';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {aspectRatioMap} from '../../../../utils/aspectRatioMap';
import {noop} from '../../../../utils/noop';
import {useVideoAspectRatio} from '../videoElement/hooks/useVideoAspectRatio';
import {useVideoStateChangeHandler} from '../videoElement/hooks/useVideoStateChangeHandler';
import {useVideoStateManagement} from '../videoElement/hooks/useVideoStateManagement';
import {useVideoTracking} from '../videoElement/hooks/useVideoTracking';
import {AnimationElementProps} from './AnimationElement';

const StyledAnimationElement = styled.div`
    overflow: hidden;
    position: relative;
    height: 100%;
`;

export function AnimationElementPublish(
    props: AnimationElementProps
): JSX.Element | null {
    const {
        altText: title,
        contentName,
        coverImage,
        videoImageSrc,
        disclaimers,
        fileReference,
        originalCqPath,
        playsInLoop,
        showTimeline,
        trackAnimationStart,
        matchParent,
        playButtonTitle,
        pauseButtonTitle,
        videoRenditions,
        transcriptFileReference,
        downloadTranscriptButtonTitle,
        responsiveMediaConfig,
        actsAsTeaser,
        countdownState,
        onCoverImageLoad
    } = props;
    const aspectRatio = useVideoAspectRatio(
        matchParent,
        props.aspectRatio
    ) as MediaAspectRatio;
    const videoTracking = useVideoTracking({
        fileReference,
        title,
        contentName,
        originalCqPath,
        countdownState
    });

    const {onPlay, onPause, onAutoPause, onVideoStateChange} = videoTracking;

    const {
        videoContainerRef,
        videoPlayerProxy,
        videoPlayerRef
    } = useVideoStateManagement({
        autoPlay: true,
        autoPlayDelayed: false,
        autoPause: true,
        trackingCallbacks: {pause: onAutoPause}
    });

    const onStateChange = useVideoStateChangeHandler(
        trackAnimationStart ? onVideoStateChange : noop
    );

    return (
        <CyAttributeAppender name="animationElement">
            <StyledAnimationElement ref={videoContainerRef}>
                <VideoPlayerV2
                    aspectRatio={
                        aspectRatioMap[aspectRatio] || 'matchParentHeight'
                    }
                    onPauseClick={onPause}
                    onPlayClick={onPlay}
                    disclaimer={disclaimers}
                    onStateChanged={onStateChange}
                    ref={videoPlayerRef}
                    showTimeline={showTimeline}
                    videoComponentFactoryProps={{
                        coverImageModel: {
                            ...coverImage,
                            src: coverImage?.src || videoImageSrc,
                            focalPoint: 'CENTER_CENTER',
                            aspectRatio
                        },
                        muted: true,
                        playsInLoop,
                        aspectRatio,
                        title,
                        videoContainerRef,
                        videoPlayerProxy,
                        videoRenditions,
                        responsiveMediaConfig,
                        onCoverImageLoad
                    }}
                    playButtonTitle={playButtonTitle}
                    pauseButtonTitle={pauseButtonTitle}
                    transcriptFileReference={transcriptFileReference}
                    downloadTranscriptButtonTitle={
                        downloadTranscriptButtonTitle
                    }
                    actsAsTeaser={actsAsTeaser}
                />
            </StyledAnimationElement>
        </CyAttributeAppender>
    );
}
