import {SpaAsyncConfig} from '../../../generated/core';

export const SECONDARY_CONSUMER_SERVICE = 'secondary-consumer-service';

/**
 * if the current page is a mydealer page, then return the "secondary-consumer-service"
 * @param spaAsyncConfig
 * @param serverPath
 * @returns
 */
export const getSecondaryConsumerForMydealer = (
    spaAsyncConfig: SpaAsyncConfig,
    serverPath?: string
) => {
    const basePath = spaAsyncConfig.mydealerServiceOptions?.dealerBasePath;
    const currentPath = serverPath || window.location.pathname;
    if (basePath && currentPath.startsWith(basePath)) {
        return SECONDARY_CONSUMER_SERVICE;
    }
    return undefined;
};
