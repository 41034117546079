import * as React from 'react';
import {
    Breakpoints,
    Container,
    ContainerPadding,
    Layout,
    LayoutRowGap,
    ContainerWrap
} from '@volkswagen-onehub/components-core';

import {TwoColumnsSectionModel} from '../../../../generated/core';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {SectionDisclaimerContainer} from '../../../components/disclaimers/SectionDisclaimerContainer';
import {MediaContext} from '../../../context/media/MediaContext';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {Parsys, ParsysType} from '../../structure/Parsys';
import {SectionWrapper} from './SectionWrapper';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';

// the layout type
const mappedLayout = {
    SMALL_MEDIUM: {
        [Breakpoints.default]: [
            {name: 'a', columns: 24},
            {name: 'b', columns: 24}
        ],
        [Breakpoints.b960]: [
            {name: 'a', columns: 8},
            {name: 'b', columns: 16}
        ]
    },
    MEDIUM_SMALL: {
        [Breakpoints.default]: [
            {name: 'a', columns: 24},
            {name: 'b', columns: 24}
        ],
        [Breakpoints.b960]: [
            {name: 'a', columns: 16},
            {name: 'b', columns: 8}
        ]
    },
    SMALL_SMALL: {
        [Breakpoints.default]: [
            {name: 'a', columns: 24},
            {name: 'b', columns: 24}
        ],
        [Breakpoints.b960]: [
            {name: 'a', columns: 12},
            {name: 'b', columns: 12}
        ]
    }
};

// the sizes attribute for media components in the left column
const mediaSizesLeft = {
    SMALL_MEDIUM: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 25vw, 90vw`,
    MEDIUM_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 50vw, 90vw`,
    SMALL_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 38vw, 90vw`
};

// the sizes attribute for media components in the right column
const mediaSizesRight = {
    SMALL_MEDIUM: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 50vw, 90vw`,
    MEDIUM_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 25vw, 90vw`,
    SMALL_SMALL: `(min-width: ${Breakpoints.b560}px) 50vw, (min-width: ${Breakpoints.b960}px) 38vw, 90vw`
};

const CUSTOM_PADDING_2_6_0 = {
    [Breakpoints.default]: ContainerPadding.grid002,
    [Breakpoints.b560]: ContainerPadding.grid006,
    [Breakpoints.b960]: ContainerPadding.static0
};

const CUSTOM_PADDING_2_6_1 = {
    [Breakpoints.default]: ContainerPadding.grid002,
    [Breakpoints.b560]: ContainerPadding.grid006,
    [Breakpoints.b960]: ContainerPadding.grid001
};

const CUSTOM_PADDING_2_6_2 = {
    [Breakpoints.default]: ContainerPadding.grid002,
    [Breakpoints.b560]: ContainerPadding.grid006,
    [Breakpoints.b960]: ContainerPadding.grid002
};

// the padding for the left column container
const containerPaddingLeft = {
    SMALL_MEDIUM: {
        left: CUSTOM_PADDING_2_6_2,
        right: CUSTOM_PADDING_2_6_0
    },
    MEDIUM_SMALL: {
        left: CUSTOM_PADDING_2_6_2,
        right: CUSTOM_PADDING_2_6_2
    },
    SMALL_SMALL: {
        left: CUSTOM_PADDING_2_6_2,
        right: CUSTOM_PADDING_2_6_1
    }
};

// the padding for the right column container
const containerPaddingRight = {
    SMALL_MEDIUM: {
        left: CUSTOM_PADDING_2_6_2,
        right: CUSTOM_PADDING_2_6_2
    },
    MEDIUM_SMALL: {
        left: CUSTOM_PADDING_2_6_0,
        right: CUSTOM_PADDING_2_6_2
    },
    SMALL_SMALL: {
        left: CUSTOM_PADDING_2_6_1,
        right: CUSTOM_PADDING_2_6_2
    }
};

/**
 * S102 Two Column Section
 */
function InternalTwoColumnsSection(props: TwoColumnsSectionModel): JSX.Element {
    const {
        anchorId,
        mboxId,
        tagTypeBroken,
        contentLabels,
        contentName,
        layout,
        contentId: sectionId
    } = props;

    return (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S102 Two Columns Section"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            {/* this is a workaround, id should be a property of <Layout> */}
            <SectionWrapper
                mboxId={mboxId}
                anchorId={anchorId}
                sectionId={sectionId}
                contentLabels={contentLabels}
                contentName={contentName}
            >
                <GeneralDisclaimerProvider displayTypes={['T3_SECTION_BASED']}>
                    <Layout
                        allowOverflowingContent
                        appearance={mappedLayout[layout]}
                        rowGap={LayoutRowGap.dynamic0250}
                    >
                        <Container
                            padding={{
                                inlineStart: containerPaddingLeft[layout].left,
                                inlineEnd: containerPaddingLeft[layout].right
                            }}
                            stretchContent
                            wrap={ContainerWrap.always}
                        >
                            <CyAttributeAppender name="twoColumnsSection1">
                                <MediaContext.Provider
                                    value={{
                                        sizes: mediaSizesLeft[layout]
                                    }}
                                >
                                    <Parsys
                                        path="twoColumnsSectionParsys1"
                                        parsysType={ParsysType.ITEM_PARSYS}
                                    />
                                </MediaContext.Provider>
                            </CyAttributeAppender>
                        </Container>
                        <Container
                            padding={{
                                inlineStart: containerPaddingRight[layout].left,
                                inlineEnd: containerPaddingRight[layout].right
                            }}
                            stretchContent
                            wrap={ContainerWrap.always}
                        >
                            <CyAttributeAppender name="twoColumnsSection2">
                                <MediaContext.Provider
                                    value={{
                                        sizes: mediaSizesRight[layout]
                                    }}
                                >
                                    <Parsys
                                        path="twoColumnsSectionParsys2"
                                        parsysType={ParsysType.ITEM_PARSYS}
                                    />
                                </MediaContext.Provider>
                            </CyAttributeAppender>
                        </Container>
                    </Layout>
                    <SectionDisclaimerContainer
                        displayTypes={['T3_SECTION_BASED']}
                    />
                </GeneralDisclaimerProvider>
            </SectionWrapper>
        </AuthoringWrapper>
    );
}

export const TwoColumnsSection = MapTo(
    'vwa-ngw18/components/editorial/sections/twoColumnsSection',
    InternalTwoColumnsSection
);
