import * as React from 'react';
import {styled} from '@volkswagen-onehub/components-core';
import {
    FirstLevelATeaserElement,
    FirstLevelBTeaserElement,
    SmallTeaserElement,
    TeaserElement
} from '../../../../d6/components/teaser-element';

import {
    EditorialTeaserElementModel,
    LinkWindowTarget,
    TeaserDisplayType
} from '../../../../../generated/core';
import {FlyoutTeaserElement} from '../../../../components/teaser-element/FlyoutTeaserElement';
import {
    TeaserElementCategory,
    TeaserMainLink
} from '../../../../components/teaser-element/helpers';
import {useTrackingService} from '../../../../context';
import {InjectedPersonalizedContext} from '../../../../context/personalization/PersonalizationContext';
import {ContextTrackingData} from '../../../../context/tracking/TrackingService';
import {TrackingActionOverride} from '../../../../infrastructure/TrackableLinkProps';
import {MapTo} from '../../../../infrastructure/compatibility/MapTo';
import {C} from '../../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../../test/CyAttributeAppender';
import {elseUndefined} from '../../../../utils/elseUndefined';
import {processContentId} from '../../../../utils/processContentId';
import {isInXfStaticEditMode} from '../../../../utils/xfUtils';
import {
    SmallTeaserElementMediaLink,
    TeaserElementAdditionalLink,
    TeaserElementAuthoringWrapper,
    TeaserElementExperienceFragmentView,
    TeaserElementMediaLink,
    teaserElementAbstract,
    SmallTeaserElementHeading,
    TeaserElementHeading
} from './helpers';
import {RenderContext} from '../../../../context/RenderContext';

const EMPTY_LABEL = 'Editorial Teaser Element';

const StyledTeaserContentWrapper = styled.div``;

const StyledAbstractElement = styled.div`
    margin-top: ${({theme}) => theme.size.dynamic0100};
`;

const StyledAdditionalLinkWrapper = styled.div`
    padding-top: ${({theme}) => theme.size.static100};
`;

function wrapForAuthorMode(
    element: JSX.Element,
    tagTypeBroken: boolean
): JSX.Element {
    return (
        <TeaserElementAuthoringWrapper
            emptyLabel={EMPTY_LABEL}
            tagTypeBroken={tagTypeBroken}
        >
            {element}
        </TeaserElementAuthoringWrapper>
    );
}

function wrapForXFMode(
    element: JSX.Element,
    tagTypeBroken: boolean
): JSX.Element {
    return (
        // when editing static/editorial teasers directly, we need to wrap them in 3 col layout
        <RenderContext.Provider value={{noElement: true}}>
            <TeaserElementExperienceFragmentView>
                {wrapForAuthorMode(element, tagTypeBroken)}
            </TeaserElementExperienceFragmentView>
        </RenderContext.Provider>
    );
}

function getWrappedTeaser(
    teaser: JSX.Element,
    cqPath: string,
    tagTypeBroken: boolean
): JSX.Element {
    if (isInXfStaticEditMode(cqPath)) {
        return wrapForXFMode(teaser, tagTypeBroken);
    } else if (C.isInEditor()) {
        return wrapForAuthorMode(teaser, tagTypeBroken);
    }
    return teaser;
}

function InternalEditorialTeaserElement(
    props: EditorialTeaserElementProps & EditorialTeaserElementExtraProps
): JSX.Element {
    const trackingService = useTrackingService();

    const {
        teaserElementLinkHref,
        teaserElementLinkTarget,
        teaserElementLayerPath,
        teaserLinkLayerType,
        teaserLayerLink,
        showCategoryElement,
        categoryLinkHref,
        categoryLinkRichText,
        categoryLinkTarget,
        categoryLayerLink,
        showAbstractElement,
        displayType,
        cqPath,
        showAdditionalLink,
        teaserElementLinkText,
        contentId,
        itemCount,
        itemPosition,
        headingPlainText
    } = props;

    const teaserHref = teaserLayerLink
        ? teaserElementLayerPath
        : teaserElementLinkHref;

    const categoryElement = elseUndefined(
        showCategoryElement,
        <CyAttributeAppender name="editorialTeaserCategory">
            <TeaserElementCategory
                href={categoryLinkHref}
                contentId={contentId}
                text={categoryLinkRichText || []}
                isLayerLink={categoryLayerLink}
                target={categoryLinkTarget as LinkWindowTarget}
            />
        </CyAttributeAppender>
    );

    const abstractElement = elseUndefined(
        showAbstractElement,
        teaserElementAbstract
    );
    const canShowAdditionalLink = Boolean(
        (showAdditionalLink || isInXfStaticEditMode(cqPath)) &&
            teaserElementLinkText
    );

    const additionalLink = elseUndefined(
        canShowAdditionalLink,
        <TeaserElementAdditionalLink
            href={teaserHref}
            contentId={contentId}
            text={teaserElementLinkText}
            isLayerLink={teaserLayerLink}
            layerType={teaserLinkLayerType}
            target={teaserElementLinkTarget as LinkWindowTarget}
        />
    );

    const trackingActionOverride: TrackingActionOverride = (
        _href: string,
        _linkName: string,
        contextData: ContextTrackingData,
        layerName?: string
    ) => {
        trackingService.trackTeaserClick(
            contentId,
            teaserHref,
            headingPlainText,
            {
                ...contextData,
                itemCount,
                itemPosition
            }
        );

        if (teaserLayerLink) {
            trackingService.trackLayerLoad(
                processContentId(teaserElementLayerPath, layerName),
                contextData
            );
        }
    };

    function trackingFlyoutActionOverride(sectionName: string): void {
        trackingService.trackNavigationTeaserClick(
            contentId,
            teaserElementLinkHref,
            headingPlainText,
            sectionName
        );
    }

    function getTeaserElementMediaLink(small: boolean = false): JSX.Element {
        const props = {
            href: teaserHref,
            contentId: contentId,
            layerType: teaserLinkLayerType,
            target: teaserElementLinkTarget as LinkWindowTarget,
            isLayerLink: teaserLayerLink,
            trackingActionOverride
        };

        return small ? (
            <SmallTeaserElementMediaLink {...props} />
        ) : (
            <TeaserElementMediaLink {...props} />
        );
    }

    function getTeaserHeading(
        isSmallTeaser: boolean = false,
        showAdditionalLink?: boolean
    ): JSX.Element {
        const heading = isSmallTeaser ? (
            <SmallTeaserElementHeading />
        ) : (
            <TeaserElementHeading />
        );

        return showAdditionalLink ? (
            heading
        ) : (
            <CyAttributeAppender name="editorialTeaserMainLink">
                <TeaserMainLink
                    href={teaserHref}
                    contentId={contentId}
                    layerProps={{layerType: teaserLinkLayerType}}
                    target={teaserElementLinkTarget as LinkWindowTarget}
                    isLayerLink={teaserLayerLink}
                    trackingActionOverride={trackingActionOverride}
                >
                    {heading}
                </TeaserMainLink>
            </CyAttributeAppender>
        );
    }

    function getTeaserContent(
        displayType: TeaserDisplayType,
        showsAdditionalLink?: boolean
    ): JSX.Element {
        const isSmallTeaser = displayType === 'SMALL';
        return (
            <>
                {categoryElement}
                <StyledTeaserContentWrapper>
                    {getTeaserHeading(isSmallTeaser, showsAdditionalLink)}
                    {!isSmallTeaser && abstractElement && (
                        <StyledAbstractElement>
                            {abstractElement}
                        </StyledAbstractElement>
                    )}
                </StyledTeaserContentWrapper>
            </>
        );
    }

    let teaser;
    switch (displayType) {
        case 'SMALL':
            teaser = (
                <CyAttributeAppender name="editorialTeaserElement">
                    <SmallTeaserElement
                        mediaElement={getTeaserElementMediaLink(true)}
                    >
                        {getTeaserContent(displayType, canShowAdditionalLink)}
                        {additionalLink && (
                            <StyledAdditionalLinkWrapper>
                                {additionalLink}
                            </StyledAdditionalLinkWrapper>
                        )}
                    </SmallTeaserElement>
                </CyAttributeAppender>
            );
            break;
        case 'FIRST_LEVEL_A':
            teaser = (
                <CyAttributeAppender name="editorialTeaserElement">
                    <FirstLevelATeaserElement
                        mediaElement={getTeaserElementMediaLink()}
                    >
                        {getTeaserContent(displayType, canShowAdditionalLink)}
                        {additionalLink && (
                            <StyledAdditionalLinkWrapper>
                                {additionalLink}
                            </StyledAdditionalLinkWrapper>
                        )}
                    </FirstLevelATeaserElement>
                </CyAttributeAppender>
            );
            break;
        case 'FIRST_LEVEL_B':
            teaser = (
                <CyAttributeAppender name="editorialTeaserElement">
                    <FirstLevelBTeaserElement
                        mediaElement={getTeaserElementMediaLink()}
                    >
                        {getTeaserContent(displayType, canShowAdditionalLink)}
                        {additionalLink && (
                            <StyledAdditionalLinkWrapper>
                                {additionalLink}
                            </StyledAdditionalLinkWrapper>
                        )}
                    </FirstLevelBTeaserElement>
                </CyAttributeAppender>
            );
            break;
        case 'FLYOUT':
            teaser = (
                <CyAttributeAppender name="editorialTeaserElement">
                    <FlyoutTeaserElement
                        {...props}
                        teaserElementLinkHref={teaserElementLinkHref}
                        teaserElementLinkTarget={teaserElementLinkTarget}
                        teaserLayerLink={teaserLayerLink}
                        trackingActionOverride={trackingFlyoutActionOverride}
                    />
                </CyAttributeAppender>
            );
            break;
        case 'DEFAULT':
        default:
            teaser = (
                <CyAttributeAppender name="editorialTeaserElement">
                    <TeaserElement mediaElement={getTeaserElementMediaLink()}>
                        {getTeaserContent(displayType, canShowAdditionalLink)}
                        {additionalLink && (
                            <StyledAdditionalLinkWrapper>
                                {additionalLink}
                            </StyledAdditionalLinkWrapper>
                        )}
                    </TeaserElement>
                </CyAttributeAppender>
            );
    }

    return getWrappedTeaser(teaser, cqPath, props.tagTypeBroken);
}

export type EditorialTeaserElementProps = EditorialTeaserElementModel &
    InjectedPersonalizedContext;

export interface EditorialTeaserElementExtraProps
    extends Partial<EditorialTeaserElementModel> {
    readonly itemPosition?: number; // position in parent
    readonly itemCount?: number; // nr of teasers in parent
}

export const RESOURCE_TYPE =
    'vwa-ngw18/components/editorial/elements/editorialTeaserElement';

export const EditorialTeaserElement = MapTo<EditorialTeaserElementExtraProps>(
    RESOURCE_TYPE,
    InternalEditorialTeaserElement
);
