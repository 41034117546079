import * as React from 'react';

import styled, {ThemeContext} from 'styled-components';
import {Direction, PropsWithDirection, horizontalScroll} from '../helpers';

import {INPAGE_BAR_HEIGHT} from './parts/bar-link';
import {setTopBarHeight} from '../navigation-top-bar-one-hub-helpers';
import {Breakpoints} from '@volkswagen-onehub/components-core';
import {smoothScrollToX} from '../../../utils/smoothScroll';
import {
    getNewPosition,
    getLinkDistance,
    getFocusedListItemIndex,
    focusItem,
    getItemURL,
    getNextIndex,
    shouldMoveBackwards
} from './helpers';
import {Fade} from '../../../utils/Fade';

const StyledWrapV1 = styled.div`
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
        position: absolute;
        top: 0;
        bottom: 1px; // to not overlap inpagenav bottom border
        content: '';
        width: ${props => props.theme.size.static400};
        pointer-events: none;
        z-index: 1;
    }

    &:before {
        left: 0;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }

    &:after {
        right: 0;
        background: linear-gradient(
            to left,
            rgba(255, 255, 255, 0.98),
            rgba(255, 255, 255, 0)
        );
    }

    &:${props =>
        props.theme.direction === Direction.LTR ? 'after' : 'before'} {
        width: 0;
    }

`;

const StyledScrollWrapV1 = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${horizontalScroll}
`;
StyledScrollWrapV1.displayName = 'StyledScrollWrapV1';

const CSS_VAR_INPAGE_HEIGHT = '--s2-one-hub-navigation-inpage-height';

//margin: 0 auto - to stop on a side when overflow happens
export const StyledInpageNavList = styled.ul<{
    isInPageNavOverflowing: boolean;
}>`
    display: flex;
    gap: ${props => props.theme.size.dynamic0130};
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 auto;
    padding: ${props => `0 ${props.theme.size.dynamic0100}`};
    list-style: none;
    ${setTopBarHeight(CSS_VAR_INPAGE_HEIGHT, false)};

    height: var(${CSS_VAR_INPAGE_HEIGHT});

    @media (min-width: ${Breakpoints.b560}px) {
        padding: ${props => `0 ${props.theme.size.dynamic0130}`};
    }

    @media (min-width: ${Breakpoints.b1280}px) {
        padding: ${props => `0 ${props.theme.size.static400}`};
    }

    & li:last-of-type {
        margin-inline-end: ${props =>
            props.isInPageNavOverflowing ? 0 : props.theme.size.grid004};

        @media (min-width: ${Breakpoints.b560}px) and (max-width: ${Breakpoints.b1280 -
                1}px) {
            margin-inline-end: ${props =>
                props.isInPageNavOverflowing ? 0 : props.theme.size.grid003};
        }
    }
`;
StyledInpageNavList.displayName = 'StyledInpageNavList';

const ANIMATION_DURATION = 300;
const ANIMATION_DISTANCE = `${INPAGE_BAR_HEIGHT * 2}px`;
const listId = 'InPageNavBarList';

export interface InPageNavBarProps {
    readonly activeItemTitle?: string;
    readonly activeItemUrl?: string;
    readonly isOpen?: boolean;
    readonly onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const InternalInPageNavBarV1 = (
    props: React.PropsWithChildren<InPageNavBarProps & PropsWithDirection>
): JSX.Element => {
    const {children, direction, activeItemUrl} = props;

    const [isInPageNavOverflowing, setIsInPageNavOverflowing] = React.useState(
        true
    );

    const listRefV1: React.RefObject<HTMLDivElement> = React.useRef(null);
    const listPaddingRefV1: React.MutableRefObject<number> = React.useRef(0);
    const previousActiveItemRefV1: React.MutableRefObject<
        string | null
    > = React.useRef(null);
    const isScrollingRef: React.MutableRefObject<boolean> = React.useRef(false);
    const scrollingPositionRef: React.MutableRefObject<number> = React.useRef(
        0
    );

    const scrollToListItemV1 = (
        listWrapper: HTMLDivElement,
        activeItem: string,
        options?: {duration: number}
    ): void => {
        if (isScrollingRef.current || activeItem === '#') {
            return;
        }

        isScrollingRef.current = true;
        const newPosition = getNewPosition({
            scrollableElement: listWrapper,
            activeItem,
            spacing: listPaddingRefV1.current,
            direction
        });

        const linkDistance = getLinkDistance({
            listWrapper,
            previousPosition: previousActiveItemRefV1.current,
            newPosition: activeItem
        });

        smoothScrollToX({
            distance: newPosition,
            scrollableElement: listWrapper,
            animationDuration: options?.duration || linkDistance * 300,
            onScrollEnd: () => {
                if (newPosition !== scrollingPositionRef.current) {
                    smoothScrollToX({
                        distance: scrollingPositionRef.current,
                        scrollableElement: listWrapper,
                        animationDuration: options?.duration || 150,
                        onScrollEnd: () => {
                            isScrollingRef.current = false;
                        }
                    });
                } else {
                    isScrollingRef.current = false;
                }
            }
        });

        previousActiveItemRefV1.current = activeItem;
        scrollingPositionRef.current = newPosition;
    };

    const handleClickV1 = (e: React.MouseEvent<HTMLDivElement>): void => {
        const target = e.target as HTMLElement;

        if (target) {
            const parentNode = target.parentNode as HTMLElement;
            if (target.tagName === 'A' || parentNode.tagName === 'A') {
                return;
            }

            const listWrapper = listRefV1.current;
            if (listWrapper) {
                // NOTE: without this workaroud, when clicked in-between the links,
                // user could then use arrows but whole scrollable container would be scrolled
                (listWrapper as HTMLElement).setAttribute('tabindex', '0');
                (listWrapper as HTMLElement).focus();
            }
        }
    };

    const handleKeyDownV1 = (
        event: React.KeyboardEvent<HTMLDivElement>
    ): void => {
        if (!listRefV1.current) return;

        const listWrapperV1 = listRefV1.current;
        const listItems = listWrapperV1.querySelectorAll('a');
        const listItemsLength = listItems.length;

        if (
            [' ', 'ArrowLeft', 'ArrowRight', 'Tab'].every(
                keyName => keyName !== event.key
            )
        ) {
            return;
        }

        const focusedItemIdxV1 = getFocusedListItemIndex(listItems);

        if (event.key === ' ' && focusedItemIdxV1 >= 0) {
            const element = listItems[focusedItemIdxV1];

            if (element) {
                element.click();
            }
            event.preventDefault();
            return;
        }

        const moveBack = shouldMoveBackwards({
            keyboardKey: event.key,
            direction,
            isShiftKey: event.shiftKey
        });

        const focusedItemIdx = focusedItemIdxV1;

        const itemToFocus = getNextIndex({
            focusedItemIdx,
            listItemsLength,
            moveBack
        });
        const focusedItemUrl = getItemURL(listItems, itemToFocus);
        event.key !== 'Tab' && focusItem(listItems, itemToFocus);

        if (focusedItemUrl) {
            scrollToListItemV1(listWrapperV1, focusedItemUrl, {
                duration: 0
            });
        }
    };

    React.useEffect(() => {
        const listWrapperV1 = listRefV1.current;
        if (listWrapperV1 && activeItemUrl) {
            scrollToListItemV1(listWrapperV1, activeItemUrl);
        }

        // should not include scrollToListItem, otherwise it would run on every re-render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeItemUrl]);

    React.useEffect(() => {
        let isMounted = true;
        const listWrapper = listRefV1.current;

        if (!listWrapper) {
            return;
        }
        const setListPaddingV1 = () => {
            const computedListStyles = window.getComputedStyle(
                listWrapper.children[0]
            );
            listPaddingRefV1.current =
                parseInt(computedListStyles.paddingRight) +
                parseInt(computedListStyles.paddingLeft);
        };

        const getInPageNavOverflowingV1 = () => {
            if (isMounted) {
                setIsInPageNavOverflowing(
                    listWrapper.scrollWidth > listWrapper.clientWidth
                );
            }
        };

        getInPageNavOverflowingV1();
        setListPaddingV1();

        window.addEventListener('resize', () => {
            getInPageNavOverflowingV1();
            setListPaddingV1();
        });

        return function cleanUp() {
            isMounted = false;
            window.removeEventListener('resize', () => {
                getInPageNavOverflowingV1();
                setListPaddingV1();
            });
        };
    }, []);

    return (
        <Fade
            duration={ANIMATION_DURATION}
            distance={ANIMATION_DISTANCE}
            directionFrom={'bottom'}
        >
            <StyledWrapV1>
                <StyledScrollWrapV1
                    ref={listRefV1}
                    onKeyDown={handleKeyDownV1}
                    onClick={handleClickV1}
                >
                    <StyledInpageNavList
                        id={listId}
                        isInPageNavOverflowing={isInPageNavOverflowing}
                    >
                        {children}
                    </StyledInpageNavList>
                </StyledScrollWrapV1>
            </StyledWrapV1>
        </Fade>
    );
};

export function InPageNavBar(
    props: React.PropsWithChildren<InPageNavBarProps>
): JSX.Element {
    return (
        <ThemeContext.Consumer>
            {({direction}) => (
                <InternalInPageNavBarV1 direction={direction} {...props}>
                    {props.children}
                </InternalInPageNavBarV1>
            )}
        </ThemeContext.Consumer>
    );
}
