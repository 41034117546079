import * as React from 'react';
import {useDisclaimerStoreContext, useLogger, useModelContext} from '..';
import {DisplayType} from '../../../generated/core';
import {ConfigurableConsole} from '../logger/ConfigurableConsole';
import {DisclaimerStore} from './DisclaimerStore';
import {GeneralDisclaimerContextData} from './base/GeneralDisclaimerContextData';
import {GeneralDisclaimerContextImpl} from './base/GeneralDisclaimerContextImpl';
import {LayerDisclaimerContext} from './DisclaimerStoreContext';
export const GeneralDisclaimerContext = React.createContext<
    GeneralDisclaimerContextData | undefined
>(undefined);

export interface GeneralDisclaimerProviderProps {
    children: React.ReactNode;
    displayTypes: DisplayType[];
    allowNested?: boolean;
    disableScroll?: boolean;
    keepWhenUnregistered?: boolean;
}

export const GeneralDisclaimerProvider = (
    props: GeneralDisclaimerProviderProps
) => {
    const path = useModelContext().value || '/';
    const store = useDisclaimerStoreContext().value;
    const logger = useLogger().disclaimer;

    if (store) {
        return (
            <InternalGeneralDisclaimerProvider {...props}>
                {props.children}
            </InternalGeneralDisclaimerProvider>
        );
    } else {
        logger.debug('creating disclaimer store on demand %s', path);
        return (
            <LayerDisclaimerContext path={path}>
                <InternalGeneralDisclaimerProvider {...props}>
                    {props.children}
                </InternalGeneralDisclaimerProvider>
            </LayerDisclaimerContext>
        );
    }
};

const InternalGeneralDisclaimerProvider = (
    props: GeneralDisclaimerProviderProps
) => {
    const path = useModelContext().value || '/';
    const logger = useLogger().disclaimer;
    const parent = React.useContext(GeneralDisclaimerContext);
    const store = useDisclaimerStoreContext().value;
    const ctx = React.useRef(createCtx(parent, path, logger, props, store));

    return (
        <GeneralDisclaimerContext.Provider value={ctx.current}>
            {props.children}
        </GeneralDisclaimerContext.Provider>
    );
};

function createCtx(
    parent: GeneralDisclaimerContextData | undefined,
    path: string,
    logger: ConfigurableConsole,
    props: GeneralDisclaimerProviderProps,
    store?: DisclaimerStore
) {
    if (!store) {
        throw new Error('must provide store');
    }
    if (!parent) {
        return new GeneralDisclaimerContextImpl(
            path,
            logger,
            props.displayTypes,
            store,
            undefined,
            props.disableScroll,
            props.keepWhenUnregistered
        );
    } else {
        return parent.create(
            path,
            logger,
            props.displayTypes,
            props.allowNested,
            props.disableScroll,
            props.keepWhenUnregistered
        );
    }
}
