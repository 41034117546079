import * as React from 'react';
import {ThemeContext} from 'styled-components';
import {
    Layout,
    LayoutConfiguration,
    LayoutConfigurationForBreakpoint,
    Breakpoints,
    ThemeProvider,
    css,
    styled
} from '@volkswagen-onehub/components-core';
import {Direction} from '../helpers';
import {setTopBarHeight} from '../navigation-top-bar-one-hub-helpers';
import {useMediaQueries} from '../../../context/mediaQueries/mediaQueryContext';

type EditorialStageProps = Readonly<{
    media?: JSX.Element;
    overlay?: JSX.Element;
    metaInfo?: JSX.Element;
    copy?: JSX.Element;
    readingTimeBox?: JSX.Element;
    disableOverlayFilter?: boolean; // @deprecated
    disclaimersOverlay?: JSX.Element;
    isInEditor?: boolean;
}>;

const CSS_VAR_EXPANDED_TOPBAR_HEIGHT = '--s2-topbar-height-expanded';
const safeTopBarPadding = css`calc(${props =>
    props.theme.size.static200} + var(${CSS_VAR_EXPANDED_TOPBAR_HEIGHT}, 0px))`;

const EditorialStageWrapper = styled.div`
    position: relative;
    /* This has no direct connection to the top bar component, it just sets the
	height of how large the expanded top bar for the current breakpoint is into
	a CSS variable. The CSS variable can be accessed inside this component.
	In the future we could possibly refactor this by somehow making these
	variables globally available.*/
    ${setTopBarHeight(CSS_VAR_EXPANDED_TOPBAR_HEIGHT, true)}
`;

EditorialStageWrapper.displayName = 'EditorialStageWrapper';

const MobileOverlay = styled.div`
    position: relative;
    pointer-events: none;
    min-height: 80vh;
    display: flex;
    align-items: flex-end;

    // NOTE: static550 to keep enough place for video controls
    padding: ${safeTopBarPadding} ${props => props.theme.size.grid002}
        ${props => props.theme.size.static550};

    @media (min-width: ${Breakpoints.b560}px) {
        display: none;
    }
`;

MobileOverlay.displayName = 'MobileOverlay';

const DesktopOverlay = styled.div`
    display: none;
    @media (min-width: ${Breakpoints.b560}px) {
        display: block;
    }
`;
DesktopOverlay.displayName = 'DesktopOverlay';

function flipForRTL(
    dir: Direction,
    columns: LayoutConfigurationForBreakpoint[]
): LayoutConfigurationForBreakpoint[] {
    return dir === Direction.RTL ? columns.reverse() : columns;
}

const getLayout = (dir: Direction): LayoutConfiguration => ({
    [Breakpoints.default]: [
        {name: 'media', columns: 24},
        {name: 'content', columns: 24}
    ],
    // Desktop Layout should be the _same_ for RTL, so we have to flip it again
    // (first flip is implicitly done by Layout component)
    [Breakpoints.b560]: flipForRTL(dir, [
        {name: 'media', columns: 11},
        {name: 'content', columns: 13}
    ]),
    [Breakpoints.b1600]: flipForRTL(dir, [
        {name: 'media', columns: 12},
        {name: 'content', columns: 12}
    ])
});

const MediaArea = styled.div<{isInEditor?: boolean}>`
    min-height: 80vh;
    position: relative;

    @media (min-width: ${Breakpoints.b560}px) {
        height: ${props => (props.isInEditor ? '100%' : '100vh')};
        max-height: 100vh;
    }
`;

const MetaWrap = styled.div``;
const CopyWrap = styled.div``;

const ContentArea = styled.div<{isInEditor?: boolean}>`
	padding: ${props => props.theme.size.static250} ${props =>
    props.theme.size.grid002} 0;
	display: flex;
	flex-direction: column;

	${MetaWrap} + ${CopyWrap} {
		margin-top: ${props => props.theme.size.static350}
	}

	@media (min-width: ${Breakpoints.b560}px) {
		min-height: ${props => (props.isInEditor ? '100%' : '100vh')};
		padding: ${safeTopBarPadding} ${props => props.theme.size.grid002} ${props =>
    props.theme.size.grid002};

		* + ${MetaWrap} {
			margin-top: ${props => props.theme.size.static200};
		}
		* + ${CopyWrap} {
			margin-top: ${props => props.theme.size.static450};
		}
	}
`;

const ContentBoxWrap = styled.div`
    flex: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const DesktopReadingTimeBox = styled.div`
    flex: 1;
    display: none;
    @media (min-width: ${Breakpoints.b560}px) {
        display: initial;
    }
`;

const AbsoluteMedia = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const EditorialStage: React.FunctionComponent<EditorialStageProps> = props => {
    const {
        media,
        overlay,
        copy,
        metaInfo,
        readingTimeBox,
        disclaimersOverlay,
        isInEditor
    } = props;

    const {isMobile} = useMediaQueries();
    const isDesktopOverlay = !isMobile && overlay;

    return (
        <EditorialStageWrapper>
            <ThemeContext.Consumer>
                {value => (
                    <Layout appearance={getLayout(value.direction)}>
                        <MediaArea isInEditor={isInEditor}>
                            <AbsoluteMedia>{media}</AbsoluteMedia>
                            {isMobile && (
                                <MobileOverlay>
                                    <ThemeProvider theme="inverted">
                                        {overlay}
                                    </ThemeProvider>
                                </MobileOverlay>
                            )}
                            {!isInEditor && disclaimersOverlay}
                        </MediaArea>
                        <ContentArea isInEditor={isInEditor}>
                            <ContentBoxWrap>
                                {isDesktopOverlay && (
                                    <DesktopOverlay>{overlay}</DesktopOverlay>
                                )}
                                {metaInfo && <MetaWrap>{metaInfo}</MetaWrap>}
                                {copy && <CopyWrap>{copy}</CopyWrap>}
                            </ContentBoxWrap>
                            {readingTimeBox && (
                                <DesktopReadingTimeBox>
                                    {readingTimeBox}
                                </DesktopReadingTimeBox>
                            )}
                        </ContentArea>
                    </Layout>
                )}
            </ThemeContext.Consumer>
        </EditorialStageWrapper>
    );
};

export const EditorialStageForAEMEditor: React.FunctionComponent<Omit<
    EditorialStageProps,
    'isInEditor'
>> = props => <EditorialStage {...props} isInEditor />;

EditorialStageForAEMEditor.displayName = 'EditorialStageForAEMEditor';
