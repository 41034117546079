import * as React from 'react';
import {useTrackingService} from '../context';
import {isInBrowser} from '../utils/browser/isInBrowser';

export function useTrackAssetType(
    mediaType: string | undefined,
    portraitMediaType: string | undefined,
    sectionName: string | undefined,
    portraitBreakpoints: number | undefined
) {
    const [viewportWidth, setViewportWidth] = React.useState<
        number | undefined
    >((isInBrowser() && window.screen.width) || undefined);
    const trackingService = useTrackingService();

    const isPortrait = React.useCallback(() => {
        if (portraitBreakpoints && viewportWidth) {
            return viewportWidth < portraitBreakpoints;
        }
        // in the case of undefined we will display portrait mode as a basic render
        return true;
    }, [viewportWidth, portraitBreakpoints]);

    const trackAsset = React.useCallback(() => {
        if (sectionName && mediaType && portraitMediaType) {
            const assetTypeLandscape = mediaType.toLowerCase() + '.landscape';
            const assetTypePortrait =
                portraitMediaType.toLowerCase() + '.portrait';

            const assetDisplayed = isPortrait()
                ? assetTypePortrait
                : assetTypeLandscape;

            return trackingService.trackAssetLoad('id', [
                {
                    sectionName: sectionName,
                    assetType: [assetTypeLandscape, assetTypePortrait],
                    displayed: assetDisplayed
                }
            ]);
        }
    }, [
        sectionName,
        mediaType,
        portraitMediaType,
        trackingService,
        isPortrait
    ]);

    const handleViewportWidthChange = React.useCallback(() => {
        isInBrowser() && setViewportWidth(window.screen.width);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInBrowser() && window.screen.width]);

    React.useEffect(() => {
        trackAsset();
        window.addEventListener('resize', () => {
            handleViewportWidthChange();
        });
    }, [
        trackingService,
        mediaType,
        portraitMediaType,
        sectionName,
        portraitBreakpoints,
        viewportWidth,
        isPortrait,
        handleViewportWidthChange,
        trackAsset
    ]);
}
