/**
 * Reads the document's cookies and returns the value of the given cookie's name.
 *
 * @param {String} name The name of the cookie.
 * @return {String} The value of the cookie or null, if the cookie could not be read.
 */
export function getCookie(name: string): string | null {
    const end = name.length + 1;

    return (
        document.cookie
            .split(';')
            .map(c => c.trim())
            .filter(cookie => cookie.substring(0, end) === `${name}=`)
            .map(cookie => decodeURIComponent(cookie.substring(end)))[0] || null
    );
}
