import * as React from 'react';
import styled from 'styled-components';
import {Breakpoints} from '@volkswagen-onehub/components-core';

export interface EditorialTeaserProps {
    readonly headlineElement?: JSX.Element;
    readonly items: JSX.Element[];
    readonly children?: JSX.Element;
}

const StyledEditorialTeaserWrapper = styled.div`
    max-width: ${Breakpoints.b2560}px;
`;
StyledEditorialTeaserWrapper.displayName = 'StyledEditorialTeaserWrapper';

const StyledEditorialTeaserHeaderWrapper = styled.div`
    padding: 0 ${props => props.theme.size.grid006};
    margin-bottom: ${props => props.theme.size.dynamic0130};
`;
StyledEditorialTeaserHeaderWrapper.displayName =
    'StyledEditorialTeaserHeaderWrapper';

const StyledColumnsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 ${props => props.theme.size.grid001};

    @media (min-width: ${Breakpoints.b960}px) {
        padding: 0 ${props => props.theme.size.grid002};
    }
`;
StyledColumnsWrapper.displayName = 'StyledColumnsWrapper';

const StyledColumn = styled.div`
    width: 30%;

    & > div:not(:last-child) {
        margin-bottom: ${props => props.theme.size.dynamic0150};
    }
`;
StyledColumn.displayName = 'StyledColumn';

export const EditorialTeaser: React.FunctionComponent<EditorialTeaserProps> = (
    props: EditorialTeaserProps
): JSX.Element | null => {
    if (props.items.length !== 3 && props.items.length !== 6) {
        return null;
    }

    const doubleRow = props.items.length === 6;

    return (
        <StyledEditorialTeaserWrapper>
            {props.headlineElement && (
                <StyledEditorialTeaserHeaderWrapper>
                    {props.headlineElement}
                </StyledEditorialTeaserHeaderWrapper>
            )}

            <StyledColumnsWrapper>
                <StyledColumn>
                    {props.items[0]}
                    {doubleRow && props.items[3]}
                </StyledColumn>
                <StyledColumn>
                    {props.items[1]}
                    {doubleRow && props.items[4]}
                </StyledColumn>
                <StyledColumn>
                    {props.items[2]}
                    {doubleRow && props.items[5]}
                </StyledColumn>
            </StyledColumnsWrapper>
        </StyledEditorialTeaserWrapper>
    );
};
