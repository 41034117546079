import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {
    useContentStore,
    useLogger,
    useNavigationStore,
    useRegistry
} from '../../../context';
import {NextBestActionGroup} from './NextBestActionGroup';
import {C} from '../../../registries/compatibilty';
import {getActions} from './getActions';
import {PersonalizedNbabStore} from '../../../context/nbab/PersonalizedNbabStore';
import {mergePersonalizedActions} from './mergePersonalizedActions';
import {useAbTest} from '../../../hooks/useAbTest';
import {useActionsEnabled} from '../../../components/links/action/useActionEnabled';
import {NextBestActionModel} from '../../../../generated/core';
import {PersonalizedAction} from './PersonalizedNbab';

interface NextBestActionProps {
    sectionId?: string;
    sectionRef?: React.RefObject<HTMLDivElement>;
    sectionGroup?: boolean;
    contentLabels?: string;
}

/** Global NBAB, can change based on scroll position */
export const NextBestAction: React.FunctionComponent<NextBestActionProps> = observer(
    function NBA({
        sectionId,
        sectionRef,
        sectionGroup,
        contentLabels
    }: NextBestActionProps) {
        const contentStore = useContentStore();
        const pageRootModel = contentStore.getCurrentPageRootModel();
        const navigationStore = useNavigationStore();
        const personalizedNbabStore: PersonalizedNbabStore = useRegistry().getSingleton(
            'PersonalizedNbabStore'
        );
        const logger = useLogger().nbab;
        const isHorizontalView = useAbTest('nbabHorizontalView');

        const personalizedActions = personalizedNbabStore
            ? personalizedNbabStore.personalizedActions
            : [];

        // get cms defined actions for page/section
        const cmsActionConfig = getActions(
            pageRootModel?.nextBestActionDataModel,
            sectionId,
            sectionGroup,
            navigationStore.activeSectionId,
            navigationStore.activeSectionGroupId,
            logger,
            contentLabels
        );

        // get map with enabled state of all user interactions
        const enabledUserInteractions = useActionsEnabled(
            (cmsActionConfig?.enabledActions || [])
                .filter(a => a.linkType === 'action')
                .map(a => a.path)
                .filter(u => Boolean(u))
        );

        // filter out disabled user interactions and invalid actions
        const filteredCMSActions = (cmsActionConfig?.enabledActions || [])
            .filter(
                a =>
                    a.linkType !== 'action' ||
                    enabledUserInteractions.get(a.path)
            )
            .filter(isNbabComplete);

        const filteredPersonalizedActions = personalizedActions.filter(
            isPersonalizedNbabComplete
        );

        // merge personalized actions
        const mergedActions = cmsActionConfig?.disabled
            ? []
            : mergePersonalizedActions(
                  filteredCMSActions,
                  filteredPersonalizedActions
              );

        if (C.isInEditor() || !cmsActionConfig || mergedActions.length === 0) {
            return null;
        }

        const {
            isShown,
            trackingData,
            pageActions,
            noIconForHorizontalView
        } = cmsActionConfig;

        return (
            <NextBestActionGroup
                actions={mergedActions}
                trackingData={trackingData}
                pageActions={pageActions}
                isNbabVisible={isShown}
                sectionRef={sectionRef}
                isHorizontalView={isHorizontalView}
                noIconForHorizontalView={noIconForHorizontalView}
            />
        );
    }
);

const isNbabComplete = (action: NextBestActionModel) => {
    if (!action.enabled) return false;

    if (['internal', 'external'].includes(action.linkType) && !action.path)
        return false;

    return true;
};

const isPersonalizedNbabComplete = (action: PersonalizedAction) => {
    if (action.actionType === 'internal' && !action.path) return false;
    if (action.actionType === 'external' && !action.url) return false;

    return true;
};
