import {TokenTextAppearance, styled} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import {
    DisplayType,
    FullscreenStageSectionModel
} from '../../../../generated/core';
import {AlertBox, AlertBoxSeverity} from '../../../components/AlertBox';
import {AuthoringWrapper} from '../../../components/AuthoringWrapper';
import {BellowItemDisclaimerContainer} from '../../../components/disclaimers/BellowItemDisclaimerContainer';
import {OverlayDisclaimers} from '../../../components/disclaimers/OverlayDisclaimerContainers';
import {ResponsiveMediaRendererConf} from '../../../components/ResponsiveMediaRenderer';
import {GeneralDisclaimerProvider} from '../../../context/disclaimer/GeneralDisclaimerProvider';
import {
    MediaContext,
    MediaContextData,
    VideoInteraction
} from '../../../context/media/MediaContext';
import {FullscreenStage} from '../../../d6/components/fullscreen-stage';
import {VideoPlayerV2PlayState} from '../../../d6/components/video-player-v2';
import {MapTo} from '../../../infrastructure/compatibility/MapTo';
import {C} from '../../../registries/compatibilty';
import {CyAttributeAppender} from '../../../test/CyAttributeAppender';
import {useIsClientOnlyRendering} from '../../../utils/useIsClientOnlyRendering';
import {ButtonElement} from '../elements/buttonElement/ButtonElement';
import {HeadingElement} from '../elements/HeadingElement';
import {MediaElement} from '../elements/MediaElement';
import {CopyItem} from '../items/CopyItem';
import {SectionWrapper} from './SectionWrapper';
import {useNavigationStore} from '../../../context';
import {radialGradient} from '../../../d6/components/gradient-background';

export const DEPRECATION_ALERT =
    'Please migrate to S139 Basic Stage. This stage is deprecated.';

const headingElement = (
    <HeadingElement path="heading" hideItemDisclaimers style="H0" order="H1" />
);

const copyDesktopItem = (
    <CopyItem
        path="copy"
        appearance={TokenTextAppearance.bigcopy100}
        hideItemDisclaimers
    />
);

const copyMobileItem = (
    <CopyItem
        path="copy"
        isNotEditable={true}
        appearance={TokenTextAppearance.bigcopy100}
        hideItemDisclaimers
    />
);

const buttonElement = <ButtonElement path="button" />;

const StyledAuthorMediaWrapper = styled.div`
    position: relative;
    width: 60%;
    &:nth-child(2) {
        bottom: 0;
        left: 0;
        right: 0;
    }
`;

const StyledAuthorWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100vw;
`;

const StyledAuthorContentWrapper = styled.div`
    padding: ${props => props.theme.size.static300};
    background: ${radialGradient};
    width: 40%;
    min-height: 300px;
    span {
        color: white;
    }
`;

export interface FullscreenStageSectionProps
    extends FullscreenStageSectionModel,
        MediaContextData {}

const responsiveMediaConfigurationV2: ResponsiveMediaRendererConf[] = [
    {
        mediaQuery: '(max-aspect-ratio: 3/4)',
        sizes: '100vw',
        aspectRatio: 'r_9_16'
    },
    {
        mediaQuery: '(min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_1_1'
    },
    {
        mediaQuery: '(min-aspect-ratio: 4/3)',
        sizes: '100vw',
        aspectRatio: 'r_16_9'
    }
];

const mediaDisclaimerOverlay = (
    enableStaticLayout?: boolean
): JSX.Element | undefined => {
    return enableStaticLayout ? (
        <OverlayDisclaimers
            displayTypes={['T4_ITEM_BASED']}
            color={'light'}
            type="item"
        />
    ) : (
        undefined
    );
};

const InternalFullscreenStageSection = (props: FullscreenStageSectionProps) => {
    const [hideOverlayContent, setHideOverlayContent] = React.useState(false);
    const isServer = !useIsClientOnlyRendering();
    const navigationStore = useNavigationStore();

    const {
        contentLabels,
        contentId: sectionId,
        contentName,
        cqItems,
        anchorId,
        showButton,
        tagTypeBroken,
        enableStaticLayout,
        mboxId
    } = props;

    const responsiveMediaConfiguration = responsiveMediaConfigurationV2;

    const disclaimerInsertTypes: DisplayType[] = enableStaticLayout
        ? ['T3_SECTION_BASED']
        : ['T3_SECTION_BASED', 'T4_ITEM_BASED'];

    const media = (
        <CyAttributeAppender name="fullscreenStageMedia">
            <MediaElement
                path="media"
                containerProps={{
                    matchParent: true
                }}
                useParentAspectRatio
                enableItemInteractiveDisclaimer
                hideItemBasedDisclaimers
                responsiveMediaConfig={responsiveMediaConfiguration}
            />
        </CyAttributeAppender>
    );

    const interactionDataReceived = (data: VideoInteraction) => {
        if (data.type === 'VIDEO') {
            const hideOverlay =
                (data.data.videoState === VideoPlayerV2PlayState.PLAYING &&
                    !data.startedByIO) ||
                data.data.timelineDragInProgress;
            if (hideOverlayContent !== hideOverlay) {
                setHideOverlayContent(hideOverlay);
            }
        }
    };

    const deprecationAlert = (
        <AlertBox severity={AlertBoxSeverity.WARNING}>
            <p key={DEPRECATION_ALERT}>{DEPRECATION_ALERT}</p>
        </AlertBox>
    );

    const authorView = (
        <AuthoringWrapper
            anchorId={anchorId}
            title="S104 Fullscreen Stage"
            bgColor={AuthoringWrapper.BG_COLOR_SECTION}
            tagTypeBroken={tagTypeBroken}
        >
            <GeneralDisclaimerProvider
                displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
            >
                <MediaContext.Provider
                    value={{sizes: '100vw', reduceBrightness: true}}
                >
                    {deprecationAlert}
                    <StyledAuthorWrapper>
                        <StyledAuthorContentWrapper>
                            {headingElement}
                            {copyDesktopItem}
                            {showButton ? buttonElement : null}
                            <BellowItemDisclaimerContainer
                                color={'light'}
                                displayTypes={disclaimerInsertTypes}
                            />
                        </StyledAuthorContentWrapper>
                        <StyledAuthorMediaWrapper>
                            <MediaElement
                                path="media"
                                hideItemBasedDisclaimers
                                enableItemInteractiveDisclaimer
                            />
                            {mediaDisclaimerOverlay(enableStaticLayout)}
                        </StyledAuthorMediaWrapper>
                    </StyledAuthorWrapper>
                </MediaContext.Provider>
            </GeneralDisclaimerProvider>
        </AuthoringWrapper>
    );

    const publishView = (
        <SectionWrapper
            mboxId={mboxId}
            sectionId={sectionId}
            anchorId={anchorId}
            contentLabels={contentLabels}
            contentName={contentName}
        >
            <GeneralDisclaimerProvider
                displayTypes={['T3_SECTION_BASED', 'T4_ITEM_BASED']}
            >
                <MediaContext.Provider
                    value={{
                        sizes: '100vw',
                        setInteractionData: interactionDataReceived
                    }}
                >
                    <CyAttributeAppender name="fullscreenStage">
                        <FullscreenStage
                            media={media}
                            heading={headingElement}
                            copy={!cqItems.copy.empty ? copyDesktopItem : null}
                            copyMobile={
                                !cqItems.copy.empty ? copyMobileItem : null
                            }
                            button={showButton ? buttonElement : null}
                            isServer={isServer}
                            enableStaticLayout={enableStaticLayout}
                            disclaimersInsert={
                                /*  we use ItemDisclaimerContainer because we want to render disclaimers full width and under the richtext item */
                                <BellowItemDisclaimerContainer
                                    color={'light'}
                                    displayTypes={disclaimerInsertTypes}
                                />
                            }
                            disclaimersOverlay={mediaDisclaimerOverlay(
                                enableStaticLayout
                            )}
                            isAnnouncementBarVisible={
                                navigationStore.isAnnouncementBarVisible
                            }
                            announcementBarHeight={
                                navigationStore.getAnnouncementBarHeight
                            }
                        />
                    </CyAttributeAppender>
                </MediaContext.Provider>
            </GeneralDisclaimerProvider>
        </SectionWrapper>
    );

    return C.isInEditor() ? authorView : publishView;
};

export const FullscreenStageSection = MapTo(
    'vwa-ngw18/components/editorial/sections/fullscreenStageSection',
    InternalFullscreenStageSection
);
