import {AspectRatio} from '@volkswagen-onehub/components-core';
import * as React from 'react';
import styled, {ThemeContext} from 'styled-components';
import {Direction} from '../helpers';
import {
    AspectRatioToColumnsMap,
    FullWidthAspectRatioToColumnsMap,
    copyStartColumnMap,
    copyWidthMap,
    fullWidthCopyStartColumnMap,
    fullWidthCopyWidthMap
} from './config';

export enum MediaAlignment {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export interface CopyInsetMediaProps {
    readonly lineHeight?: number;
    readonly mediaAlignment?: MediaAlignment;
    readonly media: JSX.Element;
    readonly aspectRatio: AspectRatio;
    readonly isFullWidth?: boolean;
}

interface StyledContainerProps {
    readonly lineHeight: number;
    readonly aspectRatio: AspectRatio;
    readonly alignment: MediaAlignment;
    readonly isFullWidth?: boolean;
}

const getStyledImageWidth = (
    alignment: MediaAlignment,
    aspectRatio: AspectRatio,
    breakpoint: number,
    isFullWidth?: boolean
): string => {
    const gridConfig = isFullWidth
        ? FullWidthAspectRatioToColumnsMap[breakpoint][aspectRatio][alignment]
        : AspectRatioToColumnsMap[breakpoint][aspectRatio][alignment];

    return `calc(100% * (${gridConfig.imageWidthInColumns /
        gridConfig.totalContentColumns}))`;
};

const getStyledCopyContainerPadding = (
    alignment: MediaAlignment,
    aspectRatio: AspectRatio,
    breakpoint: number,
    isFullWidth?: boolean
): string => {
    if (isFullWidth) {
        const gridConfig =
            FullWidthAspectRatioToColumnsMap[breakpoint][aspectRatio][
                alignment
            ];

        if (alignment === MediaAlignment.RIGHT) {
            return `0 calc(100% * (${(gridConfig.totalContentColumns -
                fullWidthCopyWidthMap[breakpoint]) /
                gridConfig.totalContentColumns}
					)
			) 0 0`;
        } else {
            return `0 0 0 calc(100% * (${(fullWidthCopyStartColumnMap[
                breakpoint
            ] -
                gridConfig.imageStartColumn) /
                gridConfig.totalContentColumns}
					)
			)`;
        }
    } else {
        const gridConfig =
            AspectRatioToColumnsMap[breakpoint][aspectRatio][alignment];

        if (alignment === MediaAlignment.RIGHT) {
            return `0 calc(100% * (${(gridConfig.totalContentColumns -
                copyWidthMap[breakpoint]) /
                gridConfig.totalContentColumns}
						)
				) 0 0`;
        } else {
            return `0 0 0 calc(100% * (${(copyStartColumnMap[breakpoint] -
                gridConfig.imageStartColumn) /
                gridConfig.totalContentColumns}
						)
				)`;
        }
    }
};

const getContainerColumnEnd = (
    alignment: MediaAlignment,
    aspectRatio: AspectRatio,
    breakpoint: number,
    isFullWidth?: boolean
): number => {
    if (isFullWidth) {
        const gridConfig =
            FullWidthAspectRatioToColumnsMap[breakpoint][aspectRatio][
                alignment
            ];

        return alignment === MediaAlignment.LEFT
            ? gridConfig.totalContentColumns + gridConfig.imageStartColumn
            : gridConfig.totalContentColumns +
                  fullWidthCopyStartColumnMap[breakpoint];
    } else {
        const gridConfig =
            AspectRatioToColumnsMap[breakpoint][aspectRatio][alignment];

        return alignment === MediaAlignment.LEFT
            ? gridConfig.totalContentColumns + gridConfig.imageStartColumn
            : gridConfig.totalContentColumns + copyStartColumnMap[breakpoint];
    }
};

const getContainerColumnStart = (
    alignment: MediaAlignment,
    aspectRatio: AspectRatio,
    breakpoint: number,
    isFullWidth?: boolean
): number => {
    if (isFullWidth) {
        return alignment === MediaAlignment.LEFT
            ? FullWidthAspectRatioToColumnsMap[breakpoint][aspectRatio][
                  alignment
              ].imageStartColumn
            : fullWidthCopyStartColumnMap[breakpoint];
    } else {
        return alignment === MediaAlignment.LEFT
            ? AspectRatioToColumnsMap[breakpoint][aspectRatio][alignment]
                  .imageStartColumn
            : copyStartColumnMap[breakpoint];
    }
};

const StyledMediaContainer = styled.div<StyledContainerProps>`
    margin-bottom: ${props => props.theme.size.dynamic0050};
    overflow: hidden;
    @media (min-width: 768px) {
        position: relative;
        z-index: 1;
        clear: both;
        float: ${props =>
            props.alignment === MediaAlignment.LEFT ? 'left' : 'right'};
        margin-block: ${props => props.theme.size.dynamic0050};
        margin-left: ${props =>
            props.alignment === MediaAlignment.LEFT
                ? 0
                : props.theme.size.dynamic0050};
        margin-right: ${props =>
            props.alignment === MediaAlignment.RIGHT
                ? 0
                : props.theme.size.dynamic0050};
        width: ${props =>
            getStyledImageWidth(
                props.alignment,
                props.aspectRatio,
                768,
                props.isFullWidth
            )};
    }
    @media (min-width: 768px) {
        width: ${props =>
            getStyledImageWidth(
                props.alignment,
                props.aspectRatio,
                1024,
                props.isFullWidth
            )};
    }
    @media (min-width: 1440px) {
        width: ${props =>
            getStyledImageWidth(
                props.alignment,
                props.aspectRatio,
                1440,
                props.isFullWidth
            )};
    }
`;

const StyledCopyContainer = styled.div`
    padding: ${props =>
        props.isFullWidth
            ? `${props.theme.size.grid002} 0`
            : props.theme.size.grid002};
    @media (min-width: 768px) {
        padding: ${(p: StyledContainerProps) =>
            getStyledCopyContainerPadding(
                p.alignment,
                p.aspectRatio,
                768,
                p.isFullWidth
            )};
    }
    @media (min-width: 768px) {
        padding: ${(p: StyledContainerProps) =>
            getStyledCopyContainerPadding(
                p.alignment,
                p.aspectRatio,
                1024,
                p.isFullWidth
            )};
    }
    @media (min-width: 1440px) {
        ${(p: StyledContainerProps) =>
            getStyledCopyContainerPadding(
                p.alignment,
                p.aspectRatio,
                1440,
                p.isFullWidth
            )};
    }
    line-height: ${(p: StyledContainerProps) => p.lineHeight}px;
`;

const StyledComponentContainer = styled.div`
    grid-column-start: ${(p: StyledContainerProps) => (p.isFullWidth ? 1 : 2)};
    grid-column-end: ${(p: StyledContainerProps) => (p.isFullWidth ? 22 : 12)};
    @media (min-width: 768px) {
        grid-column-start: ${(p: StyledContainerProps) =>
            getContainerColumnStart(
                p.alignment,
                p.aspectRatio,
                768,
                p.isFullWidth
            )};
        grid-column-end: ${(p: StyledContainerProps) =>
            getContainerColumnEnd(
                p.alignment,
                p.aspectRatio,
                768,
                p.isFullWidth
            )};
        &::before {
            content: '';
            display: block;
            height: ${(p: StyledContainerProps) => p.lineHeight * 3}px;
            width: 0;
            float: left;
        }
    }
    @media (min-width: 768px) {
        grid-column-start: ${(p: StyledContainerProps) =>
            getContainerColumnStart(
                p.alignment,
                p.aspectRatio,
                1024,
                p.isFullWidth
            )};
        grid-column-end: ${(p: StyledContainerProps) =>
            getContainerColumnEnd(
                p.alignment,
                p.aspectRatio,
                1024,
                p.isFullWidth
            )};
    }
    @media (min-width: 1440px) {
        grid-column-start: ${(p: StyledContainerProps) =>
            getContainerColumnStart(
                p.alignment,
                p.aspectRatio,
                1440,
                p.isFullWidth
            )};
        grid-column-end: ${(p: StyledContainerProps) =>
            getContainerColumnEnd(
                p.alignment,
                p.aspectRatio,
                1440,
                p.isFullWidth
            )};
    }
`;

const StyledCopyInsetMedia = styled.div`
    position: relative;
    z-index: 0;
    height: 100%;
    width: 100%;
    grid-template-rows: 1fr;
    display: grid;
    @media (min-width: 375px) {
        grid-template-columns: repeat(12, 1fr);
    }
    @media (min-width: 768px) {
        grid-template-columns: repeat(24, 1fr);
    }
`;
StyledCopyInsetMedia.displayName = 'StyledCopyInsetMedia';

const swapMediaAlignmentByDirection = (
    direction: Direction,
    mediaAlignment: MediaAlignment
): MediaAlignment => {
    if (direction !== Direction.RTL) {
        return mediaAlignment;
    }
    // swap MediaALignment if direction is RTL

    return mediaAlignment === MediaAlignment.LEFT
        ? MediaAlignment.RIGHT
        : MediaAlignment.LEFT;
};

export const CopyInsetMedia: React.FunctionComponent<CopyInsetMediaProps> = (
    props
): JSX.Element => {
    const {
        children,
        mediaAlignment = MediaAlignment.LEFT,
        media,
        lineHeight = 24,
        aspectRatio,
        isFullWidth = false
    } = props;

    return (
        <ThemeContext.Consumer>
            {({direction}) => {
                const directionalAlignment = swapMediaAlignmentByDirection(
                    direction,
                    mediaAlignment
                );

                const MediaSection = (
                    <StyledMediaContainer
                        lineHeight={lineHeight}
                        aspectRatio={aspectRatio}
                        alignment={directionalAlignment}
                        isFullWidth={isFullWidth}
                    >
                        {media}
                    </StyledMediaContainer>
                );

                const CopySection = (
                    <StyledCopyContainer
                        lineHeight={lineHeight}
                        aspectRatio={aspectRatio}
                        alignment={directionalAlignment}
                        isFullWidth={isFullWidth}
                    >
                        {children}
                    </StyledCopyContainer>
                );

                return (
                    <StyledCopyInsetMedia>
                        <StyledComponentContainer
                            lineHeight={lineHeight}
                            aspectRatio={aspectRatio}
                            alignment={directionalAlignment}
                            isFullWidth={isFullWidth}
                        >
                            {MediaSection}
                            {CopySection}
                        </StyledComponentContainer>
                    </StyledCopyInsetMedia>
                );
            }}
        </ThemeContext.Consumer>
    );
};
