import * as React from 'react';

import {useRouterService, useTrackingService} from '../../../../context';
import {TrackingDTO} from '../../../../context/tracking/TrackingService';
import {useContextTrackingData} from '../../../../hooks/useContextTrackingData';
import {useIntersectionTrigger} from './intersectionTrigger';
import {TeaserPersonalization} from '../../../../utils/tracking/TeaserPersonalization';

export interface ViewportTrackingHookParams {
    sectionId: string;
    teaserList?: TeaserPersonalization[];
    contentLabels?: string;
    contentName?: string;
    additionalTrackingData?: Partial<TrackingDTO>;
}

function getRatioOfViewportCovered(
    viewportRect: DOMRectReadOnly,
    intersectionRect: DOMRectReadOnly
): number {
    if (!intersectionRect) {
        // sometimes the rect is null, when the intersection has zero width and height
        return 0;
    }
    // Returns the ratio of the viewport(!) covered by the section.
    // This is not the same as the IntersectionObserverEntry.intersectionRatio,
    // as that only tells us the ratio of the section(!) visible in the viewport.
    //
    // Example: a section could be a 100% visible in the viewport (entry.intersectionRatio 1.0),
    // but only cover 15% of the viewport. (0.15)

    // We will only look at the height intersection for simplicity.

    const coveredRatio = intersectionRect.height / viewportRect.height;

    // NaN should only happen if viewport has height 0, but whatever
    return Number.isNaN(coveredRatio) ? 0 : coveredRatio;
}

export function isSectionPassingThresholds(
    intersection: IntersectionObserverEntry,
    sectionRatioThreshold: number,
    viewportRatioThreshold: number
): boolean {
    // (very small sections on large viewports can pass this check, but not the other)
    const isSectionRatioOverThreshold =
        intersection.intersectionRatio > sectionRatioThreshold;

    // (very large sections on small viewports pass this check, but not the other)
    const isViewportRatioOverThreshold =
        getRatioOfViewportCovered(
            // we know we have rootBounds because it's the viewport
            intersection.rootBounds as DOMRect,
            intersection.intersectionRect as DOMRect
        ) > viewportRatioThreshold;

    // EITHER: is percentage/ratio of section(!) in viewport over the needed threshold?
    // OR: is percentage/ratio of viewport(!) covered by section over the needed threshold?

    return isSectionRatioOverThreshold || isViewportRatioOverThreshold;
}

function useHasUserScrolled(): boolean {
    const [hasUserScrolled, setHasUserScrolled] = React.useState(false);

    React.useEffect(() => {
        if (!hasUserScrolled) {
            const handleScroll = () => setHasUserScrolled(true);

            window.addEventListener('scroll', handleScroll);

            return () => window.removeEventListener('scroll', handleScroll);
        }

        return;
    }, [setHasUserScrolled, hasUserScrolled]);

    return hasUserScrolled;
}

function useTrackingPredicate(
    sectionRatioThreshold: number,
    viewportRatioThreshold: number,
    isSmoothScrollRunning: boolean,
    anchorId?: string
): (entry: IntersectionObserverEntry) => boolean {
    const hasUserScrolled = useHasUserScrolled();

    const routerService = useRouterService();
    const location = routerService.getLocation();
    const [noDeepLink, isDeepLinked] = React.useMemo(() => {
        const hashAtLoad = location.hash.substring(1);
        const noDeepLink = hashAtLoad.length === 0;
        const isDeepLinked =
            anchorId && decodeURIComponent(hashAtLoad) === anchorId
                ? true
                : false;

        return [noDeepLink, isDeepLinked] as [boolean, boolean];

        // should only execute once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return React.useCallback(
        (entry: IntersectionObserverEntry): boolean => {
            // Behaviour specific to there being a deeplink to a section
            // If we have a deeplink, only that deeplinked section is allowed to track,
            // until the user scrolled at least once.

            const trackingAllowed =
                !isSmoothScrollRunning &&
                (hasUserScrolled || isDeepLinked || noDeepLink);

            return (
                trackingAllowed &&
                isSectionPassingThresholds(
                    entry,
                    sectionRatioThreshold,
                    viewportRatioThreshold
                )
            );
        },
        [
            isSmoothScrollRunning,
            hasUserScrolled,
            sectionRatioThreshold,
            viewportRatioThreshold,
            noDeepLink,
            isDeepLinked
        ]
    );
}

function useViewportTracking(
    params: ViewportTrackingHookParams
): (entries: IntersectionObserverEntry[]) => void {
    const trackingService = useTrackingService();
    const trackingData = useContextTrackingData(params.contentLabels);

    const trackEnterViewport = React.useCallback(
        (entries: IntersectionObserverEntry[]): void => {
            entries.forEach(() => {
                if (trackingService) {
                    trackingService.trackEnterViewport(
                        params.sectionId,
                        params.teaserList,
                        trackingData,
                        params.additionalTrackingData
                    );
                }
            });
        },
        [trackingService, params, trackingData]
    );

    return trackEnterViewport;
}

function useTriggerTracking(
    track: (entries: IntersectionObserverEntry[]) => void
): (entries: IntersectionObserverEntry[]) => void {
    return React.useCallback(
        (entries: IntersectionObserverEntry[]): void => {
            track(entries);
        },
        [track]
    );
}

function calculateIntersectionThresholds(upTo: number): number[] {
    const numSteps = 10;

    return Array(numSteps)
        .fill(0)
        .map((_, step) => ((step + 1) / numSteps) * upTo);
}

export function useEnterViewportTracking(
    ref: React.RefObject<HTMLDivElement>,
    trackingParams: ViewportTrackingHookParams,
    trackingTimeoutMs: number,
    sectionRatioThreshold: number,
    viewportRatioThreshold: number,
    ready: boolean,
    isSmoothScrollRunning: boolean,
    anchorId?: string
): void {
    const intersectionTrackingPredicate = useTrackingPredicate(
        sectionRatioThreshold,
        viewportRatioThreshold,
        isSmoothScrollRunning,
        anchorId
    );
    const trackEnterViewport = useViewportTracking(trackingParams);
    const triggerTracking = useTriggerTracking(trackEnterViewport);

    const intersectionThresholds = React.useMemo(
        () => calculateIntersectionThresholds(sectionRatioThreshold),
        [sectionRatioThreshold]
    );

    useIntersectionTrigger(
        ref,
        triggerTracking,
        intersectionTrackingPredicate,
        trackingTimeoutMs,
        intersectionThresholds,
        ready
    );
}
